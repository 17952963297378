import React, { useEffect, useState, useRef } from 'react';
import Styled from './index.style';
import cont3img from 'assets/images/event/211119/cont3.jpg';
import btnSubmitimg from 'assets/images/event/211119/btn_go.png';
import { openLink, gotoAILink } from 'common/util/util';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Plyr from "plyr-react";
import { useLocation } from 'react-router-dom';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";
import DefaultTextModal from 'component/Modal/DefaultTextModal';

const EventPage = () => {
	const { t } = useTranslation('event/20221006AiSpeak');
	const language = localStorage.getItem('i18nextLng');

	// const token = useSelector((state) => state.user.token);
	const isLogin = useLoginStatus();
	let { handleModal } = React.useContext(ModalContext);
	const location = useLocation();
	const user = useSelector((state) => state.user.data);

	// youtube
	const videoRef = useRef(null);

	const alertAILink = (e) => {
		e.preventDefault();
	
		handleModal(
		  <DefaultTextModal
			title={'Sorry for the inconvenience. Please use AI SPEAK on the desktop. (iOS 15.6~)'}
			btnType={'primary'}
		  />
		);
	}
	const clickAILink = (e) => {
		if (!isLogin) {
			e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
			handleModal(<Login />, false, 420, location.pathname);
			return;
		}else{
			gotoAILink(e, user, alertAILink);
		}
	}

  return (
		<Styled.EventWrap className={language === 'ko-KR' ? 'ko' : null}>
			<div className='visual'>
				<div className='inner'>
					<div className='btnWrap'>
						<a href="#!" onClick={clickAILink}>{t('visual.button')}</a>
					</div>
				</div>
			</div>

			<div className='sticky'>
				<a href="#!" onClick={clickAILink}><span className='blind'>{t('sticky.button')}</span></a>
      		</div>

			<div className='intro'>
				<div className='inner'>
					<h2><span className='blind'>{t('intro.title')}</span></h2>
					<ul>
						<li><span className='blind'>{t('intro.group.step1')}</span></li>
						<li><span className='blind'>{t('intro.group.step2')}</span></li>
						<li><span className='blind'>{t('intro.group.step3')}</span></li>
					</ul>
					<div className='txt_dsc'>
						<Trans t={t}>intro.desc</Trans>
					</div>
					<div className='txt_bg'>
						<Trans t={t}>intro.desc2</Trans>
					</div>
				</div>
			</div>

			<div className="video">
				<div className='inner'>
					<h2><span className='blind'>{t('video.title')}</span></h2>
					<p className='title_text'>{t('video.text')}</p>
					<div className="video_container">
						<Plyr
							ref={videoRef}
							source={{
								type: "video",
								sources: [
									{
										src: "gd2IHHxafTA",
										provider: "youtube",
									},
								],
							}}
						/>
					</div>
					<p className='txt_bg'><Trans t={t}>video.desc</Trans></p>
				</div>
			</div>

			<div className="participate">
				<div className='inner'>
					<h2><span className='blind'>{t('participate.title')}</span></h2>
					<ul>
						<li><span className='blind'>{t('participate.group.step1')}</span></li>
						<li><span className='blind'>{t('participate.group.step2')}</span></li>
						<li><span className='blind'>{t('participate.group.step3')}</span></li>
					</ul>
				</div>
			</div>

			<div className="present">
				<div className='inner'>
					<h2><span className='blind'>{t('present.title')}</span></h2>
					<ul>
						<li>
							<h3 className='blind'>TOP 10</h3>
							<p><Trans t={t}>present.group.desc</Trans></p>
						</li>
						<li>
							<a className='touch_bg' href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://dlvisang01.mastertopik.com/MasterTOPIK/(MT)360%20WORDS_B1_VOL2.pdf");}}></a>
							<h3 className='blind'>{t('present.group.stitle')}</h3>
							<a className='arrow' href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://dlvisang01.mastertopik.com/MasterTOPIK/(MT)360%20WORDS_B1_VOL2.pdf");}}><span className='blind'>{t('present.group.down')}</span></a>
							<p><Trans t={t}>present.group.desc2</Trans></p>
						</li>
					</ul>
					<div className='schedule'>
						<dl>
							<dt>{t('present.group2.stit')}</dt>
							<dd>{t('present.group2.text')}</dd>
							<dt>{t('present.group2.stit2')}</dt>
							<dd>{t('present.group2.text2')}</dd>
						</dl>
						<p className='help_text'>{t('present.helpText')}</p>
					</div>
				</div>
			</div>

			<div className='notification'>
				<div className='inner'>
					<h3>{t('notification.title')}</h3>
					<ul>
						<li>{t('notification.text')}</li>
						<li>
							<dl>
								<dt>{t('notification.text2.title')}</dt>
								<dd>{t('notification.text2.desc1')}</dd>
								<dd>{t('notification.text2.desc2')}</dd>
								<dd>{t('notification.text2.desc3')}</dd>
							</dl>
						</li>
						<li>{t('notification.text3')}</li>
						<li>{t('notification.text4')}</li>
						<li>{t('notification.text5')} <a href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4405428974611-How-to-use-AI-SPEAK-");}}>{t('notification.guide')}</a></li>
						<li>{t('notification.text6')} <a href="#!" onClick={(e)=>{e.preventDefault(); openLink("/event/topiknote");}}>{t('notification.click')}</a></li>
						<li>{t('notification.text7')}</li>
						<li>{t('notification.text8')}</li>
						<li>{t('notification.text9')}</li>
						<li>{t('notification.text10')}</li>
						<li>{t('notification.inquiry')} : <a href="mailto:mastertopik@visang.com">mastertopik@visang.com</a></li>
					</ul>
				</div>
			</div>
    </Styled.EventWrap>
  );
};

export default EventPage;

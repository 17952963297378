import styled from 'styled-components';

const StyledTable = {
  Table: styled.div`
    width: 100%;
    margin-bottom: 3em;
    table-layout: fixed;
    border-top: 1px #5d6165 solid;
    span {
      display: inline-block;
      width: 25%;
      text-align: center;
    }
    @media (max-width: 767px) {
      thead {
        display: none;
      }
      font-size: 1.25em;
    }
  `,
  TableHead: styled.div`
    span {
      padding: 1.5em 0 1em;
      color: #4f5258;
      font-size: 1.6em;
      font-weight: 400;
    }
    @media (max-width: 767px) {
      display: none;
    }
  `,
  TableBody: styled.div`
    > div {
      position: relative;
      width: 100%;
      border-bottom: 1px #2b2e35 solid;

      span {
        padding: 1.56em 0.1em;
        color: #fff;
        font-size: 1.6em;
        font-weight: 400;
      }
      @media (max-width: 767px) {
        span {
          width: 100%;
          text-align: left;
          padding: 0.6em 0;

          &:first-of-type {
            margin-top: 1em;
            padding-bottom: 0em;
            color: #8b8f99;
          }
          &:last-of-type {
            margin-bottom: 1.7em;
          }
        }
        .quantity {
          position: absolute;
          bottom: 1.8em;
          right: 1em;
          width: 6.1em;
          padding: 0.6em 0;
          border-radius: 0.6em;
          background-color: #2c2e32;
          text-align: center;
        }
      }
    }
  `,
};

export default StyledTable;

import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const DonutChart = ({ percentage }) => {
  const chartSize = 87;

  const data = {
    datasets: [{
      data: [percentage, 100 - percentage],
      backgroundColor: ['#ffb500', '#3d4045'],
      hoverBackgroundColor: ['#ffb500', '#3d4045'],
      borderWidth:0,
    }],
  };

  const options = {
    cutoutPercentage:92,
    tooltips: {
      enabled: false,
    },
    legend: {
      display: false,
    },
    elements: {
      center: {
        text: `${percentage}%`,
        color: '#3498db', // 퍼센트 색상
        sidePadding: 20, // 텍스트와 도넛의 간격
        minFontSize: 40, // 최소 폰트 크기
        maxFontSize: 50, // 최대 폰트 크기
      },
    },
  };

  return <Doughnut data={data} options={options} width={chartSize} height={chartSize} />;
};

export default DonutChart;
import React from 'react';
import Style from '../styled/List.style';
import ListItem from 'container/HelpPage/List/ListItem';
import { Link } from 'react-router-dom';

const List = ({ list, searchText }) => {
  return (
    <>
      <Style.ItemWrap>
        {
          list.map((item, index) => {
            return (
              <Link key={item.tblCustomerCenterDocIdx} to={`/help/article/${item.tblCustomerCenterDocIdx}`}>
                <ListItem item={item} searchText={searchText} />
              </Link>
            );
          })
        }
      </Style.ItemWrap>
    </>
  );
};
export default List;


import React, { useState, useEffect } from "react";
import Loading from "component/atoms/Loading";
import PageHeader from "component/atoms/PageHeader";
import Img from "component/atoms/Img";
import { Layout, SearchInput, List } from "container/LearningMaterialPage/styled";

import icoPdf from "assets/images/ico_file_pdf.png";
import icoExcel from "assets/images/ico_file_excel.png";
import icoMp3 from "assets/images/ico_file_mp3.png";
import icoImg from "assets/images/ico_file_img.png";
import icoWord from "assets/images/ico_file_word.png";
import icoOther from "assets/images/ico_file_other.png";

import { useAxios } from "common/hook/useAxiosHook";
import learningMaterialApi from "api/learningMaterial";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { debounce } from "lodash";
import SearchEmpty from "component/atoms/SearchEmpty";
import SeoHelmet from "component/Helmet";
import { SeoData } from "common/seoData";

import Features from "container/LandingPage/Features";
import { Trans, useTranslation } from "react-i18next";

const LearningMaterialPage = () => {
  const { t } = useTranslation("learningMaterialPage");
  const token = useSelector((state) => state.user.token);
  const [searchWord, setSearchWord] = useState("");

  const [list, fetchList] = useAxios(learningMaterialApi.getAllList, [], true);
  const { loading, data, error } = list;

  const [listItem, setListItem] = useState([]);
  // const [searchList, setSearchList] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const onSearch = debounce((e) => {
    setSearchWord(e.target.value);
  }, 1000);

  useEffect(() => {
    if (!searchWord) {
      fetchList({ page: 0, token });
    } else {
      fetchList({ Page: 0, token }, searchWord);
    }
  }, [searchWord]);

  useEffect(() => {
    if (data && !searchWord) {
      if (data.first) {
        setListItem([...data.content]);
      } else {
        setListItem([...listItem, ...data.content]);
      }
      setHasMore(data.number + 1 < data.totalPages);
    } else if (data && searchWord) {
      if (data.first) {
        setListItem([...data.content]);
      } else {
        setListItem([...listItem, ...data.content]);
      }
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [list]);

  const onNext = () => {
    if (!searchWord) {
      fetchList({ page: data.number + 1, token });
    } else {
      fetchList({ page: data.number + 1, token }, searchWord);
    }
  };

  const getFileExtension = (item) => {
    let fileExtension = "";
    const isFile = item.length > 0 ? item[0].name.split(/\./).reverse()[0] : "";

    if (isFile === "pdf") {
      fileExtension = icoPdf;
    } else if (isFile === "doc" || isFile === "docx") {
      fileExtension = icoWord;
    } else if (isFile === "xls" || isFile === "xlsx") {
      fileExtension = icoExcel;
    } else if (isFile === "jpg" || isFile === "JPG" || isFile === "jpeg" || isFile === "png" || isFile === "PNG") {
      fileExtension = icoImg;
    } else if (isFile === "mp3") {
      fileExtension = icoMp3;
    } else {
      fileExtension = icoOther;
    }
    const fileIcon = item.length > 1 ? icoOther : fileExtension;
    return isFile === "" ? "" : <Img src={fileIcon} alt="figure Image" />;
  };

  return (
    <Layout.Wrapper>
      <SeoHelmet title={SeoData.learningMaterial.title} desc={SeoData.learningMaterial.desc} />
      <PageHeader title={t("title")} text={t("subtitle")} />

      <SearchInput.Container>
        <SearchInput.InputContainer>
          <SearchInput.SearchImage />
          <SearchInput.Input onChange={(e) => onSearch(e)} placeholder={t("placeholder")} />
          <SearchInput.CloseImage />
        </SearchInput.InputContainer>
      </SearchInput.Container>

      {data?.content.length === 0 ? (
        <SearchEmpty type="search" />
      ) : (
        <List.List>
          <InfiniteScroll dataLength={listItem.length} next={onNext} loader={<Loading />} hasMore={hasMore}>
            {listItem.map((item) => {
              const date = item.regDate.split("T")[0];

              return (
                <List.Item key={item.idx}>
                  <List.Icon>{getFileExtension(item.attachments)}</List.Icon>
                  <List.Category>{item.categoryCode}</List.Category>
                  <List.Title>
                    <List.TitleLink to={`/learning-material/detail/${item.idx}`}>{item.title}</List.TitleLink>
                  </List.Title>
                  <List.Date>{date}</List.Date>
                </List.Item>
              );
            })}
          </InfiniteScroll>
        </List.List>
      )}
    </Layout.Wrapper>
  );
};

export default LearningMaterialPage;

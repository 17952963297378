import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import LevelTestHeader from 'container/LevelTestPage/common/LevelTestHeader';
import checkState from 'container/LevelTestPage/common/checkState';
import Step from 'container/LevelTestPage/common/Step';
import { Button, Result } from 'container/LevelTestPage/styled';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const { t } = useTranslation('levelTestPage');

  checkState();

  const history = useHistory();
  const { state } = useLocation();
  const [percent, setPercent] = useState(0);

  let count = '';
  let total = '';

  if (state) {
    count = state.count;
    total = state.total;
  }


  let score = '';
  let testLevel = '';

  if (count === 0) {
    score = 0;
    testLevel = 0;
  } else if (count <= 5) {
    score = 25;
    testLevel = 1;
  } else if (count <= 10) {
    score = 50;
    testLevel = 2;
  } else if (count <= 14) {
    score = 70;
    testLevel = 3;
  } else if (count <= 16) {
    score = 80;
    testLevel = 4;
  } else if (count <= 18) {
    score = 90;
    testLevel = 5;
  } else if (count <= total) {
    score = 100;
    testLevel = 6;
  }

  useEffect(() => {
    let increased = setTimeout(() => {
      setPercent(score);
    }, 500);

    return () => clearTimeout(increased);
  }, [percent]);
  console.log(testLevel);

  const handleClick = () => {
    history.push({
      pathname: '/level-test/select-target',
      state: {
        ...state,
        languageLevel: testLevel,
      },
    });
  };

  return (
    <>
      <Step activeIndex={2} />
      <LevelTestHeader
        title={t('result.title')}
      />
      <Result.Box>
        <Result.CircleWrapper>
          <Result.Circle
            percent={percent}
            strokeColor='#ffb500'
            gapDegree={150}
            gapPosition="bottom"
          />
          <Result.Percent>{testLevel}</Result.Percent>
        </Result.CircleWrapper>
        <Result.Msg>{t(`selectTarget.level${testLevel}`)}</Result.Msg>
        <Result.Score score={count} total={total}>{t('result.desc2')}</Result.Score>
      </Result.Box>
      <Button.ButtonGroup className='center'>
        <Button.Button onClick={handleClick}>{t('result.button')}</Button.Button>
        <Button.ReturnLink to='/level-test'>{t('goBack')}</Button.ReturnLink>
      </Button.ButtonGroup>
    </>
  );
};

export default Index;

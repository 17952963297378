import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import main from './index';
// import SpecialLecture from 'container/EventPage/20220714SpecialLecture/index';
import Teachers from './Teacher';
import ErrorPage from "component/atoms/Error";

export default function CoursesRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={main} />
      {/* <Route exact path={`${path}/korean`} component={SpecialLecture} /> */}
      <Route exact path={[`${path}/teachers/:teacherId`, `${path}/teachers`]} component={Teachers} />

      <Route path={`${path}`} component={ErrorPage} />
    </Switch>
  );
}

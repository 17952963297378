import styled from 'styled-components';
import { Link } from 'react-router-dom';
import StyledForm from 'component/form/ModalForm.style';
const StyledSiteMap = {
  StyledSelect: styled(StyledForm.Select)`
  width: 10em;
  font-size: 1.6rem;
  margin-bottom: 3.5rem;
  .select__control {
    min-height: 25px;
    background-color: #2b2e35;
  }
  > .select__menu,
  .select__option,
  .select__menu-list {
    background-color: #2b2e35;
  }
  .select__option:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  .css-b8ldur-Input {
    color: #fff;
  }
  .select__indicator {
    svg {
      color: #8b8f99;
    }
  }
  .select__placeholder,
  .select__single-value {
    color: #fff;
    font-weight: 400;
  }
  @media (max-width: 930px) {
    margin-bottom:0;
    width:100%;
    .select__control {
      min-height:42px;

      background: none;
      border:1px solid #555555;
    }
    > .select__menu,
    .select__option,
    .select__menu-list {
      background-color: none;
    }
      .select__value-container{

      }
    .select__indicators{
      border-left:1px solid #555555;
    }
  }
`,

  Section: styled.section`
    margin-bottom: 8em;
    overflow: hidden;
    &.footer-after-login {
      .sitemap-group {
        width: calc((100% - 20em) / 6);
        &.lang,
        &.sns{
          width:100%
        }
        @media (max-width: 930px) {
            &.lang{
              width:45%
            }
        }
      }
      .class {
        width: 13.7em;
      }
      .long{
        width:20em;
        @media (max-width: 1200px) {
          width:16em;
        }
      }

    }
    @media (max-width: 930px) {
      overflow: visible;

      &.footer-after-login {
        display: flex;
        flex-wrap: wrap;
        .sitemap-group {
          width: 33.33%;
        }
      }
    }
  `,
  Group: styled.div.attrs({ className: 'sitemap-group' })`
    float: left;
    width: 20%;

    @media (max-width: 930px) {
      width: calc(100% / 3);
      &.about{
        margin-top: -67px;
      }
      &.sitemap-group.lang,
      &.sitemap-group.class,
      &.sitemap-group.sns {
        clear: both;
        float: none;
        width: auto;
        text-align: left;
        h2 {
          text-transform: none;
          /* display: none; */
          color: #8e9199;
          font-size:12px;
          margin-bottom: 15px;;
        }
      }
      &.sitemap-group.sns {

        font-size: 2em;
        position: absolute;
        left:0;
        top: 50px;
        span {
          display: none;
        }
        div {
          display: inline-block;
        }
        svg{
          font-size: 4rem;
          margin-right: 0.9em;
          path{color:#fff;}
        }
      }
      &.sitemap-group.class {
        width:45%;
        position: absolute;
        left:0;
        bottom:0;
        > div {
          /* max-width: calc(40% - 2em); */
          margin: 2em 1em 0 0;
        }
      }
      &.sitemap-group.lang{
        width:45%;
        position: absolute;
        right:0;
        bottom:0;
      }
      &.line2{
        margin-top: 5rem;
      }
    }
  `,
  SnsGroupLists: styled.div`
    display: flex;
  `,
  colGroup : styled.div`
    display: flex;
    flex-direction: column;
    >div{width:100% }

    @media (max-width: 930px) {
      position:relative;
      clear: both;
        float: none;
        width: auto;
        height:165px;
      width: 100%;
      padding: 210px 0 0;
    }
  `,
  GroupName: styled.h2`
    font-size: 18px;
    min-height: 1.5em;
    margin-bottom: 3rem;
    text-transform: uppercase;
    font-weight: 400;
    color: #fff;;
    a {
      font-size: 18px;
        span{
          color: #fff;;
        }
    }
    /* color: #969696; */
    @media (max-width: 1200px) {
      font-size: 15px;
      a{
        font-size: 15px;
        span{font-size: 15px;}
      }
    }
    @media (max-width: 930px) {
      /* font-size: 2em; */

          font-size: 1.6rem;
          font-weight: 400;
          color: #fff;;

      a {
        font-size: 1.6rem;
        span{
          font-size: 1.6rem;
          font-weight: 400;
          color: #fff;;
          svg.arrow {
            position: relative;
            top:-1px;
            margin: 0px 0 0 7px;
            transform: scale(0.7);
          }
        }
      }
      &.mock{
        width:131px;
      }
    }
    &.mock{
      margin-top:3.5em;
      margin-bottom:3rem;
    }
    &.about-after-login {
      margin-top: 4em;
      margin-bottom: 1em;
    }
  `,
  Item: styled.div`
    &:not(:last-child) {
      margin-bottom: 3em;
    }

    &.select-box {
      > div {
        display: inline-block;
        width: 13.7em;
      }
      .select__control {
        border-radius: 0.4rem;
      }
      @media (max-width: 930px) {
        height: 100%;
        > div {
          width: 20rem;
        }
        .select__menu {
          text-align: left;
        }
      }
      @media (max-width: 375px) {
        > div {
          width: 23rem;
        }
      }
    }
  `,
  ItemLink: styled(Link)`
    font-size: 1.6rem;
    font-weight: 400;
    color:#cccccc;
    &:hover {
      text-decoration: underline;
    }
    svg {
      font-size: 1.3em;
      width: 1em;
      margin-right: 0.5em;
      vertical-align: -0.2em;
    }
    svg.arrow {
        margin:6px 0.5em;
      }
    img {
      display: inline-block;
      border: 1px solid #999;
      border-radius: 4px;
      overflow: hidden;
      vertical-align: middle;
    }
    @media (max-width: 1200px) {
      font-size: 13px;
    }
    @media (max-width: 930px) {
      font-size: 12px;

      /* padding-right: 10px; */
      svg.arrow {
        font-size: 7px;
        margin: 0 0.5em;
      }
    }
  `,
};

export default StyledSiteMap;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styled from './index.style';
import H3 from 'component/atoms/H3';
import UserThemeItem from 'component/molecules/ThemeItem/UserThemeItem';

import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { useSelector } from 'react-redux';
import { masterTopikUrl } from 'common/constant';

import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';

SwiperCore.use([Navigation]);

const swiperBreakPoint = {
  1200: { slidesPerView: 2.5, spaceBetween: 40 },
  1025: { slidesPerView: 2, spaceBetween: 20 },
  768: { slidesPerView: 3.5, spaceBetween: 20 },
  420: { slidesPerView: 2.5, spaceBetween: 10 },
  0: { slidesPerView: 1.5, spaceBetween: 10 },
};

/**
 * @param {*} param
 * @param {array} param.initState [state, fetchData]
 * @returns
 */
const PopularThemes = ({ initState }) => {
  const { t } = useTranslation('communityPage');
  const token = useSelector((state) => state.user.token);
  const [themeList, fetchThemeList] = initState;
  const { loading, data } = themeList;
  const [isNextChange, setIsNextChange] = useState(false);
  const [slideItem, setSlideItem] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => fetchThemeList({ page: 0, token }), []);

  useEffect(() => {
    if (isNextChange && data !== null && currentPage < data.totalPages - 1) {
      !loading && fetchThemeList({ page: currentPage + 1, token });
      setCurrentPage(currentPage + 1);
    }
  }, [isNextChange]);

  useEffect(() => {
    const tempSlideDataList = data?.content;
    tempSlideDataList && setSlideItem([...slideItem, ...tempSlideDataList]);
  }, [themeList]);

  return (
    <Styled.Container>
      <H3>{t('title2')}</H3>
      <Styled.Swiper
        breakpoints={swiperBreakPoint}
        slidesPerGroup={2}
        onSlideNextTransitionStart={() => setIsNextChange(true)}
        onSlideNextTransitionEnd={() => setIsNextChange(false)}
        navigation
      >
        {slideItem.map((item, index) => {
          const imgSrcList = item.themeProductResponse.map(
            (itemChild) =>
              masterTopikUrl +
              itemChild?.thumbnailPath +
              itemChild?.thumbnailTitle
          );
          return (
            <SwiperSlide key={index}>
              <UserThemeItem
                id={item.id}
                imgSrcList={imgSrcList}
                title={item.name}
                tag={item.categoryName}
                videoCount={item.courseCount}
                likeCount={item.likeCount}
                userThumb={item.userProfilePhotoPath}
                userName={item.userName}
                isGood={item.isGood}
              />
            </SwiperSlide>
          );
        })}
      </Styled.Swiper>
    </Styled.Container>
  );
};

PopularThemes.propTypes = {
  initState: PropTypes.array.isRequired,
};
export default PopularThemes;

import React, { useEffect, useRef, useState} from 'react';
import Styled from './index.style';

import { Trans, useTranslation } from 'react-i18next';
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import { openLink } from 'common/util/util';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";


const EventPage = () => {
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation('event/20221130BookSale');

  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);
	const location = useLocation();

	const checkLogin = (e) => {
    if (!isLogin) {
      e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
			handleModal(<Login />, false, 420, location.pathname);
			return;
		}
    // else{
    //   openLink(`/bookstore`);
    // }
	}

	return (
		<Styled.EventWrap className={language === 'ko-KR' ? 'ko' : null}>
      <div className='visual'>
        <div className='inner'>
          <div className='blind'>
            <h2>{t('visual.title')}</h2>
            <p>{t('visual.desc')}</p>
            <p>{t('visual.desc2')}</p>
            <p>{t('visual.desc3')}</p>
          </div>
        </div>
      </div>

      <div className='buynow'>
        <div className='inner'>
          <div className='h2'>
            <Trans t={t}>buynow.title</Trans>
            <h2 className='mt'><Trans t={t}>buynow.title2</Trans></h2>
          </div>
          <p className='label'>{t('buynow.label')}</p>
          <div className='links'>
            {/* <a className='korean' href="#!" onClick={checkLogin}> */}
            <Link to={"/bookstore"} className='korean' onClick={checkLogin}>
              <div className='info'>
                <div className='tit'><Trans t={t}>buynow.link.tit</Trans></div>
                <p>{t('buynow.link.stit')}</p>
                <div className='price'>
                  <em>15%</em>
                  <strong>$22.10</strong>
                  <span>$26.10</span>
                </div>
                <span className='btn'>{t('buynow.button')}</span>
              </div>
            </Link>
            <a className='amazon' href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://www.amazon.com/s?i=stripbooks&rh=p_27%3AVISANG")}}>
              <div className='info'>
                <div className='tit'><Trans t={t}>buynow.link2.tit</Trans></div>
                <p>{t('buynow.link2.stit')}</p>
                <div className='price'>
                  <em>50%</em>
                  <strong>$13.05</strong>
                  <span>$26.10</span>
                </div>
                <span className='btn'>{t('buynow.button')}</span>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className='ebook'>
        <div className='inner'>
          <div className='h2'>
            <h2 className='mb'><Trans t={t}>ebook.title</Trans></h2>
            <Trans t={t}>ebook.title2</Trans>
          </div>
          <div className='img'>
            <Styled.BtnArrow>
              <a href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/11153733207059--Event-Books-sale")}}><Trans t={t}>ebook.button</Trans></a>
            </Styled.BtnArrow>
          </div>
        </div>
      </div>

      <div className='notification'>
        <div className='inner'>
          <h3>{t('notification.title')}</h3>
          <dl>
            <dt>{t('notification.group1.title')}</dt>
            <dd><Trans t={t}>notification.group1.desc1</Trans></dd>
            <dd><Trans t={t}>notification.group1.desc2</Trans></dd>
            <dd><Trans t={t}>notification.group1.desc3</Trans></dd>
            <dd><Trans t={t}>notification.group1.desc4</Trans></dd>
          </dl>
          <dl className='number'>
            <dt>{t('notification.group2.title')}</dt>
            <dd><span className='num'>1)</span><Trans t={t}>notification.group2.desc1</Trans></dd>
            <dd><span className='num'>2)</span><Trans t={t}>notification.group2.desc2</Trans></dd>
            <dd><span className='num'>3)</span><Trans t={t}>notification.group2.desc3</Trans></dd>
          </dl>
          <dl>
            <dt>{t('notification.group3.title')}</dt>
            <dd>E-mail: <a href="mailto:mastertopik@visnag.com">mastertopik@visang.com</a></dd>
          </dl>
        </div>
      </div>
		</Styled.EventWrap>
  );
};

export default EventPage;
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {Trans, useTranslation } from 'react-i18next';
import Styled from '../styled/ProductModal.style';
import { ModalContext } from 'component/Modal/modalContext';
import useLoginStatus from "common/hook/useLoginStatus";
import { useAxios } from 'common/hook/useAxiosHook';
import productApi from 'api/product';
import userApi from 'api/user';
import { addApplication, deleteApplication, addItem, deleteAllItem } from "container/PaymentPage/state/action";
import ConfirmModal from "../ConfirmModal";
import ProductItem from './ProductItem';

const ProductModal = ({data, products, selectedIdx}) => {
  const { t } = useTranslation('aliveClassPage');
  let { handleModal } = React.useContext(ModalContext);
  const history = useHistory();
  const language = localStorage.getItem('i18nextLng');
	const token = useSelector((state) => state.user.token);
  const userIdx = useSelector((state) => state.user.userIdx);
	const dispatch = useDispatch();
  
	const [tabActive, setTabActive] = useState(1);  //탭 1 | 2
	const [selectIdx, setSelectIdx] = useState(selectedIdx? selectedIdx : -1); //선택한 상품 (상품코드 기준)

  const [teachingTime, setTeachingTime] = useState('');   //수업 가능 시간
  const [productTitleList, setProductTitleList] = useState([]); //수업 제목
  const [priceCostList, setPriceCostList] = useState([]);       //정가 number
  const [salesCostList, setSalesCostList] = useState([]);       //판매가 number
  const [discountTypeList, setDiscountTypeList] = useState([]); //할인 타입 string PER | CST
  const [discountCostList, setDiscountCostList] = useState([]); //할인량 number

  const applicationRef = useRef();
  const [email, setEmail] = useState("");
  const [schedule, setSchedule] = useState("");
  const [learn, setLearn] = useState("");

  const selectedTitle = useMemo(()=>{ //선택한 상품 제목
    const find = products.findIndex((data)=>data.tblProductIdx === selectIdx);
    if(find > -1){
      if(productTitleList.length === 0) return '';
      return productTitleList[find];
    }
    return '';
  }, [selectIdx, productTitleList]);

  const selectedPrice = useMemo(()=>{ //선택한 상품 금액
    const find = products.findIndex((data)=>data.tblProductIdx === selectIdx);
    let costStr = '';
    if(find > -1){
      if(salesCostList.length === 0) return '';
      const cost = salesCostList[find];
      
      if(language === 'ko-KR'){
        costStr = `KRW ￦${cost.toLocaleString('ko-KR')}`;
      }else{
        if(cost%1 !== 0) costStr = `USD $${cost}`;
        else costStr = `USD $${cost}.00`;
      }
    }
    return costStr;
  }, [selectIdx, salesCostList]);

	const [
		{ loading: productLoading, data: productData },
		fetchProduct,
	] = useAxios(productApi.getVideoClassProduct, [], true);
  const [
		{ data: myVCData },
		fetchMyVCPaymentList
	] = useAxios(() => userApi.fetchMyPaymentListByItemType('VIDEOCLASS', token), [], true);

  useEffect(()=>{
    console.log('data:: ', data)
    if(data){
      if(language === 'ko-KR'){
        setTeachingTime(data.teachingTimeKO);
      }else{
        setTeachingTime(data.teachingTimeEN);
      }
    }
  }, [data])
  useEffect(()=>{
    console.log('products:: ', products)
    if(products && products.length > 0){
      let title = [];
      let pCost = [];
      let sCost = [];
      let dType = [];
      let dCost = [];
      if(language === 'ko-KR'){
        products.map((product)=>{
          title = [...title, product.productTitle];
          pCost = [...pCost, product.priceCostWon];
          sCost = [...sCost, product.salesStoreWon];
          dType = [...dType, product.discountTypeWon];
          dCost = [...dCost, product.discountCostWon];
        });
      }else{
        products.map((product)=>{
          title = [...title, product.productTitleEN];
          pCost = [...pCost, product.priceCostDollar];
          sCost = [...sCost, product.salesStoreDollar];
          dType = [...dType, product.discountTypeDollar];
          dCost = [...dCost, product.discountCostDollar];
        });
      }
      setProductTitleList(title);
      setPriceCostList(pCost);
      setSalesCostList(sCost);
      setDiscountTypeList(dType);
      setDiscountCostList(dCost);
    }
  }, [products]);

  useEffect(() => {
		if(myVCData && selectIdx > -1){
			const orderList = myVCData.filter((data)=>data.productIdx === selectIdx);
			if(orderList.length > 0){
				//구매불가능
				handleModal(
					<ConfirmModal
						tMessage={t('doublePaymentModal.title')}
						tButton={t('doublePaymentModal.confirm')}
					/>
				)
				return;
			}else{
				//구매가능
				fetchProduct(selectIdx, token);
			}
		}
	},[myVCData, selectIdx])

  useEffect(()=>{
    if(productData){
			const name = (language) => {
				switch(language){
					case 'ko-KR':
						return productData.title;
					case 'en-US':
						return productData.titleEN;
					case 'zh-CN':
						return productData.titleCN;
					case 'ja-JP':
						return productData.titleJP;
					default:
						return productData.title;
				};
			}

			const videoItem = {
				id: productData.productId, //data.id,
				type: 'Video Class',
				name: name(language),
				price: productData.salesStoreWon,
				priceDollar: productData.salesStoreDollar,
				discountCost: productData.priceCostWon - productData.salesStoreWon,
				discountCostDollar: productData.priceCostDollar - productData.salesStoreDollar,
			};

			dispatch(deleteAllItem());
			dispatch(addItem(videoItem));
			history.push({
				pathname: `/payment/video-class`,
			});
      handleModal();
    }
  }, [productData])

  const clickProductItem = (productIdx) => {
    // alert('상품클릭'+productIdx);
    setSelectIdx(productIdx);
  }

  const clickNext = useCallback(() => {
    const find = products.find((data)=>data.tblProductIdx === selectIdx);
    if(selectIdx > -1 && find){
      setTabActive(2);
    }else{
      alert(t('ProductModal.step1.alert'));
      // handleModal(
      //   <ConfirmModal
      //     tMessage={'원하는 수업 상품을 선택하세요'}
      //     tButton={'확인'}
      //   />
      // );
    }
  }, [products, selectIdx]);

  const clickApply = useCallback(() => {
    if(applicationRef && applicationRef.current){
      // applicationRef.current.submit();
      
      dispatch(deleteApplication());
      if(email && schedule && learn){
        dispatch(addApplication({userIdx, productIdx: selectIdx, email, schedule, learn}));
        fetchMyVCPaymentList(token);
      }else{
        alert(t('ProductModal.step2.alert'));
      }
    }
  }, [email, schedule, learn, selectIdx]);

  return (
    <Styled.Container>
      <Styled.H3>
        <Styled.TeacherName>
          <div className='label'>Teacher</div>
          <div className='name'>{data.teacherNameEN}</div>
          <div className='name'>{data.teacherName}</div>
        </Styled.TeacherName>
      </Styled.H3>
      <Styled.ContentWrap>
        <Styled.Steps>
          <div className={tabActive === 1 ? 'active' : ''}><span>01</span><p>{t('ProductModal.step1.title')}</p></div>
          <div className={tabActive === 2 ? 'active' : ''}><span>02</span><p>{t('ProductModal.step2.title')}</p></div>
        </Styled.Steps>

        {/* step1 */}
        {tabActive === 1
        ?
        <Styled.StepCont>
          <Styled.Info>
            <div className='info'>
              <Trans t={t}>ProductModal.step1.info</Trans>
            </div>
          </Styled.Info>

          {/* 상품목록 */}
          <div>
            {products && products.length > 0
            ? products.map((product, index)=>{
                return (
                  <ProductItem 
                    key={'productItem-modal-'+index}
                    modal={true}
                    isSelected={product.tblProductIdx === selectIdx}
                    productIdx={product.tblProductIdx}
                    title={productTitleList[index]}
                    round={product.classRound}
                    duration={product.classDuration}
                    priceCost={priceCostList[index]}
                    salesCost={salesCostList[index]}
                    discountType={discountTypeList[index]}
                    discountCost={discountCostList[index]}
                    onClick={clickProductItem}
                  />
                );
              })
            :<></>}
          </div>
        </Styled.StepCont>
        : <></>}

        {/* step2 */}
        {tabActive === 2
        ?
        <Styled.StepCont>
          <Styled.Info>
            <div className='info'>
              <div className='stitle'>{t('ProductModal.step2.stitle')}</div>
              <div className="teachingTime">{teachingTime}</div>
            </div>
            <form ref={applicationRef} id="application" className='form'>
              <div className='tit'>{t('ProductModal.step2.label')}</div>
              <p>{t('ProductModal.step2.desc')}</p>
              <div className='input'><input id="email" type="text" onChange={(e)=>setEmail(e.target.value)}/></div>

              <div className='tit'>{t('ProductModal.step2.label2')}</div>
              <p>{t('ProductModal.step2.desc2')}</p>
              <div className='input'><textarea name="" id="schedule" cols="30" rows="2" onChange={(e)=>setSchedule(e.target.value)}></textarea></div>

              <div className='tit'>{t('ProductModal.step2.label3')}</div>
              <p>{t('ProductModal.step2.desc3')}</p>
              <div className='input'><textarea name="" id="learn" cols="30" rows="2" onChange={(e)=>setLearn(e.target.value)}></textarea></div>
            </form>
          </Styled.Info>
        </Styled.StepCont>
        : <></>}

      </Styled.ContentWrap>
      <Styled.ButtonWrap>
        <div className='txt'>
          <div className='product'>
            {/* 수업 선택 전*/}
            {/* {t('ProductModal.button.info')} */}
            {/* 수업 선택 후*/}
            {/* <strong>1 Month Package / 4 Lessons / 60 min</strong> */}
            <strong>{selectedTitle}</strong>
          </div>
          <div className='price'>
            {selectedPrice}
          </div>
        </div>
        <div className='btnWrap'>
          <button className={tabActive === 1 ? 'on' : ''} onClick={clickNext}>{t('ProductModal.button.next')}</button>
          <button className={tabActive === 2 ? 'on' : ''} onClick={clickApply}>{t('ProductModal.button.apply')}</button>
        </div>
      </Styled.ButtonWrap>
    </Styled.Container>
  );
};

export default ProductModal;

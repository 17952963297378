import styled from 'styled-components';

const StyledPaymentPage = {
  SelectContainer: styled.div`
    float: left;

    @media (max-width: 767px) {
      font-size: 1.25em;
    }

    &::after {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }
    .selectLabel {
      display: inline-block;
      margin-right: 1em;
      font-size: 1.6em;
      font-weight: 400;
    }
    .paySelect {
      border-radius: 0.4em;
      cursor: pointer;

      input {
        padding: 0.4em 1em;
        cursor: pointer;
      }
      ul {
        top: 3.2em;
        li {
          padding: 0.4em 1em;
        }
      }
    }
  `,
};

export default StyledPaymentPage;

import React, { useCallback, useEffect, useState } from "react";
import StyledForm from "component/form/ModalForm.style";
import StyledModal from "component/Modal/styled/Modal.style";
import Styled from "./styled/CreateEmailAccount.style";
import { Link, useHistory } from "react-router-dom";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";
import makeAnimated from "react-select/animated";
import { useAxios } from "common/hook/useAxiosHook";
import {
  signUpUser,
  getTermsOfUse,
  getTermsOfInfo,
  getTermsOfHandling,
  getNationalityList as getNationality,
} from "component/user/api/userApi";
import TermsForm from "component/user/TermsForm";
import { onValidate } from "common/util/regex";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "component/user/state";
import { UserStatus } from "common/constant";
import { toast } from "react-toastify";
import userApi from "api/user";
import useErrorToastify from "common/hook/useErrorToastify";
import { GoCheck } from "react-icons/go";
import LoadingOverlay from "component/atoms/LoadingOverlay";
import { Trans, useTranslation } from "react-i18next";

const animatedComponents = makeAnimated();

export default function CreateEmailAccount({ userID, ssid }) {
  const { handleModal } = React.useContext(ModalContext);

  const { t } = useTranslation("signupModal");

  const [
    {
      loading: confirmationLoading,
      data: confirmationRes,
      error: confirmationError,
    },
    fetchConfirmationEmail,
  ] = useAxios(userApi.postConfirmationEmail, [], true);

  useErrorToastify(confirmationError);

  useEffect(() => {
    if (!userID || !ssid) {
      toast.error("이메일 인증이 필요합니다.");
      handleModal("");
      return;
    }
    fetchConfirmationEmail({ userID, ssid });
  }, [userID, ssid]);

  const openLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false);
  };

  const [formData, setFormData] = useState({
    userID,
    ssid,
    countryIndex: null,
    // userFirstName: null,
    // userLastName: null,
    userFullName: null,
    nickname: null,
    password: null,
    termsOfUse: false,
    termsOfInfo: false,
    emailAcceptance: false,
    smsAcceptance: false,
    userInfoCollectionAcceptance: true,
  });

  const [validationMsg, setValidationMsg] = useState({
    email: null,
    name: null,
    password: null,
  });

  // 유효성 검사
  const validationCheck = useCallback(
    (key, value, msg) => {
      setValidationMsg({
        ...validationMsg,
        [key]: !onValidate[key](value) && msg,
      });
    },
    [validationMsg]
  );

  // 회원 가입 api 전달에 필요한 닉네임 생성 (성 + 이름 조합)
  useEffect(() => {
    setFormData({
      ...formData,
      nickname: formData.userFullName,
    });
  }, [formData.userFullName]);

  const handleChange = useCallback(
    ({ target }) => setFormData({ ...formData, [target.name]: target.value }),
    [formData]
  );

  const selectHandleChange = useCallback(
    ({ value }, { name }) => setFormData({ ...formData, [name]: value }),
    [formData]
  );

  const [fullTermsOfUse] = useAxios(getTermsOfUse);
  const [fullTermsOfInfo] = useAxios(getTermsOfInfo);
  const [fullTermsOfHandling] = useAxios(getTermsOfHandling);
  const [nationalityResponse] = useAxios(getNationality);
  const [signUp, fetchSignUp] = useAxios(signUpUser, [], true);
  const { loading: signUpLoading, data: response, error } = signUp;
  const [reformatNationList, setReformatNationList] = useState();

  console.log(getNationality);
  // 국가 목록 reformat
  useEffect(() => {
    if (nationalityResponse && nationalityResponse.data) {
      const nationList = [];

      nationalityResponse?.data?.map((item) =>
        nationList.push({
          value: item.id,
          label: item.countryNameENG,
        })
      );

      if (!reformatNationList && nationList.length > 0) {
        setReformatNationList(nationList);
      }
    }
  }, [nationalityResponse]);

  const dispatch = useDispatch();
  const loginStatus = useSelector((state) => state.user.status);
  const token = useSelector((state) => state.user.token);

  // 회원가입 요청
  const onSubmit = async (e) => {
    e.preventDefault();

    if (!formData.termsOfUse || !formData.termsOfInfo) {
      toast.error("You must agree to the required terms");
      return false;
    }

    await fetchSignUp(formData);
  };

  // 중복 이메일 가입 에러
  useEffect(() => error && alert(error.data.message), [error]);

  // 가입 완료 후 로그인 (토큰 발급)
  useEffect(() => {
    response &&
      response.status === 201 &&
      dispatch(actions.fetchLogin(formData.userID, formData.password));
  }, [dispatch, response]);

  // 로그인(토근 발급) 후 회원 정보 조회 및 modal 닫기
  const history = useHistory();
  useEffect(() => {
    if (loginStatus === UserStatus.Auth) {
      dispatch(actions.fetchUser(token));
    } else if (loginStatus === UserStatus.Login) {
      history.push({
        pathname: '/complete-signup',
        state: {
          refresh: true,
        }
      });
      handleModal();
    }
  }, [dispatch, handleModal, loginStatus, token]);

  return (
    <>
      <LoadingOverlay isLoading={confirmationLoading || signUpLoading} />

      <StyledModal.H2>{t("title")}</StyledModal.H2>
      <StyledForm.FormWrapper onSubmit={onSubmit}>
        <Styled.TopDesc>{t("guidance1")}</Styled.TopDesc>

        <StyledForm.InputContainer>
          <StyledForm.Label htmlFor="userID">
            {confirmationRes?.status === 200 && (
              <span className="confirmed">
                <GoCheck />
              </span>
            )}
            {t("email.label")}
          </StyledForm.Label>
          <StyledForm.Input
            type="text"
            id="userID"
            name="userID"
            autoComplete="email"
            value={formData.userID}
            onChange={handleChange}
            placeholder={t("email.placeholder")}
            disabled
          />
          <Styled.EmailAuthButton onSubmit={onSubmit} disabled>
            {t("email.verified")}
          </Styled.EmailAuthButton>
        </StyledForm.InputContainer>

        <TermsForm
          setFormData={(termsData) =>
            setFormData({ ...formData, ...termsData })
          }
          fullTermsOfUse={fullTermsOfUse}
          fullTermsOfInfo={fullTermsOfInfo}
          fullTermsOfHandling={fullTermsOfHandling}
          t={t}
        />

        <StyledForm.InputContainer>
          <StyledForm.Label htmlFor="password">
            {t("password.label")}
          </StyledForm.Label>
          <StyledForm.Input
            type="password"
            id="password"
            name="password"
            autoComplete="new-password"
            minLength="10"
            maxLength="20"
            placeholder={t("password.placeholder")}
            onChange={handleChange}
            onBlur={({ target }) =>
              validationCheck(
                "password",
                target.value,
                t("password.placeholder")
              )
            }
            required
          />
          {validationMsg.password && (
            <StyledForm.WarnText>{validationMsg.password}</StyledForm.WarnText>
          )}
        </StyledForm.InputContainer>

        <Styled.NameContainer>
          <StyledForm.Label htmlFor="userFullName">
            {t("name.label")}
          </StyledForm.Label>
          <StyledForm.Input
            type="text"
            name="userFullName"
            autoComplete="full-name"
            id="userFullName"
            placeholder={t("name.placeholder")}
            onChange={handleChange}
            onBlur={({ target }) =>
              validationCheck("name", target.value, t("name.placeholder"))
            }
            required
          />
          {validationMsg.name && (
            <StyledForm.WarnText>{validationMsg.name}</StyledForm.WarnText>
          )}
        </Styled.NameContainer>

        <StyledForm.InputContainer>
          <StyledForm.Label htmlFor="countryIndex">
            {t("nationality.label")}
          </StyledForm.Label>
          <StyledForm.Select
            onChange={selectHandleChange}
            defaultValue={formData.countryIndex}
            options={reformatNationList}
            components={animatedComponents}
            isLoading={nationalityResponse.loading}
            name="countryIndex"
            inputId="countryIndex"
            placeholder={t("nationality.placeholder")}
            required
          />
        </StyledForm.InputContainer>

        <StyledForm.InputContainer>
          <Styled.BlueButton>{t("submit")}</Styled.BlueButton>
        </StyledForm.InputContainer>

        <Styled.Bottom.Login>
          {t("guidance2")}{" "}
          <Link to="#none" onClick={openLoginModal}>
            {t("guidance2Link")}
          </Link>
        </Styled.Bottom.Login>

        <Styled.Bottom.Terms>
          <Trans
            t={t}
            i18nKey="guidance3"
            components={{
              link1: <Link to="/privacy" target="_blank" />,
              link2: <Link to="/terms" target="_blank" />,
            }}
          />
        </Styled.Bottom.Terms>
      </StyledForm.FormWrapper>
    </>
  );
}

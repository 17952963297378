import { asyncApi } from "common/util/asyncApi";

const url = "/api/v1/payments";

const settingApi = {
  postSubmitPaymethod: async (data, token) => {
    let response = await asyncApi({
      url: `${url}/card`,
      method: "post",
      data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
};

export default settingApi;

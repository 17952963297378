import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';
import ContentsWrapper from 'component/atoms/ContentsWrapper';

import SeoHelmet from 'component/Helmet';
import MyCurriculumContents from './components/MyCurriculumContents';
import MyCurriculumCourses from './components/MyCurriculumCourses';
import LearningHistory from './components/LearningHistory';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import curriculumApi from 'api/curriculum';
import contentsApi from 'api/contents';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



const MyCurriculum = () => {

  const token = useSelector((state) => state.user.token);
  const history = useHistory();

  const [fetchMyCurriculum] = useAxios(
    () => curriculumApi.getMyCurriculum(token),
    []
  );

  const { data: myCurriculumData } = fetchMyCurriculum;

  const [fetchMyHistory] = useAxios(
    () => contentsApi.getHistoryList(token),
    []
  );

  const { data: myHistoryData } = fetchMyHistory;

  return (
    <>
      <SeoHelmet
        title={"My Curriculum"}
      />
      <ContentsWrapper>
        <MyCurriculumContents data={myCurriculumData} />
        <MyCurriculumCourses data={myCurriculumData} />
        <LearningHistory data={myHistoryData} />

        <Button onClick={()=>{ history.push('/learningHistory')}}>See All Learning History</Button>
      </ContentsWrapper>
    </>
  );
};

export default MyCurriculum;


const Button = styled.button`
  font-size:1.6rem;
  color:#ffffff;
  background:#485cc7;
  border-radius: 5px;;
  width:34rem;
  height:5.4rem;
  display: flex;
  align-items: center;
  justify-content: center;;
  margin:5rem auto;

  @media (max-width: 1024px) {
    margin:25px auto;
    width:90%;
    font-size:15px;
    height:50px;
   }


`;
import React from 'react';
import SwiperCore, { Navigation } from 'swiper';

import Styled from './styled/slider.style';

SwiperCore.use([Navigation]);
const swiperBreakPoint = {
  1025: {
    slidesPerView: 4,
    spaceBetween: 40,
  },
  850: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  768: {
    slidesPerView: 3.05,
    spaceBetween: 20,
  },
  600: {
    slidesPerView: 3.1,
    spaceBetween: 10,
  },
  0: {
    slidesPerView: 2.05,
    spaceBetween: 10,
  },
};

const SliderContainer = ({ children, length, loop = true }) => {
  if (length <= 4) {
    return (
      <Styled.Container>
        <Styled.Swiper breakpoints={swiperBreakPoint} slidesPerView={4}>
          {children}
        </Styled.Swiper>
      </Styled.Container>
    );
  }
  return (
    <Styled.Container>
      <Styled.Swiper
        breakpoints={swiperBreakPoint}
        slidesPerView={4}
        navigation
        loop={loop}
      >
        {children}
      </Styled.Swiper>
    </Styled.Container>
  );
};

export default SliderContainer;

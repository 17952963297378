import React from 'react';
import Styled from './index.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import ArrowLink from 'component/atoms/ArrowLink';
import { Trans } from 'react-i18next';

export default function BannerCourses({ t }) {
  return (
    <Styled.Wrapper>
      <ContentsWrapper>
        <Styled.H2>
          <Trans t={t}>bannerCourses.title</Trans>
        </Styled.H2>
        <Styled.Desc>
          <p>{t('bannerCourses.desc1')}</p>
          <p>{t('bannerCourses.desc2')}</p>
        </Styled.Desc>
        <ArrowLink primary='true' to='/courses'>
        {t('bannerCourses.button')}
        </ArrowLink>
      </ContentsWrapper>
    </Styled.Wrapper>
  );
}

import styled from 'styled-components';
import TableLayout from 'common/styled/tableLayout.style';
import detailIcon from 'assets/images/ico_detail.png';
import { Link } from 'react-router-dom';

const StylePaymentListSection = {
  Container: styled.div`
    margin-bottom: 15em;
    padding: 4em 3em 4.5em 3em;
    border-radius: 0.6em;
    background-color: #1e2024;
  `,
  Title: styled.h3`
    margin-bottom: 2em;
    color: #8b8f99;
    font-size: 2em;
    font-weight: 700;
  `,
  List: styled.div`
    ${TableLayout}
    margin-bottom: 2.5em;
    padding-left: 1em;
    padding-bottom: 2.5em;
    border-bottom: 1px solid #2b2e35;
    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
    .title {
      min-width: 10em;
      font-size: 1.8em;
      > span {
        display: inline-block;
        color: #8b8f99;
        font-size: 0.78em;
      }
    }
    .creditcard,
    .date {
      width: 21.7%;
      padding: 0 1em;
      color: #c9cbd1;
      font-size: 1.6em;
      font-weight: 300;
    }
    .status {
      width: 18%;
      padding: 0 1em;
      color: #c9cbd1;
      font-size: 1.6em;
      font-weight: 300;
    }
    .detail {
      width: 10%;
      text-decoration: underline;
    }
    @media (max-width: 767px) {
      .title {
        width: 5.5em;
      }
      .creditcard {
        display: none;
      }
      .date {
        width: auto;
        text-align: center;
      }
      .status {
        text-align: center;
        width: 7em;
      }
      .detail {
        width: 5em;
      }
    }
  `,
  Detail: styled(Link)`
    position: relative;
    width: 7%;
    padding-left: 2em;
    color: #5c5f66;
    font-size: 1.4em;
    font-weight: 400;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      width: 1.125em;
      height: 1.125em;
      background: url(${detailIcon}) no-repeat center center/cover;
      transform: translateY(-50%);
    }
  `,
};

export default StylePaymentListSection;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languageCode } from 'common/constant';

import { termsOfPrivacyKo0915 } from 'container/TermsPage/contents/2023/termsOfPrivacyKo0915';
import { termsOfPrivacyEn0915 } from 'container/TermsPage/contents/2023/termsOfPrivacyEn0915';
import { termsOfPrivacyZh0915 } from 'container/TermsPage/contents/2023/termsOfPrivacyZh0915';
import { termsOfPrivacyJa0915 } from 'container/TermsPage/contents/2023/termsOfPrivacyJa0915';

const Privacy20230915 = () => {
  const { i18n } = useTranslation('privacyPage');
  const { KOREAN, CHINESE, JAPANESE } = languageCode;
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!i18n?.language) return;

    switch (i18n.language) {
      case KOREAN.locale:
        setContent(termsOfPrivacyKo0915);
        break;
      case CHINESE.locale:
        setContent(termsOfPrivacyZh0915);
        break;
      case JAPANESE.locale:
        setContent(termsOfPrivacyJa0915);
        break;
      default:
        setContent(termsOfPrivacyEn0915);
        break;
    }
  }, [i18n.language]);

  return <>{content}</>;
};

export default Privacy20230915;
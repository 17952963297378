import React, { useEffect, useState } from 'react';
import PageSubTitle from 'component/molecules/PageSubTitle';
import { useAxios } from 'common/hook/useAxiosHook';
import bookmarksApi from 'api/bookmarks';
import InfiniteList from 'component/molecules/InfiniteList';
import Error from 'component/Error';
import VideoItem from 'component/molecules/VideoItem';
import { useSelector } from 'react-redux';
import Loading from 'component/atoms/Loading';
import { masterTopikUrl } from 'common/constant';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import emptyImg from 'assets/images/ico_none_bookmark.png';

const EmptyList = styled.div`
  font-size: 3.4em;
  font-weight: 500;
  padding: 1em 0;
  text-align: center;
  &:before {
    content: '';
    display: block;
    width: 6rem;
    height: 6.6rem;
    margin: 0 auto 1em;
    background: url(${emptyImg}) no-repeat center / contain;
  }
`;

export default function Bookmark() {
  const { t } = useTranslation('bookmarkPage');
  const token = useSelector((state) => state.user.token);
  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [bookmarkList, fetchBookmarkList] = useAxios(
    bookmarksApi.getListOfUser,
    [],
    true
  );
  const { loading, data, error } = bookmarkList;

  // 최초 1회 호출
  useEffect(() => fetchBookmarkList({ page: 0, token }), []);

  useEffect(() => {
    if (data !== null) {
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [bookmarkList]);

  const onNext = () => {
    fetchBookmarkList({ page: data.number + 1, token });
  };

  if (error) return <Error error={error} />;

  return (
    <>
      <PageSubTitle title={t('bookmarkTab.title')} />
      {loading && slideItem.length === 0 && <Loading />}

      {!loading && slideItem.length === 0 && (
        <EmptyList>북마크 한 강좌가 없습니다.</EmptyList>
      )}

      <InfiniteList
        dataLength={slideItem.length}
        next={onNext}
        hasMore={hasMore}
      >
        {slideItem.map((item, index) => (
          <VideoItem
            key={index}
            to={`/courses/detail/${item.productId}`}
            imgSrc={masterTopikUrl + item.thumbnailPath + item.thumbnailTitle}
            title={item.courseTitle}
            desc={item.chapterLectureTitle}
            percentage={item.chapterLectureProgressPercent}
            isNew={item.isNew}
            videoLink={`/courses/detail/${item.productId}/${item.chapterLectureId}`}
          />
        ))}
      </InfiniteList>
    </>
  );
}

import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';

const LnbStyle = {
  NavWrap: styled.nav.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <IoIosArrowDown />
      </>
    ),
  }))`
    position: relative;
    svg {
      display: none;
    }
    li {
      button {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 1em 1.6em;
        border-radius: 0.6em;
        overflow: hidden;
        text-align: left;
        font-size: 1.8em;
        color: #bfc0c1;
        strong {
          font-weight: 400;
        }
        span {
          position: absolute;
          right: 1.6em;
        }
      }
      &.on button {
        background-color: #fff;
        color: #0f1012;
        font-weight: 700;
        strong {font-weight: 700;}
      }
    }
    .document {
      li {
        border-bottom: 1px solid #27282a;
        &.on {border-bottom: none;}
        button {
          padding: 1.6em 1em;
        }
      }
    }

    @media (max-width: 1024px){
      --btnSize: 2em;
      height: calc(var(--btnSize) * 3);
      svg {
        display: block;
        position: absolute;
        top: 50%;
        right: 1em;
        font-size: 2.4em;
        color: #838587;
        transform: translateY(-50%);
        transition: transform 0.2s ease;
        z-index: 11;
      }
      .nav {
        position: absolute;
        width: 100%;
        z-index: 10;
        border-radius: 0.6em;
        overflow: hidden;
      }
      li {
        button {
          padding: 0.7em 2em !important;
          font-size: var(--btnSize);
          color: #bfc0c1;
          span {
            position: static;
            margin-left: 1em;
          }
        }
        &.on button {
          font-weight: 400;
          color: #bfc0c1;
          strong {
            font-weight: 400;
          }
        }
      }
      .document {
        max-height: 40vh;
        overflow-y: auto;
      }
      .document li {
        border: none;
      }
      &.hide-menu {
        overflow: hidden;
        border-radius: 0.6em;
        border: 1px solid #5c5f66;
        .nav {
          background-color: transparent;
        }
        li {
          button {
            background-color: transparent !important;
          }
        }
      }
      &.show-menu {
        overflow: visible;
        .nav {
          padding-bottom: 2em;
          background-color: #33363d;
        }
        li {
          button {
            border-radius: 0;
          }
          &.on button {
            background-color: #2b2e35;
          }
        }
      }
    }
  `,

  Info: styled.p`
    display: none;
    position: absolute;
    top: -1em;
    transform: translateY(-100%);
    font-weight: 700;
    font-size: 1.4em;
    color: #d1d1d1;
    &.title {
      display: block;
    }
    @media (max-width: 1024px){
      --btnSize: 2em;
      display: block;
      position: static;
      top: 0;
      transform: translateY(0);
      padding: 1em 2em;
      line-height: 1;
      font-size: var(--btnSize);
      color: #bfc0c1;
      border-bottom: 1px solid #484a51;
      span {
        margin-left: 1em;
      }
    }
  `,

  ButtonMore: styled.button`
    margin-top: 1.8em;
    padding-bottom: 0.2em;
    border-bottom: 1px solid #f3a704;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4em;
    color: #f3a704;
    @media (max-width: 1024px){
      margin-top: 1em;
      margin-left: 2.5em;
      font-size: 1.6em;
    }
  `,

  Title: styled.p`
    &.recom::before {
      content: '★ ';
    }
  `,
};

export default LnbStyle;

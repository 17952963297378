import React from "react";
import Styled from "./index.style";
import useLoginStatus from "common/hook/useLoginStatus";
import bannerApi from "api/banner";
import { useAxios } from "common/hook/useAxiosHook";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";

const FooterBanner = () => {
  const isLogin = useLoginStatus();
  const { handleModal } = React.useContext(ModalContext);

  const [{ data }] = useAxios(() => bannerApi.getBanner("LOGIN"), []);

  const handleClick = (e) => {
    e.preventDefault();
    const { isLoginOpen, linkUrl } = data;
    if (
      isLoginOpen &&
      (linkUrl === "" || linkUrl === null || linkUrl === undefined)
    ) {
      handleModal(<Login />, false, 420);
    } else {
      if (
        !isLoginOpen &&
        linkUrl &&
        (linkUrl.includes("http") || linkUrl.includes("https"))
      ) {
        window.open(`${linkUrl}`);
      } else if (
        !isLoginOpen &&
        linkUrl &&
        (!linkUrl.includes("http") || !linkUrl.includes("https"))
      ) {
        window.open(`http://${linkUrl}`);
      } else {
        handleModal(<Login />, false, 420);
      }
    }
  };

  if (isLogin) return null;

  if (data)
    return (
      <Styled.Wrapper className="footer-banner">
        <Styled.Container>
          <Styled.Text>
            {data.content &&
              data.content.split("<br>").map((line, index) => {
                return (
                  <React.Fragment key={index}>
                    {line}
                    {index === 0 ? <br /> : null}
                  </React.Fragment>
                );
              })}
          </Styled.Text>
          <Styled.Btn onClick={handleClick}>{data.buttonName}</Styled.Btn>
        </Styled.Container>
      </Styled.Wrapper>
    );

  return null;
};

export default FooterBanner;

import React from 'react';
import PropTypes from 'prop-types';
import StyledModal from 'component/Modal/styled/Modal.style';
import StyledModalForm from 'component/form/ModalForm.style';
import { ModalContext } from 'component/Modal/modalContext';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

const Text = styled.p`
  font-size: 1.8em;
  margin: -1em 0 2em;
  font-weight: 300;
  text-align: center;
`;

const CloseButton = styled(StyledModalForm.Button)`
  display: block;
  max-width: 15em;
  margin: auto;
  border: 1px solid #d1d1d1;
  ${(props) => {
    if(props.$btnType){
      return css`
        background-color: #475cc7;
        color: #fff;
      `
    }
    else {
      return css`
        background-color: #fff;
        color: #999;
      `
    }
  }}
`;

/**
 *
 * @param {*} param
 * @param {string} param.title
 * @param {string=} param.desc
 * @param {string=} param.btnType
 */
const DefaultTextModal = ({ title, desc, btnType }) => {
  let { handleModal } = React.useContext(ModalContext);
  const { t } = useTranslation();

  return (
    <>
      <StyledModal.H2>{title}</StyledModal.H2>
      {desc && <Text>{desc}</Text>}
      <StyledModalForm.DivWrapper>
        <CloseButton onClick={() => handleModal()} $btnType={btnType}>{t('close')}</CloseButton>
      </StyledModalForm.DivWrapper>
    </>
  );
};

DefaultTextModal.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  btnType: PropTypes.string,
};

export default DefaultTextModal;

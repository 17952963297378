import section1_logo from 'assets/images/event/241028/section1_logo.png'
import section1_title from 'assets/images/event/241028/section1_title.png'
import section1_bg2 from 'assets/images/event/241028/section1_bg2.png'
import React from "react";

const Section1 = () => {
    return (
        <section className='section1'>
            <div className='sectionWarp'>
                <div className='imgBox'>
                    <img src={section1_logo} alt='section1_logo'/>
                </div>
                <div className='bgc1'>
                    <h1>Korean Video Contest</h1>
                </div>
                <div className='imgBox'>
                    <img src={section1_title} alt='section1_title'/>
                </div>
                <div className='imgBox videoContainer'>
                    <div className='videoBox'>
                        <iframe width="100%" height="100%"
                                src="https://www.youtube.com/embed/2axwXjE6MLE?si=BPp2ToLaBKUi2kMS"
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                    </div>
                    <img src={section1_bg2} alt='section1_bg2'/>
                </div>
            </div>
        </section>
    )
}

export default Section1
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

/**
 *
 * @param {*} param
 * @param {error} param.error
 */
export default function useErrorToastify(error) {
  useEffect(
    () =>
      error &&
      toast.error(
        error.data.message ? error.data.message : 'Something went wrong'
      ),
    [error]
  );
}

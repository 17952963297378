import styled from 'styled-components';

const ClassDetailStyle = {
  Container: styled.div`
    margin: 0 0 3em;
    color: #0f1012;
  `,

  H3: styled.h3`
    padding: 0 2em 2em;
    border-bottom: 1px solid #e8e8e8;
    text-align: center;
    @media (max-width: 1024px){
      border-bottom: none;
    }
  `,

  TeacherName: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.1;
    color: #000;
    .label {
      margin-right: 1em;
      padding: 0.5em 0.8em;
      background: #ff0069;
      border-radius: 0.2em;
      text-transform: uppercase;
      font-size: 1.4em;
      color: #fff;
    }
    .name {
      position: relative;
      font-weight: 700;
      font-size: 2.2em;
      & + .name {
        margin-left: 0.5em;
        padding-left: 0.5em;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0.1em;
          left: 0;
          width: 2px;
          height: 1em;
          background: #ebedf2;
        }
      }
    }
    @media (max-width: 1024px){
      position: relative;
      padding: 6em 0 0;
      .label {
        position: absolute;
        top: 1em;
        left: 50%;
        transform: translateX(-50%);
      }
      .name {
        font-size: 3em;
      }
    }
  `,
  ContentWrap: styled.div`
    /* max-height: calc(100vh - 50em); */
    overflow-y: auto;
    font-weight: 400;
  `,

  Steps: styled.div`
    margin: 2.6em 0 1.1em;
    font-size: 1.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    > div {
      position: relative;
      font-weight: 700;
      color: #5c5f66;
       span {
        display: inline-block;
        width: 2.2em;
        height: 2.2em;
        line-height: 2.2em;
        margin-right: 1em;
        text-align: center;
        border-radius: 50%;
        background: #5c5f66;
        color: #9a9ba0;
      }
      p {display: inline-block;}
      &.active {
        color: #485cc7;
        span {
          background: #485cc7;
          color: #fff;
        }
      }
      &:first-child ~ div {
        --width : 5em;
        --padding : 1.125em;
        padding-left: var(--width);
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: var(--padding);
          top: 50%;
          width: calc(var(--width) - var(--padding)*2);
          height: 0;
          border-top: 1px solid #2b2e35;
        }
      }
    }
    @media (max-width: 1024px){
      > div {
        position: relative;
        font-weight: 700;
        color: #5c5f66;
        p {
          display: block;
          margin-top: 1em;
        }
      }
    }
  `,
  StepCont: styled.div`
    min-height: 50em;
    max-height: calc(100vh - 50em);
    padding: 2em 0;
    overflow-y: auto;
    @media (max-width: 1024px){
      min-height: auto;
    }
  `,

  Info: styled.div`
    font-size: 1.6em;
    .info {
      margin-bottom: 1.875em;
      padding: 0 1.875em;
      .stitle {
        font-weight: 700;
        color: #485cc7;
      }
      .teachingTime{
        word-break: keep-all;
        white-space: pre-wrap;
      }
    }
    .form {
      padding: 1.875em;
      border-radius: 0.4em;
      background: #f7f8fd;
      .tit {
        margin: 1.875em 0 0.2em;
        font-weight: 700;
        &:first-of-type {
          margin-top: 0;
        }
      }
      p {
        margin-bottom: 0.2em;
        font-size: 0.875em;
        color: #5c5f66;
      }
      input, textarea {
        width: 100%;
        padding: 1em 1.25em;
        border: none;
        border-radius: 0.4em;
        background: #ebedf2;
        color: #666666;
      }
      input {
        line-height: 1em;
      }
      textarea {
        line-height: 1.4em;
      }
    }
    @media (max-width: 1024px){
      .info {
        padding-left: 0;
        padding-right: 0;
      }
    }
  `,

  ButtonWrap: styled.div`
    padding-top: 1em;
    border-top: 1px solid #e8e8e8;
    line-height: 3.3em;
    font-size: 1.6em;
    display: flex;
    .txt {
      flex-basis: calc(100% - 10em);
      overflow: hidden;
    }
    .btnWrap {
      flex-basis: 10em;
      button {
        display: none;
        width: 100%;
        height: 3.5em;
        background: #485cc7;
        border-radius: 0.4em;
        font-weight: 700;
        font-size: 1em;
        color: #fff;
        &:disabled{
          background: #b0b1b2;
        }
        &.on{
          display: block;
        }
      }
    }
    .product {
      float: left;
      font-size: 1.25em;
      color: #c9cbd1;
      strong {
        font-weight: 700;
        color: #485cc7;
      }
    }
    .price {
      float: right;
      margin-right: 1em;
      font-weight: 700;
      font-size: 1.25em;
      color: #1e2024;

    }
    @media (max-width: 1024px){
      display: block;
      padding-top: 2em;
      line-height: 1.2em;
      .txt {
        .product {
          float: none;
        }
        .price {
          float: none;
          margin-top: 1em;
          text-align: right;
        }
      }
      .btnWrap {
        margin-top: 2em;
        padding-top: 2em;
        border-top: 1px solid #5c5f66;
      }
    }
  `,
};

export default ClassDetailStyle;

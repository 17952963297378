import styled from 'styled-components';
import { Link } from 'react-router-dom';
import imgUserNav from 'assets/images/gnb_new/mypage_arrow.png';
const StyledUserNav = {
  Container: styled.div`
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 2.5em 0 0 1.8em;
    &.active {
      &:before {
        content: '';
        position: absolute;
        top: 44px;
        right: 10px;
        width: 200px;
        height: 25px;
        background: url(${imgUserNav}) no-repeat right center;
      }
      .user-nav-list {
        height: auto;
        max-height: 80vh;
        overflow-y:auto;
        opacity: 1;
        transition: all 0.3s ease;
        padding:2.5rem 0;
      }
    }
    @media (max-width: 1024px) {
      display: none;
    }
  `,
  Button: styled.button.attrs({
    type: 'button',
    className: 'nav-button',
    children: <span></span>,
  })`
    position: relative;
    display: inline-block;
    width: 4em;
    height: 4em;
    background-color: #fff;
    border-radius: 100px;
    span:before,
    span:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1em;
      width: 2em;
      height: 2px;
      border-radius: 10px;
      background-color: #000;
      transition: all 0.6s ease;
    }
    span:before {
      margin-top: calc(-0.4em - 1px);
    }
    span:after {
      margin-top: calc(0.4em - 1px);
    }
  `,
  List: styled.div.attrs({ className: 'user-nav-list' })`
    position: absolute;
    top: 6.5em;
    right: 0;
    width: 280px;
    height: 0;

    border-radius: 0.6em;
    background-color: #fff;
    box-shadow: 5px 5px 10px rgba(15, 16, 18, 0.3);
    overflow: hidden;
    opacity: 0;
    transition: height 0.3s ease, opacity 0.3s ease 0.1s;
  `,
  MyList:styled.div`
    width:100%;
    > span{

        position:relative;
        > a, .userNavDepth{
          width:calc(100% - 6rem);
          margin:0 auto;
          border-bottom:1px solid #f2f3f3;


        }

        a,.userNavDepth{
          position:relative;
          svg{
            position: absolute;
            right:2rem;
            top:50%;
            transform: translateY(-50%) rotate(-180deg);

          }
        }
    }

    .userNavDepth{
      display: block;
      padding: 0 10px 0 0;
      font-size: 16px;
      font-weight: 600;
      color: #1e2024;
      line-height: 40px;
      height:40px;
      transition: all 0.1s ease;
      cursor: pointer;

      &.active{
        svg{
          transform: translateY(-50%) rotate(0deg);
        }
      }
      &:hover {
        color: #485cc7;
        svg path{fill:#485cc7}
      }
   }
`,
  Link: styled(Link)`
    display: block;
    padding: 0 10px 0 0px;
    font-size: 16px;
    font-weight: 600;
    color: #1e2024;
    line-height: 40px;
    height:40px;
    transition: all 0.1s ease;
    &:hover {
      color: #485cc7;
    }
    &.logout{
      color: #777777;
      border-bottom:none;
    }
  `,
  Item: styled.span`
    display: block;

    .submenu{
      padding:10px 0;
      background: #f6f6f6;
      display: none;
      a{
        font-size: 1.4rem;
        color: #777777;
        height:25px;
        line-height:25px;
        padding:0 calc(3rem);
        &:hover{
          background: #eaeaeb;;
        }
      }

      &.active{
        display: block;

      }
    }


  `,
};

export default StyledUserNav;

import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';

const FaqStyled = {
  Item: styled.div`
    padding: 3em;
    margin-bottom: 1em;
    border-radius: 0.6rem;
    background-color: #1e2024;
    &.active {
      > h4 {
        margin-bottom: 1.5em;
      }
      > div {
        max-height: ${(props) =>
          props.activeHeight ? props.activeHeight : 500}px;
        margin-top: 1.5em;
      }
      .arrow {
        transform: rotate(180deg);
      }
    }
  `,
  H4: styled.h4`
    position: relative;
    font-size: 2em;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.4s ease;
    @media (max-width: 767px) {
      font-size: 2.6em;
      padding-right: 1.5em;
    }
  `,
  Desc: styled.div`
    font-size: 1.6em;
    font-weight: 200;
    line-height: 1.8;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    @media (max-width: 767px) {
      font-size: 2.3em;
    }
  `,
  Arrow: styled(IoIosArrowDown).attrs({
    className: 'arrow',
  })`
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 2rem;
    margin-top: -1rem;
    transition: 0.4s ease;
    @media (max-width: 767px) {
      font-size: 3rem;
      margin-top: -1.5rem;
    }
  `,
};

export default FaqStyled;

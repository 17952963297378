import { useState, useEffect } from 'react';
import './index.css';
import Section1 from 'component/Event/241028/Section1';
import Section2 from 'component/Event/241028/Section2';
import Section3 from 'component/Event/241028/Section3';
import Section3_1 from 'component/Event/241028/Section3-1';
import Section4 from 'component/Event/241028/Section4';
import Section5 from 'component/Event/241028/Section5';
import Section6 from 'component/Event/241028/Section6';
import Section1_ch from 'component/Event/241028/chinese/Section1';
import Section2_ch from 'component/Event/241028/chinese/Section2';
import Section3_ch from 'component/Event/241028/chinese/Section3';
import Section3_1_ch from 'component/Event/241028/chinese/Section3-1';
import Section4_ch from 'component/Event/241028/chinese/Section4';
import Section5_ch from 'component/Event/241028/chinese/Section5';
import Section6_ch from 'component/Event/241028/chinese/Section6';
import Banner from 'component/Event/241028/Banner';
import {useSelector} from "react-redux";
import useLoginStatus from "../../../common/hook/useLoginStatus";

const EventPage = () => {

  // 언어 선택(현재 웹사이트에 맞게 적용 필요)
  //const [language , setLanguage] = useState('english')

  const isLogin = useLoginStatus();

  const user = useSelector((state) => state.user.data);
  const [language , setLanguage] = useState('EN')


  useEffect(() => {
    if(isLogin)
      setLanguage(user.selectLanguage);

    // 아래 코드 주석 처리 해제 시 배너 페이지
     //setLanguage('Banner')
  }, [])

  return (
      <div className="App">
        {language === 'Banner' ?
            <Banner/> :
            language === 'CN' ?
                <main className='chinese'>
                  <Section1_ch/>
                  <Section2_ch/>
                  <Section3_ch/>
                  <Section3_1_ch/>
                  <Section4_ch/>
                  <Section5_ch/>
                  <Section6_ch/>
                </main> :
                <main className='english'>
                  <Section1/>
                  <Section2/>
                  <Section3/>
                  <Section3_1/>
                  <Section4/>
                  <Section5/>
                  <Section6/>
                </main>
        }
      </div>
  );
}

export default EventPage;

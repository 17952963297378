import React, { useEffect } from 'react';
import Style from './styled/index.style';

import ContentsWrapper from 'component/atoms/ContentsWrapper';

import Banner from 'container/AudioPage/Banner'
import BannerEvent from 'container/AudioPage/BannerEvent'

import BookSection from 'container/AudioDetailPage/BookSection';
import AudioSection from 'container/AudioDetailPage/AudioSection';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAxios } from 'common/hook/useAxiosHook';
import BookStoreApi from 'api/bookstore';

const AudioPage = () => {

  const language = localStorage.getItem('i18nextLng');
  const { bookLevel } = useParams();

  const [{ data: bookInfo }, fetchBookInfo] = useAxios(
    BookStoreApi.getBookSoundLevelTrack,
    [],
    true
  );

  const [{ data: audioChapters }, fetchAudioChapters] = useAxios(
    BookStoreApi.getBookSoundLevelChapterGroup,
    [],
    true
  );

  useEffect(() => {
    fetchBookInfo(bookLevel);
    fetchAudioChapters(bookLevel);
  }, [bookLevel])

  // const bookInfo = {
  //   title : "Visang Korean Beginner 1",
  //   thumbnailUrl : "https://www.mastertopik.com/Fileup/Book/비상 한국어 초급1 표지.png",
  //   description : "This textbook helps beginners improve Korean language skills through vocabulary, grammar, speaking practice, activities, and evaluation. It helps for improving Korean speaking and listening skills.",
  //   attachment : [
  //     {
  //       fileName : "Consonant vowel",
  //       filePath : "https://dlvisang01.mastertopik.com/MasterTOPIK/visangkorean/Consonant_vowel.zip"
  //     },
  //     {
  //       fileName : "Beginner 1",
  //       filePath : "https://dlvisang01.mastertopik.com/MasterTOPIK/visangkorean/beginner1.zip"
  //     }
  //   ],
  //   audioChapters : [
  //     {
  //       chapterName : "0. 한글 자음과 모음",
  //       audioList : [
  //         {
  //           audioName : "0-1",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/0-1.mp3"
  //         },
  //         {
  //           audioName : "0-2",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/0-2.mp3"
  //         },
  //         {
  //           audioName : "0-3",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/0-3.mp3"
  //         }
  //       ]
  //     },
  //     {
  //       chapterName : "1. 저는 학생이에요",
  //       audioList : [
  //         {
  //           audioName : "1-1",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/1-1.mp3"
  //         },
  //         {
  //           audioName : "1-2",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/1-2.mp3"
  //         },
  //         {
  //           audioName : "1-3",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/1-3.mp3"
  //         },
  //         {
  //           audioName : "1-4",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/1-4.mp3"
  //         },
  //         {
  //           audioName : "1-5",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/1-5.mp3"
  //         }
  //       ]
  //     },
  //     {
  //       chapterName : "2. 그 사람은 제 동생이에요",
  //       audioList : [
  //         {
  //           audioName : "2-1",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/2-1.mp3"
  //         },
  //         {
  //           audioName : "2-2",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/2-2.mp3"
  //         },
  //         {
  //           audioName : "2-3",
  //           audioPath : "https://d13owoaeiymgg6.cloudfront.net/mp3/VisangKorean/Beginner1/2-3.mp3"
  //         }
  //       ]
  //     }
  //   ]
  // }

  return (
    <>
      {bookInfo &&
        <ContentsWrapper>
          <Style.DetailTitle>
            <h2>{(language === 'ko-KR') ? bookInfo.bookSoundTrack.productTitleKO : bookInfo.bookSoundTrack.productTitleEN}</h2>
          </Style.DetailTitle>
        </ContentsWrapper>
      }

      <BookSection bookInfo={bookInfo} />

      <ContentsWrapper>
        <AudioSection audioInfo={audioChapters} />

        <Banner />
        <BannerEvent />
      </ContentsWrapper>
    </>
  );
};
export default AudioPage;
import React, { useState } from 'react';
import Styled from 'container/LandingPage/FaqList/index.style';
import { MemoizedFaqItem } from './FaqItem';
import ArrowLink from 'component/atoms/ArrowLink';

export default function Faqs({ faqListState, t }) {
  const language = localStorage.getItem('i18nextLng');
  const [selectedFaq, setSelectedFaq] = useState(0);
  // const faqList = faqListState.data && faqListState.data;
  const faqListKo = [
    {
      title: '무료 체험은 누구나 신청할 수 있으며, 이용 기간이 어떻게 되나요?',
      bodyAsHtml:
        '무료체험을 신청한 이력이 없다면 누구나 무료체험이 가능합니다. 지금 가입하시고, 마스터토픽의 모든 콘텐츠 2주간 무료로 즐겨보세요.',
    },
    {
      title: '무료체험 기간에는 모든 콘텐츠를 이용할 수 있나요?',
      bodyAsHtml:
        '물론입니다. 1,000개 이상의 강의, 학습자료, 실전 연습 자료(TOPIK모의고사, AI SPEAK) 모두 이용할 수 있습니다.',
    },
    {
      title: '결제 정보를 입력해야 무료체험이 가능한가요?',
      bodyAsHtml:
        '네, 인증 절차이므로, 결제 정보 입력은 필수입니다. 무료체험 기간(14일) 중 해지하면 결제되지 않으니 안심하세요.',
    },
    {
      title: '멤버쉽을 정기 구독하고 싶어요. 어떻게 신청하면 돼요?',
      bodyAsHtml:
        '무료체험 기간이 종료되면 자동으로 유료 결제(정기구독)가 시작됩니다. 따라서 별도의 신청 절차는 없습니다.',
    },
    {
      title: '무료체험 해지는 어떻게 하나요?',
      bodyAsHtml:
        '무료체험 해지를 원하시면 [우측 상단 二 버튼> 설정> 멤버쉽 취소]를 클릭하시면 됩니다.',
    },
    {
      title: '멤버쉽 결제됐는데, 이용하지는 않았어요. 환불이 가능할까요?',
      bodyAsHtml:
        '환불 문의는 주셨군요. 환불은 결제일에 따라 달라질 수 있으므로 우측 하단 <span>클릭하여</span> 환불 문의를 주세요.<br>확인후 마스터토픽 팀에서 안내드리겠습니다.<br />** 전액 환불: 결제 후 7일 이내, 사용하지 않았을 경우 결제한 구독권 전액 환불 가능합니다.<br>** 부분 환불: 결제 후 1개월 이내, 구독권 이용 금액(수수료 포함)을 제외하고 환불해 드립니다',
    },
  ];
  const faqListEn = [
    {
      title:
        'Can anyone register for a free trial? How long will I able to use it?',
      bodyAsHtml:
        'Anyone who has not applied for a free trial is eligible for a free trial. Sign up now and enjoy 2 weeks of all contents in the masterTOPIK for free.​',
    },
    {
      title: 'Can I use all contents during the free trial period?​',
      bodyAsHtml:
        'Yes. More than 1,000 lectures, learning materials, and practice materials (TOPIK mock test, AI SPEAK) are all available.​',
    },
    {
      title: 'Do I need to enter payment information to use the free trial?',
      bodyAsHtml:
        'Yes, payment information is required as part of the authentication process. Please be assured that no payment will be made even if you cancel during the free trial period (14 days).​',
    },
    {
      title:
        'I would like to subscribe to a regular membership. How can I register?​',
      bodyAsHtml:
        'When the free trial period ends, the paid subscription will start automatically, so there is no separate application process required​',
    },
    {
      title: 'How can I cancel my free trial?​',
      bodyAsHtml:
        'Please click [二 button located at the top right corner > Settings > Cancel Membership] to cancel your free trial.​',
    },
    {
      title:
        "I paid for the membership, but I didn't use it. Can I get a refund?​",
      bodyAsHtml:
        "Refunds may vary depending on the payment date, so please click <span>to</span> request a refund.<br>masterTOPIK team will guide you after confirmation.​<br />**Full refund: If you haven't used the subscription that you paid for within 7 days of payment, you can receive a full refund for the purchased subscription<br>**Partial refund: Within one month of payment, we will refund the amount of the subscription that has not been used (including fees) minus the amount used.",
    },
  ];
  let faqList;

  language === 'ko-KR' ? (faqList = faqListKo) : (faqList = faqListEn);
  return (
    <Styled.Wrapper>
      <Styled.H2>{t('faqs.title')}</Styled.H2>
      {faqList.map((item, index) => (
        <MemoizedFaqItem
          key={index}
          item={item}
          onClick={() => setSelectedFaq(selectedFaq === index ? 0 : index)}
          classActive={selectedFaq === index && 'active'}
        />
      ))}
      {/* 2021-01-08 faqs 소그룹 리스트 경우에 대한 마크업
       {faqList && Object.keys(faqList).map((partId) => (
        <>
          <Styled.H3>{partId}</Styled.H3>
          {faqList[partId].map((item) => (
            <MemoizedFaqItem
              key={item.index}
              item={item}
              onClick={() =>
                setSelectedFaq(selectedFaq === item.index ? 0 : item.index)
              }
              classActive={selectedFaq === item.index && 'active'}
            />
          ))}
        </>
      ))} */}
      {/* Master K 관련 내용은 4월초 오픈 예정이라 우선 미노출 처리
      <Styled.ArrowLinkBlock>
        <ArrowLink to='#none'>
          Try Visang <Styled.Tutoring /> <b>Service (Master K)</b>
        </ArrowLink>
        <p>{t('faqs.tutoring')}</p>
      </Styled.ArrowLinkBlock> */}
    </Styled.Wrapper>
  );
}

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SwiperSlide } from 'swiper/react';
import Error from 'component/Error';
import SwiperList from 'component/molecules/SwiperList';
import VideoItem from 'component/molecules/VideoItem';
import { EmptyList } from 'component/atoms/EmptyList';
import StyledUserHomePage from 'container/UserHomePage/index.style';
import { masterTopikUrl } from 'common/constant';
import { useTranslation } from 'react-i18next';

export default function WatchedList({ initialState }) {
  const { t } = useTranslation('userHomePage');
  const token = useSelector((state) => state.user.token);
  const [watchedList, fetchWatchList] = initialState;
  const [slideItems, setSlideItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { loading, data, error } = watchedList;

  useEffect(() => {
    fetchWatchList({ page: 0, token });
  }, []);

  useEffect(() => {
    if (data !== null) {
      setSlideItems([...slideItems, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [watchedList]);

  const onNext = () => {
    if (!loading && hasMore) {
      fetchWatchList({ page: data.number + 1, token });
    }
  };

  if (error) return <Error error={error} />;

  if (data && data.content.length > 0)
    return (
      <>
        <StyledUserHomePage.H2>{t('classSection.title1')}</StyledUserHomePage.H2>
        {slideItems.length === 0 ? (
          <EmptyList isLoading={loading} />
        ) : (
          <SwiperList slideItemList={slideItems} onNext={onNext} id='watched'>
            {slideItems.map((item, index) => (
              <SwiperSlide key={index}>
                <VideoItem
                  title={item.title}
                  imgSrc={masterTopikUrl + item.thumbnailPath}
                  desc={item.chapterLectureTitle}
                  to={`/courses/detail/${item.productId}`}
                  videoLink={`/courses/detail/${item.productId}/${item.chapterLectureId}`}
                  idNew={item.isNew}
                  percent={item.progressPercent}
                />
              </SwiperSlide>
            ))}
          </SwiperList>
        )}
      </>
    );
  return <></>;
}

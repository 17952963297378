import styled from 'styled-components';

const StyleContainer = styled.div`
  padding-bottom: 10em;
  .about {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
  .about p {
    margin-top: 30px;
    font-size: 20px;
  }
  .about .about-title {
    margin-top: 40px;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
  }

  @media (max-width: 767px) {
    font-size: 1.5em;
  }
`;

export default StyleContainer;

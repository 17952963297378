import styled from "styled-components";
import visualImg from 'assets/images/event/221006/visual.jpg';
import visualImgKo from 'assets/images/event/221006/ko/visual.jpg';
import btnSticky from 'assets/images/event/221006/btn_sticky.png';
import btnStickyKo from 'assets/images/event/221006/ko/btn_sticky.png';
import icoArrow from 'assets/images/event/221006/arrow_right.png';
import titleSayImg from 'assets/images/event/221006/tit_say.png';
import titleSayImgKo from 'assets/images/event/221006/ko/tit_say.png';
import introImg from 'assets/images/event/221006/intro.png';
import introImgKo from 'assets/images/event/221006/ko/intro.png';
import icoQuoteImgL from 'assets/images/event/221006/ico_quote_left.png';
import icoQuoteImgR from 'assets/images/event/221006/ico_quote_right.png';
import titleVideoImg from 'assets/images/event/221006/tit_video.png';
import titleVideoImgKo from 'assets/images/event/221006/ko/tit_video.png';
import titleParticipateImg from 'assets/images/event/221006/tit_participate.png';
import titleParticipateImgKo from 'assets/images/event/221006/ko/tit_participate.png';
import participateImg from 'assets/images/event/221006/participate.png';
import participateImgKo from 'assets/images/event/221006/ko/participate.png';
import titlePresentImg from 'assets/images/event/221006/tit_present.png';
import titlePresentImgKo from 'assets/images/event/221006/ko/tit_present.png';
import presentTopImg from 'assets/images/event/221006/present_top.png';
import presentAllImg from 'assets/images/event/221006/present_all.png';
import presentAllImgKo from 'assets/images/event/221006/ko/present_all.png';
import clickDownImg from 'assets/images/event/221006/down.png';
import clickDownImgKo from 'assets/images/event/221006/ko/down.png';

const StyledEventPage = {
  EventWrap: styled.div`
    font-size: 1.8rem;
    line-height: 1.2;
    background:#fff;
    font-weight:700;
    text-align:center;
    color:#555;
    letter-spacing: -0.035em;
    .inner {
      max-width: 750px;
      margin: auto;
    }
    h2 {
      height: 2.223em;
      margin-bottom: 3em;
      background-size: contain;
      background-position: 50% 0;
      background-repeat: no-repeat;
    }
    .txt_bg {
      display:inline-block;
      font-size: 3.2rem;
      color:#21409a;
      font-weight: 400;
      span {
        display:inline-block;
        background: #86b0fd;
        line-height: 1;
      }
    }

    .visual {
      background-color: #aec3f9;
			.inner {
        padding-top: 1128px;
        background-image: url(${visualImg});
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
				z-index:2;
      }
      .btnWrap {
        padding: 0 9.6em 4em 9.6em;
        a {
          display:block;
          background:#3f38cb;
          border:0.11em #473f4e solid;
          padding: 0.78em 0;
          border-radius: 3em;
          font-size: 3rem;
          color:#fff;
          &:after {
            content:"";
            display:inline-block;
            background:url(${icoArrow}) 0 0 no-repeat;
            width: 1.2em;
            height: 0.6em;
            background-size: contain;
            margin: -0.2em 0 0 0.5em;
            vertical-align:middle;
          }
        }
      }
    }
    &.ko .visual {
      .inner{
        background-image:url(${visualImgKo});
      }
    }
    .sticky {
      position: fixed;
      top: 12em;
      right: 8em;
      z-index: 1;
      a {
        display:block;
        background-image:url(${btnSticky});
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: contain;
        width: 8.1em;
        height: 8.1em;
        border-radius: 4.05em;
        color: #fff;
        font-size: 2rem;
        text-align: center;
        padding-top: 5.6em;
        font-weight: 400;
        z-index: 10;
        box-shadow: 0 5px 10px 5px rgba(0,0,0,.1);
      }
    }
    &.ko .sticky {
      a {
        background-image:url(${btnStickyKo});
      }
    }

    .intro {
      padding: 6em 0;
      background:#6b96fc;
      h2 {
        background-image: url(${titleSayImg});
      }
      ul {
        padding-top: 30.668%;
        background:url(${introImg}) 50% 0 no-repeat;
        background-size: contain;
      }
      .txt_dsc {
        display:inline-block;
        margin: 1.5em 0 2em 0;
        font-size: 2.8rem;
        color:#232a6b;
        font-weight: 400;
        line-height: 1.3;
        strong {
          display:inline-block;
          padding: 0 0.2em;
          background: #004cff;
          color:#fff;
        }
        span {
          display:inline-block;
          border-bottom: 0.1em #232a6b solid;
          line-height: 1;
          font-weight:600;
        }
      }
      .txt_bg {
        position: relative;
        padding: 0 2em;
        &:before,
        &:after {
          content:"";
          display:inline-block;
          position: absolute;
          top: 0;
          width: 1.469em;
          height: 1em;
          background-size: contain;
          background-position: 0 0;
          background-repeat: no-repeat;
        }
        &:before {
          left: 0;
          background-image: url(${icoQuoteImgL});
        }
        &:after {
          right: 0;
          background-image: url(${icoQuoteImgR});
        }
      }
    }
    &.ko .intro {
      h2 {
        background-image: url(${titleSayImgKo});
      }
      ul {
        background:url(${introImgKo}) 50% 0 no-repeat;
      }
    }

    .video {
      padding: 6em 0;
      background:#eff3fe;

      h2 {
        background-image: url(${titleVideoImg});
        margin-bottom: 1em;
      }
      .title_text {
        color:#21409a;
        font-size: 2.6rem;
        font-weight: 400;
      }
      .video_container {
        margin: 2.4em 0;
      }
      .txt_bg {
        font-size: 3rem;
        color:#2b3b82;
        span {
          background: #c1dbfa;
        }
      }
    }
    &.ko .video {
      h2 {
        background-image: url(${titleVideoImgKo});
      }
    }

    .participate {
      padding: 6em 0;
      background:#aec3f9;
      h2 {
        background-image: url(${titleParticipateImg});
      }
      ul {
        padding-top: 73.334%;
        background:url(${participateImg}) 50% 0 no-repeat;
        background-size: contain;
      }
    }
    &.ko .participate {
      h2 {
        background-image: url(${titleParticipateImgKo});
      }
      ul {
        background:url(${participateImgKo}) 50% 0 no-repeat;
      }
    }

    .present {
      padding: 6em 0 0 0;
      background:#dde4fe;
      h2 {
        background-image: url(${titlePresentImg});
      }
      ul {
        display: flex;
        li {
          flex: 1;
          position: relative;
          padding-top: 59%;
          background-repeat: no-repeat;
          background-size: contain;
          font-size: 2.2rem;
          font-weight: 400;
          color:#21409a;
          a.touch_bg{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
          a.arrow {
            display: block;
            position: absolute;
            top: 45%;
            left: 74%;
            width: 6.8188em;
            height: 4.9098em;
            background-image:url(${clickDownImg});
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: contain;
          }
          &:first-child {
            background-image:url(${presentTopImg});
            background-position: 0 0;
            margin-right: 0.834em;
          }
          &:last-child {
            background-image:url(${presentAllImg});
            background-position: 100% 0;
            margin-left: 0.834em;
          }
          h3 {
            padding: 1em;
          }
          strong {
            color:#1b2a6d;
            font-size: 2.6rem;
            font-weight: 600;
          }
          span {
            display:inline-block;
            margin-left: 0.5em;
            padding: 0.2em 0.4em;
            border-radius: 0.2em;
            background:#365fe2;
            color:#fff;
            font-size: 1.8rem;
          }
        }
      }
      .schedule {
        margin-top: 4em;
        background: #fff;
        padding: 3em;
        border-radius: 2em 2em 0 0;
        text-align:left;
        dl {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
           dt {
            flex-shrink: 0;
            width: 25%;
            background: #719bff;
            padding: 0.6em 1.2em;
            margin-bottom: 1em;
            border-radius: 1em;
            color:#fff;
            font-size: 1.8rem;
            font-weight: 400;
            text-align:center;
            word-break: keep-all;
           }
           dd {
            flex-grow: 1;
            width: 75%;
            padding-left:1em;
            margin-bottom: 1em;
            color:#1b2a6d;
            font-size: 2.8rem;
           }
        }
        .help_text {
          margin: 0.5em 0 0 1em;
          color:#4d61c9;
          font-size: 2rem;
          font-weight: 400;
        }
      }
    }
    &.ko .present {
      h2 {
        background-image: url(${titlePresentImgKo});
      }
      ul {
        li {
          a.arrow {
            width:5.046em;
            background-image:url(${clickDownImgKo});
          }
          &:last-child {
            background-image:url(${presentAllImgKo});
          }
        }
      }
    }

    .notification {
      background:#1b2a6d;
      text-align:left;
      padding: 4em 0;
      color:#fff;
      font-weight:300;
      letter-spacing:normal;
      h3 {
        margin-bottom: 2em;
        font-size:2.8rem;
        color:#aec3f9;
        font-weight:400;
        text-align: center;
      }
      dt {
        padding: 0 0 0.4em 0;
      }
      li {
        position:relative;
        padding-left:0.8em;
        margin-bottom: 0.5em;
        font-size: 2rem;
        &:before {
          content:"";
          display:inline-block;
          position: absolute;
          top:0.5em;
          left:0;
          width: 0.2em;
          height: 0.2em;
          border-radius: 0.1em;
          background:#fff;
          vertical-align:middle;
        }
        em {
          color:#fff799;
        }
        a {
          text-decoration:underline;
        }
      }
    }


    @media (max-width: 750px) {
      .inner {
        margin: 0 20px;
      }

      .visual {
        .inner {
          padding-top: 140.3%;
        }
        .btnWrap {
          padding: 0 0 4em 0;
        }
      }
      .sticky {
        position: fixed;
        top: 23em;
        right: 20px;
      }

      .intro,
      .video,
      .participate  {
        padding: 4em 0;
      }
      .present {
        padding: 4em 0 0 0;
      }
    }

    @media (max-width: 580px) {
      .txt_bg {
        font-size: 2.2rem;
      }
      .visual {
        .inner {
          padding-top: 136.3%;
        }
      }
      .sticky {
        top: 15em;
      }
      .intro {
        .txt_dsc {
          font-size: 2.4rem;
        }
      }
      .video {
        .txt_bg {
          font-size: 2.4rem;
        }
      }
      .present {
        ul {
          li {
            &:first-child {
              margin-right: 0.434em;
            }
            &:last-child {
              margin-left: 0.434em;
            }
            a {
              top: 35%;
              left: 74%;
              width: 4.5188em;
              height: 2.6098em;
            }
          }
        }
        .schedule {
          margin-top: 2em;
          padding: 1.5em;
          dl {
            dt {
            width: 30%;
            padding: 0.6em;
            font-size: 1.6rem;
            }
            dd {
            width: 70%;
            padding-left:0.5em;
            margin-bottom: 0.5em;
            font-size: 2.2rem;
            }
          }
          .help_text {
            margin: 0.2em 0 0 0;
            font-size: 1.6rem;
          }
        }
      }
      &.ko .present {
        ul {
          li {
            a {
              top: 35%;
              left: 74%;
              width: 4.5188em;
              height: 3.6098em;
            }
          }
        }
      }
    }
  `,
};

export default StyledEventPage;

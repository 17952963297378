import styled from 'styled-components';

const BookListStyle = {
  BookList: styled.ul`
    margin: 7em 0 13em;
    display: flex;
    flex-wrap: wrap;
    gap: 7em 0;
  `,

  BookListItem: styled.li`
    width: 14.61%;
    margin-left: 13.84%;
    &:nth-child(4n + 1) {
      margin-left: 0;
    }
    a {
      display: block;
    }
    .title {
      margin-top: 0.8em;
      text-align: center;
      letter-spacing: -0.03em;
      font-size: 2.2em;
      color: #fff;
    }
    @media (max-width: 1024px) {
      width: 16%;
      margin-left: 12%;
    }
    @media (max-width: 750px) {
      width: 25%;
      margin-left: 12%;
      &:nth-child(4n + 1) {
        margin-left: 12%;
      }
      &:nth-child(3n + 1) {
        margin-left: 0;
      }
    }
  `,
};

export default BookListStyle;
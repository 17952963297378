import React, { useState } from 'react';
import styled from 'styled-components';
import StyleContainer from './styled/index.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';
import PageHeader from 'component/atoms/PageHeader';

const Style = styled.div`
  > header {
    margin-top: 3em;
  }
`;

const AboutPage = () => {
  const { t } = useTranslation('aboutPage');

  return (
    <ContentsWrapper>
     <SeoHelmet title={SeoData.about.title} desc={SeoData.about.desc} />
     <PageHeader title={t('header title')} text={t('header subtitle')} />
     <StyleContainer>
        <div class='about'>
          <h3 class='about-title'>{t('title')} <br />{t('subtitle')}</h3>
          <p>{t('comment1')}<br />{t('comment1-1')}</p>
          <p>{t('comment2')}</p>
          <p>{t('comment3')}</p>
        </div>
      </StyleContainer>
    </ContentsWrapper>
  );
};

export default AboutPage;

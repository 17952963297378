import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/bookmarkUnit';

/**
 * getAllList 모든 북마크 목록
 * getListOfUser 사용자의 북마크 목록
 */
const bookmarksApi = {
  getBookmarkList: async (token) => {
    let response = await asyncApi({
      url: `${url}/bookmarkUnit`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getBookmarkUnit: async (productId, lectureId, token) => {
    let response = await asyncApi({
      url: `${url}/${productId}/${lectureId}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  updateBookmark: async (productId, lectureId, token) => {
      const response = await asyncApi({
        url: `${url}/updateBookmark?lectureId=${lectureId}`,
        headers: {Authorization: `Bearer ${token}`},
      });
      return response.data;
  },
  deleteBookmark: async (productId, lectureId, token) => {
      const response = await asyncApi({
        url: `${url}/deleteBookmark?lectureId=${lectureId}`,
        headers: { Authorization: `Bearer ${token}` },
      });
    return response.data;
  },
};

export default bookmarksApi;

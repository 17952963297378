import styled from 'styled-components';
import StyledUtil from 'common/styled/Util.style';

const StyledArticle = {
  Article: styled.article`
    margin-bottom: 6em;
    border-bottom: 1px solid #454545;
    h2 {
      font-size: 3.2em;
      margin-bottom: 0.2em;
    }
    header {
      position: relative;
      padding: 5em 0 3em;
      border-bottom: 1px solid #454545;
    }
  `,
  UserMenu: styled.div`
    position: absolute;
    bottom: 3em;
    right: 0;
    span {
      margin-left: 1.5em;
      line-height: 3rem;
    }
  `,
  Info: styled.div`
    margin-top: 1.5em;

    span {
      &:not(:last-child):after {
        content: '';
        ${StyledUtil.Bar};
      }
    }
  `,
  UserName: styled.span`
    font-size: 1.4em;
    font-weight: 500;
  `,
  RegDt: styled.span`
    font-size: 1.4em;
    font-weight: 400;
    color: #969696;
  `,
  Content: styled.div`
    min-height: 40rem;
    padding: 5rem 2rem;
    font-size: 1.8em;
    line-height: 1.6;

    p:empty {
      height: 1em;
    }

    img {
      max-width: 100%;
    }

    a{
      text-decoration: underline;
      color: #007aff;
    }
    a:active {
      color : rgb(200, 51, 85);
    }
  `,
};

export default StyledArticle;

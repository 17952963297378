import styled from 'styled-components';

const StyleContainer = styled.div`
  padding-bottom: 10em;
  .policy {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
  .policy p {
    margin: 10px 0;
    font-size: 14px;
  }

  .policy .policy-title {
    margin-top: 40px;
    padding: 0;
    font-size: 24px;
  }

  .policy .policy-subject {
    margin: 0;
    padding: 2px 0;
    font-size: 14px;
  }

  .policy .policy-subject dt {
    margin: 0;
    padding: 0;
    font-weight: 500;
  }

  .policy .policy-subject dd {
    margin: 4px 0 4px 10px;
    padding: 0;
    font-weight: 500;
  }

  .policy .policy-subject dl {
    margin: 0;
    padding: 0;
  }

  .policy .policy-section {
    margin: 0;
    padding: 0;
  }

  .policy .policy-section a {
    font-weight: 500;
    color: #fb0;
    text-decoration: underline;
  }

  .policy .policy-subtitle {
    margin: 0;
    padding: 40px 0 0;
    font-size: 18px;
  }

  .policy .policy-tit {
    margin: 0;
    padding: 24px 0 0;
    font-size: 16px;
    font-weight: bold;
  }

  .policy .policy-sec-tit {
    margin: 0;
    padding: 24px 0 0;
    font-size: 18px;
    font-weight: bold;
  }

  .policy .policy-bu {
    margin: 4px 0;
    padding: 0;
    font-size: 14px;
    list-style: none;
  }

  .policy .policy-bu > li {
    margin: 4px 0 4px 3px;
    text-indent: -3px;
  }

  .policy .policy-bu li .policy-tbl {
    margin: 4px 0 8px 12px;
  }

  .policy li .policy-tbl {
    margin: 4px 0 8px 0;
  }

  .policy .policy-tbl {
    border-spacing: 0;
    border-collapse: collapse;
  }
  .policy .policy-tbl th,
  .policy .policy-tbl td {
    padding: 2px 40px;
    border: 1px solid;
  }
  .policy .policy-tbl.type2 th,
  .policy .policy-tbl.type2 td {
    padding: 2px 10px;
    border: 1px solid;
  }
  .policy .policy-tbl.type2 th {
    text-align: center;
  }

  .policy .policy-num {
    margin: 8px 0;
    padding: 0;
    list-style: none;
  }

  .policy .policy-num > li {
    margin: 4px 0 4px 18px;
    padding: 0;
    font-size: 14px;
    text-indent: -18px;
  }

  .policy .policy-num > li .policy-num {
    margin-top: 0;
  }

  .policy .policy-dl {
    margin: 4px 0;
    padding: 0;
    text-indent: 0;
  }

  .policy .policy-dl dt,
  .policy .policy-dl dd {
    margin: 0;
    padding: 0;
  }
  .policy .policy-dl dd {
    margin-left: 16px;
  }

  .policy .policy-bu > li p,
  .policy .policy-num > li p,
  .policy .policy-dl p {
    margin: 0;
    text-indent: 0;
  }

  @media (max-width: 767px) {
    font-size: 1.5em;
  }
`;

export default StyleContainer;

import React, { useCallback, useEffect, useRef, useState} from 'react';
import Styled from './index.style';

import { Trans, useTranslation } from 'react-i18next';
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import { openLink } from 'common/util/util';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";

import titImg from 'assets/images/event/230203/tit_topik.png';
import titImg2 from 'assets/images/event/230203/tit_korean.png';
import peopleImg from 'assets/images/event/230203/people.png';
import peopleImg2 from 'assets/images/event/230203/people2.png';
import peopleImg3 from 'assets/images/event/230203/people3.png';
import peopleImg4 from 'assets/images/event/230203/people4.png';


const EventPage = () => {
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation('event/20230203FreeTrial');

  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);
	const location = useLocation();

  // Tab
  const [tabIndex, setTabIndex] = useState(0);

  // Sticky
  const stickyWrapRef = useRef();
  const stickyRef = useRef();
  const [hHeight, sethHeight] = useState(0); //header bottom
  const [stickyHeight, setStickyHeight] = useState(0); //stickyRef height
  const [stickyOffset, setStickyOffset] = useState(0); //stickyWrapRef offsetTop
  const [winScrollY, setWinScrollY] = useState(window.scrollY);
  const count = useRef(1);
  
  const tabClick = (i) => {
    window.scrollTo({
      top: (stickyOffset - hHeight),
      behavior: 'smooth'
    });
    setTabIndex(i);
	};
  const fixedBtn = useRef();

  useEffect(() => {
    /* Mount 된 직후 초기값 셋팅 */
    onResize();

    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onResize);
    };
  }, []);

  useEffect(()=>{
    if(stickyOffset && count.current === 1){
      //stickyOffset 값이 바뀐 최초1회만 호출됨
        count.current = 0;
        if(location.hash){
          switch (location.hash) {
            case '#curriculum':
              tabClick(0);
              break;
            case '#review':
              tabClick(1);
              break;
            case '#howto':
              tabClick(2);
              break;
          }
        }
    }
  }, [stickyOffset])

  const onResize = () => {
    const headerBtm = document.querySelector('header').getBoundingClientRect().bottom; //header bottom
    sethHeight(headerBtm);
    setStickyHeight(stickyRef.current.clientHeight);
    setStickyOffset(stickyWrapRef.current.offsetTop);
    setWinScrollY(window.scrollY);
  }

  useEffect(()=>{
    if(winScrollY >= (stickyOffset - hHeight)){
      stickyRef.current.classList.add('sticky');
      stickyWrapRef.current.style.paddingTop = stickyHeight + 'px';
      stickyRef.current.style.top = hHeight + 'px';

      fixedBtn.current.classList.add('fixed');
    }else {
      stickyRef.current.classList.remove('sticky');
      stickyWrapRef.current.style.paddingTop = '0';
      stickyRef.current.style.top = '0';

      fixedBtn.current.classList.remove('fixed');
    }
    console.log(winScrollY)
    console.log(stickyOffset)
  }, [hHeight, stickyHeight, stickyOffset, winScrollY]);

	const checkLogin = (e) => {
		if (!isLogin) {
      e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
			handleModal(<Login />, false, 420, location.pathname);
			return;
		}
	}

	return (
		<Styled.EventWrap className={language === 'ko-KR' ? 'ko' : null}>
      <div className='visual'>
        <div className='inner'>
          <div className='blind'>
            <h2>{t('visual.title')}</h2>
          </div>
          <Styled.BtnArrow ref={fixedBtn}>
            <Link to={"/plan"}><Trans t={t}>visual.button</Trans></Link>
          </Styled.BtnArrow>
        </div>
      </div>

      <div className='sticky' ref={stickyWrapRef} id="tab">
        <div className='btnWrap' ref={stickyRef}>
          <Styled.TabWrap>
            <Styled.Tab color={'#70ffeb'}>
              <button className={tabIndex === 0 ? 'active' : ''} onClick={(e)=>{tabClick(0)}}>{t('sticky.tab')}</button>
            </Styled.Tab>
            <Styled.Tab color={'#8577fe'}>
              <button className={tabIndex === 1 ? 'active' : ''} onClick={(e)=>{tabClick(1)}}>{t('sticky.tab2')}</button>
            </Styled.Tab>
            <Styled.Tab color={'#09bdff'}>
              <button className={tabIndex === 2 ? 'active' : ''} onClick={(e)=>{tabClick(2)}}>{t('sticky.tab3')}</button>
            </Styled.Tab>
          </Styled.TabWrap>
        </div>
      </div>

      <div className={tabIndex === 0 ? "tab_cont" : "tab_cont hide"}>
        <div className='curriculum'>
          <div className='inner'>
            <div className='title'>
              <p><Trans t={t}>curriculum.sub</Trans></p>
              <h2><Trans t={t}>curriculum.title</Trans></h2>
            </div>
            <div className='cont'>
              <div className='blind'>
                {t('curriculum.desc')}
              </div>
            </div>
          </div>
        </div>
        <div className='curriculum2'>
          <div className='inner'>
            <div className='title'>
              <p><Trans t={t}>curriculum2.sub</Trans></p>
              <h2><Trans t={t}>curriculum2.title</Trans></h2>
            </div>
            <div className='cont'>
              <div className='blind'>
                {t('curriculum2.desc')}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={tabIndex === 1 ? "tab_cont" : "tab_cont hide"}>
        <div className='review'>
          <div className='inner'>
            <div className='title'>
              <h2><Trans t={t}>review.title</Trans></h2>
              <p><Trans t={t}>review.sub</Trans></p>
            </div>
            <div className='cont'>
              <div className='blind'>
                {t('review.desc')}
              </div>
            </div>
          </div>
        </div>
        <div className='review2'>
          <div className='inner'>
            <h3 className='stitle'>“{t('reviewCont.title')}”</h3>
            <div className='list'>
              <h4><img src={titImg} alt="TOPIK" /></h4>
              <ul>
                <li>
                  <div className='box'>
                    <div className='tit'><span>{t('reviewCont.review.title')}</span></div>
                    <div className='cont'>{t('reviewCont.review.content')}</div>
                    <div className='people'>
                      <div>
                        <p className='level'>{t('reviewCont.review.level')}</p>
                        {t('reviewCont.review.name')}
                      </div>
                      <div className='img'>
                        <img src={peopleImg} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='box'>
                    <div className='tit'><span>{t('reviewCont.review2.title')}</span></div>
                    <div className='cont'>{t('reviewCont.review2.content')}</div>
                    <div className='people'>
                      <div>
                        <p className='level'>{t('reviewCont.review2.level')}</p>
                        {t('reviewCont.review2.name')}
                      </div>
                      <div className='img'>
                        <img src={peopleImg2} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <h4><img src={titImg2} alt="KOREAN" /></h4>
              <ul>
                <li>
                  <div className='box'>
                    <div className='tit'><span>{t('reviewCont.review3.title')}</span></div>
                    <div className='cont'>{t('reviewCont.review3.content')}</div>
                    <div className='people'>
                      <div>
                        <p className='level'>{t('reviewCont.review3.level')}</p>
                        {t('reviewCont.review3.name')}
                      </div>
                      <div className='img'>
                        <img src={peopleImg3} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className='box'>
                    <div className='tit'><span>{t('reviewCont.review4.title')}</span></div>
                    <div className='cont'>{t('reviewCont.review4.content')}</div>
                    <div className='people'>
                      <div>
                        <p className='level'>{t('reviewCont.review4.level')}</p>
                        {t('reviewCont.review4.name')}
                      </div>
                      <div className='img'>
                        <img src={peopleImg4} alt="" />
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className={tabIndex === 2 ? "tab_cont" : "tab_cont hide"}>
        <div className='howto'>
          <div className='inner'>
            <div className='title'>
              <p><Trans t={t}>howto.sub</Trans></p>
              <h2><Trans t={t}>howto.title</Trans></h2>
            </div>
            <div className='plan'>
              <div className='standard'>
                <span className='group'>{t('howto.plan.group')}</span>
                <p className='type'><span className='blind'>Standard</span></p>
                <div className='desc'><Trans t={t}>howto.plan.desc</Trans></div>
              </div>
              <div className='premium'>
                <span className='group'>{t('howto.plan2.group')}</span>
                <p className='type'><span className='blind'>Premium</span></p>
                <div className='desc'><Trans t={t}>howto.plan2.desc</Trans></div>
                <div className='plus'><Trans t={t}>howto.plan2.plus</Trans></div>
              </div>
            </div>
            <p className='txt'>{t('howto.txt')}</p>
            <div className='step'>
              <h3>{t('howto.stitle')}</h3>
              <ul>
                <li>
                  <span className='label'>STEP 1</span>
                  <Trans t={t}>howto.step</Trans>
                </li>
                <li>
                  <span className='label'>STEP 2</span>
                  <Trans t={t}>howto.step2</Trans>
                </li>
                <li>
                  <span className='label'>STEP 3</span>
                  <Trans t={t}>howto.step3</Trans>
                </li>
              </ul>
              <p>{t('howto.add')}</p>
            </div>
          </div>
        </div>
      </div>

      <div className='notification'>
        <div className='inner'>
          <h3>{t('notification.title')}</h3>
          <dl>
            <dt>{t('notification.group.title')}</dt>
            <dd><Trans t={t}>notification.group.desc</Trans></dd>
            <dd><Trans t={t}>notification.group.desc2</Trans></dd>

            <dt>{t('notification.group2.title')}</dt>
            <dd><Trans t={t}>notification.group2.desc</Trans></dd>
            <dd><Trans t={t}>notification.group2.desc2</Trans></dd>
            <dd><Trans t={t}>notification.group2.desc3</Trans></dd>
            <dd><Trans t={t}>notification.group2.desc4</Trans>{t('notification.group2.desc5')} <a href="mailto:mastertopik@visnag.com">mastertopik@visang.com</a></dd>

            <dt>{t('notification.group3.title')}</dt>
            <dd><Trans t={t}>notification.group3.desc</Trans></dd>
            <dd><Trans t={t}>notification.group3.desc2</Trans>{t('notification.group3.desc3')} <a href="mailto:mastertopik@visnag.com">mastertopik@visang.com</a></dd>
          </dl>
        </div>
      </div>
		</Styled.EventWrap>
  );
};

export default EventPage;
import { asyncApi } from 'common/util/asyncApi';

const pathName = '/api/v1/learning-materials';

/**
 * getAllList 전체 학습 자료
 * getItem 학습 자료 상세
 * getFile 학습자료 다운로드
 */
const learningMaterialApi = {
  getAllList: async (props = { page: 0, token: null, size: 8 }, searchWord) => {
    let url;
    if (searchWord) {
      url = pathName + `?q=${searchWord}`;
    } else {
      url = pathName;
    }
    let response = await asyncApi({
      url,
      params: { page: props.page, size: props.size },
      headers: { Authorization: `Bearer ${props.token}` },
    });

    return response.data;
  },
  getItem: async (itemId, token) => {
    let itemUrl = pathName + `/${itemId}`;
    let response = await asyncApi({
      url: itemUrl,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getFile: async (itemId, attachmentIdx, token) => {
    let response = await asyncApi({
      url: `${pathName}/${itemId}`,
      params: { attachmentIdx },
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    });

    return response;
  },
};

export default learningMaterialApi;

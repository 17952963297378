import React from 'react';
import styled from 'styled-components';
import Img from 'component/atoms/Img';
import logoImg from 'assets/images/logo01.png';

const Container = styled.div`
  clear: both;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  align-items: center;
  font-size: 1rem;
  height: 100vh;
  background-color: #0f1012;
  padding-bottom: 20vh;
  text-align: center;
  justify-content: center;
  animation: visible 1s ease;
  overflow: hidden;
  p {
    font-size: 3rem;
    color: #c7cad1;
  }
  img {
    animation: fading 3s ease-in-out infinite;
    max-width: 30em;
    transform: scale(1);
    opacity: 0.3;
  }
  @keyframes fading {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.3;
    }
  }
  @keyframes visible {
    0% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  @media (max-width: 1024px) {
    img {
      width: 20em;
    }
  }
`;

const InitLoading = () => (
  <Container>
    <span className='img-box'>
      <Img src={logoImg} alt='Loading' />
    </span>
  </Container>
);

export default InitLoading;

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LevelTestGuide from './LevelTestGuide';
import SelectSubject from './SelectSubject';
import CheckLevel from './CheckLevel';
import CheckLevelSelf from './CheckLevelSelf';
import CheckLevelTest from './CheckLevelTest';
import CheckLevelResult from './CheckLevelResult';
import SelectTarget from './SelectTarget';
import CheckRecommendation from './CheckRecommendation';

export default function CoursesRouter() {
  return (
    <Switch>
      <Route exact path='/level-test' component={SelectSubject} />
      {/* <Route path='/level-test/checkLevel/:selectedSubjectId' component={CheckLevel} /> */}
      <Route path='/level-test/check-level' component={CheckLevel} />
      <Route path='/level-test/check-level-self' component={CheckLevelSelf} />
      <Route path='/level-test/check-level-guide' component={LevelTestGuide} />
      <Route path='/level-test/check-level-test' component={CheckLevelTest} />
      <Route path='/level-test/check-level-result' component={CheckLevelResult} />
      <Route path='/level-test/select-target' component={SelectTarget} />
      <Route path='/level-test/check-recommendation' component={CheckRecommendation} />
    </Switch>
  );
}

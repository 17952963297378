import styled from 'styled-components';
import StyledActivityInsight, {
  swiperButtonStyles,
} from 'container/UserHomePage/styled/ActivityInsight.style';
import { Swiper } from 'swiper/react';
import { Link } from 'react-router-dom';
import watchedIcon from 'assets/images/ico_watched.png';
import watchedLargeIcon from 'assets/images/ico_completed_large.png';
import completedIcon from 'assets/images/ico_completed.png';
import completedLargeIcon from 'assets/images/ico_watched_large.png';

const StyledSummary = {
  Wrapper: styled(StyledActivityInsight.Container)`
    position: relative;
    height: 44rem;
    padding-bottom:0;
  `,
  Swiper: styled(Swiper)`
    height:100%;
    ${swiperButtonStyles}
    .swiper-container {
      position: static;
    }
    .swiper-button-prev,
    .swiper-button-next {
      top: 0;
    }
    .swiper-button-prev {
      right: 3em;
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-pagination-bullets{    bottom: 6px;}
    .swiper-pagination-bullets .swiper-pagination-bullet {
			margin: 0 4px;
		}
		.swiper-pagination-bullet {
			width: 10px;
			height: 10px;
      background:#8b8f99;
			opacity: 0.3;
		}
		.swiper-pagination-bullet-active {
			/* background:#8b8f99; */
      opacity:1;
		}


    @media (max-width: 767px) {
      margin-top: 3em;
      .swiper-button-prev,
      .swiper-button-next {
        margin-top: 0;
        background-color: #0f1012;
      }
      .swiper-button-prev {
        left: auto;
        right: 4em;
      }
      .swiper-button-next {
        right: 0;
      }
      .swiper-pagination-bullets{    bottom: 6px;}
      .swiper-pagination-bullets .swiper-pagination-bullet {
        display: none;
      }
    }
  `,
  H3: styled(StyledActivityInsight.H3)`
    position: absolute;
    top: 0;
    left: 0;
    span {
      display: block;
      font-size: 0.7em;
      font-weight: 300;
      color: #8e9199;
    }
    @media (max-width: 767px) {
      position: static;
      padding-right: 2.8em;
      span {
        display: inline-block;
        font-size: 0.8em;
      }
    }
  `,
  Box: styled(StyledActivityInsight.Box)`
    position: relative;
    padding-top: 7.5em;
    text-align: center;
    @media (max-width: 767px) {
      padding-top: 2.5em;
    }
  `,
  ChartTop: styled.div`
    margin-bottom: 1.5em;
    font-size: 1.4em;
    span {
      font-size: 1.7em;
      margin-left: 0.5em;
      font-weight: 500;
      color: #ffbb00;
      vertical-align: -0.1em;
    }
    @media (max-width: 767px) {
      margin-top: 0;
      font-size: 2em;
      span {
        font-size: 1.5em;
      }
    }
  `,
  HistoryBox: styled.div`
    padding: 3em 3.5em;
    margin-top: 2em;
    border-radius: 0.6rem;
    background-repeat: no-repeat;
    background-position: 3.5em center;
    background-color: #2b2e35;
    text-align: right;
    &.watched {
      background-image: url(${watchedIcon});
    }
    &.completed {
      background-image: url(${completedIcon});
    }
    @media (max-width: 1024px) {
      background-size: 4rem;
      &.watched {
        background-image: url(${watchedLargeIcon});
      }
      &.completed {
        background-image: url(${completedLargeIcon});
      }
    }
  `,
  HistoryH4: styled.h4`
    font-size: 1.4em;
    margin-bottom: 0.5em;
    @media (max-width: 767px) {
      font-size: 2.5em;
    }
  `,
  HistoryDesc: styled.div`
    font-size: 3.4em;
    font-weight: bold;
    line-height: 1.2;
  `,
  WithLists: styled.ul`
    display:flex;
    flex-direction: column;
    gap:2rem;
    width:100%;
  `,
  WithList: styled.li`
    display:flex;
    align-items: center;
    gap:2rem;
    width: 100%;
    height:13rem;
    border-radius: 0.6rem;;
    padding:0 2.5rem;
    &:nth-child(1){
      background-color: #2f2f40;
    }
    &:nth-child(2){
      background-color: #2e3a40;
    }
  `,
  WithListIcon: styled.div`
    width:4rem;
    @media (max-width: 1240px) {
      width:2rem;
      img{width:100%}
    }
    @media (max-width: 1240px) {
      width:4rem;
    }
  `,
  WithListTextBox: styled.div`
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap:1rem;
    flex:1;
    @media (max-width: 1240px) {
      gap:0.5rem;
    }
  `,
  WithListTitle: styled.h3`
    font-size:2.2rem;
    color:#fff;
    font-weight: 600;;
    text-align: left;
    @media (max-width: 1240px) {
      font-size: 1.5rem;
    }
    @media (max-width: 768px) {
      font-size:2.2rem;
    }
  `,
  WithListSlg: styled.p`
    color:#8e9199;
    font-size:1.4rem;
    text-align: left;
    @media (max-width: 1240px) {
      font-size: 1rem;
    }
    @media (max-width: 768px) {
      font-size:1.4rem;
    }
  `,
  WithListTextMore: styled(Link)`
    display:flex;
    justify-content: center;
    align-items: center;
    width:2rem;
    @media (max-width: 1240px) {
      width:1.2rem;
      img{height:20px;}
    }
  `,
};

export default StyledSummary;

import React, { useContext, useEffect } from 'react';
import Style from '../styled/TestPaper.style';
import OmrCard from 'container/MockTestPage/Components/OmrCard';
import Question from 'container/MockTestPage/Components/Question';
import { useAxios } from 'common/hook/useAxiosHook';
import mockTestApi from 'api/mockTests';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import Loading from 'component/atoms/Loading';
import { MockTestContext } from '../index';

const Content = () => {
  const { productId, unitId } = useParams();
  const { mockTestStore, setMockTestStore } = useContext(MockTestContext);

  const token = useSelector((state) => state.user.token);
  const [{ data, loading }] = useAxios(
    () => mockTestApi.getQuestions({ token, productId, unitId }),
    [productId, unitId]
  );

  useEffect(() => {
    //임시저장한 데이터 useContext데이터에 저장하기 => 불러오기 기능
    if (!data) return;
    const tempMockTestAnswer = [];
    data.forEach((question) => {
      if (question.userAnswer) {
        tempMockTestAnswer.push({
          unitValuationId: question.unitValuationId,
          answerNo: parseInt(question.userAnswer),
        });
      }
    });
    setMockTestStore((prev) => ({
      ...prev,
      mockTestAnswerRequest: tempMockTestAnswer,
    }));
  }, [data]);

  if (loading) return <Loading />;
  if (data)
    return (
      <>
        <Style.MockTestContainer>
          <Style.QuestionContainer>
            {data.map((data) => {
              return <Question key={data.unitValuationId} data={data} />;
            })}
          </Style.QuestionContainer>
          <OmrCard
            data={data}
            submitBtnRef={mockTestStore.submitBtnRef}
            saveBtnRef={mockTestStore.saveBtnRef}
          />
        </Style.MockTestContainer>
      </>
    );
  return <></>;
};

export default React.memo(Content);

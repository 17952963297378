import videoClassStoreType from './type';
import {
  addVideoToState,
  deleteItem,
  addApplicationToState,
} from 'container/PaymentPage/state/logic';

const initialState = {
  videoItems: [],
  videoClassApplication: {
    userIdx: null,
    productIdx: null,
    email: null,
    schedule: null,
    learn: null,
  }
};

const videoClassStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case videoClassStoreType.Add_Video:
      return {
        ...state,
        videoItems: addVideoToState(state.videoItems, action.payload),
      };
    case videoClassStoreType.Delete_Video:
      return {
        ...state,
        videoItems: deleteItem(state.videoItems, action.payload),
      };
    case videoClassStoreType.Delete_All:
      return { ...state, videoItems: [] };
    case videoClassStoreType.Add_Coupon:
      return { ...state, coupon: action.payload };
    case videoClassStoreType.Add_Application:
      return {
        ...state,
        videoClassApplication: action.payload,
      };
    case videoClassStoreType.Delete_Application:
      return { ...state, 
        videoClassApplication: {
          userIdx: null,
          productIdx: null,
          email: null,
          schedule: null,
          learn: null,
        }
      }
    default:
      return state;
  }
};

export default videoClassStoreReducer;

import React from 'react';
import styled from 'styled-components';
import Img from 'component/atoms/Img';
import searchIcon from 'assets/images/ico_searchEmpty.png';
import { useTranslation } from 'react-i18next';

const Style = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 70vh;
    padding: 0 1em;
    img {
      width: 8em;
      height: 8em;
      object-fit: cover;
    }
  `,
  H2: styled.h2`
    margin: 0.5em 0;
    color: #fff;
    font-size: 3.4em;
    font-weight: 700;
  `,
  Desc: styled.p`
    color: #8e9199;
    font-size: 2em;
    font-weight: 400;
  `,
};

const VocaCardEmptyPage = () => {
  const { t } = useTranslation('vocaCardEmptyPage');

  return (
    <Style.Container>
      <div>
        <Img src={searchIcon} alt='search-empty' />
        <Style.H2>{t('title')}</Style.H2>
        <Style.Desc>{t('desc')}</Style.Desc>
      </div>
    </Style.Container>
  );
};

export default VocaCardEmptyPage;

import React, { useState } from 'react';
import Style from './styled/CourseEPS.style';
import { ModalContext } from 'component/Modal/modalContext';
import { useHistory } from 'react-router-dom';
import EPSTranslation from './EPS/TranslationData';
const CourseEPS = () => {
  let [clickFlag, setClickFlag] = useState('indonesia');
  let { handleModal } = React.useContext(ModalContext);

  const history = useHistory();
  const clickBtn = () => {
    handleModal();
    history.push('/courses/detail/1128');
  };

  let flagActive = (language, className) => {
    return clickFlag === language ? `active ${className}` : className;
  };

  return (
    <Style.Container>
      <Style.LanguageContainer>
        {
          // <span
          //   className={flagActive('english', 'usa')}
          //   onClick={() => setClickFlag('english')}
          // />
        }
        <span
          className={flagActive('indonesia', 'indonesia')}
          onClick={() => setClickFlag('indonesia')}
        />
        {
          // <span
          //   className={flagActive("nepal", 'nepal')}
          //   onClick={() => setClickFlag("nepal")}
          // />
        }
      </Style.LanguageContainer>
      <h2>{EPSTranslation[clickFlag].header.title}</h2>
      <p>{EPSTranslation[clickFlag].header.desc1}</p>
      <p>{EPSTranslation[clickFlag].header.desc2} </p>

      <Style.SectionContainer>
        <Style.LeftSection>
          <h3>{EPSTranslation[clickFlag].leftSection.title}</h3>
          <div className='content'>
            {EPSTranslation[clickFlag].leftSection.content.map(
              (data, index) => {
                return <p key={index}>{data}</p>;
              }
            )}
          </div>
        </Style.LeftSection>
        <Style.RightSection>
          <div className='content vocab'>
            <h4 className='title'>
              {EPSTranslation[clickFlag].rightSection.content[0].title}
            </h4>
            <span>
              {EPSTranslation[clickFlag].rightSection.content[0].desc1}
            </span>
            <p>{EPSTranslation[clickFlag].rightSection.content[0].desc2}</p>
          </div>
          <div className='content grammar'>
            <h4 className='title'>
              {EPSTranslation[clickFlag].rightSection.content[1].title}
            </h4>
            <span>
              {EPSTranslation[clickFlag].rightSection.content[1].desc1}
            </span>
            <p>{EPSTranslation[clickFlag].rightSection.content[1].desc2}</p>
          </div>
          <div className='content test'>
            <h4 className='title'>
              {EPSTranslation[clickFlag].rightSection.content[2].title}
            </h4>
            <span>
              {EPSTranslation[clickFlag].rightSection.content[2].desc1}
            </span>
            <p>{EPSTranslation[clickFlag].rightSection.content[2].desc2}</p>
          </div>
        </Style.RightSection>
      </Style.SectionContainer>
      <div style={{ clear: 'both' }}></div>
      <Style.ViewButton
        onClick={() => {
          clickBtn();
        }}
      >
        {EPSTranslation[clickFlag].button.content}
      </Style.ViewButton>
    </Style.Container>
  );
};

export default CourseEPS;

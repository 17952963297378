import React from 'react';
import Style from './styled/OverViewList.style';
import { useHistory } from 'react-router-dom';
import { Circle } from 'rc-progress';
import { BiPause } from 'react-icons/bi';
import { BiPlay } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUrl } from './state/action';

const OverViewList = ({ title, percentage, currentVideoUrl }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const logIn = useSelector((state) => state.user.token);
  let overviewUrl = useSelector(
    (state) => state.courseDetail.videoUrl?.overview
  );

  return (
    <Style.Accordion>
      <Style.List
        onClick={() => {
          if (logIn) {
            history.push(history.location.pathname + '/0');
          }
          dispatch(setCurrentUrl(overviewUrl));
        }}
      >
        <span className='icon'>
          <Circle
            percent={
              percentage && currentVideoUrl === 'youtube' ? percentage : 0
            }
            strokeWidth={10}
            trailWidth={10}
            strokeColor={percentage && '#f8b209'}
            trailColor='#8b8f99'
            className='progressBar'
          />
          {percentage && currentVideoUrl === 'youtube' ? (
            <BiPause className='progressBarIcon' color='#f8b209' />
          ) : (
            <BiPlay className='progressBarIcon' color='#8b8f99' />
          )}
        </span>
        <Style.ListTitle> {title}</Style.ListTitle>
      </Style.List>
    </Style.Accordion>
  );
};

export default OverViewList;

import styled from 'styled-components';
import { AiOutlineGlobal, AiOutlineGift } from 'react-icons/ai';

const StyledLanguage = {
  Container: styled.div.attrs(({ children }) => ({
    children: (
      <>
        <AiOutlineGlobal />
        {children}
      </>
    ),
  }))`
    float: left;
    position: relative;
    z-index: 5;
    font-size: 1.6em;
    line-height: 2.5em;
    width: 3.2rem;
    height: 2.5em;
    margin: 2.5rem 2rem 0 0;
    padding: 2.5em 0 0 0.5em;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -1.3rem;
      margin-left: -1.3rem;
      width: 2.6rem;
      height: 2.6rem;
      font-size: 2.6rem;
      color: #fff;
      @media (max-width: 1024px) {
      width: 3rem;
      height: 3rem;
    }
    }

    &:hover ul {
      max-height: 23rem;
      &::before{
        opacity: 1;
      }
      li {
        opacity: 1;
      }
    }
    @media (max-width: 1024px) {
      /* width: 5.5em;
      opacity: 0.9; */
      &:before {
        width: 6em;
        margin-left: -0.25em;
        border: 1px solid #fff;
        border-radius: 100px;
      }
    }
  `,
  Container2: styled.div.attrs(({ children }) => ({
    children: (
      <>
        <AiOutlineGift />
        {children}
      </>
    ),
  }))`
    float: left;
    position: relative;
    z-index: 5;
    font-size: 1.6em;
    line-height: 2.5em;
    width: 3.2rem;
    height: 2.5em;
    margin: 2.3rem 0 0 0;
    padding: 2.5em 0 0 0.5em;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -1.3rem;
      margin-left: -1.3rem;
      width: 3rem;
      height: 3rem;
      font-size: 3rem;
      color: #fff;
    }
    &:hover ul {
      max-height: 23rem;
      &::before{
        opacity: 1;
      }
      li {
        opacity: 1;
      }
    }
    @media (max-width: 1024px) {
      /* width: 5.5em;
      opacity: 0.9; */
      &:before {
        width: 6em;
        margin-left: -0.25em;
        border: 1px solid #fff;
        border-radius: 100px;
      }
    }
  `,
  List: styled.ul`
    position: absolute;
    top: 0;
    width:7.2rem;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 3em;
    max-height: 0;
    transition: all 0.2s ease;
    text-align: center;
    overflow: hidden;
    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 3em;
      left: 50%;
      width: 0;
      height: 0;
      transition: all 0.2s ease;
      transform: translateX(-50%) translateY(-0.8rem);
      border-left: 0.8rem solid transparent;
      border-right: 0.8rem solid transparent;
      border-bottom: 0.8rem solid rgba(15, 16, 18, 0.5);
      opacity: 0;
    }
    @media (max-width: 1024px) {
      padding-top: 3em;
    }
  `,
  Item: styled.li`
    height: 3rem;
    width:7.2rem;
    background-color: rgba(15, 16, 18, 0.5);
    opacity: 0;
    transition: all 0.2s ease;
    &.nav-real-1{padding-top:1rem; height:4rem}
    &.nav-real-3{padding-bottom:1rem; height:4rem}
    &.active {
      display: none;
    }
    a {
      display: block;
      line-height: 3rem;
      font-size:1.4rem;
      &:hover {
        color: #ffb500;
      }
    }
    @media (max-width: 767px) {
      a {
        font-size: 1.2em;
        line-height: 2.3em;
      }
    }
  `,
};

export default StyledLanguage;

import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import "swiper/components/pagination/pagination.scss"

import backgroundImg from 'assets/images/main_background_visual.jpg';

const MainTopStyled = {
  Wrapper: styled.div`
    margin-top: -9rem; // header area
    position: relative;
    font-size: 2em;
    padding: calc(6em + 9rem) 0 8em;
    background: url('${backgroundImg}') no-repeat center;
    background-size: cover;
    font-weight: 100;
    overflow: hidden;
    &::before{
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgb(0, 0, 0);
      opacity: 0.4;
    }
    @media (max-width: 1024px) {
      padding: 7em 0;
    }
    @media (max-width: 767px) {
      font-size: 2.2em;
    }
    @media (max-width: 375px) {
      > * {
        position: relative;
        z-index: 1;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0)
        );
      }
    }
  `,
  H2: styled.h2`
    margin-bottom: 0.2em;
    font-size: 2.5em;
    font-weight: bold;
    line-height: 1.3;
    @media (max-width: 375px) {
      span {
        display: block;
      }
    }
  `,
  Desc: styled.div`
    margin-bottom: 3em;
  `,

  AccountWrap: styled.div`
    float: left;
    width: 360px;
    @media (max-width: 1280px) {
      width: 28.125vw;
    }
    @media (max-width: 1024px) {
      float: none;
      width: 100%;
      text-align: center;
    }
  `,
  CreateAccount: styled.div`
    margin: 0 auto;
    font-size: 0.5em;
    font-weight: 700;
  `,
  SwiperWrap: styled.div`
    position: relative;
    float: right;
    padding-right: 50px;

    @media (max-width: 1024px) {
      float: none;
      max-width: 760px;
      margin: 0 auto 4em;
      padding-right: 0;
    }
  `,
  SwiperInner: styled.div`
    --slide-width : 760px;
    position: relative;
    width: calc(var(--slide-width));
    padding-top: 78.94736842105263%;

    @media (max-width: 1280px) {
      --slide-width : 59.375vw;
    }
    @media (max-width: 1024px) {
      --slide-width : 100%;
    }
  `,
  Swiper: styled(Swiper)`
    --margin-right : 50px;
    --swiper-theme-color: #fff;
    --swiper-navi-pos: 20px;
    --swiper-navi-size: 2.8em;

    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 6.5em 0 0 1.5em;
    touch-action: none;

    &:hover {
      .swiper-button-prev,
      .swiper-button-next{
        opacity: 1;
      }
    }
    .swiper-button-prev,
    .swiper-button-next{
      transition: opacity ease-in 0.2s;
      opacity: 0;
      display: flex;
      position: absolute;
      right: calc(var(--swiper-navi-pos) * 2);
      width: 1em;
      height: 1em;
      font-size: var(--swiper-navi-size);
      border-radius: 50%;
      z-index: 10;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      color: var(--swiper-navigation-color, var(--swiper-theme-color));
      background-color: rgba(0,0,0,.2);
      transform: rotate(90deg);
      &::after{
        font-family: swiper-icons;
        font-size: 0.6em;
        text-transform: none !important;
        letter-spacing: 0;
        font-variant: initial;
        line-height: 1;
      }
    }
    .swiper-button-prev {
      &:after {
        content: 'prev';
      }
      top: var(--swiper-navi-pos);

    }
    .swiper-button-next {
      &:after {
        content: 'next';
      }
      bottom: var(--swiper-navi-pos);
    }

    @media (max-width: 1280px) {
      --margin-right : 40px;
    }
    @media (max-width: 1024px) {
      --margin-right : 0px;
      border-radius: 6vw;
      margin-bottom: 50px;
      .swiper-button-prev,
      .swiper-button-next{
        display: none;
      }
    }
  `,
  SwiperFraction: styled.div`
    --swiper-navi-pos: 6vw;
    transition: opacity ease-in 0.2s;
    opacity: 0;

    @media (max-width: 1024px) {
      opacity: 1;
      position: absolute;
      left: var(--swiper-navi-pos);
      bottom:  var(--swiper-navi-pos);
      z-index: 10;
      background-color: rgba(0, 0, 0, .3);
      padding: 0.5em 1.4em;
      border-radius: 2em;
      line-height: 1;
      font-size: 3vw;
      color: #bdd1c9;
      strong {
        font-weight: 700;
        color: #fff;
      }
    }
  `,
  SwiperProgressbar: styled.div`
    position: absolute;
    width: 4px;
    height: 100%;
    left: auto;
    right: 0;
    top: 0;
    border-radius: 2px;
    background: #454240;
    opacity: 1;
    overflow: hidden;
    .swiper-pagination-progressbar-fill {
      border-radius: 2px;
      background: #ff3867;
    }
    @media (max-width: 1024px) {
      position: relative;
      width: 100%;
      height: 4px;
      margin-top: 6vw;
    }
  `,
  SwiperSlide: styled(SwiperSlide)`
  `,
  Link: styled(Link)`
    span {position: absolute;font-size: 100px;}
    img {
      display: inline-block;
      image-rendering: -webkit-optimize-contrast;
      backface-visibility: hidden;
	  }
  `,
};

export default MainTopStyled;

import React, { useEffect , useState, useRef} from "react";
import Styled from "./styled/UserNav.style";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "component/user/state";
import { navDataLogin } from "../navData";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { deleteAllItem as deleteAllItemBook } from "container/BookStorePage/state/action";
import { deleteAllItem as deleteAllItemVideo, deleteApplication } from "container/PaymentPage/state/action";
import { SiteMapData } from "common/constant";
import { actions as commonAction } from 'common/state';
import { gotoAILink } from 'common/util/util';
import { ModalContext } from 'component/Modal/modalContext';
import DefaultTextModal from 'component/Modal/DefaultTextModal';



const SubMenuItem = ({ item}) => {
    const [activeSub, isActiveSub] = useState(false);
    const handleActive = () => isActiveSub(!activeSub);



  return (
    <>
     <span className={`userNavDepth  ${activeSub ? 'active' : ''}`} onClick={handleActive}>{item.name}
        <svg
          width="8px" height="5px">
          <path fill-rule="evenodd"  fill="#1e2024"
          d="M-0.000,4.1000 L7.1000,4.1000 L3.1000,0.000 L-0.000,4.1000 Z"/>
        </svg>
      </span>
      <div className={`submenu ${activeSub ? 'active' : ''}`}>
        {item.subList.map((subItem)=> <Styled.Link to={subItem.url}>{subItem.name}</Styled.Link> )}
      </div>
    </>
  );
}


const UserNav = () => {
  const containerRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  let { handleModal } = React.useContext(ModalContext);


  const [userNavMenu, setUserNavMenu] = useState(false);
  const cleanCartItem = () => {
    // bookStore 장바구니 지우기
    dispatch(deleteAllItemBook());
    dispatch(deleteAllItemVideo());
    dispatch(deleteApplication());
    dispatch(commonAction.setLastPayment(''));
  };

  const onLogout = (e) => {
    e.preventDefault();
    cleanCartItem();
    dispatch(actions.fetchLogout());
    history.push("/");
  };

  const user = useSelector((state) => state.user.data);


  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setUserNavMenu(!userNavMenu)
    }
  };



  const mypageHandler = () =>{

    setUserNavMenu(!userNavMenu)
  }


  const alertAILink = (e) => {
    e.preventDefault();

    handleModal(
      <DefaultTextModal
        title={'Sorry for the inconvenience. Please use AI SPEAK on the desktop. (iOS 15.6~)'}
        btnType={'primary'}
      />
    );
  }

  const clickAILink = (e) => {
    // alertAILink(e); //오류 시 alert창 활성화
    gotoAILink(e, user, alertAILink);
  }


  useEffect(() => {
    if(userNavMenu){
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [userNavMenu]);



  return (
    <Styled.Container className={` ${userNavMenu ? 'active' : ''}`}>
      <Styled.Button onClick={mypageHandler}/>
      <Styled.List ref={containerRef}>
      <Styled.MyList>
        {navDataLogin.map((item, index) => (

          <Styled.Item key={index}>
              {!item.subList ? (
                  <Styled.Link to={item.url}>{item.name}</Styled.Link>
              ) : (
                <>
                 <SubMenuItem item={item}/>

                </>
              )}
            </Styled.Item>
          ))}
          <Styled.Item>
            <Styled.Link to="#none" onClick={onLogout} className="logout">
              Logout
            </Styled.Link>
          </Styled.Item>
        </Styled.MyList>

      </Styled.List>
    </Styled.Container>
  );
};

export default UserNav;

import React from 'react';
import Style from '../styled/List.style';

const ListItem = ({ item, searchText }) => {
  const formattingContents = item.content.replaceAll(/<[^>]*>?/g, '')
    .replaceAll('&nbsp;'," ")
    .replaceAll(new RegExp(searchText, "gi"), `<strong>$&</strong>`);

  return (
    <>
      <Style.Item>
        <a href=''>
          <Style.TitleWrap>
            <Style.Category>{item.category}</Style.Category>
            {/* 추천 문서 일 경우 클래스 recom 추가 */}
            <Style.Title className={item.isRecommend ? 'recom' : ''}>{item.title}</Style.Title>
          </Style.TitleWrap>
          <Style.Desc dangerouslySetInnerHTML={{ __html: formattingContents }}></Style.Desc>
        </a>
      </Style.Item>
    </>
  );
};
export default ListItem;


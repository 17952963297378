import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import KoreanTab from "./KoreanTab";
import TopikTab from "./TopikTab";

export default function CoursesRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={[path, `${path}/korean`]} component={KoreanTab} />
      <Route exact path={`${path}/topik`} component={TopikTab} />
    </Switch>
  );
}

import { asyncApi } from "common/util/asyncApi";

const url = "/api/v1/members";

/**
 *
 *
 */

const userApi = {
  setPassword: async (data) => {
    let response = await asyncApi({
      url: `${url}/settings/set-password`,
      method: "put",
      data,
    });
    return response.status;
  },
  findPassword: async (userID) => {
    let response = await asyncApi({
      url: `${url}/settings/reset-password-confirmation`,
      params: { userID },
    });
    return response.status;
  },
  getLoginDateList: async (token) => {
    let response = await asyncApi({
      url: `${url}/login-days`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  fetchMyPlan: async (token) => {
    let response = await asyncApi({
      url: `${url}/settings/my-plan`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  updateUserLanguage: async ({ token, language }) => {
    let response = await asyncApi({
      url: `${url}/settings/language`,
      method: "put",
      data: { language },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status;
  },
  cancelUserPlan: async (token) => {
    const config = {
      method: "put",
      url: `${url}/settings/cancel-my-plan`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await asyncApi(config);
    return response;
  },
  updateEmailNotification: async ({ access_token, data }) => {
    const config = {
      method: "put",
      url: `${url}/settings/notification`,
      data,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    let response = await asyncApi(config);
    return response;
  },

  fetchMyPaymentList: async (token) => {
    const config = {
      url: url + "/settings/my-payment-histories",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  fetchMyInvoice: async (token, id) => {
    const config = {
      url: `/api/v1/invoices/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  fetchMyPaymentListByItemType: async (itemType, token) => { //itemType: 'BOOK' | 'VIDEOCLASS' | 'EPS' | 'STANDARD' | 'PREMIUM'
    const response = await asyncApi({
      url: `/api/v1/ordercheck/my-payment-histories`,
      params: { itemKind: itemType },
      headers: token && { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  fetchMyAddress: async (token) => {
    const config = {
      url: `/api/v1/my-addresses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await asyncApi(config);
    return response.data;
  },

  deleteMyAddress: async (token, id) => {
    const data = { addressIdx: id };
    const config = {
      method: "delete",
      url: `/api/v1/my-addresses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    const response = await asyncApi(config);
    return response.data;
  },

  postSendAuthenticationEmail: async (userID) => {
    const response = await asyncApi({
      method: "post",
      url: `${url}/send-authentication-email`,
      data: { userID },
    });
    return response.status;
  },

  postConfirmationEmail: async (data) => {
    const response = await asyncApi({
      method: "post",
      url: `${url}/confirmation`,
      data,
    });
    return response;
  },
  getMyPaymentMethod: async (token) => {
    const response = await asyncApi({
      method: "get",
      url: `${url}/settings/payment-info`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  disableDormantAccount: async (token, snsName, password) => {
    const socialLoginCheck = snsName !== null;
    console.log("@@ socialLoginCheck", socialLoginCheck);
    const response = await asyncApi({
      method: "put",
      url: `${url}/dormant?release`,
      data: { password: socialLoginCheck ? "" : password },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  //화상수업 결제 시 이메일 발송
  sendVideoClassEmail: async (email, orderId) => {
    const response = await asyncApi({
      method: "get",
      url: `${url}/event/sendEmail`,
      params: {
        sendEmail: email,
        orderId,
      },
    });
  
    return response.status;
  },
};

export default userApi;

import { PAYMENT_METHOD } from "common/constant";
import { ModalContext } from "component/Modal/modalContext";
import OverseasConfirmModal from "./OverseasConfirmModal";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const REACT_APP_OVERSEAS_PAYMENT_URL =
  process.env.REACT_APP_OVERSEAS_PAYMENT_URL;

const OverseasPayForm = ({ data, priceCurrency, pginfo, book = false }) => {
  const { pathname } = useLocation();
  const { OVERSEA_CREDIT, PAYPAL, UNION_PAY } = PAYMENT_METHOD;
  const { handleModal } = React.useContext(ModalContext);
  const inputFormRef = useRef(null);

  const handleSubmit = () => {
    inputFormRef.current?.click();
  };

  useEffect(() => {
    if (!data || !inputFormRef) return;
    if (pathname === "/setting" || book) {
      handleSubmit();
      return;
    } else {
      if (data.isTrialExpired) {
        handleSubmit();
      } else {
        handleModal(
          <OverseasConfirmModal handleSubmit={handleSubmit} />,
          false
        );
      }
    }
  }, [inputFormRef]);

  let paymentType;
  if (pginfo === OVERSEA_CREDIT) {
    paymentType = "CARD_INTERNATIONAL";
  } else if (pginfo === PAYPAL) {
    paymentType = "PAYPAL";
  } else if (pginfo === UNION_PAY) {
    paymentType = "UNIONPAY";
  }

  if (!data) return <div></div>;
  const {
    storeId,
    serviceName,
    payerEmail,
    payAmt,
    storeOrderNo,
    payerId,
    hash,
    timestamp,
    returnUrl,
    recurringType,
    intervalType,
    intervalCnt,
    totalCycles,
  } = data;

  const isEmpty = (data) => {
    if (typeof data === "object") {
      if (JSON.stringify(data) === "{}" || JSON.stringify(data) === "[]") {
        console.log("Null");
        return true;
      } else if (!data) {
        console.log("Null");
        return true;
      }
      console.log("Not Null");
      return false;
    } else if (typeof data === "string") {
      if (!data.trim()) {
        console.log("Null");
        return true;
      }
      return false;
    } else if (typeof data === "undefined") {
      console.log("Null");
      return true;
    } else {
      console.log("Not Null");
      return false;
    }
  };

  return (
    <form
      method="POST"
      action={REACT_APP_OVERSEAS_PAYMENT_URL}
      encType="application/x-www-form-urlencoded"
      style={{ display: "none" }}
    >
      상점 아이디:
      <input name="storeid" type="text" value={storeId} readOnly />
      <input name="currency" type="text" value={priceCurrency} readOnly />
      <input name="servicename" type="text" value={serviceName} readOnly />
      <input name="storeorderno" type="text" value={storeOrderNo} readOnly />
      <input name="payamt" type="text" value={payAmt} readOnly />
      <input name="payerid" type="text" value={payerId} readOnly />
      <input name="payeremail" type="text" value={payerEmail} readOnly />
      <input name="returnurl" type="text" value={returnUrl} readOnly />
      <input name="timestamp" type="text" value={timestamp} readOnly />
      <input name="hash" type="text" value={hash} readOnly />
      <input name="recurringtype" type="text" value={recurringType} readOnly />
      <input name="pginfo" type="text" value={pginfo} readOnly />
      {pginfo === PAYMENT_METHOD.PAYPAL &&
        !isEmpty(recurringType) &&
        recurringType === "Y" &&
        !isEmpty(intervalType) &&
        !isEmpty(intervalCnt) &&
        !isEmpty(totalCycles)(
          <>
            <input
              name="intervaltype"
              type="text"
              value={intervalType}
              readonly
            />
            <input
              name="intervalcnt"
              type="text"
              value={intervalCnt}
              readonly
            />
            <input
              name="totalcycles"
              type="text"
              value={totalCycles}
              readonly
            />
          </>
        )}
      <input type="submit" ref={inputFormRef} value="해외 결제 GO"></input>
    </form>
  );
};

export default OverseasPayForm;

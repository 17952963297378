import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import mockTestsApi from 'api/mockTests';
import { Layout, MockTest } from 'container/ClassroomPage/styled';
import SetCard from 'component/ui/card/setCard';
import { Trans, useTranslation } from 'react-i18next';
import pdf from 'assets/TOPIK_Mock_Test_Exam_Paper.pdf';
import { useHistory } from 'react-router';
import { ModalContext } from 'component/Modal/modalContext';
import DefaultTextModal from 'component/Modal/DefaultTextModal';
import OrderBySelect from 'component/molecules/OrderBySelect';

const Styled = {
  SelectContainer: styled.div`
    display: inline-block;
    width: 100%;
    margin: -3em 0 2em;
    .order-by-select {
      float: right;
      z-index: 2;
    }

    @media (max-width: 767px) {
      margin-top: 2em;
    }
  `,
};

const Index = () => {
  const { t } = useTranslation('classroomPage');

  const mockTestListByData = [
    { label: t('mockTestTab.selectTopic.option1'), value: null },
    { label: t('mockTestTab.selectTopic.option2'), value: 11 },
    { label: t('mockTestTab.selectTopic.option3'), value: 12 }
  ]
  let { handleModal } = React.useContext(ModalContext);

  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.data);

  const [mockTestType, setMockTestType] = useState(null);

  const [list, fetchList] = useAxios(() => mockTestsApi.getAllList(token), []);
  const [dashboard, fetchDashboard] = useAxios(() => mockTestsApi.getDashboard(token), []);
  const { loading, data, error } = list;
  const {
    loading: dashboardLoad,
    data: dashboardData,
    error: dashboardError,
  } = dashboard;

  const goToTestReport = () => {
    if (!dashboardData.size) {
      handleModal(<DefaultTextModal title='완료한 모의고사가 없습니다' />);
      return;
    }
    history.push('/test-report');
  };
  const handleChange = ({ value }) => {
    if (mockTestType !== value) {
      setMockTestType(value);
    }
  };
  const goToVideoGuide = (e) => {
    e.preventDefault();
    window.open('https://youtu.be/wf0SnQc6yOg');
  };

  return (
    <div>
      <MockTest.Step>
        <MockTest.StepItem>
          <strong>Step 1</strong>
          <span>
            <Trans t={t}>mockTestTab.process.step1</Trans>
          </span>
        </MockTest.StepItem>
        <MockTest.StepItem>
          <strong>Step 2</strong>
          <span>
            <Trans t={t}>mockTestTab.process.step2</Trans>
          </span>
        </MockTest.StepItem>
        <MockTest.StepItem>
          <strong>Step 3</strong>
          <span>
            <Trans t={t}>mockTestTab.process.step3</Trans>
          </span>
        </MockTest.StepItem>
        <MockTest.StepItem>
          <strong>Step 4</strong>
          <span>
            <Trans t={t}>mockTestTab.process.step4</Trans>
          </span>
        </MockTest.StepItem>
      </MockTest.Step>

      <MockTest.TestGuide>
        <p>{t('mockTestTab.testNotice.desc')}</p>

        <MockTest.BtnGroup>
          <MockTest.BtnVideo onClick={goToVideoGuide}>
            {t('mockTestTab.testNotice.button1')}
          </MockTest.BtnVideo>
          <MockTest.BtnDownload to={pdf} target='_blank'>
            {t('mockTestTab.testNotice.button2')}
          </MockTest.BtnDownload>
        </MockTest.BtnGroup>
      </MockTest.TestGuide>

      <Layout.Wrapper>
        <Layout.H2>
          {user.fullName} {t('mockTestTab.mockTest.title')}
        </Layout.H2>

        <MockTest.DashboardBox>
          <MockTest.Dashboard className='left'>
            <MockTest.ItemBox>
              <MockTest.Item>
                <MockTest.Title>
                  {t('mockTestTab.mockTest.section1')}
                </MockTest.Title>
                {dashboardData && (
                  <MockTest.Count>{dashboardData.size}</MockTest.Count>
                )}
              </MockTest.Item>
            </MockTest.ItemBox>
            <MockTest.ItemBox>
              <MockTest.Item>
                <MockTest.Title>
                  {t('mockTestTab.mockTest.section2')}
                </MockTest.Title>
                {dashboardData && (
                  <MockTest.Count>{dashboardData.count}</MockTest.Count>
                )}
              </MockTest.Item>
            </MockTest.ItemBox>
          </MockTest.Dashboard>

          <MockTest.Dashboard className='right'>
            <p>{t('mockTestTab.mockTest.section3')}</p>
            <MockTest.Btn to='#' onClick={goToTestReport}>
              <Trans t={t}>mockTestTab.mockTest.button</Trans>
            </MockTest.Btn>
          </MockTest.Dashboard>
        </MockTest.DashboardBox>
      </Layout.Wrapper>

      <Layout.Wrapper>
        <Layout.H2>{t('mockTestTab.testListSection.title')}</Layout.H2>
        <Layout.TextP>{t('mockTestTab.testListSection.subtitle')}</Layout.TextP>
        <Styled.SelectContainer>
          <OrderBySelect
            onChange={handleChange}
            optionList={mockTestListByData}
            width='14.5em'
            className='order-by-select'
          />
        </Styled.SelectContainer>
        <MockTest.SetCardList>
          {data?.content &&
            data.content.map((item, index) => {
              if (mockTestType === null) {
                return <SetCard item={item} key={index} />;
              } else if (mockTestType === item.levelType) {
                return <SetCard item={item} key={index} />;
              }
            })}
        </MockTest.SetCardList>
      </Layout.Wrapper>
    </div>
  );
};

export default Index;

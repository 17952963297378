import React from 'react';
import Styled from './index.style';

export default function HistorySlide({ courseCount, t }) {
  return (
    <>
      <Styled.H3>{t('dashBoard.scoreSection.history.title')}</Styled.H3>
      <Styled.Box>
        <Styled.HistoryBox className='watched'>
          <Styled.HistoryH4>{t('dashBoard.scoreSection.history.watched')}</Styled.HistoryH4>
          <Styled.HistoryDesc>{courseCount?.classCount}</Styled.HistoryDesc>
        </Styled.HistoryBox>

        <Styled.HistoryBox className='completed'>
          <Styled.HistoryH4>{t('dashBoard.scoreSection.history.completed')}</Styled.HistoryH4>
          <Styled.HistoryDesc>{courseCount?.completedCount}</Styled.HistoryDesc>
        </Styled.HistoryBox>
      </Styled.Box>
    </>
  );
}

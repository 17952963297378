import styled from 'styled-components';

const StyledConfirmPage = {
  Receipt: {
    Header: styled.header`
      h2 {
        font-size: 4.8em;
        font-weight: 700;
        @media (max-width: 767px) {
          text-align: center;
        }
      }
    `,
    Container: styled.div`
      display: table;
      width: 100%;
      table-layout: fixed;
      margin: 4.5em 0 10em;
      @media (max-width: 767px) {
        font-size: 1.25em;
        display: block;
      }
    `,
    ProfileInfo: styled.div`
      display: table-cell;
      width: 35.8%;
      padding: 6em 0 5.9em 7em;
      border-right: 1px #2b2e35 solid;
      border-radius: 0.6em;
      background-color: #1e2024;
      @media (max-width: 767px) {
        display: block;
        width: 100%;
        border-bottom: 2px #2b2e35 solid;
        border-right: none;
        padding: 4em 0 4em 3em;
      }

      > h2 {
        color: #8e9199;
        font-size: 2.4em;
        font-weight: 700;
      }
      > .info {
        margin-top: 14em;
        @media (max-width: 767px) {
          margin-top: 5.9em;
        }

        > .checkCircle {
          display: inline-block;
          width: 3em;
          height: 3em;
          margin-bottom: 0.5em;
          border-radius: 50%;
          background-color: #485cc7;
          text-align: center;
          line-height: 4.4em;
          font-size: 1.5em;
        }
        > .userInfo {
          display: block;
          margin-top: 0.4em;
          color: #fff;
          font-size: 1.8em;
          font-weight: 700;
        }
        > .title {
          display: block;
          margin: 2.25em 0 1.25em;
          color: #485cc7;
          font-size: 1.6em;
          font-weight: 700;
        }
        > .product {
          display: block;
          margin-top: 0.5em;
          color: #fff;
          font-size: 1.6em;
          font-weight: 400;
        }
        > .desc {
          display: block;
          color: #8b8f99;
          font-size: 1.4em;
          font-weight: 400;
        }
      }
    `,
    ProductInfo: styled.div`
      display: table-cell;
      padding: 5em 7em 5.2em;
      border-radius: 0.6em;
      background-color: #1e2024;
      @media (max-width: 767px) {
        display: block;
        padding: 4em 3em 4em;
      }

      > .title {
        display: block;
        color: #485cc7;
        font-size: 1.6em;
        font-weight: 700;
      }
      > .white {
        margin-bottom: 2.4em;
        color: #fff;
        text-transform: uppercase;
      }
      .product {
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.5em;
        font-size: 1.6em;
        font-weight: 400;
      }
      > .head {
        margin-top: 1em;
        padding: 0;
        color: #575858;
      }
      .last {
        padding-bottom: 0.8em;
        border-bottom: 1px dashed #2b2e35;
      }
      .totalSection {
        padding-top: 1.8em;
      }
      .total {
        span {
          width: 20%;
          text-align: right;
          @media (max-width: 1024px) {
            width: 40%;
          }
          @media (max-width: 375px) {
            width: 60%;
          }
          &:first-of-type {
            margin-right: 1em;
          }
        }
        span:first-of-type {
          text-align: right;
          width: 100%;
          color: #4f5258;
          font-weight: 400;
        }
      }
      .finalTotal {
        margin-top: 0.8em;
        padding: 0;
        font-size: 1.8em;
        font-weight: 700;
      }
    `,
  },
};

export default StyledConfirmPage;

import styled from 'styled-components';
import downloadIcon from 'assets/images/ico_download.svg';

export const TabItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1em;
  padding: 1.5em 2em;
  border-radius: 6px;
  background: #1e2024;
  color: #d6d6d7;
  span {
    font-size: 1.6em;
    font-weight: 500;
  }

  @media (max-width: 767px) {
    font-size: 0.8em;
  }
  @media (max-width: 374px) {
    flex-direction: column;
  }
`;

export const WorkbookBtn = styled.a`
  position: relative;
  padding: 0.4em 1.7em 0.4em 2.1em;
  background: transparent;
  color: #8e9199;
  font-size: 1.4em;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 1.3em;
    height: 1.3em;
    background: url(${downloadIcon}) no-repeat center / 1em;
    transform: translateY(-50%);
  }
  @media (max-width: 767px) {
    padding-right: 0;
  }
`;

import React from 'react';
import styled from 'styled-components';
import ReactSlider from 'react-slider';
import Duration from 'common/util/Duration';

const Styled = {
  Slider: styled(ReactSlider)`
    font-size: 10px;
    height: 2em;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0.85em;
      width: 100%;
      height: 0.3em;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 999px;
    }
    &:active,
    &:hover {
      &:before {
        top: 0.75em;
        height: 0.5em;
        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
      }
      .progress-track {
        top: 0.75em;
        height: 0.5em;
      }
      .progress-thumb {
        opacity: 1;
      }
    }
  `,
  Track: styled.div`
    top: 0.85em;
    height: 0.3em;
    border-radius: 999px;
    &.progress-track-0 {
      background-color: #ffb500;
    }
  `,
  Thumb: styled.div`
    top: 0.85em;
    height: 0.3em;
    width: 0.3em;
    margin-left: -0.15em;
    background-color: #ffb500;
    color: #fff;
    border-radius: 999px;
    outline: none;
    transition: opacity 0.3s ease;
    opacity: 0;
    span {
      position: absolute;
      bottom: calc(100% + 0.4em);
      left: 50%;
      font-size: 12px;
      transform: translateX(-50%);
      letter-spacing: 2px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    }
  `,
};

/**
 *
 * @param {*} props
 * @param {boolean} props.time 시간 포맷 여부
 */
const ProgressSlider = (props) => {
  const Thumb = (_props, _state) => (
    <Styled.Thumb {..._props}>
      <span>
        {props.isTime ? (
          <Duration seconds={_state.valueNow} />
        ) : (
          _state.valueNow
        )}
      </span>
    </Styled.Thumb>
  );

  const Track = (_props, _state) => (
    <Styled.Track {..._props} index={_state.index} />
  );

  return (
    <Styled.Slider
      thumbClassName='progress-thumb'
      trackClassName='progress-track'
      renderTrack={Track}
      renderThumb={Thumb}
      {...props}
    />
  );
};

export default ProgressSlider;

import React from 'react';
import Style from 'container/MockTestPage/styled/TestWritePaper.style';
import Img from 'component/atoms/Img';
import Input from 'container/MockTestPage/WriteResultPage/Input';

const Question = ({ data }) => {
  const {
    isInstruction,
    instructions,
    questionSeq,
    questionPoints,
    questions,
    title,
  } = data;
  return (
    <>
      {isInstruction && <Img src={instructions} alt={title} />}
      <Style.Question>
        <div className='title'>
          {questionSeq}. ({questionPoints}점)
        </div>
        <div className='desc'>
          <Img src={questions} alt={'question'} />
        </div>
        <Input data={data} />
      </Style.Question>
    </>
  );
};

export default Question;

const Section5 = () => {
    return (
        <section className='section5'>
            <div className='sectionWarp'>
                <div className='mainTitleBox'>
                    <h2>Sample<br /> submission video</h2>
                </div>
                <div className="content1">
                    <div className="bgc2">Speaking Video</div>
                    <div className="modal">
                        <div className="modalContent">
                            <div className="textBox">
                                <p>
                                    <span className="span1">
                                        안녕하세요! 저는 HDX 북미법인 글로벌스탭 찰스입니다. 우리 법인은 2010년에 만들어졌어요.<br></br>
                                        그리고 우리 법인은 미국 로스앤젤레스에 위치해 있어요.
                                    </span><br></br>
                                    <span className="span2">(Hello! I'm Charles, the global staff of HDX North America. Our branch was created in 2010.
                                        And our branch is located in Los Angeles, USA.)</span><br></br>
                                    <span className="span3">L.A. is a city full of culture and art. With its stunning beaches and spectacular Hollywood,
                                    it's full of life. </span><br></br><br></br>

                                    <span className="span1">
                                    이 곳에서 5명의 주재원과 40명의 글로벌스탭이 함께 근무해요.
                                    </span><br></br>
                                    <span className="span2">(Five expatriates and 40 global staff work together here.)</span><br></br>
                                    <span className="span3">Everyone is kind and fun to work with.</span><br></br><br></br>

                                    <span className="span1">
                                    우리 법인은 건설기계를 판매해요.
                                    </span><br></br>
                                    <span className="span2">(we sells construction machinery.)</span><br></br>
                                    <span className="span3">Our excavator is the number one in this city. Isn't it cool?</span><br></br><br></br>

                                    <span className="span1">
                                    우리 법인장님은 김현대 상무님이에요.
                                    </span><br></br>
                                    <span className="span2">(our president is Hyundai kim)</span><br></br>
                                    <span className="span3">He is a master of excavators. 법인장님 know everything about excavators. <br></br>
                                    He also introduced me to Korean food, so I tried it and it was really delicious. <br></br>
                                    My favorite menu is 불고기!<br></br><br></br>

                                    We have 10 people on our team, and we promote excavators. <br></br>
                                    This is our best colleague, Michael.
                                    </span><br></br><br></br>

                                    <span className="span1">
                                    우리는 산책을 좋아해요.
                                    </span><br></br>
                                    <span className="span2">(We like to take a walk)</span><br></br>
                                    <span className="span3">We have lunch together and enjoy a short walk around the neighborhood.</span><br></br><br></br>

                                    <span className="span1">
                                    우리는 해변에서 피크닉을 하고 싶어요.
                                    </span><br></br>
                                    <span className="span2">(We want to have a picnic on the beach.)</span><br></br>
                                    <span className="span3">Recently, we discussed with our team the idea of having a picnic on the beach. <br></br>
                                    We want to enjoy delicious food while looking at the sea and listening to each other's stories.<br></br><br></br>

                                    How was the introduction of our HDX American branch ?<br></br>
                                    Please keep supporting us.
                                    </span><br></br><br></br>

                                    <span className="span1">
                                    감사합니다! 사랑해요 HD현대
                                    </span><br></br>
                                    <span className="span2">(thank you I love HD hyundai)</span><br></br>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content2">
                    <div className="bgc2">Sketch Video</div>
                    <div>
                        <div className="videoBox">
                            <iframe width="100%" height="100%"
                                    src="https://www.youtube.com/embed/yivGr5Flcpg?si=m5BcxOw73Wzs_reI"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                {/* <p>Click the button for the Korean guide needed<br /> for filming your branch introduction video!</p> */}


            </div>


        </section>
    )
}

export default Section5
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styled from 'component/molecules/CourseSwiperList/index.style';

import SwiperCore, { Navigation } from 'swiper';
import SliderNav from 'component/atoms/SliderNav/SliderNav.style';

SwiperCore.use([Navigation]);

const swiperBreakPoint = {
  1025: { slidesPerView: 4, spaceBetween: 40, slidesPerGroup: 4 },
  768: { slidesPerView: 4, spaceBetween: 20, slidesPerGroup: 4 },
  600: { slidesPerView: 3.1, spaceBetween: 10, slidesPerGroup: 2 },
  0: { slidesPerView: 2.05, spaceBetween: 10, slidesPerGroup: 2 },
};

/**
 * @param {*} param
 * @param {array} param.slideItemList
 * @param {string | number} param.id
 * @param {function} param.onNext next page event
 * @param {string=} param.className 'container' div class
 * @returns
 */
export default function SwiperList({
  slideItemList,
  id,
  onNext,
  children,
  className,
}) {
  const [isNextChange, setIsNextChange] = useState(false);

  useEffect(() => {
    isNextChange && onNext();
  }, [isNextChange]);

  return (
    <Styled.Container className={className}>
      <SliderNav.Prev
        className={`slider-prev prev${id} ${
          slideItemList.length > 4 && 'visible'
        }`}
      />
      <SliderNav.Next
        className={`slider-next next${id} ${
          slideItemList.length > 4 && 'visible'
        }`}
      />
      <Styled.Swiper
        breakpoints={swiperBreakPoint}
        slidesPerView={4}
        navigation={{
          nextEl: `.next${id}`,
          prevEl: `.prev${id}`,
        }}
        onSlideNextTransitionStart={() => setIsNextChange(true)}
        onSlideNextTransitionEnd={() => setIsNextChange(false)}
      >
        {children}
      </Styled.Swiper>
    </Styled.Container>
  );
}

SwiperList.propTypes = {
  slideItemList: PropTypes.array,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onNext: PropTypes.func,
  className: PropTypes.string,
};

import React, { useContext } from "react";
import Styled from "./index.style";
import screenfull from "screenfull";
import { PlayerContext } from "component/Player";
import { getUA } from "react-device-detect";
import { MOBILE_APP_USER_AGENT } from "common/constant";
import { RiFullscreenExitFill, RiFullscreenFill } from "react-icons/ri";

const FullScreenToggle = (props) => {
  const { playerState, handleState, wrapperRef } = useContext(PlayerContext);
  const { fullScreen } = playerState;

  const handleClickFullscreen = () => {
    const videoDiv = document.querySelector(".player");

    if (getUA === MOBILE_APP_USER_AGENT) {
      if (document.fullscreenElement && document.exitFullscreen) {
        console.log("@@ PC close");
        document.exitFullscreen();
        return;
      }

      if (videoDiv.requestFullscreen) {
        console.log("@@ PC requestFullscreen");
        videoDiv.requestFullscreen();
      } else if (videoDiv.mozRequestFullScreen) {
        console.log("@@ PC mozRequestFullScreen");
        videoDiv.mozRequestFullScreen();
      } else if (videoDiv.webkitRequestFullscreen) {
        console.log("@@ PC webkitRequestFullscreen");
        videoDiv.webkitRequestFullscreen();
      } else if (videoDiv.msRequestFullscreen) {
        console.log("@@ PC webkitRequestFullscreen");
        videoDiv.msRequestFullscreen();
      }
      return;
    }

    if (screenfull.isEnabled) {
      screenfull.toggle(wrapperRef.current);
      screenfull.on("change", () => {
        handleState({
          fullScreen: screenfull.isFullscreen,
        });
      });
    }
  };

  return (
    <Styled.Button onClick={handleClickFullscreen} {...props}>
      {!fullScreen ? <RiFullscreenFill /> : <RiFullscreenExitFill />}
    </Styled.Button>
  );
};

export default FullScreenToggle;

import React, { useEffect, useRef, useState} from 'react';
import Styled from './index.style';

import { Trans, useTranslation } from 'react-i18next';
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import { openLink } from 'common/util/util';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";


const EventPage = () => {
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation('event/20220829TopikChallenge');

  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);
	const location = useLocation();

  // Sticky
  const stickyWrapRef = useRef();
  const stickyRef = useRef();
  const [hHeight, sethHeight] = useState(0); //header bottom
  const [stickyHeight, setStickyHeight] = useState(0); //stickyRef height
  const [stickyOffset, setStickyOffset] = useState(0); //stickyWrapRef offsetTop
  const [winScrollY, setWinScrollY] = useState(window.scrollY);

  useEffect(() => {
    /* Mount 된 직후 초기값 셋팅 */
    onResize();

    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onResize);
    };
  }, []);

  const onResize = () => {
    const headerBtm = document.querySelector('header').getBoundingClientRect().bottom; //header bottom
    sethHeight(headerBtm);
    setStickyHeight(stickyRef.current.clientHeight);
    setStickyOffset(stickyWrapRef.current.offsetTop);
    setWinScrollY(window.scrollY);
  }

  useEffect(()=>{
    if(winScrollY > (stickyOffset - hHeight)){
      stickyRef.current.classList.add('sticky');
      stickyWrapRef.current.style.paddingTop = stickyHeight + 'px';
      stickyRef.current.style.top = hHeight + 'px';
    }else {
      stickyRef.current.classList.remove('sticky');
      stickyWrapRef.current.style.paddingTop = '0';
      stickyRef.current.style.top = '0';
    }
  }, [hHeight, stickyHeight, stickyOffset, winScrollY]);


	const checkLogin = (e) => {
		if (!isLogin) {
      e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
			handleModal(<Login />, false, 420, location.pathname);
			return;
		}
	}

	return (
		<Styled.EventWrap className={language === 'ko-KR' ? 'ko' : null}>
      <div className='visual'>
        <div className='inner'>
          <div className='blind'>
            <h2>{t('visual.title')}</h2>
            <p>{t('visual.desc')}</p>
            <p>{t('visual.desc2')}</p>
            <p>{t('visual.desc3')}</p>
          </div>
        </div>
      </div>

      <div className='sticky' ref={stickyWrapRef}>
        <Styled.BtnArrow ref={stickyRef}>
          <Link to={"/plan"} onClick={checkLogin}><Trans t={t}>sticky.button</Trans></Link>
        </Styled.BtnArrow>
      </div>

      <div className='whatis'>
        <div className='inner'>
          <div className='blind'>
            <h2>{t('whatis.title')}</h2>
            <div>{t('whatis.desc')}</div>
            <div>{t('whatis.desc2')}</div>
            <div>{t('whatis.desc3')}</div>
          </div>
        </div>
      </div>

      <div className='steps'>
        <div className='inner'>
          <div className='blind'>
            <h2>{t('steps.title')}</h2>
            <div>{t('steps.step1')}</div>
            <div>{t('steps.step2')}</div>
            <div>{t('steps.step3')}</div>
          </div>
        </div>
      </div>

      <div className='registration'>
        <div className='inner'>
          <div className='blind'>
            <h2>{t('registration.title')}</h2>
            <div>{t('registration.desc')}</div>
            <h3>{t('registration.stitle')}</h3>
            <div>{t('registration.step1')}</div>
            <div>{t('registration.step2')}</div>
            <div>{t('registration.step3')}</div>
          </div>
        </div>
      </div>

      <div className='lecture'>
        <div className='inner'>
          <div className='blind'>
            <h2>{t('lecture.title')}</h2>
            <div>{t('lecture.desc')}</div>
            <div>{t('lecture.desc2')}</div>
          </div>

          <Styled.BtnArrow>
            {language === 'ko-KR'
            ? <a href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/ko/articles/9122912523667--Notice-토픽-TOPIK-챌린지-강의-리스트-")}}><Trans t={t}>lecture.button</Trans></a> 
            : <a href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/9122912523667--Notice-List-of-TOPIK-Challenge-Lecture-")}}><Trans t={t}>lecture.button</Trans></a> 
            }
          </Styled.BtnArrow>
        </div>
      </div>

      <div className='register'>
        <div className='inner'>
          <div className='blind'>{t('register.desc')}</div>
          <Styled.BtnArrow>
            <Link to={"/plan"} onClick={checkLogin}><Trans t={t}>register.button</Trans></Link>
          </Styled.BtnArrow>
        </div>
      </div>

      <div className='notification'>
        <div className='inner'>
          <h3>{t('notification.title')}</h3>
          <dl>
            <dt>{t('notification.group1.title')}</dt>
            <dd>{t('notification.group1.desc1')}</dd>
            <dd>{t('notification.group1.desc2')}</dd>

            <dt>{t('notification.group2.title')}</dt>
            <dd>{t('notification.group2.desc1')}</dd>
            <dd>{t('notification.group2.desc2')}</dd>
            <dd>{t('notification.group2.desc3')}</dd>
            <dd>{t('notification.group2.desc4')}</dd>

            <dt>{t('notification.group3.title')}</dt>
            <dd>{t('notification.group3.desc1')}</dd>
            <dd>{t('notification.group3.desc2')}</dd>
          </dl>
        </div>
      </div>
		</Styled.EventWrap>
  );
};

export default EventPage;
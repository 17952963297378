import React from 'react';
import Style from './styled/List.style';
import SecondAccordion from './SecondAccordion';

const CourseDetailList = ({
  title,
  overviewLecture,
  chapterHour,
  chapterMinute,
  lessonCount,
  lessonList,
  onClick,
  show,
  percentage,
}) => {
  let minute = chapterHour * 60 + chapterMinute;
  return (
    <Style.Accordion>
      <Style.List onClick={onClick}>
        <Style.ListTitle> {title}</Style.ListTitle>
        <Style.ArrowSpan show={show}>
          <span>
            {lessonCount >= 0 && `${lessonCount} classes, `}
            {minute >= 0 && `${minute} m`}
          </span>
        </Style.ArrowSpan>
      </Style.List>
      <Style.SubListContainer show={show}>
        {lessonList &&
          lessonList.map((data, index) => {
            const { chapterLectureId, title, progressPercent, time } = data;
            return (
              <SecondAccordion
                key={chapterLectureId}
                overviewLecture={overviewLecture && index < 3 && true}
                title={title}
                time={time}
                percent={progressPercent}
                id={chapterLectureId}
                idx={index}
                logoutPercentage={overviewLecture && index < 3 && percentage}
              />
            );
          })}
      </Style.SubListContainer>
    </Style.Accordion>
  );
};

export default CourseDetailList;

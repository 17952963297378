import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/customercenter';

const helpApi = {
  getRecommendKeywords: async () => {
    const config = {
      url: url + "/recommendSearch"
    };
    let response = await asyncApi(config);
    return response.data;
  },
  getBannerArticle: async () => {
    const config = {
      url: url + "/lineBanner",
    };
    let response = await asyncApi(config);
    return response.data;
  },
  getExploreArticle: async () => {
    const config = {
      url: url + "/exploreList",
    };
    let response = await asyncApi(config);
    return response.data;
  },
  getArticleDetail: async (articleIdx) => {
    const config = {
      url: url + "/docDetail",
      params: {
        idx: articleIdx
      }
    };
    let response = await asyncApi(config);
    return response.data;
  },
  getSearchList: async (searchText, category, page, size = 10) => {
    const config = {
      url: url + "/docList",
      params: {
        keyword: searchText,
        category: category,
        page: page,
        pageRow: size
      },
    };
    let response = await asyncApi(config);
    return response.data;
  },
};

export default helpApi;
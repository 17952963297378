import React, { useState } from 'react';
import styled from 'styled-components';
import StyleContainer from './styled/index.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';

import Privacy20230307 from 'container/TermsPage/contents/2023/Privacy20230307';
import Privacy20221230 from 'container/TermsPage/contents/2022/Privacy20221230';
import Privacy20210302 from 'container/TermsPage/contents/2021/Privacy20210302';
import Privacy20201228 from 'container/TermsPage/contents/2020/Privacy20201228';
import Privacy20191230 from 'container/TermsPage/contents/2019/Privacy20191230';
import Privacy20181212 from 'container/TermsPage/contents/2018/Privacy20181212';
import Privacy20161207 from 'container/TermsPage/contents/2016/Privacy20161207';
import Privacy20140102 from 'container/TermsPage/contents/2014/Privacy20140102';
import TermsSelectBox from 'container/TermsPage/SelectBox';
import Privacy20230915 from 'container/TermsPage/contents/2023/Privacy20230915';
import Privacy20240325 from 'container/TermsPage/contents/2024/Privacy20240325';

const Style = styled.div`
  > header {
    margin-top: 3em;
  }
`;

const termsOptions = [
  { label: '2024.03.25', value: '2024.03.25' },
  { label: '2023.09.15', value: '2023.09.15' },
  { label: '2023.03.07', value: '2023.03.07' },
  { label: '2022.12.30', value: '2022.12.30' },
  { label: '2021.03.02', value: '2021.03.02' },
  { label: '2020.12.28', value: '2020.12.28' },
  { label: '2019.12.30', value: '2019.12.30' },
  { label: '2018.12.12', value: '2018.12.12' },
  { label: '2016.12.07', value: '2016.12.07' },
  { label: '2014.01.02', value: '2014.01.02' },
];
const TermsOfPrivacy = () => {
  const { t } = useTranslation('privacyPage');

  const [selected, setSelect] = useState(termsOptions[0].value);
  const services = {
    '2024.03.25': <Privacy20240325 />,
    '2023.09.15': <Privacy20230915 />,
    '2023.03.07': <Privacy20230307 />,
    '2022.12.30': <Privacy20221230 />,
    '2021.03.02': <Privacy20210302 />,
    '2020.12.28': <Privacy20201228 />,
    '2019.12.30': <Privacy20191230 />,
    '2018.12.12': <Privacy20181212 />,
    '2016.12.07': <Privacy20161207 />,
    '2014.01.02': <Privacy20140102 />,
  };
  const onChange = (e) => {
    setSelect(e.value);
  };

  return (
    <ContentsWrapper>
      <SeoHelmet title={SeoData.terms.title} desc={SeoData.terms.desc} />
      <TermsSelectBox
        placeholder={termsOptions[0].value}
        optionList={termsOptions}
        onChange={(e) => onChange(e)}
        width={'20em'}
        isSearchable={false}
      />
      <div style={{ clear: 'both' }}></div>
      <Style>
        <PageHeader title={t('title')} text={t('subtitle')} />
      </Style>
      <StyleContainer>{services[selected]}</StyleContainer>
    </ContentsWrapper>
  );
};

export default TermsOfPrivacy;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import arrowRightIcon from 'assets/images/arrow_right.svg';
import bgOnboarding from 'assets/images/bg_onboarding.png';
import icoOnboarding from 'assets/images/ico_onboarding.png';
import ContentsWrapper from 'component/atoms/ContentsWrapper';

import { BsArrowRight } from 'react-icons/bs';

const StyledProfile = {
  Wrapper: styled(ContentsWrapper)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding-bottom: 6em;
    table-layout: fixed;
    > * {
      display: inline-block;
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  `,

  Inner: styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
  `,

  Thumbnail: styled.div`
    flex: 0 0 15em;
    width: 15em;
    height: 15em;
    margin-top: 2em;
    img {
      border-radius: 50%;
      overflow: hidden;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @media (max-width: 767px) {
      flex: 0 0 11.67em;
      width: 11.67em;
      height: 11.67em;
    }
  `,
  Texts: styled.div`
    flex: 1;
    margin-top: 2em;
    padding-left: 4.5em;
    @media (max-width: 767px) {
      padding-left: 4em;
    }
  `,
  Name: styled.div`
    font-size: 4.8em;
    line-height: 1.5;
    span {
      font-weight: bold;
      color: #ffba00;
    }
    @media (max-width: 767px) {
      font-size: 4em;
      span {
        display: block;
      }
    }
  `,
  State: styled.div`
    display: flex;
  `,
  StateIcon: styled.span`
    display: block;
    padding: 0.2em 0.8em;
    font-weight: 400;
    color: #586eed;
    border: 1px #586eed solid;
    border-radius: 0.3em;
    font-size: 1.4em;
    margin-right: 0.8em;
  `,
  StateFreeButton: styled(Link)`
    display: block;
    padding: 0.2em 0.8em;
    font-weight: 400;
    color: #fff;
    background-color: #f87c58;
    border: 1px #f87c58 solid;
    border-radius: 0.3em;
    font-size: 1.4em;
    &:hover {
      background-color: transparent;
      color: #f87c58;
    }
  `,
  Desc: styled(Link)`
    display: inline-block;
    font-size: 2em;
    max-width: 30em;
    margin: 0.5em 0 0 0;
    color: #c9cbd1;
    border-bottom: 1px #c9cbd1 solid;
    background: url(${arrowRightIcon}) no-repeat center right;
    background-size: 1.6rem;
    padding-right: 1.1em;
    @media (max-width: 414px) {
      font-size: 1.6em;
    }
  `,

  Onboarding: styled(Link)`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    min-height: 100%;
    padding: 4em 36em 4em 23em;
    background: #1e2024 url(${bgOnboarding}) no-repeat 8em 50%;
    background-size: 10em;
    border-radius: 0.6em;
    @media (max-width: 750px) {
      padding: 4em 16% 4em 24%;
      background-position: 4% 50%;
      background-size: 16%;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 5.3vw;
        width: 4vw;
        height: 100%;
        background: url(${icoOnboarding}) no-repeat 0 50%;
        background-size: 100% auto;
      }
    }
  `,
  OnboardingName: styled.div`
    font-weight: 600;
    font-size: 2.8em;
    color: #586eed;
    span {
      display: inline-block;
    }
    @media (max-width: 750px) {
      font-size: 3em;
    }
  `,
  OnboardingDesc: styled.div`
    margin-top: 0.8em;
    font-size: 1.8em;
    color: #8e9199;
    @media (max-width: 750px) {
      margin-top: 0.6em;
      font-size: 2em;
    }
  `,
  OnboardingButton: styled.div.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <BsArrowRight />
      </>
    ),
  }))`
    position: absolute;
    right: 6em;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 26em;
    padding: 1em;
    background-color: #ffb500;
    border-radius: 0.6em;
    text-align: center;
    color: #000;
    span {
      display: inline-block;
      margin-right: 1em;
      font-weight: 600;
      font-size: 1.8em;
      vertical-align: middle;
    }
    svg {
      font-size: 2.6em;
      vertical-align: middle;
    }
    @media (max-width: 750px) {
      & {
        display: none;
      }
    }
  `,
};

export default StyledProfile;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import iconHeart from 'assets/images/ico_heart.png';
import iconHeartLarge from 'assets/images/ico_heart_large.png';
import { CountIconStyle } from 'component/atoms/Icon/index.style';

const Styled = styled.span`
  ${CountIconStyle}
  background-image: url(${iconHeart});
  @media (mas-width: 1024px) {
    background-image: url(${iconHeartLarge});
  }
`;

/**
 * 아이콘이 포함된 좋아요 갯수 표시
 * @param {number} props.count
 * @param {string=} props.ClassName
 */
function CountIconLike({ count = 0, onClick, className }) {
  return (
    <Styled onClick={onClick} className={className}>
      {count}
    </Styled>
  );
}

CountIconLike.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default CountIconLike;

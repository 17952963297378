import React, { useState, useEffect } from 'react';

//component
import PageHeader from 'component/atoms/PageHeader';
import SearchContainer from 'container/LibraryPage/SearchContainer';

//style
import { StyledLibraryPage } from './styled/index';

import CourseRecommendContainer from 'container/LibraryPage/CourseRecommendContainer';
import ThemeContainer from 'container/LibraryPage/ThemeContainer';
import ThemeRecommendContainer from 'container/LibraryPage/ThemeRecommendContainer';
import SearchThemeContainer from 'container/LibraryPage/SearchThemeContainer';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import SearchCourseContainer from 'container/LibraryPage/SearchCourseContainer';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
//import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';
import useLoginStatus from 'common/hook/useLoginStatus';
// import PlanBannerPopup from "component/PlanBanner";
import RollBanner from "component/RollBanner";

const Library = () => {
  const { t } = useTranslation('libraryPage');
  const login = useSelector((state) => state.user.token);
  const [searchWords, setSearchCourseWords] = useState(null);
  const [select, setSelect] = useState('ALL');

  const debounceSearch = debounce((text) => {
    setSearchCourseWords(text);
  }, 1000);

  return (
    <>
      <SeoHelmet title={SeoData.library.title} desc={SeoData.library.desc} />

      <StyledLibraryPage.Wrapper>
        {/* 20221209 PlanBannerPopup > RollBanner로 변경 */}
        {/* <RollBanner /> */}
        {/* <PlanBannerPopup /> */}

        <PageHeader title={t('title')} text={t('subtitle')} />

        <SearchContainer setSearchWord={debounceSearch} setSelect={setSelect} />

        {searchWords && select !== 'Themes' && (
          <SearchCourseContainer
            searchCourseWords={searchWords}
            select={select}
            token={login}
          />
        )}
        {searchWords && select !== 'Courses' && (
          <SearchThemeContainer
            searchThemeWords={searchWords}
            select={select}
          />
        )}

        {!searchWords && <CourseRecommendContainer token={login} />}
        {!searchWords && <ThemeContainer />}
      </StyledLibraryPage.Wrapper>

      <StyledLibraryPage.RecommendContainer>
        <StyledLibraryPage.Wrapper>
          <ThemeRecommendContainer />
        </StyledLibraryPage.Wrapper>
      </StyledLibraryPage.RecommendContainer>
    </>
  );
};

export default Library;

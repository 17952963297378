import styled from 'styled-components';

const StyleAccountSelect = {
  Container: styled.div`
    .select {
      &__indicator {
        padding: 0.6em;
        svg {
          width: 1em;
          height: 1em;
        }
        @media (max-width: 767px) {
          font-size: 2.1em;
        }
      }
      &__control {
        width: 9.5em;
        height: 2.5em;
        border: none;
        border-bottom: 1px solid #2b2e35;
        border-radius: 0;
        background-color: transparent;
        box-shadow: 0 0 0 0 transparent;
        &:focus {
          outline: 0;
        }
        &:hover {
          border-color: #2b2e35;
        }
        @media (max-width: 767px) {
          width: 19em;
        }
      }
      &__single-value {
        font-size: 0.8em;
        font-weight: 500;
        color: #d1d1d1;
        @media (max-width: 767px) {
          font-size: 2em;
        }
      }
      &__indicator-separator {
        display: none;
      }
      &__menu {
        width: 9.5em;
        background-color: #2b2e35;
        overflow: hidden;
        &-list {
          padding: 0;
        }
      }
      &__option {
        width: 100%;
        height: 2.5em;
        font-size: 0.8em;
        font-weight: 400;
        color: #c7cad1;
        letter-spacing: 0.05em;
        &--is-focused {
          background-color: #1e2024;
        }

        &:active,
        &:focus,
        &--is-selected {
          background-color: #1f2024;
          color: #55575e;
        }
        @media (max-width: 767px) {
          font-size: 1em;
        }
      }
    }
    @media (max-width: 767px) {
      .select__control {
        font-size: 1rem;
      }
    }
  `,
};

export default StyleAccountSelect;

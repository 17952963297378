import React from 'react';
import Styled from './index.style';
import practiceIcon1 from 'assets/images/mycurriculum_new/practice_icon1.png';
import practiceIcon2 from 'assets/images/mycurriculum_new/practice_icon2.png';
import practiceIcon3 from 'assets/images/mycurriculum_new/practice_icon3.png';
const PracticeSlide = ({ pinDateList, t }) => {
  return (
    <>
      <Styled.H3>{t('dashBoard.practice.title')}</Styled.H3>
      <Styled.Box>
        <Styled.WithLists>
          <Styled.WithList>
            <Styled.WithListIcon><img src={practiceIcon1} alt={t('dashBoard.practice.topik.title')}/></Styled.WithListIcon>
            <Styled.WithListTextBox>
              <Styled.WithListTitle>{t('dashBoard.practice.topik.title')}</Styled.WithListTitle>
              <Styled.WithListSlg>{t('dashBoard.practice.topik.slg')}</Styled.WithListSlg>
            </Styled.WithListTextBox>
            <Styled.WithListTextMore to="/courses/topik-mock-test"><img src={practiceIcon3} alt="more"/></Styled.WithListTextMore>
          </Styled.WithList>
          <Styled.WithList>
            <Styled.WithListIcon><img src={practiceIcon2} alt={t('dashBoard.practice.speak.title')}/></Styled.WithListIcon>
            <Styled.WithListTextBox>
              <Styled.WithListTitle>{t('dashBoard.practice.speak.title')}</Styled.WithListTitle>
              <Styled.WithListSlg>{t('dashBoard.practice.speak.slg')}</Styled.WithListSlg>
            </Styled.WithListTextBox>
            <Styled.WithListTextMore to="/aispeak"><img src={practiceIcon3} alt="more"/></Styled.WithListTextMore>
          </Styled.WithList>
        </Styled.WithLists>
      </Styled.Box>
    </>
  );
};

export default PracticeSlide;

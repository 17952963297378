import useLoginStatus from "common/hook/useLoginStatus.js";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Style from "./index.style.js";

const Table = ({ t }) => {
  const userData = useSelector((state) => state.user.data);
  const [isFree, setIsFree] = useState(true);
  const isLogin = useLoginStatus();

  useEffect(() => {
    if (!isLogin || userData?.serviceLevel === "FREE") return;
    setIsFree(false);
  }, [userData]);

  return (
    <Style.Container>
      <Style.Table>
        <colgroup>
          <col className="col1" />
          <col className="col2" />
          <col className="col3" />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th style={{ color: "#485CC7" }}>
              PRE<span>MIUM</span>
            </th>
            <th style={{ color: "#69BD18" }}>
              STAN<span>DARD</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="title">
              {t("planTableNew.item1.title")}
              <p>{t("planTableNew.item1.desc")}</p>
            </td>
            <td>
              <Style.Check color={'#485CC7'}></Style.Check>
            </td>
            <td>
              <Style.Check color={'#69bd18'}></Style.Check>
            </td>
          </tr>
          <tr>
            <td className="title">
              {t("planTableNew.item2.title")}
              <p>{t("planTableNew.item2.desc")}</p>
            </td>
            <td>
              <Style.Check color={'#485CC7'}></Style.Check>
            </td>
            <td>
              <Style.Minus color={'#69bd18'}></Style.Minus>
            </td>
          </tr>
          <tr>
            <td className="title">
              {t("planTableNew.item3.title")}
            </td>
            <td>
              <Style.Check color={'#485CC7'}></Style.Check>
            </td>
            <td>
              <Style.Check color={'#69bd18'}></Style.Check>
            </td>
          </tr>
          <tr>
            <td className="title">
              {t("planTableNew.item4.title")}
              <p>{t("planTableNew.item4.desc")}</p>
              <p>{t("planTableNew.item4.desc2")}</p>
              <p>{t("planTableNew.item4.desc3")}</p>
              <p>{t("planTableNew.item4.desc4")}</p>
              <p>{t("planTableNew.item4.desc5")}</p>
            </td>
            <td>
              <Style.Check color={'#485CC7'}></Style.Check>
            </td>
            <td>
              <Style.Check color={'#69bd18'}></Style.Check>
            </td>
          </tr>
        </tbody>
      </Style.Table>
    </Style.Container>
  );
};

export default Table;

import styled from 'styled-components';
import { FiSearch } from 'react-icons/fi';
import { RiCloseCircleFill } from 'react-icons/ri';

export const SearchStyle = {
  Container: styled.div`
    position: absolute;
    top: 50%;
    left: 390px;
    transform: translateY(-45%);
    width: 540px;
    &.main {
      position: relative;
      top: 0;
      left: 0;
      transform: translate(0);
      width:700px;
      margin: 3em auto 0;
      input {
        text-align: center !important;
      }
    }
    @media (max-width: 1024px) {
      position: relative;
      top: 0;
      left: 0;
      transform: translateY(0);
      width: 100%;
      margin-top: 3em;
      &.main {
        width: 100%;
      }
    }
  `,
  InputContainer: styled.span`
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 0.6rem;
    background-color: #33363d;
  `,

  Input: styled.input`
    display: inline-block;
    padding-left: 2em;
    width: calc(100% - 5em);
    height: 2.7em;
    border: none;
    background: transparent;
    color: #ffffff;
    font-size: 2em;
    outline: none;
    &:focus {
      outline: none;
    }
    @media (max-width: 1024px) {
      height: 3.2em;
    }
  `,
  SearchImage: styled(FiSearch)`
    display: inline-block;
    width: 1.5em;
    color: #a3a5a8;
    font-size: 2.5em;
    transform: translateY(0.05em);
    cursor: pointer;
  `,
  CloseImage: styled(RiCloseCircleFill)`
    display: inline-block;
    width: 1.5em;
    font-size: 2.2em;
    color: #191b1f;
    cursor: pointer;
  `,
}

export default SearchStyle;

import React from 'react';
import styled from 'styled-components';
import DaumPostcode from 'react-daum-postcode';
import { ModalContext } from 'component/Modal/modalContext';

const StyledAddressApiModal = styled.div`
  h2 {
    margin-bottom: 1.25em;
    text-align: center;
    color: #000;
    font-size: 2.4em;
    font-weight: 600;
  }
  overflow-x: auto;
`;

const AddressApiModal = ({ onComplete }) => {
  let { handleModal } = React.useContext(ModalContext);

  const complete = (data) => {
    onComplete(data);
    handleModal();
  };
  return (
    <StyledAddressApiModal>
      <h2>Find Address</h2>
      <DaumPostcode
        style={{ width: '95%' }}
        onComplete={(data) => complete(data)}
      />
    </StyledAddressApiModal>
  );
};

export default AddressApiModal;

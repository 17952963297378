import jresearchType from './type';

const initialState = {
  currentVideoUrl: null,
  videoUrl: null,
  selectNumber: 0,
  lectureId: 0
};

const jresearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case jresearchType.Set_Current_Video_Url:
      return {
        ...state,
        currentVideoUrl: action.payload,
      };

    case jresearchType.Set_Video_Url:
      return {
        ...state,
        videoUrl: action.payload,
      };

    case jresearchType.Set_Select_Number:
      return {
        ...state,
        selectNumber: action.payload,
      };

    case jresearchType.Set_Lecture_ID:
      return {
        ...state,
        lectureId: action.payload
      };
    default:
      return state;
  }
};

export default jresearchReducer;

import styled from 'styled-components';
import Img from 'component/atoms/Img';

const StyledCollectedThumbnail = {
  Wrapper: styled.div`
    position: relative;
    font-size: 1rem;
    width: 100%;
    height: 19.5em;
    margin-bottom: 1em;
    border-radius: 0.6em;
    overflow: hidden;
    span {
      position: absolute;
      overflow: hidden;
    }
    span:first-child {
      left: 0;
      top: 0;
      width: 19.5em;
      height: 100%;
    }
    span:not(:first-child) {
      right: 0;
      width: calc(100% - 19.5em - 5px);
      height: calc(50% - (5px / 2));
    }
    span:nth-child(2) {
      top: 0;
    }
    span:nth-child(3) {
      bottom: 0;
    }
  `,
  Img: styled(Img)`
    position: absolute;
    top: 0;
    left: 50%;
    width: initial;
    max-width: initial;
    height: 100%;
    transform: translate(-50%, 0);
  `,
};

export default StyledCollectedThumbnail;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Style from './styled/BookSection.style';

import thumbnailDefault from 'assets/images/thumb_default02.jpg';


const AudioDetailBooks = ({ bookInfo }) => {
  const { t } = useTranslation('audioPage');
  const language = localStorage.getItem('i18nextLng');

  return (
    <Style.Container>
      <Style.TextbookContainer>
        <Style.H2>{t('Detail.stitle')}</Style.H2>
        {bookInfo && bookInfo.bookSoundTrack &&
          <Style.Textbook>
            <Style.TextbookImgContainer>
              <Style.TextbookImg
                // src={masterTopikUrl + textBookData.thumbnail}
                src={bookInfo.bookSoundTrack.thumbnailPath}
                onError={thumbnailDefault}
              />
            </Style.TextbookImgContainer>
            <Style.TextbookContent>
              <span className='textbook-title'>
                {
                  (language === 'ko-KR') ?
                    bookInfo.bookSoundTrack.productTitleKO :
                    bookInfo.bookSoundTrack.productTitleEN
                }
              </span>
              <span className='textbook-desc'>
                {
                  (language === 'ko-KR') ?
                    bookInfo.bookSoundTrack.productSpecialTextKO :
                    bookInfo.bookSoundTrack.productSpecialTextEN
                }
              </span>
            </Style.TextbookContent>
          </Style.Textbook>
        }

      </Style.TextbookContainer>

      <Style.WorkbookContainer>
        <Style.H2>{t('Detail.stitle2')}</Style.H2>

        {bookInfo && bookInfo.soundDownList && bookInfo.soundDownList.map((item, index) => {
          return (
            <Style.WorkBookFile key={index}>
              <span>{item.downFileName}</span>
              <Style.WorkbookBtn
                href={item.downUrl}
                download
              >
                {t('Detail.button')}
              </Style.WorkbookBtn>
            </Style.WorkBookFile>
          );
        })}
      </Style.WorkbookContainer>
    </Style.Container>
  );
};

export default AudioDetailBooks;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import UserThumbnail from 'component/atoms/UserThumbnail';

const Wrapper = styled.span`
  &.community > span:last-of-type {
    vertical-align: initial;
  }
`;
const UserName = styled.span`
  display: inline-block;
  font-size: 1.4em;
  margin-left: 0.7em;
  font-weight: 500;
  color: #fff;
  line-height: 3rem;
  vertical-align: middle;
`;

/**
 * 유저 썸네일 + 이름
 * @param {string=} props.src
 * @param {string} props.name
 * @param {string=} props.ClassName
 */
const UserInfo = ({ src, name, className }) => {
  return (
    <Wrapper className={className}>
      <UserThumbnail src={src} />
      <UserName>{name}</UserName>
    </Wrapper>
  );
};

UserInfo.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
};

export default UserInfo;

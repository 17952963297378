import React from 'react';
import Style from './styled/Modal.style';
import { ModalContext } from 'component/Modal/modalContext';

const Modal = ({ handleClick = null, message }) => {
  let { handleModal } = React.useContext(ModalContext);

  return (
    <Style.Container>
      <p>{message}</p>
      <button onClick={() => {
        handleClick();
        handleModal();
      }}>Yes</button>
      <button className='no-btn' onClick={() => handleModal()}>
        No
      </button>
    </Style.Container>
  );
};

export default Modal;

import React, { useEffect, useState } from 'react';
import Style from './styled/Process.style';
import { useSelector, useDispatch } from 'react-redux';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { ModalContext } from 'component/Modal/modalContext';
import RadioButton from 'component/ui/RadioButton';
import Term from 'component/Term';
import Table from './Table';
import Tab from './Tab';
import OrderSummary from './OrderSummary';
import {
  useParams,
  useHistory,
  useLocation,
  useSearchParams,
} from 'react-router';
import orderApi from 'api/order';
import { useAxios } from 'common/hook/useAxiosHook';
import { getUA, isMobile } from 'react-device-detect';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import OverseasPayForm from 'container/PaymentPage/OverseasPayForm';
import OverseasPayPaypalForm from 'container/PaymentPage/OverseasPayPaypalForm';
import { toast } from 'react-toastify';
// import CurrencySelectBox from "component/molecules/CurrencySelectBox";
import Coupon from 'container/PlanPaymentPage/Coupon';
import {
  CURRENCY,
  MOBILE_APP_USER_AGENT,
  PAYMENT_METHOD,
} from 'common/constant';
import { actions } from 'common/state';
import { Trans, useTranslation } from 'react-i18next';
import plansApi from 'api/plans';

const PlanPayment = () => {
  const { t } = useTranslation('paymentPage');
  const {
    DOMESTIC_CREDIT,
    OVERSEA_CREDIT_PLAN,
    PAYPAL,
    PAYPAL_SUBSCRIPTION,
    UNION_PAY,
  } = PAYMENT_METHOD;
  const { state: planData, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const productId = searchParams.get('productId');

  const currentCurrency = useSelector((state) => state.common.currency);
  const token = useSelector((state) => state.user.token);
  const userData = useSelector((state) => state.user.data);
  const userAgent = window.navigator.userAgent;
  const { handleModal } = React.useContext(ModalContext);
  const [paymentMethod, setPaymentMethod] = useState(DOMESTIC_CREDIT);
  const [overseasPayment, setOverseasPayment] = useState(false);
  const [overseasPaymentPaypal, setOverseasPaymentPaypal] = useState(false);
  const [coupon, setCoupon] = useState({ couponNumber: '', discount: 0 });
  const [isSubmit, setIsSubmit] = useState(false);
  const [terms, setTerm] = useState(false);
  const [message, setMessage] = useState({});

  const history = useHistory();
  if (userData.serviceLevel !== 'FREE' || !planData || !productId)
    history.replace('/');

  const [{ loading, data, error }, submitSubscription] = useAxios(
    orderApi.postSubscription,
    [],
    true
  );

  const [
    { loading: freeTrialLoading, data: freeTrialData },
    getFreeTrial,
  ] = useAxios(plansApi.freeTrial, [], true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!freeTrialData) return;
  }, [freeTrialData]);

  useEffect(() => {
    getFreeTrial(planData.planCode, token);
    if (userAgent !== MOBILE_APP_USER_AGENT) {
      setPaymentMethod(
        currentCurrency === CURRENCY.DOMESTIC
          ? DOMESTIC_CREDIT
          : OVERSEA_CREDIT_PLAN
      );
    } else {
      setPaymentMethod('');
    }
  }, []);

  useEffect(() => {
    if (!data) return;
    if (currentCurrency === CURRENCY.DOMESTIC) {
      if (userAgent !== MOBILE_APP_USER_AGENT) {
        const url = isMobile ? data.mobileUrl : data.onlineUrl;
        window.open(url, 'payletter', '_blank');
      }
    } else if (paymentMethod === PAYPAL_SUBSCRIPTION) {
      setOverseasPaymentPaypal(true);
    } else {
      setOverseasPayment(true);
    }
  }, [data]);

  useEffect(() => {
    // if (userAgent !== MOBILE_APP_USER_AGENT) {
    //   setPaymentMethod(
    //     currentCurrency === CURRENCY.DOMESTIC
    //       ? DOMESTIC_CREDIT
    //       : OVERSEA_CREDIT_PLAN
    //   );
    // } else {
    //   setPaymentMethod("");
    // }
  }, [currentCurrency]);

  useEffect(() => {
    //validation 에러 메세지
    for (const error in message) {
      if (error === 'terms') {
        toast.error(message[error]);
        return;
      }
    }
  }, [message]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userAgent = window.navigator.userAgent;

    if (!terms) {
      setMessage({ terms: 'Please check your Agreement' });
      return;
    }

    if (userAgent === MOBILE_APP_USER_AGENT) {
      const planCode = planData?.planCode.toLowerCase();
      window.WebInterface?.startSubscription(`plan_${planCode}`);
    }
    setIsSubmit(true);
  };

  useEffect(() => {
    if (!isSubmit) return;
    if (userAgent !== MOBILE_APP_USER_AGENT) {
      const data = {
        currency: currentCurrency,
        paymentMethod,
        subscriptionPlanName: planData.planCode,
        productIdx: productId,
        couponCode: coupon.couponNumber ? coupon.couponNumber : null,
      };
      submitSubscription(data, token);
      setMessage({});
    } else {
      setPaymentMethod('');
    }
  }, [isSubmit]);

  const handleTermsModal = (e) => {
    e.preventDefault();
    handleModal(<Term type='terms' />, false, 1060);
  };

  const handlePaymentMethod = (value) => (e) => {
    e.preventDefault();

    if (value === DOMESTIC_CREDIT)
      dispatch(actions.setCurrency(CURRENCY.DOMESTIC));
    else dispatch(actions.setCurrency(CURRENCY.OVERSEA));

    setPaymentMethod(value);
  };

  return (
    <ContentsWrapper>
      <SeoHelmet title={SeoData.payment.title} desc={SeoData.payment.desc} />
      <LoadingOverlay isLoading={loading || freeTrialLoading} />

      <Tab index={1} t={t} />

      <Style.Container>
        {/* <CurrencySelectBox /> */}

        <Style.LeftSection>
          <Style.ProcessContainer>
            <Style.ProcessTitle>
              {t('selectedSection.title')}
            </Style.ProcessTitle>
            <Table
              currentCurrency={currentCurrency}
              freeTrialData={freeTrialData}
              t={t}
            />
          </Style.ProcessContainer>

          <Style.ProcessContainer>
            <Style.ProcessTitle color={'true'}>
              {getUA === MOBILE_APP_USER_AGENT
                ? t('paymentInfo.orderForm.confirm')
                : t('paymentInfo.title')}
            </Style.ProcessTitle>
            {getUA === MOBILE_APP_USER_AGENT ? null : (
              <>
                <Coupon
                  currency={currentCurrency}
                  setCoupon={setCoupon}
                  t={t}
                />

                <Style.PaymentContainer>
                  <h3>{t('paymentInfo.orderForm.method')}</h3>
                  <div className='payMethods'>
                    <button
                      className={paymentMethod === DOMESTIC_CREDIT && 'active'}
                      onClick={handlePaymentMethod(DOMESTIC_CREDIT)}
                    >
                      {t('orderSummary.domesticCredit')}
                    </button>
                    <button
                      className={
                        paymentMethod === OVERSEA_CREDIT_PLAN && 'active'
                      }
                      onClick={handlePaymentMethod(OVERSEA_CREDIT_PLAN)}
                    >
                      {t('orderSummary.overseasCredit')}
                    </button>
                    <button
                      className={
                        paymentMethod === PAYPAL_SUBSCRIPTION && 'active'
                      }
                      onClick={handlePaymentMethod(PAYPAL_SUBSCRIPTION)}
                    >
                      PayPal
                    </button>
                    <button
                      className={paymentMethod === UNION_PAY && 'active'}
                      onClick={handlePaymentMethod(UNION_PAY)}
                    >
                      Union Pay
                    </button>
                  </div>
                </Style.PaymentContainer>
              </>
            )}

            <Style.PaymentContainer className='terms'>
              {getUA === MOBILE_APP_USER_AGENT ? null : (
                <h3>{t('paymentInfo.orderForm.confirm')}</h3>
              )}
              <div>
                <RadioButton handleClick={(e) => setTerm((prev) => !prev)}>
                  <Trans
                    t={t}
                    i18nKey='paymentInfo.orderForm.terms'
                    components={{
                      link1: (
                        <span className='terms' onClick={handleTermsModal} />
                      ),
                    }}
                  />
                </RadioButton>
                {message?.terms && (
                  <Style.WarnText className='error'>
                    {message.terms}
                  </Style.WarnText>
                )}
              </div>
            </Style.PaymentContainer>
          </Style.ProcessContainer>
        </Style.LeftSection>

        <Style.RightSection>
          {planData && (
            <OrderSummary
              currentCurrency={currentCurrency}
              onclick={handleSubmit}
              coupon={coupon}
              freeTrialData={freeTrialData}
              takeCourseTerm={planData.takeCourseTerm}
              t={t}
            />
          )}
        </Style.RightSection>

        {overseasPayment && (
          <OverseasPayForm
            data={data}
            priceCurrency={currentCurrency}
            pginfo={paymentMethod}
          />
        )}

        {overseasPaymentPaypal && (
          <OverseasPayPaypalForm
            data={data}
            priceCurrency={currentCurrency}
            pginfo={paymentMethod}
          />
        )}
      </Style.Container>
    </ContentsWrapper>
  );
};

export default PlanPayment;

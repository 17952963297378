import React, { useContext } from 'react';
import Styled from './index.style';
import { RiPlayMiniFill } from 'react-icons/ri';
import { PlayerContext } from 'component/Player';

/**
 *
 * @param {*} props
 * @param {string=} props.handlePlayToggle
 * @param {func=} props.isPlaying
 */
const PlayToggleOverlay = () => {
  const { playerState, handleState } = useContext(PlayerContext);

  const handlePlayToggle = () => {
    handleState({ playing: !playerState.playing });
  };

  return (
    <Styled.PlayToggleOverlay
      className={!playerState.playing && 'pause'}
      onClick={handlePlayToggle}
    >
      <div>
        <RiPlayMiniFill />
      </div>
    </Styled.PlayToggleOverlay>
  );
};
export default PlayToggleOverlay;

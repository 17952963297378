import React from "react";
import styled from "styled-components";
import { ModalContext } from "component/Modal/modalContext";
import { Trans, useTranslation } from "react-i18next";
import { PayPalButton } from "react-paypal-button-v2";
const PAYPAL_CLIENT_ID = process.env.REACT_APP_PAYPAL_CLIENT_ID;

const Style = {
    Desc: styled.p`
    margin: 1em 0 1.5em;
    text-align: center;
    font-size: 2em;
    font-weight: 700;
  `,
    ButtonContainer: styled.div`
    display: flex;
    > * {
      flex: 1;
    }
  `,
    Button: styled.button`
    display: inline-block;
    padding: 0 0;
    border: 1px #8b8f99 solid;
    border-radius: 0.375em;
    font-size: 1.6em;
    font-weight: 700;
    letter-spacing: 0.1px;
    &.primary {
      margin-right: 0.5em;
      border: 0;
      background-color: #475cc7;
      color: #fff;
    }
  `,
};

const PayPalBtn = (props) => {
    const { t } = useTranslation("paymentPage");
    //let { handleModal } = React.useContext(ModalContext);

    // const onSubmit = (e) => {
    //     alert('onSubmit');
    // };

    const { amount, currency, createSubscription, onApprove, catchError,onError, onCancel} = props;
    // const paypalKey = "ATo1XKJVJMgdUiH1g5B4eBdBrG0556RESCff4-5scI74AhqAQOZc8F7ds7OdXBCSsOZTcIr2mR-odaiu";
    //const paypalKey = "AUj-1FbBy-KlcO3OfSsg9tYamvP8Mp28sZ-TakryZn77m9tXg901TsGy-FsAuB9dCD-sSM6XLOdOfiH7";

    return (
        <>
            <Style.Desc>
                <Trans t={t}>Click the Paypal button to go to the subscription payment window.</Trans>
            </Style.Desc>
            <Style.ButtonContainer>
                <Style.Button className="primary" >
                    <PayPalButton
                        amount={amount}
                        currency={currency}
                        createSubscription={(data, details) => createSubscription(data, details)}
                        onApprove={(data, details) => onApprove(data, details)}
                        onError={(err) => onError(err)}
                        catchError={(err) => catchError(err)}
                        onCancel={(err) => onCancel(err)}
                        options={{
                            clientId: PAYPAL_CLIENT_ID,
                            vault:true,
                            intent: "subscription"
                        }}
                        style={{
                            shape: 'rect',
                            color: 'blue',
                            layout: 'horizontal',
                            label: 'paypal',
                            height: 55,
                            tagline: false
                        }}
                    />
                </Style.Button>
                <Style.Button
                    onClick={() => {
                        onError();
                    }}
                >
                    {t("overseasConfirm.cancel")}
                </Style.Button>
            </Style.ButtonContainer>

        </>
    );
};

export default PayPalBtn;

import styled, {css} from 'styled-components';

const StyledEventPage = {
  Wrapper: styled.header`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    z-index: 100;
    width: 100%;
    /* height: 9em; */
    background-color: transparent;
    transition: background-color 0.2s ease;

    &.scrolled {
      background-color: rgba(0, 0, 0, 0.8);
      /* .line-banner {
        height: 0;
        opacity: 0;
      } */
      .nav-with-line-banner {
        /* margin-top: 0; */
      }
    }
  `,

  LogoWrapper: styled.div`
    float:left;
    display: table;
    height: 9em;
    `,
  Logo: styled.h1`
    display:table-cell;
    vertical-align:middle;
    width: 21em;

    a {
      display: block;
    }

    &.logoLogin {
      width: 2.518em;
    }
  `,
  RightMenu: styled.div`
    float: right;
    margin-top: 2.5em;
    height: 4em;
  `,
  JoinButton: styled.button.attrs({
    type: 'button',
  })`
    height: 100%;
    padding: 0 1em;
    font-size: 1.6em;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #ffb500;
    background-color: #ffb500;
    border-radius: 2em;
    color: #fff;
    cursor: pointer;
  `,
  LoginButton: styled.button.attrs({
    type: 'button',
  })`
    height: 100%;
    padding: 0 1em;
    font-size: 1.6em;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #b9b9b9;
    border-radius: 2em;
    color: #fff;
    cursor: pointer;
    margin-left: 0.8em;
  `,
};

export default StyledEventPage;

import styled from 'styled-components';

const BreadcrumbStyle = {
  Breadcrumb: styled.nav`
    position: absolute;
    bottom: -10.5em;
    z-index: 2;
    ul {
      display: flex;
      font-weight: 300;
      font-size: 1.4em;
      color: #d1d1d1;
    }
    li + li::before {
      content: '>';
      font-family: serif;
      margin: 0 1em;
    }
    li:last-child {
      font-weight: 400;
    }
    li a:hover {
      text-decoration: underline;
    }
    @media (max-width: 1024px) {
      position: static;
      margin: 2em 0 3em;
      ul {
        font-size: 1.6em;
      }
    }
  `,
};

export default BreadcrumbStyle;

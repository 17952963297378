import React from "react";
import Styled from "./index.style";
import { Link } from "react-router-dom";
import BoardReply from "component/molecules/BoardReply";
import Moment from "react-moment";
import InfiniteScroll from "react-infinite-scroll-component";
import Loading from "component/atoms/Loading";

const Board = ({ boardList, next, hasMore = false, t }) => {
  return (
    <Styled.Wrapper>
      <Styled.Header>
        <Styled.Column className="post-title">
          {t("postTable.theader1")}
        </Styled.Column>
        <Styled.Column className="replier"></Styled.Column>
        <Styled.Column className="view-count">
          {t("postTable.theader2")}
        </Styled.Column>
        <Styled.Column className="replies-count">
          {t("postTable.theader3")}
        </Styled.Column>
        <Styled.Column className="reg-date">
          {t("postTable.theader4")}
        </Styled.Column>
      </Styled.Header>

      <InfiniteScroll
        dataLength={boardList.length}
        next={next}
        loader={<Loading />}
        hasMore={hasMore}
      >
        {boardList.map((item, index) => {
          if (!item.isOpen) {
            return (
              <Styled.Item key={index}>
                <Link to={"#"} onClick={(e) => e.preventDefault()}>
                  <Styled.Column className="post-title">
                    관리자가 비공개 처리한 글입니다.
                    <div className="post-title-desc">
                      {item.categoryCode}
                      {item.categoryCode && item.productTitle && " | "}
                      {item.productTitle}
                    </div>
                  </Styled.Column>
                  <Styled.Column className="replier">
                    <BoardReply replierList={item.replierList} />
                  </Styled.Column>
                  <Styled.Column className="view-count">
                    {item.viewCount}
                  </Styled.Column>
                  <Styled.Column className="replies-count">
                    {item.repliesCount}
                  </Styled.Column>
                  <Styled.Column className="reg-date">
                    <Moment fromNow ago>
                      {item.regDate}
                    </Moment>
                  </Styled.Column>
                </Link>
              </Styled.Item>
            );
          } else {
            return (
              <Styled.Item key={index}>
                <Link to={`/community/${item.id}`}>
                  <Styled.Column className="post-title">
                    {item.title}
                    <div className="post-title-desc">
                      {item.categoryCode}
                      {item.categoryCode && item.productTitle && " | "}
                      {item.productTitle}
                    </div>
                  </Styled.Column>
                  <Styled.Column className="replier">
                    <BoardReply replierList={item.replierList} />
                  </Styled.Column>
                  <Styled.Column className="view-count">
                    {item.viewCount}
                  </Styled.Column>
                  <Styled.Column className="replies-count">
                    {item.repliesCount}
                  </Styled.Column>
                  <Styled.Column className="reg-date">
                    <Moment fromNow ago>
                      {item.regDate}
                    </Moment>
                  </Styled.Column>
                </Link>
              </Styled.Item>
            );
          }
        })}
      </InfiniteScroll>
    </Styled.Wrapper>
  );
};

export default Board;

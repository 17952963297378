import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from 'component/atoms/Loading';
import Error from 'component/Error';
import { EmptyList } from 'component/atoms/EmptyList';
import Styled from 'container/UserHomePage/styled/ServiceContents.style';
import Board from 'container/UserHomePage/styled/LearningMaterial.style';

import Img from 'component/atoms/Img';
import icoPdf from 'assets/images/ico_file_pdf.png';
import icoExcel from 'assets/images/ico_file_excel.png';
import icoMp3 from 'assets/images/ico_file_mp3.png';
import icoImg from 'assets/images/ico_file_img.png';
import icoWord from 'assets/images/ico_file_word.png';
import icoOther from 'assets/images/ico_file_other.png';
import { useTranslation } from 'react-i18next';

export default function LearningMaterial({ initialState }) {
  const { t } = useTranslation('userHomePage');
  const token = useSelector((state) => state.user.token);
  const [learning, fetchLearningList] = initialState;
  const { loading, data, error } = learning;
  const boardItem = data?.content && data.content;

  useEffect(() => {
    fetchLearningList({ page: 0, token, size: 4 });
  }, []);

  const getFileExtension = (item) => {
    let fileExtension = '';
    const isFile = item.length > 0 ? item[0].name.split(/\./).reverse()[0] : '';

    if (isFile === 'pdf') {
      fileExtension = icoPdf;
    } else if (isFile === 'doc' || isFile === 'docx') {
      fileExtension = icoWord;
    } else if (isFile === 'xls' || isFile === 'xlsx') {
      fileExtension = icoExcel;
    } else if (isFile === 'jpg' || isFile === 'jpeg') {
      fileExtension = icoImg;
    } else if (isFile === 'mp3') {
      fileExtension = icoMp3;
    } else {
      fileExtension = icoOther;
    }
    const fileIcon = item.length > 1 ? icoOther : fileExtension;
    return isFile === '' ? '' : <Img src={fileIcon} alt='figure Image' />;
  };

  if (error) return <Error error={error} />;

  return (
    <>
      <Styled.H2>{t('materialSection.title')}</Styled.H2>
      {data === null ? (
        <EmptyList isLoading={loading} />
      ) : (
        <Board.List>
          {boardItem.map((item, index) => {
            const date = item.regDate.split('T')[0];

            return (
              <Board.Item key={index}>
                <Board.Icon>{getFileExtension(item.attachments)}</Board.Icon>
                <Board.Category>{item.categoryCode}</Board.Category>
                <Board.Title>
                  <Board.TitleLink to={`/learning-material/detail/${item.idx}`}>
                    {item.title}
                  </Board.TitleLink>
                </Board.Title>
                <Board.Date>{date}</Board.Date>
              </Board.Item>
            );
          })}
        </Board.List>
      )}
    </>
  );
}

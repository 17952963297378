import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Style from './styled/Banner.style';

import { Link, useLocation } from 'react-router-dom';
import { openLink } from 'common/util/util';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";

import H3 from 'component/atoms/H3';

const AudioBanner = () => {
  const { t } = useTranslation('audioPage');

  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);
	const location = useLocation();

	const checkLogin = (e) => {
		if (!isLogin) {
      e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
			handleModal(<Login />, false, 420, location.pathname);
			return;
		}
	}

  return (
    <>
      <H3>{t('stitle')}</H3>
      <Style.BannerList>
        <li>
          <Link to='//www.mastertopik.com/courses/48' target='_blank'>
            <Style.BannerListItem className='courses'>
              <h4>{t('bannerList1.title')}</h4>
              <p>{t('bannerList1.desc')}</p>
            </Style.BannerListItem>
          </Link>
        </li>
        <li>
          <Link to='/aispeak' target='_blank'>
            <Style.BannerListItem className='aispeak'>
              <h4>{t('bannerList2.title')}</h4>
              <p>{t('bannerList2.desc')}</p>
            </Style.BannerListItem>
          </Link>
        </li>
        <li>
          <Link to={'/voca-card'} onClick={checkLogin} target='_blank'>
            <Style.BannerListItem className='voca'>
              <h4>{t('bannerList3.title')}</h4>
              <p>{t('bannerList3.desc')}</p>
            </Style.BannerListItem>
          </Link>
        </li>
      </Style.BannerList>
    </>
  );
};
export default AudioBanner;
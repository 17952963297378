import React from 'react';
import { Layout } from 'container/LevelTestPage/styled';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import LevelTestRouter from 'container/LevelTestPage/Router';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';

const LevelTest = () => {

  return (
    <>
    <SeoHelmet title={SeoData.levelTest.title} desc={SeoData.levelTest.desc}/>
      <ContentsWrapper>
        <Layout.Container>
          <LevelTestRouter />
        </Layout.Container>
      </ContentsWrapper>
    </>
  );
}

export default LevelTest;

export const regex = {
  email: /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
  name: /^[a-zA-Z\s]+$/,
  password: /^[A-Za-z0-9+!@#$%^&.*]{10,20}$/,
};

export const onValidate = {
  email: (value) => regex.email.test(value),
  name: (value) => regex.name.test(value),
  password: (value) => regex.password.test(value),
};

import styled from 'styled-components';
import { StyledLibrarySearch } from 'container/LibraryPage/styled/libraryInput.style';
import { HiOutlineChevronDown } from 'react-icons/hi';
import checkImg from 'assets/images/ico_check.png';
import TableLayout from 'common/styled/tableLayout.style';
import StyledUtil from 'common/styled/Util.style';
const NotificationPage = {
  InputContainer: styled(StyledLibrarySearch.InputContainer)`
    width: 100%;
    margin: 8.7em 0 3.7em;
  `,
  SearchInput: styled(StyledLibrarySearch.Input)``,
  SearchImage: styled(StyledLibrarySearch.SearchImage)`
    cursor: pointer;
  `,

  BtnContainer: styled.div`
    > div {
      float: right;

      > button:last-child {
        margin-right: 0;
      }
      @media (max-width: 767px) {
        float: none;
        &.btn-container {
          > button {
            margin: 0;
            width: 49%;
            margin-bottom: 1em;
          }
          > button:nth-child(odd) {
            margin-right: 2%;
          }
        }
      }
    }
    margin-bottom: 1rem;
    &::after {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }
  `,
  Btn: styled.button`
    margin-right: 0.71em;
    padding: 0.92em 1.42em;
    border: 1px solid #909193;
    border-radius: 0.6rem;
    color: #cacbcd;
    font-size: 1.4em;
    font-weight: 500;
    @media (max-width: 1024px) {
      padding: 1.12em 1.42em;
    }
    @media (max-width: 767px) {
      padding: 1.32em 1.42em;
    }
    @media (max-width: 375px) {
      padding: 1.6em 1.42em;
    }
  `,

  NotificationContainer: styled.div`
    margin: 2em 0 17.4em;
    border-top: 1px solid #5d6165;
    border-bottom: 1px solid #5d6165;
    > div:last-child {
      border-bottom: none;
    }
  `,
  List: styled.div`
    .notification-heading {
      ${TableLayout}
      padding: 2.4em 2.8em;
      border-bottom: 1px solid #1d1f24;
      cursor: pointer;
      > .notification-input {
        width: 5em;
        > input[type='checkbox'] {
          position: absolute;
          width: 1px;
          height: 1px;
          visibility: hidden;

          & + label {
            position: relative;
            cursor: pointer;
          }

          & + label:before {
            content: '';
            display: inline-block;
            width: 2.1em;
            height: 2.1em;
            border: 1px #5d6165 solid;
            border-radius: 2px;
          }
          &:checked + label:before {
            border: 1px #fff solid;
            background: url(${checkImg}) no-repeat center;
          }
        }
      }
      > .notification-content {
        position: relative;

        &.unread {
          &::before {
            content: '';
            position: absolute;
            top: 0.7em;
            left: -1.3em;
            width: 0.4em;
            height: 0.4em;
            border-radius: 50%;
            background-color: #ff3867;
          }
        }
        p {
          ${StyledUtil.lineClamp}
          font-size: 1.8em;
          font-weight: 400;
        }
        span {
          font-size: 1.6em;
          color: #666;
        }
      }

      > span {
        width: 4em;
      }
    }

    > .dropdown-content {
      display: none;
      padding: 1.75em 3em;
      border-top: 1px solid #2b2e35;
      border-bottom: 1px solid #2b2e35;
      background-color: #1e2024;
      color: #d6d6d7;
      font-size: 1.6em;
      font-weight: 400;
      @media (max-width: 767px) {
        padding: 1.5em 2em;
      }
      &.open {
        display: block;
      }
      > .link {
        display: inline-block;
        margin-top: 1.75em;
        color: #8b8f99;
        font-weight: 500;
        > svg {
          vertical-align: sub;
        }
      }
    }
  `,
  DropDownArrow: styled(HiOutlineChevronDown)`
    color: #5a5d64;
    transform: ${({ open }) => open && 'rotate(-180deg)'};
  `,
};

export default NotificationPage;

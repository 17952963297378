import React, { useEffect } from 'react';
import Style from './styled/List.style';
import SecondAccordion from './SecondAccordion';
import { useAxios } from 'common/hook/useAxiosHook';
import BookStoreApi from 'api/bookstore';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const DetailList = ({
  audioChapter,
  onClick,
  show
}) => {

  const { bookLevel } = useParams();

  const [{ data: audioList }, fetchAudioList] = useAxios(
    BookStoreApi.getBookSoundLevelChapter,
    [],
    true
  );

  useEffect(() => {
    if (audioChapter && show && !audioList) {
      fetchAudioList(bookLevel, audioChapter.levelChapter);
    }
  }, [bookLevel, audioChapter, show])

  return (
    <Style.Accordion>
      <Style.List onClick={onClick}>
        <Style.ListTitle>{audioChapter.levelChapterName}</Style.ListTitle>
        <Style.ArrowSpan show={show}></Style.ArrowSpan>
      </Style.List>
      <Style.SubListContainer show={show}>
        {audioList && audioList.bookSoundChapter.map((item, index) => {
          return (
            <SecondAccordion
              key={index}
              audio={item}
            />
          );
        })}
      </Style.SubListContainer>
    </Style.Accordion>
  );
};

export default DetailList;

import notificationType from './type';

export const setAlarmCount = (number) => ({
  type: notificationType.Set_Number,
  payload: number,
});

export const countReadAll = () => ({
  type: notificationType.Delete_All,
});

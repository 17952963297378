import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import checkState from 'container/LevelTestPage/common/checkState';
import { Quiz, Button } from 'container/LevelTestPage/styled';
import Step from 'container/LevelTestPage/common/Step';
import QuizView from 'container/LevelTestPage/CheckLevelTest/QuizView';
import { quizData } from 'container/LevelTestPage/CheckLevelTest/quizData';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const { t } = useTranslation('levelTestPage');

  checkState();

  const history = useHistory();
  const { state } = useLocation();

  const [quizState, setQuizState] = useState(0);
  const [checked, setChecked] = useState({
    selected: null,
    correct: null,
    incorrect: null,
    disabled: false,
    result: null,
    text: null
  });
  const [nextBtn, setNextBtn] = useState(false);
  const [count, setCount] = useState(0);

  const quizDate = quizData;
  const total = quizDate.length;
  const quiz = quizDate[quizState];

  const checkAnswer = () => {
    setNextBtn(true);

    if (quiz.correctAnswer === checked.selected) {
      setChecked({
        ...checked,
        correct: quiz.correctAnswer,
        disabled: true,
        result: 'correct',
        text: t('levelTest.rsText2')
      });
      setCount(addCount => addCount + 1);
    } else {
      setChecked({
        ...checked,
        correct: quiz.correctAnswer,
        incorrect: checked.selected,
        disabled: true,
        result: 'incorrect',
        text: t('levelTest.rsText1')
      });
    }
  }

  const showNextQuiz = () => {
    setNextBtn(false);
    setQuizState(newQuizState => newQuizState + 1);
    setChecked({
      ...checked,
      selected: null,
      correct: null,
      incorrect: null,
      disabled: false,
      result: null,
      text: null
    });
  }

  const handleClick = () => {
    history.push({
      pathname: '/level-test/check-level-result',
      state: {
        ...state,
        count,
        total
      },
    });
  };

  return (
    <>
      <Step activeIndex={2} />
      <Quiz.Wrapper>
        <QuizView
          quizState={quiz}
          checked={checked}
          setChecked={setChecked}
          total={total}
          t={t}
        />
        <Quiz.ButtonGroup className='right'>
          {nextBtn && (
            <Quiz.ShowResult className={checked.result}>
              {checked.text}
            </Quiz.ShowResult>
          )}
          {
            nextBtn && (quizState >= total - 1) ? '' : (
              <Button.QuizButton
                onClick={nextBtn ? showNextQuiz : checkAnswer}
              >
                {nextBtn ? t('levelTest.button3') : t('levelTest.button1')}
              </Button.QuizButton>
            )
          }
          <Button.QuizEndButton onClick={handleClick}>
            {
              nextBtn && (quizState >= total - 1) ? 'END' : t('levelTest.button2')
            }
          </Button.QuizEndButton>
        </Quiz.ButtonGroup>
      </Quiz.Wrapper>
    </>
  );
}

export default Index;

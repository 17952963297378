import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import Styled from "./index.style";

const VideoClassApplication = ({ 
  title, date, email, schedule, lesson
 }) => {
  const { t } = useTranslation("paymentSuccessPage2");

  return (
    <>
      <Styled.Container>
        <Styled.BlockWrap>
          <div className="H3">{t("Application.stitle")}</div>
          <Styled.TextBox>
            <Styled.TextItem>
              <Styled.Blue>{t("Application.label1")}</Styled.Blue>
              <p>{title}</p>
            </Styled.TextItem>
            <Styled.TextItem>
              <Styled.Blue>{t("Application.label2")}</Styled.Blue>
              <p>
                <Moment format="YYYY/MM/DD HH:mm">{date}</Moment>
              </p>
            </Styled.TextItem>
          </Styled.TextBox>
          <Styled.TextBox>
            <Styled.TextItem>
              <ul>
                <li>
                  <p className='gray appli'>{t("Application.label3")}</p>
                  <p className='pre'>{email}</p>
                </li>
                <li>
                  <p className='gray appli'>{t("Application.label4")}</p>
                  <p className='pre'>{schedule}</p>
                </li>
                <li>
                  <p className='gray appli'>{t("Application.label5")}</p>
                  <p className='pre'>{lesson}</p>
                </li>
              </ul>
            </Styled.TextItem>
          </Styled.TextBox>
        </Styled.BlockWrap>
      </Styled.Container>
    </>
  );
};

export default VideoClassApplication;

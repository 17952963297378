import React, { useContext, useEffect, useState } from 'react';
import StyledModalForm from 'component/form/ModalForm.style';
import StyledModal from 'component/Modal/styled/Modal.style';
import StyledCreateEmailAccount from './styled/CreateEmailAccount.style';
import { Link } from 'react-router-dom';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';
import styled from 'styled-components';
import { useAxios } from 'common/hook/useAxiosHook';
import userApi from 'api/user';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import DefaultTextModal from 'component/Modal/DefaultTextModal';
import { useTranslation } from 'react-i18next';

const TopDesc = styled.p`
  font-size: 1.6em;
  margin-bottom: 1em;
  text-align: center;
  padding: 0 0.5em;
`;

export default function FindPassword() {
  const { t } = useTranslation('findPwModal');
  let { handleModal } = React.useContext(ModalContext);
  const openLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false);
  };

  const [userID, setUserID] = useState();
  const [findPassword, fetchFindPassword] = useAxios(
    userApi.findPassword,
    [],
    true
  );
  const { loading, data: resultStatus, error } = findPassword;

  const onSubmit = (e) => {
    e.preventDefault();
    fetchFindPassword(userID);
  };

  useEffect(() => {
    if (resultStatus === 200) {
      handleModal(
        <DefaultTextModal
          title={t('afterEmail.title')}
          desc={t('afterEmail.desc')}
        />
      );
    }
  }, [resultStatus]);

  return (
    <>
      <StyledModal.H2>{t('title')}</StyledModal.H2>

      <StyledModalForm.FormWrapper onSubmit={onSubmit}>
        <TopDesc>{t('desc')}</TopDesc>

        {/* <StyledCreateEmailAccount.NameContainer>
          <StyledModalForm.Label htmlFor='name'>Name</StyledModalForm.Label>
          <StyledModalForm.Input
            type='text'
            id='name'
            placeholder='First name'
            required
          />
          <StyledModalForm.Input
            type='text'
            id='name'
            placeholder='Last name'
            required
          />
          <p>Name must be typed in English.</p>
        </StyledCreateEmailAccount.NameContainer> */}

        <StyledModalForm.InputContainer>
          <StyledModalForm.Label htmlFor='email'>
            {t('email')}
          </StyledModalForm.Label>
          <StyledModalForm.Input
            type='text'
            id='email'
            required
            placeholder={t('placeholder')}
            onChange={(e) => setUserID(e.target.value)}
          />
        </StyledModalForm.InputContainer>

        <StyledModalForm.InputContainer>
          <StyledCreateEmailAccount.BlueButton>
            {t('button')}
          </StyledCreateEmailAccount.BlueButton>

          {error && (
            <StyledModalForm.WarnText>
              {error.data.message}
            </StyledModalForm.WarnText>
          )}
        </StyledModalForm.InputContainer>

        <StyledCreateEmailAccount.Bottom.Login>
          {t('guidance')}{' '}
          <Link to='#none' onClick={openLoginModal}>
            {t('link')}
          </Link>
        </StyledCreateEmailAccount.Bottom.Login>

        <LoadingOverlay isLoading={loading} />
      </StyledModalForm.FormWrapper>
    </>
  );
}

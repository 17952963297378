import styled from "styled-components";
import StyledUtil from "common/styled/Util.style";
import Img from "component/atoms/Img";
import { Link } from "react-router-dom";

const StyledThemeItem = {
  Wrapper: styled.div`
    position: relative;
  `,
  Link: styled(Link)`
    display: inline-block;
    width: 100%;
    .img-box {
      display: inline-block;
      position: relative;
      padding-bottom: 100%;
      width: 100%;
      border-radius: 0.6rem;
      overflow: hidden;
      > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &:hover :last-child {
      text-decoration: underline;
    }
    > img {
      border-radius: 0.6rem;
    }
  `,
  Img: styled(Img)`
    width: 100%;
    margin-bottom: 1em;
    border-radius: 0.6rem;
  `,
  Title: styled.div`
    font-size: 2em;
    font-weight: 500;
    height: 3em;
    &:not(.clamp) {
      ${StyledUtil.textEllipsis}
    }
    &.clamp {
      ${StyledUtil.lineClamp}
    }
    @media (max-width: 767px) {
      font-size: 2.5em;
    }
  `,
  Subtitle: styled.div`
    ${StyledUtil.textEllipsis}
    font-size: 1.6em;
    color: #8e9199;
    @media (max-width: 767px) {
      font-size: 1.7em;
    }
  `,
  Info: styled.div`
    border-bottom: 1px solid #454545;
    margin-bottom: 1.5em;
    color: #8e9199;
    .tag-link {
      margin-bottom: 1em;
    }
    @media (max-width: 767px) {
      .tag-link {
        padding: 0 0.7em;
        max-width: 45%;
        ${StyledUtil.textEllipsis}
      }
    }

    &:after {
      content: "";
      display: block;
      clear: both;
    }
  `,
  InfoRight: styled.span`
    float: right;
    margin-top: 0.6em;
    span {
      margin-bottom: 1em;
    }
    span:not(:first-of-type) {
      margin-left: 1em;
    }
    @media (max-width: 767px) {
      margin-top: 0.2em;
    }
  `,
  User: styled.div`
    span {
      vertical-align: middle;
    }
  `,
  UserName: styled.span`
    display: inline-block;
    margin-left: 0.8em;
    font-size: 1.4em;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 1.7em;
    }
  `,
  InfoContainer: styled.div`
    padding: 1.5em 0;
    border-bottom: 1px solid #222;
    text-align: right;
    overflow: hidden;
    span {
      margin-top: 0.4rem;
      margin-bottom: 0.4rem;
    }
  `,
  Tag: styled.div`
    ${StyledUtil.textEllipsis}
    float: left;
    font-size: 14px;
    padding: 0.4rem 1.3em;
    max-width: 50%;
    border: 1px solid #8e9199;
    border-radius: 100px;
    color: #8e9199;
  `,
  UserContainer: styled.div`
    padding-top: 1.5em;
  `,
  UserName: styled.span`
    ${StyledUtil.textEllipsis}
    display: inline-block;
    font-size: 14px;
    max-width: calc(100% - 4em);
    margin-left: 1em;
    vertical-align: middle;
  `,
};

export default StyledThemeItem;

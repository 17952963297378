import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ViewContext } from 'container/CourseViewPage';
import { useParams } from 'react-router';

import next from 'assets/images/mycurriculum_new/video_next.png';
import prev from 'assets/images/mycurriculum_new/video_prev.png';
import IconBookmarkActive from 'assets/images/mycurriculum_new/icon_bookmark_active.png';
import IconBookmark from 'assets/images/mycurriculum_new/icon_bookmark.png';
import contentsApi from 'api/contents';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import bookmarksUnitApi from 'api/bookmarksUnit';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {toast} from "react-toastify";
import {asyncApi} from "../../../common/util/asyncApi";

const Container = styled.div`
  padding-bottom: 3em;
`;
const Title = styled.h3`
  font-size: 2.6em;
  font-weight: 500;
`;
const Desc = styled.p`
  font-size: 1.6em;
  margin-top: 0.3em;
  color: #8e9199;
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top:2rem;
  >div{
    flex:1;
    display: flex;;
    gap:1rem;
  }
`;
const BoxLeft = styled.div``;
const BoxRight = styled.div`
  justify-content: flex-end;
`;
const Button = styled.button`
  font-size:1.4rem;
  color:#ffffff;
  height: 3.5rem;
  padding:0 1.5rem;
  display: flex;
  align-items:center;
  justify-content: center;
  font-weight:500;
  border-radius: 0.6rem;
  gap:1rem;
  &.bookmark{
    background-color: #393b3e;;
  }
  &.prev{
    background-color: #2a2c2e;
  }
  &.next{
    background-color: #2a2c2e;
  }
  &.active{
    &.bookmark{
      color:#ffb500
    }
  }
  &:hover{
    &.prev{
    background-color: #485cc7;
  }
  &.next{
    background-color: #485cc7;
  }
  }
  @media (max-width: 1024px) {
    height:5rem;
  }

  @media (max-width: 767px) {
    height:5rem;
    gap:0;
    &.bookmark,
    &.prev,
    &.next{
      font-size:0;
    }
    }
`;
/**
 * 코스 플레이어 하단
 */
const VideoInfo = ({playerRef, postLogData, prevLectureId, nextLectureId}) => {
  const token = useSelector((state) => state.user.token);
  const { productId, lectureId } = useParams();
  const { lectureState } = useContext(ViewContext);
  const [{ data: lectureData }] = lectureState;

  const [lecture, setLecture] = useState(null);
  const [bookmarkActive, setBookmarkActive] = useState(false);

  const history = useHistory();

  const [{ data: videoLogData }, fetchVideoLog] = useAxios(
    contentsApi.updateLogApi,
    [],
    true
  );

  const [getBookmark,refreshGetBookmark] = useAxios(
      bookmarksUnitApi.getBookmarkUnit(productId, lectureId, token),
      [],
      true
  );



  useEffect(() => {
    //only get like && bookmark info when log in
    if (token) {
      refreshGetBookmark();
    }
  }, [token]);

  useEffect(() => {
    if (parseInt(lectureId) === 0) {
      setLecture({ title: 'Overview' });
      return;
    }

    if (!lectureData) return;
    setLecture(lectureData);
  }, [lectureData, lectureId]);

  useEffect(() => {
    if (getBookmark.data) {
      setBookmarkActive(getBookmark.data);
    }
  }, [getBookmark.data]);

  const clickCompleteButton = () => {
    if (parseInt(lectureId) === 0) return;

    postLogData &&
      fetchVideoLog(
        {
          id: postLogData.id,
          productId,
          chapterLectureId: lectureId,
          playPosition: playerRef.current?.getDuration() - 1,
        },
        token
      );
    moveNextLesson();
  };
/*
  const [updateBookmark,refreshUpdateBookmark] = useAxios(
      bookmarksUnitApi.updateBookmark(lectureId, token),
      [],
      true
  );

  const [deleteBookmark,refreshDeleteBookmark] = useAxios(
      bookmarksUnitApi.deleteBookmark(lectureId, token),
      [],
      true
  );

 */
  const clickBookmarkButton = async () => {
    if (parseInt(lectureId) === 0) {

    } else {

      if (bookmarkActive) {
        const response = await asyncApi({
          url: `/api/v1/bookmarkUnit/deleteBookmark/${productId}/${lectureId}`,
          headers: {Authorization: `Bearer ${token}`},
        }).then(
            //setBookmarkActive(response.data)
            setBookmarkActive(false)
        );
      } else {
        const response = await asyncApi({
          url: `/api/v1/bookmarkUnit/updateBookmark/${productId}/${lectureId}`,
          headers: {Authorization: `Bearer ${token}`},
        }).then(
            setBookmarkActive(true)
        );

      }
    }
  }
  const movePrevLesson = () => {
    if(prevLectureId !== 0){
      history.push(`/courses/detail/${productId}/${prevLectureId}`);
    }
  }

  const moveNextLesson = () => {
    if(nextLectureId !== 0){
      history.push(`/courses/detail/${productId}/${nextLectureId}`);
    }
  };

  return (
    lecture && (
      <Container>
        <Title>{lecture.title}</Title>
        <Desc>{lecture.description}</Desc>
        <ButtonWrap>
          <BoxLeft>
            <Button className={`bookmark ${bookmarkActive ? 'active' : ''}`} onClick={clickBookmarkButton}>
              <img src={bookmarkActive ? IconBookmarkActive : IconBookmark} />Bookmark
            </Button>
          </BoxLeft>
          <BoxRight>
            <Button className={`prev`} onClick={() => movePrevLesson()}><img src={prev} alt="Previous"/>Previous</Button>
            <Button className={`next`} onClick={() => clickCompleteButton()}>Complete & Next<img src={next} alt="Complete & Next"/></Button>
          </BoxRight>

        </ButtonWrap>
      </Container>
    )
  );
};

export default VideoInfo;


import styled from 'styled-components';
import imageFile from 'assets/images/temp/bg_teacher_01.png';

const StyledCourseList = {
  Container: styled.div`
    display: flex;
    margin-bottom: 3.7em;

    @media (max-width: 600px) {
      display: block;
    }
  `,
  Image: styled.div`
    flex: 0.25;
    position: relative;
    display: inline-block;
    width: 29.5em;
    height: 21em;
    margin-right: 5em;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 600px) {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-bottom: 3em;
    }
    @media (max-width: 767px) {
      margin-right: 2.5em;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  `,
  Content: styled.div`
    display: inline-block;
    flex: 0.8;
  `,
  FlexDiv: styled.div`
    display: flex;
    justify-content: space-between;
    &:first-of-type {
      margin-bottom: 4.5em;
    }
    @media (max-width: 767px) {
      &:first-of-type {
        margin-bottom: 2em;
      }
    }
  `,
  CourseTitle: styled.span`
    color: #c9cbd1;
    font-size: 1.8em;
    font-weight: 500;
  `,
  Date: styled.p`
    color: #5c5f66;
    font-size: 1.6em;
    font-weight: 400;
    @media (max-width: 767px) {
      &.period {
        display: none;
      }
    }
  `,
  LectureTitle: styled.h3`
    color: #fff;
    font-size: 2.2em;
    font-weight: 700;
  `,
  ProgressContainer: styled.div`
    position: relative;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #ffb500;
      font-size: 1.6em;
      font-weight: 700;
      transform: translate(-50%, -50%);
    }
    svg {
      width: 8.5em;
      height: 8.5em;
    }
    @media (max-width: 767px) {
      display: none;
    }
  `,
  LectureNo: styled.p`
    margin-top: 1em;
    text-align: center;
    color: #5c5f66;
    font-size: 1.4em;
    font-weight: 400;
    @media (max-width: 767px) {
      display: none;
    }
  `,

  LectureBtn: styled.button`
    display: inline-block;
    margin-top: 1.4em;
    padding: 0.7em 1.4em;
    border: 1px #8d9399 solid;
    border-radius: 0.2em;
    color: #d1d1d1;
    font-size: 1.4em;
    font-weight: 500;
  `,
};

export default StyledCourseList;

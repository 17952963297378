import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Style from '../styled/index.style';

import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';

import Search from 'container/HelpPage/components/Search';
import SearchResult from 'container/HelpPage/components/SearchResult';
import Breadcrumb from 'container/HelpPage/components/Breadcrumb';
import ContactUs from 'container/HelpPage/components/ContactUs';

import LnbCategory from 'container/HelpPage/Lnb/Category';
import List from 'container/HelpPage/List/ListContainer';
import Pagination from 'container/HelpPage/List/Pagination';
import { debounce, range } from 'lodash';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SearchEmpty from 'component/atoms/SearchEmpty';
import { useAxios } from 'common/hook/useAxiosHook';
import helpApi from 'api/help';

const HelpListPage = () => {
  const { t } = useTranslation('helpPage');

  const location = useLocation();
  const [isMobile, setisMobile] = useState(false);

  const [searchText, setSearchText] = useState("");

  const { category: categoryFromParams } = useParams();
  const [category, setCategory] = useState(categoryFromParams);

  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setPage] = useState(1);
  const [currentPageNumbers, setPageNumbers] = useState([]);

  const pageSize = 10;


  const [searchResultAmount, setResultAmount] = useState({
    all: 0,
    guide: 0,
    notice: 0,
    event: 0,
    study: 0
  });

  const [articles, setArticles] = useState([]);

  const [searchResults, searchArticles] = useAxios(
    helpApi.getSearchList,
    [],
    true
  );

  const { loading, data, end } = searchResults;

  const categoryName = (category === 'all') ? 'ALL'
    : (category === 'guide') ? 'Guide'
      : (category === 'notice') ? 'Notice'
        : (category === 'event') ? 'Event'
          : (category === 'study') ? 'Study' : 'ALL';

  const currentResultAmount = (category === 'all') ? searchResultAmount.all
    : (category === 'guide') ? searchResultAmount.guide
      : (category === 'notice') ? searchResultAmount.notice
        : (category === 'event') ? searchResultAmount.event
          : (category === 'study') ? searchResultAmount.study : 0;



  const debounceSearch = useCallback(
    debounce((searchText, category, currentPage, pageSize) => {
      searchArticles(searchText, category, currentPage, pageSize);
      console.log(`search about ${searchText} page ${currentPage} in category ${category}`);
    }, 200)
    ,[]
  );

  const createPages = () => {
    if (maxPage <= 5) {
      setPageNumbers(range(1, maxPage + 1, 1));
      return;
    }

    let start = currentPage;
    let end = currentPage;

    while (end - start < 4) {
      if (start > 1) start--;
      if (end < maxPage) end++;
    }

    setPageNumbers(range(start, end + 1, 1))
  };

  useEffect(() => {
    if (data) {
      if (data.pageTotal) {
        setMaxPage(data.pageTotal);
      }

      setResultAmount({
        all: data.countAll,
        guide: data.countGuide,
        notice: data.countNotice,
        event: data.countEvent,
        study: data.countStudy
      });

      let newList = data.docList;
      if (isMobile) {
        if (currentPage === 1) {
          setArticles(newList);
        } else {
          setArticles([...articles, ...newList]);
        }
      } else {
        setArticles(newList);
      }
    }
  }, [data]);


  useEffect(() => {
    if (location.state && searchText !== location.state.search) {
      setSearchText(location.state.search);
    }
    if (category !== categoryFromParams) {
      setCategory(categoryFromParams);
    }
  });

  useEffect(() => {
    debounceSearch(searchText, category, currentPage, pageSize);
    createPages();
  }, [currentPage]);

  useEffect(() => {
    createPages();
  }, [maxPage]);

  useEffect(() => {
    if (isMobile && currentPage > 1) {
      setPage(1);
    } else {
      debounceSearch(searchText, category, currentPage, pageSize);
    }
  }, [isMobile]);

  const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    }
    window.addEventListener("resize", resizingHandler);
    return () => {
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);

  useEffect(() => {
    if (currentPage > 1) {
      setPage(1);
    } else {
      debounceSearch(searchText, category, currentPage, pageSize);
    }
  }, [category, searchText]);

  return (
    <>
      <ContentsWrapper>
        <PageHeader title={t('title')} />

        {/* 화면 경로 */}
        <Breadcrumb categoryName={searchText !== "" ? "Search Results" : categoryName} />

        {/* 검색 */}
        <Search placeholder={t('search')} searchText={searchText} />
      </ContentsWrapper>

      <Style.DetailWrapper>
        <ContentsWrapper className='Contents'>

          {/* LNB 영역 */}
          <Style.DetailLnb>
            {/* 카테고리 */}
            <LnbCategory
              category={category}
              setCategory={setCategory}
              searchText={searchText}
              searchResult={searchResultAmount} />
          </Style.DetailLnb>

          {/* 컨텐츠 영역 */}
          <Style.DetailContent>
            {
              (articles.length > 0 ?
                <>
                  {/* 검색 결과 */}
                  <SearchResult
                    result={currentResultAmount}
                    search={searchText}
                    category={categoryName}
                  />
                  {/* 문서 목록 */}
                  <List list={articles} searchText={searchText} />

                  {/* 페이징 */}
                  <Pagination
                    t={t}
                    isMobile={isMobile}
                    currentPage={currentPage}
                    setPage={setPage}
                    btns={currentPageNumbers}
                    maxPage={maxPage}
                  />
                </>
                : data && data.result === "fail" && <SearchEmpty />)
            }
          </Style.DetailContent>

        </ContentsWrapper>
      </Style.DetailWrapper>

      <ContactUs />
    </>
  );
};
export default HelpListPage;


import React, { useEffect, useState } from 'react';
import Style from "../styled/Search.style";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Search = ({ placeholder, className, searchText }) => {
  const [searchInputText, setSearchInputText] = useState("");
  const history = useHistory();

  const removeText = () => {
    setSearchInputText("");
  };

  useEffect(() => {
    if (searchText) {
      setSearchInputText(searchText);
    }
  }, [searchText])

  const search = () => {
    history.push({
      pathname: `/help/all`,
      state: {
        search: searchInputText
      }
    });
  };

  const handleOnKeyPress = (e) => {
    if (e.key === 'Enter') {
      search();
    }
  };

  return (
    <Style.Container className={className}>
      <Style.InputContainer>
        <Style.Input placeholder={placeholder} value={searchInputText} onChange={(event) => setSearchInputText(event.target.value)} onKeyPress={handleOnKeyPress} />
        <Style.CloseImage style={searchInputText !== "" ? { visibility: "visible" } : { visibility: "hidden" }} onClick={() => removeText()} />
        <Style.SearchImage onClick={() => search()} />
      </Style.InputContainer>
    </Style.Container>
  );
};
export default Search;
import React, { useState, useEffect } from 'react';
import Style from './styled/PlanSection.style';
import CourseDetailList from './List';
import { BsArrowRightShort } from 'react-icons/bs';
import OverViewList from './OverViewList';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

const CourseListContainer = ({
  openLoginModal,
  data,
  overviewRef,
  loginStatus,
  totalClassNo,
  productId,
  totalHour,
}) => {
  const { t } = useTranslation('courseDetailPage');
  const { pathname: currentPath } = useLocation();
  const [listShowIndex, setListShowIndex] = useState(0);
  const [overViewPercentage, setOverViewPercentage] = useState(0);

  const currentVideoUrl = useSelector(
    (state) => state.courseDetail.currentVideoUrl?.provider
  );
  const openAccordion = (index) => {
    setListShowIndex((prev) => (prev === index ? -1 : index));
  };
  useEffect(() => {
    if (!loginStatus) {
      const getPercentage = setInterval(() => {
        if (overviewRef.current) {
          let percentage =
            (overviewRef.current.getCurrentTime() /
              overviewRef.current.getDuration()) *
            100;
          setOverViewPercentage(percentage);
        } else {
          setOverViewPercentage(0);
        }
      }, 1000);
      return () => clearInterval(getPercentage);
    }
  }, [currentVideoUrl]);

  return (
    <Style.ListContainer>
      {loginStatus && <h2>{t('coursePlan.title')}</h2>}
      <div className='title'>
        <h3>
          {loginStatus ? (
            <Trans t={t}>coursePlan.subtitle</Trans>
          ) : (
            <Trans t={t}>coursePlan.subtitle2</Trans>
          )}
        </h3>
        <span>
          {totalClassNo} classes, {totalHour}hr
        </span>
      </div>

      <OverViewList
        percentage={overViewPercentage}
        title='Lecture overview'
        currentVideoUrl={currentVideoUrl}
      />
      {data &&
        data.map((data, index) => {
          const {
            id,
            title,
            hour,
            minute,
            lessonCount,
            lessonResponseList,
          } = data;
          return (
            <CourseDetailList
              key={id}
              title={title}
              overviewLecture={!loginStatus && index === 0 && true}
              chapterHour={hour}
              chapterMinute={minute}
              lessonCount={lessonCount}
              lessonList={lessonResponseList}
              onClick={() => openAccordion(index)}
              show={listShowIndex === index && 'active'}
              percentage={!loginStatus && index === 0 && overViewPercentage}
            />
          );
        })}

      {!loginStatus && data.length > 5 && (
        <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
          {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
        </Style.MoreBtn>
      )}
    </Style.ListContainer>
  );
};

export default React.memo(CourseListContainer);

export const mockTestSampleData = [
  {
    id: 1,
    levelType: 11, //타입별 컬러 다름
    name: 'TOPIK 1',
    question: 'Q1. 무엇에 대한 내용입니까?',
    answer: 2,
    text: "저는 학생입니다.\n언니는 회사원입니다.",
    example: ["나이", "직업", "취미", "약속"],
    commentary: "'학생'과 '회사원'은 직업을 의미한다. "
  },
  {
    id: 2,
    levelType: 11, //타입별 컬러 다름
    name: 'TOPIK 1',
    question: 'Q2. 무엇에 대한 내용입니까?',
    answer: 4,
    text: "불고기가 있습니다.\n비빔밥도 있습니다.",
    example: ["건강", "날씨", "독서", "음식"],
    commentary: "'불고기'와 '비빔밥'은 음식을 나타낸다. "
  },
  {
    id: 3,
    levelType: 11, //타입별 컬러 다름
    name: 'TOPIK 1',
    question: 'Q3. 빈칸에 들어갈 말로 가장 알맞은 것을 고르십시오.',
    answer: 1,
    text: "()에 갔습니다.\n영화를 봤습니다.",
    example: ["극장", "은행", "학교", "공원"],
    commentary: "'영화를 보는 장소는 '극장'이다. "
  },
  {
    id: 4,
    levelType: 12, //타입별 컬러 다름
    name: 'TOPIK 2',
    question: 'Q4. () 에 들어갈 말로 가장 알맞은 것을 고르십시오',
    answer: 2,
    text: "운전을 하는데 졸려서\n사고가 ().",
    example: ["나지 않았어요", "날 뻔했어요", "나는 편이에요", "날 수 있어요"],
    commentary: "' 사고가 날 수 있었다는 말이므로 'V+뻔하다'를 사용한다. "
  },
  {
    id: 5,
    levelType: 12, //타입별 컬러 다름
    name: 'TOPIK 2',
    question: 'Q5. 밑줄 친 부분과 의미가 가장 비슷한 것을 고르십시오.',
    answer: 1,
    text: "이메일을 #확인하는 대로# \n연락을 주시면 감사하겠습니다.",
    example: ["확인하자마자", "확인할 정도로", "확인하기는커녕", "확인하는 데다가"],
    commentary: "이메일을 확인하면 바로 연락을 달라는 말이므로 '-자마자'를 사용한다."
  },
  {
    id: 6,
    levelType: 12, //타입별 컬러 다름
    name: 'TOPIK 2',
    question: 'Q6. 다음은 무엇에 대한 글인지 고르십시오',
    answer: 3,
    text: "여러분의 재능을 기다립니다.\n우리 이웃과 함께 해요!",
    example: ["직업 활동", "유학 생활", "봉사 활동", "문화 체험"],
    commentary: "‘재능’과 ‘이웃’이라는 표현은 봉사 활동과 관련이 있다."
  },
];
import React from 'react';
import styled from 'styled-components';
import Loading from 'component/atoms/Loading';

const Empty = styled.div`
  font-size: 2em;
  padding: 3em 0 4em;
  font-weight: 300;
  font-style: italic;
  text-align: center;
  color: #8e9199;
`;

export const EmptyList = ({ children, isLoading=false }) => {
  return (
    <>
      {
        isLoading ? <Loading /> : <Empty>{children}</Empty>
      }
    </>
  );
};

EmptyList.defaultProps = {
  children: 'The list is empty.',
};

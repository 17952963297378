import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import arrowRightIcon from 'assets/images/arrow_right.svg';

const ReviewsStyled = {
  Wrapper: styled.div`
    padding-top: 16em;
    padding-bottom: 27em;
    background-color: #393c40;
    text-align: center;
    @media (max-width: 767px) {
      padding-top: 12em;
      padding-bottom: 14em;
    }
  `,
  Inner: styled(ContentsWrapper)`
    @media (max-width: 767px) {
      max-width: 500px;
      margin: auto;
    }
  `,
  H2: styled.h2`
    font-size: 7em;
    font-weight: bold;
    margin-bottom: 1.3em;
    span {
      display: inline-block;
    }
    @media (max-width: 767px) {
      margin-bottom: 0.5em;
    }
    @media (max-width: 375px) {
      font-size: 6em;
      line-height: 1.3;
    }
  `,
  Swiper: styled(Swiper)`
    position: relative;
    /* max-width: 1180px; */
    margin: auto;
    overflow: visible;

    .swiper-pagination {
      position: absolute;
      bottom: -10rem;
      left: 0;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        width: 6px;
        height: 6px;
        margin: 0 5px;
        background-color: #fff;
        border-radius: 10px;
        opacity: 0.5;
        transition: all 0.6s ease;
      }
      .swiper-pagination-bullet-active {
        width: 39px;
        opacity: 1;
      }
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: -5em;
      z-index: 2;
      width: 100vw;
      height: calc(100% + 10em);
      background-color: #393c40;
    }
    &:before {
      left: 100%;
    }
    &:after {
      right: 100%;
    }

    @media (min-width: 1025px) {
      .swiper-button-prev,
      .swiper-button-next {
        position: absolute;
        top: 50%;
        z-index: 10;
        width: 6.8rem;
        height: 6.8rem;
        margin-top: -3.4rem;
        border-radius: 7px;
        background-color: rgba(92, 95, 102, 0.3);
        cursor: pointer;
        transform: rotate(45deg);
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
        }
        &:after {
          background: url(${arrowRightIcon}) no-repeat center;
          background-size: 1.6rem;
        }
      }
      .swiper-button-prev {
        left: -20rem;
        &:after {
          transform: rotate(135deg);
        }
      }
      .swiper-button-next {
        right: -20rem;
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
    @media (max-width: 375px) {
      .swiper-pagination {
        bottom: -8em;
        span {
          width: 4px;
          height: 4px;
          margin: 0 3px;
        }
        .swiper-pagination-bullet-active {
          width: 22px;
        }
      }
    }
  `,
  ItemContainer: styled.div`
    display: table;
    width: 100%;
    table-layout: fixed;
    > * {
      display: table-cell;
      vertical-align: middle;
    }
    @media (max-width: 767px) {
      display: block;
      > * {
        display: block;
        width: auto;
        text-align: center;
      }
    }
  `,
  Texts: styled.div`
    padding-bottom: 2em;
    font-size: 2em;
    text-align: left;
    @media (max-width: 767px) {
      width: auto;
      padding: 2em;
    }
  `,
  ImgWrapper: styled.div`
    width: 40%;
    text-align: right;
    @media (max-width: 767px) {
      width: auto;
      text-align: center;
    }
  `,
  H3: styled.h3`
    font-size: 1.7em;
    margin-bottom: 1em;
    font-weight: 500;
    color: #ffb500;
    @media (max-width: 767px) {
      margin-bottom: 0.8em;
    }
  `,
  Desc: styled.div`
    margin-bottom: 2em;
    @media (max-width: 767px) {
      font-size: 1.2em;
    }
    @media (max-width: 375px) {
      margin-bottom: 0;
    }
  `,
  Author: styled.div`
    font-weight: 500;
    color: #fff;
    span {
      margin-left: 0.5em;
      font-size: 0.8em;
      font-weight: 300;
      color: #ccc;
    }
    @media (max-width: 375px) {
      display: none;
    }
  `,
};

export default ReviewsStyled;

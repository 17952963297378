import React, { useEffect, useState } from 'react';
import Styled from './index.style';
import { useAxios } from 'common/hook/useAxiosHook';
import useErrorToastify from 'common/hook/useErrorToastify';
import PageHeader from 'component/atoms/PageHeader';
import Loading from 'component/atoms/Loading';
import TeacherCard from './TeacherCard';
import TeacherDetail from './TeacherDetail';
import teachersApi from 'api/teacher';
import { useParams } from 'react-router-dom';

import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';

const Teachers = () => {
  const { t } = useTranslation('teachersPage');
  const [teacherList] = useAxios(teachersApi.getAllList);
  const { loading, data, error } = teacherList;

  const currentVideoState = useState();
  const [, setCurrentVideo] = currentVideoState;

  const activeState = useState(null);
  const [activeId, setActiveId] = activeState;

  // 각 강사별 첫번째 강의영상 목록 (자동재생 용도)
  useEffect(() => {
    if (data) {
      let initVideoList = {};
      data.map(
        ({ id, lessons }) =>
          (initVideoList[id] = lessons.length > 0 ? lessons[0].movUrl : null)
      );
      setCurrentVideo(initVideoList);
    }
  }, [data, setCurrentVideo]);

  // 강사 id가 pathname에 있을 경우
  const { id: teacherId } = useParams();
  const [paramId, setParamId] = useState(null);
  useEffect(() => {
    if (data) {
      const parsingId = parseInt(teacherId);
      if (!isNaN(parsingId) && data.some(({ id }) => parsingId === id)) {
        setParamId(parsingId);
        setTimeout(() => setActiveId(parsingId), 100);
      }
    }
  }, [data, teacherId]);

  useErrorToastify(error);

  return (
    <Styled.Wrapper>
      <SeoHelmet title={SeoData.teachers.title} desc={SeoData.teachers.desc} />
      <PageHeader title={t('title')} text={t('subtitle')} />

      {loading && <Loading />}

      <Styled.Ul>
        {data &&
          data.map((item, index) => (
            <Styled.Li key={index} className={activeId === item.id && 'active'}>
              <TeacherCard
                item={item}
                activeState={activeState}
                paramId={paramId}
              />
              {activeId === item.id && (
                <TeacherDetail
                  item={item}
                  setActiveId={setActiveId}
                  currentVideoState={currentVideoState}
                  t={t}
                />
              )}
            </Styled.Li>
          ))}
      </Styled.Ul>
    </Styled.Wrapper>
  );
};

export default Teachers;

import React from 'react';
import Styled from './index.style';

import PlayToggleOverlay from './PlayToggleOverlay';
import ProgressBar from './ProgressBar';
import PlayingToggle from './PlayingToggle';
import VolumeControl from './VolumeControl';
import Timer from './Timer';
import VideoQuality from './VideoQuality';
import PlaybackRate from './PlaybackRate';
import FullScreenToggle from './FullScreenToggle';

const PlayerController = () => {
  return (
    <Styled.Wrapper className='player-controller-wrapper desktop'>
      <PlayToggleOverlay />

      <Styled.Container className='player-controller'>
        <ProgressBar />

        <Styled.LeftSide>
          <PlayingToggle className='playing-button' />

          <VolumeControl className='volume-control' />

          <Timer className='player-timer' />
        </Styled.LeftSide>

        <Styled.RightSide>
          {/*<VideoQuality className='video-quality' />*/}

          <PlaybackRate className='playback-rate' />

          <FullScreenToggle className='fullscreen-button' />
        </Styled.RightSide>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default PlayerController;

import React, { useEffect, useState } from 'react';
import Loading from 'component/atoms/Loading';
import Error from 'component/Error';
import { EmptyList } from 'component/atoms/EmptyList';
import Styled from 'container/UserHomePage/styled/ServiceContents.style';
import Board from 'container/UserHomePage/styled/HotTopic.style';
import { Link } from 'react-router-dom';
import BoardReply from 'component/molecules/BoardReply';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

export default function Index({ initialState }) {
  const { t } = useTranslation('userHomePage');

  const [hotTopic, fetchHotTopicList] = initialState;
  const { loading, data, error } = hotTopic;
  const boardItem = data?.content && data.content;

  useEffect(() => {
    fetchHotTopicList(0, 5);
  }, []);

  if (error) return <Error error={error} />;

  return (
    <>
      <Styled.H2>{t('topicTable.title')}</Styled.H2>

      <Board.Wrapper>
        <Board.Header>
          <Board.Column className='post-title'>
            {t('topicTable.thead1')}
          </Board.Column>
          <Board.Column className='replier'></Board.Column>
          <Board.Column className='view-count'>
            {t('topicTable.thead2')}
          </Board.Column>
          <Board.Column className='replies-count'>
            {t('topicTable.thead3')}
          </Board.Column>
          <Board.Column className='reg-date'>
            {t('topicTable.thead4')}
          </Board.Column>
        </Board.Header>
        <div>
          {data === null ? (
            <EmptyList isLoading={loading} />
          ) : (
            boardItem.map((item, index) => (
              <Board.Item key={index}>
                <Link to={`/community/${item.id}`}>
                  <Board.Column className='post-title'>
                    {item.title}
                    {/* <div className='post-title-desc'>
                      {item.categoryCode}
                      {item.categoryCode && item.productTitle && ' | '}
                      {item.productTitle}
                    </div> */}
                  </Board.Column>
                  <Board.Column className='replier'>
                    <BoardReply replierList={item.replierList} />
                  </Board.Column>
                  <Board.Column className='view-count'>
                    {item.viewCount}
                  </Board.Column>
                  <Board.Column className='replies-count'>
                    {item.repliesCount}
                  </Board.Column>
                  <Board.Column className='reg-date'>
                    <Moment fromNow ago>
                      {item.regDate}
                    </Moment>
                  </Board.Column>
                </Link>
              </Board.Item>
            ))
          )}
        </div>
      </Board.Wrapper>
    </>
  );
}

import styled from 'styled-components';
import personIcon from 'assets/images/ico_person.png';
import dollarIcon from 'assets/images/ico_dollar.png';

const StyleCancelSection = {
  Container: styled.div`
    margin-bottom: 18em;
    padding: 5em 5em 5.7em 5em;
    border-radius: 0.6em;
    background-color: #1e2024;
    &:first-of-type {
      margin-right: 3em;
      @media (max-width: 767px) {
        padding: 5em;
        margin-right: 0;
        margin-bottom: 5em;
      }
    }
  `,

  ImageSpan: styled.span`
    display: inline-block;
    width: 7em;
    height: 7.8em;
    margin-bottom: 2.5em;
    &.cancel {
      background: url(${personIcon}) no-repeat center center/cover;
    }
    &.plan {
      background: url(${dollarIcon}) no-repeat center center/cover;
    }
  `,
  Title: styled.h3`
    margin-bottom: 1.35em;
    color: #fff;
    font-size: 3.4em;
    font-weight: 700;
  `,
  Desc: styled.p`
    margin-bottom: 1.66em;
    color: #fff;
    font-size: 1.8em;
    font-weight: 400;
  `,
  List: styled.p`
    position: relative;
    margin-bottom: 1.56em;
    padding-left: 0.8em;
    color: #d1d1d1;
    font-size: 1.6em;
    font-weight: 300;
    &::before {
      content: '';
      position: absolute;
      width: 3px;
      height: 3px;
      top: 50%;
      left: 0;
      background-color: #d1d1d1;
      transform: translateY(-50%);
    }
    > span {
      display: inline-block;
      margin-left: 0.3em;
      color: #fff;
      font-weight: 500;
    }
    @media (max-width: 767px) {
      font-size: 2em;
    }
  `,

  Button: styled.button`
    display: block;
    width: 65%;
    margin: 0.625em auto;
    padding: 1.25em 1em;
    border-radius: 0.375em;
    background-color: transparent;
    border: 1px #5c5f66 solid;
    color: #5c5f66;
    font-size: 1.6em;
    font-weight: 700;
    &.cancel {
      margin-top: 6.875em;
      border: none;
      background-color: #485cc7;
      color: #fff;
    }
    &.plan {
      margin-top: 8.3em;
      border: none;
      background-color: #ffb500;
      color: #fff;
    }
    @media (max-width: 767px) {
      width: 100%;
      font-size: 2em;
    }
  `,
};

export default StyleCancelSection;

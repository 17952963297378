import React from 'react';
import Styled from './index.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import ButtonLink, { ButtonTheme } from "component/atoms/ButtonLink";
import { ThemeProvider } from "styled-components";
import { Trans } from 'react-i18next';
import landingBanner from "api/landingPage";
import { useAxios } from "common/hook/useAxiosHook";

export default function ResearchMain({ t }) {
  const [{ data: updateClickedResult }, updateClicked] = useAxios(
    landingBanner.updateResearchButtonClicked,
    [],
    true
  );

  const clickBtn = (type) => {
    updateClicked(type);
  }

  return (
    <Styled.Wrapper>
      <ContentsWrapper>
        <Styled.H2><Trans t={t}>research.title</Trans></Styled.H2>
        <Styled.ButtonContainer>
          <ThemeProvider theme={ButtonTheme.yellow}>
            <ButtonLink to="/courses" onClick={()=>clickBtn('course')}>{t('research.button')}</ButtonLink>
            <ButtonLink to="/courses/topik-mock-test" onClick={()=>clickBtn('alive')}>{t('research.button2')}</ButtonLink>
          </ThemeProvider>
        </Styled.ButtonContainer>
      </ContentsWrapper>
    </Styled.Wrapper>
  );
}

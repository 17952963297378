import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Style from "./styled/index.style";
import { RiShareBoxLine } from "react-icons/ri";
import checkImg from "assets/images/ico_check.png";
import { MOBILE_APP_USER_AGENT } from "common/constant";

const FixedList = ({ data, onclick }) => {
  const { hash: hashId } = useLocation();
  const listRef = useRef();
  const [open, setOpen] = useState(false);
  const { id, regDate, read, title, content, linkUrl } = data;
  const history = useHistory();

  const openNotification = async () => {
    setOpen((prev) => !prev);
    if (!read) {
      await onclick(id);
    }
  };

  const onClick = (e, url) => {
    const userAgent = window.navigator.userAgent;

    e.preventDefault();
    if (!url) {
      alert("url is null");
      return;
    }
    if (userAgent !== MOBILE_APP_USER_AGENT) {
      if (url?.includes("http") || url?.includes("https")) {
        window.open(url);
      } else {
        window.open(`https://${url}`);
      }
    } else if (userAgent === MOBILE_APP_USER_AGENT) {
      const matchesExternalUrl =
        window.location.origin.split("/")[2] !== url.split("/")[2];
      if (!matchesExternalUrl) {
        const customPathname = url.split("mastertopik.com")[1];
        history.push(`${customPathname}`);
        return;
      } else if (matchesExternalUrl) {
        window?.WebInterface?.movetoAndroidBrowser(matchesExternalUrl, url);
        return;
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (hashId && hashId === `#notice-${id}`) {
      const headerH = document.querySelector("header").getBoundingClientRect()
        .height;
      const listTop = listRef.current.getBoundingClientRect().top;
      const scrolledTop = listTop + window.pageYOffset - headerH;
      window.scrollTo(0, scrolledTop);
      setTimeout(() => openNotification(), 100);
    }
  }, [id, hashId]);

  return (
    <Style.List ref={listRef}>
      <div
        className="notification-heading"
        onClick={openNotification}
        id={`notice-${id}`}
      >
        <div className={`notification-content${read ? "" : " unread"}`}>
          <p>{title}</p>
          <span>{regDate.split("T")[0]}</span>
        </div>
        <span>
          <Style.DropDownArrow size="3em" open={open} />
        </span>
      </div>
      <div className={open ? "dropdown-content open" : "dropdown-content"}>
        {content}
        <br />
        {linkUrl && (
          <button
            className="link"
            // to="#none"
            onClick={(e) => onClick(e, linkUrl)}
          >
            Link <RiShareBoxLine size="1.3em" />
          </button>
        )}
      </div>
    </Style.List>
  );
};

export default React.memo(FixedList);

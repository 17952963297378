import styled from 'styled-components';
import { VscClose } from 'react-icons/vsc';

export const StyledTeacherDetail = {
  Wrapper: styled.div`
    position: absolute;
    top: calc(100% + 6em);
    z-index: 9;
    width: 1300px;
    height: 63em;
    opacity: 0;
    overflow: hidden;

    animation-name: fade-in;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    @media (max-width: 1340px) {
      width: calc(100vw - 40px);
    }
    @media (max-width: 1024px) {
      height: calc(50vw + 500px);
    }
    @media (max-width: 767px) {
      position: relative;
      height: auto;
      width: auto;
      margin-top: 5em;
      overflow: visible;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: -1;
        width: 100vw;
        height: 100%;
        margin-left: -50vw;
        background-color: #2b2e33;
      }
    }
  `,
  Inner: styled.div`
    height: 100%;
    padding: 9em 0;
    color: #fff;
    overflow: auto;
    @media (min-width: 768px) and (max-width: 1024px) {
      padding-bottom: 0;
    }
  `,
  Player: styled.div`
    float: left;
    width: calc(50% - 2em);
    height: 100%;
    border-radius: 0.8rem;
    overflow: hidden;
    > div {
      // player 상위에 div가 고정픽셀로 들어가고 있어 퍼센트를 강제로 지정
      width: 100% !important;
      height: 100% !important;
    }
    @media (max-width: 1024px) {
      float: none;
      width: auto;
      height: auto;
      margin-bottom: 5em;
    }
  `,
  Container: styled.div`
    float: right;
    width: calc(50% - 2em);

    @media (max-width: 1024px) {
      float: none;
      width: auto;
    }
  `,
  GoToLibrary: styled.div`
    font-size: 2em;
    margin-bottom: 0.5em;
    font-weight: 400;
  `,
  Close: styled(VscClose)`
    position: absolute;
    top: 0.5em;
    right: 0;
    color: #8b8f99;
    font-size: 4em;
    cursor: pointer;
  `,
};

export default StyledTeacherDetail;

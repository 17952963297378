import React, { useEffect } from 'react';
import Header from 'container/MockTestPage/TestPage/Header';
import Content from 'container/MockTestPage/TestPage/Content';

const TestPage = () => {
  useEffect(() => {
    //새창 나가면 parent 창 refresh

    window.onunload = refreshParent;
    function refreshParent() {
      window.opener.location.reload();
    }
  }, []);

  return (
    <>
      <Header />
      <Content />
    </>
  );
};

export default TestPage;

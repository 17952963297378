import axios from 'axios';
import { GetLanguage } from 'common/util/GetLanguage';
import { API_HOST } from '../constant';

/**
 *
 * @param {object} param
 * @param {'get' | 'post' =} param.method
 * @param {string} param.url
 * @param {object=} param.params
 * @param {object=} param.data
 * @param {object=} param.headers
 */
export const asyncApi = async ({
  method = 'get',
  url,
  params,
  data,
  headers,
  responseType,
}) => {
  const response = await axios({
    url,
    method,
    baseURL: API_HOST,
    params,
    data,
    headers,
    withCredentials: true,
    responseType,
  });
  return response;
};

axios.interceptors.request.use(function (config) {
  const language = localStorage.getItem('i18nextLng');
  const { code } = new GetLanguage(language);

  if (!code) return config;

  config.headers['Accept-Language'] = code;
  return config;
});

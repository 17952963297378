import React from 'react';
import { StyledLibrarySearch } from './styled/libraryBadge.style';
import { ThemeProvider } from 'styled-components';
import { BadgeTheme } from 'component/user/Badge';

const LibraryBadge = ({ children, active, onClick }) => {
  return (
    <ThemeProvider theme={BadgeTheme.primary}>
      <StyledLibrarySearch.Badge onClick={() => onClick()} className={active}>
        {children}
      </StyledLibrarySearch.Badge>
    </ThemeProvider>
  );
};

export default LibraryBadge;

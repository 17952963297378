import React, { useState, useEffect } from "react";
import ContentsWrapper from "component/atoms/ContentsWrapper";
import { useHistory, useLocation } from "react-router-dom";
import { useAxios } from "common/hook/useAxiosHook";
import { useSelector } from "react-redux";
import Style from "./styled/index.style";
import { useTranslation } from "react-i18next";
import plansApi from "api/plans";
import { useCallback } from "react";

const options = [
  { code: 0, desc: "콘텐츠가 불만족스러워서 (품질, 신규 콘텐츠 부족 등)" },
  { code: 1, desc: "영상이 불만족스러워서 (속도 느림, 화질 저하 등)" },
  { code: 2, desc: "서비스가 불만족스러워서 (잦은 에러, 사용성 불편 등)" },
  { code: 3, desc: "멤버쉽 요금이 비싸서" },
  { code: 4, desc: "무료·할인 이벤트 기간이 끝나서" },
  { code: 5, desc: "다른 서비스 이용으로 필요가 없어서" },
  { code: 6, desc: "시청할 시간이 없어서" },
  { code: 99, desc: "기타" },
];

const CancelSurveyPage = () => {
  const location = useLocation();
  const history = useHistory();

  //flow 되로 들어가지않으면 페이지 막음
  if (!location.state) history.goBack();

  const { t } = useTranslation("cancelMembershipPage");
  const [checkedIdx, setCheckedIdx] = useState(null);
  const [reason, setReason] = useState("");
  const [disabled, setDisabled] = useState(false);
  const token = useSelector((state) => state.user.token);

  const [{ data }, updateFeedback] = useAxios(
    plansApi.updateCancelFeedback,
    [],
    true
  );

  const writeReason = (e) => {
    setReason(e.target.value);
  };

  const submit = useCallback(() => {
    if (checkedIdx === null) return;
    if (!disabled) {
      const data = {
        leaveReasonCode: options[checkedIdx].code,
        leaveReason: reason,
      };
      setDisabled(true);
      updateFeedback({ token, data });
    }
  }, [disabled, checkedIdx]);

  useEffect(() => {
    if (!data) return;

    if (data.status === 200) {
      history.replace("/setting");
    }
  }, [data, disabled]);

  return (
    <ContentsWrapper>
      <Style.Container>
        <div className="imageContainer">
          <Style.Image></Style.Image>
        </div>
        <Style.Title>{t("title")}</Style.Title>
        <Style.Desc>
          {t("desc")}
          <span>({t("notice")})</span>
        </Style.Desc>
        <Style.Content>
          <Style.CheckBoxContainer>
            {options.map((option, index) => {
              if (option.code !== 99) {
                return (
                  <div className="checkbox" key={index}>
                    <Style.InputCheck
                      type="button"
                      id="survey"
                      checked={checkedIdx === index ? true : false}
                      readOnly
                    />
                    <label
                      onClick={() => setCheckedIdx(index)}
                      htmlFor="survey"
                    >
                      {option.desc}
                    </label>
                  </div>
                );
              } else {
                return (
                  <div className="checkbox" key={index}>
                    <Style.InputCheck
                      type="checkbox"
                      id="survey"
                      checked={checkedIdx === index ? true : false}
                      readOnly
                    />
                    <label
                      htmlFor="survey-8"
                      onClick={() => setCheckedIdx(index)}
                    >
                      {option.desc}{" "}
                    </label>
                    <input
                      className="etc"
                      type="text"
                      placeholder="(이유를 직접 입력해주세요.) "
                      disabled={checkedIdx !== index ? true : false}
                      onChange={(e) => writeReason(e)}
                    />
                  </div>
                );
              }
            })}
          </Style.CheckBoxContainer>
        </Style.Content>
        <Style.BtnText>{t("endingWord")}</Style.BtnText>
        <Style.Button onClick={() => submit()}>{t("button")}</Style.Button>
      </Style.Container>
    </ContentsWrapper>
  );
};
export default CancelSurveyPage;

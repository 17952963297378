import React from 'react';
import Style from '../styled/Breadcrumb.style';
import { Link } from 'react-router-dom';
import { lowerCase } from 'lodash';

const Breadcrumb = (props) => {
  return (
    <Style.Breadcrumb>
      <ul>
        <li><Link to='/help'>Help</Link></li>
        <li><Link to={`/help/${lowerCase(props.categoryName)}`}>{props.categoryName}</Link></li>
      </ul>
    </Style.Breadcrumb>
  );
};
export default Breadcrumb;


import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useAxios } from 'common/hook/useAxiosHook';
import myLevelApi from 'api/myLevel';
import checkState from 'container/LevelTestPage/common/checkState';
import LevelTestHeader from 'container/LevelTestPage/common/LevelTestHeader';
import Step from 'container/LevelTestPage/common/Step';
import { Layout, Button } from 'container/LevelTestPage/styled';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const { t } = useTranslation('levelTestPage');

  checkState();

  const levelData = [
    {
      id: 0,
      level: 'LV.0',
      step: 'STEP 0',
      desc: t('selectTarget.level0')
    },
    {
      id: 1,
      level: 'LV.1',
      step: 'STEP 1',
      desc: t('selectTarget.level1')
    },
    {
      id: 2,
      level: 'LV.2',
      step: 'STEP 2',
      desc: t('selectTarget.level2')
    },
    {
      id: 3,
      level: 'LV.3',
      step: 'STEP 3',
      desc: t('selectTarget.level3')
    },
    {
      id: 4,
      level: 'LV.4',
      step: 'STEP 4',
      desc: t('selectTarget.level4')
    },
    {
      id: 5,
      level: 'LV.5',
      step: 'STEP 5',
      desc: t('selectTarget.level5')
    },
    {
      id: 6,
      level: 'LV.6',
      step: 'STEP 6',
      desc: t('selectTarget.level6')
    },
  ];

  const history = useHistory();
  const { state } = useLocation();
  const [, fetchLevelSave] = useAxios(myLevelApi.postLevelTestData, [], true);

  const [checked, setChecked] = useState({
    id: null,
    step: null,
    desc: null,
  });

  const selectedTargetLevelKo = {
    0: [1],
    1: [2, 3, 4],
    2: [3, 4],
    3: [4, 5, 6],
    4: [5, 6],
    5: [6],
    6: [6],
  };
  const selectedTargetLevelTopik = {
    0: [1, 2, 3],
    1: [2, 3, 4],
    2: [3, 4],
    3: [4, 5, 6],
    4: [5, 6],
    5: [6],
    6: [6],
  };

  let token = '';
  let languageCode = '';
  let languageLevel = '';
  let subjectCode = '';

  if (state) {
    token = state.token;
    languageCode = state.languageCode;
    languageLevel = state.languageLevel;
    subjectCode = state.subjectCode;
  }

  const selectedLevel =
    subjectCode === 1
      ? selectedTargetLevelKo[languageLevel]
      : selectedTargetLevelTopik[languageLevel];

  const handleClick = () => {
    if (checked.id === null) return;
    else {
      fetchLevelSave({
        token,
        languageCode,
        languageLevel,
        targetLevel: checked.id,
        subjectCode,
      });
      history.push({
        pathname: '/level-test/check-recommendation',
        state: {
          ...state,
          languageLevel: languageLevel,
          targetLevel: checked.id,
        },
      });
    }
  };

  return (
    <>
      <Step activeIndex={3} />
      <LevelTestHeader title={t('selectTarget.title')} />
      <Button.SelectedLevelGroup>
        <Button.SelectedLevel disabled='disabled' className='disabled'>
          <span>MT LOGO</span>
        </Button.SelectedLevel>
        {levelData.map((selectedEle) => {
          const { id, level, step, desc } = selectedEle;
          const disabled = state && !selectedLevel.includes(id) && 'disabled';
          return (
            <Button.SelectedLevel
              key={id}
              className={`${disabled} ${checked.id === id ? 'active' : ''}`}
              disabled={disabled}
              onClick={() => setChecked({ ...checked, id, step, desc })}
            >
              {level}
            </Button.SelectedLevel>
          );
        })}
      </Button.SelectedLevelGroup>

      <Layout.SelectedLevelTxt>
        {checked.step ? (
          <>
            <span>{checked.step}</span>
            {checked.desc}
          </>
        ) : (
          ''
        )}
      </Layout.SelectedLevelTxt>

      <Button.ButtonGroup className='center'>
        <Button.Button onClick={handleClick}>{t('selectTarget.button')}</Button.Button>
        <Button.ReturnLink to='/level-test'>
          {t('goBack')}
        </Button.ReturnLink>
      </Button.ButtonGroup>
    </>
  );
};

export default Index;

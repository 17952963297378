import styled from 'styled-components';
import Img from 'component/atoms/Img';
import Play from 'assets/images/ico_play.png';
import PlaySmall from 'assets/images/ico_play_small.png';

const StyledCourseCard = {
  Card: styled.div``,
  ImageContainer: styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 1em;
    border-radius: 0.6rem;
    overflow: hidden;
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-image: url(${Play});
      background-repeat: no-repeat;
      background-position: center;
      background-color: rgba(0, 0, 0, 0.4);
      background-size: 19px;
    }
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: rgba(0, 0, 0, 0.4);
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: ${({ percentage }) => (percentage ? percentage : '0')};
      height: 4px;
      border-radius: 6px;
      background-color: #ffb500;
      z-index: 100;
    }
    @media (min-width: 1025px) {
      .overlay {
        background-image: url(${PlaySmall});
      }
    }
  `,
  Image: styled(Img).attrs({
    alt: 'Course Thumbnail',
  })`
    width: 100%;
  `,
  Title: styled.h3`
    overflow: hidden;
    height: 3em;
    margin-bottom: 0.5em;
    font-size: 2em;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @supports (-webkit-line-clamp: 2) {
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    @media (max-width: 767px) {
      font-size: 2.2em;
    }
  `,
  Desc: styled.div`
    font-size: 1.6em;
    color: #aaa;
    @media (max-width: 767px) {
      font-size: 2em;
    }
  `,
};

export default StyledCourseCard;

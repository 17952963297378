import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Styled from 'component/molecules/CourseSwiperList/index.style';
import VideoItem from 'component/molecules/VideoItem';
import { masterTopikUrl } from 'common/constant';

import SwiperCore, { Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import SliderNav from 'component/atoms/SliderNav/SliderNav.style';

SwiperCore.use([Navigation]);

const swiperBreakPoint = {
  1280: { slidesPerView: 3, spaceBetween: 40, slidesPerGroup: 3 },
  1025: { slidesPerView: 3, spaceBetween: 20, slidesPerGroup: 3 },
  600: { slidesPerView: 3.1, spaceBetween: 10, slidesPerGroup: 2 },
  0: { slidesPerView: 2.05, spaceBetween: 10, slidesPerGroup: 2 },
};

/**
 * @param {*} param
 * @param {array} param.slideItemList 강좌 목록
 * @param {string | number} param.courseId 코스 name
 * @param {function} param.onNext next page event
 * @param {string=} param.className 'container' div class
 * @returns
 */
export default function CourseSwiperList({
  slideItemList,
  courseId,
  onNext,
  className,
}) {
  const [isNextChange, setIsNextChange] = useState(false);

  useEffect(() => {
    isNextChange && onNext();
  }, [isNextChange]);

  return (
    <Styled.Container className={className}>
      <SliderNav.Prev
        className={`slider-prev prev${courseId} ${
          slideItemList.length > 3 && 'visible'
        }`}
      />
      <SliderNav.Next
        className={`slider-next next${courseId} ${
          slideItemList.length > 3 && 'visible'
        }`}
      />
      <Styled.Swiper
        breakpoints={swiperBreakPoint}
        slidesPerView={3}
        navigation={{
          nextEl: `.next${courseId}`,
          prevEl: `.prev${courseId}`,
        }}
        onSlideNextTransitionStart={() => setIsNextChange(true)}
        onSlideNextTransitionEnd={() => setIsNextChange(false)}
      >
        {slideItemList.map(
          (
            {
              title,
              classCount,
              thumbnailPath,
              hour,
              productId,
              chapterLectureId,
              progressPercent,
              isNew,
            },
            index
          ) => (
            <SwiperSlide key={index}>
              <VideoItem
                title={title}
                imgSrc={masterTopikUrl + thumbnailPath}
                desc={`${classCount} classes, ${hour} hours`}
                to={`/courses/detail/${productId}`}
                videoLink={`/courses/detail/${productId}/${chapterLectureId}`}
                percentage={progressPercent}
                idNew={isNew}
              />
            </SwiperSlide>
          )
        )}
      </Styled.Swiper>
    </Styled.Container>
  );
}

CourseSwiperList.propTypes = {
  slideItemList: PropTypes.array,
  courseId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onNext: PropTypes.func,
  className: PropTypes.string,
};

import styled from 'styled-components';
import StyledUtil from 'common/styled/Util.style';
import Img from 'component/atoms/Img';
import { Link } from 'react-router-dom';
import playIcon from 'assets/images/ico_play.png';
import playIconOver from 'assets/images/ico_play_over.png';
import playIconSmall from 'assets/images/ico_play_small.png';

const StyledCourseItem = {
  Wrapper: styled.div`
    position: relative;
  `,
  Link: styled(Link)`
    &:hover .overlay::before {
      background-image: url(${playIconOver});
    }
  `,
  ImgBox: styled.div`
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(${playIconSmall});
      background-repeat: no-repeat;
      background-position: center;
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 0.8;
    }
    @media (max-width: 1024px) {
      &:after {
        background-image: url(${playIcon});
        background-size: 2em;
      }
    }
  `,
  Img: styled(Img)`
    width: 100%;
    margin-bottom: 1em;
    border-radius: 0.6rem;
  `,
  NewIcon: styled.span.attrs(() => ({
    children: (
      <>NEW</>
    ),
  }))`
    position: absolute;
    top: 0.385em;
    left: 0.385em;
    padding: 0.231em 0.692em;
    background-color: #c83355;
    border-radius: 0.154em;
    font-size: 1.3em;
    font-weight: 700;
    z-index: 1;
  `,
  Text: styled.div`
    height: 8.5em;
    &:hover {
      text-decoration: underline;
    }
  `,
  Title: styled.div`
    ${StyledUtil.lineClamp}
    font-size: 2em;
    font-weight: 500;
    @media (max-width: 767px) {
      /* font-size: 2.5em; */
    }
  `,
  Desc: styled.div`
    ${StyledUtil.textEllipsis}
    font-size: 1.6em;
    color: #8e9199;
    @media (max-width: 767px) {
      /* font-size: 2em; */
    }
  `,
};

export default StyledCourseItem;

import React from "react";
import Style from "./styled/index.style";
import ContentsWrapper from "component/atoms/ContentsWrapper";
import PageHeader from "component/atoms/PageHeader";
import BookStoreContent from "container/BookStorePage/Content";
import SeoHelmet from "component/Helmet";
import { SeoData } from "common/seoData";
import { Trans, useTranslation } from "react-i18next";
import { useAxios } from "common/hook/useAxiosHook";
import BookStoreApi from "api/bookstore";

const BookStorePage = () => {
  const { t } = useTranslation("bookstorePage");

  const [{ data }] = useAxios(BookStoreApi.getHeader, []);

  const handleClick = (e) => {
    e.preventDefault();
    if (
      data?.bannerUrl === "" ||
      data?.bannerUrl === null ||
      data?.bannerUrl === undefined
    ) {
      return;
    } else {
      if (
        data?.bannerUrl &&
        (data?.bannerUrl.includes("http") || data?.bannerUrl.includes("https"))
      ) {
        window.open(`${data?.bannerUrl}`);
      } else if (
        data?.bannerUrl &&
        (!data?.bannerUrl.includes("http") ||
          !data?.bannerUrl.includes("https"))
      ) {
        window.open(`http://${data?.bannerUrl}`);
      } else {
        return;
      }
    }
  };

  if (data)
    return (
      <>
        <SeoHelmet
          title={SeoData.bookstore.title}
          desc={SeoData.bookstore.desc}
        />
        <ContentsWrapper>
          <PageHeader title={t("title")} text={t("subtitle")} />
        </ContentsWrapper>
        <Style.HeaderContainer backgroundUrl={`${data.filePath}`}>
          <div className="content">
            <p>
              {data.title &&
                data.title !== "" &&
                data.title.split("<br>").map((line, index) => (
                  <>
                    {line}
                    {index === data.title.split("<br>").length - 1 ? null : (
                      <br />
                    )}
                  </>
                ))}
            </p>

            <Trans
              t={t}
              i18nKey="bannerMain.link"
              className="tag"
              components={{
                a: <a onClick={handleClick} href="#none" />,
              }}
            />
          </div>
        </Style.HeaderContainer>
        <ContentsWrapper>
          <BookStoreContent />
        </ContentsWrapper>
      </>
    );

  return null;
};

export default BookStorePage;

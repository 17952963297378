import styled from 'styled-components';

import backgroundImg from 'assets/images/main_background_courses.jpg';

const BannerCoursesStyled = {
  Wrapper: styled.div`
    position: relative;
    font-size: 2em;
    padding: 12em 0;
    background: url('${backgroundImg}') no-repeat center;
    background-size: cover;
    font-weight: 100;
    a {
      display: inline-block;
      margin-top: 0.5em;
    }
    span {
      display: inline-block;
    }
    @media (max-width: 1024px) {
      padding: 7em 0;
    }
    @media (max-width: 767px) {
      font-size: 2.2em;
    }
    @media (max-width: 375px) {
      > * {
        position: relative;
        z-index: 1;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          to right,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0)
        );
      }
    }
  `,
  H2: styled.h2`
    margin-bottom: 0.2em;
    font-size: 2.5em;
    font-weight: bold;
    line-height: 1.3;
    @media (max-width: 375px) {
      span {
        display: block;
      }
    }
  `,
  Desc: styled.div`
    margin-bottom: 2em;
    @media (max-width: 375px) {
      display: none;
    }
  `,
};

export default BannerCoursesStyled;

import styled from 'styled-components';

const StyledOverall = {
  H3: styled.h3`
    margin-bottom: 0.58em;
    font-size: 3.4em;
    font-weight: 500;
  `,
  FlexRow: styled.div`
    position: relative;
    display: flex;
    margin-bottom: 2.5em;
    > * {
      background: #1e2024;
      border-radius: 0.6rem;
      &.overall_selectbox{
        position: relative;
        width: 14.5em;
        .order-by-select{
          position: absolute;
        }
      }
    }
    > .chart-container {
      position: relative;
      width: 69%;
      margin-right: 2.8em;
      padding: 2em 1em 1em 1.5em;
      .select-container {
        position: absolute;
        .select__control,
        .select__menu {
          font-size: 0.8em;
          background-color: #1e2024;
        }
      }
    }
    > *:last-child {
      flex: 1;
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      margin-bottom: 0;

      > .chart-container,
      > * {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2.8em;
      }
    }
    @media (max-width: 767px) {
      .chart {
        min-width: 700px;
      }
      > .chart-container {
        overflow-x: auto;
      }
    }
  `,
  SelectContainer: styled.div`
    display: inline-block;
    width: 100%;
    margin: -3em 0 2em;
    .order-by-select {
      float: right;
      z-index: 2;
    }

    @media (max-width: 767px) {
      margin-top: 2em;
    }
  `,
};

export default StyledOverall;

import React from 'react';
import styled, { css } from 'styled-components';
import StyledUserHomePage from 'container/UserHomePage/index.style';
import { Link } from 'react-router-dom';
import { Circle } from 'rc-progress';
import { Swiper } from 'swiper/react';
import StyledUtil from 'common/styled/Util.style';
import { defaultButtonStyles } from 'component/atoms/ButtonLink';

import arrowRight from 'assets/images/arrow2_right_gray.svg';
import arrowLeft from 'assets/images/arrow2_left_gray.svg';
import gradient from 'assets/images/gradient02.png';
import mypageBg from 'assets/images/mypage_level_test.jpg';
import dot from 'assets/images/mycurriculum_new/dot.png';

export const swiperButtonStyles = css`
  .swiper-button-disabled {
    opacity: 0.5;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 2.5em;
    z-index: 10;
    width: 3em;
    height: 3em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.5em;
    background-color: #1e2024;
    cursor: pointer;
  }
  .swiper-button-prev {
    right: 6em;
    background-image: url(${arrowLeft});
  }
  .swiper-button-next {
    right: 3em;
    background-image: url(${arrowRight});
  }
  @media (max-width: 1024px) {
    .swiper-button-prev {
      right: 5em;
    }
    .swiper-button-next {
      right: 2em;
    }
  }
  @media (max-width: 767px) {
    .swiper-button-prev,
    .swiper-button-next {
      top: 50%;
      width: 4em;
      height: 4em;
      margin-top: -2em;
      background-size: 2.5em;
      transition: all 0.2s ease;
    }
    .swiper-button-prev {
      right: auto;
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
  }
`;

const StyledActivityInsight = {
  Wrapper: styled.div`
    position: relative;

    &::after {
      content: '';
      display: block;
      clear: both;
    }
  `,
  LeftInner: styled.div`
    float: left;
    width: 66.92307692307692%;
    > *:not(:first-child) {
      margin-top: 3em;
    }
    .curriculumLink {
      position: absolute;
      display: inline-block !important;
      top: 0.6em;
      right: 1em;

      @media (max-width: 767px) {
        display: none;
      }
    }
    @media (max-width: 767px) {
      float: none;
      width: auto;
    }
  `,
  RightInner: styled.div`
    float: right;
    width: 30.76923076923077%;
    > *:not(:first-child) {
      margin-top: 3em;
    }
    @media (max-width: 767px) {
      float: none;
      width: auto;
    }
  `,
  Container: styled.div`
    position: relative;
    padding: 2.5em 3em;
    border-radius: 0.6em;
    background-color: #1e2024;
    overflow: hidden;
    @media (max-width: 1024px) {
      padding: 2.5em 2em;
    }
    @media (max-width: 767px) {
      height: auto !important;
      padding: 0 !important;
      margin-bottom: 7em;
      background: none;
    }
  `,
  H2: styled(StyledUserHomePage.H2)`
    @media (max-width: 767px) {
      display: none;
    }
  `,
  H3: styled.h3`
    font-size: 2rem;
    font-weight: 500;
    color: #c9cbd1;
    padding-bottom: 1em;
    @media (max-width: 767px) {
      font-size: 3em;
      margin-bottom: 0.6em;
      color: #fff;
      &.mycurri-progress-h3{
        margin-bottom:0;
        padding-bottom:10px;
      }
    }
  `,
  Box: styled.div`
    @media (max-width: 767px) {
      position: relative;
      padding: 2.5em 3em;
      border-radius: 0.6em;
      background-color: #1e2024;
    }
  `,
  MoreLink: styled(Link)`
    /* 작업코멘트 20201214 변경 예정 */
    display: none;
  `,
  NoDataWrap: styled.div`
    width: 100%;
    border-radius: 0.6em;
    padding-bottom: 59.077%;
    background: url(${mypageBg}) no-repeat center;
    background-size: cover;
    &.curriculum {
      height: 79em;
      position: relative;
    }
  `,
  NoData: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    padding: 2em;
    transform: translate(-50%, -50%);
    text-align: center;
  `,
  NoDataText: styled.div`
    font-size: 3.6em;
    font-weight: 700;
  `,
  NoDataLink: styled(Link)`
    display: inline-block;
    padding: 0.938em 0;
    margin-top: 2em;
    min-width: 18.75em;
    border-radius: 0.375em;
    background-color: #485cc7;
    font-size: 1.6em;
    font-weight: 700;
    line-height: 1.2;

    &.dormant {
      background-color: #ffb500;
    }
  `,
  NoDataClosed: styled.span`
    position: absolute;
    top: 2em;
    right: 2em;
    display: block;
    width: 4em;
    height: 4em;
    cursor: pointer;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 20px;
      background-color: #fff;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(135deg);
    }
  `,
};

export const StyledMyCurriculum = {
  Wrapper: styled(StyledActivityInsight.Container)`
    height: 7rem;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
  `,
  H3: styled(StyledActivityInsight.H3)`
    float: left;
    margin-right: 3em;
    @media (max-width: 1024px) {
      margin-right: 1.5em;
    }
    @media (max-width: 767px) {
      float: none;
      margin-right: 0;
    }
  `,
  Status: styled.div`
    display: inline-block;
    font-size: 1.8em;
    padding: 0.15em 1em;
    margin-right: 1em;
    border: 1px solid #8f6e12;
    border-radius: 100px;
    font-weight: 500;
    color: #ffbb00;
    vertical-align: middle;
    @media (max-width: 767px) {
      font-size: 2em;
    }
  `,
  Title: styled.div`
    ${StyledUtil.textEllipsis}
    display: inline-block;
    max-width: calc(100% - 20em);
    font-size: 1.8em;
    font-weight: bold;
    vertical-align: middle;
    @media (max-width: 1024px) {
      max-width: calc(100% - 18em);
    }
    @media (max-width: 767px) {
      font-size: 2em;
      max-width: calc(100% - 8em);
      white-space: initial;
      text-overflow: initial;
      overflow: initial;
    }
  `,

  CurriculumLink: styled(Link)`
    float: right;
    /* display: inline-block; */
    padding-top: 0.6em;
  `,
};

export const StyledCurriculumProgress = {
  Wrapper: styled(StyledActivityInsight.Container)`
    height: 34rem;
    overflow: visible;

    &:after {
      content: '';
      clear: both;
      display: inline-block;
      width: 100%;
    }

    .curriculumLink {
      display: none;
    }


    @media (max-width: 767px) {
      background-color: #1e2024;
      padding:18px !important;
    }
  `,
  Box: styled(StyledActivityInsight.Box)`
    display: inline-block;
    width: 100%;
    height: 28.8em;
    /* padding-top: 3em; */
    overflow-x: hidden;
    overflow-y: auto;

    @media (max-width: 767px) {
      &.mobile-hidden {
        display: none;
      }
    }
  `,
  Title: {
    Wrapper: styled.div`
      position: relative;
      float: left;
      width: 25%;
      border-right: 1px solid #3d3d3d;
      background: #1e2024;
      font-size: 1.6em;
      z-index: 10;
    `,
    Top: styled.div`
      position: sticky;
      top: 0;
      background: #1e2024;
      height: 3.8rem;
      border-bottom: 1px solid #3d3d3d;
      font-weight: bold;
      text-align: center;
    `,
    List: styled.div`
      padding: 1rem 0;
    `,
    TextWrap: styled.div`
      height:8rem;
      display: flex;
      flex-direction: column;
      gap:1rem;
      justify-content: center;
    `,
    Item: styled.a`
      ${StyledUtil.textEllipsis}
      display: inline-block;
      width: 100%;
      padding-right: 0.5em;
      line-height: 1;
      vertical-align: middle;
      @media (max-width: 768px) {
        &.mobile-title{
          color:#ffffff;
          font-size:14px;
          font-weight:500
        }
      }
    `,
    Info: styled.p`
      color:#8e9199;
      font-size:1.4rem;
      @media (max-width: 768px) {
        &.mobile-title{
          color:#8e9199;
          font-size:9px;
        }
      }

    `,
    Button : styled.button`
      font-size:1.6rem;
      color:#ffffff;
      background:#485cc7;
      border-radius: 5px;;
      width:34rem;
      height:5.4rem;
      display: flex;
      align-items: center;
      justify-content: center;;
      margin:5rem auto;

      @media (max-width: 1024px) {
        margin:25px auto;
        width:90%;
        font-size:15px;
        height:50px;
      }


  `

  },
  Timeline: {
    Wrapper: styled.div``,
    SwiperWrap: styled.div`
      float: left;
      width: 75%;
    `,
    SwiperTopText:  styled.div.attrs(({ children }) => ({
      className: 'timeline-top',
      children: <span>{children}</span>,
    }))`
      position: sticky;
      top: 0;
      background: #1e2024;
      z-index: 999;
      height: 3.8rem;
      font-size: 1.4em;
      font-weight: bold;
      text-align: center;
      text-indent: 0.5em;
      color: #b9b9b9;

      &:before {
        content: '';
        position: absolute;
        top: calc(3.8rem - 1px);
        left: 50%;
        margin-left: -50vw;
        width: 100vw;
        height: 1px;
        background-color: #3d3d3d;
      }
    `,
    Swiper: styled(Swiper)`
      ${swiperButtonStyles}
      position: static;

      padding-right: 6em;
      &.swiper-container {
        overflow: initial;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 3em;
        z-index: 9;
        width: 7rem;
        height: 100%;
        background: url(${gradient}) repeat-y right center;
        background-size: 100% auto;
      }
      .swiper-slide:first-child .timeline-list {
        border-left: 0;
      }
      .timeline-item:nth-child(5n - 4):before {
        background-color: #00acb2;
      }
      .timeline-item:nth-child(5n - 3):before {
        background-color: #80aa00;
      }
      .timeline-item:nth-child(5n - 2):before {
        background-color: #8b56e3;
      }
      .timeline-item:nth-child(5n - 1):before {
        background-color: #586eed;
      }
      .current-week {
        .timeline-top span {
          position: relative;
          color: #ffbb00;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -4px;
            width: 4px;
            height: 4px;
            background-color: #ffbb00;
            border-radius: 4px;
          }
        }
        .timeline-list {
          background-color: #0f1012;
        }
      }
      @media (max-width: 1024px) {
        &:after {
          right: 2em;
        }
      }
    `,
    SlideInfo: styled.p`
      position:absolute;
      left:3rem;
      top:50%;
      transform: translateY(-50%);
      color: #fff;;
      font-size:1.4rem;
      z-index:5;
      width:10rem;
      span{
        font-weight:700
      }
    `,
    Top: styled.div.attrs(({ children }) => ({
      className: 'timeline-top',
      children: <span>{children}</span>,
    }))`
      position: sticky;
      top: 0;
      background: #1e2024;
      z-index: 999;
      height: 3.5rem;
      font-size: 1.4em;
      font-weight: bold;
      text-align: center;
      text-indent: 0.5em;
      color: #b9b9b9;
      z-index:3;

      &:before {
        content: '';
        position: absolute;
        top: calc(3.5rem - 1px);
        left: 50%;
        margin-left: -50vw;
        width: 100vw;
        height: 1px;
        background-color: #3d3d3d;
      }
    `,
    List: styled.div.attrs({ className: 'timeline-list' })`
      padding: 1rem 0;
      border-left: 1px dashed #3d3d3d;
      position:relative;
    `,
    Item: styled.div.attrs({ className: 'timeline-item' })`
      position: relative;
      height: 8rem;
      border-bottom:1px solid #2e2f31;
      &:before {
        content: '';
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        width: calc(${({ week }) => (week ? week * 100 : 0)}% - 10px);
        height: 30px;
        background-color: #000000;
        border-radius: 3px;
      }
      `,
    progressPcWrap:  styled.div`
       position: relative;
      float: left;
      width: 75%;
      background: #1e2024;
      font-size: 1.6em;
      z-index: 10;
    `,
    progressPcTitle:  styled.div`
        position: sticky;
      top: 0;
      background: #1e2024;
      height: 3.8rem;
      border-bottom: 1px solid #3d3d3d;
      font-weight: bold;
      text-align: center;
      z-index:3;
      `,

    progressPcList:  styled.div`  padding: 1rem 0;
        position: relative;
       .progress-wrap1 .progress-bar{background-color: #00acb2;}
        .progress-wrap2 .progress-bar{background-color: #80aa00;}
        .progress-wrap3 .progress-bar{background-color: #8b56e3;}
        .progress-wrap4 .progress-bar{background-color: #586eed;}
        .progress-wrap1{border-top:none;}
    `,
    progressPc:  styled.div`
       height:8rem;
      display: flex;
      flex-direction: column;
      gap:1rem;
      justify-content: center;
      border-top:1px solid rgba(61,61,61,0.5);
      padding-left:2rem;
      &:first-child{border-top:none;}

    `,
    ProgressPcBar:  styled.div`
        width: ${(props) => props.percentage + '%'};
        height:30px;
        border-radius: 0.5rem;;
        padding-left:2rem;
        font-size:1.4rem;
        display:flex;
        align-items: center;;
        position:relative;
        z-index: 2;
        span{
          font-weight:600;
        }

    `,
    progressLineWrap:  styled.div`
      position:absolute;
      left:0;
      top:0;
      width:100%;
      height:100%;
      display: flex;

    `,
    progressLine:  styled.div`
       width: ${(props) => props.width + '%'};
       height:100%;
      background:url(${dot}) repeat-y left top;
    `,
    Mobile: styled.div`

      @media (min-width: 768px) {
        display: none;


      }
      @media (max-width: 768px) {
        border-top:1px solid #3d3d3d;
         padding-top:15px;
        .progress-wrap:nth-child(1) .progress-bar{background-color: #00acb2;}
        .progress-wrap:nth-child(2) .progress-bar{background-color: #80aa00;}
        .progress-wrap:nth-child(3) .progress-bar{background-color: #8b56e3;}
        .progress-wrap:nth-child(4) .progress-bar{background-color: #586eed;}
      }

    `,
    Progress: styled.div`
      width:100%;
      background:#0f1012;
      height:20px;
      border-radius: 5px;
      position: relative;
      margin-top:4px;

    `,
    ProgressBar: styled.div`
      position: absolute;
      height:100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis
      border-radius: 5px;
      width: ${(props) => props.percentage + '%'};
      padding-left:10px;
      font-size:10px;
      color:#fff;
      display: flex;
      justify-content:flex-start;
      align-items: center;;
      span{
        font-weight:500
      }
    `,
  },

};

export const StyledCurriculumProgressDetail = {
  Wrapper: styled(StyledActivityInsight.Container)`
    height: 32rem;
  `,
  Box: styled(StyledActivityInsight.Box)`
    @media (max-width: 767px) {
      background: none;
    }
  `,
  Swiper: styled(Swiper)`
    ${swiperButtonStyles}
    padding-top: 3em;
    text-align: center;
    position: static;
    .swiper-button-prev,
    .swiper-button-next {
      background-color: transparent;
    }
    @media (max-width: 767px) {
      position: relative;
      .swiper-slide {
        padding-top: 0;
        padding-bottom: 36em;
      }
    }
  `,
  CircleWrapper: styled.div`
    position: relative;
    width: 12em;
    height: 12em;
    margin: 0 auto 2em;
    @media (max-width: 767px) {
      position: absolute;
      bottom: 5em;
      left: 50%;
      width: 28em;
      height: 28em;
      margin: 0 0 0 -14em;
    }
  `,
  Circle: styled(Circle).attrs({
    trailWidth: '5',
    trailColor: '#2b2b2b',
    strokeWidth: '5',
  })`
    vertical-align: middle;
  `,
  Percent: styled.div.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <span> %</span>
      </>
    ),
  }))`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    font-size: 3.2em;
    font-weight: 100;
    text-align: center;
    color: #eee;
    line-height: 1;
    transform: translateY(-50%);
    span {
      font-size: 0.5em;
    }
  `,
  Subject: styled.div`
    font-size: 1.4em;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 2.8em;
    }
  `,
  Count: styled.div.attrs(({ children }) => ({
    children: (
      <>
        My progress <span>{children}</span>
      </>
    ),
  }))`
    font-size: 13px;
    margin-top: 0.2em;
    font-weight: 300;
    color: #b9b9b9;
    &:before {
      content: '';
      display: inline-block;
      width: 7px;
      height: 7px;
      margin-right: 5px;
      border-radius: 7px;
      background-color: ${({ color }) => color};
      vertical-align: middle;
    }
    span {
      display: inline-block;
      margin-left: 1em;
    }
    @media (max-width: 767px) {
      position: absolute;
      bottom: 0;
      left: 0;
      font-size: 2.2em;
      width: 100%;
      text-align: center;
    }
  `,
};

export const StyledMockTest = {
  Wrapper: styled(StyledActivityInsight.Container)`
    height: 32rem;
    @media (max-width: 767px) {
      margin-bottom: 0;
    }
  `,
  SubText: styled.div`
    font-size: 1.4em;
    margin-top: -1em;
    margin-bottom: 2.2em;
    color: #8e9199;
    text-overflow: ellipsis;
    @media (max-width: 767px) {
      margin-top: -2em;
      margin-bottom: 1.5em;
      font-size: 2em;
    }
  `,
  Li: styled.li`
    padding: 1.2em 0;
    overflow: hidden;
  `,
  Subject: styled.span`
    float: left;
    font-size: 1.6em;
    line-height: 3.4rem;
    max-width: calc(100% - 11rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 767px) {
      font-size: 2em;
      line-height: 5.7rem;
      max-width: calc(100% - 8em);
    }
  `,
  Button: styled(Link)`
    ${defaultButtonStyles}
    float: right;
    font-size: 1.4em;
    width: 10rem;
    padding: 0.5em 0;
    border-radius: 4px;
    background-color: #485cc7;
    font-weight: 500;
    text-align: center;
    line-height: 1.5;
    &.complete {
      background-color: #2a2c2e;
    }
    @media (max-width: 767px) {
      font-size: 2em;
      width: 7em;
      &:first-letter {
        text-transform: uppercase;
      }
      span {
        display: none;
      }
    }
  `,

};

export default StyledActivityInsight;

import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserStatus } from '../constant';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';

export default function useNeedLogin() {
  const history = useHistory();
  const status = useSelector((state) => state.user.status);
  let { handleModal } = React.useContext(ModalContext);
  useEffect(() => {
    if (status !== UserStatus.Login) {
      history.replace('/');
      handleModal(<Login />, false);
    }
  }, [status, history]);
}

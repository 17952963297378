import React from 'react';
import Styled from './MobileStickyBottom.style';
import { SiteMapData } from 'common/constant';
import Img from 'component/atoms/Img';
import tabIcon01 from 'assets/images/ico_mobile_tab01.png';
import tabIcon02 from 'assets/images/ico_mobile_tab02.png';
import tabIcon03 from 'assets/images/ico_mobile_tab03.png';
import tabIcon04 from 'assets/images/ico_mobile_tab04.png';
import tabIcon06 from 'assets/images/gnb_new/ico_mobile_tab06.png';
import tabIcon07 from 'assets/images/gnb_new/ico_mobile_tab07.png';
import tabIcon08 from 'assets/images/gnb_new/ico_mobile_tab08.png';

const MobileStickyBottom = () => {
  return (
    <Styled.Wrapper>
      <Styled.Link to='/'>
        <Img src={tabIcon01} alt='HOME' />
        <span>HOME</span>
      </Styled.Link>
      <Styled.Link to={SiteMapData.course}>
        <Img src={tabIcon06} alt='Course' />
        <span>Course</span>
      </Styled.Link>
      <Styled.Link to={SiteMapData.classroom}>
        <Img src={tabIcon02} alt='Classroom' />
        <span>Classroom</span>
      </Styled.Link>
      <Styled.Link to={SiteMapData.mock}>
        <Img src={tabIcon07} alt='Mock Test' />
        <span>Mock Test</span>
      </Styled.Link>
      <Styled.Link to={`${SiteMapData.setting}`}>
        <Img src={tabIcon08} alt='Settings' />
        <span>Settings</span>
      </Styled.Link>
    </Styled.Wrapper>
  );
};

export default MobileStickyBottom;

import React, { useState, useEffect } from 'react';
import Styled from './styled/summaryBanner.style';
import { useSelector } from 'react-redux';

import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { ThemeProvider } from 'styled-components';
import { ButtonTheme } from 'component/atoms/ButtonLink';
import { masterTopikUrl } from 'common/constant';
import { useParams } from 'react-router-dom';
import { useAxios } from 'common/hook/useAxiosHook';
import {
  fetchCourseLike,
  postCourseLike,
  deleteCourseLike,
  fetchCourseBookmark,
  postCourseBookmark,
  deleteCourseBookmark,
} from './api/courseDetailApi';
import { useLocation } from 'react-router-dom';

const SummaryBanner = ({ openLoginModal, data, loginStatus, t }) => {
  let { productId: urlProductId } = useParams();
  const token = useSelector((state) => state.user.token);
  const { courseResponse, teacherResponse } = data;
  const location = useLocation();
  const [getCourseLike, refreshGetCourseLike] = useAxios(
    () => fetchCourseLike(urlProductId, token),
    [urlProductId, token],
    true
  );
  const [, refreshUpdateLik] = useAxios(
    () => postCourseLike(urlProductId, token),
    [urlProductId, token],
    true
  );
  const [, refreshDeleteLike] = useAxios(
    () => deleteCourseLike(urlProductId, token),
    [urlProductId, token],
    true
  );

  const [getCourseBookmark, refreshGetCourseBookmark] = useAxios(
    () => fetchCourseBookmark(urlProductId, token),
    [urlProductId, token],
    true
  );

  const [, refreshUpdateBookmark] = useAxios(
    () => postCourseBookmark({ productId: urlProductId }, token),
    [urlProductId, token],
    true
  );

  const [, refreshDeleteBookmark] = useAxios(
    () => deleteCourseBookmark({ productId: urlProductId }, token),
    [urlProductId, token],
    true
  );

  const [bookmarkBtn, setBookmarkBtn] = useState(false);
  const [likeBtn, setLikeBtn] = useState(false);

  let imgUrl = masterTopikUrl + courseResponse.thumbnailPath;

  const tempOnClick = (type) => {
    //좋아요 버튼
    if (type === 'like') {
      if (likeBtn) {
        refreshDeleteLike();
      } else {
        refreshUpdateLik();
      }
      setLikeBtn((prev) => !prev);
    }

    //북마크 버튼
    if (type === 'bookmark') {
      if (bookmarkBtn) {
        refreshDeleteBookmark();
      } else {
        refreshUpdateBookmark();
      }
      setBookmarkBtn((prev) => !prev);
    }
  };

  useEffect(() => {
    //only get like && bookmark info when log in
    if (token) {
      refreshGetCourseLike();
      refreshGetCourseBookmark();
    }
  }, [token]);

  useEffect(() => {
    if (getCourseLike.data) {
      setLikeBtn(getCourseLike.data);
    }
    if (getCourseBookmark.data) {
      setBookmarkBtn(getCourseBookmark.data);
    }
  }, [getCourseLike.data, getCourseBookmark.data]);

  return (
    <Styled.Container bgImage={imgUrl}>
      <ContentsWrapper>
        <Styled.H2> {courseResponse.title}</Styled.H2>
        <Styled.Span>
          By {teacherResponse.firstName} {teacherResponse.lastName}
        </Styled.Span>
        <Styled.P>{courseResponse.character}</Styled.P>
        {!loginStatus && (
          <ThemeProvider theme={ButtonTheme.primary.filled}>
            <Styled.SignUpBtn onClick={() => openLoginModal(location.pathname)}>
            {t('buttons.button0')}
            </Styled.SignUpBtn>
          </ThemeProvider>
        )}

        {loginStatus && (
          <ThemeProvider theme={ButtonTheme.primary.filled}>
            <Styled.StartBtn
              to={`/courses/detail/${urlProductId}/${courseResponse.chapterLectureId}`}
            >
              {t('buttons.button1')}
            </Styled.StartBtn>
            <Styled.LikeBtn to='#' onClick={() => tempOnClick('like')}>
              <Styled.Heart size='1.2em' color={likeBtn ? '#d11f1f' : ''} />
              {t('buttons.button2')}
            </Styled.LikeBtn>
            <Styled.LineBreak />
            <Styled.BookmarkBtn to='#' onClick={() => tempOnClick('bookmark')}>
              <Styled.Bookmark
                size='1.2em'
                color={bookmarkBtn ? '#485cc7' : ''}
              />
              {t('buttons.button3')}
            </Styled.BookmarkBtn>
            <Styled.DiscussionBtn to='/community'>
              <Styled.Discussion size='1.2em' />
              {t('buttons.button4')}
            </Styled.DiscussionBtn>
          </ThemeProvider>
        )}
      </ContentsWrapper>
    </Styled.Container>
  );
};

export default SummaryBanner;

import styled from 'styled-components';

const StyledNotificationSection = {
  Container: styled.div`
    padding: 4em 3em 4.5em 3em;
    border-radius: 0.6em;
    background-color: #1e2024;
    @media (max-width: 767px) {
      font-size: 1.1em;
    }
  `,
  Title: styled.h3`
    margin-bottom: 1.75em;
    color: #8b8f99;
    font-size: 2em;
    font-weight: 700;
    letter-spacing: 0.1px;
  `,
  Notification: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.38em;
    padding-left: 0.95em;
    padding-bottom: 1.2em;
    border-bottom: 1px solid #2b2e35;
    color: #fff;
    font-size: 1.8em;
    font-weight: 300;
    &:last-of-type {
      margin-bottom: 0;
    }
  `,
  ToggleSwitchContainer: styled.span`
    font-size: 0.55em;
    > label > div {
      background-color: #383a3d;
    }
  `,
};

export default StyledNotificationSection;

import React, { useEffect, useState } from 'react';
import StyledSelect from './index.style';
import { useDispatch, useSelector } from 'react-redux';
import makeAnimated from 'react-select/animated';
import { CURRENCY } from 'common/constant';
import { actions } from 'common/state';

const animatedComponents = makeAnimated();

const options = Object.values(CURRENCY).map((item) => ({
  value: item,
  label: item,
}));

const CurrencySelectBox = () => {
  const currentCurrency = useSelector((state) => state.common.currency);

  const [selectedOption, setSelectedOption] = useState(currentCurrency);

  const handleCurrency = (e) => setSelectedOption(e);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!selectedOption) return;
    dispatch(actions.setCurrency(selectedOption?.value));
  }, [selectedOption]);

  return (
    <StyledSelect
      options={options}
      components={animatedComponents}
      onChange={handleCurrency}
      isSearchable={false}
      placeholder={selectedOption}
    />
  );
};

export default CurrencySelectBox;

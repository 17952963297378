import styled from 'styled-components';

const ResearchMainStyled = {
  Wrapper: styled.div`
    position: relative;
    padding: 9em 0;
    background: #393c40;
    text-align: center;
  `,
  H2: styled.h2`
    margin-bottom: 1em;
    font-size: 3.8em;
    font-weight: 700;
    @media (max-width: 767px) {
      span {
        display: block;
      }
    }
  `,
  ButtonContainer: styled.div`
    a + a {
      margin-left: 1em;
    }
  `,
};

export default ResearchMainStyled;

import React from 'react';
import { Modal } from 'container/LevelTestPage/styled';
import { ModalContext } from 'component/Modal/modalContext';
import { Trans } from 'react-i18next';

const SetupCompleteModal = ({ data, t }) => {
  let { handleModal } = React.useContext(ModalContext);
  const { curriculumChargeId } = data;

  return (
    <Modal.Container>
      <Modal.Desc>
        <Trans t={t}>modal1.notice</Trans>
      </Modal.Desc>

      <Modal.ButtonGroup>
        <Modal.Link
          to={`/classroom/curriculum/${curriculumChargeId}`}
          onClick={() => handleModal()}
        >
          {t('modal1.button')}
        </Modal.Link>
      </Modal.ButtonGroup>
    </Modal.Container>
  );
};

export default SetupCompleteModal;

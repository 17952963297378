import React from 'react';
import styled from 'styled-components';
import { ModalContext } from 'component/Modal/modalContext';
import CreateThemeModalDepth1 from './CreateThemeModalDepth1';

const CreateThemeButton = styled.button`
  position: relative;
  width: 15em;
  height: 10em;
  margin-bottom: 6em;
  border-radius: 0.6rem;
  background-color: #1e2024;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4em;
    height: 0.2em;
    margin: -0.1em 0 0 -2em;
    background-color: #fff;
  }
  &:after {
    transform: rotate(90deg);
  }
`;

const CreateTheme = () => {
  let { handleModal } = React.useContext(ModalContext);
  const createThemeDepth1Modal = (e) => {
    e.preventDefault();
    handleModal(<CreateThemeModalDepth1 />, false, 540);
  };

  return (
    <>
      <CreateThemeButton onClick={createThemeDepth1Modal} />
    </>
  );
};

export default CreateTheme;

import React from 'react';

export const termsOfServiceZh0915 = () => (
  <div class='policy'>
    <h3 class='policy-title'>服务利用条款</h3>

    <dl class='policy-subject'>
      <dt>- 目录 –</dt>
      <dd>
        <dl>
          <dt>第1章 总则</dt>
          <dd>第1条 [目的]</dd>
          <dd>第2条 [用语的定义]</dd>
          <dd>第3条 [公司信息等的提供]</dd>
          <dd>第4条 [条款的告示等]</dd>
          <dd>第5条 [条款的改正等]</dd>
          <dd>第6条 [条款的解释]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>第2章 会员注册</dt>
          <dd>第7条 [会员注册]</dd>
          <dd>第8条 [会员信息的变更]</dd>
          <dd>第9条 [关于会员的ID及密码管理的义务]</dd>
          <dd>第10条 [关于会员或用户的通知]</dd>
          <dd>第11条 [会员注销及资格丧失等]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>第3章 服务利用合同</dt>
          <dd>第12条 [内容等的告示]</dd>
          <dd>第13条 [利用合同的成立等]</dd>
          <dd>第14条 [自动支付及解除]</dd>
          <dd>第15条 [教材等的销售及派送]</dd>
          <dd>第16条 [“公司”的义务]</dd>
          <dd>第17条 [会员的义务]</dd>
          <dd>第18条 [内容使用支付方法]</dd>
          <dd>第19条 [服务的提供及中断]</dd>
          <dd>第20条 [内容服务的变更]</dd>
          <dd>第21条 [信息的提供及发布广告]</dd>
          <dd>第22条 [会员的帖子及删除]</dd>
          <dd>第23条 [版权等的归属]</dd>
          <dd>第24条 [个人信息保护]</dd>
          <dd>第25条 [适用于韩国以外用户的特别条款]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>第4章 服务利用合同的变更、取消、退款</dt>
          <dd>第26条 [会员的服务利用合同变更/解除]</dd>
          <dd>第27条 [会员的合同取消、退款政策]</dd>
          <dd>第28条 [错收费用的返还]</dd>
          <dd>第29条 [公司的合同解除及利用限制]</dd>
          <dd>第30条 [关于服务瑕疵等的损失赔偿]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>第5章 其他</dt>
          <dd></dd>
          <dd>第31条 [免责条款]</dd>
          <dd>第32条 [纠纷的解决]</dd>
          <dd>第33条 [准据法及裁判管辖]</dd>
          <dd>[附则]]</dd>
        </dl>
      </dd>
    </dl>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第1章 总则</h4>
      <p>
        您好。MasterTOPIK是通过网络向会员提供各种韩国语教育内容的针对性订阅服务。
      </p>

      <p class='policy-tit'>第1条 [目的]</p>
      <p>
        此条款的目的在于利用VISANG
        EDUCATION（以下简称‘公司’）所提供的教育内容及网络相关服务（以下简称‘服务’）时，规定会员的权利和义务、责任及其他必要的事宜等。MasterTOPIK(www.mastertopik.com)（以下简称‘MasterTOPIK’）可通过通过公司的ID及密码利用所有相关服务。
      </p>

      <p class='policy-tit'>第2条 [用语的定义]</p>
      <p>
        此条款使用的用于按如下定义。此条款未明示的用语遵从相关法令的规定，此外依据一般商务惯例解释。
      </p>

      <ol class='policy-num'>
        <li>1. 公司：是指‘VISANG EDUCATION’。</li>
        <li>
          2. 사이트 : 网站：是指公司为了提供服务所运营的网站‘mastertopik.com’。
        </li>
        <li>
          3.
          应用程序：是指安装于手机、平板电脑等便携式遥控终端，展现公司所提供服务的应用程序。
        </li>
        <li>
          4.
          用户：是指访问网站及应用程序，根据此条款接受公司服务的会员及非会员。
        </li>
        <li>
          5.
          会员：是指向公司提供个人信息，进行会员登录的人，根据此条款，与公司签署服务利用条款的人。
        </li>
        <li>6. 非会员：是指不是以会员的资格利用公司服务的人。</li>
        <li>
          7.
          ID：是指为了识别会员及利用服务，由会员选定，由公司承认的文字与数字的组合或邮箱地址。
        </li>
        <li>
          8.
          密码：为了确认会员及保护会员的权益及秘密，由会员自行设置，向公司登录的英文、数字
        </li>
        <li>
          9.
          内容：是指根据信息通信网利用促进及信息保护等相关法律第2条第1项第1号的规定，使用于信息通信网的符号、文字、语音、声响、图片或视频等资料或信息，为了提高其保管及利用的实效，以电子形态制作或处理的信息。
        </li>
        <li>10. 会员资格：是指定期订阅内容。</li>
        <li>11. 免费服务：是指无需支付费用，即可利用的服务。</li>
        <li>12. 收费服务：是指需要支付费用，方可利用的服务。</li>
        <li>
          13.
          支付：是指会员为了利用收费服务，通过此条款第18条各号指定的各种支付方式，向公司支付由公司指定的金额。
        </li>
        <li>14. 解除：是指公司或会员开通服务后，终止利用合同。</li>
        <li>
          15.
          操作员（管理者）：是指为了对服务进行全面的管理和圆满的运营，由公司选定的人或机构（公司）。
        </li>
        <li>
          16.
          帖子：是指会员在利用服务时，向公司网站发布的文字、符号、声响、画像、视频等信息形态的文章、照片视频及各种问价和链接、评论等信息。
        </li>
      </ol>

      <h4 class='policy-tit'>第3条 [公司信息等的提供]</h4>
      <p>
        公司将此条款的内容、商号、代表人姓名、营业场所所在地地址（包括可处理消费者不满的地址）、电话号码、传真编号、邮件地址、营业执照编号、通信销售业申告编号及个人信息管理责任人等公布于在线服务初始画面，易于“用户”确认。但，条款通过连接画面，用于用户查阅。
      </p>

      <h4 class='policy-tit'>第4条 [条款的告示等]</h4>
      <p>
        公司采取技术性措施，可让用户打印整份此条款，并在交易过程中确认该条款的内容。
      </p>

      <h4 class='policy-tit'>第5条 [条款的改正等]</h4>
      <ol class='policy-num'>
        <li>
          1.
          “公司”在不违反内容产业振兴法、电子商务等消费者保护的相关法律、关于条款管制的法律、电子文件及电子商务基本法、电子金融交易法、数字签名法、信息通信网利用促进及信息保护等的相关法律、关于访问销售等的法律、消费者基本法等相关法律的范围内，可改正此条款。
        </li>
        <li>
          2.
          如“公司”改正条款时，需要明示适用日期及改正原因，在适用日期10日之前，与现行条款一同发布于服务初始画面或通过其他方提供，向现有会员以电子邮件形式发送适用日期及变更内容中的重要事项。但，如条款改正对会员有不利之处，需明示适用日期及变更原因，在适用日期30日之前到使用日期前日，与现行条款一同进行公告，处公告以外，通过电子邮件等电子方式进行另外的明确通知。
        </li>
        <li>3. 如会员不同意改正条款的适用，会员可中断利用服务，且注销会员。</li>
        <li>
          4.
          现有会员根据同条第2项的内容进行告知或通知，在改正条款适用日之前未提出异议时，视为同意适用变更的条款。
        </li>
      </ol>

      <h4 class='policy-tit'>第6条 [条款的解释]</h4>
      <p>
        此条款未规定的事宜和关于此条款的解释遵从内容产业振兴法、电子商务等消费者保护的相关法律、
        关于条款管制的法律、数字内容用户保护方针、消费者保护方针及其他关系法令或商务惯例。
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第2章 会员注册</h4>

      <p class='policy-tit'>第7条 [会员注册]</p>
      <ol class='policy-num'>
        <li>
          1.
          只有满14岁以上的用户方可注册会员，用户同意条款的内容，并且填写公司提供的规定申请样式中的相关事宜，在申请注册会员后，公司予以许可，即视为签署合同。
        </li>
        <li>
          2.
          在前项的申请样式中，由用户填入的所有信息视为实际真是数据，未输入实名或实际信息的用户无法受到法律上的保护，并可限制使用服务。
        </li>
        <li>
          3.
          原则上，公司对于本条第1项的用户申请，允许其注册会员。但，对于符合如下各号内容的申请，公司可不予许可或保留许可，即使在许可之后，也可取消许可。
          <ol class='policy-num'>
            <li>① 用户的年龄未满14岁时</li>
            <li>② 注册申请人曾根据此条款丧失会员资格时</li>
            <li>③ 注册申请人曾在3个月内注销过会员时</li>
            <li>④ 未实名或使用他人名义时</li>
            <li>⑤ 填入虚假信息或未填入公司要求的内容时</li>
            <li>⑥ 因用户的责任理由不可承认或违法其他诸多事项进行申请时</li>
            <li>⑦ 违反本条款第17条的会员义务时</li>
            <li>
              ⑧
              申请会员注册时，填入并向公司提供的事项（ID、密码等）违反善良的风俗和其他社会秩序或有侮辱他人时
            </li>
            <li>
              ⑨ 利用恶性程序及Bug或恶意利用系统弱点等以不正的方法使用服务时
            </li>
            <li>⑩ 未具备其他公司指定的利用申请条件时</li>
          </ol>
        </li>
        <li>
          4. 公司如有服务相关设备无剩余或技术上或业务上有问题时，可保留许可。
        </li>
        <li>
          5.
          根据第3项和第4项的内容，未许可会员的注册申请或保留时，公司需要将其通知于申请人。但，并非因公司的责任理由，无法通知于申请人时，可例外处理。
        </li>
      </ol>

      <p class='policy-tit'>第8条 [会员信息的变更]</p>
      <ol class='policy-num'>
        <li>
          1.
          会员通过会员信息管理画面准确的输入密码，可随时阅览及修改自身的个人信息。
        </li>
        <li>
          2.
          会员如变更在申请会员注册时填写的事项时，可在线修改或通过电子邮件等其他方法通知公司其变更事项。
        </li>
        <li>
          3.
          会员变更了在申请会员注册时填写的事项，且未将其变更事项通知于“公司”，公司概不承担以此产生的损失。
        </li>
      </ol>

      <p class='policy-tit'>第9条 [关于会员的ID及密码管理的义务]</p>
      <ol class='policy-num'>
        <li>1. 会员的ID和密码的管理责任在于会员，并且不可让第3方利用。</li>
        <li>
          2. 会员如认知到ID及密码被盗用或被第3方使用时，需立即通知于公司。
        </li>
        <li>
          3.
          第2项情形时，为了保护会员的个人信息及防止其他不正使用行为等，公司可向会员要求变更密码等所需的措施，如会员未遵从公司的要求而造成的损失，公司概不负责。
        </li>
        <li>
          4.
          如会员注册多个账号时，公司会通知会员，并删除会员所选择的代表ID以外的其他ID。
        </li>
      </ol>

      <p class='policy-tit'>第10条 [关于会员或用户的通知]</p>
      <ol class='policy-num'>
        <li>
          1.
          如发生需要向会员或用户进行通知的事宜时，公司可通过会员所提供的电子邮箱地址或提示、弹窗、有线、无线等方法进行通知。
        </li>
        <li>
          2.
          公司如进行会员或用户整体的通知时，会在公司网站公告栏上发布7日以上，即视为与前项通知相同。但，会员本人的收费服务使用等与交易相关的重担事项，需要按照第1项的方法进行通知。
        </li>
      </ol>

      <p class='policy-tit'>第11条 [会员注销及资格丧失等]</p>
      <ol class='policy-num'>
        <li>
          1.
          会员想要注销会员时，可通过公司的客服中心，在线邀请注销，这时，公司会立即处理会员注销，并将其结果通知于会员。
        </li>
        <li>
          2.
          会员在注销会员时，不可继续使用已购买或已下载的内容、上传的帖子等。对此，公司已说明保管方法及删除，且会员未采取个人保管等措施时，公司概不负责其产生的后果。
        </li>
        <li>
          3.
          如会员违反本条款或关系法令时，公司可限制使用服务或使其丧失会员资格。
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第3章 服务利用合同</h4>

      <p class='policy-tit'>第12条 [内容等的告示]</p>
      <ol class='policy-num'>
        <li>
          1.
          公司将如下各项内容发布于该内容的使用初始画面或其封面上，便于用户了解。
          <ol class='policy-num'>
            <li>① 内容的名称或种类</li>
            <li>② 内容的制作及显示年月日</li>
            <li>③ 内容制作人的姓名（法人时，发布法人名称）</li>
            <li>④ 内容的内容、使用方法、使用费用及其他使用条件</li>
          </ol>
        </li>
        <li>
          2.
          公司向用户提供内容别可使用设备及为了使用而具备的最少限度的技术式样。
        </li>
      </ol>

      <p class='policy-tit'>第13条 [利用合同的成立等]</p>
      <ol class='policy-num'>
        <li>
          1.
          会员可根据公司提供的如下或与此类似的流程申请使用。公司在签署合同之前，关于如下各号事宜，为了让用户准确理解且无失误或错误的进行交易，提供如下信息。
          <ol class='policy-num'>
            <li>① 内容目录的阅览及选择</li>
            <li>② 内容的详细信息</li>
            <li>③ 确认订购商品及支付金额（退款规定说明）</li>
            <li>④ 关于申请使用内容的确认或对于公司确认的同意</li>
            <li>⑤ 支付方法的确认及选择</li>
            <li>⑥ 重新确认支付金额</li>
          </ol>
        </li>
        <li>
          2. 属于如下各号的会员之使用申请，公司可不予许可或保留许可。
          <ol class='policy-num'>
            <li>① 未实名或使用他人名义时</li>
            <li>② 填入虚假信息或未填入公司要求的内容时</li>
            <li>③ 服务相关设备无剩余或技术上或业务上有问题时</li>
            <li>④ 因公司难以管制的情形，对服务正常的使用产生阻碍时</li>
            <li>⑤ 未缴纳收费服务的使用费用时</li>
            <li>⑥ 收费服务申请金额与实际汇款金额不符时</li>
          </ol>
        </li>
        <li>
          3.
          会员根据第1项申请使用内容（服务）时，公司作为许可的表示，按照第10条第1项的方法通知于会员，从许可通知到达会员之日起，即视为合同成立。
        </li>
        <li>
          4.
          关于公司的许可表示包括对于会员申请使用的确认及服务提供可行与否、使用申请的更正、取消等相关信息等。
        </li>
        <li>
          5.
          根据本条第3项，在发送公司的许可通知以后，如发生地2项的3号乃至6号的理由时，公司可限制会员使用内容（服务），直到该理由解除为止。
        </li>
      </ol>

      <p class='policy-tit'>第14条 [自动支付及解除]</p>
      <ol class='policy-num'>
        <li>
          1.
          公司为了每月服务利用合同的自动更新及邀请支付费用，在服务使用期间及服务使用期间结束后，会在一段时间内保管会员的支付相关信息
        </li>
        <li>
          2.
          如会员申请自动支付，并且未另外申请解除时，不管实际使用与否，会在每月指定的结算日期及方式自动请款及结算收费服务的费用。
        </li>
        <li>
          3.
          通过自动支付解除的到期日为自动支付日起至1个月。解除自动支付时，会员可在定期支付日起至1个月内使用服务。
        </li>
        <li>
          4.
          未经他人的同意使用期结算信息时，会承担民事/刑事上的责任，并可根据关系法令授予相应惩罚。
        </li>
        <li>
          5.
          因结算信息变更、丢失信用卡及手机、其他理由等，无法完成定期支付时，在最后定期支付日起，经过1个月后会自动停止服务利用。
        </li>
        <li>
          6.
          因会员未缴纳使用费用等责任理由，导致定期支付停止及根据此的服务利用停止造成的损失，公司概不负责。
        </li>
      </ol>

      <p class='policy-tit'>第15条 [教材等的销售及派送]</p>
      <ol class='policy-num'>
        <li>
          1. 公司在各网站销售教材等产品时，按如下提供服务。
          <ol class='policy-num'>
            <li>① 可在公司网站购买关于内容服务的教材。</li>
            <li>② 教材等产品仅销售于在公司网站注册会员的会员。</li>
            <li>③ 如有会员申请购买教材时，公司会向会员发送受理确认通知。</li>
            <li>④ 公司可在网上提供会员教材派送的过程。</li>
            <li>
              ⑤
              教材派送原则上，从会员完成购买支付日起，7日以内进行派送，但因天灾地变、教材的库存不足等，会有所延迟。
            </li>
          </ol>
        </li>
        <li>
          2.
          与公司签署购买教材合同的会员在教材接收日起，7日以内可撤销订购（取消订单）。但，会员在收到教材等时，属于如下各号情形时，不可进行退货及更换。
          <ol class='policy-num'>
            <li>① 因会员的责任理由，造成教材等全部损害或毁损时</li>
            <li>② 因会员的使用或部分消费，造成教材等价值显著减少时</li>
          </ol>
        </li>
        <li>
          3.
          在本条第2项的撤销订购期间内，想以如下各号理由退货及更换已收到的教材时，在发现问题之后，30日或接收日起3个月以内进行申请，退货、更换费用由公司承担。但，已收到的教材需要发送给公司。
          <ol class='policy-num'>
            <li>① 派送的教材与订单内容有差异或与公司提供的信息有差异时</li>
            <li>② 派送的教材在公司的流通、处理中发生外观上的瑕疵时</li>
            <li>
              ③ 派送的教材为破损本（推测是在印刷/装订错误等制作教材时发生）时
            </li>
          </ol>
        </li>
        <li>
          4.
          并非以本条第3项的理由，对派送的教材等进行退货及更换时，会员需承担所有追加发生的费用。这时，会员需要承担的派送费以购买教材等当时的派送费为基准，济州岛等岛屿和山区及海外派送时，以一般派送公司的派送费为基准。
        </li>
        <li>
          5.
          根据撤销订购等，需要退款教材费用时，除了优惠券的实际支付费用中，扣除结算手续费和汇款手续费后，退款剩余余额。
        </li>
      </ol>

      <p class='policy-tit'>第16条 [“公司”的义务]</p>
      <ol class='policy-num'>
        <li>1. 公司需要诚实守信的行使法令和此条款指定的权利和履行义务。</li>
        <li>
          2.
          为了让会员安全的使用内容，公司需要保护个人信息（包括信用信息），且具备安全系统，公布及遵守个人信息处理方针。
        </li>
        <li>
          3. 公司需要采取措施，方便会员随时利用内容及确认其费用支付的明细。
        </li>
        <li>
          4.
          关于内容使用，公司如认可会员所提出的意见或不满时，需要立即对其处理。对于会员提出的意见或不满事项，可活用公告栏或电子邮件等，告知其处理过程及结果。
        </li>
        <li>5. 因公司违反此条款所指定的义务，造成用户损失时，公司对其赔偿。</li>
      </ol>

      <p class='policy-tit'>第17条 [会员的义务]</p>
      <ol class='policy-num'>
        <li>
          1.
          会员不可有如下行为，如会员违反此时，公司可指定期间，限制服务的全部或部分利用或催告一段时间后解除使用合同。但，公司如有如上制裁时，给与会员可辨明的机会，如会员证明无自身的故意或过失时，会员可按服务提供停止期限延长利用期限。
          <ol class='policy-num'>
            <li>
              ① 申请或变更时，发布虚假内容或盗用他人的信息（ID等个人信息）
            </li>
            <li>
              ②
              利用服务时获取的信息（内容），在没有公司的事前许可之前，以用户利用以外的目的进行复制或使用于出版、传送等或提供给第3方的行为
            </li>
            <li>③ 变更公司发布的信息（教案、讲课视频等内容）</li>
            <li>④ 发送或发布公司禁止的信息（电脑程序等）</li>
            <li>⑤ 侵害公司和其他第3方的版权等知识财产权</li>
            <li>⑥ 损伤公司及其他3方的名誉或妨碍业务的行为</li>
            <li>
              ⑦
              将淫秽或暴力性的文字或文章、视频、声响、其他违反公序良俗的信息公开或发布于公司网站的行为
            </li>
            <li>
              ⑧ 在没有公司的事前许可之前，利用服务销售商品等进行营业活动的行为
            </li>
            <li>
              ⑨
              在公共场所及以营利为目的的营业场所、卖场等地，公开播放公司所提供的内容，超出个人使用范围使用的行为
            </li>
            <li>
              ⑩ 妨碍其他用户的服务利用或冒充公司的运营团队、职员、相关人士的行为
            </li>
            <li>⑪ 其他非法或不当的行为</li>
          </ol>
        </li>
        <li>
          2.
          会员需要遵守对于关系法令、此条款的规定、利用说明及内容所公知的注意事项、由公司通知的事项等，不可有放妨碍其他公司业务的行为。
        </li>
        <li>
          3.
          会员在没有得到公司明确表示出的同意或许可之前，不可将服务的利用权限、其他利用合同上的地位转让、赠予或最为担保提供给他人。
        </li>
        <li>
          4.
          会员需要安全的管理自身的ID及密码，因管理疏忽、不正使用所发生的结果，由会员自行承担责任。
        </li>
      </ol>

      <p class='policy-tit'>第18条 [内容使用支付方法]</p>
      <p>对于使用内容的费用支付方法，可选择如下各号方法进行支付。</p>
      <ol class='policy-num'>
        <li>1. 信用卡支付</li>
        <li>2. 通过电子货币的支付(Paypal)</li>
      </ol>

      <p class='policy-tit'>第19条 [服务的提供及中断]</p>
      <ol class='policy-num'>
        <li>1. 内容服务以年中无休、1日24小时提供为原则。</li>
        <li>
          2.
          如有电脑等信息通信设备的维护、更换及故障、通信终端或运营商有正当理由时，可暂时终端内容服务的提供。这时，公司会按照第10条指定的方法通知于会员。但，公司有无法事前通知的不得已理由时，可在事后通知。
        </li>
        <li>
          3.
          因公司的责任理由，暂时中断收费（内容）服务的提供，导致会员发生损失时，会以延长未能使用服务的期限等方式进行赔偿。但，因第3方的故意或过失，导致服务中断或发生障碍，公司可不承担责任。
        </li>
        <li>
          4.
          公司因事业种类的转换、事业的放弃、单位之间的统合等理由，无法提供服务时，需按照第10条指定的方法，通知于会员，并根据第27条5项内容进行退款。
        </li>
        <li>
          5.
          公司在提供服务时，如判断有需要时，公司可施行定期诊断，定期诊断时间按照服务提供画面的通知进行。
        </li>
        <li>
          6.
          公司在提供服务时，如判断有需要时，可让用户安装另外的程序，如会员不想安装时，可拒绝安装程序。但，如会员拒绝安装程序时，有可能会限制服务的利用。
        </li>
        <li>7. 关于程序，公司可收集及利用用户的PC设备数据。</li>
        <li>
          8.
          对于前项的数据，公司只以改善程序或提供适合于用户使用环境的服务或技术为目的使用，不可使用于其他目的。
        </li>
        <li>9. 根据供应内容的提供单位或版权者的邀请，可限制部分内容的提供。</li>
      </ol>

      <p class='policy-tit'>第20条 [内容服务的变更]</p>
      <ol class='policy-num'>
        <li>
          1.
          公司如有正当的理由时，根据运营商、技术上的需要，可变更正在提供的内容服务。
        </li>
        <li>
          2.
          公司根据第1项变更服务时，按照第10条的方法将变更理由及变更内容通知于会员。
        </li>
        <li>
          3.
          本条第2项情形时，根据第5条2项的方法通知用户及获取同意。这时，公司对于拒绝同意的用户提供变更之前的服务。但，不可持续提供变更前服务时，可解除合同。
        </li>
      </ol>

      <p class='policy-tit'>第21条 [信息的提供及发布广告]</p>
      <ol class='policy-num'>
        <li>
          1.
          公司对于会员在利用服务时所需的各种信息可通知事项或电子邮件等方法提供给会员。但，会员可随时通过电子邮件等拒绝接收信息。
        </li>
        <li>
          2.
          想要将第1项的信息通过电话及传真传送时，需要获得会员的事前同意后再传送。
        </li>
        <li>
          3.
          关于服务的提供，公司可在该服务画面、公司的各网站、电子邮件等发布广告。收到有发布广告的电子邮件等的会员可拒绝接收邮件。
        </li>
        <li>
          4.
          通过公司发布的广告，会员参与其广告商的促销活动或联系或进行交易的结果发生损失或损害时，公司概不负责。
        </li>
      </ol>

      <p class='policy-tit'>第22条 [会员的帖子及删除]</p>
      <ol class='policy-num'>
        <li>
          1.
          对于会员上传的帖子中，如有违反本条款和信息通信网利用促进及信息保护等相关法律等关系法律的帖子或符合如下各号情形时，公司可立即对其删除。但，对于第10号情形，公司可事前在公司的通知事项中通知该事项。
          <ol class='policy-num'>
            <li>① 诽谤公司、其他会员或第3方或损伤名誉的内容时</li>
            <li>② 散布违反公共秩序及美风良俗的内容时</li>
            <li>③ 认定为与犯罪行为有勾结的内容时</li>
            <li>④ 侵害公司的版权、第3方的版权等其他权利的内容时</li>
            <li>⑤ 违反法令或以侵害他人权利的方式引起纠纷时</li>
            <li>⑥ 发布不必要或未获得承认的广告、促销品时</li>
            <li>
              ⑦
              盗用、冒充他人的个人信息来制订的内容或擅自伪/编造他人输入的信息的内容时
            </li>
            <li>⑧ 重复相同的内容，发布相同帖子等违反上传目的时</li>
            <li>⑨ 判断为违反了公司公告栏运营方针等时</li>
            <li>⑩ 公司判断服务用设备的容量不足时</li>
          </ol>
        </li>
        <li>
          2.
          因发布于公司所运营公告栏上的信息，受法律上利益侵害的人可向公司邀请对该信息删除或邀请发布辩驳内容。这时，公司立即采取所需的措施，并将其立即通知于申请人。
        </li>
      </ol>

      <p class='policy-tit'>第23条 [版权等的归属]</p>
      <ol class='policy-num'>
        <li>1. 对于公司制订的著作等的版权及其他知识财产权归属于公司。</li>
        <li>
          2.
          用户在利用公司提供的服务时，有获取的信息中，其知识财产权归属于公司或提供单位的信息在没有获得公司或提供单位的事前许可之前，不可复制、传送、出版、直播及其他方法，作为盈利目的使用或让第3方使用。
        </li>
        <li>
          3.
          用户在服务内发布的帖子版权受版权法的保护。公司为了宣传公司，仅可在限制的用途范围内活用会员的帖子。但，会员如提出异议时，立即中断使用。
        </li>
        <li>
          4.
          用户允许公司以如下各号目的在海内外使用用户发布的帖子。但，对于第2号和第3号，如用户提出异议时，公司在受理异议的时间段开始中断展示及发布。
          <ol class='policy-num'>
            <li>
              ①
              在服务内，用户帖子的复制、传送、展示、发布及为了将优秀帖子公布在服务画面中，对用户帖子的大小进行变换或以单纯化等方式进行修改。
            </li>
            <li>② 在公司运营的相关网站上的服务内，展示及发布用户的帖子。</li>
            <li>
              ③
              以宣传公司服务为目的，通过媒体、通信社等报道及播放用户的帖子内容。
            </li>
          </ol>
        </li>
        <li>
          5.
          即使有第4项的规定，公司除第4项各号指定的目的之外，以商业性目的（举例：向第3方提供帖子并获取了金钱方面的代价时）使用用户的帖子时，需要事前获得该用户的同意。对于帖子，公司的使用邀请、用户的同意及同意撤销按照电话、电子邮箱、传真等公司邀请的方式进行。
        </li>
        <li>
          6.
          对于会员注销或根据第17条1项丧失会员资格，公司可无需另外获取同意或通报流程，可直接删除会员的帖子。
        </li>
        <li>
          7.
          公司因公司的合并、营业转让、公司所运营的网站之间的统合等利用，可不改变原有帖子的内容，将帖子的发布位置进行变更。
        </li>
      </ol>

      <p class='policy-tit'>第24条 [个人信息保护]</p>
      <ol class='policy-num'>
        <li>
          1.
          公司根据信息通信网利用促进及信息保护的相关法律等关系法令指定的规定，努力保护用户的个人信息。对于个人信息的保护及使用，使用相关法令及公司的个人信息处理方针。
        </li>
        <li>
          2.
          公司在收集可识别用户个人的个人信息时，会接收用户的同意，用户可随时阅览公司保有的自身个人信息及要求改正错误，公司有义务立即对其采取所需措施。
        </li>
        <li>
          3.
          公司对于用户在申请利用等时所提供的信息和根据第1项所收集的信息，在没有得到用户的同意之前，不可利用于目的之外的其他目的或提供给第3方。但，如下情形例外处理。
          <ol class='policy-num'>
            <li>
              ①
              为了使用于统计、学术研究或市场调查，以无法识别特定个人的形态来提供时
            </li>
            <li>② 根据内容的提供，在结算费用时需要使用个人信息时</li>
            <li>③ 为了防止盗用，需要确认本人时</li>
            <li>④ 根据条款的规定或法令，有不可避免的所需理由时</li>
          </ol>
        </li>
        <li>
          4.
          公司为了保护个人信息，限定了管理人，并最少化管理人数，公司承担因个人信息的丢失、盗窃、泄漏、编造等造成的用户损失。
        </li>
        <li>
          5.
          公司或从其获取个人信息的人在用户同意的范围内，使用个人信息，在达成目的之后，需要立即销毁个人信息。
        </li>
      </ol>

      <p class='policy-tit'>第25条 [适用于韩国以外用户的特别条款]</p>
      <p>
        公司致力于向所有人提供适用于统一标准的全球化服务，同时也努力遵守各地区的当地法律。会员需要同意将个人数据传送至韩国并在韩国内进行处理。
      </p>
    </div>
    <div class='policy-section'>
      <h4 class='policy-subtitle'>第4章 服务利用合同的变更、取消、退款</h4>

      <p class='policy-tit'>第26条 [会员的服务利用合同变更]</p>
      <ol class='policy-num'>
        <li>
          1.
          原则上，会员已购买/注册的收费服务不可变更处理，解除后，可在下月注册其他使用权，但对于公司事前公知且指定的部分使用权，会员想要申请变更时，可预约1个月后变更（以下简称‘变更预约’）或立即变更处理（以下简称‘立即变更’）。
        </li>
        <li>
          2.
          想要变更预约时，截止到会员邀请变更之月的利用期限到期日，会员可使用现有购买/注册的使用权，利用期限到期日次日开始变更适用为邀请变更的使用权。
        </li>
        <li>
          3.
          想要立即变更时，会员如进行立即变更申请，公司在完成受理后，立即中断现有购买/注册的使用权，立即变更适用为申请的使用权。
        </li>
        <li>
          4.
          如会员申请变更的使用权费用高于已购买的使用权费用时，邀请后，可立即适用变更后的使用权。但，在下一个支付日之前不可取消变更邀请，至少要有1次支付后，方可重新变更使用权。
        </li>
        <li>
          5.
          如会员申请变更的使用权费用低于已购买的使用权费用时，当前使用中的使用权服务将维持，在下一个支付日以后变更为邀请的使用权。这时，在下一个支付日前可取消变更邀请。
        </li>
        <li>
          6.
          所有使用权的‘变更预约’、‘立即变更’，根据使用权的变更属性，一概不进行退款。
        </li>
      </ol>

      <p class='policy-tit'>第27条 [会员的合同取消、退款政策]</p>
      <ol class='policy-num'>
        <li>
          1.
          与公司签署服务利用合同的会员，只可在支付承认日起7日之内撤销订购。但，关系法令或公司采取了如下各号当中的一种措施时，有可能会限制会员的订购撤销权。
        </li>
        <li>
          2. 会员想要进行第1项的订购撤销（取消）时，可通过网站(Settings &gt;
          Subscription)、客服中心咨询等方法，向公司提出意向。公司对于会员提出的服务利用合同取消意向，会立即进行受理。
        </li>
        <li>
          公司确认会员的订购撤销、解除申请后，如有退款金额时，原则上，在受理会员提出的该意向后，在3个营业日以内，向支付方式别单位邀请请款更正乃至取消，与会员支付费用的相同支付方式进行退款。但，以告知不可撤销（取消）订购的内容（服务）等，按照关系法令（电子商务法、内容产业振兴法等）采取了措施时，会限制会员的订购撤销权。
        </li>
        <li>
          4.
          公司在退款时，与用户支付费用的相同方，对支付费用的全部或一部分进行退款。但，不可用相同方法退款时，立即将其告知于用户，按照用户选择的方法进行退款。但，公司已在事前告知于会员及属于如下情况时，个别支付方式别退款方法、可退款期间等会有所差异。
          <ol class='policy-num'>
            <li>
              ① 信用卡等需要确认收付的支付方式，从确认收付日起至3个营业日以内
            </li>
            <li>
              ②
              支付方式别单位通过与公司的契约，提前制订好了可停止费用请款乃至取消支付的期限等，并已超出该期限时
            </li>
            <li>③ 会员有收费服务/使用权的使用结果、有获取的利益或中途解除时</li>
            <li>
              ④ 会员未立即向公司提供需要退款处理的信息乃至资料时
              （现金退款时，未提交申请人的账户及身份证复印件或提供他人名义的账户等）
            </li>
            <li>⑤ 有该会员所明确指出的意向时</li>
          </ol>
        </li>
        <li>
          5.
          对于退款使用权，公司遵守相关法律。退款以订购（购买及支付）日未基准，在1个月以内取消使用权及邀请立即退款时，公司计算使用费用（已使用日数X每日使用费用），对其扣除后进行退款。
        </li>
        <li>
          6.
          由公司进行退款时，对于会员在利用服务而获取的利益和违约金等，公司扣除根据该事例所明示扣除的金额后，对剩余金额进行退款。
        </li>
        <li>
          7.
          公司根据内容用户保护方针等，可扣除由公司承担或需要承担的附带费用、手续费后，对剩余金额进行退款。
        </li>
        <li>
          8.
          会员获赠收费服务或使用权、通过促销活动等免费/无偿获取等，对于会员未直接支付费用的服务，公司不承担退款义务。
        </li>
        <li>
          9.
          会员违反了此条款中指定的会员义务，可采取解除合同或限制利用服务、邀请损失赔偿等措施，解除合同后，如有需要退款的金额，在一定范围内，扣除会员需要自行承担的费用后，对剩余金额进行退款。这时，会员对于公司采取的措施，按照公司指定的流程，提出异议，如公司确认其异议正当时，可重新开始利用服务等，如用户证明自身无故意、过失时，公司按停止服务期间延长利用期限。
        </li>
        <li>
          10.
          根据会员的申请或同意，每月定期支付中的收费服务，该会员如脱线收费服务的使用费用，在发生拖延日会自动解除使用权，想要通过每月定期支付维持优惠服务的会员需要采取事前措施，以防支付费用的拖欠或支付方式的拖延。
        </li>
        <li>
          11.
          利用每月定期支付收费服务的会员在注销会员时，该使用权会立即解除。公司向会员确认是否有退款意向，但会员无退款邀请，主动注销时，公司不承担包括复原该账号等一切责任
        </li>
      </ol>

      <p class='policy-tit'>第28条 [错收费用的返还]</p>
      <ol class='policy-num'>
        <li>
          1.
          对于发生的错收费用，按照与支付利用费用的相同方法，对错收费用全额退款。但，不可用相同方法进行退款时，对其事前告知。
        </li>
        <li>
          2.
          因公司的责任理由发生错收费用时，不管合同费用、手续费等如何，对错收费用全额退款。但，因用户的责任理由发生错收费用时，对于公司在退款错收费用而所需的费用，在合理的范围内需要由用户承担。
        </li>
        <li>
          3.
          如公司拒绝对于用户所主张的错收费用之退款，有责任证明收缴了正当的利用费用。
        </li>
      </ol>

      <p class='policy-tit'>第29条 [公司的合同解除及利用限制]</p>
      <ol class='policy-num'>
        <li>
          1.
          如用户有第13条2项及第17条1项指定的行为，公司可无需事前通知，立即解除合同或指定某个期间，限制利用服务。
        </li>
        <li>
          2.
          对于第1项的解除合同，按照公司自主制订的通知方法，在用户表示其意向时，方可发生效力。
        </li>
        <li>
          3. 根据用户的责任理由，解除利用合同时，不可适用第27条的退款规定。
        </li>
      </ol>

      <p class='policy-tit'>第30条 [关于服务瑕疵等的损失赔偿]</p>
      <ol class='policy-num'>
        <li>
          1.
          如发生服务的中断/障碍时，从服务中断/障碍发生时段为基准，在24小时之内，公司会事前告知用户。但，因用户的责任发生服务中断或障碍时，其时间不包含在服务中断/时间内
        </li>
        <li>
          2.
          公司如事前告知了服务中断/障碍时，可根据如下各项内容，赔偿用户的损失。但，以服务改善为目的实施的设备诊断及维修，以1个月为基准，其中24小时不包括在中断/障碍时间内。
          <ol class='policy-num'>
            <li>
              ①
              以1个月为基准，服务中断/障碍时间超出10小时时：对于10个小时以及超出10个小时的2倍时间，免费延长至服务利用时间内
            </li>
            <li>
              ②
              以1个月为基准，服务中断/障碍时间未超出10小时时：对于中断/障碍时间，免费延长至服务利用时间内
            </li>
          </ol>
        </li>
        <li>
          3. 公司如未事前告知中断/障碍时，可根据如下各项内容，赔偿用户的损失。
          <ol class='policy-num'>
            <li>
              ①
              1个月内，服务中断/障碍发生累积时间超出72小时时：合同解除及退款包括未利用期间在内的利用款项及损失赔偿（但，公司可证明无故意或过失时，可免除损失赔偿责任）
            </li>
            <li>
              ②
              因公司的责任理由，导致服务中断或发生障碍时：将服务利用时间免费延长至服务中断/障碍时间的3倍
            </li>
            <li>
              ③
              因不可抗力或第3方的非法行为等，发生服务的中断或障碍时：不可解除合同，但对于服务中断或障碍时间，可免费延长至服务利用时间内
            </li>
          </ol>
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第5章 其他</h4>

      <p class='policy-tit'>第31条 [免责条款]</p>
      <ol class='policy-num'>
        <li>
          ①
          公司因天灾地变或相当于此的不可抗力，无法提供内容时，可免除内容提供的相关责任。
        </li>
        <li>② 因用户的责任理由，导致内容利用发生障碍时，公司概不负责。</li>
        <li>
          ③
          对于会员发布的关于内容的信息、资料、事实的信赖度、准确性等内容，公司概不负责。
        </li>
        <li>
          ④
          用户相互之间或用户与第3方之间以内容为媒介发生纠纷等时，公司概不负责。
        </li>
      </ol>

      <p class='policy-tit'>第32条 [纠纷的解决]</p>
      <ol class='policy-num'>
        <li>
          ①
          发生纠纷时，公司会反映对用户提出的正当意见或不满，并迅速采取适当的措施。但，无法迅速处理时，公司会将其原因和处理日程通知于用户。
        </li>
        <li>
          ②
          发生纠纷时，公司或用户可向内容产业振兴法第28条指定的内容纠纷调解委员会申请纠纷调解。
        </li>
      </ol>

      <p class='policy-tit'>第33条 [准据法及裁判管辖]</p>
      <ol class='policy-num'>
        <li>① 本条款韩国法进行规定及解释。</li>
        <li>
          ②
          因公司与用户之间发生纠纷，而提起诉讼时，需要在韩国首尔中央地方法院提起诉讼。
        </li>
      </ol>

      <p class='policy-tit'>[附则]</p>
      <p>此条款从2021年3月22日起执行。</p>
    </div>
  </div>
);

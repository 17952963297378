import React, { useEffect, useState } from 'react';
import Board from 'component/Board';
import SearchEmpty from 'component/atoms/SearchEmpty';
import Loading from 'component/atoms/Loading';

const SearchBoard = ({ initState, select, searchWords, t }) => {
  const [searchList, fetchSearchList] = initState;
  const { data, loading } = searchList;
  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const type = () => {
    let param = {};
    if (select === 'All') {
      param = { q: searchWords };
    } else if (select === 'Title') {
      param = { titles: searchWords };
    } else if (select === 'Content') {
      param = { contents: searchWords };
    } else if (select === 'Author') {
      param = { authors: searchWords };
    } else if (select === 'Comment') {
      param = { repliers: searchWords };
    }
    return param;
  };

  useEffect(() => {
    fetchSearchList(0, type());
  }, [select, searchWords]);

  const onNext = () => fetchSearchList(data.number + 1, type());

  useEffect(() => {
    if (data && data.first) {
      setSlideItem([...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    } else if (data && !data.first) {
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [searchList]);

  if (loading) return <Loading />;

  return !slideItem.length ? (
    <SearchEmpty />
  ) : (
    <Board boardList={slideItem} next={onNext} hasMore={hasMore} t={t} />
  );
};

export default SearchBoard;

import React, { useState, useEffect } from "react";
import Style from "./styled/Process.style";
import { useSelector } from "react-redux";
import { useAxios } from "common/hook/useAxiosHook";
import couponApi from "api/coupon";
import { priceFormat } from "common/util/util";
import { useHistory, useLocation } from "react-router";

const Coupon = ({ currency, setCoupon, t }) => {
  const { state, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const productId = searchParams.get('productId');
  const history = useHistory();
  const priceCurrency = (price) =>
    currency === "KRW" ? priceFormat.korea(price) : priceFormat.usa(price);

  const [couponData, setCouponData] = useState({
    plan: state.planCode,
    productIdx: productId,
    couponNumber: "",
  });
  const token = useSelector((state) => state.user.token);

  const [{ data }, refreshCouponData] = useAxios(
    couponApi.getPlanCouponData,
    [],
    true
  );

  const couponApply = () => {
    if (!couponData.plan || !couponData.couponNumber) return;
    refreshCouponData({ token, currency, ...couponData });
  };

  const onChange = (e) => {
    const { value, name } = e.target || e;
    setCouponData((prev) => ({ ...prev, ...{ [name]: value } }));
  };

  useEffect(() => {
    couponApply();
  }, [currency]);

  useEffect(() => {
    //성공하면
    if (!data) {
      return;
    }
    if (data.available) {
      setCoupon((prev) => ({
        ...prev,
        ...{ couponNumber: couponData.couponNumber, discount: data.amountOff },
      }));
    } else {
      setCoupon({ couponNumber: "", discount: 0 });
    }
  }, [data]);

  if (!state) return history.goBack();

  return (
    <Style.InputContainer className="coupon">
      <label htmlFor="coupon">{t("paymentInfo.orderForm.coupon")}</label>
      <Style.Input
        id="coupon"
        type="text"
        name="couponNumber"
        onChange={(e) => onChange(e, "couponNumber")}
      />
      <button onClick={couponApply}>
        {t("paymentInfo.orderForm.couponBtn")}
      </button>
      {data &&
        (!data.available ? (
          <span className="error">{data.unavailableReason}</span>
        ) : (
          <span>{`${data.code} ${priceCurrency(
            data.amountOff
          )} discounts applied!`}</span>
        ))}
    </Style.InputContainer>
  );
};

export default Coupon;

import styled from "styled-components";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const StylePaymentConfirmModal = {
  Container: styled.div`
    .imgContainer {
      margin-bottom: 3em;
      text-align: center;
    }
    .desc {
      margin-bottom: 0.1em;
      text-align: center;
      font-size: 1.6em;
      font-weight: 400;
      > span {
        font-weight: 500;
      }
    }
    > .btnContainer {
      display: flex;
      justify-content: center;
      button {
        display: inline-block;
        width: 39.8%;
        padding: 1.125em 0;
        border: 1px #d1d1d1 solid;
        border-radius: 0.375em;
        color: #999;
        font-size: 1.6em;
        font-weight: 700;
        &.primary {
          margin-left: 0.4375em;
          border: none;
          background-color: #485cc7;
          color: #fff;
        }
      }
    }
  `,

  Image: styled(AiOutlineExclamationCircle)`
    background-color: transparent;
    fill: #d1d1d1;
  `,
  ErrorMessage: styled.p`
    display: block;
    margin-top: 14px;
    color: red;
    font-size: 1.4em;
    font-weight: 700;
  `,
};

export default StylePaymentConfirmModal;

import { asyncApi } from 'common/util/asyncApi';

const pathName = '/api/v1/faqs';

/**
 * getList 랜딩페이지 faq 목록
 */
const faqApi = {
  getList: async (lang = 'en') => {
    let response = await asyncApi({ url: pathName + `?locale=${lang}` });
    return response.data;
  },
  getVideoClassList: async (lang = 'en') => {
    let response = await asyncApi({ url: `${pathName}/videoclass` + `?locale=${lang}` });
    return response.data;
  },
};

export default faqApi;

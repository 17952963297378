import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageHeader from 'component/atoms/PageHeader';
import getYour from 'assets/images/mycurriculum_new/icon.png';
import getYour2 from 'assets/images/mycurriculum_new/icon2.png';
import arrow from 'assets/images/mycurriculum_new/arrow.png';
import arrow2 from 'assets/images/mycurriculum_new/arrow2.png';
import LevelIcon1 from 'assets/images/mycurriculum_new/icon3.png';
import LevelIcon2 from 'assets/images/mycurriculum_new/icon4.png';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const CourseCurriculum = styled.div`
  position: relative;
  border-radius: 5px;
  width:100%;
  height:9rem;
  padding:1.4rem 3.4rem;
  font-size: 2.6em;
  color:#000000;
  background:#f2f2f2;
  font-weight:700;
  margin-top:6rem;
  display: flex;
  align-items: center;;
  .icon{margin-right:1.5rem;}
  .more{
    position:absolute;
    right:3.4rem;
    top:50%;
    transform: translateY(-50%);

  }

  @media (max-width: 1024px) {
    height:auto;
    align-items: flex-start;;
    padding:1.5rem;
    margin-top:2rem;
    .more{
      height:1.5rem;
      top:2.8rem;
      transform: translateY(0);
    }
  }


`;



const MyClassWrap = styled.div`
  display: flex;
  gap:3.4rem;
  margin-top:3rem;
  @media (max-width: 1024px) {
    gap:15px;
    margin-top:15px;
    flex-direction: column;
  }


`
const MyClass = styled.div`
  width:50%;
  display: flex;
  padding:2.5rem;
  border-radius: 5px;
  background: #1e2024;
  align-items: center;

  @media (max-width: 1024px) {
    width:100%;
  }


`;
const MyClassTitle = styled.h2`
  color:#c9cbd1;
  font-size:2rem;
  width:14rem;
  font-weight: 400;
`;
const MyClassCon = styled.div`
  flex:1;
  font-size:1.6rem;
  span{
    font-size:3rem;
    font-weight:700;
  }
`;
const MyClassButton = styled.button`
  width:10rem;
  height:3.4rem;
  color:#999999;
  border:1px solid #999;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:1.4rem;
`;

const CurrProgressWrap = styled.div`
  padding:3rem;
  border-radius: 0.5rem;
  background: #1e2024;
  margin-top:3rem;
`
const CurrProgressTitle = styled.div`
  color:#c9cbd1;
  font-size:2rem;
  border-bottom:1px solid #35373a;
  padding-bottom:2rem;
  font-weight: 400;

`
const CurrProgress = styled.div`
  padding:0 4rem;;
  @media (max-width: 1024px) {
    padding:0
  }
`
const ProgressItems = styled.ul`
  display:flex;
  flex-direction: column;
  gap:3rem;
  padding:3rem 0 2rem;
  @media (max-width: 1024px) {
    gap:1.5rem;
  }
`
const ProgressItem = styled.li`
  display: flex;;
  &.item1{
    .bar{background-color:#00acb2;}
  }
  &.item2{
     .bar{background-color:#80aa00;}
  }
  &.item3{
     .bar{background-color:#8b56e3;}
  }
  @media (max-width: 1024px) {
    flex-direction: column;
    width:100%;
  }


`
const ItemTitle = styled.h3`
  color:#ffffff;
  font-size:1.6rem;
  width:25rem;
  @media (max-width: 1024px) {
    margin-bottom:11px;
    p{
      font-size:10px;
      color:#8e9199;
    }
  }
`
const Progress = styled.div`
  position: relative;
  width:100%;
  background: #2b2e35;
  border-radius: 0.5rem;
  height:2.7rem;
  > span{
    position:absolute;
    right:2rem;
    top:50%;
    transform: translateY(-50%);
    color:#8e9199;
    font-size:1.4rem;
  }
`
const Bar = styled.div`
  width:${(props) => props.persent + '%'};
  padding:0 2rem;
  height:100%;
  border-radius: 0.5rem;
  display:flex;
  align-items: center;;
  span{
    color:#ffffff;
    font-size:1.4rem;
    min-width:20rem
  }
  @media (max-width: 1024px) {
    padding:1rem;
  }

`

const NoLevelTest = styled.div``
const LevelTestComplete = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    width:100%;
  }


`
const LevelTitle = styled.h3`
  color:#000000;
  font-size:2.6rem;
  font-weight:600;
  > img{
    height:5.8rem;
  }
  @media (max-width: 1024px) {
    font-size:13px;
    > img{
      height:30px;
    }
  }


`
const LevelInfos = styled.ul`
  margin-left:4rem;
  background:#fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:1.2rem;
  width:51rem;
  @media (max-width: 1024px) {
    width:100%;
    margin-left:0;
    margin-top:2rem;
  }

`
const LevelInfo = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width:50%;
  gap:2rem;
  border-left:1px solid #e8e8e9;
  &:first-child{border-left:none;}
  img{
    height:3.7rem;
  }
  p{
    display: flex;
    align-items: center;
    justify-content: center;
    color:#666666;
    font-size:1.6rem;
    gap:1rem;
    span{
      color:#000000;
      font-size:3.2rem;
    }
  }

  @media (max-width: 1024px) {
    img{
      height:20px;
    }
    p{
      gap:5px;
      font-size:10px;
      span{font-size:18px;}
    }
  }


`
const MyCurriculumContents = ({ data }) => {
  const [isMobile, setisMobile] = useState(false);
  const history = useHistory();

  const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  const moveToLevelTest = () => {
    history.push("/level-test")
  }

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    }
    window.addEventListener("resize", resizingHandler);
    return () => {
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);

  const [studyDays, setStudyDays] = useState(0);

  useEffect(() => {
    if (data && data.curriculumCourseResponses) {
      var sum = 0;
      data.curriculumCourseResponses.forEach((course) => {
        sum += course.classCount;
      });
      setStudyDays(sum);
    }
  }, [data])

  return (
    <>
      <PageHeader
      title={"My Curriculum"}
      //buttonText={"See All Curriculums"}
      type={"mycurriculum"} />
      {(data && data.id) ?
        <>
          <CourseCurriculum>
            <LevelTestComplete>

              <LevelTitle><img src={getYour2} alt="icon" className='icon' /> master TOPIK Level {data.languageLevel}</LevelTitle>
              <LevelInfos>
                <LevelInfo>
                  <img src={LevelIcon1} />
                  <p><span>{studyDays}</span>Study Days</p>
                </LevelInfo>
                <LevelInfo>
                  <img src={LevelIcon2} />
                  <p><span>{data.minTime} - {data.maxTime}</span>min/day</p>
                </LevelInfo>
              </LevelInfos>
              <Link to={`/classroom/curriculum/${data.curriculumChargeId}`}>
                <img src={arrow2} alt="more" className='more' />
              </Link>
            </LevelTestComplete>


          </CourseCurriculum>


          <MyClassWrap>
            <MyClass>
              <MyClassTitle>My Level</MyClassTitle>
              <MyClassCon>
                Lv .<span>{data.languageLevel}</span> / {data.levelName}
              </MyClassCon>
              <MyClassButton onClick={moveToLevelTest}>Test Again</MyClassButton>
            </MyClass>
            <MyClass>
              <MyClassTitle>My Goal</MyClassTitle>
              <MyClassCon>
                TOPIK / Lv .<span>{data.memberGoal}</span>
              </MyClassCon>
              <MyClassButton onClick={moveToLevelTest}>Change</MyClassButton>
            </MyClass>
          </MyClassWrap>
          <CurrProgressWrap>
            <CurrProgressTitle>Curriculum progress</CurrProgressTitle>
            <CurrProgress>
              <ProgressItems>
                {data.curriculumCourseResponses && data.curriculumCourseResponses.map((course, index) => (
                    <ProgressItem className={`item${index % 3 + 1}`}>
                      <ItemTitle>{index + 1}. {course.title}</ItemTitle>
                      <Progress>
                        <Bar className='bar' persent={course.progressPercent}><span>{course.progressPercent}%</span></Bar>
                        <span>{course.viewClassCount} / {course.classCount} Days</span>
                      </Progress>
                    </ProgressItem>
                  )
                )}
              </ProgressItems>
            </CurrProgress>
          </CurrProgressWrap>
        </> :
        <>
          <CourseCurriculum>
            <NoLevelTest>
              <img src={getYour} alt="icon" className='icon' />
              <Link to="/level-test">
                Get Your Course Curriculum
                <img src={arrow} alt="more" className='more' />
              </Link>
            </NoLevelTest>
          </CourseCurriculum>

        </>
      }
    </>
  );
};

export default MyCurriculumContents;

import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import LevelTestHeader from 'container/LevelTestPage/common/LevelTestHeader';
import checkState from 'container/LevelTestPage/common/checkState';
import Step from 'container/LevelTestPage/common/Step';
import { Button, Layout } from 'container/LevelTestPage/styled';
import { useTranslation, Trans } from 'react-i18next';

const Index = () => {
  const { t } = useTranslation('levelTestPage');

  checkState();

  const history = useHistory();
  const { state } = useLocation();

  const handleClick = (selected) => {
    const url = selected === 'self'
      ? '/level-test/check-level-self'
      : '/level-test/check-level-guide';
    history.push({
      pathname: url,
      state: state,
    });
  };

  return (
    <>
      <Step activeIndex={2} />

      <Layout.CheckLevel>
        <Layout.CheckLevelBox>
          <LevelTestHeader
            fontSize={24}
            title={t('checkLevel.passTest.title')}
            text={t('checkLevel.passTest.desc')}
          />
          <Layout.ButtonGroup className='center'>
            {/* {selectedSubjectId} */}
            <Button.Button onClick={() => handleClick('self')}>
            {t('checkLevel.passTest.button')}
            </Button.Button>
          </Layout.ButtonGroup>
        </Layout.CheckLevelBox>
        <Layout.CheckLevelBox>
          <LevelTestHeader
            fontSize={24}
            title={t('checkLevel.takeTest.title')}
            text={t('checkLevel.takeTest.desc')}
            />
          <Layout.ButtonGroup className='center'>
            <Button.Button onClick={() => handleClick('test')}>
            {t('checkLevel.takeTest.button')}
            </Button.Button>
          </Layout.ButtonGroup>
        </Layout.CheckLevelBox>
      </Layout.CheckLevel>
      <Button.ButtonGroup className='center'>
        <Button.ReturnLink to='/level-test'>{t('goBack')}</Button.ReturnLink>
      </Button.ButtonGroup>
    </>
  );
}

export default Index;

import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLogo = {
  Wrapper: styled.div`
    float:left;
    display: table;
    height: 9em;
  `,
  logo: styled.h1`
    display:table-cell;
    vertical-align:middle;
    width: 21em;

    a {
      display: block;
      img.shot{display: none;}

    }
    @media (max-width: 1150px) {
        width: 2.518em;
        a{img.shot{display: block;}
        img.long{display: none;}
    }
      }

    &.logoLogin {
      width: 2.518em;
    }
  `,
};

export default StyledLogo;

import React from 'react';
import styled from 'styled-components';
import Img from 'component/atoms/Img';
import errorImage from 'assets/images/ico_error.png';
import Pencil from 'component/atoms/Pencil';

const Style = {
  Container: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding-top: 60em;

    img {
      width: 8em;
      height: 8em;
      object-fit: cover;
    }
  `,
  H2: styled.h2`
    margin: 0.5em 0;
    color: #1e2024;
    font-size: 3.4em;
    font-weight: 700;
  `,
  Desc: styled.p`
    color: #8e9199;
    font-size: 2em;
    font-weight: 400;
  `,
};
const InProgressPage = () => {
  window.document.body.style.backgroundColor = '#ECEEF9';
  return (
    <Style.Container>
      <div>
        <Pencil />
        <Style.H2>제출 되었습니다</Style.H2>
        <Style.Desc> 채점중입니다</Style.Desc>
      </div>
    </Style.Container>
  );
};

export default InProgressPage;

import React, { useEffect, useState } from 'react';
import Style from '../styled/List.style';
import ListItem from 'container/HelpPage/List/MainListItem';
import { lowerCase } from 'lodash';
import { Link } from 'react-router-dom'
import { useAxios } from 'common/hook/useAxiosHook';
import helpApi from 'api/help';

const MainList = ({ t }) => {

  const [selectedCategory, setCategory] = useState('ALL');

  const categorys = ['ALL', 'Guide', 'Notice', 'Event', 'Study'];

  const [articleList, setArticleList] = useState([]);

  const [mainListData, fetchMainListData] = useAxios(
    helpApi.getSearchList,
    [],
    true
  );

  useEffect(() => {
    fetchMainListData("",selectedCategory.toLowerCase() ,1, 10);
  }, [selectedCategory])

  useEffect(() => {
    if (mainListData && mainListData.data) {
      setArticleList(mainListData.data.docList);
    }
  }, [mainListData])

  return (
    <Style.MainListWrap>
      <Style.CategoryWrap>
        {/* <Style.BtnCategory className='on'>ALL</Style.BtnCategory>
          <Style.BtnCategory>Guide</Style.BtnCategory>
          <Style.BtnCategory>Notice</Style.BtnCategory>
          <Style.BtnCategory>Event</Style.BtnCategory>
          <Style.BtnCategory>Study</Style.BtnCategory> */}
        {
          categorys.map((item) => {
            return (
              <Style.BtnCategory
                className={selectedCategory === item ? 'on' : ''}
                onClick={() => setCategory(item)}>
                {item}
              </Style.BtnCategory>
            );
          })
        }
      </Style.CategoryWrap>

      <Style.ItemWrap className='main'>
        {articleList &&
          articleList.map((item) => {
            return (
              <ListItem item={item} />
            );
          })
        }
      </Style.ItemWrap>

      <Style.MainListBtn>
        <Style.ButtonMore>
          <Link to={`/help/${lowerCase(selectedCategory)}`}>
            {t('button.more')}
          </Link>
        </Style.ButtonMore>
      </Style.MainListBtn>
    </Style.MainListWrap>
  );
};
export default MainList;


import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'component/molecules/BoardReply/index.style';
import UserThumbnail from 'component/atoms/UserThumbnail';

/**
 * @param {*} param
 * @param {*} param.replierList 댓글 목록
 * @param {*} param.length 댓글 개수
 * @param {*} param.className
 * @returns
 */
const BoardReply = ({ replierList, className }) => {
  if (!replierList.length) return null;

  return (
    <Styled.Wrapper className={className && className}>
      <Styled.ImgContainer>
        {replierList.map(({ profilePath, name }, index) => {
          if (index < 3)
            return (
              <UserThumbnail
                className='reply-thumb'
                src={profilePath}
                alt={name}
                key={index}
              />
            );
        })}
      </Styled.ImgContainer>
      {replierList.length > 3 && (
        <Styled.Count>+ {replierList.length - 3}</Styled.Count>
      )}
    </Styled.Wrapper>
  );
};

BoardReply.propTypes = {
  replierList: PropTypes.array.isRequired,
  length: PropTypes.number,
  className: PropTypes.string,
};

export default BoardReply;

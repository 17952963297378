import React, { useState } from 'react';
import styled from 'styled-components';
import StyleContainer from './styled/index.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';
import Service20210302 from 'container/TermsPage/contents/2021/Service20210302';
import Service2014 from 'container/TermsPage/contents/2014/Service20140728';
import Service20230915 from 'container/TermsPage/contents/2023/Service20230915';
import TermsSelectBox from 'container/TermsPage/SelectBox';

const Style = styled.div`
  > header {
    margin-top: 3em;
  }
`;
const termsOptions = [
  { label: '2023.09.15', value: '2023.09.15' },
  { label: '2021.03.02', value: '2021.03.02' },
  { label: '2014.07.28', value: '2014.07.28' },
];

const TermsOfService = () => {
  const { t } = useTranslation('termsPage');

  const [selected, setSelect] = useState(termsOptions[0].value);

  const services = {
    '2023.09.15': <Service20230915 />,
    '2021.03.02': <Service20210302 />,
    '2014.07.28': <Service2014 />,
  };

  const onChange = (e) => {
    setSelect(e.value);
  };

  return (
    <ContentsWrapper>
      <SeoHelmet title={SeoData.terms.title} desc={SeoData.terms.desc} />

      <TermsSelectBox
        placeholder={termsOptions[0].value}
        optionList={termsOptions}
        onChange={(e) => onChange(e)}
        width={'20em'}
        isSearchable={false}
      />
      <div style={{ clear: 'both' }}></div>
      <Style>
        <PageHeader title={t('title')} text={t('subtitle')} />
      </Style>

      <StyleContainer>{services[selected]}</StyleContainer>
    </ContentsWrapper>
  );
};

export default TermsOfService;

import React from 'react';
import Styled from './index.style';
import { Link } from 'react-router-dom';
import H4 from 'component/atoms/H4';
import VideoIcon from 'component/atoms/Icon/VideoIcon';
import LinkIcon from 'component/atoms/Icon/LinkIcon';
import VisibleIcon from 'component/atoms/Icon/VisibleIcon';
import { TagLink } from 'component/atoms/TagLink';
import ThemeLike from 'component/molecules/ThemeLike';

const ThemeListHeader = ({
  id,
  title,
  tag,
  videoCount,
  likeCount,
  isOpen,
  isGood,
  categoryName = '#none',
}) => {
  return (
    <Styled.Container>
      <H4>
        <Link to={`/theme/${id}`}>{title}</Link>
      </H4>
      <Styled.Aside>
        <TagLink to={`/theme/tag/${tag}`}>#{tag}</TagLink>
        <Styled.AsideInner>
          <VideoIcon>{videoCount}</VideoIcon>
          <ThemeLike id={id} count={likeCount} isGood={isGood} />
          <VisibleIcon className={!isOpen && 'hidden-icon'} />
          <span className='bar' />
          <Link to={`/theme/${id}`}>
            <LinkIcon />
          </Link>
        </Styled.AsideInner>
      </Styled.Aside>
    </Styled.Container>
  );
};
export default ThemeListHeader;

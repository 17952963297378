import styled from 'styled-components';
import { Link } from 'react-router-dom';
import imgMyArrow from 'assets/images/gnb_new/mypage_arrow.png';
import arrow2 from 'assets/images/gnb_new/arrow2.png';
import StyledForm from 'component/form/ModalForm.style';

const StyledUserNav = {
  Container: styled.div`
    position: relative;
    display: inline-block;
    vertical-align:middle;
    margin: 3em 0 0 1.8em;

    &:before {
        content: '';
        position: absolute;
        top: 39px;
        right: 3px;
        width: 200px;
        height: 25px;
        background: url(${imgMyArrow}) no-repeat right center;
        display: none;
        @media (max-width: 1024px) {
          display:none;
        }
      }

      .user-nav-list {
        min-height: 520px;
        opacity: 1;
        transition: all 0.3s ease;
      }
    &.active{
      @media (max-width: 1024px) {
          .user-nav-list{
            right: 0;
          }
        }

      &:before {display:block;
        @media (max-width: 1024px) {
          display:none;
        }
      }
      .user-nav-list{
        display: block;
      }
    }
    /* @media (max-width: 1024px) {
      display: none;
    } */
  `,
  Button: styled.button`
    position: relative;
    display: inline-block;
    width: 3em;
    height: 3em;
    background-color: #fff;
    border-radius: 100px;

  `,
  List: styled.div.attrs({ className: 'user-nav-list' })`
    position: relative;
    position: absolute;
    top: 6.1em;
    right: -7rem;
    width: 38rem;
    /* height: 0; */
    border-radius: 0.6em;
    background-color: #fff;
    padding:2.5rem 0;
    box-shadow: 5px 5px 10px rgba(15, 16, 18, 0.3);
    opacity: 0;
    transition: height 0.3s ease, opacity 0.3s ease 0.1s;
    display: none;
    @media (max-width: 1024px) {
      position: fixed;
      top: 0;
      right: -100%;
      z-index: 1000;
      width: 100%;
      height: 100%;
      letter-spacing: 0;
      overflow: auto;
      transition: all 0.4s ease;
      border-radius: 0%;
      padding-top:0;
    }
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    gap:1rem;
    .my-trial,
    .my-curr{
      border:1px solid #c9cad0;
      border-radius: 0.6em;
      width:calc(100% - 6rem);
      margin:0 auto 1rem;
    }
    .my-trial{
      background: #fff;
      a{
        color:#485cc7;
        font-size:1.4rem;
        padding:1.5rem 2rem;
        line-height:1.5;
        width:100%;
        height:100%;
        display: block;
        background: url(${arrow2}) 95% center no-repeat;
        span{
          display: inline-flex;
          font-weight: 600;
        }
      }
      &:hover{
        background: #f4f6fa;
      }
    }
    .my-curr{
      background: #fff;;
      a{
        color:#666666;
        font-size:1.4rem;
         padding:1.5rem 2rem;
         line-height:1;
         display: block;
         height:auto;
         background: url(${arrow2}) 95% center no-repeat;
        span{
          font-weight: 600;
        }

      }
      &:hover{
          background: #f4f6fa;
        }
    }
  `,
  Link: styled(Link)`
    display: block;
    padding: 0 10px 0 23px;
    font-size: 16px;
    font-weight: 600;
    color: #1e2024;
    line-height: 40px;
    height:40px;
    transition: all 0.1s ease;
    &:hover {
      color: #485cc7;
    }

  `,
  Item: styled.span`
    display: block;

    .submenu{
      padding:10px 0;
      background: #f6f6f6;
      display: none;
      a{
        font-size: 1.4rem;
        color: #777777;
        height:25px;
        line-height:25px;
        padding:0 calc(3rem + 23px);
        &:hover{
          background: #eaeaeb;;
        }
      }

      &.active{
        display: block;

      }
    }


  `,
  MyList:styled.div`
    width:100%;
     > span{

        position:relative;
        > a, .setting{
          width:calc(100% - 6rem);
          margin:0 auto;
          border-bottom:1px solid #f2f3f3;

          &.logout{
            border-bottom:1px solid #000;
          }
        }

        a,.setting{
          position:relative;
          svg{
            position: absolute;
            right:2rem;
            top:50%;
            transform: translateY(-50%) rotate(-180deg);

          }
        }
     }

     .setting{
      display: block;
      padding: 0 10px 0 23px;
      font-size: 16px;
      font-weight: 600;
      color: #1e2024;
      line-height: 40px;
      height:40px;
      transition: all 0.1s ease;
      cursor: pointer;

      &.active{
        svg{
           transform: translateY(-50%) rotate(0deg);
        }
      }
      &:hover {
        color: #485cc7;
        svg path{fill:#485cc7}
      }
     }
  `,

    StyledSelect: styled(StyledForm.Select)`
          width: 160px;
    font-size: 14px ;
      margin-left: 3rem;
      margin-top:15px;
      .select__control {
        min-height: 40px;
        background-color: #fff;
          border:1px solid #eaeaeb;

      }
      > .select__menu,
      .select__option,
      .select__menu-list {
        background-color: #f6f6f6;
        color: #777777
      }
      .select__option:hover {
        background: #eaeaeb;
        color: #1e2024;
      }
      .css-b8ldur-Input {
        color: #fff;
      }
      .select__indicator {
        svg {
          color: #8b8f99;
        }
      }
      .select__placeholder,
      .select__single-value {
        color: #666666;
        font-weight: 400;
      }
      @media (max-width: 767px) {
        margin-bottom:0;
        .select__control {
          min-height:42px;

          background: none;
        }
        > .select__menu,
        .select__option,
        .select__menu-list {
          background-color: none;
        }
          .select__value-container{

          }
        .select__indicators{

        }
      }
    `,
  NavHead : styled.div`
  display: none;

  @media (max-width: 1024px) {
    display:  flex;;
    padding: 0 35px 0 20px;

    align-items: center;
    height:65px;
    position: relative;
  }

`,
    Close: styled.button.attrs({
      type: "button",
      children: <span></span>,
    })`
      display: none;
      position: absolute;
      top: 50%;
      transform:translateY(-50%);
      right: 20px;
      width: 35px;
      height: 35px;
      z-index: 9;
      margin-top: 0;
      background-color: transparent;
      span:before,
      span:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20px;
        height: 2px;
        margin: 0 0 0 -10px;
        border-radius: 10px;
        background-color: #000;
        transition: all 0.3s ease;
      }
      span:before {
        transform: rotate(-45deg);
      }
      span:after {
        transform: rotate(45deg);
      }

      @media (max-width: 1024px) {
        display: inline-block;
      }
    `,
};

export default StyledUserNav;

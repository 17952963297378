import React, { useEffect, useState } from 'react';

import Styled from './index.style';
import MainContentsStyled from '../index.style';
import ArrowLink from 'component/atoms/ArrowLink';
import Img from 'component/atoms/Img';
import SwiperCore, { EffectFade } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';
import { Trans, useTranslation } from 'react-i18next';

import levelTestImg1en from 'assets/images/i18n/main_level_test01_en.png';
import levelTestImg2en from 'assets/images/i18n/main_level_test02_en.png';
import levelTestImg3en from 'assets/images/i18n/main_level_test03_en.png';

import levelTestImg1kr from 'assets/images/i18n/main_level_test01_kr.png';
import levelTestImg2kr from 'assets/images/i18n/main_level_test02_kr.png';
import levelTestImg3kr from 'assets/images/i18n/main_level_test03_kr.png';

import levelTestImg1jp from 'assets/images/i18n/main_level_test01_jp.png';
import levelTestImg2jp from 'assets/images/i18n/main_level_test02_jp.png';
import levelTestImg3jp from 'assets/images/i18n/main_level_test03_jp.png';

import levelTestImg1cn from 'assets/images/i18n/main_level_test01_cn.png';
import levelTestImg2cn from 'assets/images/i18n/main_level_test02_cn.png';
import levelTestImg3cn from 'assets/images/i18n/main_level_test03_cn.png';

SwiperCore.use([EffectFade]);

export default function LevelTest({ t }) {
  let { handleModal } = React.useContext(ModalContext);

  const { i18n } = useTranslation();
  const [imgList, setImgList] = useState([
    levelTestImg1en,
    levelTestImg2en,
    levelTestImg3en,
  ]);

  useEffect(() => {
    if (i18n?.language === 'ko-KR') {
      setImgList([levelTestImg1kr, levelTestImg2kr, levelTestImg3kr]);
    } else if (i18n?.language === 'ja-JP') {
      setImgList([levelTestImg1jp, levelTestImg2jp, levelTestImg3jp]);
    } else if (i18n?.language === 'zh-CN') {
      setImgList([levelTestImg1cn, levelTestImg2cn, levelTestImg3cn]);
    }
  }, [i18n?.language]);

  const openLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false);
  };

  return (
    <Styled.Wrapper>
      <Styled.Texts>
        <MainContentsStyled.H2>
          <Trans t={t}>levelTest.title</Trans>
        </MainContentsStyled.H2>
        <Styled.Desc>{t('levelTest.desc')}</Styled.Desc>
        <ArrowLink primary='true' to='#none' onClick={openLoginModal}>
          {t('levelTest.button')}
        </ArrowLink>
      </Styled.Texts>
      <Styled.Swiper
        loop
        effect='fade'
        speed={300}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
      >
        {imgList.map((img, index) => (
          <SwiperSlide key={index}>
            <Img src={img} alt='Level Test Image' />
          </SwiperSlide>
        ))}
      </Styled.Swiper>
      {/* <Styled.ImgWrapper>
        <Img src={levelTestImg} alt='Level Test Image' className='pc'/>
        <Img src={levelTestImgMobile} alt='Level Test Image' className='mobile'/>
      </Styled.ImgWrapper> */}
    </Styled.Wrapper>
  );
}

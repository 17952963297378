import styled from 'styled-components';

const StyledRadioButton = {
  Container: styled.label`
    display: block;
    position: relative;
    padding-left: 2.5em;
    margin-bottom: 0.75em;
    font-size: 1.6em;
    cursor: pointer;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }
    input:checked ~ span {
      background-color: transparent;
    }
    input:checked ~ span:before {
      display: block;
    }
    .terms {
      border-bottom: 2px #fff solid;
    }
    .circle {
      position: absolute;
      top: 0.1em;
      left: 0;
      height: 1.5em;
      width: 1.5em;
      border: 1px #c5c7cd solid;
      border-radius: 50%;
      background-color: transparent;
      &:before {
        content: '';
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.6em;
        height: 0.6em;
        border-radius: 50%;
        background: #c9cbd1;
        transform: translate(-50%, -50%);
      }
    }
  `,
};

export default StyledRadioButton;

import React, { useState, useEffect } from 'react';
import Styled from './styled/ClassDetailModal.style';
import { useTranslation } from 'react-i18next';
import { ModalContext } from 'component/Modal/modalContext';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';

const ClassDetailModal = ({item, onApply}) => {
  const { t } = useTranslation('aliveClassPage');
  let { handleModal } = React.useContext(ModalContext);
  const history = useHistory();
  const language = localStorage.getItem('i18nextLng');

  const [classLevel, setClassLevel] = useState('0');
  const [levelType, setLevelType] = useState('type1');
  const [classLang, setClassLang] = useState('English');
  const [classDay, setClassDay] = useState([]);
  const [classCurri, setClassCurri] = useState([]);
  const [priceCost, setPriceCost] = useState('$0.00');
  const [salesStore, setSalesStore] = useState('$0.00');
  const [discountTotal, setDiscountTotal] = useState('$0.00');
  const [discountDefault, setDiscountDefault] = useState('$0.00');
  const [discountDefaultRange, setDiscountDefaultRange] = useState('0%');
  const [discountSpecial, setDiscountSpecial] = useState('$0.00');
  const [discountSpecialRange, setDiscountSpecialRange] = useState('0%');
  const [isSALE, setIsSALE] = useState(true);

  // Video State
	const [playing, setPlaying] = useState(false);
	const [isPlay, setIsPlay] = useState(false);
	const onPlay = () => {
		setPlaying(true);
		setIsPlay(true);
	};

  useEffect(()=>{
    //수업레벨
    if(item.classLevel){
      const levelArr = item.classLevel.split(/,\s|,/);
      if (levelArr.length === 1){
        setClassLevel(`${levelArr[0]}`);
      } else if (levelArr.length === 2){
        setClassLevel(`${levelArr[0]}-${levelArr[1]}`);
      } else if (levelArr.length > 2){
        setClassLevel(`${levelArr[0]}-${levelArr[levelArr.length-1]}`);
      }
      
      switch (levelArr[levelArr.length-1]) {
        case '0':
          setLevelType('type1');
          break;
        case '1':
        case '2':
          setLevelType('type2');
          break;
        case '3':
        case '4':
          setLevelType('type3');
          break;
        case '5':
        case '6':
          setLevelType('type4');
          break;
        default:
          setLevelType('type1');
          break;
      }
    }

    //수업언어
    if(item.classLang){
      if(language === 'ko-KR'){
        if (item.classLang === 'KO'){
          setClassLang('한국어');
        }else if (item.classLang === 'EN'){
          setClassLang('영어');
        }else if (item.classLang === 'CN'){
          setClassLang('중국어');
        }else if (item.classLang === 'JP'){
          setClassLang('일본어');
        }else if (item.classLang === 'ETC'){
          setClassLang(item.classLangEtc);
        }
      }else{
        if (item.classLang === 'KO'){
          setClassLang('Korean');
        }else if (item.classLang === 'EN'){
          setClassLang('English');
        }else if (item.classLang === 'CN'){
          setClassLang('Chinese');
        }else if (item.classLang === 'JP'){
          setClassLang('Japanese');
        }else if (item.classLang === 'ETC'){
          setClassLang(item.classLangEtc.charAt(0).toUpperCase() + item.classLangEtc.slice(1));
        }
      }
    }

    //수업요일
    if(item.classDay){
      const dayArr = item.classDay.split(/,\s|,/);
      const arr = [];
      dayArr.map((day, index)=>{
        if(language === 'ko-KR'){
          if(day === '월'){
            arr.push('월요일');
          }else if(day === '화'){
            arr.push('화요일');
          }else if(day === '수'){
            arr.push('수요일');
          }else if(day === '목'){
            arr.push('목요일');
          }else if(day === '금'){
            arr.push('금요일');
          }else if(day === '토'){
            arr.push('토요일');
          }else if(day === '일'){
            arr.push('일요일');
          }
        }else{
          if(day === '월'){
            arr.push('Mon');
          }else if(day === '화'){
            arr.push('Tue');
          }else if(day === '수'){
            arr.push('Wed');
          }else if(day === '목'){
            arr.push('Thu');
          }else if(day === '금'){
            arr.push('Fri');
          }else if(day === '토'){
            arr.push('Sat');
          }else if(day === '일'){
            arr.push('Sun');
          }
        }
      })
      setClassDay(arr);
    }

    //정가
    if(item.priceCost){
      if(language === 'ko-KR'){
        setPriceCost(`￦${item.priceCost.toLocaleString('ko-KR')}`);
      }else {
        if(item.priceCost%1 !== 0){
          setPriceCost(`$${item.priceCost}`);
        } else {
          setPriceCost(`$${item.priceCost}.00`);
        }
      }
    }
    
    //판매가
    if(item.salesStore){
      if(language === 'ko-KR'){
        setSalesStore(`￦${item.salesStore.toLocaleString('ko-KR')}`);
      }else {
        if(item.salesStore%1 !== 0){
          setSalesStore(`$${item.salesStore}`);
        } else {
          setSalesStore(`$${item.salesStore}.00`);
        }
      }
    }

    //판매가 할인
    if(item.discountType && (item.discountCost || item.discountCost === 0)){
      if(item.discountType === 'PER'){
        let totalDisc = (item.priceCost * item.discountCost);
        setDiscountTotal(language === 'ko-KR' ? `￦${Number(totalDisc.toFixed(0)).toLocaleString('ko-KR')}` : `$${totalDisc.toFixed(2)}`);
      }else{
        if(language === 'ko-KR') setDiscountTotal(`￦${(item.priceCost - item.discountCost).toLocaleString('ko-KR')}`);
        else setDiscountTotal(`$${(item.priceCost - item.discountCost).toLocaleString('ko-KR')}`);
      }
    }

    //기본 할인
    if(item.discountDefaultType && (item.discountDefault_amt || item.discountDefault_amt === 0)){
      if(item.discountDefaultType === 'PER'){
        setDiscountDefaultRange(`${item.discountDefault_amt}%`); //여긴 0~100
        let totalDisc = (item.priceCost * (item.discountDefault_amt / 100));
        setDiscountDefault(language === 'ko-KR' ? `￦${Number(totalDisc.toFixed(0)).toLocaleString('ko-KR')}` : `$${totalDisc.toFixed(2)}`);
      }else{
        if(language === 'ko-KR') {
          setDiscountDefaultRange(`￦${item.discountDefault_amt.toLocaleString('ko-KR')}`);
          setDiscountDefault(`￦${(item.priceCost - item.discountDefault_amt).toLocaleString('ko-KR')}`);
        }
        else {
          setDiscountDefaultRange(`$${item.discountDefault_amt}`);
          setDiscountDefault(`$${(item.priceCost - item.discountDefault_amt)}`);
        }
      }
    }
    //특별 할인
    if(item.discountSpecialType && (item.discountSpecial_amt || item.discountSpecial_amt === 0)){
      if(item.discountSpecialType === 'PER'){
        setDiscountSpecialRange(`${item.discountSpecial_amt}%`); //여긴 0~100
        let totalDisc = (item.priceCost * (item.discountSpecial_amt / 100));
        setDiscountSpecial(language === 'ko-KR' ? `￦${Number(totalDisc.toFixed(0)).toLocaleString('ko-KR')}` : `$${totalDisc.toFixed(2)}`);
      }else{
        if(language === 'ko-KR') {
          setDiscountSpecialRange(`￦${item.discountSpecial_amt.toLocaleString('ko-KR')}`);
          setDiscountSpecial(`￦${(item.priceCost - item.discountSpecial_amt).toLocaleString('ko-KR')}`);
        }
        else {
          setDiscountSpecialRange(`$${item.discountSpecial_amt}`);
          setDiscountSpecial(`$${(item.priceCost - item.discountSpecial_amt)}`);
        }
      }
    }

    if(item.classCurri){
      setClassCurri(item.classCurri.split(/\r\n|\n/));
    }

    if(item.salesStatus && item.salesStatus.toUpperCase() !== 'SALE'){
      setIsSALE(false);
    }else setIsSALE(true);
  }, [item])

  const onApplyClick = () => {
    if(!isSALE){
      return;
    }

    const isLogin = onApply();
    if(isLogin) handleModal();
  }

  return (
    <Styled.Container>
      <Styled.H3 className={levelType}>{/*레벨 - 0 입문 : type1 / 1-2 초급 : type2 / 3-4 중급 : type3 / 5-6 고급 : type4 */}
        {/* <span className='group'>{item.classType === "KO" ? 'KOREAN' : item.classType}</span> */}
        <span className='group'>{item.classType === "KO" ? 'Writing' : 'Reading'}</span>
        <span>Lv. {classLevel} {item.productTitle}</span>
      </Styled.H3>
      <Styled.ContentWrap>
        <Styled.H4>{t('Modal.teacher.title')}</Styled.H4>
        <Styled.Teacehr>
          <div className='stitle'>{item.teacherName}</div>
          <p>{item.slogan}</p>
          <Styled.Video>
            <ReactPlayer
              controls
              onClick={onPlay}
              playing={playing}
              // light= {videoThumbImg}
              // url='https://d13owoaeiymgg6.cloudfront.net/vod/cso_kr_reading/topik_cso_alive/HLS/topik_cso_alive.m3u8'
              url={item.movURL}
              width='100%'
              height='100%'
              config={{
                file: {
                  attributes: {
                    onContextMenu: (e) => e.preventDefault(),
                    controlsList: 'nodownload',
                  },
                },
              }}
            />
          </Styled.Video>
        </Styled.Teacehr>
        <Styled.H4>{t('Modal.class.title')}</Styled.H4>
        <Styled.Class>
          <div className='stitle'>{t('Modal.class.stitle1')}</div>
          <ul>
            <li>{item.classType === "KO" ? 'KOREAN' : item.classType}</li>
          </ul>
          <div className='stitle'>{t('Modal.class.stitle2')}</div>
          <ul>
            <li>{classLang}</li>
          </ul>
          <div className='stitle'>{t('Modal.class.stitle3')}</div>
          <ul>
            <li>{classLevel}</li>
          </ul>
          <div className='stitle'>{t('Modal.class.stitle4')}</div>
          <ul>
            <li>{classDay.map((day, index)=>{
              let text = '';
              if(index !== 0) text += ', '
              text += day;
              return text;
            })}</li>
            <li>{item.classTimeStart} - {item.classTimeEnd} (KST)</li>
          </ul>
          <div className='stitle'>{t('Modal.class.stitle5')}</div>
          <p className='detail'>{item.classDetail}</p>
          <div className='stitle'>{t('Modal.class.stitle6')}</div>
          <ul>
            {classCurri.map((curri, index)=>
              <li key={'curri'+index}>{curri}</li>
            )}
          </ul>
        </Styled.Class>
        <Styled.H4>{t('Modal.price.title')}</Styled.H4>
        <Styled.Price>
          <dl>
            <dt>{t('Modal.price.stitle1')}</dt>
            {/* priceCost: 정가 */}
            <dd>{priceCost}</dd>
          </dl>
          <dl className='discount'>
            <dt>{t('Modal.price.stitle2')}</dt>
            {/* discountTotal: 전체 할인 금액 */}
            <dd>- {discountTotal}</dd>
          </dl>
          <dl className='off'>
            {/* discountDefaultRange: 기본할인량 */}
            <dt>{item.discountDefault} {discountDefaultRange} OFF</dt>
            {/* discountDefault: 기본 할인 금액 */}
            <dd>- {discountDefault}</dd>
          </dl>
          <dl className='off promotion'>
            {/* discountSpecialRange: 특별할인량 */}
            <dt><span>{item.discountSpecial} {discountSpecialRange} OFF</span></dt>
            {/* discountSpecial: 특별할인 금액 */}
            <dd>- {discountSpecial}</dd>
          </dl>
          <dl className='total'>
            <dt>{t('Modal.price.stitle3')}</dt>
            {/* salesStore: 판매가 */}
            <dd>{salesStore}</dd>
          </dl>
        </Styled.Price>
      </Styled.ContentWrap>
      <Styled.ButtonWrap>
        <button onClick={onApplyClick} disabled={isSALE ? false : true}>
          {isSALE
            ? t('button.applyNow')
            : t('button.applyEnd')
          }
        </button>
      </Styled.ButtonWrap>
    </Styled.Container>
  );
};

export default ClassDetailModal;

const navLibrary = {
  name: 'Library',
  url: '/library',
  className: 'library',
  subList: undefined,
};
const navPlan = {
  name: 'Plan',
  url: '/plan',
  className: undefined,
  subList: [
    { name: 'Plan', url: '/plan' },
    { name: 'Free Trial', url: '/event/freetrial' },
    { name: 'Yearly Plan', url: '/event/monthpass' },
  ],
};
const navAlive = {
  name: 'Alive Class',
  url: '/alive',
  className: 'alive',
  subList: undefined,
};
const navExternalAlive = {
  name: 'Alive Class',
  url: '//mtalive.masterk1.com/',
  className: 'alive',
  subList: undefined,
};

/**
 * 로그인 전 Nav Menu
 */
export const navDataNotLogin = [
  {
    name: 'Course',
    url: '/courses',
    className: undefined,
    subList: [
      { name: 'All Courses', url: '/courses/all' },
      { name: 'TOPIK', url: '/courses/topik' },
      { name: 'Korean', url: '/courses/korean' },
      // { name: 'TOPIK Mock Test​', url: '/courses/topik-mock-test' },
      // { name: 'EPS-TOPIK', url: '/courses/eps-topik' },
    ],
  },
  {
    name: 'TOPIK Mock Test​',
    url: '/courses/topik-mock-test',
    className: undefined,
    subList: undefined,
  },
  // {
  //   name: 'Teachers',
  //   url: '/teachers',
  //   className: undefined,
  //   subList: undefined,
  // },
  // {
  //   name: 'Resources',
  //   url: '/resources',
  //   className: undefined,
  //   subList: [
  //     { name: 'Voca Card', url: '/resources' },
  //     { name: 'MT Live', url: '/resources/mt-live' },
  //   ],
  // },
  //navLibrary,
  {
    name: 'AI SPEAK',
    url: '/aispeak',
    className: undefined,
    subList: undefined,
  },
  navPlan,
  {
    name: 'Resources',
    url: '/resources',
    className: undefined,
    subList: [
      { name: 'Learning Materials', url: '/learning-material' },
      { name: 'Level Test', url: '/level-test' },
      { name: 'Bookstore', url: '/bookstore' },
      { name: 'Audio', url: '/audio' },
      { name: 'Voca Card', url: '/voca-card' },
      { name: 'Help', url: '/help' },
      // { name: 'Voca Card', url: '/resources' },
      // { name: 'MT Live', url: '/resources/mt-live' },
    ],
  },


  // 20230210 GNB에서 Alive class 삭제 (B2C 화상수업 종료)
  // navAlive,
  // navExternalAlive
];

/**
 * 로그인 후 Nav Menu
 */

export const navDataLoginPC = [
  {
    name: 'Course',
    url: '/courses',
    className: undefined,
    subList: [
      { name: 'All Courses', url: '/courses/all' },
      { name: 'TOPIK', url: '/courses/topik' },
      { name: 'Korean', url: '/courses/korean' },
      { name: 'Category', url: '/courses/category' },
      { name: 'Search', url: '/library' },
      { name: 'Teachers', url: '/teachers' },
    ],
  },
  {
    name: 'Classroom',
    url: '/classroom',
    className: undefined,
    subList: [
      { name: 'My Classroom', url: '/classroom' },
      { name: 'Level test', url: '/level-test' },
      { name: 'Curriculum', url: '/classroom/curriculum' },
      { name: 'Bookmark', url: '/bookmark' },
      { name: 'Theme', url: '/bookmark/theme' },
    ],
  },
  {
    name: 'TOPIK Mock Test​',
    url: '/classroom/topik-mock-test',
    className: undefined,
    subList: [
      { name: 'My Mock Test', url: '/classroom/topik-mock-test' },
      { name: 'My Grade Report', url: '/test-report' },
      { name: 'Introduce', url: '/courses/topik-mock-test' },
    ],
  },
  {
    name: 'AI SPEAK',
    url: '/aispeak',
    className: undefined,
    subList: undefined,
  },


  {
    name: 'Plan',
    url: '/plan',
    className: undefined,
    subList: [
      { name: 'Plan', url: '/plan' },
      { name: 'Free Trial', url: '/event/freetrial' },
      { name: 'Yearly Plan', url: '/event/monthpass' },
    ],
  },

];


export const navMypage = [
  {
    name: 'Profile',
    url: '/profile',
    className: undefined,
    subList: undefined,
  },
  {
    name: 'Notifications',
    url: '/notification',
    className: undefined,
    subList: undefined
  },
  {
    name: 'Settings',
    url: '/setting',
    className: undefined,
    subList: [
      { name: 'Account', url: '/setting#account' },
      { name: 'Subscription ', url: '/setting#subscription' },
      { name: 'Allow Notifications', url: '/setting#allownotifications' },
      { name: 'Payment List', url: '/setting#paymentlist' },
    ],
  },
  {
    name: 'Event',
    url: '/help/event',
    className: undefined,
    subList: undefined,
  },
  {
    name: 'Help',
    url: '/help',
    className: undefined,
    subList: undefined,
  },
  {
    name: 'Notice',
    url: '/help/notice',
    className: undefined,
    subList: undefined,
  },




];

export const navDataLogin = [
  {
    name: 'Home',
    url: '/',
    className: undefined,
    subList: undefined
  },
  {
    name: 'Plan',
    url: '/plan',
    className: undefined,
    subList: [
      { name: 'Plan', url: '/plan' },
      { name: 'Free Trial', url: '/event/freetrial' },
      { name: 'Yearly Plan', url: '/event/monthpass' },
    ],
  },
  {
    name: 'Course',
    url: '/courses',
    className: undefined,
    subList: [
      { name: 'All Courses', url: '/courses/all' },
      { name: 'TOPIK', url: '/courses/topik' },
      { name: 'Korean', url: '/courses/korean' },
      { name: 'Category', url: '/courses/category' },
      { name: 'Search', url: '/library' },
      { name: 'Teachers', url: '/teachers' },
    ],
  },
  {
    name: 'Classroom',
    url: '/classroom',
    className: undefined,
    subList: [
      { name: 'My Classroom', url: '/classroom' },
      { name: 'Level test', url: '/level-test' },
      { name: 'Curriculum', url: '/classroom/curriculum' },
      { name: 'Bookmark', url: '/bookmark' },
      { name: 'Theme', url: '/bookmark/theme' },
    ],
  },
  {
    name: 'TOPIK Mock Test​',
    url: '/classroom/topik-mock-test',
    className: undefined,
    subList: [
      { name: 'My Mock Test', url: '/classroom/topik-mock-test' },
      { name: 'My Grade Report', url: '/test-report' },
      { name: 'Introduce', url: '/courses/topik-mock-test' },
    ],
  },
  {
    name: 'AI SPEAK',
    url: '/aispeak',
    className: undefined,
    subList: undefined,
  },
  {
    name: 'Resources',
    url: '/resources',
    className: undefined,
    subList: [
      { name: 'Learning Materials', url: '/learning-material' },
      { name: 'Bookstore', url: '/bookstore' },
      { name: 'Audio', url: '/audio' },
      { name: 'Voca Card', url: '/voca-card' },
      { name: 'Community', url: '/community' },
    ],
  },
  {
    name: 'Settings​',
    url: '/setting',
    className: undefined,
    subList: [
      { name: 'Account', url: '/setting#account' },
      { name: 'Subscription ', url: '/setting#subscription' },
      { name: 'Allow Notifications', url: '/setting#allownotifications' },
      { name: 'Payment List', url: '/setting#paymentlist' },
    ],
  },

];

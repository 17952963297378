import styled, { css } from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import signUpIcon from 'assets/images/ico_signup.png';
import playIcon from 'assets/images/ico_play2.png';
import ButtonLink, { Button } from 'component/atoms/ButtonLink';
import { ImHeart } from 'react-icons/im';
import { BsFillBookmarkFill } from 'react-icons/bs';
import { BsFillChatFill } from 'react-icons/bs';

const emptyBtn = css`
  position: relative;
  margin-left: 0.66em;
  padding: 0.85em 2em;
  border: 1px #fff solid;
  border-radius: 0.6rem;
  background: transparent;
  color: #fff;
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    border: 1px #fff solid;
    color: #fff;
  }
`;

const StyledSummaryBanner = {
  Container: styled.div`
    position: relative;
    padding: 12.3em 0;
    font-size: 1.07em;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: ${({ bgImage }) => `url("${bgImage}") no-repeat center`};
      opacity: 0.35;
      background-size: cover;
      z-index: -10;
    }
  `,
  ContentWrapper: styled(ContentsWrapper)`
    max-width: 88.5em;
  `,

  H2: styled.h2`
    color: #fff;
    font-size: 5em;
    font-weight: 700;
  `,
  P: styled.p`
    max-width: 55.3em;
    margin-top: 2.4em;
    margin-bottom: 4.68em;
    color: #bcbfc5;
    font-size: 1.6em;
    font-weight: 300;
  `,
  Span: styled.span`
    font-size: 2em;
    font-weight: 500;
  `,
  Button: styled(ButtonLink)`
    font-size: 1.6em;
    font-weight: 700;
  `,

  SignUpBtn: styled(Button)`
    position: relative;
    padding: 1em 1.5em 1em 4em;

    font-size: 1.5em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    &::before {
      content: '';
      position: absolute;
      left: 2em;
      top: 50%;
      width: 1.3em;
      height: 1.3em;
      background: url(${signUpIcon}) no-repeat center center/cover;
      transform: translateY(-50%);
    }
  `,

  StartBtn: styled(ButtonLink)`
    position: relative;
    padding-left: 4em;
    padding-right: 2em;
    font-size: 1.5em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    &::before {
      content: '';
      position: absolute;
      left: 2em;
      top: 50%;
      width: 1.3em;
      height: 1.3em;
      background: url(${playIcon}) no-repeat center center/cover;
      transform: translateY(-50%);
    }

    @media (max-width: 767px) {
      padding-right: 5em;
    }
  `,
  Heart: styled(ImHeart)`
    margin-right: 1em;
    vertical-align: sub;
  `,
  Discussion: styled(BsFillChatFill)`
    margin-right: 1em;
    vertical-align: sub;
  `,
  Bookmark: styled(BsFillBookmarkFill)`
    margin-right: 1em;
    vertical-align: sub;
  `,

  LikeBtn: styled.button`
    ${emptyBtn};
    @media (max-width: 767px) {
      margin-left: 0.8em;
      margin-bottom: 1em;
      padding-right: 6.1em;
    }
    @media (max-width: 375px) {
      margin-left: 0.82em;
      padding-right: 6.7em;
    }
  `,
  BookmarkBtn: styled.button`
    ${emptyBtn};

    @media (max-width: 767px) {
      margin-left: 0;
    }
  `,

  DiscussionBtn: styled(ButtonLink)`
    position: relative;
    margin-left: 0.66em;
    padding-bottom: 0.8em;
    padding-left: 2em;
    padding-right: 2em;
    background-color: transparent;
    border: 1px #c9cbd1 solid;
    color: #c9cbd1;
    font-size: 1.5em;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;

    &:hover {
      border: 1px #fff solid;
      background-color: transparent;
      color: #fff;
    }
  `,

  LineBreak: styled.br`
    display: none;
    @media (max-width: 767px) {
      display: block;
    }
  `,
};

export default StyledSummaryBanner;

import ContentsWrapper from 'component/atoms/ContentsWrapper';
import styled from 'styled-components';

const StyledTeachers = {
  Wrapper: styled(ContentsWrapper)`
    @media (max-width: 767px) {
      max-width: 500px;
    }
  `,
  Ul: styled.ul`
    display: inline-block;
    width: calc(100% + 5em);
    padding: 8em 0 15em;
    margin-left: -2.5em;
    @media (max-width: 1024px) {
      width: calc(100% + 20px);
      margin-left: -10px;
    }
    @media (max-width: 767px) {
      display: block;
      width: auto;
      margin: 0 auto;
    }
  `,
  Li: styled.li`
    position: relative;
    float: left;
    width: calc((100% / 3) - 5em);
    margin: 2.5em;
    transition: margin-bottom 0.6s ease;

    &:before {
      content: '';
      position: absolute;
      top: calc(100% + 6em);
      left: 50%;
      width: 1000vw;
      margin-left: -500vw;
      height: 63em;
      background-color: #2b2e33;
      transition: opacity 0.6s ease;
      opacity: 0;
    }
    &.active:before {
      opacity: 1;
      transition: opacity 0.6s ease;
    }

    &.active .teacher-card {
      transform: rotateY(180deg);
      &:after {
        border-top-width: 1.4em;
        transition: all 0.3s ease 0.4s;
      }
    }
    &.active .texts {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    .teacher-detail {
      right: 0;
    }

    @media (min-width: 1025px) {
      &.active:nth-child(3n - 2) + li + li,
      &.active:nth-child(3n - 2) + li,
      &.active:nth-child(3n - 1) + li,
      &.active {
        margin-bottom: 73em;
        transition: margin-bottom 0.6s ease;
      }

      &:nth-child(3n - 2) .teacher-detail {
        left: 0;
      }
      &:nth-child(3n - 1) .teacher-detail {
        left: 50%;
        margin-left: -650px;
      }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      width: calc((100% / 2) - 20px);
      margin: 10px;

      &:before {
        height: calc(50vw + 500px);
      }
      &.active:nth-child(2n - 1) + li,
      &.active {
        margin-bottom: calc(50vw + 580px);
      }
      &:nth-child(2n - 1) .teacher-detail {
        left: 0;
      }
    }

    @media (max-width: 767px) {
      float: none;
      width: 100%;
      margin: 2.5em 0;
      &:before {
        display: none;
      }
      &.active {
        margin-bottom: 5em;
      }
    }
  `,
};

export default StyledTeachers;

import React, { useEffect, useState } from 'react';
import Styled from './SiteMap.style';
import { useTranslation } from 'react-i18next';
import { AiFillFacebook, AiFillYoutube } from 'react-icons/ai';
import { FiInstagram } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { SiteMapData } from 'common/constant';
import playstoreImg from 'assets/images/footer-btn-playstore.jpg';
import Img from 'component/atoms/Img';
import makeAnimated from 'react-select/animated';
import { languageCode } from 'common/constant';
import { useAxios } from 'common/hook/useAxiosHook';
import userApi from 'api/user';
import { actions } from 'component/user/state';

const animatedComponents = makeAnimated();

const familySiteOption = [
  { label: '1', value: 'https://www.masterTopik.com' },
  { label: '2', value: 'https://www.naver.com' },
  { label: '3', value: 'https://www.google.co.kr/' },
];

const options = [
  {
    name: languageCode.KOREAN.code.toUpperCase(),
    value: languageCode.KOREAN.locale,
    label: '한국어'
  },
  {
    name: languageCode.JAPANESE.code.toUpperCase(),
    value: languageCode.JAPANESE.locale,
    label: '日本語​'
  },
  {
    name: languageCode.CHINESE.code.toUpperCase(),
    value: languageCode.CHINESE.locale,
    label: '中国话​'
  },
  {
    name: languageCode.ENGLISH.code.toUpperCase(),
    value: languageCode.ENGLISH.locale,
    label: 'English'
  },
];

export default function SiteMapAfterLogin() {
  const dispatch = useDispatch();
  const { t } = useTranslation('footer');
  const [
    { data: updateLanguageStatus },
    refreshUpdateLanguage
  ] = useAxios (userApi.updateUserLanguage,[],true);

  const [languageindex, setLanguageIndex] = useState(null);

  const user = useSelector((state) => state.user.data);
  const token = useSelector((state) => state.user.token);

  const onChangeLanguage = (e) => {
    refreshUpdateLanguage({token, language : e.name });
    const newIndex = options.findIndex(({ name }) => {
      return name === e.name;
    });
    setLanguageIndex(newIndex);
    //window.location.reload();
    // window.open(e.value, '_blank');
  };

  useEffect(() => {
    if (user.selectLanguage){
      const defaultIndex = options.findIndex(({ name }) => {
        return name === user.selectLanguage;
      });
      setLanguageIndex(defaultIndex);
    }
  }, [user.selectLanguage]);

  useEffect(() => {
    if (updateLanguageStatus !== 200) return;
    dispatch(actions.fetchUser(token));
  }, [dispatch, token, updateLanguageStatus]);

  return (
    <Styled.Section className='footer-after-login'>
      <Styled.Group>
        <Styled.GroupName>Course</Styled.GroupName>
        <Styled.Item>
            <Styled.ItemLink to={SiteMapData.course}>
              <span>All Courses​</span>
            </Styled.ItemLink>
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.topik}>
              <span>TOPIK</span>
            </Styled.ItemLink>
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.korean}>
              <span>Korean</span>
            </Styled.ItemLink>
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.category}>
              <span>Category</span>
            </Styled.ItemLink>
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.search}>
              <span>Search</span>
            </Styled.ItemLink>
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.teachers}>
              <span>Teachers</span>
            </Styled.ItemLink>
          </Styled.Item>
      </Styled.Group>


      <Styled.Group>

          <Styled.GroupName>Classroom​</Styled.GroupName>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.classroom}>
              <span>My Classroom</span>
            </Styled.ItemLink>
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.level}>
              <span>Level Test</span>
            </Styled.ItemLink>
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.curriculum}>
              <span>Curriculum​</span>
            </Styled.ItemLink>
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.bookmark}>
              <span>Bookmark</span>
            </Styled.ItemLink>
          </Styled.Item>
       </Styled.Group>


       <Styled.Group className='long'>
          <Styled.GroupName>TOPIK Mock Test</Styled.GroupName>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.mock2}>
              <span>My Mock Test</span>
            </Styled.ItemLink>
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.grade}>
              <span>My Grade Report</span>
            </Styled.ItemLink>
          </Styled.Item>
          <Styled.Item>
            <Styled.ItemLink to={SiteMapData.mock}>
              <span>Introduce</span>
            </Styled.ItemLink>
          </Styled.Item>

          <Styled.GroupName className='ai'>
            <Styled.ItemLink to={SiteMapData.aispeak}>
              <span>AI SPEAK
              <svg width="7px" height="10px" className='arrow'>
                <path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M0.500,9.800 L0.500,0.200 L6.500,4.1000 L0.500,9.800 Z"/>
              </svg>
                  </span>
            </Styled.ItemLink>
          </Styled.GroupName>
       </Styled.Group>


      <Styled.Group  className='line2'>
        <Styled.GroupName>
        <Styled.ItemLink to={SiteMapData.plan}>
            <span>Plan
            <svg width="7px" height="10px" className='arrow'>
              <path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M0.500,9.800 L0.500,0.200 L6.500,4.1000 L0.500,9.800 Z"/>
            </svg>

            </span>
          </Styled.ItemLink>
        </Styled.GroupName>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.free}>
            <span> Free Trial​</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.yearly}>
            <span>Yearly Plan​</span>
          </Styled.ItemLink>
        </Styled.Item>

        <Styled.GroupName>About</Styled.GroupName>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.privacy} >
            <span>Privacy</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.terms} >
            <span>Terms</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.visang} target='_blank'>
            <span>Visang Education</span>
          </Styled.ItemLink>
        </Styled.Item>


      </Styled.Group>

      <Styled.Group  className='line2'>
        <Styled.GroupName>Resources</Styled.GroupName>
         <Styled.Item>
          <Styled.ItemLink to={SiteMapData.learningMaterial}>
            <span>Learning Materials​</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.bookstore}>
            <span>Bookstore​</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.audio}>
            <span>Audio​</span>
          </Styled.ItemLink>
        </Styled.Item>

        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.vocaCard}>
            <span>Voca Cards​</span>
          </Styled.ItemLink>
        </Styled.Item>

         <Styled.Item>
          <Styled.ItemLink to={SiteMapData.community}>
            <span>Community</span>
          </Styled.ItemLink>
        </Styled.Item>
      </Styled.Group>



      <Styled.Group className='line2'>
        <Styled.GroupName>My Menu​</Styled.GroupName>
         <Styled.Item>
          <Styled.ItemLink to={SiteMapData.profile}>
            <span>Profile</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.notification }>
            <span>Notification</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.setting}>
            <span>Settings</span>
          </Styled.ItemLink>
        </Styled.Item>

        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.helpEvent}>
            <span>Event</span>
          </Styled.ItemLink>
        </Styled.Item>

         <Styled.Item>
          <Styled.ItemLink to={SiteMapData.help}>
            <span>Help</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={t('contact')} target='_blank'>
            <span>Contact</span>
          </Styled.ItemLink>
        </Styled.Item>
      </Styled.Group>



      <Styled.colGroup className="">

      <Styled.Group className='lang'>
        <Styled.GroupName>Language</Styled.GroupName>

         <Styled.StyledSelect
            clasName="select-box"
            options={options}
            components={animatedComponents}
            onChange={(e) => onChangeLanguage(e)}
            value={options[languageindex]}
          />
      </Styled.Group>

      <Styled.Group className='sns'>
        <Styled.GroupName>Follow</Styled.GroupName>
          <Styled.SnsGroupLists>

            <Styled.Item>
              <Styled.ItemLink to={SiteMapData.youtube} target='_blank'>
                <AiFillYoutube />
                {/* <span>Youtube</span> */}
              </Styled.ItemLink>
            </Styled.Item>
            {/* <Styled.Item>
              <Styled.ItemLink to={SiteMapData.youku} target='_blank'>
                <BiPlayCircle />
                <span>Youku</span>
              </Styled.ItemLink>
            </Styled.Item> */}
            <Styled.Item>
              <Styled.ItemLink to={SiteMapData.instagram} target='_blank'>
                <FiInstagram />
                {/* <span>Instagram</span> */}
              </Styled.ItemLink>
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemLink to={SiteMapData.facebook} target='_blank'>
                <AiFillFacebook />
                {/* <span>Facebook</span> */}
              </Styled.ItemLink>
            </Styled.Item>
        </Styled.SnsGroupLists>
      </Styled.Group>




      <Styled.Group className='class'>
        <Styled.GroupName>Download{/* Class */}</Styled.GroupName>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.googlePlayStore} target='_blank'>
            <span>
              <Img src={playstoreImg} alt='Google Play Store' />
            </span>
          </Styled.ItemLink>
        </Styled.Item>
        {
          // <Styled.Item className='select-box'>
          //   <OrderBySelect
          //     placeholder='Family Site'
          //     optionList={familySiteOption}
          //     onChange={(e) => clickFamilySite(e)}
          //   />
          // </Styled.Item>
        }
        {/* MasterK 사이트 오픈 후 등록 예정 */}
        {/* <Styled.Item>
          <Styled.ItemLink to={SiteMapData.masterK} target='_blank'>
            <span>
              <Img src={tutoringImg} alt='Tutoring' />
            </span>
          </Styled.ItemLink>
        </Styled.Item> */}
      </Styled.Group>
      </Styled.colGroup>

    </Styled.Section>
  );
}

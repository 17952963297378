import styled, { css } from 'styled-components';

const Title = styled.h3`
  font-size: 3.6em;
  font-weight: 700;
  text-transform: uppercase;
`;
const Button = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 2em;
  padding: 4em;
  border: 1px #b0b1b2 solid;
  border-radius: 0.6em;
  color: #b0b1b2;
  letter-spacing: -0.03em;
  line-height: 1;
  transition: all 0.2s ease;
  overflow: hidden;
  &:hover {
    color: #b0b1b2;
    * {
      color: inherit;
    }
  }
  @media (max-width: 1280px) {
    padding: 4em 2em;
    font-size: 0.9em;
  }
  @media (max-width: 767px) {
    padding: 4em 3em;
    font-size: 1em;
  }
`;
const StyledPlanBox = {
  Container: styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 6.6em 4.5%;
    border-radius: 0.8em;
    background-color: #2b2e35;
    text-align: center;
    @media (max-width: 1280px) {
      padding: 6.5em 4em;
    }
    @media (max-width: 767px) {
      padding: 4.4em 4em;
    }
  `,
  BestTag: styled.span`
    position: absolute;
    top: -3em;
    left: -5em;
    width: 11em;
    padding-top: 4.5em;
    padding-bottom: 0.7em;
    background-color: #ff3867;
    text-align: center;
    font-size: 1.4em;
    font-weight: 700;
    transform: rotate(-45deg);
  `,
  Title: styled(Title)`
    color: ${({ color }) => color && color};
  `,
  Desc: styled.p`
    margin: 1em 0 3em;
    color: #fff;
    font-size: 1.6em;
    font-weight: 300;
    @media (max-width: 767px) {
      margin: 1em 0 2em;
    }
  `,
  Button: styled(Button)`
  flex-flow: row wrap;
  color: ${({ color }) => color && color};
  border-color: ${({ color }) => color && color};
  &:hover {
    background-color: ${({ color }) => color && color};
    color: ${({ color }) => color && '#fff'};
  };
  cursor: pointer;

    &.myPlan {
      background-color: ${({ color }) => color && color};
      color: ${({ color }) => color && '#fff'};
      cursor: default;
    }
  `,
  ButtonLeftTop: styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  `,
  Days: styled.div`
    flex-shrink: 0;
    font-weight: 700;
    font-size: 2.8em;
  `,
  Discount: styled.div`
    flex-shrink: 0;
    margin-left: 0.6em;
    padding: 0.3em 0.6em;
    border-radius: 2em;
    background: #ffb500;
    font-weight: 700;
    font-size: 1.5em;
    color: #0f1012 !important;
  `,
  Label: styled.p`
    color: #fff;
    font-size: 1.6em;
    font-weight: 400;
    margin-top: 0.5em;
    text-align: left;
  `,
  PriceWrap: styled.div`
    position: relative;
    margin: auto 0 auto auto;
    padding-left: 1em;
    text-align: right;
  `,
  Price: styled.div`
    position: relative;
    display: inline-block;
    font-weight: 700;
    font-size: 2.8em;
    > span {
      position: absolute;
      font-size: 0.6em;
      font-weight: 700;
      top: 0.2em;
      left: -0.4em;
      transform: translateX(-100%);
      text-transform: uppercase;
      line-height: 1;
    }
    .free {
      font-size: 0.6em;
    }
  `,
  Monthly: styled.div`
    margin-top: 1em;
    font-weight: 700;
    .monthly {
      font-size: 1.8em;
      span {
        font-weight: 400;
      }
    }
    .free {
      font-size: 2em;
      span {
        position: relative;
        display: inline-block;
        margin-left: 0.2em;
        padding-left: 0.3em;
        line-height: 1.2;
      }
      &.nMonths {
        span::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          display: block;
          height: 0.9em;
          border-left: 1px solid ${({ color }) => color && color};
          opacity: 0.3;
        }
      }
    }
    @media (max-width: 767px) {
      .free {
        font-weight: 400;
      }
    }
  `,
};

export default StyledPlanBox;

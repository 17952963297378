import React, { useContext, useEffect, useState } from 'react';
import { ViewContext } from 'container/CourseViewPage';
import VideoProgressCircle from 'component/atoms/VideoProgressCircle';
import useInterval from 'common/hook/useInterval';

const ProgressCircle = () => {
  const { isPlayState, playerRef } = useContext(ViewContext);

  const [isPlay] = isPlayState;
  const [percent, setPercent] = useState(0);

  useInterval(() => {
    const player = playerRef.current;
    player && setPercent((player.getCurrentTime() / player.getDuration()) * 100);
  }, 1000);

  return <VideoProgressCircle isPlay={isPlay} percent={percent} />;
};

export default ProgressCircle;

import styled from "styled-components";
import ContentsWrapper from "component/atoms/ContentsWrapper";

const Block = styled.div`
  position: relative;
  display: table-cell;
  padding: 4.5% 5.5%;
  border-radius: 6px;
  background-color: #1e2024;
  vertical-align: bottom;
  @media (max-width: 767px) {
    display: block;
  }
`;

const StyledOrderComplete = {
  Wrapper: styled(ContentsWrapper)`
    padding-top: 10em;
    padding-bottom: 15em;
    @media (max-width: 767px) {
      font-size: 8px;
    }
  `,
  H2: styled.div`
    font-size: 3.6em;
    margin-bottom: 0.8em;
    font-weight: 700;
  `,
  Container: styled.div`
    display: table;
    width: 100%;
    margin-bottom: 8em;
    table-layout: fixed;
    &.next{
      margin-bottom: 3em;
    }
    p {
      font-size: 1.6em;
      font-weight: 400;
    }
    .gray {
      font-size: 1.4em;
      color: #8b8f99;
    }
    @media (max-width: 767px) {
      display: block;
    }
  `,
  Left: styled(Block)`
    width: 36%;
    border-right: 1px solid #2b2e35;
    p {
      margin-bottom: 0.5em;
    }
    @media (max-width: 767px) {
      width: auto;
      border-right: 0;
      border-bottom: 1px solid #2b2e35;
    }
  `,
  Right: styled(Block)``,
  BlockWrap: styled(Block)`
    display: block;
    overflow: hidden;
    .H3 {
      margin-bottom: 2em;
      font-size: 2.4em;
      font-weight: 600;
      color: #8e9199;
    }
  `,
  TextBox: styled.div`
    display: flex;
    margin-top: 5em;
    @media (max-width: 767px) {
      display: block;
      margin-top: 4em;
    }
  `,
  TextItem: styled.div`
    flex: 1;
    li {
      display: flex;
      margin-top: 2em;
      &:first-of-type {
        margin-top: 0;
      }
      .gray {
        flex-basis: 12em;
        &.appli{
          flex: 0 0 12em;
        }
      }
      .pre{
        white-space: pre-wrap;
        word-break: keep-all;
      }
    }
    @media (max-width: 767px) {
      margin-top: 3em;
      li{
        display: block;
        .gray {
          margin-bottom: 1em;
        }
      }
    }
  `,
  H3: styled.h3`
    position: absolute;
    top: 6rem;
    left: 7rem;
    font-size: 2.4em;
    font-weight: 600;
    color: #8e9199;
    @media (max-width: 767px) {
      position: static;
    }
  `,
  Icon: styled.div`
    display: block;
    width: 1.6em;
    height: 1.6em;
    margin: 0.7em 0 0.5em;
    border-radius: 100px;
    background-color: #485cc7;
    font-size: 3em;
    text-align: center;
    line-height: 1.8em;
  `,
  Bold: styled.div`
    font-size: 1.8em;
    font-weight: 500;
  `,
  Blue: styled.div`
    margin-bottom: 1em;
    font-size: 1.6em;
    font-weight: 500;
    color: #485cc7;
    &:not(:first-child) {
      margin-top: 30px;
    }
  `,
  Table: styled.table`
    width: 100%;
    font-size: 1.6em;
    font-weight: 400;
    table-layout: fixed;
    thead {
    }
    tbody th {
      text-align: right;
    }
    tfoot {
      font-size: 1.125em;
      font-weight: 500;
    }
    tr.border-top {
      border-top: 1px dashed #2d3038;
    }
    th {
      color: #666;
    }
    th,
    td {
      padding: 5px;
      text-align: left;
      vertical-align: middle;
      &:last-child {
        width: 12em;
        text-align: right;
      }
    }
    @media (max-width: 767px) {
      th:last-child,
      td:last-child {
        width: 6em;
      }
    }
  `,
  Hr: styled.hr`
    margin: 10px 0;
    border: 0 dashed #2b2e35;
    border-width: 1px 0 0;
  `,
  ButtonContainer: styled.div`
    text-align: center;
    a {
      margin: 5px;
    }
    @media (max-width: 767px) {
      a {
        font-size: 1.7em;
        padding: 0.7em 2em;
      }
    }
  `,
};

export default StyledOrderComplete;

import React from 'react';
import Styled from './index.style';
import LineChart from 'component/atoms/LineChart';

const ViewTimeSlide = ({ viewTimeData, t }) => {

  if (!viewTimeData) return (
    <>
      <Styled.H3>
        {t('dashBoard.scoreSection.viewTime.title')}{' '}
      </Styled.H3>
      <Styled.Box>
        <Styled.ChartTop>
          {t('dashBoard.scoreSection.viewTime.chartTitle')}{' '}
          <span>0h</span>
        </Styled.ChartTop>
        <LineChart labels={["", "", "", "", ""]} data={[0,0,0,0,0]} />
      </Styled.Box>
    </>
  );

  return (
    <>
      <Styled.H3>
        {t('dashBoard.scoreSection.viewTime.title')}{' '}
        <span>
          ({viewTimeData.beginDate} ~ {viewTimeData.endDate})
        </span>
      </Styled.H3>
      <Styled.Box>
        <Styled.ChartTop>
          {t('dashBoard.scoreSection.viewTime.chartTitle')}{' '}
          <span>{viewTimeData.totalTime ? viewTimeData.totalTime : 0}h</span>
        </Styled.ChartTop>
        <LineChart labels={viewTimeData?.day} data={viewTimeData?.time} />
      </Styled.Box>
    </>
  );
};
export default ViewTimeSlide;

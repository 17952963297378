import React from "react"
import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"


const PrivateRoute = ({ component: Component, redirectUrl = '/', ...rest }) => {

  const login = useSelector((state) => state.user.token)

  return <Route {...rest} render={(props) => {
    if (login) {
      return <Component {...props} />
    } else {
      return <Redirect to={redirectUrl} />
    }
  }} />
}

export default PrivateRoute;
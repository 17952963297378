import styled from "styled-components";
import arrowRightIcon from 'assets/images/ico-omr-arrow.png';
import arrowRightIcon2 from 'assets/images/arrow_right.svg';
import visualBgImg from 'assets/images/event/220110/bg.jpg';
import visualImg from 'assets/images/event/220110/visual_img.png';
import checkIconImg from 'assets/images/event/220110/ico_check.png';
import arrowBottomImg from 'assets/images/event/220110/arrow_bottom.png';
import btnLoginIcon from 'assets/images/event/220110/ico_login.png';
import btnLoginArrowIcon from 'assets/images/event/220110/arrow_right.png';
import testArrowIcon from 'assets/images/event/220110/arrow_right_r.png';
import testArrowIcon2 from 'assets/images/event/220110/arrow_right_w.png';
import awardBgImg from 'assets/images/event/220110/bg_awads.jpg';
import awardBgLargeImg from 'assets/images/event/220110/bg_awads_large.jpg';
import testBgImg from 'assets/images/event/220110/btn_bg.png';
import testGoArrowIcon from 'assets/images/event/220110/arrow_right2.png';

const StyledEventPage = {
  EventWrap: styled.div`
    font-size: 1.6em;
    line-height: 1.2;
    background:#fff;
    text-align:center;
    color:#555;

    .inner {
      width: 840px;
      padding: 0 20px;
      margin: auto;
    }

    h2 {
      padding:3em 0 1.4em;
      font-size:3.4rem;
      color:#292d77;
      font-weight:700;
      strong {
        color:#ed4044;
      }
    }
    .visual {
      position:relative;
      height: 800px;
      background:#0b1026 url(${visualBgImg}) 50% 0 no-repeat;
      background-size:cover;
      padding-top:16em;
      color:#fff;

			.txt {
				width: 100%;
				padding-bottom: 54%;
				background: url(${visualImg}) 50% 100% no-repeat;
  			background-size: contain;
				text-align: center;
				.sub {
					font-weight: 400;
					font-size: 2.6rem;
				}
				.title {
					margin-top: 0.5em;
					line-height: 1;
					font-weight: 700;
					font-size: 6.4rem;
				}
				.date {
					margin: 1.3em 0;
					font-weight: 700;
					font-size: 2.4rem;
					color: #bdfffd;
				}
			}
      img {
        position: relative;
				max-width: 800px;
				margin: 0 auto;
      }
    }
    .timeline {
      position: absolute;
      top:4em;
      z-index:5;
      width:800px;
      padding: 1em 2em;

      &:before {
        content: "";
        display:block;
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:#fff;
        opacity: .75;
      }
      .deadline {
        position: relative;
        font-size:1.6rem;
        color:#0e1222;
        border-bottom:1px #0e1222 solid;
        line-height:1;
      }
      .days {
        position: absolute;
        top:50%;
        left:2em;
        width: 5.6em;
        height: 5.6em;
        margin-top: -2.8em;
        padding-top: 1.4em;
        background:#292d77;
        border-radius: 2.8em;
        opacity: .5;
        line-height:1;
        font-size:1.8rem;
        em {
          font-size: 2.6rem;
          font-weight: 700;
        }
      }
      .time {
        position: relative;
        display:flex;
        margin: 1em 9em 0;
        div {
          flex:1;
          position: relative;
          color:#00203f;
          strong {
            display:block;
            font-size:4rem;
            line-height: 1;
          }
          span {
            font-size:1.4rem;
            color:#445d80;
          }
          &:after {
            content: ":";
            position: absolute;
            top: 0.1em;
            right: 0;
            font-size:4rem;
            line-height: 1;
            color:#00203f;
            font-weight:700;
          }
          &:first-child:after,
          &:last-child:after {
            display:none;
          }
        }
      }
      a {
        position: absolute;
        top:50%;
        right:2em;
        width: 5.6em;
        height: 5.6em;
        padding-top:1em;
        margin-top: -2.8em;
        background:#f8444f;
        border-radius: 2.8em;
        line-height: 1.2;
        font-size:1.8rem;
        font-weight:400;
        &:after {
          content: "";
          position: absolute;
          left:50%;
          bottom: 0.8em;
          width:0.6em;
          height:1em;
          margin-left:-0.4em;
          background: url(${arrowRightIcon}) no-repeat center;
          background-size:contain;
          transform: scaleX(-1);
        }
      }
    }
    .section1 {
      padding-top: 11em;
      padding-bottom: 5em;
      .btnWrap {
        padding: 0 7.6em;
        a {
          display:block;
          padding: 1.5em 0;
          background:#1d93d3;
          border-radius: 3em;
          color:#fff;
          font-size: 2.2rem;
          font-weight:700;

          &:after {
            content: "";
            display: inline-block;
            width: 2.4em;
            height: 0.9em;
            background: url(${arrowRightIcon2}) no-repeat center;
            background-size: contain;
          }
        }
      }
      .check {
        margin-bottom:3.6em;
        li {
          background: url(${checkIconImg}) 0 0.2em no-repeat;
          background-size: 1em auto;
          padding-left: 1.5em;
          margin-top: 0.5em;
          text-align:left;
          font-size:2rem;
          font-weight:400;
        }
        li:first-child {
          margin-top:0;
        }
      }
      h3 {
        font-size:2.2rem;
        color:#000;
        font-weight:400;
        margin-bottom: 1.6em;
      }
      .benefit {
        display:flex;
        justify-content: space-between;
        li {
          position:relative;
          font-size:1.8rem;
          text-align: center;
          font-weight: 400;
          em {
            display:flex;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            top:-2em;
            right:0;
            background:#00203f;
            width: 7em;
            height: 7em;
            border-radius: 3.5em;
            color:#fff;
            font-size:1.4rem;
          }
          p {
            margin-top: 0.8em;
          }
        }
      }
      .btnWrapCounsel {
        margin-top:2em;
        text-align: right;
        a {
          display:inline-block;
          padding: 0.6em 1em;
          background:#f8444f;
          border-radius: 0.6em;
          color:#fff;
          font-size: 1.8rem;
          font-weight:700;

          &:after {
            content: "";
            display: inline-block;
            width: 2em;
            height: 0.8em;
            background: url(${arrowRightIcon}) no-repeat center;
            background-size: contain;
            transform: scaleX(-1);
          }
        }
      }
    }

    .section2 {
      position:relative;
      padding: 1em 0;
      background:#004290;
      font-size:2.2rem;
      color:#fff;
      font-weight: 400;

      &:after {
        content: "";
        position: absolute;
        left:50%;
        bottom:-0.6em;
        width: 1em;
        height: 0.6em;
        margin-left: -0.5em;
        background: url(${arrowBottomImg}) 0 0 no-repeat;
        background-size: contain;
      }
    }

    .section3 {
      background:#cfeae1;
      padding-bottom: 5em;

      ol {
        text-align: left;
        padding-left: 7.2em;
        li {
          position:relative;
          min-height: 3em;
          padding:1.5em 0 1.5em 6em;
          color:#000;
          font-size:2.2rem;
          font-weight: 700;

          &:before {
            content:"";
            position:absolute;
            left: 1.5em;
            width:2px;
            height:100%;
            background: #292d76;
          }
          &:last-child:before {
            display:none;
          }

          em {
            display:inline-block;
            position:absolute;
            top: 50%;
            left:0;
            width: 3em;
            height: 3em;
            margin-top: -1.5em;
            border-radius: 1.5em;
            background: #292d76;
            color:#fff;
            text-align:center;
            line-height: 1;
            padding-top:0.3em;

            span {
              font-size: 1.2rem;
            }
          }
          span {
            font-size: 1.8rem;
            font-weight: 400;
          }
          .btnLogin {
            display:inline-block;
            text-decoration:underline;

            &:before {
              content:"";
              display:inline-block;
              width: 1.9em;
              height: 1.9em;
              background: url(${btnLoginIcon}) 0 0 no-repeat;
              background-size: contain;
              vertical-align:middle;
              margin-right: 0.8em;
            }
            &:after {
              content:"";
              display:inline-block;
              width: 0.6em;
              height: 1em;
              background: url(${btnLoginArrowIcon}) 50% 50% no-repeat;
              background-size: contain;
              vertical-align:middle;
              margin-left: 0.5em;
            }
          }
          .btnDown {
            display:inline-block;
            padding: 0.2em 0.4em;
            margin-left:0.5em;
            background:#000;
            border-radius:0.2em;
            font-size:1.4rem;
            color:#fff;
            font-weight:300;
          }
        }
      }

      .testBox {
        background:#57b2c2;
        border-radius:2em;
        margin-top:4em;
        padding: 3.8em 8em;

        h3 {
          margin-bottom: 1.5em;
          font-size: 2.8rem;
          color:#002b4d;
          font-weight:700;
        }

        ul li {
          background:#fff;
          border-radius:0.3em;
          padding: 1.2em 2.6em;
          margin-top: 1em;
          text-align:left;
          color:#004290;
          font-weight:700;
          font-size: 2.2rem;
          &:after {
            content:"";
            display:block;
            clear:both;
          }
          &:first-child {
            margin-top: 0;
          }
          &:last-child {
            background:#f8444f;
            color:#fff;
            a {
              color:#fff;
              background: url(${testArrowIcon2}) right center no-repeat;
              background-size: 1em auto;
            }
          }
          a {
            float:right;
            margin-top:0.2em;
            padding-right: 1.6em;
            font-size:1.8rem;
            color:#ed4044;
            font-weight:400;
            text-decoration:underline;
            background: url(${testArrowIcon}) right center no-repeat;
            background-size: 1em auto;
          }
        }
      }
      dl {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        align-items: flex-start;
        font-weight:400;
        dt {
          flex: 0 0 10em;
          margin-top:1.4em;
          border:3px #9fcdbe solid;
          border-radius: 0.3em;
          padding: 0.3em 0;
          font-size:1.7rem;
          line-height:1;
          color:#006360;
          &:first-child {
            margin-top:0;
          }
        }
        dd {
          flex:0 0 73%;
          margin-top:1.2em;
          padding: 0 0 0.3em 2.6em;
          font-size:2.2rem;
          color:#00203f;
          font-weight: 400;
          text-align:left;
          &:first-of-type {
            margin-top:0;
          }
          span {
            color:#445d80;
          }
        }
      }
    }
    .section4 {
      background:#00203f url(${awardBgImg}) center 0 no-repeat;
      padding-bottom: 5em;
      h2 {
        padding-top: 113px;
      }
      .prizes ol {
        display:flex;
        justify-content: space-between;
        li {
          flex: 1;
          color:#fff;
          font-size: 2.2rem;
          strong {
            display:block;
            padding: 1.1em 0;
            font-weight: 400;
          }
          dd {
            padding-bottom:1.1em;
            span {
              color:#faefdd;
            }
          }
        }
      }
      .prizes ul {
        margin-top: 4em;
        text-align:left;
        li {
          position:relative;
          padding-left:0.8em;
          margin-bottom: 0.5em;
          color:#7999ae;
          &:before {
            content:"";
            display:inline-block;
            position: absolute;
            top:0.4em;
            left:0;
            width: 0.2em;
            height: 0.2em;
            border-radius: 0.1em;
            background:#7999ae;
            vertical-align:middle;
          }
        }
      }
			.prizes .people {
				position: relative;
				display: inline-block;
				span {
					position: absolute;
					bottom: 13%;
					left: 0;
					width: 100%;
					font-weight: 400;
					font-size: 1.5rem;
					color: #00203f;
					em {
						font-weight: 700;
						font-size: 1.1em;
					}
				}
			}
      h3 {
        padding: 3.2em 0 1.4em;
        color:#cfeae1;
        font-size:3.4rem;
        font-weight:700;
        strong {
          color:#f84080;
        }
      }
      table {
        width: 100%;
        font-size: 2.2rem;
        color:#fff;
        tbody th,
        tbody td {
          width: 25%;
          padding: 1.2em 0;
          border-top:1px #477582 solid;
          border-bottom:1px #477582 solid;
        }
        tbody th {
          background:#c85687;
          font-weight:700;
        }
        tbody td {
          background:none;
        }
        tr:nth-child(n+4) th {
          color:#00203f;
          background:#578a97;
        }
        sup {
          position: relative;
          top:-0.4em;
          font-size:75%;
          vertical-align:baseline;
          line-height:0;
        }
      }
      .txt {
        margin: 2em 0 7em;
        text-align:right;
        color:#7999ae;
      }
      .share {
        display:flex;
        align-content: space-between;
        align-items: center;
        background:#9eddd4;
        padding: 1em 3em;
        text-align:left;
        font-size: 2.4rem;
        font-weight:700;
        color:#00203f;
        &:after {
          content:"";
          display:block;
          clear:both;
        }
        span {
          display:block;
          flex:1 1 0;
          text-align:right;
        }
        a {
          text-align:right;
        }
      }
      .notification {
        text-align:left;
        color:#ffffff;
        font-size:1.8rem;
        line-height: 1.3;

        h3 {
          padding:4em 0 0 0;
          font-size:3rem;
          color:#fff;
        }
        dt {
          padding: 1.6em 0 0.8em;
        }
        dd {
          position:relative;
          padding-left:0.8em;
          margin-bottom: 0.5em;
          &:before {
            content:"";
            display:inline-block;
            position: absolute;
            top:0.5em;
            left:0;
            width: 0.2em;
            height: 0.2em;
            border-radius: 0.1em;
            background:#fff;
            vertical-align:middle;
          }
          em {
            color:#fff799;
          }
          a {
            text-decoration:underline;
          }
        }
        P {
          padding: 1.6em 0 0.8em;
          a {
            text-decoration:underline;
          }
        }
      }
      .btnTestBottom {
        position: relative;
        margin-top: 5em;
        &:before,
        &:after {
          content:"";
          display:block;
          position:absolute;
          top:0;
          width:50%;
          height:100%;
        }
        &:before {
          left:0;
          background:#46fbbd;
        }
        &:after {
          right:0;
          background:#9a50ff;
        }
        .btnBox {
          position:relative;
          z-index:2;
          max-width:1300px;
          margin: auto;
          width:100%;
          padding: 2em 0;
          background: url(${testBgImg}) 50% 0 no-repeat;
          a {
            font-size:3rem;
            color:#00203f;
            font-weight:700;
            &:after {
              content: "";
              display: inline-block;
              width: 1em;
              height: 0.5em;
              background: url(${testGoArrowIcon}) no-repeat center;
              background-size: contain;
              margin-left: 0.6em;
            }
          }
        }
      }
    }

    @media (max-width: 1024px) {
      .inner {
        width: 100%;
        margin: 0;
      }
      h2 {
        padding:2em 0 1.6em;
      }
      .visual {
				height: auto;
				background-color: #fff;
				background-size: auto 80%;
        .inner {
          padding:0;
        }
				.txt {
					padding-bottom: 48%;
					background-size: 90% auto;
					.sub {
						font-size: 2.4rem;
					}
					.title {
						font-size: 5.8rem;
					}
					.date {
						font-size: 2.2rem;
					}
				}
      }
      .timeline {
        left:2em;
        width: calc(100% - 4em);
        padding: 1.2em 1em;
        .days {
          left:1em;
        }
        a {
          right:1em;
        }
        .time {
          margin: 1em 6.4em 0;
          div {
            strong {
              font-size:2em;
            }
            &:after {
              font-size:2em;
            }
          }
        }
      }
      .section1 {
        padding-top: 0;
      }
      .section4 {
        padding-top: 19em;
        background:#00203f url(${awardBgLargeImg}) center 0 no-repeat;
        background-size: contain;
        h2 {
          display:none;
        }
				.prizes .people {
					span {
						font-size: 2rem;
					}
				}
        h3 {
          padding:2em 0 1.6em;
        }
        .txt {
          margin:2em 0 5em;
        }
        .notification h3 {
          padding-top: 3em;
        }
      }
    }
    @media (max-width: 768px) {
      .benefit {
        li {
          img {
            width: 80%;
          }
        }
      }
      .section3 {
        ol {
          padding-left: 2em;
          li {
            padding:1.5em 0 1.5em 4em;
          }
        }
        .testBox {
          padding: 3em 4em;
        }
      }
      .section4 {
        padding-top: 14em;
        .share {
          padding: 1em;
          img {
            width: 30%;
          }
        }
        .btnTestBottom {
          .btnBox {
            a {
                font-size:2.6rem;
              }
            }
          }
        }
      }
    }
    @media (max-width: 680px) {
			.visual {
        padding-top: 14em;
				.txt {
					.sub {
						font-size: 2.2rem;
					}
					.title {
						font-size: 5.2rem;
					}
					.date {
						font-size: 2rem;
					}
				}
      }
      .timeline {
        top: 3em;
      }
    }
    @media (max-width: 550px) {
			.visual {
        padding-top: 12em;
				.txt {
					.sub {
						font-size: 2rem;
					}
					.title {
						font-size: 4rem;
					}
					.date {
						font-size: 1.8rem;
					}
				}
			}
      .timeline {
        top: 2em;
      }
    }
    @media (max-width: 414px) {
      .section1 {
        .btnWrap {
          padding: 0;
          a {
            font-size: 2rem;
          }
        }
        .benefit {
          li {
            em {
              width: 5.4em;
              height: 5.4em;
              border-radius: 2.7em;
              font-size:1.4rem;
            }
          }
        }
      }
      .section3 {
        ol {
          padding-left: 0;
        }
        .testBox {
          padding: 2em;
          h3 {
            margin-bottom: 1em;
            font-size: 2.2rem;
          }
          ul li {
            padding: 1em;
          }
        }
        dl dd {
          flex: 0 0 63%;
          padding: 0 0 0.3em 1em;
        }
      }
      .section4 {
        background-size: 140% auto;
        padding-top: 12em;
        .prizes ol {
          li {
            img {
              width: 80%;
            }
          }
        }
        .prizes ul {
          margin-top: 2em;
        }
				.prizes .people {
					span {
						font-size: 1.8rem;
					}
				}
        .share {
          font-size: 2rem;
          img {
            width: 50%;
          }
        }
        h3 {
          padding:2em 0 1.6em;
        }
        .btnTestBottom {
          .btnBox {
            a {
                font-size:2.2rem;
              }
            }
          }
        }
      }
    }
  `,
};

export default StyledEventPage;

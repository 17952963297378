import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import MyClassroomTab from 'container/ClassroomPage/MyClassroom';
import MockTestTab from 'container/ClassroomPage/MockTest';
import CurriculumTab from 'container/ClassroomPage/Curriculum';
import MyCurriculum from 'container/MyCurriculumPage';
import MyCurriculumDetail from 'container/MyCurriculumPage/MyCurriculumDetail';


export default function CoursesRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={MyClassroomTab} />
      <Route exact path={`${path}/topik-mock-test`} component={MockTestTab} />
      {/* <Route exact path={`${path}/curriculum`} component={MyCurriculum} />
      <Route exact path={`${path}/curriculum/:curriculumId`} component={MyCurriculumDetail} /> */}
    </Switch>
  );
}

import React from 'react';
import styled from 'styled-components';
import Img from 'component/atoms/Img';
import loadingLogo from 'assets/images/logo_loading.png';

const Container = styled.div`
  clear: both;
  font-size: 1rem;
  height: 16em;
  padding: 5em 0;
  text-align: center;
  animation: fading 3s ease-in-out infinite;
  overflow: hidden;
  &.light {
    padding: 2em 0;
    .img-box {
      display: inline-block;
      padding: 1em;
      border-radius: 0.6em;
      background-color: #0f1012;
    }
    p {
      color: #333;
    }
  }
  p {
    font-size: 3rem;
    color: #c7cad1;
  }
  img {
    width: 5em;
  }
  @keyframes fading {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
  @media (max-width: 1024px) {
    img {
      width: 3em;
    }
  }
`;

const Loading = ({ isLight = false }) => (
  <Container
    className={isLight ? 'light loading-container' : 'loading-container'}
  >
    <span className='img-box'>
      <Img src={loadingLogo} alt='Loading' />
    </span>
    <p>Loading...</p>
  </Container>
);

export default Loading;

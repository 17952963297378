import React from 'react';
import Styled from './styled/TeacherIntro.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { masterTopikUrl } from 'common/constant';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';
import Style from 'container/MockTestPage/styled/component.style';


const TeacherIntro = ({ data }) => {
  const { t } = useTranslation('courseDetailPage');

  const {
    courseResponse: {
      aboutCourse, recommandGroup, productAfterEffect, productLang
    }
  } = data;

  return (
    <Styled.Container>
      <ContentsWrapper>

        {aboutCourse &&
          <Styled.IntroBox className='first'>
            <Styled.H2>
              About Course
            </Styled.H2>
            <Styled.Slg>
              {aboutCourse}
            </Styled.Slg>
          </Styled.IntroBox>
        }

        {recommandGroup &&
          <Styled.IntroBox>
            <Styled.H2>
              Target
            </Styled.H2>
            <Styled.Slg>
              {recommandGroup}
            </Styled.Slg>
          </Styled.IntroBox>
        }

        {productAfterEffect &&
          <Styled.IntroBox>
            <Styled.H2>
              Expected Outcomes
            </Styled.H2>
            <Styled.Slg>
              {productAfterEffect}
            </Styled.Slg>
          </Styled.IntroBox>
        }

        {productLang &&
          <Styled.IntroBox>
            <Styled.H2>
              Course Language
            </Styled.H2>
            <Styled.Slg>{productLang}</Styled.Slg>
          </Styled.IntroBox>
        }

      </ContentsWrapper>
    </Styled.Container>
  );
};

export default TeacherIntro;



import styled from 'styled-components';

const StyledIndividualTable = {
  Container: styled.div`
    margin-bottom: 21.5em;
    padding: 3.5em 3em;
    border-radius: 0.6rem;
    background-color: #1e2024;

    @media (max-width: 765px) {
      padding: 2em 1.5em;
    }
  `,
  Table: styled.table`
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    font-size: 1.6em;

    thead th {
      padding-bottom: 1.3em;
      vertical-align: middle;

      &:first-of-type {
        border-right: 1px #2c2c2c solid;
      }
    }
    tbody th {
      padding: 3.25em 0;
      font-weight: 700;
      vertical-align: middle;
    }
    tbody tr {
      border-top: 1px #2c2c2c solid;
      border-bottom: 1px #2c2c2c solid;

      &:last-of-type {
        border: none;
      }
    }

    tbody tr > * {
      border-right: 1px #2c2c2c solid;

      &:last-child {
        border: none;
      }
    }

    tbody td {
      padding: 0 2.5em;
      vertical-align: middle;
    }
    @media (max-width: 767px) {
      display: none;
    }
  `,
  MobileTable: styled.table`
    display: none;
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    font-size: 1.6em;
    thead th {
      padding-bottom: 1.3em;
      vertical-align: middle;

      &:first-of-type {
        border-right: 1px #2c2c2c solid;
      }
    }
    tbody th {
      padding: 2em 0.3em;
      font-weight: 700;
      vertical-align: middle;
    }
    tbody tr {
      border-top: 1px #2c2c2c solid;
      border-bottom: 1px #2c2c2c solid;

      &:last-of-type {
        border: none;
      }
    }

    tbody tr > * {
      border-right: 1px #2c2c2c solid;

      &:last-child {
        border: none;
      }
    }

    tbody td {
      padding: 1em 1.5em 1em 1.5em;
      vertical-align: middle;
    }

    p {
      margin-bottom: 1em;
    }

    @media (max-width: 767px) {
      display: table;
    }
  `,

  ResultBtn: styled.button`
    padding: 0.7em 1.6em;
    background-color: #2a2c2e;
    border-radius: 0.6rem;
    color: #fff;
    font-size: 0.875em;
  `,

  Bar: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1.2em;

    .bar {
      position: relative;
      display: inline-block;
      margin-right: 1em;
      width: 25em;
      height: 0.6rem;
      border-radius: 3px;
      background-color: #2e3033;

      @media (max-width: 767px) {
        width: 7em;
        height: 1rem;
      }

      &:before {
        content: '';
        position: absolute;
        width: ${({ width }) => (width ? `${width}%` : 0)};
        height: 100%;
        border-radius: 3px;
      }
      &.listening:before {
        background-color: #80aa00;
      }
      &.writing:before {
        background-color: #8b56e3;
      }
      &.reading:before {
        background-color: #00acb2;
      }
    }
  `,
};

export default StyledIndividualTable;

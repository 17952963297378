import styled from 'styled-components';
import logoImage from 'assets/images/mockTestLogo.png';
const StyledStartPage = {
  Container: styled.div`
    margin: 5em 8.6em;
    padding: 6em 3em;
    background: #fff;
    border-radius: 0.8em;
    text-align: center;
    color: #1e2024;
    @media (max-width: 1024px) {
      margin: 3em 4em;
    }
  `,
  Logo: styled.p`
    display: inline-block;
    overflow: hidden;
    width: 19em;
    height: 1.9em;
    background: url(${logoImage}) no-repeat 0 0;
    background-size: contain;
    line-height: 500px;
  `,
  Title: styled.p`
    margin-top: 0.958em;
    font-size: 4.8em;
    font-weight: 700;
    line-height: 1.25em;

    @media (max-width: 767px) {
      font-size: 3.2em;
    }
  `,
  Subject: styled.p`
    font-size: 4em;
    font-weight: 500;
    @media (max-width: 767px) {
      font-size: 2.4em;
    }
  `,
  TotalTime: styled.div`
    margin-top: 1.591em;
    font-size: 2.2em;
    font-weight: 500;
    > span {
      display: inline-block;
      margin-left: 0.714em;
      font-size: 1.273em;
      font-weight: 900;
      color: #485cc7;
    }
    @media (max-width: 767px) {
      font-size: 1.8em;
      > span {
        display: block;
        margin-left: 0;
      }
    }
  `,
  Desc: styled.div`
    margin: 2.778em 0;
    font-size: 1.8em;
    font-weight: 400;
    color: #393c40;
    .sub {
      margin-top: 0.625em;
      font-size: 0.889em;
      font-weight: 300;
      color: #a2a2a2;
    }
    @media (max-width: 767px) {
      font-size: 1.5em;
    }
  `,
  Btn: styled.button`
    display: inline-block;
    padding: 1.063em 2.125em;
    min-width: 21.25em;
    background-color: #ffb500;
    border-radius: 0.25em;
    line-height: 1.25em;
    color: #fff;
    font-size: 1.6em;
    font-weight: 600;
    @media (max-width: 767px) {
      min-width: 100%;
    }
  `,
};

export default StyledStartPage;

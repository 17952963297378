import React, { useEffect } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import PrivateRoute from "./component/PrivateRoute";
import { ModalProvider } from "component/Modal/modalContext";
import { useDispatch, useSelector } from "react-redux";
import { MOBILE_APP_USER_AGENT, UserStatus } from "common/constant";
import styled from "styled-components";

import "assets/css/font.css";
import "assets/css/font-jp.css";
import "assets/css/font-cn.css";
import "assets/css/reset.css";
import "react-toastify/dist/ReactToastify.css";

import Header from "component/layout/Header";
import Footer from "component/layout/Footer";
import LandingPage from "container/LandingPage";
import Teachers from "container/TeachersPage";
import Library from "container/LibraryPage";
import CourseDetail from "container/CourseDetailPage";
import CourseView from "container/CourseViewPage";
import Course from "container/CoursePage";
import Resource from "container/ResourcePage";
import NotificationPage from "container/NotificationPage";
import UserHomePage from "container/UserHomePage";
import Profile from "container/ProfilePage";
import UserBookmarkPage from "container/UserBookmarkPage";
import OAuth2RedirectHandler from "component/user/oauth2/OAuth2RedirectHandler";
import { actions as userActions } from "component/user/state";
import ScrollToTop from "common/util/ScrollToTop";
import LevelTestPage from "container/LevelTestPage";
import ResetPassword from "container/userPage/ResetPassword";
import Plan from "container/PlanPage";
import PaymentPage from "container/PaymentPage";
import ThemeDetailByUser from "container/ThemeDetailPage/ThemeDetailByUser";
import ThemeDetailByTag from "container/ThemeDetailPage/ThemeDetailByTag";
import UserMTLivePage from "container/MyMT/UserMTLivePage";
import UserVocaCardPage from "container/MyMT/UserVocaCardPage";
import SettingPage from "container/SettingPage";
import CancelPage from "container/SettingPage/CancelPage";
import InvoicePage from "container/SettingPage/InvoicePage";
import CancelSurveyPage from "container/SettingPage/CancelSurveyPage";
import LearningMaterial from "container/LearningMaterialPage";
import LearningMaterialDetail from "container/LearningMaterialPage/Detail";
import BookStorePage from "container/BookStorePage";
import ClassroomPage from "container/ClassroomPage";
import { Slide, ToastContainer } from "react-toastify";
import CurriculumView from "container/ClassroomPage/CurriculumView";
import OrderComplete from "container/OrderComplete";
import InitLoading from "component/atoms/InitLoading";
import { useRedirect } from "common/hook/useRedirectHooks";
import ErrorPage from "component/atoms/Error";
import { useTranslation } from "react-i18next";
import PlanPayment from "container/PlanPaymentPage";
import MockTestPage from "container/MockTestPage";
import ConfirmationEmail from "component/user/AuthEmail";
import OrderRedirectHandler from "container/OrderComplete/OrderRedirectHandler";
import { Helmet } from "react-helmet";
import { GetLanguage } from "common/util/GetLanguage";
import ResultTestPaper from "container/MockTestPage/TestResultPage";
import CompleteSignupPage from "container/userPage/CompleteSignupPage";
import useLoginStatus from "common/hook/useLoginStatus";
import TermsPage from "container/TermsPage";
import OrderFailed from "container/OrderComplete/OrderFailed";
import CommunityRouter from "container/CommunityPage/Router";
import MockTestReportPage from "container/MockTestReportPage";
import DormantAccountPage from "component/atoms/DormantAccount";
import CloseWindow from "component/closeWindow";
import FooterBanner from "component/layout/Footer/LineBanner";
import AppPayLetter from "container/PaymentPage/AppPayletter";
import PaymentChangeRedirectHandler from "container/SettingPage/PaymentChangeComplete";
import AboutPage from "container/AboutPage";
import AliveClassRoutes from "container/AliveClassPage/Routes";
import EventPageRouter from "container/EventPage";
import AISpeakPage from "container/AISpeakPage";
import HelpPageRouter from "container/HelpPage";
import Jresearch from "container/JresearchPage";
import WbmKorea from "container/WbmKoreaPage";
import Audio from "container/AudioPage";
import AudioDetail from "container/AudioDetailPage/index";
import MyCurriculum from "container/MyCurriculumPage/index";
import MyCurriculumDetail from "container/MyCurriculumPage/MyCurriculumDetail/index";
import LearningHistoryDetail from "container/LearningHistoryPage/LearningHistoryDetail";


const Wrapper = styled.div`
  min-height: 70vh;
  &.with-line-banner {
    padding-top: 6rem;
  }
`;

export default function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { status: loginStatus, data: user, token } = useSelector(
    (state) => state.user
  );

  const { i18n, ready: i18nReady } = useTranslation();

  if (process.env.REACT_APP_ENV === "production") {
    console.log = function no_console() {};
    console.warn = function no_console() {};
  }

  // 로그인 초기화
  useEffect(() => {
    token && !user && dispatch(userActions.fetchUser(token));
    dispatch(userActions.initUser());
  }, []);

  useEffect(() => {
    const isRest = user?.isRest;
    if (token && isRest) {
      history.replace("/dormant-account");
    }
  }, [location.pathname]);

  useEffect(() => {
    const redirectErrorMessage = location.search;
    const userAgent = window.navigator.userAgent;
    if (location.search && userAgent === MOBILE_APP_USER_AGENT) {
      window.WebInterface?.clearCookie(true);
    }
    if (redirectErrorMessage.includes("?error=")) {
      const sliceErrorMessage = redirectErrorMessage
        .split("?error=")
        .filter((text) => text !== "")[0];
      alert(decodeURIComponent(sliceErrorMessage));
      history.replace("/");
    }
  }, []);

  useEffect(() => {
    if (!user?.selectLanguage) return;
    const selectedLangByUser = new GetLanguage(user.selectLanguage);

    if (!i18n.language && selectedLangByUser.locale === i18n.language) return;
    i18n.changeLanguage(selectedLangByUser.locale);
  }, [i18n, i18n.language, user]);

  const isBannerClose = useSelector((state) => state.common.isBannerClose);
  const isLogin = useLoginStatus();

  useRedirect();

  return (
    <ModalProvider>
      <Helmet htmlAttributes={{ lang: i18n.language }} />
      <Switch>
        <PrivateRoute
          exact
          path="/mock-test/result/:productId/:unitId"
          component={ResultTestPaper}
          redirectUrl="/"
        />
        <PrivateRoute
          exact
          path="/mock-test/:productId/:unitId"
          component={MockTestPage}
          redirectUrl="/"
        />
        <PrivateRoute
          exact
          path="/bookstore-app-payment"
          component={AppPayLetter}
        />

        <ScrollToTop>
          <Header/>

          <Wrapper className={!isBannerClose && !isLogin && "with-line-banner"}>
            {!i18nReady && <InitLoading />}
            <Switch>
              <Route
                exact
                path="/dormant-account"
                component={DormantAccountPage}
              />

              <Route
                exact
                path="/"
                component={
                  loginStatus === UserStatus.Login ? UserHomePage : LandingPage
                }
              />
              <Route
                exact
                path="/courses/detail/:productId"
                component={CourseDetail}
              />
              <PrivateRoute
                exact
                path="/courses/detail/:productId/:lectureId"
                component={CourseView}
                redirectUrl="/"
              />
              <Route path="/courses" component={Course} />

              <Route
                path={["/teachers/:id", "/teachers"]}
                component={Teachers}
              />
              <Route path="/resources" component={Resource} />
              <Route path="/library" component={Library} />
              <Route path="/reset_password/confirm" component={ResetPassword} />

              <PrivateRoute
                exact
                path="/classroom/curriculum"
                component={MyCurriculum}
              />

              <PrivateRoute
                exact
                path="/classroom/curriculum/:curriculumId"
                component={MyCurriculumDetail}
                redirectUrl="/courses"
              />

              <PrivateRoute
                path="/classroom"
                component={ClassroomPage}
                redirectUrl="/courses"
              />
              <PrivateRoute
                path="/mt-live"
                component={UserMTLivePage}
                redirectUrl="/"
              />
              <Route
                path="/voca-card"
                component={UserVocaCardPage}
                redirectUrl="/"
              />
              <Route
                path="/bookstore"
                component={BookStorePage}
                redirectUrl="/"
              />
              {/* Temporary */}
              <PrivateRoute path="/notification" component={NotificationPage} />
              <PrivateRoute path="/profile" component={Profile} />
              <PrivateRoute path="/bookmark" component={UserBookmarkPage} />
              <Route exact path="/plan" component={Plan} />
              <PrivateRoute path="/payment/:type" component={PaymentPage} />
              <Route path="/terms" component={TermsPage} />
              <PrivateRoute
                path="/test-report"
                component={MockTestReportPage}
              />
              <PrivateRoute
                exact
                path="/setting"
                component={SettingPage}
                redirectUrl="/"
              />
              <PrivateRoute
                path="/setting/cancel"
                component={CancelPage}
                redirectUrl="/"
              />
              <PrivateRoute
                path="/setting/cancel-survey"
                component={CancelSurveyPage}
                redirectUrl="/"
              />
              <PrivateRoute
                path="/setting/invoice/:invoiceId"
                component={InvoicePage}
                redirectUrl="/"
              />
              <Route
                exact
                path="/learning-material"
                component={LearningMaterial}
                // redirectUrl='/'
              />

              <Route
                path="/level-test"
                component={LevelTestPage}
                redirectUrl="/"
              />

              <Route
                exact
                path="/learning-material/detail/:id"
                component={LearningMaterialDetail}
              />
              <Route
                exact
                path="/theme/tag/:keyword"
                component={ThemeDetailByTag}
              />
              <Route exact path="/theme/:id" component={ThemeDetailByUser} />

              <PrivateRoute
                exact
                path="/plan-payment/:planName"
                component={PlanPayment}
              />
              <PrivateRoute
                exact
                path="/order-return/:orderType/:orderId"
                component={OrderRedirectHandler}
              />
              <PrivateRoute
                exact
                path="/order-complete/:orderType/:orderId"
                component={OrderComplete}
              />
              <PrivateRoute
                exact
                path="/order-failed/"
                component={OrderFailed}
              />

              <PrivateRoute
                exact
                path="/payment-change"
                component={PaymentChangeRedirectHandler}
              />

              <PrivateRoute path="/community" component={CommunityRouter} />

              <PrivateRoute
                exact
                path={`/complete-signup`}
                component={CompleteSignupPage}
              />
              <Route path="/auth/redirect" component={OAuth2RedirectHandler} />
              <Route path="/redirect/close-window" component={CloseWindow} />

              <Route
                path={[
                  "/registration/confirmation/:ssid/:userID",
                  "/registration/confirmation/:ssid",
                ]}
                component={ConfirmationEmail}
              />
              <Route path="/about" component={AboutPage} />
              <Route path="/alive" component={AliveClassRoutes} />
              <Route path="/event" component={EventPageRouter} />
              {/* <Route path="/mt-alive" component={EventPage} /> */}

              <Route
                exact
                path="/aispeak"
                component={AISpeakPage}
               />

              <Route path="/help" component={HelpPageRouter} />

              <Route
                  exact
                  path="/Jresearch"
                  component={Jresearch}
              />

              <Route
                  exact
                  path="/WBM"
                  component={WbmKorea}
              />

              <Route
                exact
                path="/audio"
                component={Audio}
              />

              <Route
                exact
                path="/audio/:bookLevel"
                component={AudioDetail}
              />


              <Route
                exact
                path="/learningHistory"
                component={LearningHistoryDetail}
              />

              <Route component={ErrorPage} />


            </Switch>
          </Wrapper>

          <FooterBanner />
          <Footer />

          <ToastContainer
            transition={Slide}
            position="top-center"
            autoClose={3000}
            hideProgressBar={true}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
            progress={0}
          />
        </ScrollToTop>
      </Switch>
    </ModalProvider>
  );
}

import React from 'react';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import StyledUserHomePage from 'container/UserHomePage/index.style';
import contentsApi from 'api/contents';
import bookmarksApi from 'api/bookmarks';
import themesApi from 'api/theme';
import WatchedList from 'container/UserHomePage/WatchedList';
import BookmarkList from 'container/UserHomePage/BookmarkList';
import MyThemeList from 'container/UserHomePage/MyThemeList';

export default function MyClassList() {
  const token = useSelector((state) => state.user.token);
  const watchedListState = useAxios(contentsApi.getWatchedList, [], true);
  const bookmarkListState = useAxios(bookmarksApi.getListOfUser, [], true);
  const myThemeListState = useAxios(themesApi.getMyPageMyThemes, [], true);

  return (
    <StyledUserHomePage.Wrapper>
      <WatchedList initialState={watchedListState} />
      <BookmarkList initialState={bookmarkListState} />
      <MyThemeList initialState={myThemeListState} />
    </StyledUserHomePage.Wrapper>
  );
}

import styled from 'styled-components';
import { IconTextStyles } from 'component/atoms/Icon';
import iconVideo from 'assets/images/ico_video.png';
import iconVideoLarge from 'assets/images/ico_video_large.png';

const VideoIcon = styled.span`
  ${IconTextStyles}
  background-image: url(${iconVideo});
  @media (max-width: 1024px) {
    background-image: url(${iconVideoLarge});
  }
`;

export default VideoIcon;

import React, { useCallback, useContext, useRef, useState } from 'react';
import Styled from './index.style';
import { PlayerContext } from 'component/Player';
import { MdCheck } from 'react-icons/md';
import useClickOutside from 'common/hook/useOutsideClick';

const PlaybackRate = (props) => {
  const { playbackRateList, playerState, handleState } = useContext(
    PlayerContext
  );

  const [isOpen, setIsOpen] = useState();
  const ref = useRef();
  useClickOutside(
    ref,
    useCallback(() => {
      setIsOpen(false);
    }, [])
  );

  const handleRate = (playbackRate) => () => {
    handleState({ playbackRate });
    setIsOpen(false);
  };

  return (
    <Styled.SelectWrapper {...props} ref={ref}>
      <Styled.SelectButton onClick={() => setIsOpen(!isOpen)}>
        {playerState.playbackRate}x
      </Styled.SelectButton>

      <Styled.SelectList className={isOpen && 'active'}>
        <Styled.SelectListInner>
          {playbackRateList.map((playbackRate, index) => (
            <Styled.SelectItem key={index} onClick={handleRate(playbackRate)}>
              {playerState.playbackRate === playbackRate && <MdCheck />}
              {playbackRate}x
            </Styled.SelectItem>
          ))}
        </Styled.SelectListInner>
      </Styled.SelectList>
    </Styled.SelectWrapper>
  );
};

export default PlaybackRate;

import React, { useState, useEffect, useRef } from 'react';
import Styled from './styled/VideoTab.style';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

const VideoLnb = ({ list }) => {
  const history = useHistory();
  const [mobileSelect, setMobileSelect] = useState(false);

  const [moSelectValue, setMoSelectValue] = useState('ALL');
  const [categoryId, setCategoryId] = useState(0);
  const [mainCateName, setMainCateName] = useState();
  const [subCateName, setSubCateName] = useState();

  const moCateSelect = useRef();

  const mainCate00 = useRef();
  const mainCate01 = useRef();
  const mainCate02 = useRef();
  const mainCate03 = useRef();

  const selectMenu = (value) => {
    setMobileSelect((mobileSelect) => !mobileSelect);
    setMoSelectValue(value);
  };

  const linkProps = (num, subNum?) => {
    setCategoryId(num);

    mainCate00.current.classList.remove('on');
    mainCate01.current.classList.remove('on');
    mainCate02.current.classList.remove('on');
    mainCate03.current.classList.remove('on');

    if (num === 'all') mainCate00.current.classList.add('on');
    else if (num === 'topik' || subNum === 1)
      mainCate01.current.classList.add('on');
    else if (num === 'korean' || subNum === 2)
      mainCate02.current.classList.add('on');
    else if (num === 'category' || subNum === 3)
      mainCate03.current.classList.add('on');

    history.push({
      pathname: `/courses/${num}`,
      state: { id: num },
    });
  };
  useEffect(() => {
    if (!list || list.length <= 0) return;
    const mainCateName = [];
    const subCateName = [];
    list.map((item) => {
      mainCateName.push(item);
      subCateName.push([]);

      item.subList &&
        item.subList.map((sub) => {
          subCateName[subCateName.length - 1].push(sub);
        });
    });
    setMainCateName(mainCateName);
    setSubCateName(subCateName);
    // console.log('LNB list', list);
  }, [list]);
  return (
    <>
      <Styled.LnbWrap className={mobileSelect ? 'show-menu' : 'hide-menu'}>
        <Styled.SelectContainer>
          <input
            ref={moCateSelect}
            type='text'
            readOnly
            value={moSelectValue}
            onClick={() => selectMenu()}
          />
        </Styled.SelectContainer>
        <div className='lnbWrap'>
          <ul className='lnb'>
            <li className='all'>
              <div
                ref={mainCate00}
                onClick={() => {
                  linkProps('all');
                  selectMenu('ALL');
                }}
                className='category on'
              >
                {mainCateName && mainCateName[0]
                  ? mainCateName[0].mainCategoryName
                  : ''}
              </div>
              {/* 서브리스트 */}
              {/* 박정우CP님 요청으로 노출 X
              <ul className='menu'>
                {subCateName &&
                  subCateName[0] &&
                  subCateName[0].map((item, idx) => {
                    return (
                      <li key={idx}>
                        <button
                          className={categoryId === item.nameIdx ? 'on' : ''}
                          onClick={() => {
                            linkProps(item.nameIdx);
                          }}
                        >
                          {item.subCategoryName}
                        </button>
                      </li>
                    );
                  })}
              </ul> */}
            </li>
            <li>
              <div
                ref={mainCate01}
                onClick={() => {
                  linkProps('topik');
                  selectMenu('TOPIK');
                }}
                // className={categoryId === 'topik' ? 'category on' : 'category'}
                className='category'
              >
                {mainCateName && mainCateName[1]
                  ? mainCateName[1].mainCategoryName
                  : ''}
              </div>
              {/* 서브리스트 */}
              <ul className='menu'>
                {subCateName &&
                  subCateName[1] &&
                  subCateName[1].map((item, idx) => {
                    return (
                      <li key={idx}>
                        <button
                          className={categoryId === item.nameIdx ? 'on' : ''}
                          onClick={() => {
                            linkProps(item.nameIdx, 1);
                            selectMenu(item.subCategoryName);
                          }}
                        >
                          {item.subCategoryName}
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </li>
            <li>
              <div
                ref={mainCate02}
                onClick={() => {
                  linkProps('korean');
                  selectMenu('KOREAN');
                }}
                // className={categoryId === 'korean' ? 'category on' : 'category'}
                className='category'
              >
                {mainCateName && mainCateName[2]
                  ? mainCateName[2].mainCategoryName
                  : ''}
              </div>
              {/* 서브리스트 */}
              <ul className='menu'>
                {subCateName &&
                  subCateName[2] &&
                  subCateName[2].map((item, idx) => {
                    return (
                      <li key={idx}>
                        <button
                          className={categoryId === item.nameIdx ? 'on' : ''}
                          onClick={() => {
                            linkProps(item.nameIdx, 2);
                            selectMenu(item.subCategoryName);
                          }}
                        >
                          {item.subCategoryName}
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </li>
            <li>
              <div
                ref={mainCate03}
                onClick={() => {
                  linkProps('category');
                  selectMenu('CATEGORY');
                }}
                className='category'
              >
                {mainCateName && mainCateName[3]
                  ? mainCateName[3].mainCategoryName
                  : ''}
              </div>
              {/* 서브리스트 */}
              <ul className='menu'>
                {subCateName &&
                  subCateName[3] &&
                  subCateName[3].map((item, idx) => {
                    return (
                      <li key={idx}>
                        <button
                          className={categoryId === item.nameIdx ? 'on' : ''}
                          onClick={() => {
                            linkProps(item.nameIdx, 3);
                            selectMenu(item.subCategoryName);
                          }}
                        >
                          {item.subCategoryName}
                        </button>
                      </li>
                    );
                  })}
              </ul>
            </li>
          </ul>
        </div>
      </Styled.LnbWrap>
    </>
  );
};

export default VideoLnb;

import React, { useCallback, useEffect, useState } from 'react';
import StyledForm from 'component/form/ModalForm.style';
import StyledModal from 'component/Modal/styled/Modal.style';
import Styled from './styled/CreateEmailAccount.style';
import { Link } from 'react-router-dom';
import { ModalContext } from 'component/Modal/modalContext';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import { onValidate } from 'common/util/regex';
import { useAxios } from 'common/hook/useAxiosHook';
import useErrorToastify from 'common/hook/useErrorToastify';
import { toast } from 'react-toastify';
import userApi from 'api/user';
import Login from 'component/user/Login';
import DefaultTextModal from 'component/Modal/DefaultTextModal';
import { Trans } from 'react-i18next';

export default function RequiredConfirmEmail({ t }) {
  let { handleModal } = React.useContext(ModalContext);
  const openLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false);
  };

  const [userID, setUserID] = useState(null);

  const handleChange = ({ target }) => setUserID(target.value);

  const [{ loading, data: resultStatus, error }, fetchAuthEmail] = useAxios(
    userApi.postSendAuthenticationEmail,
    [],
    true
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!userID || !onValidate.email(userID)) {
      toast.error(t('email.alert'));
      return false;
    }
    fetchAuthEmail(userID);
  };

  useEffect(() => {
    if (resultStatus === 200) {
      handleModal(
        <DefaultTextModal
          title={t('afterEmail.title')}
          desc={t('afterEmail.desc')}
        />
      );
    }
  }, [resultStatus]);

  useErrorToastify(error);

  return (
    <>
      <StyledModal.H2>{t('title')}</StyledModal.H2>

      <LoadingOverlay isLoading={loading} />

      <StyledForm.FormWrapper onSubmit={onSubmit}>
        <StyledForm.InputContainer>
          <StyledForm.Label htmlFor='userID'>
            {t('email.label')}
          </StyledForm.Label>
          <StyledForm.Input
            type='text'
            id='userID'
            name='userID'
            autoComplete='email'
            onChange={handleChange}
          />
          <Styled.EmailAuthButton onSubmit={onSubmit}>
            {t('email.button')}
          </Styled.EmailAuthButton>
        </StyledForm.InputContainer>

        <Styled.Bottom.Login>
          {t('guidance2')}{' '}
          <Link to='#none' onClick={openLoginModal}>
            {t('guidance2Link')}
          </Link>
        </Styled.Bottom.Login>

        <Styled.Bottom.Terms>
          <Trans
            t={t}
            i18nKey='guidance3'
            components={{
              link1: <Link to='/terms/terms-of-privacy' target='_blank' />,
              link2: <Link to='/terms' target='_blank' />,
            }}
          />
        </Styled.Bottom.Terms>
      </StyledForm.FormWrapper>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Styled from "./index.style";
import Img from "component/atoms/Img";
import { useHistory } from "react-router-dom";

export default function CoursesVideoThumb({ videoItem, videoRef, setIsPlay }) {

  const { length } = useHistory();
  const Video = Styled.Video;
  const [playing, setPlaying] = useState(false);

  const onPLay = () => {
    //videoRef.current.plyr.play();
    setPlaying(true);
    console.log("call play");
    setIsPlay(true);
  };

  useEffect(() => {
    //현재 페이지에서 -> 현재페이지로 이동할때 컴포넌트 새로고침
    console.log("call pause");
    setIsPlay(false);
    setPlaying(false);
  }, [length, videoItem]);

  return (
    <>
      {videoItem && (
        <Video.Thumb onClick={onPLay} className={playing && "hidden-poster"}>
          <Img src={videoItem.thumbnail} alt={videoItem.title} />
          <Video.PlayIcon />
          <Video.Inner>
            <Video.Tags>
              {/* {videoItem.keywordList.map((keyword) => `#${keyword} `)} */}
              {videoItem.keyword}
            </Video.Tags>
            <Video.Title>{videoItem.title}</Video.Title>
            <Video.Teacher>
              {/* {videoItem.teacherFirstName} {videoItem.teacherLastName} */}
              {videoItem.teacherName}
            </Video.Teacher>
          </Video.Inner>
        </Video.Thumb>
      )}
    </>
  );
}

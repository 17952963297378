import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import StyledTab from 'component/TabMenu/index.style';
import PageHeader from 'component/atoms/PageHeader';
import ResourcesRouter from 'container/ResourcePage/Router';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';


const Wrapper = styled(ContentsWrapper)`
  padding-bottom: 10em;
`;

const Resource = () => {
  const { url } = useRouteMatch();
  const { t } = useTranslation('resourcesPage');

  return (
    <Wrapper>
      <PageHeader
        title={t('title')}
        text={t('subtitle')}
      />

      <StyledTab.Container>
        <StyledTab.NavLink exact to={url}>
          Voca Card
        </StyledTab.NavLink>
        <StyledTab.NavLink to={`${url}/mt-live`}>MT Live</StyledTab.NavLink>
      </StyledTab.Container>

      <ResourcesRouter />
    </Wrapper>
  );
};

export default Resource;

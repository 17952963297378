import React, { useEffect, useState } from "react";
import ThemeCategoryItem from "component/molecules/ThemeCategoryItem";
import { SwiperSlide } from "swiper/react";
import themesApi from "api/theme";
import { useAxios } from "common/hook/useAxiosHook";
import SwiperList from "component/molecules/SwiperList";
import { EmptyList } from "component/atoms/EmptyList";
import PageSubTitle from "component/molecules/PageSubTitle";
import UserThemeItem from "component/molecules/ThemeItem/UserThemeItem";
import Features from "container/LandingPage/Features";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ThemeRecommendContainer = () => {
  const { t } = useTranslation("libraryPage");
  const token = useSelector((state) => state.user.token);
  const [slideItems, setSlideItems] = useState([]);
  const [fetchRecommendList, refreshFetchRecommendList] = useAxios(
    themesApi.getRecommendList,
    [],
    true
  );
  const { data } = fetchRecommendList;

  useEffect(() => {
    refreshFetchRecommendList({ page: 0, token: token });
  }, []);

  useEffect(() => {
    if (data !== null) {
      setSlideItems([...slideItems, ...data.content]);
    }
  }, [fetchRecommendList]);

  const onNext = () => {
    if (!data.last) {
      refreshFetchRecommendList({ page: data.number + 1, token: token });
    }
  };
  return (
    <>
      <PageSubTitle title={t("contentSection.section4.title")} />
      <SwiperList
        slideItemList={slideItems}
        onNext={onNext}
        id="library-recommendThemes"
      >
        {slideItems.length > 0 ? (
          slideItems.map((theme) => {
            const {
              id,
              name,
              description,
              thumbnailPath,
              userName,
              userProfilePhotoPath,
              likeCount,
              courseCount,
              categoryName,
              isGood,
            } = theme;
            return (
              <SwiperSlide key={id}>
                <UserThemeItem
                  adminTheme={true}
                  id={id}
                  imgSrcList={thumbnailPath}
                  title={name}
                  desc={description}
                  userName={userName}
                  userThumb={userProfilePhotoPath}
                  likeCount={likeCount}
                  videoCount={courseCount}
                  tag={categoryName}
                  isGood={isGood}
                />
              </SwiperSlide>
            );
          })
        ) : (
          <EmptyList />
        )}
      </SwiperList>
    </>
  );
};

export default React.memo(ThemeRecommendContainer);

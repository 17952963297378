import styled from 'styled-components';
import imageFile from 'assets/images/temp/bg_teacher_01.png';

const StyledCourseList = {
  Container: styled.div`
    display: flex;
    margin-bottom: 3.7em;
    border:1px solid #1f1f1f;
    border-radius: 0.7rem;
    padding:2.9rem;
    @media (max-width: 1024px) {

    }


    @media (max-width: 600px) {
      display: block;
      padding:0;
      border:none;
      background:#1f2024;
    }
  `,
  Image: styled.div`
    flex: 0.25;
    position: relative;
    display: inline-block;
    width: 29.5em;
    height: 21em;
    margin-right: 5em;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media (max-width: 600px) {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-bottom: 3em;
    }
    @media (max-width: 767px) {
      margin-right: 2.5em;
    }

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
  `,
  Content: styled.div`
    display: inline-block;
    flex: 0.8;
    @media (max-width: 600px) {
      padding:0 2rem 3rem;
    }
  `,
  FlexDiv: styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    &:first-of-type {
      /* margin-bottom: 4.5em; */
    }
    .lecture-info{
      width:75%;
    }
    .chart-wrap{
      width:25%;
      display: flex;
      justify-content:center;
      align-items: center;
      flex-direction: column;
      /* padding-top:3rem; */
    }
    @media (max-width: 767px) {
      &:first-of-type {
        /* margin-bottom: 2em; */
      }
    }
  `,
  CourseTitle: styled.span`
    color: #c9cbd1;
    font-size: 1.8em;
    font-weight: 500;
    line-height:1;
    padding:1.5rem 0;
  `,
  Button: styled.button`
    color: #5c5f66;
    font-size: 1.4rem;
    font-weight: 400;
    width:14rem;
    height:3.2rem;
    display:flex;
    align-items: center;
    justify-content:center;;
    background:#1e2024;
    border-radius: 5px;;
    @media (max-width: 767px) {
      &.period {
        /* display: none; */
      }
    }
    @media (max-width: 600px) {
      border:1px solid #5c5f66;
    }
  `,
  LectureTitle: styled.h3`
    color: #fff;
    font-size: 2.2em;
    font-weight: 700;
  `,
  ProgressContainer: styled.div`
    position: relative;
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #ffb500;
      font-size: 1.6em;
      font-weight: 700;
      transform: translate(-50%, -50%);
    }
    svg {
      width: 8.5em;
      height: 8.5em;
    }
    @media (max-width: 767px) {
      display: none;
    }
  `,
  LectureNo: styled.p`
    margin-top: 1em;
    text-align: center;
    color: #5c5f66;
    font-size: 1.4em;
    font-weight: 400;
    @media (max-width: 767px) {
      display: none;
    }
  `,

  LectureBtn: styled.button`
    display: inline-block;
    margin-top: 1.4em;
    padding: 0.7em 1.4em;
    border: 1px #8d9399 solid;
    border-radius: 0.2em;
    color: #d1d1d1;
    font-size: 1.4em;
    font-weight: 500;
  `,
  LectureInfos: styled.ul`
    display: flex;
    flex-direction: column;
    gap:1rem;
    margin-top:0.5rem;
  `,
  LectureInfo: styled.li`
    color:#c9cbd1;
    font-size:1.6rem;
    padding-left:1.5rem;
    position:relative;
    line-height: 1;
    :before{
      content: "";
      position:absolute;
      left:0;
      top:0.5rem;
      border-radius: 0.35rem;;
      width:0.7rem;
      height:0.7rem;
      display:block;
      background:#8e9199;
    }
  `,
  LectureInfoText: styled.p`
    color:#8e9199;
    font-size:1.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top:3rem;
    width:80%;
    @media (max-width: 600px) {
      margin-top:2rem;
    }
  `,
  ChangePopWrap: styled.div`
    width:54rem;
    height:68.8rem;
    background:#fff;
    border-radius: 0.5rem;
    position: absolute;
    right:0;
    top:0;
    z-index: 105;
    padding:0 0 4rem;
    display:none;
    &.active{display:block}
    @media (max-width: 600px) {
      width:100%;
    }
  `,
  ChangePopHead: styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
    height:11.5rem;
    width:100%;
    h3{
      color:#000000;
      font-size:2.4rem;
      font-weight: 500;
      line-height:1;
    }
    p{
      margin-top:1rem;
      color:#5c5f66;
      font-size:1.6rem;
    }
    button{
      position: absolute;
      right:2rem;
      top:2rem;
    }
    @media (max-width: 600px) {
      padding:0 10%;
      text-align: center;

    }
  `,
  ChangePopBody: styled.div`
    padding:0 3rem
  `,
  ChangePopLists: styled.div`
    overflow-y: auto;
    border-top:1px solid #e8e8e8;
    border-bottom:1px solid #e8e8e8;
    padding-top:2.5rem;
    padding-bottom:2.5rem;
    display: flex;
    flex-wrap: wrap;
    gap:3rem 2.5rem;
    height: 45rem;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border: 0;
      opacity: 0;
      z-index: -1;
    }
    label {
      cursor: pointer;
    }
  `,
  ChangePopList: styled.div`
    width:calc(50% - 1.5rem);
  `,
  Thum: styled.div`
    width:100%;
    img{width:100%;}
    &.checked {
      border: 3px solid #485cc7;
    }
  `,
  TextBox: styled.div`
    h3{
      font-size:1.6rem;
      color:#0e0e0e;
      margin-top:1rem;
      font-weight: 500;
    }
    p{
      color:#909090;
      font-size:1.4rem;
    }
  `,
  ChangePopFoot: styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    padding-top:3rem;
    gap:1.3rem;
    button{
      border:1px solid #d1d1d1;
      border-radius: 0.6rem;
      width:17.5rem;
      height:5.4rem;
      font-size: 1.6rem;
      color:#999999;
      &.change{
        border:1px solid #485cc7;
        background:#485cc7;
        color:#fff;
      }
    }
  `,
};

export default StyledCourseList;



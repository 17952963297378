import styled from "styled-components";
import { Link } from "react-router-dom";
import iconSearch from "assets/images/ico_search.png";
import iconSearchLarge from "assets/images/ico_search_large.png";
import arrowDown from "assets/images/arrow2_down_white.svg";
import StyledForm from 'component/form/ModalForm.style';
export const StyledNav = {
  Wrapper: styled.nav.attrs({
    className: "nav",
  })`
    display: inline-block;
    letter-spacing: 1px;
    @media (max-width: 1024px) {
      position: fixed;
      top: 0;
      right: -100%;
      z-index: 1000;
      width: 100%;
      height: 100%;
      background-color: #393c41;
      letter-spacing: 0;
      overflow: auto;
      transition: all 0.4s ease;
    }
  `,
  Inner: styled.div`
    display: inline-block;
    @media (max-width: 1024px) {
      position: relative;
      display: block;
      /* min-height: 100%; */
      padding-top: 0px;
      padding-bottom: 65px;
    }
  `,
  Item: styled.span`
    display: inline-block;
    /* margin-top: 2.5em; */
    margin-left: 4em;
    line-height: 4em;
    &.mypage {
      display: none;
    }
    &.library a {
      padding-right: 20px;
      background-repeat: no-repeat;
      background-position: right center;
      background-image: url(${iconSearch});
    }
		&.alive a {
			margin : 0.5em 0;
			padding : 0 0.5em;
			line-height : 1.6em;
			background : #ffffff;
			text-transform : none;
			color: #000000;
    }
    &.mobile-ver {
      display: none;
    }
    @media (max-width: 1200px) {
      margin-left:2.5em;
    }

    @media (max-width: 1024px) {
      &.pc-ver {
        display: none;
      }
      &.mobile-ver {
        display: block;
      }
      display: block;
      /* margin-bottom: 35px; */
      margin-left: 0;
      line-height: 1.5;
      .active.depth2{ background: #313438; padding:10px 0}
      &:last-child {
        padding-bottom: 30px;
      }
      &.mypage {
        display: block;
      }
      &.library a {
        position: relative;
        background: none;

        &:after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          margin-left: 0.5em;
          height: 2.5rem;
          width: 2.5rem;
          background: url(${iconSearchLarge}) no-repeat right center / contain;
          transform: translateY(-50%);
        }
      }
    }
  `,
  WithSub: styled.span`
    @media (max-width: 1024px) {
      position: relative;
      z-index: 9;
      display: block;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      &:after {
        content: "";
        position: absolute;
        right: 20px;
        top: 0;
        width: 12px;
        height: 100%;
        background: url(${arrowDown}) no-repeat center;
        background-size: 12px;
        transition: all 0.5s ease;
      }
      &.active:after {
        transform: rotate(180deg);
      }
      &.active a {
        font-weight: bold;
      }
    }
  `,
  Link: styled(Link)`
    display: inline-block;
    font-size: 1.6em;
    font-weight: 400;
    text-transform: uppercase;
    transition: all 0.2s ease-in;
    height:9rem;
    line-height:9rem;
    span.free{
      width:4.7rem;
      height:1.5rem;
      color: #28211f;
      border-radius: 0.75rem;
      background-color: #ffb500;
      font-size:1rem;
      letter-spacing: -0.03rem;
      padding:0 0.5rem;
      box-sizing: border-box;
      margin-left: 0.3rem;
      font-weight:400;
      position: relative;
      top: -2px;
      @media (max-width: 1024px) {
        font-size: 12px;
        display: inline-flex;
        width:70px;
        height:23px;
        align-items: center;
        justify-content: center;
        color:#fff;
        background: #f87c58;
        border-radius: 17.5px;;
        margin-left:15px;
        text-transform:none
      }
    }
    svg{
        vertical-align: middle;
        margin-top: -1rem;
        margin-left:5px;
        transition: all 0.2s ease-in;
    }

    @media (max-width: 1024px) {
      display: block;
      padding: 0 35px 0 20px;
      font-size: 21px;
      font-weight: 500;
      svg{
       display: none;
    }

    }
  `,
  Button: styled.button.attrs({
    type: "button",
    className: "nav-button",
    children: <span></span>,
  })`
    display: none;
    position: absolute;
    top: 2.25em;
    right: -30px;
    width: 4.5em;
    height: 4.5em;
    background-color: #fff;
    border-radius: 100px;
    transition-property: right, width, height, background-color;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    span:before,
    span:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1em;
      width: 2em;
      height: 2px;
      border-radius: 10px;
      background-color: #000;
      transition: all 0.3s ease;
    }
    span:before {
      margin-top: calc(-0.4em - 1px);
    }
    span:after {
      margin-top: calc(0.4em - 1px);
    }
    &.nav-with-line-banner {
      margin-top: 6em;
    }
    @media (max-width: 1024px) {
    /* @media (max-width: 1197px) { */
      display: block;
    }
  `,
  Sub: {
    Container: styled.div`
      /* @media (min-width: 1198px) { */
      /* @media (min-width: 1025px) {
        display: none;

      } */

      a {
        font-size: 14px;
        padding: 5px;
        margin: 10px 20px 0;
        line-height: 1.5;
        opacity: 1;
        transition: all 0.4s ease, opacity 0.3s ease 0.2s, font-size 0s ease;
      }
    `,
    Link: styled(Link)`
      display: block;
      font-size: 0;
      padding: 0;
      margin: 0 0 0 50px;
      opacity: 0;
      font-weight: 300;
      line-height: 0;
      transition: all 0.4s ease, opacity 0.2s ease, font-size 0s ease 0.3s;
    `,
  },
  Bottom: {
    Wrapper: styled.div`
      /* position: absolute; */
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 60px 20px 35px;
      @media (min-width: 1025px) {
        display: none;
      }
    `,
    SocialMedia: styled(Link)`
      display: inline-block;
      font-size: 24px;
      &:not(:first-child) {
        margin-left: 30px;
      }
    `,
    Links: styled.div`
      margin-top: 15px;
      > a {
        font-size: 12px;
        color: #8e9199;
        margin-right: 20px;
      }
      b {
        font-size: 14px;
        font-weight: 500;
        &:not(:first-of-type):before {
          content: "·";
          margin: 0 10px;
        }
      }
    `,
    ServiceLink: styled(Link).attrs({
      children: "Service",
      to: "#none",
    })`
      text-transform: uppercase;
    `,
    BottomTop:styled.div`
      display: flex;
      align-items: center;
      justify-content:space-between;
    `,
    SnsWrap: styled.div``,
  },
  MobileLogout: styled.button.attrs({
    type: "button",
    children: "Logout",
  })`
    position: absolute;
    top: 20px;
    right: 20px;
    display: block;
    width: 80px;
    height: 30px;
    border: 1px solid #828282;
    border-radius: 100px;
    font-size: 12px;
    font-weight: 300;
    color: #828282;
    letter-spacing: 1px;
    @media (min-width: 1025px) {
      display: none;
    }
  `,
  Close: styled.button.attrs({
    type: "button",
    children: <span></span>,
  })`
    display: none;
    position: absolute;
    top: 50%;
    transform:translateY(-50%);
    right: 20px;
    width: 35px;
    height: 35px;
    z-index: 9;
    margin-top: 0;
    background-color: transparent;
    span:before,
    span:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 20px;
      height: 2px;
      margin: 0 0 0 -10px;
      border-radius: 10px;
      background-color: #fff;
      transition: all 0.3s ease;
    }
    span:before {
      transform: rotate(-45deg);
    }
    span:after {
      transform: rotate(45deg);
    }

    @media (max-width: 1024px) {
      display: inline-block;
    }
  `,
  NavHead : styled.div`
    display: none;

    @media (max-width: 1024px) {
      display:  flex;;
      padding: 0 35px 0 20px;

      align-items: center;
      height:65px;
      position: relative;
    }

  `,
  UserWrap : styled.div`
    display:flex;
    gap:15px;
    button{
      width:90px;
      height:35px;
      color:#0f1012;
      font-size:15px;
      border-radius: 35px;
      background:#Fff;
      font-weight:600;
      &.user-login{
        background:#ffb500
      }
    }
  `,
  NewEvent:  styled.div`
  padding:0 20px;
  `,
  NewEventLink:  styled(Link)`
  @media (min-width: 1024px) {
    display: none;
  }
    width: 100%;
    background: #485cc7;
    color: #cfd7ff;
    font-size:15px;
    height:50px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding:0 20px;
    position: relative;
    img{
      width: 20px;;
      margin-right: 10px;;
      &.more_arrow{
        width:8px;
        margin-right: 0px;;
        position: absolute;
        right:25px;
        top:50%;
        transform: translateY(-50%);
      }
    }


  `,

  StyledSelect: styled(StyledForm.Select)`
    width: 160px;
    font-size: 14px ;
    margin-bottom: 0;
    display: inline-flex;
    .select__control {
      width:100%;
      min-height: 25px;
      background-color: #2b2e35;
    }
    > .select__menu,
    .select__option,
    .select__menu-list {
      background-color: #2b2e35;
    }
    .select__option:hover {
      background: rgba(0, 0, 0, 0.4);
    }
    .css-b8ldur-Input {
      color: #c7cad1;
    }
    .select__indicator {
      svg {
        color: #8b8f99;
      }
    }
    .select__placeholder,
    .select__single-value {
      color: #c7cad1;
      font-weight: 400;
    }

    `,


};

export default StyledNav;

import styled from 'styled-components';

const BannerStyle = {
  Banner: styled.div`
    margin: 5em 0;
    border-top: 1px solid #202227;
    border-bottom: 1px solid #202227;
    a {
      display: block;
      padding: 2.5em 0;
    }
  `,

  TitleWrap: styled.div`
    display: flex;
  `,

  Category: styled.span`
    flex-shrink: 0;
    display: inline-block;
    margin-right: 0.7em;
    width: 4.75em;
    border-radius: 0.36em;
    background: #ffb500;
    text-align: center;
    font-weight: 700;
    font-size: 1.6em;
    color: #0f1012;
  `,

  Title: styled.p`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.8em;
    color: #fff;
  `,

};

export default BannerStyle;

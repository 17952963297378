import React from 'react';
import Style from './styled/Start.style';
import { useParams, useHistory } from 'react-router-dom';
import { useAxios } from 'common/hook/useAxiosHook';
import mockTestApi from 'api/mockTests';
import { useSelector } from 'react-redux';
import { secondsToTime } from 'common/util/util';

const Start = ({ setCurrentPage }) => {
  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const userData = useSelector((state) => state.user.data);
  const { productId, unitId } = useParams();
  const [{ data }] = useAxios(
    () => mockTestApi.getIntro({ token, productId, unitId }),
    [productId, unitId]
  );
  /*
  if (userData.serviceLevel !== 'PREMIUM') {
    history.replace('/plan');
  }
  */

  const clickStart = () => {
    // val-w = 쓰기 시험지로 가기
    if (data.viewerType === 'VAL-W') {
      setCurrentPage('writeTest');
    } else {
      setCurrentPage('test');
    }
  };

  const clickClose = () => {
    window.close();
  };

  if (data) {
    // 에러메시지가 있는 경우는 응시 횟수 초과 안내로 대체
    if (data.testErrorMessage !== null) {
      return (
        <Style.Container>
          <Style.Logo />
          <Style.Title>TOPIK 모의고사 응시 완료하였습니다.</Style.Title>
          <Style.Subject>
            <p className='sub'>다음 달 TOPIK 모의고사를 준비해주세요!</p>
            <p className='sub'>(월 1회 응시 가능)</p>
          </Style.Subject>
          <Style.Desc>
            <p className='sub'>이번 달 시험 : {data.testTitle}</p>
            <p className='sub'>최근 응시일 : {data.latelyTestDate}</p>
            <p className='sub'>다음 응시일 : {data.nextTestDate} 이후</p>
          </Style.Desc>
          <Style.Btn onClick={clickClose}>닫기</Style.Btn>
        </Style.Container>
      );
    } else {
      return (
        <Style.Container>
          <Style.Logo />
          <Style.Title>{data.testTitle}</Style.Title>
          <Style.Subject>{data.subjectTitle}</Style.Subject>
          <Style.TotalTime>
            총 문제 풀이 시간 <span>{secondsToTime(data.testTime)}</span>
          </Style.TotalTime>
          <Style.Desc>
            <p>주어진 시간 안에 문제를 풀고 정답을 입력하세요.</p>
            <p className='sub'>평가가 끝난 후에는 평가 결과서가 제공됩니다. </p>
            <p className='sub'>
              {'([Classroom > TOPIK Mock Test] 메뉴에서 확인 가능)'}
            </p>
          </Style.Desc>
          <Style.Btn onClick={clickStart}>시작하기</Style.Btn>
        </Style.Container>
      );
    }
  }

  return <></>;
};

export default Start;

import React from 'react';
import Styled from './index.style';
import { Redirect, useParams } from 'react-router-dom';
import { useAxios } from 'common/hook/useAxiosHook';
import userApi from 'api/user';
import { useSelector } from 'react-redux';
import Loading from 'component/atoms/Loading';
import useErrorToastify from 'common/hook/useErrorToastify';
import OrderCompleteForBooks from './OrderCompleteForBooks';
import OrderCompleteForPlan from './OrderCompleteForPlan';
import OrderCompleteForVideoClass from './OrderCompleteForVideoClass';

import { useTranslation } from 'react-i18next';

const OrderComplete = () => {
  const { t } = useTranslation('paymentSuccessPage');

  const { orderType, orderId } = useParams();
  const token = useSelector((state) => state.user.token);

  const [{ loading, data: orderData, error }] = useAxios(
    () => userApi.fetchMyInvoice(token, orderId),
    []
  );

  useErrorToastify(error);

  if (orderType !== 'books' && orderType !== 'plan' && orderType !== 'videoclass')
    return <Redirect path='/' />;

  return (
    <Styled.Wrapper>
      <Styled.H2>{t('title')}</Styled.H2>

      {loading && <Loading />}

      {orderData && (
        <>
          {orderType === 'books' && (
            <OrderCompleteForBooks orderData={orderData} />
          )}
          {orderType === 'plan' && (
            <OrderCompleteForPlan orderData={orderData} />
          )}
          {orderType === 'videoclass' && (
            <OrderCompleteForVideoClass orderData={orderData} />
          )}
        </>
      )}
    </Styled.Wrapper>
  );
};

export default OrderComplete;

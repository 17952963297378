import React from 'react';
import PropTypes from 'prop-types';
import Styled from './index.style';
import { IoIosPause, IoIosPlay, IoMdCheckmark } from 'react-icons/io';

/**
 * @param {object} props
 * @param {boolean} props.isPlay
 * @param {number=} props.percent
 * @param {string=} props.strokeColor
 * @param {func=} props.onclick
 */
const VideoProgressCircle = ({
  isPlay,
  completed,
  percent = 0,
  strokeColor = '#ffba12',
  onclick,
}) => (
  <Styled.Container className='progress-circle' onClick={onclick}>
    <Styled.Circle
      percent={percent}
      strokeColor={strokeColor}
      trailColor='#8b8f99'
      strokeWidth={8}
      trailWidth={8}
    />
    <Styled.Icon>
      {isPlay ? (
        <IoIosPause className='pause' />
      ) : completed ? (
        <IoMdCheckmark className='complete' />
      ) : (
        <IoIosPlay className='play' />
      )}
    </Styled.Icon>
  </Styled.Container>
);

VideoProgressCircle.propTypes = {
  isPlay: PropTypes.bool.isRequired,
  percent: PropTypes.number,
  strokeColor: PropTypes.string,
  onclick: PropTypes.func,
};

export default VideoProgressCircle;

import HelpMainPage from 'container/HelpPage/Main';
import React, { useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import HelpListPage from "container/HelpPage/ListPage";
import HelpDetailPage from "container/HelpPage/DetailPage";

const HelpPageRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`} component={HelpMainPage} />
      <Route path={`${path}/article/:articleIdx`} component={HelpDetailPage} />
      <Route path={`${path}/:category`} component={HelpListPage} />
    </Switch>
  );
}

export default HelpPageRouter;
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Styled from './index.style';

import { Trans, useTranslation } from 'react-i18next';
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import { openLink } from 'common/util/util';
import { useDispatch, useSelector } from 'react-redux';
import plansApi from 'api/plans';
import coursesApi from 'api/courses';
import useLoginStatus from 'common/hook/useLoginStatus';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';
import { useAxios } from 'common/hook/useAxiosHook';
import { actions } from 'component/user/state';
import Plyr from 'plyr-react';

import Header from './header';

import visualImg from 'assets/images/event/230609/visualImg.png';
import visualImg2 from 'assets/images/event/230609/visualImg2.png';
import visualImg3 from 'assets/images/event/230609/visualImg3.png';
import studyImg from 'assets/images/event/230609/study.jpg';
import storyImg from 'assets/images/event/230609/story.jpg';
import course1L from 'assets/images/event/230609/course1_L.jpg';
import course1R from 'assets/images/event/230609/course1_R.jpg';
import course2L from 'assets/images/event/230609/course2_L.jpg';
import course2R from 'assets/images/event/230609/course2_R.jpg';
import course3A from 'assets/images/event/230609/course3_A.jpg';
import course3W from 'assets/images/event/230609/course3_W.jpg';
import course1Lgif from 'assets/images/event/230609/course1_L.gif';
import course1Rgif from 'assets/images/event/230609/course1_R.gif';
import course2Lgif from 'assets/images/event/230609/course2_L.gif';
import course2Rgif from 'assets/images/event/230609/course2_R.gif';
import course3Agif from 'assets/images/event/230609/course3_A.gif';
import course3Wgif from 'assets/images/event/230609/course3_W.gif';
import courseRep1 from 'assets/images/event/230609/courseRep1.png';
import courseRep2 from 'assets/images/event/230609/courseRep2.png';
import courseRep3 from 'assets/images/event/230609/courseRep3.png';
import courseRep4 from 'assets/images/event/230609/courseRep4.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
SwiperCore.use([Autoplay]);

const EventPage = () => {
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation('event/20230609FreeTrial');
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const isLogin = useLoginStatus();
  const history = useHistory();
  const location = useLocation();
  let { handleModal } = React.useContext(ModalContext);

  const videoRef = useRef(null);
  const user = useSelector((state) => state.user.data);

  const [
    { loading: getPlanLoading, data: getPlanData, error: getPlanError },
    fetchPlan,
  ] = useAxios(plansApi.getPlan, [], true);

  const [{ data: getCourseList12 }, fetchList12] = useAxios(
    coursesApi.getList230609,
    [],
    true
  );
  const [{ data: getCourseList34 }, fetchList34] = useAxios(
    coursesApi.getList230609,
    [],
    true
  );
  const [{ data: getCourseList56 }, fetchList56] = useAxios(
    coursesApi.getList230609,
    [],
    true
  );

  const [gradeList12, setGradeList12] = useState({});
  const [gradeList34, setGradeList34] = useState({});
  const [gradeList56, setGradeList56] = useState({});

  const [gradeTab, setGradeTab] = useState(0);
  const [faqIndex, setfaqIndex] = useState(0);
  const faqClick = (i) => {
    setfaqIndex(i);
  };

  useEffect(() => {
    if (!token) return;
    fetchPlan(token);

    dispatch(actions.fetchUser(token));
  }, [token]);
  useEffect(() => {
    const el = document.getElementById('header');
    if (!el) return;
    el.setAttribute('style', 'display: none;');

    fetchList12(12);
    fetchList34(34);
    fetchList56(56);

    return () => {
      el.removeAttribute('style');
    };
  }, []);
  useEffect(() => {
    if (!getPlanData) return;
    if (getPlanData.currentPlan !== 'FREE') {
      console.log('서비스 이용중인 회원', getPlanData.currentPlan);
      history.push({
        pathname: '/',
      });
    }
  }, [getPlanData]);

  useEffect(() => {
    if (!getCourseList12) return;
    setGradeList12(getCourseList12);
  }, [getCourseList12]);
  useEffect(() => {
    if (!getCourseList34) return;
    setGradeList34(getCourseList34);
  }, [getCourseList34]);
  useEffect(() => {
    if (!getCourseList56) return;
    setGradeList56(getCourseList56);
  }, [getCourseList56]);

  const freeStart = () => {
    if (isLogin) {
      const item = getPlanData.plans.find((plan) => plan.productId === 1147);
      history.push({
        pathname: `/plan-payment/${item.planCode.toLowerCase()}`,
        search: `?productId=${item.productId}`,
        state: item,
      });
    } else {
      handleModal(<Login />, false, 420, location.pathname);
    }
  };
  return (
    <>
      {/* header */}
      {
        <Header
          login={isLogin}
          planData={getPlanData ? getPlanData : undefined}
        />
      }
      {/* //header */}

      <Styled.EventWrap className={language === 'ko-KR' ? 'ko' : null}>
        <div className='visual'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>visual.title</Trans>
              </h2>
              <div className='sub'>
                <Trans t={t}>visual.sub</Trans>
              </div>
            </div>
            <ul className='img'>
              <li>
                <img src={visualImg} alt='' />
              </li>
              <li>
                <img src={visualImg2} alt='' />
              </li>
              <li>
                <img src={visualImg3} alt='' />
              </li>
            </ul>
            <div className='desc'>
              <Trans t={t}>visual.desc</Trans>
            </div>
          </div>
        </div>

        <div className='study'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>study.title</Trans>
              </h2>
            </div>
            <Link to='/courses/detail/1191' className='thumb'>
              <img src={studyImg} alt={t('study.title')} />
            </Link>
            <h3>{t('study.stitle')}</h3>
            <div className='desc'>
              <Trans t={t}>study.desc</Trans>
              <div className='point'>
                <Trans t={t}>study.desc2</Trans>
              </div>
            </div>
          </div>
        </div>

        <div className='topik'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>topik.title</Trans>
              </h2>
            </div>
            <div className='courseWrap'>
              <div className='course'>
                <Link to='/courses/detail/1302'>
                  <h3>{t('topik.course1-1.tit')}</h3>
                  <div className='thumb'>
                    <img src={course1R} alt='' />
                  </div>
                  <div className='msg'>{t('topik.desc')}</div>
                  <div className='gif'>
                    <img src={course1Rgif} alt='' />
                  </div>
                  <div className='desc'>{t('topik.course1-1.desc')}</div>
                </Link>
              </div>
              <div className='course'>
                <Link to='/courses/detail/1303'>
                  <h3>{t('topik.course1-2.tit')}</h3>
                  <div className='thumb'>
                    <img src={course1L} alt='' />
                  </div>
                  <div className='msg'>{t('topik.desc')}</div>
                  <div className='gif'>
                    <img src={course1Lgif} alt='' />
                  </div>
                  <div className='desc'>{t('topik.course1-2.desc')}</div>
                </Link>
              </div>
            </div>
            <div className='title emoji'>
              <h2>
                <Trans t={t}>topik.title2</Trans>
              </h2>
            </div>
            <div className='courseWrap'>
              <div className='course'>
                <Link to='/courses/detail/1123'>
                  <h3>{t('topik.course2-1.tit')}</h3>
                  <div className='thumb'>
                    <img src={course2R} alt='' />
                  </div>
                  <div className='msg'>{t('topik.desc')}</div>
                  <div className='gif'>
                    <img src={course2Rgif} alt='' />
                  </div>
                  <div className='desc'>{t('topik.course2-1.desc')}</div>
                </Link>
              </div>
              <div className='course'>
                <Link to='/courses/detail/1142'>
                  <h3>{t('topik.course2-2.tit')}</h3>
                  <div className='thumb'>
                    <img src={course2L} alt='' />
                  </div>
                  <div className='msg'>{t('topik.desc')}</div>
                  <div className='gif'>
                    <img src={course2Lgif} alt='' />
                  </div>
                  <div className='desc'>{t('topik.course2-2.desc')}</div>
                </Link>
              </div>

              <div className='course'>
                <Link to='/courses/detail/1171'>
                  <h3>{t('topik.course3-1.tit')}</h3>
                  <div className='thumb'>
                    <img src={course3A} alt='' />
                  </div>
                  <div className='msg'>{t('topik.desc')}</div>
                  <div className='gif'>
                    <img src={course3Agif} alt='' />
                  </div>
                  <div className='desc'>{t('topik.course3-1.desc')}</div>
                </Link>
              </div>
              <div className='course'>
                <Link to='/courses/detail/1172'>
                  <h3>{t('topik.course3-2.tit')}</h3>
                  <div className='thumb'>
                    <img src={course3W} alt='' />
                  </div>
                  <div className='msg'>{t('topik.desc')}</div>
                  <div className='gif'>
                    <img src={course3Wgif} alt='' />
                  </div>
                  <div className='desc'>{t('topik.course3-2.desc')}</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='represent'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>represent.title</Trans>
              </h2>
            </div>
            <div className='courseWrap'>
              <div className='course'>
                <Link to='/courses/detail/1160'>
                  <div className='thumb'>
                    <img src={courseRep1} alt='' />
                  </div>
                  <h3>{t('represent.course1')}</h3>
                </Link>
              </div>
              <div className='course'>
                <Link to='/courses/detail/1166'>
                  <div className='thumb'>
                    <img src={courseRep2} alt='' />
                  </div>
                  <h3>{t('represent.course2')}</h3>
                </Link>
              </div>
              <div className='course'>
                <Link to='/courses/detail/1170'>
                  <div className='thumb'>
                    <img src={courseRep3} alt='' />
                  </div>
                  <h3>{t('represent.course3')}</h3>
                </Link>
              </div>
              <div className='course'>
                <Link to='/courses/detail/1199'>
                  <div className='thumb'>
                    <img src={courseRep4} alt='' />
                  </div>
                  <h3>{t('represent.course4')}</h3>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='study story'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>story.title</Trans>
              </h2>
            </div>
            <Link to='/courses/detail/1211' className='thumb'>
              <img src={storyImg} alt={t('story.title')} />
            </Link>
            <div className='desc'>
              <Trans t={t}>story.desc</Trans>
              <div className='point'>
                <Trans t={t}>story.desc2</Trans>
              </div>
            </div>
          </div>
        </div>

        <div className='allCourse'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>list.title</Trans>
              </h2>
            </div>
            <div className='tabWrap'>
              <button
                className={gradeTab === 0 ? 'active' : ''}
                onClick={() => {
                  setGradeTab(0);
                }}
              >
                {t('list.tab')}
              </button>
              <button
                className={gradeTab === 1 ? 'active' : ''}
                onClick={() => {
                  setGradeTab(1);
                }}
              >
                {t('list.tab2')}
              </button>
              <button
                className={gradeTab === 3 ? 'active' : ''}
                onClick={() => {
                  setGradeTab(3);
                }}
              >
                {t('list.tab3')}
              </button>
              <button
                className={gradeTab === 5 ? 'active' : ''}
                onClick={() => {
                  setGradeTab(5);
                }}
              >
                {t('list.tab4')}
              </button>
            </div>
            <ul className='listWrap'>
              {(gradeTab === 0 || gradeTab === 1) &&
                gradeList12.resultList &&
                gradeList12.resultList.map((list) => {
                  return (
                    <li>
                      <a href={list.link}>
                        <div className='thumb'>
                          <img
                            src={`https://www.mastertopik.com/${list.thumbnailPath}`}
                            alt={list.productTitleEN}
                          />
                        </div>
                      </a>
                    </li>
                  );
                })}
              {(gradeTab === 0 || gradeTab === 3) &&
                gradeList34.resultList &&
                gradeList34.resultList.map((list) => {
                  return (
                    <li>
                      <a href={list.link}>
                        <div className='thumb'>
                          <img
                            src={`https://www.mastertopik.com/${list.thumbnailPath}`}
                            alt={list.productTitleEN}
                          />
                        </div>
                      </a>
                    </li>
                  );
                })}
              {(gradeTab === 0 || gradeTab === 5) &&
                gradeList56.resultList &&
                gradeList56.resultList.map((list) => {
                  return (
                    <li>
                      <a href={list.link}>
                        <div className='thumb'>
                          <img
                            src={`https://www.mastertopik.com/${list.thumbnailPath}`}
                            alt={list.productTitleEN}
                          />
                        </div>
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>

        <div className='materials'>
          <div className='inner'>
            <div className='title'>
              <p className='sub'>{t('materials.sub')}</p>
              <h2>
                <Trans t={t}>materials.title</Trans>
              </h2>
            </div>
            <ul>
              <li>
                <Trans t={t} className='desc'>
                  materials.list.desc
                </Trans>
                <h3>{t('materials.list.tit')}</h3>
              </li>
              <li>
                <Trans t={t} className='desc'>
                  materials.list2.desc
                </Trans>
                <h3>{t('materials.list2.tit')}</h3>
              </li>
              <li>
                <Trans t={t} className='desc'>
                  materials.list3.desc
                </Trans>
                <h3>{t('materials.list3.tit')}</h3>
              </li>
            </ul>
          </div>
        </div>

        <div className='banner'>
          <div className='inner'>
            <div className='desc'>
              <Trans t={t}>banner.desc</Trans>
            </div>
            <Styled.ArrowButton
              onClick={() => {
                freeStart();
              }}
            >
              <span>{t('banner.button')}</span>
            </Styled.ArrowButton>
          </div>
        </div>

        <div className='korean'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>korean.title</Trans>
              </h2>
            </div>
            <ul>
              <li>
                <Trans t={t} className='desc'>
                  korean.list.desc
                </Trans>
                {isLogin ? (
                  <Link to='/bookstore'>{t('korean.list.button')}</Link>
                ) : (
                  <button
                    onClick={() =>
                      handleModal(<Login />, false, 420, location.pathname)
                    }
                  >
                    {t('korean.list.button')}
                  </button>
                )}
              </li>
              <li>
                <Trans t={t} className='desc'>
                  korean.list2.desc
                </Trans>
                <Link to='/courses'>{t('korean.list2.button')}</Link>
              </li>
              <li>
                <Trans t={t} className='desc'>
                  korean.list3.desc
                </Trans>
                <a href='https://ai.masterkorea.com/'>
                  {t('korean.list3.button')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </Styled.EventWrap>
    </>
  );
};

export default EventPage;

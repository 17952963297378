import { useSelector } from 'react-redux';

export const addVideoToState = (videoItems, video) => {
  const findExistingItem = videoItems.find(
    (videoItem) => videoItem.id === video.id
  );

  if (findExistingItem) {
    return videoItems.map((videoItem) =>
      videoItem.id === video.id
        ? {
            ...videoItem,
            price: videoItem.price,
            quantity: videoItem.quantity + 1,
            total: videoItem.price * (videoItem.quantity + 1),
            totalDiscount: videoItem.discountCost * (videoItem.quantity + 1),
            totalDollar: videoItem.priceDollar * (videoItem.quantity + 1),
            totalDiscountDollar:
              videoItem.discountCostDollar * (videoItem.quantity + 1),
          }
        : videoItem
    );
  } else {
    return [
      {
        ...video,
        quantity: 1,
        total: video.price,
        totalDiscount: video.discountCost,
        totalDollar: video.priceDollar,
        totalDiscountDollar: video.discountCostDollar,
      },
      ...videoItems,
    ];
  }
};

export const deleteItem = (videoItems, video) => {
  const findExistingItem = videoItems.find(
    (cartItem) => cartItem.id === video.id
  );

  if (findExistingItem.quantity === 1) {
    return videoItems.filter((cartItem) => cartItem.id !== video.id);
  } else {
    const removedVideoItems = videoItems.map((videoItem) =>
      videoItem.id === video.id
        ? {
            ...videoItem,
            price: videoItem.price,
            quantity: videoItem.quantity - 1,
            total: videoItem.price * (videoItem.quantity - 1),
            totalDiscount: videoItem.discountCost * (videoItem.quantity - 1),
            totalDollar: videoItem.priceDollar * (videoItem.quantity - 1),
            totalDiscountDollar:
              videoItem.discountCostDollar * (videoItem.quantity - 1),
          }
        : videoItem
    );
    return removedVideoItems;
  }
};

//add total price and add total discount price
export const AddTotalPrice = (korean) => {
  const state = useSelector((state) => state.videoClassStore);

  let totalDisCountPrice, totalPrice, finalTotal;

  if (korean) {
    totalPrice = state.videoItems.reduce((acc, data) => {
      return (acc += data.total);
    }, 0);

    totalDisCountPrice = state.videoItems.reduce((acc, data) => {
      return (acc += data.totalDiscount);
    }, 0);

    finalTotal = totalPrice - totalDisCountPrice;
  } else {
    totalPrice = state.videoItems.reduce((acc, data) => {
      return (acc += data.totalDollar);
    }, 0);

    totalDisCountPrice = state.videoItems.reduce((acc, data) => {
      return (acc += data.totalDiscountDollar);
    }, 0);

    finalTotal = totalPrice - totalDisCountPrice;
  }

  return { totalDisCountPrice, totalPrice, finalTotal };
};
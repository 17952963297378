import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languageCode } from 'common/constant';

import { termsOfPrivacyKo0325 } from 'container/TermsPage/contents/2024/termsOfPrivacyKo0325';
import { termsOfPrivacyEn0325 } from 'container/TermsPage/contents/2024/termsOfPrivacyEn0325';
import { termsOfPrivacyZh0325 } from 'container/TermsPage/contents/2024/termsOfPrivacyZh0325';
import { termsOfPrivacyJa0325 } from 'container/TermsPage/contents/2024/termsOfPrivacyJa0325';

const Privacy20240325 = () => {
  const { i18n } = useTranslation('privacyPage');
  const { KOREAN, CHINESE, JAPANESE } = languageCode;
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!i18n?.language) return;

    switch (i18n.language) {
      case KOREAN.locale:
        setContent(termsOfPrivacyKo0325);
        break;
      case CHINESE.locale:
        setContent(termsOfPrivacyZh0325);
        break;
      case JAPANESE.locale:
        setContent(termsOfPrivacyJa0325);
        break;
      default:
        setContent(termsOfPrivacyEn0325);
        break;
    }
  }, [i18n.language]);

  return <>{content}</>;
};

export default Privacy20240325;
import React, { useEffect, useState } from 'react';
import Styled from './index.style';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import UserInfo from 'component/atoms/UserInfo';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import communityApi from 'api/community';

const CommentWrite = ({ onRefresh, t }) => {
  const { id } = useParams();
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.data);

  const [content, setContent] = useState('');

  const [postReply, fetchPostReply] = useAxios(
    communityApi.postReply,
    [],
    true
  );
  const { loading, data: postStatus, error } = postReply;

  const onSubmit = (e) => {
    e.preventDefault();
    fetchPostReply(id, null, content, token);
  };

  useEffect(() => {
    if (postStatus === 201) {
      setContent('');
      onRefresh();
    }
  }, [postStatus]);

  return (
    <Styled.Wrapper>
      <UserInfo
        src={user.profilePhotoPath}
        name={user.userName ? user.userName : user.userNickName}
      />

      <Styled.Form onSubmit={onSubmit}>
        <Styled.Textarea
          required
          onChange={(e) => setContent(e.target.value)}
          value={content}
          placeholder={t('post.commentPlaceholder')}
        />

        <Styled.Submit>{t('buttonPost')}</Styled.Submit>
      </Styled.Form>

      <LoadingOverlay isLoading={loading} />
    </Styled.Wrapper>
  );
};

export default CommentWrite;

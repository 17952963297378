import jresearchType from './type';

//setting current video in course detail page
export const setCurrentUrl = (urlObj) => ({
  type: jresearchType.Set_Current_Video_Url,
  payload: urlObj,
});

export const setVideoUrl = (urlObj) => ({
  type: jresearchType.Set_Video_Url,
  payload: urlObj,
});

export const setSelectNumber = (numObj) => ({
  type: jresearchType.Set_Select_Number,
  payload: numObj,
});

export const setLectureId = (numObj) => ({
  type: jresearchType.Set_Lecture_ID,
  payload: numObj,
});

import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { Link } from 'react-router-dom';
import Img from 'component/atoms/Img';

const FeaturesStyled = {
  Wrapper: styled(ContentsWrapper)`
    display: table;
    padding-bottom: 30em;
    table-layout: fixed;
    @media (max-width: 767px) {
      display: block;
      max-width: 500px;
      padding-top: 14em;
      padding-bottom: 14em;
    }
  `,
  Texts: styled.div`
    display: table-cell;
    vertical-align: middle;
    @media (max-width: 767px) {
      display: block;
    }
  `,
  Desc: styled.div`
    font-size: 2em;
    min-height: 3em;
    overflow: hidden;
    @media (max-width: 767px) {
      font-size: 2.4em;
    }
    @media (max-width: 375px) {
      display: none;
    }
  `,
  ImgWrapper: styled.div`
    display: table-cell;
    width: 74.5em;
    text-align: right;
    vertical-align: middle;
    @media (max-width: 1024px) {
      width: 50%;
    }
    @media (max-width: 767px) {
      display: block;
      width: 90%;
      margin: auto;
      padding-top: 4em;
      text-align: center;
    }
  `,
  PronunciationImg: styled(Img)`
    margin-right: -6em;
    @media (max-width: 767px) {
      margin-right: 0;
    }
  `,
  PlayStoreLink: styled(Link)`
    display: inline-block;
    margin-top: 3em;
    img {
      width: 20em;
    }
    @media (max-width: 767px) {
      img {
        width: 26em;
      }
    }
  `,
};

export default FeaturesStyled;

import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/bookmarks';
const size = 12;

/**
 * getAllList 모든 북마크 목록
 * getListOfUser 사용자의 북마크 목록
 */
const bookmarksApi = {
  getAllList: async () => {
    let response = await asyncApi({ url, params: { size } });
    return response.data;
  },
  getListOfUser: async (props = { token: null, page: 0 }) => {
    let response = await asyncApi({
      url,
      params: { members: '', size, page: props.page },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
};

export default bookmarksApi;

import React, { useCallback, useEffect, useState, useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, Controller } from 'swiper';
import Styled from './index.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import CreateAccount from 'component/user/CreateAccount';
import { useAxios } from "common/hook/useAxiosHook";
import landingBanner from "api/landingPage";
import { API_HOST } from 'common/constant';
import { openLink } from 'common/util/util';
import { sampleBanner } from './SampleData';
// import LoadingOverlay from "component/atoms/LoadingOverlay";

SwiperCore.use([Autoplay, Pagination, Navigation, Controller]);

export default function LandingTop() {
  const [{ loading: bannerLoading, data: bannerData, error: bannerError }] = useAxios(
    landingBanner.getBanner, []
  );
  const [data, setData] = useState();
  // 팝업 띄움 여부
  const [isMobile, setisMobile] = useState(false);
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const swiperRef = useRef(null);
  const [updateSwiper, setUpdateSwiper] = useState(0);

  const [realIndex, setRealIndex] = useState(0);    //pagination 현재 페이지 번호
  const [totalLength, setTotalLength] = useState(0);//pagination 전체 페이지 갯수
  const [fileUrlList, setFileUrlList] = useState([]);//fileUrl 셋팅

  // 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
  const resizingHandler = () => {
    // console.log('resizingHandler')
    if (window.innerWidth <= 1023) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1023) {
      setisMobile(true);
    }

    window.addEventListener("resize", resizingHandler);
    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);

  useEffect(() => {
    if (!bannerData) return;
    setData(bannerData);
    if(bannerData.urlList && bannerData.urlList.length > 0){
      const arr = getFileUrlList(bannerData.urlList);
      // console.log('arr', arr)
      setFileUrlList(arr);
    }
  }, [bannerData]);

  useEffect(()=>{
    if (process.env.REACT_APP_ENV === "local") {
      if(bannerError === undefined){
        setData(sampleBanner);
        if(sampleBanner.urlList && sampleBanner.urlList.length > 0){
          const arr = getFileUrlList(sampleBanner.urlList);
          // console.log('arr', arr)
          setFileUrlList(arr);
        }
      }
    }
  }, [bannerError])

  const getFileUrlList = (urlList) => {
    const hostReg = /mastertopik.com|localhost/gi;
    const urlReg = new RegExp(/^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/, 'i');
    /*
      urlReg.exec('https://www.mastertopik.com/theme/4?param=123#Related')

      $0: "https://www.mastertopik.com/theme/4?param=123#Related"
      $1: "https:"
      $2: "https"
      $3: "//www.mastertopik.com"
      $4: "www.mastertopik.com"
      $5: "/theme/4"
      $6: "?param=123"
      $7: "param=123"
      $8: "#Related"
      $9: "Related"
    */

    let arr = [];
    urlList && urlList.length > 0 && urlList.map((list)=>{
      const urlArr = urlReg.exec(list.fileUrl);

      if(urlArr && urlArr[4] && urlArr[4].match(hostReg)){
        arr = [...arr, {
          linkUrl: urlArr[5],
          isExternalLink: false,
          isBlank: false,
        }];
      }else arr = [...arr, {
        linkUrl: list.fileUrl,
        isExternalLink: true,
        isBlank: true,
      }];
    });

    return arr;
  }

  useEffect(()=>{
    const timeout = setTimeout(() => {
      //swiper update
      swiperRef.current?.swiper?.update();
    }, 0);

    return () => {
      clearTimeout(timeout);
    }
  },[updateSwiper]);

  const swiperProps = React.useMemo(()=>{
    // console.log(data && data.urlList && data.urlList.length)
    return {
      loop: data && data.urlList && data.urlList.length > 1 ? true : false,
      autoplay: data && data.urlList && data.urlList.length > 1 ? {
        delay: 4000,
        disableOnInteraction: false
      } : false,
      watchOverflow: true,
    }
  }, [data]);

  const onRealIndexChange = (swiper)=>{
    if(swiper){
      // console.log('번호', swiper, swiper.realIndex)
      setRealIndex(swiper.realIndex + 1);
      setTotalLength(swiper.slides?.length && swiper.slides?.length > 1 ? swiper.slides?.length - 2 : (swiper.slides?.length === 1 ? 1 : 0));
    }
  }

  const onClickURL = (e, url, isExternalLink, isBlank) => {
    if(isBlank){
      e.preventDefault(); //Link to 기능 무효화
      openLink(isExternalLink? url : API_HOST + url, '_blank');
    }
  }

  return (
    <>
    {/* <LoadingOverlay isLoading={bannerLoading} /> */}
    <Styled.Wrapper>
      <ContentsWrapper>
        {data && 
        <>
          {data && data.urlList && data.urlList.length > 0 &&
            <Styled.SwiperWrap>
              <Styled.SwiperInner>
                <Styled.Swiper
                  ref={swiperRef}
                  slidesPerView={1}
                  spaceBetween={0}
                  observer={true}
                  updateOnWindowResize={true}
                  modules={[Autoplay, Navigation]}
                  speed={300}
                  navigation={true}
                  {...swiperProps}
                  pagination={{
                    type: "progressbar",
                    el: `.swiper-pagination-progressbar`,
                  }}
                  breakpoints={{
                    1024: {
                      direction : "vertical",
                    },
                  }}
                  onResize={(swiper)=>setUpdateSwiper((state)=>state+1)}
                  onInit={(swiper)=>setUpdateSwiper((state)=>state+1)}
                  onSwiper={onRealIndexChange}
                  onRealIndexChange={onRealIndexChange}
                >
                  {data.urlList.map((list, index)=>{
                    return (
                      <SwiperSlide key={index}>
                        <Styled.Link to={fileUrlList[index].linkUrl}
                          onClick={(e)=>onClickURL(e, fileUrlList[index].linkUrl, fileUrlList[index].isExternalLink, fileUrlList[index].isBlank)}
                        >
                          {/* <span>{list.filePath}</span> */}
                          <img src={list.filePath} alt="" />
                        </Styled.Link>
                      </SwiperSlide>
                    );
                  })}

                  {/* pagination 현재 번호/전체 숫자 */}
                  <Styled.SwiperFraction><strong>{realIndex}</strong>/{totalLength}</Styled.SwiperFraction>
                </Styled.Swiper>
              </Styled.SwiperInner>
              <Styled.SwiperProgressbar className='swiper-pagination-progressbar'></Styled.SwiperProgressbar>
            </Styled.SwiperWrap>
          }
          <Styled.AccountWrap>
            <Styled.H2>
              {data.title}
            </Styled.H2>
            <Styled.Desc>
              {data.subTitle}
            </Styled.Desc>
            <Styled.CreateAccount>
              <CreateAccount landingPage={true} />
            </Styled.CreateAccount>
          </Styled.AccountWrap>
        </>
        }
      </ContentsWrapper>
    </Styled.Wrapper>
    </>
  );
}

import styled from "styled-components";
import { Link } from 'react-router-dom';
import { VscClose } from 'react-icons/vsc';
import CouponIcon from 'assets/images/ico_coupon.png';

const StyledCoupon = {
  Wrapper: styled.div`
    position: fixed;
    right:20px;
    bottom:20px;
    width:24.5em;
    height:10em;
    background:#fff url(${CouponIcon}) 2em center no-repeat;
    padding: 2em 0 0 7em;
    border:1px rgba(255,255,255, .3) solid;
    border-radius: 0.4em;
    box-shadow: -4px 4px 0 2px rgba(255,255,255, .3);
    z-index:999999;
    @media (max-width: 1024px) {
      width:calc(100% - 40px);
      height:7.4em;
      box-shadow:none;
      background: #fff;
      padding: 1.6em 0 0 0;
      text-align:center;
      font-size:1.4em;
      br {
        display:none;
      }
    }
  `,
  LinkText: styled(Link)`
    font-size: 1.45em;
    line-height: 1.3;
    color: #0f1012;
    div {
      margin-bottom: 0.2em;
    }
    strong {
      font-weight: 700;
      color: #586eed;
      border-bottom: 0.14em #586eed solid;
    }
  `,

  Close: styled.button.attrs({
    type: 'button',
    children: <VscClose />,
  })`
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 18px;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    color:#000;
	  z-index:1001;
  `,
};

export default StyledCoupon;

import React, { useState, useContext, useEffect } from 'react';
import Style from '../styled/component.style';
import { MockTestContext } from '../index';

const li = [1, 2, 3, 4];
const Choices = ({ id }) => {
  const { mockTestStore, setMockTestStore } = useContext(MockTestContext);

  const [active, setActive] = useState(null);

  useEffect(() => {
    //있으면 choice active로 변경 (omr카드 연동 기능)
    const findIndex = mockTestStore.mockTestAnswerRequest.findIndex(
      (answer) => answer.unitValuationId === id
    );
    if (findIndex !== -1) {
      setActive(mockTestStore.mockTestAnswerRequest[findIndex].answerNo);
    } else {
      return;
    }
  }, [mockTestStore]);

  const clickChoice = (number) => {
    setActive(number);
    const index = mockTestStore.mockTestAnswerRequest.findIndex(
      (answer) => answer.unitValuationId === id
    );

    if (index === -1) {
      setMockTestStore((prev) => ({
        ...prev,
        mockTestAnswerRequest: [
          ...prev.mockTestAnswerRequest,
          { unitValuationId: id, answerNo: number },
        ],
      }));
    } else {
      const newData = { ...mockTestStore };
      newData.mockTestAnswerRequest[index]['answerNo'] = number;
      setMockTestStore(newData);
    }
  };

  return (
    <Style.QuestionChoiceContainer>
      {li.map((data) => {
        return (
          <Style.QuestionChoice key={data}>
            <span
              onClick={() => clickChoice(data)}
              className={data === active ? 'active' : ' '}
            >
              {data}
            </span>
          </Style.QuestionChoice>
        );
      })}
    </Style.QuestionChoiceContainer>
  );
};

export default React.memo(Choices);

import React from 'react';
import Styled from './index.style';
import { navDataNotLogin } from '../navData';
import NavItemWithSub from 'component/layout/Header/Nav/NavItemWithSub';
import { openLink } from 'common/util/util';

const MenuBeforeLogin = ({  handleMouseOver, handleMouseOut }) =>{
  return <>
  {navDataNotLogin.map((item, index) => (
    <Styled.Item key={index} className={`depth1 depth1-${index+1} ${item.className} logout-depth1`}>
      {!item.subList ? (
        // (item.name === 'Alive Class') ?
        //   // <Styled.Link to={item.url} target='_blank'>{item.name}</Styled.Link>
        //   <Styled.Link to="" onClick={()=>{openLink('https:' + item.url, '_blank'); return false;}}>{item.name}</Styled.Link>
        // :
          <Styled.Link to={item.url} onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}  className="nav_a">{item.name}</Styled.Link>
      ) : (
        <NavItemWithSub item={item}  handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOut} />
      )}
    </Styled.Item>
  ))}
  </>
}

export default MenuBeforeLogin;

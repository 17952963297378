import styled from 'styled-components';

import BgBanner from 'assets/images/audio/bg_banner.png';
import IcoAudio from 'assets/images/audio/ico_audio.png';

const BannerStyle = {
  BannerAudio: styled.div`
    margin: 8em 0 4em;
    background: #a3e4f6;
    a {
      display: block;
      padding: 3em 2.5em;
      text-align: center;
    }
    div {
      display: inline-block;
      font-weight: 900;
      font-size: 2.6em;
      color: #212121;
      span {
        color: #017bd1;
      }
      &::after {
        content: '';
        display: inline-block;
        width: 2.2em;
        height: 2.5em;
        margin-left: 0.6em;
        background: url(${IcoAudio}) no-repeat 50% 50%/contain;
        vertical-align: middle;
      }
    }
  `,

  BannerWrap: styled.div`
    margin: 4em 0 20em;
    background: url(${BgBanner}) no-repeat 50% 50%/cover;
    a {
      display: block;
      padding: 4em 2.5em 2.5em;
      text-align: center;
    }
    p {
      margin-bottom: 0.8em;
      font-weight: 900;
      font-size: 2.1em;
      color: #212121;
    }
    span {
      display: inline-block;
      padding: 0.5em 2em 0.5em 2.5em;
      border-radius: 2em;
      background-color: #212121;
      font-size: 1.8em;
      color: #fff;
      svg {
        position: relative;
        top: 0.1em;
        margin-left: 0.5em;
      }
    }
  `,
};

export default BannerStyle;
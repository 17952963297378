import React from 'react';
import { useParams } from 'react-router-dom';
import Styled from './ClassTabListItem.style';
import VideoProgressCircle from 'component/atoms/VideoProgressCircle';
import ProgressCircle from 'container/CourseViewPage/Aside/ClassTab/ProgressCircle';

const ClassTabSubListItem = ({ lecture }) => {
  const { productId, lectureId: currentLectureId } = useParams();

  return (
    <Styled.NavLink
      to={`/courses/detail/${productId}/${lecture.chapterLectureId}`}
      activeClassName='active'
    >
      {parseInt(currentLectureId) === lecture.chapterLectureId ? (
        <ProgressCircle lectureId={lecture.chapterLectureId} />
      ) : (
        <VideoProgressCircle
          isPlay={false}
          completed={lecture.completed}
          percent={lecture.progressPercent}
        />
      )}

      <Styled.LectureName>{lecture.title}</Styled.LectureName>
    </Styled.NavLink>
  );
};

export default React.memo(ClassTabSubListItem);

import React, { useState, useEffect, useCallback } from 'react';
import { useAxios } from 'common/hook/useAxiosHook';
import { useTranslation, Trans } from 'react-i18next';
import Styled from 'container/userPage/CompleteSignupPage/index.style';
import PlanCard from 'container/userPage/CompleteSignupPage/PlanCard.style';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { actions } from 'component/user/state';
import { Helmet } from 'react-helmet';
import { SiteMapData } from 'common/constant';
import { gotoAILink } from 'common/util/util';
import plansApi from 'api/plans';
import postCurriculums from 'api/myLevel';
import myyLevelApi from 'api/myLevel';
import topikCourses from 'assets/images/completeSignup_topik.png';
import koreanCourses from 'assets/images/completeSignup_korean.png';

import {
  addMemberGoal,
  registMemberGoalLevel,
  recommentContents,
  confirmMemberGoal,
  memberGoalFinish,
} from 'api/memberGoal';

const CompleteSignupPage = () => {
  const { t } = useTranslation('CompleteSignupPage');
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const { state: locationState } = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user.data);
  const clickAILink = (e) => {
    gotoAILink(e, user);
  };
  const [stepNum, setStepNum] = useState(1); // 현재 진행 단계
  const [membership, setMembership] = useState('korean'); // 멤버쉽 추천
  const [selLevel, setSelLevel] = useState(-1); // 레벨 설정

  const [message1, setMessage1] = useState('모두 선택해주세요​'); // step1 버튼 메세지
  const [activeBtn1, setActiveBtn1] = useState(0); // step1 활성화 버튼

  const [{ data: memberGoal }, getMemberGoal] = useAxios(
    confirmMemberGoal,
    [],
    true
  ); // 이전 정보 겟
  const [selAddMemberGoal, updateSelAddMemberGoal] = useAxios(
    addMemberGoal,
    [],
    true
  ); // step1 저장 axios

  const [message2, setMessage2] = useState('1개 선택해주세요​'); // step2 버튼 메세지
  const [activeBtn2, setActiveBtn2] = useState(0); // step2 활성화 버튼
  const [selRegistMemberGoalLevel, updateSelRegistMemberGoalLevel] = useAxios(
    registMemberGoalLevel,
    [],
    true
  ); // step2 저장 axios

  const [{ data: dataStep03 }, refreshGetRecommentContents] = useAxios(
    recommentContents,
    [],
    true
  ); // step3 정보 겟 axios
  const [{ data: isFinished }, updateGoalFinish] = useAxios(
    memberGoalFinish,
    [],
    true
  ); // 결제페이지까지 갔는지 여부

  const [activeStep4, setActiveStep4] = useState(''); // 4단계 선택 값
  const [activeTabStep4, setActiveTabStep4] = useState('month'); // 4단계 탭 선택 값

  const [
    { loading: getPlanLoading, data: getPlanData, error: getPlanError },
    fetchPlan,
  ] = useAxios(plansApi.getPlan, [], true);
  const handleFetchPlan = () => fetchPlan(token);
  useEffect(() => {
    if (!token) return;
    handleFetchPlan();
    dispatch(actions.fetchUser(token));
  }, [token]);

  useEffect(() => {
    if (!getPlanData) return;
    if (getPlanData.currentPlan !== 'FREE') {
      console.log('서비스 이용중인 회원', getPlanData.currentPlan);
      history.push({
        pathname: '/',
      });
    }
    getMemberGoal(token, user.userIdx);
  }, [getPlanData]);

  useEffect(() => {
    if (!memberGoal) return;
    if (!memberGoal.memberGoal.goalNum) return;
    const query = 'input[name=step1]';
    const selected = document.querySelectorAll(query);
    const goalNum = memberGoal.memberGoal.goalNum.split(',');

    for (const goal of goalNum) {
      if (goal !== 0) {
        selected[goal - 1]?.setAttribute('checked', true);
      }
    }
    checkBtn1();
  }, [memberGoal]);

  useEffect(() => {
    if (stepNum !== 2) return;
    const query = 'input[name=step2]';
    const selected = document.querySelectorAll(query);
    const level = memberGoal.memberGoal.level;
    let sel = -1;
    if (level === 0) sel = 0;
    else if (level === 1) sel = 1;
    else if (level === 3) sel = 2;
    else if (level === 5) sel = 3;

    if (sel > -1) selected[sel].setAttribute('checked', true);
    checkBtn2(sel);
  }, [stepNum]);

  const resizingHandler = () => {
    const el = document.querySelector('header header');
    if (!el) return;
    if (window.innerWidth <= 1024) {
      el.setAttribute('style', 'display: none;');
    } else {
      el.removeAttribute('style');
    }
  };

  useEffect(() => {
    if (locationState?.refresh === true) {
      history.replace({
        state: {
          refresh: false,
        },
      });
      history.go(0);
    }
    resizingHandler();
    window.addEventListener('resize', resizingHandler);
    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener('resize', resizingHandler);
      const el = document.querySelector('header header');
      if (!el) return;
      el.removeAttribute('style');
    };
  }, []);
  // step01
  const checkBtn1 = () => {
    const query = 'input[name=step1]:checked';
    const selected = document.querySelectorAll(query);
    if (selected.length === 0) setMessage1('모두 선택해주세요​');
    else setMessage1('');

    setActiveBtn1(selected.length);
  };
  const nextStep1 = () => {
    const valueArr = [];
    const query = 'input[name=step1]:checked';
    const selected = document.querySelectorAll(query);

    selected.forEach((sel) => {
      if (sel.value === '1' || sel.value === '2' || sel.value === '5')
        setMembership('topik');
      valueArr.push(sel.value);
    });
    updateSelAddMemberGoal(token, user.userIdx, valueArr);
    window.scrollTo(0, 0);
    if (activeBtn1 > 0) {
      setStepNum(2);
    } else setMessage1('(!) 1개 이상 선택하세요.');
  };
  // ---- step01

  // step02
  const checkBtn2 = (sel) => {
    setActiveStep4(membership);

    const query = 'input[name=step2]:checked';
    const selected = document.querySelectorAll(query);
    if (selected.length === 0) {
      setSelLevel(-1);
      setMessage2('1개 선택해주세요​');
    } else {
      setSelLevel(sel);
    }
  };
  const nextStep2 = () => {
    if (selLevel < 0) return;
    updateSelRegistMemberGoalLevel(token, user.userIdx, selLevel);
    refreshGetRecommentContents(token, user.userIdx, {
      membership,
      selLevel,
    }); // step03 정보 가져오기
    window.scrollTo(0, 0);
    setStepNum(3);
  };
  // ---- step02

  // step03
  useEffect(() => {
    // if (dataStep03) {
    //   console.log('dataStep03', dataStep03);
    // }
  }, [dataStep03]);
  // ---- step03

  const payment = () => {
    let item, idx;
    if (activeTabStep4 === 'month') {
      if (membership === 'topik') {
        idx = 1147;
      } else if (membership === 'korean') {
        idx = 1177;
      }
    } else if (activeTabStep4 === 'year') {
      if (membership === 'topik') {
        idx = 1291;
      } else if (membership === 'korean') {
        idx = 1292;
      }
    }
    item = getPlanData.plans.find((plan) => plan.productId === idx);
    myyLevelApi.postCurriculums({ token, curriculumId: dataStep03.codeNum });
    updateGoalFinish(token, user.userIdx);

    history.push({
      pathname: `/plan-payment/${item.planCode.toLowerCase()}`,
      search: `?productId=${item.productId}`,
      state: item,
    });
  };

  return (
    <>
      {process.env.REACT_APP_ENV === 'production' ? (
        <Helmet>
          {/* Event snippet for 가입 conversion page */}
          <script
            async
            src='https://www.googletagmanager.com/gtag/js?id=GTM-M6DSB3B'
          ></script>
          <script type='text/javascript'>
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('event', 'conversion', {'send_to': 'AW-415877565/KlPcCJCZxY4DEL2Tp8YB'});
          `}
          </script>
        </Helmet>
      ) : (
        <></>
      )}
      <Styled.Container>
        <Styled.Wrapper>
          {/* step 1 */}
          {stepNum === 1 && (
            <>
              <Styled.Welcome>
                <Styled.H2>
                  {t('welcome.title')},{' '}
                  <Styled.Name>{user.fullName}</Styled.Name>
                </Styled.H2>
                <Styled.Text>
                  <Trans t={t}>welcome.desc</Trans>
                </Styled.Text>
              </Styled.Welcome>
              <Styled.RecommendedCourse>
                <Styled.StepWrap>
                  <Styled.Steps>
                    <span>1</span>/3
                  </Styled.Steps>
                  <Styled.StepTitle>{t('step1.title')}</Styled.StepTitle>
                  <Styled.StepInner>
                    <Styled.InputWrap onClick={checkBtn1}>
                      <Styled.InputCheck name='step1' id='step1-1' value='1' />
                      <Styled.Label htmlFor='step1-1'>
                        <span>{t('step1.input1')}</span>
                      </Styled.Label>
                    </Styled.InputWrap>
                    <Styled.InputWrap onClick={checkBtn1}>
                      <Styled.InputCheck name='step1' id='step1-2' value='2' />
                      <Styled.Label htmlFor='step1-2'>
                        <span>{t('step1.input2')}</span>
                      </Styled.Label>
                    </Styled.InputWrap>
                    <Styled.InputWrap onClick={checkBtn1}>
                      <Styled.InputCheck name='step1' id='step1-3' value='3' />
                      <Styled.Label htmlFor='step1-3'>
                        <span>{t('step1.input3')}</span>
                      </Styled.Label>
                    </Styled.InputWrap>
                    <Styled.InputWrap onClick={checkBtn1}>
                      <Styled.InputCheck name='step1' id='step1-4' value='4' />
                      <Styled.Label htmlFor='step1-4'>
                        <span>{t('step1.input4')}</span>
                      </Styled.Label>
                    </Styled.InputWrap>
                    <Styled.InputWrap onClick={checkBtn1}>
                      <Styled.InputCheck name='step1' id='step1-5' value='5' />
                      <Styled.Label htmlFor='step1-5'>
                        <span>{t('step1.input5')}</span>
                      </Styled.Label>
                    </Styled.InputWrap>
                  </Styled.StepInner>
                  <Styled.ButtonWrap>
                    <p>{message1}</p>
                    <Styled.Button
                      className={activeBtn1 > 0 ? 'active' : ''}
                      onClick={nextStep1}
                    >
                      {t('buttons.next')}
                    </Styled.Button>
                  </Styled.ButtonWrap>
                  <Styled.Skip
                    onClick={() => {
                      setActiveStep4('topik');
                      setStepNum(4);
                    }}
                  >
                    {t('buttons.skip')}
                  </Styled.Skip>
                </Styled.StepWrap>
              </Styled.RecommendedCourse>
            </>
          )}
          {/* //step 1 */}
          {/* step 2 */}
          {stepNum === 2 && (
            <Styled.RecommendedCourse>
              <Styled.StepWrap>
                <Styled.Steps>
                  <span>2</span>/3
                </Styled.Steps>
                <Styled.StepTitle>
                  {t('step2.title')}
                  <p>{t('step2.desc')}</p>
                </Styled.StepTitle>
                <Styled.StepInner>
                  <Styled.InputWrap onClick={() => checkBtn2(0)}>
                    <Styled.InputRadio id='step2-1' name='step2' />
                    <Styled.Label htmlFor='step2-1'>
                      <span className='level'>0</span>
                      <span className='title'>
                        {t('step2.input1.title')}
                        <Trans t={t} className='desc'>
                          step2.input1.desc
                        </Trans>
                      </span>
                    </Styled.Label>
                  </Styled.InputWrap>
                  <Styled.InputWrap onClick={() => checkBtn2(1)}>
                    <Styled.InputRadio id='step2-2' name='step2' />
                    <Styled.Label htmlFor='step2-2'>
                      <span className='level'>1-2</span>
                      <span className='title'>
                        {t('step2.input2.title')}
                        <Trans t={t} className='desc'>
                          step2.input2.desc
                        </Trans>
                      </span>
                    </Styled.Label>
                  </Styled.InputWrap>
                  <Styled.InputWrap onClick={() => checkBtn2(3)}>
                    <Styled.InputRadio id='step2-3' name='step2' />
                    <Styled.Label htmlFor='step2-3'>
                      <span className='level'>3-4</span>
                      <span className='title'>
                        {t('step2.input3.title')}
                        <Trans t={t} className='desc'>
                          step2.input3.desc
                        </Trans>
                      </span>
                    </Styled.Label>
                  </Styled.InputWrap>
                  <Styled.InputWrap onClick={() => checkBtn2(5)}>
                    <Styled.InputRadio id='step2-4' name='step2' />
                    <Styled.Label htmlFor='step2-4'>
                      <span className='level'>5-6</span>
                      <span className='title'>
                        {t('step2.input4.title')}
                        <Trans t={t} className='desc'>
                          step2.input4.desc
                        </Trans>
                      </span>
                    </Styled.Label>
                  </Styled.InputWrap>
                </Styled.StepInner>
                <Styled.ButtonWrap>
                  {selLevel > -1 ? undefined : <p>{message2}</p>}
                  <Styled.Button
                    className={selLevel > -1 ? 'active' : ''}
                    onClick={nextStep2}
                  >
                    {t('buttons.next2')}
                  </Styled.Button>
                </Styled.ButtonWrap>
              </Styled.StepWrap>
            </Styled.RecommendedCourse>
          )}
          {/* //step 2 */}
          {/* step 3 */}
          {stepNum === 3 && dataStep03 && (
            <Styled.RecommendedCourse>
              <Styled.StepWrap>
                <Styled.Steps>
                  <span>3</span>/3
                </Styled.Steps>
                <Styled.StepTitle>{t('step3.title')}</Styled.StepTitle>
                <Styled.MyCourse>
                  <div className='title'>{dataStep03.curriName}</div>
                  <ul>
                    <li>
                      <span>{dataStep03.lectureCnt}</span>
                      <p>{t('step3.courses')}</p>
                    </li>
                    <li>
                      <span>{dataStep03.videoCnt}</span>
                      <p>{t('step3.videos')}</p>
                    </li>
                    <li>
                      <span>{dataStep03.studyTime}</span>
                      <p>{t('step3.time')}</p>
                    </li>
                  </ul>
                </Styled.MyCourse>
                {console.log(
                  'dataStep03.recommendSubject',
                  dataStep03.recommendSubject
                )}
                {dataStep03.recommendSubject.toLowerCase() === 'korean' && (
                  <>
                    {/* 한국어 추천 코스 */}
                    <Styled.LectureList>
                      <div className='title'>{t('essential.korean')}</div>
                      <img src={koreanCourses} alt={t('essential.korean')} />
                    </Styled.LectureList>
                    {/* //한국어 추천 코스 */}
                  </>
                )}

                {dataStep03.recommendSubject.toLowerCase() === 'topik' && (
                  <>
                    {/* TOPIK 추천 코스 */}
                    <Styled.LectureList>
                      <div className='title'>{t('essential.topik')}</div>
                      <img src={topikCourses} alt={t('essential.topik')} />
                    </Styled.LectureList>

                    <Styled.TockTest>
                      <div className='title'>{t('topik.title')}</div>
                      <Trans t={t} className='desc'>
                        topik.desc
                      </Trans>
                    </Styled.TockTest>
                    {/* //TOPIK 추천 코스 */}
                  </>
                )}
                <Styled.AdditionalWrap>
                  <Styled.Additional className='material'>
                    <div className='title'>{t('additional1.title')}</div>
                    <Trans t={t} className='desc'>
                      additional1.desc
                    </Trans>
                  </Styled.Additional>
                  <Styled.Additional className='speak'>
                    <div className='title'>{t('additional2.title')}</div>
                    <Trans t={t} className='desc'>
                      additional2.desc
                    </Trans>
                  </Styled.Additional>
                  <Styled.Additional className='voca'>
                    <div className='title'>{t('additional3.title')}</div>
                    <Trans t={t} className='desc'>
                      additional3.desc
                    </Trans>
                  </Styled.Additional>
                </Styled.AdditionalWrap>

                <Styled.ButtonWrap>
                  <p>{t('buttons.desc')}</p>
                  <Styled.Button
                    className='active'
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setStepNum(4);
                    }}
                  >
                    {t('buttons.start')}
                  </Styled.Button>
                </Styled.ButtonWrap>
              </Styled.StepWrap>
            </Styled.RecommendedCourse>
          )}
          {/* //step 3 */}
          {/* step 4 */}
          {stepNum === 4 && (
            <>
              <Styled.Recomend>
                <Styled.H2>{t('recomend.title')}</Styled.H2>
                <Styled.Text>
                  <Trans t={t}>recomend.desc</Trans>
                </Styled.Text>
              </Styled.Recomend>
              <Styled.StepWrap>
                <Styled.PlanTabWrap>
                  {/* 선택 시 클래스 active 추가 */}
                  <Styled.PlanTab
                    onClick={() => {
                      setActiveTabStep4('month');
                    }}
                    className={activeTabStep4 === 'month' ? 'active' : ''}
                  >
                    {t('plan.tab1')}
                  </Styled.PlanTab>
                  <Styled.PlanTab
                    onClick={() => {
                      setActiveTabStep4('year');
                    }}
                    className={activeTabStep4 !== 'month' ? 'active' : ''}
                  >
                    {t('plan.tab2')}{' '}
                    <span className='off'>40% {t('plan.off')}</span>
                  </Styled.PlanTab>
                </Styled.PlanTabWrap>
                {/* PREMIUM */}
                <PlanCard.Container
                  onClick={() => setMembership('topik')}
                  className={membership === 'topik' ? 'active' : ''}
                >
                  <PlanCard.Inner>
                    <PlanCard.BestTag>BEST</PlanCard.BestTag>
                    <PlanCard.Title color={'#485cc7'}>PREMIUM</PlanCard.Title>
                    <PlanCard.DescWrap>
                      <PlanCard.Desc>
                        <Trans t={t}>plan.premium.desc</Trans>
                      </PlanCard.Desc>
                      <PlanCard.Desc>
                        <Trans t={t}>plan.premium.desc2</Trans>
                        <p>{t('plan.premium.desc2-1')}</p>
                      </PlanCard.Desc>
                    </PlanCard.DescWrap>
                    <PlanCard.Price>
                      {activeTabStep4 === 'month' ? (
                        <strong className='price'>
                          {t('price.numMonthPri')}
                        </strong>
                      ) : (
                        <strong className='price'>
                          {t('price.numYearPri')}
                        </strong>
                      )}
                      <span className='month'>
                        /
                        {activeTabStep4 !== 'month'
                          ? t('price.txtYear')
                          : t('price.txtMonth')}
                      </span>
                      <p className='free'>{t('plan.free')}</p>
                    </PlanCard.Price>
                  </PlanCard.Inner>
                </PlanCard.Container>
                {/* //PREMIUM */}
                {/* STANDARD​ */}
                <PlanCard.Container
                  onClick={() => setMembership('korean')}
                  className={membership === 'korean' ? 'active' : ''}
                >
                  <PlanCard.Inner>
                    <PlanCard.Title color={'#69bd18'}>STANDARD​</PlanCard.Title>
                    <PlanCard.DescWrap>
                      <PlanCard.Desc>
                        <Trans t={t}>plan.standard.desc</Trans>
                      </PlanCard.Desc>
                    </PlanCard.DescWrap>
                    <PlanCard.Price>
                      {activeTabStep4 === 'month' ? (
                        <strong className='price'>
                          {t('price.numMonthStd')}
                        </strong>
                      ) : (
                        <strong className='price'>
                          {t('price.numYearStd')}
                        </strong>
                      )}
                      <span className='month'>
                        /
                        {activeTabStep4 !== 'month'
                          ? t('price.txtYear')
                          : t('price.txtMonth')}
                      </span>
                      <p className='free'>{t('plan.free')}</p>
                    </PlanCard.Price>
                  </PlanCard.Inner>
                </PlanCard.Container>
                {/* //STANDARD​ */}
                <Styled.ButtonWrap>
                  <p>{t('buttons.desc2')}</p>
                  <Styled.Button className='active' onClick={payment}>
                    {t('buttons.startFree')}
                  </Styled.Button>
                </Styled.ButtonWrap>
                <Styled.Skip to={'/courses'}>{t('buttons.skip')}</Styled.Skip>
              </Styled.StepWrap>
            </>
          )}
          {/* //step 4 */}
        </Styled.Wrapper>
      </Styled.Container>
    </>
  );
};

export default CompleteSignupPage;

import styled from 'styled-components';
import { BsSearch } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';

export const StyledLibrarySearch = {
  Container: styled.div`
    margin-top: 8em;
  `,
  InputContainer: styled.span`
    display: inline-block;
    position: relative;
    width: 85.5%;
    border-radius: 0.6rem;
    background-color: #1e2024;
    margin-right: 0.63%;

    &.library {
      width: calc(100% - 0.4em - 17em);
      margin-right: 0.4em;

      @media (max-width: 767px) {
        width: calc(100% - 0.4em - 14em);
      }
    }
  `,

  Input: styled.input`
    display: inline-block;
    /* width: 89%; */
    width: calc(100% - 6.25em);
    height: 3em;
    border: none;
    background: transparent;
    color: #838587;
    font-size: 1.6em;
    outline: none;
    word-break: normal;

    &:focus {
      outline: none;
    }
  `,

  SearchImage: styled(BsSearch)`
    display: inline-block;
    /* width: 1em;
    margin: 0 1.8%; */
    width: 2.5em;
    color: #cacbd0;
    font-size: 2em;
    transform: translateY(0.25em);
  `,
  CloseImage: styled(IoMdClose)`
    display: inline-block;
    /* width: 3%; */
    width: 2em;
    font-size: 2.5em;
    color: #cacbd0;
    transform: translateY(0.25em);
    cursor: pointer;
  `,

  SearchList: styled.ul`
    display: ${({ show }) => (show ? 'block' : 'none')};
    position: absolute;
    top: 4.5em;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 0 0 6px 6px;
    background-color: #1e2024;
    z-index: 10;

    li {
      padding: 0.3em 1em;
      color: #838587;
      font-size: 1.6em;
      &:hover {
        background: #2b2e35;
      }
    }
  `,
};

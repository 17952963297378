import React, { useCallback, useEffect, useRef, useState } from 'react';
import Styled from './header.style';

import { Trans, useTranslation } from 'react-i18next';
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import { openLink } from 'common/util/util';
import useLoginStatus from 'common/hook/useLoginStatus';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';
import CreateAccount from 'component/user/CreateAccount';

import { useScrollHandler } from 'common/hook/useScrollHandler';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import logo from 'assets/images/mockTestLogo.png';

const Header = ({ scrollY, login, planData }) => {
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation('event/20230515FreeTrial');

  const history = useHistory();
  const location = useLocation();
  let { handleModal } = React.useContext(ModalContext);
  const scroll = useScrollHandler();

  return (
    <>
      <Styled.Wrapper className={!scroll && 'scrolled'}>
        <ContentsWrapper>
          <Styled.LogoWrapper>
            <Styled.Logo>
              <a href='/'>
                <img src={logo} alt='MASTER TOPIK' />
              </a>
            </Styled.Logo>
          </Styled.LogoWrapper>
          <Styled.RightMenu>
            {login ? (
              <Styled.JoinButton
                onClick={() => {
                  const item = planData?.plans.find(
                    (plan) => plan.productId === 1177
                  );
                  history.push({
                    pathname: `/plan-payment/${item.planCode.toLowerCase()}`,
                    search: `?productId=${item.productId}`,
                    state: item,
                  });
                }}
              >
                <Trans t={t}>button.start2</Trans>
              </Styled.JoinButton>
            ) : (
              <>
                <Styled.JoinButton>
                  <Trans
                    t={t}
                    onClick={() => {
                      handleModal(<CreateAccount />);
                    }}
                  >
                    button.join
                  </Trans>
                </Styled.JoinButton>
                <Styled.LoginButton>
                  <Trans
                    t={t}
                    onClick={() => {
                      handleModal(<Login />, false, 420, location.pathname);
                    }}
                  >
                    button.login
                  </Trans>
                </Styled.LoginButton>
              </>
            )}
          </Styled.RightMenu>
        </ContentsWrapper>
      </Styled.Wrapper>
    </>
  );
};

export default Header;

import React, { useState } from "react";
import Styled from "./index.style";

import PlayToggleOverlay from "./PlayToggleOverlay";
import ProgressBar from "./ProgressBar";
import Timer from "./Timer";
import VideoQuality from "./VideoQuality";
import PlaybackRate from "./PlaybackRate";
import FullScreenToggle from "./FullScreenToggle";
import useInterval from "common/hook/useInterval";

const PlayerControllerMobile = () => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsActive(!isActive);
  };

  useInterval(() => setIsActive(false), isActive ? 5000 : null);

  return (
    <Styled.Wrapper className={isActive ? "player-controller-wrapper mobile active" : "player-controller-wrapper mobile"}>
      <PlayToggleOverlay onClick={handleClick} />

      <Styled.Container className="player-controller">
        <ProgressBar />

        <Styled.LeftSide>
          <Timer className="player-timer" />
        </Styled.LeftSide>

        <Styled.RightSide>
          {/*<VideoQuality className="video-quality" />*/}

          <PlaybackRate className="playback-rate" />

          <FullScreenToggle className="fullscreen-button" />
        </Styled.RightSide>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default PlayerControllerMobile;

import { Circle } from 'rc-progress';
import styled from 'styled-components';

const StyledVideoProgressCircle = {
  Container: styled.span`
    position: relative;
		display: inline-block;
		font-size: 1rem;
    width: 2.6em;
    height: 2.6em;
    border-radius: 2.6em;
    overflow: hidden;
		vertical-align: middle;
    cursor: pointer;
  `,
  Circle: styled(Circle)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `,
  Icon: styled.span`
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 1.4em;
    margin: -0.5em 0 0 -0.4em;
    line-height: 1;
    .pause {
      margin-left: -0.05em;
    }
    .play {}
  `,
};

export default StyledVideoProgressCircle;

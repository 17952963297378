import React, { useEffect, useRef } from 'react';
import Style from './styled/index.style';
import { useAxios } from 'common/hook/useAxiosHook';
import {
  fetchProfileClassInfo,
  fetchProfileViewTime,
  postProfileImage,
} from 'container/ProfilePage/api/profileApi';
import { useDispatch, useSelector } from 'react-redux';
import defaultThumbnail from 'assets/images/img_user.png';
import { useTranslation } from 'react-i18next';
import Img from 'component/atoms/Img';
import { actions } from 'component/user/state';
import { toast } from 'react-toastify';
import { chkFileType } from 'common/util/util';

const Header = () => {
  const { t } = useTranslation('profilePage');
  const dispatch = useDispatch();

  const token = useSelector((state) => state.user.token);
  const userInfoData = useSelector((state) => state.user.data);

  const inputFileRef = useRef(null);
  const [{ data: viewTimeData }] = useAxios(
    () => fetchProfileViewTime(token),
    []
  );
  const [{ data: classTimeData }] = useAxios(
    () => fetchProfileClassInfo(token),
    []
  );
  const [{ data: postImage }, refreshPostProfileImage] = useAxios(
    postProfileImage,
    [],
    true
  );
  useEffect(() => {
    if (!postImage) return;
    window.location.reload();
  }, [postImage]);

  const handleFileInputClick = () => {
    inputFileRef.current.click();
  };

  const setFile = async (event) => {

    // 이미지 파일 확장자가 아니라면 거른다.
    let fileName = event.target.files[0].name;
    if(!chkFileType(fileName)) {
      return;
    }

    if (!event.target.files[0]) return;

    //파일 사이즈 제어
    const maxSize = 500000;
    if (event.target.files[0].size > maxSize) {
      toast.error('Image Size Limit');
      return;
    }
    await refreshPostProfileImage(event.target.files[0], token);
    dispatch(actions.fetchUser(token));
  };

  return (
    <Style.Container>
      <Style.ProfileInfo>
        <input
          style={{ display: 'none' }}
          onChange={(e) => setFile(e)}
          type='file'
          accept='image/*'
          ref={inputFileRef}
        />
        <Style.ImageContainer>
          <Img
            className='profile_img'
            src={
              userInfoData ? userInfoData.profilePhotoPath : defaultThumbnail
            }
            alt='profile-img'
            onError={defaultThumbnail}
          />
          <Style.InputFile onClick={handleFileInputClick} />
        </Style.ImageContainer>
        <Style.ProfileContent className='profile_content'>
          <Style.User.Info>
            <Style.User.Item className='view_time'>
              <Style.User.Title>
                {t('stateBoard.view')}&nbsp;
                {viewTimeData && viewTimeData.beginDate && (
                  <span>
                    {`${viewTimeData.beginDate} ~ ${viewTimeData.endDate}`}
                  </span>
                )}
              </Style.User.Title>
              <Style.User.Desc>
                {viewTimeData && viewTimeData.totalTime
                  ? viewTimeData.totalTime
                  : 0 + ' hr'}
              </Style.User.Desc>
            </Style.User.Item>
          </Style.User.Info>
          <Style.User.Info>
            <Style.User.Item className='class_watched'>
              <Style.User.Title>{t('stateBoard.watched')}</Style.User.Title>
              <Style.User.Desc>
                {classTimeData && classTimeData.classCount}
              </Style.User.Desc>
            </Style.User.Item>
          </Style.User.Info>
          <Style.User.Info>
            <Style.User.Item className='class_completed'>
              <Style.User.Title>{t('stateBoard.completed')}</Style.User.Title>
              <Style.User.Desc>
                {classTimeData && classTimeData.completedCount}
              </Style.User.Desc>
            </Style.User.Item>
          </Style.User.Info>
        </Style.ProfileContent>
      </Style.ProfileInfo>
    </Style.Container>
  );
};

export default Header;

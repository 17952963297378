import styled from "styled-components";
import { IconTextStyles } from "component/atoms/Icon";
import iconEdit from "assets/images/ico_edit.png";
import iconEditLarge from "assets/images/ico_edit_large.png";

const ReviseIcon = styled.span`
  ${IconTextStyles}
  padding-left: 2em;
  background-image: url(${iconEdit});
  background-position: -0.9em center;
  transition: background-image 0.3s ease;
  color: #8e9199;
  cursor: pointer;
  @media (max-width: 1024px) {
    background-image: url(${iconEditLarge});
    background-size: 38px;
  }
`;

export default ReviseIcon;

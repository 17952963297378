import React, { useEffect } from 'react';
import { useAxios } from 'common/hook/useAxiosHook';
import communityApi from 'api/community';
import { useHistory, useParams } from 'react-router-dom';
import StyledModal from 'component/Modal/styled/Modal.style';
import StyledModalForm from 'component/form/ModalForm.style';
import { ModalContext } from 'component/Modal/modalContext';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

/**
 * @param {number} param.id 게시글 id
 */
const ConfirmDeleteModal = ({ id }) => {
  const { t } = useTranslation('communityPage');
  let { handleModal } = React.useContext(ModalContext);
  const token = useSelector((state) => state.user.token);
  const [deleteState, fetchDelete] = useAxios(
    communityApi.deleteArticle,
    [],
    true
  );
  const { loading, data: deleteStatus, error } = deleteState;

  const history = useHistory();
  useEffect(() => {
    if (deleteStatus === 200) {
      history.replace('/community');
      handleModal();
      toast('It has been deleted.');
    }
  }, [deleteStatus]);

  return (
    <>
      <StyledModal.H2>
        <Trans t={t}>detailConfirmModalTitle</Trans>
      </StyledModal.H2>
      <StyledModalForm.ButtonGroup>
        <StyledModalForm.Button onClick={() => fetchDelete(id, token)}>
          {t('buttonDelete')}
        </StyledModalForm.Button>
        <StyledModalForm.CancelButton onClick={() => handleModal()}>
          {t('buttonCancel')}
        </StyledModalForm.CancelButton>
      </StyledModalForm.ButtonGroup>
      {error && (
        <StyledModalForm.WarnText>
          {error.data.message}
        </StyledModalForm.WarnText>
      )}
      <LoadingOverlay isLoading={loading} />
    </>
  );
};

export default ConfirmDeleteModal;

import React, { useState, useEffect, useRef } from 'react';
import Style from '../styled/Lnb.style';
import { Link } from 'react-router-dom';
import { lowerCase } from 'lodash';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAxios } from 'common/hook/useAxiosHook';
import helpApi from 'api/help';

const Nav = ({ t, category, articleIdx }) => {
  const [mobileSelect, setMobileSelect] = useState(false);
  const openMenu = () => {
    setMobileSelect(mobileSelect => !mobileSelect);
  }

  const history = useHistory();

  const moveToArticle = (idx) => {
    console.error("test: move" + idx);
    history.push({
      pathname: `/help/article/${idx}`
    });
  };

  const [searchResults, getSearchResults] = useAxios(
    helpApi.getSearchList,
    [],
    true
  );

  const {data : result} = searchResults;

  useEffect(()=>{
    getSearchResults("",lowerCase(category),1,10);
  },[category]);

  useEffect(()=>{
    console.error("test: result",result);
  },[result])

  return (
    <Style.NavWrap className={mobileSelect ? 'show-menu' : 'hide-menu'}>
      <div className='nav' onClick={() => openMenu()}>
        <Style.Info className='title'>Articles in this section</Style.Info>
        <ul className='document'>
          {/* <li className='on'>
            <button>
              <Style.Title className='recom'>I want to vreate an account </Style.Title>
            </button>
          </li>
          <li>
            <button>
              <Style.Title>I want to vreate an account I want to vreate an account  I want to vreate an account </Style.Title>
            </button>
          </li>
          <li>
            <button>
              <Style.Title>I want to vreate an account </Style.Title>
            </button>
          </li> */}
          { result && result.docList &&
            result.docList.map((item) => {
              return (
                <li className={articleIdx === item.tblCustomerCenterDocIdx ? 'on' : ''}>
                  <button onClick={() => moveToArticle(item.tblCustomerCenterDocIdx)}>
                    <Style.Title className={item.isRecommend ? 'recom' : ''}>{item.title}</Style.Title>
                  </button>
                </li>
              );
            })
          }
        </ul>
        <Link to={`/help/${lowerCase(category)}`}><Style.ButtonMore>{t('button.more')}</Style.ButtonMore></Link>
      </div>
    </Style.NavWrap>
  );
};
export default Nav;
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const btnStyle = css`
  position: relative;
  margin: 0 auto;
  padding: 1.125em;
  border: 1px solid;
  border-radius: 6px;
  color: #fff;
  font-size: 1.6em;
  font-weight: 600;
`;

const Modal = {
  Container: styled.div`
    text-align: center;
  `,
  Desc: styled.div`
    font-size: 1.8em;
    font-weight: 400;

    &:first-child {
      padding-top: 1em;
    }
  `,
  Uppercase: styled.span`
    text-transform: uppercase;
  `,
  ButtonGroup: styled.div`
    display: flex;
    margin-top: 5em;
  `,
  Link: styled(Link)`
    ${btnStyle}
    width: 50%;
    line-height: 1;
    border-color: #ffb500;
    background-color: #ffb500;
    color: #fff;
    cursor: pointer;
  `,
  Button: styled.div`
    ${btnStyle}
    width: 50%;
    line-height: 1;
    border-color: #ffb500;
    background-color: #ffb500;
    color: #fff;
    cursor: pointer;

    &.no {
      background-color: #ffffff;
      border-color: #5c5f66;
      color: #5c5f66;
      margin-left: 1em;
    }
  `,
}

export default Modal;

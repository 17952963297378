import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import commonReducer from './state';
import userReducer from 'component/user/state';
import UserSaga from 'component/user/state/saga';
import notiReducer from 'container/NotificationPage/state';
import courseDetailReducer from 'container/CourseDetailPage/state/reducer';
import bookStoreReducer from 'container/BookStorePage/state/reducer';
import NotiSaga from 'container/NotificationPage/state/saga';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import persistStore from 'redux-persist/es/persistStore';
import notificationReducer from 'component/layout/Header/RightMenu/state/reducer';
import videoClassStoreReducer from 'container/PaymentPage/state/reducer';
import jresearchReducer from 'container/JresearchPage/state/reducer';
import wbmKoreaReducer from 'container/WbmKoreaPage/state/reducer';
import audioDetailReducer from 'container/AudioDetailPage/state/reducer';

const persistConfig = {
  key: 'root',
  storage: storage,
};

const reducer = combineReducers({
  common: commonReducer,
  user: userReducer,
  notification: notiReducer,
  courseDetail: courseDetailReducer,
  bookStore: bookStoreReducer,
  alarm: notificationReducer,
  videoClassStore: videoClassStoreReducer,
  jresearch: jresearchReducer,
  wbmKorea: wbmKoreaReducer,
  audioDetail: audioDetailReducer
});

const persistedReducer = persistReducer(persistConfig, reducer);

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

function* rootSaga() {
  yield all([UserSaga(), NotiSaga()]);
}
sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;

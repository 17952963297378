import React, { useState, useEffect } from "react";
import PageSubTitle from "component/molecules/PageSubTitle";
import { SwiperSlide } from "swiper/react";
import themesApi from "api/theme";
import { useAxios } from "common/hook/useAxiosHook";
import SwiperList from "component/molecules/SwiperList";
import UserThemeItem from "component/molecules/ThemeItem/UserThemeItem";
import { masterTopikUrl } from "common/constant";
import Features from "container/LandingPage/Features";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const ThemeContainer = () => {
  const { t } = useTranslation("libraryPage");
  const [slideItems, setSlideItems] = useState([]);
  const token = useSelector((state) => state.user.token);
  const [getThemeList, refreshGetThemeList] = useAxios(
    themesApi.getAllThemeList,
    [],
    true
  );
  const { data } = getThemeList;

  useEffect(() => {
    refreshGetThemeList({ page: 0, token: token });
  }, []);

  useEffect(() => {
    if (data !== null) {
      setSlideItems([...slideItems, ...data.content]);
    }
  }, [getThemeList]);

  const onNext = () => {
    if (!data.last) {
      refreshGetThemeList({ page: data.number + 1, token: token });
    }
  };

  return (
    <div>
      <PageSubTitle title={t("contentSection.section5.title")} />
      <SwiperList slideItemList={slideItems} onNext={onNext} id="myThemes">
        {slideItems.map((item) => {
          const imgSrcList = item.themeProductResponse.map(
            (itemChild) =>
              masterTopikUrl +
              itemChild?.thumbnailPath +
              itemChild?.thumbnailTitle
          );
          return (
            <SwiperSlide key={item.id}>
              <UserThemeItem
                id={item.id}
                imgSrcList={imgSrcList}
                title={item.name}
                tag={item.categoryName}
                videoCount={item.courseCount}
                likeCount={item.likeCount}
                isGood={item.isGood}
                userName={item.userName}
                userThumb={item.userProfilePhotoPath}
              />
            </SwiperSlide>
          );
        })}
      </SwiperList>
    </div>
  );
};

export default React.memo(ThemeContainer);

import styled from 'styled-components';

const StyledCancelModal = {
  Container: styled.div`
    text-align: center;
  `,
  Title: styled.h3`
    margin-bottom: 1.875em;
    font-size: 2.4em;
    font-weight: 700;
  `,
  Desc: styled.p`
    font-size: 1.6em;
    font-weight: 400;
  `,

  Button: styled.button`
    display: inline-block;
    width: 94%;
    margin-top: 1.875em;
    padding: 1.25em 0;
    border-radius: 0.375em;
    background-color: #ffb500;
    color: #fff;
    font-size: 1.6em;
    font-weight: 700;
  `,
  Message: styled.p`
    margin-top: 3.25em;
    color: #000;
    font-size: 1.6em;
    font-weight: 700;
  `,
};

export default StyledCancelModal;

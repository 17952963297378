import React from 'react';
import Style from 'container/MockTestPage/styled/TestResultPage.style';
import { useSelector } from 'react-redux';
import { secondsToTime } from 'common/util/util';

const Header = ({ data }) => {
  const user = useSelector((state) => state.user.data);

  if (data)
    return (
      <Style.HeaderContainer>
        <Style.HeaderContent>
          <span className='logo'></span>
          <p>{data.testTitle}</p>
          <Style.HeaderResults>
            <li>
              <strong>응시자</strong>
              <span>{user.fullName}</span>
            </li>
            <li>
              <strong>응시일</strong>
              <span>{data.testDate.slice(0, 10)}</span>
            </li>
            <li>
              <strong></strong>
              <span></span>
            </li>
            <li>
              <strong>총점</strong>
              <span>{data.countScore}</span>
            </li>
            <li>
              <strong>등급</strong>
              <span>{data.status}</span>
            </li>
            <li>
              <strong>문제풀이 시간</strong>
              <span>{secondsToTime(data.testTime - data.recordedTime)}</span>
            </li>
            <li>
              <strong>총 문제 수</strong>
              <span>{data.size}</span>
            </li>
            <li>
              <strong>정답 수</strong>
              <span>{data.count}</span>
            </li>
            <li>
              <strong></strong>
              <span> </span>
            </li>
          </Style.HeaderResults>
        </Style.HeaderContent>
      </Style.HeaderContainer>
    );
  return <></>;
};

export default Header;

import React, { useEffect, useRef, useState} from 'react';
import Styled from './index.style';
import flowImg1_1 from 'assets/images/event/230103/flow/flow1_1.jpg';
import flowImg1_2 from 'assets/images/event/230103/flow/flow1_2.jpg';
import flowImg1_3 from 'assets/images/event/230103/flow/flow1_3.jpg';
import flowImg1_4 from 'assets/images/event/230103/flow/flow1_4.jpg';
import flowImg1_5 from 'assets/images/event/230103/flow/flow1_5.jpg';
import flowImg1_6 from 'assets/images/event/230103/flow/flow1_6.jpg';
import flowImg2_1 from 'assets/images/event/230103/flow/flow2_1.jpg';
import flowImg2_2 from 'assets/images/event/230103/flow/flow2_2.jpg';
import flowImg2_3 from 'assets/images/event/230103/flow/flow2_3.jpg';
import flowImg2_4 from 'assets/images/event/230103/flow/flow2_4.jpg';
import flowImg2_5 from 'assets/images/event/230103/flow/flow2_5.jpg';
import flowImg2_6 from 'assets/images/event/230103/flow/flow2_6.jpg';
import flowImg3_1 from 'assets/images/event/230103/flow/flow3_1.jpg';
import flowImg3_2 from 'assets/images/event/230103/flow/flow3_2.jpg';
import flowImg3_3 from 'assets/images/event/230103/flow/flow3_3.jpg';
import flowImg3_4 from 'assets/images/event/230103/flow/flow3_4.jpg';
import flowImg3_5 from 'assets/images/event/230103/flow/flow3_5.jpg';
import flowImg3_6 from 'assets/images/event/230103/flow/flow3_6.jpg';

import { Trans, useTranslation } from 'react-i18next';
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import { openLink } from 'common/util/util';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";


const EventPage = () => {
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation('event/20230103MonthPass');

  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);
	const location = useLocation();

	const checkLogin = (e) => {
    if (!isLogin) {
      e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
			handleModal(<Login />, false, 420, location.pathname);
			return;
		}
    // else{
    //   openLink(`/bookstore`);
    // }
	}

	return (
		<Styled.EventWrap className={language === 'ko-KR' ? 'ko' : null}>
      <div className='visual'>
        <div className='inner'>
          <div className='blind'>
            <h2>{t('visual.title')}</h2>
            <p>{t('visual.desc')}</p>
          </div>
          <Styled.BtnWrap>
            <Link to={"/plan"}>
              {language === 'ko-KR' ?
              <div className="label"><span className="blind">{t('visual.label')}</span></div>
              : null}
              {t('visual.button')}
            </Link>
          </Styled.BtnWrap>
        </div>
      </div>

      <div className='intro'>
        <div className='inner'>
          <div className='h2'>
            <Trans t={t}>intro.title</Trans>
            <h2><Trans t={t}>intro.title2</Trans></h2>
          </div>
          <div className="flow_screen">
            <div className="flow_banner group1">
              <ul>
                <li><img src={flowImg1_1} alt="" /></li>
                <li><img src={flowImg1_2} alt="" /></li>
                <li><img src={flowImg1_3} alt="" /></li>
              </ul>
              <ul>
                <li><img src={flowImg1_4} alt="" /></li>
                <li><img src={flowImg1_5} alt="" /></li>
                <li><img src={flowImg1_6} alt="" /></li>
              </ul>
            </div>
            <div className="flow_banner group2">
              <ul>
                <li><img src={flowImg2_1} alt="" /></li>
                <li><img src={flowImg2_2} alt="" /></li>
                <li><img src={flowImg2_3} alt="" /></li>
              </ul>
              <ul>
                <li><img src={flowImg2_4} alt="" /></li>
                <li><img src={flowImg2_5} alt="" /></li>
                <li><img src={flowImg2_6} alt="" /></li>
              </ul>
            </div>
            <div className="flow_banner group3">
              <ul>
                <li><img src={flowImg3_1} alt="" /></li>
                <li><img src={flowImg3_2} alt="" /></li>
                <li><img src={flowImg3_3} alt="" /></li>
              </ul>
              <ul>
                <li><img src={flowImg3_4} alt="" /></li>
                <li><img src={flowImg3_5} alt="" /></li>
                <li><img src={flowImg3_6} alt="" /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='graph'>
        <div className='inner'>
          <div className='h2'>
            <Trans t={t}>graph.title</Trans>
            <h2><Trans t={t}>graph.title2</Trans></h2>
          </div>
          <div className='img'>
            <div className='blind'><Trans t={t}>graph.desc</Trans></div>
            <div className='blind'><Trans t={t}>graph.desc2</Trans></div>
            <div className='blind'><Trans t={t}>graph.desc3</Trans></div>
          </div>
        </div>
      </div>

      <div className='coupon'>
        <div className='inner'>
          <div className='h2'>
            <Trans t={t}>coupon.title</Trans>
            <h2>
              <Trans t={t}>coupon.title2</Trans>
              <div className='last'>
                <Trans t={t}>coupon.title3</Trans>
              </div>
            </h2>
          </div>
          <div className='img'>
            <div className='blind'><Trans t={t}>coupon.desc</Trans></div>
          </div>
          <Styled.BtnWrap>
            <Link to={"/plan"}>
              {t('visual.button')}
            </Link>
          </Styled.BtnWrap>
        </div>
      </div>

      <div className='notification'>
        <div className='inner'>
          <h3>{t('notification.title')}</h3>
          <dl>
            <dt>{t('notification.group1.title')}</dt>
            <dd><Trans t={t}>notification.group1.desc1</Trans></dd>
            <dd><Trans t={t}>notification.group1.desc2</Trans></dd>
          </dl>
          <dl>
            <dt>{t('notification.group2.title')}</dt>
            <dd><Trans t={t}>notification.group2.desc1</Trans></dd>
            <dd><Trans t={t}>notification.group2.desc2</Trans></dd>
            <dd><Trans t={t}>notification.group2.desc3</Trans></dd>
            <dd><Trans t={t}>notification.group2.desc4</Trans></dd>
          </dl>
          <dl>
            <dt>{t('notification.group3.title')}</dt>
            <dd><Trans t={t}>notification.group3.desc1</Trans></dd>
            <dd><Trans t={t}>notification.group3.desc2</Trans></dd>
            <dd><Trans t={t}>notification.group3.desc3</Trans></dd>
          </dl>
          <dl>
            <dt>{t('notification.group4.title')}</dt>
            <dd><Trans t={t}>notification.group4.desc1</Trans></dd>
            <dd><Trans t={t}>notification.group4.desc2</Trans></dd>
          </dl>
        </div>
      </div>
		</Styled.EventWrap>
  );
};

export default EventPage;
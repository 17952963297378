import React, { useContext, useCallback } from "react";
import Styled from "./index.style";
import screenfull from "screenfull";
import { PlayerContext } from "component/Player";
import { RiFullscreenExitFill, RiFullscreenFill } from "react-icons/ri";
import { getUA } from "react-device-detect";
import { MOBILE_APP_USER_AGENT } from "common/constant";

const FullScreenToggle = (props) => {
  const { playerState, handleState, playerRef, wrapperRef } = useContext(
    PlayerContext
  );
  const { fullScreen } = playerState;

  console.log("isFullscreen", screenfull.isFullscreen);
  const handleClickFullscreen = useCallback(() => {
    // if (getUA === MOBILE_APP_USER_AGENT) {
    //   if (!wrapperRef.current) return;
    //   if (document.fullscreenElement && document.exitFullscreen) {
    //     console.log("@@ Mobile close");
    //     document.exitFullscreen();
    //     return;
    //   }
    //   console.log(wrapperRef.current);
    //   if (wrapperRef.current.requestFullscreen) {
    //     console.log(
    //       "@@ Mobile requestFullscreen",
    //       wrapperRef.current.requestFullscreen
    //     );
    //     wrapperRef.current.requestFullscreen();
    //   } else if (wrapperRef?.current?.mozRequestFullScreen) {
    //     console.log(
    //       "@@ Mobile mozRequestFullScreen",
    //       wrapperRef?.current?.mozRequestFullScreen
    //     );
    //     wrapperRef.current.mozRequestFullScreen();
    //   } else if (wrapperRef.current.webkitRequestFullscreen) {
    //     console.log(
    //       "@@ Mobile webkitRequestFullscreen",
    //       wrapperRef.current.webkitRequestFullscreen
    //     );
    //     wrapperRef.current.webkitRequestFullscreen();
    //   } else if (wrapperRef.current.msRequestFullscreen) {
    //     console.log(
    //       "@@ Mobile webkitRequestFullscreen",
    //       wrapperRef.current.msRequestFullscreen
    //     );
    //     wrapperRef.current.msRequestFullscreen();
    //   }
    //   return;
    // }
    if (!screenfull.isFullscreen) {
      screenfull.request(wrapperRef.current);
    } else {
      screenfull.exit(wrapperRef.current);
    }
  }, [screenfull]);

  return (
    <Styled.Button onClick={handleClickFullscreen} {...props}>
      {!fullScreen ? <RiFullscreenFill /> : <RiFullscreenExitFill />}
    </Styled.Button>
  );
};

export default FullScreenToggle;

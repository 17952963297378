import courseDetailType from './type';

//setting current video in course detail page
export const setCurrentUrl = (urlObj) => ({
  type: courseDetailType.Set_Current_Video_Url,
  payload: urlObj,
});

export const setVideoUrl = (urlObj) => ({
  type: courseDetailType.Set_Video_Url,
  payload: urlObj,
});

export const setSelectNumber = (numObj) => ({
  type: courseDetailType.Set_Select_Number,
  payload: numObj,
});

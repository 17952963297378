import styled from 'styled-components';

export const BadgeTheme = {
  primary: {
    padding: '0.3em 1.2em',
    borderColor: '#1e2024',
    bg: '#1e2024',
    text: '#9e9fa0',
  },
};

const Badge = styled.span`
  display: inline-block;
  padding: ${(props) => props.theme.padding};
  border: 1px solid ${(props) => props.theme.borderColor};
  border-radius: 17px;
  background: ${(props) => props.theme.bg};
  text-align: center;
  color: ${(props) => props.theme.text};
  font-size: 1.5em;
  cursor: pointer;
`;

Badge.defaultProps = {
  theme: {
    padding: '0.2em 1.3em',
    borderColor: '#8e9199',
    bg: 'transparent',
    text: '#8e9199',
  },
};

export default Badge;
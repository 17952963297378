import styled, {css} from 'styled-components';

import "assets/css/font-pretendard.css"; /* event only */

import icoArrow from 'assets/images/event/221130/ico_arrow.png';
import icoArrow2 from 'assets/images/event/221130/ico_arrow2.png';
import visualImg from 'assets/images/event/221130/visual.jpg';
import visualImgKo from 'assets/images/event/221130/ko/visual.jpg';
import bookImg from 'assets/images/event/221130/book.jpg';
import bookImg2 from 'assets/images/event/221130/book2.jpg';
import ebookImg from 'assets/images/event/221130/ebook.jpg';

const StyledEventPage = {
  BtnArrow: styled.div.attrs({ className: 'btnWrap' })`
    a {
      display: block;
      max-width: 540px;
      margin: auto;
      padding: 1.4em 1em;
      border-radius: 10em;
      line-height: 1em;
      font-size: 1.4em;
      color: #fff;
      text-align: center;
      background: #202125;
      > div {
        display: inline-block;
        padding-right: 1em;
        background-image:url(${icoArrow});
        background-position: 100% 50%;
        background-repeat: no-repeat;
        background-size: auto 0.8em;
      }
    }
    @media (max-width: 750px) {
      a {
        font-size: 1.6em !important;
      }
    }
  `,

	EventWrap: styled.div`
    font-family: 'Pretendard';
		font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    color: #555;
    letter-spacing: -0.035em;

		.blind {
			overflow: hidden;
			position: absolute;
			clip: rect(0 0 0 0);
			width: 1px;
			height: 1px;
			margin: -1px;
		}

    .inner {
      position: relative;
			max-width: 750px;
      margin: auto;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 6em 0;
    }

    .h2 {
      margin-bottom: 1.6em;
      font-size: 1.4em;
      h2 {
        font-weight: 700;
        font-size: 2em;
        &.mt {
          margin-top: 0.5em;
        }
        &.mb {
          margin-bottom: 0.5em;
        }
      }
    }

    .visual {
      background: #1d1d1e;
			.inner {
        padding-top: 1344px;
        background-size: cover;
        background-image: url(${visualImg});
				z-index:2;
      }
    }
    &.ko .visual {
      .inner{
        background-image:url(${visualImgKo});
      }
    }

    .buynow {
      background: #0776cd;
      .h2 {
        color: #e3f2fd;
        h2 {color: #fff;}
      }
      .label {
        display: inline-block;
        padding: 0.6em 1em;
        margin-bottom: 1.6em;
        background: #0362ba;
        border-radius: 3em;
        font-weight: 500;
        font-size: 1.4em;
        color: #fff;
      }
      .links {
        max-width: 690px;
        margin: 0 auto;
      }
      .links a {
        position: relative;
        display: block;
        min-height: 380px;
        padding-left: 40%;
        background-color: #fff;
        border-radius: 2em;
        overflow: hidden;
        text-align: left;
        & + a {
          margin-top: 2em;
        }
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 40%;
          height: 100%;
          background-color: #ecedef;
          background-position: 50% 50%;
          background-size: contain;
          background-repeat: no-repeat;
        }
        &.korean::before {
          background-image:url(${bookImg});
        }
        &.amazon::before {
          background-image:url(${bookImg2});
        }
        .info {
          padding: 2em 1.75em;
          height: 100%;
          .tit {
            font-weight: 600;
            font-size: 1.6em;
            color: #121212;
          }
          p {
            font-size: 1.2em;
            color: #767676;
          }
          .price {
            margin: 1em 0 5em;
            em {
              display: inline-block;
              margin-right: 0.3em;
              font-weight: 700;
              font-size: 1.8em;
              color: #e51e1e;
            }
            strong {
              display: inline-block;
              margin-right: 0.3em;
              font-weight: 700;
              font-size: 1.8em;
              color: #121212;
            }
            span {
              font-size: 1.2em;
              color: #999;
              text-decoration: line-through;
            }
          }
        }
        .btn {
          position: absolute;
          bottom: 2.5em;
          display: block;
          padding: 0.8em 3.5em 0.8em 1.2em;
          border-radius: 0.8em;
          background-color: #ffc426;
          font-size: 1.2em;
          color: #45350c;
          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 1.5em;
            height: 0.5em;
            right: 1.2em;
            top: 1.2em;
            background: url(${icoArrow2}) no-repeat 0 0;
            background-size: contain;
          }
        }
      }
    }

    .ebook {
      background: #0d42b9;
      .h2 {
        color: #cfdbf6;
        h2 {color: #fff;}
      }
      .img {
        position: relative;
        padding-top: 573px;
        background-image: url(${ebookImg});
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: 100% auto;
      }
    }

    @media (max-width: 750px) {
      .inner {
        padding: 4em 4%;
      }
      .visual {
        .inner {
          padding-top: 179.2%;
        }
      }
      .buynow {
        .links a {
          min-height: 50vw;
          .btn {bottom: 2em;}
        }
      }
      .ebook {
        .img {
          padding-top: 76.4%;
        }
      }
    }

    .notification {
      background-color: #373c49;
      text-align: left;
      color: #fff;
      font-weight: 300;
      letter-spacing: normal;
      .inner {
        padding: 4em 4%;
      }
      h3 {
        text-align: center;
        font-size: 2.8rem;
        font-weight: 700;
      }
      dl {
        font-size: 2.2rem;
      }
      dt {
        margin: 2em 0 0.5em;
        font-weight: 700;
				a {
					border-bottom: none;
				}
      }
      dd {
        position:relative;
        padding-left: 1.2em;
        margin-bottom: 0.5em;
        color: #e5e4e6;
        &:before {
          content:"";
          display:inline-block;
          position: absolute;
          top:0.5em;
          left:0.3em;
          width: 0.25em;
          height: 0.25em;
          border-radius: 0.2em;
          background: #e5e4e6;
          vertical-align:middle;
        }
        em {
          color: #fff799;
        }
      }
      dl.number dd {
        &:before {
          display: none;
        }
        .num {
          position: absolute;
          top:0;
          left:0;
        }
      }
      P {
        padding: 1.6em 0 0.8em;
      }
      a {
        display: inline-block;
        border-bottom: 1px #fff solid;
        line-height: 1.2;
      }
    }
  `,
};

export default StyledEventPage;

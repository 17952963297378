import React, { useState, useRef } from 'react';
import Style from "./index.style";
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { Trans } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation, Controller } from 'swiper';
SwiperCore.use([Autoplay, Pagination, Navigation, Controller]);


const Banner = ({ t }) => {
  //배너 여닫기
  const [isActive, setActive] = useState(0);
  const bannerToggle = () => {
    setActive(isActive => !isActive);
  };

  const swiperRef = useRef()
  const handleMouseEnter = () => {
    swiperRef?.current?.swiper?.autoplay?.stop()
  };
  const handleMouseLeave = () => {
    swiperRef?.current?.swiper?.autoplay?.start()
  };

  return (
    <Style.Container onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Swiper
        ref={swiperRef}
        spaceBetween={0}
        slidesPerView={1}
        updateOnWindowResize={true}
        speed={300}
        loop
        autoplay={{
          delay: 3000
        }}
      >
        <SwiperSlide>
          <Style.Banner>
            <Style.BannerLink to="/event/freetrial">
              <Style.TextWrap><Trans t={t}>banner.link</Trans></Style.TextWrap>
            </Style.BannerLink>
            <Style.ButtonToggle onClick={bannerToggle} className={isActive ? "active" : null}><span className='blind'>Note</span></Style.ButtonToggle>
          </Style.Banner>
        </SwiperSlide>

        <SwiperSlide>
          <Style.Banner>
            <Style.BannerLink to="/event/freetrial#curriculum">
              <Style.TextWrap><Trans t={t}>banner.link2</Trans></Style.TextWrap>
            </Style.BannerLink>
            <Style.ButtonToggle onClick={bannerToggle} className={isActive ? "active" : null}><span className='blind'>Note</span></Style.ButtonToggle>
          </Style.Banner>
        </SwiperSlide>

        <SwiperSlide>
          <Style.Banner>
            <Style.BannerLink to="/event/freetrial#review">
              <Style.TextWrap><Trans t={t}>banner.link3</Trans></Style.TextWrap>
            </Style.BannerLink>
            <Style.ButtonToggle onClick={bannerToggle} className={isActive ? "active" : null}><span className='blind'>Note</span></Style.ButtonToggle>
          </Style.Banner>
        </SwiperSlide>

        <SwiperSlide>
          <Style.Banner>
            <Style.BannerLink to="/event/freetrial#howto">
              <Style.TextWrap><Trans t={t}>banner.link4</Trans></Style.TextWrap>
            </Style.BannerLink>
            <Style.ButtonToggle onClick={bannerToggle} className={isActive ? "active" : null}><span className='blind'>Note</span></Style.ButtonToggle>
          </Style.Banner>
        </SwiperSlide>
      </Swiper>
      <Style.Note className={isActive ? "active" : null}>
        <ContentsWrapper>
          <Style.NoteList>
            <li><Trans t={t}>banner.desc</Trans></li>
            <li><Trans t={t}>banner.desc2</Trans></li>
            <li><Trans t={t}>banner.desc3</Trans></li>
            <li><Trans t={t}>banner.desc4</Trans></li>
            <li><Trans t={t}>banner.desc5</Trans></li>
          </Style.NoteList>
        </ContentsWrapper>
      </Style.Note>
    </Style.Container>
  );
};

export default Banner;

import React, { useEffect, useState } from "react";
import StyledUtil from "common/styled/Util.style";
import styled from "styled-components";
import H3 from "component/atoms/H3";
import Error from "component/Error";
import SwiperList from "component/molecules/SwiperList";
import { SwiperSlide } from "swiper/react";
import ThemeCategoryItem from "component/molecules/ThemeCategoryItem";
import UserThemeItem from "component/molecules/ThemeItem/UserThemeItem";
import Features from "container/LandingPage/Features";
import { Trans, useTranslation } from "react-i18next";

const Wrapper = styled.div`
  margin-bottom: 10em;
  h3 {
    margin-bottom: 1em;
  }
`;

const StyledItem = {
  Container: styled.div``,
  ImgBox: styled.div`
    margin-bottom: 1em;
    border-radius: 0.6rem;
    overflow: hidden;
    img {
      width: 100%;
    }
  `,
  Title: styled.div`
    ${StyledUtil.textEllipsis}
    font-size: 2em;
    margin-bottom: 0.1em;
    font-weight: 400;
  `,
  Desc: styled.div`
    ${StyledUtil.lineClamp}
    font-size: 1.6em;
    color: #8e9199;
  `,
};

const RecommendThemes = ({ initialState, token }) => {
  const { t } = useTranslation("coursePage");
  const [themeItem, fetchThemeItem] = initialState;
  const { loading, data: themeItemList, error } = themeItem;

  const [slideItemList, setSlideItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => fetchThemeItem({ page: currentPage, token: token }), []);

  useEffect(() => {
    if (themeItemList) {
      setSlideItemList([...slideItemList, ...themeItemList.content]);
    }
  }, [themeItemList]);

  const onNext = () => {
    if (!loading && currentPage < themeItemList.totalPages - 1) {
      fetchThemeItem({ page: currentPage + 1, token: token });
      setCurrentPage(currentPage + 1);
    }
  };

  if (error) return <Error error={error} />;

  return (
    <Wrapper>
      <H3>
        <Trans t={t}>videoTab.section7.title</Trans>
      </H3>
      <SwiperList
        id="category-theme"
        slideItemList={slideItemList}
        onNext={onNext}
      >
        {slideItemList.map((item, index) => (
          <SwiperSlide key={index}>
            <UserThemeItem
              adminTheme={true}
              id={item.id}
              imgSrcList={item.thumbnailPath}
              title={item.name}
              desc={item.description}
              userName={item.userName}
              userThumb={item.userProfilePhotoPath}
              likeCount={item.likeCount}
              videoCount={item.courseCount}
              tag={item.categoryName}
              isGood={item.isGood}
            />
          </SwiperSlide>
        ))}
      </SwiperList>
    </Wrapper>
  );
};
export default RecommendThemes;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import bgOnboarding from 'assets/images/bg_onboarding.png';


const TodayLesson = {
  Wrapper: styled.div`
    max-width: calc(1300px + 20px * 2);
    padding:0 20px;
    display: flex;
    /* max-width: calc(1300px); */

    overflow: hidden;
    margin:0 auto 2.5rem;
    flex-direction: column;
    > * {
      display: inline-block;
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    @media (max-width: 767px) {
      margin-bottom: 7em;
    }
  `,
  Inner: styled.div`
    padding:1.5rem 3rem 4rem;
    background-color: #1e2024;
    border-radius: 0.6em;
  `,
  Head: styled.div`
    display:flex;
    justify-content: space-between;
    margin-bottom:2.5rem;
    align-items: flex-end;
    @media (max-width: 767px) {
     align-items: center;;
    }
  `,
  H3 : styled.h3`
    font-size: 2rem;
    font-weight: 500;
    color: #c9cbd1;
    @media (max-width: 767px) {
      font-size: 3em;
      color: #fff;
    }
  `,
  MoreBtn: styled(Link)`
    color:#8b8f99;
    font-size:1.4rem;

    svg{
      fill:#8b8f99;
      margin-left:0.5rem;
    }
    &:hover{
      color:#ffb500;
      svg path{
        fill:#ffb500;
      }
    }

    @media (max-width: 767px) {
      font-size:0;
      img{
        width:11px;
      }
    }
  `,
   LessonWrap : styled.div`
    display:flex;
    align-items:center;
    gap:2rem;
    padding:0 4rem;
    background-color: #f2f2f2;
    border-radius: 0.6em;
    width:100%;
    height:8rem;
    @media (max-width: 767px) {
     height:auto;
     padding:15px 4rem;
    }
   `,
   Icon : styled.div`
    width:4rem;
   `,
   TextBox : styled.div`
    flex:1;
   `,
   Title : styled.h3`
    font-size:2rem;
    color:#000000;
    font-weight:600;
   `,
   Slg : styled.p`
    color:#1e2024;
    font-size:1.4rem;
    font-weight: 400;;
   `,
   Next : styled.div`
    width:7.4rem;
    a{
      color:#586eed;
      font-size:1.4rem;
      text-decoration: underline;
      font-weight: 400;;
    }
    @media (max-width: 767px) {
        display: none;
    }

   `,


};

export default TodayLesson;

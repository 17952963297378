import styled from 'styled-components';

const StyleTab = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12.5em 0 7em;
    counter-reset: menu;
  `,

  Menu: styled.span`
    display: inline-block;
    position: relative;
    margin-right: 1em;
    padding-left: 1.8em;
    padding-right: 4.35em;
    color: #85878c;
    font-size: 1.6em;
    font-weight: 700;
    text-transform: uppercase;

    @media (max-width: 767px) {
      padding-left: 0;
      padding-right: 0;
      margin-right: 5em;
      padding-top: 4.33em;
      text-align: center;
    }

    &:last-of-type {
      padding-right: 0;
      @media (max-width: 767px) {
        margin-right: 0;
      }

      &::after {
        content: none;
      }
    }
    &.active {
      color: #fff;
      &::before {
        background-color: #485cc7;
      }
    }

    &::before {
      counter-increment: menu;
      content: '0' counter(menu);
      position: absolute;
      top: 40%;
      left: 0;
      width: 2.5em;
      height: 2.5em;
      margin-right: 1em;
      border-radius: 50%;
      background-color: #1e2024;
      line-height: 2.5em;
      text-align: center;
      color: inherit;
      font-size: 0.875em;
      transform: translate(-50%, -50%);
      @media (max-width: 767px) {
        left: 50%;
        width: 3.2em;
        height: 3.2em;
        margin-right: 0;
        line-height: 3.4em;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 1em;
      width: 2em;
      border-bottom: 1px #2b2e35 solid;
      transform: rotate(180deg);
      @media (max-width: 767px) {
        right: -3em;
      }
    }
  `,
};

export default StyleTab;

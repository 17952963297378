import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { UserStatus } from 'common/constant';

export default function useBlockLoginUser() {
  const history = useHistory();
  const status = useSelector(state => state.user.status);
  useEffect(() => {
    if (status === UserStatus.Login) {
      history.replace('/');
    }
  }, [status, history]);
}

import React, { useEffect } from "react";
import Styled from "./index.style";
import { IoIosArrowForward } from "react-icons/io";
import { ModalContext } from "component/Modal/modalContext";
import CreateAccount from "component/user/CreateAccount";
import { useDispatch, useSelector } from "react-redux";
import { VscClose } from "react-icons/vsc";
import { actions } from "common/state";
import { useAxios } from "common/hook/useAxiosHook";
import bannerApi from "api/banner";
import { useTranslation } from "react-i18next";
import useLoginStatus from "common/hook/useLoginStatus";

// import NoData from "container/UserHomePage/ActivityInsight/NoData";

import Login from "component/user/Login";
import { MOBILE_APP_USER_AGENT } from "common/constant";

const LineBanner = () => {
  const { t } = useTranslation("landingPage");
  const dispatch = useDispatch();
  const { handleModal } = React.useContext(ModalContext);
  const isBannerState = useSelector((state) => state.common.isBannerClose);
  const isLogin = useLoginStatus();

  const [{ data }] = useAxios(() => bannerApi.getBanner("LINE"), []);

  const handleClose = () => {
    const dispatchBannerParams = {
      state: false,
      bannerData: {
        linkUrl: data.linkUrl,
        content: data.content,
        buttonName: data.buttonName,
        isLoginOpen: data.isLoginOpen,
      },
    };
    document.body.classList.remove("line-banner-active");
    dispatch(actions.setBannerClose(dispatchBannerParams));
  };

  useEffect(() => {
    if (
      data &&
      isBannerState &&
      isBannerState.bannerData &&
      Object.entries(isBannerState.bannerData).toString() ===
        Object.entries(data).toString()
    ) {
      dispatch(actions.setBannerClose({ ...isBannerState, state: false }));
      document.body.classList.remove("line-banner-active");
    } else if (
      !data &&
      isBannerState && 
      !isBannerState.bannerData
    ){
      dispatch(actions.setBannerClose({ ...isBannerState, state: false }));
      document.body.classList.remove("line-banner-active");
    } else {
      dispatch(actions.setBannerClose({ ...isBannerState, state: true }));
      document.body.classList.add("line-banner-active");
    }
  }, [data]);

  const handleClick = (e) => {
    e.preventDefault();
    const { linkUrl, isLoginOpen } = data;
    const userAgent = window.navigator.userAgent;

    if (!isLoginOpen) {
      if (linkUrl && (linkUrl.includes("http") || linkUrl.includes("https"))) {
        if (userAgent === MOBILE_APP_USER_AGENT) {
          window?.WebInterface?.movetoAndroidBrowser(true, linkUrl);
        } else {
          window.open(`${linkUrl}`);
        }
      } else if (
        linkUrl &&
        (!linkUrl.includes("http") || !linkUrl.includes("https"))
      ) {
        if (userAgent === MOBILE_APP_USER_AGENT) {
          window?.WebInterface?.movetoAndroidBrowser(
            true,
            `https://${linkUrl}`
          );
        } else {
          window.open(`https://${linkUrl}`);
        }
      } else {
        return;
      }
    }

    if (isLoginOpen && isLogin) {
      // 로그인 배너인데 내가 로그인 상태
      // 로그인 배너인데 내가 로그인 상태고 UrlLink 가 있으면
      if (linkUrl && (linkUrl.includes("http") || linkUrl.includes("https"))) {
        window.open(`${linkUrl}`);
      } else if (
        linkUrl &&
        (!linkUrl.includes("http") || !linkUrl.includes("https"))
      ) {
        if (userAgent === MOBILE_APP_USER_AGENT) {
          window?.WebInterface?.movetoAndroidBrowser(
            true,
            `https://${linkUrl}`
          );
        } else {
          window.open(`http://${linkUrl}`);
        }
      } else {
        // 로그인 배너인데 내가 로그인 상태고 UrlLink가 없으면
        return;
      }
    }

    if (isLoginOpen) {
      // 로그인 배너인데 내가 로그인 상태가 아니면 Login Popup
      handleModal(<Login />, false, 420);
      return;
    }
  };

  if (!isBannerState.state) return null;

  return (
    <>
      {data?.content && (
        <Styled.Wrapper className="line-banner">
          <Styled.Link to="" onClick={(e) => handleClick(e)}>
            {data?.content
              ? data.content.split("<br>").map((line, index) => {
                  return (
                    <React.Fragment key={index}>
                      {line}
                      {index === 0 ? <br /> : null}
                    </React.Fragment>
                  );
                })
              : "Prepare the TOPIK Test (May 9th)! Go to TOPIK Special Live Lecture!"}
            <IoIosArrowForward />
          </Styled.Link>
          <Styled.Close onClick={handleClose}>
            <VscClose />
          </Styled.Close>
        </Styled.Wrapper>
      )}
    </>
  );
};

export default LineBanner;

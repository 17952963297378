import React, { useState, useEffect } from 'react';
import PageSubTitle from 'component/molecules/PageSubTitle';
import { SwiperSlide } from 'swiper/react';
import themesApi from 'api/theme';
import { useAxios } from 'common/hook/useAxiosHook';
import SwiperList from 'component/molecules/SwiperList';
import UserThemeItem from 'component/molecules/ThemeItem/UserThemeItem';
import { EmptyList } from 'component/atoms/EmptyList';
import Loading from 'component/atoms/Loading';
import { masterTopikUrl } from 'common/constant';
import SearchEmpty from 'component/atoms/SearchEmpty';
import { Trans, useTranslation } from 'react-i18next';

const SearchThemeContainer = ({ searchThemeWords, select }) => {
  const { t } = useTranslation('libraryPage');

  const [slideItems, setSlideItems] = useState([]);
  const [getSearchThemeList, refreshGetSearchThemeList] = useAxios(
    themesApi.getSearchThemes,
    [],
    true
  );
  const { loading, data } = getSearchThemeList;
  useEffect(() => {
    if (select !== 'Courses') {
      refreshGetSearchThemeList(searchThemeWords);
    }
  }, [searchThemeWords, select]);

  useEffect(() => {
    if (data && data.first) {
      setSlideItems([...data.content]);
    } else if (data && !data.first) {
      setSlideItems([...slideItems, ...data.content]);
    }
  }, [data]);

  const onNext = () => {
    if (!data.last) {
      refreshGetSearchThemeList(searchThemeWords, data.number + 1);
    }
  };
  if (loading) return <Loading />;

  return (
    <div>
      {slideItems.length === 0 && select === 'Themes' && (
        <SearchEmpty type='search' />
      )}

      {slideItems.length > 0 && (
        <>
          <PageSubTitle title={t('contentSection.section5.title')} />
          <SwiperList slideItemList={slideItems} onNext={onNext} id='myThemes'>
            {slideItems.map((item) => {
              const imgSrcList = item.themeProductResponse.map(
                (itemChild) =>
                  masterTopikUrl +
                  itemChild?.thumbnailPath +
                  itemChild?.thumbnailTitle
              );
              return (
                <SwiperSlide key={item.id}>
                  <UserThemeItem
                    id={item.id}
                    imgSrcList={imgSrcList}
                    title={item.name}
                    tag={item.categoryName}
                    videoCount={item.courseCount}
                    likeCount={item.likeCount}
                    isGood={item.isGood}
                    userData={false}
                  />
                </SwiperSlide>
              );
            })}
          </SwiperList>
        </>
      )}
    </div>
  );
};

export default React.memo(SearchThemeContainer);

import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';

const Layout = {
  Wrapper: styled.div`
    &:first-child h2 {
      margin-top: 0;
    }
  `,
  H2: styled.h2`
    margin: 2.5em 0 1em;
    font-size: 3.6em;
    font-weight: 700;
  `,
  TextP: styled.p`
    margin-bottom: 2.18em;
    color: #9a9ca1;
    font-size: 1.6em;
    font-weight: 300;
  `,
};

export default Layout;

import React, { useEffect, useState , useRef } from "react";
import Styled from "./styled/Mypage.style";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "component/user/state";
import { navMypage } from "../navData";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { deleteAllItem as deleteAllItemBook } from "container/BookStorePage/state/action";
import { deleteAllItem as deleteAllItemVideo, deleteApplication } from "container/PaymentPage/state/action";
import { actions as commonAction } from 'common/state';
import { ModalContext } from 'component/Modal/modalContext';
import imgUserNav from 'assets/images/gnb_new/user.png';
import makeAnimated from 'react-select/animated';
import { languageCode } from 'common/constant';
import { useAxios } from "common/hook/useAxiosHook";
import userApi from "api/user";
import curriculumsApi from 'api/curriculums';
import { RiContactsBookLine } from "react-icons/ri";
import plansApi from "api/plans";
import Img from "component/atoms/Img";
const animatedComponents = makeAnimated();

const options = [
  {
    name: languageCode.KOREAN.code.toUpperCase(),
    value: languageCode.KOREAN.locale,
    label: '한국어'
  },
  {
    name: languageCode.JAPANESE.code.toUpperCase(),
    value: languageCode.JAPANESE.locale,
    label: '日本語​'
  },
  {
    name: languageCode.CHINESE.code.toUpperCase(),
    value: languageCode.CHINESE.locale,
    label: '中国话​'
  },
  {
    name: languageCode.ENGLISH.code.toUpperCase(),
    value: languageCode.ENGLISH.locale,
    label: 'English'
  },
];


const Mypage = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeSub, isActiveSub] = useState(false);
  const [myPageMenu, setMyPageMenu] = useState(false);
  const containerRef = useRef(null);
  const [languageindex, setLanguageIndex] = useState(null);

  const user = useSelector((state) => state.user.data );
  const token = useSelector((state) => state.user.token);
  const userPlan = user.serviceLevel;

  const [membershipLevel, setMembershipLevel] = useState(0);
  // 무료 체험 사용 x & Free 회원 : 0
  // 무료 체험 사용 o & Free 회원 : 1
  // 무료 체험 사용 & Standard/Premium 회원 : 2


  const [
    {data : userCurriculums} ,
    getCurriculums
  ] = useAxios(curriculumsApi.getMyList,[],true);

  const [
    { data: updateLanguageStatus },
    refreshUpdateLanguage
  ] =useAxios(userApi.updateUserLanguage,[],true);

  const [
    { loading: freeTrialLoading, data: freeTrialData },
    getFreeTrial
  ] = useAxios(plansApi.freeTrial, [], true);

  const cleanCartItem = () => {
    // bookStore 장바구니 지우기
    dispatch(deleteAllItemBook());
    dispatch(deleteAllItemVideo());
    dispatch(deleteApplication());
    dispatch(commonAction.setLastPayment(''));
  };

  const onLogout = (e) => {
    e.preventDefault();
    cleanCartItem();
    dispatch(actions.fetchLogout());
    history.push("/");
  };

  const onChangeLanguage = (e) => {
    refreshUpdateLanguage({token, language : e.name });
    const newIndex = options.findIndex(({ name }) => {
      return name === e.name;
    });
    setLanguageIndex(newIndex);
  };

  useEffect(() => {
    if (user.selectLanguage){
      const defaultIndex = options.findIndex(({ name }) => {
        return name === user.selectLanguage;
      });
      setLanguageIndex(defaultIndex);
      console.error(`select : ${user.selectLanguage} , idx: ${defaultIndex}`);
    }
  }, [user.selectLanguage]);


  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setMyPageMenu(!myPageMenu)
    }
  };

  useEffect(() => {
    setMyPageMenu(false)
  }, [location]);


  const mypageHandler = () =>{
    setMyPageMenu(!myPageMenu)
  }

  useEffect(() => {
    if(myPageMenu){
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [myPageMenu]);

  useEffect(() => {
    if (updateLanguageStatus !== 200) return;
    dispatch(actions.fetchUser(token));
  }, [dispatch, token, updateLanguageStatus]);

  useEffect(()=> {
    getCurriculums(token);
    getFreeTrial("PREMIUM",token);
  },[]);



  useEffect(()=>{
    if(freeTrialData && userPlan){
      if (userPlan === 'PREMIUM' || userPlan === 'STANDARD') {
        setMembershipLevel(2);
      } else if (freeTrialData.isFreeTrial === 'N') {
        setMembershipLevel(1);
      } else {
        setMembershipLevel(0);
      }
    }
  },[freeTrialData, userPlan]);

  const handleActive = () => isActiveSub(!activeSub);

  return (
    <Styled.Container className={` ${myPageMenu ? 'active' : ''}`}>
      <Styled.Button onClick={mypageHandler}><Img src={user.profilePhotoPath?  user.profilePhotoPath  : imgUserNav} alt="mypage" onError={imgUserNav}/></Styled.Button>
      <Styled.List ref={containerRef}>
      <Styled.NavHead>
              <Styled.Close onClick={mypageHandler} />
        </Styled.NavHead>
        {/*
          (Free 회원) https://www.mastertopik.com/plan
          (구독중) https://www.mastertopik.com/setting
       */}
          <div className="my-trial">
            {
              membershipLevel === 0?
              <Styled.Link to="/plan"><span>{user.fullName}</span>, Start your free trial today!</Styled.Link>:
              membershipLevel === 1?
              <Styled.Link to="/plan"><span>{user.fullName}</span>, Restart learning Korean!</Styled.Link> :
              <Styled.Link to="/setting">My plan: <span>{userPlan}</span></Styled.Link>
            }

          </div>
          <div className="my-curr">
            {(userCurriculums && userCurriculums.id)? <Styled.Link to="/classroom/curriculum">Curriculum: <span>{userCurriculums.title}</span></Styled.Link>
            : <Styled.Link to="/classroom/curriculum"><span>Get your Korean, TOPIK course recommendation.</span></Styled.Link> }

          </div>
          <Styled.MyList>
          {navMypage.map((item, index) => (
          <Styled.Item key={index}>
            {!item.subList ? (
                <Styled.Link to={item.url}>{item.name}</Styled.Link>
            ) : (
              <>
                <span className={`setting  ${activeSub ? 'active' : ''}`} onClick={handleActive}>{item.name}
                  <svg
                    width="8px" height="5px">
                    <path fill-rule="evenodd"  fill="#1e2024"
                    d="M-0.000,4.1000 L7.1000,4.1000 L3.1000,0.000 L-0.000,4.1000 Z"/>
                  </svg>
                </span>
                <div className={`submenu ${activeSub ? 'active' : ''}`}>
                  {item.subList.map((subItem)=> <Styled.Link to={subItem.url}>{subItem.name}</Styled.Link> )}
                </div>
              </>
            )}
          </Styled.Item>
        ))}
         <Styled.Item>
          <Styled.Link to="#none" onClick={onLogout} className='logout'>
              Logout
            </Styled.Link>
          </Styled.Item>
          </Styled.MyList>

          <Styled.StyledSelect
              clasName="select-box"
              options={options}
              components={animatedComponents}
              onChange={(e) => onChangeLanguage(e)}
              value={options[languageindex]}
              maxMenuHeight = {160}
              menuPlacement = {window.innerHeight >= 750? 'auto' : 'top'}

            />
      </Styled.List>



    </Styled.Container>
  );
};

export default Mypage;


{/* <svg
 width="8px" height="5px">
<path fill-rule="evenodd"  fill="rgb(72, 92, 199)"
 d="M-0.000,4.1000 L7.1000,4.1000 L3.1000,0.000 L-0.000,4.1000 Z"/>
</svg> */}
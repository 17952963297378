import React from 'react';
import { CardNew } from 'container/LevelTestPage/styled';
import { planColors } from 'common/constant';
import bgImage from 'assets/images/temp/bg_curriculum.jpg';
import { GoPlus } from 'react-icons/go';
import { Link } from 'react-router-dom';

const CurriculumCarNew = ({ item, setChecked, checked }) => {
  const {
    curriculumChargeId,
    thumbnailPath,
    serviceGrade,
    title,
    description,
  } = item;
  return (
    <CardNew.Wrapper>
      <CardNew.ImgBox
        className={
          checked.curriculumChargeId === curriculumChargeId ? 'selected' : ''
        }
        onClick={() => {
          setChecked({
            ...checked,
            curriculumChargeId,
            serviceGrade,
          });
        }}
      >
        <img src={thumbnailPath ? thumbnailPath : bgImage} alt='curriculum' />
        {/* <CardNew.Badge
          bgColor={planColors[serviceGrade ? serviceGrade : 'FREE'].color}
        >
          {serviceGrade}
        </CardNew.Badge> */}
      </CardNew.ImgBox>
      <CardNew.Content>
        <Link
          to={`/classroom/curriculum/${curriculumChargeId}`}
          target='_blank'
        >
          <CardNew.Title line={2}>{title}</CardNew.Title>
          {/* <CardNew.Infos>
            <CardNew.Info>대상 : 레벨 3 중급 </CardNew.Info>
            <CardNew.Info>학습 : TOPIK 3-4급 준비를 위한 학습 </CardNew.Info>
          </CardNew.Infos> */}
          <CardNew.Desc line={2} style={{whiteSpace : "pre-wrap"}}>{description}</CardNew.Desc>
          <CardNew.PlusBtn />
        </Link>
      </CardNew.Content>
    </CardNew.Wrapper>
  );
};

export default CurriculumCarNew;

import styled from "styled-components";
import { Swiper } from 'swiper/react';
import visualBgImg from 'assets/images/event/220321/visual_bg.jpg';
import visualImg from 'assets/images/event/220321/visual.jpg';
import visualLargeImg from 'assets/images/event/220321/visual_large.jpg';
import visualImgKo from 'assets/images/event/220321/ko/visual.jpg';
import visualLargeImgKo from 'assets/images/event/220321/ko/visual_large.jpg';
import cursorIcon from 'assets/images/event/220321/ico_cursor.png';
import titleBgImg from 'assets/images/event/220321/bg_title.png';
import titleBgImg2 from 'assets/images/event/220321/bg_title2.png';
import lecturerTabImg from 'assets/images/event/220321/tab_lecturer1.png';
import lecturerTabOnImg from 'assets/images/event/220321/tab_lecturer1_on.png';
import lecturerTabImg2 from 'assets/images/event/220321/tab_lecturer2.png';
import lecturerTabOnImg2 from 'assets/images/event/220321/tab_lecturer2_on.png';
import lecturerTabLargeImg from 'assets/images/event/220321/tab_lecturer1_large.png';
import lecturerTabOnLargeImg from 'assets/images/event/220321/tab_lecturer1_on_large.png';
import lecturerTabLargeImg2 from 'assets/images/event/220321/tab_lecturer2_large.png';
import lecturerTabOnLargeImg2 from 'assets/images/event/220321/tab_lecturer2_on_large.png';
import lecturerTabImgKo from 'assets/images/event/220321/ko/tab_lecturer1.png';
import lecturerTabOnImgKo from 'assets/images/event/220321/ko/tab_lecturer1_on.png';
import lecturerTabImg2Ko from 'assets/images/event/220321/ko/tab_lecturer2.png';
import lecturerTabOnImg2Ko from 'assets/images/event/220321/ko/tab_lecturer2_on.png';
import lecturerTabLargeImgKo from 'assets/images/event/220321/ko/tab_lecturer1_large.png';
import lecturerTabOnLargeImgKo from 'assets/images/event/220321/ko/tab_lecturer1_on_large.png';
import lecturerTabLargeImg2Ko from 'assets/images/event/220321/ko/tab_lecturer2_large.png';
import lecturerTabOnLargeImg2Ko from 'assets/images/event/220321/ko/tab_lecturer2_on_large.png';
import lecturerTabIco1 from 'assets/images/event/220321/ico_lecturer_tab1.png';
import lecturerTabIco2 from 'assets/images/event/220321/ico_lecturer_tab2.png';
import arrowLeft from 'assets/images/event/220321/arrow_left.png';
import arrowRight from 'assets/images/event/220321/arrow_right.png';
import payCardImg from 'assets/images/event/220321/ico_card.png';
import payBankImg from 'assets/images/event/220321/ico_bank.png';
import stepIcon from 'assets/images/event/220321/ico_step.png';
import linkIcon from 'assets/images/event/220321/bl_arrow.png';
import linkIcon2 from 'assets/images/event/220321/bl_arrow2.png';

const StyledEventPage = {
  SwiperWrap: styled.div`
    margin-top: 2em;
  `,
  Swiper: styled(Swiper)`
    position: relative;
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      z-index:10;
    }
    .swiper-button-prev {
      left:0;
    }
    .swiper-button-next {
      right: 0;
    }
  `,
  thumb: styled.div`
    z-index:10;

    &.hidden-poster {
      display:none;
    }
  `,
  EventWrap: styled.div`
		font-size: 2rem;
    line-height: 1.2;
    background:#fff;
    font-weight:700;
    text-align:center;
    color:#555;
    letter-spacing: -0.035em;

		.blind {
			overflow:hidden;
			position: absolute;
			clip:rect(0 0 0 0);
			width:1px;
			height:1px;
			margin:-1px;
		}

    .inner {
      margin: auto;
    }

    h2 {
      padding: 5em 0 2.5em;
      span {
        background: url(${titleBgImg}) 0 85% repeat-x;
        font-size: 3.6rem;
        color:#0c1c4d;
        letter-spacing: -0.03em;
        font-weight:700;
      }
    }

    .topBanner {
      background:#00e6e1;
      padding: 0.9em;
      color:#15349e;
      font-size: 2.2rem;
      line-height:1.7;
      strong {
        display:inline-block;
        margin: 0 1.2em 0 0.4em;
        border-top:1px #1b2a6d solid;
        border-bottom:1px #1b2a6d solid;
        color:#1b2a6d;
				&:first-child {
					margin-left: 0;
				}
      }
			.desc {display: inline-block;}
      .btn {
				display: inline-block;
        background:#15349e;
				margin-left: 3em;
        padding: 0.4em 2em;
        border-radius: 1.4em;
        color:#00e6e1;
        font-size: 2rem;
        white-space:nowrap;
				span {
					font-weight: 400;
				}
      }
    }

    .visual {
      position:relative;
      background-color: #071f4f;
			background-image: url(${visualImg});
			background-repeat: no-repeat;
			background-position: 50% 0;
			background-size: auto 100%;
			.inner {
				position:relative;
				box-sizing: border-box;
				width: 1400px;
				height: 710px;
				margin: 0 auto;
				padding-top: 458px;
				padding-left: 50px;
				text-align: left;
				z-index:2;
      	.btnWrap a {
          display:inline-block;
					padding: 2%;
          background:#ffde00;
          color:#15349e;
          cursor: url(${cursorIcon}), pointer;
					font-weight: 700;
          font-size: 3.0rem;
          line-height: 1em;
					span {
						font-weight: 400;
						font-size: 0.7em;
					}
        }
      }
    }
		.visual.ko {
			background-image: url(${visualImgKo});
		}

    .section1 {
      background: #ffffff;
      padding-bottom: 6em;
      .inner {
        max-width: 1300px;
      }
      .dsc {
        margin-bottom: 4em;
        color:#5c596d;
        font-size: 1.8rem;
        font-weight:400;
      }

      .lecturer {
				.tab {
					--tabMargin : -10px;
					--tabHeight : 80px;
					--tabAfter : 20px;
					--tabArrow : calc(var(--tabHeight)/2) ;

					position: relative;
					display:flex;
					padding-top: calc(var(--tabMargin) * -1);
					height: var(--tabHeight);
					li {
						flex:1 0 auto;
						position: relative;
						width: calc(50% - var(--tabAfter));
						background-color: #1b2a6d;
						a {
							position: absolute;
							margin-top: var(--tabMargin);
							display:block;
							width: 100%;
							max-width : calc(375px - var(--tabAfter));
							height: var(--tabHeight);
							font-weight: 300;
							font-size: 2.4rem;
							color: #11f6d9;
							padding-top: calc(var(--tabHeight) - 1.5em);
							background-repeat : no-repeat;
							background-size: auto var(--tabHeight);
							&:after {
								content: "";
								display: block;
								position: absolute;
								width: 0;
								height: 0;
								top: 0;
							}
						}
						&:first-child {
							a {
								right : 0;
								text-align: left;
								padding-left: var(--tabHeight);
								background-image: url(${lecturerTabIco1});
								background-position: 0 0;
								&:after {
									right: 0;
									border-top: var(--tabArrow) solid transparent;
									border-left: var(--tabArrow) solid transparent;
									border-right: var(--tabArrow) solid #34d2b7;
									border-bottom: var(--tabArrow) solid #34d2b7;
								}
							}
						}
						&:last-child {
							a {
								left : 0;
								text-align: right;
								padding-right: var(--tabHeight);
								background-image: url(${lecturerTabIco2});
								background-position: 100% 0;
								&:after {
									left: 0;
								border-top: var(--tabArrow) solid transparent;
								border-left: var(--tabArrow) solid #34d2b7;
								border-right: var(--tabArrow) solid transparent;
								border-bottom: var(--tabArrow) solid #34d2b7;
								}
							}
						}
						&.active {
							width: calc(50% + var(--tabAfter));
							height: var(--tabHeight);
							margin-top: var(--tabMargin);
							background-color: #34d2b7;
							a {
								max-width : calc(375px + var(--tabAfter));
								line-height: var(--tabHeight);
								margin-top: 0;
								font-weight: 700;
								font-size: 3.4rem;
								color : #fff;
								text-shadow:  1px 1px rgba(0, 0, 0, 0.3);
								background: none;
								padding: 0;
								&:after {
									display:none;
								}
							}
						}
					}
				}
        .tabContent {
					max-width: 750px;
					margin: 0 auto;
          p {
            margin: 2em 0 1em;
            font-size: 2.4rem;
            color:#000;
          }
          .playerWrap {
            position:relative;
            width: 100%;
            padding-top:56%;

            > div {
              position:absolute;
              top:0;
              left:0;
            }
          }
          .tag {
            margin-top:1em;
            text-align:left;
            display:flex;
            flex-direction: row;
            flex-wrap: wrap;
            li {
              margin: 0 0.6em 0.4em 0;

              span {
                display:inline-block;
                background:#34d2b7;
                padding: 0.2em 0.5em;
                border-radius: 1em;
                color:#15349e;
              }
            }
          }
        }
        .block {
          display:block;
        }
        .hide {
          display:none;
        }
      }
    }

    .section2 {
      background:#f8f8f8;
      padding-bottom: 6em;

      .inner {
        max-width: 1340px;
        padding: 0px 20px;
      }
      .benefitSwiperTab {
        .swiper-wrapper {
          display:flex;
          align-items: flex-end;
        }
        .swiper-slide {
          flex:1;
          background:#1b2a6d;
          height: 5em;
          padding-top:1.8em;
          color:#f8f8f8;
          cursor: pointer;
          white-space:nowrap;
          em {
            display:inline-block;
            color:#fdc13e;
            border-bottom:3px #fdc13e solid;
            vertical-align:top;
          }
          strong {
            display:none;
          }
        }
        .swiper-slide-thumb-active {
          flex:6;
          height: 5.6em;
          background:#0da0e4;
          padding-left:2em;
          padding-top:1.9em;
          color:#1b2a6d;
          text-align:left;
          em {

          }
          strong {
            display:inline-block;
            margin-left:1em;
            letter-spacing: -0.045em;
            font-size: 2.4rem;
          }
          br {
            display:none;
          }
        }
      }
      .benefitSwiper {
        margin-top:3em;
        .swiper-button-prev,
        .swiper-button-next {
          position: absolute;
          top:50%;
          width:3.334em;
          height:2.889em;
          margin-top: -1.44em;
          cursor:pointer;
          background-position: 0 0;
          background-repeat:no-repeat;
          background-size:contain;
					&:after {
						display:none;
					}
        }
        .swiper-button-prev {
          background-image: url(${arrowLeft});
        }
        .swiper-button-next {
          background-image: url(${arrowRight});
        }
        .swiper-slide {
          display:flex;
          align-items: center;

          .imgbox {
            flex:1;
            padding-left:3em;
          }
          .text {
            flex:1;
            padding-right:3em;
            font-size:4rem;
            color:#000000;
            font-weight:400;
            strong {
              font-weight:700;
            }
            P {
              margin-top:2em;
              font-size: 2.2rem;
              color:#5c596d;
              letter-spacing: normal;
            }
          }
        }
      }
    }

    .section3 {
      background:#34d2b7;
      h2 {
        span {
          color:#15349e;
          background: url(${titleBgImg2}) 0 85% repeat-x;
        }
      }
      .inner {
        max-width: 1300px;
      }
      ul {
        display:flex;

        li {
          flex:1;
          position:relative;
          background:#90e0db;
          padding: 2em 3.5em 1.8em 3.5em;
          color:#1b2a6d;
          .top {
            h3 {
              padding-bottom:0.6em;
              color:#1b2a6d;
              font-size:2.8rem;
              font-weight:700;
              border-bottom:4px #04a3a5 solid;
              margin-bottom:1.2em;
            }
            p {
              font-size: 2.4rem;
            }
          }
          &:nth-of-type(even) {
            background:#ebeeff;
            h3 {
              border-bottom:4px #34d2b7 solid;
            }
          }
          .lecturer {
						min-height: 10em;
            &:before {
              content:"";
              display:inline-block;
              width:1.4em;
              height:2px;
              background:#485cc7;
            }
            color:#15349e;
            strong {
              display:block;
              margin:0.8em 0 0.2em 0;
							font-size: 2.2rem;
            }
            > div {
              font-weight:400;
              line-height:1.5;
							font-size:1.6rem;
            }
          }
          .price {
            margin:1.6em 0 0 0;
            white-space:nowrap;

            > p {
              display:flex;
              justify-content: space-between;
              align-items: flex-end;
            }
            .discount {
              strong {
                flex:1;
                text-align:left;
                color:#f21490;
                font-size:2rem;
                em {
                  font-size:2.8rem;
                }
              }
              del {
                flex:1;
                text-align:right;
                color:#7a7a7a;
                font-weight:400;
              }
            }
            .total {
              margin-top:0.5em;
              align-items: flex-start;
              span {
                color:#000000
              }
              strong {
                color:#1b2a6d;
                font-size:2.8rem;
                letter-spacing: -0.07em;
              }
            }
          }
          .btn {
						margin-top: 1em;
            a.btn_apply {
              display:block;
              background:#485cc7;
              padding: 1em 0;
              color:#fff;
              font-size:2.4rem;
            }
						a.btn_pdf {
							display:inline-block;
							margin-top: 0.8em;
							border-bottom: 1px solid #1b2a6d;
							font-weight: 400;
							font-size: 1em;
							color: #1b2a6d;
							padding-right: 0.7em;
							background: url(${linkIcon}) no-repeat 100% 50%;
							background-size: 0.4em auto;
						}
          }
        }
      }
    }

    .section4 {
      background:#ebeeff;
      text-align:left;
      .inner {
        max-width:1300px;
        padding: 3em 5em;
      }
      table th,
      table td {
        padding:0.4em 0;
      }
      table th {
        width:25%;
      }
      table th span {
        display:block;
        padding:0.2em 0;
        border:2px #485cc7 solid;
        border-radius:1em;
        color:#485cc7;
        text-align:center;
      }
      table td {
        width:80%;
        padding:0.4em 0 0.4em 2em;
        color:#5c596d;
        font-weight:400;
        font-size:2rem;

        ul li {
          position:relative;
          padding-left:0.6em;
          &:before {
            content:"";
            display:inline-block;
            position: absolute;
            top:0.5em;
            left:0;
            width: 4px;
            height: 4px;
            border-radius: 4px;
            background:#5c596d;
            vertical-align:middle;
          }
        }
				span {
					color:#8a8a8a;
				}
      }
    }

		.banner {
      background:#8b93aa;
      padding: 1em;
      color:#fff;
			font-weight: 400;
      font-size: 2.6rem;
      line-height:1.6;
			.inner {
				display: block;
				max-width: 1000px;
				margin: 0 auto;
				overflow: hidden;
			}
			.desc {
				float: left;
			}
      .btn {
				float: right;
        padding: 0.2em 1.2em;
				border: 1px solid #fff;
        border-radius: 1.5em;
        color:#fff;
        font-size: 2rem;
        white-space:nowrap;
				span {
					padding-right: 1em;
					background: url(${linkIcon2}) no-repeat 100% 50%;
					background-size: 0.4em auto;
				}
      }
    }

    .section5 {
      background:#fff;
      padding-bottom: 4em;
      .inner {
        max-width:1340px;
        padding: 0 20px;
      }
      ol {
        display:flex;
        margin-top:1em;
        li {
          flex:1;
          position:relative;
          color:#15349e;

          &:before {
            content:"";
            position:absolute;
            top:50%;
            right:-1.2em;
            background: url(${stepIcon}) 0 0 no-repeat;
            background-size:contain;
            width: 2.778em;
            height:0.723em;
            margin-top:-2em;
          }

          &:last-child:before {
            display:none;
          }

          em {
            padding: 0 0.6em;
            border:0.12em #15349e solid;
            border-radius:1em;
          }

          h3 {
            margin: 0.8em 0 1.4em;
            font-weight:700;
            color:#1b2a6d;
            font-size:2.8rem;
          }
          p {
            margin: 2em 0 3em;
            font-size:2.2rem;
          }
        }
      }
    }

		.payment {
      background:#ebeeff;
      text-align:left;

      .inner {
        max-width:1300px;
        margin: auto;
        padding: 3.6em 4em;
        h3 {
          color:#0c1c4d;
          font-size:2.4rem;
          font-weight:700;
          margin-bottom: 0.8em;
        }
				ul.desc {
					font-weight: 400;
					li {
						position:relative;
						padding-left:0.6em;
						&:before {
							content:"";
							display:inline-block;
							position: absolute;
							top:0.5em;
							left:0;
							width: 4px;
							height: 4px;
							border-radius: 4px;
							background:#5c596d;
							vertical-align:middle;
						}
					}
				}
        ul.way {
          display:flex;
					padding-top : 1.6em;
          > li {
            flex:1;
            padding-left:6.77em;
            background-repeat:no-repeat;
            background-position:0 0.4em;
            background-image: url(${payCardImg});
						background-size: auto 4.7em;

            &:last-child {
              background-image: url(${payBankImg});
            }
            h4 {
              color:#15349e;
              font-size:1.6rem;
              text-decoration:underline;
              line-height:1.8;
              font-weight:700;
              margin-bottom:0.4em;
            }
						p {
							margin-bottom:0.4em;
							font-size: 2rem;
							color:#1c1332;
							span {
								color:#f1148f;
							}
						}
						h5 {
							font-weight: 400;
							font-size: 1.8rem;
							color: #1c1332;
						}
						li {
							font-weight: 400;
						}
          }
        }
      }
    }

    .shareWrap {
      background:#5cecd3;
      .inner {
        max-width: 870px;
        display:flex;
        align-content: space-between;
        align-items: center;
        padding: 1em 20px;
        text-align:left;
        font-size: 2.6rem;
        font-weight:400;
        color:#1b2a6d;
        &:after {
          content:"";
          display:block;
          clear:both;
        }
        strong {
          display:inline-block;
          font-weight:700;
        }
        .btn {
          display:block;
          flex:1 1 0;
          text-align:right;
          a {
            display:inline-block;
            width:5.5em;
            height:2.154em;
            img {
              width:100%;
            }
          }
        }
      }
    }

    .notification {
      background:#1b2a6d;
      text-align:left;
      padding: 4em 0;
      color:#fff;
      font-weight:300;
      letter-spacing:normal;
      .inner {
        max-width: 870px;
        padding: 0 20px;
      }
      h3 {
        font-size:2.8rem;
        font-weight:700;
      }

      dt {
        padding: 1.6em 0 0.8em;
        font-weight:700;
      }
      dd {
        position:relative;
        padding-left:0.8em;
        margin-bottom: 0.5em;
        &:before {
          content:"";
          display:inline-block;
          position: absolute;
          top:0.5em;
          left:0;
          width: 0.2em;
          height: 0.2em;
          border-radius: 0.1em;
          background:#fff;
          vertical-align:middle;
        }
        em {
          color:#fff799;
        }
        a {
          text-decoration:underline;
        }
      }
      P {
        padding: 1.6em 0 0.8em;
        a {
          text-decoration:underline;
        }
      }
    }

    @media (max-width: 1400px) {
      .visual {
        .inner {
					width: 100%;
					height: 50.7vw;
          padding-top: 32.7vw;
					padding-left: 3vw;
          .btnWrap a {
            font-size: 2.14vw;
          }
        }
      }
    }

    @media (max-width: 1024px) {
      h2 {
        padding: 3.6em 0 1.8em;
      }
      .visual {
				background-image: url(${visualLargeImg});
        .inner {
					width: 100%;
					height: 144vw;
					padding-top: 115vw;
          padding-left: 0;
					text-align: center;
          .btnWrap a {
            padding: 4vw 6vw;
						font-size: 4.3vw;
          }
        }
      }
			.visual.ko {
				background-image: url(${visualLargeImgKo});
			}

      .section1 {
        padding-bottom:4em;
        .lecturer {
					.tab {
						--tabMargin : -1.25vw;
						--tabHeight : 10vw;
						--tabAfter : 2.5vw;
					}
        }
      }

      .section2 {
        padding-bottom:4em;
      }

      .section3 {
        ul {
          flex-direction: column;
          li {
            padding: 2em 20px 2em 20px;
            margin-bottom:2em;
            background:#ebeeff;
            .btn {
              clear : both;
            }
            .top {
              text-align:left;
              h3 {
								margin-bottom:0.6em;
                border-bottom:3px #04a3a5 solid;
								font-size:2.4rem;
              }
              .teacher {
                font-size:2.8rem;
              }
            }
            .lecturer {
							min-height: auto;
              text-align:left;
              &:before {
                display:none;
              }
            }
            .price {
							text-align:right;
							float: right;
              margin: 0 0 1em 0;
            }
						.discount {
							strong {
								margin-right:2em;
							}
						}
            .total {
              span {
                margin-right:2em;
              }
            }
          }
        }
      }

			.banner {
				text-aling: center;
				.desc {
					float: none;
				}
				.btn {
					float:  none;
					display: inline-block;
					margin-top: 0.6em;
				}
			}

      .section5 {
        .inner {
          padding:0 4.6em;
        }
        ol {
          flex-direction: column;

          li {
            flex-direction: column;
            min-height:9em;
            margin-top:2em;
            text-align:left;
            &:first-child {
              margin-top:0;
            }
            &:before {
              display:none;
            }
            h3 {
              margin: 0.6em 0;
            }
            p {
              margin:0;
            }
            img {
              position:absolute;
              top:0;
              right:0;
              width:9em;
            }
          }
        }
      }

			.payment {
        .inner {
          padding: 2em 4.6em;
        }
      }
    }

    @media (max-width: 768px) {
			.topBanner {
				padding: 0.8em;
				line-height: 1.3;
				font-size: 2.4rem;
				strong {
					margin: 0 0 0.4em 0;
					font-size: 2rem;
				}
				.desc {
					display:block;
				}
        .btn {
          display:none;
        }
      }
     .section1 {
        .inner {
          max-width: 100%;
        }
        .lecturer {
					.tab {
						li {
							a {
								width: calc(100% - 20px);
								font-size: 3vw;
							}
							&.active {
								a {
									text-align: center;
									font-size: 4.2vw;
								}
							}
						}
					}
          .tabContent {
            padding: 0 20px;
          }
        }
      }

      .section2 {
        padding-bottom: 4em;

        .benefitSwiperTab {
          .swiper-slide {
            em {
              border-bottom:3px #fdc13e solid;
            }
          }
          .swiper-slide-thumb-active {
            padding-left:1.2em;
            padding-top:1.2em;
            strong {
              margin-left:0.6em;
            }
            br {
              display:block;
            }
          }
        }

        .benefitSwiper {
          .swiper-slide {
            flex-direction: column;
            .imgbox {
              padding:0 4em;
            }
            .text {
              padding-top: 1em;
              padding-right:0;
              P {
                margin-top:2em;
              }
            }
          }
        }
      }
      .section4 {
        .inner {
          padding:3em 20px;
        }
        table th {
          width:25%;
        }
        table td {
          width:75%;
          padding:0.4em 0 0.4em 1em;
        }
      }
      .section5 {
        .inner {
          padding:0 20px;
        }
      }
			.payment {
        .inner {
          padding: 2em 20px;

          ul.way {
            flex-direction: column;
            > li {
              background-size: 4.4em auto;
              &:first-child {
                margin-bottom:1em;
              }
            }
          }
        }
      }
    }

    @media (max-width: 550px) {
      h2 {
        span {
          font-size: 2.8rem;
        }
      }
      .section4 {
        table th {
          width:37%;
        }
        table td {
          width:63%;
        }
      }
    }
  `,
};

export default StyledEventPage;

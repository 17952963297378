import React from 'react';
import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import mtimg from 'assets/images/event/211008/mt.png';
import mailimg from 'assets/images/event/211008/mail.png';
import downloadimg from 'assets/images/event/211008/download.png';
import cont3img from 'assets/images/event/211008/cont3.jpg';
import linkimg from 'assets/images/event/211008/link.png';
import contimg from 'assets/images/event/211008/cont.jpg';
import bgimg from 'assets/images/event/211008/bg.jpg';
import cont2img from 'assets/images/event/211008/cont2.jpg';
import cont4img from 'assets/images/event/211008/cont4.jpg';
import { toast } from 'react-toastify';

const Style = styled.div`
.event211008 {margin-top: -15.5rem}
.event211008 > div {position: relative;text-align: center;}
.event211008 .section1 {background: #141035}
.event211008 .section1 h1 {position: absolute; top:10.2%; left:0; width: 100%;}
.event211008 .section1 .video {position: absolute; top:44%; left:50%; margin: 0 0 0 -375px;width: 750px;}
.event211008 .section1 .video .video_content {position: relative; width: 100%; height: auto; padding-top: 56%;}
.event211008 .section1 .video .video_content iframe {position: absolute;top:0;left:0;width: 100%;height: 100%;z-index: 1;}

.event211008 .section2 {background: url(${bgimg}) 50% 0 repeat-x;}
.event211008 .section2 .inner {background:  url(${cont2img}) 50% 0 no-repeat; background-size:contain; margin: 0 auto}
.event211008 .section2 .mail {position: absolute; top:46.3%; width: 100%;}
.event211008 .section2 .download {position: absolute; top:63.6%; left:50%; width: 750px;margin: 0 0 0 -375px;text-align: right;}

.event211008 .section3 {background-color: #141035;}
.event211008 .section4 {background: #d02da7}
.event211008 .section4 .link {position: absolute; width: 750px; top:33%; left:50%; margin: 0 0 0 -375px; text-align: right;}

.event211008 .section5 {background-color: #0f0f0f; padding: 70px 0; font-size:1.75em}
.event211008 .section5 h2 {font-size:2em; color: #d9d9d9;}
.event211008 .section5 dl {width: 750px; margin:auto;text-align: left; color: #9c9b9b;}
.event211008 .section5 dl dt {margin-top: 25px;color: #b4b4b4; font-weight: 700; font-size:1.2em}
.event211008 .section5 dl dd {position: relative; padding-left: 10px;}
.event211008 .section5 dl dd:before {content:""; display: inline-block; position: absolute; top:12px; left:0;width: 2px; height: 2px; background-color: #9c9b9b; vertical-align: middle;}
.event211008 .section5 dl dd.first {padding-left: 0;}
.event211008 .section5 dl dd.first:before {display: none;}
.event211008 .section5 dl dd a {text-decoration: underline;}

@media (max-width: 900px) {
  .event211008 .section1 h1 img {width: 25%;}
  .event211008 .section1 .video {width: 100%; left:0; margin:0; padding: 0 8%;}
  .event211008 .section2 .mail img {width: 52%;}
  .event211008 .section2 .download {top:63.5%; left:auto; right:8%; width: 32%;margin: 0}
  .event211008 .section4 .link {width: 100%; left: 0; margin: 0; padding-right: 9%;}
  .event211008 .section4 .link img {width: 6%;}
  .event211008 .section5 dl {width: 100%; padding: 0 5%;}
}
`;

const EventPage = () => {

  const doCopy = text => {
    // 흐름 1.
    if (!document.queryCommandSupported("copy")) {
      return toast.error("Unsupported browser.");
    }

    // 흐름 2.
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.position = "fixed";

    // 흐름 3.
    document.body.appendChild(textarea);
    // focus() -> 사파리 브라우저 서포팅
    textarea.focus();
    // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
    textarea.select();
    // 흐름 4.
    document.execCommand("copy");
    // 흐름 5.
    document.body.removeChild(textarea);
    //alert("Copying is complete.");
    toast.info("Copying is complete.");
  };


  return (
     <Style>

      <div class="event211008">
        <div class="section1">
          <h1><a href="https://www.mastertopik.com/" target="_blank"><img src={mtimg} alt="masterTOPIK" /></a></h1>
          <div class="video">
            <div class="video_content">
              <iframe width="817" height="460" src="https://www.youtube.com/embed/ioS8Eq5cb1A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </div>
          <img src={contimg} alt="" />
        </div>

        <div class="section2">
          <div class="inner">
            <div class="mail">
              <a href="mailto:mastertopik@visang.com"><img src={mailimg} alt="Send my Hangeul Song video" /></a>
            </div>

            <div class="download">
              <a href="https://dlvisang01.mastertopik.com/MasterTOPIK/MT_Hangeul Song.zip" target="_blank"><img src={downloadimg} alt="Download slow ver. Hangeul Song" /></a>
            </div>
          </div>
		  <img src={cont2img} alt="" />
        </div>

        <div class="section3">
          <img src={cont3img} alt="" />
        </div>

        <div class="section4">
          <div class="link">
            <a href="javascript:;" onClick={() => doCopy("http://www.mastertopik.com/event/hangeulsong")} ><img src={linkimg} alt="Share the Hangeul Song Cover Contest to your friends!" /></a>
          </div>
			<img src={cont4img} alt="" />
        </div>

        <div class="section5">
          <h2>Notification</h2>
          <dl>
            <dt>Contact Information</dt>
            <dd class="first"><a href="mailto:mastertopik@visang.com">mastertopik@visang.com</a></dd>

            <dt>Video Submission Format</dt>
            <dd>Cover song Genre: Song(solo/team, both available), No restriction on choreography and genre</dd>
            <dd>Video Submission Format: mp4, avi, mkv format available</dd>

            <dt>Video Submission Notice</dt>
            <dd>If the submitted and uploaded video file cannot be played, we can request to resend the file</dd>
            <dd>Videos that does not have a face included will be excluded from selection</dd>
            <dd>Video File Format : Film horizontally when using a mobile / 720p or higher quality video quality recommended</dd>

            <dt>Winner Selection Standard</dt>
            <dd>Viewer (MT Members) Voting Result: 50%</dd>
            <dd>Kim Misook Teacher’s Score: 50%</dd>

            <dt>Prize and Award</dt>
            <dd>The winners will be announced through a masterTOPIK live broadcast on11/12(Fri)</dd>
            <dd>Information on winners’ prizes will be posted on masterTOPIK notice on 11/12(Fri)</dd>
            <dd>We’ll contact the winners Individually after promotion</dd>
            <dd>The prize can be changed to other products with the equivalent price after mutual agreement.</dd>
          </dl>
        </div>
      </div>
    </Style>
  );
};

export default EventPage;

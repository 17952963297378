import { asyncApi } from 'common/util/asyncApi';

export const getNationalityList = async () => {
  let response = await asyncApi({ url: '/api/v1/members/nations' });
  return response.data;
};

export const postSendAuthenticationEmail = async (formData) => {
  const response = await asyncApi({
    method: 'post',
    url: '/api/v1/members/send-authentication-email',
    data: { ...formData },
  });

  return response.status;
};

// 이용약관
export const getTermsOfUse = async () => {
  let response = await asyncApi({ url: '/api/v1/terms/use' });
  return response.data;
};

// 개인정보 수집 및 이용동의
export const getTermsOfInfo = async () => {
  let response = await asyncApi({ url: '/api/v1/terms/info' });
  return response.data;
};

// 개인정보 광고 및 활용동의
export const getTermsOfHandling = async () => {
  let response = await asyncApi({ url: '/api/v1/terms/handling' });
  return response.data;
};

export const signUpUser = async (formData) => {
  const response = await asyncApi({
    method: 'post',
    url: '/api/v1/members',
    data: { ...formData },
  });
  return response;
};

export const SNSTerms_old = async (isAgree, token) => {
  const response = await asyncApi({
    method: 'put',
    url: '/api/v1/members/setting/terms',
    data: { userInfoCollectionAcceptance: isAgree },
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.status;
};

export const SNSTerms = async (formData, token) => {
  const response = await asyncApi({
    method: 'put',
    url: '/api/v1/members/setting/terms',
    data: { ...formData },
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.status;
};

// 로그인 후 GA 추가
export const loginAddGa = async (id) => {
  let response = await asyncApi({
    url: `/api/v1/members/encodeString?str=${id}`,
  });
  return response.data;
};

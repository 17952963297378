import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/curriculums';
const size = 12;

/**
 * getAllList 내가 수강 중인 커리큘럼 조회
 */
const curriculumsApi = {
  getMyList: async (token) => {
    let response = await asyncApi({
      url,
      params: { mine: '' },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getFilteredList: async () => {
    let response = await asyncApi({ url, params: { filtered: '' } });
    return response.data;
  },
};

export default curriculumsApi;

import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ModalContext } from 'component/Modal/modalContext';

const Style = {
  Container: styled.div`
    margin: 3em 0;
    text-align: center;

    > div {
      padding: 1em;
      font-size: 2em;
      font-weight: 500;
    }

    a {
      display: inline-block;
      width: 50%;
      padding: 1em;
      border-radius: 0.375em;
      background: #485cc7;
      color: #fff;
      font-size: 1.6em;
      font-weight: 500;
    }
  `,
};
const MembershipErrorModal = () => {
  const { t } = useTranslation('membershipErrorModal');
  const userServiceLevel = useSelector(
    (state) => state.user.data?.serviceLevel
  );
  const { handleModal } = React.useContext(ModalContext);

  return (
    <Style.Container>
      <Trans t={t} values={{ membership: userServiceLevel }}>
        text
      </Trans>
      <Link to='/plan' onClick={() => handleModal()}>
        {t('button')}
      </Link>
    </Style.Container>
  );
};

export default MembershipErrorModal;

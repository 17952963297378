import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'component/atoms/Img';
import thumbnailDefault from 'assets/images/thumb_default_course.jpg';

const Container = styled.div`
  position: relative;
  font-size: 1rem;
  height: 19.5em;
  border-radius: 0.6rem;
  overflow: hidden;
  span {
    position: absolute;
    overflow: hidden;
  }
  img {
    position: absolute;
    top: 0;
    left: 50%;
    width: auto;
    max-width: initial;
    height: 100%;
    transform: translateX(-50%);
    object-fit: cover;
  }
  .img1 {
    top: 0;
    left: 0;
    width: 19.5em;
    height: 19.5em;
  }
  .img2,
  .img3 {
    right: 0;
    width: calc(100% - 19.5em - 5px);
    height: calc(50% - (5px / 2));
  }
  .img2 {
    top: 0;
  }
  .img3 {
    bottom: 0;
  }
  @media (max-width: 1024px) {
    img {
      width: 100%;
    }
    .img1 {
      width: 70%;
    }
    .img2,
    .img3 {
      width: calc(30% - 5px);
    }
  }
  @media (max-width: 768px) {
    .img1 {
      width: 65%;
    }
    .img2,
    .img3 {
      width: calc(35% - 5px);
    }
  }
`;

const TripleThumb = ({ imgSrcList = ['', '', ''], alt }) => {
  for (let i = 0; i < 3; i++) {
    if (!imgSrcList[i] || imgSrcList[i] === null) {
      imgSrcList[i] = '';
    }
  }

  return (
    <Container>
      <span className='img1'>
        <Img src={imgSrcList[0]} alt={alt} onError={thumbnailDefault} />
      </span>
      <span className='img2'>
        <Img src={imgSrcList[1]} alt={alt} onError={thumbnailDefault} />
      </span>
      <span className='img3'>
        <Img src={imgSrcList[2]} alt={alt} onError={thumbnailDefault} />
      </span>
    </Container>
  );
};

TripleThumb.propTypes = {
  imgSrcList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TripleThumb;

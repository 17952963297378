import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { IoIosArrowDown } from 'react-icons/io';
import styled from 'styled-components';

import logoTutoring from 'assets/images/logo_tutoring.png';

const FaqStyled = {
  Wrapper: styled(ContentsWrapper)`
    padding-top: 13em;
    padding-bottom: 10em;
    @media (max-width: 767px) {
      padding-bottom: 18em;
    }
  `,
  H2: styled.h2`
    font-size: 5em;
    margin-bottom: 1em;
    font-weight: bold;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 4.2em;
    }
  `,
  H3: styled.h3`
    font-size: 1.8em;
    font-weight: 500;
    padding: 0.5em 0;
    color: #ffb500;
    &:not(:first-of-type) {
      margin-top: 2em;
    }
    @media (max-width: 767px) {
      font-size: 2.4em;
    }
  `,
  Item: styled.div`
    padding: 3em;
    margin-bottom: 1em;
    border-radius: 0.6rem;
    background-color: #1e2024;
    &.active {
      > h4 {
        margin-bottom: 1.5em;
      }
      > div {
        max-height: ${(props) =>
          props.activeHeight ? props.activeHeight : 500}px;
        margin-top: 1.5em;
      }
      .arrow {
        transform: rotate(180deg);
      }
    }
  `,
  H4: styled.h4`
    position: relative;
    font-size: 2em;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.4s ease;
    @media (max-width: 767px) {
      font-size: 2.6em;
      padding-right: 1.5em;
    }
  `,
  Desc: styled.div`
    font-size: 1.6em;
    font-weight: 200;
    line-height: 1.8;
    max-height: 0;
    overflow: hidden;
    transition: all 0.4s ease;
    @media (max-width: 767px) {
      font-size: 2.3em;
    }
  `,
  Arrow: styled(IoIosArrowDown).attrs({
    className: 'arrow',
  })`
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 2rem;
    margin-top: -1rem;
    transition: 0.4s ease;
    @media (max-width: 767px) {
      font-size: 3rem;
      margin-top: -1.5rem;
    }
  `,
  ArrowLinkBlock: styled.div`
    margin-top: 10em;
    text-align: center;
    a {
      font-size: 2.4em;
      font-weight: 300;
    }
    p {
      margin-top: 0.5em;
      font-size: 1.8em;
    }
    @media (max-width: 767px) {
      margin-top: 14em;
      a {
        font-size: 2.9em;
      }
    }
  `,
  Tutoring: styled.span.attrs({
    children: 'Tutoring',
  })`
    display: inline-block;
    width: 13rem;
    height: 2.6rem;
    margin: 0 0.4em;
    background: url(${logoTutoring}) no-repeat center;
    background-size: contain;
    line-height: 99;
    overflow: hidden;
    @media (max-width: 767px) {
      width: 90px;
      height: 12px;
    }
  `,
};

export default FaqStyled;

import React, { useEffect } from 'react';
import Styled from './index.style';
import { Link } from 'react-router-dom';
import logoImg from 'assets/images/logo01.png';
import logoImgC from 'assets/images/logoColor.png';
import logoBiImg from 'assets/images/logo_bi.png';
import Img from 'component/atoms/Img';

const Logo = ({ isLogin, isHovering }) => {
  return (
    <Styled.Wrapper>
      {isLogin ? (
        <>
          <Styled.logo className='logoLogin'>
            <Link to='/'>
              <Img src={logoBiImg} alt='MASTER TOPIK' />
            </Link>
          </Styled.logo>
        </>
      ) : (
        <>
          <Styled.logo>
            <Link to='/'>
              {isHovering ? <Img src={logoImgC} alt='MASTER TOPIK' className='long'/> : <Img src={logoImg} alt='MASTER TOPIK' className='long'/>}

              <Img src={logoBiImg} alt='MASTER TOPIK' className='shot'/>
            </Link>
          </Styled.logo>


        </>
      )}
    </Styled.Wrapper>
  );
};

export default Logo;

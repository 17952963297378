import React from 'react';
import { Line } from 'react-chartjs-2';

/**
 *
 * @param {*} param
 * @param {array} param.labels
 * @param {array} param.data
 * @param {height=} param.height
 */
const LineChart = ({ labels = [], data = [], height = 230 }) => {
  const chartData = {
    labels,
    datasets: [
      {
        label: 'View Time',
        data,
        fill: false,
        pointBorderWidth: 2,
        pointRadius: 5,
        pointBackgroundColor: '#151617',
        pointBorderColor: 'rgba(255, 187, 0, 1)',
        pointHoverRadius: 5,
        borderWidth: 6,
        borderColor: 'rgba(255, 187, 0, 0.2)',
        lineTension: 0,
      },
    ],
  };

  const options = {
    tooltips: { enabled: false },
    legend: { display: false },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            color: 'rgba(194, 194, 194, 0.3)',
            lineWidth: 1.5,
            borderDash: [1.5, 13],
            tickMarkLength: 10,
            offsetGridLines: true,
          },
          ticks: {
            // display: false,
            suggestedMin: 0,
            fontColor: 'transparent',
          },
        },
      ],
      xAxes: [
        {
          offset: true,
          // gridLines: { display: false },
          ticks: {
            fontFamily: "'Noto Sans KR', 'Malgun Gothic', sans-serif",
            fontSize: 14,
            fontColor: '#b9b9b9',
            fontStyle: 'bold',
          },
        },
      ],
    },
  };

  return <Line height={height} data={chartData} options={options} />;
};
export default LineChart;

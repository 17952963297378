import { asyncApi } from "common/util/asyncApi";
const url = "/api/v1/bookstore";
const size = 3;

const BookStoreApi = {
  getHeader: async () => {
    const response = await asyncApi({
      url: url + `?banner`,
    });
    return response.data;
  },

  getBooks: async (page = 0) => {
    const config = { url, params: { size, page: page } };

    let response = await asyncApi(config);
    return response.data;
  },

  getBookSoundTrack: async() => {
    const config = {
      url: `${url}/getBookSoundTrack`
    };

    let response = await asyncApi(config);
    return response.data;
  },

  getBookSoundLevelTrack: async(bookLevel) => {
    const config = {
      url: `${url}/getBookSoundLevelTrack`,
      params: {
        level: bookLevel,
      }
    };

    let response = await asyncApi(config);
    return response.data;
  },

  getBookSoundLevelChapterGroup: async(bookLevel) => {
    const config = {
      url: `${url}/getBookSoundLevelChapterGroup`,
      params: {
        level: bookLevel,
      }
    };

    let response = await asyncApi(config);
    return response.data;
  },

  getBookSoundLevelChapter: async(bookLevel, audioChapter) => {
    const config = {
      url: `${url}/getBookSoundLevelChapter`,
      params: {
        level: bookLevel,
        chapter: audioChapter
      }
    };

    let response = await asyncApi(config);
    return response.data;
  },
};

export default BookStoreApi;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Styled = {
  Wrapper: styled.div`
    padding: 2em 1em;
    margin: 6em 0;
    background-color: #1e2024;
    border-radius: 0.6em;
    text-align: center;
  `,
  Text: styled.span`
    display: inline-block;
    margin: 0 1rem;
    font-size: 1.8em;
  `,
  Button: styled(Link)`
    display: inline-block;
    font-size: 1.4em;
    width: 10rem;
    margin: 0 1rem;
    border-radius: 0.4em;
    border-color: #485cc7;
    background-color: #485cc7;
    font-weight: 400;
    color: #fff;
    line-height: 3.4rem;

    @media (max-width: 767px) {
      display: block;
      margin: 1em auto 0;
    }
  `,
};

const GoToCreate = ({t}) => {
  return (
    <Styled.Wrapper>
      <Styled.Text>
        {t('askSection.desc')}
      </Styled.Text>
      <Styled.Button to='/community/create'>{t('askSection.button')}</Styled.Button>
    </Styled.Wrapper>
  );
};

export default GoToCreate;

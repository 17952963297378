import quizQ1 from 'assets/images/event/241030/quiz-q1.png';
import quizQ2 from 'assets/images/event/241030/quiz-q2.png';
import quizQ3 from 'assets/images/event/241030/quiz-q3.png';
import quizQ4 from 'assets/images/event/241030/quiz-q4.png';
import quizQ5 from 'assets/images/event/241030/quiz-q5.png';
import quizQ6 from 'assets/images/event/241030/quiz-q6.png';
import quizQ7 from 'assets/images/event/241030/quiz-q7.png';
import quizQ8 from 'assets/images/event/241030/quiz-q8.png';
import quizQ9 from 'assets/images/event/241030/quiz-q9.png';
import quizQ10 from 'assets/images/event/241030/quiz-q10.png';
import quizQ11 from 'assets/images/event/241030/quiz-q11.png';
import quizQ12 from 'assets/images/event/241030/quiz-q12.png';
import quizQ13 from 'assets/images/event/241030/quiz-q13.png';
import quizQ14 from 'assets/images/event/241030/quiz-q14.png';
import quizQ15 from 'assets/images/event/241030/quiz-q15.png';
import quizQ16 from 'assets/images/event/241030/quiz-q16.png';
import quizQ17 from 'assets/images/event/241030/quiz-q17.png';
import quizQ18 from 'assets/images/event/241030/quiz-q18.png';
import quizQ19 from 'assets/images/event/241030/quiz-q19.png';
import quizQ20 from 'assets/images/event/241030/quiz-q20.png';

const quizData = [

  {
    id: 1,
    title: `그림을 보고 빈칸에 <span>알맞은 말</span>을 고르세요.`,
    examples: ["팡", "박", "밤", "판"],
    answer: "판",
    src:quizQ1,
    gridType: 2
  },
  {
    id: 2,
    title: `그림을 보고 빈칸에 <span>알맞은 말</span>을 고르세요.`,
    examples: ["외", "직", "점", "회"],
    answer: "회",
    src:quizQ2,
    gridType: 2
  },
  {
    id: 3,
    title: `그림을 보고 <span>알맞은 단어</span>를 고르세요.`,
    examples: ["농구", "야구", "축구", "테니스"],
    answer: "축구",
    src:quizQ3,
    gridType: 2
  },
  {
    id: 4,
    title: `그림을 보고 <span>알맞은 단어</span>를 고르세요.`,
    examples: ["요리", "빨래", "설거지", "청소"],
    answer: "설거지",
    src:quizQ4,
    gridType: 2
  },
  {
    id: 5,
    title: `그림을 보고 <span>알맞은 단어</span>를 고르세요.`,
    examples: ["기자", "가수", "경찰관", "소방관"],
    answer: "소방관",
    src:quizQ5,
    gridType: 2
  },
  {
    id: 6,
    title: `(<Fragment>&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brMob"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    examples: ["을", "에게", "에", "에서"],
    answer: "에게",
    src:quizQ6,
    gridType: 2
  },
  {
    id: 7,
    title: `(<Fragment>&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brMob"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ7,
    examples: ["까지", "에서", "에게", "부터"],
    answer: "에서",
    gridType: 2
  },
  {
    id: 8,
    title: `(<Fragment>&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brMob"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ8,
    examples: ["놀습니다", "놀니다", "놀븝니다", "놉니다"],
    answer: "놉니다",
    gridType: 2
  },
  {
    id: 9,
    title: `(<Fragment>&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brMob"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ9,
    examples: ["걷네요", "걸으세요", "걸읍시다", "걷는데요"],
    answer: "걸읍시다",
    gridType: 2
  },
  {
    id: 10,
    title: `(<Fragment>&ensp;&ensp;&ensp;</Fragment>)에 들어갈 말로<br class="brMob"> 가장 <span>알맞은</span> 것을 고르십시오.`,
    src:quizQ10,
    examples: ["와서", "오기 때문에", "오니까", "오느라고"],
    answer: "오니까",
    gridType: 2
  },
  {
    id: 11,
    title: `다음은 <span>무엇에</span> 대한 내용입니까?`,
    src:quizQ11,
    examples: ["여행", "날씨", "장소", "이사"],
    answer: "날씨",
    gridType: 2
  },
  {
    id: 12,
    title: `다음은 <span>무엇에</span> 대한 내용입니까?`,
    src:quizQ12,
    examples: ["서점", "커피숍", "도서관", "백화점"],
    answer: "도서관",
    gridType: 2
  },
  {
    id: 13,
    title: `다음은 <span>무엇에</span> 대한 내용입니까?`,
    src:quizQ13,
    examples: ["박물관", "미술관", "방송국", "우체국"],
    answer: "우체국",
    gridType: 2
  },
  {
    id: 14,
    title: `다음은 <span>무엇에</span> 대한 내용입니까?`,
    src:quizQ14,
    examples: ["수업", "직업", "장소", "운동"],
    answer: "운동",
    gridType: 2
  },
  {
    id: 15,
    title: `다음은 <span>무엇에</span> 대한 내용입니까?`,
    src:quizQ15,
    examples: ["취미", "외모", "감정", "성격"],
    answer: "성격",
    gridType: 2
  },
  {
    id: 16,
    title: `다음을 읽고 <span>이어지는 말</span>을 고르십시오.`,
    src:quizQ16,
    examples: ["저는 김밥을 좋아해요.", "미국 음식을 좋아해요.", "한국 음식을 먹고 싶어요", "아니요. 저는 비빔밥이 좋아요."],
    answer: "저는 김밥을 좋아해요.",
    gridType: 1
  },
  {
    id: 17,
    title: `다음을 읽고 <span>이어지는 말</span>을 고르십시오.`,
    src:quizQ17,
    examples: ["아니요. 편지만 써요.", "아니요. 매일 전화해요.", "네. 저는 가족한테 전화해요.", "네. 저에게 이메일을 보내요."],
    answer: "아니요. 편지만 써요.",
    gridType: 1
  },
  {
    id: 18,
    title: `다음을 읽고 <span>이어지는 말</span>을 고르십시오.`,
    src:quizQ18,
    examples: ["공부를 하지 않아요.", "친구와 밥을 먹고 싶어요.", "공원에서 운동을 했어요.", "친구를 만나거나 영화를 봐요."],
    answer: "친구를 만나거나 영화를 봐요.",
    gridType: 1
  },
  {
    id: 19,
    title: `다음을 읽고 내용이 <span>같은 것</span>을 고르십시오.`,
    src:quizQ19,
    examples: ["저는 빨간색 모자를 샀습니다.", "지난 월요일에 명동에 갔습니다.", "친구하고 쇼핑했습니다.", "지난 주말에 영화를 봤습니다."],
    answer: "친구하고 쇼핑했습니다.",
    gridType: 1
  },
  {
    id: 20,
    title: `다음을 읽고 내용이 <span>같은 것</span>을 고르십시오.`,
    src:quizQ20,
    examples: ["저는 처음부터 농구를 아주 잘했습니다.", "제 친구에게 농구를 가르쳤습니다.", "농구를 하고 싶지 않습니다.", "저하고 친구는 농구를 잘합니다."],
    answer: "저하고 친구는 농구를 잘합니다.",
    gridType: 1
  },
];

export default quizData;
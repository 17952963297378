import styled from 'styled-components';
import { HiDownload } from 'react-icons/hi';
import { IoIosArrowUp } from 'react-icons/io';

const DetailViewStyle = {
  DetailWrap: styled.div`
    padding: 6em 6em 12em;
    border-radius: 0.6em;
    background: #1e2024;
    @media (max-width: 1024px){
      padding: 5em 4em 10em;
    }
  `,

  TitleArea: styled.div`
    position: relative;
    margin-bottom: 5em;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `,

  Title: styled.div`
    flex: 1;
    flex-basis: 100%;
    margin-bottom: 0.2em;
    font-size: 2.8em;
    color: #fff;
  `,

  Date: styled.div`
    margin: 0.5em 0;
    font-size: 1.6em;
    color: #8e9199;
  `,

  Attachment: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1em;
    margin-left: 1em;
  `,

  BtnDownload: styled.a.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <HiDownload />
      </>
    ),
  }))`
    display: inline-block;
    padding: 0.85em 1em;
    border: 1px solid #46484d;
    border-radius: 0.428em;
    line-height: 1;
    font-size: 1.4em;
    color: #f3a704;
    svg {
      margin-left: 0.5em;
      color: #8e9199;
      font-size: 1.2em;
      vertical-align: text-bottom;
    }
  `,

  ContentArea: styled.div`
    font-size: 1.8em;
    color: #8e9199;
  `,

  BtnWrap: styled.div`
    margin-top: 2em;
    text-align: right;
    @media (max-width: 1024px){
      margin-top: 3em;
      text-align: center;
    }
  `,

  BtnTop: styled.button.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <IoIosArrowUp />
      </>
    ),
  }))`
    border-bottom: 1px solid #f3a704;
    padding-bottom: 0.3em;
    font-size: 2em;
    color: #f3a704;
    svg {
      margin-left: 0.25em;
      transform: translateY(0.15em);
    }
  `,
};

export default DetailViewStyle;

import React, { useEffect, useRef, useState } from 'react';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import Style from './styled/PlanSection.style';
import CourseListContainer from './ListContainer';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUrl, setVideoUrl } from './state/action';

import { useAxios } from 'common/hook/useAxiosHook';
import jresearchApi from 'container/JresearchPage/api/jresearchApi';
import Loading from 'component/atoms/Loading';

import ReactPlayer from 'react-player';
import contentsApi from 'api/contents';

const JresearchPlanSection = ({
  t,
  productId,
  overview,
}) => {
  const dispatch = useDispatch();

  let overviewRef = useRef(0);

  const lectureId = useSelector((state) => state.jresearch.lectureId);
  const videoUrl = useSelector((state) => state.jresearch.currentVideoUrl);

  const [getVideoData, refreshGetVideoData] = useAxios(
    jresearchApi.fetchVideoData,
    [productId],
    true
  );

  const { loading, data } = getVideoData;
  const [playing, setPlaying] = useState(false);
  const [videoInfoData, fetchVideo] = useAxios(
    contentsApi.getVideoByType,
    [],
    true
  )

  const { data: videoInfo } = videoInfoData;


  useEffect(() => {
    if (lectureId !== 0) {
      fetchVideo(lectureId, "", "");
      console.log("test: lectureId", lectureId);
    }
  }, [lectureId])

  useEffect(() => {
    refreshGetVideoData(productId, "");
    if (lectureId !== "0") {
      fetchVideo(lectureId, "", "");
    }
  }, [fetchVideo]);

  useEffect(() => {
    if (overview) {
      dispatch(setCurrentUrl({ url: overview.ot, provider: 'youtube' }));
      dispatch(
        setVideoUrl({
          overview: { url: overview.ot, provider: 'youtube' },
          lecture: { url: overview.lecture, provider: 'mp4' },
        })
      );
    }
  }, [overview]);

  useEffect(() => {
    console.log('test: videoUrl !!!!', videoUrl);
  }, [videoUrl]);

  useEffect(() => {
    if (videoInfo) {
      dispatch(setCurrentUrl({ url: videoInfo.url, provider: 'youtube' }));
    }
  }, [videoInfo])

  if (loading) return <Loading />;

  if (data) {
    return (
      <ContentsWrapper>
        <Style.Container>
          <Style.CoursePlan>
            <h2>{t('stitle')}</h2>
            <ReactPlayer
              ref={overviewRef}
              url={videoUrl?.url}
              playing={playing}
              onPlay={() => setPlaying(true)}
              onPause={() => setPlaying(false)}
              controls
              config={{
                file: {
                  attributes: {
                    onContextMenu: (e) => e.preventDefault(),
                    controlsList: 'nodownload',
                  },
                },
              }}
            />
          </Style.CoursePlan>
          <CourseListContainer
            data={data}
            videoRef={overviewRef}
            playing={playing}
            productId={productId}
            lectureId={lectureId}
          />
        </Style.Container>
      </ContentsWrapper>

    );
  }
  return <></>;
};

export default JresearchPlanSection;

import React, { useEffect, useState } from 'react';
import Style from './styled/index.style';
import { useSelector, useDispatch } from 'react-redux';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { useAxios } from 'common/hook/useAxiosHook';
import {
  fetchAllNotifications,
  readAll,
  updateToRead,
  updateToReadFixed,
  getFixedNotice,
  readListDelete,
} from '../../api/notificationApi';
import SelectBox from 'container/NotificationPage/SelectBox';
import PageHeader from 'component/atoms/PageHeader';
import NotificationList from './List';
import FixedList from './FixedList';
import Modal from './Modal';
import { ModalContext } from 'component/Modal/modalContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'lodash';
import { countReadAll } from 'component/layout/Header/RightMenu/state/action';
import SearchEmpty from 'component/atoms/SearchEmpty';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';

const NotificationPage = () => {
  const { t } = useTranslation('notificationPage');
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  let { handleModal } = React.useContext(ModalContext);
  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [notificationData, refreshNotificationData] = useAxios(
    fetchAllNotifications,
    []
  );
  const [fixedNoticeList, fetchFixedNoticeList] = useAxios(
    getFixedNotice,
    [],
    true
  );
  const { data } = notificationData;
  const { data: fixedData } = fixedNoticeList;
  const [, refreshUpdateToRead] = useAxios(updateToRead, [], true);
  const [, refreshUpdateToReadFixed] = useAxios(updateToReadFixed, [], true);
  const [, refreshReadAll] = useAxios(readAll, [], true);
  const [{ data: deleteStatus }, fetchReadDeleteList] = useAxios(
    readListDelete,
    [],
    true
  );
  const [clickDeleteBtn, setClickDeleteBtn] = useState(false);

  const readNotification = async (id) => {
    refreshUpdateToRead(token, id);

    slideItem.some((li) => {
      if (li.memberNoticeIdx === id) {
        li.confirmDate = 'true';
        return true;
      }
      return false;
    });
  };
  const readFixedNotification = async (id) => {
    refreshUpdateToReadFixed(token, id);
    fixedData.content.some((li) => {
      if (li.id === id) {
        li.read = 'true';
        return true;
      }
      return false;
    });
  };
  const readAllNotification = () => {
    refreshReadAll(token);
    slideItem.forEach((li) => {
      li.confirmDate = 'true';
    });
    fixedData.content.forEach((li) => {
      li.read = 'true';
    });
    dispatch(countReadAll());
  };
  const onNext = () => {
    refreshNotificationData({ access_token: token, page: data.number + 1 });
  };

  const readNotificationDelete = () => {
    fetchReadDeleteList(token);
  };

  useEffect(() => {
    if (deleteStatus?.status === 200) {
      refreshNotificationData({ access_token: token });
    }
  }, [deleteStatus]);

  useEffect(() => {
    refreshNotificationData({ access_token: token });
    fetchFixedNoticeList(0, token);
  }, []);

  useEffect(() => {
    if (!data) return;
    if (!data.title) {
      if (data.first) {
        setSlideItem([...data.content]);
        setHasMore(!data.last);
        return;
      }
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(!data.last);
    } else if (data.title) {
      if (data.first) {
        setSlideItem([...data.content]);
        setHasMore(!data.last);
        return;
      }
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(!data.last);
    }
  }, [data]);

  const openDeletePopUp = (e, remove) => {
    e.preventDefault();
    switch (remove) {
      case 'read':
        return handleModal(
          <Modal
            handleClick={readNotificationDelete}
            message='Are you sure you want to delete the notifications you read?'
          />
        );
      case 'all':
        return handleModal(
          <Modal message='Are you sure you want to delete all notifications?' />
        );
      case 'check':
        return handleModal(
          <Modal message='Are you sure you want to delete the notifications history?' />
        );
      default:
    }
  };

  const onSearch = debounce((e) => {
    e.preventDefault();
    refreshNotificationData({
      access_token: token,
      title: e.target.value,
    });
  }, 1000);

  return (
    <ContentsWrapper>
      <SeoHelmet
        title={SeoData.notification.title}
        desc={SeoData.notification.desc}
      />
      <PageHeader title={t('title')} />
      <form onSubmit={(e) => e.preventDefault(e)}>
        <Style.InputContainer>
          <Style.SearchImage onClick={(e) => onSearch(e)} />
          <Style.SearchInput
            placeholder={t('placeholder')}
            onChange={(e) => onSearch(e)}
          />
        </Style.InputContainer>
      </form>
      <Style.BtnContainer>
        {clickDeleteBtn ? (
          <div className='btn-container'>
            <Style.Btn onClick={(e) => openDeletePopUp(e, 'read')}>
              Delete Read alert
            </Style.Btn>
            <Style.Btn onClick={(e) => openDeletePopUp(e, 'all')}>
              Delete All
            </Style.Btn>
            <Style.Btn onClick={(e) => openDeletePopUp(e, 'check')}>
              Delete
            </Style.Btn>
            <Style.Btn onClick={() => setClickDeleteBtn(false)}>
              Cancel
            </Style.Btn>
          </div>
        ) : (
          <div>
            {/* {false ? <SelectBox /> : <span></span>} */}
            <Style.Btn onClick={() => readAllNotification()}>
              {t('button')}
            </Style.Btn>
            <Style.Btn onClick={(e) => openDeletePopUp(e, 'read')}>
              Delete Read alert
            </Style.Btn>
            {/* {false ? (
              <Style.Btn onClick={() => setClickDeleteBtn(true)}>
                Delete
              </Style.Btn>
            ) : (
              <span></span>
            )} */}
          </div>
        )}
      </Style.BtnContainer>
      <Style.NotificationContainer>
        {fixedData?.content.length === 0 && data?.content.length === 0 && (
          <SearchEmpty type='search' />
        )}
        <div>
          {fixedData?.content &&
            fixedData.content.map((item) => {
              return (
                <FixedList
                  key={item.id}
                  data={item}
                  onclick={readFixedNotification}
                />
              );
            })}
        </div>
        <InfiniteScroll
          dataLength={slideItem.length}
          next={onNext}
          hasMore={hasMore}
        >
          {slideItem &&
            slideItem.map((data) => {
              return (
                <NotificationList
                  key={data.memberNoticeIdx}
                  data={data}
                  clickDeleteBtn={clickDeleteBtn}
                  onclick={readNotification}
                />
              );
            })}
        </InfiniteScroll>
      </Style.NotificationContainer>
    </ContentsWrapper>
  );
};

export default NotificationPage;

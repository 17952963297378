import React from 'react';
import Style from '../styled/Banner.style';
import { Link } from 'react-router-dom';

import ContentsWrapper from 'component/atoms/ContentsWrapper';

const Banner = ({ item }) => {

  return (
    <Style.Banner>
      <ContentsWrapper>
        <a href={item.linkUrl}>
          <Style.TitleWrap>
            <Style.Category>{item.category}</Style.Category>
            <Style.Title>{item.copy}</Style.Title>
          </Style.TitleWrap>
        </a>
      </ContentsWrapper>
    </Style.Banner>
  );
};
export default Banner;


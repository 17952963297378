import React from 'react';
import Style from './styled/PaymentSection.style';
import { ModalContext } from 'component/Modal/modalContext';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import userApi from 'api/user';
import { Trans, useTranslation } from 'react-i18next';
import PaymentModal from './PaymentModal';

const PaymentSection = () => {
  const { t } = useTranslation('settingPage');
  const token = useSelector((state) => state.user.token);
  const { handleModal } = React.useContext(ModalContext);
  const [{ data }] = useAxios(() => userApi.getMyPaymentMethod(token), []);

  const cardNumber =
    data &&
    data.cardNum &&
    Array.from({ length: data.cardNum.length - 4 }, (_, startNo) => startNo);

  const onChangePayMethod = () => {
    if (!data) return;
    handleModal(<PaymentModal currentPayMethod={data} />, false, 520);
  };

  if (data && data.payMethod)
    return (
      <Style.Container>
        <Style.Title>{t('card.title')}</Style.Title>
        <span className='method'>{t('card.method')}</span>
        {(data.cardNum || data.payMethod) && (
          <p className='cardNo'>
            <span>
              {data.payMethod}
              {data.payMethod !== 'GOOGLE PLAY STORE' ||
              data.paymentType !== 'INAPP' ? (
                <>
                  <Style.CircleContainer>
                    {cardNumber?.map((data, index) => {
                      return <Style.CircleSpan key={index} />;
                    })}
                  </Style.CircleContainer>
                  {data.cardNum && data.cardNum.substr(data.cardNum.length - 4)}
                </>
              ) : null}
            </span>
            {/* {(data.paymentType !== "INAPP" ||
              data.payMethod !== "GOOGLE PLAY STORE") && (
              <span className="change" onClick={onChangePayMethod}>
                <Trans t={t}>subInfo.changeButton</Trans>
              </span>
            )} */}
          </p>
        )}
      </Style.Container>
    );
  return <></>;
};

export default PaymentSection;

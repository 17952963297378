import React, { useEffect, useState } from 'react';
import StyledList from './styled/List.style';
import { Circle } from 'rc-progress';
import { BiPlay, BiPause } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUrl } from 'container/AudioDetailPage/state/action';

const audioPlayType = {
  play: "play",
  stop: "stop",
  pause: "pause"
};

const SecondAccordion = ({
  audio
}) => {

  const [audioFile] = useState(new Audio());
  const [audioPlay, setAudioPlay] = useState(audioPlayType.stop); //play, stop, pause
  const [audioPercent, setPercent] = useState(0);

  const [initialCheck, setCheck] = useState(true); //값 초기화에서 오디오 실행 방지

  const dispatch = useDispatch();
  let currentAudioUrl = useSelector((state) => state.audioDetail.currentAudioUrl);

  useEffect(() => {
    if (!audioFile) return;
    if (audioPlay === audioPlayType.play) {
      audioFile.play();
    } else if (audioPlay === audioPlayType.pause) {
      audioFile.pause();
    } else {
      audioFile.pause();
      audioFile.currentTime = 0;
      setPercent(0);
    }
    if (audioPlay === audioPlayType.play) {
      const getPercentage = setInterval(() => {
        let percent = (audioFile.currentTime * 100) / audioFile.duration
        setPercent(percent.toFixed());
      }, 1000);
      return () => clearInterval(getPercentage);
    }
  }, [audioPlay]);

  useEffect(() => {
    audioFile.src = audio.soundUrl;
    audioFile.preload = "none";
    audioFile.load();
    audioFile.addEventListener('ended', () => {
      setAudioPlay(audioPlayType.stop);
      setPercent(0);
    });
    return () => {
      audioFile.pause();
    };
  }, [audio]);

  const changePlayState = () => {
    if (initialCheck) setCheck(false);
    if (currentAudioUrl === audio.soundUrl) {
      if (audioPlay === audioPlayType.play) {
        setAudioPlay(audioPlayType.pause);
      } else if (audioPlay === audioPlayType.pause ||
        audioPlay === audioPlayType.stop
      ) {
        setAudioPlay(audioPlayType.play);
      }
    } else {
      dispatch(setCurrentUrl(audio.soundUrl));
    }
  };

  useEffect(() => {
    if (currentAudioUrl === audio.soundUrl) {
      if (!initialCheck) {
        setAudioPlay(audioPlayType.play);
      }
    } else {
      if (audioPlay === audioPlayType.play || audioPlay === audioPlayType.pause) {
        setAudioPlay(audioPlayType.stop);
      }
    }
  }, [currentAudioUrl]);


  return (
    <div>
      <StyledList.VideoListContainer
        className='clickable'
        onClick={() => changePlayState()}
        onTouchStart={() => {
          const cur = audioFile.currentTime;
          audioFile.src = audio.soundUrl;
          audioFile.currentTime = cur;
        }}
      >
        {
          //비디오 데이터
          <StyledList.VideoList>
            <span className='icon'>
              <Circle
                percent={audioPercent}
                strokeWidth={5}
                trailWidth={5}
                strokeColor='#f8b209'
                trailColor='#8b8f99'
                className='progressBar'
              />
              {
                audioPlay !== audioPlayType.play ?
                  <BiPlay className='progressBarIcon' color='#8b8f99' size='0.8em' />
                  : <BiPause className='progressBarIcon' color='#f8b209' />
              }
            </span>
            <StyledList.VideoListTitle style={(audioPlay !== audioPlayType.play) ? {} : { color: "#f8b209" }}>{audio.soundName}</StyledList.VideoListTitle>
          </StyledList.VideoList>
        }
      </StyledList.VideoListContainer>
    </div>
  );
};

export default SecondAccordion;

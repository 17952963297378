import styled from "styled-components";
import visualImg from 'assets/images/event/220714/visual.jpg';
import visualImgKo from 'assets/images/event/220714/ko/visual.jpg';
import titleImg from 'assets/images/event/220714/title.png';
import titleImgKo from 'assets/images/event/220714/ko/title.png';
import titleImg2 from 'assets/images/event/220714/title2.png';
import titleImgKo2 from 'assets/images/event/220714/ko/title2.png';
import icoHandImg from 'assets/images/event/220714/ico_hand.png';
import icoAirplaneImg from 'assets/images/event/220714/ico_airplane.png';
import arrowRightIcon from 'assets/images/arrow_right.svg';

const StyledEventPage = {
  EventWrap: styled.div`
		font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    text-align:center;
    color:#555;
    letter-spacing: -0.035em;

		.blind {
			overflow:hidden;
			position: absolute;
			clip:rect(0 0 0 0);
			width:1px;
			height:1px;
			margin:-1px;
		}

    .inner {
      margin: auto;
    }

    h2 {
			height: 50px;
			margin-bottom: 3em;
      background-size: contain;
      background-position: 50% 0;
      background-repeat:no-repeat;
    }

    .visual {
      position:relative;
      background:#3235ff;
			.inner {
				position:relative;
        background-image:url(${visualImg});
        background-position: 50% 0;
        background-repeat:no-repeat;
        background-size: auto 100%;
				max-width: 1024px;
        padding-top: 1175px;
				margin: 0 auto;
				z-index:2;
      }
    }
		.ko {
      .visual {
			  .inner {
          background-image: url(${visualImgKo});
        }
      }
		}

		--boxBorder: 4px;

		.detail {
			padding: 6em 0;
			background: #00f0f7;
			.inner {
				max-width: 750px;
			}
			h2 {
				background-image: url(${titleImg});
			}
			.desc {
				margin-bottom: 1.5em;
				font-size: 3rem;
				color: #253d9d;
				h3 {
					margin: 0.4em 0;
					font-weight: 700;
					font-size: 5.2rem;
					color: #7b2588;
				}
				p {
					display: inline-block;
					background: #ff2bab;
					padding: 0.1em 0.5em;
					font-weight: 400;
					font-size: 3.4rem;
					color: #fff;
				}
			}
			.preview {
				position: relative;
				&::before {
					content: "";
					box-sizing: border-box;
					display: block;
					position: absolute;
					top: 10px;
					left: 0;
					width: 100%;
					height: 100%;
					border: calc(var(--boxBorder) / 2) solid #000;
					border-radius: 25px;
					background: #fae7bf;
				}
				.titWrap {
					position: relative;
					padding-top: 1.5rem;
					background: #000;
					border: var(--boxBorder) solid #000;
					border-bottom: none;
					border-radius: 25px 25px 0 0;
					overflow: hidden;
					.tit {
						padding: 0.4em;
						background: #ebbc2e;
						font-weight: 700;
						font-size: 3.2rem;
						color: #7b2588;
						&::before {
							content:"";
							display:inline-block;
							width: 0.25em;
							height: 0.25em;
							border-radius: 0.2em;
							background:#7b2588;
							vertical-align:middle;
						}
						&::after {
							content:"";
							display:inline-block;
							width: 0.25em;
							height: 0.25em;
							border-radius: 0.2em;
							background:#7b2588;
							vertical-align:middle;
						}
						span {
							padding: 0.4em;
						}
					}
				}
				.playerWrap {
					position: relative;
					width: 100%;
					padding-top: 56%;
					border: var(--boxBorder) solid #000;
					border-radius: 0 0 25px 25px;
					background: #000;
					overflow: hidden;
					> div {
						position:absolute;
						top:0;
						left:0;
					}
				}

			}
		}
		.ko {
      .detail {
			  h2 {
          background-image: url(${titleImgKo});
        }
      }
		}

		.how {
			padding: 6em 0;
			background: #9736b9;
			.inner {
				max-width: 750px;
			}
			h2 {
				background-image: url(${titleImg2});
			}
			li {
				position: relative;
				margin-bottom: 1.5em;
				&::before {
					content: "";
					box-sizing: border-box;
					display: block;
					position: absolute;
					top: 10px;
					left: 0;
					width: 100%;
					height: 100%;
					border: calc(var(--boxBorder) / 2) solid #231f20;
					border-radius: 25px;
				}
				&:first-child::before {
					background-color: #00f0f7;
				}
				&:nth-child(2)::before {
					background-color: #affc22;
				}
				&:nth-child(3)::before {
					background-color: #ff6c52;
				}
				&::after {
					content: "";
					box-sizing: border-box;
					display: block;
					position: absolute;
					z-index: 2;
					background-position: 0 0;
					background-repeat: no-repeat;
					background-size: contain;
				}
				&:first-child::after {
					bottom: 15%;
					right: 3%;
					width: 10%;
					padding-top: 8.26%;
					background-image: url(${icoHandImg});
				}
				&:nth-child(3)::after {
					top: 15%;
					right: -3.75%;
					width: 13%;
					padding-top: 14%;
					background-image: url(${icoAirplaneImg});
				}
				> div {
					position: relative;
					padding: 1em 15%;
					border: var(--boxBorder) solid #231f20;
					border-radius: 25px;
					background-color: #fff;
					font-weight: 300;
					font-size: 2.4rem;
					color: #231f20;
					p {
						margin-bottom: 0.3em;
						letter-spacing: -0.08em;
						font-weight: 700;
						font-size: 3.6rem;
						color: #253d9d;
						span {
							display: inline-block;
							padding: 0.2em 1em 0.3em;
							line-height: 1;
							border: calc(var(--boxBorder) / 2 + 1px) solid #000;
							border-radius: 2em;
							background: #f2e904;
							font-size: 2.4rem;
							color: #000;
							vertical-align: top;
						}
					}
				}
				.num {
					position: absolute;
					display: block;
					top: -10px;
					left: 10px;
					padding: 5px;
					border: calc(var(--boxBorder) / 2) solid #000;
					border-radius: 50%;
					background: #009bee;
					em {
						display: block;
						width: 1.4em;
						height: 1.4em;
						border-radius: 50%;
						line-height: 1.1;
						border: calc(var(--boxBorder) / 2) solid #000;
						background: #fff;
						font-weight: 900;
						font-size: 3.6rem;
						color: #000;
					}
				}
			}
			.btnWrap {
				margin-top: 3em;
				a {
					display: inline-block;
					padding: 0.5em 2.3em 0.6em;
					line-height: 1;
					border: calc(var(--boxBorder) + 1px) solid #000;
					border-radius: 2em;
					background: #f2e904;
					box-shadow: 0 10px 0 rgba(0, 0, 0, 0.4);
					font-weight: 700;
					font-size: 4rem;
					color: #000;
				}
			}
		}
		.ko {
      .how {
			  h2 {
          background-image: url(${titleImgKo2});
        }
      }
		}

    .notification {
      background:#2c2c2c;
      text-align:left;
      padding: 4em 0;
      color:#fff;
      font-weight:300;
      letter-spacing:normal;
      .inner {
        max-width: 750px;
      }
      h3 {
        font-size:2.8rem;
        font-weight:700;
        text-align: center;
      }
      dl {
        font-size: 2.2rem;
      }
      dt {
        padding: 1.6em 0 0.8em;
        font-weight:700;
				a {
					border-bottom: none;
				}
      }
      dd {
        position:relative;
        padding-left:0.8em;
        margin-bottom: 0.6em;
        &:before {
          content:"";
          display:inline-block;
          position: absolute;
          top:0.5em;
          left:0;
          width: 0.25em;
          height: 0.25em;
          border-radius: 0.2em;
          background:#fff;
          vertical-align:middle;
        }
        em {
          color:#fff799;
        }
      }
      P {
        padding: 1.6em 0 0.8em;
      }
      a {
        display: inline-block;
        padding-right: 1em;
        border-bottom: 1px #fff solid;
        background: url(${arrowRightIcon}) 100% 50% no-repeat;
        background-size: 0.6em auto;
        line-height: 1.2;
      }
    }

    @media (max-width: 1024px) {
			.visual {
        .inner {
          padding-top: 114.746vw;
        }
      }
    }

		@media (max-width: 750px) {
			--boxBorder: 2px;
			.inner {
        padding: 0 20px;
      }
			h2 {
				height: 40px;
				margin-bottom: 1.5em;
			}
			.visual {
        .inner {
          padding-top: 148.8vw;
        }
      }
			.detail {
				padding: 4em 0;
			}
			.how {
				padding: 4em 0;
			}
			.how {
				li {
					.num {
						em {
							font-size: 3rem;
						}
					}
				}
				.btnWrap {
					a {
						font-size: 3.5rem;
					}
				}
			}
    }
  `,
};

export default StyledEventPage;

import styled from 'styled-components';
import { css } from 'styled-components';
import { Link } from 'react-router-dom';
import ButtonLink from 'component/atoms/ButtonLink';
import Dashboard from 'container/ClassroomPage/styled/Dashboard.style';
import icoStep1 from 'assets/images/ico_mock_test01.png';
import icoStep2 from 'assets/images/ico_mock_test02.png';
import icoStep3 from 'assets/images/ico_mock_test03.png';
import icoStep4 from 'assets/images/ico_mock_test04.png';
import icoStep1Large from 'assets/images/ico_mock_test01_large.png';
import icoStep2Large from 'assets/images/ico_mock_test02_large.png';
import icoStep3Large from 'assets/images/ico_mock_test03_large.png';
import icoStep4Large from 'assets/images/ico_mock_test04_large.png';
import icoCompleted from 'assets/images/ico_mock_test_completed.png';
import icoWriting from 'assets/images/ico_mock_test_writing.png';
import icoCompletedLarge from 'assets/images/ico_mock_test_completed_large.png';
import icoWritingLarge from 'assets/images/ico_mock_test_writing_large.png';

const btnStyle = css`
  padding: 0.938em;
  border: 1px #ccc solid;
  border-radius: 0.6rem;
  font-size: 1.6em;
  font-weight: 700;
  text-transform: uppercase;
`;

const dashboardStyle = css`
  position: relative;
  padding: 2.5em 3em;
  border-radius: 0.6em;
  background-color: #1e2024;
  overflow: hidden;

  @media (max-width: 1024px) {
    padding: 2em;
  }

  @media (max-width: 767px) {
    padding: 2em 3em;
  }
`;

const MockTest = {
  Step: styled.ol`
    position: relative;
    margin: 0 5em;
    display: flex;
    align-items: stretch;
    justify-content: top;

    @media (max-width: 767px) {
      display: block;
    }

    @media (max-width: 375px) {
      margin: 0 auto;
    }
  `,
  StepItem: styled.li`
    position: relative;
    width: 25%;
    padding: 0 2em;

    &::before {
      content: '';
      position: absolute;
      top: 7em;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 1px dashed #1e2024;
      transform: translateX(-50%);
    }

    &:first-child::before {
      display: none;
    }

    > * {
      display: block;
      text-align: center;
      font-weight: 500;
    }

    strong {
      position: relative;
      color: #5c5f66;
      font-size: 1.6em;
      padding-top: 10em;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 8.75em;
        height: 8.75em;
        transform: translateX(-50%);
        z-index: 1;
      }

      &::before {
        background-color: #1e2024;
        border-radius: 50rem;
      }

      &::after {
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
      }
    }

    &:first-child strong::after {
      background-image: url(${icoStep1});
    }

    &:nth-child(2) strong::after {
      background-image: url(${icoStep2});
    }

    &:nth-child(3) strong::after {
      background-image: url(${icoStep3});
    }

    &:last-child strong::after {
      background-image: url(${icoStep4});
    }

    span {
      margin-top: 0.5em;
      font-size: 2em;
    }

    @media (max-width: 1024px) {
      strong::after {
        background-size: 4.375em auto;
      }

      &:first-child strong::after {
        background-image: url(${icoStep1Large});
      }

      &:nth-child(2) strong::after {
        background-image: url(${icoStep2Large});
      }

      &:nth-child(3) strong::after {
        background-image: url(${icoStep3Large});
      }

      &:last-child strong::after {
        background-image: url(${icoStep4Large});
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      padding: 2em 2em 2em 10em;

      &::before {
        top: 0;
        left: 5em;
        width: 1px;
        height: 100%;
        border-bottom: 1px dashed #1e2024;
        transform: translateY(-50%);
      }

      > * {
        text-align: left;
      }

      strong {
        font-size: 1.6em;
        padding-top: 0em;

        &::before,
        &::after {
          content: '';
          position: absolute;
          top: calc(50% + 1.2em);
          left: -5em;
          width: 4em;
          height: 4em;
          transform: translateY(-50%);
          z-index: 1;
        }

        &::after {
          background-size: 2.188em auto;
        }
      }
    }
  `,
  TestGuide: styled.div`
    margin-top: 6em;
    padding: 5em;
    border-radius: 0.6em;
    border: 2px solid #191b1f;

    p {
      font-size: 2.2em;
      font-weight: 700;
      text-align: center;
    }
  `,
  BtnGroup: styled.div`
    margin-top: 2.2em;
    text-align: center;
  `,
  BtnVideo: styled(ButtonLink)`
    ${btnStyle}
    margin: 0.5em 0.375em 0;
  `,
  BtnDownload: styled(ButtonLink)`
    ${btnStyle}
    border-color: #ffb500;
    color: #ffb500;
    margin: 0.5em 0.375em 0;
  `,
  DashboardBox: styled.div`
    margin-top: 3em;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 1024px) {
      display: block;
    }
  `,
  Dashboard: styled(Dashboard.Wrapper)`
    &.left {
      flex: 1 1 auto;

      @media (max-width: 767px) {
        display: block;
        width: 100%;
        padding: 0em 3em;
      }
    }

    &.right {
      display: block;
      min-width: 35em;
      margin-left: 3em;
      padding: 3.5em 3em;
      flex: 0 0 auto;
      text-align: center;

      p {
        font-size: 1.8em;
        font-weight: 700;
      }

      @media (max-width: 1024px) {
        margin: 3em 0 0;
        padding: 2em 0em;
        max-width: none;

        p {
          display: inline-block;
          vertical-align: middle;
        }

        a {
          margin: 0 0 0 1em;
          vertical-align: middle;
        }
      }

      @media (max-width: 375px) {
        p {
          display: block;
        }
        a {
          margin: 1em 0 0 0;
        }
      }
    }
  `,
  ItemBox: styled(Dashboard.ItemBox)`
    width: 50%;

    @media (max-width: 767px) {
      display: block;
      width: 100%;
      padding: 2em 0em;
      border-left: 0;
      border-top: 1px solid #2c2c2c;

      &:first-child {
        border-top: 0;
      }
    }

    &:first-child ::before {
      width: 3.3em;
      background-image: url(${icoCompleted});
    }

    &:last-child ::before {
      width: 3.4em;
      background-image: url(${icoWriting});
    }
  `,
  Item: styled(Dashboard.Item)`
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 3.8em;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      transform: translateY(-50%);
    }
  `,
  Title: styled(Dashboard.Title)``,
  Count: styled(Dashboard.Count)``,
  /*
  Dashboard: styled.div`
    margin-top: 3em;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media (max-width: 1024px) {
      display: block;
    }
  `,
  LeftInner: styled.div`
    ${dashboardStyle}
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    flex: 1 1 auto;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1px;
      height: calc(100% - 5em);
      background-color: #2c2c2c;
      transform: translateY(-50%);
    }

    @media (max-width: 767px) {
      display: block;

      &::after {
        width: calc(100% - 4em);
        height: 1px;
        transform: translateX(-50%);
      }
    }
  `,
  SetNumber: styled.div`
    position: relative;
    flex: 0 0 auto;
    padding-left: 4.375em;
    padding-right: 1em;
    color: #5f6268;
    font-size: 1.6em;
    font-weight: 500;

    &::before {
      content:'';
      position: absolute;
      top: 50%;
      left: 0;
      height: 2.375em;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
      transform: translateY(-50%);
    }

    &:first-child::before {
      width: 2.063em;
      background-image: url(${icoCompleted});
    }

    &:last-child::before {
      width: 2.125em;
      background-image: url(${icoWriting});
    }

    span {
      display: block;
      font-size: 2.500em;
      font-weight: 700;
      color: #ffffff;
    }

    @media (max-width: 1024px) {
      padding-left: 2.500em;

      &:first-child::before {
        width: 2em;
        height: 2em;
        background-image: url(${icoCompletedLarge});
      }

      &:last-child::before {
        width: 2em;
        height: 2em;
        background-image: url(${icoWritingLarge});
      }

      span {
        display: block;
        font-size: 2em;
        font-weight: 700;
        color: #ffffff;
      }
    }

    @media (max-width: 767px) {
      padding-left: 3.125em;

      &:first-child {
        margin-bottom: 3em;
      }
    }
  `,
  RightInner: styled.div`
    ${dashboardStyle}
    max-width: 35em;
    margin-left: 3em;
    flex: 0 0 auto;
    text-align: center;

    p {
      font-size: 1.8em;
      font-weight: 700;
    }

    @media (max-width: 1024px) {
      margin: 3em 0 0;
      max-width: none;

      p {
        display: inline-block;
        vertical-align: middle;
      }

      a {
        margin: 0 0 0 1em;
        vertical-align: middle;
      }
    }

    @media (max-width: 375px) {

      p {
        display: block;
      }
      a {
        margin: 1em 0 0 0;
      }
    }
  `,
  */
  Btn: styled(Link)`
    display: inline-block;
    text-align: center;
    padding: 0.5em;
    margin-top: 0.714em;
    background-color: #485cc7;
    line-height: 3.4rem;
    font-size: 1.4em;
    font-weight: 500;
    border-radius: 0.4rem;
  `,
  SetCardList: styled.div`
    margin-bottom: 12.4em;
    > div:nth-child(3n) {
      margin-right: 0;
    }
    &::after {
      content: '';
      display: table;
      clear: both;
      overflow: hidden;
    }
    @media (max-width: 1024px) {
      > div:nth-child(odd) {
        margin-right: 2%;
      }
    }
  `,
};

export default MockTest;

import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { Swiper } from 'swiper/react';

const LevelTestStyled = {
  Wrapper: styled(ContentsWrapper)`
    position: relative;
    padding-top: 10em;
    padding-bottom: 25em;
    @media (max-width: 767px) {
      max-width: 500px;
      padding-top: 8em;
      padding-bottom: 8em;
    }
  `,
  Texts: styled.div`
    width: 50%;
    @media (max-width: 767px) {
      width: auto;
    }
  `,
  Desc: styled.div`
    font-size: 2em;
    margin-bottom: 2em;
    color: #ccc;
    @media (max-width: 767px) {
      display: none;
    }
  `,
  Swiper: styled(Swiper)`
    position: absolute;
    top: 10em;
    right: 2rem;
    max-width: 56em;
    .swiper-slide {
      background-color: #0f1012;
    }
    @media (min-width: 1025px) {
      &:after {
        content: '';
        position: absolute;
        bottom: 8%;
        left: 0;
        width: 100vw;
        height: 1px;
        border-top: 1px dashed #fff;
        opacity: 0.3;
      }
      .pc {
        display: inline-block;
      }
      .mobile {
        display: none;
      }
    }
    @media (max-width: 1024px) {
      max-width: 40%;
    }
    @media (max-width: 767px) {
      position: static;
      width: 77%;
      max-width: 400px;
      margin: 7em auto 0;
    }
  `,
};

export default LevelTestStyled;

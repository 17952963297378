import React from 'react';
import styled from 'styled-components';
import H2 from 'component/atoms/H2';
import { Link } from 'react-router-dom';

const Header = styled.header`
  position: relative;
  margin-top: 8em;

  @media (max-width: 767px) {
    margin-top: 5em;
  }
`;

const Desc = styled.p`
  color: #d1d1d1;
  font-size: 2em;
  font-weight: 300;
`;
const Button = styled.button`
  color: #d1d1d1;
  font-size:1.6em;
  font-weight: 300;
  width: 18.6rem;
  height:4.4rem;
  background: #1e2126;;
  border:1px solid #3b3f46;
  border-radius: 5px;;
  position: absolute;
  right:0;
  bottom:0;

`;

/**
 * @param {object} param
 * @param {object} param.title
 * @param {object=} param.text
 */
const PageHeader = ({ title, text, text2, buttonText, type }) => {
  return (
    <Header className='pageHeader'>
      { title === "Help" ?
        <H2 className='page-title'><Link to="/help">{title}</Link></H2>
      : <H2 className={`page-title ${type}`}  >{title}</H2>}
      {text && <Desc>{text}</Desc>}
      {text2 && <Desc>{text2}</Desc>}
      {buttonText && <Button>{buttonText}</Button>}
    </Header>
  );
};

export default PageHeader;

import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export default function CheckState() {
  const history = useHistory();
  const { state } = useLocation();

  useEffect(() => {
    let cleanup = true;
    if (state) return;
    history.replace('/level-test');
    return () => cleanup = false;
  }, [history]);

  return state;
}

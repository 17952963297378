import React, { useState, useEffect, useRef } from "react";
import Styled from "./styled/librarySelect.style";
import { StyledLibrarySearch } from "./styled/libraryInput.style";
import useClickOutside from "common/hook/useOutsideClick";
import { Trans, useTranslation } from "react-i18next";

const LibrarySelect = ({ selectData, setSelect }) => {
  const { t } = useTranslation("libraryPage");
  const inputRef = useRef();

  const [selected, setSelected] = useState(false);
  const [selectedList, setSelectedList] = useState(false);

  useEffect(() => {
    setSelected(t("searchSection.filterOptions.option1"));
  }, [t]);

  useClickOutside(inputRef, () => {
    setSelectedList(false);
  });

  const onSelect = (value, label) => {
    setSelect(value);
    setSelected(label);
  };

  return (
    <Styled.SelectContainer
      onClick={() => setSelectedList((prev) => !prev)}
      className="library"
    >
      <Styled.Input readOnly value={selected} ref={inputRef} />
      <StyledLibrarySearch.SearchList show={selectedList}>
        <li
          onClick={() =>
            onSelect("ALL", t("searchSection.filterOptions.option1"))
          }
        >
          {t("searchSection.filterOptions.option1")}
        </li>
        {selectData &&
          selectData.map(({ value, label }, index) => {
            return (
              <li key={index} onClick={() => onSelect(value, label)}>
                {label}
              </li>
            );
          })}
      </StyledLibrarySearch.SearchList>
    </Styled.SelectContainer>
  );
};

export default LibrarySelect;

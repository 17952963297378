import React, { useState } from 'react';
import styled from 'styled-components';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';
import myClassroomApi from 'api/myClassroom';
import bookmarksApi from 'api/bookmarks';
import themesApi from 'api/theme';
import { Layout, MyClass } from 'container/ClassroomPage/styled';
import CoursesListPage from 'container/ClassroomPage/MyClassroom/CoursesList';
import BookmarkListPage from 'container/ClassroomPage/MyClassroom/BookmarkList';
import MyThemeList from 'container/UserBookmarkPage/Theme/MyThemeList';
import InterestedTheme from 'container/ClassroomPage/MyClassroom/InterestedTheme';
import contentsApi from 'api/contents';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin-bottom: 15em;
`;

const Index = () => {
  const { t } = useTranslation('classroomPage');
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.data);
  const watches = useAxios(contentsApi.getWatchedList, [], true);
  const complete = useAxios(myClassroomApi.getComplete, [], true);
  const bookmarks = useAxios(bookmarksApi.getListOfUser, [], true);
  const myTheme = useAxios(themesApi.getMyList, [], true);
  const interestedTheme = useAxios(themesApi.getInterestedThemeList, [], true);

  const [coursesWatched, setCoursesWatched] = useState(0);
  const [coursesCompleted, setCoursesCompleted] = useState(0);
  const [bookmarkedCourses, setBookmarkedCourses] = useState(0);
  const [myThemes, setMyThemes] = useState(0);
  // {user.fullName}’{t('myClassroomTab.statusBoard.title')}
  return (
    <Wrapper>
      <Layout.Wrapper>
        <Layout.H2>
        <Trans t={t} values={{ name:user.fullName }}>
          myClassroomTab.statusBoard.title
        </Trans>
        </Layout.H2>
        <MyClass.MoWrap>
          <MyClass.Dashboard>
            <MyClass.ItemBox>
              <MyClass.Item>
                <MyClass.Title>
                  <Trans t={t}>myClassroomTab.statusBoard.current</Trans>
                </MyClass.Title>
                <MyClass.Count>{coursesWatched}</MyClass.Count>
              </MyClass.Item>
            </MyClass.ItemBox>
            <MyClass.ItemBox>
              <MyClass.Item>
                <MyClass.Title>
                  <Trans t={t}>myClassroomTab.statusBoard.completed</Trans>
                </MyClass.Title>
                <MyClass.Count>{coursesCompleted}</MyClass.Count>
              </MyClass.Item>
            </MyClass.ItemBox>
            <MyClass.ItemBox>
              <MyClass.Item>
                <MyClass.Title>
                <Trans t={t}>myClassroomTab.statusBoard.bookmarked</Trans>
                </MyClass.Title>
                <MyClass.Count>{bookmarkedCourses}</MyClass.Count>
              </MyClass.Item>
            </MyClass.ItemBox>
            <MyClass.ItemBox>
              <MyClass.Item>
                <MyClass.Title>
                <Trans t={t}>myClassroomTab.statusBoard.themes</Trans>
                </MyClass.Title>
                <MyClass.Count>{myThemes}</MyClass.Count>
              </MyClass.Item>
            </MyClass.ItemBox>
          </MyClass.Dashboard>
        </MyClass.MoWrap>
      </Layout.Wrapper>

      <Layout.Wrapper>
        <Layout.H2>
        <Trans t={t}>myClassroomTab.current</Trans>
        </Layout.H2>
        <CoursesListPage
          id='myClassroom-watches'
          initialState={watches}
          setTotal={setCoursesWatched}
          token={token}
        />
      </Layout.Wrapper>

      <Layout.Wrapper>
        <Layout.H2>
          <Trans t={t}>myClassroomTab.completed</Trans>
        </Layout.H2>
        <CoursesListPage
          id='myClassroom-complete'
          initialState={complete}
          setTotal={setCoursesCompleted}
          token={token}
        />
      </Layout.Wrapper>

      <Layout.Wrapper>
        <Layout.H2>{t('myClassroomTab.bookmarked')}</Layout.H2>
        <BookmarkListPage
          id='myClassroom-bookmark'
          initialState={bookmarks}
          setTotal={setBookmarkedCourses}
          token={token}
        />
      </Layout.Wrapper>

      <Layout.Wrapper>
        <Layout.H2>{t('myClassroomTab.themes')}</Layout.H2>
        <MyThemeList
          initialState={myTheme}
          setTotal={setMyThemes}
          token={token}
        />
      </Layout.Wrapper>
      <Layout.Wrapper>
        <Layout.H2>{t('myClassroomTab.interesting')}</Layout.H2>
        <InterestedTheme
          id='myClassroom-interestedTheme'
          initialState={interestedTheme}
          token={token}
        />
      </Layout.Wrapper>
    </Wrapper>
  );
};

export default Index;

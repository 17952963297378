import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import Img from 'component/atoms/Img';
import { IoIosArrowForward } from 'react-icons/io';

const AnalysisStyled = {
  Wrapper: styled(ContentsWrapper)`
    position: relative;
    padding-bottom: 25em;
    h2 {
      max-width: 90%;
    }
    @media (max-width: 767px) {
      max-width: 500px;
      padding-top: 4em;
      padding-bottom: 1em;
      h2 {
        max-width: 100%;
      }
    }
  `,
  StepList: styled.div`
    padding-top: 6em;
    counter-reset: analysis;
    &:after {
      content: '';
      clear: both;
      display: table;
    }
  `,
  StepItem: styled.div`
    counter-increment: analysis;
    position: relative;
    float: left;
    width: calc(50% - 2em);
    padding-left: 7em;
    text-align: left;
    &:first-child {
      margin-right: 4em;
    }
    @media (max-width: 767px) {
      width: 100%;
      padding-left: 0;
      padding-bottom: 7em;
      &:first-child {
        margin-right: 0;
      }
    }
  `,
  StepArrow: styled(IoIosArrowForward)`
    position: absolute;
    bottom: 50%;
    right: -0.8em;
    font-size: 6em;
    transform: scale(0.8, 1);
    @media (max-width: 767px) {
      display: none;
    }
  `,
  StepDesc: styled.div`
    font-size: 2em;
    min-height: 3em;
    margin-bottom: 1.5em;
    text-align: left;
    overflow: hidden;
    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 1rem;
      width: 4rem;
      height: 4rem;
    }
    &:before {
      content: '';
      border-radius: 1rem;
      transform: rotate(45deg);
      background-color: #fff;
    }
    &:after {
      content: counter(analysis);
      font-weight: bold;
      line-height: 3.7rem;
      text-align: center;
      color: #000;
    }
    @media (max-width: 1024px) {
      br {
        display: none;
      }
    }
    @media (max-width: 767px) {
      font-size: 2.4em;
      padding-left: 3.5em;
      min-height: 0;
    }
  `,
  StepImg: styled(Img)`
    @media (max-width: 1024px) {
      width: 70%;
      max-width: 400px;
    }
  `,
};

export default AnalysisStyled;

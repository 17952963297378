import React from 'react';
import { Layout } from 'container/LevelTestPage/styled';

/**
 * @param {object} param
 * @param {object} param.title
 * @param {object} param.text
 */
const LevelTestHeader = ({ title, text, fontSize }) => {

  return (
    <Layout.PageHeader>
      <Layout.PageHeaderH3
        style={{ fontSize: `${fontSize / 10}em` }}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {
        text ? (
          <Layout.PageHeaderDesc dangerouslySetInnerHTML={{ __html: text }} />
        ) : ''
      }
    </Layout.PageHeader>
  );
};

LevelTestHeader.defaultProps = {
  fontSize: 30,
};

export default LevelTestHeader;

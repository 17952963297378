import { asyncApi } from "common/util/asyncApi";

const url = "/api/v1/themes";
const size = 6;

/**
 * getRecommendPopularList 추천 인기 테마 목록 (단일 이미지 랜딩페이지)
 * getPopularList 인기 테마 목록 (3개 모아보기 이미지)
 * getRecommendList 카테고리 추천 테마 목록
 * getMyList 테마 개요 목록 (북마크 페이지 > 테마 탭)
 * getListByThemeId 테마 아이템 목록 (북마크 페이지 > 테마 탭, 테마 상세 페이지 > My theme)
 * getByThemeId
 * getInterestedThemeList 유저의 '좋아요'한 목록 (북마크 페이지 > 테마 탭 > Interested theme)
 * getLikedPopularList 좋아요 기반 인기순 테마 목록 (북마크 페이지 > 테마 탭 > Popular theme)
 * getListByCategory
 * postLike
 * deleteLike
 * postTheme 테마 생성
 * postThemeItems
 * putTheme
 * deleteTheme 테마 삭제
 * deleteThemeItems
 * getMyPageMyThemes 마이페이지 나의 테마 목록
 */
const themesApi = {
  getAllThemeList: async (props = { page: 0, token: null }) => {
    const { page, token } = props;
    let response = await asyncApi({
      url: url + "?q=",
      params: { page, size: 12 },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  getRecommendPopularList: async (page = 0) => {
    let response = await asyncApi({
      url,
      params: { recommends: "", popular: "", page },
    });
    return response.data;
  },
  getPopularList: async (props = { page: 0, token: null }) => {
    let response = await asyncApi({
      url,
      params: { popular: "", page: props.page, size },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getRecommendList: async (props = { page: 0, token: null }) => {
    let response = await asyncApi({
      url,
      params: { recommends: "", page: props.page, size: 12 },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getMyList: async (props = { token: null, page: 0 }) => {
    let response = await asyncApi({
      url,
      params: { mine: true, page: props.page, size: 8 },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getListByThemeId: async (props = { token: null, id: "", page: 0 }) => {
    let response = await asyncApi({
      url: `${url}/${props.id}`,
      params: { products: "", page: props.page, size },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getByThemeId: async (props = { token: null, id: "" }) => {
    let response = await asyncApi({
      url: `${url}/${props.id}`,
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getInterestedThemeList: async (props = { token: null, page: 0 }) => {
    let response = await asyncApi({
      url,
      params: { likes: "", page: props.page, size },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getLikedPopularList: async (props = { token: null, page: 0 }) => {
    let response = await asyncApi({
      url,
      params: { popular: "", page: props.page, size: 12 },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getListByCategory: async (
    props = { token: null, keyword: null, page: 0, size: 12 }
  ) => {
    let response = await asyncApi({
      url,
      params: { categories: props.keyword, page: props.page, size },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  postLike: async (props = { token: null, id: null }) => {
    let response = await asyncApi({
      method: "post",
      url: `${url}/${props.id}?likes`,
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  deleteLike: async (props = { token: null, id: null }) => {
    let response = await asyncApi({
      method: "delete",
      url: `${url}/${props.id}?likes`,
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.status;
  },
  postTheme: async (
    props = {
      categoryId: null,
      name: null,
      description: null,
      isOpen: null,
      token: null,
    }
  ) => {
    let response = await asyncApi({
      method: "post",
      url,
      data: {
        categoryId: props.categoryId,
        name: props.name,
        description: props.description,
        isOpen: props.isOpen,
      },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response;
  },
  postThemeItems: async (props = { id: null, productIds: [], token: null }) => {
    let response = await asyncApi({
      method: "post",
      url: `${url}/${props.id}/products`,
      data: [...props.productIds],
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response;
  },

  putTheme: async (
    props = {
      id: null,
      categoryId: null,
      name: null,
      description: null,
      isOpen: null,
      token: null,
    }
  ) => {
    let response = await asyncApi({
      method: "put",
      url: `${url}/${props.id}`,
      data: {
        categoryId: props.categoryId,
        name: props.name,
        description: props.description,
        isOpen: props.isOpen,
      },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.status;
  },
  deleteTheme: async (props = { id: null, token: null }) => {
    let response = await asyncApi({
      method: "delete",
      url: `${url}/${props.id}`,
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.status;
  },
  deleteThemeItems: async (
    props = { id: null, productId: null, token: null }
  ) => {
    let response = await asyncApi({
      method: "delete",
      url: `${url}/${props.id}/products`,
      data: [...props.productIds],
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.status;
  },
  getMyPageMyThemes: async (props = { page: 0, token: null }) => {
    let response = await asyncApi({
      url: `${url}?my-page`,
      params: { page: props.page, size: 8 },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getMyPageRecommends: async (props = { page: 0, token: null }) => {
    let response = await asyncApi({
      url: `${url}?recommends`,
      params: { page: props.page, size: 8 },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getSearchThemes: async (text, page = 0) => {
    const config = { url: url + `?q=${text}`, params: { page } };
    const response = await asyncApi(config);
    return response.data;
  },
};

export default themesApi;

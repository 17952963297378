import styled from 'styled-components';
import Img from 'component/atoms/Img';

const MainContentsStyled = {
  H2: styled.h2`
    font-size: 5em;
    margin-bottom: 0.3em;
    font-weight: 600;
    * {
      word-break: keep-all;
    }
    &:before {
      content: '';
      display: block;
      width: calc(1em + 10px);
      height: 2px;
      margin-bottom: 1em;
      background-color: #fff;
    }
    @media (max-width: 1024px) {
      line-height: 1.3;
    }
    @media (max-width: 767px) {
      font-size: 4.8em;
      &:before {
        content: none;
      }
    }
  `,
  ImgPc: styled(Img)`
    display: none;
    @media (min-width: 1025px) {
      display: block;
    }
  `,
  ImgMobile: styled(Img)`
    display: block;
    @media (min-width: 1025px) {
      display: none;
    }
  `,
};

export default MainContentsStyled;

import courseDetailType from './type';

const initialState = {
  currentVideoUrl: null,
  videoUrl: null,
  selectNumber: 0,
};

const courseDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case courseDetailType.Set_Current_Video_Url:
      return {
        ...state,
        currentVideoUrl: action.payload,
      };

    case courseDetailType.Set_Video_Url:
      return {
        ...state,
        videoUrl: action.payload,
      };

    case courseDetailType.Set_Select_Number:
      return {
        ...state,
        selectNumber: action.payload,
      };

    default:
      return state;
  }
};

export default courseDetailReducer;

import styled from "styled-components";

const StylePaymentModal = {
  Container: styled.div`
    p {
      text-align: center;

      font-size: 1.6em;
      font-weight: 400;
    }
    > .desc {
      margin: 0.71em 0 2.14em;
      text-align: center;
      color: #666;
      font-size: 1.4em;
      font-weight: 400;
    }
    @media (max-width: 767px) {
      font-size: 1.25em;
    }
  `,
  Title: styled.h3`
    text-align: center;
    margin-bottom: 1.8em;
    font-size: 2.4em;
    font-weight: 700;
    @media (max-width: 767px) {
      margin-top: 1em;
    }
  `,
  CardContainer: styled.div`
    display: flex;
    > div {
      flex: 1;
    }
  `,
  Card: styled.div`
    display: inline-block;
    margin-right: 1em;
    padding: 2em 1.8em 3em 1.8em;
    text-align: center;
    border-radius: 0.6em;
    border: 1px #d1d1d1 solid;
    cursor: pointer;
    &:last-of-type {
      margin-right: 0;
    }
    > span {
      position: relative;
      display: inline-block;
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
      background-color: #999ba0;

      color: #fff;
      > svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    > div {
      color: #999;
      font-size: 1.6em;
      font-weight: 700;
    }

    &.active {
      > span {
        background-color: #485cc7;
      }
      > div {
        color: #222;
      }
    }
  `,
  ButtonContainer: styled.div`
    text-align: center;
    margin: 3em 0;
    button {
      width: 61%;
      padding: 1.25em 0;
      border-radius: 0.375em;
      background: #ffb500;
      color: #fff;
      font-size: 1.6em;
      font-weight: 700;
      text-transform: uppercase;
    }
  `,
};

export default StylePaymentModal;

import styled from 'styled-components';
import { VscClose } from 'react-icons/vsc';

const StyledModal = {
  Fixer: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    overflow: auto;

    animation-name: fade-in;
    animation-duration: 0.3s;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `,
  Wrapper: styled.div`
    width: 100%;
    height: 100%;
    padding: 4em 20px;
    table-layout: fixed;
    display: table;
  `,
  Inner: styled.div`
    display: table-cell;
    vertical-align: middle;
  `,
  Box: styled.div`
    position: relative;
    z-index: 9;
    max-width: ${({ maxWidth }) => maxWidth}px;
    padding: 3em;
    margin: auto;
    background-color: #fff;
    border-radius: 6px;
    color: #000;
    @media (max-width: 767px) {
      padding: 20px;
    }
  `,
  Header: styled.div`
    padding-bottom: 3.5em;
  `,
  H2: styled.h2`
    font-size: 2.4em;
    padding: 0 1em;
    margin-bottom: 1.25em;
    font-weight: 600;
    text-align: center;
    span {
      display: inline-block;
    }
  `,
  Text: styled.p`
    font-size: 1.8em;
    margin: -1em 0 2em;
    font-weight: 400;
    text-align: center;
  `,
  Close: styled.button.attrs({
    type: 'button',
    children: <VscClose />,
  })`
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 30px;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    &.closeByBackground:before {
    }
    @media (max-width: 767px) {
      top: 7px;
      right: 7px;
    }
  `,
  CloseLayer: styled.div`
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100vw;
    height: 100vh;
    cursor: default;
  `,
};

export default StyledModal;

import styled from 'styled-components';

const Styled = {
  ProductItem: styled.div`
    padding: 0.7em 2em;
    border-radius: 0.4em;
    border: 2px solid #485cc7;
    cursor: pointer;
    font-size: 2.6em;
    overflow: hidden;
    color: #485cc7;
    .product {
      float: left;
      display: flex;
      align-items: center;
    }
    .price {
      float: right;
      display: flex;
      align-items: center;
      font-weight: 700;
    }
    .info {
      display: inline-block;
      margin-left: 1em;
      padding: 0.5em 1.2em;
      border-radius: 2em;
      background: #2c2e32;
      line-height: 1.1em;
      font-size: 0.78em;
      color: #fff
    }
    .save {
      display: inline-block;
      margin-right: 0.8em;
      padding: 0.3em 0.8em;
      border-radius: 2em;
      border: 1px solid #e26b13;
      line-height: 1.1em;
      font-size: 0.7em;
      color: #e26b13;
    }
    & + & {
      margin-top: 1em;
    }
    @media (min-width: 1025px) {
      &:hover, &.active {
        background: #485cc7;
        color: #fff;
        .info {
          background: #fff;
          color: #0f1012
        }
      }
    }
    &.modal {
      padding: 0.8em 1.6em;
      font-size: 2.4em;
      border-color: #d1d1d1;
      background-color: #f7f8fd;
      & + & {
        margin-top: 0.8em;
      }
      .tit {
        font-size: 0.8em;
      }
      .info {
        margin-left: 0.5em;
        padding: 0.4em 1em;
        background-color: #adaeb3;
      }
      @media (min-width: 1025px) {
        &:hover, &.active {
          border-color: #485cc7;
          background-color: #485cc7;
          color: #fff;
          .info {
            background: #fff;
            color: #0f1012
          }
        }
      }
    }

    /* mobile */
    @media (max-width: 1024px) {
      padding: 0.7em 1em;
      border-radius: 0.2em;
      font-size: 2.4em;
      .product {
        float: none;
        display: block;
        text-align: left;
        .tit {
          margin-bottom: 0.2em;
          font-weight: 700;
          font-size: 1.2em;
        }
        .info {
          margin: 0;
        }
      }
      .price {
        float: none;
        flex-wrap: wrap;
        justify-content: right;
        margin-top: 0.9em;
        color : #fff;
      }

      &.modal{
        padding: 0.7em 1em;
        border-radius: 0.2em;
        font-size: 2.4em;
        border-color: #485cc7;
        .price {
          color: #485cc7;
        }
      }
    }
  `,
};

export default Styled;

import styled from 'styled-components';
import { BsCheck } from 'react-icons/bs';

const StyledPlanCard = {
  Container: styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    border-radius: 0.6em;
    background-color: #2b2e35;
    text-align: center;
    & + & {
      margin-top: 3em;
    }
  `,
  Inner: styled.div`
    border-radius: 0.6em;
    border: 3px solid #2b2e35;
    padding: 4em;
    .active & {
      border-color: #ffb500;
    }
  `,
  BestTag: styled.span`
    position: absolute;
    top: -3em;
    left: -5em;
    width: 11em;
    padding-top: 4.5em;
    padding-bottom: 0.5em;
    background-color: #ff3867;
    text-align: center;
    font-size: 1.8em;
    font-weight: 700;
    transform: rotate(-45deg);
    color: #2b2e35;
  `,
  Title: styled.div`
    margin-bottom: 0.85em;
    font-size: 2.8em;
    font-weight: bold;
    text-transform: uppercase;
    color: ${({ color }) => color && color};
    .active & {
      color: #ffb500;
    }
  `,
  DescWrap: styled.div`
    display: inline-block;
  `,
  Desc: styled.div.attrs(({ children }) => ({
    children: (
      <>
        <BsCheck className='ico' />
        {children}
      </>
    ),
  }))`
    position: relative;
    padding-left: 1.5em;
    text-align: left;
    font-size: 1.8em;
    color: #fff;
    & + & {
      margin-top: 0.5em;
    }
    span {
      font-weight: bold;
    }
    p {
      color: #999;
    }
    .ico {
      position: absolute;
      left: 0;
      top: 0.05em;
      font-size: 1.3em;
      color: #fff;
      .active & {
        color: #ffb500;
      }
    }
  `,
  Price: styled.div`
    margin-top: 3.6em;
    .price {
      font-weight: bold;
      font-size: 3em;
      .active & {
        color: #ffb500;
      }
    }
    .month {
      font-weight: 300;
      font-size: 1.8em;
      .active & {
        color: #ffb500;
      }
    }
    .free {
      margin-top: 0.5em;
      font-size: 1.6em;
      color: #8e9199;
    }
  `,
};

export default StyledPlanCard;

import styled from "styled-components";
import { Swiper } from 'swiper/react';
import topBannerBgImg from 'assets/images/event/220512/topbanner_bg.png';
import visualImg from 'assets/images/event/220512/visual.jpg';
import visualImgKo from 'assets/images/event/220512/ko/visual.jpg';
import helpImg from 'assets/images/event/220512/help.png';
import bookImg from 'assets/images/event/220512/book.png';
import linkIcon from 'assets/images/event/220512/bl_arrow.png';
import linkIcon2 from 'assets/images/event/220512/bl_arrow2.png';
import checkIcon from 'assets/images/event/220512/ico_check.png';

const StyledEventPage = {
	SwiperWrap: styled.div`
		margin-top: 2em;
	`,
	Swiper: styled(Swiper)`
		position: relative;
		.swiper-button-prev,
		.swiper-button-next {
			position: absolute;
			z-index:10;
		}
		.swiper-button-prev {
			left:0;
		}
		.swiper-button-next {
			right: 0;
		}
	`,
	thumb: styled.div`
		z-index:10;

		&.hidden-poster {
			display:none;
		}
	`,
	EventWrap: styled.div`
		font-size: 1.8rem;
		line-height: 1.2;
		background:#fff;
		font-weight:400;
		text-align:center;
		color:#555;
		letter-spacing: -0.035em;

		.blind {
			overflow:hidden;
			position: absolute;
			clip:rect(0 0 0 0);
			width:1px;
			height:1px;
			margin:-1px;
		}

		.inner {
			max-width : 790px;
			margin: auto;
			padding: 5em 20px;
			font-size: 2rem;
		}

		h2 {
			margin-bottom: 2em;
			span {
				display: inline-block;
				padding: 0.24em 0.8em;
				border: 2px solid #55569e;
				border-radius: 1.2em;
				font-weight: 700;
				font-size: 1.4em;
				color: #272b9e;
			}
		}

		.topBanner {
			background:url(${topBannerBgImg}) 0 0 repeat;
			padding: 1.2em 0;
			font-weight: 700;
			font-size: 2.2rem;
			color: #030051;
		}

		.visual {
			position:relative;
			width: 100%;
			height: 1300px;
			background-color: #3c358a;
			background-image: url(${visualImg});
			background-repeat: no-repeat;
			background-position: 50% 0;
			background-size: auto 100%;
			.inner {
				position:relative;
				padding: 1130px 0 0 0;
				.btnWrap {
					position:relative;
					display: flex;
					width: 735px;
					margin: 0 auto;
					li {
						flex: 1;
						text-align : center;
						a {
							display: inline-block;
							box-sizing: border-box;
							width: 78%;
							padding: 0.4em 0.4em 0.5em;
							border-radius: 1em;
							background: #fe6c17;
							font-weight: 700;
							font-size: 1.5em;
							color: #fff;
						}
					}
				}
			}
		}
		.visual.ko {
			background-image: url(${visualImgKo});
		}

		.section1 {
			background: #f2eadf;
			.overview {
				.tab{
					display:table;
					width: 100%;
					height: 100%;
					li {
						display: table-cell;
						width: 50%;
						a {
							display: block;
							height: 100%;
							padding: 0.9em 0.45em 0.65em;
							letter-spacing: -0.05em;
							font-size: 1.4em;
							color: #fff;
						}
						&:first-child a {
							background: #4e53cb;
						}
						&:last-child a {
							background: #248832;
						}
						& a.active {
							font-weight: 700;
						}
					}
				}
				.tabContent {
					position: relative;
					> div {
						padding: 2.5em 1.75em;
						background: #fff;
						text-align: left;
						font-weight: 400;
						color: #030051;
					}
					#tab1 {
						position: relative;
						border: 6px solid #4e53cb;
					}
					#tab2 {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						border: 6px solid #248832;
					}
					dl {
						display: flex;
						flex-wrap: wrap;
						margin-bottom: 1.4em;
						font-size: 1.4em;
						dt {
							position: relative;
							box-sizing: border-box;
							width: 8em;
							margin-bottom: 1em;
							padding-left: 0.6em;
							font-weight: 700;
							&:after {
								content: "";
								display: block;
								position: absolute;
								top: 0;
								left: 0;
								margin-top: 0.1em;
								width: 0.2em;
								height: 0.9em;
								background: #3a43c4;
							}
						}
						dd {
							width: calc(100% - 8em);
							strong {
								font-weight: 700;
							}
							span {
								color: #647a99;
							}
						}
					}
					ol {
						li {
							position: relative;
							margin-top: 1.5em;
							padding-left: 6em;
							&:first-child {
								margin-top: 0;
							}
							em {
								position: absolute;
								top: 0;
								left: 0;
								display: block;
								padding: 0.4em 0.8em;
								border-radius: 1em;
								background : #3a43c4;
								font-size: 1.1em;
								color: #fff;
							}
							h3 {
								margin-bottom: 0.3em;
								letter-spacing: -0.08em;
								font-weight: 700;
								font-size: 1.6em;
								color: #383878;
							}
							> div {
								letter-spacing: -0.04em;
								font-size: 1.4em;
							}
							a {
								position: relative;
								display: inline-block;
								margin-top: 0.3em;
								padding-right: 0.8em;
								text-decoration: underline;
								font-weight: 700;
								font-size: 1.2em;
								color: #fe6c17;
								&:after {
									content: "";
									display: block;
									position: absolute;
									top: 0;
									right: 0;
									width: 0.8em;
									height: 100%;
									background: url(${linkIcon}) no-repeat 100% 55%;
									background-size: 0.5em auto;
								}
							}
						}
					}
				}
				.block {
					z-index: 2;
				}
				.hide {
					z-index: 1;
				}
			}
			.question {
				padding: 2em 0;
				background: #2b2b69 url(${helpImg}) no-repeat 8% 100%;
				background-size: 18% auto;
				h3 {
					padding: 0 1em;
					font-weight: 700;
					font-size: 1.6em;
					color: #fff;
				}
				.list {
					margin-top: 1em;
					padding: 0 5% 0 30%;
					text-align: left;
					li {
						position: relative;
						padding-left: 1em;
						letter-spacing: -0.04em;
						font-size: 1.4em;
						color: #d3d4f3;
						span {
							color: #6cff33;
						}
						&:after {
							content: "";
							display: block;
							position: absolute;
							top: 0;
							left: 0;
							width: 0.78em;
							height: 100%;
							background: url(${checkIcon}) no-repeat 0 10%;
							background-size: 0.78em auto;
						}
						&+li {
							margin-top: 0.5em;
						}
						a {
							position: relative;
							display: inline-block;
							margin-top: 0.6em;
							padding: 0.34em 1.38em 0.34em 0.34em;
							background: #393973;
							border: 1px solid #8080a5;
							font-size: 0.857em;
							&:after {
								content: "";
								display: block;
								position: absolute;
								top: 0;
								right: 0.3em;
								width: 0.8em;
								height: 100%;
								background: url(${linkIcon2}) no-repeat 100% 55%;
								background-size: 0.5em auto;
							}
						}
					}

				}
			}
		}

		.section2 {
			background: #f2eadf;
			.inner {
				padding: 0 20px;
				.desc {
					margin-bottom: 1em;
					font-size: 1.5em;
					color: #030051;
				}
				.img div {
					padding: 0.6em;
					background: #4e53cb;
					letter-spacing: -0.05em;
					font-weight: 700;
					font-size: 1.7em;
					color: #ffff00;
				}
			}
		}

		.section3 {
			background: #c0ffca;
			h2 span {
				border-color: #43a050;
				color: #177524;
			}
			.desc {
				margin-bottom: 2em;
				letter-spacing: -0.1em;
				color: #030051;
				p {
					margin-bottom: 2rem;
					font-weight: 700;
					font-size: 1.9em;
				}
				div {
					font-size: 1.3em;
				}
			}
			.list {
				position: relative;
				box-sizing: border-box;
				display: table;
				width: 100%;
				min-height: 440px;
				padding: 2em 3% 2em 45%;
				background: #248832 url(${bookImg}) no-repeat 0 30%;
				background-size: 46% auto;
				ul{
					display: table-cell;
					vertical-align: middle;
					li {
						position: relative;
						margin-top: 0.8em;
						padding-left: 1.4em;
						text-align: left;
						font-weight: 700;
						font-size: 1.6em;
						color: #fff;
						&:first-child {
							margin-top: 0;
						}
						em {
							position: absolute;
							top: 0.15em;
							left: 0;
							display: block;
							width: 1.4em;
							height: 1.4em;
							border-radius: 0.7em;
							background: rgba(37, 202, 107, 0.7);
							text-align: center;
							font-weight: 300;
							font-size: 0.8em;
							color: #137120;
						}
					}
				}
			}
		}

		.notification {
			background: #2b2b69;
			text-align:left;
			padding: 4.5em 0;
			color:#fff;
			font-weight:300;
			letter-spacing:normal;
			.inner {
				max-width: 790px;
				padding: 0 20px;
			}
			h3 {
				text-align: center;
				font-size: 2.8rem;
				font-weight: 700;
			}
			dl {
				line-height: 3.6rem;
				font-size: 2.4rem;
			}
			dt {
				padding: 3.6rem 0 0;
				font-weight:700;
			}
			dd {
				a {
					font-weight:400;
					text-decoration:underline;
				}
			}
			P {
				padding: 1.6em 0 0.8em;
				a {
					text-decoration:underline;
				}
			}
		}

		.shareWrap {
			background:#5cd4ec;
			.inner {
				max-width: 790px;
				display:flex;
				align-content: space-between;
				align-items: center;
				padding: 0.86em 20px;
				text-align:left;
				letter-spacing: -0.06em;
				font-weight: 400;
				font-size: 2.8rem;
				color: #1b2a6d;
				&:after {
					content:"";
					display:block;
					clear:both;
				}
				strong {
					display:inline-block;
					font-weight:700;
				}
				.btn {
					display:block;
					flex:1 1 0;
					text-align:right;
					a {
						display:inline-block;
						width:5.5em;
						height:2.154em;
						img {
							width:100%;
						}
					}
				}
			}
		}

		@media (max-width: 1024px) {
			.inner {
				font-size: 1.8rem;
			}
		}

		@media (max-width: 790px) {
			.inner {
				font-size: 1.6rem;
			}
			.visual {
				height: 164.556vw;
				.inner {
					padding-top: 143.037vw;
					.btnWrap {
						width: 100%;
						padding: 0 3.544vw;
					}
				}
			}
			.section3 {
				.list {
					min-height: 50vw;
				}
			}
		}
		@media (max-width: 390px) {
			.inner {
				font-size: 1.4rem;
			}
		}
  `,
};

export default StyledEventPage;

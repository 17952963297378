import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Styled from '../styled/ProductItem.style';

import { ModalContext } from 'component/Modal/modalContext';
import ProductModal from './ProductModal';

/**
 * 
 * @param {*} props 
 * @param {boolean} modal     모달창인지 여부
 * @param {boolean} isSelected 아이템 선택되었는지
 * @param {number} productIdx 상품 코드
 * @param {string} title      수업 제목
 * @param {string} round      수업 시수
 * @param {string} duration   수업 시간
 * @param {number} priceCost    정가
 * @param {number} salesCost    판매가
 * @param {string} discountType 할인 타입 PER | CST
 * @param {number} discountCost 할인 금액
 * @param {string} onClick    클릭
 */
const ProductItem = (props) => {
  let { handleModal } = React.useContext(ModalContext);
  const [cost, setCost] = useState('');
  const [discount, setDiscount] = useState('');
  const language = localStorage.getItem('i18nextLng');

  useEffect(()=>{
    if(!props) return;
    const {salesCost, discountType, discountCost} = props;

    //정가
    if(salesCost){
      if(language === 'ko-KR') setCost(`KRW ￦${salesCost.toLocaleString('ko-KR')}`);
      else {
        if(salesCost%1 !== 0) setCost(`USD $${salesCost}`);
        else setCost(`USD $${salesCost}.00`);
      }
    }

    //할인율/할인금액
    if(discountType && discountCost){
      if(discountType === 'PER'){
        setDiscount(`${discountCost*100}`);
      }else if(discountType === 'CST'){
        if(language === 'ko-KR') setDiscount(`￦${discountCost.toLocaleString('ko-KR')}`);
        else setDiscount(`$${discountCost}`);
      }
    }
  }, [props]);

  const clickProduct = () => {
    if(props && props.onClick) props.onClick(props.productIdx);
  }
  
  return (
    <>
      {props
      ? <Styled.ProductItem 
          onClick={clickProduct} 
          className={(props.modal? "modal" : "") + (props.isSelected ? " active" : "")}
        >
          <div className='product'>
            <div className='tit'><strong>{props.title}</strong></div>
            <div className='info'><strong>{props.round}</strong> Lessons / <strong>{props.duration}</strong> min</div>
          </div>
          <div className='price'>
            {props.discountType && props.discountCost
            ? <div className='save'>SAVE {discount}%</div>
            : <></>}
            <div>{cost}</div>
          </div>
        </Styled.ProductItem>
      : <></>}
    </>
  );
};

export default ProductItem;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languageCode } from 'common/constant';
import { termsOfServiceKo0915 } from 'container/TermsPage/contents/2023/termsOfServiceKo0915';
import { termsOfServiceEn0915 } from 'container/TermsPage/contents/2023/termsOfServiceEn0915';
import { termsOfServiceZh0915 } from 'container/TermsPage/contents/2023/termsOfServiceZh0915';
import { termsOfServiceJa0915 } from 'container/TermsPage/contents/2023/termsOfServiceJa0915';

const Service20230915 = () => {
  const { i18n } = useTranslation('privacyPage');
  const { KOREAN, CHINESE, JAPANESE } = languageCode;
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!i18n?.language) return;

    switch (i18n.language) {
      case KOREAN.locale:
        setContent(termsOfServiceKo0915);
        break;
      case CHINESE.locale:
        setContent(termsOfServiceZh0915);
        break;
      case JAPANESE.locale:
        setContent(termsOfServiceJa0915);
        break;
      default:
        setContent(termsOfServiceEn0915);
        break;
    }
  }, [i18n.language]);

  return <>{content}</>;
};

export default Service20230915;
import React, { useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import LevelTestHeader from 'container/LevelTestPage/common/LevelTestHeader';
import checkState from 'container/LevelTestPage/common/checkState';
import Step from 'container/LevelTestPage/common/Step';
import { Layout, Button } from 'container/LevelTestPage/styled';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const { t } = useTranslation('levelTestPage');

  checkState();

  const levelData = [
    {
      id: 0,
      level: 'LV.0',
      step: 'STEP 0',
      desc: t('checkLevel.chooseLevel.level0')
    },
    {
      id: 1,
      level: 'LV.1',
      step: 'STEP 1',
      desc: t('checkLevel.chooseLevel.level1')
    },
    {
      id: 2,
      level: 'LV.2',
      step: 'STEP 2',
      desc: t('checkLevel.chooseLevel.level2')
    },
    {
      id: 3,
      level: 'LV.3',
      step: 'STEP 3',
      desc: t('checkLevel.chooseLevel.level3')
    },
    {
      id: 4,
      level: 'LV.4',
      step: 'STEP 4',
      desc: t('checkLevel.chooseLevel.level4')
    },
    {
      id: 5,
      level: 'LV.5',
      step: 'STEP 5',
      desc: t('checkLevel.chooseLevel.level5')
    },
    {
      id: 6,
      level: 'LV.6',
      step: 'STEP 6',
      desc: t('checkLevel.chooseLevel.level6')
    },
  ];

  const history = useHistory();
  const { state } = useLocation();

  const [checked, setChecked] = useState({
    id: null,
    step: null,
    desc: null
  });

  const handleClick = () => {
    if (checked.id === null) return;

    history.push({
      pathname: '/level-test/select-target',
      state: {
        ...state,
        languageLevel: checked.id
      },
    });
  };

  return (
    <>
      <Step activeIndex={2} />
      <LevelTestHeader
        title={t('checkLevel.chooseLevel.title')}
      />
      <Button.SelectedLevelGroup>
        <Button.SelectedLevel disabled="disabled" className="disabled">
          <span>MT LOGO</span>
        </Button.SelectedLevel>
        {levelData.map((btnVal) => {
          const { id, level, step, desc} = btnVal;
          return(
            <Button.SelectedLevel
              key={id}
              className={checked.id === id ? 'active' : ''}
              onClick={() => setChecked({...checked, id, step, desc})}
            >
              {level}
            </Button.SelectedLevel>
          )
        })}
      </Button.SelectedLevelGroup>

      <Layout.SelectedLevelTxt>
        {checked.step
          ? (
            <>
              <span>{checked.step}</span>
              {checked.desc}
            </>
          ) : ''
        }
      </Layout.SelectedLevelTxt>

      <Button.ButtonGroup className='center'>
        <Button.Button onClick={handleClick}>{t('checkLevel.chooseLevel.button')}</Button.Button>
        <Button.ReturnLink to='/level-test'>{t('goBack')}</Button.ReturnLink>
      </Button.ButtonGroup>
    </>
  );
}

export default Index;

import React, { useState, useRef } from 'react';
import Start from 'container/MockTestPage/Start';
import TestPage from './TestPage/index';
import WritePage from 'container/MockTestPage/WritePage';
import { Helmet } from 'react-helmet';
export const MockTestContext = React.createContext();

const MockTestPage = () => {
  const [currentPage, setCurrentPage] = useState('start');
  const submitBtnRef = useRef(null);
  const saveBtnRef = useRef(null);
  const [mockTestStore, setMockTestStore] = useState({
    testTime: '',
    mockTestAnswerRequest: [],
    submitBtnRef: submitBtnRef,
    saveBtnRef: saveBtnRef,
  });
  const testType = {
    start: <Start setCurrentPage={setCurrentPage} />,
    test: <TestPage />,
    writeTest: <WritePage />,
  };
  return (
    <>
      <Helmet><script>zE('webWidget', 'hide');</script></Helmet>
      <MockTestContext.Provider value={{ mockTestStore, setMockTestStore }}>
        {testType[currentPage]}
      </MockTestContext.Provider>
    </>
  );
};

export default MockTestPage;

const validation = (formState) => {
  let errors = {};

  //name
  if (formState.name !== undefined && !formState.name.trim()) {
    errors.name = 'Username required';
  }

  //mobile
  if (formState.mobileSecond !== undefined && !formState.mobileSecond.trim()) {
    errors.mobile = 'mobile required';
  } else if (
    formState.mobileSecond.length !== 4 ||
    formState.mobileLast.length !== 4
  ) {
    errors.mobile = 'mobile number is incorrect';
  }

  if (formState.mobileLast !== undefined && !formState.mobileLast.trim()) {
    errors.mobile = 'mobile required';
  }
  //address
  if (formState.zoneCode !== undefined && !formState.zoneCode.trim()) {
    errors.address = 'address required';
  } else if (
    formState.addressLast !== undefined &&
    !formState.addressLast.trim()
  ) {
    errors.address = 'second address is required';
  }

  if (!formState.terms) {
    errors.terms = 'Please check your Agreement';
  }
  return errors;
};
export default validation;

import React, { useEffect } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import CourseMockTest from 'container/CoursePage/MockTestTab';
import VideoTab from 'container/CoursePage/VideoTab';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { ModalContext } from 'component/Modal/modalContext';
import CourseEPS from 'container/CoursePage/CourseEPS';

export default function CoursesRoutes() {
  const { path } = useRouteMatch();
  let { handleModal } = React.useContext(ModalContext);

  const { pathname } = useLocation();
  useEffect(() => {
    console.log('pathpathpath', pathname);
    if (pathname !== `${path}/eps-topik`) return;
    handleModal(<CourseEPS />, true, 1060);
  }, []);

  return (
    <>
      {pathname === '/courses/topik-mock-test' ? (
        <ContentsWrapper>
          <Route path={`${path}/topik-mock-test`} component={CourseMockTest} />
        </ContentsWrapper>
      ) : (
        <Route path={[path, `${path}/eps-topik`]} component={VideoTab} />
      )}
    </>
  );
}

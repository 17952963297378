import React, { useState } from 'react';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';
import Loading from 'component/atoms/Loading';
import themesApi from 'api/theme';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import ThemeItemList from './ThemeItemList';
import ThemeDetailHeader from '../ThemeDetailHeader';
import styled from 'styled-components';
import PreviousLink from 'component/atoms/PreviousLink';
// import PreviousLink from 'component/atoms/PreviousLink';
// import RelatedTagItemList from 'container/ThemeDetailPage/ThemeDetailByUser/RelatedTagItemList';

const Wrapper = styled(ContentsWrapper)`
  padding-bottom: 15rem;
`;

const ThemeDetailByUser = () => {
  const token = useSelector((state) => state.user.token);
  const themeState = useAxios(themesApi.getByThemeId, [], true);
  const themeListState = useAxios(themesApi.getListByThemeId, [], true);
  const relatedThemeListState = useAxios(themesApi.getListByCategory, [], true);

  const selectableState = useState(false);
  const selectedListState = useState([]);

  const [tag, setTag] = useState('');
  const [isHeaderLoading, setIsHeaderLoading] = useState(true);
  const [isListLoading, setIsListLoading] = useState(true);

  return (
    <>
      <Wrapper>
        <PreviousLink text='Go Back' />

        {isHeaderLoading && isListLoading && <Loading />}

        <ThemeDetailHeader
          initialState={{ themeState, themeListState }}
          token={token}
          setIsLoading={setIsHeaderLoading}
          setTag={setTag}
          selectableState={selectableState}
          selectedListState={selectedListState}
        />

        <ThemeItemList
          initialState={themeListState}
          token={token}
          setIsLoading={setIsListLoading}
          selectableState={selectableState}
          selectedListState={selectedListState}
        />
      </Wrapper>

      {/* <RelatedTagItemList
        initialState={relatedThemeListState}
        token={token}
        tag={tag}
      /> */}
    </>
  );
};

export default ThemeDetailByUser;

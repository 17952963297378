import React, { useEffect } from "react";
import styled from 'styled-components';
import { Bar } from "react-chartjs-2";
import { useAxios } from "common/hook/useAxiosHook";
import mockTestReportApi from "api/mockTestReport";
import { useSelector } from "react-redux";

const OverallStackBarChart = ({ topikType }) => {
  const token = useSelector((state) => state.user.token);
  const [{ data: yearsData }] = useAxios(() => mockTestReportApi.getYears(token), []);
  const [{ data: chartData, loading }, refreshGetData] = useAxios(mockTestReportApi.getOverallChartDataType, [], true);
  useEffect(() => {
    refreshGetData(token, topikType);
  }, [topikType]);
  const sortTestDate = chartData?.sort((a, b) => {
    const dateCheck = (date) => new Date(date).getTime();
    return dateCheck(a.testDate) - dateCheck(b.testDate);
  });

  let topikData = [];
  sortTestDate?.map((item) => {
    if (topikType === 11 && item.levelType === topikType) topikData.push(item);
    else if (topikType === 12 && item.levelType === topikType) topikData.push(item);
  });
  const options = {
    offsetGridLines: true,
    drawTicks: false,
    layout: {
      padding: {
        top: 30,
        right: 40,
        bottom: 40,
      },
    },
    tooltips: {
      mode: "index",
      yAlign: "bottom",
      alignment: "center",
      backgroundColor: "#1e2024",
      borderColor: "#5D6165",
      borderWidth: 1,
      callbacks: {
        //툴팁 설정
        title: function (yAxis) {
          return `${sortTestDate[yAxis[0].index].totalGrade} ${sortTestDate[yAxis[0].index].totalScore
            }`;
        },
        label: function () {
          return null;
        },
      },
    },
    legend: {
      display: true,
      position: "top",
      align: "end",
      labels: {
        usePointStyle: true,
        boxWidth: 7,
        boxHeight: 7,
        fontColor: "#fff",
        fontSize: 13,
        padding: 20,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          ticks: {
            padding: 5,
            fontColor: "#fff",
            fontSize: 14,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: false,
            color: "#56585B",
            zeroLineColor: "#56585B",
          },
          categoryPercentage: 0.7,
          barPercentage: 0.9,

          ticks: {
            beginAtZero: true,
            padding: 20,
            fontColor: "#fff",
            fontSize: 14,
          },
        },
      ],
    },
  };

  //차트 data로 구조 변경
  const data = topikData && {
    // x axis 텍스트
    labels: topikData.map((chart, index) => {
      return `${chart.testDate.substr(0, 4)} ${index + 1}회차`;
    }),
    type: {
      dataSet: topikData.map((data) => {
        return data.levelType;
      }),
    },
    // bar graph data
    read: {
      dataSet: topikData.map((data) => {
        return data.readingScore;
      }),
    },
    write: {
      dataSet: topikData.map((data) => {
        return data.writingScore;
      }),
    },
    listen: {
      dataSet: topikData.map((data) => {
        return data.listeningScore;
      }),
    },
  };
  if (topikData)
    return (
      <div className="chart-container">
        <div className="select-container">
        </div>
        <div className="chart">
          {topikType === 11 ?
            <Bar
              pointStyle="star"
              height={410}
              width={50}
              data={{
                labels: data.labels,
                responsive: true,
                offset: true,
                datasets: [
                  {
                    label: "읽기",
                    pointStyle: "rectRounded",
                    backgroundColor: "#00acb2",
                    barThickness: 20,
                    categoryPercentage: 1,
                    data: data.read.dataSet,
                  },
                  {
                    label: "듣기",
                    backgroundColor: "#80aa00",
                    barThickness: 20,
                    categoryPercentage: 1,
                    pointStyle: "triangle",
                    data: data.listen.dataSet,
                  },
                ]
              }}
              options={options}
            />
            :
            <Bar
              pointStyle="star"
              height={410}
              width={50}
              data={{
                labels: data.labels,
                responsive: true,
                offset: true,
                datasets: [
                  {
                    label: "읽기",
                    pointStyle: "rectRounded",
                    backgroundColor: "#00acb2",
                    barThickness: 20,
                    categoryPercentage: 1,
                    data: data.read.dataSet,
                  },
                  {
                    label: "쓰기",
                    backgroundColor: "#8b56e3",
                    barThickness: 20,
                    categoryPercentage: 1,
                    pointStyle: "triangle",
                    data: data.write.dataSet,
                  },
                  {
                    label: "듣기",
                    backgroundColor: "#80aa00",
                    barThickness: 20,
                    categoryPercentage: 1,
                    pointStyle: "triangle",
                    data: data.listen.dataSet,
                  },
                ]
              }}
              options={options}
            />
          }

        </div>
      </div>
    );
  return <></>;
};

export default OverallStackBarChart;

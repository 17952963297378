import styled from 'styled-components';

const StyleTerm = {
  Container: styled.div`
    h2 {
      text-align: center;
      font-size: 3.6em;
      font-weight: 700;
    }
    > p {
      margin-bottom: 1.8em;
      padding: 0 8em;
      text-align: center;
      font-size: 1.8em;
      font-weight: 400;
    }
    > .contentBox {
      overflow-y: auto;
      width: 100%;
      height: 41em;
      padding: 3em 7.5em 2.5em 3em;
      border: 1px #c9cbd1 solid;
      border-radius: 0.6em;

      > div {
        font-size: 1.4em;
        font-weight: 400;
        white-space: pre-wrap; /* CSS3*/
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-all; /* Internet Explorer 5.5+ */
      }
    }
  `,
};

export default StyleTerm;

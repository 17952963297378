import React, { useEffect, useRef, useState } from 'react';
import Styled from './index.style';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import useErrorToastify from 'common/hook/useErrorToastify';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import communityApi from 'api/community';
import { VscClose } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import UserInfo from 'component/atoms/UserInfo';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/**
 * 대댓글 작성
 * @param {*} param
 * @param {*} param.item 부모 댓글 정보
 * @param {*} param.setReReplyId 대댓글 아이디 전달
 * @param {*} param.onRefresh 새로고침
 */
const CommentReReply = ({ item, setReReplyId, onRefresh }) => {
  const { t } = useTranslation('communityPage');
  const { id } = useParams();
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.data);

  const moveCaretAtEnd = (e) => {
    var temp_value = e.target.value;
    e.target.value = '';
    e.target.value = temp_value;
  };

  const [content, setContent] = useState(`@${item.userName}\n`);

  const [postReply, fetchPostReply] = useAxios(
    communityApi.postReply,
    [],
    true
  );
  const { loading, data, error } = postReply;
  const onSubmit = (e) => {
    e.preventDefault();
    if (content.trim() === item.content) {
      toast.warn('Nothing has changed.');
      return false;
    }
    fetchPostReply(id, item.id, content, token);
  };

  useEffect(() => {
    if (data === 201) {
      setReReplyId(null);
      onRefresh();
    }
  }, [data]);

  useErrorToastify(error);

  return (
    <Styled.Wrapper>
      <UserInfo
        src={user.profilePhotoPath}
        name={user.userName ? user.userName : user.userNickName}
      />

      <Styled.Form onSubmit={onSubmit}>
        <Styled.Textarea
          required
          onChange={(e) => setContent(e.target.value)}
          value={content}
          onFocus={moveCaretAtEnd}
          placeholder={t('post.commentPlaceholder')}
          autoFocus
        />

        <Styled.Submit>{t('buttonPost')}</Styled.Submit>

        <Styled.Cancel onClick={() => setReReplyId(null)}>
          <VscClose />
        </Styled.Cancel>
      </Styled.Form>

      <LoadingOverlay isLoading={loading} />
    </Styled.Wrapper>
  );
};

export default CommentReReply;

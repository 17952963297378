import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/grade-reports';

/**
 * 성적분석 리포트 api
 */

const mockTestReportApi = {
  getYears: async (token) => {
    const config = {
      url: `${url}/comprehensive`,
      // url: `https://dev.mastertopik.com/api/v1/grade-reports/comprehensive`,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  getOverallChartData: async (token, year) => {
    const config = {
      url: `${url}/comprehensive/${year}`,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  // 최순철 - 모의고사 결과 1,2 로 가져오기 추가
  getOverallChartDataType: async (token, type) => {
    const config = {
      url: `${url}/comprehensive_new/${type}`,
      // url: `https://dev.mastertopik.com/api/v1/grade-reports/comprehensive_new/${type}`,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  // 최순철 - 모의고사 결과 최초와 현재 정보만 반환
  getOverallData: async (token) => {
    const config = {
      url: `${url}/comprehensive/diagnosis`,
      // url: `https://dev.mastertopik.com/api/v1/grade-reports/comprehensive/diagnosis`,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  // 최순철 - 모의고사 결과 모두 가져오기
  getOverallDataType: async (token) => {
    const config = {
      url: `${url}/comprehensive/diagnosis_new`,
      // url: `https://dev.mastertopik.com/api/v1/grade-reports/comprehensive/diagnosis_new`,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  getIndividualTests: async (token) => {
    const config = {
      url: `${url}/individual`,
      // url: `https://dev.mastertopik.com/api/v1/grade-reports/individual`,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  getIndividualData: async (token, id = 0) => {
    const config = {
      url: `${url}/individual/${id}`,
      // url: `https://dev.mastertopik.com/api/v1/grade-reports/individual/${id}`,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  getIndividualAllTests: async (token) => {
    const config = {
      url: `${url}/individual_new`,
      // url: `https://dev.mastertopik.com/api/v1/grade-reports/individual_new`,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await asyncApi(config);
    return response.data;
  },
};

export default mockTestReportApi;

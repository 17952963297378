import { masterTopikUrl } from 'common/constant';
import DefaultList from 'component/atoms/DefaultList';
import H3 from 'component/atoms/H3';
import Error from 'component/Error';
import UserThemeItem from 'component/molecules/ThemeItem/UserThemeItem';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';
import { EmptyList } from 'component/atoms/EmptyList';

const Wrapper = styled.div`
  margin-bottom: 10em;
  h3 {
    margin-bottom: 1.5em;
  }
`;

const Container = styled.div`
  margin-bottom: 7em;
`;

const InterestedTheme = ({ initialState, token }) => {
  const { t } = useTranslation('bookmarkPage');
  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [themeList, fetchThemeList] = initialState;
  const { loading, data, error } = themeList;

  // 최초 1회 호출
  useEffect(() => fetchThemeList({ page: 0, token }), []);

  useEffect(() => {
    if (data !== null) {
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [themeList]);

  if (error) return <Error error={error} />;

  return (
    <Wrapper>
      <H3>{t('themeTab.themes1')}</H3>
      {slideItem.length === 0 && <EmptyList />}

      <DefaultList>
        {slideItem.map((item, index) => {
          const imgSrcList = item.themeProductResponse.map(
            (itemChild) =>
              masterTopikUrl +
              itemChild?.thumbnailPath +
              itemChild?.thumbnailTitle
          );
          return (
            <UserThemeItem
              key={index}
              id={item.id}
              imgSrcList={imgSrcList}
              title={item.name}
              tag={item.categoryName}
              videoCount={item.courseCount}
              likeCount={item.likeCount}
              userThumb={item.userProfilePhotoPath}
              userName={item.userName}
              isGood={item.isGood}
            />
          );
        })}
      </DefaultList>
    </Wrapper>
  );
};
export default InterestedTheme;

import styled from 'styled-components';

import arrowRightIcon from 'assets/images/arrow_right.svg';
import playImgSmall from 'assets/images/ico_play_small.png';
import playImg from 'assets/images/ico_play.png';
import { Swiper } from 'swiper/react';
import gradient from 'assets/images/gradient_bottom01.png';

const CoursesStyled = {
  Wrapper: styled.div`
    position: relative;
    padding-top: 13em;
    margin-bottom: 22em;
    background-color: #393c40;
    text-align: center;
    @media (max-width: 1024px) {
      padding-bottom: 9em;
    }
    @media (max-width: 767px) {
      padding: 10em 0;
      margin-bottom: 5em;
    }
  `,
  H2: styled.h2`
    font-size: 7em;
    font-weight: bold;
    margin-bottom: 0.2em;
    @media (max-width: 767px) {
      font-size: 6em;
    }
  `,
  SwiperWrap: styled.div`
    position: relative;
    .slider-prev,
    .slider-next {
      top: 0.5em;
      background-color: #242629;
      transform: scale(0.7) rotate(45deg);
    }
    .slider-prev {
      left: 0;
    }
    .slider-next {
      right: 0;
    }
    @media (max-width: 1024px) {
      .slider-prev,
      .slider-next {
        display: block !important;
      }
    }
    @media (max-width: 767px) {
      .slider-prev,
      .slider-next {
        top: 1em;
      }
    }
  `,
  Swiper: styled(Swiper)`
    position: relative;
    padding: 0 10em;
    margin: 1.6em 0 1.5em;
    .swiper-slide {
      width: auto;
      &:not(:last-child) span:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 1.25em;
        width: 2px;
        height: 2px;
        background-color: #fff9;
      }
    }
    .swiper-slide span {
      position: relative;
      display: inline-block;
      font-size: 2em;
      font-weight: 100;
      padding: 0.5em 4em 0.5em 0;
      cursor: pointer;
      &.active {
        font-weight: 700;
        color: #ffb500;
      }
      @media (max-width: 767px) {
        font-size: 2.4em;
        padding: 0.5em 2.5em 0.5em 0;
      }
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      z-index: 2;
      width: 10rem;
      height: 100%;
      background: linear-gradient(
        to right,
        rgba(57, 60, 64, 1) 90%,
        rgba(57, 60, 64, 0)
      );
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
      transform: rotate(180deg);
    }
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 50%;
      z-index: 99;
      width: 3rem;
      height: 3rem;
      margin-top: -1.5rem;
      padding: 0;
      border: 0;
      background: url(${arrowRightIcon}) no-repeat center transparent;
      background-size: 2rem;
      &:not(.swiper-button-disabled) {
        cursor: pointer;
      }
      &.swiper-button-disabled {
        opacity: 0.5;
      }
    }
    .swiper-button-prev {
      left: 0;
      transform: rotate(180deg);
    }
    .swiper-button-next {
      right: 0;
    }
    @media (max-width: 767px) {
      padding: 0 7.5em;
      &:before,
      &:after {
        width: 7.5em;
      }
      .swiper-button-prev,
      .swiper-button-next {
        display: block;
      }
    }
  `,
  Video: {
    Wrapper: styled.div`
      position: relative;
      height: 73.2em;
      margin-bottom: -10em;
      text-align: left;
      box-shadow: 0 0 10px 5px rgba(32, 33, 36, 0.3);
      .plyr__control--overlaid {
        background-color: #ffb500;
      }
      @media (max-width: 1024px) {
        height: auto;
        margin-bottom: 0;
        border-radius: 0.6rem;
        overflow: hidden;
        box-shadow: none;
      }
      @media (max-width: 767px) {
        font-size: 0.8em;
      }
      @media (max-width: 375px) {
        [data-plyr='rewind'],
        [data-plyr='fast-forward'] {
          display: none;
        }
      }
    `,
    Thumb: styled.div`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.hidden-poster {
        opacity: 0;
        height: 0;
        transition: opacity 0.4s ease 0.2s, height 0s ease 0.6s;
      }
    `,
    Inner: styled.div`
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 6.3%;
      background: url(${gradient}) repeat-x center bottom;
      @media (max-width: 1024px) {
        padding: 5em;
        background-size: auto 100%;
      }
      @media (max-width: 767px) {
        font-size: 0.7em;
      }
      @media (max-width: 375px) {
        font-size: 0.6em;
      }
    `,
    Tags: styled.div`
      font-size: 3.2em;
      font-weight: 400;
      color: #ffb500;
      line-height: 1.1;
    `,
    Title: styled.h4`
      font-size: 5em;
      margin: 0.1em 0;
      font-weight: bold;
      line-height: 1.2;
    `,
    Teacher: styled.div`
      font-size: 2.4em;
      color: #aeaeae;
      @media (max-width: 767px) {
        font-size: 3.5em;
        margin-top: 0.5em;
      }
    `,
    PlayIcon: styled.div`
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: calc(40px + 5rem);
      height: calc(40px + 5rem);
      margin: calc(-20px - 2.5rem) 0 0 calc(-20px - 2.5rem);
      font-size: 3rem;
      cursor: pointer;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: url(${playImgSmall}) no-repeat 54% center;
      }
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: -1;
        width: 6.8rem;
        height: 6.8rem;
        margin: -3.4rem 0 0 -3.4rem;
        border-radius: 1rem;
        background-color: #ffb500;
        opacity: 0.5;
        transform: rotate(45deg);
        transition: all 0.2s ease;
      }
      &:hover:before {
        opacity: 0.8;
      }
      @media (max-width: 1024px) {
        &:after {
          background-image: url(${playImg});
          background-size: 2.4rem;
        }
      }
    `,
  },
  Arrow: {
    Right: styled.button.attrs({
      type: 'button',
    })`
      right: 0;
    `,
    Left: styled.button.attrs({
      type: 'button',
    })`
      left: 0;
      transform: rotate(180deg);
    `,
  },
};

export default CoursesStyled;

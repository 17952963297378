import React, { useEffect, useState } from 'react';
import StyledForm from 'component/form/ModalForm.style';
import { useAxios } from 'common/hook/useAxiosHook';
import themeCategoriesApi from 'api/themeCategories';

const ThemeCategorySelect = ({ selectedOption, setSelectedOption, title }) => {
  const [categoryOptionList, setCategoryOptionList] = useState([]);
  const [categoryListState, fetchCategoryListState] = useAxios(() =>
    themeCategoriesApi.getAllList({ size: 1000 })
  );

  const { loading, data, error } = categoryListState;
  const categoryList = data?.content;

  // 셀렉트박스 옵션 포맷으로 리스트 생성
  useEffect(() => {
    if (categoryList && categoryList.length > 0) {
      setCategoryOptionList(
        categoryList.map((item) => ({ value: item.id, label: item.name }))
      );
    }
  }, [data]);

  return (
    <StyledForm.InputContainer>
      <StyledForm.Label htmlFor='themeCategories'>
        {title}
      </StyledForm.Label>
      <StyledForm.Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={categoryOptionList}
        isLoading={loading}
        inputId='themeCategories'
        placeholder='Select'
        required
      />
    </StyledForm.InputContainer>
  );
};

export default ThemeCategorySelect;

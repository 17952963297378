import styled from 'styled-components';
import checkImg from 'assets/images/ico_checkImg.png';

const StyleFilterContainer = {
  Container: styled.div`
    margin-bottom: 4em;
  `,
  InputContainer: styled.div`
    margin-bottom: 0.5em;
    @media (max-width: 767px) {
      margin-bottom: 1.5em;
    }

    &:after {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }
    > span {
      display: inline-block;
      min-width: 6em;
      margin-right: 1em;
      font-size: 1.8em;
      font-weight: 500;
      letter-spacing: 1px;
      @media (max-width: 767px) {
        display: block;
        margin-bottom: 0.5em;
      }
    }
    .filter-input {
      display: inline-block;
      margin-right: 1em;
      margin-bottom: 1em;
      @media (max-width: 767px) {
        margin-right: 1.5em;
      }
      > input[type='checkbox'] {
        position: absolute;
        width: 1px;
        height: 1px;
        visibility: hidden;

        & + label {
          position: relative;
          font-size: 1.6em;
          font-weight: 300;
          padding-left: 1.8em;
          cursor: pointer;
          @media (max-width: 767px) {
            display: block;
            font-size: 2em;
          }
        }

        & + label:before {
          content: '';
          display: inline-block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 1.1em;
          height: 1.1em;
          border: 1px #c9cbd1 solid;
          border-radius: 2px;
        }
        &:checked + label:before {
          /* border: none; */
          background: url(${checkImg}) no-repeat center/contain;
        }
      }
    }
  `,
};

export default StyleFilterContainer;

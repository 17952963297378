import styled, {css} from 'styled-components';
import { RiArrowRightSLine } from 'react-icons/ri';
import playIcon from 'assets/images/ico_play.png';
import playIconOver from 'assets/images/ico_play_over.png';

//Korean
import visualImg from 'assets/images/event/230802/koreanTab/visual.jpg';
import visualImgKo from 'assets/images/event/230802/koreanTab/ko/visual.jpg';

//Topik
import visual2Img from 'assets/images/event/230802/topikTab/visual.jpg';
import visual2ImgKo from 'assets/images/event/230802/topikTab/ko/visual.jpg';


const StyledEventPage = {
  StickyWrap: styled.div`
    position: relative;
  `,
  Sticky: styled.div`
    position: relative;
    width: 100%;
    background: #27406d;
    transition: box-shadow ease-in .2s;
    &.sticky {
      position: fixed;
      left: 0;
      z-index: 90;
      box-shadow: .5em .5em 1em rgba(0,0,0,.4);
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background: #3f3d62;
    }
    ul {
      position: relative;
      max-width: 1300px;
      margin: auto;
      display: flex;
      z-index: 2;
      li {
        flex: 1;
        a {
          display: block;
          height: 3.2em;
          line-height: 3.2em;
          text-align: center;
          font-weight: 700;
          font-size: 1.2em;
          color: #fff;
          opacity: .22;
          &.active {
            opacity: 1;
          }
        }
      }
    }
  `,

  TargetWrap: styled.div`
    position: absolute;
    top: 1200px;
    left: 0;
    width: 100%;
    background-color: ${({ bgColor }) => bgColor && bgColor};
    .inner {
      padding: 0 !important;
    }
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li {
        flex: 1;
      }
    }
    a {
      display: block;
      padding: 1em 0.2em;
      font-size: 1.1em;
      color: #FFF;
    }
    @media (max-width: 750px) {
      top: 160vw;
    }
  `,

  ButtonWrap: styled.div`
    margin-top: 3em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1em 1.5em;
  `,
  ArrowButton: styled.div.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <RiArrowRightSLine />
      </>
    ),
  }))`
    position: relative;
    a {
      display: block;
      min-width: 300px;
      line-height: 1;
      padding: 0.8em 2em 0.8em 0;
      border-radius: 0.3em;
      text-align: center;
      font-weight: 400;
      font-size: 1.3em;
      background-color: ${({ bgColor }) => bgColor && bgColor};
      color: ${({ color }) => color && color};
      > div {
        padding: 0 1em;
      }
    }
    svg {
      position: absolute;
      top: 50%;
      right: 1em;
      transform: translateY(-50%);
      font-size: 1.5em;
      color: ${({ color }) => color && color};
      vertical-align: middle;
    }
    @media (max-width: 750px) {
      a {
        min-width: 240px;
      }
    }
  `,

	EventWrap: styled.div`
    position: relative;
		font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    color: #555;
    letter-spacing: -0.035em;

		.blind {
			overflow: hidden;
			position: absolute;
			clip: rect(0 0 0 0);
			width: 1px;
			height: 1px;
			margin: -1px;
		}

    .inner {
      position: relative;
			max-width: 750px;
      margin: auto;
      padding: 6em 0;
      @media (max-width: 790px) {
        & {
          margin: 0 5%;
        }
      }
    }

    .visual {
      background: var(--bgColor);
			.inner {
        max-width: none;
        padding-top: 1500px;
        padding-bottom: 0;
        background-image: var(--visualImg);
        background-position: 50% 0;
        background-repeat: no-repeat;
        background-size: auto 100%;
      }
      .btn_wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 945px;
        text-align: center;
        a {
          display: inline-block;
          width: 85%;
          max-width: 320px;
          padding: 0.9em;
          border-radius: 2em;
          background: #02affe;
          letter-spacing: -0.04em;
          font-size: 1.5em;
          color: #fff;
        }
      }
      @media (max-width: 790px) {
        .inner {
          margin : 0;
          padding-top: 200%;
        }
        .btn_wrap {
          padding-top: 126%;
          a {
            padding: 4vw;
          }
        }
      }
    }
    &.ko .visual {
      .inner{
        background-image: var(--visualImgKo);
      }
    }

    .titleWrap {
      position: relative;
      margin-bottom: 3em;
      .label {
        display: inline-block;
        padding: 0.2em 0.8em;
        border: 1px solid #fff;
        border-radius: 2em;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1em;
        color: #fff;
      }
      .title {
        margin-top: 1em;
        font-weight: 700;
        font-size: 2.3em;
        color: #fff;
      }
      .desc {
        margin-top: 0.8em;
        font-weight: 200;
        font-size: 1.3em;
        color: #fff;
      }
    }

    .videoList li button,
    .video button {
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${playIcon});
        background-repeat: no-repeat;
        background-position: 50% 50%;
      }
      &:hover::after {
        background-image: url(${playIconOver});
      }
    }
    .videoList li button::after {
      background-size: 1em auto;
    }

    .gift1 {
      background-color: var(--bgColor);
      margin-top: -300px;
      .titleWrap {
        .label {
          border-color: #2757d7;
          color: #2757d7;
        }
        .title {
          color: #ffdc5e;
        }
        .desc {
          color: #fefefe;
        }
      }
      .videoList {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 0.5em 0;
        li {
          width: 32.7%;
          border-radius: 0.8em;
          overflow: hidden;
        }
      }
      .video {
        margin-top: 1.5em;
        border-radius: 1.25em;
        overflow: hidden;
      }
      @media (max-width: 750px) {
        margin-top: -40%;
      }
    }

    .gift2 {
      background-color: var(--bgColor2);
      .titleWrap {
        .label {
          border-color: #bf5dc3;
          color: #bf5dc3;
        }
        .title {
          color: #ffaf40;
        }
        .desc {
          color: #ffffff;
        }
      }
    }

    .gift3 {
      background-color: var(--bgColor3);
      .titleWrap {
        .label {
          border-color: #ffe68d;
          color: #ffe68d;
        }
        .title {
          color: #432597;
        }
        .desc {
          color: #404587;
        }
      }
    }

    .gift4 {
      background-color: var(--bgColor4);
      .titleWrap {
        .label {
          border-color: #02affe;
          color: #02affe;
        }
        .title {
          color: #02d7fe;
        }
        .desc {
          color: #ffffff;
        }
      }
      .video {
        border-radius: 1.25em;
        overflow: hidden;
      }
    }

    .notification {
      background: var(--bgColor5);
      text-align: left;
      line-height: 1.4;
      font-weight: 300;
      color: #bebcce;
      .inner {
        padding: 4em 0;
      }
      h3 {
        margin-bottom: 1em;
        font-size: 1.2em;
        font-weight: 700;
      }
      li {
        position:relative;
        padding-left: .6em;
        margin-bottom: .2em;
        font-size: 1.2em;
        &:before {
          content:"";
          display:inline-block;
          position: absolute;
          top:.5em;
          left:0;
          width: .25em;
          height: .25em;
          border-radius: .2em;
          background: #bebcce;
          vertical-align:middle;
        }
        a {
          border-bottom: 1px solid #bebcce;
        }
      }
    }
  `,

  KoreanTab: styled.div`
    --bgColor : #052f55;
    --bgColor2 : #6b317f;
    --bgColor3 : #ffaf40;
    --bgColor4 : #2e3192;
    --bgColor5 : #1a1265;
    --visualImg : url(${visualImg});
    --visualImgKo : url(${visualImgKo});
  `,
  TopikTab: styled.div`
    --bgColor : #330e65;
    --bgColor2 : #2e8eab;
    --bgColor3 : #ffaf40;
    --bgColor4 : #1c1b54;
    --visualImg : url(${visual2Img});
    --visualImgKo : url(${visual2ImgKo});

    .visual {
      .btn_wrap {
        a {
          background: #cf72ff;
        }
      }
    }
    .gift1 {
      .titleWrap {
        .label {
          border-color: #8a46d6;
          color: #8a46d6;
        }
        .title {
          color: #ffdc5e;
        }
        .desc {
          color: #fefefe;
        }
      }
    }

    .gift2 {
      .titleWrap {
        .label {
          border-color: #00d5e8;
          color: #00d5e8;
        }
        .title {
          color: #261c4e;
        }
        .desc {
          color: #1b0d64;
        }
      }
    }

    .gift3 {
      .titleWrap {
        .label {
          border-color: #ffe68d;
          color: #ffe68d;
        }
        .title {
          color: #432597;
        }
        .desc {
          color: #404587;
        }
      }
    }

    .gift4 {
      .titleWrap {
        .label {
          border-color: #008ccc;
          color: #008ccc;
        }
        .title {
          color: #02d7fe;
        }
        .desc {
          color: #ffffff;
        }
      }
    }
  `,
};

export default StyledEventPage;

import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import Img from 'component/atoms/Img';
import downloadIcon from 'assets/images/ico_download.png';
import ButtonLink from 'component/atoms/ButtonLink';

const StyledBooks = {
  Container: styled(ContentsWrapper)`
    display: flex;
    padding-bottom: 10em;

    @media (max-width: 767px) {
      flex-direction: column;
      font-size: 1.25em;
    }
  `,
  WorkbookContainer: styled.div`
    flex: 1;
    margin-right: 3.4em;
    > div:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 767px) {
      margin-right: 0;
    }
  `,
  WorkBookFile: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    padding: 1.5em 2em;
    border-radius: 6px;
    background: #1e2024;
    color: #d6d6d7;

    span {
      font-size: 1.8em;
      font-weight: 400;
    }
  `,

  WorkbookBtn: styled.a`
    position: relative;
    padding: 0.4em 1.7em 0.4em 2.1em;
    border: #485cc7;
    background: transparent;
    color: #8e9199;
    font-size: 1.4em;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 1.3em;
      height: 1.3em;
      background: url(${downloadIcon}) center center/cover;
      transform: translateY(-50%);
    }
  `,

  TextbookContainer: styled.div`
    flex: 1;
    @media (max-width: 767px) {
      margin-top: 8em;
    }
  `,

  Textbook: styled.div`
    display: flex;
  `,

  TextbookImgContainer: styled.div`
    position: relative;

    margin-right: 4em;
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      left: 0;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  `,

  TextbookImg: styled(Img).attrs({
    alt: 'textbook image',
  })`
    width: 30em;
    height: auto;
    border-radius: 6px;
  `,
  TextbookContent: styled.div`
    span {
      display: block;
    }
    .textbook-title {
      margin-bottom: 1.1em;
      font-size: 2.2em;
      font-weight: 700;
      word-break: keep-all;
    }

    .textbook-desc {
      margin-bottom: 0.5 em;
      color: #5c5f66;
      font-size: 1.6em;
      font-weight: 400;
    }
  `,

  H2: styled.h2`
    font-size: 3em;
    font-weight: 700;
    margin-bottom: 1.06em;
  `,

  TextbookBtn: styled(ButtonLink)`
    display: inline-block;
    margin-top: 5.5em;
    border: #485cc7;
    background: #485cc7;
    padding: 0.7em 0.8em;
    font-size: 1.4em;
    font-weight: 400;
    letter-spacing: 1px;

    &:hover {
      background-color: #3e52c0;
    }
  `,
};

export default StyledBooks;

import React from 'react';
import Style from "../styled/index.style";

const SerachResult = (props) => {
  return (
    <>
      {
        props.search !== "" && <Style.SearchResult>
          <p><strong>{props.result}</strong> results for <strong>"{props.search}"</strong> in {props.category}</p>
        </Style.SearchResult>
      }
    </>
  );
};
export default SerachResult;
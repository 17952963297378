import styled from 'styled-components';
import Dashboard from 'container/ClassroomPage/styled/Dashboard.style';
import icoWatched from 'assets/images/ico_watched.png';
import icoCompleted from 'assets/images/ico_completed.png';
import icoBookmark from 'assets/images/ico_bookmark.png';
import icoTheme from 'assets/images/ico_my_theme.png';
import icoWatchedLarge from 'assets/images/ico_watched_large.png';
import icoCompletedLarge from 'assets/images/ico_completed_large.png';
import icoBookmarkLarge from 'assets/images/ico_bookmark_large.png';
import icoThemeLarge from 'assets/images/ico_my_theme_large.png';

const MyClass = {
  MoWrap: styled.div`
    background-color: #1e2024;
    border-radius: 0.6em;
    padding: 3.5em 0;
    text-align: center;

    @media (max-width: 1024px) {
      padding: 2em 0;
    }
  `,
  Dashboard: styled(Dashboard.Wrapper)`
    background-color: inherit;
    border-radius: 0em;
    padding: 0;
    text-align: left;

    @media (max-width: 767px) {
      display: inline-block;
    }
  `,
  ItemBox: styled(Dashboard.ItemBox)`
    width: 25%;

    &:first-child {
      border-left: 0;
    }

    &:nth-child(1) ::before {
      width: 3.6em;
      height: 3.6em;
      background-image: url(${icoWatched});
    }

    &:nth-child(2) ::before {
      width: 3.5em;
      height: 3.4em;
      background-image: url(${icoCompleted});
    }

    &:nth-child(3) ::before {
      width: 3.0em;
      height: 3.3em;
      background-image: url(${icoBookmark});
    }

    &:nth-child(4) ::before {
      width: 3.8em;
      height: 3.2em;
      background-image: url(${icoTheme});
    }

    @media (max-width: 1024px) {
      width: auto;

      &:nth-child(1) ::before {
        width: 3em;
        height: 3em;
        background-image: url(${icoWatchedLarge});
      }

      &:nth-child(2) ::before {
        width: 3.125em;
        height: 3em;
        background-image: url(${icoCompletedLarge});
      }

      &:nth-child(3) ::before {
        width: 2.75em;
        height: 3em;
        background-image: url(${icoBookmarkLarge});
      }

      &:nth-child(4) ::before {
        width: 3em;
        height: 2.5em;
        background-image: url(${icoThemeLarge});
      }
    }

    @media (max-width: 767px) {
      display: block;
      text-align: left;
      border-left: 0;
      padding-top: 1em;

      &:first-child {
        padding-top: 0;
      }
    }
  `,
  Item: styled(Dashboard.Item)`

    &::before {
      content:'';
      position: absolute;
      top: 50%;
      left: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transform: translateY(-50%);
    }

    @media (max-width: 1024px) {
      padding-left: 5em;
    }
  `,
  Title: styled(Dashboard.Title)`
    @media (max-width: 767px) {
      display: inline-block;
      vertical-align: middle;
      font-size: 2em;
    }
  `,
  Count: styled(Dashboard.Count)`

    @media (max-width: 1024px) {
      font-size: 3.6em;
    }

    @media (max-width: 767px) {
      display: inline-block;
      vertical-align: middle;
      padding-left: 0.5em;
    }
  `,
}

export default MyClass;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Styled from './index.style';

import { Trans, useTranslation } from 'react-i18next';
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import { openLink } from 'common/util/util';
import { useDispatch, useSelector } from 'react-redux';
import plansApi from 'api/plans';
import useLoginStatus from 'common/hook/useLoginStatus';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';
import { useAxios } from 'common/hook/useAxiosHook';
import { actions } from 'component/user/state';
import Plyr from 'plyr-react';

import Header from './header';

import info2Img from 'assets/images/event/230515/img_info2_1.png';
import info2Img2 from 'assets/images/event/230515/img_info2_2.png';
import info2Img3 from 'assets/images/event/230515/img_info2_3.png';
import slide1 from 'assets/images/event/230515/slide1.png';
import slide2 from 'assets/images/event/230515/slide2.png';
import slide3 from 'assets/images/event/230515/slide3.png';
import slide4 from 'assets/images/event/230515/slide4.png';
import slide5 from 'assets/images/event/230515/slide5.png';
import slide6 from 'assets/images/event/230515/slide6.png';
import slide7 from 'assets/images/event/230515/slide7.png';
import slide8 from 'assets/images/event/230515/slide8.png';
import slide1Ko from 'assets/images/event/230515/ko/slide1.png';
import slide2Ko from 'assets/images/event/230515/ko/slide2.png';
import slide3Ko from 'assets/images/event/230515/ko/slide3.png';
import slide4Ko from 'assets/images/event/230515/ko/slide4.png';
import slide5Ko from 'assets/images/event/230515/ko/slide5.png';
import slide6Ko from 'assets/images/event/230515/ko/slide6.png';
import slide7Ko from 'assets/images/event/230515/ko/slide7.png';
import slide8Ko from 'assets/images/event/230515/ko/slide8.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay } from 'swiper';
SwiperCore.use([Autoplay]);

const EventPage = () => {
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation('event/20230515FreeTrial');
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const isLogin = useLoginStatus();
  const history = useHistory();
  const location = useLocation();
  let { handleModal } = React.useContext(ModalContext);

  const videoRef = useRef(null);
  const user = useSelector((state) => state.user.data);

  const [faqIndex, setfaqIndex] = useState(0);
  const faqClick = (i) => {
    setfaqIndex(i);
  };

  useEffect(() => {
    if (!token) return;
    handleFetchPlan();
    dispatch(actions.fetchUser(token));
  }, [token]);
  const handleFetchPlan = () => fetchPlan(token);
  const [
    { loading: getPlanLoading, data: getPlanData, error: getPlanError },
    fetchPlan,
  ] = useAxios(plansApi.getPlan, [], true);

  useEffect(() => {
    const el = document.getElementById('header');
    if (!el) return;
    el.setAttribute('style', 'display: none;');

    return () => {
      el.removeAttribute('style');
    };
  }, []);
  useEffect(() => {
    if (!getPlanData) return;
    if (getPlanData.currentPlan !== 'FREE') {
      console.log('서비스 이용중인 회원', getPlanData.currentPlan);
      history.push({
        pathname: '/',
      });
    }
  }, [getPlanData]);

  const freeStart = () => {
    if (isLogin) {
      const item = getPlanData.plans.find((plan) => plan.productId === 1177);
      history.push({
        pathname: `/plan-payment/${item.planCode.toLowerCase()}`,
        search: `?productId=${item.productId}`,
        state: item,
      });
    } else {
      handleModal(<Login />, false, 420, location.pathname);
    }
  };

  return (
    <>
      {/* header */}
      {
        <Header
          login={isLogin}
          planData={getPlanData ? getPlanData : undefined}
        />
      }
      {/* //header */}

      <Styled.EventWrap className={language === 'ko-KR' ? 'ko' : null}>
        <div className='visual'>
          <div className='inner'>
            <div className='title'>
              <div className='sub'>
                <Trans t={t}>visual.sub</Trans>
              </div>
              <h2>
                <Trans t={t}>visual.title</Trans>
              </h2>
            </div>
            <Styled.ButtonWrap>
              <button onClick={() => freeStart()}>
                <Trans t={t}>button.start</Trans>
              </button>
            </Styled.ButtonWrap>
          </div>
        </div>

        <div className='info'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>info.title</Trans>
              </h2>
            </div>
            <div className='boxWrap'>
              <h3 className='stitle'>
                <Trans t={t}>info.stitle</Trans>
              </h3>
              <div className='box'>
                <div className='percent'>
                  <strong>80</strong>
                  <span>%</span>
                </div>
                <p>
                  <Trans t={t}>info.desc</Trans>
                </p>
              </div>
            </div>
            <div className='boxWrap type2'>
              <h3 className='stitle'>
                <Trans t={t}>info.stitle2</Trans>
              </h3>
              <div className='box'>
                <div className='percent'>
                  <strong>96</strong>
                  <span>%</span>
                </div>
                <p>
                  <Trans t={t}>info.desc2-1</Trans>
                </p>
              </div>
              <div className='box'>
                <div className='percent'>
                  <strong>94</strong>
                  <span>%</span>
                </div>
                <p>
                  <Trans t={t}>info.desc2-2</Trans>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='info2'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>info2.title</Trans>
              </h2>
            </div>
            <ul>
              <li>
                <div className='img'>
                  <div className='step'>
                    <span>STEP1</span>
                    <Trans t={t}>info2.step1.title</Trans>
                  </div>
                  <img src={info2Img} alt='' />
                </div>
                <div className='desc'>
                  <Trans t={t}>info2.step1.desc</Trans>
                </div>
              </li>
              <li>
                <div className='img'>
                  <div className='step'>
                    <span>STEP2</span>
                    <Trans t={t}>info2.step2.title</Trans>
                  </div>
                  <img src={info2Img2} alt='' />
                </div>
                <div className='desc'>
                  <Trans t={t}>info2.step2.desc</Trans>
                </div>
              </li>
              <li>
                <div className='img'>
                  <div className='step'>
                    <span>STEP3</span>
                    <Trans t={t}>info2.step3.title</Trans>
                  </div>
                  <img src={info2Img3} alt='' />
                </div>
                <div className='desc'>
                  <Trans t={t}>info2.step3.desc</Trans>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className='video'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>video.title</Trans>
              </h2>
            </div>
            <h3>
              <Trans t={t}>video.stitle</Trans>
            </h3>
            <div className='boxWrap'>
              <div className='box'>
                <div className='best'>
                  <span className='blind'>
                    <Trans t={t}>video.best</Trans>
                  </span>
                </div>
                <Plyr
                  ref={videoRef}
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: 'zYmWKE5Q2mU',
                        provider: 'youtube',
                      },
                    ],
                  }}
                />
              </div>
              <div className='box'>
                <div className='best'>
                  <span className='blind'>
                    <Trans t={t}>video.best</Trans>
                  </span>
                </div>
                <Plyr
                  ref={videoRef}
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: 'kdZmrc-GO7w',
                        provider: 'youtube',
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <h3>
              <Trans t={t}>video.stitle2</Trans>
            </h3>
            <div className='boxWrap'>
              <div className='box'>
                <Plyr
                  ref={videoRef}
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: '_MdcfQCE0tA',
                        provider: 'youtube',
                      },
                    ],
                  }}
                />
              </div>
              <div className='box'>
                <Plyr
                  ref={videoRef}
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: 'OiubOpXNTRM',
                        provider: 'youtube',
                      },
                    ],
                  }}
                />
              </div>
            </div>
            <h3>
              <Trans t={t}>video.stitle3</Trans>
            </h3>
            <div className='boxWrap'>
              <div className='box'>
                <Plyr
                  ref={videoRef}
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: 'imJ45mxoq8o',
                        provider: 'youtube',
                      },
                    ],
                  }}
                />
              </div>
              <div className='box'>
                <Plyr
                  ref={videoRef}
                  source={{
                    type: 'video',
                    sources: [
                      {
                        src: 'wf0SnQc6yOg',
                        provider: 'youtube',
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='review'>
          <div className='inner'>
            <div className='title'>
              <h2>
                <Trans t={t}>review.title</Trans>
              </h2>
            </div>
            <Styled.SwiperWrap>
              <Swiper
                loop={true}
                direction={'vertical'}
                slidesPerView={1}
                spaceBetween={0}
                observer={true}
                updateOnWindowResize={true}
                speed={300}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
              >
                {language === 'ko-KR' ? (
                  <>
                    {/* ko */}
                    <SwiperSlide>
                      <img src={slide1Ko} alt={t('review.slide1')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide2Ko} alt={t('review.slide2')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide3Ko} alt={t('review.slide3')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide4Ko} alt={t('review.slide4')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide5Ko} alt={t('review.slide5')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide6Ko} alt={t('review.slide6')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide7Ko} alt={t('review.slide7')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide8Ko} alt={t('review.slide8')} />
                    </SwiperSlide>
                  </>
                ) : (
                  <>
                    <SwiperSlide>
                      <img src={slide1} alt={t('review.slide1')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide2} alt={t('review.slide2')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide3} alt={t('review.slide3')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide4} alt={t('review.slide4')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide5} alt={t('review.slide5')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide6} alt={t('review.slide6')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide7} alt={t('review.slide7')} />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img src={slide8} alt={t('review.slide8')} />
                    </SwiperSlide>
                  </>
                )}
              </Swiper>
            </Styled.SwiperWrap>
          </div>
        </div>

        <div className='free'>
          <div className='inner'>
            <div className='desc'>
              <Trans t={t}>free.desc</Trans>
            </div>
            <Styled.ButtonWrap>
              <button onClick={() => freeStart()}>
                <Trans t={t}>button.start</Trans>
              </button>
            </Styled.ButtonWrap>
          </div>
        </div>

        <div className='faq'>
          <div className='inner'>
            <h3 className='title'>FAQ</h3>
            <ul>
              <li
                className={faqIndex === 0 ? 'active' : ''}
                onClick={(e) => {
                  faqClick(0);
                }}
              >
                <div className='question'>
                  <Trans t={t}>faq.question</Trans>
                </div>
                <div className='answer'>
                  <Trans t={t}>faq.answer</Trans>
                </div>
              </li>
              <li
                className={faqIndex === 1 ? 'active' : ''}
                onClick={(e) => {
                  faqClick(1);
                }}
              >
                <div className='question'>
                  <Trans t={t}>faq.question2</Trans>
                </div>
                <div className='answer'>
                  <Trans t={t}>faq.answer2</Trans>
                </div>
              </li>
              <li
                className={faqIndex === 2 ? 'active' : ''}
                onClick={(e) => {
                  faqClick(2);
                }}
              >
                <div className='question'>
                  <Trans t={t}>faq.question3</Trans>
                </div>
                <div className='answer'>
                  <Trans t={t}>faq.answer3</Trans>
                </div>
              </li>
              <li
                className={faqIndex === 3 ? 'active' : ''}
                onClick={(e) => {
                  faqClick(3);
                }}
              >
                <div className='question'>
                  <Trans t={t}>faq.question4</Trans>
                </div>
                <div className='answer'>
                  <Trans t={t}>faq.answer4</Trans>
                </div>
              </li>
              <li
                className={faqIndex === 4 ? 'active' : ''}
                onClick={(e) => {
                  faqClick(4);
                }}
              >
                <div className='question'>
                  <Trans t={t}>faq.question5</Trans>
                </div>
                <div className='answer'>
                  <Trans t={t}>faq.answer5</Trans>
                </div>
              </li>
              <li
                className={faqIndex === 5 ? 'active' : ''}
                onClick={(e) => {
                  faqClick(5);
                }}
              >
                <div className='question'>
                  <Trans t={t}>faq.question6</Trans>
                </div>
                <div className='answer'>
                  <Trans t={t}>faq.answer6</Trans>
                  <Trans t={t} className='refund'>
                    faq.answer6-2
                  </Trans>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Styled.EventWrap>
    </>
  );
};

export default EventPage;

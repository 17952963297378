import React, { useEffect } from 'react';
import Style from './styled/index.style';
//component
import SummaryBanner from './SummaryBanner';
import TeacherSection from './TeacherSection';
import TeacherIntro from './TeacherIntro';
import PlanSection from './PlanSection';
import PageSubTitle from 'component/molecules/PageSubTitle';

import Books from './Books';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';
import { useParams } from 'react-router-dom';
import { fetchCourseData, fetchOverview } from './api/courseDetailApi';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';

import Loading from 'component/atoms/Loading';
import RelatedCourse from './RelatedCourse';
import ErrorPage from 'component/atoms/Error';
import { useTranslation } from 'react-i18next';

const CourseDetail = () => {
  const { t } = useTranslation('courseDetailPage');
  const { productId: urlProductId } = useParams();
  const token = useSelector((state) => state.user.token);
  const { handleModal } = React.useContext(ModalContext);
  const [
    {
      loading: courseDetailLoading,
      data: courseDetailData,
      error: courseDetailError,
    },
    refreshGetCourseDetailData,
  ] = useAxios(fetchCourseData, [urlProductId], true);

  const [{ data: overviewData }, refreshGetOverview] = useAxios(
    () => fetchOverview(urlProductId),
    [],
    true
  );
  useEffect(() => {
    refreshGetCourseDetailData(urlProductId, token);
    if (!token) {
      refreshGetOverview();
    }
  }, [urlProductId]);

  const openLoginModal = (url) => {
    handleModal(<Login />, true, 420, url);
  };
  // console.log('urlProductId', urlProductId);
  // console.log('overviewData !!!!', overviewData);

  if (courseDetailLoading) return <Loading />;
  if (courseDetailError) return <ErrorPage />;
  if (courseDetailData) {
    return (
      <>
        <SummaryBanner
          openLoginModal={openLoginModal}
          data={courseDetailData}
          loginStatus={token}
          t={t}
        />

        <TeacherIntro  data={courseDetailData}  />
        <TeacherSection data={courseDetailData} />
        <PlanSection
          openLoginModal={openLoginModal}
          productId={urlProductId}
          overview={!token && overviewData}
          token={token}
          courseDetailData={courseDetailData.courseResponse}
        />
        {token && (
          <>
            <Books productId={urlProductId} token={token} />
            {
              // <Quiz />
            }
          </>
        )}

        <Style.Container>
          <PageSubTitle title={t('relatedCourse.title')} />
          <RelatedCourse token={token} productId={urlProductId} />
        </Style.Container>
      </>
    );
  }
  return <></>;
};

export default CourseDetail;

import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from "react-redux";
import Styled from './styled/index.style';
import { useTranslation } from 'react-i18next';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';
import SeoHelmet from 'component/Helmet';
import LoadingOverlay from "component/atoms/LoadingOverlay";
import { SeoData } from 'common/seoData';
import { useAxios } from "common/hook/useAxiosHook";
import useLoginStatus from "common/hook/useLoginStatus";
import { openLink } from 'common/util/util';

import H3 from 'component/atoms/H3';
import videoClassApi from "api/videoclass";
import faqApi from 'api/faq';
import useErrorToastify from "common/hook/useErrorToastify";
import StickyArea from './StickyArea';
import ClassItem from './ClassItem';
import TeacherItem from './Teacher/TeacherItem';
import { MemoizedFaqItem } from './FaqItem';
import { page, product, myclass, faqList, teacherItems } from './SampleData';

const AliveClassPage = () => {
  const { t } = useTranslation('aliveClassPage');
  const language = localStorage.getItem('i18nextLng');
  const isLogin = useLoginStatus();
  const user = useSelector((state) => state.user.data);

  const [mainData, setMainData] = useState();
  const [topikProducts, setTopikProducts] = useState([]);   //api로 받아온 topik products data
  const [koreanProducts, setKoreanProducts] = useState([]); //api로 받아온 korean products data
  const [myProducts, setMyProducts] = useState([]);         //api로 받아온 내가 결제한 prudcts data
  const [teachersData, setTeachersData] = useState([]);     //api로 받아온 선생님 data
  const [faqListData, setFaqListData] = useState([]);
  const [topikProductsFilt, setTopikProductsFilt] = useState([]);   //화면에 보여주기 위해 정제한 topik products data
  const [koreanProductsFilt, setKoreanProductsFilt] = useState([]); //화면에 보여주기 위해 정제한 korean products data
  const [myProductsFilt, setMyProductsFilt] = useState([]);         //화면에 보여주기 위해 정제한 내가 결제한 products data
  const [teachersDataFilt, setTeachersDataFilt] = useState([]);     //화면에 보여주기 위해 정제한 teachers list data
  const [topikLang, setTopikLang] = useState('ALL');  //ALL | EN | KO | ETC
  const [koreanLang, setKoreanLang] = useState('ALL');
  const [myClassLang, setMyClassLang] = useState('ALL');
  const [selectedFaq, setSelectedFaq] = useState(0);

  const [
    { loading: getMainLoading, data: getMainData, error: getMainError },
    fetchMain,
  ] = useAxios(videoClassApi.getMain, [], true);
  const [
    { loading: getProductsLoading, data: getProductsData, error: getProductsError },
    fetchProducts,
  ] = useAxios(videoClassApi.getProducts, [], true);
  const [
    { loading: getMyClassLoading, data: getMyClassData, error: getMyClassError },
    fetchMyClass,
  ] = useAxios(videoClassApi.getMyClass, [], true);
  const [{data: getFaqListData, error: getFaqListError},
    fetchFaqList
  ] = useAxios(faqApi.getVideoClassList, [], true);

  useEffect(() => {
    fetchMain();
    fetchProducts();
  }, []);
  useEffect(()=>{
    if(isLogin && user.userID){
      fetchMyClass(user.userID);
    }
  }, [isLogin])
  useEffect(()=>{
    if(language){
      //lang : 'ko-KR' | 'en-US' | 'zh-CN' | 'ja-JP'
      fetchFaqList(
        language === 'ko-KR'
          ? 'ko'
          : language === 'ja-JP'
            ? 'ja'
            : language.toLowerCase()
      );
    }
  }, [language])

  useEffect(() => {
    if (!getMainData) return;
    console.log('getMainData', getMainData)
    if(getMainData.videoclassPage){
      const data = getMainData.videoclassPage;
      const obj = setMainDataByLang(data, language);
      if(obj) setMainData(obj);
    }
  }, [getMainData]);
  useEffect(() => {
    if (!getProductsData) return;
    console.log('getProductsData', getProductsData)

    if(getProductsData.videoclassProductTopikList){
      const data = getProductsData.videoclassProductTopikList;
      const list = setProductListByLang(data, language);
      if(list && list.length > 0) setTopikProducts(list);
    }
    if(getProductsData.videoclassProductKoList){
      const data = getProductsData.videoclassProductKoList;
      const list = setProductListByLang(data, language);
      if(list && list.length > 0) setKoreanProducts(list);
    }
    if(getProductsData.videoclassProductTeachersList){
      const data = getProductsData.videoclassProductTeachersList;
      const list = setTeachersListByLang(data, language);
      if(list && list.length > 0) setTeachersData(list);
    }
  }, [getProductsData]);
  useEffect(()=>{
    if(!getMyClassData) return;
    console.log('getMyClassData', getMyClassData)

    if(getMyClassData.videoclassMyProductList){
      const data = getMyClassData.videoclassMyProductList;
      const list = setProductListByLang(data, language);
      if(list && list.length > 0) setMyProducts(list);
    }
  }, [getMyClassData])
  useEffect(()=>{
    if(!getFaqListData) return;
    console.log('getFaqListData', getFaqListData)

    if(getFaqListData && getFaqListData.length > 0) setFaqListData(getFaqListData);
  }, [getFaqListData])

  useEffect(()=>{
    setTopikProductsFilt(topikProducts);
  },[topikProducts])
  useEffect(()=>{
    setKoreanProductsFilt(koreanProducts);
  },[koreanProducts])
  useEffect(()=>{
    setTeachersDataFilt(teachersData);
  },[teachersData])
  useEffect(()=>{
    setMyProductsFilt(myProducts);
  },[myProducts])

  useEffect(()=>{
    if (process.env.REACT_APP_ENV === "local") {
      if(getMainError === undefined && getProductsError === undefined){
        setMainData(page);
        setTopikProducts(product.videoclassProductTopikList);
        setKoreanProducts(product.videoclassProductKoList);
        setTeachersData(setTeachersListByLang(teacherItems, language));
      }
      if(getMyClassError === undefined){
        setMyProducts(myclass.videoclassMyProductList);
      }
      if(getFaqListError === undefined){
        setFaqListData(faqList);
      }
    }
  },[getMainError, getProductsError, getMyClassError, getFaqListError])

  useErrorToastify(getMainError);
  useErrorToastify(getProductsError);
  useErrorToastify(getMyClassError);
  useErrorToastify(getFaqListError);

  useEffect(()=>{
    if (topikLang === 'ALL'){
      setTopikProductsFilt(topikProducts);
    } else if (topikLang === 'KO' || topikLang === 'EN'){
      setTopikProductsFilt(topikProducts.filter((data)=>data.classLang === topikLang));
    } else { //ETC
      setTopikProductsFilt(topikProducts.filter((data)=>(data.classLang !== 'KO' && data.classLang !== 'EN')));
    }
  }, [topikLang])
  useEffect(()=>{
    if(koreanLang === 'ALL'){
      setKoreanProductsFilt(koreanProducts);
    } else if (koreanLang === 'KO' || koreanLang === 'EN'){
      setKoreanProductsFilt(koreanProducts.filter((data)=>data.classLang === koreanLang));
    } else { //ETC
      setKoreanProductsFilt(koreanProducts.filter((data)=>(data.classLang !== 'KO' && data.classLang !== 'EN')));
    }
  }, [koreanLang])
  useEffect(()=>{
    if(myClassLang === 'ALL'){
      setMyProductsFilt(myProducts);
    } else if (myClassLang === 'KO' || myClassLang === 'EN'){
      setMyProductsFilt(myProducts.filter((data)=>data.classLang === myClassLang));
    } else { //ETC
      setMyProductsFilt(myProducts.filter((data)=>(data.classLang !== 'KO' && data.classLang !== 'EN')));
    }
  }, [myClassLang])

  return (
    <>
      <SeoHelmet
        title={SeoData.aliveclass.title}
        desc={SeoData.aliveclass.desc}
      />
      <LoadingOverlay isLoading={getMainLoading || getProductsLoading || getMyClassLoading} />


      <ContentsWrapper>
        <PageHeader title={t('title')}/>

        {mainData //|| topikProducts.length > 0 || koreanProducts.length > 0
        ? <>
        <Styled.SubTitle>
          <div>{mainData.subTitle1}</div>
          <div>{mainData.subTitle2}</div>
          <a className='link' href="#" onClick={(e)=>{e.preventDefault(); openLink("https://mtalive.masterk1.com");}}>Alive Classroom</a>
        </Styled.SubTitle>

        <StickyArea
          dday={mainData.bandBannerDDay}
          text={mainData.bandBannerText}
          link={mainData.bandBannerLink}
          showTeachers={teachersData.length > 0}
          showTopik={topikProducts.length > 0}
          showKorean={koreanProducts.length > 0}
          showMyClass={isLogin && myProducts}
        />

        <Styled.Section id='Overview'>
          <H3>{t('overview.title')}</H3>

          <Styled.FaqWrapper>
            {faqListData &&
              faqListData.map((item, index) => (
                <MemoizedFaqItem
                  key={index}
                  item={item}
                  onClick={() => setSelectedFaq(selectedFaq === index ? 0 : index)}
                  classActive={selectedFaq === index && 'active'}
                />
              ))
            }
          </Styled.FaqWrapper>

          <Styled.Overview>
            <table>
              <colgroup>
                <col /><col />
              </colgroup>
              <tbody>
                <tr>
                  <th><span>{t('overview.label1')}</span></th>
                  <td>{mainData.classSchedule}</td>
                </tr>
                <tr>
                  <th><span>{t('overview.label2')}</span></th>
                  <td>{mainData.classTime}</td>
                </tr>
                <tr>
                  <th><span>{t('overview.label3')}</span></th>
                  <td>{mainData.classPrice}</td>
                </tr>
                <tr>
                  <th><span>{t('overview.label4')}</span></th>
                  <td>{mainData.classApplication}</td>
                </tr>
                <tr>
                  <th><span>{t('overview.label5')}</span></th>
                  <td>{mainData.classPeriod}</td>
                </tr>
                <tr>
                  <th><span>{t('overview.label6')}</span></th>
                  <td>{mainData.classLanguage}</td>
                </tr>
                <tr>
                  <th><span>{t('overview.label7')}</span></th>
                  <td>{mainData.classRemarks}</td>
                </tr>
              </tbody>
            </table>
          </Styled.Overview>
        </Styled.Section>

        {teachersDataFilt.length > 0
        ? <Styled.Section id='Teachers'>
            <Styled.ClassTitle>
              <H3>{t('teacher.title')}</H3>
            </Styled.ClassTitle>
            <Styled.ClassList>
              {teachersDataFilt.map((data, index)=>{
                return(
                  <TeacherItem 
                    key={'teachers'+index}
                    item={data}
                  />
                );
              })}
            </Styled.ClassList>
          </Styled.Section>
        :<></>}

        {topikProducts.length > 0
        ? <Styled.Section id='TOPIKClass'>
            <Styled.ClassTitle>
              <H3>{t('topik.title')}</H3>
              <Styled.ClassLang>
                <ul>
                  <li className={topikLang === 'ALL' ? 'active': ''}><button onClick={()=>setTopikLang('ALL')}>ALL</button></li>
                  <li className={topikLang === 'EN' ? 'active': ''}><button onClick={()=>setTopikLang('EN')}>EN</button></li>
                  <li className={topikLang === 'KO' ? 'active': ''}><button onClick={()=>setTopikLang('KO')}>KO</button></li>
                  <li className={topikLang === 'ETC' ? 'active': ''}><button onClick={()=>setTopikLang('ETC')}>ETC.</button></li>
                </ul>
              </Styled.ClassLang>
            </Styled.ClassTitle>
            <Styled.ClassList>
              {topikProductsFilt.map((data, index)=>{
                return(
                  <ClassItem
                    key={'topikClass'+index}
                    item={data}
                  />
                );
              })}
            </Styled.ClassList>
          </Styled.Section>
        :<></>}

        {koreanProducts.length > 0
        ? <Styled.Section id='GeneralKorean'>
            <Styled.ClassTitle>
              <H3>{t('korean.title')}</H3>
              <Styled.ClassLang>
                <ul>
                  <li className={koreanLang === 'ALL' ? 'active': ''}><button onClick={()=>setKoreanLang('ALL')}>ALL</button></li>
                  <li className={koreanLang === 'EN' ? 'active': ''}><button onClick={()=>setKoreanLang('EN')}>EN</button></li>
                  <li className={koreanLang === 'KO' ? 'active': ''}><button onClick={()=>setKoreanLang('KO')}>KO</button></li>
                  <li className={koreanLang === 'ETC' ? 'active': ''}><button onClick={()=>setKoreanLang('ETC')}>ETC.</button></li>
                </ul>
              </Styled.ClassLang>
            </Styled.ClassTitle>
            <Styled.ClassList>
              {koreanProductsFilt.map((data, index)=>{
                return(
                  <ClassItem
                    key={'koreanClass'+index}
                    item={data}
                  />
                );
              })}
            </Styled.ClassList>
          </Styled.Section>
        : <></>}

        {isLogin
        ? <Styled.Section id='MyClass'>
            <Styled.ClassTitle>
              <H3>{t('myclass.title')}</H3>
              <Styled.ClassLang>
                <ul>
                  <li className={myClassLang === 'ALL' ? 'active': ''}><button onClick={()=>setMyClassLang('ALL')}>ALL</button></li>
                  <li className={myClassLang === 'EN' ? 'active': ''}><button onClick={()=>setMyClassLang('EN')}>EN</button></li>
                  <li className={myClassLang === 'KO' ? 'active': ''}><button onClick={()=>setMyClassLang('KO')}>KO</button></li>
                  <li className={myClassLang === 'ETC' ? 'active': ''}><button onClick={()=>setMyClassLang('ETC')}>ETC.</button></li>
                </ul>
              </Styled.ClassLang>
            </Styled.ClassTitle>
            {myProductsFilt.length > 0
              ? <Styled.ClassList>
                  {myProductsFilt.map((data, index)=>{
                    return(
                      <ClassItem
                        key={'myClass'+index}
                        item={data}
                      />
                    );
                  })}
                </Styled.ClassList>
              : <Styled.MyClass>
                  <p>{t('myclass.empty')}</p>
                </Styled.MyClass>
            }
          </Styled.Section>
        : <></>}

        <Styled.Section id='Notification'>
          <H3>{t('notification.title')}</H3>
          <Styled.Notification>
            <dl>
              <dt>FAQ : <a href="#" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/8068667121427")}}>{t('notification.group1.title')}</a></dt>
              <dd>{t('notification.group1.desc1')}</dd>
              <dd>{t('notification.group1.desc2')}</dd>
              <dd>{t('notification.group1.desc3')}</dd>
              <dt>{t('notification.group2.title')}</dt>
              <dd><strong>{t('notification.group2.date1')}</strong> : <span> {mainData.noteRecruitPeriod}</span></dd>
              <dd><strong>{t('notification.group2.date2')}</strong> : <span> {mainData.noteBeginDate}</span></dd>
              <dd><strong>{t('notification.group2.date3')}</strong> : <span> {mainData.noteClassPeriod}</span></dd>
              <dd><strong>{t('notification.group2.date4')}</strong>{t('notification.date.add')} : <span>{mainData.noteClassNotice}</span></dd>
              <dd>{t('notification.group2.desc1')}</dd>
              <dd>{t('notification.group2.desc2')}</dd>
              <dt>{t('notification.group3.title')}</dt>
              <dd><strong>{t('notification.group3.desc1')}</strong> : <a href="#" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4759778093203")}}>{t('notification.click')}</a></dd>
              <dd><strong>{t('notification.group3.desc2')}</strong> : <a href="#" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4759908099859")}}>{t('notification.click')}</a></dd>
              <dd><strong>{t('notification.group3.desc3')}</strong> : <a href="#" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/8049714206355")}}>{t('notification.click')}</a></dd>
              <dd><strong>{t('notification.group3.desc4')}</strong> : <a href="#" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/7969982999059")}}>{t('notification.click')}</a></dd>
            </dl>
            <p>{t('notification.inquiry')} : <a href="mailto:mastertopik@visang.com">mastertopik@visang.com</a></p>
          </Styled.Notification>
        </Styled.Section>
        </>
        : <></>}

      </ContentsWrapper>
    </>
  );
};

export default AliveClassPage;

const setMainDataByLang = (data, lang) => {
  if(!data) return;

  //lang : 'ko-KR' | 'en-US' | 'zh-CN' | 'ja-JP'
  let obj = null;
  if(lang === 'ko-KR'){
    obj = {
      subTitle1: data.subTitleKo1,
      subTitle2: data.subTitleKo2,
      bandBannerDDay: data.bandBannerDDay,
      bandBannerText: data.bandBannerTextKo,
      bandBannerLink: data.bandBannerLinkKo,
      classSchedule: data.classScheduleKo,
      classTime: data.classTimeKo,
      classPrice: data.classPriceKo,
      classApplication: data.classApplicationKo,
      classPeriod: data.classPeroidKo,
      classLanguage: data.classLanguageKo,
      classRemarks: data.classRemarksKo,
      noteRecruitPeriod: data.noteRecruitPeriodKo,
      noteBeginDate: data.noteBeginDateKo,
      noteClassPeriod: data.noteClassPeriodKo,
      noteClassNotice: data.noteClassNoticeKo,
    };
  }else{
    obj = {
      subTitle1: data.subTitleEn1,
      subTitle2: data.subTitleEn2,
      bandBannerDDay: data.bandBannerDDay,
      bandBannerText: data.bandBannerTextEn,
      bandBannerLink: data.bandBannerLinkEn,
      classSchedule: data.classScheduleEn,
      classTime: data.classTimeEn,
      classPrice: data.classPriceEn,
      classApplication: data.classApplicationEn,
      classPeriod: data.classPeroidEn,
      classLanguage: data.classLanguageEn,
      classRemarks: data.classRemarksEn,
      noteRecruitPeriod: data.noteRecruitPeriodEn,
      noteBeginDate: data.noteBeginDateEn,
      noteClassPeriod: data.noteClassPeriodEn,
      noteClassNotice: data.noteClassNoticeEn,
    };
  }

  return obj;
}

const setProductListByLang = (arr, lang) => {
  if(!arr || arr.length <= 0 || !Array.isArray(arr)) return;

  //lang : 'ko-KR' | 'en-US' | 'zh-CN' | 'ja-JP'
  let list = [];
  if(lang === 'ko-KR'){
    arr.map((data)=>{
      if(!data.tblProductIdx) return;
      else
      list = [...list, {
        tblProductIdx: data.tblProductIdx,                //상품코드
        productTitle: data.productTitle,                  //상품명

        discountDefault: data.discountDefault_KO,         //기본할인명
        discountDefaultType: data.discountDefault_won,    //할인율PER|할인금액CST
        discountDefault_amt: data.discountDefault_won_amt,//할인 숫자
        discountSpecial: data.discountSpecial_KO,         //특별할인명
        discountSpecialType: data.discountSpecial_won,    //할인율PER|할인금액CST
        discountSpecial_amt: data.discountSpecial_won_amt,//할인 숫자
        priceCost: data.priceCostWon,       //정가 금액
        salesStore: data.salesStoreWon,     //판매가 금액
        discountType: data.discountTypeWon, //판매가 할인율PER|할인금액CST
        discountCost: data.discountCostWon, //판매가 할인 숫자

        classType: data.classType,          //수업구분 TOPIK|KO
        classLang: data.classLang.trim(),   //수업진행언어 KO|EN|CN|JP|ETC
        classLangEtc: data.classLangEtcKO,  //ETC일때 언어
        classLevel: data.classLevel,        //수업레벨 (0/1/2/3/4/5/6)
        classDay: data.classDay,            //수업일정 (요일) (월/화/수/목/금/토/일)
        classTimeStart: data.classTimeStart,//수업일정 (시작시간)
        classTimeEnd: data.classTimeEnd,    //수업일정 (종료시간)
        classDuration: data.classDuration,  //수업구성 - 수업 시간 (분)
        classRound: data.classRound,        //수업구성 - 수업 시수 (회)
        classDetail: data.classDetail_KO,   //수업 상세 소개
        classCurri: data.classCurri_KO,     //수업 커리큘럼

        tblMemberTeacherIdx: data.tblMemberTeacherIdx,  //선생님 코드번호
        teacherID: data.teacherID,                      //선생님 이메일
        teacherName: data.teacherName,                  //선생님 이름
        profiles: data.profiles,              //선생님 history
        profilesDetail: data.profilesDetail,  //선생님 상세소개
        slogan: data.slogan,                  //선생님 슬로건 > 이게 상세소개네...
        movURL: data.movURL,                  //선생님 소개url

        salesStatus: data.salesStatus ? data.salesStatus : null, //상품 판매 상태 WAIT | SALE | STOP | FIN
      }];
    });
  }else{
    arr.map((data)=>{
      if(!data.tblProductIdx) return;
      else
      list = [...list, {
        tblProductIdx: data.tblProductIdx,                //상품코드
        productTitle: data.productTitleEN,                  //상품명

        discountDefault: data.discountDefault_EN,         //기본할인명
        discountDefaultType: data.discountDefault_usd,    //할인율PER|할인금액CST
        discountDefault_amt: data.discountDefault_usd_amt,//할인 숫자
        discountSpecial: data.discountSpecial_EN,         //특별할인명
        discountSpecialType: data.discountSpecial_usd,    //할인율PER|할인금액CST
        discountSpecial_amt: data.discountSpecial_usd_amt,//할인 숫자
        priceCost: data.priceCostDollar,        //정가 금액
        salesStore: data.salesStoreDollar,      //판매가 금액
        discountType: data.discountTypeDollar,  //판매가 할인율PER|할인금액CST
        discountCost: data.discountCostDollar,  //판매가 할인 숫자

        classType: data.classType,          //수업구분 TOPIK|KO
        classLang: data.classLang.trim(),   //수업진행언어 KO|EN|CN|JP|ETC
        classLangEtc: data.classLangEtcEN,  //ETC일때 언어
        classLevel: data.classLevel,        //수업레벨 (0/1/2/3/4/5/6)
        classDay: data.classDay,            //수업일정 (요일) (월/화/수/목/금/토/일)
        classTimeStart: data.classTimeStart,//수업일정 (시작시간)
        classTimeEnd: data.classTimeEnd,    //수업일정 (종료시간)
        classDuration: data.classDuration,  //수업구성 - 수업 시간 (분)
        classRound: data.classRound,        //수업구성 - 수업 시수 (회)
        classDetail: data.classDetail_EN,   //수업 상세 소개
        classCurri: data.classCurri_EN,     //수업 커리큘럼
        tblMemberTeacherIdx: data.tblMemberTeacherIdx,  //선생님 코드번호
        teacherID: data.teacherID,                      //선생님 이메일
        teacherName: data.teacherNameEn,                  //선생님 이름
        profiles: data.profilesEN,              //선생님 history
        profilesDetail: data.profilesDetailEN,  //선생님 상세소개
        slogan: data.sloganEN,                  //선생님 슬로건 > 이게 상세소개네...
        movURL: data.movURL,                    //선생님 소개url

        salesStatus: data.salesStatus ? data.salesStatus : null, //상품 판매 상태 WAIT | SALE | STOP | FIN
      }]
    });
  }

  // console.log('list', list)
  return list;
}

const setTeachersListByLang = (arr, lang) => {
  if(!arr || arr.length <= 0 || !Array.isArray(arr)) return;

  //lang : 'ko-KR' | 'en-US' | 'zh-CN' | 'ja-JP'
  let list = [];
  if(lang === 'ko-KR'){
    arr.map((data)=>{
      if(!data.tblMemberTeacherIdx) return;
      else
      list = [...list, {
        tblMemberTeacherIdx: data.tblMemberTeacherIdx,  //선생님 코드번호
        teacherID: data.teacherID,                      //선생님 이메일
        teacherImage: data.thumbnailPath1,                //선생님 이미지
        teacherName: data.teacherName,                  //선생님 이름
        subject: data.teachingSubject,                  //수업하는 과목
        subjectEtc: data.teachingSubjectEtcKO,          //수업하는 과목 - 기타
        classLang: data.classLang,                      //가능 언어
        classLangEtc: data.classLangEtcKO,              //가능 언어 - 기타
        livingIn: data.livingInKO,                      //거주 국가
        payPerHour: data.payPerHourKO,                  //시간당 금액
        slogan: data.slogan,                            //선생님 슬로건
        teachingStyle: data.teachingStyleKO,            //수업 스타일
        teachingType: data.teachingTypeKO,              //수업 종류
        teachingTime: data.teachingTimeKO,              //수업 가능 시간
      }];
    });
  }else{
    arr.map((data)=>{
      if(!data.tblMemberTeacherIdx) return;
      else
      list = [...list, {
        tblMemberTeacherIdx: data.tblMemberTeacherIdx,  //선생님 코드번호
        teacherID: data.teacherID,                      //선생님 이메일
        teacherImage: data.thumbnailPath1,              //선생님 이미지
        teacherName: data.teacherNameEN,                //선생님 이름
        subject: data.teachingSubject,                  //수업하는 과목
        subjectEtc: data.teachingSubjectEtcEN,          //수업하는 과목 - 기타
        classLang: data.classLang,                      //가능 언어
        classLangEtc: data.classLangEtcEN,              //가능 언어 - 기타
        livingIn: data.livingInEN,                      //거주 국가
        payPerHour: data.payPerHourEN,                  //시간당 금액
        slogan: data.sloganEN,                          //선생님 슬로건
        teachingStyle: data.teachingStyleEN,            //수업 스타일
        teachingType: data.teachingTypeEN,              //수업 종류
        teachingTime: data.teachingTimeEN,              //수업 가능 시간
      }]
    });
  }

  // console.log('list', list)
  return list;
}
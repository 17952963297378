import styled, { css } from 'styled-components';
import Board from 'component/Board/index.style';


const StyledBoard = {
  Wrapper: styled(Board.Wrapper)``,
  Header: styled(Board.Header)``,
  Item: styled(Board.Item)``,
  Column: styled(Board.Column)``,
};

export default StyledBoard;

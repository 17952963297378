import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledHeader = {
  Wrapper: styled.header`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 10em;
    background-color: #1f2024;
    @media (min-width: 1025px) {
      display: none;
    }
  `,
  Link: styled(Link)`
    width: 25%;
    color: #d1d1d1;
    img {
      width: 2.6em;
      margin-bottom: 0.5em;
    }
    span {
      display: block;
      font-size: 1.8em;
    }
  `,
};

export default StyledHeader;

import React, { useEffect, useState } from 'react';
import Styled from './SiteMap.style';
import { useTranslation } from 'react-i18next';
import { SiteMapData } from 'common/constant';
import { AiFillFacebook, AiFillYoutube } from 'react-icons/ai';
import { BiPlayCircle } from 'react-icons/bi';
import { FiInstagram } from 'react-icons/fi';
import playstoreImg from 'assets/images/footer-btn-playstore.jpg';
import tutoringImg from 'assets/images/footer-btn-tutoring.jpg';
import Img from 'component/atoms/Img';
import { toBeContinue } from 'common/util/util';
import OrderBySelect from 'component/molecules/OrderBySelect';
import { openLink } from 'common/util/util';
import makeAnimated from 'react-select/animated';
import { languageCode } from 'common/constant';
const animatedComponents = makeAnimated();

const familySiteOption = [
  { label: '1', value: 'https://www.masterTopik.com' },
  { label: '2', value: 'https://www.naver.com' },
  { label: '3', value: 'https://www.google.co.kr/' },
];

export default function SiteMap() {
  const { i18n } = useTranslation();
  const { t } = useTranslation('footer');


  const [selectedOption, setSelectedOption] = useState(1);

  const handleCurrency = (e) => setSelectedOption(e);

  const clickFamilySite = (e) => {
    i18n.changeLanguage(e.value);
    window.location.reload();
    // window.open(e.value, '_blank');
  };
  return (
    <Styled.Section>
      <Styled.Group>

        <Styled.GroupName>
        <Styled.ItemLink to={SiteMapData.course}>
            <span>Course
            <svg width="7px" height="10px" className='arrow'>
              <path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M0.500,9.800 L0.500,0.200 L6.500,4.1000 L0.500,9.800 Z"/>
            </svg>
          </span>
          </Styled.ItemLink>
        </Styled.GroupName>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.topik}>
            <span>TOPIK</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.Korean}>
            <span>Korean</span>
          </Styled.ItemLink>
        </Styled.Item>

        <Styled.GroupName className='mock'>
        <Styled.ItemLink to={SiteMapData.mock}>
            <span>TOPIK Mock Test

            <svg width="7px" height="10px" className='arrow'>
              <path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M0.500,9.800 L0.500,0.200 L6.500,4.1000 L0.500,9.800 Z"/>
            </svg>
            </span>
          </Styled.ItemLink>
        </Styled.GroupName>
        <Styled.GroupName className='ai'>
        <Styled.ItemLink to={SiteMapData.aispeak}>
            <span>AI SPEAK
            <svg width="7px" height="10px" className='arrow'>
              <path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M0.500,9.800 L0.500,0.200 L6.500,4.1000 L0.500,9.800 Z"/>
            </svg>
                </span>
          </Styled.ItemLink>
        </Styled.GroupName>
        {/* <Styled.Item>
          <Styled.ItemLink to={SiteMapData.resources}>
            <span>Resources</span>
          </Styled.ItemLink>
        </Styled.Item> */}
        {/* <Styled.Item>
          <Styled.ItemLink to={SiteMapData.library}>
            <span>Library</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.plan}>
            <span>Plan</span>
          </Styled.ItemLink>
        </Styled.Item> */}

        {/* 20230210 GNB에서 Alive class 삭제 (B2C 화상수업 종료) */}
        {/* <Styled.Item>
          <Styled.ItemLink to={SiteMapData.aliveClass}>
            <span>Alive Class</span>
          </Styled.ItemLink>
        </Styled.Item> */}
        {/* <Styled.Item>
          <Styled.ItemLink to="" onClick={()=>{openLink('https:' + SiteMapData.externalAliveClass, '_blank'); return false;}}>
            <span>Alive Class</span>
          </Styled.ItemLink>
        </Styled.Item> */}
      </Styled.Group>

      <Styled.Group>
        <Styled.GroupName>
        <Styled.ItemLink to={SiteMapData.plan}>
            <span>Plan
            <svg width="7px" height="10px" className='arrow'>
              <path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M0.500,9.800 L0.500,0.200 L6.500,4.1000 L0.500,9.800 Z"/>
            </svg>

            </span>
          </Styled.ItemLink>
        </Styled.GroupName>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.free}>
            <span><b> Free Trial​</b></span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.yearly}>
            <span>Yearly Plan​</span>
          </Styled.ItemLink>
        </Styled.Item>
        {/* <Styled.Item>
          <Styled.ItemLink to={SiteMapData.visang} target='_blank'>
            <span>Visang</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.about}>
            <span>About</span>
          </Styled.ItemLink>
        </Styled.Item> */}
      </Styled.Group>

      <Styled.Group>
        <Styled.GroupName>
        <Styled.ItemLink to={SiteMapData.resources}>
            <span>Resources

            <svg width="7px" height="10px" className='arrow'>
              <path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M0.500,9.800 L0.500,0.200 L6.500,4.1000 L0.500,9.800 Z"/>
            </svg>
            </span>
          </Styled.ItemLink>
          </Styled.GroupName>
        {/* <Styled.Item>
          <Styled.ItemLink to={t('faq')} target='_blank'>
            <span>FAQ</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={t('notice')} target='_blank'>
            <span>Notice</span>
          </Styled.ItemLink>
        </Styled.Item> */}


         <Styled.Item>
          <Styled.ItemLink to={SiteMapData.learningMaterial}>
            <span>Learning Materials​</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.level}>
            <span>Level Test​</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.bookstore}>
            <span>Bookstore​</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.audio}>
            <span>Audio​</span>
          </Styled.ItemLink>
        </Styled.Item>

        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.vocaCard}>
            <span>Voca Cards​</span>
          </Styled.ItemLink>
        </Styled.Item>

         <Styled.Item>
          <Styled.ItemLink to={SiteMapData.help}>
            <span>Help</span>
          </Styled.ItemLink>
        </Styled.Item>

        <Styled.Item>
          <Styled.ItemLink to={t('contact')} target='_blank'>
            <span>Contact</span>
          </Styled.ItemLink>
        </Styled.Item>
      </Styled.Group>


      <Styled.Group className='about'>
        <Styled.GroupName>
        <Styled.ItemLink to={SiteMapData.about}>
            <span>About
            <svg width="7px" height="10px" className='arrow'>
              <path fill-rule="evenodd"  fill="rgb(255, 255, 255)" d="M0.500,9.800 L0.500,0.200 L6.500,4.1000 L0.500,9.800 Z"/>
            </svg>
            </span>
          </Styled.ItemLink>
        </Styled.GroupName>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.privacy} >
            <span>Privacy</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.terms} >
            <span>Terms</span>
          </Styled.ItemLink>
        </Styled.Item>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.visang} target='_blank'>
            <span>Visang Education</span>
          </Styled.ItemLink>
        </Styled.Item>

      </Styled.Group>

      <Styled.colGroup className="">

      <Styled.Group className='lang'>
        <Styled.GroupName>Language</Styled.GroupName>

         <Styled.StyledSelect
            clasName="select-box"
            options={[
                {
                  name: languageCode.KOREAN.code.toUpperCase(),
                  value: languageCode.KOREAN.locale,
                  label: '한국어'
                },
                {
                  name: languageCode.JAPANESE.code.toUpperCase(),
                  value: languageCode.JAPANESE.locale,
                  label: '日本語​'
                },
                {
                  name: languageCode.CHINESE.code.toUpperCase(),
                  value: languageCode.CHINESE.locale,
                  label: '中国话​'
                },
                {
                  name: languageCode.ENGLISH.code.toUpperCase(),
                  value: languageCode.ENGLISH.locale,
                  label: 'English'
                },
            ]}
            components={animatedComponents}
            onChange={clickFamilySite}
            placeholder={i18n.language === languageCode.KOREAN.locale ? '한국어' : i18n.language === languageCode.JAPANESE.locale ? '日本語​' :  i18n.language === languageCode.CHINESE.locale ? '中国话​': 'English'}
          />
      </Styled.Group>

      <Styled.Group className='sns'>
        <Styled.GroupName>Follow</Styled.GroupName>
          <Styled.SnsGroupLists>

            <Styled.Item>
              <Styled.ItemLink to={SiteMapData.youtube} target='_blank'>
                <AiFillYoutube />
                {/* <span>Youtube</span> */}
              </Styled.ItemLink>
            </Styled.Item>
            {/* <Styled.Item>
              <Styled.ItemLink to={SiteMapData.youku} target='_blank'>
                <BiPlayCircle />
                <span>Youku</span>
              </Styled.ItemLink>
            </Styled.Item> */}
            <Styled.Item>
              <Styled.ItemLink to={SiteMapData.instagram} target='_blank'>
                <FiInstagram />
                {/* <span>Instagram</span> */}
              </Styled.ItemLink>
            </Styled.Item>
            <Styled.Item>
              <Styled.ItemLink to={SiteMapData.facebook} target='_blank'>
                <AiFillFacebook />
                {/* <span>Facebook</span> */}
              </Styled.ItemLink>
            </Styled.Item>
        </Styled.SnsGroupLists>
      </Styled.Group>




      <Styled.Group className='class'>
        <Styled.GroupName>Download{/* Class */}</Styled.GroupName>
        <Styled.Item>
          <Styled.ItemLink to={SiteMapData.googlePlayStore} target='_blank'>
            <span>
              <Img src={playstoreImg} alt='Google Play Store' />
            </span>
          </Styled.ItemLink>
        </Styled.Item>
        {
          // <Styled.Item className='select-box'>
          //   <OrderBySelect
          //     placeholder='Family Site'
          //     optionList={familySiteOption}
          //     onChange={(e) => clickFamilySite(e)}
          //   />
          // </Styled.Item>
        }
        {/* MasterK 사이트 오픈 후 등록 예정 */}
        {/* <Styled.Item>
          <Styled.ItemLink to={SiteMapData.masterK} target='_blank'>
            <span>
              <Img src={tutoringImg} alt='Tutoring' />
            </span>
          </Styled.ItemLink>
        </Styled.Item> */}
      </Styled.Group>
      </Styled.colGroup>


    </Styled.Section>
  );
}

import styled, { css } from 'styled-components';
import arrowRightIcon from 'assets/images/arrow_right.svg';

const style = css`
  position: absolute;
  display: none;
  top: 50%;
  z-index: 10;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 4px;
  background-color: #cf9404;
  cursor: pointer;
  transform: rotate(45deg);
  transition: all 0.2s ease 0.2s;
  opacity: 0.8;
  &:after {
    content: '';
    position: absolute;
    top: -25%;
    left: -25%;
    width: 150%;
    height: 150%;
    opacity: 1;
    background: url(${arrowRightIcon}) no-repeat center;
    background-size: 1.6rem;
  }
  &.swiper-button-disabled {
    opacity: 0.6;
  }
  &.visible {
    display: block;
  }
  @media (max-width: 1024px) {
    display: none;
    &.visible {
      display: none;
    }
  }
`;

const SliderNav = {
  Next: styled.button.attrs({ type: 'button' })`
    ${style}
    right: -10rem;
    &:after {
      transform: rotate(-45deg);
    }
    @media (max-width: 1600px) {
      right: -2.1rem;
    }
  `,
  Prev: styled.button.attrs({ type: 'button' })`
    ${style}
    left: -10rem;
    &:after {
      transform: rotate(135deg);
    }
    @media (max-width: 1600px) {
      left: -2.1rem;
    }
  `,
};

export default SliderNav;

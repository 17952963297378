import styled from "styled-components";
import visualBgImg from 'assets/images/event/220609/visual_bg.jpg';
import visualImg from 'assets/images/event/220609/visual.jpg';
import visualImgKo from 'assets/images/event/220609/ko/visual.jpg';
import linkIcon from 'assets/images/event/220609/ico_arrow.png';
import titleBgImg from 'assets/images/event/220609/bg_tit.png';
import titleBgImg2 from 'assets/images/event/220609/bg_tit2.png';
import arrowRightIcon from 'assets/images/arrow_right.svg';

const StyledEventPage = {
  EventWrap: styled.div`
    background:#e8f2d7;
		font-size: 2rem;
    line-height: 1.2;
    font-weight:700;
    text-align:center;
    color:#555;
    letter-spacing: -0.035em;

		.blind {
			overflow:hidden;
			position: absolute;
			clip:rect(0 0 0 0);
			width:1px;
			height:1px;
			margin:-1px;
		}

    .inner {
      margin: auto;
    }

    h2 {
      padding: 5em 0 1.5em;
      color:#00736d;
      font-size: 3rem;
      letter-spacing: -0.03em;
      font-weight:400;
    }

    .topBanner {
      background:#00909d;
      padding: 1.2em;

      a {
        position:relative;
        color:#ffdb94;
        font-size: 2.2rem;

        &:after {
          content:"";
          display:inline-block;
          width:1.455em;
          height:0.773em;
          background: url(${linkIcon}) 0 0 no-repeat;
          background-size:contain;
          margin-left:0.6em;
        }
      }
    }

    .visual {
      position:relative;
      background:url(${visualBgImg}) 0 0 repeat-x;
			.inner {
				position:relative;
        background-image: url(${visualImg});
        background-position: 50% 0;
        background-repeat:no-repeat;
        background-size: 100% auto;
				max-width: 870px;
        padding-top: 1088px;
				margin: 0 auto;
				z-index:2;
      }

      .btnWrap {
        margin-top:2.2em;
        a {
          display:inline-block;
          background:#e8255f;
          padding: 0.8em 3em;
          border-radius: 2em;
          color:#fff;
          font-size:3rem;
        }
        p {
          margin-top:1em;
          color:#059f49;
          font-size:2rem;
          font-weight:400;
        }
      }
    }
		.visual.ko {
			.inner {
        background-image: url(${visualImgKo});
      }
		}

    .applyWrap {
      padding-bottom: 6em;
      .inner {
        max-width:750px;
      }
      h2 {
        div {
          position:relative;
          padding-top:0.6em;
          &:before {
            content:"";
            position: absolute;
            top:0;
            left:50%;
            width: 3em;
            height: 0.12em;
            margin-left:-1.4em;
            background:#00736d;
          }
        }
      }
      h3 {
        background:#24a29b;
        padding:0.5em 0;
        color:#fff;
        font-size:3.4rem;
      }
      .timetable {
        display: flex;
        flex-wrap: wrap;
        background:#fff;
        padding: 2.4em 0;
        font-size:2.6rem;
        font-weight:400;
        color:#202020;

        > div {
          flex: 1;
          padding:0 2em;
        }
        .right {
          border-left:2px #c9c9c9 solid;
        }
        h4 {
          margin-bottom:1em;
          font-size:3rem;
          color:#059f49;

          span {
            background: url(${titleBgImg}) 0 90% repeat-x;
            font-weight:700;
          }
        }
        ul {
          text-align:left;

          li {
            display:flex;
            position:relative;
            padding-left: 0.9em;
            margin-top: 0.6em;
            &:before {
              content:"";
              position: absolute;
              top: 0.4em;
              left: 0;
              width: 0.4em;
              height: 0.4em;
              background: #202020;
              border-radius: 0.2em;
            }
            &:first-child {
              margin-top: 0;
            }
            span {
              display: inline-block;
              margin-right: 1em;
            }
          }
        }
        .btnApply {
          display:block;
          padding: 0.7em 0 0.9em;
          margin-top: 1em;
          background:#e8255f;
          color:#fff;
          font-size: 3rem;
          line-height: 1;
          font-weight: 700;
        }
      }
      .btnDetail {
        margin-top: 1.5em;

        a {
          color:#059f49;
          font-weight: 400;
          font-size: 2.4rem;
          border-bottom: 1px solid #059f49;

          &:after {
            content:"";
            display: inline-block;
            width: 0.4em;
            height: 0.4em;
            background-color:transparent;
            border-top: 1px solid #059f49;
            border-left: 1px solid #059f49;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -ms-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
            margin-left: 0.1em;
            vertical-align:middle;

          }
        }
      }
    }

    .howto {
      padding-bottom: 6em;
      .title {
        background:#199851;
        padding: 0.8em 0;

        h2 {
          padding: 0;
          color:#fff;
          font-size: 2.2rem;

          span {
            &:before,
            &:after {
              content:"";
              display: inline-block;
              width: 7.137em;
              height: 0.819em;
              background: url(${titleBgImg2}) 0 0 no-repeat;
            }
            &:before {
              margin-right: 2em;
            }
            &:after {
              margin-left: 2em;
            }
          }
        }
      }

      .content {
        max-width: 750px;
        margin: auto;
        em {
          display: inline-block;
          width: 2.3em;
          height: 2.3em;
          font-size: 2.6rem;
          color:#fff;
          border-radius: 1.15em;
          line-height: 2.2em;
        }
        h3 {
          margin: 0.4em 0 1em;
          font-size: 3.6rem;
          font-weight: 700;
        }
        .box {
          background:#fff;
          padding: 2.6em;
          box-shadow: 0 0.2em 0 0 rgba(0,0,0,.1);
          font-weight: 400;
          font-size: 2.6rem;
          color: #202020;
          text-align: left;
          strong {
            display: inline-block;
            margin:0 0 0.4em 0;
          }
          .stit2 {
            color:#059f49;
          }
          ul li {
            margin-top: 1.1em;
            &:first-child {
              margin-top:0;
            }
            a {
              border-bottom: 1px solid #999;
              color:#888;
              font-size: 2rem;
              font-weight: 400;

              &:after {
                content:"";
                display: inline-block;
                width: 0.4em;
                height: 0.4em;
                background-color:transparent;
                border-top: 1px solid #999;
                border-left: 1px solid #999;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -ms-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
                margin:-0.2em 0 0 0.1em;
                vertical-align:middle;
              }
            }
          }
        }
        .btnApply {
          margin-top: 2.4em;
          a {
            display:inline-block;
            padding: 0.65em 0;
            width: 10.6em;
            color:#fff;
            font-size: 3.6rem;
            font-weight: 400;
          }
        }

        .aliveClass {
          padding-top: 4em;
          em {
            background:#f86d68;
          }
          h3 {
            color:#fb1323;
          }
          .box {
            .stit {
              color:#fb1323;
            }
          }
          .btnApply {
            a {
              background:#ff3539;
            }
          }
        }
        .textBook {
          padding-top: 5.5em;
          em {
            background:#b074e7;
          }
          h3 {
            color:#6d2ce2;
          }
          .box {
            .stit {
              color:#6d2ce2;
            }
          }
          .btnApply {
            a {
              background:#983eee;
            }
          }
        }
      }
    }

    .notification {
      background:#2c2c2c;
      text-align:left;
      padding: 4em 0;
      color:#fff;
      font-weight:300;
      letter-spacing:normal;
      .inner {
        max-width: 870px;
        padding: 0 20px;
      }
      h3 {
        font-size:2.8rem;
        font-weight:700;
        text-align: center;
      }

      dt {
        padding: 1.6em 0 0.8em;
        font-weight:700;
      }
      dd {
        position:relative;
        padding-left:0.8em;
        margin-bottom: 0.5em;
        &:before {
          content:"";
          display:inline-block;
          position: absolute;
          top:0.5em;
          left:0;
          width: 0.2em;
          height: 0.2em;
          border-radius: 0.1em;
          background:#fff;
          vertical-align:middle;
        }
        em {
          color:#fff799;
        }
      }
      P {
        padding: 1.6em 0 0.8em;
      }
      a {
        padding-right: 1em;
        border-bottom: 1px #fff solid;
        background: url(${arrowRightIcon}) 100% 50% no-repeat;
        background-size: 0.6em auto;
      }
    }

    @media (max-width: 870px) {
      .visual {
        .inner {
          padding-top:122.5vw;
        }
      }
      .applyWrap {
        .inner {
          padding:0 20px;
        }
      }
      .howto {
        .content {
          padding:0 20px;
        }
      }
    }

    @media (max-width: 580px) {
      .applyWrap {
        .timetable {
          display: block;
          padding: 1.4em 0;
          > div {
            padding:0 1em;
          }
          .right {
            padding-top: 2em;
            border-left:none;
          }
        }
      }
      .howto {
        .content {
          .box {
            padding: 1.4em;
          }
          .btnApply {
            a {
              display:block;
              width: 100%;
            }
          }
        }
      }
    }
  `,
};

export default StyledEventPage;

import styled from "styled-components";
import { Link } from "react-router-dom";
import StyledUtil from "common/styled/Util.style";
import Img from "component/atoms/Img";
import iconNotification from "assets/images/ico_noti01.png";
import iconNotificationLarge from "assets/images/ico_noti01_large.png";
import imgUserNav from "assets/images/img_user_nav.png";
import defaultImage from "assets/images/thumb_default04.png";
const StyledNotification = {
  Container: styled.div`
    position: relative;
    float: left;
    margin: 3em 0 0 1.8em;
  `,
  Button: styled.span.attrs((props) => ({
    children: <>{props.count ? <span>{props.count}</span> : ""}</>,
  }))`
    position: relative;
    display: inline-block;
    width: 3em;
    height: 3em;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${iconNotification});
    vertical-align: middle;
    cursor: pointer;

    span {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      min-width: 20px;
      background-color: #ffbb00;
      border-radius: 50rem 50rem 50rem 50rem;
      line-height: 1.65;
      font-size: 1.2em;
      color: #000000;
      text-align: center;

      @media (max-width: 767px) {
        min-width: 16px;
      }
    }

    @media (max-width: 1024px) {
      background-image: url(${iconNotificationLarge});
      background-size: contain;
    }
  `,
  Notification: styled.div.attrs((props) => ({
    children: (
      <>
        <div>{props.children}</div>
      </>
    ),
  }))`
    display: none;
    position: absolute;
    top: 100%;
    right: -6.5em;
    z-index: 1;

    &.show {
      display: block;
    }

    &:before {
      content: "";
      position: absolute;
      top: 1px;
      right: 6.5em;
      width: 40px;
      height: 25px;
      background: url(${imgUserNav}) no-repeat right center;
    }

    > div {
      margin-top: 2.5em;
      padding: 2em 3em;
      border-radius: 0.6em;
      background-color: #2b2e35;
      box-shadow: 5px 5px 10px rgb(15 16 18 / 30%);
      overflow: hidden;
      width: 38em;
      height: 46em;
    }
    .arrow-right {
      vertical-align: text-bottom;
    }
    @media (max-width: 767px) {
      font-size: 1.5em;
      > div {
        width: 320px;
        height: 30em;
      }
    }
  `,
  Link: styled(Link)`
    display: inline-block;
    margin: 0.5em 0.3em 0 0;
    font-size: 1.4em;
    color: #ffb500;
    text-transform: uppercase;
  `,
  TabAnchor: styled.div`
    &::after {
      content: "";
      display: block;
      clear: both;
    }
  `,
  TabItem: styled.div`
    float: left;
    width: 50%;
    font-size: 1.4em;
    line-height: 2.286em;
    text-align: center;
    border: 1px solid #5c5f66;
    cursor: pointer;
    &:first-child {
      border-radius: 4px 0 0 4px;
      border-right: 0;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
      border-left: 0;
    }

    &.active {
      background-color: #485cc7;
      border-color: #485cc7;
    }
  `,
  List: styled.div`
    display: none;
    margin-top: 2em;
    width: 100%;
    height: calc(100% - 7em);
    border-top: 1px solid #5d6165;
    border-bottom: 1px solid #5d6165;
    overflow-x: hidden;
    overflow-y: auto;

    &.show {
      display: block;
    }
  `,
  Item: styled.div.attrs((props) => ({
    className: `notification`,
    children: (
      <>
        <Link
          to={props.itemLink}
          className={`notification-link${
            props.imgSrc && props.imgSrc !== null ? " upcoming" : ""
          }`}
        >
          {props.imgSrc && props.imgSrc !== null && (
            <div className="notification-img">
              <Img
                src={'/mta/'+ props.imgSrc}
                alt="Upcoming Lectures Thumbnail"
                onError={defaultImage}
              />
            </div>
          )}
          <div className="notification-desc">
            <p className="notification-title">{props.itemTitle}</p>
            <p className="notification-date">{props.itemDate}</p>
          </div>
        </Link>
      </>
    ),
  }))`
    position: relative;
    padding: 2em 1em;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;

    &:first-child {
      border-top: 0;
    }

    .notification-link {
      display: block;
    }

    .upcoming {
      display: flex;
      align-items: stretch;
      justify-content: center;

      .notification-img {
        display: flex;
        width: 8.9em;
        margin-right: 1em;
        align-items: center;
        flex: 0 0 auto;

        img {
          border-radius: 0.6em;
        }
      }

      .notification-desc {
        flex: 1 1 auto;
      }
    }

    &.noread {
      .notification-title {
        font-weight: 400;
        color: #ffffff;
      }

      &::before {
        content: "";
        position: absolute;
        top: 2.5em;
        left: 0;
        width: 0.4em;
        height: 0.4em;
        border-radius: 50%;
        background-color: #ff3867;
      }
    }

    &:hover .notification-title {
      text-decoration: underline;
    }

    .notification-title {
      ${StyledUtil.lineClamp}
      font-size: 1.6em;
      color: #d1d1d1;
    }

    .notification-date {
      margin-top: 0.286em;
      font-size: 1.4em;
      color: #8b8f99;
    }
    @media (max-width: 767px) {
      padding: 1em;
      .notification-title {
        font-size: 1.4em;
      }
    }
  `,
  ListItem: styled.div`
    position: relative;
    padding: 2em 1em;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;

    &:first-child {
      border-top: 0;
    }

    &:hover :first-child {
      text-decoration: underline;
    }

    &.unread::before {
      content: "";
      position: absolute;
      top: 0.7em;
      left: -1.3em;
      width: 0.4em;
      height: 0.4em;
      border-radius: 50%;
      background-color: #ff3867;
    }
  `,
  Title: styled.p`
    ${StyledUtil.lineClamp}
    font-size: 1.6em;
    font-weight: 400;
  `,
  Date: styled.p`
    font-size: 1.4em;
    color: #8b8f99;
  `,
};

export default StyledNotification;

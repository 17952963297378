import { useSelector } from 'react-redux';
import { UserStatus } from 'common/constant';

/**
 * @returns {boolean} 로그인 여부
 */
export default function useLoginStatus() {
  const status = useSelector((state) => state.user.status);
  return status === UserStatus.Login;
}

import React, { useContext } from 'react';
import Styled from './index.style';
import { PlayerContext } from 'component/Player';
import Duration from 'common/util/Duration';

const Timer = (props) => {
  const { playerState } = useContext(PlayerContext);
  const { played, duration } = playerState;

  return (
    <Styled.TimerContainer {...props}>
      <Duration seconds={played * duration} /> / <Duration seconds={duration} />
    </Styled.TimerContainer>
  );
};

export default Timer;

import videoClassStoreType from './type';

export const addItem = (item) => ({
  type: videoClassStoreType.Add_Video,
  payload: item,
});

export const deleteItem = (item) => ({
  type: videoClassStoreType.Delete_Video,
  payload: item,
});

export const addCoupon = (coupon) => ({
  type: videoClassStoreType.Add_Coupon,
  payload: coupon,
});

export const deleteAllItem = () => ({ type: videoClassStoreType.Delete_All });

export const addApplication = (item) => ({
  type: videoClassStoreType.Add_Application,
  payload: item,
});

export const deleteApplication = () => ({type: videoClassStoreType.Delete_Application,});
import React, { useState, useEffect } from 'react';
import Styled from 'container/UserHomePage/styled/TodayLesson.style';
import { Link } from 'react-router-dom';
import play from 'assets/images/mycurriculum_new/icon_play2.png';
import more from 'assets/images/mycurriculum_new/arrow2.png';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import curriculumsApi from 'api/curriculums';
import productApi from 'api/product';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
export default function TodayLesson() {

  const token = useSelector((state) => state.user.token);
  const [{ data: curriculumData }] = useAxios(
    () => curriculumsApi.getMyList(token)
  );

  const [{ data: productData }, fetchProductData] = useAxios(
    productApi.getCourseOfProductDetail,
    [],
    true
  );

  const [currentCourseId, setCurremtCourseId] = useState();
  const [currentCourseName, setCurremtCourseName] = useState("");
  const [currentClass, setCurrentClass] = useState();

  const history = useHistory();

  const [isMobile, setisMobile] = useState(false);
  const resizingHandler = () => {
    if (window.innerWidth <= 767) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 767) {
      setisMobile(true);
    }
    window.addEventListener("resize", resizingHandler);
    return () => {
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);


  useEffect(() => {
    if (curriculumData && curriculumData.id && curriculumData.curriculumCourseResponses) {
      for (let i = 0; i < curriculumData.curriculumCourseResponses.length; i++) {
        const course = curriculumData.curriculumCourseResponses[i];
        console.error("test : " + i);
        if (course.progressPercent < 100) {
          setCurremtCourseId(course.id);
          setCurremtCourseName(course.title);
          fetchProductData(course.id, token);
          break;
        }
      }
    }
  }, [curriculumData]);

  useEffect(() => {
    if (productData) {
      for (let i = 0; i < productData.length; i++) {
        let isLoopEnd = false;
        const chapter = productData[i];
        for (let j = 0; j < chapter.lessonResponseList.length; j++) {
          const lesson = chapter.lessonResponseList[j];
          if (lesson.completed === false) {
            setCurrentClass(lesson);
            isLoopEnd = true;
            break;
          }
        }
        if(isLoopEnd) break;
      }
    }
  }, [productData])

  return (
    <>
      {currentClass &&
        <Styled.Wrapper>
          <Styled.Inner>
            <Styled.Head>
              <Styled.H3>My Today’s Lesson</Styled.H3>

              <Styled.MoreBtn onClick={()=>{
                history.push('/classroom');
              }}>
                MORE
                {isMobile ? <img src={more} />
                  :
                  <svg
                    width="10px" height="10px">
                    <path fill-rule="evenodd" fill="#8b8f99"
                      d="M9.1000,5.1000 L5.1000,5.1000 L5.1000,9.1000 L3.1000,9.1000 L3.1000,5.1000 L-0.000,5.1000 L-0.000,3.1000 L3.1000,3.1000 L3.1000,-0.000 L5.1000,-0.000 L5.1000,3.1000 L9.1000,3.1000 L9.1000,5.1000 Z" />
                  </svg>
                }

              </Styled.MoreBtn>
            </Styled.Head>
            <Link to={`/courses/detail/${currentCourseId}/${currentClass.chapterLectureId}`}>
            <Styled.LessonWrap>
              <Styled.Icon>
                <img src={play} alt="PLAY" />
              </Styled.Icon>
              <Styled.TextBox>
                <Styled.Title>{currentClass.title}</Styled.Title>
                <Styled.Slg>{currentCourseName} </Styled.Slg>
              </Styled.TextBox>
              <Styled.Next><Link to={`/courses/detail/${currentCourseId}/${currentClass.chapterLectureId}`}>Continue</Link></Styled.Next>
            </Styled.LessonWrap>
            </Link>
          </Styled.Inner>
        </Styled.Wrapper>
      }

    </>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import Styled from 'container/UserBookmarkPage/styled/CreateTheme.style';
import StyledModal from 'component/Modal/styled/Modal.style';
import StyledForm from 'component/form/ModalForm.style';
import { useSelector } from 'react-redux';
import { ModalContext } from 'component/Modal/modalContext';
import ToggleSwitch from 'component/ui/ToggleSwitch';
import { useAxios } from 'common/hook/useAxiosHook';
import ThemeCategorySelect from 'container/UserBookmarkPage/Theme/CreateTheme/ThemeCategorySelect';
import themesApi from 'api/theme';
import { useHistory } from 'react-router-dom';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import { useTranslation, Trans } from 'react-i18next';

export default function CreateThemeModalDepth2({ checkedList }) {
  const token = useSelector((state) => state.user.token);

  const { t } = useTranslation('createBookmarkThemeModal');

  let { handleModal } = React.useContext(ModalContext);

  const [selectedOption, setSelectedOption] = useState('Select');
  const [themeName, setThemeName] = useState('');
  const [description, setDescription] = useState('');
  const [isHidden, setIsHidden] = useState(false);

  const [postTheme, fetchPostTheme] = useAxios(themesApi.postTheme, [], true);
  const [postThemeItems, fetchPostThemeItems] = useAxios(
    themesApi.postThemeItems,
    [],
    true
  );
  const [deleteTheme, fetchDeleteTheme] = useAxios(
    themesApi.deleteTheme,
    [],
    true
  );
  const {
    loading: postLoading,
    data: postResponse,
    error: postError,
  } = postTheme;
  const {
    loading: postItemsLoading,
    data: postItemsResponse,
    error: postItemError,
  } = postThemeItems;

  const onCreate = useCallback(
    (e) => {
      e.preventDefault();
      fetchPostTheme({
        categoryId: selectedOption.value,
        name: themeName,
        description,
        isOpen: !isHidden,
        token,
      });
    },
    [selectedOption, themeName, description, isHidden, token]
  );

  useEffect(() => {
    postResponse?.status === 201 &&
      fetchPostThemeItems({
        id: postResponse.data,
        productIds: checkedList,
        token,
      });
  }, [postResponse]);

  const history = useHistory();
  useEffect(() => {
    if (postItemsResponse?.status === 201) {
      history.push(`/theme/${postResponse.data}`);
      handleModal();
    }
    if (postItemError) {
      fetchDeleteTheme({ id: postResponse.data, token });
    }
  }, [postThemeItems]);

  return (
    <>
      <StyledModal.H2>{t('title')}</StyledModal.H2>
      <Styled.InfoText>
        <Trans t={t}>desc</Trans>
      </Styled.InfoText>

      <StyledForm.FormWrapper>
        <StyledForm.InputContainer>
          <StyledForm.Label htmlFor='themeName'>{t('createTheme.nameSection')}</StyledForm.Label>
          <StyledForm.Input
            type='text'
            id='themeName'
            placeholder='A famous tourist destination in korea'
            required
            onChange={(e) => setThemeName(e.target.value)}
          />
        </StyledForm.InputContainer>

        <ThemeCategorySelect
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          title={t('createTheme.categorySection.title')}
        />

        <StyledForm.InputContainer>
          <StyledForm.Label htmlFor='themeDesc'>
            {t('createTheme.descSection')}
          </StyledForm.Label>
          <StyledForm.Textarea
            id='themeDesc'
            placeholder='This is a helpful lecture when traveling in Korea.'
            onChange={(e) => setDescription(e.target.value)}
          />
        </StyledForm.InputContainer>

        <StyledForm.InputContainer>
          <StyledForm.Label htmlFor='keepSecret'>
            {t('createTheme.text')}
          </StyledForm.Label>
          <ToggleSwitch
            id='keepSecret'
            onChange={(e) => setIsHidden(e.target.checked)}
          />
        </StyledForm.InputContainer>

        <Styled.ButtonGroup>
          <Styled.Button.Default onClick={() => handleModal()}>
            {t('createTheme.button1')}
          </Styled.Button.Default>
          <Styled.Button.Primary onClick={onCreate}>
            {t('createTheme.button2')}
          </Styled.Button.Primary>

          {postError && (
            <StyledForm.WarnText>{postError.data.message}</StyledForm.WarnText>
          )}

          {postItemError && (
            <StyledForm.WarnText>
              {postItemError.data.message}
            </StyledForm.WarnText>
          )}
        </Styled.ButtonGroup>

        <LoadingOverlay isLoading={postLoading || postItemsLoading} />
      </StyledForm.FormWrapper>
    </>
  );
}

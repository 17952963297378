import React, { useState, useEffect } from "react";
import Style from "./style/Overall.style";
import styled from 'styled-components';
import StackBarChart from "container/MockTestReportPage/components/OverallStackBarChart";
import Table from "./components/Table";
import Content from "./components/Content";
import Grade from "./components/Grade";
import RecommendCurriculum from "container/MockTestReportPage/components/RecommendCurriculum";
import RecommendCourse from "container/MockTestReportPage/components/RecommendCourse";
import { useAxios } from "common/hook/useAxiosHook";
import { useSelector } from "react-redux";
import mockTestReportApi from "api/mockTestReport";
import { EmptyList } from "component/atoms/EmptyList";
import Loading from "component/atoms/Loading";
import { useTranslation } from 'react-i18next';
import OrderBySelect from "component/molecules/OrderBySelect";

const Styled = {
  SelectContainer: styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    .select__control{
      background-color: transparent;
    }
    @media (max-width: 767px) {
      margin-top: 2em;
    }
  `,
};

const Overall = () => {
  const { t } = useTranslation('mockTestReportPage');
  const [topikType, setTopikType] = useState(11);
  const [averageGrade11, setAverageGrade11] = useState(' - ');
  const [averageGrade12, setAverageGrade12] = useState(' - ');
  const [testDate11, setTestDate11] = useState(' - ');
  const [testDate12, setTestDate12] = useState(' - ');
  const [totalScore11, setTotalScore11] = useState(0);
  const [totalScore12, setTotalScore12] = useState(0);
  const [gradeReports11, setGradeReports11] = useState([]);
  const [gradeReports12, setGradeReports12] = useState([]);
  // const [gradeReportsInfo11, setGradeReportsInfo11] = useState({
  //   averageGrade: 0,
  //   testDate: ' - ',
  //   totalScore: 0,
  //   reportList: []
  // });
  // const [gradeReportsInfo12, setGradeReportsInfo12] = useState({
  //   averageGrade: 0,
  //   testDate: ' - ',
  //   totalScore: 0,
  //   reportList: []
  // });
  const token = useSelector((state) => state.user.token);
  const userName = useSelector((state) => state.user.data.fullName);

  const [{ data, loading, error }] = useAxios(() => mockTestReportApi.getOverallDataType(token), []);

  // 모의고사 11
  useEffect(() => {
    const last = gradeReports11.length - 1;
    if (gradeReports11.length === 1) {
      setTotalScore11(gradeReports11[0].totalScore - gradeReports11[0].writingScore);
      setTestDate11(`${gradeReports11[0].testDate.substr(0, 10)}`);
    } else if (gradeReports11.length > 1) {
      setTotalScore11(gradeReports11[last].totalScore - gradeReports11[last].writingScore);
      setTestDate11(`${gradeReports11[0].testDate.substr(0, 10)} ~ ${gradeReports11[last].testDate.substr(0, 10)}`);
    } else if (gradeReports11.length === 0) {
      setTestDate11(' - ');
      setTotalScore11(' - ');
      setAverageGrade11(' - ');
    }
    // console.log('gradeReports11', gradeReports11);
  }, [gradeReports11]);

  // 모의고사 12
  useEffect(() => {
    const last = gradeReports12.length - 1;
    if (gradeReports12.length === 1) {
      setTotalScore12(gradeReports12[0].totalScore);
      setTestDate12(`${gradeReports12[0].testDate.substr(0, 10)}`);
    } else if (gradeReports12.length > 1) {
      setTotalScore12(gradeReports12[last].totalScore);
      setTestDate12(`${gradeReports12[0].testDate.substr(0, 10)} ~ ${gradeReports12[last].testDate.substr(0, 10)}`);
    } else if (gradeReports12.length === 0) {
      setTestDate12(' - ');
      setTotalScore12(' - ');
      setAverageGrade12(' - ');
    }
    // console.log('gradeReports12', gradeReports12);
  }, [gradeReports12]);


  useEffect(() => {
    if (data) {
      let list1 = [];
      let list2 = [];
      data.gradeReports.map((item) => {
        if (item.levelType === 11) {
          list1.push(item);
        } else if (item.levelType === 12) {
          list2.push(item);
        }
      });
      // console.log('list1', list1, list2);
      setAverageGrade11(data.averageGrade);
      setAverageGrade12(data.averageGrade);
      setGradeReports11(list1);
      setGradeReports12(list2);
    }
  }, [data]);
  if (loading) return <Loading />;

  const dataOptions = [
    { label: t('selectTopic.option1'), value: 11 },
    { label: t('selectTopic.option2'), value: 12 }
  ];
  const selectBoxChange = (e) => {
    setTopikType(e.value);
  };

  if (data)
    return (
      <>
        <Style.H3>{userName}님의 TOPIK 종합 성적 리포트</Style.H3>
        <Style.FlexRow>
          <Styled.SelectContainer>
            <OrderBySelect
              onChange={selectBoxChange}
              optionList={dataOptions}
              width='14.5em'
              className='order-by-select'
              placeholder={t('selectTopic.option1')}
            />
          </Styled.SelectContainer>
          <StackBarChart topikType={topikType} />
          <Table gradeReports={topikType === 11 ? gradeReports11 : gradeReports12} levelType={topikType} />
        </Style.FlexRow>
        <Style.FlexRow>
          <Content
            data={{
              grade: topikType === 11 ? averageGrade11 : averageGrade12,
              testCount: topikType === 11 ? gradeReports11.length : gradeReports12.length,
              date: topikType === 11 ? testDate11 : testDate12,
            }}
          />
          <Grade totalScore={topikType === 11 ? totalScore11 : totalScore12} levelType={topikType} />
        </Style.FlexRow>
        <RecommendCurriculum data={topikType === 11 ? data.curriculumList : data.curriculumResponses} />
        <RecommendCourse data={topikType === 11 ? data.courseList : data.courseResponses} />
      </>
    );
  return <EmptyList />;
};

export default Overall;

import React from 'react';
import Style from './styled/Process.style';
import { ModalContext } from 'component/Modal/modalContext';
import AddressModal from './AddressModal';

const AddressContainer = ({ setFormState, t }) => {
  const { handleModal } = React.useContext(ModalContext);

  const openModal = () => {
    handleModal(<AddressModal setFormState={setFormState} t={t} />);
  };

  return (
    <div>
      <Style.AddressButton onClick={(e) => openModal()}>
        {t('paymentInfo.addressForm.button')}
      </Style.AddressButton>
    </div>
  );
};

export default AddressContainer;

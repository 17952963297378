import styled, { css } from 'styled-components';
import icoBack from 'assets/images/arrow2_left_white.svg';
import icoMore from 'assets/images/arrow_right_yellow.svg';

const Styled = {
  PageTitle: styled.div`
    position: relative;
    padding-top: 7em;
  `,

  TeacherName: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.1;
    color: #fff;
    padding-bottom: 2em;
    border-bottom: 1px solid #2b2e35;
    .label {
      margin-right: 1em;
      padding: 0.5em 0.8em;
      background: #ff0069;
      border-radius: 0.2em;
      text-transform: uppercase;
      font-size: 1.4em;
    }
    .name {
      position: relative;
      font-weight: 700;
      font-size: 3.6em;
      & + .name {
        margin-left: 0.5em;
        padding-left: 0.5em;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0.1em;
          left: 0;
          width: 2px;
          height: 1em;
          background: #5d6165;
        }
      }
    }
    @media (max-width: 1024px) {
      display: none;
    }
  `,

  BtnBack: styled.button`
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    padding: 0.6em 0.6em 0.6em 2em;
    background: url(${icoBack}) no-repeat 0 50%;
    background-size: auto 1em;
    font-weight: 700;
    font-size: 1.4em;
    color: #fff;
    @media (max-width: 1024px) {
      font-size: 1.6em;
    }
  `,

  Section: styled.div`
    position: relative;
    padding-top: 2em;
    margin-bottom: 7em;
    color: #c9cbd1;
    &:last-child {
      margin-bottom: 12em;
    }
  `,

  SectonBox: styled.div`
    padding: 6em;
    border-radius: 1em;
    background: #1e2024;
    color: #8b8f99;
    .stitle {
      margin : 1.6em 0 0.8em;
      font-weight: 700;
      font-size: 2.2em;
      color: #fff;
      &:first-of-type {
        margin-top: 0;
      }
    }
    .txt {
      font-size: 1.6em;
      &.box {
        padding: 1.2em 1.5em;
        border-radius: 0.5em;
        background: #272a30;
      }
      &.pre{
        word-break: keep-all;
        white-space: pre-wrap;
      }
      a {
        text-decoration: underline;
      }
      strong {
        font-weight: 700;
      }
      .bl {
        display: block;
        font-weight: 700;
        color: #d1d1d1;
        &::before {
          content: '';
          display: inline-block;
          margin-top: 0.6em;
          margin-right: 0.6em;
          width: 0.4em;
          height: 0.4em;
          border-radius: 1em;
          background: #7c7e81;
          vertical-align: top;
        }
      }
    }
    .video {
      float: right;
      width: 50%;
      border-radius: 1em;
      overflow: hidden;
    }
    @media (max-width: 1024px) {
      padding: 2.8em 20px;
      font-size: 1.2em;
      .txt {
        &.box {
          padding: 0;
          background: none;
        }
      }
      .video {
        float: none;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  `,

  ViewBox: styled.div`
    position: relative;
    &.w50 {
      width: calc(50% - 4em);
    }
    @media (max-width: 1024px) {
      &.w50 {
        width: 100%;
      }
    }
  `,
  ViewArea: styled.div`
    max-height: 33em;
    padding-bottom: 3em;
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    border-bottom: 1px solid #5d6165;
    &.active {
      max-height: 100vh;
      border-bottom: 0px;
    }
  `,
  BtnView: styled.button `
    margin-top: 1em;
    padding-right: 1.5em;
    background: url(${icoMore}) no-repeat 100% 100%;
    background-size: auto 100%;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.6em;
    color: #ffbb00;
    &.hide{
      display: none;
    }
  `,

  InfoBox: styled.div`
    overflow: hidden;
  `,
  Info: styled.div`
    position: relative;
    .name {
      margin-bottom: 0.6em;
      font-weight: 700;
      font-size: 3em;
      color: #ffffff;
    }
    dl {
      display:flex;
      margin-bottom: 0.6em;
      dt {
        min-width: 6em;
        height: 2em;
        padding: 0.2em 0.6em;
        margin-right: 0.7em;
        border-radius: 0.28em;
        background: #393c40;
        letter-spacing: -0.02em;
        text-align: center;
        font-size: 1.5em;
        color: #8e9199;
      }
      dd {
        font-weight: 700;
        font-size: 1.8em;
        color: #c9cbd1;
      }
    }
    .txt {
      margin-top: 2em;
    }
  `,

  ProductWrap: styled.div`
    .txt.box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 3em;
      font-weight: 700;
      font-size: 1.8em;
      color: #fff;
      > div {
        display: flex;
        min-height: 1.6em;
        margin-right: 1em;
        &.num {
          strong {
            display: inline-block;
            width: 1.6em;
            height: 1.6em;
            margin-right: 0.3em;
            border-radius: 50%;
            background: #0f1012;
            text-align: center;
          }
        }
        &.email {
          font-weight: 300;
          font-size: 0.88em;
          color: #8b8f99;
          text-decoration: underline;
        }
      }
      span {color: #ffbb00;}
    }
    @media (max-width: 1024px) {
      .txt.box {
        position: relative;
        display: block;
        margin-bottom: 1.5em;
        > div {
          margin-bottom: 0.5em;
          &:last-child {
            margin-bottom: 0;
          }
        }
        .first {
          position: absolute;
        }
        .num {
          padding-left: 3em;
        }
        .email {
          padding-left: 5em;
        }
      }
    }
  `,
  ProductBox: styled.div`
    padding: 2em 6em;
    border-radius: 0.5em;
    border: 2px solid #485cc7;
    cursor: pointer;
    & + & {
      margin-top: 3em;
    }
    &:hover {
      background: #485cc7;
    }
  `,

  ApplyWrap: styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 98;
    background: #25282e;
    
    @media (min-width: 1025px) {
      &.zIndex{
        z-index: 1000000;
      }
    }
  `,
  ApplyInner: styled.div`
    max-width: calc(1300px + 20px * 2);
    margin: auto;
    padding: 1em 20px;
    overflow: hidden;
    & *{
      float: left;
    }
    .msg {
      line-height: 3em;
      font-size: 1.8em;
      color: #c9cbd1;
    }
    button {
      float: right;
    }
  `,
  BtnApply: styled.button`
    padding: 1em 1.5em;
    background: #485cc7;
    border-radius: 0.25em;
    font-weight: 700;
    font-size: 2em;
    color: #fff;
  `,
};

export default Styled;

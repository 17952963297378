import React from 'react';
import {useLocation, useRouteMatch} from 'react-router-dom';
import Styled from 'container/UserBookmarkPage/styled/UserBookmark.style';
import StyledTab from 'component/TabMenu/index.style';
import PageHeader from 'component/atoms/PageHeader';
import UserBookmarkRoutes from 'container/UserBookmarkPage/Routes';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';
import {MainMultiEventBannerWithAPI} from "../../component/EventBanner";
// eslint-disable-next-line react-hooks/rules-of-hooks

export default function UserBookmarkPage() {
  const { t } = useTranslation('bookmarkPage');
  const { url } = useRouteMatch();
  const location = useLocation();

  return (
    <>
      {/*현대사이트솔루션 이벤트 진행을 위해 임시 노출*/}
      {location.pathname === '/bookmark/theme' ? (
          <>
            <MainMultiEventBannerWithAPI />
          </>
      ) : (
          <></>
      )}
      <Styled.Wrapper className='subWrapper'>
        <SeoHelmet
          title={SeoData.UserBookmark.title}
          desc={SeoData.UserBookmark.desc}
        />
        <PageHeader title={t('title')} text={t('subtitle')} />

        <StyledTab.Container>
          <StyledTab.NavLink exact to={url}>
            Bookmark
          </StyledTab.NavLink>
          <StyledTab.NavLink to={`${url}/theme`}>Theme</StyledTab.NavLink>
        </StyledTab.Container>
      </Styled.Wrapper>
      <UserBookmarkRoutes />
    </>
  );
}

import React, { useState, useEffect } from 'react';
import PageSubTitle from 'component/molecules/PageSubTitle';
import { SwiperSlide } from 'swiper/react';

import VideoItem from 'component/molecules/VideoItem';
import SwiperList from 'component/molecules/SwiperList';
import { EmptyList } from 'component/atoms/EmptyList';
import SearchEmpty from 'component/atoms/SearchEmpty';
import { useAxios } from 'common/hook/useAxiosHook';
import coursesApi from 'api/courses';
import { Trans, useTranslation } from 'react-i18next';
import Loading from 'component/atoms/Loading';

const SearchCourseContainer = ({ searchCourseWords, select, token }) => {
  const { t } = useTranslation('libraryPage');

  const [slideItems, setSlideItems] = useState([]);
  const [getCourseList, refreshGetCourseList] = useAxios(
    coursesApi.searchCourseList,
    [],
    true
  );
  const { data, loading } = getCourseList;

  useEffect(() => {
    if (select !== 'Themes') {
      if (token) {
        refreshGetCourseList(0, searchCourseWords, token);
      } else {
        refreshGetCourseList(0, searchCourseWords);
      }
    }
  }, [searchCourseWords, select, token]);

  useEffect(() => {
    if (data && data.first) {
      setSlideItems([...data.content]);
    } else if (data && !data.first) {
      setSlideItems([...slideItems, ...data.content]);
    }
  }, [data]);

  const onNext = () => {
    if (!data.last) {
      if (!token) {
        refreshGetCourseList(data.number + 1, searchCourseWords);
      } else {
        refreshGetCourseList(data.number + 1, searchCourseWords, token);
      }
    }
  };
  if (loading) return <Loading />;
  return (
    <div>
      {slideItems.length === 0 && <SearchEmpty type='search' />}
      {slideItems.length > 0 && (
        <>
          <PageSubTitle title={t('contentSection.section1.title')} />
          <SwiperList
            id='library-course'
            slideItemList={slideItems}
            onNext={onNext}
          >
            {slideItems.map((item) => (
              <SwiperSlide key={item.productId}>
                <VideoItem
                  title={item.title}
                  imgSrc={item.thumbnailPath}
                  percent={item.progressPercent}
                  to={`/courses/detail/${item.productId}`}
                  videoLink={
                    !token
                      ? `/courses/detail/${item.productId}`
                      : `/courses/detail/${item.productId}/${item.chapterLecId}`
                  }
                  desc={
                    !token
                      ? `${item.classCount}classes, ${item.hour} hours`
                      : item.chapterTitle
                  }
                />
              </SwiperSlide>
            ))}
          </SwiperList>
        </>
      )}
    </div>
  );
};

export default React.memo(SearchCourseContainer);

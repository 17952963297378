import React, { useState, useEffect, useMemo } from 'react';
import Style from './styled/index.style';
import {
  findGender,
  DateOptions,
  formatSingleInteger,
  profileInfo,
  validation,
} from './Service';

import makeAnimated from 'react-select/animated';
import { fetchNationality, postProfileInfo } from './api/profileApi';
import { useAxios } from 'common/hook/useAxiosHook';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'component/user/state';
import useErrorToastify from 'common/hook/useErrorToastify';
import { toast } from 'react-toastify';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';
import Header from 'container/ProfilePage/Header';
import { addMemberGoal, confirmMemberGoal } from 'api/memberGoal';

const animatedComponents = makeAnimated();

const Profile = () => {
  const { t } = useTranslation('profilePage');
  const userInfoData = useSelector((state) => state.user.data);
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const [{ data: countryData }] = useAxios(fetchNationality, []);

  const [obView, setObView] = useState(false);
  const [currentNationality, setCurrentNationality] = useState(null); //국적
  const [currentPhoneNationality, setCurrentPhoneNationality] = useState(null); //핸드폰 국가정보
  const [phoneNationId, setPhoneNationId] = useState(null);

  const [updateProfileInfo, refreshUpdateProfileInfo] = useAxios(
    postProfileInfo,
    [],
    true
  );

  const [{ data: memberGoal }, getMemberGoal] = useAxios(
    confirmMemberGoal,
    [],
    true
  ); // step1 온보딩 정보 겟
  const [selAddMemberGoal, updateSelAddMemberGoal] = useAxios(
    addMemberGoal,
    [],
    true
  ); // step1 저장 axios

  useEffect(() => {
    if (
      updateProfileInfo.data?.status === 200 ||
      selAddMemberGoal.data?.result === 1
    ) {
      toast.success('profile has been updated');
    }
  }, [updateProfileInfo, selAddMemberGoal]);

  useErrorToastify(updateProfileInfo.error);

  const [userInfo, setUserInfo] = useState({
    userFirstName: '',
    userLastName: '',
    userFullName: '',
    nickname: '',
    photoURL: '',
    birthday: '',
    gender: '',
    emailAcceptation: null,
    smsAcceptation: null,
    countryIndex: '',
    koreaHP: '', //휴대폰번호
    nationNumber: 0, //휴대폰 국가번호 (유니크x)(국제 전화 코드, ex. 한국 +82)
    nationCode: '', //휴대폰 국가코드 (유니크o)(국제 국가코드, ex. 한국 KO)
  });

  const date = new Date();
  const currentYear = date.getFullYear();

  useEffect(() => {
    // console.log('memberGoal', memberGoal);
    if (!memberGoal) return;
    if (!memberGoal.memberGoal.goalNum) return;
    const data = memberGoal.memberGoal;
    if (data.goalNum !== null && data.level !== null) setObView(true);
    const query = 'input[name=obStep1]';
    const selected = document.querySelectorAll(query);
    const goalNum = data.goalNum.split(',');

    for (const goal of goalNum) {
      if (goal !== 0) {
        selected[goal - 1]?.setAttribute('checked', true);
      }
    }
  }, [memberGoal]);

  useEffect(() => {
    dispatch(actions.fetchUser(token));
    return () => dispatch(actions.fetchUser(token));
  }, [token]);

  useEffect(() => {
    if (userInfoData) {
      let info = profileInfo(userInfoData);
      setUserInfo(info);
    }

    getMemberGoal(token, user.userIdx);
  }, [userInfoData]);

  useEffect(() => {
    if (countryData && userInfoData) {
      const findData = countryData.find(
        (data) =>
          data.countryNumber === userInfoData.nationNumber &&
          data.countryCode === userInfoData.nationCode
      );
      if (findData) {
        setPhoneNationId(findData.id);
      }
    }
  }, [countryData, userInfoData]);

  const countryList = useMemo(() => {
    let countryOptions;
    let userCountry;
    if (countryData) {
      countryOptions = countryData.map((data) => {
        return {
          value: data.id,
          label: data.countryNameENG,
          name: 'countryIndex',
        };
      });
      if (userInfoData) {
        userCountry = countryOptions.find(
          (data) => data.value === userInfoData.tblNationalityIdx
        );

        setCurrentNationality(userCountry);
      }
    }

    return countryOptions;
  }, [countryData, userInfoData]);

  const phoneCountryList = useMemo(() => {
    let countryOptions;
    let userCountry;
    if (countryData) {
      countryOptions = countryData.map((data) => {
        return {
          value: data.id,
          label: `${data.countryNameENG} [${data.countryNumber}]`,
          name: 'phoneNationId',
          nationNumber: data.countryNumber,
          nationCode: data.countryCode,
        };
      });
      if (userInfoData) {
        userCountry = countryOptions.find(
          (data) =>
            data.nationNumber === userInfoData.nationNumber &&
            data.nationCode === userInfoData.nationCode
        );

        setCurrentPhoneNationality(userCountry);
      }
    }

    return countryOptions;
  }, [countryData, userInfoData]);

  const handleChange = (event) => {
    const { name, value } = event.target || event;
    if (name === 'phoneNationId') {
      setPhoneNationId(value);
    } else setUserInfo({ ...userInfo, [name]: value });
  };

  const changeProfileInfo = async (e) => {
    e.preventDefault();

    const valueArr = [];
    const query = 'input[name=obStep1]:checked';
    const selected = document.querySelectorAll(query);

    selected.forEach((sel) => {
      valueArr.push(sel.value);
    });

    if (
      JSON.stringify(userInfo) === JSON.stringify(profileInfo(userInfoData))
    ) {
      if (valueArr.toString() !== memberGoal.memberGoal.goalNum) {
        updateSelAddMemberGoal(token, user.userIdx, valueArr);
        return;
      }
      toast.warn('Nothing has changed.');
      return;
    }

    const formValidation = validation(userInfo);
    if (formValidation) {
      toast.warn(formValidation);
      return;
    }

    let date = 'yyyy-mm-dd';

    if (
      userInfo.birthdayYear &&
      userInfo.birthdayMonth &&
      userInfo.birthdayDay
    ) {
      date = date.replace('yyyy', userInfo.birthdayYear);
      date = date.replace('mm', formatSingleInteger(userInfo.birthdayMonth));
      date = date.replace('dd', formatSingleInteger(userInfo.birthdayDay));
    } else {
      date = null;
    }
    if (date) {
      userInfo['birthday'] = date;
    }

    if (phoneNationId && !userInfo.koreaHP) {
      toast.warn('please enter your phone number');
      return;
    } else if (!phoneNationId && userInfo.koreaHP) {
      toast.warn('please select your phone country code');
      return;
    } else if (phoneNationId && userInfo.koreaHP) {
      if (countryData) {
        const findData = countryData.find((data) => data.id === phoneNationId);
        userInfo.nationNumber = findData?.countryNumber
          ? findData?.countryNumber
          : 0;
        userInfo.nationCode = findData?.countryCode
          ? findData?.countryCode
          : '';
      }
    }
    refreshUpdateProfileInfo(userInfo, token);
  };

  return (
    <>
      <SeoHelmet title={SeoData.profile.title} desc={SeoData.profile.desc} />
      <Header />
      <Style.FormContainer autoComplete='off' onSubmit={changeProfileInfo}>
        <Style.NameContainer>
          <Style.Label htmlFor='name'>{t('setInfo.name')}</Style.Label>
          <Style.Input
            type='text'
            id='name'
            name='userFullName'
            value={userInfo ? userInfo.userFullName : ''}
            onChange={handleChange}
            placeholder={t('setInfo.placeholder1')}
          />
        </Style.NameContainer>
        <Style.InputContainer>
          <Style.Label htmlFor='nationality'>{t('setInfo.nation')}</Style.Label>
          <Style.Select
            onChange={handleChange}
            options={countryList && countryList}
            components={animatedComponents}
            inputId='nationality'
            placeholder={
              currentNationality ? currentNationality.label : 'select'
            }
            required
          />
        </Style.InputContainer>
        <Style.InputContainer>
          <Style.Label htmlFor='nickname'>{t('setInfo.nick')}</Style.Label>
          <Style.Input
            type='text'
            id='nickname'
            name='nickname'
            value={userInfo?.nickname ? userInfo.nickname : ''}
            onChange={handleChange}
          />
        </Style.InputContainer>
        <Style.GenderContainer>
          <Style.Label htmlFor='gender'>
            {t('setInfo.genderSelector.title')}
          </Style.Label>
          <Style.Select
            onChange={handleChange}
            options={[
              {
                value: 'M',
                label: t('setInfo.genderSelector.option1'),
                name: 'gender',
              },
              {
                value: 'F',
                label: t('setInfo.genderSelector.option2'),
                name: 'gender',
              },
              {
                value: 'O',
                label: t('setInfo.genderSelector.option3'),
                name: 'gender',
              },
            ]}
            components={animatedComponents}
            inputId='gender'
            placeholder={
              userInfo?.gender ? findGender(userInfo.gender) : 'Select'
            }
          />
        </Style.GenderContainer>
        <Style.DateContainer>
          <Style.Label htmlFor='dob'>
            {t('setInfo.birthSelector.title')}
          </Style.Label>
          <Style.Select
            onChange={handleChange}
            options={
              DateOptions &&
              DateOptions(currentYear, 1930, 'birthdayYear', true)
            }
            components={animatedComponents}
            inputId='dob'
            placeholder={
              userInfo?.birthdayYear && parseInt(userInfo.birthdayYear)
            }
            required
          />
          <Style.Select
            // defaultValue={'Select'}
            onChange={handleChange}
            options={DateOptions && DateOptions(1, 13, 'birthdayMonth')}
            components={animatedComponents}
            inputId='dob'
            placeholder={
              userInfo?.birthdayMonth && parseInt(userInfo.birthdayMonth)
            }
            required
          />
          <Style.Select
            onChange={handleChange}
            options={DateOptions && DateOptions(1, 32, 'birthdayDay')}
            components={animatedComponents}
            inputId='dob'
            placeholder={
              userInfo?.birthdayDay && parseInt(userInfo.birthdayDay)
            }
            required
          />
        </Style.DateContainer>
        <Style.InputContainer>
          <Style.Label htmlFor='phoneNumber'>
            {t('setInfo.phoneSelector.title')}
          </Style.Label>
          <Style.Select
            onChange={handleChange}
            options={phoneCountryList && phoneCountryList}
            components={animatedComponents}
            inputId='phoneNationId'
            placeholder={
              currentPhoneNationality
                ? currentPhoneNationality.label
                : t('setInfo.phoneSelector.nationSelect')
            }
          />
          <Style.PhoneInput
            type='tel'
            id='phoneNumber'
            name='koreaHP'
            maxLength='20'
            value={userInfo?.koreaHP ? userInfo.koreaHP : ''}
            placeholder={t('setInfo.phoneSelector.placeholder')}
            onChange={handleChange}
          />
        </Style.InputContainer>
        {obView ? (
          <Style.CheckContainer>
            <Style.GoalTitle>{t('setInfo.goalCheck.title')}</Style.GoalTitle>
            <Style.InputWrap>
              <Style.InputCheck id='goal1' name='obStep1' value='1' />
              <Style.InputLabel htmlFor='goal1'>
                {t('setInfo.goalCheck.check')}
              </Style.InputLabel>
            </Style.InputWrap>
            <Style.InputWrap>
              <Style.InputCheck id='goal2' name='obStep1' value='2' />
              <Style.InputLabel htmlFor='goal2'>
                {t('setInfo.goalCheck.check2')}
              </Style.InputLabel>
            </Style.InputWrap>
            <Style.InputWrap>
              <Style.InputCheck id='goal3' name='obStep1' value='3' />
              <Style.InputLabel htmlFor='goal3'>
                {t('setInfo.goalCheck.check3')}
              </Style.InputLabel>
            </Style.InputWrap>
            <Style.InputWrap>
              <Style.InputCheck id='goal4' name='obStep1' value='4' />
              <Style.InputLabel htmlFor='goal4'>
                {t('setInfo.goalCheck.check4')}
              </Style.InputLabel>
            </Style.InputWrap>
            <Style.InputWrap>
              <Style.InputCheck id='goal5' name='obStep1' value='5' />
              <Style.InputLabel htmlFor='goal5'>
                {t('setInfo.goalCheck.check5')}
              </Style.InputLabel>
            </Style.InputWrap>
          </Style.CheckContainer>
        ) : undefined}

        <Style.Button type='submit'>{t('setInfo.button')}</Style.Button>
      </Style.FormContainer>
    </>
  );
};

export default Profile;

import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';

const StyledVideoTab = {
  //course banner
  TopBanner: styled.div`
    position: relative;
    border-radius: 0.6em;
    margin-bottom: 6em;
    overflow: hidden;
    @media (max-width: 1024px) {
      margin: 2em 0;
    }
    .swiper-pagination {
      bottom: 2em;
    }
    .swiper-pagination-bullet {
      width: 1.2em;
      height: 1.2em;
      border-radius: 50%;
      background: #000;
    }
    .swiper-pagination-bullet-active {
    }
  `,
  TabLink: styled(Link)`
    display: inline-block;
    padding: 0.75em 1.15em;
    margin-bottom: -2px;
    border-bottom: 2px transparent solid;
    color: #c9cbd1;
    font-size: 2em;
    font-weight: 700;
    cursor: pointer;
    &.active {
      color: #ffb500;
      border-bottom: 2px #ffb500 solid;
    }
    @media (max-width: 767px) {
      padding: 0.75em 0.55em;
    }
    @media (max-width: 375px) {
      font-size: 1.6em;
    }
  `,
  BannerLink: styled.a`
    position: relative;
    display: block;
    height: 160px;
    background-color: #999;
    overflow: hidden;
  `,
  BannerImg: styled.img`
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    max-width: 965px;
    height: 100%;
  `,
  BannerText: styled.div`
    position: absolute;
    top: 50%;
    left: 3em;
    transform: translateY(-50%);
    font-weight: 900;
    font-size: 2.4em;
    color: #000;
    @media (max-width: 1024px) {
      left: 0;
      width: 100%;
      text-align: center;
    }
  `,

  //course list
  Wrapper: styled.div`
    position: relative;
    margin-bottom: 9em;
    &.hidden {
      display: none;
    }

    --subListGap: 40px;
    --subListLine: 3;
    @media (max-width: 1280px) {
      --subListGap: 20px;
    }
    @media (max-width: 1025px) {
      --subListGap: 10px;
    }
    @media (max-width: 600px) {
      --subListGap: 10px;
      --subListLine: 2;
    }
    .sub-list-wrap {
      display: flex;
      flex-wrap: wrap;
      gap: 4em var(--subListGap);
    }
    .no-swiper-item {
      width: calc(
        (100% - var(--subListGap) * (var(--subListLine) - 1)) /
          var(--subListLine)
      );
      & > div {
        position: relative;
        .video-thumb {
          display: block;
          position: relative;
          width: 100%;
          margin-bottom: 1em;
          border-radius: 0.6rem;
          overflow: hidden;
        }
        .title {
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          font-size: 2em;
          font-weight: 500;
        }
        .sub {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 1.6em;
          color: #8e9199;
        }
      }
    }
    .swiperList {
      .slider-prev {
        left: -2.1rem !important;
      }
      .slider-next {
        right: -2.1rem !important;
      }
    }
  `,
  Desc: styled.div`
    margin-bottom: 2em;
    color: #c9cbd1;
    font-size: 1.6em;
    font-weight: 300;
    @media (max-width: 767px) {
      font-size: 1.7em;
    }
  `,
  ButtonAll: styled(Link)`
    position: absolute;
    top: 0.5em;
    right: 0;
    display: inline-block;
    border-bottom: 1px solid #8e9199;
    font-weight: 700;
    font-size: 1.6em;
    cursor: pointer;
    z-index: 1;
    color: #8e9199;
    @media (max-width: 1024px) {
      font-weight: 400;
    }
  `,

  //video tab
  ThemeWrap: styled.div`
    padding-top: 10em;
    background-color: #393c40;
    button {
      top: 12em !important;
      background-color: rgba(0, 0, 0, 0.5);
    }
  `,
  CourseWrap: styled.div`
    &::after {
      content: '';
      display: block;
      clear: both;
    }
  `,
  Course: styled.div`
    float: right;
    width: calc(100% - 280px - 5.5em);
    @media (max-width: 1024px) {
      float: none;
      width: 100%;
    }
  `,
  SelectContainer: styled.div.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <IoIosArrowDown />
      </>
    ),
  }))`
    display: none;
    @media (max-width: 1024px) {
      position: relative;
      display: block;
      background: #33363d;
      border-radius: var(--borderRadius);
      overflow: hidden;
      input {
        width: 100%;
        height: 100%;
        padding: var(--btnPadding);
        font-size: var(--btnSize);
        font-weight: 700;
        color: #fff;
        background-color: transparent;
        border: none;
      }
      svg {
        position: absolute;
        top: 50%;
        right: 1em;
        font-size: 2.4em;
        color: #838587;
        transform: translateY(-50%);
        transition: transform 0.2s ease;
      }
    }
  `,
  LnbWrap: styled.div`
    --btnPadding: 0.86em 1.66em;
    --btnSize: 1.8em;
    --borderRadius: 0.6rem;

    float: left;
    width: 280px;
    text-align: left;
    button {
      display: block;
      width: 100%;
      text-align: left;
      color: inherit;
      line-height: 1.3;
    }

    .lnb {
      position: relative;
      overflow: hidden;
      margin-bottom: 80px;
      border-radius: 0 0 var(--borderRadius) var(--borderRadius);
      li {
        background: #33363d;
        overflow: hidden;
        &.all {
          border-radius: var(--borderRadius);
          margin-bottom: 1px;
          & + li {
            border-radius: var(--borderRadius) var(--borderRadius) 0 0;
          }
        }
      }
    }
    .category {
      padding: var(--btnPadding);
      font-size: var(--btnSize);
      font-weight: 700;
      color: #fff;
      cursor: pointer;
      &.on {
        background-color: #ffb500;
      }
    }
    button.category {
      &:hover {
        background-color: #ffb500;
      }
    }
    .menu {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 4em;

      button,
      a {
        display: block;
        padding: var(--btnPadding);
        font-size: var(--btnSize);
        font-weight: 400;
        opacity: 0.6;
        &.on,
        &:hover {
          background: #2b2e35;
          opacity: 1;
        }
      }
    }
    @media (max-width: 1024px) {
      --btnSize: 2.2em;
      position: relative;
      float: none;
      width: 100%;
      z-index: 10;
      &.show-menu {
        border-radius: var(--borderRadius) var(--borderRadius) 0 0;
        background: #33363d;
        .lnbWrap {
          display: block;
        }
      }
      .lnbWrap {
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        overflow: hidden;
        background: #33363d;
        border-radius: 0 0 var(--borderRadius) var(--borderRadius);
      }
      .lnb {
        height: 50vh;
        overflow-y: auto;
        li {
          background-color: transparent;
          &.all {
            border-radius: 0;
            margin: 0;
          }
        }
      }
      .category {
        &.on {
          background-color: transparent;
        }
      }
      button.category {
        &:hover {
          background-color: #2b2e35;
        }
      }
      .menu {
        border-top: 0;
        padding-bottom: 2.5em;
      }
    }
    @media (max-width: 750px) {
      --btnSize: 1.8em;
    }
  `,
};

export default StyledVideoTab;

import React, { useEffect, useState } from 'react';
import StyledForm from 'component/form/ModalForm.style';
import StyledModal from 'component/Modal/styled/Modal.style';
import Styled from './styled/CreateEmailAccount.style';
import { Link, useHistory } from 'react-router-dom';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';
import { useAxios } from 'common/hook/useAxiosHook';
import {
  getTermsOfUse,
  getTermsOfInfo,
  getTermsOfHandling,
  SNSTerms,
  SNSTermstest
} from 'component/user/api/userApi';
import TermsForm from 'component/user/TermsForm';
import { useDispatch } from 'react-redux';
import useErrorToastify from 'common/hook/useErrorToastify';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import { Trans, useTranslation } from 'react-i18next';
import { actions } from 'component/user/state';
import { toast } from 'react-toastify';

export default function CreateSNSAccount({ token, isTerms }) {
  const { handleModal } = React.useContext(ModalContext);
  const { t } = useTranslation('signupModal');

  const [
    { loading: putTermsLoading, data: putTermsStatus, error: putTermsError },
    fetchPutTerms,
  ] = useAxios(SNSTerms, [], true);
  const [fullTermsOfUse] = useAxios(getTermsOfUse);
  const [fullTermsOfInfo] = useAxios(getTermsOfInfo); 
  const [fullTermsOfHandling] = useAxios(getTermsOfHandling);

  useErrorToastify(putTermsError);

  const openLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false);
  };

  const [formData, setFormData] = useState({
    emailAcceptance: false,
    smsAcceptance: false,
    termsOfUse: false,
    termsOfInfo: false,
    userInfoCollectionAcceptance: true,
  });

  // 약관 동의
  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.termsOfUse || !formData.termsOfInfo) {
      toast.error('You must agree to the required terms');
      return false;
    }

    if (token) {
      dispatch(actions.setAuth(token));
      dispatch(actions.fetchUser(token));
      fetchPutTerms(formData, token);
    }
  };

  const history = useHistory();
  useEffect(() => {
    if (putTermsStatus !== 200) return;
    if (!isTerms) {
      history.push({
        pathname: '/complete-signup',
        state: {
          refresh: true,
        }
      });
    }
    handleModal();
  }, [putTermsStatus]);

  return (
    <>
      <LoadingOverlay isLoading={putTermsLoading} />

      <StyledModal.H2>{t('SNSTitle')}</StyledModal.H2>
      <StyledForm.FormWrapper onSubmit={onSubmit}>
        {/* <Styled.TopDesc>{t('guidance1')}</Styled.TopDesc> */}

        {/* <StyledForm.InputContainer>
          <StyledForm.Label htmlFor='userID'>
            <span className='confirmed'>
              <GoCheck />
            </span>
            {t('email.label')}
          </StyledForm.Label>
          <StyledForm.Input
            type='text'
            id='userID'
            name='userID'
            autoComplete='email'
            value={userData.userID}
            placeholder={t('email.placeholder')}
            disabled
          />
        </StyledForm.InputContainer> */}

        <TermsForm
          setFormData={(termsData) =>
            setFormData({ ...formData, ...termsData })
          }
          fullTermsOfUse={fullTermsOfUse}
          fullTermsOfInfo={fullTermsOfInfo}
          fullTermsOfHandling={fullTermsOfHandling}
          t={t}
          type='sns'
        />

        <StyledForm.InputContainer>
          <Styled.BlueButton>{t('submit')}</Styled.BlueButton>
        </StyledForm.InputContainer>

        <Styled.Bottom.Login>
          {t('guidance2')}{' '}
          <Link to='#none' onClick={openLoginModal}>
            {t('guidance2Link')}
          </Link>
        </Styled.Bottom.Login>

        <Styled.Bottom.Terms>
          <Trans
            t={t}
            i18nKey='guidance3'
            components={{
              link1: <Link to='/privacy' target='_blank' />,
              link2: <Link to='/terms' target='_blank' />,
            }}
          />
        </Styled.Bottom.Terms>
      </StyledForm.FormWrapper>
    </>
  );
}

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Swiper } from 'swiper/react';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import arrowRightIcon from 'assets/images/arrow_right.svg';
import gradientLeft from 'assets/images/gradient_left.png';
import gradientBottom from 'assets/images/gradient_bottom01.png';

const TeachersStyled = {
  ShadeWrapper: styled.div`
    position: relative;
    @media (min-width: 1025px) {
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        z-index: 9;
        width: 15rem;
        height: 100%;
        background: url(${gradientLeft}) repeat-y left top;
        z-index: 9;
      }
      &:before {
        left: -20px;
      }
      &:after {
        right: -20px;
        transform: rotate(180deg);
      }
    }
  `,
  Wrapper: styled(ContentsWrapper)`
    padding-top: 10em;
    padding-bottom: 10em;
    img {
      width: 100%;
      transition: opacity 0.3s ease;
    }
    @media (max-width: 767px) {
      padding-top: 8em;
      padding-bottom: 8em;
    }
  `,
  Swiper: styled(Swiper)`
    width: 100%;
    overflow: visible;
    .swiper-slide {
      width: auto;
    }
    @media (min-width: 1025px) {
      .swiper-button-prev,
      .swiper-button-next {
        position: absolute;
        top: 50%;
        z-index: 10;
        width: 6.8rem;
        height: 6.8rem;
        margin-top: -3.4rem;
        border-radius: 7px;
        background-color: rgba(255, 181, 0, 0.5);
        cursor: pointer;
        transform: rotate(45deg);
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
        }
        &:after {
          background: url(${arrowRightIcon}) no-repeat center;
          background-size: 1.6rem;
        }
      }
      .swiper-button-prev {
        left: -12rem;
        &:after {
          transform: rotate(135deg);
        }
      }
      .swiper-button-next {
        right: -12rem;
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
    @media (max-width: 1024px) {
      .swiper-slide-active {
        img {
          opacity: 0.35;
        }
        a:before,
        a:after {
          opacity: 1;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 9;
      }
      .swiper-button-prev {
        left: -20px;
        width: 20px;
        background-color: #0f1012;
      }
      .swiper-button-next {
        right: -20px;
        width: 8rem;
        background: url(${gradientLeft}) repeat-y right top;
        background-size: 130% auto;
        transform: rotate(180deg);
      }
    }
  `,
  Link: styled(Link)`
    position: relative;
    display: block;
    border-radius: 0.8rem;
    font-size: 2em;
    overflow: hidden;
    &:before,
    &:after {
      position: absolute;
      z-index: 1;
      opacity: 0;
      transition: all 0.3s ease;
    }
    &:before {
      content: '';
      top: 0;
      left: 0;
      z-index: 9;
      width: 100%;
      height: 100%;
      border: 2px solid #e2a000;
      border-radius: 0.8rem;
      box-sizing: border-box;
    }
    &:after {
      content: '${({ text }) => text}';
      top: 50%;
      left: 50%;
      width: 17rem;
      height: 5.4rem;
      margin: -2.7rem 0 0 -8.5rem;
      line-height: 5.4rem;
      border-radius: 0.6rem;
      background-color: #485cc7;
      font-weight: 600;
      text-align: center;
      color: #fff;
    }
    @media (min-width: 1025px) {
      &:hover {
        img {
          opacity: 0.35;
        }
        &:before,
        &:after {
          opacity: 1;
        }
      }
    }
    @media (max-width: 767px) {
      font-size: 2.4em;
      &:after {
        width: 14rem;
        height: 5rem;
        margin: -2.5rem 0 0 -7rem;
        line-height: 5rem;
        font-weight: 400;
      }
    }
  `,
  Texts: styled.div`
    position: absolute;
    bottom: -2px;
    left: -1px;
    z-index: 2;
    width: calc(100% + 4px);
    padding: 6rem 4rem 4rem;
    background: url(${gradientBottom}) repeat-x center bottom;
    background-size: auto 100%;
    @media (max-width: 767px) {
      padding: 4rem 2rem 2rem;
    }
  `,
  Name: styled.div`
    font-size: 1.6em;
    font-weight: 600;
    @media (max-width: 767px) {
      font-size: 1.25em;
    }
  `,
  Category: styled.div`
    font-weight: 300;
  `,
};

export default TeachersStyled;

import styled from "styled-components";
import { IconTextStyles } from "component/atoms/Icon";
import iconReply from "assets/images/ico_replay.png";
import iconReplyLarge from "assets/images/ico_replay_large.png";

const ReplyIcon = styled.span`
  ${IconTextStyles}
  padding-left: 1.7em;
  background-image: url(${iconReply});
  transition: background-image 0.3s ease;
  color: #8e9199;
  cursor: pointer;
  @media (max-width: 1024px) {
    background-image: url(${iconReplyLarge});
  }
  @media (max-width: 767px) {
    font-size: 1.6em;
  }
`;

export default ReplyIcon;

import styled from 'styled-components';

const StyledTable = {
  Container: styled.div`
    padding: 4em 2em;
  `,
  Table: styled.table`
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    table-layout: fixed;

    thead th {
      padding: 1.25em 0;
      border-right: 1px solid #616161;
      &:last-of-type {
        border: none;
      }
    }
    tr th {
      border-right: 1px solid #616161;
    }
    tr:first-of-type > * {
      border-top: 1px solid #616161;
    }
    tr:last-of-type td {
      border: none;
    }
    th {
      color: #c9cbd1;
      font-size: 1.6em;
      vertical-align: middle;
      padding: 1em;
    }
    td {
      padding: 1em;
      border-bottom: 1px dashed #616161;
      text-align: center;
      font-size: 1.6em;
      font-weight: 500;
      vertical-align: middle;

      &.current {
        background-color: #0f1012;
      }
    }
  `,
};

export default StyledTable;

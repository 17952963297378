import React, { useEffect, useState } from 'react';
import StyledActivityInsight, {
  StyledCurriculumProgressDetail as Styled,
} from 'container/UserHomePage/styled/ActivityInsight.style';

import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

const swiperBreakPoint = {
  768: {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  0: {
    slidesPerView: 1,
    spaceBetween: 10,
  },
};

SwiperCore.use([Navigation]);

export default function CurriculumProgressDetail({ courseList = [] }) {
  const [slideList, setSlideList] = useState([]);
  const colorList = ['#00acb2', '#80aa00', '#8b56e3', '#586eed'];

  useEffect(() => courseList.length > 0 && setSlideList(courseList), [
    courseList,
  ]);

  return (
    <Styled.Wrapper>
      <StyledActivityInsight.H3>
        Curriculum progress in detail
      </StyledActivityInsight.H3>

      <Styled.Box>
        <Styled.Swiper
          breakpoints={swiperBreakPoint}
          spaceBetween={20}
          slidesPerView={4}
          navigation
        >
          {slideList.length > 0 &&
            slideList.map((item, index) => {
              const percent = Math.round(
                (item.viewClassCount / item.classCount) * 100
              );
              const keyColor = colorList[index % 4];
              return (
                <SwiperSlide key={index}>
                  <a href={`courses/detail/${item.id}`}>
                    <Styled.CircleWrapper>
                      <Styled.Circle percent={percent} strokeColor={keyColor} />

                      <Styled.Percent>{percent}</Styled.Percent>
                    </Styled.CircleWrapper>

                    <Styled.Subject>{item.title}</Styled.Subject>

                    <Styled.Count color={keyColor}>
                      <b>{item.viewClassCount}</b>/{item.classCount}
                    </Styled.Count>
                  </a>
                </SwiperSlide>
              );
            })}
        </Styled.Swiper>
      </Styled.Box>
    </Styled.Wrapper>
  );
}

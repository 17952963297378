import React from 'react';
import Style from 'component/ui/card/styled/CurriculumCard.style';
import { planColors } from 'common/constant';
import { useHistory } from 'react-router-dom';
import Img from 'component/atoms/Img';
import thumbnailDefault from 'assets/images/thumb_default02.jpg';

const CurriculumCard = ({ data }) => {
  const history = useHistory();
  return (
    <Style.Container
      className='card'
      onClick={() =>
        // history.push(history.location.pathname + `/${data.curriculumChargeId}`)
        history.push(`/classroom/curriculum/${data.curriculumChargeId}`)
      }
    >
      <Style.ImgContainer>
        <Img
          src={data.thumbnailPath ? data.thumbnailPath : thumbnailDefault}
          alt='curriculum thumbnail'
          onError={thumbnailDefault}
        />
        <Style.Badge

          bgColor={
            planColors[data.serviceGrade ? data.serviceGrade : 'FREE'].color
          }
        >
          {data.serviceGrade}
        </Style.Badge>
      </Style.ImgContainer>
      <Style.Content>
        <Style.Title line={3}>{data.title}</Style.Title>
        <Style.Desc line={3}>{data.description}</Style.Desc>
      </Style.Content>
    </Style.Container>
  );
};

export default CurriculumCard;

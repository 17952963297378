import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import Loading from 'component/atoms/Loading';
import themesApi from 'api/theme';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PreviousLink from 'component/atoms/PreviousLink';
import H2 from 'component/atoms/H2';
import InfiniteList from 'component/molecules/InfiniteList';
import UserThemeItem from 'component/molecules/ThemeItem/UserThemeItem';
import { EmptyList } from 'component/atoms/EmptyList';
import { masterTopikUrl } from 'common/constant';

const Wrapper = styled(ContentsWrapper)`
  padding-bottom: 15rem;
`;

const Title = styled(H2)`
  font-size: 4.8em;
  padding: 1em 0;
  margin-bottom: 6rem;
  border-bottom: 1px solid #2b2e35;
  text-align: center;
  letter-spacing: 1px;
`;
const BtnContainer = styled.div`
  text-align: center;
  a {
    display: inline-block;
    margin-top: 5em;
    padding: 1em;
    border: 1px solid #454545;
    border-radius: 0.375em;
    font-size: 1.4em;
  }
`;

const ThemeDetailByTag = () => {
  const { keyword } = useParams();
  const token = useSelector((state) => state.user.token);
  const [themeList, fetchThemeList] = useAxios(
    themesApi.getListByCategory,
    [],
    true
  );
  const { loading, data, error } = themeList;

  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  // 최초 1회 호출
  useEffect(() => fetchThemeList({ keyword, page: 0, token }), []);

  useEffect(() => {
    if (data !== null) {
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [themeList]);

  const onNext = () => {
    fetchThemeList({ keyword, page: data.number + 1, token });
  };

  return (
    <>
      <Wrapper>
        <PreviousLink text='Go Back' />

        <Title>#{keyword.toLowerCase()}</Title>

        {loading && !slideItem.length && <Loading />}

        {!loading && slideItem.length === 0 && (
          <EmptyList>The list is empty.</EmptyList>
        )}

        <InfiniteList
          dataLength={slideItem.length}
          next={onNext}
          hasMore={hasMore}
        >
          {slideItem.map((item, index) => {
            const imgSrcList = item.themeProductResponse.map(
              (itemChild) =>
                masterTopikUrl +
                itemChild?.thumbnailPath +
                itemChild?.thumbnailTitle
            );
            return (
              <UserThemeItem
                key={index}
                id={item.id}
                imgSrcList={imgSrcList}
                title={item.name}
                tag={item.categoryName}
                videoCount={item.courseCount}
                likeCount={item.likeCount}
                userThumb={item.userProfilePhotoPath}
                userName={item.userName}
                isGood={item.isGood}
              />
            );
          })}
        </InfiniteList>
      </Wrapper>
    </>
  );
};

export default ThemeDetailByTag;

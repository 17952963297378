import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ContentsWrapper from 'component/atoms/ContentsWrapper';

export const Layout = {
  Wrapper: styled(ContentsWrapper)`
    padding-bottom: 10em;
  `,
  ButtonGroup: styled.div`
    margin-top: 2em;
    text-align: right;
  `,
  Button: styled(Link)`
    position: relative;
    display: inline-block;
    padding: 0.813em 1.625em;
    border: 1px solid #8e9199;
    border-radius: 0.375em;
    color: #8e9199;
    font-size: 1.6em;
    font-weight: 500;
    text-align: center;
    line-height: 1em;
  `,
}

export default Layout;

import styled from 'styled-components';
import iconLink from 'assets/images/ico_link.png';
import iconLinkLarge from 'assets/images/ico_link_large.png';

const LinkIcon = styled.span`
  display: inline-block;
  font-size: 1.4em;
  width: 2.3em;
  height: 2.3em;
  background-image: url(${iconLink});
  background-repeat: no-repeat;
  background-position: center;
  vertical-align: unset !important;
  @media (max-width: 1024px) {
    background-image: url(${iconLinkLarge});
    background-size: 15px auto;
  }
`;

export default LinkIcon;

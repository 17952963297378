import React from 'react';
import styled from 'styled-components';
import Img from 'component/atoms/Img';
import thumbnailDefault from 'assets/images/thumb_default04.png';

/**
 * 원형 유저 썸네일 (width: 3em)
 */
const UserThumbImg = styled.span.attrs(({ src }) => ({
  children: <Img src={src} onError={thumbnailDefault} alt='userThumbnail' />,
}))`
  position: relative;
  display: inline-block;
  font-size: 1rem;
  width: 3em;
  height: 3em;
  border-radius: 100px;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export default UserThumbImg;

import React from 'react';

export const termsOfServiceKo = () => (
  <div class='policy'>
    <h4 class='policy-subtitle'>제1장 총칙</h4>
    <div class='policy-section'>
      <p class='policy-tit'>제1조 [목적]</p>
      <p>
        이 약관은 MasterTOPIK(이하 ‘회사’라 합니다)가 온라인으로 제공하는
        교육콘텐츠 및 제반 서비스의 이용과 관련하여 회사와 이용자와의 권리와
        의무, 책임사항 및 기타 필요한 사항 등을 규정함을 목적으로 하며, 회사에서
        서비스하는 마스터토픽닷컴(www.mastertopik.com)(이하 ‘마스터토픽닷컴’
        이라 합니다) 에서는 회사의 이용자번호(ID) 및 비밀번호로 해당 서비스를
        모두 로그인 하실 수 있습니다.
      </p>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제2조 [정의]</p>
      <ol class='policy-num'>
        <li>
          ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
          <ol class='policy-num'>
            <li>
              1. “이용자”라 함은 회사의 사이트에 접속하여 이 약관에 따라 회사가
              제공하는 콘텐츠 및 제반서비스를 이용하는 회원 및 비회원을
              말합니다.
            </li>
            <li>
              2. “회원”이라 함은 회사와 이용계약을 체결하고 이용자 아이디(ID)를
              부여받은 14세 이상 이용자로서 회사의 정보를 지속적으로 제공받으며
              회사가 제공하는 서비스를 지속적으로 이용할 수 있는 자를 말합니다.
            </li>
            <li>
              3. “콘텐츠”라 함은 정보통신망이용촉진 및 정보보호 등에 관한 법률
              제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는 부호,
              문자, 음성, 음향, 이미지 또는 영상 등으로 표현된 자료 또는
              정보로서, 그 보존 및 이용에 있어서 효용을 높일 수 있도록 전자적
              형태로 제작 또는 처리된 것을 말합니다.
            </li>
            <li>
              4. “아이디(ID)”라 함은 회원의 식별과 서비스이용을 위하여 회원이
              정하고 회사가 승인하는 문자 또는 숫자의 조합을 말합니다.
            </li>
            <li>
              5. “비밀번호(PASSWORD)”라 함은 회원이 부여받은 아이디와 일치되는
              회원임을 확인하고 비밀보호를 위해 회원 자신이 정한 문자 또는
              숫자의 조합을 말합니다.
            </li>
            <li>
              6. “운영자(관리자)”라 함은 서비스의 전반적인 관리와 원활한 운영을
              위하여 회사에서 선정한 사람 혹은 기관(회사)를 말합니다.
            </li>
            <li>
              7. “사이버머니”라 함은 회사의 사이트 내에서 통용되는 날개, 포인트,
              캐시 등의 명칭으로 사용되는 가상의 지불수단으로 콘텐츠 및 서비스
              등을 구매할 수 있습니다.
            </li>
            <li>
              8. “게시물”이라 함은 회원이 서비스를 이용함에 있어 회사의 사이트에
              게시한 문자, 부호, 음향, 화상, 동영상 등의 정보 형태의 글, 사진,
              동영상 및 각 종 파일과 링크, 댓글 등의 정보를 말합니다.
            </li>
          </ol>
        </li>
        <li>
          ② 전항 각호의 용어를 제외한 용어의 정의는 거래 관행 및 관계 법령에
          따릅니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제3조 [회사정보 등의 제공]</p>
      <p>
        회사는 이 약관의 내용, 상호, 대표자명, 주소(소비자의 불만을 처리할 수
        있는 곳의 주소를 포함), 전화번호, 모사전송번호, 전자우편주소,
        사업자등록번호, 통신판매업 신고번호 및 개인정보관리책임자 등을 이용자가
        쉽게 알 수 있도록 온라인 서비스초기화면에 게시합니다. 다만, 약관은
        이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
      </p>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제4조 [약관의 게시 등]</p>
      <p>
        회사는 이 약관을 이용자가 별도의 연결화면을 통하여 약관의 내용을 확인할
        수 있도록 사이트 내에 게시합니다.
      </p>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제5조 [약관의 개정 등]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 콘텐츠산업진흥법, 전자상거래 등에서의 소비자보호에 관한 법률,
          약관의 규제에 관한 법률 등 관련법을 위배하지 않는 범위에서 이 약관을
          개정할 수 있습니다.
        </li>
        <li>
          ② 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행
          약관과 함께 서비스초기화면에 그 적용일자 10일(이용자에게 불리한 변경
          또는 중대한 사항의 변경은 30일) 이전부터 적용일 후 상당한 기간 동안
          공지하고, 기존회원에게는 개정약관, 적용일자 및 변경사유를
          전자우편주소로 발송합니다.
        </li>
        <li>
          ③ 회원이 개정약관의 적용에 동의하지 않는 경우 회원은 서비스 이용을
          중단하고, 회원탈퇴를 할 수 있습니다.
        </li>
        <li>
          ④ 기존 회원이 동조 2항에 따른 고지 또는 통지에도 불구하고, 동 기간
          내에 이의를 제기하지 않는 경우에는 변경된 약관에 동의한 것으로 봅니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제6조 [약관의 해석 등]</p>
      <p>
        이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 관계법령,
        회사가 정한 서비스의 개별이용 약관, 세부 이용 지침 및 규칙 등의 규정
        또는 상관례에 따르게 됩니다.
      </p>
    </div>

    <h4 class='policy-subtitle'>제2장 회원가입</h4>

    <div class='policy-section'>
      <p class='policy-tit'>제7조 [회원가입]</p>
      <ol class='policy-num'>
        <li>
          ① 회원가입은 14세 이상 이용자만 가능하며 이용자가 약관의 내용에 대하여
          동의를 하고, 회사가 제공하는 소정의 신청양식에 관련사항을 기재하여
          회원가입을 신청한 후 회사가 이러한 신청에 대하여 승낙함으로써
          체결됩니다.
        </li>
        <li>
          ② 전항의 신청양식에 이용자가 기재하는 모든 정보는 실제 데이터인 것으로
          간주하며 실명이나 실제 정보를 입력하지 않은 사용자는 법적인 보호를
          받을 수 없으며, 서비스 이용의 제한을 받을 수 있습니다.
        </li>
        <li>
          ③ 회사는 본조 제1항 이용자의 신청에 대하여 회원가입을 승낙함을
          원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는
          승낙을 하지 않거나 유보할 수 있으며, 승낙한 이후라도 취소할 수
          있습니다.
          <ol class='policy-num'>
            <li>1. 이용자의 나이가 만 14세 미만인 경우</li>
            <li>
              2. 가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이
              있는 경우
            </li>
            <li>3. 가입신청자가 3개월 이내에 회원 탈퇴한 적이 있는 경우</li>
            <li>4. 실명이 아니거나 타인의 명의를 이용한 경우</li>
            <li>
              5. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은
              경우
            </li>
            <li>
              6. 이용자의 귀책사유로 승인이 불가능하거나 기타 제반 사항을
              위반하며 신청하는 경우
            </li>
            <li>7. 본 약관의 제18조의 회원의 의무를 위반한 경우</li>
            <li>
              8. 회원가입 신청 시 기재하여 회사에 제공된 사항(ID, 비밀번호, 주소
              등)이 선량한 풍속 기타 사회질서에 위배되거나 타인을 모욕하는 경우
            </li>
            <li>9. 기타 회사가 정한 이용신청 요건이 미비된 경우</li>
          </ol>
        </li>
        <li>
          ④ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가
          있는 경우에는 승낙을 유보할 수 있습니다.
        </li>
        <li>
          ⑤ 제3항과 제4항에 따라 회원가입신청의 승낙을 하지 아니하거나 유보한
          경우, 회사는 이를 신청자에게 알려야 합니다. 단, 회사의 귀책사유 없이
          신청자에게 통지할 수 없는 경우에는 예외로 합니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제8조 [회원정보의 변경]</p>
      <ol class='policy-num'>
        <li>
          ① 회원은 회원정보관리화면 등을 통하여 비밀번호를 정확히 입력하면,
          언제든지 자신의 개인정보를 열람하고 수정할 수 있습니다.
        </li>
        <li>
          ② 회원이 회원가입 신청 시 기재한 사항이 변경되었음에도 그 변경사항을
          “회사”에 알리지 않아 발생한 불이익에 대하여 “회사”는 책임지지
          않습니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>
        제9조 [회원의 아이디 및 비밀번호의 관리에 대한 의무]
      </p>
      <ol class='policy-num'>
        <li>
          ① 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를
          제3자가 이용하도록 하여서는 안 됩니다.
        </li>
        <li>
          ② 회원은 아이디 및 비밀번호가 도용되거나 제3자에 의해 사용되고 있음을
          인지한 경우에는 이를 즉시 회사에 통지하여야 합니다.
        </li>
        <li>
          ③ 회사는 제2항의 경우에 회원의 개인정보보호 및 기타 부정이용행위 등의
          방지를 위하여 회원에게 비밀번호의 변경 등 필요한 조치를 요구할 수
          있으며, 회원이 회사의 요구를 따르지 않아 발생한 불이익에 대하여 회사는
          책임지지 않습니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제10조 [회원에 대한 통지]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 회원에게 알려야 할 사항이 발생할 경우, 회원이 제공한
          전자우편주소 또는 쪽지, 팝업창, 유선, 무선 등의 방법으로 통지할 수
          있습니다.
        </li>
        <li>
          ② 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사 웹사이트 게시판에
          게시함으로써 전항의 통지에 갈음할 수 있습니다. 단, 회원 본인의 유료
          서비스 이용 등 거래와 관련하여 중대한 사항에 대해서는 제1항의 통지를
          합니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제11조 [회원탈퇴 및 자격 상실 등]</p>
      <ol class='policy-num'>
        <li>
          ① 회원이 회원탈퇴를 하고자 하는 경우 회사 고객센터에 전화하거나,
          온라인으로 회사에 탈퇴를 요청할 수 있으며, 이 경우 회사는 지체 없이
          회원탈퇴 처리하고 그 사실을 회원에게 통지합니다.
        </li>
        <li>
          ② 회사는 회원이 본 약관 또는 관계법령을 위반하는 경우, 서비스 이용을
          제한하거나 회원자격을 상실시킬 수 있습니다.
        </li>
        <li>
          ③ 회사는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 및 동법
          시행령에 따라 연속하여 1년 동안 서비스를 이용하지 않은
          회원(휴면회원)의 개인정보는 다른 회원의 개인정보와 분리하여 별도 보관,
          관리하며 법률에 특별한 규정이 있는 경우를 제외하고는 해당 개인정보를
          이용하거나 제공하지 않습니다. 이 경우, 조치일 30일 전까지 필요한
          조치가 취해진다는 사실과 개인정보가 분리되어 보관, 관리된다는 사실 및
          해당 개인정보의 항목을 전자우편, 서면, 모사전송, 전화 또는 이와 유사한
          방법 중 하나의 방법으로 회원에게 통지합니다.
        </li>
      </ol>
    </div>

    <h4 class='policy-subtitle'>제3장 서비스 이용계약</h4>

    <div class='policy-section'>
      <p class='policy-tit'>제12조 [콘텐츠의 내용 등의 게시]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 다음 사항을 해당 콘텐츠(연결화면 포함) 또는 그 포장에
          이용자가가 알기 쉽게 표시합니다.
          <ol class='policy-num'>
            <li>1. 콘텐츠의 명칭 및 종류</li>
            <li>2. 콘텐츠의 가격 및 이용시간</li>
            <li>3. 콘텐츠 제작자의 성명</li>
            <li>4. 콘텐츠의 내용, 이용방법, 이용료 기타 이용조건</li>
          </ol>
        </li>
        <li>
          ② 회사는 콘텐츠별 이용가능기기 및 이용에 필요한 최소한의 기술사양에
          관한 정보를 이용자에게 제공합니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제13조 [이용계약의 성립 등]</p>
      <ol class='policy-num'>
        <li>
          ① 회원은 회사가 제공하는 다음 또는 이와 유사한 절차에 의하여
          이용신청을 합니다. 회사는 계약 체결 전에 각 호의 사항에 관하여
          이용자가 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 정보를
          제공합니다.
          <ol class='policy-num'>
            <li>1. 콘텐츠 목록의 열람 및 선택</li>
            <li>2. 콘텐츠의 상세정보</li>
            <li>3. 주문 상품 및 결제 금액 확인(환불규정 안내)</li>
            <li>
              4. 콘텐츠의 이용신청에 관한 확인 또는 회사의 확인에 대한 동의
            </li>
            <li>5. 결제방법의 확인 및 선택</li>
            <li>6. 결제금액의 재확인</li>
          </ol>
        </li>
        <li>
          ② 회사는 회원의 이용신청이 다음 각 호에 해당하는 경우에는 승낙하지
          않거나 승낙을 유보할 수 있습니다.
          <ol class='policy-num'>
            <li>1. 실명이 아니거나 타인의 명의를 이용한 경우</li>
            <li>
              2. 허위의 정보를 기재하거나, 회사가 제시하는 내용을 기재하지 않은
              경우
            </li>
            <li>
              3. 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가
              있는 경우
            </li>
            <li>
              4. 회사가 통제하기 곤란한 사정으로 정상적인 서비스 이용에 지장이
              있는 경우
            </li>
            <li>5. 유료 서비스 이용 요금을 납입하지 않은 경우</li>
            <li>6. 유료 서비스 신청 금액과 입금 금액이 일치하지 않은 경우</li>
          </ol>
        </li>
        <li>
          ③ 회사는 회원이 제1항에 따라 콘텐츠(서비스) 이용신청을 할 경우, 승낙의
          의사표시로서 제10조 제1항의 방법에 의한 방법으로 회원에게 통지하고
          승낙의 통지가 회원에게 도달한 시점에 계약이 성립한 것으로 봅니다.
        </li>
        <li>
          ④ 회사의 승낙의 의사표시에는 회원의 이용신청에 대한 확인 및 서비스제공
          가능여부, 이용신청의 정정, 취소 등에 관한 정보 등을 포함합니다.
        </li>
        <li>
          ⑤ 본조 제3항에 따른 회사의 승낙의 통지 이후에도 제2항의 3호 내지 6호의
          사유가 발생할 경우, 회사는 그 사유가 해소될 때까지 회원의
          콘텐츠(서비스) 이용을 제한할 수 있습니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제14조 [교재 등의 판매 및 배송]</p>
      <ol class='policy-num'>
        <li>
          ① 회사의 각 사이트에서 교재 등의 제품을 판매할 경우 다음과 같이
          서비스합니다.
          <ol class='policy-num'>
            <li>
              1. 회사의 사이트에서 콘텐츠 서비스와 관련하여 판매하는 교재의
              구매는 회사의 사이트에서 가능합니다.
            </li>
            <li>
              2. 교재 등의 제품은 회사 사이트에 회원가입을 완료한 회원에 한하여
              판매합니다.
            </li>
            <li>
              3. 회사는 회원의 교재 구매신청이 있는 경우 회원에게 수신확인통지를
              합니다.
            </li>
            <li>
              4. 회사는 회원이 교재 배송 과정을 온라인으로 확인 할 수 있도록
              제공합니다.
            </li>
            <li>
              5. 교재 배송은 회원의 구매 결제가 완료된 시점에서 7일 이내에
              배송하는 것을 원칙으로 하며, 천재지변, 교재의 재고 부족 등으로
              인하여 지연 될 수 있습니다.
            </li>
          </ol>
        </li>
        <li>
          ② 회사와 교재 구매계약을 체결한 회원은 교재를 수령한 날로부터 7일
          이내에 청약의 철회(주문 취소)를 할 수 있습니다. 단 회원이 교재 등을
          배송 받은 경우 다음 각 호의 1에 해당하는 경우에 반품 및 교환을 할 수
          없습니다.
          <ol class='policy-num'>
            <li>1. 회원의 책임있는 사유로 교재 등이 멸실 또는 훼손된 경우</li>
            <li>
              2. 회원의 사용 또는 일부 소비에 의하여 교재 등의 가치가 현저히
              감소한 경우
            </li>
          </ol>
        </li>
        <li>
          ③ 본 조 제②항의 청약철회 기간에도 불구하고 배송 받은 교재를 다음 각
          호의 사유로 반품 및 교환하고자 할 경우 문제점 발견 후 30일 혹은
          수령일로부터 3개월 이내로 하며 반품, 교환비용은 회사가 부담합니다. 단,
          이때 이미 배송 받은 교재는 회사에 반송하여야 합니다.
          <ol class='policy-num'>
            <li>
              1. 배송된 교재가 주문내용과 상이하거나 회사가 제공한 정보와 상이할
              경우
            </li>
            <li>
              2. 배송된 교재가 회사의 유통, 취급 중 발생된 외관상 하자가 있는
              경우
            </li>
            <li>
              3. 배송된 교재가 파본(인쇄/제본오류 등 교재 제작 시 발생된 것으로
              추정)인 경우
            </li>
          </ol>
        </li>
        <li>
          ④ 본 조 제③항의 각호에 해당하지 않는 사유로 배송 된 교재 등을 반품,
          교환하는 경우 추가비용 일체를 회원이 부담해야 합니다. 이 때 회원이
          부담할 배송비는 교재 등 구입 당시 배송비를 기준으로 하며 제주도 등의
          섬과 산간지역 및 국외 배송인 경우 일반 배송업체의 배송비를 기준으로
          합니다.
        </li>
        <li>
          ⑤ 청약철회 등에 따라 교재를 환불할 때는 쿠폰을 제외한 실 결제금액에서
          결제 수수료와 송금 수수료를 차감하고 환불됩니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제15조 [“회사”의 의무]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 법령과 이 약관이 정하는 권리의 행사와 의무의 이행을 신의에
          좇아 성실하게 하여야 합니다.
        </li>
        <li>
          ② 회사는 회원이 안전하게 콘텐츠를 이용할 수 있도록 개인정보(신용정보
          포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보 취급방침을
          공시하고 준수합니다.
        </li>
        <li>
          ③ 회사는 회원이 콘텐츠이용 및 그 대금내역을 수시로 확인할 수 있도록
          조치합니다.
        </li>
        <li>
          ④ 회사는 콘텐츠이용과 관련하여 회원으로부터 제기된 의견이나 불만이
          정당하다고 인정할 경우에는 이를 지체 없이 처리합니다. 회원이 제기한
          의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편 등을 통하여
          그 처리과정 및 결과를 전달합니다.
        </li>
        <li>
          ⑤ 회사는 이 약관에서 정한 의무 위반으로 인하여 이용자가 입은 손해를
          배상합니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제16조 [회원의 의무]</p>
      <ol class='policy-num'>
        <li>
          ① 회원은 다음 행위를 하여서는 아니 되며, 회원이 이를 위반할 경우
          회사는 기간을 정하여 서비스의 전부 또는 일부의 이용을 제한하거나
          상당기간 최고 후 이용계약을 해지할 수 있습니다. 단, 회사는 상기 제재가
          있는 경우에 일정기간 동안 회원에게 소명할 수 있는 기회를 부여하며,
          회원이 자신의 고의나 과실이 없었음을 입증한 경우 회사는 서비스 제공
          정지기간만큼 이용기간을 연장합니다.
          <ol class='policy-num'>
            <li>
              1. 신청 또는 변경 시 허위내용의 기재나 타인의 정보(ID 등 개인정보)
              도용
            </li>
            <li>
              2. 서비스를 이용하여 얻은 정보(콘텐츠)를 회사의 사전 승낙 없이
              이용자의 이용 이외의 목적으로 복제하거나 이를 출판, 전송 등에
              사용하거나 제3자에게 제공하는 행위
            </li>
            <li>3. 회사에 게시된 정보의 변경</li>
            <li>4. 회사가 금지한 정보(컴퓨터 프로그램 등)의 송신 또는 게시</li>
            <li>5. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
            <li>
              6. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
            </li>
            <li>
              7. 외설 또는 폭력적인 말이나 글, 화상, 음향, 기타 공서양속에
              반하는 정보를 “회사”의 사이트에 공개 또는 게시하는 행위
            </li>
            <li>
              8. 다른 이용자의 서비스 이용을 방해하거나 회사의 운영진, 직원,
              관계자로 사칭하는 행위
            </li>
            <li>9. 기타 불법적이거나 부당한 행위</li>
          </ol>
        </li>
        <li>
          ② 회원은 관계법령, 이 약관의 규정, 이용안내 및 콘텐츠와 관련하여
          공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타
          회사의 업무에 방해되는 행위를 하여서는 안 됩니다.
        </li>
        <li>
          ③ 회원은 회사의 명시적인 동의나 승낙이 없는 한 서비스의 이용권한, 기타
          이용 계약상의 지위를 타인에게 양도, 증여하거나 담보로 제공할 수
          없습니다.
        </li>
        <li>
          ④ 회원은 자신의 ID 및 비밀번호를 안전하게 관리하여야 하며, 관리소홀,
          부정사용에 의하여 발생하는 결과에 대한 책임은 회원에게 있습니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제17조 [지급방법]</p>
      <p>
        콘텐츠의 이용에 대한 대금지급방법은 다음 각 호의 방법 중 가능한 방법으로
        할 수 있습니다.
      </p>
      <ol class='policy-num'>
        <li>1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체</li>
        <li>2. 선불카드, 직불카드, 신용카드 등의 각종 카드결제</li>
        <li>3. 온라인무통장입금</li>
        <li>4. 전자화폐에 의한 결제</li>
        <li>5. 마일리지 등 회사가 지급한 포인트에 의한 결제</li>
        <li>6. 회사와 계약을 맺었거나 회사가 인정한 상품권에 의한 결제</li>
        <li>7. 전화 또는 휴대전화를 이용한 결제</li>
        <li>8. 기타 전자적 지급방법에 의한 대금지급 등</li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제18조 [서비스의 제공 및 중단]</p>
      <ol class='policy-num'>
        <li>① 콘텐츠서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.</li>
        <li>
          ② 회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절
          또는 운영상 상당한 이유가 있는 경우 콘텐츠서비스의 제공을 일시적으로
          중단할 수 있습니다. 이 경우 회사는 제10조에 정한 방법으로 회원에게
          통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는
          경우 사후에 통지할 수 있습니다.
        </li>
        <li>
          ③ 회사는 귀책사유로 인하여 유료 (콘텐츠)서비스의 제공이 일시적으로
          중단됨으로 인하여 회원이 입은 손해에 대하여 서비스를 사용하지 못한
          기간만큼 서비스 기간을 연장하는 등의 방식으로 배상합니다. 단, 제3자의
          고의 또는 과실로 인하여 서비스가 중지되거나 장애가 발생한 경우에는
          회사는 책임이 없습니다.
        </li>
        <li>
          ④ 회사가 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로
          서비스를 제공할 수 없게 되는 경우, 제10조에서 정한 방법으로 회원에게
          통지하고 제25조 5항에 따라 환불조치 합니다.
        </li>
        <li>
          ⑤ 회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며,
          정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제19조 [콘텐츠서비스의 변경]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
          제공하고 있는 콘텐츠서비스를 변경할 수 있습니다.
        </li>
        <li>
          ② 회사는 제1항에 따라 서비스가 변경되는 경우 변경사유 및 변경 내용을
          제10조의 방법으로 회원에게 통지합니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제20조 [정보의 제공 및 광고의 게재]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 회원이 서비스이용 중 필요하다고 인정되는 다양한 정보를
          공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만,
          회원은 언제든지 전자우편 등을 통하여 수신 거절을 할 수 있습니다.
        </li>
        <li>
          ② 회사는 서비스 제공과 관련하여 해당 서비스화면, 회사의 각 사이트,
          전자우편 등에 광고를 게재할 수 있습니다. 광고가 게재된 전자우편 등을
          수신한 회원은 수신거절을 할 수 있습니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제21조 [회원의 게시물 및 삭제]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 회원이 등록한 게시물 중 본 약관과 정보통신망이용촉진 및
          정보보호 등에 관한 법률 등 관계 법률을 위반한 게시물 또는 다음 각 호에
          해당하는 경우에는 이를 즉시 삭제할 수 있습니다. 단 제 10호의 경우에는
          당해 사항을 사전에 회사의 공지사항에 공지합니다.
          <ol class='policy-num'>
            <li>
              1. 회사, 다른 회원 또는 제3자를 비방하거나 명예를 손상시키는
              내용인 경우
            </li>
            <li>2. 공공질서 및 미풍양속에 위반되는 내용을 유포하는 경우</li>
            <li>3. 범죄적 행위에 결부된다고 인정되는 내용인 경우</li>
            <li>
              4. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인
              경우
            </li>
            <li>
              5. 법령을 위반하거나 타인의 권리를 침해하는 방식으로 분쟁을
              야기하는 경우
            </li>
            <li>6. 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우</li>
            <li>
              7. 타인의 개인정보를 도용, 사칭하여 작성한 내용이거나, 타인이
              입력한 정보를 무단으로 위/변조한 내용인 경우
            </li>
            <li>
              8. 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는
              경우
            </li>
            <li>9. 회사의 게시판 운영지침 등에 위반된다고 판단되는 경우</li>
            <li>
              10. 회사는 서비스용 설비의 용량에 여유가 없다고 판단되는 경우
            </li>
          </ol>
        </li>
        <li>
          ② 회사가 운영하는 게시판 등에 게시된 정보로 인하여 법률상 이익이
          침해된 자는 회사에게 당해 정보의 삭제 또는 반박내용의 게재를 요청할 수
          있습니다. 이 경우 회사는 지체 없이 필요한 조치를 취하고 이를 즉시
          신청인에게 통지합니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제22조 [저작권 등의 귀속]</p>
      <ol class='policy-num'>
        <li>
          ① 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에
          귀속합니다.
        </li>
        <li>
          ② 이용자는 회사가 제공하는 서비스를 이용함으로써 얻은 정보 중 회사
          또는 제공업체에 지적재산권이 귀속된 정보를 회사 또는 제공업체의
          사전승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에 의하여
          영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
        </li>
        <li>
          ③ 이용자가 서비스 내에 게시한 게시물의 저작권은 저작권법에 의해 보호를
          받습니다. 회사는 회사의 홍보를 위해 제한된 용도에 한해 회원의 게시물을
          활용할 수 있습니다. 단, 회원이 이의를 제기할 경우 사용을 중단합니다.
        </li>
        <li>
          ④ 이용자는 자신이 게시한 게시물을 회사가 국내외에서 다음 각 호의
          목적으로 사용하는 것을 허락합니다. 단, 제2호 및 3호의 경우 이용자가
          이의를 제기할 경우 회사는 이의를 제기한 시점부터 전시, 배포를
          중단합니다.
          <ol class='policy-num'>
            <li>
              1. 서비스 내에서 이용자 게시물의 복제, 전송, 전시, 배포 및 우수
              게시물을 서비스화면에 노출하기 위하여 이용자게시물의 크기를
              변환하거나 단순화하는 등의 방식으로 수정 하는 것.
            </li>
            <li>
              2. 회사에서 운영하는 관련 사이트의 서비스 내에서 이용자 게시물을
              전시, 배포 하는 것.
            </li>
            <li>
              3. 회사의 서비스를 홍보하기 위한 목적으로 미디어, 통신사 등에게
              이용자의 게시물 내용을 보도, 방영하도록 하는 것.
            </li>
          </ol>
        </li>
        <li>
          ⑤ 제 4항의 규정에도 불구하고, 회사가 이용자의 게시물을 제4항 각 호에
          기재된 목적 이외에 상업적 목적(예: 제3자에게 게시물을 제공하고 금전적
          대가를 지급받는 경우 등)으로 사용할 경우에는 사전에 해당 이용자로부터
          동의를 얻어야 합니다. 게시물에 대한 회사의 사용 요청, 이용자의 동의 및
          동의철회는 전화, 전자우편, 팩스 등 회사가 요청하는 방식에 따릅니다.
        </li>
        <li>
          ⑥ 회사는 회원이 탈퇴하거나 제27조 1항에 의하여 회원 자격을 상실한 경우
          별도의 동의 또는 통보절차 없이 회원의 게시물을 삭제할 수 있습니다.
        </li>
        <li>
          ⑦ 회사는 회사의 합병, 영업양도, 회사가 운영하는 사이트간의 통합 등의
          사유로 원래의 게시물의 내용을 변경하지 않고 게시물의 게시 위치를
          변경할 수 있습니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제23조 [개인정보보호]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 정보통신망이용촉진 및 정보보호에 관한 법률 등 관계 법령이
          정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다.
          개인정보의 보호 및 사용에 대해서는 관련법령 및 회사의 개인정보
          취급방침이 적용됩니다.
        </li>
        <li>
          ② 회사가 이용자의 개인 식별이 가능한 개인정보를 수집하는 때에는 당해
          이용자의 동의를 받으며, 이용자는 언제든지 회사가 가지고 있는 자신의
          개인정보에 대해 열람 및 오류의 정정을 요구할 수 있으며, 회사는 이에
          대해 지체 없이 필요한 조치를 취할 의무를 집니다.
        </li>
        <li>
          ③ 회사는 이용자가 이용신청 등에서 제공한 정보와 제1항에 의하여 수집한
          정보를 당해 이용자의 동의 없이 목적 외로 이용하거나 제3자에게 제공할
          수 없습니다. 다만, 다음의 경우에는 예외로 합니다.
          <ol class='policy-num'>
            <li>
              1. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정
              개인을 식별할 수 없는 형태로 제공하는 경우
            </li>
            <li>2. 콘텐츠 제공에 따른 요금정산을 위하여 필요한 경우</li>
            <li>3. 도용방지를 위하여 본인확인에 필요한 경우</li>
            <li>
              4. 약관의 규정 또는 법령에 의하여 필요한 불가피한 사유가 있는 경우
            </li>
          </ol>
        </li>
        <li>
          ④ 회사는 개인정보보호를 위하여 관리자를 한정하여 그 수를 최소화하며,
          개인정보의 분실, 도난, 유출, 변조 등으로 인한 이용자의 손해에 대하여
          책임을 집니다.
        </li>
        <li>
          ⑤ 회사 또는 그로부터 개인정보를 제공받은 자는 이용자가 동의한 범위
          내에서 개인정보를 사용할 수 있으며, 목적이 달성된 경우에는 당해
          개인정보를 지체 없이 파기합니다.
        </li>
      </ol>
    </div>

    <h4 class='policy-subtitle'>제4장 서비스 이용계약의 취소와 환불</h4>

    <div class='policy-section'>
      <p class='policy-tit'>제24조 [회원의 서비스 이용계약 취소]</p>
      <ol class='policy-num'>
        <li>
          ① 회사와 서비스의 이용에 관한 계약을 체결한 회원은 결제 승인일(서비스
          개시일 또는 교재의 제공일이 결제일보다 늦은 경우에는 개시일 또는
          제공일)로부터 7일 이내에만 청약의 철회를 할 수 있습니다. 다만,
          관계법령 또는 회사가 다음 각 호중 하나의 조치를 취한 경우에는 회원의
          청약철회권이 제한될 수 있습니다.
        </li>
        <li>
          ② 회원은 제1항의 청약철회(취소)를 하고자 하는 경우 회사의 고객센터에
          전화를 하거나 모사전송 등의 방법으로 회사에 그 의사를 표시하여야
          합니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제25조 [회원의 취소와 변경 및 환불정책]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 회원이 서비스 이용계약의 취소 의사표시를 한 경우 이를 즉시
          접수하고 회원의 요청과 환불규정을 확인한 후, 3일 이내(영업일 기준)에
          환불하여 드립니다. 단, 청약철회(취소)가 불가한 콘텐츠(서비스)임을
          고지하는 등 관계법령(전자상거래법, 콘텐츠산업진흥법 등)에 따른 조치를
          취한 경우에는 회원의 청약철회권이 제한될 수 있습니다.
        </li>
        <li>
          ② 회사가 환불할 경우에 회원이 서비스이용으로부터 얻은 이익에 해당하는
          금액과 위약금 등 회사가 해당 사례에 따라 공제하기로 명시한 금액을
          공제하고 환불할 수 있습니다.
        </li>
        <li>
          ③ 회사는 환불시, 이용자가 대금을 결제한 방법과 동일한 방법으로
          결제대금의 전부 또는 일부를 환급합니다. 단, 동일한 방법으로 환급이
          불가능할 경우에 즉시 이를 이용자에게 고지하고, 이용자가 선택한
          방법으로 환급합니다.
        </li>
        <li>
          ④ 회사는 수강료 환불에 관하여 학원의 설립/운영 및 과외교습에 관한 법률
          등 관련 법률을 준수하며, 환불기준을 다음 표와 같이 정합니다.
          <p>
            <strong>- 수강료 환불기준 -</strong>
          </p>
          <ol class='policy-num'>
            <li>
              1) 수업을 제공할 수 없게 된 날 - 납부 수강료를 일할 계산한 금액
            </li>
            <li>
              2) 수강료 징수기간(수강기간)이 1개월 이내인 경우
              <ul class='policy-bu'>
                <li>- 수업시작 전 - 납부 수강료 전액 환불</li>
                <li>
                  - 총 수업 시간의 1/3이 지나기 전 - 납부 수강료의 2/3 해당액
                  환불
                </li>
                <li>- 총 수업 시간의 1/2이 지난 후 - 환불하지 아니함</li>
              </ul>
            </li>
            <li>
              3) 수강료 징수기간(수강기간)이 1개월을 초과하는 경우
              <ul class='policy-bu'>
                <li>- 수업시작 전 - 납부 수강료 전액 환불</li>
                <li>
                  - 수업시간 후 - 환불사유가 발생한 그 달의 환불대상
                  수강료(수강료 징수기간이 1개월 이내인 경우에 준하여 산출된
                  수강료)와 나머지 달의 수강료 전액을 합한 금액 환불 <br />
                  단, 온라인강의 상품의 경우 교습내용을 실제 수강한(PMP 다운로드
                  포함) 부분만큼(수강한 강의수 / 전체 강의수) 공제한 금액을
                  반환할 수 있다.
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          ⑤ 회원이 서비스 이용계약을 취소할 경우 해당 이용계약에 따라
          적립/지급된 포인트, 캐쉬, 날개는 차감되며, 회사의 유료 서비스 결제 시
          해당 사이트에서 통용되는 사이버머니를 사용한 경우에는 환불금액에서
          해당 사이버머니는 제외됩니다. 다만 현금으로 구매한 캐쉬의 경우 현금
          또는 캐쉬로 환불합니다.
        </li>
        <li>
          ⑥ 회사가 부득이한 사정으로 서비스를 지속할 수 없게 되는 경우 회사는
          이를 회원에게 공지한 후 유료 서비스 이용 회원에게 최대한 신속하게
          환불하여 드립니다.
        </li>
        <li>
          ⑦ 서비스 이용 중인 콘텐츠를 다른 콘텐츠로 변경하고자 하는 경우 제1항
          등 청약철회 또는 취소의 방법으로 환불처리 한 후 재신청하여야 하며,
          직접 변경은 할 수 없습니다.
        </li>
        <li>
          ⑧ 이용자가 체결한 구매계약에 의해서 제공되는 상품(서비스)가
          콘텐츠(동영상 등)의 학습비와 재화(도서,교재,학습기기 등)가 포함되었을
          경우, 각각 구분하여 학습비 반환 및 환급 기준을 적용합니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제26조 [과오금의 환급]</p>
      <ol class='policy-num'>
        <li>
          ① 회사의 귀책사유로 과오금이 발생한 경우 회사는 과오금 전액을
          환급합니다. 단, 이용자의 귀책사유로 과오금이 발생한 경우 회사는
          환급하는데 소요되는 비용을 합리적인 범위내에서 공제하고 환급할 수
          있습니다.
        </li>
        <li>
          ② 회사는 환불시, 이용자가 대금을 결제한 방법과 동일한 방법으로
          결제대금의 전부 또는 일부를 환급합니다. 단, 동일한 방법으로 환급이
          불가능할 경우에 즉시 이를 이용자에게 고지하고, 이용자가 선택한
          방법으로 환급합니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제27조 [회사의 계약해제/해지 및 이용제한]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 이용자가 제13조 2항 및 제16조 1항에서 정한 행위를 하였을 경우
          사전통지 없이 계약을 해제/해지하거나 또는 기간을 정하여 서비스이용을
          제한할 수 있습니다.
        </li>
        <li>
          ② 제1항의 해제/해지는 회사가 자신이 정한 통지방법에 따라 이용자에게 그
          의사를 표시한 때에 효력이 발생합니다.
        </li>
        <li>
          ③ 이용자의 귀책사유에 따른 이용계약의 해제/해지의 경우 제25조의
          환불규정을 적용하지 않을 수 있습니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제28조 [서비스 하자 등에 대한 피해보상]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 서비스의 중지/장애가 발생하는 경우 서비스 중지/장애 발생
          시점을 기준으로 24시간 이내에 이용자에게 사전고지합니다. 다만,
          이용자의 책임있는 사유로 인하여 서비스가 중지되거나 장애가 발생한 경우
          서비스 중지/장애 시간에 포함하지 않습니다.
        </li>
        <li>
          ② 회사가 서비스의 중지/장애에 대하여 사전고지한 경우에 있어서 회사는
          다음 각 호에 의해 이용자의 피해를 구제합니다. 다만, 서비스 개선을
          목적으로 한 설비 점검 및 보수시 1개월을 기준으로 24시간은
          중지/장애시간에 포함하지 않습니다.
          <ol class='policy-num'>
            <li>
              1. 1개월을 기준으로 서비스 중지/장애시간이 10시간을 초과하는 경우
              : 10시간과 이를 초과한 시간의 2배의 시간만큼 이용기간을 무료로
              연장
            </li>
            <li>
              2. 1개월을 기준으로 서비스 중지/장애시간이 10시간을 초과하지 않는
              경우 : 중지/장애시간에 해당하는 시간을 무료로 연장
            </li>
          </ol>
        </li>
        <li>
          ③ 회사가 서비스의 중지/장애에 대하여 사전고지 하지 않은 경우에 있어서
          회사는 다음 각 호에 의해 이용자의 피해를 구제합니다.
          <ol class='policy-num'>
            <li>
              1. 1개월 동안의 서비스 중지/장애 발생 누적시간이 72시간을 초과한
              경우 : 계약해제 또는 해지 및 미이용기간을 포함한 잔여기간에 대한
              이용대금 환불 및 손해배상(단, 회사가 고의 또는 과실없음을 입증한
              경우 손해배상 책임을 지지 않음)
            </li>
            <li>
              2. 회사의 책임있는 사유로 인한 서비스 중지 또는 장애의 경우 :
              서비스 중지/장애시간의 3배를 무료로 연장
            </li>
            <li>
              3. 불가항력 또는 제3자의 불법행위 등으로 인해 서비스의 중지 또는
              장애가 발생한 경우 : 계약을 해제할 수는 없지만 서비스의 중지 또는
              장애시간만큼 무료로 이용기간을 연장
            </li>
          </ol>
        </li>
      </ol>
    </div>

    <h4 class='policy-subtitle'>제5장 기 타</h4>

    <div class='policy-section'>
      <p class='policy-tit'>제29조 [면책조항]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 콘텐츠를 제공할
          수 없는 경우에는 콘텐츠 제공에 관한 책임이 면제됩니다.
        </li>
        <li>
          ② 회사는 이용자의 귀책사유로 인한 콘텐츠이용의 장애에 대하여는 책임을
          지지 않습니다.
        </li>
        <li>
          ③ 회사는 회원이 콘텐츠와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
          정확성 등의 내용에 관하여는 책임을 지지 않습니다.
        </li>
        <li>
          ④ 회사는 이용자 상호간 또는 이용자와 제3자 간에 콘텐츠를 매개로 하여
          발생한 분쟁 등에 대하여 책임을 지지 않습니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제30조 [분쟁의 해결]</p>
      <ol class='policy-num'>
        <li>
          ① 회사는 분쟁이 발생하였을 경우에 이용자가 제기하는 정당한 의견이나
          불만을 반영하여 적절하고 신속한 조치를 취합니다. 다만, 신속한 처리가
          곤란한 경우에 회사는 이용자에게 그 사유와 처리일정을 통보합니다.
        </li>
        <li>
          ② 분쟁이 발생하였을 경우 회사 또는 이용자는 콘텐츠산업진흥법
          제28조에서 정하고 있는 콘텐츠분쟁조정위원회에 분쟁조정을 신청할 수
          있습니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>제31조 [전속관할]</p>
      <ol class='policy-num'>
        <li>
          ① 회사와 이용자간의 콘텐츠계약에 관한 소의 관할은 제소 당시의 이용자의
          주소에 의하고, 주소가 없는 경우 거소를 관할하는 지방법원의 전속관할로
          합니다.
        </li>
        <li>
          ② 제소 당시 이용자의 주소 또는 거소가 분명하지 아니한 경우에는
          “민사소송법”에 따라 관할법원을 정합니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <p class='policy-tit'>부칙 (2016. 07. 28.)</p>
      <p>이 약관은 2014년 7 월 28일부터 시행합니다.</p>
    </div>
  </div>
);

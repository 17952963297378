import React, { useState } from 'react';
import Style from './styled/Count.style';
import { BsPlus } from 'react-icons/bs';
import { BiMinus } from 'react-icons/bi';

const Count = ({ count, addItem, deleteItem }) => {
  const [productCount, setProductCount] = useState(count);

  const clickAdd = () => {
    addItem();
    setProductCount((prev) => prev + 1);
  };
  const clickDelete = () => {
    deleteItem();
    setProductCount((prev) => (productCount > 0 ? prev - 1 : prev));
  };
  return (
    <Style.Container>
      <BiMinus onClick={() => clickDelete()} />
      {productCount}
      <BsPlus onClick={() => clickAdd()} />
    </Style.Container>
  );
};
export default Count;

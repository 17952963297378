import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TagLink = styled(Link).attrs(({ className }) => ({
  className: className ? 'tag-link ' + className : 'tag-link',
}))`
  display: inline-block;
  padding: 0 1.5em;
  border: 1px solid #8e9199;
  border-radius: 100px;
  font-size: 1.4em;
  line-height: 3rem;
  @media (max-width: 767px) {
    font-size: 2em;
    line-height: 2em;
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import Styled from './ClassTabListItem.style';
import ClassTabSubListItem from 'container/CourseViewPage/Aside/ClassTab/ClassTabSubListItem';

const ClassTabListItem = ({ item, currentChapterId, chapterListRef }) => {
  const { id: chapterId, title, lessonResponseList } = item;

  const containerRef = useRef(0);
  const listRef = useRef(0);

  const [isCompletedChapter, setIsCompletedChapter] = useState(false);
  useEffect(() => {
    lessonResponseList &&
      setIsCompletedChapter(
        !lessonResponseList.filter((i) => !i.completed).length
      );
  }, [lessonResponseList]);

  const [isListActive, setIsListActive] = useState(false);
  // 활성화 토글 & 스크롤 위치 이동
  useEffect(() => {
    if (currentChapterId === chapterId) {
      setIsListActive(true);
      chapterListRef.current.scrollTo(
        0,
        containerRef.current.offsetTop - chapterListRef.current.offsetTop
      );
    }
  }, [chapterId, chapterListRef, currentChapterId]);

  const toggleList = () => setIsListActive(!isListActive);

  return (
    <Styled.Container
      ref={containerRef}
      className={isListActive && 'active'}
      activeHeight={listRef.current.scrollHeight}
    >
      <Styled.ChapterName className='chapter-name' onClick={toggleList}>
        {isCompletedChapter && <Styled.ChapterCompleted />}
        {title}
      </Styled.ChapterName>

      <Styled.List className='class-tab-list' ref={listRef}>
        {lessonResponseList &&
          lessonResponseList.map((lecture, index) => (
            <ClassTabSubListItem key={index} lecture={lecture} />
          ))}
      </Styled.List>
    </Styled.Container>
  );
};

export default ClassTabListItem;

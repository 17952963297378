import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languageCode } from 'common/constant';
import { termsOfPrivacyKo } from 'container/TermsPage/contents/2019/termsOfPrivacyKo';

const Privacy20191230 = () => {
  const { i18n } = useTranslation('privacyPage');
  const { KOREAN, CHINESE, JAPANESE } = languageCode;
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!i18n?.language) return;

    switch (i18n.language) {
      case KOREAN.locale:
        setContent(termsOfPrivacyKo);
        break;
      case CHINESE.locale:
        // setContent(termsOfPrivacyZh);
        setContent(termsOfPrivacyKo);
        break;
      case JAPANESE.locale:
        // setContent(termsOfPrivacyJa);
        setContent(termsOfPrivacyKo);
        break;
      default:
        // setContent(termsOfPrivacyEn);
        setContent(termsOfPrivacyKo);
        break;
    }
  }, [i18n.language]);

  return <>{content}</>;
};

export default Privacy20191230;

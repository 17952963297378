import React, { useEffect, useState } from 'react';
import Styled from 'container/UserHomePage/styled/ActivityInsight.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';

import MyCurriculum from 'container/UserHomePage/ActivityInsight/MyCurriculum';
import CurriculumProgress from 'container/UserHomePage/ActivityInsight/CurriculumProgress';
import CurriculumProgressDetail from 'container/UserHomePage/ActivityInsight/CurriculumProgressDetail';
import Summary from './Summary';
import MockTest from 'container/UserHomePage/ActivityInsight/MockTest';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import curriculumsApi from 'api/curriculums';
import userApi from 'api/user';
import classroomApi from 'api/myClassroom';
import mockTestApi from 'api/mockTests';

import { confirmMemberGoal } from 'api/memberGoal';

import NoData from 'container/UserHomePage/ActivityInsight/NoData';
import { useTranslation } from 'react-i18next';

export default function ActivityInsight({ user }) {
  const { t } = useTranslation('userHomePage');
  const [onBordingFinish, setOnBordingFinish] = useState(false);
  const token = useSelector((state) => state.user.token);
  const myPlan = useSelector((state) => state.user.data.serviceLevel);
  const [{ data: curriculumData }] = useAxios(() =>
    curriculumsApi.getMyList(token)
  );
  const [loginDateList] = useAxios(() => userApi.getLoginDateList(token));
  const [{ data: courseCountData }] = useAxios(() =>
    classroomApi.getCourseCount(token)
  );
  const [{ data: mockTestListData }] = useAxios(() =>
    mockTestApi.getAllList(token, 3)
  );
  const [{ data: viewTimeData }] = useAxios(() =>
    classroomApi.getViewTime(token)
  );
  const [{ data: memberGoal }, getMemberGoal] = useAxios(
    confirmMemberGoal,
    [],
    true
  ); // 이전 정보 겟

  useEffect(() => {
    if (user) {
      getMemberGoal(token, user.userIdx);
    }
  }, [user]);

  useEffect(() => {
    if (!memberGoal) return;
    setOnBordingFinish(memberGoal.memberGoal.isFinish === 1);
  }, [memberGoal]);

  const curriculumChart = (noCurriculum = false) => (
    <>
      {noCurriculum ? (
        <Styled.LeftInner>
          <Styled.NoDataWrap className='curriculum'>
            <Styled.NoData>
              <Styled.NoDataText>{t('cover.text')}</Styled.NoDataText>
              <Styled.NoDataLink to='/classroom/curriculum'>
                {t('cover.button2')}
              </Styled.NoDataLink>
            </Styled.NoData>
          </Styled.NoDataWrap>
        </Styled.LeftInner>
      ) : (
        <Styled.LeftInner>
          <MyCurriculum
            progressStatus={curriculumData?.progressStatus}
            title={curriculumData?.title}
          />
          {/* <CurriculumProgress data={curriculumData} /> */}
          <CurriculumProgressDetail
            courseList={curriculumData?.curriculumCourseResponses}
          />
        </Styled.LeftInner>
      )}

      <Styled.RightInner>
        <Summary
          loginDateList={loginDateList}
          courseCount={courseCountData}
          viewTimeData={viewTimeData}
        />

        {/* <MockTest mockTestList={mockTestListData?.content} /> */}
      </Styled.RightInner>
    </>
  );

  const curriculum = () => {
    if (!curriculumData) return;
    //휴먼계정일때
    // if (
    //   myPlan === 'FREE' &&
    //   curriculumData.curriculumChargeId &&
    //   !onBordingFinish
    // ) {
    //   return (
    //     <NoData myPlan={'FREE'} close={true}>
    //       {curriculumChart()}
    //     </NoData>
    //   );
    // }

    // if (myPlan === 'FREE' && !onBordingFinish) {
    //   return <NoData myPlan={'FREE'}>{curriculumChart()}</NoData>;
    // }

    //커리컬럼 데이터 없을때
    if (!curriculumData.curriculumChargeId) return curriculumChart(true);

    //커리컬럼 데이터 있을때
    if (curriculumData.curriculumChargeId) {
      return curriculumChart();
    }
  };
  return (
    <ContentsWrapper>
      {/* <Styled.H2>{t('dashBoard.title')}</Styled.H2> */}
      <Styled.Wrapper> {curriculum()}</Styled.Wrapper>
    </ContentsWrapper>
  );
}

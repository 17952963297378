import styled from 'styled-components';
import StyledUtil from 'common/styled/Util.style';
import { NavLink } from 'react-router-dom';
import leftArrow from 'assets/images/arrow2_left_gray.svg';
import { GoCheck } from 'react-icons/go';

const Container = styled.div`
  &:not(:last-of-type) {
    border-bottom: 1px solid #1e2024;
  }
  &.active .chapter-name {
    background-color: #191b1f;
    color: #fff;
    &:after {
      transform: rotate(90deg);
    }
  }
`;

const StyledClassTabListItem = {
  Container: styled(Container)`
    &.active .class-tab-list {
      max-height: ${(props) =>
        props.activeHeight ? props.activeHeight : 1000}px;
    }
  `,
  ChapterName: styled.div`
    ${StyledUtil.textEllipsis};
    position: relative;
    padding: 2rem 5rem;
    font-size: 1.4em;
    font-weight: 500;
    color: #8e9199;
    line-height: 20px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    &:after {
      content: '';
      position: absolute;
      top: 1.5rem;
      right: 2rem;
      width: 3rem;
      height: 3rem;
      background: url(${leftArrow}) no-repeat center;
      background-size: 1.2rem;
      transform: rotate(-90deg);
      transition: all 0.2s ease;
      opacity: 0.5;
    }
  `,
  ChapterCompleted: styled.em.attrs({ children: <GoCheck /> })`
    position: absolute;
    top: 50%;
    left: 1.2rem;
    width: 2.6rem;
    height: 2.6rem;
    margin-top: -1.3rem;
    border-radius: 999px;
    border: 2px solid #8e9199;
    font-size: 1.2rem;
    text-align: center;
    color: #8e9199;
    line-height: 2.6rem;
  `,
  List: styled.div`
    max-height: 0;
    overflow: hidden;
    transition: all 0.2s ease;
  `,
  NavLink: styled(NavLink)`
    display: block;
    font-size: 1.4em;
    padding: 5px 20px;
    margin: 5px 0;
    color: #8e9199;
    &:first-child {
      margin-top: 1em;
    }
    &:last-child {
      margin-bottom: 1em;
    }
    &:hover {
      color: #fff;
    }
    &.active {
      font-weight: 400;
      color: #fff;
    }
    .progress-circle {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      font-size: 7px;
    }
  `,
  LectureName: styled.span`
    ${StyledUtil.textEllipsis};
    display: inline-block;
    max-width: calc(100% - 33px);
    vertical-align: middle;
  `,
};

export default StyledClassTabListItem;

import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import alarmIcon from 'assets/images/ico_mobileAlarm.png';
import userIcon1 from 'assets/images/ico_user_guide1.png';
import userIcon2 from 'assets/images/ico_user_guide2.png';
import userIcon3 from 'assets/images/ico_user_guide3.png';
import userIcon4 from 'assets/images/ico_user_guide4.png';

const UserGuideStyled = {
  Container: styled.div`
    height: 42em;
    position: relative;
  `,
  Swiper: styled(Swiper)`
    position: relative;
    margin: auto;
    overflow: hidden;
    padding-bottom: 9em;

    &.last-item {
      padding-bottom: 2.4em;
    }

    .swiper-wrapper {
      align-items: center;
    }

    .swiper-pagination {
      position: absolute;
      bottom: 1rem;
      left: 0;
      width: 100%;
      text-align: center;
      span {
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0 2px 0 4px;
        background-color: #0f1012;
        border-radius: 10px;
        opacity: 0.3;
        transition: all 0.6s ease;
      }
      .swiper-pagination-bullet-active {
        width: 22px;
        opacity: 1;
      }
    }

    [class*='slide-item'] {
      text-align: center;
      padding-top: 20em;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 18em;
    }

    .slide-item1 {
      background-image: url(${userIcon1});
    }

    .slide-item2 {
      background-image: url(${userIcon2});
    }

    .slide-item3 {
      background-image: url(${userIcon3});
    }

    .slide-item4 {
      background-image: url(${userIcon4});
    }
  `,
  Point: styled.span`
    display: inline-block;
    text-align: center;
    font-size: 1.3em;
    color: #fff;
    line-height: 2.154em;
    padding: 0 1em;
    background-color: #485cc7;
    border-radius: 50rem;
  `,
  SlideTitle: styled.p`
    font-size: 2.7em;
    font-weight: 700;
    text-align: center;
  `,
  SlideText: styled.p`
    color: #5c5f66;
    font-size: 1.3em;
  `,
  Button: styled.button`
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: calc(100% + 40px);
    height: 50px;
    margin: 0 -20px -20px;
    background-color: #f2f2f2;
    border-radius: 0 0 6px 6px;
    color: #999999;
    font-size: 1.2em;
  `,
};

export default UserGuideStyled;

const videoClassStoreType = {
  Add_Video: 'Add_Video',
  Delete_Video: 'Delete_Video',
  Delete_All: 'Delete_All',
  Add_Coupon: 'Add_Coupon',
  Add_Application: 'Add_Application',
  Delete_Application: 'Delete_Application',
};

export default videoClassStoreType;

import React from 'react';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import Styled from 'container/UserHomePage/styled/Recommend.style';
import StyledUserHomePage from 'container/UserHomePage/index.style';
import coursesApi from 'api/courses';
import themesApi from 'api/theme';
import RecommendCourses from 'container/UserHomePage/RecommendCourses';
import RecommendThemes from 'container/UserHomePage/RecommendThemes';

export default function Recommend() {
  const token = useSelector((state) => state.user.token);
  const courseListState = useAxios(coursesApi.getMyPageRecommend, [], true);
  const themeListState = useAxios(themesApi.getMyPageRecommends, [], true);
  return (
    <Styled.Wrapper>
      <StyledUserHomePage.Wrapper>
        {
          //<Styled.H2>Master Topik Recommend</Styled.H2>
        }
        {
          //<RecommendCourses initialState={courseListState} />
        }

        <RecommendThemes initialState={themeListState} />
      </StyledUserHomePage.Wrapper>
    </Styled.Wrapper>
  );
}

import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/terms';

/**
 * getTerm Terms 약관 가져오기
 * getPrivacy privacy 약관 가져오기
 *
 */
const termApi = {
  getTerm: async () => {
    let termUrl = url + '/use';
    let response = await asyncApi({ url: termUrl });
    return response.data;
  },
  getPrivacy: async () => {
    let termUrl = url + '/handling';
    let response = await asyncApi({ url: termUrl });
    return response.data;
  },
};

export default termApi;

import React from 'react';
import CreateTheme from 'container/UserBookmarkPage/Theme/CreateTheme';
import { useAxios } from 'common/hook/useAxiosHook';
import themesApi from 'api/theme';
import { useSelector } from 'react-redux';
import MyThemeList from 'container/UserBookmarkPage/Theme/MyThemeList';
import InterestedTheme from 'container/UserBookmarkPage/Theme/InterestedTheme';
import PopularTheme from 'container/UserBookmarkPage/Theme/PopularTheme';
import RecommendThemes from 'container/UserBookmarkPage/Theme/RecommendTheme';
import H3 from 'component/atoms/H3';
import styled from 'styled-components';
import Styled from 'container/UserBookmarkPage/styled/UserBookmark.style';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';

const StyledH3 = styled(H3)`
  margin-bottom: 1.5em;
`;

export default function Theme() {
const { t } = useTranslation('bookmarkPage');
  const token = useSelector((state) => state.user.token);
  const myThemeState = useAxios(themesApi.getMyList, [], true);
  const interestedThemeState = useAxios(
    themesApi.getInterestedThemeList,
    [],
    true
  );
  const likedPopularState = useAxios(themesApi.getLikedPopularList, [], true);
  const themeCategoryState = useAxios(themesApi.getRecommendList, [], true);

  return (
    <>
      <Styled.Wrapper>
        <StyledH3>{t('themeTab.title')}</StyledH3>
        <CreateTheme />
        <MyThemeList initialState={myThemeState} token={token} />
        <InterestedTheme initialState={interestedThemeState} token={token} />
        <PopularTheme initialState={likedPopularState} token={token} />
      </Styled.Wrapper>
      <Styled.RecommendContainer>
        <Styled.Wrapper>
          <RecommendThemes initialState={themeCategoryState} token={token} />
        </Styled.Wrapper>
      </Styled.RecommendContainer>
    </>
  );
}

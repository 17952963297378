import styled from 'styled-components';
import { css } from 'styled-components';
// import { Link } from 'react-router-dom';
import { AiOutlineCalendar } from 'react-icons/ai';
import { GrView } from 'react-icons/gr';
import { RiDownloadLine } from 'react-icons/ri';
import Img from 'component/atoms/Img';
import icoPdf from 'assets/images/ico_file_pdf.png';
import icoExcel from 'assets/images/ico_file_excel.png';
import icoMp3 from 'assets/images/ico_file_mp3.png';
import icoImg from 'assets/images/ico_file_img.png';
import icoWord from 'assets/images/ico_file_word.png';
import icoOther from 'assets/images/ico_file_other.png';
import List from './List.style';

const prevNext = css`
  position: relative;
  width: 8.750em;
  padding-left: 2.750em;
  font-size: 1.6em;
  font-weight: 500;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 1em;
    width: 0.5em;
    height: 0.5em;
    border-top: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
  }

  @media (max-width: 767px) {
    padding-left: 2em;
    width: 7em;

    &::before {
      left: 0;
    }
  }

  @media (max-width: 375px) {
    padding-left: 1.5em;
    width: 6em;
  }
`;

export const Detail = {
  Board: styled.div`
    margin: 1em 0 0 0;
  `,
  Header: styled.div`
    padding: 3em 0;
    border-bottom: 1px solid #2b2e35;
  `,
  Title: styled.div`
    font-size: 3.2em;
    font-weight: 500;
  `,
  Info: styled.div`
    position: relative;
    display: flex;
    margin-top: 1em;

    > * {
      font-size: 1.6em;
      color:#8b8f99;

      svg {
        margin-right: 0.5em;
      }

      * {
        vertical-align: middle;
      }
    }
  `,
  Date: styled.div.attrs(({ children }) => ({
    children: (
      <>
        <AiOutlineCalendar />
        <span>{children}</span>
      </>
    ),
  }))`
    margin-right: 1em;
  `,
  Views: styled.div.attrs(({ children }) => ({
    children: (
      <>
        <GrView />
        <span>{children}</span>
      </>
    ),
  }))`
    svg path{
      stroke: #8b8f99;
    }
  `,
  Container: styled.div`
    padding: 7em 23em;
    border-bottom: 1px solid #2b2e35;

    @media (max-width: 1024px) {
      padding: 5em;
    }

    @media (max-width: 767px) {
      padding: 5em 0;
    }
  `,
  Content: styled.div`
    font-size: 1.8em;

    img {
      display: block;
      max-width: 100%;
    }

    white-space: pre-wrap;
  `,
  Img: styled(Img)`
    display: block;
    width: 100%;
  `,
  FileDownArea: styled.div`
    margin: 4em -0.5em 0;
  `,
  FileDown: styled.button`
    position: relative;
    display: inline-block;
    margin: 0.313em;
    padding: 1.125em 1.750em;
    border: 1px solid #5c5f66;
    border-radius: 0.375em;
    color: #ffb500;
    font-size: 1.6em;
    font-weight: 500;
    text-align: left;

    &:hover::before,
    &:hover::after {
      content: '';
      position: absolute;
    }

    &:hover::before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      border-radius: 0.6em;
    }

    &:hover::after {
      top: 50%;
      left: 50%;
      width: 1.500em;
      height: 1.750em;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transform: translate(-50%, -50%);
    }

    &.pdf:hover::after {
      background-image: url(${icoPdf});
    }
    &.excel:hover::after {
      background-image: url(${icoExcel});
    }
    &.mp3:hover::after {
      background-image: url(${icoMp3});
    }
    &.img:hover::after {
      background-image: url(${icoImg});
    }
    &.word:hover::after {
      background-image: url(${icoWord});
    }
    &.other:hover::after {
      background-image: url(${icoOther});
    }
  `,
  FileName: styled.span.attrs(({ children }) => ({
    children: (
      <>
        <RiDownloadLine />
        {children}
      </>
    ),
  }))`
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 11em;

    svg {
      margin-right: 0.750em;
      vertical-align: top;
    }

    + span {
      display: block;
      margin-top: 0.571em;
      margin-left: 2em;
      color: #8b8f99;
      font-size: 0.875em;
      font-weight: 300;
    }
  `,
  List: styled(List.List)`
    margin-top: 0;
  `,
  Item: styled(List.Item)`
    background-color: transparent;
    border-radius: 0;
    padding: 2.5em 1.5em;
    margin-top: 0em;
    border-bottom: 1px solid #2b2e35;

    @media (max-width: 767px) {
      padding: 2em 1.5em;
    }
  `,
  Prev: styled.div`
    ${prevNext}

    &::before {
      transform: translateY(calc(-50% + 0.250em)) rotate(45deg);
    }
  `,
  Next: styled.div`
    ${prevNext}

    &::before {
      transform: translateY(calc(-50% - 0.125em)) rotate(-135deg);
    }
  `,
  ListIcon: styled(List.Icon)`

    @media (max-width: 767px) {
      width: 3em;
    }
  `,
  ListCategory: styled(List.Category)`
  `,
  ListTitle: styled(List.Title)`
    @media (max-width: 767px) {
      font-size: 2em;
    }
  `,
  ListTitleLink: styled(List.TitleLink)`
  `,
  ListDate: styled(List.Date)`

    @media (max-width: 767px) {
      display: none;
    }
  `,
}

export default Detail;

import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Theme from 'container/UserBookmarkPage/Theme';
import Bookmark from 'container/UserBookmarkPage/Bookmark';
import Styled from 'container/UserBookmarkPage/styled/UserBookmark.style';

export default function UserBookmarkRoutes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/theme`} component={Theme} />
      <Styled.Wrapper>
        <Route exact path={path} component={Bookmark} />
      </Styled.Wrapper>
    </Switch>
  );
}

import styled from 'styled-components';
import StyledForm from 'component/form/ModalForm.style';

const StyledSelect = styled(StyledForm.Select)`
  width: 10em;
  font-size: 1.6rem;
  .select__control {
    min-height: 25px;
    background-color: #2b2e35;
  }
  > .select__menu,
  .select__option,
  .select__menu-list {
    background-color: #2b2e35;
  }
  .select__option:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  .css-b8ldur-Input {
    color: #c7cad1;
  }
  .select__indicator {
    svg {
      color: #8b8f99;
    }
  }
  .select__placeholder,
  .select__single-value {
    color: #c7cad1;
    font-weight: 400;
  }
  @media (max-width: 767px) {
    font-size: 2em;
  }
`;

export default StyledSelect;
import React, { useContext, useState } from 'react';
import Style from 'container/MockTestPage/styled/TestWritePaper.style';
import FileUploadContainer from '../WritePage/FileUploadContainer';
import { MockTestContext } from '../index';

const Input = ({ data, refreshGetData }) => {
  const { userAnswer, unitValuationId } = data;
  const { mockTestStore, setMockTestStore } = useContext(MockTestContext);
  const [inputText, setInputText] = useState(userAnswer ? userAnswer : '');

  const answerOnChange = (e) => {
    const index = mockTestStore.mockTestAnswerRequest.findIndex(
      (answer) => answer.unitValuationId === unitValuationId
    );

    if (index === -1) {
      setMockTestStore((prev) => ({
        ...prev,
        mockTestAnswerRequest: [
          ...prev.mockTestAnswerRequest,
          { unitValuationId: unitValuationId, answerStr: e.target.value },
        ],
      }));
    } else {
      const newData = { ...mockTestStore };
      newData.mockTestAnswerRequest[index]['answerStr'] = e.target.value;
      setMockTestStore(newData);
    }
    setInputText(e.target.value);
  };

  return (
    <Style.AnswerInputContainer>
      <p>답안 작성</p>
      <textarea
        cols='30'
        rows='10'
        onChange={answerOnChange}
        value={inputText}
      ></textarea>
      <FileUploadContainer data={data} />
    </Style.AnswerInputContainer>
  );
};

export default Input;

import styled from 'styled-components';

const LayoutStyle = {
  DetailWrapper: styled.div`
    margin: 4.5em 0 14em;
    border-top: 1px solid #202227;
    padding-top: 4em;
    .Contents::after {
      content: '';
      display: block;
      clear: both;
    }
    @media (max-width: 1024px) {
      margin-top: 0;
      border-top: none;
      padding-top: 0;
    }
  `,

  DetailLnb: styled.div`
    float: left;
    width: 280px;
    margin-top: 6.5em;

    @media (max-width: 1024px) {
      float: none;
      width: 100%;
      margin-top: 2em;
    }
  `,

  DetailContent: styled.div`
    float: right;
    width: calc(100% - 280px - 6em);

    @media (max-width: 1024px) {
      float: none;
      width: 100%;
      margin-top: 3em;
    }
  `,

  SearchResult: styled.div`
    height: 6.5em;
    p {
      font-size: 2.6em;
      strong {
        font-weight: 700;
      }
    }
  `,

  SearchWordWrap: styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 2em;
    gap: 0.8em;
  `,

  SearchWord: styled.button`
    padding: 0.5em 1.2em;
    border: 1px solid #8e9199;
    border-radius: 2em;
    font-weight: 300;
    font-size: 1.6em;
    color: #8e9199;
  `,
};

export default LayoutStyle;

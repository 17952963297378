import React, { useState } from 'react';
import Styled from './index.style';
import useLoginStatus from 'common/hook/useLoginStatus';
import { useScrollHandler } from 'common/hook/useScrollHandler';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import Nav from './Nav';
import RightMenu from './RightMenu';
import Logo from './Logo';
import MobileStickyBottom from './MobileStickyBottom';
import LineBanner from 'component/layout/Header/LineBanner';
import { useLocation } from 'react-router-dom';

/** (Mobile) Nav black background layer */
const NavCloseLayer = () => (
  <div
    className='nav-close-layer'
    onClick={() => document.body.classList.remove('nav-active')}
  ></div>
);

function Header({ scrollY }) {
  const isLogin = useLoginStatus();
  const scroll = useScrollHandler();
  const location = useLocation();


  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  if (location.pathname.startsWith("/Jresearch")) return (<></>);
  if (location.pathname.startsWith("/WbmKorea")) return (<></>);
  if (location.pathname.startsWith("/event/korean")) return (<></>);
  if (location.pathname.startsWith("/event/HdKorean")) return (<></>);
  if (location.pathname.startsWith("/MtCurriculum")) return (<></>);

  return (
    <Styled.Wrapper id='header' className={`${!scroll && 'scrolled'} ${(!isLogin &&isHovering) && 'hover'} ${(isLogin &&isHovering) && 'hover2'}  ${isLogin ?'login-head':  'logut-head'}`}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <LineBanner />

      <ContentsWrapper>
        <Logo isLogin={isLogin} isHovering={isHovering}/>

        <Nav isLogin={isLogin} handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOut}

        />

        <RightMenu isLogin={isLogin} />
      </ContentsWrapper>

      {isLogin && location.pathname !== '/event/freetrial' && location.pathname !=='/aispeak' && (
        <MobileStickyBottom />
      )}

      <NavCloseLayer />
    </Styled.Wrapper>
  );
}

export default Header;

import styled from 'styled-components';
import StyledUserHomePage from 'container/UserHomePage/index.style';

const StyledRecommend = {
  Wrapper: styled.div`
    padding-top: 5em;
    background-color: #393c40;
    .swiper-container {
      .swiper-button-prev,
      .swiper-button-next {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
    @media (max-width: 1024px) {
      .swiper-container {
        &:before {
          background-color: #393c40;
        }
      }
    }
    @media (max-width: 767px) {
      padding-top: 12em;
    }
  `,
  H2: styled.h2`
    font-size: 6em;
    margin-bottom: 1em;
    font-weight: bold;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 4em;
    }
  `,
  H3: styled(StyledUserHomePage.H2)`
    margin-top: 1.5em;
    text-align: center;
  `,
};

export default StyledRecommend;

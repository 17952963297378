import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/plans';

/**
 *
 */
const plansApi = {
  getPlan: async (token) => {
    let response = await asyncApi({
      url,
      headers: { Authorization: token && `Bearer ${token}` },
    });
    // console.log('planapi', response.data)
    return response.data;
  },
  putPlan: async (id, token) => {
    let response = await asyncApi({
      url: `${url}/${id}`,
      method: 'put',
      headers: { Authorization: token && `Bearer ${token}` },
    });
    return response.data;
  },

  findPassword: async (userID) => {
    let response = await asyncApi({
      url: `${url}/settings/reset-password-confirmation`,
      params: { userID },
    });
    return response.status;
  },
  getLoginDateList: async (token) => {
    let response = await asyncApi({
      url: `${url}/login-days`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  fetchMyPlan: async (token) => {
    let response = await asyncApi({
      url: `${url}/settings/my-plan`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  updateUserLanguage: async ({ token, language }) => {
    let response = await asyncApi({
      url: `${url}/settings/language`,
      method: 'put',
      data: { language },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status;
  },
  cancelUserPlan: async (token) => {
    const config = {
      method: 'post',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await asyncApi(config);
    return response;
  },
  //멤버쉽 철회 취소
  cancelCancelUserPlan: async (token) => {
    const config = {
      method: 'delete',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await asyncApi(config);
    return response;
  },
  updateCancelFeedback: async ({ token, data }) => {
    const config = {
      method: 'put',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    let response = await asyncApi(config);
    return response;
  },

  updateEmailNotification: async ({ token, data }) => {
    const config = {
      method: 'put',
      url: `${url}/settings/notification`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await asyncApi(config);
    return response;
  },

  fetchMyPaymentList: async (token) => {
    const config = {
      url: url + '/settings/my-payment-histories',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  fetchMyInvoice: async (token, id) => {
    const config = {
      url: `/api/v1/invoices/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await asyncApi(config);
    return response.data;
  },
  fetchMyAddress: async (token) => {
    const config = {
      url: `/api/v1/my-addresses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await asyncApi(config);
    return response.data;
  },

  deleteMyAddress: async (token, id) => {
    const data = { addressIdx: id };
    const config = {
      method: 'delete',
      url: `/api/v1/my-addresses`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    };
    const response = await asyncApi(config);
    return response.data;
  },

  postSendAuthenticationEmail: async (userID) => {
    const response = await asyncApi({
      method: 'post',
      url: `${url}/send-authentication-email`,
      data: { userID },
    });
    return response.status;
  },

  postConfirmationEmail: async (data) => {
    const response = await asyncApi({
      method: 'post',
      url: `${url}/confirmation`,
      data,
    });
    return response;
  },
  
  freeTrial: async (serviceGradeCode, token) => {
    let response = await asyncApi({
      url: `${url}/freeTrial`,
      method: 'post',
      params: {serviceGradeCode: serviceGradeCode},
      headers: { Authorization: `Bearer ${token}` },
    });
    // console.log('response.data : ',response.data);
    return response.data;
  },


};

export default plansApi;

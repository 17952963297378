import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import ArrowLink from 'component/atoms/ArrowLink';
import icoLive from 'assets/images/ico_live.png';
import icoLiveLarge from 'assets/images/ico_live_large.png';
import icoVoca from 'assets/images/ico_voca.png';
import icoStore from 'assets/images/ico_bookstore.png';
import icoStoreLarge from 'assets/images/ico_bookstore_large.png';
import Img from 'component/atoms/Img';
import StyledUtil from 'common/styled/Util.style';

const LinkItem = styled(ArrowLink)`
  float: left;
  font-size: 1.8em;
  width: calc(100% / 3 - 4rem);
  padding: 1.35em 1em 1.35em 4em;
  margin: 0 2rem;
  border-radius: 0.6rem;
  background-color: #485cc7;
  background-repeat: no-repeat;
  background-position: 2em center;
  svg {
    float: right;
    font-size: 1.2em;
    margin-top: 0.11em;
  }
  @media (max-width: 767px) {
    font-size: 2em;
    width: calc(100% / 3 - 2rem);
    padding: 3.2em 1em 1em;
    margin: 0 1rem;
    background-position: 1em 1.2em;
    svg {
      display: none;
    }
  }
`;

const StyledServiceContents = {
  Wrapper: styled(ContentsWrapper)`
    margin: 16em auto 18em;
    @media (max-width: 767px) {
      margin: 12em auto;
    }
  `,
  H2: styled.h2`
    font-size: 3.6em;
    margin-top: 14rem;
    margin-bottom: 2.5rem;
    font-weight: 500;
    @media (max-width: 767px) {
      margin-top: 8rem;
    }
  `,
  Card: {
    Wrapper: styled.div`
      border-radius: 0.6rem;
      overflow: hidden;
    `,
    ImageContainer: styled.div``,
    Image: styled(Img).attrs({ alt: 'Thumbnail' })``,
    Texts: styled.div`
      height: 12.6em;
      padding: 2em;
      background-color: #1e2024;
      @media (max-width: 767px) {
        height: 15em;
      }
    `,
    Title: styled.div`
      ${StyledUtil.lineClamp}
      font-size: 1.8em;
      font-weight: 500;
      opacity: 0.9;
      @media (max-width: 767px) {
        font-size: 2.2em;
      }
    `,
    Desc: styled.div`
      ${StyledUtil.textEllipsis}
      font-size: 1.6em;
      margin-top: 0.5em;
      color: #8e9199;
      @media (max-width: 767px) {
        font-size: 2em;
      }
    `,
  },
  LinkList: styled.div`
    width: calc(100% + 4rem);
    margin-top: 15em;
    margin-left: -2rem;
    overflow: hidden;
    @media (max-width: 767px) {
      margin-top: 7em;
    }
  `,
  LinkToLive: styled(LinkItem)`
    background-image: url(${icoLive});
    @media (max-width: 1024px) {
      background-image: url(${icoLiveLarge});
      background-size: 3.3rem;
    }
    @media (max-width: 767px) {
      background-size: 4.1rem;
    }
  `,
  LinkToVoca: styled(LinkItem)`
    background-image: url(${icoVoca});
    @media (max-width: 1024px) {
      background-size: 2.4rem;
    }
    @media (max-width: 767px) {
      background-size: 2.9rem;
    }
  `,
  LinkToStore: styled(LinkItem)`
    background-image: url(${icoStore});
    @media (max-width: 1024px) {
      background-image: url(${icoStoreLarge});
      background-size: 2rem;
    }
    @media (max-width: 767px) {
      background-size: 2.5rem;
    }
  `,
};

export default StyledServiceContents;

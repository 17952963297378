import section1_logo from 'assets/images/event/241028/section1_logo.png'
import section1_title from 'assets/images/event/241028/section1_title2.png'
import section1_bg2 from 'assets/images/event/241028/section1_bg2.png'
import React from "react";

const Section1 = () => {
    return (
        <section className='section1'>
            <div className='sectionWarp'>
                <div className='imgBox'>
                    <img src={section1_logo} alt='section1_logo'/>
                </div>
                <div className='bgc1'>
                    <h1>韩国语视频竞赛</h1>
                </div>
                <div className='imgBox'>
                    <img src={section1_title} alt='section1_title'/>
                </div>
                <div className='imgBox videoContainer'>
                    <div className='videoBox'>
                        <iframe width="100%" height="100%"
                            src="//player.bilibili.com/player.html?isOutside=true&aid=113406178885944&bvid=BV1jKS9YVE8k&cid=26559513412&p=1"
                            scrolling="no" border="0" frameBorder="no" framespacing="0" allowFullScreen="true"></iframe>
                    </div>
                    <img src={section1_bg2} alt='section1_bg2'/>
                </div>
            </div>
        </section>
    )
}

export default Section1
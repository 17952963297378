import styled from 'styled-components';

const StyledContent = {
  Container: styled.div`
    margin-bottom: 16.5em;
    .reset {
      /* all: unset; */
    }
  `,
  Title: styled.h3`
    margin-top: 2.27em;
    margin-bottom: 0.8em;
    font-size: 3.6em;
    font-weight: 700;
  `,
  SubTitle: styled.p`
    margin-bottom: 0.625em;
    font-size: 2.4em;
    font-weight: 700;
  `,

  ListContainer: styled.div`
    width: 100%;
    margin-bottom: 3.7em;
    padding: 0 4em;
    border-radius: 0.6em;
    background-color: #1e2024;
    @media (max-width: 767px) {
      padding: 0 3em;
    }
  `,
};

export default StyledContent;

export const API_HOST = process.env.REACT_APP_API_HOST;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const OAUTH2_REDIRECT_URI = API_HOST + '/auth/redirect';
export const GOOGLE_AUTH_URL =
  API_HOST + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL =
  API_HOST + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const WECHAT_AUTH_URL = API_HOST + '/api/v1/wechat/login';

export const FetchStatus = {
  Request: 'Request',
  Success: 'Success',
  Fail: 'Fail',
};

export const UserStatus = {
  Auth: 'Auth',
  Login: 'Login',
  NotLogin: 'NotLogin',
  LoginFailed: 'LoginFailed',
  NotRegistered: 'NotRegistered' //App Facebook login
};

export const masterTopikUrl = BASE_URL ? BASE_URL : '';

export const planColors = {
  FREE: { color: '#E26B13', desc: 'free' },
  EPS: { color: '#FFB500', desc: 'eps' },
  STANDARD: { color: '#69BD18', desc: 'standard' },
  PREMIUM: { color: '#485CC7', desc: 'premium' },
};

export const SiteMapData = {
  course: '/courses',
  topik: '/courses/topik',
  korean: '/courses/korean',
  category: '/courses/category',
  search: '/library',
  teachers: '/teachers',

  mock: '/classroom/topik-mock-test',
  aispeak: '/aispeak',

  plan: '/plan',
  free: '/event/freetrial',
  yearly: '/event/monthpass',

  resources: '/resources',
  learningMaterial: '/learning-material',
  level: '/level-test',
  bookstore: '/bookstore',
  audio: '/audio',
  vocaCard: '/voca-card',
  help: '/help',
  helpEvent: '/help/event',

  setting: '/setting',
  privacy: '/terms/terms-of-privacy',
  terms: '/terms/terms-of-service',
  visang: '//www.visang.com/',
  contact: 'https://mastertopik.channel.io/',

  library: '/library',

  classroom: '/classroom',
  curriculum: '/classroom/curriculum',
  bookmark: '/bookmark',

  mock2: '/classroom/topik-mock-test',
  grade: '/test-report',

  profile: '/profile',
  aiSpeak: '//ai.masterkorea.com:8091/connect/site',
  aiSpeakIntro: '/aispeak',
  aliveClass: '/alive',
  externalAliveClass: '//mtalive.masterk1.com/',
  community: '/community',
  mtLive: '/mt-live',
  bookstore: '/bookstore',
  setting: '/setting',
  about: '/about',
  mtAlive: '/mt-alive',
  mockTest: '/courses/topik-mock-test',

  notification: '/notification',




  googlePlayStore: '//play.google.com/store/apps/details?id=com.mastertopik',
  masterK: '#none',

  facebook: '//www.facebook.com/mtopik2015',
  youtube: '//www.youtube.com/c/mastertopik',
  youku: '//i.youku.com/mastertopik',
  instagram: '//www.instagram.com/visang_mastertopik',
};

export const languageCode = {
  KOREAN: { code: 'ko', locale: 'ko-KR' },
  ENGLISH: { code: 'en', locale: 'en-US' },
  CHINESE: { code: 'cn', locale: 'zh-CN' },
  JAPANESE: { code: 'jp', locale: 'ja-JP' },
};

export const CURRENCY = {
  DOMESTIC: 'KRW',
  OVERSEA: 'USD',
};

export const planKeyColor = {
  FREE: '#e26b13',
  EPS: '#ffb500',
  STANDARD: '#69bd18',
  PREMIUM: '#485cc7',
};

export const PAYMENT_METHOD = {
  DOMESTIC_CREDIT: 'creditcard',
  OVERSEA_CREDIT_PLAN: 'PLCreditCardMpi',
  OVERSEA_CREDIT: 'PLCreditCard', //카드 유형 테스트 중.
  PAYPAL: 'PayPalExpressCheckout',
  PAYPAL_SUBSCRIPTION: 'PayPalSubscription',
  UNION_PAY: 'PLUnionPay',
};

export const MOBILE_APP_USER_AGENT =
  'Mozilla/5.0 (Linux; Android 4.1.1; Galaxy Nexus Build/JRO03C) AppleWebKit/535.19 (KHTML, like Gecko) Chrome/18.0.1025.166 Mobile Safari/535.19';

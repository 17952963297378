import React, { useContext, useEffect, useMemo } from "react";
import Styled from "./Modal.style";
import StyledModal from "component/Modal/styled/Modal.style";
import StyledForm from "component/form/Form.style";
import { ModalContext } from "component/Modal/modalContext";
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions } from "component/user/state";
import Moment from "react-moment";
import { CURRENCY } from "common/constant";
import { formatNumber } from "common/util/util";
import { useTranslation, Trans } from "react-i18next";

const ChangeCompleteModal = ({ selectedPlan, getPlan, handleFetchPlan }) => {
  const { t } = useTranslation("planPage");

  const { handleModal } = useContext(ModalContext);
  const selectedPlanName = selectedPlan.planCode;
  const currentCurrency = useSelector((state) => state.common.currency);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  const currentPlan = useMemo(()=> getPlan.plans.find(
    (plan) => plan.productId === getPlan.currentPlanProductId
  ),[selectedPlan, getPlan]);

  useEffect(() => {
    dispatch(userActions.fetchUser(token));
    handleFetchPlan();
  }, []);

  const WebInterfaceHandleModal = () => {
    handleFetchPlan();
    handleModal();
  };

  useEffect(() => {
    window.addEventListener("webBridge", async (e) => {
      console.log(e.detail);
      WebInterfaceHandleModal();
    });
  }, [window.dispatchEvent]);

  return (
    <Styled.Container>
      {selectedPlan.upgradable && selectedPlan.productId === 1147 && (
        <StyledModal.H2>{t("Modal.upgradeConfirm.title")}</StyledModal.H2>
      )}
      {(selectedPlan.downgradable || selectedPlan.upgradable && selectedPlan.productId !== 1147) && (
        <StyledModal.H2>{t("Modal.downgradeConfirm.title")}</StyledModal.H2>
      )}
      <Styled.ChangePlan>
        {/* <Styled.CurrentPlan className={getPlan.currentPlan.toLowerCase()}>
          {getPlan.currentPlan}
        </Styled.CurrentPlan> */}
        <Styled.CurrentPlanP className={getPlan.currentPlan.toLowerCase()}>
          <span>{getPlan.currentPlan}</span>
          <Styled.PlanSpan>
            ({currentPlan.takeCourseTerm}{
              currentPlan.takeCourseTerm === 1
              ? t('planCard.month') : t('planCard.months')
            })
          </Styled.PlanSpan>
        </Styled.CurrentPlanP>
        <Styled.Arrow />
        {/* <Styled.NewPlan className={selectedPlan.planCode.toLowerCase()}>
          {selectedPlanName}
        </Styled.NewPlan> */}
        <Styled.NewPlanP className={selectedPlan.planCode.toLowerCase()}>
          <span>{selectedPlan.planCode}</span>
          <Styled.PlanSpan>
            ({selectedPlan.takeCourseTerm}{
              selectedPlan.takeCourseTerm === 1
              ? t('planCard.month') : t('planCard.months')
            })
          </Styled.PlanSpan>
        </Styled.NewPlanP>
      </Styled.ChangePlan>
      {selectedPlan.upgradable && selectedPlan.productId === 1147 && (
        <Styled.Text className={selectedPlan.planCode.toLowerCase()}>
          <Trans
            t={t}
            values={{
              plan: selectedPlan.planCode,
              planMonth: selectedPlan.takeCourseTerm
            }}
          >
            {selectedPlan.takeCourseTerm === 1
              ? 'Modal.upgradeConfirm.desc'
              : 'Modal.upgradeConfirm.desc_months'
            }
          </Trans>
        </Styled.Text>
      )}
      {(selectedPlan.downgradable || selectedPlan.upgradable && selectedPlan.productId !== 1147) && (
        <Styled.Text>
          <Trans
            t={t}
            values={{
              plan: selectedPlan.planCode,
              date: getPlan.nextBeginDate.slice(0, 10),
              planMonth: selectedPlan.takeCourseTerm
            }}
          >
            {selectedPlan.takeCourseTerm === 1
              ? 'Modal.downgradeConfirm.desc'
              : 'Modal.downgradeConfirm.desc_months'
            }
          </Trans>
        </Styled.Text>
      )}
      <Styled.ButtonGroup>
        <StyledForm.ButtonLink to="/classroom" onClick={() => handleModal()}>
          {t("Modal.upgradeConfirm.button")}
        </StyledForm.ButtonLink>
      </Styled.ButtonGroup>
    </Styled.Container>
  );
};

export default ChangeCompleteModal;

import styled from 'styled-components';
import gradientBottom from 'assets/images/gradient_bottom02.png';
import imgUserNav from 'assets/images/img_user_nav.png';

const DefaultButton = styled.button.attrs({ type: 'button' })`
  width: 4rem;
  height: 4rem;
  font-size: 2.2em;
  color: #fff;
  vertical-align: middle;
  opacity: 0.7;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const StyledPlayerControllerMobile = {
  Wrapper: styled.div`
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &.active {
      .player-controller {
        bottom: 0;
        opacity: 1;
      }
      .play-toggle-overlay div {
        opacity: 1;
      }
    }
    @media (max-width: 1024px) {
      display: block;
    }
  `,
  Container: styled.div`
    position: absolute;
    bottom: -4em;
    left: 0;
    z-index: 10;
    font-size: 1rem;
    width: 100%;
    height: 8em;
    padding: 2em 2em 0;
    background: url(${gradientBottom}) repeat-x center bottom;
    background-size: auto 100%;
    opacity: 0;
    transition: all 0.3s ease;
    .progress-slider {
      margin-bottom: -0.3em;
    }
    @media (max-width: 1024px) {
      font-size: 1.4rem;
    }
    @media (max-width: 375px) {
      font-size: 1.6rem;
      padding: 2em 0.5em 0;
    }
  `,
  LeftSide: styled.div`
    float: left;
    > * {
      margin-right: 5px;
    }
  `,
  RightSide: styled.div`
    float: right;
    > * {
      margin-left: 5px;
    }
  `,
  Button: styled(DefaultButton)``,
  Playing: styled(DefaultButton)`
    font-size: 3em;
  `,
  VolumeContainer: styled.span`
    position: relative;
    display: inline-block;
    width: 4.5em;
    transition: width 0.2s ease;
    overflow: hidden;
    vertical-align: middle;
    &:active,
    &:hover {
      width: 16em;
      .volume-slider {
        opacity: 1;
      }
    }
    .volume-slider {
      position: absolute !important;
      top: 0.9em;
      left: 4.5em;
      width: 10em;
      vertical-align: middle;
      opacity: 0;
      transition: all 0.2s ease;
      .progress-thumb-0,
      .progress-track-0 {
        background-color: #fff;
      }
    }
  `,
  Volume: styled(DefaultButton)`
    font-size: 2em;
  `,
  TimerContainer: styled.div`
    display: inline-block;
    font-size: 1.4em;
    margin-bottom: 0.2em;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
    vertical-align: middle;
    letter-spacing: 1px;
    font-weight: 200;
    opacity: 0.9;
  `,
  SelectWrapper: styled.div`
    position: relative;
    z-index: 500;
    display: inline-block;
    margin-bottom: 0.2em;
    vertical-align: middle;
  `,
  SelectButton: styled.button.attrs({ type: 'button' })`
    min-width: 4em;
    height: 2em;
    padding: 0 0.5em;
    border-radius: 0.4rem;
    background-color: #fff;
    font-size: 1.2em;
    font-weight: 600;
    color: #333;
    opacity: 0.7;
    cursor: pointer;
    &:hover,
    &.active {
      opacity: 0.9;
    }
  `,
  SelectList: styled.div`
    display: none;
    position: absolute;
    bottom: calc(100% + 1em);
    right: 0;
    width: 10em;
    box-shadow: 5px 5px 10px rgb(15 16 18 / 30%);

    &:before {
      content: '';
      position: absolute;
      bottom: -1.5em;
      right: 1em;
      width: 2em;
      height: 2em;
      background: url(${imgUserNav}) no-repeat 95% center;
      background-size: auto 1.8em;
      transform: rotate(180deg);
    }

    &.active {
      display: block;
    }
  `,
  SelectListInner: styled.div`
    border-radius: 0.6em;
    background-color: #2b2e35;
    overflow: hidden;
  `,
  SelectItem: styled.div`
    position: relative;
    display: block;
    width: 100%;
    padding: 0.7rem 1rem 0.7rem 3rem;
    font-size: 1.4em;
    font-weight: 400;
    text-align: left;
    color: #c7cad1;
    cursor: pointer;
    svg {
      position: absolute;
      top: 50%;
      left: 0.8em;
      margin-top: -0.5em;
      color: #c7cad1;
    }
    &:hover {
      background-color: #1e2024;
    }
    @media (max-width: 1024px) {
      svg {
        left: 0.5em;
      }
    }
  `,
  PlayToggleOverlay: styled.div`
    transition: all 0.3s ease;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      width: 100%;
      height: 100%;
    }
    div {
      position: absolute;
      top: 51%;
      left: 50%;
      z-index: 9;
      width: 1em;
      font-size: 10rem;
      margin: -0.5em 0 0 -0.5em;
      text-align: center;
      color: #ffbb00;
      line-height: 1;
      opacity: 0;
      transition: all 0.3s ease;
      cursor: pointer;
    }
    &.pause {
      background-color: rgba(0, 0, 0, 0.2);
      div {
        top: 50%;
        opacity: 1;
      }
    }
    div:hover {
      top: 49%;
    }
    div:active {
      top: 50%;
    }
  `,
};

export default StyledPlayerControllerMobile;

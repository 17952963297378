import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Style from './styled/AudioSection.style';
import DetailList from './List';

const ListContainer = ({ audioInfo }) => {
  const { t } = useTranslation('audioPage');

  const [listShowIndex, setListShowIndex] = useState(-1);

  const openAccordion = (index) => {
    setListShowIndex((prev) => (prev === index ? -1 : index));
  };

  return (
    <Style.ListContainer>
      <h2>{t('Detail.stitle3')}</h2>
      {audioInfo && audioInfo.levelChapterGroup.map((item, index) => {
        return (
          <DetailList
            key={index}
            audioChapter={item}
            onClick={() => openAccordion(index)}
            show={listShowIndex === index && 'active'}
          />
        );
      })}
    </Style.ListContainer>
  );
};

export default ListContainer;

import React, { useEffect, useState } from 'react';
import Styled from './index.style';
import { openLink } from 'common/util/util';
import btnApplyImg from 'assets/images/event/220728/btn_apply.png';
import btnApplyImg2 from 'assets/images/event/220728/btn_apply2.gif';
import reviewImg from 'assets/images/event/220728/review.jpg';
import reviewImg2 from 'assets/images/event/220728/review2.jpg';
import reviewImg3 from 'assets/images/event/220728/review3.jpg';
// import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
// import { addItem, deleteAllItem } from "container/PaymentPage/state/action";
// import { useAxios } from 'common/hook/useAxiosHook';
// import useLoginStatus from "common/hook/useLoginStatus";
// import productApi from 'api/product';
// import { ModalContext } from "component/Modal/modalContext";
// import Login from "component/user/Login";
// import ConfirmModal from "container/EventPage/ConfirmModal";
// import userApi from 'api/user';
import { MOBILE_APP_USER_AGENT } from "common/constant";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from 'swiper';

SwiperCore.use([Navigation, Pagination]);

const EventPage = () => {
	// const language = localStorage.getItem('i18nextLng');

	// const token = useSelector((state) => state.user.token);
	// const isLogin = useLoginStatus();
	// let { handleModal } = React.useContext(ModalContext);
	// const location = useLocation();
	// const history = useHistory();
	// let dispatch = useDispatch();
	// const [vcProductId, setVCProductId] = React.useState(-1);

	// const [
	// 	{ loading: productLoading, data: productData },
	// 	fetchProduct,
	// ] = useAxios(productApi.getVideoClassProduct, [], true);
	// const [
	// 	{ data: myVCData },
	// 	fetchMyVCPaymentList
	// ] = useAxios(() => userApi.fetchMyPaymentListByItemType('VIDEOCLASS', token), [], true);

	// useEffect(() => {
	// 	if(productData){
	// 		const name = (language) => {
	// 			switch(language){
	// 				case 'ko-KR':
	// 					return productData.title;
	// 				case 'en-US':
	// 					return productData.titleEN;
	// 				case 'zh-CN':
	// 					return productData.titleCN;
	// 				case 'ja-JP':
	// 					return productData.titleJP;
	// 				default:
	// 					return productData.title;
	// 			};
	// 		}

	// 		let videoItem = {
	// 			id: productData.productId, //data.id,
	// 			type: 'Video Class',
	// 			name: name(language),
	// 			price: productData.salesStoreWon,
	// 			priceDollar: productData.salesStoreDollar,
	// 			discountCost: productData.priceCostWon - productData.salesStoreWon,
	// 			discountCostDollar: productData.priceCostDollar - productData.salesStoreDollar,
	// 		};

	// 		dispatch(deleteAllItem());
	// 		dispatch(addItem(videoItem));
	// 		history.push({
	// 			pathname: `/payment/video-class`,
	// 		})
	// 	}
	// }, [productData]);

	// const productId = (index) => {
	// 	if (process.env.REACT_APP_ENV === "production") {
	// 		switch(index){
	// 			case 0: return 1218;
	// 			default: return 1218;
	// 		}
	// 	}else{
	// 		switch(index){
	// 			case 0: return 1190;
	// 			default: return 1190;
	// 		}
	// 	}
	// }

	// useEffect(() => {
	// 	if(myVCData && vcProductId >= 0){
	// 		const orderList = myVCData.filter((data)=>data.productIdx === vcProductId);
	// 		if(orderList.length > 0){
	// 			//구매불가능
	// 			handleModal(
	// 				<ConfirmModal
	// 					tMessage={'You have already purchased this class.'}
	// 					tButton={'CONFIRM'}
	// 				/>
	// 			)
	// 			return;
	// 		}else{
	// 			//구매가능
	// 			fetchProduct(vcProductId, token)
	// 		}
	// 	}
	// },[myVCData, vcProductId])

	// const clickApply = (index) => {
	// 	if (!isLogin) {
	// 		handleModal(<Login />, false, 420, location.pathname);
	// 		return;
	// 	}

	// 	setVCProductId(productId(index));
	// 	fetchMyVCPaymentList(token);
	// }

	//swiper
	const [BenefitSwiper, setBenefitSwiper] = useState(null);

	//D-day 설정
	const [date, setDate] = useState({});
	let futureDate = new Date('2022/08/07 23:59:59');
	const getDate = (date1, date2) => {
		if(date2.getTime() - date1.getTime() < 0){
			return {
				day: 0,
				hour: 0,
				minute: 0,
				second: 0,
			};
		}else{
			const date = new Date(date2.getTime() - date1.getTime())
			return {
				day: date.getUTCDate() - 1,
				hour: date.getUTCHours(),
				minute: date.getUTCMinutes(),
				second: date.getUTCSeconds()
			};
		}
	};
	useEffect(() => {
		setDate(getDate(new Date(), futureDate));
		// const timer = setInterval(() => {
      	// 	setDate(getDate(new Date(), futureDate));
		// }, 1000);
  }, []);

	return (
		<Styled.EventWrap>
			<div className='visual'>
				<div className='inner'>
					<h1 className='blind'>TOPIK PASS Live Class Open!</h1>
					<div className='btnWrap'>
						<Link to={"/alive"}><img src={btnApplyImg} alt="Apply Now" /></Link>
					</div>
				</div>
			</div>

      <div className='gift'>
				<div className='inner'>
					<h2><span className='blind'>Preparing for the 84th TOPIK test Take the live class and Get a refund for TOPIK fee</span></h2>
          <p className='blind'>100% support for TOPIK Exam costs Notice : Support the cost with a Gift Card.</p>
				</div>
			</div>

			<div className='price'>
				<div className='inner'>
					<div className='blind'>
						<h2>How  to take Korean Video Class At Cheaper Price</h2>
						<p>Special Early Bird Discount is Available Until September 4! Price Will Go Up Starting from September 5.</p>
						<ul>
							<li>
								<h3>Original Price</h3>
								<p>(After September 5th)</p>
								<strong>$170 per month</strong>
							</li>
							<li>
								<h3>Special Early Bird Discount</h3>
								<p>(~ 2022.9.4)</p>
								<strong>$85 per month</strong>
							</li>
						</ul>
					</div>
					<div className='btnWrap'>
						<Link to={"/alive"}><img src={btnApplyImg2} alt="Apply Before Deadline" /></Link>
					</div>
				</div>
			</div>

			<div className='classInfo'>
				<div className='inner'>
					<div className='blind'>
						<h2>Don’t Worry! MT Alive Solved All Weakness of Video Class!</h2>
						<p>Are You Hesitating Because You Had Bad Experience with Video Class Earlier?</p>
					</div>
					<ul>
							<li>
								<div><span className='blind'>Quality of lecturer differs a lot</span></div>
								<div><span className='blind'>Verified Academic Background and Careers</span>
									<Link to={"/alive"}>View Lecturer’s Details &gt;</Link>
								</div>
							</li>
							<li>
								<div><span className='blind'>I’m forced to buy the whole lecture package</span></div>
								<div><span className='blind'>Able to Take Single Class for One Month</span></div>
							</li>
							<li>
								<div><span className='blind'>Once I choose a class, I cannot change it</span></div>
								<div><span className='blind'>Able to Change Class After First Lecture! (Refer to Notice for Detailed Terms and Conditions)</span></div>
							</li>
						</ul>
				</div>
			</div>

			<div className='review'>
				<div className='inner'>
					<div className='blind'>
						<h2>Check out MT video Class Review</h2>
						<p>Check Out Favorable Reviews from Students Who Took Video Class!</p>
					</div>

					<div className='swiperWrap'>
						<Styled.Swiper
								slidesPerView={1}
								speed={300}
								loop={true}
								autoplay={{
									delay: 4000
								}}
								navigation
          			pagination={{ clickable: true }}
							>
							<SwiperSlide>
								<img src={reviewImg} alt="" />
								<div className='blind'>
									<em>Lu**(TOPIK Lv. 5)</em>
									I don’t have to search Google anymore thanks to clear and accurate lecture!
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<img src={reviewImg2} alt="" />
								<div className='blind'>
									<em>Gu**(TOPIK Lv. 6)</em>
									I can communicate with lecturer more easily. The video class helped me a lot in passing TOPIK Level 6!
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<img src={reviewImg3} alt="" />
								<div className='blind'>
									<em>Kase**</em>
									It was amazing to meet the lecturer I saw in other YouTube videos. The class was really helpful with so much details!
								</div>
							</SwiperSlide>
						</Styled.Swiper>
					</div>
				</div>
			</div>

			<div className='banner'>
				<div className='inner'>
					<strong>Apply for the class (~9/15)</strong>
					<Link to={"/alive"}>APPLY NOW</Link>
				</div>
			</div>

			<div className='notification'>
				<div className='inner'>
					<h3>Event Notice</h3>
					<dl>
						<dt>Lecture Information</dt>
						<dd> Lecture Schedule : 2022.09.19 (Mon) ~ 2022.10.14 (Fri)</dd>
						<dd>Lecture Room: MT Video Class Platform, MT Alive<br />(Lecture Room Link: <a href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://mtalive.masterk1.com");}}>https://mtalive.masterk1.com</a>)</dd>
						<dd>Please check the video class platform instruction before taking the lecture <a href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4420635446419--MT-Alive-How-to-attend-online-live-class-");}}>View Notice</a></dd>
						<dd>Live Class Account : Once you pay for the lecture, you will receive two accounts in your email (ID) (Date will be notified later)</dd>

						<dt>Class Change Application</dt>
						<dd>Class Change Policy: If you are having difficulty level of difficulty/language, you can change the class once a month.</dd>
						<dd>How to Change Class:  Send email (E-mail: <a href="#!" onClick={(e)=>{e.preventDefault(); openLink("mailto:mastertopik@visang.com");}}>mastertopik@visang.com</a>)</dd>

						<dt>Payment and Refund</dt>
						<dd>Means of Payment : Foreign card, Korean card, PayPal, Union Pay</dd>
						<dd>Refund Policy (<a href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/8048463386003--MT-Alive-Policy-for-Students");}}>View Refund Policy</a>)</dd>
						<dd>1 Day Before Class: Full refund</dd>
						<dd>1 Day before 50% Progress : 50% refund</dd>
						<dd>After 50% Progress: Non-refundable</dd>

						<dt>Live Class Event</dt>
						<dd>The event notice will be announced individually by e-mail on October 21st(Fri)</dd>
						<dd>The examination fee is supported by a gift card.<br/>(Gift card types vary from country to country.)</dd>
					</dl>
				</div>
			</div>
		</Styled.EventWrap>
  );
};

export default EventPage;
import React from 'react';
import { useAxios } from 'common/hook/useAxiosHook';
import learningMaterialApi from 'api/learningMaterial';
import communityApi from 'api/community';
import Styled from 'container/UserHomePage/styled/ServiceContents.style';
import HotTopic from 'container/UserHomePage/HotTopic';
import LearningMaterial from 'container/UserHomePage/LearningMaterial';
import { useTranslation } from 'react-i18next';

export default function ServiceContents() {
  const { t } = useTranslation('userHomePage');
  const topPostList = useAxios(communityApi.getTopPostList, [], true);
  const learningMaterial = useAxios(learningMaterialApi.getAllList, [], true);

  return (
    <Styled.Wrapper>
      <LearningMaterial initialState={learningMaterial} />

      <HotTopic initialState={topPostList} />

      <Styled.LinkList>
        <Styled.LinkToLive to='/mt-live'>
          {t('links.button1')}
        </Styled.LinkToLive>
        <Styled.LinkToVoca to='/voca-card'>
          {t('links.button2')}
        </Styled.LinkToVoca>
        <Styled.LinkToStore to='/bookstore'>
          {t('links.button3')}
        </Styled.LinkToStore>
      </Styled.LinkList>
    </Styled.Wrapper>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import StyledForm from 'component/form/ModalForm.style';
import Styled from './styled/TermsForm.style';
import { Trans } from 'react-i18next';

/**
 * 회원가입 약관 동의 양식 (필수 동의 여부는 state에 전달하지 않음)
 * @param {*} param
 * @param {function} param.setFormData formData state 변경
 * @param {object} param.fullTermsOfUse 이용약관 내용
 * @param {object} param.fullTermsOfInfo 개인정보 수집 및 이용동의 내용
 * @param {object} param.fullTermsOfHandling 개인정보 광고 및 활용동의 내용
 */
export default function TermsForm({
  formData,
  setFormData,
  fullTermsOfUse,
  fullTermsOfInfo,
  fullTermsOfHandling,
  t,
  type,
}) {
  const [allAgree, setAllAgree] = useState(false);

  const [termsChecked, setTermsChecked] = useState({
    termsOfUse: false,
    termsOfInfo: false,
    emailAcceptance: false,
  });

  const [termsOpen, setTermsOpen] = useState({
    termsOfUse: false,
    termsOfInfo: false,
    emailAcceptance: false,
  });

  const handleOpen = useCallback(
    (id) => setTermsOpen({ ...termsOpen, [id]: !termsOpen[id] }),
    [termsOpen]
  );

  // 개별 선택 체크박스 핸들링
  const handleCheck = useCallback(
    ({ target }) =>
      setTermsChecked({
        ...termsChecked,
        [target.id]: target.checked,
      }),
    [termsChecked]
  );

  // 전체 선택 체크박스 핸들링
  const handleAllCheck = useCallback(
    ({ target }) => {
      let copiedState = termsChecked;
      for (const key in copiedState) copiedState[key] = target.checked;
      setTermsChecked(copiedState);
      setAllAgree(target.checked);
    },
    [termsChecked]
  );

  // 개별 선택 체크박스 핸들링에 대한 전체 체크박스 변경
  useEffect(() => {
    let isAllItemsChecked = true;
    for (const key in termsChecked) {
      if (!termsChecked[key]) {
        isAllItemsChecked = false;
        break;
      }
    }
    setAllAgree(isAllItemsChecked);
  }, [termsChecked]);

  // 동의 여부 상위 컴포넌트로 전달
  useEffect(() => {
    setFormData(termsChecked);
  }, [termsChecked, allAgree]);

  return (
    <StyledForm.InputContainer>
      <StyledForm.Label>{t('terms.title')}</StyledForm.Label>

      <Styled.Boxing>
        <Styled.BoxingInner>
          <Styled.InputCheck
            onChange={handleCheck}
            checked={termsChecked.termsOfUse}
            type='checkbox'
            title='Check (agree) on access terms'
            id='termsOfUse'
          />
          <Styled.Label htmlFor='termsOfUse'>
            <Trans
              t={t}
              i18nKey='terms.terms1Label'
              components={{
                span: <span className='red' />,
              }}
            />
          </Styled.Label>

          {/* <Styled.DetailButtonLeft>
            <Styled.Desc>Check (agree) on access terms</Styled.Desc>
          </Styled.DetailButtonLeft> */}
          <Styled.DetailButton
            onClick={() => handleOpen('termsOfUse')}
            className={termsOpen.termsOfUse && 'active'}
          />
          <Styled.Detail className={termsOpen.termsOfUse && 'active'}>
            <div
              dangerouslySetInnerHTML={{
                __html: fullTermsOfUse.data,
              }}
            />
          </Styled.Detail>
        </Styled.BoxingInner>

        <Styled.BoxingInner>
          <Styled.InputCheck
            onChange={handleCheck}
            checked={termsChecked.termsOfInfo}
            type='checkbox'
            title='Consent to Collection and Usage of Personal Information'
            id='termsOfInfo'
          />
          <Styled.Label htmlFor='termsOfInfo'>
            <Trans
              t={t}
              i18nKey='terms.terms2Label'
              components={{
                span: <span className='red' />,
              }}
            />
          </Styled.Label>
          {/* <Styled.DetailButtonLeft>
            <Styled.Desc>
              You may not give your consent and still join for the membership.
            </Styled.Desc>
          </Styled.DetailButtonLeft> */}
          <Styled.DetailButton
            onClick={() => handleOpen('termsOfInfo')}
            className={termsOpen.termsOfUse && 'active'}
          />
          <Styled.Detail className={termsOpen.termsOfInfo && 'active'}>
            {type === 'sns' ? (
              <Trans t={t} i18nKey='snsTerms.terms2Content' />
            ) : (
              <Trans t={t} i18nKey='terms.terms2Content' />
            )}
          </Styled.Detail>
        </Styled.BoxingInner>

        <Styled.BoxingInner>
          <Styled.InputCheck
            onChange={handleCheck}
            checked={termsChecked.emailAcceptance}
            type='checkbox'
            title='Consent to email alerts'
            id='emailAcceptance'
          />
          <Styled.Label htmlFor='emailAcceptance'>
            <Trans
              t={t}
              i18nKey='terms.terms3Label'
              components={{
                span: <span className='blue' />,
              }}
            />
          </Styled.Label>
          {/* <Styled.Desc>
            You may not give your consent and still join for the membership. You
            must enter your Korean mobile phone number when you are signing up
            after you consent to SMS alerts in order to complete your membership
            application.
          </Styled.Desc> */}

          <Styled.DetailButton
            onClick={() => handleOpen('termsOfHandling')}
            className={termsOpen.termsOfHandling && 'active'}
          />
          <Styled.Detail className={termsOpen.termsOfHandling && 'active'}>
            <Trans t={t} i18nKey='terms.terms3Content' />
          </Styled.Detail>
        </Styled.BoxingInner>
      </Styled.Boxing>

      <Styled.Boxing>
        <Styled.BoxingInner>
          <Styled.InputCheck
            type='checkbox'
            title='Check (agree) all'
            id='allCheck'
            checked={allAgree}
            onChange={handleAllCheck}
          />
          <Styled.Label htmlFor='allCheck'>
            {t('terms.allAgreeLabel')}
          </Styled.Label>
          <Styled.Desc>{t('terms.allAgreeContent')}</Styled.Desc>
        </Styled.BoxingInner>
      </Styled.Boxing>

      <Styled.NoticeH3>{t('terms.descLabel')}</Styled.NoticeH3>
      <Styled.NoticeDesc>{t('terms.descContent1')}</Styled.NoticeDesc>
      <Styled.NoticeDesc>
        <b>{t('terms.descContent2')}</b>
      </Styled.NoticeDesc>
    </StyledForm.InputContainer>
  );
}

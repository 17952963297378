import React, { useState } from 'react';
import useInterval from 'common/hook/useInterval';
import Style from '../styled/TestPaper.style';

const CountdownProgress = ({ second = 0, testTime }) => {
  const [countdown, setCountdown] = useState(second);

  useInterval(() => {
    setCountdown(countdown - 1);
  }, 1000);

  if (countdown)
    return (
      <Style.Progress
        percentage={second && testTime && 100 - (countdown / testTime) * 100}
      >
        <div className='progress-bar'>
          <span></span>
        </div>
      </Style.Progress>
    );
  return <></>;
};

export default CountdownProgress;

import React, { useContext, useEffect, useRef, useState } from 'react';
import StyledAside from '../index.style';
import ClassTabListItem from './ClassTabListItem';
import { ViewContext } from 'container/CourseViewPage';
import useErrorToastify from 'common/hook/useErrorToastify';
import Styled from './ClassTabListItem.style';
import OverviewItem from 'container/CourseViewPage/Aside/ClassTab/OverviewItem';

const ClassTab = ({ courseListData }) => {
  const [chapterList, setChapterList] = useState([]);
  useEffect(() => {
    courseListData && setChapterList(courseListData);
  }, [courseListData]);

  const { lectureState } = useContext(ViewContext);
  const [{ data: lectureData, error: lectureError }] = lectureState;

  const ref = useRef(0);

  const [lecture, setLecture] = useState();
  useEffect(() => {
    lectureData && setLecture(lectureData);
  }, [lectureData]);

  useErrorToastify(lectureError);

  return (
    <StyledAside.TabWrapper ref={ref}>
      <OverviewItem />

      {chapterList &&
        chapterList.map((item, index) => (
          <ClassTabListItem
            item={item}
            key={index}
            currentChapterId={lecture?.chapterId}
            chapterListRef={ref}
          />
        ))}
    </StyledAside.TabWrapper>
  );
};

export default ClassTab;

import React, { useEffect, useState } from 'react';
import Styled from './index.style';
import { useParams } from 'react-router-dom';
import CommentItem from 'container/CommunityPage/Detail/Comment/Item';
import Loading from 'component/atoms/Loading';
import { useAxios } from 'common/hook/useAxiosHook';
import communityApi from 'api/community';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import useErrorToastify from 'common/hook/useErrorToastify';

const ChildItem = ({
  parentComment,
  token,
  userData,
  editState,
  reReplyState,
  onRefresh,
}) => {
  const { id } = useParams();

  const [isVisible, setIsVisible] = useState(false);

  const [childComment, fetchChildComment] = useAxios(
    communityApi.getChildCommentList,
    [],
    true
  );
  const { loading, data, error } = childComment;

  useEffect(() => {
    isVisible && fetchChildComment(id, parentComment.id, token, 0);
  }, [isVisible]);

  const [] = useAxios();

  useErrorToastify(error);

  if (parentComment.childRepliesCount > 0) {
    return (
      <Styled.ChildItem>
        <Styled.ToggleChildItem onClick={() => setIsVisible(!isVisible)}>
          {isVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
          답글 {parentComment.childRepliesCount}개
        </Styled.ToggleChildItem>
        {isVisible && (
          <>
            {loading && <Loading />}

            {data?.content?.map((item, index) => (
              <Styled.Item key={index}>
                <CommentItem
                  item={item}
                  userData={userData}
                  editState={editState}
                  reReplyState={reReplyState}
                  onRefresh={onRefresh}
                />
              </Styled.Item>
            ))}
          </>
        )}
      </Styled.ChildItem>
    );
  } else {
    return null;
  }
};

export default ChildItem;

import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';

const LinkStyle = css`
  display: inline-block;
  padding: 0.75em 1.15em;
  margin-bottom: -2px;
  border-bottom: 2px transparent solid;
  color: #c9cbd1;
  font-size: 2em;
  font-weight: 700;
  cursor: pointer;
  &.active {
    color: #ffb500;
    border-bottom: 2px #ffb500 solid;
  }
  @media (max-width: 767px) {
    padding: 0.75em 0.55em;
  }
  @media (max-width: 375px) {
    font-size: 1.6em;
  }
`;

const StyledTabMenu = {
  Container: styled.div`
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '7.5em')};
    margin-bottom: ${({ marginBottom }) =>
      marginBottom ? marginBottom : '6.7em'};
    border-bottom: 2px #2b2e35 solid;
  `,
  Button: styled.div`
    ${LinkStyle}
  `,
  NavLink: styled(NavLink).attrs({ activeClassName: 'active' })`
    ${LinkStyle}
  `,
  Btn: styled.button`
    ${LinkStyle}
    border-bottom:none;
  `,
};

export default StyledTabMenu;

import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Styled from './styled/VideoTab.style';
import coursesApi from 'api/courses';
import CourseSwiperList from 'component/molecules/CourseSwiperList';
import { useAxios } from 'common/hook/useAxiosHook';
import { masterTopikUrl } from 'common/constant';
import thumbnailDefault from 'assets/images/thumb_default_course.jpg';

import H3 from 'component/atoms/H3';

export default function CoursesList({ category, subPage }) {
  // console.log('category !!!', category);
  const { name: courseId, value: title, description: subTitle } = category;
  const [courseState, fetchCourseState] = useAxios(
    coursesApi.getCourse,
    [courseId],
    true
  );
  const listWrap = useRef();
  const { pathname } = useLocation();
  const { loading, data: course } = courseState;
  const coursePage = course?.coursePage;

  const [slideItemList, setSlideItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    fetchCourseState({ name: courseId });
  }, [courseId, fetchCourseState]);
  useEffect(() => {
    const newSlideItemList = courseState.data?.coursePage?.content;
    newSlideItemList &&
      // setSlideItemList([...slideItemList, ...newSlideItemList]);
      setSlideItemList([...newSlideItemList]);
  }, [course]);

  const onNext = () => {
    if (!loading && currentPage < coursePage.totalPages - 1) {
      fetchCourseState({
        name: courseId,
        page: currentPage + 1,
      });
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <>
      {category && category.value !== '' ? (
        <Styled.Wrapper>
          <H3>{title}</H3>
          {subPage ? undefined : (
            <Styled.ButtonAll to={`/courses/${courseId}`}>
              View all​
            </Styled.ButtonAll>
          )}
          <Styled.Desc>{subTitle}</Styled.Desc>
          <div className={subPage ? 'sub-list-wrap' : 'list-wrap'}>
            {subPage ? (
              slideItemList.map((item) => {
                const imgSrc = masterTopikUrl + item.thumbnailPath;
                return (
                  <div className='no-swiper-item'>
                    <div>
                      <Link to={`/courses/detail/${item.productId}`}>
                        <img
                          class='video-thumb'
                          src={imgSrc ? imgSrc : thumbnailDefault}
                          alt={item.title}
                        />
                      </Link>
                      <Link to={`/courses/detail/${item.productId}`}>
                        <div class='title'>{item.title}</div>
                        <div class='sub'>{`${item.classCount} classes, ${item.hour} hours`}</div>
                      </Link>
                    </div>
                  </div>
                );
              })
            ) : (
              <CourseSwiperList
                className='swiperList'
                slideItemList={slideItemList}
                courseId={courseId}
                onNext={onNext}
              />
            )}
          </div>
        </Styled.Wrapper>
      ) : null}
    </>
  );
}

export const SeoData = {
  // Default meta data
  masterTOPIK: {
    title: 'masterTOPIK',
    desc: 'masterTOPIK - just the best',
  },
  bookstore: {
    title: 'Bookstore | masterTOPIK',
    desc: 'Buy the textbooks at masterTOPIK.',
  },
  classroom: {
    title: 'Classroom | masterTOPIK',
    desc: 'Take online Korean courses.',
  },
  community: {
    title: 'Community | masterTOPIK',
    desc: 'Post various stories about Korean learning, Korea, etc.',
  },
  course: {
    title: 'Course | masterTOPIK',
    desc: 'Check masterTOPIK courses.',
  },
  landing: {
    title: 'masterTOPIK',
    desc: 'masterTOPIK - just the best',
  },
  learningMaterial: {
    title: 'Learning Material | masterTOPIK',
    desc: 'Check various learning materials of masterTOPIK.',
  },
  levelTest: {
    title: 'LevelTest | masterTOPIK',
    desc: 'Test your Korean language skills.',
  },
  MockTest: {
    title: 'MockTest | masterTOPIK',
    desc: 'Test your Korean language skills.',
  },
  library: {
    title: 'Library | masterTOPIK',
    desc: 'Browse various courses and themes of masterTOPIK.',
  },
  MTLive: {
    title: 'MTLive | masterTOPIK',
    desc: 'Once a month, check free MT Live.',
  },
  vocaCard: {
    title: 'VocaCard | masterTOPIK',
    desc: 'Check vocabulary learning materials of masterTOPIK.',
  },
  notification: {
    title: 'Notification | masterTOPIK',
    desc: 'Check various notification messages of masterTOPIK.',
  },
  plan: {
    title: 'Plan | masterTOPIK',
    desc: 'Check various service plans of masterTOPIK.',
  },
  resource: {
    title: 'Resource | masterTOPIK',
    desc: 'Check various learning materials of masterTOPIK.',
  },
  teachers: {
    title: 'Teachers | masterTOPIK',
    desc: 'Check Korean language teachers of masterTOPIK.',
  },
  theme: {
    title: 'themeDetailByUser | masterTOPIK',
    desc: 'Explore various themes of masterTOPIK.',
  },
  terms: {
    title: 'Terms | masterTOPIK',
    desc: 'Check terms of use of masterTOPIK.',
  },
  payment: {
    title: 'Payment | masterTOPIK',
    desc: 'Check your payment history of masterTOPIK.',
  },
  profile: {
    title: 'Profile | masterTOPIK',
    desc: 'Register and edit your personal profile.',
  },
  settings: {
    title: 'Settings | masterTOPIK',
    desc: 'Check various settings such as payment.',
  },
  UserBookmark: {
    title: 'Theme | masterTOPIK',
    desc: 'Check the bookmarks I saved.',
  },
  userHomePage: {
    title: 'Home | masterTOPIK',
    desc: 'Check my learning process.',
  },
  about: {
    title: 'About | masterTOPIK',
    desc: 'Check About of use of masterTOPIK.',
  },
  aliveclass: {
    title: 'Alive Class | masterTOPIK',
    desc: '',
  }
};

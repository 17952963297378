import React, { useState } from 'react';
import { useRouteMatch, useLocation } from 'react-router-dom';

import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';

import CoursesRoutes from 'container/CoursePage/Routes';
import GoToLibrary from 'container/CoursePage/GoToLibrary';
import StyledTab from 'component/TabMenu/index.style';

import Styled from './styled/VideoTab.style';

import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { ModalContext } from 'component/Modal/modalContext';
import CourseEPS from 'container/CoursePage/CourseEPS';
import { useTranslation } from 'react-i18next';
//import Features from 'container/LandingPage/Features';
// import PlanBannerPopup from "component/PlanBanner";
import RollBanner from 'component/RollBanner';
import { useEffect } from 'react';
import {MainMultiEventBannerWithAPI} from "../../component/EventBanner";

const Course = () => {
  const { t } = useTranslation('coursePage');
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  let { handleModal } = React.useContext(ModalContext);
  const location = useLocation();

  return (
    <>
        {/*현대사이트솔루션 이벤트 진행을 위해 임시 노출*/}
        {location.pathname === '/courses/all' ? (
            <>
                <MainMultiEventBannerWithAPI />
            </>
        ) : (
            <></>
        )}
      <SeoHelmet title={SeoData.course.title} desc={SeoData.course.desc} />
      <ContentsWrapper>
        {/* <RollBanner /> */}
        <PageHeader title={t('title')} />

        <StyledTab.Container marginTop={'4.5em'} marginBottom={'5em'}>
          {/* <Styled.TabLink
            to={url}
            className={pathname === '/courses/topik-mock-test' ? '' : 'active'}
          >
            Video
          </Styled.TabLink>
          <Styled.TabLink
            to={`${url}/topik-mock-test`}
            className={pathname === '/courses/topik-mock-test' ? 'active' : ''}
          >
            TOPIK Mock Test
          </Styled.TabLink> */}
        </StyledTab.Container>
      </ContentsWrapper>
      <CoursesRoutes />

      <GoToLibrary />
    </>
  );
};

export default Course;

import React from 'react';
import alarmImage from 'assets/images/ico_alarm.png';
import styled from 'styled-components';
import Img from 'component/atoms/Img';

const Style = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100%;
    padding: 0 1em;
  `,

  Desc: styled.p`
    color: #d1d1d1;
    font-size: 1.6em;
    font-weight: 400;
  `,
};
const AlarmEmpty = () => {
  return (
    <Style.Container>
      <div>
        <Img src={alarmImage} alt='alarm' />
        <Style.Desc> 새로운 알람이 없습니다.</Style.Desc>
      </div>
    </Style.Container>
  );
};

export default AlarmEmpty;

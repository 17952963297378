import React from 'react';
import { Step } from 'container/LevelTestPage/styled';
import { useTranslation } from 'react-i18next';

const LevelTestStep = ({ activeIndex }) => {
  const { t } = useTranslation('levelTestPage');
  const stepDate = [
    { id: 1, number: '01', text: t('processBar.step1') },
    { id: 2, number: '02', text: t('processBar.step2') },
    { id: 3, number: '03', text: t('processBar.step3') },
    { id: 4, number: '04', text: t('processBar.step4') },
  ];
  return (
    <>
      <Step.Step>
        {stepDate.map((value) => {
          return (
            <Step.Item
              key={value.id}
              className={`${activeIndex === value.id ? 'active' : ''}`}
            >
              <strong>{value.number}</strong>
              <span>{value.text}</span>
            </Step.Item>
          )
        })}
      </Step.Step>

    </>
  );
}

export default LevelTestStep;

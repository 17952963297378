import styled from 'styled-components';

const ClassDetailStyle = {
  Container: styled.div`
    margin: 0 0 3em;
    color: #0f1012;
  `,

  H3: styled.h3`
    padding: 0.5em 1em 1.2em;
    border-bottom: 1px solid #5c5f66;
    text-align: center;
    font-weight: 700;
    font-size: 3.6em;
    color: #000;
    &.type1 { //입문
      .group {
        background-color: #e36b14;
      }
    }
    &.type2 { //초급
      .group {
        background-color: #ffb500;
      }
    }
    &.type3 { //중급
      .group {
        background-color: #6abc19;
      }
    }
    &.type4 { //고급
      .group {
        background-color: #596eed;
      }
    }
    span {
      display: inline-block;
    }
    .group {
      margin-right: 1em;
      padding: 0.2em 0.8em;
      border-radius: 2px;
      font-weight: 700;
      font-size: 0.5em;
      color: #fff;
      vertical-align: middle;
    }
  `,

  ContentWrap: styled.div`
    max-height: calc(100vh - 50em);
    overflow-y: auto;
    margin: 5em 0;
    padding: 0 4em;
    @media (max-width: 750px) {
      padding-left: 0;
      padding-right: 0;
      font-size: 1.08em;
    }
  `,

  H4: styled.h4`
    margin: 2em 0 0.8em;
    font-weight: 700;
    font-size: 2.4em;
    color: #000;
    &:first-child {
      margin-top: 0;
    }
  `,

  Teacehr: styled.div`
    font-size: 1.6em;
    .stitle {
      margin-bottom: 1em;
      font-weight: 700;
      font-size: 1.25em;
      color: #000;
    }
  `,

  Video: styled.div`
    max-width: 630px;
    margin: 2em auto 0;
  `,

  Class: styled.div`
    padding: 2em;
    background: #f7f8fd;
    font-size: 1.6em;
    .stitle {
      margin: 1.5em 0 0.5em;
      font-weight: 700;
      font-size: 1.125em;
      color: #000;
      &:first-child {
        margin-top: 0;
      }
    }
    li {
      position:relative;
      padding-left: 0.8em;
      margin-bottom: 0.2em;
      &:before {
        content:"";
        display:inline-block;
        position: absolute;
        top: 0.7em;
        left: 0;
        width: 0.2em;
        height: 0.2em;
        border-radius: 0.1em;
        background:#0f1012;
        vertical-align:middle;
      }
    }
    .detail {
      white-space: pre-wrap;
    }
  `,

  Price: styled.div`
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    dl {
      display: flex;
      padding: 0 1em;
      margin: 1.8em 0;
      font-size: 1.6em;
      dt {
        flex: 1;
        font-weight: 700;
      }
      dd {
        flex: 1;
        text-align: right;
      }
      &.discount{
        font-weight: 700;
        color: #485cc7;
      }
      &.off {
        padding-left: 3em;
        margin: 1em 0;
        dt {
          font-weight: 300;
          span {

          }
        }
      }
      &.promotion {
        color: #485cc7;
        span {
          background: #eceef9;
        }
      }
      &.total {
        padding-top: 1.8em;
        border-top: 1px solid #c9cbd1;
        font-weight: 700;
        font-size: 1.8em;
        dd {
          color: #e26b13;
        }
      }
    }
  `,

  ButtonWrap: styled.div`
    text-align: center;
    button {
      width: 40%;
      height: 3.5em;
      background: #485cc7;
      border-radius: 6px;
      font-weight: 700;
      font-size: 1.6em;
      color: #fff;

      &:disabled{
        background: #b0b1b2;
      }
    }
  `,
};

export default ClassDetailStyle;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Form from 'component/form/Form.style';
import { useHistory, useLocation } from 'react-router-dom';
import { useAxios } from 'common/hook/useAxiosHook';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';
import { onValidate } from 'common/util/regex';
import userApi from 'api/user';
import { ModalContext } from 'component/Modal/modalContext';
import SubmitResultModal from 'container/userPage/ResetPassword/SubmitResultModal';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';

const Styled = {
  Wrapper: styled(ContentsWrapper)`
    max-width: 700px;
    padding-bottom: 15em;
    .page-header {
      text-align: center;
      margin-top: 10em;
      margin-bottom: 7em;
    }
  `,
};

export default function ResetPassword() {
  const { t } = useTranslation('editPasswordModal');

  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const userID = searchParams.get('userID');
  const confirmationToken = searchParams.get('token');

  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [regexError, isRegexError] = useState(false);
  const [confirmError, isConfirmError] = useState(false);

  const [setPassword, fetchSetPassword] = useAxios(
    userApi.setPassword,
    [],
    true
  );
  const { loading, data: resultStatus, error } = setPassword;

  const handleNewPassword = (e) => {
    isRegexError(!onValidate.password(e.target.value));
    confirmPassword && isConfirmError(confirmPassword !== e.target.value);
  };

  const handleConfirmPassword = (e) => {
    isConfirmError(newPassword !== e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!regexError && !confirmError) {
      const body = { newPassword, confirmationToken };
      fetchSetPassword(body);
    }
  };

  const history = useHistory();
  let { handleModal } = React.useContext(ModalContext);
  useEffect(() => {
    if (resultStatus === 200) {
      history.push('/');
      handleModal(<SubmitResultModal />);
    }
  }, [resultStatus]);

  useEffect(() => {
    if (!userID || !confirmationToken) history.replace('/');
  }, [searchParams]);

  return (
    <>
      <Styled.Wrapper>
        <SeoHelmet
          title={SeoData.userHomePage.title}
          desc={SeoData.userHomePage.desc}
        />
        <PageHeader title={t('title')} />

        <Form.FormWrapper action='' method='put' onSubmit={onSubmit}>
          <Form.InputContainer>
            <Form.Label htmlFor='username'>ID</Form.Label>
            <Form.Input type='text' id='username' value={userID} disabled />
          </Form.InputContainer>

          <Form.InputContainer>
            <Form.Label htmlFor='newPassword'>{t('newPw')}</Form.Label>
            <Form.Input
              type='password'
              id='newPassword'
              autoComplete='new-password'
              required
              onChange={(e) => setNewPassword(e.target.value)}
              onBlur={handleNewPassword}
            />
            {regexError && (
              <Form.WarnText>
                Password field must have at least 10 characters and at least 1
                letter and number
              </Form.WarnText>
            )}
          </Form.InputContainer>

          <Form.InputContainer>
            <Form.Label htmlFor='confirmPassword'>{t('chkPw')}</Form.Label>
            <Form.Input
              type='password'
              id='confirmPassword'
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={handleConfirmPassword}
            />
            {confirmError && <Form.WarnText>{t('alerts')}</Form.WarnText>}
          </Form.InputContainer>

          <Form.Button type='submit'>{t('button')}</Form.Button>

          <LoadingOverlay isLoading={loading} />

          {error && <Form.WarnText>{error.data.message}</Form.WarnText>}
        </Form.FormWrapper>
      </Styled.Wrapper>
    </>
  );
}

import quizQ1 from 'assets/images/event/230921/quiz-q1.png';
import quizQ2 from 'assets/images/event/230921/quiz-q2.png';
import quizQ3 from 'assets/images/event/230921/quiz-q3.png';
import quizQ4 from 'assets/images/event/230921/quiz-q4.png';
import quizQ5 from 'assets/images/event/230921/quiz-q5.png';
import quizQ6 from 'assets/images/event/230921/quiz-q6.png';
import quizQ7 from 'assets/images/event/230921/quiz-q7.png';
import quizQ8 from 'assets/images/event/230921/quiz-q8.png';
import quizQ9 from 'assets/images/event/230921/quiz-q9.png';
import quizQ10 from 'assets/images/event/230921/quiz-q10.png';

const quizData = [
  {
    id:1,
    title:'빈칸에 <span><b>공통</b></span>으로 들어갈 말을 고르세요.',
    titleEn : 'Choose the words that will go in common with the blanks.',
    examples : ['민', '국'],
    answer : '국',
    src:quizQ1
  },
  {
    id:2,
    title:'그림을 보고 <span><b>올바른 것</b></span>을 고르세요.',
    titleEn : 'Look at the picture and choose the correct one',
    examples : ['근무하다', '지각을 하다'],
    answer : '지각을 하다',
    src:quizQ2
  },
  {
    id:3,
    title:'빈칸에 <span><b>알맞은 말</b></span>을 고르세요.',
    titleEn : 'Choose the correct word for the blank',
    examples : ['화', '금'],
    answer : '화',
    src:quizQ3
  },
  {
    id:4,
    title:'빈칸에 <span><b>알맞은 말</b></span>을 고르세요.',
    titleEn : 'Choose the correct word for the blank',
    examples : ['고', '무'],
    answer : '고',
    src:quizQ4
  },
  {
    id:5,
    title:'(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)에 들어갈 <span><b>올바른 말</b></span>을 고르세요.',
    titleEn : 'Choose the correct words to put in the blank',
    examples : ['쓰다', '하다'],
    answer : '쓰다',
    src:quizQ5
  },
  {
    id:6,
    title:'(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)에 들어갈 <span><b>올바른 말</b></span>을 고르세요.',
    titleEn : 'Choose the correct words to put in the blank',
    examples : ['신다', '입다'],
    answer : '입다',
    src:quizQ6
  },
  {
    id:7,
    title:'(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)에 들어갈 <span><b>올바른 말</b></span>을 고르세요.',
    titleEn : 'Choose the correct words to put in the blank',
    examples : ['속상했다', '뿌듯했다'],
    answer : '뿌듯했다',
    src:quizQ7
  },
  {
    id:8,
    title:'(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)에 들어갈 <span><b>올바른 말</b></span>을 고르세요.',
    titleEn : 'Choose the correct words to put in the blank',
    examples : ['미리', '혹시'],
    answer : '미리',
    src:quizQ8
  },
  {
    id:9,
    title:'다음에 <span><b>어울리는 표현</b></span>은?',
    titleEn : "What's the most appropriate expression?",
    examples : ['눈앞이 캄캄하다', '눈도 깜짝 안 하다'],
    answer : '눈앞이 캄캄하다',
    src:quizQ9
  },
  {
    id:10,
    title:'다음 상황에 <span><b>어울리는 표현</b></span>은?',
    titleEn : "What's the best expression for the situation?",
    examples : ['쇠뿔도 단김에 뺀다.', '돌다리를 두들겨보고 건넌다.'],
    answer : '돌다리를 두들겨보고 건넌다.',
    src:quizQ10
  }
];

export default quizData;
import { asyncApi } from 'common/util/asyncApi';
const url = '/api/v1/coupons';

const couponApi = {
  getBookCouponData: async ({ productId, couponNumber, currency, token }) => {
    let response = await asyncApi({
      url: `${url}/${couponNumber}`,
      params: { productId, currency },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getPlanCouponData: async ({ plan, productIdx, couponNumber, currency, token }) => {
    let response = await asyncApi({
      url: `${url}/${couponNumber}`,
      params: { plan, currency, productIdx },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
    /*
    {"code":"PREFORYOU",
    "name":"Welcome Back! Premium 20% OFF",
    "currency":"KRW",
    "percentOff":20.0,
    "couponType":"PERCENT_OFF",
    "appliesTo":{"salePrice":27500.0000},
    "available":true,
    "unavailableReason":null,
    "amountOff":5500}
    */
  },
};

export default couponApi;

import axios from 'axios';
import { API_HOST } from '../constant';

/**
 *
 * @param {object} param
 * @param {'get' | 'post' =} param.method
 * @param {string} param.url
 * @param {object=} param.params
 * @param {object=} param.data
 */
export function callApi({ method = 'get', url, params, data, headers }) {
  return axios({
    url,
    method,
    baseURL: API_HOST,
    params,
    data,
    headers,
    withCredentials: true,
  })
    .then((response) => ({ response: response.data }))
    .catch((error) => ({ error: error.response.data }));
}

import { StyleSheet, Font } from '@react-pdf/renderer';

Font.register({
  family: 'Nanum Gothic',
  // src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-ExtraBold.ttf',
  fonts: [
    {
      src:
        'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf',
      fontWeight: 400,
    },

    {
      src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Bold.ttf',
      fontWeight: 700,
    },
  ],
});

const StyledPrintReport = StyleSheet.create({
  page: {
    padding: '10 30',
    backgroundColor: '#fff',
    fontFamily: 'Nanum Gothic',
  },
  LogoContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  Logo: {
    width: 130,
    height: 20,
  },
  stampContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 30,
    marginBottom: 20,
  },
  stamp: {
    width: 250,
    height: 80,
  },
  h1: {
    marginTop: 30,
    textAlign: 'center',
    fontSize: 30,
    fontWeight: 700,
  },
  h2: {
    marginTop: 7,
    textAlign: 'center',
    color: '#999',
    fontSize: 18,
    fontWeight: 400,
  },
  h3: {
    marginTop: 30,
    marginBottom: 15,
    fontSize: 15,
    fontWeight: 700,
  },
  reportCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  infoContainer: {
    width: 'calc(50% - 10px)',
    flexDirection: 'column',
    border: '1px #999 solid',
    borderColor: '#999',
    backgroundColor: '#F7F7F7',
  },
  infoSpan: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  infoTitle: {
    width: '120px',
    flexDirection: 'column',
    fontSize: 10,
    padding: '10px',
    textAlign: 'center',
    position: 'relative',
  },
  infoDesc: {
    width: 'calc(100% - 120px)',
    flexDirection: 'column',
    fontSize: 10,
    padding: '10px',
    height: '100%',
    borderLeft: '1px #999 solid',
    borderColor: '#999',
    backgroundColor: '#fff',
  },
  gradeContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'row',
    width: 'calc(50% - 10px)',
    marginLeft: '20px',
    padding: '5px 0',
    border: '1px #999 solid',
    borderColor: '#999',
    backgroundColor: '#F7F7F7',
  },
  gradeSpan: {
    flexDirection: 'column',
    width: 'calc(100% / 2 - 10px)',
  },
  gradeTitle: {
    marginBottom: 5,
    fontSize: 10,
  },
  gradeSlash: {
    flexDirection: 'column',
    width: '20px',
    fontWeight: 400,
    fontSize: 30,
    color: '#999',
    textAlign: 'center',
  },
  gradeDesc: {
    fontSize: 16,
    fontWeight: 700,
  },

  // 시험결과
  tblTestResult: {
    border: '1px #bfbfbf solid',
    borderColor: '#bfbfbf',
    borderBottom: '0',
  },
  tblTestResultThead: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px #bfbfbf solid',
    borderColor: '#bfbfbf',
    backgroundColor: '#f7f7f7',
  },
  tblTestResultTh1: {
    width: '150px',
    fontWeight: 700,
    fontSize: 10,
    textAlign: 'center',
    padding: '10px 10px',
  },
  tblTestResultTh2: {
    borderLeft: '1px #bfbfbf solid',
    borderColor: '#bfbfbf',
    width: 'calc(100% - 150px)',
    fontSize: 10,
    textAlign: 'center',
    padding: '10px 10px',
  },
  tblTestResultTbody: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: '1px #bfbfbf solid',
    borderColor: '#bfbfbf',
  },
  tblTestResultTd1: {
    flexDirection: 'column',
    width: '150px',
    fontWeight: 700,
    fontSize: 10,
    textAlign: 'center',
    padding: '10px 10px',
  },
  tblTestResultTd2: {
    borderLeft: '1px #bfbfbf solid',
    borderColor: '#bfbfbf',
    flexDirection: 'column',
    width: 'calc(100% - 150px)',
    fontSize: 10,
    textAlign: 'left',
    padding: '10px 10px',
  },
  BarContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  Bar: {
    position: 'relative',
    marginRight: 5,
    width: '160px',
    height: '6px',
    backgroundColor: '#ebebeb',
    borderRadius: '7px',
  },
  BarInner: {
    position: 'absolute',
    width: '30%',
    height: '6px',
    borderRadius: '7px',
  },
});

export default StyledPrintReport;

import React from 'react';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import styled from 'styled-components';
import MtLive from 'component/MTLive';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';

const StyledContainer = styled.div`
  margin-top: 8em;
`;

const UserMTLivePage = () => {
  return (
    <ContentsWrapper>
      <SeoHelmet title={SeoData.MTLive.title} desc={SeoData.MTLive.desc} />
      <StyledContainer>
        <MtLive isMyPage={true} />
      </StyledContainer>
    </ContentsWrapper>
  );
};

export default UserMTLivePage;

import styled from "styled-components";
import StyledUtil from "common/styled/Util.style";
import Moment from "react-moment";

const StyledCommentItem = {
  Container: styled.div`
    position: relative;
  `,
  Content: styled.div`
    font-size: 1.6em;
    /* min-height: 7em; */
    padding: 1.25em 2.5em 2.5em;
    color: #c9cbd1;
    line-height: 1.6em;
  `,
  RegDt: styled(Moment)`
    display: inline-block;
    font-size: 1.4em;
    color: #8e9199;
    line-height: 3rem;
    vertical-align: middle;
    :before {
      ${StyledUtil.Bar};
      content: "";
    }
  `,
  Menu: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    span {
      line-height: 3rem;
    }
    span:not(:first-child) {
      margin-left: 1.5em;
    }

    @media (max-width: 767px) {
      position: relative;
      padding-top: 1em;
      text-align: right;
    }
  `,
};

export default StyledCommentItem;

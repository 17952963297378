import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useAxios } from "common/hook/useAxiosHook";
import LikeIcon from "component/atoms/Icon/LikeIcon";
import themesApi from "api/theme";
import { LoadingIcon } from "component/atoms/Loading/index.style";

const LikeButton = styled(LikeIcon)`
  cursor: pointer;
`;
const StyledLoading = styled(LoadingIcon)`
  font-size: 2px;
  margin: 0;
`;

/**
 * 테마 좋아요 & 좋아요 취소 기능 포함
 * @param {*} param
 * @param {number} param.id
 * @param {number} param.count
 * @param {boolean} param.isGood
 */
const ThemeLike = ({ id, count, isGood = false }) => {
  const login = useSelector((state) => state.user.token);
  const [isLike, setIsLike] = useState(isGood);
  const [likeCount, setLikeCount] = useState(count);

  const token = useSelector((state) => state.user.token);
  const [postLike, fetchPostLike] = useAxios(themesApi.postLike, [], true);
  const [deleteLike, fetchDeleteLike] = useAxios(
    themesApi.deleteLike,
    [],
    true
  );

  const {
    loading: postLoading,
    data: postLikeCount,
    error: postError,
  } = postLike;
  const {
    loading: deleteLoading,
    data: deleteStatus,
    error: deleteError,
  } = deleteLike;

  const handleLike = useCallback(() => {
    if (!login) return;
    if (!postLoading && !deleteLoading) {
      isLike ? fetchDeleteLike({ token, id }) : fetchPostLike({ token, id });
    }
  }, [isLike, login]);

  // 좋아요 완료 후
  useEffect(() => {
    if (postLikeCount !== null) {
      setIsLike(true);
      setLikeCount(postLikeCount);
    }
  }, [postLikeCount]);

  // 좋아요 취소 후
  useEffect(() => {
    if (deleteStatus === 200) {
      setIsLike(false);
      setLikeCount(likeCount - 1);
    }
  }, [deleteStatus]);

  if (id === null || id === undefined) return <LikeIcon>{count}</LikeIcon>;

  return (
    <LikeButton className={isLike && "liked"} onClick={handleLike}>
      {postLoading && deleteLoading && <StyledLoading />}
      {likeCount}
    </LikeButton>
  );
};

ThemeLike.propTypes = {
  id: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  isGood: PropTypes.bool.isRequired,
};

export default ThemeLike;

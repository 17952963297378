import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';
import arrowRight from 'assets/images/arrow2_right_gray.svg';
import arrowLeft from 'assets/images/arrow2_left_gray.svg';
import gradient from 'assets/images/gradient03.png';


const swiperButtonStyles = css`
  .swiper-button-disabled {
    opacity: 0.5;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: 1em;
    z-index: 10;
    width: 3em;
    height: 3em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 1.5em;
    cursor: pointer;
  }
  .swiper-button-prev {
    right: 4em;
    background-image: url(${arrowLeft});
  }
  .swiper-button-next {
    right: 0em;
    background-image: url(${arrowRight});
  }
  @media (max-width: 1024px) {
    .swiper-button-prev {
      right: 5em;
    }
    .swiper-button-next {
      right: 2em;
    }
  }
  @media (max-width: 767px) {
    .swiper-button-prev,
    .swiper-button-next {
      top: 50%;
      width: 4em;
      height: 4em;
      margin-top: -2em;
      background-size: 2.5em;
      transition: all 0.2s ease;
    }
    .swiper-button-prev {
      right: auto;
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
  }
`;

const StyledThemeList = {
  Container: styled.div`
    position: relative;

  `,
  Swiper: styled(Swiper)`
    ${swiperButtonStyles}
    position: static;
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -1px;
      z-index: 1;
      width: 7em;
      height: 100%;
      background: url(${gradient}) repeat-y center;
      background-size: 100% auto;
    }
    @media (max-width: 767px) {
      position: relative;
      .swiper-slide {
        padding-top: 0;
        /* padding-bottom: 36em; */
      }
    }
  `,
};

export default StyledThemeList;

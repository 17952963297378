import React, { useEffect, useState } from 'react';
import Styled from './index.style';
import ReactPlayer from 'react-player';
import visualImg from 'assets/images/event/220321/visual.jpg';
import visualLargeImg from 'assets/images/event/220321/visual_large.jpg';
import visualImgKo from 'assets/images/event/220321/ko/visual.jpg';
import visualLargeImgKo from 'assets/images/event/220321/ko/visual_large.jpg';
import videoThumbImg from 'assets/images/event/220321/video.png';
import videoThumbImg2 from 'assets/images/event/220321/video2.png';
import benefitImg from 'assets/images/event/220321/benefit.png';
import benefitImg2 from 'assets/images/event/220321/benefit2.png';
import benefitImg3 from 'assets/images/event/220321/benefit3.png';
import benefitImg4 from 'assets/images/event/220321/benefit4.png';
import benefitLargeImg from 'assets/images/event/220321/benefit_large.png';
import benefitLargeImg2 from 'assets/images/event/220321/benefit2_large.png';
import benefitLargeImg3 from 'assets/images/event/220321/benefit3_large.png';
import benefitLargeImg4 from 'assets/images/event/220321/benefit4_large.png';
import stepImg from 'assets/images/event/220321/step1.png';
import stepImg2 from 'assets/images/event/220321/step2.png';
import stepImg3 from 'assets/images/event/220321/step3.png';
import stepImg4 from 'assets/images/event/220321/step4.png';
import linkCopyimg from 'assets/images/event/220321/btn_copy.png';

import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { Switch, useHistory, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import { addItem, deleteAllItem } from "container/PaymentPage/state/action";
import { useAxios } from 'common/hook/useAxiosHook';
import useLoginStatus from "common/hook/useLoginStatus";
import productApi from 'api/product';
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";
import ConfirmModal from "container/EventPage/ConfirmModal";
import userApi from 'api/user';
import { MOBILE_APP_USER_AGENT } from "common/constant";
import { isMobile } from 'react-device-detect';

SwiperCore.use([Navigation, Thumbs]);

const EventPage = () => {
	const { t } = useTranslation('event/20220321Alive');
	const language = localStorage.getItem('i18nextLng');

	const token = useSelector((state) => state.user.token);
	const isLogin = useLoginStatus();
	let { handleModal } = React.useContext(ModalContext);
	const location = useLocation();
	const history = useHistory();
	let dispatch = useDispatch();
	const [vcProductId, setVCProductId] = React.useState(-1);

	// Video State
	const [playing, setPlaying] = useState(false);
	const [isPlay, setIsPlay] = useState(false);
	const onPLay = () => {
    setPlaying(true);
    setIsPlay(true);
  };

	// 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
	const [isMobileByWidth, setisMobile] = useState(false);
	const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  useEffect(() => {
		if (window.innerWidth <= 1024) {
      setisMobile(true);
    }
    window.addEventListener("resize", resizingHandler);
    return () => {
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);

	//선생님 소개
	const [tabActive, setTabActive] = useState(1);

	// swiper
	const [BenefitSwiper, setBenefitSwiper] = useState(null);

	//링크복사
	const doCopy = text => {

		// 흐름 1.
		if (!document.queryCommandSupported("copy")) {
		return toast.error("Unsupported browser.");
		}

		const API_HOST = process.env.REACT_APP_ENV === 'local' ? 'http://localhost:3000' : process.env.REACT_APP_API_HOST;

		// 흐름 2.
		const textarea = document.createElement("textarea");
		textarea.value = API_HOST + text;
		textarea.style.top = 0;
		textarea.style.left = 0;
		textarea.style.position = "fixed";

		// 흐름 3.
		document.body.appendChild(textarea);
		// focus() -> 사파리 브라우저 서포팅
		textarea.focus();
		// select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
		textarea.select();
		// 흐름 4.
		document.execCommand("copy");
		// 흐름 5.
		document.body.removeChild(textarea);
		alert("Link copied.");
		//toast.success("Link copied.");
	};

	const [
		{ loading: productLoading, data: productData },
		fetchProduct,
	] = useAxios(productApi.getVideoClassProduct, [], true);
	const [
		{ data: myVCData },
		fetchMyVCPaymentList
	] = useAxios(() => userApi.fetchMyPaymentListByItemType('VIDEOCLASS', token), [], true);

	useEffect(() => {
		if(productData){
			const name = (language) => {
				switch(language){
					case 'ko-KR':
						return productData.title;
					case 'en-US':
						return productData.titleEN;
					case 'zh-CN':
						return productData.titleCN;
					case 'ja-JP':
						return productData.titleJP;
					default:
						return productData.title;
				};
			}

			let videoItem = {
				id: productData.productId, //data.id,
				type: 'Video Class',
				name: name(language),
				price: productData.salesStoreWon,
				priceDollar: productData.salesStoreDollar,
				discountCost: productData.priceCostWon - productData.salesStoreWon,
				discountCostDollar: productData.priceCostDollar - productData.salesStoreDollar,
			};

			dispatch(deleteAllItem());
			dispatch(addItem(videoItem));
			history.push({
				pathname: `/payment/video-class`,
			})
		}
	}, [productData]);

	const productId = (index) => {
		if (process.env.REACT_APP_ENV === "production") {
			switch(index){
				// case 0: return 1200;
				// case 1: return 1201;
				// case 2: return 1202;
				// case 3: return 1203;
				// default: return 1200;
				case 0: return 1213;
				case 1: return 1214;
				default: return 1213;
			}
		}else{
			switch(index){
				// case 0: return 1182;
				// case 1: return 1183;
				// case 2: return 1184;
				// case 3: return 1189;
				// default: return 1182;
				case 0: return 1190;
				case 1: return 1191;
				default: return 1190;
			}
		}
	}

	useEffect(() => {
		if(myVCData && vcProductId >= 0){
			const orderList = myVCData.filter((data)=>data.productIdx === vcProductId);
			if(orderList.length > 0){
				//구매불가능
				handleModal(
					<ConfirmModal 
						tMessage={t('doublePaymentModal.title')} 
						tButton={t('doublePaymentModal.confirm')}
					/>
				)
				return;
			}else{
				//구매가능
				fetchProduct(vcProductId, token)
			}
		}
	},[myVCData, vcProductId])

	const clickApply = (index) => {
		if (!isLogin) {
			handleModal(<Login />, false, 420, location.pathname);
			return;
		}

		setVCProductId(productId(index));
		fetchMyVCPaymentList(token);
	}

	const openLink = (url, target) => {
		const userAgent = window.navigator.userAgent;
		if (userAgent === MOBILE_APP_USER_AGENT) {
			window?.WebInterface?.movetoAndroidBrowser(true, url);
		}else{
			window.open(url, target ? target : '_blank');
		}
	}

	return (
		<Styled.EventWrap>
			<div className='topBanner'>
				<a href='#apply'>
					<strong>{t('topBanner.title')}</strong>
					<div className='desc'>{t('topBanner.desc')}</div>
					<div className="btn">
						{t('topBanner.button')} <span>(~6/13)</span>
					</div>
				</a>
			</div>
			<div className={language === 'ko-KR' ? 'visual ko' : 'visual'}>
				<div className="inner">
					<div className='blind'>
						<h2>{t('visual.title')}</h2>
						<p>{t('visual.desc')}</p>
					</div>
					<div className='btnWrap'>
						<a href='#apply'>{t('visual.button')} <span>(~6/13)</span></a>
					</div>
				</div>
			</div>

			<div className='section3' id="apply">
				<div className='inner'>
				<h2><span>{t('section3.title')}</span></h2>
					<ul>
						<li>
							<div className='top'>
								<h3>{t('section3.lecturer1.title')}</h3>
								<p className='teacher'>{t('section3.lecturer1.teacher')}</p>
							</div>
							<div className='lecturer'>
								<strong>{t('section3.lecturer1.term')}</strong>
								<div>{t('section3.lecturer1.period')}</div>
								<strong>{t('section3.lecturer1.time')}</strong>
								<div>
									18:00-19:50 (KST) <br/>{t('section3.lecturer1.schedule')}
								</div>
							</div>
							<div className='price'>
								<p className='discount'><strong><em>50%</em> off</strong><del>160,000 {t('section3.won')}</del></p>
								<p className='total'><span>{t('section3.total')}</span><strong>80,000 {t('section3.won')}</strong></p>
							</div>
							<div className='btn'>
								<a className='btn_apply' href='javascript:void(0);' onClick={()=>clickApply(0)}>{t('section3.button')}</a>
								<a className='btn_pdf' href='javascript:void(0);'
									onClick={()=>{openLink('http://dlvisang01-visangdown01.cdn.x-cdn.com/MasterTOPIK/MT%20Alive/curriculum_topik(solving)_MT_alive.pdf'); return false;}}>
									{t('section3.pdf')}
								</a>
							</div>
						</li>
						<li>
							<div className='top'>
								<h3>{t('section3.lecturer2.title')}</h3>
								<p className='teacher'>{t('section3.lecturer2.teacher')}</p>
							</div>
							<div className='lecturer'>
								<strong>{t('section3.lecturer2.term')}</strong>
								<div>{t('section3.lecturer2.period')}</div>
								<strong>{t('section3.lecturer2.time')}</strong>
								<div>
									18:00-18:50 (KST) <br/>{t('section3.lecturer2.schedule')}
								</div>
							</div>
							<div className='price'>
								<p className='discount'><strong><em>30%</em> off</strong><del>100,000 {t('section3.won')}</del></p>
								<p className='total'><span>{t('section3.total')}</span><strong>70,000 {t('section3.won')}</strong></p>
							</div>
							<div className='btn'>
								<a className='btn_apply' href='javascript:void(0);' onClick={()=>clickApply(1)}>{t('section3.button')}</a>
								<a className='btn_pdf' href='javascript:void(0);'
									onClick={()=>{openLink('http://dlvisang01-visangdown01.cdn.x-cdn.com/MasterTOPIK/MT%20Alive/curriculum_topik(w)_MT_alive.pdf'); return false;}}>
									{t('section3.pdf')}
								</a>
							</div>
						</li>
					</ul>
				</div>
			</div>

			<div className='section4'>
				<div className='inner'>
					<table>
						<tbody>
							<tr>
								<th><span>{t('section4.group1.title')}</span></th>
								<td><Trans t={t}>section4.group1.desc</Trans></td>
							</tr>
							<tr>
								<th><span>{t('section4.group2.title')}</span></th>
								<td><Trans t={t}>section4.group2.desc</Trans></td>
							</tr>
							<tr>
								<th><span>{t('section4.group3.title')}</span></th>
								<td>
									<ul>
										<li><Trans t={t}>section4.group3.desc1</Trans></li>
										<li><Trans t={t}>section4.group3.desc2</Trans></li>
									</ul>
									<span>* {t('section4.group3.add')}</span>
								</td>
							</tr>
							<tr>
								<th><span>{t('section4.group4.title')}</span></th>
								<td>
									<ul>
										<li><Trans t={t}>section4.group4.desc</Trans></li>
									</ul>
								</td>
							</tr>
							<tr>
								<th><span>{t('section4.group5.title')}</span></th>
								<td>
									<ul>
										<li><Trans t={t}>section4.group5.desc</Trans></li>
									</ul>
								</td>
							</tr>
							<tr>
								<th><span>{t('section4.group6.title')}</span></th>
								<td>
									<strong>{t('section4.group6.stitle')}</strong>
									<ul>
										<li><Trans t={t}>section4.group6.desc1</Trans></li>
										<li><Trans t={t}>section4.group6.desc2</Trans></li>
									</ul>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div className='banner'>
				<div className='inner'>
					<div className='desc'>{t('banner.desc')}</div>
					<a href='javascript:void(0);' onClick={()=>{openLink('https://www.mastertopik.com/courses/detail/1206'); return false;}} className="btn">
						<span>{t('banner.button')}</span>
					</a>
				</div>
			</div>

			<div className='section1'>
				<div className='inner'>
					<h2><span>{t('section1.title')}</span></h2>
					<p className='dsc'>{t('section1.desc')}</p>

					<div className="lecturer">
						<div className='tab_wrap'>
							<ul className={language === 'ko-KR' ? 'tab ko' : 'tab'}>
								<li className={tabActive === 1 ? 'active' : ''}>
									<a onClick={(e) => {
										e.preventDefault();
										setTabActive(1);
									}}
									href="#tab1"
									>{t('section1.tab1')}</a>
								</li>
								<li className={tabActive === 2 ? 'active' : ''}>
									<a onClick={(e) => {
										e.preventDefault();
										setTabActive(2);
									}}
									href="#tab2"
									>{t('section1.tab2')}</a>
								</li>
							</ul>
						</div>
						<div className="tabContent">
							<div className={tabActive === 1 ? "block" : "hide"} id="tab1">
								<p>{t('section1.tabCont1.title')}</p>
								<div className='playerWrap'>
									<ReactPlayer
										controls
										onClick={onPLay}
										playing={playing}
										light= {videoThumbImg}
										url='https://d13owoaeiymgg6.cloudfront.net/vod/cso_kr_reading/topik_cso_alive/HLS/topik_cso_alive.m3u8'
										width='100%'
										height='100%'
									/>
								</div>
								<ul className='tag'>
									<li><span># {t('section1.tabCont1.tag1')}</span></li>
									<li><span># {t('section1.tabCont1.tag2')}</span></li>
									<li><span># {t('section1.tabCont1.tag3')}</span></li>
								</ul>
							</div>
							<div className={tabActive === 2 ? "block" : "hide"} id="tab2">
								<p>{t('section1.tabCont2.title')}</p>
								<div className='playerWrap'>
									<ReactPlayer
										controls
										onClick={onPLay}
										playing={playing}
										light= {videoThumbImg2}
										url='https://d13owoaeiymgg6.cloudfront.net/vod/teacher_introduce/film_kim_mi_sook/HLS/film_kim_mi_sook.m3u8'
										width='100%'
										height='100%'
									/>
								</div>
								<ul className='tag'>
									<li><span># {t('section1.tabCont2.tag1')}</span></li>
									<li><span># {t('section1.tabCont2.tag2')}</span></li>
									<li><span># {t('section1.tabCont2.tag3')}</span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='section2'>
				<div className='inner'>
					<h2><span>{t('section2.title')}</span></h2>

					<Styled.SwiperWrap>
						<Styled.Swiper
								onSwiper={setBenefitSwiper}
								slidesPerView={4}
								spaceBetween={2}
								modules={[Navigation, Thumbs]}
								className='benefitSwiperTab'
							>
							<SwiperSlide>
								<em>01</em>
								<strong><Trans t={t}>section2.slide1.tab</Trans></strong>
							</SwiperSlide>
							<SwiperSlide>
								<em>02</em>
								<strong><Trans t={t}>section2.slide2.tab</Trans></strong>
							</SwiperSlide>
							<SwiperSlide>
								<em>03</em>
								<strong><Trans t={t}>section2.slide3.tab</Trans></strong>
							</SwiperSlide>
							<SwiperSlide>
								<em>04</em>
								<strong><Trans t={t}>section2.slide4.tab</Trans></strong>
							</SwiperSlide>
						</Styled.Swiper>
						<Styled.Swiper
								speed={300}
								autoplay={{
									delay: 3000,
									disableOnInteraction: false
								}}
								loop={true}
								navigation={true}
								thumbs={{ swiper : BenefitSwiper }}
								modules={[Navigation, Thumbs]}
								className='benefitSwiper'
							>
							<SwiperSlide>
								<div className="imgbox">
									{isMobile ? (
										<>
											{/* MOBILE */}
											<img src={benefitLargeImg} alt="" />
										</>
									) : (
										<>
											{/* PC */}
											<img src={benefitImg} alt="" />
										</>
									)}
								</div>
								<div className='text'>
									<Trans t={t}>section2.slide1.desc</Trans>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='imgbox'>
									{isMobile ? (
										<>
											{/* MOBILE */}
											<img src={benefitLargeImg2} alt="" />
										</>
									) : (
										<>
											{/* PC */}
											<img src={benefitImg2} alt="" />
										</>
									)}
								</div>
								<div className='text'>
									<Trans t={t}>section2.slide2.desc</Trans>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='imgbox'>
									{isMobile ? (
										<>
											{/* MOBILE */}
											<img src={benefitLargeImg3} alt="" />
										</>
									) : (
										<>
											{/* PC */}
											<img src={benefitImg3} alt="" />
										</>
									)}
								</div>
								<div className='text'>
									<Trans t={t}>section2.slide3.desc</Trans>
								</div>
							</SwiperSlide>
							<SwiperSlide>
								<div className='imgbox'>
									{isMobile ? (
										<>
											{/* MOBILE */}
											<img src={benefitLargeImg4} alt="" />
										</>
									) : (
										<>
											{/* PC */}
											<img src={benefitImg4} alt="" />
										</>
									)}
								</div>
								<div className='text'>
									<Trans t={t}>section2.slide4.desc</Trans>
								</div>
							</SwiperSlide>
						</Styled.Swiper>
					</Styled.SwiperWrap>
				</div>
			</div>

			<div className='section5'>
				<div className='inner'>
					<h2><span>{t('section5.title')}</span></h2>
					<ol>
						<li>
							<em>STEP 1</em>
							<h3>{t('section5.step1.title')}</h3>
							<img src={stepImg} alt="Study Ahead" />
							<p><Trans t={t}>section5.step1.desc</Trans></p>
						</li>
						<li>
							<em>STEP 2</em>
							<h3>{t('section5.step2.title')}</h3>
							<img src={stepImg2} alt="Take Lecture" />
							<p><Trans t={t}>section5.step2.desc</Trans></p>
						</li>
						<li>
							<em>STEP 3</em>
							<h3>{t('section5.step3.title')}</h3>
							<img src={stepImg3} alt="Review" />
							<p><Trans t={t}>section5.step3.desc</Trans></p>
						</li>
						<li>
							<em>STEP 4</em>
							<h3>{t('section5.step4.title')}</h3>
							<img src={stepImg4} alt="Practice Speaking" />
							<p><Trans t={t}>section5.step4.desc</Trans></p>
						</li>
					</ol>
				</div>
			</div>

			<div className='payment'>
				<div className='inner'>
					<h3>{t('payment.title')}</h3>
					<ul className='desc'>
						<li>{t('payment.desc1')}</li>
						<li>{t('payment.desc2')} [mastertopik@visang.com].</li>
					</ul>
					<ul className='way'>
						<li>
							<h4>{t('payment.way1.title')}</h4>
							<p>
								<Trans t={t}>payment.way1.desc</Trans>
							</p>
							<ul>
								<li>- {t('payment.way1.list1')}</li>
								<li>- {t('payment.way1.list2')}</li>
								<li>- {t('payment.way1.list3')}</li>
								<li>- {t('payment.way1.list4')}</li>
							</ul>
						</li>
						<li>
							<h4>{t('payment.way2.title')}</h4>
							<p>
								<Trans t={t}>payment.way2.desc</Trans>
							</p>
							<h5>{t('payment.way2.stitle')}</h5>
							<ul>
								<li>- {t('payment.way2.list1')} 133-910036-26304</li>
							</ul>
						</li>
					</ul>
				</div>
			</div>

			<div className='shareWrap'>
				<div className='inner'>
					<div className="text">
						<Trans t={t}>shareWrap</Trans>
					</div>
					<div className="btn">
						<a href="javascript:;" onClick={() => doCopy("/event/mtalive")}><img src={linkCopyimg} alt="Copy" /></a>
					</div>
				</div>
			</div>

			<div className='notification'>
				<div className='inner'>
					<h3>{t('notification.title')}</h3>
					<p><strong>FAQ : <a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4420417022099"); return false;}}>{t('notification.link')}</a></strong></p>
					<dl>
						<dd><Trans t={t}>notification.group1.desc1</Trans></dd>
						<dd><Trans t={t}>notification.group1.desc2</Trans></dd>
						<dd><Trans t={t}>notification.group1.desc3</Trans></dd>

						<dt>{t('notification.group2.title')}</dt>
						<dd><Trans t={t}>notification.group2.desc1</Trans></dd>
						<dd><Trans t={t}>notification.group2.desc2</Trans></dd>
						<dd><Trans t={t}>notification.group2.desc3</Trans></dd>
						<dd><Trans t={t}>notification.group2.desc4</Trans></dd>
						<dd><Trans t={t}>notification.group2.desc5</Trans></dd>

						<dt>{t('notification.group3.title')}</dt>
						<dd><strong>{t('notification.group3.desc1')}</strong> : <a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4759778093203"); return false;}}>{t('notification.click')}</a></dd>
						<dd><strong>{t('notification.group3.desc2')}</strong> : <a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4759908099859"); return false;}}>{t('notification.click')}</a></dd>
						<dd><strong>{t('notification.group3.desc3')}</strong> : <a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4420437413907"); return false;}}>{t('notification.click')}</a></dd>
						<dd><strong>{t('notification.group3.desc4')}</strong> : <a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4420635446419"); return false;}}>{t('notification.click')}</a></dd>
					</dl>
					<p>{t('notification.inquiry')} : <a href="mailto:mastertopik@visang.com">mastertopik@visang.com</a></p>
				</div>
			</div>
		</Styled.EventWrap>
  );
};

export default EventPage;
import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';

const StyledCourseViewPage = {
  Wrapper: styled(ContentsWrapper)`
    display: table;
    width: 100%;
    padding-top: 2em;
    padding-bottom: 5em;
    table-layout: fixed;
    &:after {
      display: none;
    }
    > * {
      display: table-cell;
      vertical-align: top;
    }
    .teb-menu {
      margin: 0;
      .menu {
        font-size: 14px;
      }
    }
    @media (max-width: 1024px) {
      display: block;
      font-size: 1.4em;
      > * {
        display: block;
      }
    }
    @media (max-width: 767px) {
      font-size: 1.4em;
    }
  `,
  Container: styled.div`
    border-radius: 0 0 0.6em 0.6em;
    background-color: #191b1f;
    @media (max-width: 1024px) {
      width: auto;
    }
  `,
  Aside: styled.aside`
    width: 34em;
    padding-left: 3em;
    .teb-menu {
      margin-top: 2em;
    }
    @media (max-width: 1024px) {
      width: auto;
      padding: 5em 0 10em;
    }
  `,
};

export default StyledCourseViewPage;

import React, { useState, useEffect } from 'react';
import Style from './styled/PlanSection.style';
import CourseDetailList from './List';

import OverViewList from './OverViewList';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const CourseListContainer = ({
  data,
  data2,
  data3,
  data4,
  data5,
  data6,
  data7,
  data8,
  data9,
  videoRef,
  playing,
  productId,
  productId2,
  productId3,
  productId4,
  productId5,
  productId6,
  productId7,
  productId8,
  productId9,
  lectureId
}) => {

  const { t } = useTranslation('wbmKoreaPage');
  const [listShowIndex, setListShowIndex] = useState(-1);
  const [listShowIndex2, setListShowIndex2] = useState(-1);
  const [listShowIndex3, setListShowIndex3] = useState(-1);
  const [listShowIndex4, setListShowIndex4] = useState(-1);
  const [listShowIndex5, setListShowIndex5] = useState(-1);
  const [listShowIndex6, setListShowIndex6] = useState(-1);
  const [listShowIndex7, setListShowIndex7] = useState(-1);
  const [listShowIndex8, setListShowIndex8] = useState(-1);
  const [listShowIndex9, setListShowIndex9] = useState(-1);
  const [listShowTitle, setListShowTitle] = useState(-1);
  const [listShowTitle2, setListShowTitle2] = useState(-1);
  const [listShowTitle3, setListShowTitle3] = useState(-1);
  const [listShowTitle4, setListShowTitle4] = useState(-1);
  const [listShowTitle5, setListShowTitle5] = useState(-1);
  const [listShowTitle6, setListShowTitle6] = useState(-1);
  const [listShowTitle7, setListShowTitle7] = useState(-1);
  const [listShowTitle8, setListShowTitle8] = useState(-1);
  const [listShowTitle9, setListShowTitle9] = useState(-1);
  const [showTitle, setShowTitle] = useState('');
  const [showTitle2, setShowTitle2] = useState('');
  const [showTitle3, setShowTitle3] = useState('');
  const [showTitle4, setShowTitle4] = useState('');
  const [showTitle5, setShowTitle5] = useState('');
  const [showTitle6, setShowTitle6] = useState('');
  const [showTitle7, setShowTitle7] = useState('');
  const [showTitle8, setShowTitle8] = useState('');
  const [showTitle9, setShowTitle9] = useState('');
  const [overViewPercentage, setOverViewPercentage] = useState(0);

  const currentVideoUrl = useSelector(
    (state) => state.wbmKorea.currentVideoUrl?.provider
  );
  const openAccordion = (index) => {
    setListShowIndex((prev) => (prev === index ? -1 : index));
  };

  const openAccordion2 = (index2) => {
    setListShowIndex2((prev) => (prev === index2 ? -1 : index2));
  };
  const openAccordion3 = (index3) => {
    setListShowIndex3((prev) => (prev === index3 ? -1 : index3));
  };
  const openAccordion4 = (index4) => {
    setListShowIndex4((prev) => (prev === index4 ? -1 : index4));
  };
  const openAccordion5 = (index5) => {
    setListShowIndex5((prev) => (prev === index5 ? -1 : index5));
  };
  const openAccordion6 = (index6) => {
    setListShowIndex6((prev) => (prev === index6 ? -1 : index6));
  };
  const openAccordion7 = (index7) => {
    setListShowIndex7((prev) => (prev === index7 ? -1 : index7));
  };
  const openAccordion8 = (index8) => {
    setListShowIndex8((prev) => (prev === index8 ? -1 : index8));
  };
  const openAccordion9 = (index9) => {
    setListShowIndex9((prev) => (prev === index9 ? -1 : index9));
  };

  // 상품 리스트 열고 닫기
  const openTitleAccordion = (index) => {
    setListShowTitle((prev) => (prev === index ? -1 : index));
    if(listShowTitle === -1){
      setListShowIndex(-1);
      setShowTitle('');
    }else{
      setShowTitle('active');
    }
  };
  const openTitleAccordion2 = (index2) => {
    setListShowTitle2((prev) => (prev === index2 ? -1 : index2));
    if(listShowTitle2 === -1){
      setListShowIndex2(-1);
      setShowTitle2('');
    }else{
      setShowTitle2('active');
    }
  };
  const openTitleAccordion3 = (index3) => {
    setListShowTitle3((prev) => (prev === index3 ? -1 : index3));
    if(listShowTitle3 === -1){
      setListShowIndex3(-1);
      setShowTitle3('');
    }else{
      setShowTitle3('active');
    }
  };
  const openTitleAccordion4 = (index4) => {
    setListShowTitle4((prev) => (prev === index4 ? -1 : index4));
    if(listShowTitle4 === -1){
      setListShowIndex4(-1);
      setShowTitle4('');
    }else{
      setShowTitle4('active');
    }
  };
  const openTitleAccordion5 = (index5) => {
    setListShowTitle5((prev) => (prev === index5 ? -1 : index5));
    if(listShowTitle5 === -1){
      setListShowIndex5(-1);
      setShowTitle5('');
    }else{
      setShowTitle5('active');
    }
  };
  const openTitleAccordion6 = (index6) => {
    setListShowTitle6((prev) => (prev === index6 ? -1 : index6));
    if(listShowTitle6 === -1){
      setListShowIndex6(-1);
      setShowTitle6('');
    }else{
      setShowTitle6('active');
    }
  };
  const openTitleAccordion7 = (index7) => {
    setListShowTitle7((prev) => (prev === index7 ? -1 : index7));
    if(listShowTitle7 === -1){
      setListShowIndex7(-1);
      setShowTitle7('');
    }else{
      setShowTitle7('active');
    }
  };
  const openTitleAccordion8 = (index8) => {
    setListShowTitle8((prev) => (prev === index8 ? -1 : index8));
    if(listShowTitle8 === -1){
      setListShowIndex8(-1);
      setShowTitle8('');
    }else{
      setShowTitle8('active');
    }
  };
  const openTitleAccordion9 = (index9) => {
    setListShowTitle9((prev) => (prev === index9 ? -1 : index9));
    if(listShowTitle9 === -1){
      setListShowIndex9(-1);
      setShowTitle9('');
    }else{
      setShowTitle9('active');
    }
  };
  useEffect(() => {
    const getPercentage = setInterval(() => {
      if (videoRef.current) {
        let percentage =
          (videoRef.current.getCurrentTime() /
          videoRef.current.getDuration()) *
          100;
        setOverViewPercentage(percentage);
      } else {
        setOverViewPercentage(0);
      }
    }, 1000);
    return () => clearInterval(getPercentage);
  }, [currentVideoUrl]);

  useEffect(()=>{
    if(data){
      data.forEach((value, index)=>{
        value.lessonResponseList.forEach((child, childIdx) => {
          if(child.chapterLectureId === Number(lectureId)){
            setListShowIndex(index);
          }
        });
      });
    }

    if(data2){
      data2.forEach((value, index)=>{
        value.lessonResponseList.forEach((child, childIdx) => {
          if(child.chapterLectureId === Number(lectureId)){
            setListShowIndex2(index);
          }
        });
      });
    }

    if(data3){
      data3.forEach((value, index)=>{
        value.lessonResponseList.forEach((child, childIdx) => {
          if(child.chapterLectureId === Number(lectureId)){
            setListShowIndex3(index);
          }
        });
      });
    }

    if(data4){
      data4.forEach((value, index)=>{
        value.lessonResponseList.forEach((child, childIdx) => {
          if(child.chapterLectureId === Number(lectureId)){
            setListShowIndex4(index);
          }
        });
      });
    }

    if(data5){
      data5.forEach((value, index)=>{
        value.lessonResponseList.forEach((child, childIdx) => {
          if(child.chapterLectureId === Number(lectureId)){
            setListShowIndex5(index);
          }
        });
      });
    }

    if(data6){
      data6.forEach((value, index)=>{
        value.lessonResponseList.forEach((child, childIdx) => {
          if(child.chapterLectureId === Number(lectureId)){
            setListShowIndex6(index);
          }
        });
      });
    }

    if(data7){
      data7.forEach((value, index)=>{
        value.lessonResponseList.forEach((child, childIdx) => {
          if(child.chapterLectureId === Number(lectureId)){
            setListShowIndex7(index);
          }
        });
      });
    }

    if(data8){
      data8.forEach((value, index)=>{
        value.lessonResponseList.forEach((child, childIdx) => {
          if(child.chapterLectureId === Number(lectureId)){
            setListShowIndex8(index);
          }
        });
      });
    }
    if(data9){
      data9.forEach((value, index)=>{
        value.lessonResponseList.forEach((child, childIdx) => {
          if(child.chapterLectureId === Number(lectureId)){
            setListShowIndex9(index);
          }
        });
      });
    }

  },[]);


  useEffect(()=>{
    setListShowTitle(0);
    setListShowTitle2(0);
    setListShowTitle3(0);
    setListShowTitle4(0);
    setListShowTitle5(0);
    setListShowTitle6(0);
    setListShowTitle7(0);
    setListShowTitle8(0);
    setListShowTitle9(0);

  },[]);

  return (
    <Style.ListContainer>
      <h2>{t('stitle2')}</h2>
      <div className='title'>
        <span>
          {/* {totalClassNo} classes, {totalHour}hr */}
        </span>
      </div>
      {/* 상품 리스트 */}
      <Style.Accordion>
        <Style.CourseItem onClick={() => openTitleAccordion(0)}>
          <Style.CourseItemTitle>배워서 바로 쓰는 한국어 초급1 (일본어)</Style.CourseItemTitle>
          <Style.ArrowSpan show={showTitle}></Style.ArrowSpan>
        </Style.CourseItem>
        {/* 챕터 리스트 */}
        <Style.CourseItemList show={showTitle}>
          <OverViewList
              percentage={overViewPercentage}
              title='Lecture overview'
              playing={playing}
              lectureId={lectureId}
          />
          {data &&
              data.map((data, index) => {
                const {
                  id,
                  title,
                  hour,
                  minute,
                  lessonCount,
                  lessonResponseList,
                } = data;
                return (
                    <CourseDetailList
                        key={id}
                        title={title}
                        chapterHour={hour}
                        chapterMinute={minute}
                        lessonCount={lessonCount}
                        lessonList={lessonResponseList}
                        onClick={() => openAccordion(index)}
                        show={listShowIndex === index && 'active'}
                        videoRef={videoRef}
                        playing={playing}
                    />
                );
              })}
          {/* {!loginStatus && data && data.length > 5 && (
                  <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
                    {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
                  </Style.MoreBtn>
                )} */}
        </Style.CourseItemList>
      </Style.Accordion>

      <Style.Accordion>
        <Style.CourseItem onClick={() => openTitleAccordion2(0)}>
          <Style.CourseItemTitle>VISANG Korean (Beginner 1-A)</Style.CourseItemTitle>
          <Style.ArrowSpan show={showTitle2}></Style.ArrowSpan>
        </Style.CourseItem>
        {/* 챕터 리스트 */}
        <Style.CourseItemList show={showTitle2}>
          {data2 &&
              data2.map((data2, index) => {
                const {
                  id,
                  title,
                  hour,
                  minute,
                  lessonCount,
                  lessonResponseList,
                } = data2;
                return (
                    <CourseDetailList
                        key={id}
                        title={title}
                        chapterHour={hour}
                        chapterMinute={minute}
                        lessonCount={lessonCount}
                        lessonList={lessonResponseList}
                        onClick={() => openAccordion2(index)}
                        show={listShowIndex2 === index && 'active'}
                        videoRef={videoRef}
                        playing={playing}
                    />
                );
              })}
          {/* {!loginStatus && data && data.length > 5 && (
                  <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
                    {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
                  </Style.MoreBtn>
                )} */}
        </Style.CourseItemList>
      </Style.Accordion>

      <Style.Accordion>
        <Style.CourseItem onClick={() => openTitleAccordion3(0)}>
          <Style.CourseItemTitle>VISANG Korean (Beginner 1-B)</Style.CourseItemTitle>
          <Style.ArrowSpan show={showTitle3}></Style.ArrowSpan>
        </Style.CourseItem>
        {/* 챕터 리스트 */}
        <Style.CourseItemList show={showTitle3}>
          {data3 &&
              data3.map((data3, index) => {
                const {
                  id,
                  title,
                  hour,
                  minute,
                  lessonCount,
                  lessonResponseList,
                } = data3;
                return (
                    <CourseDetailList
                        key={id}
                        title={title}
                        chapterHour={hour}
                        chapterMinute={minute}
                        lessonCount={lessonCount}
                        lessonList={lessonResponseList}
                        onClick={() => openAccordion3(index)}
                        show={listShowIndex3 === index && 'active'}
                        videoRef={videoRef}
                        playing={playing}
                    />
                );
              })}
          {/* {!loginStatus && data && data.length > 5 && (
                  <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
                    {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
                  </Style.MoreBtn>
                )} */}
        </Style.CourseItemList>
      </Style.Accordion>

      <Style.Accordion>
        <Style.CourseItem onClick={() => openTitleAccordion4(0)}>
          <Style.CourseItemTitle>배워서 바로 쓰는 한국어 초급1 (영어)</Style.CourseItemTitle>
          <Style.ArrowSpan show={showTitle4}></Style.ArrowSpan>
        </Style.CourseItem>
        {/* 챕터 리스트 */}
        <Style.CourseItemList show={showTitle4}>
                    {data4 &&
              data4.map((data4, index) => {
                const {
                  id,
                  title,
                  hour,
                  minute,
                  lessonCount,
                  lessonResponseList,
                } = data4;
                return (
                    <CourseDetailList
                        key={id}
                        title={title}
                        chapterHour={hour}
                        chapterMinute={minute}
                        lessonCount={lessonCount}
                        lessonList={lessonResponseList}
                        onClick={() => openAccordion4(index)}
                        show={listShowIndex4 === index && 'active'}
                        videoRef={videoRef}
                        playing={playing}
                    />
                );
              })}
          {/* {!loginStatus && data && data.length > 5 && (
                  <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
                    {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
                  </Style.MoreBtn>
                )} */}
        </Style.CourseItemList>
      </Style.Accordion>
      <Style.Accordion>
        <Style.CourseItem onClick={() => openTitleAccordion5(0)}>
          <Style.CourseItemTitle>배워서 바로 쓰는 한국어 초급2 (한국어)	</Style.CourseItemTitle>
          <Style.ArrowSpan show={showTitle5}></Style.ArrowSpan>
        </Style.CourseItem>
        {/* 챕터 리스트 */}
        <Style.CourseItemList show={showTitle5}>
          {data5 &&
              data5.map((data5, index) => {
                const {
                  id,
                  title,
                  hour,
                  minute,
                  lessonCount,
                  lessonResponseList,
                } = data5;
                return (
                    <CourseDetailList
                        key={id}
                        title={title}
                        chapterHour={hour}
                        chapterMinute={minute}
                        lessonCount={lessonCount}
                        lessonList={lessonResponseList}
                        onClick={() => openAccordion5(index)}
                        show={listShowIndex5 === index && 'active'}
                        videoRef={videoRef}
                        playing={playing}
                    />
                );
              })}
          {/* {!loginStatus && data && data.length > 5 && (
                  <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
                    {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
                  </Style.MoreBtn>
                )} */}
        </Style.CourseItemList>
      </Style.Accordion>
      <Style.Accordion>
        <Style.CourseItem onClick={() => openTitleAccordion6(0)}>
          <Style.CourseItemTitle>배워서 바로 쓰는 한국어 중급1</Style.CourseItemTitle>
          <Style.ArrowSpan show={showTitle6}></Style.ArrowSpan>
        </Style.CourseItem>
        {/* 챕터 리스트 */}
        <Style.CourseItemList show={showTitle6}>
          {data6 &&
              data6.map((data6, index) => {
                const {
                  id,
                  title,
                  hour,
                  minute,
                  lessonCount,
                  lessonResponseList,
                } = data6;
                return (
                    <CourseDetailList
                        key={id}
                        title={title}
                        chapterHour={hour}
                        chapterMinute={minute}
                        lessonCount={lessonCount}
                        lessonList={lessonResponseList}
                        onClick={() => openAccordion6(index)}
                        show={listShowIndex6 === index && 'active'}
                        videoRef={videoRef}
                        playing={playing}
                    />
                );
              })}
          {/* {!loginStatus && data && data.length > 5 && (
                  <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
                    {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
                  </Style.MoreBtn>
                )} */}
        </Style.CourseItemList>
      </Style.Accordion>
      <Style.Accordion>
        <Style.CourseItem onClick={() => openTitleAccordion7(0)}>
          <Style.CourseItemTitle>배워서 바로 쓰는 한국어 중급2</Style.CourseItemTitle>
          <Style.ArrowSpan show={showTitle7}></Style.ArrowSpan>
        </Style.CourseItem>
        {/* 챕터 리스트 */}
        <Style.CourseItemList show={showTitle7}>
          {data7 &&
              data7.map((data7, index) => {
                const {
                  id,
                  title,
                  hour,
                  minute,
                  lessonCount,
                  lessonResponseList,
                } = data7;
                return (
                    <CourseDetailList
                        key={id}
                        title={title}
                        chapterHour={hour}
                        chapterMinute={minute}
                        lessonCount={lessonCount}
                        lessonList={lessonResponseList}
                        onClick={() => openAccordion7(index)}
                        show={listShowIndex7 === index && 'active'}
                        videoRef={videoRef}
                        playing={playing}
                    />
                );
              })}
          {/* {!loginStatus && data && data.length > 5 && (
                  <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
                    {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
                  </Style.MoreBtn>
                )} */}
        </Style.CourseItemList>
      </Style.Accordion>
      <Style.Accordion>
        <Style.CourseItem onClick={() => openTitleAccordion8(0)}>
          <Style.CourseItemTitle>배워서 바로 쓰는 한국어 고급1</Style.CourseItemTitle>
          <Style.ArrowSpan show={showTitle8}></Style.ArrowSpan>
        </Style.CourseItem>
        {/* 챕터 리스트 */}
        <Style.CourseItemList show={showTitle8}>
          {data8 &&
              data8.map((data8, index) => {
                const {
                  id,
                  title,
                  hour,
                  minute,
                  lessonCount,
                  lessonResponseList,
                } = data8;
                return (
                    <CourseDetailList
                        key={id}
                        title={title}
                        chapterHour={hour}
                        chapterMinute={minute}
                        lessonCount={lessonCount}
                        lessonList={lessonResponseList}
                        onClick={() => openAccordion8(index)}
                        show={listShowIndex8 === index && 'active'}
                        videoRef={videoRef}
                        playing={playing}
                    />
                );
              })}
          {/* {!loginStatus && data && data.length > 5 && (
                  <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
                    {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
                  </Style.MoreBtn>
                )} */}
        </Style.CourseItemList>
      </Style.Accordion>

      <Style.Accordion>
        <Style.CourseItem onClick={() => openTitleAccordion9(0)}>
          <Style.CourseItemTitle>배워서 바로 쓰는 한국어 고급2</Style.CourseItemTitle>
          <Style.ArrowSpan show={showTitle9}></Style.ArrowSpan>
        </Style.CourseItem>
        {/* 챕터 리스트 */}
        <Style.CourseItemList show={showTitle9}>
          {data9 &&
              data9.map((data9, index) => {
                const {
                  id,
                  title,
                  hour,
                  minute,
                  lessonCount,
                  lessonResponseList,
                } = data9;
                return (
                    <CourseDetailList
                        key={id}
                        title={title}
                        chapterHour={hour}
                        chapterMinute={minute}
                        lessonCount={lessonCount}
                        lessonList={lessonResponseList}
                        onClick={() => openAccordion9(index)}
                        show={listShowIndex9 === index && 'active'}
                        videoRef={videoRef}
                        playing={playing}
                    />
                );
              })}
          {/* {!loginStatus && data && data.length > 5 && (
                  <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
                    {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
                  </Style.MoreBtn>
                )} */}
        </Style.CourseItemList>
      </Style.Accordion>

    </Style.ListContainer>
  );
};
export default CourseListContainer;
// export default React.memo(CourseListContainer);

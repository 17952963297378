import styled, { css } from "styled-components";

export const contentsWidth = "1300px";
export const sidePadding = "20px";

export const ContentsWrapperStyles = css`
  position: relative;
  max-width: calc(${contentsWidth} + ${sidePadding} * 2);
  padding: 0 ${sidePadding};
  margin: auto;
`;

const ContentsWrapper = styled.div`
  ${ContentsWrapperStyles}

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;

export default ContentsWrapper;

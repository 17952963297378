import React from 'react';
import Styled from './styled/Language.style';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { languageCode } from 'common/constant';

const list = [
  {
    name: languageCode.KOREAN.code.toUpperCase(),
    value: languageCode.KOREAN.locale,
  },
  {
    name: languageCode.JAPANESE.code.toUpperCase(),
    value: languageCode.JAPANESE.locale,
  },
  {
    name: languageCode.CHINESE.code.toUpperCase(),
    value: languageCode.CHINESE.locale,
  },
  {
    name: languageCode.ENGLISH.code.toUpperCase(),
    value: languageCode.ENGLISH.locale,
  },
];

const Language = () => {
  const { i18n } = useTranslation();
  const clickLink = (value) => {
    i18n.changeLanguage(value);
    window.location.reload();
  };

  let count = 0;
  return (
    <Styled.Container className='header-lang-wrap'>
      <Styled.List>
        {list.map(({ name, value }, index) => {


            if(value !== i18n.language){
              count++
            }


            return <Styled.Item
                      key={index}
                      className={`${value === i18n.language && 'active'} ${value !== i18n.language && 'nav-real-'+count}`}
                    >
                      <Link to='#' onClick={() => clickLink(value)}>
                        {
                          name == 'KO' ? "한국어" : name == "EN" ? 'English' : name  == 'CN' ? '中國话' : "日本語"

                        }
                      </Link>
                    </Styled.Item>
          }
        )}
      </Styled.List>
    </Styled.Container>
  );
};

export default Language;

import React, { useState } from 'react';
import Style from '../styled/component.style';
import ResultChoice from 'container/MockTestPage/Components/ResultChoice';

const ResultOmrCard = ({ data }) => {
  const [open, showOpen] = useState(false);

  return (
    <Style.OmrCardContainer className={open && 'open'}>
      <button type='button' onClick={() => showOpen((prev) => !prev)}>
        답안지
      </button>
      <Style.OmrCard>
        {data.map((question) => {
          const { userAnswer, correctNo } = question;
          return (
            <ResultChoice
              key={question.unitValuationId}
              answers={{ userAnswer, correctNo }}
            />
          );
        })}
      </Style.OmrCard>
    </Style.OmrCardContainer>
  );
};

export default ResultOmrCard;

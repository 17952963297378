import styled from 'styled-components';
import StyledCourseCard from 'component/ui/card/styled/courseCard.style';
import Img from 'component/atoms/Img';

const StyledPlanSection = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    margin: 10em 0 10em 0;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `,

  CoursePlan: styled.div`
    flex: 1;
    margin-right: 4em;
    > div {
      // player 상위에 div가 고정픽셀로 들어가고 있어 퍼센트를 강제로 지정
      width: 100% !important;
      height: 100% !important;
    }
    @media (max-width: 767px) {
      margin-right: 0;
    }
    h2 {
      margin-bottom: 1.2em;
      font-size: 3em;
      font-weight: 700;
    }
    @media (max-width: 767px) {
      margin-top: 2em;
      font-size: 1.25em;
    }
    .plyr {
      height: 43em;
    }
  `,

  ImageContainer: styled(StyledCourseCard.ImageContainer)`
    height: 43em;
  `,

  Img: styled(Img).attrs({
    alt: 'Course Thumbnail',
  })`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `,

  ListContainer: styled.div`
    flex: 1;

    h2 {
      margin-bottom: 0.6em;
      font-size: 3em;
      font-weight: 700;
    }
    @media (max-width: 767px) {
      margin-top: 2em;
      font-size: 1.25em;
    }
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 3em;
      line-height: 5em;
      @media (max-width: 767px) {
        margin-bottom: 1em;
      }
      > h3 {
        display: inline-block;
        vertical-align: 1px;
        font-size: 2em;
        font-weight: 700;
      }
      > span {
        float: right;
        color: #8e9199;
        vertical-align: 1px;
        font-size: 1.6em;
        font-weight: 400;
      }
    }
  `,
  MoreBtn: styled.span`
    float: right;
    margin-top: 1.5em;
    font-size: 1.6em;
    font-weight: 700;
    cursor: pointer;

    > svg {
      vertical-align: middle;
    }
  `,
};

export default StyledPlanSection;

import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';

const StyledCourseDetailPage = {
  Container: styled(ContentsWrapper)`
    margin-bottom: 16.5em;
  `,
};

export default StyledCourseDetailPage;

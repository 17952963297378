export const quizData = [
  {
    id: 1,
    question: 'Choose the correct answer.',
    image: require('assets/images/levelTest-Illustration01.jpg').default,
    alt: 'figure Image',
    description: [
      '학생들 : 안녕히 계세요.',
      '선생님 : <span class="blah"><span>',
    ],
    choices: [
      '안녕하세요.',
      '어서 오세요.',
      '안녕히 가세요.',
      '만나서 반갑습니다.',
    ],
    correctAnswer: 2,
  },
  {
    id: 2,
    question: 'Choose the correct answer that fits in ㉠㉡.',
    image: require('assets/images/levelTest-Illustration02.jpg').default,
    alt: 'figure Image',
    description: ['책상 ㉠ 에 책이 있어요.', '가방 ㉡ 에 컵이 있어요.'],
    choices: [
      '㉠ 위 - ㉡ 뒤',
      '㉠ 아래 - ㉡ 앞',
      '㉠ 위 - ㉡ 오른쪽',
      '㉠ 아래 – ㉡ 왼쪽',
    ],
    correctAnswer: 3,
  },

  {
    id: 3,
    question: 'Choose the correct answer about the picture.',
    image: require('assets/images/levelTest-Illustration03.jpg').default,
    alt: 'figure Image',
    description: null,
    choices: [
      '물 한 명과 사과 셋 개가 있어요.',
      '물 한 병과 과자 세 개가 있어요.',
      '우유 한 명과 가자 세 개가 있어요.',
      '우유 한 병과 과자 네 개가 있어요.',
    ],
    correctAnswer: 1,
  },
  {
    id: 4,
    question: 'Choose the most appropriate answer that fits in the blank.',
    image: require('assets/images/levelTest-Illustration04.jpg').default,
    alt: 'figure Image',
    description: [
      'A : 동동 씨, 지금 뭐 해요?',
      'B : 저는 지금 (<span class="blank"></span>)',
    ],
    choices: [
      '학교에 가요.',
      '영화관에 가요.',
      '병원에서 가요.',
      '영화관에서 가요.',
    ],
    correctAnswer: 1,
  },
  {
    id: 5,
    question: 'Choose the correct answer that fits in ㉠㉡.',
    image: null,
    alt: 'figure Image',
    description: [
      'A: 지난 주말에 뭐 했어요?',
      'B: 공원에서 운동했어요.',
      'A: 자전거를 ( ㉠ )',
      'B: 아니요, 친구하고 같이 테니스를 ( ㉡ ).',
    ],
    choices: [
      '㉠ 탔어요? - ㉡ 쳤어요.',
      '㉠ 탔어요? - ㉡ 놀았어요.',
      '㉠ 했어요? - ㉡ 쳤어요.',
      '㉠ 했어요? - ㉡ 놀았어요.',
    ],
    correctAnswer: 0,
  },
  {
    id: 6,
    question: "This is tomorrow's weather, choose the incorrect answer.",
    image: require('assets/images/levelTest-Illustration05.jpg').default,
    alt: 'figure Image',
    description: null,
    choices: [
      '춘천은 맑고 덥겠습니다.',
      '부산은 흐리고 눈이 오겠습니다.',
      '내일 광주에는 비가 오겠습니다.',
      '서울은 조금 흐리지만 비는 안 오겠습니다.',
    ],
    correctAnswer: 1,
  },
  {
    id: 7,
    question: 'Choose the most appropriate answer that fits in the blank',
    image: require('assets/images/levelTest-Illustration06.jpg').default,
    alt: 'figure Image',
    description: [
      'A: 처음 보는 이어폰이네요?',
      'B: K-POP 노래를 (<span class="blank"></span>) 샀어요.',
    ],
    choices: ['들으러', '들으려고', '들으려면', '들으면서'],
    correctAnswer: 1,
  },
  {
    id: 8,
    question: 'Choose the most appropriate answer that fits in the blank.',
    image: require('assets/images/levelTest-Illustration07.jpg').default,
    alt: 'figure Image',
    description: [
      'A: 박물관 안에서는 <span class="blah"></span>',
      'B: 네, 알겠습니다. 죄송합니다.',
    ],
    choices: [
      '카메라를 찍으세요.',
      '사진을 하지 마세요.',
      '사진을 찍지 마세요.',
      '카메라가 찍으면 안 돼요.',
    ],
    correctAnswer: 2,
  },
  {
    id: 9,
    question: 'Choose the incorrect answer about what doctor would say.',
    image: require('assets/images/levelTest-Illustration08.jpg').default,
    alt: 'figure Image',
    description: [
      'A: 어제부터 열도 나고 기침을 해요.',
      'B:<span class="blah"></span>',
    ],
    choices: [
      '옷을 따뜻하게 입으세요.',
      '약을 먹고 집에서 푹 쉬세요.',
      '차가운 음식을 먹으면 안 돼요.',
      '계속 아프면 병원에 오지 마세요.',
    ],
    correctAnswer: 3,
  },
  {
    id: 10,
    question:
      'Choose the correct answer that summarizes the content of the dialogue.',
    image: null,
    alt: 'figure Image',
    description: [
      'A: 민수씨, 아르바이트할 곳을 찾았어요?',
      'B: 아니요. 아직 못 찾았어요.',
      'A: 제가 일하는 백화점에서 아르바이트할 사람을 찾고 있는데, 혹시 생각 있어요?',
      'B: 백화점에서 아르바이트는 안 해 봤는데 한번 해 보고 싶네요.',
    ],
    choices: [
      '여자는 남자와 같은 일을 합니다.',
      '여자는 백화점에서 일하고 있습니다.',
      '남자는 아르바이트를 해 봤습니다.',
      '남자는 아르바이트를 안 하려고 합니다.',
    ],
    correctAnswer: 1,
  },
  {
    id: 11,
    question: '알맞은 대답을 고르십시오.',
    image: null,
    alt: 'figure Image',
    description: [
      'A: 마크 씨 소식 들었어요?',
      '이번 가을에 결혼을 한다고 해요.',
      'B: <span class="blah"></span>.',
    ],
    choices: [
      '정말요? 결혼했는지 몰랐어요.',
      '정말요? 꼭 연락 주셔야 돼요.',
      '그래요? 정말 좋은 소식이네요.',
      '그래요? 오랫동안 못 만났어요.',
    ],
    correctAnswer: 2,
  },
  {
    id: 12,
    question: '다음 밑줄 친 부분과 의미가 비슷한 것을 고르십시오.',
    image: null,
    alt: 'figure Image',
    description: [
      'A: 미나씨는 요즘 좋아 보이네요.',
      'B: 그러게요, 요새 계속 웃고 다니는 걸 보니까 <span class="underline">좋은 일이 있나 봐요.</span>',
    ],
    choices: [
      '있거든요.',
      '있는 편이에요.',
      '있는 척이에요.',
      '있는 모양이에요.',
    ],
    correctAnswer: 3,
  },
  {
    id: 13,
    question: '공통으로 들어갈 단어를 고르십시오.',
    image: null,
    alt: 'figure Image',
    description: [
      '✔ 친구는 좋은 기회를 (<span class="blank"></span>) 유학을 갈 수 있었다.',
      '✔ 나는 학교 앞에 원룸을 (<span class="blank"></span>) 자취를 시작했다.',
      '✔ 그는 뮤지컬 덕분에 전세계적으로 인기를 (<span class="blank"></span>)',
    ],
    choices: ['얻다', '받다', '갖다', '오르다'],
    correctAnswer: 0,
  },
  {
    id: 14,
    question: '다음 글의 내용과 다른 것을 고르십시오.',
    image: require('assets/images/levelTest-Illustration09.jpg').default,
    alt: 'figure Image',
    description: null,
    choices: [
      '정장은 4일 동안 빌릴 수 있다.',
      '회원 가입을 해야 정장을 이용할 수 있다.',
      '취업을 준비하는 사람에게 무료로 정장을 빌려준다.',
      '정장이 필요한 사람들은 누구나 정장을 빌릴 수 있다.',
    ],
    correctAnswer: 3,
  },
  {
    id: 15,
    question: '다음 밑줄 친 부분과 의미가 비슷한 것을 고르십시오.',
    image: null,
    alt: 'figure Image',
    description: [
      'A: 서울에서 혼자 산 지 5년이 넘었는데 안 외로워요?',
      'B: 아직까지는 괜찮아요. 그런데 몸이 아프면 엄마가 많이 생각나요.',
      'A: 맞아요. 누구나 아프면 가족들이 <span class="underline">생각나기 마련이에요.</span> 힘내세요.',
    ],
    choices: [
      '생각날 지경이에요.',
      '생각나는 편이에요.',
      '생각나야 할 거예요.',
      '생각나게 되어 있어요.',
    ],
    correctAnswer: 3,
  },
  {
    id: 16,
    question: '남자의 중심 생각을 고르십시오.',
    image: null,
    alt: 'figure Image',
    description: [
      '남자: 지은 씨, 뭐 하세요?',
      '여자: 동호회에서 등산을 가는데 인터넷으로 옷 한 벌 사려고요. 백화점에 직접 가는 것보다 시간도 아낄 수 있고 가격도 저렴하거든요.',
      '남자: 시간도 시간이지만 가서 입어보고 고르는 게 좋지 않아요? 색깔이나 모양이 화면으로 보는 것과 다른 경우도 많잖아요.',
    ],
    choices: [
      '여행갈 때는 새 옷을 사야 한다.',
      '쇼핑할 때 가격을 따져 봐야 한다.',
      '살 옷은 직접 입어보고 사는 게 낫다.',
      '인터넷 쇼핑은 시간을 아낄 수 있다.',
    ],
    correctAnswer: 2,
  },
  {
    id: 17,
    question: '(   )에 들어갈 가장 알맞은 것을 고르십시오.',
    image: null,
    alt: 'figure Image',
    description: [
      '뮤지컬은 한 역할에 여러 배우들이 출연한다. 배우에 따라 연기나 분위기가 달라지기 때문에 같은 작품이라도 색다른 느낌을 받을 수 있다. 그래서 뮤지컬 팬들은 (<span class="blank"></span>) 작품을 즐기기 위해 똑같은 공연이라도 반복해서 관람하곤 한다. ',
    ],
    choices: [
      '각 배우들의 개성이 담긴',
      '공연장에서 인기가 있는',
      '유행하는 노래가 나오는',
      '좋아하는 배우가 등장하는',
    ],
    correctAnswer: 0,
  },
  {
    id: 18,
    question: '(   )에 들어갈 가장 알맞은 것을 고르십시오.',
    image: null,
    alt: 'figure Image',
    description: [
      'A: 아니, 어제 가게에서 이상한 손님을 만났어요.',
      'B: 왜요? 손님이 어떻게 했어요?',
      'A: 구매한 지 1년이 지났는데 환불해 달라고 떼를 쓰잖아요. ',
      'B : 그 손님 정말 (<span class="blank"></span>)',
    ],
    choices: [
      '눈이 높네요.',
      '콧대가 높네요.',
      '귀가 따갑네요.',
      '얼굴이 두껍네요.',
    ],
    correctAnswer: 3,
  },
  {
    id: 19,
    question: '알맞은 대답을 고르십시오.',
    image: null,
    alt: 'figure Image',
    description: [
      'A: 운동을 하다 안 하다 해서 별로 효과가 없는 것 같아요.',
      'B:<span class="blah"></span>',
    ],
    choices: [
      '맞아요. 운동은 안 하다가 하면 효과가 없어요.',
      '글쎄요. 운동을 하다가 안 하면 효과가 없어요.',
      '글쎄요. 운동은 규칙적으로 해야 효과가 나와요.',
      '맞아요. 운동은 규칙적으로 해야 효과가 있어요.',
    ],
    correctAnswer: 3,
  },
  {
    id: 20,
    question: '다음을 읽고 내용이 같은 것을 고르십시오.',
    image: null,
    alt: 'figure Image',
    description: [
      '매년 많은 사람들이 제주도의 올레길을 찾고 있다. 올레길은 제주도에서 만든 도보여행 코스로 ‘올레’는 제주 방언으로 좁은 골목을 말한다. 올레길은 자동차가 다니지 않아 걷기 좋고, 길이 계속 이어져 걷고 싶은 만큼 걸을 수 있다. 각 코스는 평균 5-6시간 소요되며, 주로 제주도의 해안 지역을 따라 골목, 산길, 들길, 해안길, 제주도 주변의 작은 섬을 도는 코스도 있다. 계획적인 코스개발과 홍보로 제주 올레길은 제주도의 관광사업에 크게 기여할 수 있었다.',
    ],
    choices: [
      '올레는 좁은 골목을 뜻하는 외국어이다.',
      '제주도의 올레길은 자동차로도 갈 수 있다.',
      '제주도의 올레길은 여유롭게 자연을 감상할 수 있다.',
      '제주도의 올레길은 짧게 끊어져 있기 때문에 걷기 좋다.',
    ],
    correctAnswer: 2,
  },
];

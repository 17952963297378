import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { Link } from 'react-router-dom';

import icoTest from 'assets/images/ico_completeSingup_test.png';
import icoETC from 'assets/images/ico_completeSingup_etc.png';
import icoETC2 from 'assets/images/ico_completeSingup_etc2.png';
import icoETC3 from 'assets/images/ico_completeSingup_etc3.png';

import { BsChevronDown } from 'react-icons/bs';
import { FaCheckCircle } from 'react-icons/fa';

const StyledCompleteSignUpPage = {
  Container: styled.div`
    padding-top: 10rem;
    padding-bottom: 20rem;
  `,
  Wrapper: styled(ContentsWrapper)`
    text-align: center;
  `,
  Welcome: styled.div.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <BsChevronDown className='icoArrow' />
      </>
    ),
  }))`
    position: relative;
    max-width: 534px;
    margin: 0 auto 3em;
    padding-bottom: 3em;
    color: #fff;
    .icoArrow {
      position: absolute;
      left: 50%;
      bottom: -0.2em;
      transform: translateX(-50%);
      font-size: 3em;
      color: #666666;
    }
  `,
  H2: styled.h2`
    font-size: 3.4em;
    margin-bottom: 0.2em;
    font-weight: 600;
  `,
  Name: styled.span`
    display: inline-block;
    &::after {
      content: '🎉';
    }
  `,
  Text: styled.p`
    font-size: 2em;
  `,
  Recomend: styled.div`
    position: relative;
    max-width: 534px;
    margin: 0 auto 5em;
    color: #fff;
    h2 {
      font-size: 2.6em;
    }
    p {
      font-size: 1.8em;
    }
  `,
  RecommendedCourse: styled.div`
    padding: 5em 20px 6em;
    background: #1e2024;
  `,
  StepWrap: styled.div`
    max-width: 534px;
    margin: 0 auto;
    text-align: center;
  `,
  Steps: styled.div`
    display: inline-block;
    margin-bottom: 1.6em;
    padding: 0.5em 1em;
    border-radius: 2em;
    background-color: #0f1012;
    font-size: 1.4em;
    color: #8e9199;
    span {
      color: #fff;
    }
  `,
  StepTitle: styled.div`
    margin-bottom: 1.333em;
    font-weight: 600;
    font-size: 3em;
    color: #fff;
    p {
      margin-top: 0.1em;
      font-weight: normal;
      font-size: 0.5333em;
      color: #868991;
    }
  `,
  StepInner: styled.div`
    max-width: 450px;
    margin: 0 auto;
  `,
  InputWrap: styled.div`
    position: relative;
    & + & {
      margin-top: 1.4em;
    }
  `,
  Label: styled.label.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <FaCheckCircle className='icoCheck' />
      </>
    ),
  }))`
    display: flex;
    width: 100%;
    min-height: 6em;
    padding: 1.5em 8em 1.5em 4em;
    border: 1px solid #868991;
    border-radius: 0.6em;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;
    &[for] {
      cursor: pointer;
    }
    .icoCheck {
      position: absolute;
      font-size: 2.4em;
      right: 1.6em;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      transition: all 0.1s ease;
      opacity: 0;
      color: #ffb500;
    }
    span {
      text-align: left;
      font-weight: 600;
      font-size: 1.6em;
    }
    span.level {
      flex-shrink: 0;
      width: 2.7em;
      padding: 0.3em 0.5em;
      border-radius: 0.6em;
      font-size: 2em;
      border: 1px solid #fff;
      text-align: center;
    }
    span.title {
      font-size: 2.2em;
      .desc {
        display: block;
        font-weight: normal;
        font-size: 0.64em;
      }
    }
  `,
  InputCheck: styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    top: 1.5em;
    left: 4em;
    z-index: -1;
    &:checked + label[for] {
      border: 1px solid #ffb500;
      color: #ffb500;
    }
    &:checked + label[for] .icoCheck {
      opacity: 1;
    }
  `,
  InputRadio: styled.input.attrs({ type: 'radio' })`
    position: absolute;
    top: 1.5em;
    left: 4em;
    z-index: -1;
    &:checked + label[for] {
      border: 1px solid #ffb500;
      color: #ffb500;
    }
    &:checked + label[for] {
      span.level {
        border-color: #ffb500;
        background-color: #ffb500;
        color: #0f1012;
      }
    }
    &:checked + label[for] .icoCheck {
      opacity: 1;
    }
  `,
  MyCourse: styled.div`
    position: relative;
    max-width: 450px;
    margin: 0 auto 4em;
    padding: 4em 0 3em;
    border: 1px solid #ffb500;
    border-radius: 0.6em;
    .title {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0.2em 1em;
      border-radius: 2em;
      background: #ffb500;
      font-weight: 600;
      font-size: 2em;
      color: #0f1012;
      white-space: nowrap;
    }
    ul {
      display: flex;
      justify-content: center;
      gap: 3em;
      span {
        display: inline-block;
        width: 2.867em;
        height: 2.867em;
        padding: 0.9em 0;
        line-height: 1;
        letter-spacing: -0.04em;
        border: 1px solid #fff;
        border-radius: 50%;
        font-weight: 200;
        font-size: 3em;
      }
      p {
        margin-top: 0.8em;
        font-weight: 600;
        font-size: 1.6em;
        color: #fff;
      }
    }
  `,
  LectureList: styled.div`
    margin-bottom: 5em;
    .title {
      margin-bottom: 0.8em;
      font-weight: 600;
      font-size: 2em;
      color: #fff;
    }
  `,
  TockTest: styled.div`
    min-height: 9.2em;
    margin-bottom: 2em;
    padding: 2em 2em 2em 17em;
    border-radius: 0.6em;
    text-align: left;
    color: #fff;
    background: #434750 url(${icoTest}) no-repeat 8em 50%;
    background-size: 6.8em auto;
    .title {
      font-weight: 600;
      font-size: 1.8em;
    }
    p {
      margin-top: 0.4em;
      font-size: 1.3em;
    }
  `,
  AdditionalWrap: styled.div`
    width: 100%;
    margin-bottom: 4em;
    padding: 2.7em 6em;
    border: 1px solid #3d3d3d;
    border-radius: 0.6em;
  `,
  Additional: styled.div`
    width: 100%;
    min-height: 6.4em;
    margin-top: 1.7em;
    padding: 1em 0 1em 8em;
    text-align: left;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 6.4em;
    &.material {
      background-image: url(${icoETC});
    }
    &.speak {
      background-image: url(${icoETC2});
    }
    &.voca {
      background-image: url(${icoETC3});
    }
    &:first-child {
      margin-top: 0;
    }
    .title {
      font-weight: 600;
      font-size: 1.8em;
    }
    p {
      margin-top: 0.4em;
      font-size: 1.4em;
    }
  `,
  ButtonWrap: styled.div`
    margin-top: 4.5em;
    p {
      margin-bottom: 0.625em;
      font-size: 1.6em;
      color: #5c5f66;
    }
  `,
  Button: styled.button`
    width: 100%;
    max-width: 300px;
    height: 3em;
    margin: 0 auto;
    border-radius: 0.5em;
    background-color: #2a2c2e;
    font-weight: 600;
    font-size: 1.8em;
    color: #fff;
    transition: all 0.15s ease;
    &.active {
      background-color: #ffb500;
      color: #000;
    }
  `,
  Skip: styled(Link)`
    display: inline-block;
    margin-top: 1.14em;
    border-bottom: 1px solid #8e9199;
    line-height: 1.2;
    font-size: 1.4em;
    color: #8e9199;
  `,
  PlanTabWrap: styled.div`
    width: 34em;
    height: 4em;
    margin: 0 auto 4em;
    border-radius: 0.6em;
    overflow: hidden;
    display: flex;
  `,
  PlanTab: styled.button`
    width: 50%;
    height: 100%;
    flex-shrink: 0;
    background-color: #2b2e35;
    text-align: center;
    font-size: 1.6em;
    color: #fff;
    transition: all 0.15s ease;
    .off {
      display: inline-block;
      margin-left: 0.4em;
      padding: 0.3em 0.7em;
      background-color: #0f1012;
      border-radius: 3em;
      font-weight: 300;
      font-size: 0.75em;
    }
    &.active {
      background-color: #ffb500;
      color: #000;
      font-weight: 600;
      .off {
        color: #fff;
      }
    }
  `,
};

export default StyledCompleteSignUpPage;

import styled from 'styled-components';

const Title = styled.h3`
  font-size: 1.8em;
  font-weight: 700;
  text-transform: uppercase;
  @media (max-width: 767px) {
    font-size: 1.7em;
  }
`;
const Price = styled.span`
  display: inline-block;
  position: relative;
  margin-top: 0.1em;
  font-size: 4.8em;
  font-weight: 700;
  > span {
    position: absolute;
    font-size: 0.4em;
    font-weight: 500;
    top: 1em;
    left: -2.3em;
    text-transform: uppercase;
    line-height: 1;
    @media (max-width: 375px) {
      left: -2.8em;
    }
  }
`;
const Button = styled.button`
  width: 100%;
  font-size: 1.6em;
  height: 3.375em;
  margin-top: 2.1em;
  border: 1px #b0b1b2 solid;
  border-radius: 0.25em;
  font-weight: 500;
  text-transform: uppercase;
  color: #b0b1b2;
  letter-spacing: 2px;
  &.change {
    transition: all 0.2s ease;
    &:hover {
      color: #fff;
    }
  }
`;

const StyledPlanBox = {
  Container: styled.div`
    overflow: hidden;
    position: relative;
    width: 100%;
    padding: 4.7em 13%;
    border-radius: 0.8em;
    background-color: #2b2e35;
    text-align: center;
  `,
  BestTag: styled.span`
    position: absolute;
    top: -3em;
    left: -5em;
    width: 11em;
    padding-top: 4.5em;
    padding-bottom: 0.7em;
    background-color: #ff3867;
    text-align: center;
    font-size: 1.4em;
    font-weight: 700;
    transform: rotate(-45deg);
  `,
  Title: styled(Title)`
    color: ${({ color }) => color && color};
  `,
  Price: styled(Price)`
    color: ${({ color }) => color && color};
  `,
  Days: styled.p`
    color: #898c94;
    font-size: 1.4em;
    font-weight: 400;
    text-transform: uppercase;
    @media (max-width: 767px) {
      font-size: 1.7em;
    }
  `,
  Desc: styled.p`
    margin-top: 2.5em;
    height: 3em;
    color: #fff;
    font-size: 1.4em;
    font-weight: 400;
    @media (max-width: 767px) {
      font-size: 1.7em;
    }
  `,
  Button: styled(Button)`
    &.change {
      color: ${({ color }) => color && color};
      border-color: ${({ color }) => color && color};
      &:hover {
        background-color: ${({ color }) => color && color};
      }
    }
    cursor: ${({ isCurrentPlan }) => isCurrentPlan && 'default'};

  `,
};

export default StyledPlanBox;

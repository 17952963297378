import { asyncApi } from "common/util/asyncApi";

const url = "/api/v1/boards";
const size = 8;

/**
 * getAllList 모든 코스 목록
 * getAllCategory 모든 코스 카테고리 정보 목록
 * getCourse 'name'과 일치하는 단일 코스
 */
const communityApi = {
  getAllList: async (page = 0, params = null) => {
    let response = await asyncApi({ url, params: { page, size, ...params } });
    return response.data;
  },
  getTopPostList: async (page = 0, size = 5) => {
    let response = await asyncApi({
      url: `${url}?hots`,
      params: { page, size },
    });
    return response.data;
  },
  getRecommendPostList: async (page = 0) => {
    let response = await asyncApi({
      url: `${url}?recommends`,
      params: { page, size },
    });
    return response.data;
  },
  getNoticesPostList: async (page = 0) => {
    let response = await asyncApi({
      url: `${url}?notices`,
      params: { page, size },
    });
    return response.data;
  },
  getPost: async (id, token) => {
    let response = await asyncApi({
      url: `${url}/${id}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  postArticle: async (title, content, token) => {
    let response = await asyncApi({
      url: `${url}`,
      method: "post",
      data: { title, content },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  putArticle: async (id, title, content, token) => {
    let response = await asyncApi({
      url: `${url}/${id}`,
      method: "put",
      data: { title, content },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  deleteArticle: async (id, token) => {
    let response = await asyncApi({
      url: `${url}/${id}`,
      method: "delete",
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status;
  },
  postLike: async (id, token) => {
    let response = await asyncApi({
      url: `${url}/${id}?likes`,
      method: "post",
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  deleteLike: async (id, token) => {
    let response = await asyncApi({
      url: `${url}/${id}?likes`,
      method: "delete",
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  getCommentList: async (id, token, page = 0) => {
    let response = await asyncApi({
      url: `${url}/${id}/replies`,
      params: { page, size: 20 },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getChildCommentList: async (id, replyId, token, page = 0) => {
    let response = await asyncApi({
      url: `${url}/${id}/replies/${replyId}`,
      params: { page, size: 50 },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  postReply: async (id, replyId, content, token) => {
    let pathName = `${url}/${id}/replies`;
    if (replyId !== null) {
      pathName += `/${replyId}`;
    }
    let response = await asyncApi({
      url: pathName,
      method: "post",
      data: { content },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status;
  },
  putReply: async (replyId, content, token) => {
    let response = await asyncApi({
      url: `${url}`,
      method: "put",
      params: { replies: replyId },
      data: { content },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status;
  },
  deleteReply: async (replyId, token) => {
    let response = await asyncApi({
      url: `${url}/?replies=${replyId}`,
      method: "delete",
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status;
  },
  postReplyLike: async (id, replyId, token) => {
    let response = await asyncApi({
      url: `${url}/${id}/replies/${replyId}?likes`,
      method: "post",
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  deleteReplyLike: async (id, replyId, token) => {
    let response = await asyncApi({
      url: `${url}/${id}/replies/${replyId}?likes`,
      method: "delete",
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  //연관검색어
  getSearchFilterData: async (word) => {
    const config = { url: url + `?products=${word}` };
    const response = await asyncApi(config);
    return response.data;
  },

  getSearch: async (page = 0, type) => {
    const config = {
      url: url,
      params: { page, ...type },
    };
    const response = await asyncApi(config);
    return response.data;
  },
};

export default communityApi;

import styled from "styled-components";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';

const StyledRollBanner = {
  RollBanner: styled.div`
    position:relative;
    width:100%;
    background:#393c40;
    margin-top:3.5em;
    /* margin-bottom:6em; */
    @media (max-width: 1024px) {
      margin-bottom:1.8em;
    }
    @media (max-width: 767px) {

    }
  `,

  SwiperWrap: styled.div`
    position: relative;
    padding-right: 24em;
    & .swiper-container{
      margin-bottom: 0;
      & .swiper-slide{
        height: auto;
      }
    }
    @media (max-width: 767px) {
      padding-right: 5vw;
    }
    @media (max-width: 414px) {
      padding-right: 9vw;
    }
  `,
  SwiperControl: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
  `,

  Fraction: styled.div`
    width: auto;
    line-height: 1;
    padding: 0.5em 1em;
    margin-right: 2em;
    border-radius: 2em;
    background: #2a2c2e;
    font-size: 1.6em;
    color: #c9cbd1;
    .swiper-pagination-current {
      font-weight: 600;
    }
    @media (max-width: 767px) {
      margin-right: 6vw;
      display: none;
    }
  `,
  Navigation: styled.button`
    --swiper-theme-color: #828282;
    --swiper-navigation-size: 2.8em;
    --swiper-navigation-color: var(--swiper-theme-color);
    position: relative;
    width: 6em;
    height: 100%;
    border-right: 1px solid #2b2e32;
    z-index: 10;
    cursor: pointer;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
    &.swiper-button-disabled {
      opacity: 0.35;
      cursor: auto;
      pointer-events: none;
    }
    &:after {
      font-family: swiper-icons;
      font-size: var(--swiper-navigation-size);
      text-transform: none !important;
      letter-spacing: 0;
      text-transform: none;
      font-variant: initial;
      line-height: 1;
    }
    &.swiper-button-prev {
      border-left: 1px solid #2b2e32;
      &:after {
        content: 'prev';
      }
    }
    &.swiper-button-next {
      &:after {
        content: 'next';
      }
    }
    .swiper-button-lock {
      display: none;
    }
    @media (max-width: 767px) {
      --swiper-navigation-size: 1.8em;
      position: absolute;
      right: 0;
      width: 3em;
      height: 3em;
      border: none !important;
      transform: rotate(90deg);
      &.swiper-button-prev {
        top: 1em;
      }
      &.swiper-button-next {
        bottom: 1em;
      }
    }
  `,
  Banner: styled.div`
    display: flex;
    align-items: center;
    padding: 1em 0;
    font-weight: 600;
    font-size: 2em;
    color: #fff;
  `,
  Link: styled(Link)`
    display: flex;
    width: 100%;
    height: 100%;
  `,
  Category: styled.div`
    flex-shrink: 0;
    line-height: 1;
    padding: 0.5em 1em;
    border-radius: 2em;
    margin-right: 1em;
    color: #000;
    &.notice {background-color: #ff9648;}
    &.event {background-color: #a670ff;}
    &.guide {background-color: #8bd840;}
    &.news {background-color: #ffbb00;}
    &.talk {background-color: #00c7ce;}
    &.etc {background-color: #7b8fff;}
    @media (max-width: 767px) {
      font-size: 0.8em;
    }
  `,
  Text: styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 767px) {
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  `,
};

export default StyledRollBanner;

import styled, {css} from 'styled-components';

import icoArrow from 'assets/images/event/230203/ico_arrow.png';
import icoCircle from 'assets/images/event/230203/ico_circle.png';
import icoPlus from 'assets/images/event/230203/ico_plus.png';

import visualBg from 'assets/images/event/230203/bg_visual.png';
import visualImg from 'assets/images/event/230203/visual.jpg';
import visualImgKo from 'assets/images/event/230203/ko/visual.jpg';

import contImg from 'assets/images/event/230203/preview.jpg';
import contImg2 from 'assets/images/event/230203/material.jpg';
import contImg3 from 'assets/images/event/230203/review.jpg';
import contImgKo from 'assets/images/event/230203/ko/preview.jpg';
import contImg2Ko from 'assets/images/event/230203/ko/material.jpg';
import contImg3Ko from 'assets/images/event/230203/ko/review.jpg';

import typeText from 'assets/images/event/230203/txt_type.png';
import typeText2 from 'assets/images/event/230203/txt_type2.png';

import how from 'assets/images/event/230203/howto1.png';
import how2 from 'assets/images/event/230203/howto2.png';
import how3 from 'assets/images/event/230203/howto3.png';
import howKo from 'assets/images/event/230203/ko/howto1.png';

const StyledEventPage = {
  BtnArrow: styled.div.attrs({ className: 'btnWrap' })`
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 91;
    &.fixed {
      position: fixed;
      bottom: 7em;
    }
    a {
      display: inline-block;
      margin: auto;
      padding: 0.8em 1.3em;
      border-radius: 10em;
      line-height: 1em;
      font-weight: 700;
      font-size: 1.7em;
      color: #fff;
      text-align: center;
      background: #ef3f43;
      box-shadow: 0.2em 0.2em 0.4em rgba(0,0,0,.3);
      > div {
        display: inline-block;
        padding-right: 1em;
        background-image:url(${icoArrow});
        background-position: 100% 50%;
        background-repeat: no-repeat;
        background-size: 13px 21px;
      }
      span{
        color: #fff600;
      }
    }
    @media (max-width: 750px) {
      &.fixed {
        bottom: 4em;
      }
      a {
        font-size: 1.4em;
        > div {
          background-size: 0.4em auto;
        }
      }
    }
  `,

  TabWrap: styled.div`
    max-width: 750px;
    margin: 0 auto;
    display: flex;
  `,

  Tab: styled.div`
    flex: 1;
    height: 5.75em;
    border: 5px solid #0a0a2d;
    font-size: 1em;
    & + & {
      margin-left: -5px;
    }
    color: ${({ color }) => color && color};
    button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      letter-spacing: -1px;
      font-weight: 700;
      font-size: 1.3em;
      border: 5px solid #0a0a2d;
      color: #0a0a2d;
      background-color: ${({ color }) => color && color};
      &.active {
        font-size: 1.5em;
        border-color: ${({ color }) => color && color};
        background-color: #0a0a2d;
        color: ${({ color }) => color && color};
        &::before {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%) translateY(100%);
          width: 0;
          height: 0;
          border-top: 20px solid ${({ color }) => color && color};
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
        }
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%) translateY(calc(100% - 7px));
          width: 0;
          height: 0;
          border-top: 20px solid #0a0a2d;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
        }
      }
    }
    @media (max-width: 750px) {
      height: 3em;
      margin-left: 0;
      border: none;
      button {
        border: none;
        border-bottom: 1px solid #ccd3dc;
        background-color: #fff;
        font-weight: 400;
        font-size: 1em;
        color: #4b4b4b;
        &.active {
          background-color: #485cc7;
          border-color: #485cc7;
          font-weight: 600;
          font-size: 1em;
          color: #fff;
          &::before {
            display: none;
          }
          &::after {
            transform: translateX(-50%) translateY(calc(100%));
            border-top: 10px solid #485cc7;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
          }
        }
      }
    }
  `,

	EventWrap: styled.div`
    /* font-family: 'Pretendard'; */
		font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    color: #555;
    letter-spacing: -0.035em;

    .blind {
      overflow: hidden;
      position: absolute;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
    }

    .inner {
      position: relative;
			max-width: 750px;
      margin: auto;
      padding: 5em 0;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: 100%;
      .cont {
        background-position: 50% 0;
        background-repeat: no-repeat;
        background-size: 100%;
      }
    }

    .title {
      margin-bottom: 3em;
      > *:first-child {
        margin-top: 0;
      }
      > *:last-child {
        margin-bottom: 0;
      }
      p {
        font-size: 1.2em;
      }
      h2 {
        margin: 0.75em 0;
        font-weight: 700;
        font-size: 2em;
      }
    }

    @media (max-width: 750px) {
      .inner {
        padding: 4em 4%;
      }
    }

    .hide {
      display: none !important;
    }

    .visual {
      padding-bottom: 8em;
      background-color: #0a0a2d;
      background-image: url(${visualBg});
      background-position: 50% 0;
      background-repeat: repeat-x;
      background-size: auto 995px;
			.inner {
        max-width: 940px;
        padding-top: 995px;
        padding-bottom: 0;
        background-image: url(${visualImg});
        background-size: auto 995px;
				z-index:2;
      }
    }
    &.ko .visual {
      .inner{
        background-image:url(${visualImgKo});
      }
    }
    @media (max-width: 750px) {
      .visual {
        background-size: auto 132vw;
        .inner {
          padding-top: 132vw;
          background-size: auto 132vw;
        }
      }
    }

    .sticky {
      position: absolute;
      width: 100%;
      z-index: 90;
      margin-top: 0;
      .btnWrap {
        text-align: center;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 2em;
          background: #0a0a2d;
        }
        &.sticky {
          position: fixed;
          top: 9rem;
          left: 0;
          width: 100%;
          z-index: 90;
          margin-top: 0;
          &::before {
            display: none;
          }
        }
      }
    }

    .tab_cont {
      > div:nth-of-type(1) {
        .inner {
          padding-top: 9em;
          @media (max-width: 750px) {
            padding-top: 7em;
          }
        }
      }
    }

    .curriculum {
      background-color: #191931;
      .inner {
        max-width: 940px;
      }
      .title {
        p {color: #abb3cd;}
        h2 {color: #ffffff;}
      }
      .cont {
        padding-top: 525px;
        background-image:url(${contImg});
      }
    }
    &.ko .curriculum {
      .cont {
        background-image:url(${contImgKo});
      }
    }
    @media (max-width: 940px) {
      .curriculum {
        .cont {
          padding-top: 55.851%;
        }
      }
    }
    .curriculum2 {
      background-color: #eeeeee;
      .title {
        p {color: #4a3db1;}
        h2 {color: #3e3cd1;}
      }
      .cont {
        padding-top: 490px;
        background-image:url(${contImg2});
      }
    }
    &.ko .curriculum2 {
      .cont {
        background-image:url(${contImg2Ko});
      }
    }
    @media (max-width: 750px) {
      .curriculum2 {
        .cont {
          padding-top: 65.333%;
        }
      }
    }

    .review {
      background-color: #3e3cd1;
      .inner {
        padding-bottom: 3.5em;
      }
      .title {
        p {color: #abe4ff;}
        h2 {color: #fff;}
      }
      .cont {
        padding-top: 258px;
        background-image:url(${contImg3});
      }
    }
    &.ko .review {
      .cont {
        background-image:url(${contImg3Ko});
      }
    }
    @media (max-width: 750px) {
      .review {
        .cont {
          padding-top: 34.4%;
        }
      }
    }

    .review2 {
      background-color: #4c37b7;
      .inner {
        padding-top: 3.5em;
        text-align: left;
      }
      .stitle {
        margin-bottom: 1.25em;
        font-weight: 700;
        font-size: 1.6em;
        color: #fff;
      }

      --ratio : 1;
      --tit: calc(var(--ratio) * 50px);
      --circle : calc(var(--ratio) * 30px);
      --pos : calc(var(--ratio) * 10px);
      --left : calc(var(--ratio) * 12px);
      --border : calc(var(--ratio) * 6px);
      @media (max-width: 750px) {
        --ratio : 0.8;
      }

      .list {
        h4 {
          height: var(--tit);
          img {
            width: auto;
            height: 100%;
          }
        }
        ul:first-of-type {
          li:last-child {
            padding-bottom: var(--circle);
            .box {
              &::before {
                height: calc(100% + var(--circle));
              }
            }
          }
        }
        li {
          position: relative;
          padding-left: 4.5em;
          padding-top: var(--tit);
          background-image: url(${icoCircle});
          background-size: var(--circle);
          background-position: 0 var(--pos);
          background-repeat: no-repeat;
          &:first-of-type {
            padding-top: var(--circle);
            background: none;
            .box {
              &::before {
                top: calc(var(--circle)*-1);
                height: calc(100% + var(--circle));
              }
            }
          }
        }
        .box {
          position: relative;
          padding: 1.75em 2em 1em 2.5em;
          border-radius: 1em 0 0 1em;
          background-color: #fff;
          text-align: left;
          color: #000;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -4.5em;
            transform: translateX(var(--left));
            width: var(--border);
            height: 100%;
            border-radius: calc(var(--border)/2);
            background-color: #22184a;
          }
          .tit {
            position: relative;
            display: inline-block;
            font-weight: 700;
            font-size: 1.1em;
            line-height: 1.6;
            span {
              position: relative;
              background-color: #ffff1c;
            }
          }
          .cont {
            margin-top: 1em;
            font-size: 0.9em;
          }
          .people {
            display: flex;
            justify-content: end;
            align-items: end;
            font-size: 0.8em;
            p {
              font-weight: 700;
            }
          }
          .img {
            margin-left: 10px;
            width: 16%;
            max-width: 120px;
          }
        }
      }
    }

    .howto {
      background-color: #191931;
      .title {
        p {color: #abb3cd;}
        h2 {color: #fff;}
      }
      .plan {
        display: flex;
        > div {
          width: 50%;
          padding: 2em 2.5em;
          & + div {
            margin-left: 0.5em;
          }
        }
        .type {
          margin: 1em 0;
          height: 2.3em;
          background-position: 50% 0;
          background-repeat: no-repeat;
          background-size: contain;
        }
        .group {
          display: inline-block;
          padding: 0.5em 1em;
          border-radius: 3em;
          background-color: #22184a;
          font-size: 0.9em;
          color: #fff;
        }
        .desc {
          font-weight: 700;
          font-size: 1.1em;
        }
        .plus {
          position: relative;
          margin-top: 1.3em;
          padding-top: 1.3em;
          border-top: 0.13em solid #485cc7;
          font-size: 1em;
          color: #ffffff;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            transform: translateY(-50%) translateX(-50%);
            background-color: #485cc7;
            background-image: url(${icoPlus});
            background-size: 1em;
            background-position: 50% 50%;
            background-repeat: no-repeat;
          }
        }
        .standard {
          border: 0.5em solid #fea700;
          background-color: #feb900;
          .type {
            background-image: url(${typeText});
          }
          .desc {
            color: #fffcd3;
          }
        }
        .premium {
          border: 0.5em solid #486aff;
          background-color: #4c76ff;
          .type {
            background-image: url(${typeText2});
          }
          .desc {
            color: #b3e6ff;
          }
        }
      }
      .txt {
        margin-top: 1em;
        text-align: right;
        font-size: 0.9em;
        color: #7c8cb5;
      }
      .step {
        margin-top: 2.5em;
        padding: 2em;
        background-color: #fff;
        h3 {
          margin-bottom: 1em;
          font-weight: 700;
          font-size: 1.6em;
          color: #191931;
        }
        li {
          min-height: 193px;
          margin-top: 1em;
          padding-left: 60%;
          background-position: 0 0;
          background-repeat: no-repeat;
          background-size: auto 193px;
          text-align: left;
          &:nth-of-type(1) {
            background-image: url(${how});
          }
          &:nth-of-type(2) {
            background-image: url(${how2});
          }
          &:nth-of-type(3) {
            background-image: url(${how3});
          }
          .label {
            display: inline-block;
            margin: 1em 0;
            padding: 0.3em 0.5em;
            background-color: #0092c7;
            font-weight: 700;
            font-size: 0.8em;
            color: #fff;
          }
          div {
            font-size: 1.2em;
            color: #191931;
            span {
              font-weight: 700;
            }
          }
        }
        p {
          margin-top: 1em;
          text-align: left;
          font-size: 0.8em;
          color: #ef3f43;
        }
      }
    }
    &.ko .howto {
      .step {
        li:nth-of-type(1) {
          background-image: url(${howKo});
        }
      }
    }
    @media (max-width: 750px) {
     .howto {
      .plan {
        & > div {
          padding: 1.5em 1em;
        }
      }
      .step {
        li {
          min-height: 20vw;
          padding-left: 50%;
          font-size: 0.9em;
          background-size: 46% auto;
          background-position: 0 50%;
          .label {
            margin: 0.5em 0 0.8em;
          }
        }
      }
     }
    }
    @media (max-width: 360px) {
     .howto {
      .step {
        li {
          font-size: 0.8em;
        }
      }
     }
    }

    .notification {
      background-color: #090f35;
      text-align: left;
      color: #fff;
      font-weight: 200;
      letter-spacing: normal;
      .inner {
        padding: 4em 4%;
      }
      h3 {
        text-align: center;
        font-size: 2.8rem;
        font-weight: 700;
      }
      dl {
        font-size: 2.2rem;
      }
      dt {
        margin: 2em 0 0.5em;
        font-weight: 500;
				a {
					border-bottom: none;
				}
      }
      dd {
        position:relative;
        padding-left: 1.2em;
        margin-bottom: 0.5em;
        color: #e5e4e6;
        &:before {
          content:"-";
          display:inline-block;
          position: absolute;
          top:0;
          left:0.3em;
          width: 0.25em;
          height: 0.25em;
          border-radius: 0.2em;
          vertical-align:middle;
        }
        em {
          color: #fff799;
        }
      }
      a {
        display: inline-block;
        border-bottom: 1px #fff solid;
        line-height: 1.2;
      }
    }
  `,
};

export default StyledEventPage;

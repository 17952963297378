import styled from 'styled-components';
import changeIcon from 'assets/images/ico_change.png';

const StyledPaymentSection = {
  Container: styled.div`
    margin-bottom: 3em;
    padding: 4em 3em 4.5em 2em;
    border-radius: 0.6em;
    background-color: #1e2024;
    @media (max-width: 767px) {
      font-size: 1.2em;
    }
    .method {
      display: inline-block;
      margin-bottom: 1.5em;
      color: #8b8f99;
      font-size: 1.6em;
      font-weight: 700;
    }
    .cardNo {
      padding-left: 0.55em;
      padding-bottom: 1em;
      border-bottom: 1px #2b2e35 solid;
      font-size: 1.8em;
      font-weight: 400;
      > span {
        display: inline-block;
        margin-right: 1em;
      }

      > .change {
        position: relative;
        float: right;
        margin-top: 0.5em;
        margin-right: 0.625em;
        padding-left: 2em;
        color: #505256;
        font-size: 0.78em;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 1.125em;
          height: 1.125em;
          background: url(${changeIcon}) no-repeat center center/cover;
          transform: translateY(-50%);
        }
        @media (max-width: 767px) {
          display: inline-block;
          float: none;
        }
      }
    }
  `,

  Title: styled.h3`
    margin-bottom: 2em;
    color: #8b8f99;
    font-size: 2em;
    font-weight: 700;
  `,
  CircleContainer: styled.span`
    display: inline-block;
    margin-right: 0.8em;
    &:first-of-type {
      margin-left: 1em;
    }
  `,
  CircleSpan: styled.span`
    display: inline-block;
    width: 0.35em;
    height: 0.35em;
    margin-right: 0.5em;
    border-radius: 50%;
    background-color: #fff;
    vertical-align: middle;
  `,
};

export default StyledPaymentSection;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Style from './styled/MTLIve.style';

export default function VideoThumb({ videoRef }) {
  const { length } = useHistory();

  const [playing, setPlaying] = useState(false);
  const onPLay = () => {
    videoRef.current.plyr.play();
    setPlaying(true);
  };
  useEffect(() => {
    //현재 페이지에서 -> 현재페이지로 이동할때 컴포넌트 새로고침
    setPlaying(false);
  }, [length]);

  return (
    <>
      <Style.Thumb onClick={onPLay} className={playing && 'hidden-poster'}>
        <Style.PlayIcon />
      </Style.Thumb>
    </>
  );
}

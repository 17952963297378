import React, { useState, useRef } from 'react';
import Style from './styled/Index.style.js';
import StyledSelect from 'component/MTLive/styled/ResourceSelect.style';
import useClickOutside from 'common/hook/useOutsideClick';
import Process from './Process.js';
const ProcessPage = ({ setTabMenuIndex, t }) => {
  const inputRef = useRef();
  useClickOutside(inputRef, () => {
    setSelectedList(false);
  });
  const [selected, setSelected] = useState('USD');
  const [selectedList, setSelectedList] = useState(false);
  return (
    <div>
      {/* <Style.SelectContainer>
        <span className='selectLabel'>Select Your Currency</span>
        <StyledSelect.SelectContainer
          className='paySelect'
          onClick={() => setSelectedList((prev) => !prev)}
        >
          <StyledSelect.Input readOnly value={selected} ref={inputRef} />
          <StyledSelect.HiddenList show={selectedList}>
            <li onClick={() => setSelected('USD')}> USD</li>
            <li onClick={() => setSelected('KRW')}> KRW</li>
          </StyledSelect.HiddenList>
        </StyledSelect.SelectContainer>
      </Style.SelectContainer> */}
      <Process setTabMenuIndex={setTabMenuIndex} t={t} />
    </div>
  );
};

export default ProcessPage;

import styled from 'styled-components';
import iconInsert from 'assets/images/ico_insert.png';
import iconInsertLarge from 'assets/images/ico_insert_large.png';

const StyledCommentWrite = {
  Wrapper: styled.div`
    position: relative;
    padding: 3em 4em;
  `,
  Form: styled.form`
    position: relative;
    margin-top: 2em;
  `,
  Textarea: styled.textarea`
    font-size: 1.6em;
    width: 100%;
    height: 12rem;
    padding: 1em 10em 1em 1.5em;
    border: 1px solid #454545;
    border-radius: 0.6rem;
    background-color: #0f1012;
    color: #ddd;
    line-height: 1.4;
    overflow: auto;
    resize: none;
    transition: border-color 0.2s ease;
    &:focus {
      border-color: #485cc7;
    }
  `,
  Submit: styled.button.attrs({ type: 'submit' })`
    position: absolute;
    right: 2.5rem;
    top: 50%;
    font-size: 1.4em;
    width: 11rem;
    height: 4.6rem;
    padding-left: 2.4rem;
    margin-top: -2.3rem;
    border-radius: 0.4rem;
    background-image: url(${iconInsert});
    background-repeat: no-repeat;
    background-position: 1.5em center;
    background-color: #485cc7;
    background-size: 1.6rem auto;
    font-weight: 300;
    letter-spacing: 1px;
    color: #fff;
    @media (max-width: 1024px) {
      background-image: url(${iconInsertLarge});
    }
  `,
};

export default StyledCommentWrite;

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import StyledUtil from 'common/styled/Util.style';
import filterIcon from 'assets/images/ico_filter.png';

const StyledCurriculum = {
  Container: styled.div`
    display: flex;
    margin-bottom: 3em;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      padding: 1.9em 3em;
      border-radius: 0.6em;
      background-color: #1e2024;
      &:first-of-type {
        margin-right: 3em;
      }
      > .badgeContainer {
        text-align: center;
      }
      > .textContainer {
        /* width: 25%; */
        text-align: right;
      }
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      > div {
        justify-content: space-evenly;
        > * {
          flex: 1;
          width: auto;
        }
      }
      > div:first-child {
        margin-right: 0;
        margin-bottom: 3em;
      }
    }
  `,
  Title: styled.h3`
    padding-top: 2.61em;
    color: #fff;
    font-size: 3.6em;
    font-weight: 700;
  `,
  TitleDesc: styled.p`
    margin-bottom: 1.8em;
    color: #d1d1d1;
    font-size: 1.6em;
    font-weight: 300;
  `,

  SectionTitle: styled.span`
    display: inline-block;
    align-self: center;
    margin-right: 3em;
    color: #c9cbd1;
    font-size: 2em;
    font-weight: 500;
    white-space: nowrap;
    &.title {
      margin-right: 0;
      /* width: 38%; */
    }
    @media (max-width: 1024px) {
      margin-right: 0;
    }
  `,
  Badge: styled.span`
    display: inline-block;
    margin-right: 1em;
    padding: 0.1em 0.5em;
    border: 1px solid #ffbb00;
    border-radius: 0.94em;
    text-align: center;
    color: #ffbb00;
    font-size: 1.7em;
    font-weight: 500;
    white-space: nowrap;

    @media (max-width: 1024px) {
      padding: 0.2em 0.8em;
    }
  `,
  Subject: styled.span`
    ${StyledUtil.textEllipsis}
    display: inline-block;
    width: 15em;
    color: #fff;
    font-size: 1.8em;
    font-weight: 500;
    @media (max-width: 1200px) {
      text-align: right;
    }
  `,
  Level: styled.span`
    display: inline-block;
    /* width: 35%; */
    line-height: 0;
    color: #fff;
    font-size: 1.6em;
    font-weight: 500;

    > span:first-child {
      font-size: 1.875em;
      font-weight: 700;
    }
    > .type {
      @media (max-width: 767px) {
        display: none;
      }
    }

    @media (max-width: 1200px) {
      text-align: center;
    }
  `,
  TestBtn: styled.button`
    display: inline-block;
    padding: 0.6em 0.4em;
    border: 1px solid #5d6165;
    border-radius: 0.28em;
    color: #999;
    font-size: 1.4em;
    font-weight: 500;
  `,
  CurriculumCardContainer: styled.div`
    clear: both;
    width: calc(100% + 4rem);
    margin-left: -2rem;

    &::before {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }
    &::after {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }

    .card {
      float: left;
      width: calc(25% - 4rem);
      margin: 0 2rem 6rem;
    }

    @media (max-width: 1024px) {
      width: calc(100% + 2rem);
      margin-left: -1rem;

      .card {
        width: calc(25% - 2rem);
        margin: 0 1rem 3rem;
      }
    }
    @media (max-width: 767px) {
      width: calc(100% + 1rem);
      margin-left: -0.5rem;

      .card {
        width: calc(50% - 1rem);
        margin: 0 0.5rem 3rem;
      }
    }
  `,
  FilterBtn: styled.span`
    position: relative;
    color: #d1d1d1;
    padding: 0.64em 0.8em 0.64em 3em;
    border: 1px #5d6165 solid;
    border-radius: 0.4em;
    font-size: 1.4em;
    font-weight: 700;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      width: 1.4em;
      height: 1em;
      top: 50%;
      left: 1em;
      transform: translateY(-50%);
      background: url(${filterIcon}) no-repeat center center/contain;
    }
  `,

  ProgressContainer: styled.div`
    .curriculum {
      height: auto;
    }
  `,
  SectionLevelTest: styled.div`
    padding: 5em 3em;
    border-radius: 0.6em;
    background-color: #1e2024;
    text-align: center;
  `,
  LevelTestText: styled.div`
    font-size: 3.6em;
    font-weight: 700;
  `,
  LevelTestButton: styled(Link)`
    display: inline-block;
    padding: 0.938em 0;
    margin-top: 2em;
    min-width: 18.75em;
    border-radius: 0.375em;
    background-color: #485cc7;
    font-size: 1.6em;
    font-weight: 700;
    line-height: 1.2;
  `,
  CourseListWrap: styled.div`
    margin-top:3rem;
  `
};

export default StyledCurriculum;

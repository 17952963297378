import React, { useCallback, useEffect, useState } from 'react';
import Style from './styled/courseMockTest.style';

import correctionServiceImg from 'assets/images/course_correction_service.png';
import analyzingServiceImg from 'assets/images/course_analyzing_service.png';
import { mockTest_check_icon } from 'assets/images/course/index';

import { Trans, useTranslation } from 'react-i18next';

import Img from 'component/atoms/Img';
import SetCard from 'component/ui/card/setCard';

import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';
import mockTestApi from 'api/mockTests';

import useLoginStatus from "common/hook/useLoginStatus";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import makeAnimated from 'react-select/animated';
import { mockTestSampleData } from 'container/CoursePage/MockTestSample';
import { ModalContext } from 'component/Modal/modalContext';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Login from 'component/user/Login';
import OrderBySelect from 'component/molecules/OrderBySelect';

SwiperCore.use([Navigation]);

// 문제 컴포넌트
const Example = (props) => {
  const {
    levelType,
    name,
    question,
    answer,
    text,
    example,
    commentary,
  } = props.item
  const [activeState, setActiveState] = useState(0);
  // 보기 선택 이벤트
  const selectAnswerEvent = useCallback((number) => {
    if (activeState !== number) {
      setActiveState(number)
    } else {
      setActiveState(0)
    }

  }, [activeState])

  let formattedText, formattedQuestion;
  if (text.includes('#')) {
    formattedText = text.replace(/#(.*?)#/g, '<span>$1</span>');
  } else if (text.includes('()')) {
    formattedText = text.replace('()', '(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)');

  } else {
    formattedText = text
  }

  if (question.includes('()')) {
    formattedQuestion = question.replace('()', '(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)');
  } else {
    formattedQuestion = question
  }


  return (
    <Style.TestWrapper>
      <Style.TestHeading>
        <Style.TestHeadingType type={levelType}>{name}</Style.TestHeadingType>
        <Style.Question dangerouslySetInnerHTML={{ __html: formattedQuestion }}></Style.Question>
      </Style.TestHeading>
      <Style.TestBody>
        <Style.Text><div dangerouslySetInnerHTML={{ __html: formattedText }}></div></Style.Text>
        <Style.Example>
          {example && example.map((unit, idx2) => {
            const number = idx2 + 1;
            return (
              <div
                onClick={() => selectAnswerEvent(number)}
                key={idx2}
                className={`${number === activeState ? 'active' : ''} ${(number === answer && activeState !== 0) ? 'answer' : ""}`}
              >
                <span>{number === activeState ? <img src={mockTest_check_icon} alt='선택 번호' /> : number}</span>
                <p>{unit}</p>
              </div>
            )
          })}
        </Style.Example>
      </Style.TestBody>
      <Style.Commentary
        className={`${(activeState !== 0) ? 'active' : ""} ${(activeState===answer)? "answer" : ""}`}
      >{commentary}</Style.Commentary>
    </Style.TestWrapper>
  )
};


const CourseMockTest = () => {
  const { t } = useTranslation('coursePage');
  const token = useSelector((state) => state.user.token);
  const [list] = useAxios(() => mockTestApi.getAllList(token), []);
  const { data } = list;

  const language = localStorage.getItem('i18nextLng');

  const options = [
    {
      value: null,
      label: t('mockTestTab.selector.option1')
    },
    {
      value: 11,
      label: t('mockTestTab.selector.option2')
    },
    {
      value: 12,
      label: t('mockTestTab.selector.option3')
    }
  ];
  const [selectedOption, setSelectedOption] = useState(options[0]); // 임시 옵션설정
  const handleCurrency = (e) => {
    setSelectedOption(e);
  };

  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);
  const location = useLocation();

  const checkLogin = (e) => {
    if (!isLogin) {
      e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
      handleModal(<Login />, false, 420, location.pathname);
      return;
    }
  };

  return (
    <div>
      <Style.MockTestSample>
        <Style.Heading>
          <h2 className='sample-title'>
            <Trans t={t}>mockTestTab.sample.title</Trans>
          </h2>
          <p>
            <Trans t={t}>mockTestTab.sample.desc</Trans>
          </p>

        </Style.Heading>
        <div className='mock-test-sample-wrapper'>
          <div className='subtitle'>
            {language === "ko-KR" && <h3><Trans t={t}>mockTestTab.sample.subTitle</Trans></h3>}
            <p><Trans t={t}>mockTestTab.sample.subDesc</Trans></p>
          </div>
          <div className='mock-test-sample-contents'>
            <Style.Prev
              className={`slider-prev prev`}
            />
            <Style.Next
              className={`slider-next next`}
            />
            <Style.Swiper
              spaceBetween={20}
              onSlideChange={(e) => { }}
              allowTouchMove={false}
              navigation={{
                nextEl: `.next`,
                prevEl: `.prev`,
              }}
            >
              {mockTestSampleData && mockTestSampleData.map((item, idx) => {
                return (
                  <SwiperSlide key={item.id}>
                    <Example item={item} />
                  </SwiperSlide>
                )
              })}
            </Style.Swiper>
          </div>
          <div className='btn-area'>
            {/*
              로그인시 :/plan-payment/premium?productId=1147
              비로그인시 : 로그인 화면
            */}
            <Link className="more-btn" to={"/classroom/topik-mock-test"} onClick={checkLogin}>
              <Trans t={t}>mockTestTab.sample.button</Trans>
            </Link>
            <p><Trans t={t}>mockTestTab.sample.buttonText</Trans></p>
          </div>
        </div>
      </Style.MockTestSample>

      <Style.ContentContainer className='wrapper left-image'>
        <div className='image-container'>
          <Img src={correctionServiceImg} alt='Correction Service Image' />
        </div>
        <Style.Heading className='wrapperTxt'>
          <h2 className='spacing'>
            <Trans t={t}>mockTestTab.section1.title</Trans>
          </h2>
          <p>
            <Trans t={t}>mockTestTab.section1.desc</Trans>
          </p>

        </Style.Heading>
      </Style.ContentContainer>
      <Style.ContentContainer className='wrapper right-image'>

        <Style.Heading className='wrapperTxt'>
          <h2>
            <Trans t={t}>mockTestTab.section2.title</Trans>
          </h2>
          <p>
            <Trans t={t}>mockTestTab.section2.desc</Trans>
          </p>
        </Style.Heading>
        <div className='image-container'>
          <Img src={analyzingServiceImg} alt='Correction Service Image' />
        </div>
      </Style.ContentContainer>


      <Style.ListHeading>
        <Style.SetListH2>
          <Trans t={t}>mockTestTab.section5.title</Trans>
        </Style.SetListH2>
        <Style.SetListP>
          <Trans t={t}>mockTestTab.section5.desc</Trans>
        </Style.SetListP>

        <Style.SelectContainer>
          <OrderBySelect
            onChange={handleCurrency}
            optionList={options}
            width='14.5em'
            className='order-by-select'
          />
        </Style.SelectContainer>

      </Style.ListHeading>
      <Style.SetCardList>
        {data?.content &&
          data.content.map((item, index) => {
            if (selectedOption.value === null || selectedOption.value === item.levelType) {
              return <SetCard item={item} key={index} />;
            }
          })}

      </Style.SetCardList>
    </div>
  );
};

export default CourseMockTest;

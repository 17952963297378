import {
  createReducer,
  createSetValueAction,
  setValueReducer,
} from 'common/redux-helper';

export const Types = {
  SetValue: 'user/SetValue',
  FetchAll: 'notification/FetchAll',
};

export const actions = {
  setValue: createSetValueAction(Types.SetValue),
  fetchAll: () => ({ type: Types.FetchAll }),
};

const INITIAL_STATE = {
  keyword: '',
  FetchAll: [],
};
const userReducer = createReducer(INITIAL_STATE, {
  [Types.SetValue]: setValueReducer,
  [Types.FetchAll]: (state, action) => {},
});
export default userReducer;

import React from 'react';
import Style from './styled/BookList.style';
import { BsArrowDown } from 'react-icons/bs';
import { masterTopikUrl } from 'common/constant';
import { priceFormat } from 'common/util/util.js';
import { ModalContext } from 'component/Modal/modalContext';
import PurchaseModal from 'container/BookStorePage/PurchaseModal';
import Img from 'component/atoms/Img';
import ThumbnailDefault from 'assets/images/thumb_default.jpg';
import { useTranslation } from 'react-i18next';
import useLoginStatus from 'common/hook/useLoginStatus';
import Login from 'component/user/Login';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const BookList = ({ data }) => {
  const { t } = useTranslation('bookstorePage');
  const { handleModal } = React.useContext(ModalContext);

  const isLogin = useLoginStatus();
  const location = useLocation();

  const showPurchaseModal = () => {
    if(!isLogin) {
      handleModal(<Login/>, false, 420, location.pathname);
      return;
    }

    let bookItem = {
      id: data.id,
      type: 'Text Book',
      name: data.title,
      price: data.discountPrice,
      priceDollar: data.discountPriceDollar,
      discountCost: data.discountCost,
      discountCostDollar: data.discountCostDollar,
      globalBookUrl: data.globalBookUrl,
    };
    handleModal(<PurchaseModal data={bookItem} url={data.urls} />, false, 500);
  };

  const priceDiv = () => {
    //true => 언어 변경 condition 추가
    const language = localStorage.getItem('i18nextLng');

    const price =
      language === 'ko-KR'
        ? priceFormat.korea(data.price)
        : priceFormat.usa(data.priceDollar);

    const discountPrice =
      language === 'ko-KR'
        ? priceFormat.korea(data.discountPrice)
        : priceFormat.usa(data.discountPriceDollar);

    if (data?.discountType.trim() !== '') {
      return (
        <div className='priceContainer'>
          <span className='priceTxt'>Textbook price :</span>
          <span className='actualPrice'>{price}</span>
          <span className='price'>
            <span className='finalPrice'>{discountPrice}</span>
            {data?.discountPercent > 0 && (
              <span className='discount'>
                ( {data.discountPercent} %
                <BsArrowDown size='1.3em' />)
              </span>
            )}
          </span>
        </div>
      );
    } else {
      return (
        <div className='priceContainer'>
          <span className='priceTxt'>Textbook price :</span>
          <span className='price'>
            <span className='finalPrice'>{discountPrice}</span>
          </span>
        </div>
      );
    }
  };
  return (
    <Style.Container>
      <Img
        src={masterTopikUrl + data.thumbnail}
        alt='text book'
        onError={ThumbnailDefault}
      />
      <Style.Content>
        <div className='bookInfo'>
          <h5>{data?.title}</h5>
          <span className='publisher'>Publisher : {data?.company}</span>
          <span className='page'>
            Number of pages : {data?.pageCount} pages
          </span>
        </div>
        <p className='desc'>{data?.description}</p>
        <div className='container'>
          {priceDiv()}
          <Style.Button onClick={() => showPurchaseModal()}>
            {t('button')}
          </Style.Button>
        </div>
      </Style.Content>
    </Style.Container>
  );
};

export default BookList;

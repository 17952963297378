import { asyncApi } from 'common/util/asyncApi';
const pathName = '/api/v1/contents';

/**
 * getWatchedList 수강한 강좌 리스트 (마이페이지)
 * getLecture 강의 정보 조회 ( by 강좌 ID /  강의 ID)
 */
const contentsApi = {
  getLecture: async (productId, lectureId, token) => {
    let response = await asyncApi({
      url: `${pathName}/${productId}/${lectureId}`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getVideoByType: async (lectureId, qualityType, token) => {
    let response = await asyncApi({
      url: `${pathName}/${lectureId}`,
      params: { url: '', type: qualityType },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getHistoryList: async (token) => {
    let response = await asyncApi({
      url: `${pathName}/history`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  getWatchedList: async ({ page, token }) => {
    let response = await asyncApi({
      url: `${pathName}/history?my-page`,
      params: { watches: '', page },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  postLogApi: async (productId, chapterLectureId, token) => {
    try {
      const response = await asyncApi({
        url: `${pathName}/start`,
        method: 'post',
        data: { productId, chapterLectureId },
        headers: { Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (e) {
      return e;
    }
  },
  updateLogApi: async (data, token) => {
    const config = {
      url: `${pathName}/update`,
      method: 'put',
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await asyncApi(config);
      return response.data;
    } catch (e) {
      console.log(e);
      return;
    }
  },
};

export default contentsApi;

import audioDetailType from './type';

const initialState = {
  currentAudioUrl: null,
};

const audioDetailReducer = (state = initialState, action) => {
  switch (action.type) {
    case audioDetailType.Set_Current_Audio_Url:
      return {
        ...state,
        currentAudioUrl: action.payload,
      };
    default:
      return state;
  }
};

export default audioDetailReducer;

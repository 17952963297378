import React from 'react';
import Styled from './index.style';
import { Link } from 'react-router-dom';
import { SiteMapData } from 'common/constant';
import { AiFillFacebook, AiFillYoutube } from 'react-icons/ai';
import { BiPlayCircle } from 'react-icons/bi';
import { FiInstagram } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import makeAnimated from 'react-select/animated';
import { languageCode } from 'common/constant';
const animatedComponents = makeAnimated();
const NavBottom = ({isLogin}) => {
  const Bottom = Styled.Bottom;
  const { i18n } = useTranslation();
  const { t } = useTranslation('footer');

  const clickFamilySite = (e) => {
    i18n.changeLanguage(e.value);
    window.location.reload();
    // window.open(e.value, '_blank');
  };
  return (
    <Bottom.Wrapper>
      <Bottom.BottomTop>
        <Bottom.SnsWrap>
          <Bottom.SocialMedia
            to={SiteMapData.youtube}
            target='_blank'
            title='Youtube'
          >
            <AiFillYoutube />
          </Bottom.SocialMedia>
          <Bottom.SocialMedia
            to={SiteMapData.instagram}
            target='_blank'
            title='Instagram'
          >
            <FiInstagram />
            </Bottom.SocialMedia>
          <Bottom.SocialMedia
            to={SiteMapData.facebook}
            target='_blank'
            title='Facebook'
          >
            <AiFillFacebook />
          </Bottom.SocialMedia>
        </Bottom.SnsWrap>


      {/* <Bottom.SocialMedia to={SiteMapData.youku} target='_blank' title='Youku'>
        <BiPlayCircle />
      </Bottom.SocialMedia> */}

        <Styled.StyledSelect
            className="select-box"
            options={[
                {
                  name: languageCode.KOREAN.code.toUpperCase(),
                  value: languageCode.KOREAN.locale,
                  label: '한국어'
                },
                {
                  name: languageCode.JAPANESE.code.toUpperCase(),
                  value: languageCode.JAPANESE.locale,
                  label: '日本語​'
                },
                {
                  name: languageCode.CHINESE.code.toUpperCase(),
                  value: languageCode.CHINESE.locale,
                  label: '中国话​'
                },
                {
                  name: languageCode.ENGLISH.code.toUpperCase(),
                  value: languageCode.ENGLISH.locale,
                  label: 'English'
                },
            ]}
            components={animatedComponents}
            onChange={clickFamilySite}
            placeholder={i18n.language === languageCode.KOREAN.locale ? '한국어' : i18n.language === languageCode.JAPANESE.locale ? '日本語​' :  i18n.language === languageCode.CHINESE.locale ? '中国话​': 'English'}
          />
      </Bottom.BottomTop>


      <Bottom.Links>

        {/* <b>
          <Link to={t('faq')} target='_blank'>
            FAQ
          </Link>
        </b>
        <b>
          <Link to={t('notice')} target='_blank'>
            Notice
          </Link>
        </b>
        <b>
          <Link to={t('contact')} target='_blank'>
            Contact Us
          </Link>
        </b> */}
        {
          isLogin ? (
            <>
              <b>
              <Link to={SiteMapData.privacy}>
                  Privacy
                </Link>
              </b>
              <b>
                <Link to={SiteMapData.terms}>
                  Terms
                </Link>
              </b>
              <b>
                <Link to={SiteMapData.contact} target="_blank">
                  Contact
                </Link>
              </b>
              <b>
                <Link to={SiteMapData.help}>
                  Help
                </Link>
              </b>
            </>
          ): (
            <>
              <b>
              <Link to={SiteMapData.help}>
                  Help
                </Link>
              </b>
              <b>
                <Link to={SiteMapData.helpEvent}>
                  Event
                </Link>
              </b>
            </>
          )
        }

      </Bottom.Links>
    </Bottom.Wrapper>
  );
};

export default NavBottom;

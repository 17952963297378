import React, { useContext, useEffect, useMemo } from "react";
import Styled from "./Modal.style";
import { ModalContext } from "component/Modal/modalContext";
import StyledModal from "component/Modal/styled/Modal.style";
import { useAxios } from "common/hook/useAxiosHook";
import { useSelector } from "react-redux";
import LoadingOverlay from "component/atoms/LoadingOverlay";
import useErrorToastify from "common/hook/useErrorToastify";
import plansApi from "api/plans";
import ChangeCancelCompleteModal from "container/PlanPage/modal/ChangeCancelCompleteModal";
import { useTranslation, Trans } from "react-i18next";

const ConfirmChangeCancelModal = ({
  selectedPlan,
  getPlan,
  handleFetchPlan,
}) => {
  const { t } = useTranslation("planPage");
  const currentPlan = useMemo(()=> getPlan.plans.find(
    (plan) => plan.productId === getPlan.currentPlanProductId
  ),[selectedPlan, getPlan]);

  const { handleModal } = useContext(ModalContext);
  const token = useSelector((state) => state.user.token);
  const [{ loading, data: changeData, error }, changeSubscription] = useAxios(
    plansApi.putPlan,
    [],
    true
  );

  const onChangePlan = () => {
    console.log(getPlan);
    changeSubscription(selectedPlan.id, token);
  };

  const WebInterfaceHandleModal = () => {
    handleFetchPlan();
    handleModal();
  };

  useEffect(() => {
    window.addEventListener("webBridge", async (e) => {
      console.log(e.detail);
      WebInterfaceHandleModal();
    });
  }, [window.dispatchEvent]);

  const onChangeCompleted = () => {
    handleModal(
        <ChangeCancelCompleteModal
            selectedPlan={selectedPlan}
            getPlan={getPlan}
            handleFetchPlan={handleFetchPlan}
        />,
        false,
        460
    );
  };

  useEffect(() => {
    // paypal 인경우 approve 절차를 위해 윈도우를 open 한다.
    console.log(changeData);

    if (changeData !== null) {
      if (changeData.length == 0) {
        onChangeCompleted();
      } else {
        // https://www.sandbox.paypal.com/webapps/billing/subscriptions/update?ba_token=BA-7B553245BY917471B
        let paypalWin = window.open(changeData, "PopupWin", "width=500,height=600,left=500,top=100,modal=yes");

        let popupTick = setInterval(function() {
          if (paypalWin.closed) {
            clearInterval(popupTick);
            // console.log('window closed!');
            // onPaypalUnloadTest();
          } else {
            if (paypalWin.location && paypalWin.location.host) {
              try {
                if (window.location.host === paypalWin.location.host) {
                  // console.log("정상 처리");
                  // paypalChangeSuccess = true;
                  onChangeCompleted();
                }
              } catch(e) {
                // ignore
              }
            }
          }
        }, 500);
      }
    }
  }, [changeData]);

  useErrorToastify(error);

  return (
    <Styled.Container>
      <StyledModal.H2>{t("Modal.cancelDowngrade.title")}</StyledModal.H2>

      <Styled.ChangePlan>
        {/* <Styled.CurrentPlan className={selectedPlan.planCode.toLowerCase()}>
          {selectedPlan.planCode}
        </Styled.CurrentPlan> */}
        <Styled.CurrentPlanP className={selectedPlan.planCode.toLowerCase()}>
          <span>{selectedPlan.planCode}</span>
          <Styled.PlanSpan>
            ({selectedPlan.takeCourseTerm}{
              selectedPlan.takeCourseTerm === 1
              ? t('planCard.month') : t('planCard.months')
            })
          </Styled.PlanSpan>
        </Styled.CurrentPlanP>
        <Styled.Arrow />
        {/* <Styled.NewPlan className={getPlan.currentPlan.toLowerCase()}>
          {getPlan.currentPlan}
        </Styled.NewPlan> */}
        <Styled.NewPlanP className={getPlan.currentPlan.toLowerCase()}>
          <span>{getPlan.currentPlan}</span>
          <Styled.PlanSpan>
            ({currentPlan.takeCourseTerm}{
              currentPlan.takeCourseTerm === 1
              ? t('planCard.month') : t('planCard.months')
            })
          </Styled.PlanSpan>
        </Styled.NewPlanP>
      </Styled.ChangePlan>

      <Styled.Desc>
        <Trans
          t={t}
          values={{
            date: getPlan.nextBeginDate.slice(0, 10),
            plan: selectedPlan.planCode,
            planMonth: selectedPlan.takeCourseTerm,
          }}
        >
          {selectedPlan.takeCourseTerm === 1
          ? 'Modal.cancelDowngrade.desc'
          : 'Modal.cancelDowngrade.desc_months'
          }
        </Trans>
      </Styled.Desc>

      <Styled.ButtonGroup>
        <Styled.Button className="gray bordered" onClick={() => handleModal()}>
          {t("Modal.cancelDowngrade.change")}
        </Styled.Button>
        <Styled.Button className="primary" onClick={onChangePlan}>
          {t("Modal.cancelDowngrade.cancel")}
        </Styled.Button>
      </Styled.ButtonGroup>

      <LoadingOverlay isLoading={loading} />
    </Styled.Container>
  );
};

export default ConfirmChangeCancelModal;

import React, { useState } from 'react';
import Styled from './UserGuideModal.style';
import { Modal } from 'container/LevelTestPage/styled';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import Login from 'component/user/Login';
import { ModalContext } from 'component/Modal/modalContext';

SwiperCore.use([Pagination]);

const UserGuideModal = () => {
  const [slideIdx, setSlideIdx] = useState(false);
  let { handleModal } = React.useContext(ModalContext);

  return (
    <Styled.Container>
      <Styled.Swiper
        spaceBetween={0}
        pagination={{ clickable: true }}
        onSlideChange={(e) => {
          if (e.realIndex === 3) {
            setSlideIdx(true);
          } else {
            setSlideIdx(false);
          }
        }}
        className={slideIdx ? 'last-item' : ''}
      >
        <SwiperSlide>
          <div className='slide-item1'>
            <Styled.Point>Point 01</Styled.Point>
            <Styled.SlideTitle>한국어, TOPIK 강좌 수강</Styled.SlideTitle>
            <Styled.SlideText>
              멤버십 가입하고, 한국어와 <br />
              TOPIK 강좌를 무제한으로 수강하세요.
            </Styled.SlideText>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='slide-item2'>
            <Styled.Point>Point 02</Styled.Point>
            <Styled.SlideTitle>
              레벨테스트 &amp; <br />
              맞춤 커리큘럼 추천
            </Styled.SlideTitle>
            <Styled.SlideText>
              레벨테스트로 나의 현재 수준을 알아보고, <br />
              나에게 알맞은 커리큘럼을 추천 받으세요.
            </Styled.SlideText>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='slide-item3'>
            <Styled.Point>Point 03</Styled.Point>
            <Styled.SlideTitle>TOPIK Mock Test</Styled.SlideTitle>
            <Styled.SlideText>
              실제 시험과 유사한 TOPIK 문제를 <br />
              온라인으로 풀고, 성적 분석 리포트를 받으세요.
            </Styled.SlideText>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='slide-item4'>
            <Styled.SlideTitle>다양한 회원 혜택</Styled.SlideTitle>
            <Styled.SlideText>
              MT Live, 단어 카드, 학습 자료, 커뮤니티, <br />
              AI 발음평가 등 masterTOPIK만의 <br />
              다양한 혜택을 지금 바로 누려보세요!
            </Styled.SlideText>
          </div>
        </SwiperSlide>
      </Styled.Swiper>

      {slideIdx ? (
        <Styled.Button onClick={() => handleModal(<Login />, true)}>
          로그인
        </Styled.Button>
      ) : (
        ''
      )}
    </Styled.Container>
  );
};

export default UserGuideModal;

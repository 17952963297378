import styled from 'styled-components';

const StyledSelectWrapper = styled.div`
  position: relative;
  font-size: 1rem;
  width: ${({ width }) => (width ? width : '12.5em')};
  .select {
    &__indicator {
      padding: 0.6em;
      svg {
        width: 2em;
        height: 2em;
      }
    }

    &__placeholder {
      font-size: 1.4em;
      font-weight: 400;
      color: #c7cad1;
    }

    &__control {
      height: 3.5em;
      min-height: 3.5em;
      border: 1px solid #5c5f66;
      border-radius: 0.6em;
      background-color: #0f1012;
      box-shadow: 0 0 0 transparent !important;
      &:hover {
        border: 1px solid #5c5f66;
      }
    }
    &__single-value {
      font-size: 1.4em;
      font-weight: 500;
      color: #d1d1d1;
    }
    &__indicator-separator {
      display: none;
    }
    &__menu {
      border-radius: 0.6em;
      background-color: #2b2e35;
      overflow: hidden;
      &-list {
        padding: 0;
      }
    }
    &__option {
      font-size: 1.4em;
      font-weight: 400;
      color: #c7cad1;
      letter-spacing: 0.05em;
      &--is-focused {
        background-color: #1e2024;
      }
      &:active,
      &:focus,
      &--is-selected {
        background-color: #32353c;
        color: #55575e;
      }
    }
  }
  @media (max-width: 767px) {
    .select__control {
      font-size: 1.4rem;
    }
  }
`;

export default StyledSelectWrapper;

import React from "react";
import Loading from "component/atoms/Loading";
import PreviousLink from "component/atoms/PreviousLink";
import { Layout, Detail } from "container/LearningMaterialPage/styled";
// import tempDefault from 'assets/images/temp/learning_material_img.jpg';
import Img from "component/atoms/Img";
import learningMaterialApi from "api/learningMaterial";
import { useAxios } from "common/hook/useAxiosHook";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import icoPdf from "assets/images/ico_file_pdf.png";
import icoExcel from "assets/images/ico_file_excel.png";
import icoMp3 from "assets/images/ico_file_mp3.png";
import icoImg from "assets/images/ico_file_img.png";
import icoWord from "assets/images/ico_file_word.png";
import icoOther from "assets/images/ico_file_other.png";
import ErrorPage from "component/atoms/Error";
import FileButtonContainer from "./FileButtonContainer";

import Features from "container/LandingPage/Features";
import { Trans, useTranslation } from "react-i18next";

const LearningMaterialDetail = () => {
  const { t } = useTranslation("learningMaterialDetailPage");
  const token = useSelector((state) => state.user.token);
  const { id: urlId } = useParams();
  const [item, fetchItem] = useAxios(() => learningMaterialApi.getItem(urlId, token), [urlId]);
  const { loading, data, error } = item;
  // const [file, fetchFile] = useAxios(learningMaterialApi.getFile, [], true);
  if (loading) return <Loading />;
  if (error) return <ErrorPage />;

  const getFileIcon = (isFile) => {
    let fileIcon = "";
    console.log(isFile);
    if (isFile === "pdf") {
      fileIcon = "pdf";
    } else if (isFile === "doc" || isFile === "docx") {
      fileIcon = "word";
    } else if (isFile === "xls" || isFile === "xlsx") {
      fileIcon = "excel";
    } else if (isFile === "jpg" || isFile === "JPG" || isFile === "jpeg" || isFile === "png" || isFile === "PNG") {
      fileIcon = "img";
    } else if (isFile === "mp3") {
      fileIcon = "mp3";
    } else {
      fileIcon = "other";
    }
    return fileIcon;
  };

  const getFileExtension = (item) => {
    let fileExtension = "";
    const isFile = item.length > 0 ? item[0].name.split(/\./).reverse()[0] : "";

    if (isFile === "pdf") {
      fileExtension = icoPdf;
    } else if (isFile === "doc" || isFile === "docx") {
      fileExtension = icoWord;
    } else if (isFile === "xls" || isFile === "xlsx") {
      fileExtension = icoExcel;
    } else if (isFile === "jpg" || isFile === "JPG" || isFile === "jpeg" || isFile === "png" || isFile === "PNG") {
      fileExtension = icoImg;
    } else if (isFile === "mp3") {
      fileExtension = icoMp3;
    } else {
      fileExtension = icoOther;
    }
    const fileIcon = item.length > 1 ? icoOther : fileExtension;

    return isFile === "" ? "" : <Img src={fileIcon} alt="figure Image" />;
  };

  return (
    <Layout.Wrapper>
      {/* <PreviousLink to='/learning-material' text='Back to Learning Material' /> */}

      {data && (
        <>
          <Detail.Board>
            <Detail.Header>
              <Detail.Title>{data.title}</Detail.Title>
              <Detail.Info>
                <Detail.Date>{data.regDate.split("T")[0]}</Detail.Date>
                <Detail.Views>{data.viewCnt}</Detail.Views>
              </Detail.Info>
            </Detail.Header>

            <Detail.Container>
              <Detail.Content>{data.contents}</Detail.Content>
              {data.attachments &&
                data.attachments[0].size !== null &&
                data.attachments[0].size !== undefined &&
                data.attachments[0].size !== "0" && (
                  <Detail.FileDownArea>
                    {data.attachments.map((file, index) => {
                      const fileType = file.name.split(/\./).reverse()[0];
                      return <FileButtonContainer key={index} getFileIcon={getFileIcon} fileType={fileType} fileData={file} planCode={data.planCode}/>;
                    })}
                  </Detail.FileDownArea>
                )}
            </Detail.Container>
          </Detail.Board>

          <Detail.List>
            {data.previousLearningMaterial ? (
              <Detail.Item>
                <Detail.Prev>{t("prevButton")}</Detail.Prev>
                <Detail.ListIcon>{getFileExtension(data.previousLearningMaterial.attachments)}</Detail.ListIcon>
                <Detail.ListCategory>{data.previousLearningMaterial.categoryCode}</Detail.ListCategory>
                <Detail.ListTitle>
                  <Detail.ListTitleLink to={`/learning-material/detail/${data.previousLearningMaterial.idx}`}>
                    {data.previousLearningMaterial.title}
                  </Detail.ListTitleLink>
                </Detail.ListTitle>
                <Detail.ListDate>{data.previousLearningMaterial.regDate.split("T")[0]}</Detail.ListDate>
              </Detail.Item>
            ) : (
              ""
            )}
            {data.nextLearningMaterial ? (
              <Detail.Item>
                <Detail.Next>{t("nextButton")}</Detail.Next>
                <Detail.ListIcon>{getFileExtension(data.nextLearningMaterial.attachments)}</Detail.ListIcon>
                <Detail.ListCategory>{data.nextLearningMaterial.categoryCode}</Detail.ListCategory>
                <Detail.ListTitle>
                  <Detail.ListTitleLink to={`/learning-material/detail/${data.nextLearningMaterial.idx}`}>
                    {data.nextLearningMaterial.title}
                  </Detail.ListTitleLink>
                </Detail.ListTitle>
                <Detail.ListDate>{data.nextLearningMaterial.regDate.split("T")[0]}</Detail.ListDate>
              </Detail.Item>
            ) : (
              ""
            )}
          </Detail.List>
        </>
      )}
      <Layout.ButtonGroup>
        <Layout.Button to="/learning-material"> {t("button")} </Layout.Button>
      </Layout.ButtonGroup>
    </Layout.Wrapper>
  );
};

export default LearningMaterialDetail;

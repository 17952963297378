import { asyncApi } from 'common/util/asyncApi';
const baseUrl = '/api/v1/curriculums';

const curriculumApi = {
  getMyCurriculum: async (access_token) => {
    const config = {
      url: baseUrl + '?mine',
      headers: { Authorization: `Bearer ${access_token}` },
    };

    let response = await asyncApi(config);
    return response.data;
  },
  getCurriculumList: async (access_token, page = 0, size = 8, data = []) => {
    const config = {
      method: 'post',
      url: baseUrl + '?filtered',
      params: { size, page },
      data: data,
      headers: { Authorization: `Bearer ${access_token}` },
    };

    let response = await asyncApi(config);
    return response.data;
  },

  getCurriculumDetailData: async (access_token, id, data) => {
    const config = {
      url: baseUrl + `/${id}`,
      headers: { Authorization: `Bearer ${access_token}` },
    };

    let response = await asyncApi(config);
    return response.data;
  },

  setMyCurriculum: async (access_token, id) => {
    const config = {
      method: 'post',
      url: baseUrl + `/${id}`,
      headers: { Authorization: `Bearer ${access_token}` },
    };
    let response = await asyncApi(config);
    return response;
  },
  cancelMyCurriculum: async (access_token) => {
    const config = {
      method: 'put',
      url: baseUrl,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    let response = await asyncApi(config);
    return response;
  },
};

export default curriculumApi;

import Loading from 'component/atoms/Loading';
import Error from 'component/Error';
import ThemeListHeader from 'component/molecules/ThemeListHeader';
import ThemeItemList from 'container/UserBookmarkPage/Theme/MyThemeList/ThemeItemList';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import { EmptyList } from 'component/atoms/EmptyList';

const Wrapper = styled.div`
  margin-bottom: 10em;
  .infinite-scroll-component {
    overflow: visible !important;
  }
`;

const Container = styled.div`
  margin-bottom: 10em;
`;

const MyThemeList = ({ initialState, token, setTotal }) => {
  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [themeList, fetchThemeList] = initialState;
  const { loading, data, error } = themeList;

  // 최초 1회 호출
  useEffect(() => fetchThemeList({ page: 0, token }), []);

  useEffect(() => {
    if (data !== null) {
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);

      // Classroom > MyClassroom 내 상단 요약부분
      setTotal && setTotal(data.totalElements);
    }
  }, [themeList]);

  const onNext = () => {
    fetchThemeList({ page: data.number + 1, token });
  };

  if (error) return <Error error={error} />;

  // 20210201 무한 스크롤 시작 지점 이슈가 있음
  // 로딩 지점이 무한스크롤 컴포넌트 넘어가서 발생함. 내부 렌더링이 나중에 발생해서 그런 것으로 예상됨
  return (
    <Wrapper>
      <InfiniteScroll
        dataLength={slideItem.length}
        next={onNext}
        loader={<Loading />}
        hasMore={hasMore}
        scrollThreshold={0.5}
      >
        {slideItem.length === 0 ? (
          <EmptyList isLoading={loading} />
        ) : ( slideItem.map((item, index) => (
          <Container key={index}>
            <ThemeListHeader
              id={item.id}
              title={item.name}
              tag={item.categoryName}
              videoCount={item.courseCount}
              likeCount={item.likeCount}
              isOpen={item.isOpen}
              isGood={item.isGood}
            />
            <ThemeItemList id={item.id} token={token} />
          </Container>
        )))}
      </InfiniteScroll>
    </Wrapper>
  );
};

export default MyThemeList;

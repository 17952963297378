import React, { useEffect, useState } from 'react';
import Styled from './index.style';
import { useParams } from 'react-router-dom';
import CommentWrite from 'container/CommunityPage/Detail/Comment/Write';
import CommentItem from 'container/CommunityPage/Detail/Comment/Item';
import ChildItem from 'container/CommunityPage/Detail/Comment/ChildItem';
import { useTranslation } from 'react-i18next';

const Comment = ({ initialState, token, userData }) => {
  const { t } = useTranslation('communityPage');
  const { id } = useParams();

  const [listItem, setListItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const [commentState, fetchComment] = initialState;
  const { loading, data, error } = commentState;

  const editState = useState(null);
  const reReplyState = useState(null);

  useEffect(() => fetchComment(id, token), []);

  useEffect(() => {
    if (data !== null) {
      setListItem([...listItem, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [commentState]);

  const onNext = () => fetchComment(id, token, data.number + 1);

  const [refreshedCommentState, refreshComment] = initialState;
  const onRefresh = () => {
    refreshComment(id, token);
  };
  useEffect(() => {
    if (data !== null) {
      setListItem(data.content);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [refreshedCommentState]);

  return (
    <>
      <Styled.H3>{t('post.commentTitle')}</Styled.H3>
      <Styled.Wrapper>
        <CommentWrite onRefresh={onRefresh} t={t} />

        {listItem.length > 0 && (
          <Styled.Container>
            {listItem.map((item, index) => (
              <Styled.Item key={index}>
                <CommentItem
                  item={item}
                  userData={userData}
                  editState={editState}
                  reReplyState={reReplyState}
                  onRefresh={onRefresh}
                />
                <ChildItem
                  parentComment={item}
                  token={token}
                  userData={userData}
                  editState={editState}
                  reReplyState={reReplyState}
                  onRefresh={onRefresh}
                />
                {/* {item.childReplies.length > 0 &&
                  item.childReplies.map((child, index) => (
                    <Styled.ChildItem key={index}>
                      <CommentItem
                        item={child}
                        userData={userData}
                        editState={editState}
                        reReplyState={reReplyState}
                        onRefresh={onRefresh}
                      />
                    </Styled.ChildItem>
                  ))} */}
              </Styled.Item>
            ))}
          </Styled.Container>
        )}
      </Styled.Wrapper>
    </>
  );
};

export default Comment;

import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLineBanner = {
  Wrapper: styled.div`
    background-color: #485cc7;
    height: 6rem;
    overflow: hidden;
    opacity: 1;
    @media (max-width: 1024px) {
      width: 100vw;
    }
  `,
  Link: styled(Link)`
    display: flex;
    font-size: 1.6em;
    max-width: 130rem;
    height: 3.75em;
    margin: auto;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    color: #fff;
    svg {
      font-size: 1.2em;
      margin-left: 1em;
      font-weight: bold;
    }
  `,
  Close: styled.span`
    position: absolute;
    top: 0.4em;
    right: 0.5em;
    font-size: 3rem;
    cursor: pointer;
  `,
};

export default StyledLineBanner;

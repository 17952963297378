import { asyncApi } from 'common/util/asyncApi';

// 알림 모두 가져오기
export const fetchAllNotifications = async ({
  access_token,
  title = '',
  size = 10,
  page = 0,
}) => {
  let config = {
    url: `/api/v1/notifications`,
    params: { title, size, page },
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };

  let response = await asyncApi(config);
  const newResponse = { ...response.data, title };
  return newResponse;
};

// 알림 하나 읽음 처리
export const updateToRead = async (access_token, id) => {
  let config = {
    method: 'put',
    url: `/api/v1/notifications/${id}?read`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  let response = await asyncApi(config);
  return response.data.content;
};

// 고정 알림 하나 읽음 처리
export const updateToReadFixed = async (access_token, id) => {
  let config = {
    method: 'put',
    url: `/api/v1/notifications/${id}?read&fix`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  let response = await asyncApi(config);
  return response.data.content;
};

// 모든 알림 읽음 처리
export const readAll = async (access_token) => {
  let config = {
    method: 'put',
    url: `/api/v1/notifications/read`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  let response = await asyncApi(config);
  return response.data;
};

// 읽음 처리된 알림 리스트 삭제
export const readListDelete = async (access_token) => {
  let config = {
    method: 'delete',
    url: `/api/v1/notifications/readed`,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  let response = await asyncApi(config);
  return response;
};

// 마이페이지 > 알림 아이콘 숫자
export const getNotificationAlarm = async (token) => {
  let config = {
    url: `/api/v1/notifications?alerts`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await asyncApi(config);
  return response.data;
};

// 마이페이지 > 알림 아이콘 - 공지사항 고정 알림 목록
export const getFixedNotice = async (page, token) => {
  let config = {
    url: `/api/v1/notifications?notices`,
    method: 'get',
    params: { page },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await asyncApi(config);
  return response.data;
};

// 마이페이지 > 알림 아이콘 - Upcoming Lectures 고정 알림 목록
export const getUpcomingLectures = async (page, token) => {
  let config = {
    url: `/api/v1/notifications?news`,
    method: 'get',
    params: { page },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await asyncApi(config);
  return response.data;
};

// 마이페이지 > 알림 아이콘 - 알림 목록
export const getNotice = async (page, size = 10, token) => {
  let config = {
    url: `/api/v1/notifications`,
    method: 'get',
    params: { page, size },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let response = await asyncApi(config);
  return response.data;
};

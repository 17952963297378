import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Style from '../styled/index.style';

import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';

import Search from 'container/HelpPage/components/Search';
import Breadcrumb from 'container/HelpPage/components/Breadcrumb';
import ContactUs from 'container/HelpPage/components/ContactUs';

import LnbDocument from 'container/HelpPage/Lnb/Document';
import Detail from 'container/HelpPage/Detail/Detailview';
import { useAxios } from 'common/hook/useAxiosHook';
import helpApi from 'api/help';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const HelpDetailPage = () => {
  const { t } = useTranslation('helpPage');
  const { articleIdx } = useParams();

  const [articleDetail, fetchArticleDetail] = useAxios(
    helpApi.getArticleDetail,
    [],
    true
  );

  const { loading, data, end } = articleDetail;

  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    fetchArticleDetail(articleIdx);
  }, [fetchArticleDetail, articleIdx]);

  useEffect(() => {
    if (data && data.doc) {
      const cateName = (data.doc.category === 'all') ? 'ALL'
        : (data.doc.category === 'guide') ? 'Guide'
          : (data.doc.category === 'notice') ? 'Notice'
            : (data.doc.category === 'event') ? 'Event'
              : (data.doc.category === 'study') ? 'Study' : 'ALL';

      setCategoryName(cateName);
    }
  }, [data]);

  return (
    <>
      {data &&
        <>
          <ContentsWrapper>
            <PageHeader title={t('title')} />

            {/* 화면 경로 */}
            <Breadcrumb categoryName={categoryName} />

            {/* 검색 */}
            <Search placeholder={t('search')} />
          </ContentsWrapper>

          <Style.DetailWrapper>
            <ContentsWrapper className='Contents'>

              {/* LNB 영역 */}
              <Style.DetailLnb>
                {/* 문서목록 */}
                <LnbDocument t={t} category={data.doc.category} articleIdx={data.doc.tblCustomerCenterDocIdx} />
              </Style.DetailLnb>

              {/* 컨텐츠 영역 */}
              <Style.DetailContent>
                {/* 문서 상세 */}
                <Detail t={t} article={data.doc} />
              </Style.DetailContent>

            </ContentsWrapper>
          </Style.DetailWrapper>
        </>
      }
      <LoadingOverlay isLoading={loading || !data} />
      <ContactUs />
    </>
  );
};
export default HelpDetailPage;


import React, { useState, useEffect } from 'react';
import Styled from 'container/UserHomePage/styled/Profile.style';
import Img from 'component/atoms/Img';
import defaultThumbnail from 'assets/images/img_user.png';
import { useAxios } from 'common/hook/useAxiosHook';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SiteMapData } from 'common/constant';
import { Link } from 'react-router-dom';
// import PlanBannerPopup from "component/PlanBanner";
import RollBanner from 'component/RollBanner';
import { confirmMemberGoal } from 'api/memberGoal';

export default function Profile({ user }) {
  const { t } = useTranslation('userHomePage');
  const [obBannerView, setObBannerView] = useState(false);
  const userData = useSelector((state) => state.user.data);
  const token = useSelector((state) => state.user.token);
  const linkCheckURL = () => {
    if (user.selectLanguage === 'KO') {
      window.open(
        `https://mastertopikhelp.zendesk.com/hc/ko/articles/1500004503882`
      );
    } else {
      window.open(
        `https://mastertopikhelp.zendesk.com/hc/en-us/articles/1500004503882`
      );
    }
  };

  const planCheckURL = () => {
    if (userData.serviceLevel === 'PREMIUM')
      //프리미엄 상품 구매자자
      return '/classroom/topik-mock-test';
    //    else if(userData.serviceLevel === "EPS" || "STANDARD")
    //      //무료체험 이후 또는 상품구매자
    //      return SiteMapData.plan;
    //무료체험 미진행
    else return SiteMapData.plan;
  };
  const checkMessege = () => {
    if (userData.serviceLevel === 'PREMIUM')
      //프리미엄 상품 구매자자
      return 'TOPIK MOCK TEST';
    else if (userData.serviceLevel === 'EPS')
      //무료체험 이후 또는 상품구매자
      return 'UPGRADE';
    else if (userData.serviceLevel === 'STANDARD')
      //무료체험 이후 또는 상품구매자
      return 'UPGRADE';
    else if (userData.orderPayInfo.length !== 0)
      //무료체험 이후 FREE 상태
      return 'UPGRADE';
    //무료체험 미진행
    else return 'START FREE TRIAL';
  };
  const [{ data: memberGoal }, getMemberGoal] = useAxios(
    confirmMemberGoal,
    [],
    true
  );

  useEffect(() => {
    getMemberGoal(token, user.userIdx);
  }, []);

  useEffect(() => {
    if (!memberGoal) return;
    const data = memberGoal.memberGoal;
    if (data.isFinish !== 1) setObBannerView(true);
  }, [memberGoal]);

  return (
    <Styled.Wrapper>
      {/* 20221209 PlanBannerPopup > RollBanner로 변경 */}
      <RollBanner />
      {/* <PlanBannerPopup /> */}
      {/* <Styled.Inner>
        <Styled.Thumbnail>
          <Img
            src={
              user.profilePhotoPath ? user.profilePhotoPath : defaultThumbnail
            }
            alt='Thumbnail'
            onError={defaultThumbnail}
          />
        </Styled.Thumbnail>
        <Styled.Texts>
          <Styled.State>
            <Styled.StateIcon>{userData.serviceLevel}</Styled.StateIcon>
            <Styled.StateFreeButton to={planCheckURL()}>
              {checkMessege()}
            </Styled.StateFreeButton>
          </Styled.State>
          <Styled.Name>
            <Trans t={t} values={{ name: user.fullName }}>
              greetingBoard.greeting
            </Trans>
          </Styled.Name>
          <Styled.Desc onClick={linkCheckURL}>
            {t('greetingBoard.quote')}
          </Styled.Desc>
        </Styled.Texts>
        {checkMessege() === 'START FREE TRIAL' && obBannerView ? (
          <Styled.Onboarding to={`/complete-signup`}>
            <Styled.OnboardingName>
              <Trans t={t} values={{ name: user.fullName }}>
                onboarding.greeting
              </Trans>
            </Styled.OnboardingName>
            <Styled.OnboardingDesc>
              <Trans t={t}>onboarding.quote</Trans>
            </Styled.OnboardingDesc>
            <Styled.OnboardingButton>
              <span>{t('onboarding.button')}</span>
            </Styled.OnboardingButton>
          </Styled.Onboarding>
        ) : undefined}
      </Styled.Inner> */}
    </Styled.Wrapper>
  );
}

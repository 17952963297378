import React from 'react';
import Styled from './styled/Language.style';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Event = ({isLogin}) => {

  return (
    <Styled.Container2 className='header-event-wrap' style={isLogin? {} : {margin:"2.3rem 2rem 0 0"}}>

    </Styled.Container2>
  );
};

export default Event;

import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/landing';

/**
 * getList 랜딩페이지 faq 목록
 */
const landingBanner = {
  getBanner: async () => {
    let response = await asyncApi({ url });
    return response.data;
  },
  updateResearchButtonClicked: async (learnType) => {
    //learnType : course | alive(20230210 이름은 alive이나 모의고사)
    // 20230210 GNB에서 Alive class 삭제 (B2C 화상수업 종료)
    // course 또는 mock-test로 변경되었으나 api의 learnType은 alive 그대로 유지
    const config = {
      url: `/api/v1/pr/addCount`,
      params: {learnType: learnType},
    };
    const response = await asyncApi(config);
    return response.data;
    /*
    {
      result: "success",
      reason: "add count 1"
    }
    {
      result: "fail",
      reason: "learnType value error. (course or alive)"
    }
    */
  }
};

export default landingBanner;

import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const AppPayLetter = () => {
  const { state: payletterUrl } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (payletterUrl && !payletterUrl.url && payletterUrl.itemType) {
      history.push("/payment/" + payletterUrl.itemType);
    }else{
      history.push("/");
    }

    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <iframe
        src={payletterUrl && payletterUrl.url}
        frameborder="0"
        width="100%"
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

export default AppPayLetter;

import styled, { css } from 'styled-components';
import Select from 'react-select';
import { RiErrorWarningFill } from 'react-icons/ri';

const WrapperStyles = css`
  @media (max-width: 1024px) {
    font-size: 10px;
  }
  @media (max-width: 767px) {
    font-size: 1.25em;
  }
`;

export const inputStyles = css`
  font-size: 1.6em;
  width: 100%;
  height: 3.375em;
  padding: 0 1.25em;
  font-weight: 500;
  border: 0;
  border-radius: 0.6rem;
`;

export const StyledModalForm = {
  DivWrapper: styled.div`
    ${WrapperStyles}
  `,
  FormWrapper: styled.form`
    ${WrapperStyles}
  `,
  InputContainer: styled.div`
    margin-bottom: 2rem;
  `,
  ButtonGroup: styled.div`
    overflow: hidden;
    button {
      float: left;
      width: calc(50% - 0.5rem);
    }
    button:first-child {
      margin-right: 1rem;
    }
  `,
  Label: styled.label`
    display: block;
    font-size: 1.4em;
    margin-bottom: 0.3em;
    color: #222;
    span.confirmed {
      margin-right: 5px;
      font-size: 1.1em;
      color: #485cc7;
      vertical-align: middle;
    }
    @media (max-width: 767px) {
      font-size: 1.6em;
    }
  `,
  Input: styled.input`
    ${inputStyles}
    padding-left:8px;
    background-color: #ebedf2;
    color: #2b2e35;
    transition: all 0.1s ease;
    &:focus {
      background-color: #c9cbd1;
    }
    ::placeholder {
      font-weight: 300;
    }
    :disabled {
      color: #666;
    }
  `,
  Textarea: styled.textarea`
    ${inputStyles}
    height: 6.375em;
    padding: 1.25em;
    padding-left: 8px;
    background-color: #ebedf2;
    color: #2b2e35;
    line-height: 1.5;
    resize: none;
    &:focus {
      background-color: #c9cbd1;
    }
    ::placeholder {
      font-weight: 300;
    }
  `,
  Button: styled.button.attrs(({ type }) => ({
    type: type ? type : 'submit',
  }))`
    ${inputStyles}
    position: relative;
    background-color: #ffb500;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 0.5px;
    @media (max-width: 375px) {
      font-size: 1.4em;
    }
  `,
  CancelButton: styled.button.attrs({ type: 'button' })`
    ${inputStyles}
    position: relative;
    border: 1px solid #5c5f66;
    background-color: transparent;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    color: #5c5f66;
    letter-spacing: 0.5px;
    @media (max-width: 375px) {
      font-size: 1.4em;
    }
  `,
  Select: styled(Select).attrs({
    classNamePrefix: 'select',
  })`
    font-size: 1.6em;
    .select__value-container {
    }
    .select__control {
      &--is-focused {
        border: none;
        background-color: #c9cbd1;
        box-shadow: none;
      }
    }
    .select__menu-list {
      border-radius: 0.6rem;
    }
    .select__indicator-separator {
      display: none;
    }
    .select__indicator {
      color: #666;
    }
    > div {
      background-color: #ebedf2;
      border: none;
      min-height: 3.375em;
    }
    .select__single-value {
      font-weight: 500;
      color: #2b2e35;
    }
    .select__menu {
      z-index: 100;
    }
  `,
  WarnText: styled.div.attrs(({ children }) => ({
    children: (
      <>
        <RiErrorWarningFill />
        {children}
      </>
    ),
  }))`
    margin-top: 0.5em;
    font-size: 1.4rem;
    font-weight: 400;
    padding-left: 1.6em;
    text-indent: -1.6em;
    color: #cf1010;
    word-break: break-word;
    svg {
      margin-right: 0.2em;
      font-size: 1.3em;
      vertical-align: -0.2em;
    }
  `,
};

export default StyledModalForm;

import React, { useContext } from 'react';
import Styled from './index.style';
import { PlayerContext } from 'component/Player';
import {
  RiVolumeDownFill,
  RiVolumeMuteFill,
  RiVolumeUpFill,
} from 'react-icons/ri';
import ProgressSlider from 'component/atoms/ReactSlider';

const VolumeControl = (props) => {
  const { playerState, handleState } = useContext(PlayerContext);
  const { muted, volume } = playerState;

  const handleMuted = () => {
    if (muted && volume === 0) {
      handleState({ muted: !muted, volume: 0.1 });
    } else {
      handleState({ muted: !muted });
    }
  };

  const handleVolumeChange = (value) => {
    handleState({ volume: value / 100, muted: value === 0 });
  };

  return (
    <Styled.VolumeContainer {...props}>
      <Styled.Volume className='volume-button' onClick={handleMuted}>
        {muted ? (
          <RiVolumeMuteFill />
        ) : volume > 0.5 ? (
          <RiVolumeUpFill />
        ) : (
          <RiVolumeDownFill />
        )}
      </Styled.Volume>

      <ProgressSlider
        className='volume-slider'
        value={volume * 100}
        onChange={handleVolumeChange}
      />
    </Styled.VolumeContainer>
  );
};

export default VolumeControl;

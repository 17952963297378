import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'common/store';
import { persistor } from 'common/store';
import App from './App';

import { PersistGate } from 'redux-persist/integration/react';

// import i18n (needs to be bundled ;))
import './i18n';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-M6DSB3B',
  // dataLayer: {
  //   userId: '001',
  //   userProject: 'project',
  // },
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

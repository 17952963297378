import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import StyledUtil from "common/styled/Util.style";

const containerStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 8.5rem;
`;

export const StyledPostList = {
  Wrapper: styled.div`
    padding: 2em 0;
    margin: 2em 0 8em;
    border-width: 1px 0;
    border-style: solid;
    border-color: #5d6165;
    @media (max-width: 767px) {
      font-size: 1.2em;
    }
  `,
  Header: styled.div`
    ${containerStyles};
    height: 6rem;
    > span {
      font-weight: 500;
      color: #fff;
    }
    .post-title {
      text-align: center !important;
    }

    @media (max-width: 767px) {
      display: none;
    }
  `,
  Item: styled.div`
    &:nth-child(2n - 1) {
      background-color: #1a1b1f;
    }
    > a {
      ${containerStyles};
    }
  `,
  Column: styled.span`
    font-size: 1.6em;
    text-align: center;
    color: #969696;
    &.post-title {
      ${StyledUtil.textEllipsis}
      flex: 3;
      padding: 0 3rem;
      font-weight: 500;
      text-align: left;
      color: #fff;
    }
    &.replier,
    &.view-count,
    &.replies-count,
    &.reg-date {
      width: 15rem;
    }

    @media (max-width: 1024px) {
      &.view-count,
      &.replies-count,
      &.reg-date {
        width: 12rem;
      }
    }

    @media (max-width: 767px) {
      &.view-count,
      &.replies-count,
      &.reg-date {
        display: none;
      }
    }
    .post-title-desc {
      font-weight: 400;
      color: #969696;
    }
  `,
};

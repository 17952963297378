import styled from 'styled-components';
import { BsSearch } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';

export const SearchInput = {
  Container: styled.div`
    margin-top: 8em;
  `,
  InputContainer: styled.span`
    display: inline-block;
    position: relative;
    width: 100%;
    border-radius: 0.6rem;
    background-color: #33363d;
  `,

  Input: styled.input`
    display: inline-block;
    width: calc(100% - 6.250em);
    height: 3em;
    border: none;
    background: transparent;
    color: #ffffff;
    font-size: 1.6em;
    outline: none;

    &:focus {
      outline: none;
    }
  `,
  SearchImage: styled(BsSearch)`
    display: inline-block;
    width: 2.5em;
    color: #ffffff;
    font-size: 2em;
    transform: translateY(0.25em);
  `,
  CloseImage: styled(IoMdClose)`
    display: inline-block;
    width: 2em;
    font-size: 2.5em;
    color: #ffffff;
    transform: translateY(0.25em);
    cursor: pointer;
  `,
}

export default SearchInput;

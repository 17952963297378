import styled from 'styled-components';

const StyledPencil = {
  Container: styled.div`
    position: relative;
    margin-bottom: 4em;
  `,
  Line: styled.div`
    position: absolute;
    left: 0;
    bottom: -1em;
    height: 3px;
    background: #666;
    animation: writeHorizontal 1.5s infinite ease-in alternate;
    @keyframes writeHorizontal {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  `,
  Pencil: styled.div`
    position: relative;
    width: 4em;
    left: -25%;
    font-size: 0.4em;
    margin-left: 20em;
    margin-top: 30em;
    transform: rotate(25deg) translate(-50%);
    animation: moveHorizontal 1.5s infinite linear alternate;

    @keyframes moveHorizontal {
      0% {
        left: -25%;
      }

      100% {
        left: 80%;
      }
    }
  `,

  Body: styled.div`
    width: 4em;
    height: 30em;
    background-color: #ffbb00;
  `,
  Ferrule: styled.div`
    width: 4em;
    height: 2em;
    background-color: #d0caca;
  `,
  Wood: styled.div`
    width: 4em;
    height: 0;
    border-top: 4em solid #ffca97;
    border-left: 1.5em solid transparent;
    border-right: 1.5em solid transparent;
  `,
  Lead: styled.div`
    position: relative;
    width: 0;
    height: 0;
    border-top: 1.5em solid #000000;
    border-left: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
    margin: 0px auto;
  `,

  Eraser: styled.div`
    width: 4em;
    height: 2.5em;
    background-color: #b08900;
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
  `,
};

export default StyledPencil;

import { asyncApi } from 'common/util/asyncApi';

export const fetchCourseData = async (productId, access_token) => {
  const baseUrl = `/api/v1/products/${productId}`;
  let courseData;
  if (access_token) {
    const config = {
      url: baseUrl,
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    courseData = await asyncApi(config);
  } else {
    const config = {
      url: baseUrl,
    };
    courseData = await asyncApi(config);
  }

  // let courseVideoData;
  // if (access_token) {
  //   const videoConfig = {
  //     url: baseUrl + '/course/videos?type=detail',
  //     headers: {
  //       Authorization: `Bearer ${access_token}`,
  //     },
  //   };
  //   courseVideoData = await asyncApi(videoConfig);
  // } else {
  //   const videoConfig = { url: baseUrl + '/course/videos?type=detail' };
  //   courseVideoData = await asyncApi(videoConfig);
  // }

  // let response = {
  //   courseData: courseData.data,
  //   courseVideoData: courseVideoData.data,
  // };
  const response = courseData.data;
  return response;
};

export const fetchVideoData = async (productId, access_token) => {
  let courseVideoData;
  const baseUrl = `/api/v1/products/${productId}`;

  if (access_token) {
    const config = {
      url: baseUrl + '/course/videos?type=detail',
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };
    courseVideoData = await asyncApi(config);
  } else {
    const config = {
      url: baseUrl + '/course/videos?type=detail',
    };
    courseVideoData = await asyncApi(config);
  }
  const response = courseVideoData.data;
  return response;
};

export const fetchOverview = async (productId) => {
  const baseUrl = `/api/v1/products/${productId}`;
  const config = { url: baseUrl + '/course/overview' };
  let response = await asyncApi(config);
  return response.data;
};

export const fetchRelatedCourses = async (
  productId,
  page = 0,
  token = null
) => {
  const header = token && { headers: { Authorization: `Bearer ${token}` } };

  const baseUrl = `/api/v1/products/${productId}`;
  const config = {
    url: baseUrl + '/related-courses',
    ...header,
    params: { page },
  };
  let response = await asyncApi(config);
  return response.data;
};

export const fetchWorkBook = async (productId, access_token) => {
  const baseUrl = `/api/v1/products/${productId}`;
  const config = {
    url: baseUrl + '/files',
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  let response = await asyncApi(config);
  return response.data;
};
export const fetchTextBook = async (productId) => {
  const baseUrl = `/api/v1/products/${productId}`;
  const config = { url: baseUrl + '/book' };
  let response = await asyncApi(config);
  return response.data;
};

export const fetchCourseLike = async (productId, access_token) => {
  const baseUrl = `/api/v1/products/${productId}/likes`;
  const config = {
    url: baseUrl,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  let response = await asyncApi(config);
  return response.data;
};

export const postCourseLike = async (productId, access_token) => {
  const baseUrl = `/api/v1/products/${productId}/likes`;
  const config = {
    method: 'post',
    url: baseUrl,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': `application/json`,
    },
  };
  const response = await asyncApi(config);

  return response.data;
};
export const deleteCourseLike = async (productId, access_token) => {
  const baseUrl = `/api/v1/products/${productId}/likes`;
  const config = {
    method: 'delete',
    url: baseUrl,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': `application/json`,
    },
  };
  const response = await asyncApi(config);

  return response.data;
};

export const fetchCourseBookmark = async (productId, access_token) => {
  //localhost:8080/api/v1/bookmarks?products=909

  const baseUrl = `/api/v1/bookmarks?products=${productId}`;
  const config = {
    url: baseUrl,
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  };
  let response = await asyncApi(config);
  return response.data;
};

export const postCourseBookmark = async (data, access_token) => {
  const baseUrl = `/api/v1/bookmarks`;
  const config = {
    method: 'post',
    url: baseUrl,
    data: data,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': `application/json`,
    },
  };
  const response = await asyncApi(config);

  return response.data;
};
export const deleteCourseBookmark = async (data, access_token) => {
  const baseUrl = `/api/v1/bookmarks?products=${data.productId}`;
  const config = {
    method: 'delete',
    url: baseUrl,
    data: data,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': `application/json`,
    },
  };
  const response = await asyncApi(config);

  return response.data;
};

export const postFileDownloadLog = async (access_token, productId, fileId) => {
  const baseUrl = `/api/v1/products/${productId}/files/${fileId}`;
  const config = {
    method: 'post',
    url: baseUrl,
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': `application/json`,
    },
  };
  const response = await asyncApi(config);

  return response.data;
};

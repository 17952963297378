import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/order';
const size = 8;

/**
 * postSubscription
 */
const orderApi = {
  postSubscription: async (data, token) => {
    let response = await asyncApi({
      url: `${url}/subscription`,
      method: 'post',
      data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  postOrderBooks: async (data, token) => {
    let response = await asyncApi({
      url: `${url}/books`,
      method: 'post',
      data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  postOrderVideoClass: async (data, token) => {
    let response = await asyncApi({
      url: `${url}/videoclass`,
      method: 'post',
      data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },

  changeSubscription: async (data, token) => {
    let response = await asyncApi({
      url: `${url}/subscription/change`,
      method: 'put',
      data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status;
  },

  cancelPaypalOrder: async (backUrl, token) => {
    let response = await asyncApi({
      url: backUrl,
      method: 'get',
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status;
  },

  confirmPaypalOrder: async (data, token) => {
    let response = await asyncApi({
      url: `${url}/confirmPaypal`,
      method: 'post',
      data,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.status;
  },
};

export default orderApi;

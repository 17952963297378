import React, { useEffect, useRef, useState } from 'react';
import Styled from './index.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';

import SwiperCore, { Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import SliderNav from 'component/atoms/SliderNav/SliderNav.style';
import { CourseData } from 'container/LandingPage/Courses/CourseData';
import ReactPlayer from 'react-player';
import CoursesVideoThumb from 'container/LandingPage/Courses/CoursesVideoThumb';

SwiperCore.use([Navigation]);

export default function Courses({ initialState, t, i18nReady }) {

  const listItem = CourseData({ t });

  const [videoItem, setVideoItem] = useState(null);

  useEffect(() => {
    setVideoItem(CourseData({ t })[0]);
  }, [t]);

  const videoRef = useRef();

  const touchVideoScreen = () => {
    if (!videoRef) return;
    //videoRef.current.plyr.togglePlay((bool) => !bool);
    setIsPlay((bool) => !bool);
  };

  // Video State
  const [isPlay, setIsPlay] = useState(false);;

  if (!i18nReady) return null;

  return (

    <Styled.Wrapper>
      <ContentsWrapper>
        <Styled.H2>{t('courses.title')}</Styled.H2>

        {/* {loading && <Loading />} */}

        <Styled.SwiperWrap>
          <SliderNav.Prev
            className={`slider-prev prev-courses ${
              listItem.length > 4 ? 'visible' : ''
            }`}
          />
          <SliderNav.Next
            className={`slider-next next-courses ${
              listItem.length > 4 ? 'visible' : ''
            }`}
          />
          <Styled.Swiper
            speed={300}
            slidesPerView='auto'
            navigation={{
              nextEl: `.next-courses`,
              prevEl: `.prev-courses`,
            }}
          >
            {listItem.length > 0 &&
              listItem.map((item, index) => (
                <SwiperSlide key={index}>
                  <span
                    onClick={() => setVideoItem(item)}
                    className={
                      item?.categoryValue === videoItem?.categoryValue
                        ? 'active'
                        : ''
                    }
                  >
                    {item.categoryValue}
                  </span>
                </SwiperSlide>
              ))}
          </Styled.Swiper>
        </Styled.SwiperWrap>
        {videoItem && (
        <Styled.Video.Wrapper onTouchStart={touchVideoScreen}>
          <ReactPlayer
            ref={videoRef}
            url={videoItem?.otMovUrl}
            playing={isPlay}
            controls
            width='100%'
            height='100%'
            config={{
              file: {
                attributes: {
                  onContextMenu: (e) => e.preventDefault(),
                  controlsList: 'nodownload',
                },
              },
            }}
          />
          <CoursesVideoThumb videoItem={videoItem} videoRef={videoRef} setIsPlay={setIsPlay} />
        </Styled.Video.Wrapper>
        )}
      </ContentsWrapper>
    </Styled.Wrapper>
  );
}

import React, { useEffect, useRef, useState, useCallback } from 'react';
import Styled from './styled/StickyArea.style';
import Scroll from "react-scroll";
import { MOBILE_APP_USER_AGENT } from "common/constant";
import { openLink } from 'common/util/util';

const StickyArea = ({dday, text, link, showTeachers, showTopik, showKorean, showMyClass}) => {
  const [dDay, setDDay] = useState('D-0');
  const stickyWrapRef = useRef();
  const stickyRef = useRef();
  const [hHeight, sethHeight] = useState(document.querySelector('header').getBoundingClientRect().bottom); //해더 높이
  const [sHeight, setsHeight] = useState(stickyRef.current?.clientHeight? stickyRef.current.clientHeight : 54); //stickyRef height
  const [scrollOffset, setScrollOffset] = useState((hHeight + sHeight) * -1); //tab scroll offset
  const [winScrollY, setWinScrollY] = useState(window.scrollY);

  useEffect(() => {
    const todayDate = new Date();
    const ddayDate = new Date(dday);
    const diff = ddayDate - todayDate;
    const diffDay = Math.ceil(diff / (1000*60*60*24));
    if(diffDay === 0){
      setDDay('D-Day');
    }else if(diffDay > 0){
      setDDay(`D-${diffDay}`);
    }else {
      setDDay(`D+${Math.abs(diffDay)}`);
    }
  }, [dday]);

  // Sticky Menu
  useEffect(() => {
    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onResize);
    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('scroll', onResize);
    };
  }, []);

  const onResize = () => {
    const headerBtm = document.querySelector('header').getBoundingClientRect().bottom;
    sethHeight(headerBtm);
    setsHeight(stickyRef.current.clientHeight);
    setScrollOffset((headerBtm + stickyRef.current.clientHeight) * -1);
    setWinScrollY(window.scrollY);
  }

  useEffect(()=>{
    const stickyWrap = stickyWrapRef.current;
    const sticky = stickyRef.current;
    const stickyHeight = sHeight;
    const headerBtm = hHeight;
    const scrollTop = winScrollY;
    const stickyTop = window.pageYOffset + stickyWrapRef.current.getBoundingClientRect().top - headerBtm;

    if(sticky.classList.contains('sticky')){
      if(scrollTop < stickyTop) sticky.classList.remove('sticky');
    }else{
      if(scrollTop >= stickyTop) sticky.classList.add('sticky')
    }

    if (sticky.classList.contains('sticky')){
      stickyWrap.style.paddingTop = stickyHeight + 'px';
      sticky.style.top = headerBtm + 'px';
    } else {
      stickyWrap.style.paddingTop = '0';
      sticky.style.top = '0';
    }
  }, [hHeight, sHeight, winScrollY]);

  const scrollOptions={
    activeClass: 'active',
    smooth: true,
    spy: true,
    duration: 300,
  }

  return (
    <>
      {/* 배너 고정 X */}
      <Styled.StickyBanner href='#' onClick={(e)=>{e.preventDefault(); openLink(link);}}>
        <Styled.StickyInner>
          <span className='lb'>{dDay}</span>
          <span className='txt'>{text}</span>
        </Styled.StickyInner>
      </Styled.StickyBanner>

      {/* sticky 영역 */}
      <Styled.StickyWrap className='stickyWrap' ref={stickyWrapRef}>
        <Styled.StickyArea className='stickyArea' ref={stickyRef}>
          <Styled.StickyTab>
            <Styled.StickyInner>
              <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="Overview">
                Overview
              </Scroll.Link>
              {showTeachers
                ? <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="Teachers">
                  Teachers
                </Scroll.Link>
                :<></>
              }
              {showTopik
                ? <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="TOPIKClass">
                  {/* TOPIK */}Reading
                </Scroll.Link>
                : <></>
              }
              {showKorean
                ? <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="GeneralKorean">
                  {/* Korean */}Writing
                </Scroll.Link>
                : <></>
              }
              {showMyClass
                ? <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="MyClass">
                  My Class
                </Scroll.Link>
                : <></>
              }
              <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="Notification">
                Notice
              </Scroll.Link>
            </Styled.StickyInner>
          </Styled.StickyTab>
        </Styled.StickyArea>
      </Styled.StickyWrap>
    </>
  );
};

export default StickyArea;

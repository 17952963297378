import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ModalContext } from 'component/Modal/modalContext';
import { Trans, useTranslation } from 'react-i18next';


const Style = {
  Desc: styled.p`
    margin-top: 1em;
    font-size: 2em;
    font-weight: 700;
    &.subDesc {
      margin-bottom: 2em;
      color: #8b8f99;
      font-size: 1.6em;
      font-weight: 400;
    }
  `,
  ButtonContainer: styled.div`
    display: flex;
    > * {
      flex: 1;
    }
  `,
  Button: styled.button`
    display: inline-block;
    height: 6rem;
    border: 1px #8b8f99 solid;
    border-radius: 0.375em;
    font-size: 1.6em;
    font-weight: 500;
    letter-spacing: 0.1px;
    span {
      display: block;
    }
    &.primary {
      margin-left: 0.5em;
      border: 0;
      background-color: #475cc7;
      color: #fff;
    }
  `,
};

const FreeTrialIntroModal = () => {
  const { t } = useTranslation('learningMaterialDetailPage');
  const history = useHistory();

  const { handleModal } = React.useContext(ModalContext);

  const clickYes = () => {
    history.push('/plan')
    handleModal();
  };

  const clickCancel = (e) => {
    handleModal();
  };

  return (
    <>
      <Style.Desc>
        <Trans t={t}>freeTrialIntroModal.title</Trans>
      </Style.Desc>
      <Style.Desc className='subDesc' style={{whiteSpace: "pre-line"}}>
        <Trans t={t}>freeTrialIntroModal.contents</Trans>
      </Style.Desc>
      <Style.ButtonContainer>
        <Style.Button onClick={clickCancel}>
          <Trans t={t}>freeTrialIntroModal.cancel</Trans>
        </Style.Button>
        <Style.Button className='primary' onClick={clickYes}>
          <Trans t={t}>freeTrialIntroModal.freeTrial</Trans>
        </Style.Button>
      </Style.ButtonContainer>
    </>
  );
};

export default FreeTrialIntroModal;

import React from 'react';
import styled from 'styled-components';
import { FaAddressBook } from 'react-icons/fa';

const Style = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5em 0;
    padding: 1em 0;
    p {
      margin: 1em 0;
      font-size: 2em;
      font-weight: 500;
    }
  `,
};

const EmptyAddress = () => {
  return (
    <Style.Container>
      <FaAddressBook size='5em' color='#FFB500' />
      <p>저장되어 있는 주소가 없습니다</p>
    </Style.Container>
  );
};

export default EmptyAddress;

import styled from 'styled-components';
import testIcon from 'assets/images/ico_leveltest_test.png';
import startIcon from 'assets/images/ico_leveltest_start.png';
import submitIcon from 'assets/images/ico_leveltest_submit.png';
import endIcon from 'assets/images/ico_leveltest_end.png';
import testLargeIcon from 'assets/images/ico_leveltest_test_large.png';
import startLargeIcon from 'assets/images/ico_leveltest_start_large.png';
import submitLargeIcon from 'assets/images/ico_leveltest_submit_large.png';
import endLargeIcon from 'assets/images/ico_leveltest_end_large.png';

const IntroductionService = {
  Content: styled.div`
    width: 74em;
    padding: 4em;
    margin: 4.7em auto 6em;
    background-color: #27292d;
    border-radius: 6px;

    @media (max-width: 767px) {
      width: auto;
    }
  `,
  Introduction: styled.div`
    margin-top: 1em;
    padding-left: 1.5em;
    color: #c9cbd1;
    font-size: 1.6em;

    span {
      display: inline-block;
      margin: 0 0.222em;
      color: #ffb500;
      font-size: 1.125em;
      font-weight: 600;
    }

    &:first-child {
      margin-top: 0;
    }

    &.num {
      background: url(${testIcon}) no-repeat 0 0.3em;
    }
    &.start {
      background: url(${startIcon}) no-repeat 0 0.3em;
    }
    &.submit {
      background: url(${submitIcon}) no-repeat 0 0.3em;
    }
    &.end {
      background: url(${endIcon}) no-repeat 0 0.3em;
    }

    @media (max-width: 767px) {
      padding-left: 2em;

      &.num {
        background-image: url(${testLargeIcon});
        background-size: auto 1.2em;
      }
      &.start {
        background-image: url(${startLargeIcon});
        background-size: auto 1.2em;
      }
      &.submit {
        background-image: url(${submitLargeIcon});
        background-size: auto 1.2em;
      }
      &.end {
        background-image: url(${endLargeIcon});
        background-size: auto 1.2em;
      }
    }
  `,
};

export default IntroductionService;

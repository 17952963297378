import React, { useEffect, useRef, useState } from 'react';
import Styled from './index.style';
import bookImg from 'assets/images/event/230612/book.png';

import plansApi from 'api/plans';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import useLoginStatus from 'common/hook/useLoginStatus';
import { ModalContext } from 'component/Modal/modalContext';
import { useAxios } from 'common/hook/useAxiosHook';
import Login from 'component/user/Login';
import ConfirmModal from 'container/EventPage/ConfirmModal';
import { openLink, gotoAILink } from 'common/util/util';
import DefaultTextModal from 'component/Modal/DefaultTextModal';
import { actions } from 'component/user/state';

const EventPage = () => {
  const { t } = useTranslation('event/20230612TopikNote');
  const language = localStorage.getItem('i18nextLng');
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();

  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);
  const location = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user.data);
  const topikNotePdf =
    'https://dlvisang01.mastertopik.com/MasterTOPIK/2022_topiknote_speakingedition_mastertopik.pdf';

  const [
    { loading: getPlanLoading, data: getPlanData, error: getPlanError },
    fetchPlan,
  ] = useAxios(plansApi.getPlan, [], true);

  // 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
  const [isMobileByWidth, setisMobile] = useState(false);
  const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };
  useEffect(() => {
    if (!token) return;
    handleFetchPlan();
    dispatch(actions.fetchUser(token));
  }, [token]);
  const handleFetchPlan = () => fetchPlan(token);
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    }
    window.addEventListener('resize', resizingHandler);
    return () => {
      window.removeEventListener('resize', resizingHandler);
    };
  }, []);

  const goLogin = () => {
    if (!isLogin) {
      handleModal(<Login />, false, 420, location.pathname);
    }
  };

  useEffect(() => {
    // if (!getPlanData) return;
    // if (getPlanData.currentPlan !== 'FREE') {
    //   console.log('서비스 이용중인 회원', getPlanData.currentPlan);
    //   history.push({
    //     pathname: '/',
    //   });
    // }
  }, [getPlanData]);

  const getTopikNote = (e) => {
    if (!isLogin) {
      e.preventDefault();
      handleModal(
        <ConfirmModal
          tMessage={t('notLogin.title')}
          tButton={t('notLogin.confirm')}
          afterClose={() =>
            handleModal(<Login />, false, 420, location.pathname)
          }
        />
      );
      return;
    } else {
      if (!getPlanData) {
        console.log('no get getPlanData');
        return;
      }

      if (getPlanData.currentPlan !== 'FREE') {
        const url = topikNotePdf;
        e.preventDefault();
        openLink(url);
      } else {
        const item = getPlanData.plans.find((plan) => plan.productId === 1147);
        history.push({
          // pathname: `/plan-payment/${item.planCode.toLowerCase()}`,
          // search: `?productId=${item.productId}`,
          // state: item,
          pathname: `/learning-material/detail/758`,
        });
      }
    }
  };

  const alertAILink = (e) => {
    e.preventDefault();

    handleModal(
      <DefaultTextModal
        title={
          'Sorry for the inconvenience. Please use AI SPEAK on the desktop. (iOS 15.6~)'
        }
        btnType={'primary'}
      />
    );
  };
  const clickAILink = (e) => {
    if (!isLogin) {
      e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
      handleModal(<Login />, false, 420, location.pathname);
      return;
    } else {
      gotoAILink(e, user, alertAILink);
    }
  };

  //링크복사
  const doCopy = (text) => {
    // 흐름 1.
    if (!document.queryCommandSupported('copy')) {
      return toast.error('Unsupported browser.');
    }

    const API_HOST =
      process.env.REACT_APP_ENV === 'local'
        ? 'http://localhost:3000'
        : process.env.REACT_APP_API_HOST;

    // 흐름 2.
    const textarea = document.createElement('textarea');
    textarea.value = API_HOST + text;
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.position = 'fixed';

    // 흐름 3.
    document.body.appendChild(textarea);
    // focus() -> 사파리 브라우저 서포팅
    textarea.focus();
    // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
    textarea.select();
    // 흐름 4.
    document.execCommand('copy');
    // 흐름 5.
    document.body.removeChild(textarea);
    alert('Link copied.');
    //toast.success("Link copied.");
  };

  // Sticky
  const stickyWrapRef = useRef();
  const stickyRef = useRef();
  const [hHeight, sethHeight] = useState(0); //header bottom
  const [stickyHeight, setStickyHeight] = useState(0); //stickyRef height
  const [stickyOffset, setStickyOffset] = useState(0); //stickyWrapRef offsetTop
  const [winScrollY, setWinScrollY] = useState(window.scrollY);

  useEffect(() => {
    /* Mount 된 직후 초기값 셋팅 */
    onResize();

    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onResize);
    };
  }, []);

  const onResize = () => {
    const headerBtm = document.querySelector('header').getBoundingClientRect()
      .bottom; //header bottom
    sethHeight(headerBtm);
    setStickyHeight(stickyRef.current.clientHeight);
    setStickyOffset(stickyWrapRef.current.offsetTop);
    setWinScrollY(window.scrollY);
  };

  useEffect(() => {
    if (winScrollY > stickyOffset - hHeight) {
      stickyRef.current.classList.add('sticky');
      stickyWrapRef.current.style.paddingTop = stickyHeight + 'px';
      stickyRef.current.style.top = hHeight + 'px';
    } else {
      stickyRef.current.classList.remove('sticky');
      stickyWrapRef.current.style.paddingTop = '0';
      stickyRef.current.style.top = '0';
    }
  }, [hHeight, stickyHeight, stickyOffset, winScrollY]);

  return (
    <Styled.EventWrap>
      <Styled.StickyWrap ref={stickyWrapRef}>
        <Styled.Sticky ref={stickyRef} onClick={(e) => getTopikNote(e)}>
          <span>{t('sticky')}</span>
        </Styled.Sticky>
      </Styled.StickyWrap>

      <div className={language === 'ko-KR' ? 'ko' : ''}>
        <div className='visual'>
          <div className='inner'>
            <div className='blind'>
              <h2>{t('visual.title')}</h2>
            </div>
            <div className='btnWrap'>
              <button onClick={(e) => getTopikNote(e)}>
                <span>{t('visual.button')}</span>
              </button>
            </div>
          </div>
        </div>

        <div className='noteInfo'>
          <div className='inner'>
            <h2>
              <span className='blind'>{t('noteInfo.title')}</span>
            </h2>
            <div className='box'>
              <p>
                <img src={bookImg} alt='' />
              </p>
              <ul>
                <li>
                  <strong>{t('noteInfo.point')} 1</strong>
                  <Trans t={t}>noteInfo.desc1</Trans>
                </li>
                <li>
                  <strong>{t('noteInfo.point')} 2</strong>
                  <Trans t={t}>noteInfo.desc2</Trans>
                </li>
                <li>
                  <strong>{t('noteInfo.point')} 3</strong>
                  <Trans t={t}>noteInfo.desc3</Trans>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='banner'>
          <div className='btnWrap'>
            <button onClick={(e) => getTopikNote(e)}>
              <span>{t('visual.button')}</span>
            </button>
          </div>
        </div>

        <div className='notification'>
          <div className='inner'>
            <h3>{t('notification.title')}</h3>
            <ul>
              <li>
                <Trans t={t}>notification.desc</Trans>
              </li>
              <li>
                <Trans t={t}>notification.desc2</Trans>
              </li>
              <li>
                <Trans t={t}>notification.desc3</Trans>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Styled.EventWrap>
  );
};

export default EventPage;

import React from 'react';
import Styled from './styled/index.style';

export default function ToggleSwitch(props) {
  return (
    <Styled.Wrapper>
      <Styled.Input type='checkbox' {...props} />
      <Styled.Div></Styled.Div>
    </Styled.Wrapper>
  );
}

import React from 'react';
import Style from '../style/IndividualTable.style';

const IndividualTable = ({ data }) => {
  const { listeningUnitId, readingUnitId, writingUnitId } = data;

  const openResult = (unitId) => {
    window.open(`/mock-test/result/${data.productId}/${unitId}`, '', '_blank');
  };
  return (
    <Style.Container>
      <Style.Table>
        <colgroup>
          <col style={{ width: '17.74%' }} />
          <col style={{ width: '67.50%' }} />
          <col style={{ width: '14.76%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>
              영역 <br /> Section
            </th>
            <th colSpan='2'>
              나의 성적
              <br /> My Score
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              듣기 <br /> Listening
            </th>
            <td>
              <Style.Bar width={data.listeningScore}>
                <span className='bar listening' />{' '}
                <span>{data.listeningScore}점</span>
              </Style.Bar>

              <p>{data.listeningValuation}</p>
            </td>
            <td>
              <Style.ResultBtn onClick={() => openResult(listeningUnitId)}>
                결과보기
              </Style.ResultBtn>
            </td>
          </tr>
          {data.levelType === 12 ?
            <tr>
              <th>
                쓰기 <br /> Writing
              </th>
              <td>
                <Style.Bar width={data.writingScore}>
                  <span className='bar writing' />{' '}
                  <span>{data.writingScore}점</span>
                </Style.Bar>

                <p>{data.writingValuation}</p>
              </td>
              <td>
                <Style.ResultBtn onClick={() => openResult(writingUnitId)}>
                  결과보기
                </Style.ResultBtn>{' '}
              </td>
            </tr>
            : undefined}
          <tr>
            <th>
              읽기 <br /> Reading
            </th>
            <td>
              <Style.Bar width={data.readingScore}>
                <span className='bar reading' />{' '}
                <span>{data.readingScore}점</span>
              </Style.Bar>

              <p>{data.readingValuation}</p>
            </td>
            <td>
              <Style.ResultBtn onClick={() => openResult(readingUnitId)}>
                결과보기
              </Style.ResultBtn>{' '}
            </td>
          </tr>
        </tbody>
      </Style.Table>
      <Style.MobileTable>
        <colgroup>
          <col style={{ width: '24%' }} />
          <col style={{ width: '63%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>
              영역 <br /> Section
            </th>
            <th>
              나의 성적
              <br /> My Score
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              듣기 <br /> Listening
            </th>
            <td>
              <Style.Bar width={data.listeningScore}>
                <span className='bar listening' />{' '}
                <span>{data.listeningScore}점</span>
              </Style.Bar>
              <p>{data.listeningValuation}</p>{' '}
              <Style.ResultBtn onClick={() => openResult(listeningUnitId)}>
                결과보기
              </Style.ResultBtn>{' '}
            </td>
          </tr>
          <tr>
            <th>
              쓰기 <br /> Writing
            </th>
            <td>
              <Style.Bar width={data.writingScore}>
                <span className='bar writing' />{' '}
                <span>{data.writingScore}점</span>
              </Style.Bar>
              <p>{data.writingValuation}</p>{' '}
              <Style.ResultBtn onClick={() => openResult(writingUnitId)}>
                결과보기
              </Style.ResultBtn>{' '}
            </td>
          </tr>
          <tr>
            <th>
              읽기 <br /> Reading
            </th>
            <td>
              <Style.Bar width={data.readingScore}>
                <span className='bar reading' />{' '}
                <span>{data.readingScore}점</span>
              </Style.Bar>
              <p>{data.readingValuation}</p>
              <Style.ResultBtn onClick={() => openResult(readingUnitId)}>
                결과보기
              </Style.ResultBtn>{' '}
            </td>
          </tr>
        </tbody>
      </Style.MobileTable>
    </Style.Container>
  );
};

export default IndividualTable;

import styled from 'styled-components';
import StyledUtil from 'common/styled/Util.style';
import Img from 'component/atoms/Img';

const StyledThemeCategoryItem = {
  Wrapper: styled.div`
    position: relative;
    text-align: left;
    @media (max-width: 767px) {
      font-size: 1.4em;
    }
  `,
  Img: styled(Img)`
    position: relative;
    width: 100%;
    margin-bottom: 1em;
    border-radius: 0.6rem;
    opacity: 0.5;
  `,
  Title: styled.div`
    ${StyledUtil.textEllipsis}
    font-size: 2em;
    font-weight: 500;
  `,
  Desc: styled.div`
    ${StyledUtil.lineClamp}
    font-size: 1.6em;
    font-weight: 300;
    color: #8e9199;
  `,
};

export default StyledThemeCategoryItem;

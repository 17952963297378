import styled from 'styled-components';
import logo from 'assets/images/mockTestLogo.png';

const StyleResultPage = {
  HeaderContainer: styled.div`
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 2.8em 2.8em 2em;
    background: #eceef9;
    width: 100vw;
  `,
  HeaderContent: styled.div`
    position: relative;
    flex-grow: 1;
    width: calc(100% - 29.2em);
    p {
      position: relative;
      line-height: 1.231em;
      font-size: 2.6em;
      font-weight: 700;
    }
    > .logo {
      display: block;
      position: absolute;
      top: 0.5em;
      right: 0;
      width: 19em;
      height: 1.9em;
      background: url(${logo}) no-repeat center/contain;
      @media (max-width: 1024px) {
        position: static;
        margin-bottom: 2em;
      }
    }
  `,
  HeaderResults: styled.ul`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-top: 2em;

    & > li + li + li + li {
      border-top: 1px solid #d4d6dc;
    }

    li {
      display: flex;
      align-items: stretch;
      width: 33.33%;
      background-color: #fff;
      * > {
        align-items: center;
      }
      &:nth-of-type(3n) strong {
        width: 10.7em;
      }

      @media (max-width: 1024px) {
        width: 50%;
        &:nth-of-type(3) {
          display: none;
        }
      }

      strong {
        display: inline-block;
        flex: 0 0 auto;
        padding: 0.857em 2.143em;
        width: 9.2em;
        height: 100%;
        background-color: #f7f8fd;
        font-size: 1.4em;
        font-weight: 400;
        vertical-align: middle;
        @media (max-width: 1024px) {
          width: 10.7em;
        }
        @media (max-width: 767px) {
          width: 10.7em;
          padding: 0.444em;
          font-size: 1.2em;
        }
      }
      span {
        display: inline-block;
        flex: 1 1 auto;
        padding: 0.688em 1.875em 0.625em;
        height: 100%;
        font-size: 1.6em;
        font-weight: 500;
        vertical-align: middle;
        @media (max-width: 767px) {
          word-break: break-all;
          padding: 0.444em;
          font-size: 1.2em;
        }
      }
    }
  `,
};

export default StyleResultPage;

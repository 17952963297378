import styled from 'styled-components';
import H3 from 'component/atoms/H3';
import Button from './Button.style';

const Layout = {
  Container: styled.div`
    width: 100%;
    margin: 4em 0 18em;
    padding: 6em 2em 6.5em;
    border-radius: 6px;
    background-color: #1e2024;
    overflow: hidden;
  `,
  PageHeader: styled.header`
    text-align: center;
    white-space: pre-wrap;
  `,
  PageHeaderH3: styled(H3)`
    line-height: 1.3em;
    margin-bottom: 0;
    word-break: break-word;
  `,
  PageHeaderDesc: styled.p`
    color: #a2a2a2;
    font-size: 1.6em;
    font-weight: 300;
    margin-top: 1em;
    word-break: break-word;
  `,
  CheckLevel: styled.div`
    display: flex;
    margin-bottom: 8em;
    padding-top: 3.6em;
    align-items: stretch;
    justify-content: center;

    @media (max-width: 767px) {
      display: block;
    }
  `,
  CheckLevelBox: styled.div`
    width: 40em;
    padding: 5.3em 3em 4em;
    margin: 0 10px;
    background-color: #2f333a;
    border-radius: 6px;
    flex: 0 0 auto;

    header + div {
      margin-top: 4.7em;
    }

    @media (max-width: 767px) {
      width: auto;
      margin: 10px 10px 0 10px;

      &:first-child {
        margin-top:0;
      }

      header br {
        display: none;
      }
    }
  `,
  ButtonGroup: styled(Button.ButtonGroup)`
    margin-top: 4em;
  `,
  SelectedLevelTxt: styled.p`
    text-align: center;
    font-size: 1.6em;
    margin-bottom: 3.625em;

    span {
      display: inline-block;
      padding: 0.188em 0.745em;
      margin-right: 1em;
      background-color: #ffb500;
      border-radius: 50rem;
      font-weight: 600;
    }
  `,
};

export default Layout;

import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import VocaCard from '../../component/VocaCard';
import MTLive from '../../component/MTLive';

export default function ResourcesRouter() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path} component={VocaCard} />
      <Route path={`${path}/mt-live`} component={MTLive} />
    </Switch>
  );
}

import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Overall from 'container/MockTestReportPage/Overall';
import Individual from 'container/MockTestReportPage/Individual';

export default function TestRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Overall} />
      <Route exact path={`${path}/individual`} component={Individual} />
    </Switch>
  );
}

import React from 'react';
import Style from 'container/MockTestPage/styled/TestWritePaper.style';

const PreviewImage = ({ data, deletePreview }) => {
  return (
    <Style.Preview>
      <p className='name'>
        답안지
        <button type='button' onClick={deletePreview}>
          닫기
        </button>
      </p>
      <p>
        <img src={data} alt='preview' />
      </p>
    </Style.Preview>
  );
};

export default PreviewImage;

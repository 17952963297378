import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logoImg from 'assets/images/logo01.png';
import Img from 'component/atoms/Img';

const StyledHeader = {
  Wrapper: styled.header`
  position: relative;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    /* height: 9em; */
    background-color: transparent;
    transition: background-color 0.2s ease;
    background-color: rgba(0, 0, 0, 0.4);
    @media (max-width: 1024px) {
      .header-event-wrap{display: none;}
      &.login-head{
        .header-event-wrap{display: block;}
      }
    }
    .logout-depth1{
        .depth2{
          display: none;
          @media (max-width: 1024px) {
            &.active{
              display: block;
            }

          }

        }

      }

    @media (min-width: 1024px) {

      &.hover{
        .header-event-wrap,
        .header-lang-wrap{
          svg{
            path {fill:#222}
          }
        }
        .login-btn{
            border:1px solid #b8b8b8;
            color:#28211f
          }

        &:after{
          content:"";
          height:13.2rem;
          background:#fff;
          position: absolute;
          left:0;
          top:100%;
          width:100%;
          border-top:1px solid #e8e8e8
        }
        background: #fff;;

        .nav_a{color: #222222;
          /* svg{
            path {
              fill:#222;
            }
          } */
          svg {
              transform: rotate(90deg);
              path{fill:#485cc7; transition: all 0.2s ease-in;}
            }
          &:hover{
            color:#485cc7;

          }
        }
        .depth1{
          position: relative;

          .depth2{
            display:flex;
            flex-direction: column;
            gap: 2rem;
            flex-wrap: wrap;
            height:13rem;
              position:absolute;
              left:0;
              top:100%;
              width:100%;
              z-index:5;
              padding-top:2.3rem;
              margin-right:2rem;
              a {
                font-size: 16px;
                font-weight:400;
                margin: 0.65rem 0;
                padding:0;
                line-height: 0;
                opacity: 1;
                transition: all 0.4s ease, opacity 0.3s ease 0.2s, font-size 0s ease;
                letter-spacing: -0.5px;
                color: #000;
                &:hover{
                  color:#485cc7;
                }
              }
              &.active {
                a{
                  padding: 0;
                  line-height: 0
                }
              }



          }

          &.depth1-1{
            .depth2{
              width:10rem
            }
          }
          &.depth1-2{}
          &.depth1-3{}
          &.depth1-4{
            .depth2{
              width:10rem
            }
          }
          &.depth1-5{
            .depth2{
              width:21rem
            }
          }
        }

      }

      &.hover2{

        .login-depth1{
          &:hover{
             .depth2{
              display: flex;
            }
          }
        }
      }

      .login-depth1{
          .free{display: none;}
          &:hover{
            > span > .nav_a{
            color:#485cc7;
            font-weight: 400;
              svg {
                transform: rotate(90deg);
                path{fill:#485cc7; transition: all 0.2s ease-in;}
              }
            }
          }
          .depth2{
            position:absolute;
            left:0;
            top:100%;
            z-index:5;
            width: 100%;
            margin-left: 6.518em;
            display: none;
            height:5rem;
            a{
              position:relative;
              z-index: 5;
              &:hover{
                color: #ffb500;
                border-bottom:3px solid #ffb500;;
              }
            }
            &:before{
              content:"";
              height:5rem;
              background-color: rgba(0, 0, 0, 0.4);
              position: fixed;
              left:0;
              top:9rem;
              width:100%;
            }


          }
        }

    }
    @media (max-width: 1024px) {
      &.login-head{
        .nav-user-log,
        .nav-free{
          display: none;
        }
        .select-box{display: none;}
      }
      .login-depth1{

          .depth2{

            display: none;
            &.active{
              display: block;;
            }

          }
        }
    }

    &.scrolled {
      /* background-color: rgba(0, 0, 0, 0.4); */
      /* .line-banner {
        height: 0;
        opacity: 0;
      } */
      .nav-with-line-banner {
        /* margin-top: 0; */
      }
    }
    @media (max-width: 1024px) {
      padding-right: 6em;
    }
  `,
};

export default StyledHeader;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languageCode } from 'common/constant';
import { termsOfPrivacyKo } from 'container/TermsPage/contents/2022/termsOfPrivacyKo';
import { termsOfPrivacyEn } from 'container/TermsPage/contents/2022/termsOfPrivacyEn';
import { termsOfPrivacyZh } from 'container/TermsPage/contents/2022/termsOfPrivacyZh';
import { termsOfPrivacyJa } from 'container/TermsPage/contents/2022/termsOfPrivacyJa';

const Privacy20221230 = () => {
  const { i18n } = useTranslation('privacyPage');
  const { KOREAN, CHINESE, JAPANESE } = languageCode;
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!i18n?.language) return;

    switch (i18n.language) {
      case KOREAN.locale:
        setContent(termsOfPrivacyKo);
        break;
      case CHINESE.locale:
        setContent(termsOfPrivacyZh);
        break;
      case JAPANESE.locale:
        setContent(termsOfPrivacyJa);
        break;
      default:
        setContent(termsOfPrivacyEn);
        break;
    }
  }, [i18n.language]);

  return <>{content}</>;
};

export default Privacy20221230;

import React, { useState, useRef, useEffect } from "react";
import { FaArrowRight } from "react-icons/fa";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { masterTopikUrl } from "common/constant";
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Style from "./styled/setCard.style";
import Login from "component/user/Login";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const SetCard = ({ item }) => {
  const { t } = useTranslation("setCard");
  const history = useHistory();
  const login = useSelector((state) => state.user.token);
  const userData = useSelector((state) => state.user.data);
  let { handleModal } = React.useContext(ModalContext);
  const isLogin = useLoginStatus();
  const cardRef = useRef(null);
  const [openCard, setOpenCard] = useState(false);
  const {
    productId,
    testTitle,
    status,
    thumbnailPath,
    testDate,
    mockTestResultResponses: subject,
  } = item;

  const location = useLocation();

  // Card
  const statusText = (status) => {
    let text = "";

    switch (status) {
      case "TAKE A TEST":
        text = t("button.test");
        break;
      case "CONTINUE":
        text = t("button.continue");
        break;
      case "VIEW RESULTS":
        text = t("button.result");
        break;
      case "IN-PROGRESS":
        text = t("button.progress");
        break;
      case "GRADED":
        text = "Correction Registered";
        break;
      default:
        break;
    }

    return text;
  };

  // 문제 리스트
  const statusText2 = (status) => {
    let text = "";

    switch (status) {
      case "TAKE A TEST":
        text = t("button.test");
        break;
      case "CONTINUE":
        text = t("button.continue");
        break;
      case "VIEW RESULTS":
        text = t("button.result2");
        break;
      case "IN-PROGRESS":
        text = t("button.progress2");
        break;
      case "GRADED":
        text = "Correction Registered";
        break;
      default:
        break;
    }

    return text;
  };

  const clickLink = (data) => {
    if (!data) return;

    /*20200110 모의고사 무료체험 이벤트 예외처리*/
    if (data.productId === "1196") {
      if (data.status === "TAKE A TEST" || data.status === "CONTINUE") {
        window.open(
          `/mock-test/${data.productId}/${data.unitId}`,
          "",
          "_blank"
        );
      } else {
        window.open(
          `/mock-test/result/${data.productId}/${data.unitId}`,
          "",
          "_blank"
        );
      }
    } else {
      if (userData.serviceLevel === "PREMIUM") {
        if (data.status === "TAKE A TEST" || data.status === "CONTINUE") {
          window.open(
            `/mock-test/${data.productId}/${data.unitId}`,
            "",
            "_blank"
          );
        } else {
          window.open(
            `/mock-test/result/${data.productId}/${data.unitId}`,
            "",
            "_blank"
          );
        }
      } else {
        if (data.status === "VIEW RESULTS" || data.status === "IN-PROGRESS") {
          window.open(
            `/mock-test/result/${data.productId}/${data.unitId}`,
            "",
            "_blank"
          );
        } else {
          history.push("/plan");
          toast.error("Change your Plan to PREMIUM");
        }
      }
    }
  };

  const clickFlipCard = (status) => {
    if (!isLogin) {
      handleModal(<Login />, false, 420, location.pathname);
      return;
    }

    if (status === "VIEW RESULTS") {
        // 개별 리포트로 이동
        history.push('/test-report/individual')
    } else {
      setOpenCard(true);
    }
  };

  // 테스트는 DEPTH 단계를 줄임
  useEffect(() => {
    if (status === "TAKE A TEST" || status === "CONTINUE")
      setOpenCard(true);
  }, []);

  return (
    <Style.SetCardContainer
      bg={`'${masterTopikUrl + thumbnailPath}'`}
      className={openCard ? "showBackCard" : ""}
      id={`mockTestId-${productId}`}
      ref={cardRef}
    >
      <Style.SetCardFront onClick={() => clickFlipCard(status)}>
        <Style.Heading>
          <h3>{testTitle}</h3>
          <Style.Link>
            <span>{statusText(status)}</span>
            <FaArrowRight size="1em" />
          </Style.Link>
        </Style.Heading>
        {testDate ? (
          <Style.Date>
            <AiOutlineCheckCircle size="1.5em" />
            <Style.RegDt format="MMMM, DD, YYYY">{testDate}</Style.RegDt>
          </Style.Date>
        ) : (
          ""
        )}
      </Style.SetCardFront>
      {login && (
        <Style.SetCardBack>
          <Style.Heading>
            <h3>{testTitle}</h3>
          </Style.Heading>

          {subject.map((data, key) => {
            return (
              <Style.SubjectList key={key}>
                <span>
                  {data.subjectTitle}: {data.size} questions.
                </span>
                <Style.SubjectLink
                  to={data.url ? data.url : "#none"}
                  onClick={() => clickLink(data)}
                  className={data.status === "VIEW RESULTS" ? "result" : ""}
                >
                  {statusText2(data.status)}
                </Style.SubjectLink>
              </Style.SubjectList>
            );
          })}

          <Style.CloseBtn onClick={() => setOpenCard(false)}>
            &times;
          </Style.CloseBtn>
        </Style.SetCardBack>
      )}
    </Style.SetCardContainer>
  );
};

export default SetCard;

import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';

const StyledUserHomePage = {
  Wrapper: styled(ContentsWrapper)`
    padding-bottom: 16em;
    @media (max-width: 767px) {
      padding-bottom: 12em;
    }
  `,
  H2: styled.h2`
    font-size: 3.6em;
    margin: 2.5em 0 1em;
    font-weight: bold;
  `,
};

export default StyledUserHomePage;

import styled from 'styled-components';
import Badge from 'component/user/Badge';

export const StyledLibrarySearch = {
  Badge: styled(Badge)`
    margin: 0.3em;
    padding: 0.3em 1.2em !important;
    text-align: center;
    border: 1px #838587 solid;
    color: #cacbd0;
    min-width: 6.5em;
    background: transparent;

    &:after {
      width: 0px !important;
    }

    &.active {
      background-color: #485cc7;
      border-color: #485cc7;
      color: #fff;
    }
  `,
};

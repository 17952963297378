import React from 'react';
// import { useSelector } from 'react-redux';
import Style from './style/Print.style';
import logo from 'assets/images/ico_printLogo.png';
import stamp from 'assets/images/ico_printStamp.png';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';

export function PrintReport2({ data, userName }) {

  return (
    <Document>
      <Page style={Style.page}>
        <View style={Style.LogoContainer}>
          <Image source={logo} style={Style.Logo} />
        </View>
        <View>
          <Text style={Style.h1}>마스터토픽 모의고사 성적증명서</Text>
          <Text style={Style.h2}>TOPIK SCORE REPORT</Text>
        </View>
        <Text style={Style.h3}>{userName}님의 TOPIK 모의고사 성적표</Text>

        <View style={Style.reportCard}>
          <View style={Style.infoContainer}>
            <View style={Style.infoSpan}>
              <View style={Style.infoTitle}>
                <Text>응시자</Text>
              </View>
              <View style={Style.infoDesc}>
                <Text>{userName}</Text>
              </View>
            </View>
            <View style={Style.infoSpan}>
              <View style={Style.infoTitle}>
                <Text>응시정보</Text>
              </View>
              <View style={Style.infoDesc}>
                <Text>{data.title}</Text>
              </View>
            </View>
            <View style={Style.infoSpan}>
              <View style={Style.infoTitle}>
                <Text>응시일</Text>
              </View>
              <View style={Style.infoDesc}>
                <Text>{data.testDate?.slice(0, 10)}</Text>
              </View>
            </View>
          </View>
          <View style={Style.gradeContainer}>
            <View style={Style.gradeSpan}>
              <View style={Style.gradeTitle}>
                <Text>등급</Text>
              </View>
              <View style={Style.gradeDesc}>
                <Text>{data.totalGrade}</Text>
              </View>
            </View>
            <View style={Style.gradeSlash}>
              <Text>/</Text>
            </View>
            <View style={Style.gradeSpan}>
              <View style={Style.gradeTitle}>
                <Text>총 점수</Text>
              </View>
              <View style={Style.gradeDesc}>
                <Text>
                  {data.totalScore}
                  &nbsp;
                  <span style={{ fontWeight: 400, fontSize: 10 }}>
                    /300 점
                  </span>
                </Text>
              </View>
            </View>
          </View>
        </View>

        <Text style={Style.h3}>시험 결과 (Test Result)</Text>
        <View style={Style.tblTestResult}>
          <View style={Style.tblTestResultThead}>
            <View style={Style.tblTestResultTh1}>
              <Text>영역</Text>
              <Text>Section</Text>
            </View>
            <View style={Style.tblTestResultTh2}>
              <Text>나의 성적</Text>
              <Text>My score</Text>
            </View>
          </View>
          <View style={Style.tblTestResultTbody}>
            <View style={Style.tblTestResultTd1}>
              <Text>듣기</Text>
              <Text>Listening</Text>
            </View>
            <View style={Style.tblTestResultTd2}>
              <View style={Style.BarContainer}>
                <View style={Style.Bar}>
                  <Text
                    style={[
                      Style.BarInner,
                      {
                        backgroundColor: '#80AA00',
                        width: `${data.listeningScore}%`
                      },
                    ]}
                  />
                </View>
                <Text style={{ fontWeight: 700 }}>{data.listeningScore}</Text>
              </View>
              <Text>
                {data.listeningValuation}
              </Text>
            </View>
          </View>
          <View style={Style.tblTestResultTbody}>
            <View style={Style.tblTestResultTd1}>
              <Text>쓰기</Text>
              <Text>Writing</Text>
            </View>
            <View style={Style.tblTestResultTd2}>
              <View style={Style.BarContainer}>
                <View style={Style.Bar}>
                  <Text
                    style={[
                      Style.BarInner,
                      {
                        backgroundColor: '#8B56E3',
                        width: `${data.writingScore}%`
                      },
                    ]}
                  />
                </View>
                <Text style={{ fontWeight: 700 }}>{data.writingScore}</Text>
              </View>
              <Text>
                {data.writingValuation}
              </Text>
            </View>
          </View>
          <View style={Style.tblTestResultTbody}>
            <View style={Style.tblTestResultTd1}>
              <Text>읽기</Text>
              <Text>Reading</Text>
            </View>
            <View style={Style.tblTestResultTd2}>
              <View style={Style.BarContainer}>
                <View style={Style.Bar}>
                  <Text
                    style={[
                      Style.BarInner,
                      {
                        backgroundColor: '#00ACB2',
                        width: `${data.readingScore}%`
                      },
                    ]}
                  />
                </View>
                <Text style={{ fontWeight: 700 }}>{data.readingScore}</Text>
              </View>
              <Text>
                {data.readingValuation}
              </Text>
            </View>
          </View>
        </View>
        <View style={Style.stampContainer}>
          <Image source={stamp} style={Style.stamp} />
        </View>
        <Text style={{ textAlign: 'center', fontSize: 10, fontWeight: 400 }}>
          본 성적표는 masterTOPIK에서 진행한 모의고사에 대한 성적표이며, 실제
          TOPIK시험의 효력과는 무관합니다.
        </Text>
      </Page>
    </Document>
  );
}

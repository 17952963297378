import React, { useState } from 'react';
import Style from './styled/index.style';
const RadioButton = ({ children, handleClick }) => {
  const [check, setCheck] = useState(false);
  const btnOnchange = () => {
    setCheck((prev) => !prev);
    if (handleClick) {
      handleClick('terms', !check);
    }
  };
  return (
    <Style.Container htmlFor='radio'>
      {children}
      <input
        id='radio'
        type='checkbox'
        checked={check}
        name='radio'
        onChange={() => {
          btnOnchange();
        }}
      />
      <span className='circle'></span>
    </Style.Container>
  );
};

export default RadioButton;

import React from 'react';
import Style from './index.style';
const Pencil = () => {
  return (
    <Style.Container>
      <Style.Pencil>
        <Style.Eraser />
        <Style.Ferrule />
        <Style.Body />
        <Style.Wood />
        <Style.Lead />
      </Style.Pencil>
      <Style.Line />
    </Style.Container>
  );
};

export default Pencil;

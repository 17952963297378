import React from 'react';
import { useTranslation } from 'react-i18next';
import Style from '../styled/ContactUs.style';

const ContactUs = () => {
  const { t } = useTranslation('helpPage');

  return (
    <Style.Container>
      <Style.Desc>{t('contact.desc')}</Style.Desc>
      <Style.Link href='https://mastertopik.channel.io/' target='_blank'>{t('contact.button')}</Style.Link>
    </Style.Container>
  );
};
export default ContactUs;


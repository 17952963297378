import styled from 'styled-components';
import { Link } from 'react-router-dom';

const List = {
  List: styled.div`
    margin-top: 10em;
    @media (max-width: 767px) {
      font-size: 1.2em;
    }
  `,
  Item: styled.div`
    display: flex;
    background-color: #1e2024;
    border-radius: 0.6em;
    padding: 2em 1.5em;
    margin-top: 1em;
    align-items: center;

    &:first-child {
      margin-top: 0;
    }

    > * {
      flex: 0 0 auto;
    }
  `,
  Icon: styled.div`
    width: 5.5em;
    text-align: center;

    @media (max-width: 767px) {
      width: 3em;
    }
  `,
  Category: styled.div`
    font-size: 1.6em;
    font-weight: 500;
    width: 5em;
    text-align: center;
  `,
  Title: styled.div`
    flex: 1 1 auto;
    color: #c9cbd1;
    font-size: 1.6em;
    line-height: 1.750em;
    overflow: hidden;
  `,
  TitleLink: styled(Link)`
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #c9cbd1;

    &:hover {
      text-decoration: underline;
      color: #ffffff;
    }
  `,
  Date: styled.div`
    width: 7.5em;
    color: #8b8f99;
    font-size: 1.6em;
    text-align: center;
    @media (max-width: 767px) {
      display: none;
    }
  `,
}

export default List;

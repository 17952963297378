import React from 'react';
import Styled from './index.style';
import MainContentsStyled from '../index.style';

import analysisSystemImg01 from 'assets/images/main_analysis_system01.png';
import analysisSystemImg02 from 'assets/images/main_analysis_system02.png';

export default function Analysis({ t }) {
  return (
    <Styled.Wrapper>
      <MainContentsStyled.H2>{t('analysis.title')}</MainContentsStyled.H2>
      <Styled.StepList>
        <Styled.StepItem>
          <Styled.StepDesc>{t('analysis.desc1')}</Styled.StepDesc>
          <Styled.StepImg src={analysisSystemImg01} alt='Analysis System' />
          <Styled.StepArrow />
        </Styled.StepItem>
        <Styled.StepItem>
          <Styled.StepDesc>{t('analysis.desc2')}</Styled.StepDesc>
          <Styled.StepImg src={analysisSystemImg02} alt='Analysis System' />
        </Styled.StepItem>
      </Styled.StepList>
    </Styled.Wrapper>
  );
}

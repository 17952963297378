import React, { useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import Styled from "./index.style";
import { priceFormat } from "common/util/util";
import ButtonLink, { ButtonTheme } from "component/atoms/ButtonLink";
import { RiCheckFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllItem } from "container/PaymentPage/state/action";
import { useAxios } from "common/hook/useAxiosHook";
import userApi from "api/user";
import videoClassApi from 'api/videoclass';
import VideoClassApplication from './VideoClassApplication';

const OrderCompleteForVideoClass = ({ orderData }) => {
  const { t } = useTranslation("paymentSuccessPage");
  const dispatch = useDispatch();
  const storeApplication = useSelector((state) => state.videoClassStore.videoClassApplication);

  const [order, setOrder] = useState(null);
  const [orderItemList, setOrderItemList] = useState(null);
  const hasApplication = useMemo(()=>storeApplication && storeApplication.email && storeApplication.schedule && storeApplication.learn, [storeApplication]);

	const [
		{ loading: sendEmailLoading, data: sendEmailData },
		fetchSendEmail,
	] = useAxios(userApi.sendVideoClassEmail, [], true);
  
	const [
		{ loading: sendFormLoading, data: sendFormData },
		fetchSendForm,
	] = useAxios(videoClassApi.sendForm, [], true);

  useEffect(() => {
    setOrder(orderData);
    setOrderItemList(orderData.items);
  }, [orderData]);

  const priceFormatting = (price) =>
    order.isDollar ? priceFormat.usa(price) : priceFormat.korea(price);

  useEffect(() => {
    dispatch(deleteAllItem());
  }, []);
  useEffect(() => {
    if(orderData){
      //화상수업 결제완료 시 이메일 발송
      fetchSendEmail(orderData.userEmail, orderData.orderId);

      //화상수업 결제완료 시 신청서 폼 저장
      if(orderData.items.findIndex((item)=>item.productIdx === storeApplication.productIdx) > -1 
      && orderData.items[0] && orderData.items[0].productIdx){
        fetchSendForm(
          orderData.userIdx,
          orderData.items[0].productIdx,
          orderData.orderId,
          storeApplication.email, 
          storeApplication.schedule, 
          storeApplication.learn
        );
      }
    }
  }, [fetchSendEmail, orderData]);

  useEffect(()=>{
    console.log('sendFormData:: ', sendFormData);
  }, [sendFormData]);

  return (
    <>
      {order && (
      <>
        <Styled.Container className={hasApplication ? 'next' : ''}>
          <Styled.Left>
            <Styled.H3>{t("subtitle")}</Styled.H3>

            <Styled.Icon>
              <RiCheckFill />
            </Styled.Icon>

            <Styled.Bold>
              <Moment format="YYYY/MM/DD HH:mm">{order.orderedAt}</Moment>
            </Styled.Bold>

            <Styled.Blue>{t("label3")}</Styled.Blue>
            {orderItemList.map((item) => (
              <p key={'orderItemList p'+item}>
                {item.title}{" "}
                <span style={{ marginLeft: 10 }}>
                  {priceFormatting(item.unitPrice * item.qty)}
                </span>
              </p>
            ))}
          </Styled.Left>

          <Styled.Right>
            <Styled.Blue style={{ marginBottom: 5 }}>{t("label1")}</Styled.Blue>
            <p>
              <b>
                {order.paymentMethod}{" "}
                {order.statusType !== 1 && " (결제 대기 중)"}
              </b>
            </p>

            <Styled.Blue>{t("label2")}</Styled.Blue>
            <Styled.Table>
              <thead>
                <tr>
                  <th>{t("label3")}</th>
                  <th style={{ textAlign: "center" }}>{t("label4")}</th>
                  <th>{t("label-item")}</th>
                </tr>
              </thead>
              <tbody>
                {orderItemList.map((item) => (
                  <tr key={'orderItemList tr'+item}>
                    <td>{item.title}</td>
                    <td style={{ textAlign: "center" }}>{item.qty}</td>
                    <td>{priceFormatting(item.unitPrice)}</td>
                  </tr>
                ))}
              </tbody>
            </Styled.Table>

            <Styled.Hr />

            <Styled.Table>
              <tbody>
                <tr>
                  <th>{t("label-item")}</th>
                  <td>
                    {priceFormatting(
                      orderItemList.reduce((acc, item) => {
                        return item.unitPrice
                          ? acc + item.unitPrice * item.qty
                          : acc + 0;
                      }, 0)
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{t("label-discount")}</th>
                  <td>
                    {priceFormatting(
                      orderItemList.reduce((acc, item) => {
                        return item.discountPrice
                          ? acc + item.discountPrice
                          : acc + 0;
                      }, 0)
                    )}
                  </td>
                </tr>
              </tbody>
            </Styled.Table>

            <Styled.Hr />

            <Styled.Table>
              <tfoot>
                <tr>
                  <td>{t("label-total")}</td>
                  <td>
                    {priceFormatting(
                      orderItemList.reduce((acc, item) => {
                        if (item.unitPrice && item.discountPrice) {
                          return item.unitPrice
                            ? acc +
                                item.unitPrice * item.qty -
                                item.discountPrice
                            : acc + 0;
                        } else {
                          return item.unitPrice
                            ? acc + item.unitPrice * item.qty
                            : acc + 0;
                        }
                      }, 0)
                    )}
                  </td>
                </tr>
              </tfoot>
            </Styled.Table>
          </Styled.Right>

        </Styled.Container>
        {hasApplication && order.items && order.items.findIndex((item)=>item.productIdx === storeApplication.productIdx) > -1
          ? <VideoClassApplication 
              title={orderItemList[order.items.findIndex((item)=>item.productIdx === storeApplication.productIdx)].title}
              date={order.orderedAt}
              email={storeApplication.email}
              schedule={storeApplication.schedule}
              lesson={storeApplication.learn}
            />
          : <></>
        }
      </>
      )}

      <Styled.ButtonContainer>
        <ThemeProvider theme={ButtonTheme.primary.filled}>
          <ButtonLink to="/">MY PAGE</ButtonLink>
        </ThemeProvider>
        <ButtonLink to="/setting">SETTINGS</ButtonLink>
      </Styled.ButtonContainer>
    </>
  );
};

export default OrderCompleteForVideoClass;

import React, { useState, useEffect } from 'react';
import Style from './styled/NotificationSection.style';
import ToggleSwitch from 'component/ui/ToggleSwitch';
import userApi from 'api/user';
import { useAxios } from 'common/hook/useAxiosHook';
import { actions } from 'component/user/state';
import { useSelector, useDispatch } from 'react-redux';
import { getUA } from 'react-device-detect';

import { Trans, useTranslation } from 'react-i18next';
import { MOBILE_APP_USER_AGENT } from 'common/constant';

const NotificationSection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('settingPage');
  const token = useSelector((state) => state.user.token);
  const userInfo = useSelector((state) => state.user.data);
  const [, updateEmailNotification] = useAxios(
    userApi.updateEmailNotification,
    [],
    true
  );
  const [checked, setChecked] = useState({
    emailAcceptance: userInfo.isReceiveEmail, //이메일 전송 여부
    smsAcceptance2: userInfo.isReceiveSMS2,   //핸드폰 sms 전송 여부
    smsAcceptance: userInfo.isReceiveSMS,     //모바일 푸쉬 여부
  });

  useEffect(() => {
    setChecked({
      emailAcceptance: userInfo.isReceiveEmail,
      smsAcceptance2: userInfo.isReceiveSMS2,
      smsAcceptance: userInfo.isReceiveSMS,
    });
  }, [userInfo]);

  useEffect(() => {
    dispatch(actions.fetchUser(token));
  }, []);

  const onChangeNotification = (e, type) => {
    let data;
    if (type === 'email') {
      data = { ...checked, emailAcceptance: e.target.checked };
      setChecked(data);
      updateEmailNotification({ access_token: token, data });
    } else if (type === 'sms') {
      data = { ...checked, smsAcceptance: e.target.checked };
      setChecked(data);
      updateEmailNotification({ access_token: token, data });
    } else if (type === 'phoneNumberSMS') {
      data = { ...checked, smsAcceptance2: e.target.checked };
      setChecked(data);
      updateEmailNotification({ access_token: token, data });
    }

    updateEmailNotification({ access_token: token, data });
  };
  return (
    <Style.Container>
      <Style.Title>
        <Trans t={t}>notification.title</Trans>
      </Style.Title>
      <Style.Notification>
        <Trans t={t}>notification.elem1</Trans>{' '}
        <Style.ToggleSwitchContainer>
          <ToggleSwitch
            id='email'
            onChange={(e) => onChangeNotification(e, 'email')}
            checked={checked.emailAcceptance}
          />
        </Style.ToggleSwitchContainer>
      </Style.Notification>
      <Style.Notification>
        <Trans t={t}>notification.elem3</Trans>{' '}
        <Style.ToggleSwitchContainer>
          <ToggleSwitch
            id='phoneNumberSMS'
            onChange={(e) => onChangeNotification(e, 'phoneNumberSMS')}
            checked={checked.smsAcceptance2}
          />
        </Style.ToggleSwitchContainer>
      </Style.Notification>
      {getUA === MOBILE_APP_USER_AGENT && (
        <Style.Notification>
          <Trans t={t}>notification.elem2</Trans>{' '}
          <Style.ToggleSwitchContainer>
            <ToggleSwitch
              id='sms'
              onChange={(e) => onChangeNotification(e, 'sms')}
              checked={checked.smsAcceptance}
            />
          </Style.ToggleSwitchContainer>
        </Style.Notification>
      )}
    </Style.Container>
  );
};

export default NotificationSection;

import React, { useState, useEffect } from "react";
import Style from "./styled/Process.style";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAxios } from "common/hook/useAxiosHook";
import couponApi from "api/coupon";
import { priceFormat } from "common/util/util";
import { addCoupon as addCouponBook } from "container/BookStorePage/state/action";
import { addCoupon as addCouponVideo } from "container/PaymentPage/state/action";

const animatedComponents = makeAnimated();

const Coupon = ({ currency, productsItems, t }) => {
  const dispatch = useDispatch();

  const priceCurrency = (price) =>
    currency === "KRW" ? priceFormat.korea(price) : priceFormat.usa(price);

  const [couponData, setCouponData] = useState({
    productId: -1,
    couponNumber: "",
  });

  const token = useSelector((state) => state.user.token);
  const { type: itemType } = useParams();
  const products = useSelector((state) => {
    if(itemType === 'book') return state.bookStore.bookItems;
    else if(itemType === 'video-class') return state.videoClassStore.videoItems;
    else return [];
  });
  const couponPrice = useSelector((state) => {
    if(itemType === 'book') return state.bookStore?.coupon;
    else if(itemType === 'video-class') return state.videoClassStore?.coupon;
    else return {};
  });
  //api 교체 예정 ?
  const [{ data }, refreshCouponData] = useAxios(
    couponApi.getBookCouponData,
    [],
    true
  );
  const itemSelectionOptions = products.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });
  const couponApply = () => {
    if (!couponData.productId || !couponData.couponNumber) return;
    refreshCouponData({ token, currency, ...couponData });
  };

  const onChange = (e, key) => {
    const { value } = e.target || e;
    setCouponData((prev) => ({ ...prev, ...{ [key]: value } }));
  };

  useEffect(() => {
    couponApply();
  }, [currency, products]);

  useEffect(() => {
    //성공하면
    if (!data) {
      // 쿠폰 적용 초기화
      if(itemType === 'book') dispatch(addCouponBook({ discount: 0 }));
      else if(itemType === 'video-class') dispatch(addCouponVideo({ discount: 0 }));
    }
    if (!data || !data.available) {
      return;
    }
    if (data.available) {
      const index = productsItems.findIndex(
        (item) => item.productIdx === couponData.productId
      );
      if (index !== -1) {
        productsItems[index]["couponCode"] = couponData.couponNumber;
        if(itemType === 'book') dispatch(addCouponBook({ discount: data.amountOff }));
        else if(itemType === 'video-class') dispatch(addCouponVideo({ discount: data.amountOff }));
      }
    } else {
      if(itemType === 'book') dispatch(addCouponBook({ discount: 0 }));
      else if(itemType === 'video-class') dispatch(addCouponVideo({ discount: 0 }));
    }

    return () => {
      // 장바구니 페이지에서 벗어날때 쿠폰 적용 초기화
      if(itemType === 'book') dispatch(addCouponBook({ discount: 0 }));
      else if(itemType === 'video-class') dispatch(addCouponVideo({ discount: 0 }));
    };
  }, [data]);

  return (
    <Style.InputContainer className="coupon">
      <label htmlFor="coupon">{t("paymentInfo.orderForm.coupon")}</label>
      <Style.Select
        onChange={(e) => onChange(e, "productId")}
        options={itemSelectionOptions}
        components={animatedComponents}
        inputId="product"
        placeholder={t("paymentInfo.orderForm.coupon")}
        required
      />
      <Style.Input
        id="coupon"
        type="text"
        name="coupon"
        onChange={(e) => onChange(e, "couponNumber")}
      />
      <button onClick={couponApply}>
        {t("paymentInfo.orderForm.couponBtn")}
      </button>
      {data &&
        (!data.available ? (
          <span className="error">{data.unavailableReason}</span>
        ) : (
          <span>{`${data.code} ${priceCurrency(
            data.amountOff
          )} discounts applied!`}</span>
        ))}
    </Style.InputContainer>
  );
};

export default Coupon;

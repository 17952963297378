import styled from 'styled-components';

const StyledCount = {
  Container: styled.div`
    padding: 0.5em 0;
    width: 5.6em;
    margin: auto;
    border-radius: 0.3em;
    background-color: #2c2e32;
    svg:first-of-type {
      margin-right: 0.7em;
      vertical-align: -0.1em;
      cursor: pointer;
    }
    svg:last-of-type {
      margin-left: 0.7em;
      vertical-align: -0.1em;
      cursor: pointer;
    }

    @media (max-width: 767px) {
      padding: 0;
    }
  `,
};

export default StyledCount;

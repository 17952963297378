import styled, { css } from 'styled-components';
import StyledUtil from 'common/styled/Util.style';
import { HiCheck } from 'react-icons/hi';

const buttonStyles = css`
  font-size: 1.6em;
  width: calc(50% - 0.625em);
  max-width: 10.9375em;
  height: 3.375em;
  margin: 0 0.3125em;
  border-width: 1px;
  border-style: solid;
  border-radius: 0.6rem;
`;

const StyledCreateTheme = {
  InfoText: styled.div`
    margin: -1.5em 0 2em;
    font-size: 1.6em;
    text-align: center;
    @media (max-width: 767px) {
      font-size: 2em;
    }
  `,
  Wrapper: styled.div`
    position: relative;
    max-height: 370px;
    border-width: 1px 0;
    border-style: solid;
    border-color: #e8e8e8;
    overflow: auto;
  `,
  Item: styled.div`
    position: relative;
    float: left;
    width: calc(50% - 2em);
    margin: 2em 1em;
    cursor: pointer;
    .video-thumb .overlay {
      display: none;
    }
    @media (max-width: 767px) {
      font-size: 1.42857em;
      margin: 1.5em 1em;
    }
  `,
  Subject: styled.div`
    ${StyledUtil.textEllipsis}
    font-size: 1.6em;
    font-weight: 500;
  `,
  Desc: styled.div`
    ${StyledUtil.textEllipsis}
    font-size: 1.4em;
    color: #666;
  `,
  CheckField: styled.span.attrs((props) => ({
    children: (
      <div>
        <input type='checkbox' id={props.id} />
        <span>
          <HiCheck />
        </span>
      </div>
    ),
  }))`
    position: absolute;
    top: 1em;
    left: 1em;
    input {
      position: fixed;
      left: -100px;
      width: 1px;
      height: 1px;
    }
    span {
      display: inline-block;
      font-size: 2em;
      width: 1.5em;
      height: 1.5em;
      border-radius: 100px;
      background-color: #444;
      text-align: center;
      line-height: 1.7em;
      color: #999;
      transition: all 0.1s ease;
    }
    input:checked + span {
      background-color: #485cc7;
      color: #fff;
    }
  `,
  ButtonGroup: styled.div`
    margin-top: 2em;
    text-align: center;
  `,
  Button: {
    Default: styled.button`
      ${buttonStyles}
      border-color: #d1d1d1;
      color: #999;
    `,
    Primary: styled.button`
      ${buttonStyles}
      border-color: #485cc7;
      background-color: #485cc7;
      color: #fff;
    `,
  },
};

export default StyledCreateTheme;

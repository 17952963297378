import styled, { css } from 'styled-components';

export const PdfBtn = css`
  float: right;
  margin-top: 0.35em;
  padding: 0.5em 2.5em;
  border: 1px #d1d1d1 solid;
  border-radius: 0.4rem;
  color: #d1d1d1;
  font-size: 14px;
  font-weight: 500;

  &:after {
    content: '';
    display: table;
    overflow: hidden;
    clear: both;
  }
`;

const StyledIndividual = {
  Container: styled.div`
    .chart-container {
      padding: 2.7em 3em 0 3em;
      background-color: #1e2024;

      .select-container {
        margin-bottom: 1em;
      }

      .select__control {
        background-color: transparent;
      }

      .css-2b097c-container {
        width: 30em;
      }
      .select__value-container {
        white-space: nowrap;
      }
    }
  `,

  H3: styled.h3`
    margin: 2.94em 0 1em;
    font-size: 3.4em;
    font-weight: 700;
    a {
      float: right;
      margin-top: 0.35em;
      margin-left: 0.5em;
      padding: 0.5em 2.5em;
      border: 1px #d1d1d1 solid;
      border-radius: 0.4rem;
      color: #d1d1d1;
      font-size: 0.42em;
      font-weight: 500;

      &:after {
        content: '';
        display: table;
        overflow: hidden;
        clear: both;
      }
    }

    @media (max-width: 767px) {
      margin-top: 1.5em;
      a {
        display: block;
        float: none;
        width: 100px;
        margin-top: 1.5em;
        margin-left: 0;
      }
      a:last-of-type {
        display: none;
      }
    }
  `,

  FlexRow: styled.div`
    display: flex;
    margin-bottom: 2.5em;
    > * {
      background: #1e2024;
      border-radius: 0.6rem;
    }
    > *:last-of-type {
      flex: 1;
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      margin-bottom: 0;
      > * {
        width: 100%;
        margin: 0 0 1em 0;
      }
    }
  `,
};

export default StyledIndividual;

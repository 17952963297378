import React, { useEffect, useState } from "react";
import Style from "./styled/Process.style";
import { priceFormat } from "common/util/util";
import { useHistory, useLocation } from "react-router";
import { Trans } from "react-i18next";

const getPaymentDate = (date) => {
  if(date < 0) date = 0;
  let now = new Date();
  let later = new Date(now.setDate(now.getDate()+date));
  const nowYear = later.getFullYear();
  const nowMonth = later.getMonth()+1 > 9 ? later.getMonth()+1 : '0' + (later.getMonth()+1);
  const nowDate = later.getDate() > 9 ? later.getDate() : '0' + later.getDate();

  return [nowYear, nowMonth, nowDate].join('.');
}

const OrderSummary = ({ currentCurrency, onclick, coupon, freeTrialData, takeCourseTerm, t }) => {
  const { state } = useLocation();
  const history = useHistory();
  const [paymentDate, setPaymentDate] = useState('');
  const [chargeTerm, setChargeTerm] = useState(1);

  useEffect(() => {}, [currentCurrency]);
  useEffect(() => {
    if(takeCourseTerm && takeCourseTerm > 0){
      if(takeCourseTerm === 1 || takeCourseTerm === 12) setChargeTerm(takeCourseTerm);
      else setChargeTerm('Else');
    }
  }, [takeCourseTerm])
  useEffect(()=>{
    if(!freeTrialData) return;

    if(freeTrialData.freeTakeCourseTerm > 0)
      setPaymentDate(getPaymentDate(freeTrialData.freeTakeCourseTerm-1))
  },[freeTrialData])
  const price = () =>
    currentCurrency === "KRW"
      ? priceFormat.korea(state?.priceKrw)
      : priceFormat.usa(state.priceUsd);

  const currency = (price) =>
    currentCurrency === "KRW"
      ? priceFormat.korea(price)
      : priceFormat.usa(price);

  if (!state) return history.goBack();

  return (
    <Style.OrderSummary>
      <Style.OrderTitle>{t("orderSummary.title")}</Style.OrderTitle>
      <Style.PriceSection>
        <div className="row head">
          <span>{t("orderSummary.selected")}</span>
          <span>{t("orderSummary.price")}</span>
        </div>
        <div className="row">
          <span>{state?.planCode}</span>
          <span>{price()}</span>
        </div>
      </Style.PriceSection>
      
      <Style.TotalSection>
        <div className="row">
          <span>{t("orderSummary.coupon")}</span>
          <span>
            {coupon.discount !== 0
              ? `- ${currency(coupon.discount)}`
              : `${currency(coupon.discount)}`}
          </span>
        </div>
      </Style.TotalSection>
      {
        freeTrialData && freeTrialData.isFreeTrial === 'Y' && freeTrialData.freeTakeCourseTerm > 0 ?
        <Style.TotalSection>
          <div className="row freeTrial">
            <span>
              <span>{t("orderSummary.freeTrial")}</span>
              <span>- {freeTrialData.freeTakeCourseTerm}{t("orderSummary.freeTrialPeriod")}</span>
            </span>
            <span>
              {`- ${price()}`}
            </span>
          </div>
        </Style.TotalSection>
        : <></>
      }

      <Style.TotalPrice>
        <span>{t("orderSummary.orderTotal")}</span>
        <span>
          {freeTrialData && freeTrialData.isFreeTrial === 'Y' && freeTrialData.freeTakeCourseTerm > 0
            ? currency(0)
            : currentCurrency === "KRW"
              ? currency(state?.priceKrw - coupon.discount)
              : currency(state?.priceUsd - coupon.discount)}
        </span>
      </Style.TotalPrice>

      {/* <p className='paymentMethod'>{t('orderSummary.method')}</p>
      <p>Credit Card</p> */}
      <p className="desc">
        {freeTrialData && freeTrialData.isFreeTrial === 'Y' && freeTrialData.freeTakeCourseTerm > 0
          ?<>
            <p className="title">{t("orderSummary.freeTrialPaymentNoticeTitle")}</p>
            <p className="subtitle">{t("orderSummary.freeTrialPaymentNoticeSubtitle", {endDate: paymentDate})}</p>
            <div className="list">
              <p><i>•</i>{t(`orderSummary.freeTrialPaymentNotice1.chargeTerm${chargeTerm}`, {chargeTerm: takeCourseTerm, paymentDate: paymentDate})}</p>
              <p><i>•</i>{t("orderSummary.freeTrialPaymentNotice2")}</p>
            </div>
          </>
          :
          <Trans t={t} values={{ planCode: state.planCode }}>
            orderSummary.nextPaymentNotice
          </Trans>
        }
      </p>

      <Style.Button onClick={onclick}>{t("orderSummary.button")}</Style.Button>
    </Style.OrderSummary>
  );
};

export default OrderSummary;

import styled, { css } from 'styled-components';
import { BsArrowRight } from 'react-icons/bs';

import visualBg from 'assets/images/event/230609/visual.jpg';
import icoArrowDw from 'assets/images/event/230609/ico_arrow_down.png';
import icoArrowDw2 from 'assets/images/event/230609/ico_arrow_down2.png';
import icoArrowDw3 from 'assets/images/event/230609/ico_arrow_down3.png';
import icoTit from 'assets/images/event/230609/ico_tit.png';
import icoTit2 from 'assets/images/event/230609/ico_tit2.png';
import icoTit3 from 'assets/images/event/230609/ico_tit3.png';
import icoTit4 from 'assets/images/event/230609/ico_tit4.png';
import icoTit5 from 'assets/images/event/230609/ico_tit5.png';
import icoTit6 from 'assets/images/event/230609/ico_tit6.png';
import materialImg from 'assets/images/event/230609/material.jpg';
import materialImg2 from 'assets/images/event/230609/material2.jpg';
import materialImg3 from 'assets/images/event/230609/material3.jpg';
import bannerBg from 'assets/images/event/230609/banner.jpg';

const StyledEventPage = {
  HeaderWrap: styled.div`
    background: red;
  `,
  Logo: styled.h1``,
  HeaderButtonWrap: styled.span`
    height: 4em;
  `,
  JoinButton: styled.button.attrs({
    type: 'button',
  })`
    height: 100%;
    padding: 0 1em;
    font-size: 1.6em;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #b9b9b9;
    border-radius: 2em;
    color: #fff;
    cursor: pointer;
  `,

  LoginButton: styled.button.attrs({
    type: 'button',
  })`
    height: 100%;
    padding: 0 1em;
    font-size: 1.6em;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #b9b9b9;
    border-radius: 2em;
    color: #fff;
    cursor: pointer;
  `,

  ArrowButton: styled.button.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <BsArrowRight />
      </>
    ),
  }))`
    background: #ffd202;
    border-radius: 0.4em;
    padding: 1em 2em;
    line-height: 1;
    span {
      padding-right: 1em;
      font-size: 1.1em;
      color: #323351;
      vertical-align: middle;
    }
    svg {
      font-size: 2em;
      color: #323351;
      vertical-align: middle;
    }
  `,

  EventWrap: styled.div`
    /* font-family: 'Pretendard'; */
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    color: #555;
    letter-spacing: -0.035em;
    margin-top: -9rem;

    .blind {
      overflow: hidden;
      position: absolute;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
    }

    .inner {
      position: relative;
      max-width: 750px;
      margin: auto;
      padding: 5em 0 4em;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .title {
      margin-bottom: 1.8em;
      word-wrap: break-word;
      word-break: keep-all;
      h2 {
        font-weight: 700;
        font-size: 1.8em;
      }
    }

    @media (max-width: 850px) {
      .inner {
        padding: 4em 4%;
      }
    }

    .hide {
      display: none !important;
    }

    .visual {
      background-image: url(${visualBg});
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 9em 0 2em;
        max-width: 850px;
      }
      .title {
        margin-bottom: 1.5em;
        h2 {
          margin-bottom: 0.5em;
          font-size: 2.7em;
          color: #fff;
          span {
            color: #ffd202;
            &:nth-of-type(2) {
              color: #7be1ff;
            }
          }
        }
        .sub {
          font-weight: 300;
          font-size: 1.4em;
          color: #fff;
        }
      }
      ul {
        display: flex;
        gap: 1.2em;
        li {
          box-shadow: 0.5em 0.5em 3em rgba(50, 51, 81, 0.3);
          border-radius: 50%;
          overflow: hidden;
        }
      }
      .desc {
        margin-top: 1.5em;
        padding-bottom: 2.7em;
        font-weight: 200;
        font-size: 1.1em;
        color: #fff;
        background: url(${icoArrowDw}) no-repeat 50% 100%;
        background-size: 2.3em auto;
      }
    }
    @media (max-width: 850px) {
      .visual {
        .inner {
          padding: 9em 4% 2em;
          font-size: 0.9em;
        }
        .title {
          h2 {
            font-size: 2.4em;
          }
        }
      }
    }

    .study {
      background: #e4e6ff;
      .title {
        color: #2849f2;
        h2 {
          display: inline-block;
          min-height: 1.27em;
          &::before {
            content: '';
            display: inline-block;
            width: 1.86em;
            height: 1.27em;
            margin-right: 0.2em;
            background: url(${icoTit}) no-repeat 0 0;
            background-size: contain;
            vertical-align: middle;
          }
          > div {
            display: inline;
            vertical-align: middle;
          }
        }
      }
      .thumb {
        display: block;
        max-width: 690px;
        margin: 0 auto 2em;
        img {
          width: 100%;
        }
      }
      h3 {
        margin-bottom: 0.6em;
        font-weight: 600;
        font-size: 1.2em;
        color: #2849f2;
      }
      .desc {
        font-size: 1.2em;
        color: #222;
        .point {
          position: relative;
          display: inline-block;
          font-weight: 600;
          &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: -0.1em;
            left: -0.3em;
            width: calc(100% + 0.6em);
            height: 0.6em;
            background: #9bc8ff;
            z-index: 1;
          }
          div {
            position: relative;
            z-index: 2;
          }
        }
      }
      &.story {
        background: #e9d064;
        .title {
          color: #222;
          h2 {
            position: relative;
            padding: 1.25em 0 0;
            &::before {
              position: absolute;
              top: 0;
              left: 50%;
              margin: 0;
              transform: translateX(-50%);
              width: 2.22em;
              height: 1.25em;
              background-image: url(${icoTit5});
            }
          }
        }

        .desc {
          .point {
            &::before {
              background: #15ec75;
            }
          }
        }
      }
    }

    .topik {
      background: #1e1e20;
      .title {
        color: #21df76;
        h2 {
          position: relative;
          display: inline-block;
          min-height: 1.16em;
          &::before {
            content: '';
            display: inline-block;
            width: 1.33em;
            height: 1.16em;
            margin: 0 0.2em 0 0;
            background: url(${icoTit2}) no-repeat 0 0;
            background-size: contain;
            vertical-align: middle;
          }
          > div {
            display: inline;
            vertical-align: middle;
          }
        }
        &.emoji {
          margin-top: 3.5em;
          h2 {
            padding-left: 0;
            &::before {
              display: none;
            }
            span {
              display: inline-block;
              width: 1.33em;
              height: 1.22em;
              margin: 0 0.2em 0 0;
              background: url(${icoTit3}) no-repeat 0 0;
              background-size: contain;
              vertical-align: middle;
            }
          }
        }
      }
      .courseWrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 3em 0;
      }
      .course {
        width: 47%;
        a {
          display: block;
          background: #fff;
          border-radius: 1em;
          padding: 1.8em 1.5em;
        }
        h3 {
          margin-bottom: 0.5em;
          letter-spacing: -0.02em;
          font-weight: 600;
          font-size: 1.2em;
          color: #000;
        }
        .thumb {
          border-radius: 0.6rem;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .msg {
          margin: 1em 0 0.6em;
          padding-left: 1.9em;
          text-align: left;
          font-size: 0.7em;
          color: #6e6e6e;
          background-image: url(${icoArrowDw2});
          background-repeat: no-repeat;
          background-position: 0.6em 50%;
          background-size: auto 0.43em;
        }
        .gif {
          border-radius: 0.6rem;
          overflow: hidden;
          img {
            width: 100%;
          }
        }
        .desc {
          margin-top: 1em;
          text-decoration: underline;
          font-size: 0.9em;
          color: #222;
        }
      }
    }
    @media (max-width: 690px) {
      .topik {
        .course {
          width: 49%;
          .desc {
            min-height: 2.4em;
            line-height: 1.2;
          }
        }
      }
    }

    .represent {
      background: #7969eb;
      .inner {
        max-width: 690px;
      }
      .title {
        h2 {
          color: #fff;
          span {
            display: inline-block;
            width: 1.44em;
            height: 1.88em;
            margin-right: 0.2em;
            background: url(${icoTit4}) no-repeat 0 0;
            background-size: contain;
            vertical-align: middle;
          }
        }
      }
      .courseWrap {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 1.5em 0;
      }
      .course {
        width: 47%;
        a {
          display: block;
          border-radius: 0.5em;
          overflow: hidden;
          box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.2);
        }
        .thumb {
          position: relative;
          img {
            width: 100%;
          }
        }
        h3 {
          padding: 0.9em 0.5em 0.7em;
          border-top: 4px solid #21df76;
          background: #fff;
          letter-spacing: -0.02em;
          font-weight: 600;
          font-size: 0.9em;
          color: #222;
        }
      }
    }
    @media (max-width: 690px) {
      .represent {
        .course {
          width: 49%;
        }
      }
    }

    .allCourse {
      background: #edeff3;
      .inner {
        max-width: 850px;
      }
      .title {
        color: #222;
        h2 {
          display: inline-block;
          min-height: 1.27em;
          &::before {
            content: '';
            display: inline-block;
            width: 1.58em;
            height: 1.33em;
            margin-right: 0.2em;
            background: url(${icoTit6}) no-repeat 0 0;
            background-size: contain;
            vertical-align: middle;
          }
          > div {
            display: inline;
            vertical-align: middle;
          }
        }
      }
      .tabWrap {
        display: inline-flex;
        height: 3em;
        border: 3px solid #d9dee7;
        background: #d9dee7;
        border-radius: 1.5em;
        margin-bottom: 2em;
        width: 100%;
        max-width: 480px;
        button {
          width: 25%;
          height: 100%;
          border-radius: 1.5em;
          font-size: 1em;
          color: #6e6e6e;
          &.active {
            background: #1e1e20;
            font-weight: 600;
            font-size: 1.05em;
            color: #fff;
          }
        }
      }

      --gap: 10px;
      --col: 4;
      .listWrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 1.5em var(--gap);
        li {
          width: calc((100% - var(--gap) * (var(--col) - 1)) / var(--col));
          border-radius: 0.6rem;
          overflow: hidden;
          a {
            display: block;
          }
        }
      }
    }
    @media (max-width: 750px) {
      .allCourse {
        --col: 3;
      }
    }
    @media (max-width: 640px) {
      .allCourse {
        --col: 2;
      }
    }

    .materials {
      background: #e4e6ff;
      .inner {
        max-width: 850px;
      }
      .title {
        .sub {
          display: inline-block;
          padding: 0.4em 1.2em;
          border-radius: 2em;
          background: #b19ef9;
          margin-bottom: 1.1em;
          font-size: 1em;
          color: #fff;
        }
        h2 {
          color: #222;
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
      }
      li {
        width: 31.8%;
        padding: 2.5em 0 11em;
        border-radius: 1em;
        background: #fff;
        overflow: hidden;
        color: #fff;
        background-repeat: no-repeat;
        background-position: 50% calc(100% - 2em);
        background-size: auto 7.8em;
        box-shadow: 0.6em 0.6em 0.9em rgba(50, 51, 81, 0.2);
        &:nth-child(1) {
          background-color: #6f50da;
          background-image: url(${materialImg});
        }
        &:nth-child(2) {
          background-color: #4c68e3;
          background-image: url(${materialImg2});
        }
        &:nth-child(3) {
          background-color: #4892f1;
          background-image: url(${materialImg3});
        }
        .desc {
          margin-bottom: 0.75em;
          font-weight: 300;
          font-size: 0.75em;
        }
        h3 {
          font-weight: 600;
          font-size: 1.2em;
        }
      }
    }

    .banner {
      background-color: #323351;
      background-image: url(${bannerBg});
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: auto 100%;
      .inner {
        max-width: 850px;
        height: 13.25em;
        padding-top: 3em;
        padding-bottom: 3em;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
      }
      .desc {
        width: 50%;
        font-size: 1.6em;
        font-weight: 300;
        color: #fff;
        span {
          font-weight: 600;
          span {
            color: #fde36a;
          }
        }
      }
    }
    @media (max-width: 640px) {
      .banner .inner {
        display: block;
        height: auto;
      }
      .banner .desc {
        width: 100%;
      }
      .banner button {
        margin-top: 2em;
      }
    }

    .korean {
      background: #c1d4fc;
      .inner {
        max-width: 850px;
      }
      .title {
        margin-bottom: 1.3em;
        h2 {
          font-size: 1.5em;
          color: #0084ff;
        }
      }
      ul {
        display: flex;
        justify-content: space-between;
      }
      li {
        width: 31.8%;
        border-radius: 1em;
        padding: 1em;
        background: #fff;
        overflow: hidden;
        .desc {
          padding-bottom: 2em;
          font-size: 0.9em;
          color: #222;
          background-image: url(${icoArrowDw3});
          background-repeat: no-repeat;
          background-position: 50% calc(100% - 0.7em);
          background-size: auto 0.5em;
        }
        a,
        button {
          display: inline-block;
          min-width: 80%;
          padding: 0.3em 0.6em;
          border-radius: 2em;
          font-weight: 100;
          font-size: 1.6em;
          color: #222;
        }
        &:nth-child(1) {
          a,
          button {
            background: rgb(51, 174, 242);
            background: linear-gradient(
              124deg,
              rgba(51, 174, 242, 1) 0%,
              rgba(130, 226, 130, 1) 50%
            );
          }
        }
        &:nth-child(2) {
          a {
            background-color: #ffe402;
          }
        }
        &:nth-child(3) {
          a {
            background: rgb(51, 174, 242);
            background: linear-gradient(
              124deg,
              rgba(51, 174, 242, 1) 50%,
              rgba(130, 226, 130, 1) 100%
            );
          }
        }
      }
    }
    @media (max-width: 375px) {
      .korean li a {
        font-size: 1.2em;
      }
    }
  `,
};

export default StyledEventPage;

import React, { useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { ModalContext } from "component/Modal/modalContext";
import { useState } from "react";
import { useAxios } from "common/hook/useAxiosHook";
import userApi from "api/user";
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions } from "component/user/state";

const Style = {
  Container: styled.div`
    text-align: center;
  `,
  H3: styled.h3`
    font-size: 2.4em;
    font-weight: 700;
  `,
  Desc: styled.p`
    margin: 1.3em 0;
    font-size: 1.6em;
    font-weight: 400;
  `,
  InputBox: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 1.6em;
  `,
  PasswordInput: styled.input`
    flex: 1;
    padding: 1em;
    margin-right: 10px;
    width: calc(100% - 8em);
    height: 100%;
    border-radius: 0.3em;
    border: 1px #d1d1d1 solid;
  `,
  SubmitButton: styled.button`
    flex: 0 0 6.5em;
    padding: 1em 0;
    background: #485cc7;
    border-radius: 0.3em;
    color: #fff;
  `,
  SubmitAlert: styled.strong`
    display: block;
    margin-top: 0.5em;
    font-size: 1.4em;
    color: #fc2c03;
  `,
  CancelBtn: styled(Link)`
    display: inline-block;
    width: calc(50% - 0.5em);
    margin-right: 0.5em;
    padding: 1em 0;
    border-radius: 0.6rem;
    border: 1px #d1d1d1 solid;
    color: #999;
    font-size: 1.6em;
    font-weight: 700;
  `,
  SettingPageBtn: styled(Link)`
    display: inline-block;
    width: 50%;
    padding: 1em 0;
    border-radius: 0.6rem;
    background-color: #485cc7;
    color: #fff;
    font-size: 1.6em;
    font-weight: 700;
  `,
};

const ResetPasswordModal = () => {
  const { handleModal } = React.useContext(ModalContext);
  const dispatch = useDispatch();
  const { token, data: UserData } = useSelector((state) => state.user);
  const [{ data, error }, refreshDormantAccount] = useAxios(userApi.disableDormantAccount, [], true);
  const [password, setPassword] = useState("");

  useEffect(() => {
    const { snsName: snsLoginState, isRest } = UserData;
    if (snsLoginState !== null && isRest) {
      refreshDormantAccount(token, snsLoginState, "");
      dispatch(userActions.fetchUser(token));
    }
  }, [UserData]);

  useEffect(() => {
    const { snsName: snsLoginState, isRest } = UserData;
    if (!isRest && !snsLoginState) {
      handleModal();
    }
  }, [UserData.isRest]);

  const onChangePassword = useCallback(
    (e) => {
      const {
        target: { value },
      } = e;
      setPassword(value);

      if (e.key === "Enter" && password !== "") {
        onSubmit();
      }
    },
    [password, error]
  );

  const onSubmit = async () => {
    const { snsName: snsLoginState } = UserData;
    await refreshDormantAccount(token, snsLoginState, password);
    dispatch(userActions.fetchUser(token));
  };

  return (
    <Style.Container>
      {UserData?.snsName === null ? (
        <>
          <Style.H3>휴면 상태 변경</Style.H3>
          <Style.Desc>회원님의 소중한 정보를 보호하기 위해 비밀번호를 입력해주세요.</Style.Desc>
          <Style.InputBox>
            <Style.PasswordInput
              type="password"
              value={password}
              onKeyDown={(e) => e.key === "Enter" && onChangePassword(e)}
              onChange={(e) => onChangePassword(e)}
              title="비밀번호 입력"
            />
            <Style.SubmitButton type="button" onClick={onSubmit} title="휴면 해지">
              휴면 해지
            </Style.SubmitButton>
          </Style.InputBox>
          {error && error.data ? <Style.SubmitAlert>{error.data.reason}</Style.SubmitAlert> : null}
        </>
      ) : (
        <>
          <Style.H3>휴면 상태가 해제 되었습니다.</Style.H3>
          <Style.Desc>회원님의 소중한 정보를 보호하기 위해 비밀번호를 재설정 해주세요.</Style.Desc>
          <Style.CancelBtn to="/" onClick={() => handleModal()}>
            나중에 할게요
          </Style.CancelBtn>
          <Style.SettingPageBtn to="/setting" onClick={() => handleModal()}>
            세팅페이지 이동
          </Style.SettingPageBtn>
        </>
      )}
    </Style.Container>
  );
};

export default ResetPasswordModal;

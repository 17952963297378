import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StyledForm from 'component/form/Form.style';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import H2 from 'component/atoms/H2';
import PreviousLink from 'component/atoms/PreviousLink';
import communityApi from 'api/community';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import { toast } from 'react-toastify';
import useErrorToastify from 'common/hook/useErrorToastify';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';
import { API_HOST } from 'common/constant';

const Wrapper = styled(ContentsWrapper)`
  padding-bottom: 16em;
`;

const Form = styled(StyledForm.FormWrapper)`
  max-width: 700px;
  margin: auto;
`;

const Title = styled(H2)`
  padding: 1.25em 0 1.5em;
  text-align: center;
`;

const CommunityRevise = () => {
  const { t } = useTranslation('communityPage');
  const { state } = useLocation();
  const history = useHistory();
  useEffect(() => state === null && history.replace('/community'), [state]);

  const currentData = state.data;
  const blocksFromHTML = convertFromHTML(currentData.content);
  const editorState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  const [title, setTitle] = useState(currentData?.title);
  const [content, setContent] = useState(() =>
    EditorState.createWithContent(editorState)
  );

  const token = useSelector((state) => state.user.token);

  const [articleState, fetchArticleState] = useAxios(
    communityApi.putArticle,
    [],
    true
  );
  const { loading, data, error } = articleState;

  const onCancel = (e) => {
    e.preventDefault();
    history.goBack();
  };
  const uploadImage = (file) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', API_HOST + '/api/v1/boards/upload');
      const data = new FormData();
      data.append('imageFiles', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        resolve({ data: { link: xhr.response } });
      });
      xhr.addEventListener('error', () => {
        reject(xhr.response);
      });
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const modifiedContent = draftToHtml(
      convertToRaw(content.getCurrentContent())
    );

    if (
      title.trim() === currentData.title &&
      modifiedContent === currentData.content
    ) {
      toast.warn('Nothing has changed.');
      return;
    }
    if (title && content) {
      fetchArticleState(currentData.id, title, modifiedContent, token);
    }
  };

  useEffect(() => {
    if (data !== null) {
      history.push(`/community/${data}`);
      toast('It has been changed.');
    }
  }, [data]);

  useErrorToastify(error);

  return (
    <>
      <Wrapper>
        {/* <PreviousLink to='/community' text='Back to Community' /> */}

        <Title>{t('post.reviseTitle')}</Title>

        <Form onSubmit={onSubmit}>
          <StyledForm.InputContainer>
            <StyledForm.Label htmlFor='title'>
              {t('post.titleLabel')}
            </StyledForm.Label>
            <StyledForm.Input
              type='text'
              id='title'
              name='title'
              title='title'
              required
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              maxLength={100}
            />
          </StyledForm.InputContainer>

          <StyledForm.InputContainer>
            <StyledForm.Label htmlFor='content'>
              {t('post.contentLabel')}
            </StyledForm.Label>

            <StyledForm.Editor>
              <Editor
                editorState={content}
                toolbarClassName='toolbarClassName'
                wrapperClassName='wrapperClassName'
                editorClassName='editorClassName'
                onEditorStateChange={setContent}
                handlePastedText={() => false}
                toolbar={{
                  image: {
                    uploadCallback: uploadImage,
                    alt: { present: true, mandatory: true },
                  },
                }}
              />
            </StyledForm.Editor>
          </StyledForm.InputContainer>

          <StyledForm.ButtonGroup>
            <StyledForm.Button type='submit'>
              {t('buttonPost')}
            </StyledForm.Button>
            <StyledForm.CancelButton onClick={onCancel}>
              {t('buttonCancel')}
            </StyledForm.CancelButton>
          </StyledForm.ButtonGroup>
        </Form>

        <LoadingOverlay isLoading={loading} />
      </Wrapper>
    </>
  );
};

export default CommunityRevise;

import React, { useState } from "react";
import Styled from "./index.style";
import { useParams } from "react-router-dom";
import { ModalContext } from "component/Modal/modalContext";
import UserInfo from "component/atoms/UserInfo";
import ReviseIcon from "component/atoms/Icon/ReviseIcon";
import DeleteIcon from "component/atoms/Icon/DeleteIcon";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import CommentLikeHandling from "./LikeHandling";
import ReplyIcon from "component/atoms/Icon/ReplyIcon";
import CommentRevise from "container/CommunityPage/Detail/Comment/Revise";
import CommentReReply from "container/CommunityPage/Detail/Comment/ReReply";
import { useTranslation } from "react-i18next";

const CommentItem = ({
  item,
  userData,
  editState,
  reReplyState,
  onRefresh,
}) => {
  const { t } = useTranslation("communityPage");

  const { id } = useParams();
  let { handleModal } = React.useContext(ModalContext);

  const [editId, setEditId] = editState;
  const [reReplyId, setReReplyId] = reReplyState;

  const [content, setContent] = useState(item.content);

  const handleEdit = () => {
    setEditId(editId === item.id ? null : item.id);
    setReReplyId(null);
  };

  const handleReReply = () => {
    setReReplyId(reReplyId === item.id ? null : item.id);
    setEditId(null);
  };

  const handleDelete = () =>
    handleModal(<ConfirmDeleteModal id={item.id} onRefresh={onRefresh} />);

  return (
    <Styled.Container>
      <UserInfo src={item.userProfilePath} name={item.userName} />

      <Styled.RegDt format="D, MMMM, h:mm a">{item.regDate}</Styled.RegDt>

      <Styled.Menu>
        {userData?.tblMemberBasicIdx === item.userId && (
          <>
            <ReviseIcon onClick={handleEdit}>{t("buttonEdit")}</ReviseIcon>
            <DeleteIcon onClick={handleDelete}>{t("buttonDelete")}</DeleteIcon>
          </>
        )}

        <CommentLikeHandling
          id={id}
          replyId={item.id}
          count={item.likeCount}
          isGood={item.isGood}
        />

        <ReplyIcon onClick={handleReReply}>{t("buttonReply")}</ReplyIcon>
      </Styled.Menu>
      {editId === item.id && (
        <CommentRevise
          item={item}
          setEditId={setEditId}
          onRefresh={onRefresh}
          initContent={setContent}
        />
      )}
      {editId !== item.id && item?.content && item.isOpen ? (
        <Styled.Content>
          {content.split("\n").map((line, index) => {
            return (
              <span key={index}>
                {line}
                <br />
              </span>
            );
          })}
        </Styled.Content>
      ) : editId !== item.id && item?.content && !item.isOpen ? (
        <Styled.Content>
          <span>관리자가 비공개 처리한 댓글입니다.</span>
        </Styled.Content>
      ) : (
        <Styled.Content>
          {content.split("\n").map((line, index) => {
            return (
              <span key={index}>
                {line}
                <br />
              </span>
            );
          })}
        </Styled.Content>
      )}

      {reReplyId === item.id && (
        <CommentReReply
          item={item}
          setReReplyId={setReReplyId}
          onRefresh={onRefresh}
        />
      )}
    </Styled.Container>
  );
};

export default CommentItem;

import React, { useEffect, useState } from 'react';
import Style from '../styled/Pagination.style';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Pagination = (props) => {
  // 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
  const t = props.t;

  const movePage = (pageNum) => {
    if (pageNum < 1 || pageNum > props.maxPage) return;

    props.setPage(pageNum);
  }
  console.log(t)
  return (
    <>
      {props.isMobile ? (
        <>
          {/* ~1024px */}
          <Style.Pagination>
            {props.currentPage !== props.maxPage && <Style.BtnMore onClick={() => movePage(props.currentPage + 1)}>{t('button.more')}</Style.BtnMore>}
          </Style.Pagination>
        </>
      ) : (
        <>
          {/* 1025px~ */}
          <Style.Pagination>
            {/* 이전 페이지 없을 경우 클래스 disabled 추가 */}
            <Style.BtnPrev
              className={props.currentPage === 1 ? "disabled" : ""}
              onClick={() => movePage(props.currentPage - 1)}
            ><span>{t('button.prev')}</span></Style.BtnPrev>

            {/* 선택 페이지에 클래스 active 추가 */}
            {/* <Style.BtnPage className='active'>1</Style.BtnPage>
            <Style.BtnPage>2</Style.BtnPage>
            <Style.BtnPage>3</Style.BtnPage>
            <Style.BtnPage>4</Style.BtnPage>
            <Style.BtnPage>5</Style.BtnPage> */}
            {
              props.btns.map(num => {
                return (
                  <Style.BtnPage
                    className={(props.currentPage === num) ? 'active' : ''}
                    onClick={() => movePage(num)}
                  >{num}</Style.BtnPage>
                );
              })
            }

            {/* 다음 페이지 없을 경우 클래스 disabled 추가 */}
            <Style.BtnNext
              className={props.currentPage === props.maxPage ? "disabled" : ""}
              onClick={() => movePage(props.currentPage + 1)}
            ><span>{t('button.next')}</span></Style.BtnNext>
          </Style.Pagination>
        </>
      )}
    </>
  );
};
export default Pagination;


import { all, put, call, takeEvery } from 'redux-saga/effects';
import { actions, Types } from './index';
import { callApi } from 'common/util/api';
import { makeFetchSaga } from 'common/util/fetch';

let tempAccessToken =
  'eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MzkwNzksInVzZXJJRCI6Im1hc3RlcnRvcGlrVGVzdDFAbWFzdGVyLmNvbSIsInVzZXJGaXJzdE5hbWUiOiJUb3BpayIsInVzZXJMYXN0TmFtZSI6Ik1hc3RlciIsInVzZXJUeXBlIjoiQSAgICAgICAgICIsInN1YiI6Im1hc3RlcnRvcGlrVGVzdDFAbWFzdGVyLmNvbSIsImlhdCI6MTYwOTIzMDAxMSwiZXhwIjoxNjE3MDA2MDExfQ.UB4RxPXh_D0HR9KgU68iP3At4LeEsB6w5DVvsJnudXhT9n-JOZS-hrgYNy_EamRNgqc-zF-C5-0pbVR5qXkZbg';

function* fetchAll() {
  const { isSuccess, data } = yield call(callApi, {
    url: '/api/v1/notifications?title=',
    headers: {
      Authorization: `Bearer ${tempAccessToken}`,
      'Access-Control-Allow-Origin': '*',
    },
  });
  if (isSuccess) {
    yield put(actions.setAuth(data.token));
    // alert('[Temp Message] \n token: ' + data.token);
    // fetchUser();
  } else {
    // yield put(actions.setValue('status', AuthStatus.LoginFailed));
  }
}

export default function* UserSaga() {
  yield all([
    takeEvery(
      Types.FetchAll,
      makeFetchSaga({ fetchSaga: fetchAll, canCache: false })
    ),
  ]);
}

import React, { useContext, useEffect } from "react";
import Styled from "./Modal.style";
import StyledModal from "component/Modal/styled/Modal.style";
import StyledForm from "component/form/Form.style";
import { ModalContext } from "component/Modal/modalContext";
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions } from "component/user/state";
import Moment from "react-moment";
import { CURRENCY } from "common/constant";
import { formatNumber } from "common/util/util";
import { useTranslation, Trans } from "react-i18next";

const ChangeImpossibleModal = ({ selectedPlan, getPlan, handleFetchPlan }) => {
  const { t } = useTranslation("planPage");

  const { handleModal } = useContext(ModalContext);
  const selectedPlanName = selectedPlan.planCode;
  const currentCurrency = useSelector((state) => state.common.currency);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    dispatch(userActions.fetchUser(token));
    handleFetchPlan();
  }, []);

  const WebInterfaceHandleModal = () => {
    handleFetchPlan();
    handleModal();
  };

  useEffect(() => {
    window.addEventListener("webBridge", async (e) => {
      console.log(e.detail);
      WebInterfaceHandleModal();
    });
  }, [window.dispatchEvent]);

  return (
    <Styled.Container>
      <StyledModal.H2>PayPal Subscription</StyledModal.H2>

      <Styled.Text className={selectedPlan.planCode.toLowerCase()}>
        You can change your plan after the next billing date.
      </Styled.Text>


      <Styled.ButtonGroup>
        <StyledForm.ButtonLink onClick={() => handleModal()}>
          {t("Modal.cancelDowngrade.change")}
        </StyledForm.ButtonLink>
      </Styled.ButtonGroup>
    </Styled.Container>
  );
};

export default ChangeImpossibleModal;

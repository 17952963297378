import React, { useState, useEffect } from 'react';
import { StyledLibraryPage } from './styled/index';
import SwiperCore, { Navigation } from 'swiper';
import { SwiperSlide } from 'swiper/react';
import SliderNav from 'component/atoms/SliderNav/SliderNav.style';
import LibraryInput from './libraryInput';
import LibraryBadge from './libraryBadge';
import libraryApi from 'api/library';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';
SwiperCore.use([Navigation]);

const SearchContainer = ({ setSearchWord, setSelect }) => {
  const token = useSelector((state) => state.user.token);
  const [badgeIdx, setBadgeIdx] = useState(null);
  const [{ data: badgeList }, refreshGetBadgeList] = useAxios(
    libraryApi.getBadge,
    [],
    true
  );

  useEffect(() => {
    if (token) {
      refreshGetBadgeList(token);
    } else {
      refreshGetBadgeList();
    }
  }, [token]);

  const clickBadge = (text, index) => {
    setSearchWord(text);
    setBadgeIdx(index);
  };

  return (
    <StyledLibraryPage.SearchContainer>
      <LibraryInput
        setSelect={setSelect}
        getSearchWord={setSearchWord}
        badge={badgeIdx !== null && badgeList[badgeIdx]}
        setBadgeIdx={setBadgeIdx}
      />

      {badgeList && (
        <StyledLibraryPage.SwiperWrap className='library'>
          <SliderNav.Prev
            // className={`slider-prev prev-courses ${
            //   badgeList.length > 4 && 'visible'
            // }`}
            className={`slider-prev prev-courses`}
          />
          <SliderNav.Next
            // className={`slider-next next-courses ${
            //   badgeList.length > 4 && 'visible'
            // }`}
            className={`slider-next next-courses`}
          />
          <StyledLibraryPage.Swiper
            speed={300}
            slidesPerView='auto'
            navigation={{
              nextEl: `.next-courses`,
              prevEl: `.prev-courses`,
            }}
            // className={badgeList.length > 4 ? 'arrow' : ''}
            className={'arrow'}
          >
            {badgeList.length > 0 &&
              badgeList.map((badge, index) => (
                <SwiperSlide key={index}>
                  <LibraryBadge
                    key={index}
                    active={index === badgeIdx ? 'active' : ''}
                    onClick={() => clickBadge(badge.recommendKeyword, index)}
                  >
                    {badge.recommendKeyword}
                  </LibraryBadge>
                </SwiperSlide>
              ))}
          </StyledLibraryPage.Swiper>
        </StyledLibraryPage.SwiperWrap>
      )}
    </StyledLibraryPage.SearchContainer>
  );
};

export default SearchContainer;

import styled from 'styled-components';

const StyledCurriculumView = {
  Title: styled.h3`
    margin-bottom: 0.3em;
    text-align: center;
    font-size: 3.6em;
    font-weight: 700;
  `,
  Badge: styled.span`
    display: inline-block;
    margin-right: 1.1em;
    padding: 0.3em 0.6em;
    vertical-align: middle;
    border-radius: 0.375em;
    background-color: ${({ bgColor }) => (bgColor ? bgColor : '#485cc7')};
    font-size: 0.36em;
    font-weight: 700;
  `,
  Desc: styled.p`
    margin: 0 auto;
    max-width: 42.5em;
    text-align: center;
    color: #c9cbd1;
    font-size: 1.8em;
    font-weight: 300;
  `,
  InfoContainer: styled.div`
    display: flex;
    margin: 7.5em 0 3em;
    > * {
      flex: 1;
    }
    @media (max-width: 767px) {
      flex-direction: column;
      > div {
        margin-right: 0;
        margin-bottom: 1em;
      }
    }
  `,
  Info: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 3em;
    padding: 3.2em 2.8em;
    border-radius: 0.6em;
    background-color: #1e2024;
    color: #c9cbd1;

    .number {
      color: #fff;
      font-size: 4em;
      font-weight: 700;
      > span {
        vertical-align: 0.5em;
        font-size: 0.4em;
        font-weight: 500;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
    .title {
      margin-right: 0.5em;
      font-size: 2em;
      font-weight: 400;
    }
    > .level {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: #fff;
      &.eps {
        font-size: 2em;
      }
      .levelNumber {
        font-size: 2.5em;
        font-weight: 700;
      }

      > span {
        font-size: 1.4em;
        font-weight: 500;
      }
    }

    @media (max-width: 1200px) {
      flex-direction: column;
    }
    @media (max-width: 767px) {
      flex-direction: row;
      > .level {
        text-align: right;
      }
    }
  `,
  LectureContainer: styled.div`
    width: calc(100% + 4em);
    margin: 6.5em 2em 10em -2em;

    > div {
      float: left;
      width: calc(25% - 4em);
      margin: 0 2em 6em;
    }

    &:after {
      content: '';
      overflow: hidden;
      display: table;
      clear: both;
    }

    @media (max-width: 1024px) {
      width: calc(100% + 2em);
      margin: 6.5em 2em 10em -1em;

      > div {
        float: left;
        width: calc(25% - 2em);
        margin: 0 1em 6em;
      }
    }
    @media (max-width: 767px) {
      width: calc(100% + 2em);
      margin: 6.5em 2em 10em -1em;

      > div {
        float: left;
        width: calc(50% - 2em);
        margin: 0 1em 6em;
      }
    }
  `,
  SubTitle: styled.h3`
    margin-bottom: 1em;
    font-size: 3.6em;
    font-weight: 700;
  `,
  MockTestContainer: styled.div`
    width: calc(100% + 5em);
    margin: 6.5em 2em 3em -2.5em;
    > div {
      float: left;
      width: calc(33.333% - 5em);
      margin: 0 2.5em 6em;
    }
    @media (max-width: 767px) {
      width: calc(100% + 2.5em);
      margin: 6.5em 2em 3em -1.25em;

      > div {
        float: left;
        width: calc(50% - 2.5em);
        margin: 0 1.25em 3em;
      }
    }
    @media (max-width: 375px) {
      width: calc(100%);
      margin: 6.5em 2em 3em 0;

      > div {
        float: left;
        width: calc(100%);
        margin: 0 0 3em;
      }
    }
    &:after {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }
  `,
  BtnContainer: styled.div`
    text-align: center;
    margin-bottom: 17.9em;
    > button {
      display: inline-block;
      padding: 1em 3.97em;
      border: 1px #ffb500 solid;
      border-radius: 0.375em;
      color: #ffb500;
      font-size: 1.6em;
      font-weight: 700;
    }
  `,
  ProgressContainer: styled.div`
    .curriculum {
      height: auto;
    }
  `,
};

export default StyledCurriculumView;

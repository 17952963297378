import React, { useState, useEffect } from 'react';
import { SwiperSlide } from 'swiper/react';
import Loading from 'component/atoms/Loading';
import Error from 'component/Error';
import SwiperList from 'component/molecules/SwiperList';
import { EmptyList } from 'component/atoms/EmptyList';
import UserThemeItem from 'component/molecules/ThemeItem/UserThemeItem';
import { masterTopikUrl } from 'common/constant';

const InterestedTheme = ({ id, initialState, token }) => {
  const [themeList, fetchThemeList] = initialState;
  const [slideItems, setSlideItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { loading, data, error } = themeList;

  useEffect(() => {
    fetchThemeList({ page: 0, token });
  }, []);

  useEffect(() => {
    if (data !== null) {
      setSlideItems([...slideItems, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [themeList]);

  const onNext = () => {
    if (!loading && hasMore) {
      fetchThemeList({ page: data.number + 1, token });
    }
  };

  if (error) return <Error error={error} />;

  return (
    <>
      {slideItems.length === 0 ? (
        <EmptyList isLoading={loading}/>
      ) : (
        <SwiperList slideItemList={slideItems} onNext={onNext}>
          {slideItems.map((item, index) => {
            const imgSrcList = item.themeProductResponse.map(
              (itemChild) =>
                masterTopikUrl +
                itemChild?.thumbnailPath +
                itemChild?.thumbnailTitle
            );
            return (
              <SwiperSlide key={index}>
                <UserThemeItem
                  key={index}
                  id={item.id}
                  imgSrcList={imgSrcList}
                  title={item.name}
                  tag={item.categoryName}
                  videoCount={item.courseCount}
                  likeCount={item.likeCount}
                  userThumb={item.userProfilePhotoPath}
                  userName={item.userName}
                  isGood={item.isGood}
                />
              </SwiperSlide>
            );
          })}
        </SwiperList>
      )}
    </>
  );
};

export default InterestedTheme;

import React from 'react';
import StyledModal from 'component/Modal/styled/Modal.style';
import StyledModalForm from 'component/form/ModalForm.style';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';
import styled from 'styled-components';

export default function SubmitResultModal() {
  let { handleModal } = React.useContext(ModalContext);

  const changeLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false);
  };

  return (
    <>
      <StyledModal.H2>
        The password has been successfully changed
      </StyledModal.H2>
      <StyledModalForm.DivWrapper>
        <StyledModal.Text>Please log in again.</StyledModal.Text>
        <StyledModalForm.Button onClick={changeLoginModal}>
          Login
        </StyledModalForm.Button>
      </StyledModalForm.DivWrapper>
    </>
  );
}

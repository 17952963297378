import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Styled from "./index.style";
import { actions } from "component/user/state";
import { navDataLogin, navDataLoginPC } from "../navData";
import { MOBILE_APP_USER_AGENT, SiteMapData } from "common/constant";
import { openLink, gotoAILink } from 'common/util/util';
import { ModalContext } from 'component/Modal/modalContext';
import DefaultTextModal from 'component/Modal/DefaultTextModal';
import NavItemWithSub from 'component/layout/Header/Nav/NavItemWithSub';
const MenuAfterLogin = ({isLogin, handleMouseOver, handleMouseOut}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: UserData } = useSelector((state) => state.user);
  let { handleModal } = React.useContext(ModalContext);

  const onLogout = (e) => {
    e.preventDefault();
    document.body.classList.remove("nav-active");

    const userAgent = window.navigator.userAgent;
    const { snsName } = UserData;
    const isSocialLogin = snsName ? true : false;

    if (userAgent === MOBILE_APP_USER_AGENT && isSocialLogin) {
      window.WebInterface?.logoutAndroid(isSocialLogin);
    }

    dispatch(actions.fetchLogout());
    history.push("/");
  };

  const [activeSub, isActiveSub] = useState(true);

  const handleActive = () => {
    isActiveSub(!activeSub);

  };

  const user = useSelector((state) => state.user.data);

  const alertAILink = (e) => {
    e.preventDefault();

    handleModal(
      <DefaultTextModal
        title={'Sorry for the inconvenience. Please use AI SPEAK on the desktop. (iOS 15.6~)'}
        btnType={'primary'}
      />
    );
  }

  const clickAILink = (e) => {
    // alertAILink(e); //오류 시 alert창 활성화
    gotoAILink(e, user, alertAILink);
  }

  return (
    <>
    <Styled.Item className="pc-ver">
     {navDataLoginPC.map((item, index) => (
        <Styled.Item key={index} className={`depth1 depth1-${index+1} ${item.className} login-depth1`}>
          {!item.subList ? (

              <Styled.Link to={item.url} onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}  className="nav_a">{item.name}</Styled.Link>
          ) : (
            <NavItemWithSub item={item} isLogin={isLogin} handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOut} />
          )}
        </Styled.Item>
      ))}
      </Styled.Item>
      {/* <Styled.Item className="pc-ver">
        <Styled.Link to={SiteMapData.course}>Course</Styled.Link>
      </Styled.Item>

      <Styled.Item className="pc-ver">
        <Styled.Link to={SiteMapData.classroom}>Classroom</Styled.Link>
      </Styled.Item>

      <Styled.Item className="pc-ver library">
        <Styled.Link to={SiteMapData.library}>Library</Styled.Link>
      </Styled.Item>

      <Styled.Item className="pc-ver">
        <Styled.Link to={SiteMapData.aiSpeakIntro}>AI Speak</Styled.Link>
      </Styled.Item> */}

      {/* <Styled.Item className="pc-ver">
        <Styled.Link onClick={clickAILink}>AI Speak</Styled.Link>
      </Styled.Item> */}

      {/* 20230210 GNB에서 Alive class 삭제 (B2C 화상수업 종료) */}
      {/* <Styled.Item className="pc-ver alive">
        <Styled.Link to={SiteMapData.aliveClass}>Alive Class</Styled.Link> */}
        {/* <Styled.Link to="" onClick={()=>{openLink('https:' + SiteMapData.externalAliveClass, '_blank'); return false;}}>Alive Class</Styled.Link> */}
      {/* </Styled.Item> */}

      {/* <Styled.Item className="pc-ver">
        <Styled.Link to={SiteMapData.plan}>Plan</Styled.Link>
      </Styled.Item> */}

      <Styled.Item className="mobile-ver">
        {/* <Styled.WithSub
          onClick={handleActive}
          className={activeSub && "active"}
        >
          <Styled.Link to="#none">My MT</Styled.Link>
        </Styled.WithSub> */}

        {/* <Styled.Sub.Container className={activeSub && "active"}> */}
        {navDataLogin.map((item, index) =>
          {
            return (<Styled.Item key={index} className={`depth1 depth1-${index+1} ${item.className} login-depth1`}>
              {!item.subList ? (

                  <Styled.Link to={item.url} onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}  className="nav_a">{item.name}</Styled.Link>
              ) : (
                <NavItemWithSub item={item} isLogin={isLogin} handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOut} />
              )}
            </Styled.Item>
            )
            // if(item.name !== 'Help'){
            //   return (
            //     <Styled.Sub.Link key={index} to={item.url}>
            //         {item.name}
            //     </Styled.Sub.Link>
            //   );
            // }

          })}
        {/* </Styled.Sub.Container> */}
      </Styled.Item>

      {/* <Styled.MobileLogout onClick={onLogout} /> */}
    </>
  );
};

export default MenuAfterLogin;

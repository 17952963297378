import styled from 'styled-components';

const StyledComment = {
  Wrapper: styled.div`
    border-radius: 0.6rem;
    background-color: #191b1f;
  `,
  Container: styled.div`
    padding: 4em 4em 0;
    border-top: 1px solid #3b3e42;
  `,
  H3: styled.h3`
    font-size: 2.2em;
    font-weight: 500;
    margin-bottom: 3rem;
  `,
  Item: styled.div`
    margin-bottom: 2.5em;
    &:not(:last-child) {
      border-bottom: 1px solid #3b3e42;
    }
  `,
  ChildItem: styled.div`
    padding-left: 4em;
  `,
  ToggleChildItem: styled.button.attrs({ type: 'button' })`
    font-size: 1.4em;
    padding-bottom: 2em;
    font-weight: 500;
    color: #485cc7;
    &:hover {
      text-decoration: underline;
    }
    svg {
      margin-right: 5px;
    }
  `,
};

export default StyledComment;

import styled from "styled-components";
import backgroundImg from "assets/images/bookstore_background.jpg";

const StyledBookStorePage = {
  HeaderContainer: styled.div`
    position: relative;
    width: 100%;
    margin-top: 3.5em;
    padding: 15.5em 0;
    background: ${({ backgroundUrl }) =>
      backgroundUrl
        ? `url(${backgroundUrl})no-repeat center center/cover`
        : `url(${backgroundImg})no-repeat center center/cover`};
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.6);
    }
    .content {
      position: relative;
      z-index: 10;
      margin: auto;
      text-align: center;

      > p {
        color: #fff;
        font-size: 4em;
        font-weight: 500;
      }

      div.tag {
        display: inline-block;
        margin-top: 0.5em;
        color: #fff;
        font-size: 1.8em;
        font-weight: 400;
        cursor: pointer;
        > svg {
          display: inline-block;
          margin-left: 0.3em;
        }
      }
    }

    @media (max-width: 767px) {
      padding: 12em 0;

      .content {
        padding: 0 1em;

        > p {
          font-size: 2.8em;
        }
        > span {
          font-size: 1.6em;
        }
      }
    }
  `,
};

export default StyledBookStorePage;

import styled from 'styled-components';
import iconChecked from 'assets/images/ico_leveltest_checked.png';

const Form = {
  Radio: styled.input.attrs({ type: 'radio' })`
    overflow: visible;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0!important;
    outline: none;
  `,
  SelectLearn: styled.div`
    margin: 4.5em auto 12em;
  `,
  LearnRadio: styled.div`
    position: relative;
    display: block;
    margin: 20px auto;
    max-width: 21.25em;
    border: 1px solid #fff;
    border-radius: 6px;
    text-align: center;
    font-size: 1.6em;

    &.checked {
      border-color: #ffb500;

      label {
        color: #ffb500;

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 1em;
          height: 1.5em;
          width: 1.5em;
          background: url(${iconChecked}) no-repeat 0 0 / 100%;
          transform: translateY(-50%);
        }
      }
    }

    label {
      position: relative;
      display: block;
      padding: 0.875em 4em;
    }
  `,
  SelectLevel: styled.div`
    display: flex;
    width: 40em;
    height: 19em;
    margin: 4.5em auto 2em;
    padding-bottom: 1px;
    justify-items: center;
    flex-wrap: wrap;
  `,
  ChooseRadio: styled.div`
    position: relative;
    display: flex;
    width: calc(33.33% + 1px);
    margin: 0 0 -1px -1px;
    background-color: #2b2e35;
    border: 1px solid #1e2024;
    font-size: 2.4em;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:first-child {
      border-radius: 6px 0 0 0;
    }

    &:nth-child(3) {
      border-radius: 0 6px 0 0;
    }

    &:nth-child(4) {
      border-radius: 0 0 0 6px;
    }

    &:last-child {
      border-radius: 0 0 6px 0;
    }

    &.checked {
      background-color: #191b1f;
      border-color: #ffb500;
      z-index: 1;

      label {
        color: #ffb500;
      }
    }

    label {
      position: relative;
      color: #8b8f99;
      font-weight: 600;
      cursor: pointer;
    }
  `,
  Label: styled.label`
    position: relative;
    display: flex;
    width: calc(33.33% + 1px);
    margin: 0 0 -1px -1px;
    background-color: #2b2e35;
    border: 1px solid #1e2024;
    font-size: 2.4em;
    align-items: center;
    justify-content: center;
    cursor: pointer;
      color: #8b8f99;
      font-weight: 600;

    &:first-child {
      border-radius: 6px 0 0 0;
    }

    &:nth-child(3) {
      border-radius: 0 6px 0 0;
    }

    &:nth-child(4) {
      border-radius: 0 0 0 6px;
    }

    &:last-child {
      border-radius: 0 0 6px 0;
    }

    &.checked {
      background-color: #191b1f;
      border-color: #ffb500;
      z-index: 1;
      color: #ffb500;
    }
  `,
};

export default Form;

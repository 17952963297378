import { useState, useEffect } from 'react';

const useForm = (initialState, submit, validate) => {
  const [formState, setFormState] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [checkSubmit, setCheckSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target || e;
    setFormState({
      ...formState,
      [name]: value,
    });
  };
  const handleClick = (state, data) => {
    setFormState({
      ...formState,
      [state]: data,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(formState));
    setCheckSubmit(true);
  };

  useEffect(() => {
    if (checkSubmit && Object.keys(errors).length === 0) {
      submit();
    }
  }, [errors, submit, checkSubmit]);

  return {
    handleChange,
    handleSubmit,
    handleClick,
    setFormState,
    formState,
    errors,
  };
};

export default useForm;

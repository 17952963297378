import React from 'react';

export const termsOfPrivacyKo = () => (
  <div class='policy'>
    <h3 class='policy-title'>개인정보처리방침</h3>
    <p>
      ㈜비상교육 마스터토픽 (이하 ‘마스터토픽’)는 이용자의 개인 정보를 소중하게
      생각하고, 이용자의 개인정보를 보호하기 위하여 항상 최선을 다하고 있습니다.
      마스터토픽은 정보통신서비스제공자가 준수 하여야 하는 「정보통신망 이용촉진
      및 정보보호 등에 관한 법률」, 「개인정보보호법」, 「통신비밀보호법」등
      관련 법령상의 개인정보보호 규정을 준수하고 있습니다.
    </p>
    <p>
      마스터토픽은 이용자의 권리를 적극적으로 보장하며, 개인정보의
      처리(수집·이용·제공·파기), 보호조치, 고충 처리에 대한 내용을 다음과 같이
      개인정보 처리방침을 통하여 알려드립니다.
    </p>
    <ol class='policy-num'>
      <li>1. 개인정보의 수집</li>
      <li>2. 수집한 개인정보 이용</li>
      <li>3. 개인정보의 제공 및 위탁</li>
      <li>4. 개인정보의 파기</li>
      <li>5. 개인정보 전담조직 운영</li>
      <li>6. 이용자·법정대리인의 권리행사방법 및 의무</li>
      <li>7. 개인정보의 안전성 확보조치</li>
      <li>8. 고지 의무</li>
    </ol>
    <div class='policy-section'>
      <h4 class='policy-tit'>1. 개인정보의 수집</h4>
      <ul class='policy-num'>
        <li>
          ① 개인정보 수집 원칙
          <ul class='policy-bu'>
            <li>
              - 마스터토픽은 서비스 제공에 필요한 최소한의 개인정보만 수집하도록
              필수항목과 선택항목으로 구분하여 수집하며, 수집 시 반드시 사전에
              이용자의 동의를 구하도록 하고 있습니다.
              <dl class='policy-dl'>
                <dt>※ 필수항목과 선택항목</dt>
                <dd>
                  <li>
                    필수항목 : 서비스의 본질적 기능을 수행하기 위해 필요한 정보
                  </li>
                  <li>
                    선택항목 : 부가 가치를 제공하기 위해 추가로 수집하는
                    정보로써, 입력하지 않아도 서비스 이용의 제한이 없는 정보
                  </li>
                </dd>
              </dl>
            </li>
            <li>
              - 마스터토픽은 법령에 따른 개인정보 보유. 이용기간 또는
              이용자로부터 개인정보를 수집 시에 동의 받은 개인정보 보유.
              이용기간 내에서 개인정보를 처리․보유합니다.
            </li>
            <li>
              - 마스터토픽은 사상·신념, 노동조합·정당의 가입·탈퇴, 정치적 견해,
              건강, 성생활 등에 관한 정보, 그 밖에 이용자의 사생활을 현저히
              침해할 우려가 있는 민감한 개인정보를 수집하지 않습니다.
            </li>
          </ul>
        </li>
        <li>
          ② 개인정보 수집 방법
          <ul class='policy-bu'>
            <li>
              - 마스터토픽은 홈페이지, 서면양식, 상담게시판, 전화, 팩스,
              이벤트응모, 배송 요청 등의 방법으로 이용자의 개인정보를
              수집합니다.
            </li>
            <li>
              - 기기정보, 로그 분석 프로그램을 통한 생성정보는 PC웹, 모바일
              웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          ③ 개인정보 수집 목적 및 항목
          <ul class='policy-bu'>
            <li>▶ 회원가입 및 주문 등 필요시점에서 수집하는 정보</li>
            <li>
              <table class='policy-tbl type2'>
                <thead>
                  <tr>
                    <th colspan='2'>수집 목적</th>
                    <th>수집하는 개인정보 항목</th>
                    <th>보유 기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>홈페이지 회원 가입 및 관리</td>
                    <td>
                      회원가입/회원관리 불량회원의 부정이용 방지와 비인가 사용
                      방지, 중복가입방지
                    </td>
                    <td>
                      [필수] 영문 이름, 영문 성, 아이디(이메일), 비밀번호, 국적,
                      생년월일 [선택] 페이스북 아이디
                    </td>
                    <td rowspan='6'>
                      회원탈퇴 후 파기됩니다. 다만 관계법령에 의해 보존할 경우
                      그 의무기간 동안 별도 보관되며 불, 편법 행위의 방지 및
                      대응의 목적으로 6개월간 보관됩니다. (계좌 입금자 환불처리
                      : 고객에게 환불완료 확인 및 환불처리 오류 정정을 위해
                      6개월간 보관됩니다.)
                    </td>
                  </tr>
                  <tr>
                    <td>계정찾기</td>
                    <td>[필수] 이름, 생년월일</td>
                  </tr>
                  <tr>
                    <td rowspan='3'>
                      서비스제공(상품구매 등 서비스 이용시 필수적인 정보)
                    </td>
                    <td>교재배송</td>
                    <td>
                      [필수] 배송지 정보 (이름, 연락처(전화번호), 주소정보,
                      주문번호
                    </td>
                  </tr>
                  <tr>
                    <td>구매 및 요금 결제</td>
                    <td>
                      [필수] 아이디(이메일), 한국 휴대폰 번호, 통신사, 접속 IP,
                      상품거래 정보 [항목 중 1개 필수선택] 한국 카드정보,
                      계좌정보, 전자지갑 정보
                    </td>
                  </tr>
                  <tr>
                    <td>계좌 입금자 환불처리</td>
                    <td>[필수] 계좌번호, 은행이름, 예금 주명, 연락처</td>
                  </tr>
                  <tr>
                    <td>(선택) 마케팅 및 광고에 활용</td>
                    <td>이벤트 정보 및 뉴스레터, 할인혜텍 알림보</td>
                    <td>[필수] 이메일, 한국 휴대폰 번호</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              ▶ 이용자 동의 후 추가로 수집하는 정보 개별 서비스 이용, 이벤트
              응모 및 경품 신청 과정에서 해당 서비스 이용자에 한해 추가 개인정보
              수집이 발생할 수 있습니다. 개인정보 수집 시 이용자에게 수집하는
              개인정보의 항목, 이용목적, 보관기간을 안내하고 동의를 받으며,
              동의를 거부할 권리가 있다는 사실 및 동의 거부에 따른 불이익이 있는
              경우에는 그 불이익의 내용을 함께 안내합니다.
            </li>
            <li>
              <table class='policy-tbl type2'>
                <thead>
                  <tr>
                    <th>목적</th>
                    <th>수집항목</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>제휴문의</td>
                    <td>[필수] 이름, 이메일, 휴대폰번호</td>
                  </tr>
                  <tr>
                    <td>
                      이벤트 및 프로모션 (이벤트 정보 및 뉴스레터, 할인혜택
                      알림)
                    </td>
                    <td>
                      이메일, 한국 휴대폰 번호(이벤트에 따라 수집하는 개인정보
                      항목이 다를 수 있습니다.)
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>▶ 서비스 이용 과정에서 생성되는 정보</li>
            <li>
              <table class='policy-tbl type2'>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>수집하는 개인정보 항목</th>
                    <th>보유 기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>회원식별 및 분쟁조정 등</td>
                    <td>
                      수강기록, 결제기록, 회원가입일, 최근 접속 일시, 접속IP,
                      국가IP, 최종선택 언어.
                    </td>
                    <td rowspan='2'>
                      회원탈퇴 후 파기됩니다. 다만 관계법령에 의해 보존할 경우
                      그 의무 기간 동안 별도 보관되며 불, 편법 행위의 방지 및
                      대응의 목적으로 6개월간 보관됩니다. <br />※ 쿠키의 경우,
                      브라우저 종료 시 또는 로그아웃 시 만료
                    </td>
                  </tr>
                  <tr>
                    <td>서비스 이용 통계 등</td>
                    <td>
                      사용자 아이디, 영문 이름, 영문 성, 성별, 생일, 이메일,
                      국가코드, 매칭 코드, 휴면회원유무, 페이스 북 회원유무,
                      사이트 선택 언어 <br />※ 모바일 서비스 이용시 모바일
                      기기정보(고유기기식별정보, OS버전)
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>
        </li>
        <li>
          ④ 쿠키를 설치, 운영하는 이용자의 거부권
          <ul class='policy-bu'>
            <li>
              - 쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 컴퓨터
              브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터에
              저장됩니다.
            </li>
            <li>
              - 쿠키의 사용 목적
              <ul class='policy-bu'>
                <li>
                  ㆍ이용자가 다시 웹사이트 방문 시 이용자가 설정한 서비스 이용
                  환경을 유지하여 보다 편리한 인터넷 서비스 이용 제공
                </li>
                <li>
                  ㆍ방문 서비스 정보, 접속 시간 및 빈도, 이용 과정 시 생성 또는
                  입력 정보를 분석하여 이용자 취향과 관심에 특화된 서비스 및
                  광고 제공
                </li>
              </ul>
            </li>
            <li>
              - 이용자는 쿠키 제공에 대한 선택권을 가지고 있으며, 웹
              브라우저에서 옵션 설정을 통해 쿠키 허용 / 쿠키 저장 시 확인 / 모든
              쿠키 저장 거부를 선택할 수 있습니다.
              <ul class='policy-bu'>
                <li>
                  ㆍInternet Explorer : 웹 브라우저 상단의 도구 메뉴 {'>'}
                  인터넷 옵션 {'>'} 개인정보 {'>'} 설정
                </li>
                <li>
                  ㆍChrome : 웹 브라우저 우측의 설정 메뉴 {'>'} 화면 하단의 고급
                  설정 표시 {'>'} 개인정보의 콘텐츠 설정 버튼
                  {'>'} 쿠키
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-tit'>2. 수집한 개인정보 이용</h4>
      <p>
        마스터토픽은 다음 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
        개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
        변경되는 경우에는 사전에 동의를 받는 등 필요한 조치를 이행할 예정입니다.
      </p>
      <table class='policy-tbl type2'>
        <thead>
          <tr>
            <th>구분</th>
            <th>이용 목적</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>서비스 회원 가입 및 관리</td>
            <td>
              회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증,
              회원자격 유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스
              부정이용 방지, 고충처리(문의내용 확인, 신원 확인, 사실조사를 위한
              연락 및 통지, 처리결과 통보 등), 각종 고지․통지 등을 목적으로
              개인정보를 처리합니다.
            </td>
          </tr>
          <tr>
            <td>재화 또는 서비스 제공</td>
            <td>
              물품배송, 서비스 제공, 계약서․청구서 발송, 콘텐츠 제공, 맞춤서비스
              제공, 본인인증, 연령인증, 요금결제․정산, 채권추심 등을 목적으로
              개인정보를 처리합니다.
            </td>
          </tr>
          <tr>
            <td>마케팅 및 광고 활용</td>
            <td>
              이벤트 정보 및 뉴스레터, 할인혜택 알림, 경품발송 등을 목적으로
              개인정보를 처리합니다.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class='policy-section'>
      <h4 class='policy-tit'>3. 개인정보의 제공 및 위탁</h4>
      <ol class='policy-num'>
        <li>
          ① 개인정보의 제3자 제공
          <ul class='policy-bu'>
            <li>
              - 마스터토픽은 원칙적으로 이용자의 개인정보를 ‘2. 수집한 개인정보
              이용’내에서 명시한 범위 내에서만 처리하며, 본래의 범위를 초과하여
              처리하거나 제3자에게 제공하지 않습니다.
            </li>
            <li>
              - 더 나은 서비스 제공을 위하여 개인정보를 제3자에게 제공하거나
              공유하게 되는 경우에는 제공받는 자, 제공하는 개인정보 항목,
              제공받는 자의 개인정보 이용목적, 제공받는 자의 보유.이용기간을
              명시하고 사전에 동의를 구하는 절차를 거치도록 하며, 동의하지 않는
              경우에는 제3자에게 제공 및 공유하지 않습니다.
            </li>
            <li>
              - 단, 법률의 특별한 규정 등 개인정보 보호법 제17조에 해당하는
              경우에는 개인정보를 제3자에게 제공합니다.
            </li>
          </ul>
        </li>
        <li>
          ② 개인정보처리의 위탁
          <ul class='policy-bu'>
            <li>
              - 마스터토픽은 이용자에게 더 나은 서비스를 제공하기 위하여 다음과
              같은 업무를 위탁하고 있습니다.
            </li>
            <li>
              <table class='policy-tbl type2'>
                <thead>
                  <tr>
                    <th>수탁 업체</th>
                    <th>위탁업무 내용</th>
                    <th>개인정보의 보유 및 이용기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>㈜한진</td>
                    <td>물품 배송</td>
                    <td rowspan='2'>
                      이용목적 달성 시 파기 (SMS 발송의 경우 정산 및 분쟁 대응의
                      목적으로 3개월간 보관 후 파기됩니다.)
                    </td>
                  </tr>
                  <tr>
                    <td>㈜월드해운항공</td>
                    <td>물품배송 (해외배송)</td>
                  </tr>
                  <tr>
                    <td>(주)케이지이니시스</td>
                    <td>결제 시 본인인증 및 거래</td>
                    <td>회원 탈퇴 시 혹은 위탁계약 종료시까지</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              - 마스터토픽은 위탁계약 체결 시 개인정보 보호법 제25조에 따라
              위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치,
              재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한
              사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게
              처리하는지를 감독하고 있습니다.
            </li>
            <li>
              - 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본
              개인정보 처리방침을 통하여 공개하도록 하겠습니다.
            </li>
          </ul>
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-tit'>4. 개인정보의 파기</h4>
      <ol class='policy-num'>
        <li>
          ① 개인정보의 파기
          <ul class='policy-bu'>
            <li>
              - 마스터토픽은 이용자의 개인정보 수집/이용 목적이 달성된 경우에는
              지체 없이 해당 개인정보를 파기합니다. 단, 법령에서 정보 보관
              의무를 부과하는 경우와 이용자에게 보관기간에 대해 별도 동의를 얻은
              경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.
            </li>
            <li>
              - 다음 관계법령에 의해 보관해야 하는 정보는 법령에 명시된 기간
              동안 보관 후 파기합니다.
            </li>
            <li>
              <table class='policy-tbl type2'>
                <thead>
                  <tr>
                    <th>관계법령</th>
                    <th>보존항목</th>
                    <th>보존기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan='3'>
                      전자상거래 등에서의 소비자보호에 관한 법률 제6조
                    </td>
                    <td>계약 또는 청약철회 등에 관한 기록</td>
                    <td>5년</td>
                  </tr>
                  <tr>
                    <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                    <td>5년</td>
                  </tr>
                  <tr>
                    <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                    <td>3년</td>
                  </tr>
                  <tr>
                    <td>통신비밀보호법</td>
                    <td>인터넷 로그기록자료, 접속지 추적자료</td>
                    <td>3개월</td>
                  </tr>
                  <tr>
                    <td>정보통신망법 제50조</td>
                    <td>
                      e메일, 문자와 관련된 개인정보 <br />※ 탈퇴회원 및
                      휴면회원의 발송이력은 일반 이용자의 개인정보와 별도로
                      보관되며, 다른 목적으로 이용하지 않습니다.
                    </td>
                    <td>1년</td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>
        </li>
        <li>
          ② 개인정보 유효기간제
          <ul class='policy-bu'>
            <li>
              - 마스터토픽은 장기간 서비스 미 이용자의 개인정보보호를 위하여
              휴면회원(최근 12개월 동안 서비스를 이용하지 아니한 회원 또는 직접
              유효기간을 선택한 회원)의 개인정보를 별도의 DB에 분리보관하고
              있습니다
            </li>
            <li>
              - 미 이용의 기간은 로그인, 상담원 접촉일자 등으로 산정합니다.
            </li>
            <li>
              - 마스터토픽은 미 이용자 개인정보 분리/저장시점 도래 30일 이전에
              이메일 등을 통해 해당 이용자에게 관련내용을 공지합니다.
            </li>
          </ul>
        </li>
        <li>
          ③ 개인정보 파기 절차 및 방법
          <ul class='policy-bu'>
            <li>- 종이에 출력된 정보: 분쇄기로 분쇄하거나 소각</li>
            <li>
              - 전자적 파일형태의 정보: 기록을 재생할 수 없는 기술적 방법을
              사용하여 삭제
            </li>
          </ul>
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-tit'>5. 개인정보처리 업무의 위탁</h4>
      <ol class='policy-num'>
        <li>
          ① 개인정보 보호책임자 및 담당부서
          <ul class='policy-bu'>
            <li>
              - 마스터토픽은 이용자의 개인정보 처리에 관한 업무를 총괄해서
              책임지고, 이용자의 궁금증 해결, 불만처리를 위해 개인정보
              보호책임자와 담당부서를 지정하여 운영하고 있습니다.
            </li>
            <li>
              <table class='policy-tbl'>
                <tbody>
                  <tr>
                    <td rowspan='2'>개인정보보호책임자 (CPO)</td>
                    <td>소속 : 경영지원 Core</td>
                  </tr>
                  <tr>
                    <td>이름 : 이우상</td>
                  </tr>
                  <tr>
                    <td>개인정보 담당부서</td>
                    <td>정보보안 Cell</td>
                  </tr>
                  <tr>
                    <td rowspan='2'>문의</td>
                    <td>전화 : 1544-0554</td>
                  </tr>
                  <tr>
                    <td>이메일 : privacy@visang.com</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              - 이용자는 마스터토픽의 서비스를 이용하면서 발생하는 모든 개인정보
              보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
              보호책임자 및 담당부서로 하실 수 있습니다.
            </li>
          </ul>
        </li>
        <li>
          ② 권익침해 구제방법
          <ul class='policy-bu'>
            <li>
              - 개인정보 침해에 대한 피해구제, 신고, 상담이 필요하신 경우에는
              아래 기관에 문의하셔서 도움 받으실 수 있습니다.
            </li>
            <li>- 아래 기관은 마스터토픽과는 별개의 기관입니다.</li>
            <li>
              <table class='policy-tbl type2'>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>홈페이지</th>
                    <th>전화</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>개인정보 침해신고센터 (한국인터넷진흥원 운영)</td>
                    <td>privacy.kisa.or.kr</td>
                    <td>(국번없이) 118</td>
                  </tr>
                  <tr>
                    <td>개인정보 분쟁조정위원회</td>
                    <td>www.kopico.go.kr</td>
                    <td>(국번없이) 1833-6972</td>
                  </tr>
                  <tr>
                    <td>대검찰청 사이버범죄수사단</td>
                    <td>www.spo.go.kr</td>
                    <td>(국번없이) 1301</td>
                  </tr>
                  <tr>
                    <td>경찰청 사이버안전국</td>
                    <td>cyberbureau.police.go.kr</td>
                    <td>(국번없이) 182</td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ul>
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-tit'>6. 이용자·법정대리인의 권리행사방법 및 의무</h4>
      <ol class='policy-num'>
        <li>
          ① 권리행사방법
          <ul class='policy-bu'>
            <li>
              - 이용자는 마스터토픽에 대해 언제든지 다음 방법으로 개인정보 보호
              관련 권리를 행사할 수 있습니다.
            </li>
            <li>
              <table class='policy-tbl type2'>
                <thead>
                  <tr>
                    <th>구분</th>
                    <th>요청 방법</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>개인정보 열람, 정정, 삭제, 처리정정 등의 요구</td>
                    <td>
                      ‘5. 개인정보 전담조직 운영‘ 내에서 안내된 부서에 대해
                      서면, 전화, 전자우편 등을 통하여 문의
                    </td>
                  </tr>
                  <tr>
                    <td>개인정보 조회/수정</td>
                    <td>‘나의 정보 수정’ 메뉴를 통해 정보 조회 및 수정</td>
                  </tr>
                  <tr>
                    <td>동의 철회</td>
                    <td>
                      ‘나의 정보 수정’ 메뉴의 ‘개인정보 광고 및 활용동의’에서
                      거부 가능
                    </td>
                  </tr>
                  <tr>
                    <td>회원 탈퇴</td>
                    <td>‘나의 정보 수정’의 회원탈퇴 기능 이용</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>
              - 개인정보의 정확성을 위해 이용자의 개인정보 정정/삭제 요구가 있을
              경우 해당 요구사항 처리 완료 시까지 당해 개인정보를 이용하거나
              제공하지 않습니다. 단, 잘못된 개인정보를 이미 제3자에게 제공한
              경우 제공받은 자에게 지체 없이 사실을 알려 수정될 수 있도록
              하겠습니다.
            </li>
            <li>
              - 이용자의 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등
              대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙
              별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
            </li>
            <li>
              - 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항,
              제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.
            </li>
            <li>
              - 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
              대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.
            </li>
            <li>
              - 마스터토픽은 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구,
              처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
              대리인인지를 확인합니다.
            </li>
          </ul>
        </li>
        <li>
          ② 이용자의 의무 <br />
          이용자는 개인정보 보호법 등 관계법령을 위반하여 마스터토픽이 처리하고
          있는 정보주체 본인이나 타인의 개인정보 및 사생활을 침해하여서는 아니
          됩니다.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-tit'>7. 개인정보의 안전성 확보조치</h4>
      <p>
        마스터토픽은 이용자의 개인정보를 소중히 여기며, 개인정보를 처리함에
        있어서 다음과 같은 노력을 다하고 있습니다.
      </p>
      <ol class='policy-num'>
        <li>
          ① 내부관리계획의 수립
          <p>
            개인정보의 안전한 처리를 위한 기준으로 내부관리계획을 수립 및
            시행하고 있습니다
          </p>
        </li>
        <li>
          ② 개인정보 취급자 최소화
          <p>
            개인정보를 처리하는 직원을 최소화 하며, 해당 직원들의 PC는 외부
            인터넷망과 내부망을 분리하여 개인정보 유출 가능성을 줄이고 있습니다.
            또한 개인정보를 처리하는 데이터베이스와 개인정보처리시스템에 대한
            접근 통제 기준을 체계적으로 마련하고 지속적인 감사를 시행하고
            있습니다.
          </p>
        </li>
        <li>
          ③ 정기 교육
          <p>
            개인정보 취급자들을 대상으로 정기 교육을 실시하고, 전사 직원을
            대상으로 개인정보 이슈 사항을 전파하여 개인정보의 중요성을 강조하고
            있습니다.
          </p>
        </li>
        <li>
          ④ 해킹이나 바이러스로부터 보호
          <p>
            시스템은 외부로부터 접근이 통제된 구역에 설치하여 개인정보의
            유출이나 훼손으로부터 보호합니다. 또한, 개인정보 훼손에 대비하여
            정기적 백업을 수행하고 백신프로그램을 이용하여 자료의 유출 및 손상을
            방지하고 있습니다.
          </p>
        </li>
        <li>
          ⑤ 개인정보의 암호화
          <p>
            이용자의 개인정보 전송 시 암호화된 통신구간을 이용하고, 비밀번호 등
            중요정보는 안전한 암호화 알고리즘을 사용하여 암호화 합니다.
          </p>
        </li>
        <li>
          ⑥ 물리적 통제
          <p>
            개인정보 처리와 관련된 시스템들은 통제구역에 위치하며, 출입을
            통제합니다. 또한 개인정보가 포함된 서류, 보조저장매체는 잠금장치가
            있는 안전한 장소에 보관합니다.
          </p>
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-tit'>8. 고지 의무</h4>
      <ul class='policy-bu'>
        <li>- 이 개인정보 처리방침은 2019. 12. 30부터 적용됩니다.</li>
        <li>
          - 개인정보 처리방침 내용에 대한 추가, 삭제 및 수정사항이 있을 경우에는
          시행일 최소 7일전에 공지사항 등을 통해 안내 드리겠습니다. 이용자의
          권리 또는 의무에 중요한 내용이 변경될 경우에는 최소 30일 전에 안내
          드리겠습니다.
        </li>
        <li>- 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.</li>
      </ul>
    </div>
  </div>
);

import React from 'react';
import PropTypes from 'prop-types';
import Styled from './index.style';
import ThumbnailDefault from 'assets/images/thumb_default.jpg';

/**
 *
 * @param {*} param
 * @param {string | object} param.src 미입력 시, 기본 썸네일 노출
 * @param {number=} param.percent 미입력 시, 재생 시점 bar 미노출
 * @param {string=} param.className
 */
export default function VideoThumb({
  src = ThumbnailDefault,
  percent = 0,
  alt = 'Thumbnail',
  className,
}) {
  return (
    <Styled.Wrapper
      className={className ? `video-thumb ${className}` : 'video-thumb'}
      percent={percent}
    >
      <Styled.Img src={src} alt={alt} onError={ThumbnailDefault} />
    </Styled.Wrapper>
  );
}

VideoThumb.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  percent: PropTypes.number,
  alt: PropTypes.string,
  className: PropTypes.string,
};

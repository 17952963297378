import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logoTutoring from 'assets/images/logo_tutoring.png';
import logoTutoringLarge from 'assets/images/logo_tutoring01_large.png';
import { AiOutlineUser } from 'react-icons/ai';

const StyledRightMenu = {
  Wrapper: styled.div`
    float: right;
  `,
  LoginButtonWrap: styled.span`
    float: left;
    margin-top: 2.5em;
    height: 4em;

  `,
  LoginButton: styled.button.attrs({
    type: 'button',
  })`
    height: 100%;
    padding: 0 1em;
    font-size: 1.6em;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #b9b9b9;
    border-radius: 2em;
    color: #fff;
    cursor: pointer;
  `,
  TutoringLink: styled(Link).attrs({ children: 'Tutoring' })`
    float: left;
    width: 11em;
    height: 4em;
    margin: 2.5em 3em 0 0;
    line-height: 99;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: right center;
    background-image: url(${logoTutoring});
    background-size: contain;
    @media (max-width: 1024px) {
      background-image: url(${logoTutoringLarge});

    }
  `,
  UserState: styled.div`
    float:left;
    margin:3em 1.8em 0 0;
  `,
  StateFreeButton: styled(Link)`
    display: block;
    padding: 0.2em 0.8em;
    font-weight: 400;
    color: #fff;
    background-color: #f87c58;
    border:1px #f87c58 solid;
    border-radius:0.3em;
    font-size:1.4em;
    &:hover {
      background-color:transparent;
      color:#f87c58;
    }
  `,
};

export default StyledRightMenu;

import React, { useEffect, useRef, useState, memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useSelector } from "react-redux";
import { gotoAILink } from 'common/util/util';
import { ModalContext } from 'component/Modal/modalContext';
import DefaultTextModal from 'component/Modal/DefaultTextModal';

import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';
import Img from 'component/atoms/Img';
import Plyr from 'plyr-react';

import Style from './styled/index.style';
import sectionImg from 'assets/images/aispeak_section.png';
import sectionImg2 from 'assets/images/aispeak_section2.png';
import sectionImg3 from 'assets/images/aispeak_section3.png';
import sectionImg4_1 from 'assets/images/aispeak_section4_1.png';
import sectionImg4_2 from 'assets/images/aispeak_section4_2.png';

import Login from 'component/user/Login';
import useLoginStatus from 'common/hook/useLoginStatus';
import { useLocation } from 'react-router-dom';

const AISpeakPage = () => {
  const { t } = useTranslation('aispeakPage');

  // Sticky
  const [hHeight, sethHeight] = useState(0); //header bottom
  const [winScrollY, setWinScrollY] = useState(window.scrollY);
  const fixedBtn = useRef();
  const stickyStart = useRef();
  const [stickyOffset, setStickyOffset] = useState(0); //stickyStart offsetTop

  let { handleModal } = React.useContext(ModalContext);
  const user = useSelector((state) => state.user.data);

  const isLogin = useLoginStatus();
  const location = useLocation();

  const openLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false, 420, location.pathname);
  };


  const alertAILink = (e) => {
    e.preventDefault();

    handleModal(
      <DefaultTextModal
        title={'Sorry for the inconvenience. Please use AI SPEAK on the desktop. (iOS 15.6~)'}
        btnType={'primary'}
      />
    );
  }

  const clickAILink = (e) => {
    // alertAILink(e); //오류 시 alert창 활성화
    if(isLogin){
      gotoAILink(e, user, alertAILink);
    } else {
      openLoginModal(e)
    }

  }

  useEffect(() => {
    /* Mount 된 직후 초기값 셋팅 */
    onResize();

    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onResize);
    };
  }, []);

  const onResize = () => {
    const headerBtm = document.querySelector('header') ? document.querySelector('header').getBoundingClientRect().bottom : 0; //header bottom
    sethHeight(headerBtm);
    setWinScrollY(window.scrollY);
    setStickyOffset(stickyStart.current.offsetTop);
  }

  useEffect(() => {
    if (winScrollY >= (stickyOffset + hHeight)) {
      fixedBtn.current.classList.add('fixed');
    } else {
      fixedBtn.current.classList.remove('fixed');
    }
  }, [hHeight, stickyOffset, winScrollY]);

  return (
    <>
      <ContentsWrapper>
        <PageHeader title={t('title')} text={t('subtitle.line1')} text2={t('subtitle.line2')}/>

        <Style.VideoContainer>

          <Player/>

          <Style.ButtonWrap ref={stickyStart}>
            <Style.ArrowButton onClick={clickAILink}>{t('button')}</Style.ArrowButton>
          </Style.ButtonWrap>
        </Style.VideoContainer>

        <Style.ContentContainer className='left-img' >
          <Style.ContentBox>
            <h3 className='title'>
              <Trans t={t}>section.title</Trans>
            </h3>
            <div className='desc'>
              <Trans t={t}>section.desc</Trans>
            </div>
          </Style.ContentBox>
          <Style.ContentBox className='center'>
            <Img src={sectionImg} alt='' />
          </Style.ContentBox>
        </Style.ContentContainer>

        <Style.ContentContainer className='right-img'>
          <Style.ContentBox>
            <h3 className='title'>
              <Trans t={t}>section2.title</Trans>
            </h3>
            <div className='desc'>
              <Trans t={t}>section2.desc</Trans>
            </div>
          </Style.ContentBox>
          <Style.ContentBox className='center'>
            <Img src={sectionImg2} alt='' />
          </Style.ContentBox>
        </Style.ContentContainer>

        <Style.ContentContainer className='left-img'>
          <Style.ContentBox>
            <h3 className='title'>
              <Trans t={t}>section3.title</Trans>
            </h3>
            <div className='desc'>
              <Trans t={t}>section3.desc</Trans>
            </div>
          </Style.ContentBox>
          <Style.ContentBox className='center'>
            <Img src={sectionImg3} alt='' />
          </Style.ContentBox>
        </Style.ContentContainer>

        <Style.BannerTitle>
          <h3 className='title'>
            <Trans t={t}>section4.title</Trans>
          </h3>
          <div className='desc'>
            <Trans t={t}>section4.desc</Trans>
          </div>
        </Style.BannerTitle>
        <Style.BannerContainer>
          <Style.Banner Bg={sectionImg4_1}>
            <a onClick={clickAILink} target='_blank'>
              <h3 className='title'>
                <Trans t={t}>banner</Trans>
              </h3>
              <Style.IcoArrow>Practice Now</Style.IcoArrow>
            </a>
          </Style.Banner>
          <Style.Banner Bg={sectionImg4_2}>
            <a onClick={clickAILink} target='_blank'>
              <h3 className='title'>
                <Trans t={t}>banner2</Trans>
              </h3>
              <Style.IcoArrow>Practice Now</Style.IcoArrow>
            </a>
          </Style.Banner>
        </Style.BannerContainer>
      </ContentsWrapper>

      <Style.FloatingButton ref={fixedBtn}>
        <ContentsWrapper>
          <Style.ArrowButton onClick={clickAILink}>{t('button')}</Style.ArrowButton>
        </ContentsWrapper>
      </Style.FloatingButton>
    </>
  );
};
export default AISpeakPage;


const Player = memo(() => {
    const videoRef = useRef(null);
    return <Plyr
            ref={videoRef}
            source={{
              type: 'video',
              sources: [
                {
                  src: 'imJ45mxoq8o',
                  provider: 'youtube',
                },
              ],
            }}
          />
  }
);
import styled from "styled-components";
import { Swiper } from 'swiper/react';
import visualImg from 'assets/images/event/220728/visual.jpg';
import contImg from 'assets/images/event/220728/cont.jpg';
import contImg2 from 'assets/images/event/220728/cont2.jpg';
import contImg3 from 'assets/images/event/220728/cont3.jpg';
import contImg4 from 'assets/images/event/220728/cont4.jpg';
import reviewBgImg from 'assets/images/event/220728/bg_review.jpg';
import arrowViewIcon from 'assets/images/event/220728/ico_arrow.png';
import cardIcon from 'assets/images/event/220728/ico_card.png';
import clockIcon from 'assets/images/event/220728/ico_clock.png';
import swiperNavIcon from 'assets/images/arrow2_left_white.svg';


const StyledEventPage = {
  Swiper: styled(Swiper)`
    position:static;
    .swiper-button-prev,
    .swiper-button-next {
      display:inline-block;
      position: absolute;
      top: 50%;
      z-index:10;
      background: url(${swiperNavIcon}) 50% 50% no-repeat;
      background-size: 100%;
      width: 2.5em;
      height: 3em;
      margin-top: -1.5em;
      cursor: pointer;
    }
    .swiper-button-prev {
      left: -2.7em;
    }
    .swiper-button-next {
      right: -2.7em;
      transform: rotate(180deg);
    }
  `,

	EventWrap: styled.div`
		font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    text-align:center;
    color:#555;
    letter-spacing: -0.035em;

		.blind {
			overflow:hidden;
			position: absolute;
			clip:rect(0 0 0 0);
			width:1px;
			height:1px;
			margin:-1px;
		}

    .inner {
      position:relative;
			max-width: 750px;
      margin: auto;
    }

    .visual {
      position:relative;
      background:#ffc809;
      padding-bottom: 4em;
			.inner {
				position:relative;
        background-image:url(${visualImg});
        background-position: 50% 0;
        background-repeat:no-repeat;
        background-size: 100%;
        padding-top: 1090px;
				z-index:2;
      }
			.btnWrap {
        a {
					display:block;
          width: 515px;
          margin: 0 auto;
				}
			}
    }

		.price {
			background:#3f267d;
			.inner {
				background:url(${contImg}) 50% 0 no-repeat;
        background-size: 100%;
        padding-top: 1512px;
			}
      .btnWrap {
        padding: 2.5em 0 4em;
        a {
					display:block;
				}
			}
		}

		.review {
			background:#6935ee;
      padding-bottom: 4em;
			.inner {
				background:url(${contImg2}) 50% 0 no-repeat;
        background-size: 100%;
        padding-top: 381px;
			}
      .swiperWrap {
        position: relative;
        width:687px;
        height:800px;
        background:url(${reviewBgImg}) 50% 0 no-repeat;
        background-size: 100%;
        margin: auto;
        padding: 51px 15px 0;
        .swiper-pagination-bullets {
          bottom: 0;
        }
        .swiper-pagination-bullet {
          width: 0.7em;
          height: 0.7em;
          background:#fff;
          opacity: 0.3;
        }
        .swiper-pagination-bullet-active {
          opacity: 0.6;
        }
      }
		}

		.classInfo {
			background:#f8f2dc;
      padding-bottom: 4em;
			.inner {
				height: 1519px;
        background:url(${contImg3}) 50% 0 no-repeat;
        background-size: 100%;
        padding-top: 381px;
			}
      ul {
        margin: 0 28px;
        position: relative;
        li {
          display:flex;
          justify-content: space-between;
          height: 349px;
          padding-top: 242px;
          > div {
            flex: 0 0 49%;
            padding: 0 2.4%;
          }
          a {
            display: block;
            background:#1b189d;
            color:#fff;
            font-size:2.2rem;
            padding: 0.66em 1.64em;
            border-radius:2em;
          }
        }
      }
		}

		.gift {
			background:#ffeb8b;
      padding-bottom: 4em;
			.inner {
				background:url(${contImg4}) 50% 0 no-repeat;
        background-size: 100%;
        padding-top: 904px;
        p {
          font-size:2.7rem;
          color:#4d3d06;
          line-height: 1.5;
          margin-bottom: 1.2em;
          span {
            color:#6b5712;
            font-size:2.3rem;
            line-height: 1.2;
          }
        }
        a {
          background: url(${arrowViewIcon}) 100% 50% no-repeat;
          background-size: 0.9em auto;
          padding-right: 1.3em;
          border-bottom: 1px #6b5712 solid;
          color:#6b5712;
          font-size:2.3rem;
          line-height: 1.2;
        }
			}
		}

    .banner {
      display:flex;
      flex-wrap: nowrap;
      justify-content: center;
      background: #ffc809;
      padding: 1.8em 0;
      font-size: 2.2rem;
      color:#1f1f1f;
      strong {
        display:inline-block;
        padding: 0.7em 0;
      }
      a {
        display:inline-block;
        background:#ff6724;
        color:#fff;
        padding: 0.7em 0.5em 0.7em 1.2em;;
        border-radius: 1.5em;
        margin-left: 1em;
        font-weight: 700;
        &:before {
          content:"";
          display:inline-block;
          width: 1.15em;
          height: 1.15em;
          background: url(${clockIcon}) 0 50% no-repeat;
          background-size: contain;
          margin-right: 0.2em;
          vertical-align: top;
        }
        &:after {
          content:"";
          display:inline-block;
          border-bottom: 0.4em solid transparent;
          border-top: 0.4em solid transparent;
          border-left: 0.6em solid #fff;
          border-right: 0.6em solid transparent;
          margin-left: 0.3em;
        }
      }
    }

    .notification {
      background:#2c2c2c;
      text-align:left;
      padding: 4em 0;
      color:#fff;
      font-weight:300;
      letter-spacing:normal;
      .inner {
        max-width: 750px;
      }
      h3 {
        font-size:2.8rem;
        font-weight:700;
        text-align: center;
      }
      dl {
        font-size: 2.2rem;
      }
      dt {
        padding: 1.6em 0 0.8em;
        font-weight:700;
				a {
					border-bottom: none;
				}
      }
      dd {
        position:relative;
        padding-left:0.8em;
        margin-bottom: 0.6em;
        &:before {
          content:"";
          display:inline-block;
          position: absolute;
          top:0.5em;
          left:0;
          width: 0.25em;
          height: 0.25em;
          border-radius: 0.2em;
          background:#fff;
          vertical-align:middle;
        }
        em {
          color:#fff799;
        }
      }
      P {
        padding: 1.6em 0 0.8em;
      }
      a {
        display: inline-block;
        border-bottom: 1px #fff solid;
        line-height: 1.2;
      }
    }

		@media (max-width: 790px) {
      .inner {
        margin: 0 20px;
      }
      .visual {
        .inner {
          padding-top: 137vw;
        }
        .btnWrap {
          a {
            width: 68vw;
          }
        }
      }
      .price {
        .inner {
          padding-top: 190vw;
        }
      }
      .review {
        .inner {
          padding-top: 45vw;
        }
        .swiperWrap {
          width:90%;
          height:99vw;
          padding: 7% 2% 0;
        }
      }
      .classInfo {
        .inner {
          height: 192vw;
          padding-top: 48.1vw;
        }
        ul {
          margin: 0 4%;
          li {
            height: 45vw;
            padding-top: 33vw;
          }
        }
      }
      .gift {
        .inner {
          padding-top: 114vw;
        }
      }
    }
    @media (max-width: 414px) {
      .visual {
        .inner {
          padding-top: 129vw;
        }
      }
      .price {
        .inner {
          padding-top: 182vw;
        }
      }
      .review {
        .swiperWrap {
          height:97vw;
        }
      }
      .classInfo {
        .inner {
          height: 183vw;
          padding-top: 45.1vw;
        }
        ul {
          li {
            height: 41.5vw;
            padding-top: 30vw;
            a {
              font-size: 1.2rem;
            }
          }
        }
      }
      .gift {
        .inner {
          padding-top: 106vw;
        }
      }
      .banner {
        font-size: 1.8rem;
        a {
          padding: 0.5em 0.2em 0.5em 1em;;
          margin-left: 0.8em;
          font-size: 1.8rem;
        }
      }

    }
  `,
};

export default StyledEventPage;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useLoginStatus from 'common/hook/useLoginStatus';
import { actions } from 'common/state';

/**
 * Login 후 redirect 설정
 * @param {string=} pathname (미입력 시 현재 pathname으로 redirect)
 */
export const useSetRedirect = (pathname) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const redirectUrl = pathname ? pathname : location.pathname;
  const isLogin = useLoginStatus();

  useEffect(() => {
    if (!isLogin) {
      dispatch(actions.setRedirect(redirectUrl));
    }
  }, [redirectUrl]);
};

export const useRedirect = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const redirectUrl = useSelector((state) => state.common.redirectUrl);
  const isLogin = useLoginStatus();

  const history = useHistory();
  useEffect(() => {
    redirectUrl && dispatch(actions.setRedirect(null));
  }, [pathname]);

  useEffect(() => {
    if (isLogin && redirectUrl) {
      history.replace(redirectUrl);
    }
  }, [isLogin]);
};

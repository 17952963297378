import styled from 'styled-components';
import ThumbImg from 'assets/images/aliveclass_thumb01.png';
import ThumbImg2 from 'assets/images/aliveclass_thumb02.png';
import ThumbImg3 from 'assets/images/aliveclass_thumb03.png';
import ThumbImg4 from 'assets/images/aliveclass_thumb04.png';


const StyledClass = {
  Wrapper: styled.div`
    position: relative;
    @media (max-width: 750px) {
     font-size: 1.08em;
    }
  `,

  Product: styled.div`
    cursor: pointer;
  `,

  Title: styled.div`
    position: relative;
    padding-top: 71.25%;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    background-image: url( ${ThumbImg});
    color: #fff;
    &.type1 { //입문
      background-image: url( ${ThumbImg});
      .group {
        background-color: #e36b14;
      }
    }
    &.type2 { //초급
      background-image: url( ${ThumbImg2});
      .group {
        background-color: #ffb500;
      }
    }
    &.type3 { //중급
      background-image: url( ${ThumbImg3});
      .group {
        background-color: #6abc19;
      }
    }
    &.type4 { //고급
      background-image: url( ${ThumbImg4});
      .group {
        background-color: #596eed;
      }
    }
    .group {
      position: absolute;
      top: 0.5em;
      left: 0.5em;
      padding: 0.2em 0.8em;
      border-radius: 2px;
      font-weight: 700;
      font-size: 1.3em;
    }
    .title {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 4.5em;
      margin-top: -1.5em;
      padding: 0 10%;
      letter-spacing: -0.02em;
      font-weight: 700;
      font-size: 2.2em;
      line-height: 1.5em;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  `,

  Info: styled.div`
    margin: 1.5em 0;
    font-size: 1.6em;
    .teacher {
      font-weight: 700;
      font-size: 1.25em;
      color: #fff;
    }
  `,

  Price: styled.div`
    margin: 1.5em 0;
    font-size: 1.6em;
    .listPrice {
      margin-right: 1em;
      text-decoration: line-through;
      &.nodiscount{
        margin-right: 0;
      }
    }
    .total {
      display: inline-block;
      color: #fff;
      strong {
        font-weight: 700;
        font-size: 1.5em;
      }
    }
  `,

  Button: styled.button`
    width: 100%;
    height: 3.25em;
    border: 2px solid #fff;
    border-radius: 3px;
    font-weight: 700;
    font-size: 1.6em;
    color: #fff;
  `,
};

export default StyledClass;

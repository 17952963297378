import bookstoreType from './type';
import {
  addBookToState,
  deleteItem,
} from 'container/BookStorePage/state/logic';

const initialState = {
  bookItems: [],
};

const bookStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case bookstoreType.Add_Book:
      return {
        ...state,
        bookItems: addBookToState(state.bookItems, action.payload),
      };
    case bookstoreType.Delete_Book:
      return {
        ...state,
        bookItems: deleteItem(state.bookItems, action.payload),
      };
    case bookstoreType.Delete_All:
      return { ...state, bookItems: [] };
    case bookstoreType.Add_Coupon:
      return { ...state, coupon: action.payload };
    default:
      return state;
  }
};

export default bookStoreReducer;

import React, { useContext, useEffect } from 'react';
import mockTestApi from 'api/mockTests';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Style from '../styled/TestPaper.style';
import CountdownProgress from 'container/MockTestPage/Components/CountdownProgress';
import Countdown from 'container/MockTestPage/Components/Countdown';
import { MockTestContext } from '../index';
import { toast } from 'react-toastify';

const Header = () => {
  const { mockTestStore } = useContext(MockTestContext);
  // const [countdown, setCountdown] = useState(null);
  const history = useHistory();

  const { productId, unitId } = useParams();
  const token = useSelector((state) => state.user.token);
  const [{ data }] = useAxios(
    () =>
      mockTestApi.getIntro({
        token,
        productId,
        unitId,
      }),
    [productId, unitId]
  );
  const [{ data: submitData }, submitAnswer] = useAxios(
    mockTestApi.submitAnswer,
    [productId, unitId],
    true
  );
  const [{ data: saveData }, saveAnswer] = useAxios(
    mockTestApi.saveAnswer,
    [productId, unitId],
    true
  );
  useEffect(() => {
    //임시저장 하면 알림 띄우기
    if (!saveData) return;
    if (saveData.status === 201) {
      toast.success('저장 되었습니다');
    }
  }, [saveData]);

  useEffect(() => {
    //제출 하면 결과 페이지로 이동
    if (!submitData) return;
    if (submitData.status === 200) {
      history.replace(`/mock-test/result/${productId}/${unitId}`);
    }
  }, [submitData]);

  const clickSubmit = () => {
    submitAnswer({
      token,
      productId,
      unitId,
      data: {
        testTime: mockTestStore.testTime,
        mockTestAnswerRequest: mockTestStore.mockTestAnswerRequest,
      },
    });
  };

  const clickSave = () => {
    saveAnswer({
      token,
      productId,
      unitId,
      data: {
        testTime: mockTestStore.testTime,
        mockTestAnswerRequest: mockTestStore.mockTestAnswerRequest,
      },
    });
  };

  if (data)
    return (
      <Style.Header>
        <Style.InfoContent>
          <p>
            {data.testTitle} - {data.subjectTitle}
          </p>
          <Style.InfoQuestion>
            문항 {mockTestStore.mockTestAnswerRequest.length}{' '}
            <span>/{data.size}</span>
          </Style.InfoQuestion>
          <Style.InfoProgress>
            <Style.ProgressContent>
              풀이시간{' '}
              <Countdown
                second={data.recordedTime ? data.recordedTime : data.testTime}
              />
            </Style.ProgressContent>
            <CountdownProgress
              second={data.recordedTime ? data.recordedTime : data.testTime}
              testTime={data.testTime}
            />
          </Style.InfoProgress>
        </Style.InfoContent>
        <Style.BtnGroupContainer>
          <button ref={mockTestStore.saveBtnRef} onClick={clickSave}>
            임시저장 <br /> Save
          </button>
          <button ref={mockTestStore.submitBtnRef} onClick={clickSubmit}>
            제출하기 <br /> Submit
          </button>
        </Style.BtnGroupContainer>
      </Style.Header>
    );
  return <></>;
};

export default Header;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import StyledUtil from 'common/styled/Util.style';
import StyledAside from 'container/CourseViewPage/Aside/index.style';

const Item = styled(NavLink)`
  display: block;
  padding: 2rem;
  &:not(:first-of-type) {
    border-top: 1px solid #1e2024;
  }
  &:hover {
    text-decoration: underline;
  }
  &.active {
    background-color: #191b1f;
    * {
      color: #fff;
    }
  }
  div {
    ${StyledUtil.textEllipsis}
    padding-bottom: 0.2em;
    font-size: 1.6em;
    font-weight: 500;
    color: #c9cbd1;
  }
  span {
    font-size: 1.4em;
    color: #8e9199;
  }
`;

const RecentPlayTab = ({ historyListData }) => {
  const [historyList, setHistoryList] = useState([]);
  useEffect(() => {
    historyListData && setHistoryList(historyListData);
  }, [historyListData]);

  return (
    <StyledAside.TabWrapper>
      {historyList &&
        historyList.map((item) => (
          <Item key={item.chapterLectureId}
            to={`/courses/detail/${item.productId}/${item.chapterLectureId}`}
            activeClassName='active'
          >
            <div>{item.title}</div>
            <span>{item.chapterLectureTitle}</span>
          </Item>
        ))}
    </StyledAside.TabWrapper>
  );
};

export default RecentPlayTab;

import React from 'react';
import Styled from './styled/index.style';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';
import Language from 'component/layout/Header/RightMenu/Language';
import Event from 'component/layout/Header/RightMenu/Event';
import Notification from 'component/layout/Header/RightMenu/Notification';
import UserNav from 'component/layout/Header/RightMenu/UserNav';
import Mypage from 'component/layout/Header/RightMenu/Mypage';
import { useLocation, Link } from 'react-router-dom';

import { useSelector } from "react-redux";
import { SiteMapData } from 'common/constant';

const RightMenu = ({ isLogin }) => {
  let { handleModal } = React.useContext(ModalContext);
  const location = useLocation();
  const openLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false, 420, location.pathname);
  };

  const userData = useSelector((state) => state.user.data);
  const planCheckURL = () => {
    if (userData.serviceLevel === "PREMIUM")
      //프리미엄 상품 구매자자
      return "/classroom/topik-mock-test";
    //    else if(userPlan === "EPS" || "STANDARD")
    //      //무료체험 이후 또는 상품구매자
    //      return SiteMapData.plan;
    else
      //무료체험 미진행
      return SiteMapData.plan;
  };

  const checkMessege = () => {
    if (userData.serviceLevel === "PREMIUM")
      //프리미엄 상품 구매자자
      return "TOPIK MOCK TEST";
    else if (userData.serviceLevel === "EPS")
      //무료체험 이후 또는 상품구매자
      return "UPGRADE";
    else if (userData.serviceLevel === "STANDARD")
      //무료체험 이후 또는 상품구매자
      return "UPGRADE";
    else if (userData.orderPayInfo.length !== 0)
      //무료체험 이후 또는 상품구매자
      return "UPGRADE";
    else
      //무료체험 미진행
      return "FREE TRIAL";
  };

  return (
    <Styled.Wrapper>
      {/* Master K 관련 내용은 4월초 오픈 예정이라 우선 미노출 처리
      {isLogin && <Styled.TutoringLink to='#none' />}
      */}
      {isLogin ? (
        <>
          <Styled.UserState>
            <Styled.StateFreeButton to={planCheckURL()}>{checkMessege()}</Styled.StateFreeButton>
          </Styled.UserState>
          <Link to="/help">
            <Event isLogin={isLogin}/>
          </Link>
          <Notification />
          <Mypage />
          <UserNav />
        </>
      ) : (
        <>
          <Link to="/help">
            <Event isLogin={isLogin}/>
          </Link>
          <Language />

          <Styled.LoginButtonWrap>
            <Styled.LoginButton onClick={openLoginModal} className='login-btn'>
              SIGN IN
            </Styled.LoginButton>
          </Styled.LoginButtonWrap>
        </>
      )}
    </Styled.Wrapper>
  );
};

export default RightMenu;

import React, { useState } from 'react';
import Styled from './index.style';

const NavItemWithSub = ({ item, isLogin, handleMouseOver, handleMouseOut }) => {
  const [activeSub, isActiveSub] = useState(false);


  const handleActive = () => isActiveSub(!activeSub);

  return (
    <>
      <Styled.WithSub onClick={handleActive} className={`${activeSub ? 'active' : ''}`}

      >
        <Styled.Link to={item.url} className="nav_a">{item.name}

        {item.name === 'Plan'  && <span className='free'>Free Trial</span>}
        <svg
            width="8px" height="5px">
            <path fill-rule="evenodd"  fill="rgb(255, 255, 255)"
            d="M-0.000,-0.000 L7.1000,-0.000 L3.1000,4.1000 L-0.000,-0.000 Z"/>
        </svg>
        </Styled.Link>
      </Styled.WithSub>

      <Styled.Sub.Container className={`depth2 ${activeSub ? 'active' : ''}`}>
        {item.subList.map((subItem, subIndex) => (
          <Styled.Sub.Link key={subIndex} to={subItem.url} className="nav_a">
            {subItem.name}
          </Styled.Sub.Link>
        ))}
      </Styled.Sub.Container>
    </>
  );
};

export default NavItemWithSub;

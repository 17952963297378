import React from 'react';
import { useTranslation } from 'react-i18next';
import Style from './styled/index.style';

import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';

import BookList from 'container/AudioPage/BookList';
import Banner from 'container/AudioPage/Banner'
import BannerEvent from 'container/AudioPage/BannerEvent'

const AudioPage = () => {
  const { t } = useTranslation('audioPage');

  return (
    <>
      <ContentsWrapper>
        <PageHeader title={t('title')}/>

        <BookList />

        <Banner />
        <BannerEvent />
      </ContentsWrapper>
    </>
  );
};
export default AudioPage;
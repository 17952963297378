import styled from 'styled-components';

const StyledClass = {
  Wrapper: styled.div`
    position: relative;
    border-radius: 0.6rem;
    background-color: #1e2024;
    overflow: hidden;
    cursor: pointer;
    font-size: 1em;
    @media (max-width: 750px) {
     font-size: 1.08em;
    }
  `,

  Profile: styled.div`
    position: relative;
    height: 100%;
    &:hover {
      button {opacity: 1}
    }
  `,

  Img: styled.div`
    position: relative;
    padding-top: 64.4%;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    .label {
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 6.25%;
      margin-left: 6.25%;
      padding: 0.2em 0.6em;
      border-radius: 0.28em;
      background: rgba(255, 255, 255, 0.4);
      text-transform: uppercase;
      letter-spacing: -0.02em;
      font-weight: 700;
      font-size: 1.4em;
      color: #000;
    }
  `,
  Info: styled.div`
    position: relative;
    padding: 1.25em 1.25em 5em;
    font-size: 1.6em;
    .name {
      margin-bottom: 0.6em;
      font-weight: 700;
      font-size: 1.75em;
      color: #ffffff;
    }
    dl {
      display:flex;
      margin-bottom: 0.6em;
      @media (min-width: 750px) and (max-width: 1024px) {
        display:block;
      }
      dt {
        min-width: 5em;
        height: 2em;
        padding: 0.2em 0.6em;
        margin-right: 0.7em;
        border-radius: 0.28em;
        background: #393c40;
        letter-spacing: -0.02em;
        text-align: center;
        font-size: 0.94em;
        color: #8e9199;
        flex: 0 0 auto;
      }
      dd {
        font-weight: 700;
        font-size: 1em;
        color: #ffffff;
      }
    }
    p {
      height: 3em;
      line-height: 1.5em;
      margin: 1.25em 0;
      color: #c9cbd1;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  `,

  ButtonWrap: styled.div`
    position: absolute;
    left: 0;
    bottom: 1.6em;
    width: 100%;
    height: 3.25em;
    padding: 0 1.25em;
    font-size: 1.6em;
  `,

  Price: styled.div`
    position: relative;
    width: 100%;
    height: 3.25em;
    line-height: 3.25em;
    padding-right: 0.2em;
    border-top: 1px solid #2b2e35;
    text-align: right;
    letter-spacing: -.05em;
    color: #fff;
    strong {
      font-size: 1.2em;
      font-weight: 700;
    }
    span {
      font-size: 1.125em;
    }
    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #485cc7;
      border-radius: 3px;
      font-weight: 700;
      color: #fff;
      transition: opacity ease-in-out .2s;
      opacity: 0;
      @media (max-width:1024px){
        opacity: 1;
      }
    }
  `,
};

export default StyledClass;

import H3 from 'component/atoms/H3';
import React from 'react';
import styled from 'styled-components';

const Desc = styled.span`
  display: inline-block;
  margin-left: 0.88em;
  color: #d1d1d1;
  font-size: 0.53em;
  font-weight: 400;
`;

const StyledH3 = styled(H3)`
  margin-bottom: 1em;
`;

const PageSubTitle = ({ title, subtitle: desc }) => {
  return (
    <StyledH3>
      {title} <Desc>{desc}</Desc>
    </StyledH3>
  );
};

export default PageSubTitle;

import styled from 'styled-components';
import List from 'container/LearningMaterialPage/styled/List.style';

const LearningMaterialList = {
  List: styled(List.List)`
    margin-top: 0;
  `,
  Item: styled(List.Item)``,
  Icon: styled(List.Icon)``,
  Category: styled(List.Category)``,
  Title: styled(List.Title)``,
  TitleLink: styled(List.TitleLink)``,
  Date: styled(List.Date)`
    @media (max-width: 767px) {
      display: none;
    }
  `,
};

export default LearningMaterialList;

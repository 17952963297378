import React from 'react';
import StyledList from './styled/List.style';
import Login from 'component/user/Login';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Circle } from 'rc-progress';
import { BiPlay, BiPause } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUrl, setSelectNumber } from './state/action';
import { ModalContext } from 'component/Modal/modalContext';
import MembershipErrorModal from 'component/atoms/MembershipErrorModal';
const SecondAccordion = ({
  title,
  overviewLecture,
  time,
  percent,
  id,
  idx,
  logoutPercentage,
}) => {
  let history = useHistory();
  const { productId } = useParams();
  const location = useLocation();
  const epsCourseId = '1028';

  const { handleModal } = React.useContext(ModalContext);

  const openMembershipErrorModal = (url) => {
    handleModal(<MembershipErrorModal />, true, 600);
  };

  const dispatch = useDispatch();

  let firstChapterLectureUrl = useSelector(
    (state) => state.courseDetail?.videoUrl
  );
  let currentUrl = useSelector((state) => state.courseDetail?.currentVideoUrl);

  let login = useSelector((state) => state.user.token);

  const clickList = () => {
    // login 로그인 한 경우면 토근 값이 들어오고 / 하지 않은 경우라면 빈값(= false)
    dispatch(setSelectNumber({ num: !idx ? 0 : idx }));
    if (!login) {
      if (overviewLecture) {
        console.log('firstChapterLectureUrl', firstChapterLectureUrl);
        dispatch(setCurrentUrl(firstChapterLectureUrl.lecture));
      } else {
        handleModal(<Login />, false, 420, location.pathname);
      }
    }
    //프리 계정은 멤버쉽 변경 모달창 띄움
    // if (userData && userData === "FREE") {
    //   openMembershipErrorModal();
    //   return;
    // }

    // EPS 계정은 EPS 강좌 뺴고 멤버쉽 모달창 띄움
    // if (userData && userData === "EPS") {
    //   console.log(productId, epsCourseId);
    //   if (productId === epsCourseId) {
    //     history.push(history.location.pathname + `/${id}`);
    //     return;
    //   } else {
    //     openMembershipErrorModal();
    //     return;
    //   }
    // }
    if (login) {
      // 로그인 O
      history.push(history.location.pathname + `/${id}`);
    }
  };
  const percentage = () => {
    if (login) {
      return percent ? percent : 0;
    } else if (!login) {
      return logoutPercentage && currentUrl?.provider !== 'youtube'
        ? logoutPercentage
        : 0;
    } else {
      return 0;
    }
  };
  return (
    <div>
      <StyledList.VideoListContainer
        className={overviewLecture || login ? 'clickable' : ''}
        onClick={() => clickList()}
      >
        {
          //비디오 데이터
          <StyledList.VideoList>
            <span className='icon'>
              <Circle
                percent={percentage()}
                strokeWidth={5}
                trailWidth={5}
                strokeColor='#f8b209'
                trailColor='#8b8f99'
                className='progressBar'
              />
              {!login &&
              currentUrl?.provider !== 'youtube' &&
              logoutPercentage ? (
                <BiPause className='progressBarIcon' color='#f8b209' />
              ) : (
                <BiPlay
                  className='progressBarIcon'
                  color='#8b8f99'
                  size='0.8em'
                />
              )}
            </span>
            {/* 기존 */}
            {/* <StyledList.VideoListTitle>{title}</StyledList.VideoListTitle> */}
            {/* 미리보기 추가 */}
            <StyledList.VideoListTitle
              className={overviewLecture ? 'preview' : ''}
            >
              {title}
              {overviewLecture ? (
                <StyledList.VideoListPreview>
                  preview
                </StyledList.VideoListPreview>
              ) : undefined}
            </StyledList.VideoListTitle>

            <span className='time'>{time && `${time} m`}</span>
          </StyledList.VideoList>
        }
      </StyledList.VideoListContainer>
    </div>
  );
};

export default SecondAccordion;

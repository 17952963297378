import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { ModalContext } from 'component/Modal/modalContext';

const Style = {
  Desc: styled.p`
    margin: 1em 0 1.5em;
    text-align: center;
    font-size: 2em;
    font-weight: 700;
  `,
  ButtonContainer: styled.div`
    display: flex;
    > * {
      flex: 1;
    }
  `,
  Button: styled.button`
    display: inline-block;
    padding: 1.25em 0;
    border: 1px #8b8f99 solid;
    border-radius: 0.375em;
    font-size: 1.6em;
    font-weight: 700;
    letter-spacing: 0.1px;
    &.primary {
      margin-left: 0.5em;
      border: 0;
      background-color: #475cc7;
      color: #fff;
    }
  `,
};

const AddConfirmModal = ({ t }) => {
  const history = useHistory();
  const { handleModal } = React.useContext(ModalContext);

  const goToPayment = () => {
    history.push({
      pathname: `/payment/book`,
    })
    handleModal();
  };

  return (
    <>
      <Style.Desc>{t('addConfirmModal.title')}</Style.Desc>
      <Style.ButtonContainer>
        <Style.Button onClick={() => handleModal()}>
          {t('addConfirmModal.goBack')}
        </Style.Button>
        <Style.Button className='primary' onClick={() => goToPayment()}>
          {t('addConfirmModal.goPayment')}
        </Style.Button>
      </Style.ButtonContainer>
    </>
  );
};

export default AddConfirmModal;

import React, { useRef } from "react";
import Style from "./styled/Process.style";
import { AddTotalPrice as AddTotalPriceBook } from "container/BookStorePage/state/logic";
import { AddTotalPrice as AddTotalPriceVideo } from "container/PaymentPage/state/logic";
import { useSelector } from "react-redux";
import { priceFormat } from "common/util/util";
import OverseasPayForm from "container/PaymentPage/OverseasPayForm";
import { CURRENCY } from "common/constant";
import { useParams } from "react-router-dom";

const OrderSummary = ({
  onclick,
  priceCurrency,
  data,
  formSubmit,
  pginfo,
  t,
}) => {
  const { type: itemType } = useParams();
  const products = useSelector((state) => {
    if(itemType === 'book') return state.bookStore.bookItems;
    else if(itemType === 'video-class') return state.videoClassStore.videoItems;
    else return [];
  });
  const couponPrice = useSelector((state) => {
    if(itemType === 'book') return state.bookStore?.coupon?.discount;
    else if(itemType ==='video-class') return state.videoClassStore?.coupon?.discount;
  });
  // const cartItems = useSelector((state) => state.bookStore.bookItems);
  const discount = couponPrice ? couponPrice : 0;

  //한국어 설정 확인
  const korean = priceCurrency === CURRENCY.DOMESTIC ? true : false;
  const deliveryFee = korean ? 2500 : 2.5;

  const currency = (price) => {
    if (products?.length === 0) {
      return korean ? priceFormat.korea(0) : priceFormat.usa(0);
    } else {
      return korean ? priceFormat.korea(price) : priceFormat.usa(price);
    }
  };

  return (
    <Style.OrderSummary>
      <Style.OrderTitle>{t("orderSummary.title")}</Style.OrderTitle>
      <Style.PriceSection>
        <div className="row head">
          <span>{t("orderSummary.selected")}</span>
          <span>{t("orderSummary.price")}</span>
        </div>
        {products.map((product) => {
          const price = korean ? product.total : product.totalDollar;
          return (
            <div className="row" key={product.id}>
              <span>{product.name}</span>
              <span>{currency(price)}</span>
            </div>
          );
        })}
      </Style.PriceSection>
      <Style.TotalSection>
        <div className="row">
          <span>{t("orderSummary.iTotal")}</span>
          <span>{(itemType === 'book' ? 
            currency(AddTotalPriceBook(korean).totalPrice)
            : currency(AddTotalPriceVideo(korean).totalPrice)
          )}</span>
        </div>
        <div className="row">
          <span>{t("orderSummary.coupon")}</span>
          {
            //<span>- {currency(AddTotalPriceBook().totalDisCountPrice)}</span>
          }
          <span>
            {discount !== 0
              ? `- ${currency(discount)}`
              : `${currency(discount)}`}
          </span>
        </div>
      </Style.TotalSection>
      {itemType === 'book'?
      <Style.TotalSection>
        <div className="row">
          <span>{t("orderSummary.subTotal")}</span>
            <span>{currency(AddTotalPriceBook(korean).totalPrice - discount)}</span>
        </div>
        <div className="row">
          <span>{t("orderSummary.deliveryFee")}</span>
          <span>{currency(deliveryFee)}</span>
        </div>
      </Style.TotalSection>
        : <></>
      }

      <Style.TotalPrice>
        <span>{t("orderSummary.orderTotal")}</span>
        <span>{(itemType === 'book' ? 
          currency(AddTotalPriceBook(korean).totalPrice - discount + deliveryFee)
          : currency(AddTotalPriceVideo(korean).totalPrice - discount)
        )}</span>
      </Style.TotalPrice>

      <Style.Button onClick={(e) => onclick(e)}>
        {t("orderSummary.button")}
      </Style.Button>
      
      {formSubmit && data && (
        <OverseasPayForm
          data={data}
          priceCurrency={priceCurrency}
          pginfo={pginfo}
          book={true} //결제 타입이 plan이 아니면 true
        />
      )}
    </Style.OrderSummary>
  );
};

export default OrderSummary;

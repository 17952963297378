import React from 'react';
import Style from '../styled/component.style';
import Choices from 'container/MockTestPage/Components/Choices';
import Img from 'component/atoms/Img';
import Audio from 'container/MockTestPage/Components/Audio';
import ResultChoice from 'container/MockTestPage/Components/ResultChoice';

const Question = ({ data, result = false }) => {
  const {
    isInstruction,
    instructions,
    questionSeq,
    questionPoints,
    questions,
    title,
    unitValuationId,
    listenFileUrl,
    userAnswer,
    correctNo,
  } = data;

  return (
    <>
      {isInstruction && (
        <div className='question-title'>
          {listenFileUrl && <Audio source={listenFileUrl} />}
          <Img src={instructions} alt={title} />
        </div>
      )}
      <Style.Question>
        <Style.QuestionTitle>
          {questionSeq}. ({questionPoints}점)
        </Style.QuestionTitle>
        <Style.QuestionDesc>
          <Img src={questions} alt={'question'} />
        </Style.QuestionDesc>
      </Style.Question>
      {result ? (
        <ResultChoice answers={{ userAnswer, correctNo }} />
      ) : (
        <Choices id={unitValuationId} />
      )}
    </>
  );
};

export default Question;

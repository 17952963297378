import styled from 'styled-components';

const ExploreStyle = {
  ExploreWrap: styled.div`
    margin-bottom: 6em;
  `,

  ListWrap: styled.ul`
    display: flex;
    gap: 4em;
    flex-wrap: nowrap;
    justify-content: space-between;
    @media (max-width: 1024px){
      gap: 2em;
    }
    @media (max-width: 750px){
      flex-wrap: wrap;
    }
  `,

  ListItem: styled.li`
    flex: 1;
    @media (max-width: 750px){
      flex-basis: calc(50% - 1em);
    }
  `,

  Thumb: styled.div`
    border-radius: 0.6em;
    overflow: hidden;
    img {
      width: 100%;
    }
  `,

  Title: styled.p`
    margin-top: 1em;
    font-size: 2em;
    color: #fff;
  `,

};

export default ExploreStyle;

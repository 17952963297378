import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Style from './styled/Banner.style';
import { Link } from 'react-router-dom';

const AudioBanner = () => {
  const { t } = useTranslation('jresearchPage');

  return (
    <>
      <Style.BannerAudio>
        <Link to='/audio' target='_blank'>
          <Trans t={t}>bannerMP3.title</Trans>
        </Link>
      </Style.BannerAudio>
    </>
  );
};
export default AudioBanner;
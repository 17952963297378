import styled from 'styled-components';
import Img from 'component/atoms/Img';
import StyledUtil from 'common/styled/Util.style';
import StyledCourseCard from 'component/ui/card/styled/courseCard.style';
import playImgSmall from 'assets/images/ico_play_small.png';
import playImg from 'assets/images/ico_play.png';
const StyledMTLive = {
  HeadingContainer: styled.div`
    position: relative;
    margin-bottom: 3.5em;
    > h3 {
      font-size: 3em;
      font-weight: 700;
    }
    > p {
      color: #afafaf;
      font-size: 1.6em;
      font-weight: 300;
      @media (max-width: 767px) {
        padding-bottom: 3.5em;
        font-size: 2em;
      }
    }
    > span {
      display: inline-block;
      position: absolute;
      top: 84%;
      right: 0;
      padding: 0.7em;
      border-radius: 0.6rem;
      background-color: #1e2024;
      color: #ffb500;
      font-size: 1.6em;
      font-weight: 400;
      letter-spacing: 1px;
      transform: translateY(-50%);
      @media (max-width: 767px) {
        position: static;
        margin-top: 1em;
        width: 100%;
        text-align: center;
        font-size: 2em;
        transform: translateY(0);
      }
    }
  `,
  VideContainer: styled.div`
    > .video {
      position: relative;
    }
    .plyr__control--overlaid {
      background-color: #ffb500;
    }
    > h3 {
      margin: 1.66em 0 1em;
      font-size: 3em;
      font-weight: 400;
    }
    > .info {
      display: flex;
      padding-bottom: 4.4em;
      border-bottom: 1px #2b2e35 solid;

      .info-content {
        margin-left: 2em;
        @media (max-width: 767px) {
          font-size: 1.25em;
        }
        .channel-name {
          display: block;
          margin-bottom: 0.5em;
          font-size: 2em;
          font-weight: 400;
        }
        .badge {
          display: inline-block;
          padding: 0.3em 0.8em;
          border-radius: 2px;
          background-color: #ad0d0e;
          font-size: 1.3em;
          font-weight: 700;
        }
        .viewer {
          display: inline-block;
          margin-left: 0.8em;
          vertical-align: bottom;
          color: #777980;
          font-size: 1.6em;
          font-weight: 400;
        }
      }
    }
    @media (max-width: 375px) {
      [data-plyr='rewind'],
      [data-plyr='fast-forward'] {
        display: none;
      }
    }
  `,
  ChannelImg: styled(Img).attrs({
    alt: 'channel Image',
  })`
    width: 6.6em;
    height: 6.6em;
    border-radius: 50%;
    object-fit: cover;
  `,
  PastVideosContainer: styled.div`
    margin: 4em 0 16em;

    @media (max-width: 767px) {
      margin: 4em 0 12em;
    }
    > h3 {
      margin-bottom: 1.36em;
      font-size: 2.2em;
      font-weight: 700;
    }
    > div:last-child {
      margin-bottom: 0;
    }
  `,
  PastVideo: styled.div`
    margin-bottom: 4.5em;
    a {
      display: flex;
    }
    &:after {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }
    .video-content {
      width: 74.3%;
      margin-left: 3%;
      @media (max-width: 767px) {
        font-size: 1.25em;
      }

      > h4 {
        ${StyledUtil.textEllipsis}
        margin-bottom:1px;
        font-size: 2.2em;
        font-weight: 700;
      }
      .views {
        color: #8e9199;
        font-size: 1.6em;
        font-weight: 400;
      }
      > p {
        ${StyledUtil.textEllipsis}
        margin:1.66em 0 2.7em;
        font-size: 1.8em;
        font-weight: 300;
        color: #a8aaaf;

        @media (max-width: 767px) {
          display: none;
        }
      }
      .channel-profile {
        font-size: 1.6em;
        @media (max-width: 767px) {
          display: block;
          margin-top: 1em;
        }
        img {
          width: 2.43em;
          height: 2.43em;
          margin-right: 0.93em;
          border-radius: 50%;
        }
      }
    }
  `,
  PastVideoImageContainer: styled(StyledCourseCard.ImageContainer)`
    width: 22.7%;
    margin-bottom: 0;
  `,
  PastVideoImage: styled(StyledCourseCard.Image).attrs({
    alt: 'youtube thumbnail',
  })`
    height: 100%;
    object-fit: cover;
  `,
  Thumb: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    overflow: hidden;
    cursor: pointer;

    &.hidden-poster {
      opacity: 0;
      height: 0;
      transition: opacity 0.4s ease 0.2s, height 0s ease 0.6s;
      }
    &:hover > div:before {
      opacity: 0.8;
    }
  `,
  PlayIcon: styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: calc(40px + 5rem);
    height: calc(40px + 5rem);
    margin: calc(-20px - 2.5rem) 0 0 calc(-20px - 2.5rem);
    font-size: 3rem;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background: url(${playImgSmall}) no-repeat 54% center;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: -1;
      width: 6.8rem;
      height: 6.8rem;
      margin: -3.4rem 0 0 -3.4rem;
      border-radius: 1rem;
      background-color: #ffb500;
      opacity: 0.5;
      transform: rotate(45deg);
      transition: all 0.2s ease;
    }

    @media (max-width: 1024px) {
      &:after {
        background-image: url(${playImg});
        background-size: 2.4rem;
      }
    }
  `,
};

export default StyledMTLive;

import styled from 'styled-components';
import StyledUtil from 'common/styled/Util.style';
import Img from 'component/atoms/Img';

const StyledVocaItem = {
  Wrapper: styled.div`
    position: relative;
  `,
  Img: styled(Img)`
    width: 100%;
    margin-bottom: 1em;
    border-radius: 0.6rem;
  `,
  Title: styled.div`
    ${StyledUtil.lineClamp}
    font-size: 2em;
    height: 3em;
    font-weight: 500;
  `,
};

export default StyledVocaItem;

import { asyncApi } from 'common/util/asyncApi';

const pathName = '/api/v1/teachers';

/**
 * getAllList 전체 강사 목록
 */
const teachersApi = {
  getAllList: async () => {
    let response = await asyncApi({ url: pathName });
    return response.data;
  },
};

export default teachersApi;

import styled from 'styled-components';

const H2 = styled.h2`
  font-size: 4.8em;
  font-weight: 700;
  &.mycurriculum{
    @media (max-width: 1024px) {
    font-size:3.2rem
  }
  }




`;

export default H2;

import React from 'react';
import { useRouteMatch } from 'react-router';
import TestRoutes from 'container/MockTestReportPage/Route';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';
import StyledTab from 'component/TabMenu/index.style';

const MockTestReportPage = () => {
  const { url } = useRouteMatch();
  return (
    <ContentsWrapper>
      <PageHeader title={'모의고사 성적 리포트'} />
      <StyledTab.Container>
        <StyledTab.NavLink exact to={url}>종합</StyledTab.NavLink>
        <StyledTab.NavLink to={`${url}/individual`}>개별</StyledTab.NavLink>
      </StyledTab.Container>
      <TestRoutes />
    </ContentsWrapper>
  );
};

export default MockTestReportPage;

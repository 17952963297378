import React from 'react';
import StyledActivityInsight, {
  StyledMyCurriculum as Styled,
} from 'container/UserHomePage/styled/ActivityInsight.style';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';

export default function MyCurriculum({ progressStatus = '', title = '' }) {
  const { t } = useTranslation('userHomePage');

  return (
    <Styled.Wrapper>
      <Styled.H3>{t('dashBoard.headSection.headSection')}</Styled.H3>

      <StyledActivityInsight.Box>
        <Styled.Status>{t('dashBoard.headSection.badge')}</Styled.Status>

        <Styled.Title>{title}</Styled.Title>
        <Styled.CurriculumLink to='/classroom/curriculum'>
          <FiPlus size='2.3em' />
        </Styled.CurriculumLink>
      </StyledActivityInsight.Box>
    </Styled.Wrapper>
  );
}

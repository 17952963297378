import { MOBILE_APP_USER_AGENT } from "common/constant";
import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

const OrderRedirectHandler = () => {
  const { orderType, orderId } = useParams();
  const history = useHistory();
  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    if (userAgent !== MOBILE_APP_USER_AGENT) {
      window.opener.document.location.href = `/order-complete/${orderType}/${orderId}`;
      window.self.close();
      return;
    } else {
      history.push(`/order-complete/${orderType}/${orderId}`);
    }
  }, []);

  return <></>;
};

export default OrderRedirectHandler;

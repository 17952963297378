import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import ReactPlayer from 'react-player';
import {Trans, useTranslation } from 'react-i18next';
import Styled from '../styled/TeacherIndex.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useAxios } from "common/hook/useAxiosHook";
import useLoginStatus from "common/hook/useLoginStatus";
import { openLink } from 'common/util/util';
import { ModalContext } from 'component/Modal/modalContext';
import Login from "component/user/Login";
import useErrorToastify from "common/hook/useErrorToastify";

import videoClassApi from "api/videoclass";
import H3 from 'component/atoms/H3';
import StickyArea from './TeacherStickyArea';
import { teacherDetails } from '../SampleData';
import ProductModal from './ProductModal';
import ProductItem from './ProductItem';

const TeacherDetailPage = () => {
  const { t } = useTranslation('aliveClassPage');
  const { teacherId } = useParams();
  let { modal, handleModal } = React.useContext(ModalContext);
  const language = localStorage.getItem('i18nextLng');
  const isLogin = useLoginStatus();
  const location = useLocation();
	const history = useHistory();

  const [moreTeacher, setMoreTeacher] = useState(false);  //선생님 소개 더보기
  const [moreClass, setMoreClass] = useState(false);      //수업 소개 더보기

  const [data, setData] = useState();                     //teacher 데이터
  const [products, setProducts] = useState([]);           //연결 상품 데이터 []
  const [subjectList, setSubjectList] = useState([]);     //수업하는 과목
  const [classLangList, setClassLangList] = useState([]); //수업 언어
  const [livingIn, setLivingIn] = useState('');           //거주 국가
  const [slogan, setSlogan] = useState('');               //선생님 소개글
  const [teachingStyle, setTeachingStyle] = useState(''); //수업 스타일
  const [teachingType, setTeachingType] = useState('');   //수업 타입
  const [teachingTime, setTeachingTime] = useState('');   //수업 가능 시간
  const [productTitleList, setProductTitleList] = useState([]); //수업 제목
  const [priceCost, setPriceCost] = useState([]);       //정가 number
  const [salesCost, setSalesCost] = useState([]);       //판매가 number
  const [discountType, setDiscountType] = useState([]); //할인 타입 string PER | CST
  const [discountCost, setDiscountCost] = useState([]); //할인량 number

  const [
    { loading: getTeacherDetailLoading, data: getTeacherDetailData, error: getTeacherDetailError },
    fetchTeacherDetail,
  ] = useAxios(videoClassApi.getTeacherDetail, [], true);

  // Video State
	const [playing, setPlaying] = useState(false);
	const [isPlay, setIsPlay] = useState(false);
	const onPlay = () => {
		setPlaying(true);
		setIsPlay(true);
	};

  // See More
  useEffect(() => {
    fetchTeacherDetail(teacherId);
  }, []);

  useEffect(()=>{
    if(!getTeacherDetailData) return;
    console.log('getTeacherDetailData:: ', getTeacherDetailData);

    if(getTeacherDetailData.videoclassTeacherDetail){
      setData(getTeacherDetailData.videoclassTeacherDetail);
    }
    if(getTeacherDetailData.productSubList){
      setProducts(getTeacherDetailData.productSubList);
    }
  }, [getTeacherDetailData]);
  useEffect(()=>{
    if (process.env.REACT_APP_ENV === "local") {
      if(getTeacherDetailError === undefined){
        setData(teacherDetails.videoclassTeacherDetail);
        setProducts(teacherDetails.productSubList);
      }
    }
  },[getTeacherDetailError]);

  useErrorToastify(getTeacherDetailError);

  useEffect(()=>{
    if(data){
      console.log('teacherData::', data);
      
      //수업하는 과목
      if(data.teachingSubject){
        const reg = /[^,\s]+/g;
        const arr = data.teachingSubject.match(reg);
        let list = [];
        arr.map((sub, index)=>{
          if(sub === "KO"){
            list = [...list, "Korean"];
          } else if(sub === 'ETC'){
            if(data.subjectEtc){
              list = [...list, data.subjectEtc];
            } else list = [...list, sub];
          } else list = [...list, sub];
        });
        setSubjectList(list);
      }
      
      //가능 언어
      if(data.classLang){
        const reg = /[^,\s]+/g;
        const arr = data.classLang.match(reg);
        let list = [];
        arr.map((sub, index)=>{
          if(sub === 'ETC'){
            if(language === 'ko-KR' && data.classLangEtcKO){
              list = [...list, data.classLangEtcKO];
            } else if(language !== 'ko-KR' && data.classLangEtcEN){
              list = [...list, data.classLangEtcEN];
            }
          } else list = [...list, sub];
        });
        setClassLangList(list);
      }

      if(language === 'ko-KR'){
        setLivingIn(data.livingInKO);
        setSlogan(data.slogan);
        setTeachingStyle(data.teachingStyleKO);
        setTeachingType(data.teachingTypeKO);
        setTeachingTime(data.teachingTimeKO);
      }else{
        setLivingIn(data.livingInEN);
        setSlogan(data.sloganEN);
        setTeachingStyle(data.teachingStyleEN);
        setTeachingType(data.teachingTypeEN);
        setTeachingTime(data.teachingTimeEN);
      }
    }
  }, [data]);

  useEffect(()=>{
    console.log('products:: ', products, products.length)
    if(products && products.length > 0){
      let title = [];
      let pCost = [];
      let sCost = [];
      let dType = [];
      let dCost = [];
      if(language === 'ko-KR'){
        products.map((product)=>{
          title = [...title, product.productTitle];
          pCost = [...pCost, product.priceCostWon];
          sCost = [...sCost, product.salesStoreWon];
          dType = [...dType, product.discountTypeWon];
          dCost = [...dCost, product.discountCostWon];
        });
      }else{
        products.map((product)=>{
          title = [...title, product.productTitleEN];
          pCost = [...pCost, product.priceCostDollar];
          sCost = [...sCost, product.salesStoreDollar];
          dType = [...dType, product.discountTypeDollar];
          dCost = [...dCost, product.discountCostDollar];
        });
      }
      setProductTitleList(title);
      setPriceCost(pCost);
      setSalesCost(sCost);
      setDiscountType(dType);
      setDiscountCost(dCost);
    }
  }, [products]);
  
  const clickBack = () => {
    history.goBack();
  }

  const clickBtnMore = (idx) => {
    if(idx === 0){
      setMoreTeacher(true);
    } else if(idx === 1){
      setMoreClass(true);
    }
  }

  const clickDetail = (productIdx) => {
		if (!isLogin) {
			handleModal(<Login />, false, 420, location.pathname);
			return false;
		}
    
    handleModal(
      <ProductModal
        data={data}
        products={products}
        selectedIdx={productIdx ? productIdx : -1}
      />, true, 850
    );
    return true;
  };

  return (
    <>
      <SeoHelmet
        title={SeoData.aliveclass.title}
        desc={SeoData.aliveclass.desc}
      />

      <ContentsWrapper>
        <Styled.PageTitle>
          <Styled.BtnBack onClick={clickBack}>Go Back</Styled.BtnBack>
          <Styled.TeacherName>
            <div className='label'>Teacher</div>
            <div className='name'>{data ? data.teacherNameEN : ''}</div>
            <div className='name'>{data ? data.teacherName : ''}</div>
          </Styled.TeacherName>
        </Styled.PageTitle>

        {data
        ? <>
        <StickyArea/>

        <Styled.Section id='AboutTeacher'>
          <H3>{t('teacherDetail.title')}</H3>
          <Styled.SectonBox>
            <Styled.InfoBox>
              <div className='video'>
                <ReactPlayer
                  controls
                  onClick={onPlay}
                  playing={playing}
                  url={data.movURL}
                  width='100%'
                  height='100%'
                  config={{
                    file: {
                      attributes: {
                        onContextMenu: (e) => e.preventDefault(),
                        controlsList: 'nodownload',
                      },
                    },
                  }}
                />
              </div>
              <Styled.ViewBox className='w50'>
                <Styled.ViewArea className={moreTeacher? 'active' : ''}>
                  <Styled.Info>
                    <div className='name'>{data.teacherName}</div>
                    <dl>
                      <dt>{t('teacher.info')}</dt>
                      <dd>{subjectList.join(', ')}</dd>
                    </dl>
                    <dl>
                      <dt>{t('teacher.info2')}</dt>
                      <dd>{classLangList.join(', ')}</dd>
                    </dl>
                    <dl>
                      <dt>{t('teacher.info3')}</dt>
                      <dd>{livingIn}</dd>
                    </dl>
                    <div className='txt pre'>
                      {slogan}
                    </div>
                  </Styled.Info>
                </Styled.ViewArea>
                <Styled.BtnView className={moreTeacher? 'hide' : ''}
                  onClick={()=>clickBtnMore(0)}
                >
                  {t('teacherDetail.btnMore')}
                </Styled.BtnView>
              </Styled.ViewBox>
            </Styled.InfoBox>
          </Styled.SectonBox>
        </Styled.Section>

        <Styled.Section id='AboutClass'>
          <H3>{t('teacherDetail.title2')}</H3>
          <Styled.SectonBox>
            <Styled.ViewBox>
              <Styled.ViewArea className={moreClass? 'active' : ''}>
                <div className='stitle'>{t('teacherDetail.aboutClass.title')}</div>
                <div className='txt pre'>
                  {teachingStyle}
                </div>
                <div className='stitle'>{t('teacherDetail.aboutClass.title2')}</div>
                <div className='txt pre'>
                  {teachingType}
                </div>
              </Styled.ViewArea>
              <Styled.BtnView className={moreClass? 'hide' : ''}
                onClick={()=>clickBtnMore(1)}
              >
                {t('teacherDetail.btnMore')}
              </Styled.BtnView>
            </Styled.ViewBox>
          </Styled.SectonBox>
        </Styled.Section>

        <Styled.Section id='ApplyClass'>
          <H3>{t('teacherDetail.title3')}</H3>
          <Styled.SectonBox>
            <Styled.ProductWrap>
              <div className='txt box'>
                <div className='first'><Trans t={t}>teacherDetail.apply.desc</Trans></div>
                <div className='num'><strong>1</strong><Trans t={t}>teacherDetail.apply.desc2</Trans></div>
                <div className='num'><strong>2</strong><Trans t={t}>teacherDetail.apply.desc3</Trans></div>
                <div className='num'><strong>3</strong><Trans t={t}>teacherDetail.apply.desc4</Trans></div>
                <div className='email'><Trans t={t}>teacherDetail.apply.desc5</Trans></div>
              </div>

              {/* 상품목록 */}
              <div>
                {products && products.length > 0
                ? products.map((product, index)=>{
                    return (
                      <ProductItem 
                        key={'productItem-'+index}
                        productIdx={product.tblProductIdx}
                        title={productTitleList[index]}
                        round={product.classRound}
                        duration={product.classDuration}
                        priceCost={priceCost[index]}
                        salesCost={salesCost[index]}
                        discountType={discountType[index]}
                        discountCost={discountCost[index]}
                        onClick={clickDetail}
                      />
                    );
                  })
                :<></>}
              </div>
            </Styled.ProductWrap>
          </Styled.SectonBox>
        </Styled.Section>

        <Styled.Section id='Availability'>
          <H3>{t('teacherDetail.title4')}</H3>
          <Styled.SectonBox>
            <div className='txt box'>
              <strong className='bl'>{teachingTime}</strong>
            </div>
          </Styled.SectonBox>
        </Styled.Section>

        <Styled.Section id='FAQ'>
          <H3>{t('teacherDetail.title5')}</H3>
          <Styled.SectonBox>
            <div className='stitle'>{t('teacherDetail.faq.title')}</div>
            <div className='txt box'>
              <ul>
                <li>1) {t('teacherDetail.faq.desc')}</li>
                <li>2) {t('teacherDetail.faq.desc2')}</li>
                <li>3) {t('teacherDetail.faq.desc3')}</li>
              </ul>
            </div>
            <div className='stitle'>{t('teacherDetail.faq2.title')}</div>
            <div className='txt box'>
              <ul>
                <li>1) {t('teacherDetail.faq2.desc')}</li>
                <li>2) <a href="mailto:mastertopik@visang.com">mastertopik@visang.com</a></li>
              </ul>
            </div>
          </Styled.SectonBox>
        </Styled.Section>

        </>:<></>}

        <Styled.ApplyWrap className={modal ? "" : "zIndex"}>
          <Styled.ApplyInner>
            <p className='msg'>{t('teacherDetail.btnApply.info')}</p>
            <Styled.BtnApply onClick={clickDetail}><span>{t('teacherDetail.btnApply.button')}</span></Styled.BtnApply>
          </Styled.ApplyInner>
        </Styled.ApplyWrap>

      </ContentsWrapper>
    </>
  );
};

export default TeacherDetailPage;

import ContentsWrapper from 'component/atoms/ContentsWrapper';
import styled from 'styled-components';

const StyledCommunityList = {
  ContentsWrapper: styled(ContentsWrapper)`
    padding-bottom: 16em;
    h3 {
      margin-bottom: 1em;
    }
  `,
  PopularContainer: styled.div`
    margin-bottom: 8em;
    overflow: hidden;
  `,
  PopularLeft: styled.div`
    float: left;
    width: 53em;
    /* width: calc(100% - 77em); */
    padding-right: 4em;

    @media (max-width: 1024px) {
      float: none;
      width: 100%;
    }
  `,
  PopularRight: styled.div`
    float: left;
    width: calc(100% - 53em);
    /* width: 77em; */

    @media (max-width: 1024px) {
      float: none;
      width: 100%;
      margin-top: 8em;
    }
  `,
};

export default StyledCommunityList;

import React, { useContext } from 'react';
import ProgressSlider from 'component/atoms/ReactSlider';
import { PlayerContext } from 'component/Player';

const ProgressBar = (props) => {
  const { playerState, handleState, playerRef } = useContext(PlayerContext);
  const { played, duration } = playerState;

  const handleBeforeChange = (value) => {};

  const handleChange = (value) => {};

  const handleAfterChange = (value) => {
    handleState({ played: value / duration });
    playerRef.current.seekTo(parseFloat(value / duration));
  };

  return (
    <ProgressSlider
      className='progress-bar'
      value={played * duration}
      max={duration}
      onBeforeChange={handleBeforeChange}
      onChange={handleChange}
      onAfterChange={handleAfterChange}
      isTime={true}
      {...props}
    />
  );
};

export default ProgressBar;

import React, { useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { useAxios } from "common/hook/useAxiosHook";
import bannerApi from "api/banner";
import { languageCode } from 'common/constant';
import SingleEventBanner from './EventBanner';
import MultiEventBanner from './MultiEventBanner';

import eventBannerImg from 'assets/images/event/220512/floating.png';
import eventBannerMobileImg from 'assets/images/event/220512/M_floating.png';
import eventBannerImgKo from 'assets/images/event/220512/ko/floating.png';
import eventBannerMobileImgKo from 'assets/images/event/220512/ko/M_floating.png';

import individualImg from 'assets/images/event/220512/individualPopup.png';
import individualImgKo from 'assets/images/event/220512/ko/individualPopup.png';

import eventBannerImg2 from 'assets/images/event/220321/floating.png';
import eventBannerMobileImg2 from 'assets/images/event/220321/M_floating.png';
import eventBannerImgKo2 from 'assets/images/event/220321/ko/floating.png';
import eventBannerMobileImgKo2 from 'assets/images/event/220321/ko/M_floating.png';

import twoWeekBannerEn from 'assets/images/event/230302/2week_banner_en.png';
import twoWeekBannerKo from 'assets/images/event/230302/2week_banner_ko.png';


const MainEventBanner = () => {
  const props = {
    linkUrl: '/event/mocktest',
    isBlank: false,
    imgSrc: eventBannerImg,
    imgSrcKo: eventBannerImgKo,
    mobileImgSrc: eventBannerMobileImg,
    mobileImgSrcKo: eventBannerMobileImgKo,
    imgAlt: 'masterTOPIK Alive TOPIK II Video Class',
    imgAltKo: 'masterTOPIK Alive TOPIK II 화상 수업',
  }
  return (
    <SingleEventBanner {...props} />
  );
}

const MockTestEventBanner = () => {
  const props = {
    btnColor: '#000',
    linkUrl: '/theme/4',
    isExternalLink: false,
    isBlank: true,
    imgSrc: individualImg,
    imgSrcKo: individualImgKo,
    mobileImgSrc: individualImg,
    mobileImgSrcKo: individualImgKo,
    imgAlt: 'masterTOPIK TOPIK Theme',
    imgAltKo: 'masterTOPIK TOPIK 테마',
  }
  return (
    <SingleEventBanner {...props} />
  );
}

const MainMultiEventBanner = () => {
  const COOKIE_KEY = 'MainFreetrialCookie';
  const language = localStorage.getItem('i18nextLng');

  const dataList = () => {
    switch (language) {
      case 'ko-KR':
        return [
          {
            linkUrl: '/event/freetrial',
            isExternalLink: false,
            isBlank: false,
            imgSrc: twoWeekBannerKo,
            mobileImgSrc: twoWeekBannerKo,
            imgAlt: '이용권 첫 구매라면 2주 무료',
          },];
      case 'en-US':
      case 'zh-CN':
      case 'ja-JP':
      default:
        return [
          {
            linkUrl: '/event/freetrial',
            isExternalLink: false,
            isBlank: false,
            imgSrc: twoWeekBannerEn,
            mobileImgSrc: twoWeekBannerEn,
            imgAlt: 'Everyone, Once! 2-week Free',
          },];
    }
  }

  const props = {
    btnColor: '#fff',
    cookieKey: COOKIE_KEY, //메인 이벤트 배너 쿠키
    bannerDataList: dataList(),
  }

  return (<>
    <CookiesProvider>
      <MultiEventBanner {...props} />
    </CookiesProvider>
  </>)
}

const MainMultiEventBannerWithAPI = () => {
  const COOKIE_KEY = 'MainEBCookie';
  const language = localStorage.getItem('i18nextLng');
  const [apiList, setApiList] = useState([]);
  const [dataList, setDataList] = useState(apiList);

  const [{ data: apiData }, refreshEventBanner] = useAxios(
    bannerApi.getEventBanner,
    [],
    true
  );

  const getLanguageCode = (lang) => {
    switch (lang) {
      case languageCode.KOREAN.locale:
        return languageCode.KOREAN.code;
      case languageCode.ENGLISH.locale:
      default:
        return languageCode.ENGLISH.code;
      case languageCode.CHINESE.locale:
        return languageCode.CHINESE.code;
      case languageCode.JAPANESE.locale:
        return languageCode.JAPANESE.code;
    }
  };

  useEffect(() => {
    const UpperLang = getLanguageCode(language).toUpperCase();
    refreshEventBanner(UpperLang);
  }, [])

  useEffect(() => {
    let list = [];
    if (apiData && apiData.eventBannerList) {
      apiData.eventBannerList.map((data) => {
        const obj = {
          linkUrl: data.bannerUrl,
          imgSrc: process.env.REACT_APP_ENV === 'local'
            ? `https://dev.mastertopik.com${data.filePath}/${data.fileName}`
            : `${data.filePath}/${data.fileName}`,
          mobileImgSrc: process.env.REACT_APP_ENV === 'local'
            ? `https://dev.mastertopik.com${data.filePath}/${data.fileName}`
            : `${data.filePath}/${data.fileName}`,
          imgAlt: data.bannerTitle,
        }
        list = [...list, obj];
      })
      setApiList([...list]);
    }
  }, [apiData])

  useEffect(() => {
    if (apiList && apiList.length > 0) {
      const hostReg = /mastertopik.com|localhost/gi;
      const urlReg = new RegExp(/^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/, 'i');
      /*
      urlReg.exec('https://www.mastertopik.com/theme/4?param=123#Related')

      $0: "https://www.mastertopik.com/theme/4?param=123#Related"
      $1: "https:"
      $2: "https"
      $3: "//www.mastertopik.com"
      $4: "www.mastertopik.com"
      $5: "/theme/4"
      $6: "?param=123"
      $7: "param=123"
      $8: "#Related"
      $9: "Related"
      */

      setDataList(apiList.map((data, index) => {
        const urlArr = urlReg.exec(data.linkUrl);

        if (urlArr && urlArr[4] && urlArr[4].match(hostReg)) {
          return {
            ...data,
            linkUrl: urlArr[5],
            isExternalLink: false,
            isBlank: false,
          }
        } else return {
          ...data,
          isExternalLink: true,
          isBlank: true,
        };
      }));
    }
  }, [apiList])

  const props = {
    btnColor: '#fff',
    cookieKey: COOKIE_KEY, //메인 이벤트 배너 쿠키
    bannerDataList: dataList,
  };

  return (<>
    <CookiesProvider>
      <MultiEventBanner {...props} />
    </CookiesProvider>
  </>)
}

export {
  SingleEventBanner as EventBanner,
  MultiEventBanner,
  MainEventBanner,
  MockTestEventBanner,
  MainMultiEventBanner,
  MainMultiEventBannerWithAPI,
}
import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import TermsOfPrivacy from './TermsOfPrivacy';
import TermsOfService from './TermsOfService';

const TermsPage = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={[path, `${path}/terms-of-service`]}
        component={TermsOfService}
      />
      <Route
        exact
        path={`${path}/terms-of-privacy`}
        component={TermsOfPrivacy}
      />
    </Switch>
  );
};

export default TermsPage;

import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/videoclass/mng';

/**
 * 
 */
const videoClassApi = {
  getMain: async () => {
    let response = await asyncApi({
      url: `${url}/page`,
    });
    return response.data;
  },
  getProducts: async () => {
    let response = await asyncApi({
      url: `${url}/product`,
    });
    return response.data;
  },
  getMyClass: async (userId) => {
    let response = await asyncApi({
      url: `${url}/myproduct`,
      params: {userId},
    });
    return response.data;
  },
  getTeacherDetail: async (teacherIdx) => {
    let response = await asyncApi({
      url: `${url}/teacherDetail`,
      params: {teacherIdx},
    });
    return response.data;
  },
  sendForm: async (userIdx, productIdx, orderID, stuEmail, learnSchedule, learnContent) => {
    let response = await asyncApi({
      url: `${url}/addOrderVideoclass`,
      params: {
        userIdx,
        productIdx,
        orderID,
        stuEmail,
        learnSchedule,
        learnContent
      },
    });
    return response.data;
    //result: "success"
    //result: "fail"
  },
  getApplication: async (orderID) => {
    let response = await asyncApi({
      url: `${url}/videoclassInfo`,
      params: {orderID},
    });
    return response.data;
    // /api/v1/videoclass/mng/videoclassInfo?orderID=OK22042014287108
  }
};

export default videoClassApi;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import defaultThumbnail from "assets/images/img_user_small.png";
import Img from "component/atoms/Img";

const Wrapper = styled.span`
  display: inline-block;
  font-size: 1rem;
  width: 3em;
  height: 3em;
  border-radius: 50%;
  vertical-align: middle;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

/**
 * 유저 작은 썸네일
 * @param {string=} props.src
 * @param {string=} props.alt
 * @param {string=} props.ClassName
 */
const UserThumbnail = ({ src, alt, className }) => {
  return (
    <Wrapper className={className}>
      <Img
        src={src ? src : defaultThumbnail}
        alt={alt ? alt : "user thumbnail"}
        onError={defaultThumbnail}
      />
    </Wrapper>
  );
};

UserThumbnail.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default UserThumbnail;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import InfiniteList from 'component/molecules/InfiniteList';
import VideoItem from 'component/molecules/VideoItem';
import iconUnChecked from 'assets/images/ico_checkbox03.png';
import iconUnCheckedLarge from 'assets/images/ico_checkbox03_large.png';
import iconChecked from 'assets/images/ico_checkbox04.png';
import iconCheckedLarge from 'assets/images/ico_checkbox04_large.png';
import { masterTopikUrl } from 'common/constant';

const Container = styled.span`
  position: relative;
  &.active .select-item {
    display: block;
  }
`;

const SelectItem = styled.span`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  label {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      background-position: center;
      background-size: 3em;
      background-image: url(${iconUnChecked});
      background-repeat: no-repeat;
      width: 4em;
      height: 4em;
      @media (max-width: 1024px) {
        background-image: url(${iconUnCheckedLarge});
      }
    }
  }
  input {
    position: absolute;
    top: -100px;
    left: -100px;
    z-index: -10;
  }
  input:checked ~ label:before {
    background-image: url(${iconChecked});

    @media (max-width: 1024px) {
      background-image: url(${iconCheckedLarge});
    }
  }
`;

const ThemeItemList = ({
  initialState,
  token,
  setIsLoading,
  selectableState,
  selectedListState,
}) => {
  const { id } = useParams();
  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [themeList, fetchThemeList] = initialState;
  const { loading, data, error } = themeList;

  // 최초 1회 호출
  useEffect(() => fetchThemeList({ id, page: 0, token }), []);

  useEffect(() => {
    if (data !== null) {
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
      setIsLoading(false);
    }
  }, [themeList]);

  const onNext = () => fetchThemeList({ id, page: data.number + 1, token });

  const [selectable] = selectableState;
  const [selectedList, setSelectedList] = selectedListState;
  const handleChange = ({ target }) => {
    if (target.checked) {
      setSelectedList([...selectedList, target.value]);
    } else {
      setSelectedList(selectedList.filter((item) => item !== target.value));
    }
  };

  return (
    <>
      <InfiniteList
        dataLength={slideItem.length}
        next={onNext}
        hasMore={hasMore}
      >
        {slideItem.map((item, index) => (
          <Container key={index} className={selectable && 'active'}>
            <VideoItem
              to={`/courses/detail/${item.id}`}
              imgSrc={masterTopikUrl + item.thumbnailPath + item.thumbnailTitle}
              title={item.courseTitle}
              desc={item.chapterLectureTitle}
              percentage={item.chapterLectureProgressPercent}
              isNew={item.isNew}
            />
            <SelectItem className='select-item'>
              <input
                type='checkbox'
                id={index}
                value={item.id}
                onChange={handleChange}
              />
              <label htmlFor={index} />
            </SelectItem>
          </Container>
        ))}
      </InfiniteList>
    </>
  );
};

export default ThemeItemList;

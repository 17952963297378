import React from 'react';
import SelectWrapper from './styled/SelectBox.style';
import Select from 'react-select';

const TermsSelectBox = ({
  onChange,
  optionList,
  width,
  className,
  placeholder,
}) => {
  return (
    <SelectWrapper width={width} className={className}>
      <Select
        placeholder={placeholder}
        options={optionList}
        onChange={(e) => onChange(e)}
        isSearchable={false}
        classNamePrefix='select'
      />
    </SelectWrapper>
  );
};

export default TermsSelectBox;

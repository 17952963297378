import styled from 'styled-components';
import changeIcon from 'assets/images/ico_change.png';
import { Link } from 'react-router-dom';

const StyledSubscription = {
  Container: styled.div`
    margin-bottom: 3em;
    padding: 4em 3em 1em 3em;
    border-radius: 0.6em;
    background-color: #1e2024;
    @media (max-width: 767px) {
      font-size: 1.2em;
    }
    .title {
      display: block;
      margin-bottom: 1.18em;
      color: #8b8f99;
      font-size: 1.6em;
      font-weight: 400;
      > .change {
        position: relative;
        float: right;
        padding-left: 1.625em;
        margin-bottom: 0.5em;
        margin-right: 0.625em;
        margin-top: -1.4em;
        color: #505256;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 1.125em;
          height: 1.125em;
          background: url(${changeIcon}) no-repeat center center/cover;
          transform: translateY(-50%);
        }
      }
    }

    .info {
      margin-bottom: 2.2em;
      padding-left: 0.55em;
      padding-bottom: 1em;
      border-bottom: 1px solid #2b2e35;
      font-size: 1.8em;
      font-weight: 300;

      strong {
        font-weight: 400;
      }
    }
  `,
  Title: styled.h3`
    margin-bottom: 1.75em;
    color: #8b8f99;
    font-size: 2em;
    font-weight: 700;
    letter-spacing: 0.1px;
  `,

  Badge: styled.span`
    display: inline-block;
    margin-right: 1.1em;
    padding: 0.2em 0.4em;
    border-radius: 0.4rem;
    background-color: ${({ color }) => color && color};
    font-size: 0.72em;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
  `,

  CancelBtn: styled(Link)`
    display: inline-block;
    width: 100%;
    margin-bottom: 2.18em;
    padding: 0.94em 0;
    border-radius: 0.375em;
    background-color: #2b2e35;
    text-align: center;
    color: rgba(255, 255, 255, 0.15);
    font-size: 1.6em;
    font-weight: 700;
    text-transform: uppercase;

    &.continue {
      color: #f2f2f2;
    }
  `,
};

export default StyledSubscription;

import { asyncApi } from 'common/util/asyncApi';

const pathName = '/api/v1/products';
const size = 12;

/**
 * getProduct 강좌 상품과 해당 교사 정보 조회
 * getCoursesOfProduct 강좌 상품의 강의 리스트 조회
 */
const productApi = {
  getProduct: async (productId, token) => {
    const response = await asyncApi({
      url: `${pathName}/${productId}/`,
      headers: token && { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getVideoClassProduct: async (productId, token) => {
    const response = await asyncApi({
      url: `${pathName}/${productId}/videoclass`,
      headers: token && { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getCoursesOfProduct: async (productId, token) => {
    const response = await asyncApi({
      url: `${pathName}/${productId}/course/videos?type=`,
      headers: token && { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getOverview: async (productId) => {
    const response = await asyncApi({
      url: `${pathName}/${productId}/course/overview`,
    });
    return response.data;
  },
  getCourseOfProductDetail: async (productId, access_token) => {
    let courseVideoData;
    const baseUrl = `${pathName}/${productId}`;
    if (access_token) {
      const config = {
        url: baseUrl + '/course/videos?type=detail',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      };
      courseVideoData = await asyncApi(config);
    } else {
      const config = {
        url: baseUrl + '/course/videos?type=detail',
      };
      courseVideoData = await asyncApi(config);
    }
    const response = courseVideoData.data;
    return response;
  }
};

export default productApi;

//import { SiteMapData } from 'common/constant';
//import UnavailablePlanModal from 'component/Modal/UnavailablePlanModal';
//import { ModalContext } from 'component/Modal/modalContext';
import CommunityCreate from 'container/CommunityPage/Create';
import CommunityDetail from 'container/CommunityPage/Detail';
import CommunityList from 'container/CommunityPage/List';
import CommunityRevise from 'container/CommunityPage/Revise';
import React from 'react';
//import { useSelector } from 'react-redux';
//import { Switch, useRouteMatch, Redirect, Route } from 'react-router-dom';
import { Switch, useRouteMatch, Route } from 'react-router-dom';

const CommunityRouter = () => {
  const { path } = useRouteMatch();
  //const { handleModal } = React.useContext(ModalContext);

  //const userData = useSelector((state) => state.user.data);
  //20211226 모든 사용자에게 오픈하기로하여 수정(박정우CP요청청)
  /*
  if (userData?.serviceLevel === 'FREE') {
    handleModal(<UnavailablePlanModal />);

    return <Redirect to={SiteMapData.plan} />;
  }
  */
  return (
    <Switch>
      <Route exact path={path} component={CommunityList} />
      <Route exact path={`${path}/revise`} component={CommunityRevise} />
      <Route exact path={`${path}/create`} component={CommunityCreate} />
      <Route exact path={`${path}/:id`} component={CommunityDetail} />
    </Switch>
  );
};

export default CommunityRouter;

import styled from "styled-components";
import { IconTextStyles } from "component/atoms/Icon";
import iconDelete from "assets/images/ico_delete.png";
import iconDeleteLarge from "assets/images/ico_delete_large.png";

const DeleteIcon = styled.span`
  ${IconTextStyles}
  padding-left: 2em;
  background-image: url(${iconDelete});
  background-position: -0.9em center;
  transition: background-image 0.3s ease;
  color: #8e9199;
  cursor: pointer;
  @media (max-width: 1024px) {
    background-image: url(${iconDeleteLarge});
    background-size: 38px;
  }
`;

export default DeleteIcon;

// Backend enum code
export const errorCode = {
  RESOURCE_NOT_FOUND: 'MT0001',
  USER_NOT_FOUND: 'MT002',
  ALREADY_EXISTS_ENTITY: 'MT0003',
  ALREADY_EXISTS_ID: 'MT0004',
  ALREADY_EXISTS_NICKNAME: 'MT0004',
  SERVICE_NOT_FOUND: 'MT005',
  VALUE_DOES_NOT_EXIST: 'MT0006',
  UNABLE_TO_FUNCTION: 'MT0010',
  MALFORMED_URL: 'MT0011',
  USER_DISABLED: 'MT0020',
  INVALID: 'MT0021',
  INVALID_TOKEN: 'MT0021',
  INVALID_CREDENTIALS: 'MT0021',
  INSUFFICIENT_PASSWORD_CHARACTERS: 'MT0022',
  INVALID_PASSWORD: 'MT0023',
  NO_SUCH_METHOD: 'MT0024',
  UNAUTHORIZED_REDIRECT_URI: 'MT0025',
  AUTHENTICATION_ERROR: 'MT0026',
  EXPIRED: 'MT0027',
  UNAVAILABLE_CONTENTS: 'MT0028',
  UNAUTHORIZED_REQUEST: 'MT0029',
  UNKNOWN: 'MT9999',
};

import styled from 'styled-components';
import { BsArrowRightShort } from 'react-icons/bs';
import StyledForm from 'component/form/Form.style';

const StyledModal = {
  Container: styled.div`
    .premium {
      color: #485cc7;
    }
    .standard {
      color: #69bd18;
    }
    .eps {
      color: #ffb500;
    }
    .free {
      color: #e26b13;
    }
  `,
  ChangePlan: styled.div`
    padding: 1em 0 1.5em;
    margin-bottom: 3em;
    text-align: center;
    border-radius: 0.8em;
    background-color: #fafafa;
    > span {
      display: inline-block;
      font-size: 3em;
      vertical-align: middle;
    }
  `,
  CurrentPlan: styled.span`
    font-weight: 400;
  `,
  CurrentPlanP: styled.p`
    display: inline-flex;
    flex-flow: column nowrap;
    font-size: 3em;
    vertical-align: middle;
    font-weight: 400;
  `,
  NewPlan: styled.span`
    font-weight: 600;
  `,
  NewPlanP: styled.p`
    display: inline-flex;
    flex-flow: column nowrap;
    font-size: 3em;
    vertical-align: middle;
    font-weight: 600;
  `,
  PlanSpan: styled.span`
    font-size: 0.8em;
    color: #2b2e35;
  `,
  Table: styled.table`
    width: 100%;
    margin-bottom: 3em;
    border: solid #5c5f66;
    border-width: 1px 0;
    table-layout: fixed;
    tr:not(:first-child) {
      border-top: 1px solid #c9cbd1;
    }
    th,
    td {
      height: 3.75em;
      padding: 0.625em 2em;
      font-size: 1.6em;
      vertical-align: middle;
    }
    th {
      font-weight: 500;
      text-align: left;
    }
    td {
      text-align: right;
    }
    .plan-name {
      display: inline-block;
      padding: 0 10px;
      line-height: 2.5rem;
      border-radius: 0.4rem;
      font-weight: 400;
      color: #fff;
      &.premium {
        background-color: #485cc7;
      }
      &.standard {
        background-color: #69bd18;
      }
      &.eps {
        background-color: #ffb500;
      }
      &.free {
        background-color: #e26b13;
      }
    }
  `,
  Arrow: styled(BsArrowRightShort)`
    display: inline-block;
    width: 3.5em;
    height: 3.5em;
    margin: 0 5px;
    vertical-align: middle;
    color: #d4d6dc;
  `,
  Desc: styled.p`
    font-size: 1.6em;
    padding-left: 10px;
    margin-bottom: 2px;
    text-indent: -10px;
    color: #5c5f66;
    &:last-of-type {
      margin-bottom: 3em;
    }
    &:before {
      content: '-';
      margin-right: 5px;
    }
    b {
      font-weight: 500;
      color: #2b2e35;
    }
    > div {
      display: inline;
    }
  `,
  Text: styled.p`
    font-size: 14px;
    text-align: center;
    color: #5c5f66 !important;

    &.premium b {
      color: #485cc7;
    }
    &.standard b {
      color: #69bd18;
    }
    &.eps b {
      color: #ffb500;
    }
    &.free b {
      color: #e26b13;
    }
  `,
  ButtonGroup: styled(StyledForm.ButtonGroup)`
    margin-top: 5em;
  `,
  ButtonGroupSingle: styled(StyledForm.ButtonGroup)`
    margin-top: 5em;
    text-align: center;
  `,
  Button: styled(StyledForm.Button).attrs({ type: 'button' })`
    &.gray {
      background-color: #9a9ba0;
      &.bordered {
        border: 1px solid #9a9ba0;
        background-color: transparent;
        color: #9a9ba0;
      }
    }
    &.primary {
      background-color: #485cc7;
    }
  `,
  ButtonSingle: styled(StyledForm.Button).attrs({ type: 'button' })`
    float: none !important;
    &.gray {
      background-color: #9a9ba0;
      &.bordered {
        border: 1px solid #9a9ba0;
        background-color: transparent;
        color: #9a9ba0;
      }
    }
    &.primary {
      background-color: #485cc7;
    }
  `,
  ButtonLink: styled(StyledForm.ButtonLink)``,
};

export default StyledModal;

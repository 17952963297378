import React, { useEffect } from 'react';
import styled from './styled/AccountSection.style';
import { useDispatch, useSelector } from 'react-redux';
import {
  AiFillFacebook,
  AiOutlineGoogle,
  AiOutlineWechat,
} from 'react-icons/ai';
import AccountSelect from 'container/SettingPage/AccoutSelect';
import { ModalContext } from 'component/Modal/modalContext';
import VerificationModal from 'container/SettingPage/VerificationModal';
import userApi from 'api/user';
import { useAxios } from 'common/hook/useAxiosHook';
import { actions } from 'component/user/state';
import { Trans, useTranslation } from 'react-i18next';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import useFetchInfo from 'common/hook/useFetchInfo';
import { Types as userTypes } from 'component/user/state/index';

const AccountSection = () => {
  const { t } = useTranslation('settingPage');
  const dispatch = useDispatch();

  const { handleModal } = React.useContext(ModalContext);
  const token = useSelector((state) => state.user.token);
  const snsIcons = {
    facebook: <AiFillFacebook />,
    google: <AiOutlineGoogle />,
    wechat: <AiOutlineWechat />,
  };

  const userData = useSelector((state) => state.user.data);

  const [
    { loading: updateLanguageLoading, data: updateLanguageStatus },
    refreshUpdateLanguage,
  ] = useAxios(userApi.updateUserLanguage, [], true);

  const editPassword = () => {
    handleModal(<VerificationModal />);
  };

  const changeLanguage = (e) => {
    refreshUpdateLanguage({ token, language: e.value });
  };

  const { isFetching: isFetchingUser } = useFetchInfo(userTypes.FetchUser);

  useEffect(() => {
    if (updateLanguageStatus !== 200) return;
    dispatch(actions.fetchUser(token));
  }, [dispatch, token, updateLanguageStatus]);

  return (
    <styled.Container>
      <LoadingOverlay isLoading={updateLanguageLoading || isFetchingUser} />

      <styled.Title>
        <Trans t={t}>userInfo.title</Trans>
      </styled.Title>

      {userData.snsName && (
        <>
          <span className='title'>SNS Login</span>
          <p className='info sns'>
            {snsIcons[userData.snsName]}
            {userData.snsName}
          </p>
        </>
      )}
      <span className='title'>
        {' '}
        <Trans t={t}>userInfo.email</Trans>
      </span>
      <p className='info'>{userData && userData.userID}</p>
      <span className='title'>
        <Trans t={t}>userInfo.password</Trans>{' '}
        {
          //sns로 로그인 하면 수정 못하게 변경
          !userData.snsName && (
            <span className='edit' onClick={() => editPassword()}>
              <Trans t={t}>userInfo.button</Trans>
            </span>
          )
        }
      </span>
      <p className='info'>
        {[1, 2, 3, 4, 5].map((data) => {
          return <styled.CircleSpan key={data} />;
        })}
      </p>
      <span className='title'>
        <Trans t={t}>userInfo.langSelector.title</Trans>
      </span>
      <div className='info select'>
        <AccountSelect data={userData} onChange={changeLanguage} />
      </div>
    </styled.Container>
  );
};

export default AccountSection;

import styled from "styled-components";
import { Link } from "react-router-dom";
import { ContentsWrapperStyles } from "component/atoms/ContentsWrapper";

const StyledFooter = {
  Wrapper: styled.footer`
    ${ContentsWrapperStyles}
    position: relative;
    padding-top: 6em;
    padding-bottom: 10em;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      width: 100vw;
      height: 1px;
      background-color: #4d4d4d;
      margin-left: -50vw;
    }
    @media (max-width: 767px) {
      padding-bottom: 14em;
    }
  `,
  Container: styled.div`
    @media (max-width: 1024px) {
      position: relative;
      padding-top: 5em;
      text-align: center;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 100vw;
        height: 1px;
        margin-left: -50vw;
        background-color: #222;
      }
    }
  `,
  Logo: styled(Link).attrs(() => ({
    to: "/",
  }))`
    float: left;
    width: 22em;
    @media (max-width: 1024px) {
      float: none;
      display: inline-block;
      width: 30em;
      margin: auto;
      opacity: 0.5;
    }
  `,
  Text: styled.p`
    float: left;
    /* margin-left: 1em; */
    font-size: 1.4em;
    color: #969696;
    @media (max-width: 1024px) {
      float: none;
      font-size: 2em;
      margin-left: 0;
      margin-top: 1em;
    }
    @media (max-width: 767px) {
      font-size:10px;
      color: #666666;
      text-align: left;
    }
    a {
      display: inline-block;
      margin: 0 0.5em;
      padding: 0.2em 0.6em;
      border-radius: 0.4rem;
      border: 1px #666 solid;
      svg {
        vertical-align: sub;
      }
      span {
        display: inline-block;
        margin: 0 1em;
        font-size: 0.5em;
        vertical-align: 0.5em;
      }
    }
    span {
      display: inline-block;
      margin: 0 1em;
      font-size: 0.5em;
      vertical-align: 0.5em;
    }
  `,
  TermsContainer: styled.div`
    margin-bottom: 1em;
  `,
  TermsLink: styled(Link)`
    font-size: 1.6em;
    color: #969696;
  `,
  copy: styled.div`
    font-size: 1.4em;
    color: #969696;
    margin-bottom: 1em;
    @media (max-width: 767px) {
      font-size:10px;
      color: #8e9199;
      margin-bottom: 25px;
    }
  `
};

export default StyledFooter;

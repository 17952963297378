import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PageHeader from 'component/atoms/PageHeader';
import DonutChart from './DonutChart';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import test1 from 'assets/images/mycurriculum_new/test1.png';
import test2 from 'assets/images/mycurriculum_new/test2.png';
import test3 from 'assets/images/mycurriculum_new/test3.png';
import test4 from 'assets/images/mycurriculum_new/test4.png';
import check from 'assets/images/mycurriculum_new/check.png';
import bookmark from 'assets/images/mycurriculum_new/icon_bookmark.png';
import bookmarkActive from 'assets/images/mycurriculum_new/icon_bookmark_active.png';
import bookmarkActiveCompete from 'assets/images/mycurriculum_new/icon_bookmark_active_complete.png';
import bookmarkCompete from 'assets/images/mycurriculum_new/icon_bookmark_complete.png';
import complete from 'assets/images/mycurriculum_new/icon_complete.png';
import ready from 'assets/images/mycurriculum_new/icon_ready.png';
import start from 'assets/images/mycurriculum_new/icon_start.png';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';
import productApi from 'api/product';
import { Link } from 'react-router-dom';



const MyCurriculumContents = ({ data }) => {

  const token = useSelector((state) => state.user.token);

  const [selectData, setSelectData] = useState()
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCourseId, setSelectedCourseId] = useState();


  const [totalLessonCount, setTotalLessonCount] = useState(0);
  const [totalItemsToShow, setTotalItemsToShow] = useState(5);

  const [courseResponse, fetchCourseData] = useAxios(
    productApi.getCourseOfProductDetail,
    [],
    true
  );

  const { data: courseData } = courseResponse;

  let count = 0;
  let startCheck = 0;

  const handleMoreClick = () => {
    if (totalItemsToShow >= totalLessonCount) {
      if (data && data.curriculumCourseResponses && data.curriculumCourseResponses.length > activeIndex) {
        handleItemClick(activeIndex + 1, data.curriculumCourseResponses[activeIndex + 1]);
      }
    } else {
      setTotalItemsToShow((totalItemsToShow) => totalItemsToShow + 5);
    }
  };

  const handleItemClick = (index, data) => {
    setActiveIndex(index);
    setSelectedCourseId(data.id);
    setSelectData(data);
    setTotalItemsToShow(5);
  };

  const [isMobile, setisMobile] = useState(false);
  const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    }
    window.addEventListener("resize", resizingHandler);
    return () => {
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);

  const breakpoints = {
    1024: {
      slidesPerView: 4,
    },
  };

  useEffect(() => {
    if (data && data.id && data.curriculumCourseResponses) {
      setSelectedCourseId(data.curriculumCourseResponses[0].id)
      setSelectData(data.curriculumCourseResponses[0])
    }
  }, [data]);

  useEffect(() => {
    if (selectedCourseId) {
      fetchCourseData(selectedCourseId, token);
    }
  }, [selectedCourseId])

  useEffect(() => {
    if (courseData) {
      var totalLesson = 0;
      courseData.forEach((product) => {
        totalLesson += product.lessonCount;
      });
      setTotalLessonCount(totalLesson);
    }
  }, [courseData])


  return (
    <>

      {data && data.id && selectData &&
        <>
          <PageHeader title={"My Curriculum Courses"} type={"mycurriculum"} />
          <CurrCourSliderWrap>
            <Swiper
              slidesPerView={2}
              spaceBetween={20}
              breakpoints={breakpoints}
            >
              {data.curriculumCourseResponses && data.curriculumCourseResponses.map((course, index) => (
                <SwiperSlide className={`slide ${index === activeIndex ? 'active' : ''}`} onClick={() => handleItemClick(index, course)} key={index}>
                  <div className='thum'>
                    <img src={check} alt="Check" className='check' />
                    <img src={course.thumbnailPath} alt={course.title} />
                  </div>
                  <h3>{course.title}</h3>
                </SwiperSlide>
              ))}
            </Swiper>
          </CurrCourSliderWrap>
          <CurrCourListsWrap>
            <CurrCourInfo>
              <CurrCourInfoLeft>
                {isMobile ? "" : <h3>{activeIndex + 1}. {selectData.title}</h3>}
                <ol>
                  <li>Teaches: {selectData.subjectTypeValue}</li>
                  <li>Target: {selectData.levelTypeValue}</li>
                  <li>Includes: {selectData.hour} Hours, {selectData.classCount} lessons</li>
                </ol>

              </CurrCourInfoLeft>
              <CurrCourInfoRight>
                {isMobile ? <h3>{activeIndex + 1}. {selectData.title}</h3> : ""}
                <DonutChartWrap>
                  <h3><span>{selectData.progressPercent}</span>%</h3>
                  <DonutChart percentage={selectData.progressPercent} />
                </DonutChartWrap>
                <p><span>{selectData.viewClassCount}</span>/{selectData.classCount} Days</p>
              </CurrCourInfoRight>
            </CurrCourInfo>

            {courseData?.map((group, groupIndex) => {
              let check = 0;

              if (count < totalItemsToShow) {
                return (
                  <CurrCourLists key={groupIndex}>
                    <CurrCourList>
                      <CurrCourListConWrap>

                        {group.lessonResponseList.map((lesson, itemIndex) => {
                          if (lesson.endDate && lesson.completed === true) {
                            const date = Date.parse(lesson.endDate);
                            const today = new Date();

                            if (date + (24 * 60 * 60 * 1000) <= today.getTime()) return (<></>);
                          }

                          if (lesson.completed === false){
                            startCheck++;
                          }

                          if (++count <= totalItemsToShow) {
                            return (
                              <>
                                {check++ === 0 && <CurrCourListTitle>{group.title}</CurrCourListTitle>}
                                <Link to={`/courses/detail/${selectedCourseId}/${lesson.chapterLectureId}`}>
                                <CurrCourListCon key={itemIndex}>
                                  <ListIcon className={lesson.completed === true ? 'complete' : lesson.lastPosition > 0 ? 'start' : ''}>
                                    <img src={lesson.completed === true ? complete : lesson.lastPosition > 0 ? start : ready} />
                                  </ListIcon>
                                  <ListTitle className={lesson.completed === true ? 'complete' : lesson.lastPosition > 0 ? 'start' : ''}>{lesson.title}</ListTitle>
                                  <ListState>
                                   <div class={`state ${lesson.completed === true ? 'complete' : lesson.lastPosition > 0 ? 'start' : (startCheck === 1) ? 'start' : ''}`}>{lesson.completed === true ? 'complete' : lesson.lastPosition > 0 ? 'start' : (startCheck === 1) ? 'start' : ''}</div>
                                  </ListState>
                                  <ListTime className={lesson.completed === true ? 'complete' : lesson.lastPosition > 0 ? 'start' : ''}>{lesson.time} m</ListTime>
                                  {/* <ListBookmark className={lesson.completed === true? 'complete' : lesson.progressPercent > 0 ? 'start' :''}>
                                  {lesson.completed === true
                                    ? <img src={lesson.bookmark === true ? bookmarkActiveCompete : bookmarkCompete} />
                                    : <img src={lesson.bookmark === true ? bookmarkActive : bookmark} />
                                  }
                                </ListBookmark> */}
                                </CurrCourListCon>
                                </Link>
                              </>

                            );
                          }
                        })}
                      </CurrCourListConWrap>
                    </CurrCourList>
                  </CurrCourLists>
                );
              }
            })}

            {courseData &&
              <MoreButton onClick={handleMoreClick}>
                {totalItemsToShow >= totalLessonCount ? 'Next Course' : 'See More'}
              </MoreButton>
            }
          </CurrCourListsWrap>
        </>
      }
    </>
  );
};

export default MyCurriculumContents;




const MyClassWrap = styled.div`
  display: flex;
  gap:3.4rem;
  margin-top:3rem;
`
const CurrCourSliderWrap = styled.div`
  margin-top:6rem;
  padding:3.5rem 3rem;
  background:#1e2024;
  border-radius: 5px;;
  .slide{
    /* width:295px; */

    .check{
      position:absolute;
      left:2rem;
      top:2rem;
      display: none;
    }
    .thum{
      width:100%;
      /* height:210px; */
      border-radius: 5px;
      overflow:hidden;;
      position: relative;
      img:not(.check){
        width:100%;
      }
    }
    h3{
      font-size:2rem;
      color:#fff;
      margin-top:2.5rem;
    }
    &.active{
      .thum{
        &::before{
          content:"";
          display:block;
          position: absolute;
          left:0;
          top:0;
          box-sizing: border-box;
          width:100%;
          height:100%;
          border:6px solid #ffb500;
        }
      }
      .check{display: block;}
    }
  }
  @media (max-width: 1024px) {
    margin-top:2rem;
    padding:0;
    background: none;
    .slide{
      h3{
        display: none;
      }
      &.active{
      .thum{
        &::before{
          border:3px solid #ffb500;
        }
      }
      .check{
        width:25px;
      }
    }
  }
  }
`
const CurrCourListsWrap = styled.div`
  padding:3.5rem 3rem;
  background:#1e2024;
  margin-top:3rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;;
  width:100%;
  @media (max-width: 1024px) {
   background:none;
   padding:0;
  }
  `
const CurrCourInfo = styled.div`
  display: flex;
  padding-bottom:2rem;
  border-bottom:1px solid #35373a;
  padding:0 4rem 3rem;
  width:100%;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    width:100%;
    background:#1e2024;
    padding:3.5rem 3rem;
    border-radius: 5px;
    border:none;
  }

`
const CurrCourInfoLeft = styled.div`
  flex:1;
  h3{
    font-size:2.2rem;
    color:#fff;
    font-weight:500;
  }
  ol{
    margin-top:2rem;
    display: flex;
    flex-direction: column;
    gap:0.7rem;
    li{
      color:#c9cbd1;
      font-size:1.6rem;
      padding-left:1.5rem;
      position: relative;

      &:before{
        content:"";
        position:absolute;
        left:0;
        top:1.1rem;
        width:0.5rem;
        height:0.5rem;
        border-radius: 0.5rem;
        background:#8e9199;
      }
    }
  }
`
const CurrCourInfoRight = styled.div`
  width:21rem;
  display: flex;
  justify-content: center;;
  flex-direction: column;
  align-items: center;
  p{
    color:#5c5f66;
    font-size:1.4rem;
    margin-top:1rem;
    display: flex;
    justify-content: center;;
    span{font-weight:700}
  }
  @media (max-width: 1024px) {
    width:100%;

    h3{
      font-size:2.2rem;
      color:#fff;
      font-weight:500;
      margin-bottom:25px;
    }
  }

`
const CurrCourLists = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap:2.5rem;
  width:100%;
`
const CurrCourList = styled.div`


  &.complete{}
`
const CurrCourListTitle = styled.h3`
  color:#ffffff;
  font-size:1.4rem;
  margin-bottom:1.3rem;
  &.complete{
      color:#5c5f66;

    }
`
const CurrCourListConWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap:1rem;
`
const CurrCourListCon = styled.div`
  display: flex;
  padding:0 4rem;
  height:6rem;
  align-items:center;
  background:#2b2e35;
  border-radius: 5px;;
  width:100%;
  gap:1rem;
  > div{
    display: flex;
    align-items: center;
    gap:1.3rem
  }
  @media (max-width: 1024px) {
    padding:1.6rem;
    height:auto;
    position: relative;
    min-height: 60px;
  }
`
const ListIcon = styled.div`
  width:4rem;
  align-items: center;
  justify-content: center;
`
const ListTitle = styled.h4`
  font-size:1.8rem;
  color:#fff;
  flex:1;
  &.complete{
      color:#5c5f66;

    }
`
const ListState = styled.div`
  width:12rem;
  align-items: center;
  justify-content: center;
  .state{
    font-size:1.4rem;
    width:11rem;
    height:2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.4rem;
    font-weight:700;
    text-transform: uppercase;
    &.complete{
      color:#4c4e55;
      border:1px solid #4c4e55;

    }
    &.start{
      background: #ffb500;;
      border:1px solid #ffb500;
      color:#000000;
      font-size:1.6rem;
    }
  }

  @media (max-width: 1024px) {
    width:75px;
    .state{
      height:20px;
      min-width:67px;
      padding:0 10px;
      width:auto;
      &.complete{
        min-width: 83px;
      }
      &.start{
      }
    }
  }

`
const ListTime = styled.div`
  width:9.5rem;
  justify-content: flex-end;
  padding-right:2rem;
  font-size:1.6rem;

  &.complete{
    color:#5c5f66;
  }


  @media (max-width: 1024px) {
    position: absolute;
    right:0;
    bottom:5px;
    &.complete{
      font-size:10px;
    }
  }
`
const ListBookmark = styled.div`
  width:4rem;
  align-items: center;
  justify-content: center;
  top:-5px;
  position: relative;
  @media (max-width: 1024px) {
    width:36px;
    justify-content: flex-end;

  }
`


const DonutChartWrap = styled.div`
  position: relative;
  width:9rem;
  height:9rem;
  display: flex;
  justify-content: center;;

  h3{
    color:#ffb500;
    font-weight:700;
    font-size:1.6rem;
    position: absolute;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);

  }

  @media (max-width: 1024px) {
    width:100%;
    height:160px;
    max-width:160px;
    h3{
      width:100px;
      font-size:24px;
      text-align: center;
      span{
        font-size: 50px;
      }

    }
  }

`

const MoreButton = styled.button`
  margin-top:2rem;
  width:17rem;
  height:5.4rem;
  border-radius: 5px;
  font-size:1.6rem;
  color:#fff;
  border: 1px solid #ffffff;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
  }
`;
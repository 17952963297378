import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Style from "./styled/index.style";
import { RiShareBoxLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { setAlarmCount as alarmAction } from "component/layout/Header/RightMenu/state/action";
import {
  BASE_URL,
  masterTopikUrl,
  MOBILE_APP_USER_AGENT,
} from "common/constant";

const NotificationList = ({ data, clickDeleteBtn, onclick }) => {
  const { hash: hashId, pathname } = useLocation();
  const dispatch = useDispatch();
  const alarmNo = useSelector((state) => state.alarm.alarmCount);
  const listRef = useRef();
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const {
    confirmDate,
    receiveDate,
    memberNoticeIdx,
    notification: { noticeTitle, noticeContent, linkUrl },
  } = data;

  // www 없으면 링크 앞에 "//" 넣어줌.
  // const link = linkUrl.includes("www") ? linkUrl : "//" + linkUrl;

  const openNotification = async () => {
    setOpen((prev) => !prev);
    if (!confirmDate) {
      await onclick(memberNoticeIdx);
      dispatch(alarmAction(alarmNo - 1));
    }
  };

  const onClick = (e, url) => {
    const userAgent = window.navigator.userAgent;

    e.preventDefault();
    if (!url) {
      alert("url is null");
      return;
    }
    if (userAgent !== MOBILE_APP_USER_AGENT) {
      if (url?.includes("http") || url?.includes("https")) {
        window.open(url);
      } else {
        window.open(`https://${url}`);
      }
    } else if (userAgent === MOBILE_APP_USER_AGENT) {
      const matchesExternalUrl =
        window.location.origin.split("/")[2] !== url.split("/")[2];
      if (!matchesExternalUrl) {
        const customPathname = url.split("mastertopik.com")[1];
        history.push(`${customPathname}`);
        return;
      } else if (matchesExternalUrl) {
        window?.WebInterface?.movetoAndroidBrowser(matchesExternalUrl, url);
        return;
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (hashId && hashId === `#notification-${memberNoticeIdx}`) {
      const headerH = document.querySelector("header").getBoundingClientRect()
        .height;
      const listTop = listRef.current.getBoundingClientRect().top;
      const scrolledTop = listTop + window.pageYOffset - headerH;
      window.scrollTo(0, scrolledTop);
      setTimeout(() => openNotification(), 100);
    }
  }, [memberNoticeIdx, hashId]);

  return (
    <Style.List ref={listRef}>
      <div
        className="notification-heading"
        onClick={openNotification}
        id={`notification${memberNoticeIdx}`}
      >
        {clickDeleteBtn && (
          <div className="notification-input">
            <input
              id={"notification-checkbox" + memberNoticeIdx}
              type="checkbox"
            />
            <label htmlFor={"notification-checkbox" + memberNoticeIdx} />
          </div>
        )}
        <div
          className={
            confirmDate
              ? " notification-content"
              : "notification-content unread"
          }
        >
          <p>{noticeTitle}</p>
          <span>{receiveDate.slice(0, 10)}</span>
        </div>
        <span>
          <Style.DropDownArrow size="3em" open={open} />
        </span>
      </div>
      <div className={open ? "dropdown-content open" : "dropdown-content"}>
        {noticeContent}
        <br />
        <button
          className="link"
          // to={linkUrl}
          // target="_blank"
          // rel="noreferrer"
          onClick={(e) => onClick(e, linkUrl)}
        >
          Link <RiShareBoxLine size="1.3em" />
        </button>
      </div>
    </Style.List>
  );
};

export default React.memo(NotificationList);

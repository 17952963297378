import React, { useEffect } from "react";
import styled from "styled-components";
import ContentsWrapper from "component/atoms/ContentsWrapper";
import Icon from "assets/images/ico_dormantAccount.png";
import Img from "component/atoms/Img";
import ResetPasswordModal from "component/atoms/DormantAccount/ResetPasswordModal";
import { ModalContext } from "component/Modal/modalContext";
import { useAxios } from "common/hook/useAxiosHook";
import userApi from "api/user";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const Style = {
  Container: styled.div`
    margin-top: 10em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  Title: styled.h3`
    margin: 1.17em 0 0.88em;
    font-size: 3.4em;
    font-weight: 700;
  `,
  Desc: styled.p`
    text-align: center;
    color: #8e9199;
    font-size: 2em;
    font-weight: 400;
  `,
  Button: styled.button`
    width: 22%;
    min-width: 15em;
    margin: 3.5em 0 4.1em;
    padding: 1.1em 0;
    border-radius: 0.6rem;
    background-color: #ffb500;
    color: #fff;
    font-size: 1.6em;
    font-weight: 700;
  `,
  Policy: styled.p`
    background-color: #191b1f;
    text-align: center;
    margin-bottom: 12em;
    padding: 1.6em 4em;
    color: #5c5f66;
    font-size: 1.4em;
    font-weight: 400;
    @media (max-width: 767px) {
      padding: 1.6em 2em;
    }
  `,
};

const DormantAccountPage = () => {
  const { handleModal } = React.useContext(ModalContext);
  const history = useHistory();
  const { data } = useSelector((state) => state.user);
  const activateAccount = () => {
    handleModal(<ResetPasswordModal />);
  };

  useEffect(() => {
    const { isRest, snsName } = data;
    if (snsName === null && !isRest) {
      history.replace("/");
    }
  }, [data.isRest]);

  return (
    <ContentsWrapper>
      <Style.Container>
        <Img src={Icon} alt="Dormant Account Image" />
        <Style.Title>회원님의 계정은 휴면상태입니다.</Style.Title>
        <Style.Desc>
          1년 이상 masterTOPIK을 이용하지 않아 휴면 상태로 전환되었습니다.
          <br />
          휴면상태를 해제하시겠습니까?
        </Style.Desc>
        <Style.Button onClick={activateAccount}>휴면상태 해제하기</Style.Button>
        <Style.Policy>
          masterTOPIK은 1년 이상 서비스를 이용하지 않은 경우 회원님의 아이디를
          휴면상태로 변경하고 개인정보를 안전한 공간에 분리하여 보관합니다.
          <br /> [정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조]
        </Style.Policy>
      </Style.Container>
    </ContentsWrapper>
  );
};

export default DormantAccountPage;

import styled from 'styled-components';
import { Swiper } from 'swiper/react';

const StyledVideoList = {
  Container: styled.div`
    position: relative;
    .slider-prev,
    .slider-next {
      top: 7em;
    }
  `,
  Swiper: styled(Swiper)`
    @media (max-width: 1024px) {
      overflow: visible;
    }
  `,
};

export default StyledVideoList;

import notificationType from './type';

const initialState = {
  alarmCount:0
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case notificationType.Set_Number:
      return {
        ...state,
        alarmCount: action.payload,
      };
    case notificationType.Delete_All:
      return {
        ...state,
        alarmCount: 0,
      };

    default:
      return state;
  }
};

export default notificationReducer;

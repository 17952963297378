import React from 'react';
import PropTypes from 'prop-types';
import Styled from './index.style';
import thumbnailDefault from 'assets/images/thumb_default02.jpg';
import { masterTopikUrl } from 'common/constant';

/**
 * Voca list item - 제목 최대 2줄, 여백 미포함
 * @param {*} param
 * @param {string} param.href 링크 주소
 * @param {string=} param.imgSrc 이미지 주소
 * @param {string} param.title 제목 2줄 초과 시, 말줄임표
 * @param {string=} param.className set wrapper className ( title 및 subtitle에도 접두사로 className 생성됨 )
 */
const VocaItem = ({ href, imgSrc, title, className }) => {
  return (
    <Styled.Wrapper className={className}>
      <a href={href} target='_blank' rel='noopener noreferrer'>
        <Styled.Img
          src={imgSrc ? imgSrc : thumbnailDefault}
          alt={title}
          onError={thumbnailDefault}
        />
        <Styled.Title className={className && className + '-title'}>
          {title}
        </Styled.Title>
      </a>
    </Styled.Wrapper>
  );
};

VocaItem.propTypes = {
  href: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default VocaItem;

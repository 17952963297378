import styled from 'styled-components';

export const ListStyle = {
  ItemWrap: styled.ul`
    &.main {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2em;
    }
    &.main li {
      width: calc(50% - 1em);
    }
    @media (max-width: 1024px){
      &.main {
        display: block;
      }
      &.main li {
        width: calc(100% + 40px);
      }
    }
  `,
  Item: styled.li`
    margin-bottom: 1.2em;
    border: 1px solid #2b2e35;
    border-radius: 0.6em;

    @media (max-width: 1024px){
      width: calc(100% + 40px);
      transform: translateX(-20px);
      margin-bottom: 0;
      border: none;
      border-bottom: 1px solid #2b2e35;
      border-radius: 0;
      &:first-child {
        border-top: 1px solid #2b2e35;
      }
    }
    a {
      display: block;
      padding: 3em;
      &:hover p {
        text-decoration: underline;
      }
      @media (max-width: 1024px){
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  `,

  TitleWrap: styled.div`
    display: flex;
  `,

  Category: styled.span`
    flex-shrink: 0;
    display: inline-block;
    margin-right: 0.7em;
    width: 5.2em;
    padding: 0.2em;
    border-radius: 0.36em;
    background: #393c40;
    text-align: center;
    font-weight: 700;
    font-size: 1.4em;
    color: #c9cbd1;
  `,

  Title: styled.p`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.8em;
    color: #fff;
    &.recom::before {
      content: '★ ';
    }
  `,

  Desc: styled.div`
    margin-top: 0.5em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 1.8em;
    color: #8e9199;
  `,

  // main list
  MainListWrap: styled.div`
    position: relative;
  `,

  CategoryWrap: styled.div`
    position: relative;
    margin-bottom: 2.7em;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 2em;
    gap: 0.8em;
  `,

  BtnCategory: styled.button`
    padding: 0.5em 1.2em;
    border: 1px solid #1e2024;
    background-color: #1e2024;
    border-radius: 2em;
    font-weight: 300;
    font-size: 1.6em;
    color: #8e9199;
    &.on {
      border-color: #485cc7;
      background-color: #485cc7;
      font-weight: 400;
      color: #fff;
    }
  `,

  MainListBtn: styled.div`
    position: absolute;
    right: 0;
    top: 1em;
    @media (max-width: 1024px) {
      position: relative;
      margin-top: 4em;
      text-align: center;
    }
  `,

  ButtonMore: styled.button`
    display: inline-block;
    border-bottom: 1px solid #f3a704;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.4em;
    color: #f3a704;
    @media (max-width: 1024px){
      width: 14em;
      height: 3em;
      line-height: 3em;
      background: #2a2c2e;
      border-radius: 0.375em;
      overflow: hidden;
      border: none;
      text-transform: none;
      font-weight: 400;
      font-size: 1.8em;
      color: #fff;
    }
  `,
}

export default ListStyle;

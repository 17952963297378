import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { BsPlus } from 'react-icons/bs';
import { StyledForm } from 'component/form/Form.style';

import watchedImage from 'assets/images/ico_watched_large.png';
import ico_time from 'assets/images/ico_time.png';
import completedImage from 'assets/images/ico_completed_large.png';

import { BiCheck } from "react-icons/bi";

const StyledProfile = {
  Container: styled.div`
    background-color: #1e2024;
  `,
  ImageContainer: styled.div`
    float: left;
    position: relative;
    width: 19em;
    margin: 7em 0 3em;
    @media (max-width: 767px) {
      float: none;
      text-align: center;
      margin-top: 5em;
    }
    > .profile_img {
      width: 15em;
      height: 15em;
      border-radius: 60%;
      object-fit: cover;
    }
  `,
  ProfileInfo: styled(ContentsWrapper)`
    &::after {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  `,
  ProfileContent: styled.div`
    display: flex;
    float: left;
    width: calc(100% - 19em);
    margin: 9em 0 3em;

    @media (max-width: 767px) {
      flex-direction: column;
      float: none;
      margin: 2em 0 1em;
      width: auto;
    }
  `,

  User: {
    Info: styled.div`
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:not(:last-child) {
        border-right: 1px #3d4045 solid;
        @media (max-width: 767px) {
          border: none;
        }
      }
      @media (max-width: 767px) {
        margin-bottom: 2.5em;
        display: inline-block;
        text-align: left;
      }
    `,
    Item: styled.div`
      position: relative;
      display: inline-block;
      padding-left: 7em;
      /* min-width: 28em; */
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0em;
        width: 3.6em;
        height: 3.6em;
        transform: translateY(-50%);
      }
      &.view_time {
        &::before {
          background: url(${ico_time}) center center/cover;
        }
      }
      &.class_watched {
        &::before {
          background: url(${watchedImage}) center center/cover;
        }
      }
      &.class_completed {
        &::before {
          background: url(${completedImage}) center center/cover;
        }
      }

      @media (max-width: 767px) {
        display: flex;
        padding-left: 4.5em;
        justify-content: space-between;
        align-items: center;

        &::before {
          width: 3em;
          height: 3em;
        }
      }
    `,
    Title: styled.div`
      color: #5f6268;
      font-size: 1.4em;
      font-weight: 400;

      @media (max-width: 767px) {
        font-size: 2em;
      }
    `,
    Desc: styled.div`
      color: #fff;
      font-size: 4em;
      font-weight: 500;

      @media (max-width: 767px) {
        text-align: right;
        font-size: 3em;
        margin-left: 1em;
      }
    `,
  },

  InputFile: styled(BsPlus)`
    position: absolute;
    bottom: 1em;
    right: 4em;
    width: 3.5em;
    height: 3.5em;
    padding: 0.3em;
    background: #ffb500;
    border-radius: 50%;
    cursor: pointer;
  `,

  Label: styled(StyledForm.Label)`
    color: #838791;
    font-size: 1.6em;
    font-weight: 400;
  `,
  FormContainer: styled(StyledForm.FormWrapper)`
    margin: 10em auto 17em;
    max-width: 79.4em;
    padding: 0 2em;
    > div {
      margin-bottom: 5em;
    }
    @media (max-width: 767px) {
      margin: 5em auto 10em;
    }
  `,
  InputContainer: styled(StyledForm.InputContainer)``,
  Input: styled(StyledForm.Input)`
    padding-left: 0.5em;
    background-color: #2b2e35;
    color: #c7cad1;
    font-weight: 400;

    &:focus {
      background-color: #2b2e35;
    }

    &::placeholder {
      color: #c7cad1;
      font-weight: 400;
    }
  `,
  PhoneInput: styled(StyledForm.Input)`
    padding-left: 0.5em;
    background-color: #2b2e35;
    color: #c7cad1;
    font-weight: 400;
    margin-top: 0.5em;

    &:focus {
      background-color: #2b2e35;
    }

    &::placeholder {
      color: #c7cad1;
      font-weight: 400;
    }
  `,
  NameContainer: styled.div``,
  GenderContainer: styled.div`
    > div {
      width: 13.93em;
    }
  `,
  DateContainer: styled.div`
    &::after {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }
    > div {
      float: left;
      width: 32.33%;
      margin-left: 1.5%;
      &:first-of-type {
        margin-left: 0;
      }
    }
  `,
  Select: styled(StyledForm.Select)`
    .select__control {
      background-color: #2b2e35;
    }
    > .select__menu,
    .select__option,
    .select__menu-list {
      background-color: #2b2e35;
    }
    .select__option:hover {
      background: rgba(0, 0, 0, 0.4);
    }
    .css-b8ldur-Input {
      color: #c7cad1;
    }
    .select__indicator {
      svg {
        color: #8b8f99;
      }
    }
    .select__placeholder,
    .select__single-value {
      color: #c7cad1;
      font-weight: 400;
    }
  `,
  Button: styled(StyledForm.Button)``,

  CheckContainer: styled.div`
  `,
  GoalTitle: styled.div`
    margin-bottom: 0.3em;
    color: #838791;
    font-size: 1.6em;
    font-weight: 400;
  `,
  InputWrap: styled.div`
    position: relative;
    padding: 1.8em 1.5em;
    border-bottom: 1px solid #2b2e35;
  `,
  InputLabel: styled.label.attrs(({ children }) => ({
    children: (
      <>
        <BiCheck className='icoCheck'/>
        {children}
      </>
    ),
  }))`
    position: relative;
    display: block;
    padding-left: 2.4em;
    text-align: left;
    font-weight: 400;
    line-height: 1.25;
    font-size: 1.6em;
    color: #c7cad1;
    &[for] {
      cursor: pointer;
    }
    .icoCheck {
      position: absolute;
      top: 0;
      left: 0;
      width: 1.25em;
      height: 1.25em;
      border: 1px solid #c9cbd1;
      border-radius: 2px;
      vertical-align: middle;
      color: transparent;
    }
  `,
  InputCheck: styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    opacity: 0;
    z-index: -1;
    &:checked + label[for] {
      font-weight: 600;
    }
    &:checked + label[for] .icoCheck {
      color: #c9cbd1;
    }
  `,
};

export default StyledProfile;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAxios } from 'common/hook/useAxiosHook';
import vocabulariesApi from '../../api/vocabularies';
import VocaItem from 'component/molecules/VocaItem';
import InfiniteList from 'component/molecules/InfiniteList';
import OrderBySelect from 'component/molecules/OrderBySelect';
import Error from 'component/Error';
import PageHeader from 'component/atoms/PageHeader';
import VocaCardEmptyPage from './VocaCardEmptyPage';

import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';

const Styled = {
  SelectContainer: styled.div`
    display: inline-block;
    width: 100%;
    margin: -3em 0 2em;
    .order-by-select {
      float: right;
    }

    @media (max-width: 767px) {
      margin-top: 2em;
    }
  `,
};

const VocaCard = ({ isMyPage = false }) => {
  const { t } = useTranslation('resourcesPage');
  const vocaOrderByData = [
    { label: t('vocaCardTab.selectLevel.option1'), value: null },
    { label: t('vocaCardTab.selectLevel.option2'), value: 101 },
    { label: t('vocaCardTab.selectLevel.option3'), value: 201 },
    { label: t('vocaCardTab.selectLevel.option4'), value: 301 },
    { label: t('vocaCardTab.selectLevel.option5'), value: 901 },
  ];

  const [vocaList, fetchVocalist] = useAxios(vocabulariesApi.getList, [], true);
  const { data, loading, error } = vocaList;

  const [orderByLevel, setOrderByLevel] = useState(null);
  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const onNext = () => {
    fetchVocalist({ level: orderByLevel, page: data.number + 1 });
  };

  const handleChange = ({ value }) => {
    if (orderByLevel !== value) {
      setOrderByLevel(value);
      setSlideItem([]);
    }
  };

  useEffect(() => {
    if (data !== null) {
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [vocaList]);

  useEffect(() => {
    fetchVocalist({ level: orderByLevel, page: 0 });
  }, [orderByLevel]);

  if (error) return <Error />;

  return (
    <>
      <SeoHelmet title={SeoData.vocaCard.title} desc={SeoData.vocaCard.desc} />
      {isMyPage && (
        <PageHeader
          title={t('vocaCardTab.title')}
          text={t('vocaCardTab.subtitle')}
        />
      )}
      <Styled.SelectContainer>
        <OrderBySelect
          onChange={handleChange}
          optionList={vocaOrderByData}
          width='14.5em'
          className='order-by-select'
        />
      </Styled.SelectContainer>

      {data?.content.length === 0 && <VocaCardEmptyPage />}

      <InfiniteList
        dataLength={slideItem.length}
        next={onNext}
        hasMore={hasMore}
      >
        {slideItem.map(({ url, thumbnail, title }, index) => (
          <VocaItem
            key={index}
            href={url}
            imgSrc={thumbnail}
            title={title}
            className='voca-item'
          />
        ))}
      </InfiniteList>
    </>
  );
};

export default React.memo(VocaCard);

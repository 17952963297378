import React from 'react';

export const termsOfPrivacyEn = () => (
  <div class='policy'>
    <h3 class='policy-title'>Privacy Policy</h3>

    <p>
      VISANG EDUCATION INC. (hereinafter referred to as “Company”) collects,
      uses, and provides the personal information upon the consent of the User.
      The Company complies with all related laws including [Personal Information
      Protection Act] and [Act on Promotion of Information and Communication
      Network Utilization and Information Protection, Etc.] to protect the
      personal information of its Member.{' '}
    </p>

    <p>
      This Privacy Policy covers the purpose and method on how the Company uses
      the personal information provided by the User in relation to masterTOPIK
      service and how the Company manages the personal information to protect
      the personal information.
    </p>

    <p>
      The Privacy Policy of the Company may change depending on changes in
      domestic laws and guidelines and changes in the Company Policy.
    </p>

    <dl class='policy-subject'>
      <dt>·Table of Contents : </dt>
      <dd>Article 1. Purpose of Use of Personal Information</dd>
      <dd>Article 2. Items of Personal Information to be Collected</dd>
      <dd>Article 3. Sharing and Provision of Personal Information</dd>
      <dd>Article 4. Entrustment of Personal Information Management</dd>
      <dd>Article 5. Period of Retention and Use of Personal Information</dd>
      <dd>
        Article 6. Procedures and Method of Personal Information Destruction
      </dd>
      <dd>
        Article 7. Right of User and Legal Representative and Exercising Method
      </dd>
      <dd>
        Article 8. Installation and Operation of Automatic Personal Information
        Collection System and Refusal
      </dd>
      <dd>
        Article 9. Technical and Administrative Personal Information Protection
        Measures
      </dd>
      <dd>
        Article 10. Personal Information Protection Manager and Civil Service
      </dd>
      <dd>Article 11. Amendment on Privacy Policy and Announcements</dd>
    </dl>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 1. Purpose of Use of Personal Information
      </h4>

      <p>
        The Company uses the personal information for the following purposes
        including management of Member, service provision, service development,
        and service improvement. The Company obtains a separate consent in case
        of using the personal information for marketing and advertisements. The
        Company doesn’t restrict on use of service when the Member rejects to
        give his/her consent.{' '}
      </p>

      <p class='policy-tit'>(1) Member Management</p>
      <p>
        Identification, prevention of unlawful use and unauthorized use by
        suspended Member (Member who is suspended for violating Article 7 of
        Terms of Service of masterTOPIK), confirmation on intention to sign up,
        preservation of record for a dispute mediation, civil affairs handling,
        and notification
      </p>

      <p class='policy-tit'>
        (2) Performance of Service Provision Contract and Charges for Provision
        of Service
      </p>
      <p>
        Provision of goods and contents, provision of customized service,
        issuance of bill and purchase history, provision of synchronization
        service between devices, identification, purchase and payment, shipping
        of goods, etc.
      </p>

      <p class='policy-tit'>
        (3) Development of New Service and Utilization on Marketing
      </p>
      <p>
        Development of new service, provision of customized service for
        statistical characteristics and using pattern, posting of advertisement,
        identification of access frequency, and statistics on use of service by
        the Member{' '}
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 2. Items of Personal Information to be Collected{' '}
      </h4>

      <p>
        The Company collects following personal information for sign up, service
        application and provision, and smooth customer service. There may be
        restriction on using the masterTOPIK service when the Member doesn’t
        provide the information or request on deleting the personal information.{' '}
      </p>

      <div class='policy-sec'>
        <h5 class='policy-sec-tit'>1. Signing Up</h5>
        <p>
          The Company collects following personal information for signing up and
          the Company uses and holds the following information for the specified
          period according to the related statutes (Article 5. Retention Period
          in Privacy Policy on masterTOPIK).{' '}
        </p>

        <p class='policy-tit'>(1) A Member who joins via email</p>
        <ul class='policy-bu'>
          <li>
            ·Required Information: Name, Email Address, Password, Nationality
          </li>
        </ul>

        <p class='policy-tit'>(2) A Member who joins via SNS</p>
        <ul class='policy-bu'>
          <li>· Required Information: SNS Profile, Email Address, Password</li>
        </ul>
      </div>



      <div class='policy-sec'>
        <h5 class='policy-sec-tit'>2. Adding a profile</h5>
        <ul class='policy-bu'>
          <li>· Optional: Nickname, Date of Birth, Gender, Nationality, Consent on Receiving Marketing Promotions<br />(Modified directly by the member on the profile page)
          </li>
        </ul>
      </div>

      <div class='policy-sec'>
        <h5 class='policy-sec-tit'>3. Automatically Collected Items</h5>
        <p>
          The Company collects following information including “Cookie” when the
          User uses masterTOPIK service.{' '}
        </p>

        <p class='policy-tit'>(1) Site</p>
        <p>
          The Company collects the User’s IP address, browser type, browser
          language, operation system, software and hardware property (including
          device ID) reference, exit page and URL, number of views, viewed page
          and order of view, date and time of using the site, contents watched
          by the User, total watching hours, error log, and other information
          related to methods of using the site.{' '}
        </p>

        <p class='policy-tit'>(2) Mobile App</p>
        <p>
          The Company automatically receives IP address, operation system,
          version, specific information on cell phone, tablet, or computer
          including Internet service provider used for accessing to the mobile
          app, browser type, FCM Token, domain name and other related
          information, mobile app update status and the last update time, date
          and time using the mobile app, contents watched by User, total
          watching hours, error log, and other information related to methods of
          using the mobile app by the User of masterTOPIK Mobile App.{' '}
        </p>

        <p class='policy-tit'>(3) Location Information</p>
        <p>
          The Company may automatically collect the general location information
          (example : IP address, city/province or IP address and related zip
          code) from the computer or mobile device of the User.{' '}
        </p>
      </div>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 3. Sharing and Provision of Personal Information
      </h4>

      <p>
        The Company shall not use the personal information for any purposes
        other than the collection and use of personal information and shall not
        provide the personal information to the Third Party without the consent
        of the Member unless otherwise obtaining the consent of the Member or
        any of the following cases.{' '}
      </p>

      <p>
        The User may choose not to consent to the provision of the personal
        information to the Third Party and may withdraw the consent on providing
        the personal information at any time. Provided, the User may be
        restricted in using some of the services provided by the Third Party.{' '}
      </p>

      <ul class='policy-bu'>
        <li>
          ·Where the Member consents on disclosing and providing the personal
          information to Third Party in advance;
        </li>
        <li>
          ·Where sharing and provision of personal information are required by
          the investigation agency in accordance with the provisions in the
          statute or procedures and methods specified in the statute for the
          investigation;
        </li>
        <li>
          ·Where sharing and provision of personal information are necessary for
          the charges on the provided service;
        </li>
        <li>
          ·Where the personal information is necessary for compiling statistics,
          scientific research purposes, or market survey, and the personal
          information is provided in a form by which a specific individual
          cannot be identified;
        </li>
        <li>
          ·Where the personal information is necessary for performing a contract
          on provision of information communication service and where it is
          impractical to obtain the personal information on a regular basis for
          financial or technical reason;
        </li>
        <li>
          ·Where there exist reasons related to transfer and merger of the
          business (Provided, where the personal information of the User is
          transferred for the reasons such as transfer of business, etc., the
          Company shall notify the facts concerning the transfer of personal
          information according to the procedures and methods regulated in the
          related Acts in advance and shall grant the User of the right to
          withdraw the consent that he/she gave on the transfer of personal
          information.)
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 4. Entrustment of Personal Information Management
      </h4>

      <p>
        For the smooth and convenient service, the Company entrusts the personal
        information to the external specialized company within the minimum
        limited extent. In signing the entrustment contract, the Company
        separately regulates on the related affairs to manage the personal
        information safely.{' '}
      </p>

      <p class='policy-tit'>(1) Trans-Border Transfer of Information </p>
      <p>
        According to this Policy, the Company transfers, saves, and manages the
        personal information within and outside the country of residence and the
        personal information is especially transferred to “Republic of Korea”
        for the use of service. The personal information and other Acts of the
        country where the User’s personal information is sent to may not be as
        comprehensive as the Acts of the User’s country of residence.
      </p>

      <p class='policy-tit'>(2) Payment</p>
      <ul class='policy-bu'>
        <li>·Entrusted Company : Payletter Inc., Paypal</li>
        <li>
          ·Entrusted Affairs : Provide Payment and Purchase Safety Service
        </li>
        <li>·Collected Items: Card Company Name, Card Number, Etc.</li>
        <li>
          ·Period of Retention and Use : Until Account Deletion and Entrustment
          Contract Expiration
        </li>
      </ul>

      <p class='policy-tit'>(3) Shipping</p>
      <ul class='policy-bu'>
        <li>·Entrusted Company: Hanjin Inc., World Road Inc.</li>
        <li>·Entrusted Affairs: Product Shipping</li>
        <li>·Collected Items: Name, Address, Mobile Phone Number</li>
        <li>
          ·Period of Retention and Use: Immediate Destruction Upon Achieving
          Purpose
        </li>
      </ul>

      <p class='policy-tit'>(4) Authentication</p>
      <ul class='policy-bu'>
        <li>·Entrusted Company: Payletter Inc.</li>
        <li>·Entrusted Affairs : Authentication</li>
        <li>·Collected Items : Name, Mobile Phone Number, Etc.</li>
        <li>
          ·Period of Retention and Use: Immediate Destruction Upon Achieving
          Purpose
        </li>
      </ul>

      <p class='policy-tit'>(5) Customer Inquiry</p>
      <ul class='policy-bu'>
        <li>·Entrusted Company: Channel Corp.</li>
        <li>
          ·Entrusted Affairs: Customer Inquiry and Customer Information
          Management
        </li>
        <li>·Collected Items : (Optional) Name, Email, Phone Number, Etc.</li>
        <li>
          ·Period of Retention and Use: Under Policy on Holding of Record on
          Consumer Complaints or Dispute Settlement
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 5. Period of Retention and Use of Personal Information
      </h4>

      <p>
        In principle, the Company immediately destroys the personal information
        upon deleting the account. Provided, the following information is saved
        separately for the specified period according to the related statutes
        and the personal information is immediately destroyed after the
        specified period.
      </p>

      <p>
        After deleting the account, the ID of the account cannot be re-used
        permanently. The Company checks on the use of the same ID and email to
        prevent the User from signing up with the deleted account and it is
        applied with one-way encryption to compare the distinct value that
        cannot be restored.
      </p>

      <p class='policy-tit'>(1) Account Deletion</p>
      <ul class='policy-bu'>
        <li>
          ·Grounds of Preservation: Prevention of Re-Joining of Suspended User,
          Dispute on Infringement on Rights, Cooperation on Investigation
        </li>
        <li>·Period of Preservation: One Year from Deleting Account</li>
      </ul>

      <p class='policy-tit'>(2) Records of Commercial Transaction</p>
      <ul class='policy-bu'>
        <li>
          ·Grounds of Preservation: Commercial Law and Act on the Consumer
          Protection in Electronic Commerce, Etc.
        </li>
        <li>
          ·Preserving Agency:
          <table class='policy-tbl'>
            <tbody>
              <tr>
                <th>Records concerning contract or cooling-off</th>
                <td>
                  5 Years (Act on the Consumer Protection in Electronic
                  Commerce, Etc.)
                </td>
              </tr>
              <tr>
                <th>Records concerning payment and supply of goods, etc.</th>
                <td>
                  5 Years (Act on the Consumer Protection in Electronic
                  Commerce, Etc.)
                </td>
              </tr>
              <tr>
                <th>
                  Records regarding consumer complaints and dispute settlement
                </th>
                <td>
                  3 Years (Act on the Consumer Protection in Electronic
                  Commerce, Etc.)
                </td>
              </tr>
              <tr>
                <th>Records concerning electronic financial transaction</th>
                <td>5 Years (Electronic Financial Transactions Act)</td>
              </tr>
              <tr>
                <th>Records concerning indication and advertisement</th>
                <td>
                  6 Months (Act on the Consumer Protection in Electronic
                  Commerce, Etc.)
                </td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>

      <p class='policy-tit'>(3) Records of Visiting Site</p>
      <ul class='policy-bu'>
        <li>
          ·Grounds of Preservation: Protection of Communications Secrets Act
        </li>
        <li>·Period of Preservation: 3 Months</li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 6. Procedures and Method of Personal Information Destruction
      </h4>

      <p>
        In principle, the Company immediately destroys the personal information
        upon achieving the purpose of collecting and using the personal
        information. The procedures and methods of destruction are as follows.{' '}
      </p>

      <p class='policy-tit'>(1) Procedures of Destruction</p>
      <p>
        The Information submitted by the User in signing up is transferred to a
        separate database (a separate filing cabinet in case of paper) upon
        achieving the purpose, stored for a certain period (refer to Period of
        Retention and Use of Personal Information) according to grounds of
        personal information protection in the internal policy and other related
        statutes, and destroyed after the period. The personal information is
        not used for any purposes other than the intended purpose unless
        otherwise provided in other Acts.{' '}
      </p>

      <p class='policy-tit'>(2) Methods of Destruction</p>
      <ul class='policy-bu'>
        <li>
          ·Paper Prints: Information is destroyed by shredding in a shredder or
          incinerating.
        </li>
        <li>
          ·Electronic File: Information is deleted by using a technical method
          so that information cannot be recycled.
        </li>
      </ul>

      <p class='policy-tit'>
        (3) Procedures of Dormant Account’s Personal Information Destruction
      </p>
      <p>
        In accordance with “Article 29 of Act on Promotion of Information and
        Communications Network Utilization and Information Protection, Etc. and
        Article 16 of its Enforcement Decree”, the Company converts the account
        that hasn’t logged in to masterTOPIK service over one year into a
        dormant account and manages the dormant account separately to protect
        the personal information.
      </p>
      <ul class='policy-bu'>
        <li>
          ·Personal Information to be Converted into Dormant Account : All
          information collected and managed by signing up or modifying the
          information of the Member;
        </li>
        <li>
          · Related matters are notified to the User via email and other means
          at least one month before separating and saving the personal
          information as the dormant account;
        </li>
        <li>
          · Personal information converted into dormant account is immediately
          destroyed 3 years after separating and saving the personal
          information.
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 7.Right of User and Legal Representative and Exercising Method
      </h4>

      <p>
        회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성된 경우에는 지체
        없이 해당 정보를 파기합니다. 파기 절차 및 방법은 다음과 같습니다.
      </p>

      <p class='policy-tit'>(1) Rights of User</p>
      <ul class='policy-bu'>
        <li>
          · The User may access or modify the registered personal information
          and request on deleting the account at any time;
        </li>
        <li>
          · To view and modify the personal information, the User may inspect
          and correct the personal information in “Change Personal Information”.
          To delete the account (withdraw consent), the User may have 1:1
          inquiry with the customer center or send an email to
          ‘mastertopik@visang.com’ stating the intention to delete the account;
        </li>
        <li>
          · Contact the personal information protection manager via letter,
          phone, or email and the personal information protection manager will
          take measures immediately;
        </li>
        <li>
          · In case of requesting on correction of the error in the personal
          information, the Company does not use or provide the personal
          information until it is corrected. Also, when the wrong personal
          information is already provided to the Third Party, the Company
          immediately notifies the Third Party of the personal information
          correction results to correct the information;
        </li>
        <li>
          · If the Company finds out that a Member signed up by stealing the
          personal information of others, the Company immediately takes the
          necessary measures including suspension of the service on the account
          and deletion of the account. Also, the Company takes immediate
          measures when the User is clearly aware that his/her personal
          information is stolen and requests on suspension of the service on the
          account or deletion of the account.
        </li>
      </ul>

      <p class='policy-tit'>(2) Duties of User</p>
      <ul class='policy-bu'>
        <li>
          · The User needs to enter the latest personal information accurately
          to prevent unexpected accidents. The User is liable for the accidents
          caused by entering the inaccurate information. The User may become
          disqualified for stealing the information of others or entering the
          false information.{' '}
        </li>
        <li>
          · The User has the duty to protect himself/herself and not to infringe
          the information of others. The User needs to be careful on leaking the
          personal information including password and damaging the personal
          information or posts of others.{' '}
        </li>
        <li>
          · If the User fails to fulfill such duties and damages the information
          and dignity of others, the User may be penalized according to “Act on
          Promotion of Information Communications Network Utilization and
          Information Protection, Etc.”{' '}
        </li>
      </ul>

      <p class='policy-tit'>(3) Protection of Child’s Personal Information </p>
      <p>
        The subject of the Company’s service is a General User who is not a
        child under the age of 14 years. When the Company becomes aware that the
        Service collected the personal information of a child under the age of
        14 years by the mistake, the Company will delete the information without
        obtaining the effective consent from the parents. Also, the Company does
        not intentionally manage the data of an EU resident under the age of 16
        years without the consent of the parents. When the Company collects the
        information of an EU resident under the age of 16 years without the
        consent of the parents, the Company will immediate take reasonable
        measures and delete the information. The Company also complies with
        other age limits and other requirements according to the Acts of the
        area.{' '}
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 8. Installation and Operation of Automatic Personal Information
        Collection System and Refusal
      </h4>

      <p>
        The Company uses “Cookie” that saves and loads information of the User
        to provide the personalized and customized service. “Cookie” is a small
        text file sent from the server used to run a site to the browser and it
        is saved on the User’s disk.
      </p>

      <p class='policy-tit'>(1) Purpose of Using Cookie</p>
      <ul class='policy-bu'>
        <li>·To analyze the service/site connection frequency and sites;</li>
        <li>·To identify and track the taste and interests of the User;</li>
        <li>
          ·To identify the type of visiting and using each service/site, hot
          searches, security access status, and scale of User;
        </li>
        <li>
          · To identify the degree of participation in events and number of
          visiting the site to provide the marketing and customized service.
        </li>
      </ul>

      <p class='policy-tit'>(2) Installation/Operation of Cookie and Refusal</p>
      <p>
        The User holds the right to choose whether to install the cookie.
        Therefore, the User may set an option in the web browser to allow all
        cookies, check on each saved cookie, and refuse on saving all cookies.
        Provided, when the User refuses to save the cookies, the User may be
        restricted in using some of the services that require logging in.
      </p>
      <ul class='policy-bu'>
        <li>
          ·Internet Explorer: Tools (Top of Web Browser) &gt; Internet Option
          &gt; Personal Information &gt; Settings
        </li>
        <li>
          ·Google Chrome: Settings Menu (Right Side of Web Browser) &gt;
          Advanced Settings (Bottom of Screen) &gt; Contents Settings Button
          Under Personal Information &gt; Cookie
        </li>
        <li>
          ·Firefox: Options Menu &gt; Personal Information &gt; History- Custom
          Settings &gt; Cookie Block Level Setting
        </li>
        <li>
          ·Safari: Configuration &gt; Personal Information Tab &gt; Cookie and
          Site Data Level Setting
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 9. Technical and Administrative Personal Information Protection
        Measures{' '}
      </h4>

      <p>
        The Company endeavors as follows to protect the personal information of
        the User.{' '}
      </p>

      <p class='policy-tit'>(1) Technical Protection Measures</p>
      <ul class='policy-bu'>
        <li>
          ·Information Encryption: The Company keeps the personal information by
          taking the encryption measures. The Company sends the information by
          using the encrypted communication section.
        </li>
        <li>
          ·Operation of Vaccine Program: To protect the personal information
          from hacking or computer virus, the Company installs the vaccine
          program and monitors 24 hours. The Company also continuously
          researches on the new hacking/security technologies and applies them
          on the service.
        </li>
        <li>
          ·Use of Firewall: The Company prevents any unauthorized external
          access and endeavors to arrange other technical systems for securing
          the security.
        </li>
      </ul>

      <p class='policy-tit'>(2) Administrative Protection Measures</p>
      <ul class='policy-bu'>
        <li>
          ·Minimization of Personal Information Managers: The Company keeps the
          minimum number of personal information managers and blocks the use of
          the external Internet service in the Company’s computer to reduce the
          risk of personal information leakage. The Company also creates and
          changes the password for the personal information management system,
          arranges systematic standards concerning the access, and carries out a
          constant inspection.
        </li>
        <li>
          ·Education on Personal Information Management : The Company provides a
          regular education on the personal information protection to executives
          officers and employees.{' '}
        </li>
        <li>
          ·Operation of Organization in Charge of Personal Information
          Protection : The Company designates and operates the managers as
          follows to protect the personal information of the User, answer
          questions from Users, and handle complaints. Provided, the Company is
          not liable for the damages that are not attributable to the Company
          such as damages caused by negligence of the Member or accidents that
          happened in the extents that are not managed by the Company. When the
          Company causes the damage, the Company will notify the Member and
          devise appropriate measures and compensation.
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 10. Personal Information Protection Manager and Civil Service
      </h4>

      <p class='policy-tit'>
        (1) Personal Information Protection Manager and Contacts
      </p>
      <p>
        To protect the personal information and handle the complaints concerning
        the personal information, the Company designates the department and
        personal information protection manager as follows.{' '}
      </p>
      <ul class='policy-bu'>
        <li>
          ·Personal Information Protection Manager: CP Jungwoo Lee of IT Strategy
          Core{' '}
        </li>
        <li>·Department in Charge : Information Security Cell</li>
        <li>·Phone Number: 1544-0554</li>
        <li>·Inquiry: privacy@visang.com</li>
      </ul>
      <p>
        For other inquiries, contact masterTOPIK CS Department
        (mastertopik@visang.com) for the quick and detailed answer.{' '}
      </p>

      <p class='policy-tit'>(2) Civil Service</p>
      <p>
        Contact the following agencies to report or consult about the
        infringement of personal information.
      </p>
      <ul class='policy-bu'>
        <li>
          <p>
            ·Personal Information Infringement Report Center (Ran by Korea
            Internet & Security Agency)
          </p>
          <p>Phone Number: 118 without Area Code</p>
          <p>
            URL:{' '}
            <a
              href='http://privacy.kisa.or.kr'
              target='_blank'
              rel='noreferrer'
            >
              http://privacy.kisa.or.kr
            </a>
          </p>
        </li>
        <li>
          <p>·Prosecution Service, Cyber Crime Investigation Division</p>
          <p>Phone Number: 1301 without Area Code</p>
          <p>
            URL:{' '}
            <a href='http://www.spo.go.kr' target='_blank' rel='noreferrer'>
              http://www.spo.go.kr
            </a>
          </p>
        </li>
        <li>
          <p>·Korean National Police Agency Cyber Bureau</p>
          <p>Phone Number: 182 without Area Code</p>
          <p>
            URL:{' '}
            <a
              href='https://ecrm.police.go.kr/minwon/main'
              target='_blank'
              rel='noreferrer'
            >
              ecrm.police.go.kr
            </a>
          </p>
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Article 11. Amendment on Privacy Policy and Announcements
      </h4>

      <p>
        The Company has the rights to amend the Personal Information Protection
        Policy to reflect the changes in Acts, practices in collection and use
        of data, service features, and technologies. The Amended Personal
        Information Protection Policy is available in the service and the User
        needs to review on the Personal Information Protection Policy on a
        regular basis. The User may refer to the “Announcement/Implementation
        Date” included in the last part of the document to check on the changes
        in the Personal Information Protection Policy since the last review.
        When adding, deleting, or modifying the matters above, the Company will
        notify in “Notice” of the Company’s site at least 7 days before the
        amendment. Provided, the Company will notify at least 30 days for any
        major changes on the rights of the User.{' '}
      </p>

      <p>
        <strong>Announcement Date: April 28, 2023</strong>
      </p>
      <p>
        <strong>Implementation Date: March 07, 2023</strong>
      </p>

      <p>
        For inquiries or questions concerning the use of service, please contact
        mastertopik@visang.com.
      </p>
    </div>
  </div>
);

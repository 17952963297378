import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SwiperSlide } from 'swiper/react';
import Loading from 'component/atoms/Loading';
import Error from 'component/Error';
import Styled from 'container/UserHomePage/styled/Recommend.style';
import { EmptyList } from 'component/atoms/EmptyList';
import SliderContainer from 'component/slider/sliderContainer';
import UserThemeItem from 'component/molecules/ThemeItem/UserThemeItem';
import { useTranslation } from 'react-i18next';

export default function RecommendThemes({ initialState }) {
  const { t } = useTranslation('userHomePage');
  const token = useSelector((state) => state.user.token);
  const [themeList, fetchThemeList] = initialState;
  const [slideItems, setSlideItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { loading, data, error } = themeList;

  useEffect(() => {
    fetchThemeList({ page: 0, token });
  }, []);

  useEffect(() => {
    if (data !== null) {
      setSlideItems([...slideItems, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [themeList]);

  const onNext = () => {
    if (!loading && hasMore) {
      fetchThemeList({ page: data.number + 1, token });
    }
  };

  if (error) return <Error error={error} />;

  return (
    <>
      <Styled.H3>{t('recommendSection.subTitle2')}</Styled.H3>
      {slideItems.length === 0 ? (
        <EmptyList isLoading={loading} />
      ) : (
        <SliderContainer length={slideItems.length}>
          {slideItems.map((item, index) => (
            <SwiperSlide key={index}>
              <UserThemeItem
                adminTheme={true}
                id={item.id}
                imgSrcList={item.thumbnailPath}
                title={item.name}
                desc={item.description}
                userName={item.userName}
                userThumb={item.userProfilePhotoPath}
                likeCount={item.likeCount}
                videoCount={item.courseCount}
                tag={item.categoryName}
                isGood={item.isGood}
              />
            </SwiperSlide>
          ))}
        </SliderContainer>
      )}
    </>
  );
}

import { SiteMapData } from 'common/constant';
import { errorCode } from 'common/errorCode';
import { useReducer, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';

//reference : https://velog.io/@johnque/React-API-%EC%97%B0%EB%8F%99-v9k692txn5
function reducer(state, action) {
  switch (action.type) {
    case 'LOADING':
      return {
        loading: true,
        data: null,
        error: null,
      };
    case 'SUCCESS':
      return {
        loading: false,
        data: action.data,
        error: null,
      };
    case 'ERROR':
      return {
        loading: false,
        data: null,
        error: action.error,
      };
    default:
      return { ...state };
  }
}

export function useAxios(axiosCall, useEffectDep = [], skip = false) {
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    data: null,
    error: null,
  });

  const history = useHistory();
  const fetchData = useCallback(
    async (...prop) => {
      dispatch({ type: 'LOADING' });
      try {
        let data;
        if (prop) {
          data = await axiosCall(...prop);
        } else {
          data = await axiosCall();
        }
        dispatch({ type: 'SUCCESS', data: data });
      } catch (e) {
        if (e.response?.data?.code === errorCode.UNAVAILABLE_CONTENTS) {
          history.push(SiteMapData.plan);
        }
        dispatch({ type: 'ERROR', error: e.response });
      }
      return () => {};
    },
    [axiosCall, history]
  );

  useEffect(() => {
    if (skip) return;
    fetchData();
    return () => {};
  }, useEffectDep);

  //fetchData = means refresh
  return [state, fetchData];
}

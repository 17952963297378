import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  clear: both;
  position: relative;
  width: calc(100% + 4rem);
  margin-left: -2rem;
  transition: all 0.2s ease;
  &:after {
    content: '';
    clear: both;
    display: table;
  }
  > *:not(.loading-container) {
    float: left;
    width: calc(25% - 4rem);
    margin: 0 2rem 6rem;
  }
  @media (max-width: 1024px) {
    > *:not(.loading-container) {
      width: calc(25% - 20px);
      margin: 0 10px 6rem;
    }
  }
  @media (max-width: 767px) {
    width: calc(100% + 10px);
    margin-left: -5px;
    > *:not(.loading-container) {
      width: calc(50% - 10px);
      margin: 0 5px 8rem;
    }
  }
`;

/**
 * 4단 나열 목록 - 인접한 자식들 기준으로 나열, 여백 포함 (기본 margin-bottom: 4rem)
 * @param {*} param
 * @param {string=} param.className set wrapper className
 * @param {*} param.children
 */
const DefaultList = ({ className, children }) => (
  <Wrapper className={className}>{children}</Wrapper>
);

DefaultList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default DefaultList;

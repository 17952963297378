
import { asyncApi } from "common/util/asyncApi";

const url = "/api/v1/banners";

const bannerApi = {
  //type = LINE || LOGIN
  getBanner: async (type) => {
    const config = {
      url: url + `?type=${type}`,
    };
    let response = await asyncApi(config);
    return response.data;
  },
  getRollingBanner: async (type) => {
    const config = {
      url: `${url}/getRollingBannerList`,
    };
    let response = await asyncApi(config);
    return response.data;
  },
  getEventBanner: async (language) => {
    const config = {
      url: `${url}/getEventBannerList/${language}`,
    };
    let response = await asyncApi(config);
    return response.data;
  },
};

export default bannerApi;

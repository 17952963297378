import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import arrowRightIcon from 'assets/images/arrow_right.svg';

const StyledSlider = {
  Container: styled.div`
    position: relative;
  `,
  Swiper: styled(Swiper)`
    position: static;
    text-align: left;
    @media (min-width: 1025px) {
      .swiper-button-prev,
      .swiper-button-next {
        position: absolute;
        bottom: 50%;
        z-index: 10;
        width: 4.2rem;
        height: 4.2rem;
        border-radius: 4px;
        background-color: rgba(255, 181, 0, 0.5);
        cursor: pointer;
        transform: rotate(45deg);
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 1;
        }
        &:after {
          background: url(${arrowRightIcon}) no-repeat center;
          background-size: 1.6rem;
        }
      }
      .swiper-button-prev {
        left: -10rem;
        &:after {
          transform: rotate(135deg);
        }
      }
      .swiper-button-next {
        right: -10rem;
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
    @media (max-width: 1024px) {
      overflow: visible;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -20px;
        z-index: 10;
        width: 10px;
        height: 100%;
        background-color: #0f1012;
      }
      /* .swiper-button-prev,
      .swiper-button-next {
        content: '';
        position: absolute;
        top: 0;
        width: 7rem;
        height: 100%;
        z-index: 9;
      }
      .swiper-button-prev {
        left: -20px;
        background: linear-gradient(
          to right,
          rgba(15, 16, 18, 1) 20%,
          rgba(15, 16, 18, 0)
        );
      }
      .swiper-button-next {
        right: -20px;
        background: linear-gradient(
          to left,
          rgba(15, 16, 18, 1) 20%,
          rgba(15, 16, 18, 0)
        );
      } */
    }
  `,
};

export default StyledSlider;

import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import Article from './Article';
import Comment from './Comment';
import Loading from 'component/atoms/Loading';
import communityApi from 'api/community';
import ErrorPage from 'component/atoms/Error';
import PreviousLink from 'component/atoms/PreviousLink';

const Wrapper = styled(ContentsWrapper)`
  padding-bottom: 16em;
`;

const CommunityDetail = () => {
  const { id } = useParams();
  const token = useSelector((state) => state.user.token);
  const userData = useSelector((state) => state.user.data);
  const postState = useAxios(() => communityApi.getPost(id, token));
  const commentState = useAxios(communityApi.getCommentList, [], true);

  if (postState[0].loading) return <Loading />;
  if (postState[0].error) return <ErrorPage />;
  if (postState[0].data)
    return (
      <Wrapper>
        <PreviousLink text='Go Back' />
        <Article initialState={postState} userData={userData} token={token} />
        <Comment
          initialState={commentState}
          userData={userData}
          token={token}
        />
      </Wrapper>
    );
  return <></>;
};

export default CommunityDetail;

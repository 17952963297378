import React, { useRef } from 'react';
import Styled from 'container/LandingPage/FaqList/index.style';

export default function FaqItem({ item, classActive, onClick }) {
  const content = useRef(0);
  return (
    <Styled.Item
      className={classActive}
      activeHeight={content.current.scrollHeight}
    >
      <Styled.H4 onClick={onClick}>
        {item.title}
        <Styled.Arrow />
      </Styled.H4>
      <Styled.Desc
        ref={content}
        dangerouslySetInnerHTML={{ __html: item.bodyAsHtml }}
      />
    </Styled.Item>
  );
}

export const MemoizedFaqItem = React.memo(FaqItem);

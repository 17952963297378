import React, { useContext } from 'react';
import Styled from './index.style';
import { PlayerContext } from 'component/Player';
import { RiPauseMiniFill, RiPlayMiniFill } from 'react-icons/ri';

const PlayingToggle = (props) => {
  const { playerState, handleState } = useContext(PlayerContext);

  const handlePlaying = () => {
    handleState({ playing: !playerState.playing });
  };

  return (
    <Styled.Playing onClick={handlePlaying} {...props}>
      {!playerState.playing ? <RiPlayMiniFill /> : <RiPauseMiniFill />}
    </Styled.Playing>
  );
};

export default PlayingToggle;

import styled from 'styled-components';

const StyledThemeListHeader = {
  Container: styled.div`
    font-size: 1rem;
    &:after {
      content: '';
      clear: both;
      display: table;
    }
    h4 {
      display: inline-block;
      margin-bottom: 2.5rem;
    }
    @media (max-width: 767px) {
      h4 {
        margin-bottom: 1.5rem;
      }
    }
  `,
  Aside: styled.div`
    float: right;
    color: #8e9199;
    margin-bottom: 2.5em;

    &::after {
      content:'';
      display: block;
      clear: both;
    }
    a,
    span {
      margin-left: 2rem;
      vertical-align: middle;
    }
    .tag-link {
      margin-right: 1em;
    }
    .bar {
      display: inline-block;
      width: 1px;
      height: 10px;
      margin-left: 2rem;
      background-color: #272727;
    }
    @media (max-width: 767px) {
      float: none;
      .tag-link {
        margin-left: 0;
      }
    }
  `,
  AsideInner: styled.div`
    /* display: inline-block; */
    float: right;
  `
};

export default StyledThemeListHeader;

import React from 'react';
import Style from '../styled/List.style';
import { Link } from 'react-router-dom'

const MainListItem = ({ item }) => {
  return (
    <>
      <Style.Item>
        <Link to={`/help/article/${item.tblCustomerCenterDocIdx}`}>
          <Style.TitleWrap>
            <Style.Category>{item.category}</Style.Category>
            {/* 추천 문서 일 경우 클래스 recom 추가 */}
            {/* <Style.Title className='recom'>I want to vreate an account </Style.Title> */}
            <Style.Title className={item.isRecommend ? 'recom' : ''}>{item.title}</Style.Title>
          </Style.TitleWrap>
        </Link>
      </Style.Item>
    </>
  );
};
export default MainListItem;


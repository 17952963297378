import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useAxios } from "common/hook/useAxiosHook";
import myLevelApi from "api/myLevel";
import Loading from "component/atoms/Loading";
import Error from "component/Error";
import OrderBySelect from "component/molecules/OrderBySelect";
import LevelTestHeader from "container/LevelTestPage/common/LevelTestHeader";
import Step from "container/LevelTestPage/common/Step";
import CurriculumCarNew from "container/LevelTestPage/CheckRecommendation/CurriculumCard_new";
import { Card, Button, CardNew } from "container/LevelTestPage/styled";
import { ModalContext } from "component/Modal/modalContext";
import { HiArrowRight, HiArrowLeft } from "react-icons/hi";

import SwiperCore, { Navigation } from "swiper";
import { SwiperSlide } from "swiper/react";
import SetupCompleteModal from "container/LevelTestPage/CheckRecommendation/SetupCompleteModal";
import SetupAfterPurchasingModal from "container/LevelTestPage/CheckRecommendation/SetupAfterPurchasingModal";
import { EmptyList } from "component/atoms/EmptyList";
import { useTranslation } from "react-i18next";
import useLoginStatus from "common/hook/useLoginStatus";
import Login from "component/user/Login";

SwiperCore.use([Navigation]);

const swiperBreakPoint = {
  768: { slidesPerView: 2, spaceBetween: 40 },
  0: { slidesPerView: 1, spaceBetween: 20 },
};

const Index = () => {
  const { t } = useTranslation("levelTestPage");
  const { t: modalT } = useTranslation("curriculumModal");

  const history = useHistory();
  const { state } = useLocation();

  const isLogin = useLoginStatus();

  const token = useSelector((state) => state.user.token);
  const { handleModal } = React.useContext(ModalContext);
  const [language, setLanguage] = useState("ALL");

  const [curriculums, setCurriculums] = useAxios(
    myLevelApi.getCurriculums,
    [],
    true
  );
  const [plan, setPlan] = useAxios(
    () => myLevelApi.getPlan({ token }),
    [],
    true
  );
  const [, setMyCurriculum] = useAxios(
    () =>
      myLevelApi.postCurriculums({
        token,
        curriculumId: checked.curriculumChargeId,
      }),
    [],
    true
  );
  const { data: planData } = plan;
  const planCode = planData?.serviceCharge.planCode
    ? planData.serviceCharge.planCode
    : "FREE";
  const [checked, setChecked] = useState({
    curriculumChargeId: null,
    serviceGrade: null,
    plan: planCode,
  });

  const [curriculumsItems, setCurriculumsItems] = useState([]);
  const { loading, data, error } = curriculums;

  const selectedLanguage = [
    { label: "Language", value: "ALL" },
    { label: "English", value: "EN" },
    { label: "Korean", value: "KO" },
    { label: "Japanese", value: "JP" },
    { label: "Chinese", value: "CN" },
    // { label: 'Spanish', value: 'ES' },
  ];

  const handleChange = ({ value }) => {
    if (language !== value) {
      setLanguage(value);
      setCurriculumsItems([]);
    }
  };

  const handleOpen = (data) => {

    if(!isLogin) {
      handleModal(<Login/>, false, 420, state.pathname);
      return;
    }
    const { curriculumChargeId, serviceGrade } = data;
    // const eps =
    //   planCode === "EPS" &&
    //   (serviceGrade === "STANDARD" || serviceGrade === "PREMIUM");
    // const standard =
    //   planCode === "STANDARD" &&
    //   (serviceGrade === "EPS" || serviceGrade === "PREMIUM");
    // const premium = planCode === "PREMIUM" && serviceGrade === "EPS";

    if (curriculumChargeId === null) return;

    // if (planCode === "FREE" || eps || standard || premium) {
    //   handleModal(
    //     <SetupAfterPurchasingModal data={data} myPlan={planCode} t={modalT} />,
    //     false,
    //     500
    //   );
    // } else {
    //   setMyCurriculum();
    //   handleModal(<SetupCompleteModal data={data} t={modalT} />, false, 500);
    // }

    setMyCurriculum();
    handleModal(<SetupCompleteModal data={data} t={modalT} />, false, 500);
  };

  useEffect(() => {
    if (state) {
      //setCurriculums({ token, languageCode: language });
      setCurriculums({
        languageCode: language,
        languageLevel: state.languageLevel,
        targetLevel: state.targetLevel,
        subjectCode: state.subjectCode
      })
      setPlan({ token });
      return;
    } else {
      return history.replace("/level-test");
    }
  }, [language]);

  useEffect(() => {
    if (data === null) return;
    else return setCurriculumsItems([...curriculumsItems, ...data]);
  }, [data]);

  if (error) return <Error error={error} />;

  return (
    <>
      <Step activeIndex={4} />
      <LevelTestHeader title={t("checkRecommendation.title")} />

      {/* <Card.SelectContainer>
        <OrderBySelect
          onChange={handleChange}
          optionList={selectedLanguage}
          width="14.5em"
          className="order-by-select"
        />
      </Card.SelectContainer> */}
      <CardNew.CurriculumCardWrap>
        {curriculumsItems.length === 0 ? (
          <EmptyList isLoading={loading} />
        ) : (
          <CardNew.CurriculumCarNewWrap>
            {/* <Card.Swiper
              breakpoints={swiperBreakPoint}
              slidesPerView={2}
              navigation
            >
              {curriculumsItems.map((item) => (
                <SwiperSlide key={item.curriculumChargeId}>
                  <CurriculumCard
                    item={item}
                    setChecked={setChecked}
                    checked={checked}
                  />
                </SwiperSlide>
              ))}
            </Card.Swiper> */}
             {curriculumsItems.map((item) => (
                <CurriculumCarNew
                  key={item.curriculumChargeId}
                  item={item}
                  setChecked={setChecked}
                  checked={checked}
                />
              ))}
          </CardNew.CurriculumCarNewWrap>
        )}
        {/* <Button.ButtonGroup className="allCurriculum">
          <Button.MoreButton
            to={"/classroom/curriculum#search-curriculum"}
            target="_blank"
          >
            {t("checkRecommendation.link")}
            <HiArrowRight />
          </Button.MoreButton>
        </Button.ButtonGroup> */}
        <Button.ButtonGroup className="allCurriculum">
          <Button.ReturnLink to="/level-test">
            <HiArrowLeft />
            {t("goBack")}
          </Button.ReturnLink>
        </Button.ButtonGroup>
      </CardNew.CurriculumCardWrap>
      <Button.ButtonGroup className="center">
      <Button.Button
          disabled={checked.curriculumChargeId === null && "disabled"}
          className={checked.curriculumChargeId === null && "disabled"}
          onClick={() => handleOpen(checked)}
        >
          {t("checkRecommendation.button")}
        </Button.Button>

        {/* <Button.Button
          disabled={"disabled"}
          className={"disabled"}
        >
          {t("checkRecommendation.link")}
        </Button.Button> */}

      </Button.ButtonGroup>
    </>
  );
};

export default Index;

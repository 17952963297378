import React from 'react';
import { Quiz } from 'container/LevelTestPage/styled';
import Img from 'component/atoms/Img';

const QuizView = ({ quizState, checked, setChecked, total, t }) => {
  const { id, question, image, alt, description, choices } = quizState;
  let desc = '';
  let quizNumber = id;

  if (description) {
    description.forEach((value) => {
      desc += `<p>${value}</p>`;
    })
  }

  if (quizNumber < 10) {
    quizNumber = `0${quizNumber}`;
  }

  return (
    <Quiz.Quiz>
      <Quiz.QuizState>
        <span>{id}</span>
        <span>/</span>
        <span>{total}</span>
      </Quiz.QuizState>
      <Quiz.Header
        number={quizNumber}
        question={t(`levelTest.q${id}`)}
      />
      {image && <Quiz.Tmp><Img src={image} alt={alt} /></Quiz.Tmp>}
      {desc && <Quiz.Desc dangerouslySetInnerHTML={{ __html: desc }} />}
      <Quiz.Choices>
        {
          choices.map((item, index) => {
            const selected = checked.selected === index ? 'selected' : '';
            const correct = checked.correct === index ? 'correct' : '';
            const incorrect = checked.incorrect === index ? 'incorrect' : '';

            return(
              <Quiz.ChoicesItem
                key={index}
                className={`${selected} ${correct} ${incorrect}`}
              >
                <Quiz.Button
                  onClick={() => setChecked({ ...checked, selected: index })}
                  disabled={checked.disabled}
                >{item}</Quiz.Button>
              </Quiz.ChoicesItem>
            )
          })
        }
      </Quiz.Choices>
    </Quiz.Quiz>
  )
}

export default QuizView;

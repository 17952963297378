import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/theme-categories';
const size = 12;

/**
 * getAllList 모든 카테고리 목록 (북마크 페이지 > 테마 탭 > All categories)
 */
const themeCategoriesApi = {
  getAllList: async (props = { page: 0, size }) => {
    let response = await asyncApi({
      url,
      params: { page: props.page, size: props.size },
    });
    return response.data;
  },
};

export default themeCategoriesApi;

import React, { useState, useEffect, useRef } from 'react';
import { StyledLibrarySearch } from '../LibraryPage/styled/libraryInput.style';
import useClickOutside from 'common/hook/useOutsideClick';

import LibrarySelect from './librarySelect';
import { useLocation } from 'react-router-dom';
import { useAxios } from 'common/hook/useAxiosHook';
import libraryApi from 'api/library';
import { useSelector } from 'react-redux';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';

const LibraryInput = ({
  setSelect,
  getSearchWord,
  badge,
  setBadgeIdx,
  searchList,
}) => {
  const token = useSelector((state) => state.user.token);
  const { state: urlState } = useLocation();
  const [fetchSearchList, refreshFetchSearchList] = useAxios(
    () => libraryApi.getSearchList(token),
    [],
    true
  );

  const { t } = useTranslation('libraryPage');

  const { data } = fetchSearchList;

  const [showSearchList, setShowSearchList] = useState(false);
  const [searchWord, setSearchWord] = useState('');

  const inputRef = useRef();

  //teacher페이지 선생님 링크 클릭 후 라이브러리 페이지에서 검색 기능
  useEffect(() => {
    if (urlState) {
      setBadgeIdx(null);
      setSearchWord(urlState.teacherName);
      getSearchWord(urlState.teacherName);
    }
    if (token) {
      refreshFetchSearchList();
    }
  }, []);

  useEffect(() => {
    if (badge) {
      setSearchWord(badge.recommendKeyword);
    }
  }, [badge]);

  const onSearch = (text) => {
    setBadgeIdx(null);
    setSearchWord(text);
    getSearchWord(text);
  };
  useClickOutside(inputRef, () => {
    if (data && data.length > 0) {
      setShowSearchList(false);
    }
  });

  const clickInput = () => {
    if (data && data.length > 0) {
      setShowSearchList((prev) => !prev);
    }
    refreshFetchSearchList();
  };
  return (
    <StyledLibrarySearch.Container>
      <StyledLibrarySearch.InputContainer className='library'>
        <StyledLibrarySearch.SearchImage />
        <StyledLibrarySearch.Input
          value={searchWord}
          ref={inputRef}
          onClick={() => clickInput()}
          onChange={(event) => {
            onSearch(event.target.value);
          }}
          placeholder={t('searchSection.innerMsg')}
        ></StyledLibrarySearch.Input>
        <StyledLibrarySearch.CloseImage onClick={() => onSearch('')} />

        {data && (
          <StyledLibrarySearch.SearchList show={showSearchList}>
            {data.map((data) => {
              return (
                <li key={data.id} onClick={() => onSearch(data.searchWord)}>
                  {data.searchWord}
                </li>
              );
            })}
          </StyledLibrarySearch.SearchList>
        )}
      </StyledLibrarySearch.InputContainer>

      <LibrarySelect
        selectData={[
          { value: 'Courses', label: t('searchSection.filterOptions.option2') },
          { value: 'Themes', label: t('searchSection.filterOptions.option3') },
        ]}
        setSelect={setSelect}
      />
    </StyledLibrarySearch.Container>
  );
};

export default React.memo(LibraryInput);

import styled from 'styled-components';
import visualImg from 'assets/images/event/230612/visual.jpg';
import visualImgKo from 'assets/images/event/230612/ko/visual.jpg';
import titleImg from 'assets/images/event/230612/title.png';
import titleImgKo from 'assets/images/event/230612/ko/title.png';
import icoCheckImg from 'assets/images/event/230612/ico_check.png';
import icoArrow from 'assets/images/event/230612/ico_arrow.png';
import icoGo from 'assets/images/event/230612/ico_go.png';
import arrowRightIcon from 'assets/images/arrow_right.svg';

const StyledEventPage = {
  StickyWrap: styled.div`
    position: relative;
    background-color: #18007a;
  `,
  Sticky: styled.div`
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    background-color: #18007a;
    transition: box-shadow ease-in 0.2s;
    &.sticky {
      position: fixed;
      left: 0;
      z-index: 90;
      box-shadow: 0.5em 0.5em 1em rgba(0, 0, 0, 0.4);
      max-width: 100%;
    }
    span {
      display: block;
      padding: 1em;
      font-weight: 300;
      font-size: 1em;
      color: #fff;
      cursor: pointer;
    }
  `,

  EventWrap: styled.div`
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    color: #555;
    letter-spacing: -0.035em;

    .blind {
      overflow: hidden;
      position: absolute;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
    }

    .inner {
      margin: auto;
    }

    h2 {
      background-size: contain;
      background-position: 50% 0;
      background-repeat: no-repeat;
    }

    .visual {
      position: relative;
      background: #fad9ea;
      padding-bottom: 5em;
      .inner {
        position: relative;
        background-image: url(${visualImg});
        background-position: 50% 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        max-width: 770px;
        padding-top: 1020px;
        margin: 0 auto;
        z-index: 2;
      }

      .btnWrap {
        button {
          display: block;
          max-width: 750px;
          margin: 0 auto;
          background: #f4644c;
          padding: 0.65em 1em;
          color: #fff;
          font-size: 3.2rem;
          span {
            padding-right: 1.2em;
            background: url(${icoArrow}) 100% 50% no-repeat;
            background-size: auto 0.65em;
          }
        }
        p {
          margin-top: 1em;
          color: #253d9d;
          font-size: 2.8rem;
          font-weight: 400;
        }
      }
    }
    .ko {
      .visual {
        .inner {
          background-image: url(${visualImgKo});
        }
      }
    }

    .noteInfo {
      background: #d0f5ff;
      padding: 7em 0;

      h2 {
        height: 6em;
        max-height: 120px;
        background-image: url(${titleImg});
      }

      .box {
        position: relative;
        display: flex;
        align-items: center;
        max-width: 750px;
        margin: 5.5em auto 0;
        border-radius: 3em;
        text-align: left;
        p {
          width: 42.7%;
          max-width: 320px;
          flex-shrink: 0;
          box-shadow: 0 0 1em rgba(0, 0, 0, 0.28);
          img {
            width: 100%;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
          }
        }

        ul {
          margin-left: 2em;
          li {
            margin-top: 0.9em;
            font-size: 2.6rem;
            color: #000;
            &:first-child {
              margin-top: 0;
            }
            strong {
              display: block;
              margin-bottom: 0.6em;
              padding-left: 1.8em;
              background: url(${icoCheckImg}) 0 0 no-repeat;
              background-size: 1.4em 1.4em;
              line-height: 1.4em;
              font-weight: 700;
              font-size: 0.7em;
              color: #9e0b0f;
            }
            span {
              display: inline-block;
            }
          }
        }
      }
    }

    .ko {
      .noteInfo {
        h2 {
          background-image: url(${titleImgKo});
        }
      }
    }

    .banner {
      background: #eebc25;
      .btnWrap {
        max-width: 750px;
        margin: 0 auto;
        a,
        button {
          display: inline-block;
          padding: 1em 4.9em 1em 0;
          background: url(${icoGo}) 100% 50% no-repeat;
          background-size: auto 2em;
          font-weight: 600;
          font-size: 1.6em;
          color: #190277;
        }
      }
    }

    .notification {
      background: #2c2c2c;
      text-align: left;
      padding: 4em 0;
      line-height: 1.4;
      color: #fff;
      font-weight: 300;
      letter-spacing: normal;
      .inner {
        max-width: 750px;
      }
      h3 {
        margin-bottom: 1em;
        font-size: 1.2em;
        font-weight: 700;
      }
      ul {
        font-size: 1.1em;
      }
      li {
        position: relative;
        padding-left: 0.8em;
        margin-bottom: 0.6em;
        &:before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 0.5em;
          left: 0;
          width: 0.25em;
          height: 0.25em;
          border-radius: 0.2em;
          background: #fff;
          vertical-align: middle;
        }
        em {
          color: #fff799;
        }
      }
      P {
        padding: 1.6em 0 0.8em;
      }
      a {
        display: inline-block;
        padding-right: 1em;
        border-bottom: 1px #fff solid;
        background: url(${arrowRightIcon}) 100% 50% no-repeat;
        background-size: 0.6em auto;
      }
    }

    @media (max-width: 1024px) {
      .inner {
        padding: 0 20px;
      }
    }

    @media (max-width: 770px) {
      .visual {
        .inner {
          padding-top: 132vw;
        }
      }
    }

    @media (max-width: 580px) {
      .visual {
        padding-bottom: 3em;
        .btnWrap {
          a {
            font-size: 3rem;
          }
        }
      }

      .noteInfo {
        padding: 4em 0;
        h2 {
          height: 7.5em;
        }
        .box {
          display: block;
          margin: 2em auto 0;
          border-radius: 2em;
          padding: 1.6em;

          p {
            width: 9em;
            margin: auto;
          }

          ul {
            margin-left: 0;
            li {
              &:first-child {
                margin-top: 0.9em;
              }
            }
          }
        }
      }

      .topik {
        padding: 4em 0;
        h2 {
          height: 3.5em;
        }
      }
    }
  `,
};

export default StyledEventPage;

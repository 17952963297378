import React, { useEffect } from 'react';
import Style from './styled/index.style';
//component
import SummaryBanner from './SummaryBanner';
import PlanSection from './PlanSection';

import jresearchApi from './api/jresearchApi';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';

// import Loading from 'component/atoms/Loading';
// import ErrorPage from 'component/atoms/Error';
import { useTranslation } from 'react-i18next';

import BannerAudio from 'container/JresearchPage/BannerAudio'
import BannerEvent from 'container/JresearchPage/BannerEvent'

const JresearchPage = () => {
  const { t } = useTranslation('jresearchPage');

  const productId = 1304;
  const lectureId = useSelector((state) => state.jresearch.lectureId);

  const [{ data: overviewData }, refreshGetOverview] = useAxios(
    () => jresearchApi.fetchOverview(productId),
    [],
    true
  );

  useEffect(() => {
    refreshGetOverview();
  }, [productId]);


  //GNB 영역 제거
  useEffect(()=>{
    const root = document.getElementById('root');
    root.style = "padding-top: 0rem;";
    return () => {
      root.style = "padding-top: 9rem;";
    };
  },[])

  // console.error('test: urlProductId', productId);
  // console.error('test: overviewData !!!!', overviewData);

  return (
    <>
      <SummaryBanner t={t} />
      <PlanSection
        t={t}
        productId={productId}
        overview={ lectureId === 0 && overviewData }
      />
      <Style.Container>
        <BannerAudio />
        <BannerEvent />
      </Style.Container>
    </>
  );
};

export default JresearchPage;

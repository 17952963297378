import styled, { css } from 'styled-components';

const LinkStyle = css`
  display: inline-block;
  padding: 0.86em 1.15em;
  margin-left: 0.6em;
  margin-right: 0.6em;
  margin-bottom: -2px;
  border-bottom: 2px transparent solid;
  color: #c9cbd1;
  font-size: 1.6em;
  font-weight: 700;
  cursor: pointer;
  &.active {
    color: #ffb500;
    border-bottom: 2px #ffb500 solid;
  }
  @media (max-width: 767px) {
    padding: 0.75em 0.55em;
  }
  @media (max-width: 375px) {
    font-size: 1.6em;
  }
`;

const Styled = {
  StickyWrap: styled.div`
    position: relative;
    margin-bottom: 4em;
    `,
  StickyArea: styled.div`
    position: relative;
    width: 100%;
    background: #0f1012;
    z-index: 98;
    &.sticky {
      position: fixed;
      top: 9rem;
      left: 0;
      width: 100%;
      margin-bottom: 0;
      border-top: 1px solid #2b2e35;
      @media (max-width: 750px) {
        > * {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  `,
  StickyBanner: styled.a`
    display: block;
    margin-bottom: 1.2em;
    padding: 0.8em 2em;
    background: #475cc7;
    text-align: center;
    line-height: 1.2em;
    font-size: 1.8em;
    .lb {
      display: inline-block;
      margin: 0.4em 2em 0.4em 0;
      padding: 0.2em 1.6em;
      border-radius: 1em;
      background: #ffb500;
      font-weight: 400;
      font-size: 0.88em;
      color: #475cc7;
    }
    .txt {
      position: relative;
      display: inline-block;
      padding-right: 2.6em;
      font-weight: 700;
      &::after {
        content: '';
        position: absolute;
        right: 0.7em;
        top: 50%;
        margin-top: -0.35em;
        width: 0.7em;
        height: 0.7em;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        transform: rotate(45deg);
      }
    }
  `,
  StickyInner: styled.div`
    max-width: calc(1300px);
    margin: auto;
  `,
  StickyTab: styled.div`
    border-bottom: 2px #2b2e35 solid;
    text-align: center;
    .btnTab {
      ${LinkStyle}
    }
  `,
};

export default Styled;

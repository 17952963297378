import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import StyledForm from 'component/form/Form.style';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import H2 from 'component/atoms/H2';
import communityApi from 'api/community';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { useTranslation } from 'react-i18next';
import { API_HOST } from 'common/constant';
import { chkFileType } from 'common/util/util';

const Wrapper = styled(ContentsWrapper)`
  padding-bottom: 16em;
`;

const Form = styled(StyledForm.FormWrapper)`
  max-width: 700px;
  margin: auto;
`;

const Title = styled(H2)`
  padding: 1.25em 0 1.5em;
  text-align: center;
`;

const CommunityCreate = () => {
  const token = useSelector((state) => state.user.token);

  const [title, setTitle] = useState('');
  const [content, setContent] = useState(() => EditorState.createEmpty());

  const [articleState, fetchArticleState] = useAxios(
    communityApi.postArticle,
    [],
    true
  );
  const { loading, data, error } = articleState;

  const uploadImage = (file) => {

    // 이미지 파일 확장자가 아니라면 거른다.
    let fileName = file.name;
    if(!chkFileType(fileName)) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        reject(xhr.response);
      });
    }

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', API_HOST + '/api/v1/boards/upload');
      const data = new FormData();
      data.append('imageFiles', file);
      xhr.send(data);
      xhr.addEventListener('load', () => {
        resolve({ data: { link: xhr.response } });
      });
      xhr.addEventListener('error', () => {
        reject(xhr.response);
      });
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const modifiedContent = draftToHtml(
      convertToRaw(content.getCurrentContent())
    );
    if (title && content) {
      fetchArticleState(title, modifiedContent, token);
    }
  };

  const history = useHistory();
  useEffect(() => {
    if (data !== null) {
      history.push(`/community/${data}`);
    }
  }, [data]);

  const { t } = useTranslation('communityPage');

  return (
    <>
      <Wrapper>
        <Title>{t('post.createTitle')}</Title>

        <Form onSubmit={onSubmit}>
          <StyledForm.InputContainer>
            <StyledForm.Label htmlFor='title'>
              {t('post.titleLabel')}
            </StyledForm.Label>
            <StyledForm.Input
              type='text'
              id='title'
              name='title'
              title='title'
              required
              placeholder={t('post.titlePlaceholder')}
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              maxLength={100}
            />
          </StyledForm.InputContainer>

          <StyledForm.InputContainer>
            <StyledForm.Label htmlFor='content'>
              {t('post.contentLabel')}
            </StyledForm.Label>

            <StyledForm.Editor>
              <Editor
                editorState={content}
                toolbarClassName='toolbarClassName'
                wrapperClassName='wrapperClassName'
                editorClassName='editorClassName'
                onEditorStateChange={setContent}
                placeholder={t('post.contentPlaceholder')}
                handlePastedText={() => false}
                toolbar={{
                  image: {
                    uploadCallback: uploadImage,
                    alt: { present: true, mandatory: true },
                  },
                  link: {
                    link: { className: 'linkClassName' },
                    unlink: { className: 'unlinkClassName' },
                  }
                }}
              />
            </StyledForm.Editor>
          </StyledForm.InputContainer>

          <StyledForm.ButtonGroup>
            <StyledForm.Button type='submit'>
              {t('buttonPost')}
            </StyledForm.Button>
            <StyledForm.CancelButton onClick={() => history.push('/community')}>
              {t('buttonCancel')}
            </StyledForm.CancelButton>
          </StyledForm.ButtonGroup>

          {error !== null && (
            <StyledForm.WarnText>{error.data.message}</StyledForm.WarnText>
          )}
        </Form>

        <LoadingOverlay isLoading={loading} />
      </Wrapper>
    </>
  );
};

export default CommunityCreate;

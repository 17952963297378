import React from 'react';
import styled from 'styled-components';

const Style = {
  Container: styled.div`
    margin-top: 2em;
  `,
  Link: styled.a`
    display: block;
    width: 85%;
    margin: 1em auto;
    padding: 1.25em 0;
    border-radius: 0.375em;
    background-color: #485cc7;
    text-align: center;

    color: #fff;
    font-size: 1.6em;

    &:hover {
      background-color: #3e52c0;
    }
  `,
  Title: styled.h2`
    margin-bottom: 1.25em;
    padding: 0 1em;
    text-align: center;
    font-size: 2.4em;
    font-weight: 700;
  `,
};
const ForeignLocationModal = ({ data }) => {
  const countryData = {
    0: 'Europe',
    1: 'North America',
    2: 'South America',
  };

  return (
    <Style.Container>
      <Style.Title> Where To?</Style.Title>
      {data.map((data) => {
        return (
          <Style.Link
            key={data.id}
            href={data.overseasOrderUrl}
            target='_blank'
            
          >
            {countryData[data.areaDivideCode]}
          </Style.Link>
        );
      })}
    </Style.Container>
  );
};

export default ForeignLocationModal;

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Styled from 'component/Modal/styled/Modal.style';
import { ModalContext } from 'component/Modal/modalContext';

export default function Modal() {
  let {
    modal,
    handleModal,
    modalContent,
    maxWidth,
    closeByBackground,
  } = React.useContext(ModalContext);
  const modalRootEl = document.getElementById('modal');

  useEffect(() => {
    const htmlElClassList = document.querySelector('html').classList;
    modal
      ? htmlElClassList.add('modal-open')
      : htmlElClassList.remove('modal-open');
  });

  if (modal) {
    return ReactDOM.createPortal(
      <Styled.Fixer tabIndex='-1'>
        <Styled.Wrapper>
          <Styled.Inner>
            <Styled.Box maxWidth={maxWidth}>
              <Styled.Close
                className={closeByBackground && 'closeByBackground'}
                onClick={() => handleModal()}
              />
              {modalContent}
            </Styled.Box>
          </Styled.Inner>
        </Styled.Wrapper>
        {closeByBackground && <Styled.CloseLayer onClick={() => handleModal()} />}
      </Styled.Fixer>,
      modalRootEl
    );
  } else {
    return null;
  }
}

import React, { useState, useEffect } from 'react';
import Header from 'container/MockTestPage/TestResultPage/Header';
import Content from 'container/MockTestPage/TestResultPage/Content';
import styled from 'styled-components';
import { useAxios } from 'common/hook/useAxiosHook';
import mockTestApi from 'api/mockTests';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import WriteResultPage from 'container/MockTestPage/WriteResultPage';
import InProgressPage from 'container/MockTestPage/Components/InProgressPage';
import { Helmet } from 'react-helmet';

export const MockTestContext = React.createContext();

const Style = {
  Container: styled.div`
    background-color: #eceef9;
    color: #1e2024;
    > div:last-of-type {
      padding-top: 17em;
    }
  `,
};

const ResultTestPaper = () => {
  useEffect(() => {
    //새창 나가면 parent 창 refresh
    window.onunload = refreshParent;
    function refreshParent() {
      window.opener.location.reload();
    }
  }, []);
  const { productId, unitId } = useParams();

  const token = useSelector((state) => state.user.token);
  const [mockTestStore, setMockTestStore] = useState({
    testTime: '',
    answers: [],
  });

  const [{ data }] = useAxios(
    () =>
      mockTestApi.getResultsInfo({
        token,
        productId,
        unitId,
      }),
    [productId, unitId]
  );

  const resultPage = () => {
    if (!data || !data.viewerType) return;

    // VAL-W = 쓰기 결과 페이지
    if (data.viewerType !== 'VAL-W') return <Content />;
    else if (data.status === 'IN-PROGRESS') return <InProgressPage />;
    else return <WriteResultPage />;
  };

  return (
    <>
      <Helmet><script>zE('webWidget', 'hide');</script></Helmet>
      <MockTestContext.Provider value={{ mockTestStore, setMockTestStore }}>
        <Style.Container>
          {data && <Header data={data} />}
          {resultPage()}
        </Style.Container>
      </MockTestContext.Provider>
    </>
  );
};

export default ResultTestPaper;

import React, { useCallback, useEffect, useState } from 'react';
import Styled from 'container/UserBookmarkPage/styled/CreateTheme.style';
import StyledModal from 'component/Modal/styled/Modal.style';
import StyledForm from 'component/form/ModalForm.style';
import { useSelector } from 'react-redux';
import { ModalContext } from 'component/Modal/modalContext';
import ToggleSwitch from 'component/ui/ToggleSwitch';
import { useAxios } from 'common/hook/useAxiosHook';
import ThemeCategorySelect from 'container/UserBookmarkPage/Theme/CreateTheme/ThemeCategorySelect';
import themesApi from 'api/theme';
import { useHistory } from 'react-router-dom';
import DefaultTextModal from 'component/Modal/DefaultTextModal';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import { useTranslation } from 'react-i18next';

export default function EditThemeModal({ currentData, fetchTheme }) {
  const { t } = useTranslation('editThemeModal');

  const token = useSelector((state) => state.user.token);

  let { handleModal } = React.useContext(ModalContext);

  const [selectedOption, setSelectedOption] = useState({
    label: currentData.categoryName,
    value: currentData.categoryId,
  });
  const [themeName, setThemeName] = useState(currentData.name);
  const [description, setDescription] = useState(currentData.description);
  const [isHidden, setIsHidden] = useState(!currentData.isOpen);

  // 수정 기능 api
  const [putTheme, fetchPutTheme] = useAxios(themesApi.putTheme, [], true);
  const { loading: putLoading, data: putData, error: putError } = putTheme;

  const onEdit = (e) => {
    e.preventDefault();

    fetchPutTheme({
      id: currentData.id,
      categoryId: selectedOption.value,
      name: themeName,
      description,
      isOpen: !isHidden,
      token,
    });
  };

  useEffect(() => {
    if (putData === 200) {
      fetchTheme({ id: currentData.id, token });
      handleModal();
    }
  }, [putData]);
  
  // 삭제 기능 api
  const [deleteTheme, fetchDeleteTheme] = useAxios(
    themesApi.deleteTheme,
    [],
    true
  );
  const {
    loading: deleteLoading,
    data: deleteData,
    error: deleteError,
  } = deleteTheme;

  const onDelete = useCallback((e) => {
    e.preventDefault();
    fetchDeleteTheme({ id: currentData.id, token });
  }, []);

  // 삭제 완료 후 북마크 페이지 > 테마 탭으로 이동 (삭제 완료 모달 노출)
  const history = useHistory();
  useEffect(() => {
    if (deleteData === 200) {
      history.push('/bookmark/theme');
      handleModal(
        <DefaultTextModal
          title='It has been deleted successfully'
          desc='Check out the different themes'
        />
      );
    }
  }, [deleteTheme]);

  return (
    <>
      <StyledModal.H2>{t('title')}</StyledModal.H2>
      <Styled.InfoText>
        You can create your own themes and share <br /> them with others.
      </Styled.InfoText>

      <StyledForm.FormWrapper>
        <StyledForm.InputContainer>
          <StyledForm.Label htmlFor='themeName'>{t('nameSection')}</StyledForm.Label>
          <StyledForm.Input
            type='text'
            id='themeName'
            placeholder='A famous tourist destination in korea'
            required
            value={themeName}
            onChange={(e) => setThemeName(e.target.value)}
            placeholder={t('namePlaceholder')}
          />
        </StyledForm.InputContainer>

        <ThemeCategorySelect
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          title={t('categorySection.title')}
        />

        <StyledForm.InputContainer>
          <StyledForm.Label htmlFor='themeDesc'>{t('descSection')}</StyledForm.Label>
          <StyledForm.Textarea
            id='themeDesc'
            placeholder={t('descPlaceholder')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </StyledForm.InputContainer>

        <StyledForm.InputContainer>
          <StyledForm.Label htmlFor='keepSecret'>
            {t('text')}
          </StyledForm.Label>
          <ToggleSwitch
            id='keepSecret'
            checked={isHidden}
            onChange={(e) => setIsHidden(e.target.checked)}
          />
        </StyledForm.InputContainer>

        <Styled.ButtonGroup>
          <Styled.Button.Default onClick={onDelete}>
            {t('button1')}
          </Styled.Button.Default>
          <Styled.Button.Primary onClick={onEdit}>
            {t('button2')}
          </Styled.Button.Primary>

          {putError && (
            <StyledForm.WarnText>{putError.data.message}</StyledForm.WarnText>
          )}
          {deleteError && (
            <StyledForm.WarnText>
              {deleteError.data.message}
            </StyledForm.WarnText>
          )}
        </Styled.ButtonGroup>

        <LoadingOverlay isLoading={putLoading || deleteLoading} />
      </StyledForm.FormWrapper>
    </>
  );
}

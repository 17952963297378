import React from 'react';
import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import CancelSection from './CancelSection';
import PlanSection from 'container/SettingPage/CancelPage/PlanSection';

const Style = {
  Container: styled.div`
    display: flex;
    margin-top: 13em;
    > div {
      flex: 1;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `,
};
const CancelPage = () => {
  return (
    <ContentsWrapper>
      <Style.Container>
        <CancelSection />
        <PlanSection />
      </Style.Container>
    </ContentsWrapper>
  );
};

export default CancelPage;

import React, { useCallback, useEffect, useState } from 'react';
import Recommended from '../styled/recommended.style';
import { Link } from 'react-router-dom';
import test1 from 'assets/images/mycurriculum_new/test1.png';
import test2 from 'assets/images/mycurriculum_new/test2.png';
import test3 from 'assets/images/mycurriculum_new/test3.png';
import test4 from 'assets/images/mycurriculum_new/test4.png';
import { debounce } from 'lodash';
import curriculumApi from 'api/curriculum';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAxios } from 'common/hook/useAxiosHook';
import { toast } from 'react-toastify';

export default function RecommendedCourses({ curriculumData, isMyCurriculum, setIsMyCurriculum }) {
  const token = useSelector((state) => state.user.token);
  const { curriculumId } = useParams();

  const [fetchMyCurriculum, refreshMyCurriculum] = useAxios(
    () => curriculumApi.getMyCurriculum(token),
    [],
    false
  );
  const { data: myCurriculumData } = fetchMyCurriculum;

  const [setMyCurriculumResponse, refreshSetMyCurriculum] = useAxios(
    () => curriculumApi.setMyCurriculum(token, curriculumId),
    [curriculumId],
    true
  );

  const [cancelResponse, cancelMyCurriculum] = useAxios(
    () => curriculumApi.cancelMyCurriculum(token),
    [],
    true
  );

  const clickSetCurriculum = useCallback(
    debounce((cancel) => {
      if (cancel) {
        cancelMyCurriculum();
      } else {
        refreshSetMyCurriculum();
      }
    }, 500)
    , []
  );

  useEffect(() => {
    if (myCurriculumData) {
      setIsMyCurriculum(`${myCurriculumData.curriculumChargeId}` === curriculumId);
    }
  }, [myCurriculumData])

  useEffect(() => {
    if (setMyCurriculumResponse.data && setMyCurriculumResponse.data.status === 201) {
      refreshMyCurriculum();
      toast.success('curriculum has been set');
    } else if (setMyCurriculumResponse.error) {
      toast.error(setMyCurriculumResponse.error.data.description);
    }
  }, [setMyCurriculumResponse])


  useEffect(() => {
    if (cancelResponse.data && cancelResponse.data.status === 200) {
      refreshMyCurriculum();
      toast.success('curriculum has been canceled');
    } else if (cancelResponse.error) {
      toast.error(cancelResponse.error.data.description);
    }
  }, [cancelResponse])


  return (
    <>
      {/* {curriculumData && curriculumData.RecommendedCourses &&
        <>
          <Recommended.Title>Recommended Courses</Recommended.Title>
          <Recommended.Lists>
            <Recommended.List>
              <Link to='' className=''>
                <Recommended.Thum>
                  <img src={test1} alt="이미지" />
                </Recommended.Thum>
                <Recommended.ListH3>TOPIK II 3,4급 문법</Recommended.ListH3>
                <Recommended.slg>10 classes, 3 hours</Recommended.slg>
              </Link>
            </Recommended.List>
            <Recommended.List>
              <Link to='' className=''>
                <Recommended.Thum>
                  <img src={test2} alt="이미지" />
                </Recommended.Thum>
                <Recommended.ListH3>TOPIK 3-4급(듣기) [개념편]</Recommended.ListH3>
                <Recommended.slg>10 classes, 3 hours</Recommended.slg>
              </Link>
            </Recommended.List>
            <Recommended.List>
              <Link to='' className=''>
                <Recommended.Thum>
                  <img src={test3} alt="이미지" />
                </Recommended.Thum>
                <Recommended.ListH3>TOPIK 3-4급(읽기) [개념편]</Recommended.ListH3>
                <Recommended.slg>10 classes, 3 hours</Recommended.slg>
              </Link>
            </Recommended.List>
            <Recommended.List>
              <Link to='' className=''>
                <Recommended.Thum>
                  <img src={test4} alt="이미지" />
                </Recommended.Thum>
                <Recommended.ListH3>TOPIK II 실전 모의고사</Recommended.ListH3>
                <Recommended.slg>10 classes, 3 hours</Recommended.slg>
              </Link>
            </Recommended.List>
          </Recommended.Lists>
        </>
      } */}
      <Recommended.Button onClick={() => clickSetCurriculum(isMyCurriculum)}>{isMyCurriculum ? "Cancel Curriculum" : "Set My Curriculum"}</Recommended.Button>
    </>
  );
}




import { css } from 'styled-components';

const TableLayout = css`
  display: table;
  width: 100%;
  table-layout: fixed;
  & > * {
    display: table-cell;
    vertical-align: middle;
  }
`;

export default TableLayout;

import React, { useEffect, useRef, useState, useCallback } from 'react';
import Styled from './index.style';
import quiz from 'assets/images/event/230310/quiz.jpg';
import quizA from 'assets/images/event/230310/quiz_answer.jpg';
import quiz2 from 'assets/images/event/230310/quiz2.jpg';
import quiz2A from 'assets/images/event/230310/quiz2_answer.jpg';
import quiz3 from 'assets/images/event/230310/quiz3.jpg';
import quiz3A from 'assets/images/event/230310/quiz3_answer.jpg';
import quiz4 from 'assets/images/event/230310/quiz4.jpg';
import quiz5 from 'assets/images/event/230310/quiz5.jpg';
import picture from 'assets/images/event/230310/picture.jpg';
import flowImg1 from 'assets/images/event/230310/flow.jpg';
import flowImg2 from 'assets/images/event/230310/flow2.jpg';
import flowImg3 from 'assets/images/event/230310/flow3.jpg';
import flowImg4 from 'assets/images/event/230310/flow4.jpg';
import flowImg5 from 'assets/images/event/230310/flow5.jpg';
import flowImg6 from 'assets/images/event/230310/flow6.jpg';

import { Trans, useTranslation } from 'react-i18next';
import { Link, Switch, useHistory, useLocation } from 'react-router-dom';
import { openLink } from 'common/util/util';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from 'swiper';


import DefaultTextModal from 'component/Modal/DefaultTextModal';
import { useSelector } from "react-redux";
import { useAxios } from "common/hook/useAxiosHook";
import plansApi from "api/plans";

SwiperCore.use([Navigation, Pagination]);

const EventPage = () => {
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation('event/20230310Topik');

  const today = new Date().getTime();
  const dday = new Date("2023-04-09T00:00:00").getTime();
  const gap = dday - today;
  const reday = Math.ceil(gap / (1000 * 60 * 60 * 24));

  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);
  const location = useLocation();


  const token = useSelector((state) => state.user.token);
  const [{ data: planData }] = useAxios(() => plansApi.getPlan(token), []);


  const history = useHistory();
  const checkLogin = useCallback((e) => {
    if (!isLogin) {
      e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
      handleModal(<Login />, false, 420, location.pathname);
      return;
    } else {
      if (planData.currentPlan === "FREE") {
        const item = planData.plans.find((item) => item.productId === 1147);
        history.push({
          pathname: `/plan-payment/${item.planCode.toLowerCase()}`,
          search: `?productId=${item.productId}`,
          state: item,
        });
      } else {
        handleModal(<DefaultTextModal
          title={t(language === 'ko-KR' ? '이미 멤버십 구독 중입니다.' : 'You are already subscribing to the membership.')}
          btnType={'primary'}
        />);
      }
    }
  }, [planData]);

  // Course Preview Tab
  const [tabIndex, setTabIndex] = useState(0);
  const tabClick = (i) => {
    setTabIndex(i);
    setTypeIndex(i * 2);
  };
  const [typeIndex, setTypeIndex] = useState(0);
  const typeChange = (i) => {
    setTypeIndex(i);
  };
  const youtubeData = ['6XsZWtrlckw', 'vPHdh7lL2x4', 'OiubOpXNTRM', '4l46cRxSfdk', 'tHy-e9ocxvI'];

  // Preview TOPIK Mock Test Quiz
  const [quizIndex, setQuizIndex] = useState(0);
  const answerClick = (i) => {
    if (quizIndex === i) {
      setQuizIndex(0)
    } else {
      setQuizIndex(i)
    }
  };

  // Curriculum Tab
  const [tabIndex2, setTabIndex2] = useState(0);
  const tabClick2 = (i) => {
    setTabIndex2(i);
  };

  // Sticky
  const [hHeight, sethHeight] = useState(0); //header bottom
  const [winScrollY, setWinScrollY] = useState(window.scrollY);
  const fixedBtn = useRef();
  const stickyWrapRef = useRef();
  const [stickyOffset, setStickyOffset] = useState(0); //stickyWrapRef offsetTop
  //const stickyRef = useRef();
  //const [stickyHeight, setStickyHeight] = useState(0); //stickyRef height


  useEffect(() => {
    /* Mount 된 직후 초기값 셋팅 */
    onResize();

    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onResize);
    };
  }, []);

  const onResize = () => {
    const headerBtm = document.querySelector('header') ? document.querySelector('header').getBoundingClientRect().bottom : 0; //header bottom
    sethHeight(headerBtm);
    setWinScrollY(window.scrollY);
    setStickyOffset(stickyWrapRef.current.offsetTop);
    // setStickyHeight(stickyRef.current.clientHeight);
  }

  useEffect(() => {
    if (winScrollY >= (stickyOffset - hHeight)) {
      fixedBtn.current.classList.add('fixed');
    } else {
      fixedBtn.current.classList.remove('fixed');
    }
  }, [hHeight, stickyOffset, winScrollY]);

  return (
    <Styled.EventWrap className={language === 'ko-KR' ? 'ko' : null}>
      <div className='visual'>
        <div className='inner'>
          <div className='dday'>
            {t('visual.dday')} <strong>D-<span>{reday}</span></strong>
          </div>
          <div className='blind'>
            <h2>{t('visual.title')}</h2>
            <p>{t('visual.desc')}</p>
          </div>
        </div>
        <Styled.BtnWrap ref={fixedBtn}>
          <button onClick={checkLogin}>
            <Styled.icoArrow>{t('visual.button')}</Styled.icoArrow>
          </button>
        </Styled.BtnWrap>
      </div>

      <div className='preview' ref={stickyWrapRef}>
        <div className='inner'>
          <Styled.Title color={"#f9f9f9"} colorH2={"#f9f9f9"}>
            <Trans t={t}>preview.title</Trans>
            <h2><Trans t={t}>preview.title-2</Trans></h2>
          </Styled.Title>

          {/* Course Preview */}
          <h3 className='h3'><Trans t={t}>preview.stitle</Trans></h3>
          <Styled.Tab>
            <button className={tabIndex === 0 ? 'active' : ''} onClick={(e) => { tabClick(0) }}>
              <Trans t={t}>preview.tab</Trans>
            </button>
            <button className={tabIndex === 1 ? 'active' : ''} onClick={(e) => { tabClick(1) }}>
              <Trans t={t}>preview.tab2</Trans>
            </button>
            <button className={tabIndex === 2 ? 'active' : ''} onClick={(e) => { tabClick(2) }}>
              <Trans t={t}>preview.tab3</Trans>
            </button>
          </Styled.Tab>
          <Styled.TabCont className={tabIndex === 0 ? "show" : "hide"}>
            <h4 className='tit-tab'>{t('preview.tabCont.tit')}</h4>
            <div className='course'>
              <div className='info'>
                <div className='btn-type'>
                  <button className={typeIndex === 0 ? 'active' : ''} onClick={(e) => { typeChange(0) }}><Trans t={t}>preview.type</Trans></button>
                  <button className={typeIndex === 1 ? 'active' : ''} onClick={(e) => { typeChange(1) }}><Trans t={t}>preview.type2</Trans></button>
                </div>
                <div className={"teacher " + (typeIndex === 0 ? "show" : "hide")}>
                  <p><strong>{t('preview.teacher.name')}</strong> {t('preview.teachers')}</p>
                  <ul>
                    <li>{t('preview.teacher.desc')}</li>
                    <li>{t('preview.teacher.desc2')}</li>
                  </ul>
                </div>
                <div className={"teacher " + (typeIndex === 1 ? "show" : "hide")}>
                  <p><strong>{t('preview.teacher2.name')}</strong> {t('preview.teachers')}</p>
                  <ul>
                    <li>{t('preview.teacher2.desc')}</li>
                    <li>{t('preview.teacher2.desc2')}</li>
                  </ul>
                </div>
              </div>
              <div className='video'>
                <div className='tit-lecture'><h5>{t('preview.tabCont.lecture')}</h5></div>
                <div className='youtube'>
                  <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/" + youtubeData[typeIndex]} title={t('preview.tabCont.lecture')} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </Styled.TabCont>
          <Styled.TabCont className={tabIndex === 1 ? "show" : "hide"}>
            <h4 className='tit-tab'>{t('preview.tabCont2.tit')}</h4>
            <div className='course'>
              <div className='info'>
                <div className='btn-type'>
                  <button className={typeIndex === 2 ? 'active' : ''} onClick={(e) => { typeChange(2) }}><Trans t={t}>preview.type</Trans></button>
                  <button className={typeIndex === 3 ? 'active' : ''} onClick={(e) => { typeChange(3) }}><Trans t={t}>preview.type2</Trans></button>
                </div>
                <div className={"teacher " + (typeIndex === 2 ? "show" : "hide")}>
                  <p><strong>{t('preview.teacher.name')}</strong> {t('preview.teachers')}</p>
                  <ul>
                    <li>{t('preview.teacher.desc')}</li>
                    <li>{t('preview.teacher.desc2')}</li>
                  </ul>
                </div>
                <div className={"teacher " + (typeIndex === 3 ? "show" : "hide")}>
                  <p><strong>{t('preview.teacher2.name')}</strong> {t('preview.teachers')}</p>
                  <ul>
                    <li>{t('preview.teacher2.desc')}</li>
                    <li>{t('preview.teacher2.desc2')}</li>
                  </ul>
                </div>
              </div>
              <div className='video'>
                <div className='tit-lecture'><h5>{t('preview.tabCont2.lecture')}</h5></div>
                <div className='youtube'>
                  <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/" + youtubeData[typeIndex]} title={t('preview.tabCont.lecture')} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </Styled.TabCont>
          <Styled.TabCont className={tabIndex === 2 ? "show" : "hide"}>
            <h4 className='tit-tab'>{t('preview.tabCont3.tit')}</h4>
            <div className='course'>
              <div className='info'>
                <div className="picture">
                  <img src={picture} alt="" />
                </div>
                <div className='teacher'>
                  <p><strong>{t('preview.teacher3.name')}</strong> {t('preview.teachers')}</p>
                  <ul>
                    <li>{t('preview.teacher3.desc')}</li>
                    <li>{t('preview.teacher3.desc2')}</li>
                  </ul>
                </div>
              </div>
              <div className='video'>
                <div className='tit-lecture'><h5>{t('preview.tabCont3.lecture')}</h5></div>
                <div className='youtube'>
                  <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/" + youtubeData[4]} title={t('preview.tabCont.lecture')} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </Styled.TabCont>
          {/* //Course Preview */}

          {/* Preview TOPIK Mock Test */}
          <h3 className='h3'><Trans t={t}>preview.stitle2</Trans></h3>
          <Styled.SwiperWrap>
            <Styled.Swiper
              slidesPerView={1}
              speed={300}
              loop={true}
              navigation
            >
              <SwiperSlide>
                <Styled.Quiz>
                  <div className='flip-card'>
                    <div className={"flip-card-inner " + (quizIndex === 1 ? 'animate-wiggle' : null)}>
                      <div className='flip-card-front'><img src={quiz} alt="" /></div>
                      <div className='flip-card-back'><img src={quizA} alt="" /></div>
                    </div>
                  </div>
                  <button className='btn-answer' onClick={(e) => { answerClick(1) }}>Check Answer</button>
                </Styled.Quiz>
              </SwiperSlide>
              <SwiperSlide>
                <Styled.Quiz>
                  <div className='flip-card'>
                    <div className={"flip-card-inner " + (quizIndex === 2 ? 'animate-wiggle' : null)}>
                      <div className='flip-card-front'><img src={quiz2} alt="" /></div>
                      <div className='flip-card-back'><img src={quiz2A} alt="" /></div>
                    </div>
                  </div>
                  <button className='btn-answer' onClick={(e) => { answerClick(2) }}>Check Answer</button>
                </Styled.Quiz>
              </SwiperSlide>
              <SwiperSlide>
                <Styled.Quiz>
                  <div className='flip-card'>
                    <div className={"flip-card-inner " + (quizIndex === 3 ? 'animate-wiggle' : null)}>
                      <div className='flip-card-front'><img src={quiz3} alt="" /></div>
                      <div className='flip-card-back'><img src={quiz3A} alt="" /></div>
                    </div>
                  </div>
                  <button className='btn-answer' onClick={(e) => { answerClick(3) }}>Check Answer</button>
                </Styled.Quiz>
              </SwiperSlide>
              <SwiperSlide>
                <div className='slide4'>
                  <img src={quiz4} alt="" />
                  <div className='txt-box'>
                    <div className='tit'>{t('preview.slide4.tit')}</div>
                    <Trans t={t}>preview.slide4.desc</Trans>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className='slide5'>
                  <img src={quiz5} alt="" />
                  <div className='txt-box'>
                    <div className='tit'>{t('preview.slide5.tit')}</div>
                    <Trans t={t}>preview.slide5.desc</Trans>
                  </div>
                </div>
              </SwiperSlide>
            </Styled.Swiper>
          </Styled.SwiperWrap>
          {/* //Preview TOPIK Mock Test */}
        </div>
      </div>

      <div className='curriculum'>
        <div className='inner'>
          <Styled.Title color={"#333333"} colorH2={"#0250fa"}>
            <Trans t={t}>curriculum.title</Trans>
            <h2><Trans t={t}>curriculum.title-2</Trans></h2>
          </Styled.Title>
          <Styled.Tab className='type2'>
            <button className={tabIndex2 === 0 ? 'active' : ''} onClick={(e) => { tabClick2(0) }}>
              <Trans t={t}>curriculum.tab</Trans>
            </button>
            <button className={tabIndex2 === 1 ? 'active' : ''} onClick={(e) => { tabClick2(1) }}>
              <Trans t={t}>curriculum.tab2</Trans>
            </button>
            <button className={tabIndex2 === 2 ? 'active' : ''} onClick={(e) => { tabClick2(2) }}>
              <Trans t={t}>curriculum.tab3</Trans>
            </button>
          </Styled.Tab>
          <Styled.TabCont className={tabIndex2 === 0 ? "show" : "hide"}>
            <div className='tab-cont cont'></div>
            <div className='blind'>
            </div>
          </Styled.TabCont>
          <Styled.TabCont className={tabIndex2 === 1 ? "show" : "hide"}>
            <div className='tab-cont cont2'></div>
            <div className='blind'>
            </div>
          </Styled.TabCont>
          <Styled.TabCont className={tabIndex2 === 2 ? "show" : "hide"}>
            <div className='tab-cont cont3'></div>
            <div className='blind'>
            </div>
          </Styled.TabCont>
        </div>
      </div>

      <div className='report'>
        <div className='inner'>
          <Styled.Title color={"#4b2a00"} colorH2={"#3d1d8a"}>
            <Trans t={t}>report.title</Trans>
            <h2><Trans t={t}>report.title-2</Trans></h2>
          </Styled.Title>
          <div className="flow_screen">
            <div className="flow_banner group1">
              <ul>
                <li><img src={flowImg1} alt="" /></li>
                <li><img src={flowImg2} alt="" /></li>
                <li><img src={flowImg3} alt="" /></li>
              </ul>
              <ul>
                <li><img src={flowImg4} alt="" /></li>
                <li><img src={flowImg5} alt="" /></li>
                <li><img src={flowImg6} alt="" /></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='plan'>
        <div className='inner'>
          <Styled.Title color={"#f9f9f9"} colorH2={"#f9f9f9"}>
            <Trans t={t}>plan.title</Trans>
            <h2><Trans t={t}>plan.title-2</Trans></h2>
          </Styled.Title>
          <div className='plan-wrap'>
            <div className='card'>
              <div className='tit green'>
                <h3>{t('plan.plan')}</h3>
              </div>
              <dl>
                <dt><Trans t={t}>plan.course.lb</Trans></dt>
                <dd><Trans t={t}>plan.course.cont</Trans></dd>
              </dl>
              <dl>
                <dt><Trans t={t}>plan.mocktest.lb</Trans></dt>
                <dd><Trans t={t}>plan.mocktest.cont</Trans></dd>
              </dl>
              <dl>
                <dt><Trans t={t}>plan.period.lb</Trans></dt>
                <dd>{t('plan.period.cont')}</dd>
              </dl>
              <button className='btn green' onClick={checkLogin}>
                <Trans t={t} className='price'>plan.price</Trans>
                <Styled.icoArrow2 className='apply'><span>{t('plan.apply')}</span></Styled.icoArrow2>
              </button>
            </div>
            <div className='card'>
              <div className='tit blue'>
                <h3>{t('plan.plan2')}</h3>
              </div>
              <dl>
                <dt><Trans t={t}>plan.course.lb</Trans></dt>
                <dd><Trans t={t}>plan.course.cont</Trans></dd>
              </dl>
              <dl>
                <dt><Trans t={t}>plan.mocktest.lb</Trans></dt>
                <dd><Trans t={t}>plan.mocktest.cont</Trans> <Trans t={t} className='add'>plan.mocktest.cont2</Trans></dd>
              </dl>
              <dl>
                <dt><Trans t={t}>plan.material.lb</Trans></dt>
                <dd><Trans t={t}>plan.material.cont</Trans> <Trans t={t} className='add'>plan.material.cont2</Trans></dd>
              </dl>
              <dl>
                <dt><Trans t={t}>plan.period.lb</Trans></dt>
                <dd>{t('plan.period.cont')} <span className='add'><Styled.icoPlus>{t('plan.period.cont2')}</Styled.icoPlus></span></dd>
              </dl>
              <button to={""} className='btn blue' onClick={checkLogin}>
                <Trans t={t} className='price'>plan.price2</Trans>
                <Styled.icoArrow2 className='apply'><span>{t('plan.apply')}</span></Styled.icoArrow2>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='notification'>
        <div className='inner'>
          <h3>{t('notification.title')}</h3>
          <ul>
            <li><Trans t={t}>notification.desc</Trans></li>
            <li><Trans t={t}>notification.desc2</Trans></li>
          </ul>
        </div>
      </div>
    </Styled.EventWrap>
  );
};

export default EventPage;
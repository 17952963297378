import React, { useState, useRef, useEffect } from "react";
import { useAxios } from "common/hook/useAxiosHook";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotificationAlarm,
  getFixedNotice,
  getNotice,
  getUpcomingLectures,
  updateToRead,
  updateToReadFixed,
} from "api/notificationApi";
import Styled from "./styled/Notification.style";
import srcImage from "assets/images/thumb_default.jpg";
import defaultImage from "assets/images/temp/bg_curriculum.jpg";
import useClickOutside from "common/hook/useOutsideClick";
import { setAlarmCount as alarmAction } from "component/layout/Header/RightMenu/state/action";
import AlarmEmpty from "component/atoms/AlarmEmpty";
import { FiArrowRight } from "react-icons/fi";
import Loading from "component/atoms/Loading";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const { t } = useTranslation("notificationPage");

  const token = useSelector((state) => state.user.token);
  const alarmNumber = useSelector((state) => state.alarm.alarmCount);
  const [alarm, refreshAlarm] = useAxios(() => getNotificationAlarm(token), []);
  const [fixedNoticeList, fetchFixedNoticeList] = useAxios(
    getFixedNotice,
    [],
    true
  );
  const [noticeList, fetchNoticeList] = useAxios(getNotice, [], true);
  const [upcomingList, fetchUpcomingList] = useAxios(
    getUpcomingLectures,
    [],
    true
  );

  useEffect(() => {
    const getAlarmEveryMinute = setInterval(() => {
      refreshAlarm();
      //300000 => 5분에 한번씩 알림 업데이트 됩니다
    }, 300000);
    return () => clearInterval(getAlarmEveryMinute);
  }, []);

  const dispatch = useDispatch();
  const { data: alarmCount } = alarm;
  const { data: fixed, loading: fixedLoading } = fixedNoticeList;
  const { data: notice, loading: noticeListLoading } = noticeList;
  const { data: upcoming } = upcomingList;

  const [upcomingData, setUpcomingData] = useState([]);
  const [, setUpcomingHasMore] = useState(false);

  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState(1);
  const openRef = useRef();

  useClickOutside(openRef, () => {
    setOpen(false);
  });

  const tabItem = [
    { id: 1, text: t("header.notice") },
    { id: 2, text: t("header.upcoming") },
  ];

  const handleClick = (e, linkUrl) => {
    e.preventDefault();
    if (!linkUrl) {
      return;
    }
    if (
      checked === 2 &&
      (linkUrl?.includes("http://") || linkUrl?.includes("https://"))
    ) {
      window.open(linkUrl);
    } else if (
      checked === 2 &&
      (!linkUrl?.includes("http://") || !linkUrl?.includes("https://"))
    ) {
      window.open(`http://${linkUrl}`);
    }

    setOpen(false);
    setTimeout(() => {
      // refreshAlarm(token);
      fetchFixedNoticeList(0, token);
      fetchNoticeList(0, 10, token);
      fetchUpcomingList(0, token);
    }, 300);
  };

  const handleOpen = () => {
    setOpen((prev) => !prev);
    fetchFixedNoticeList(0, token);
    fetchNoticeList(0, 10, token);
    fetchUpcomingList(0, token);
  };

  useEffect(() => {
    dispatch(alarmAction(alarmCount));
  }, [alarmCount]);

  useEffect(() => {
    if (upcoming !== null) {
      setUpcomingData([...upcoming.content]);
      setUpcomingHasMore(upcoming.number + 1 < upcoming.totalPages);
    }
  }, [upcoming]);

  return (
    <Styled.Container ref={openRef}>
      <Styled.Button count={alarmNumber} onClick={() => handleOpen()} />

      <Styled.Notification className={open && "show"}>
        <Styled.TabAnchor>
          {tabItem.map((item) => {
            const { id, text } = item;
            return (
              <Styled.TabItem
                key={id}
                className={checked === id ? "active" : ""}
                onClick={() => setChecked(id)}
              >
                {text}
              </Styled.TabItem>
            );
          })}
        </Styled.TabAnchor>
        <Styled.List className={checked === 1 && "show"}>
          {(fixedLoading || noticeListLoading) && <Loading />}
          {fixed?.content.length === 0 && notice?.content.length === 0 && (
            <AlarmEmpty />
          )}
          {fixed?.content &&
            fixed.content.map((item, index) => {
              const { id, title, day, dayType, read } = item;
              const date = day ? day + dayType : dayType;
              return (
                <Styled.Item
                  key={index}
                  itemTitle={title}
                  itemDate={date}
                  itemLink={`/notification#notice-${id}`}
                  className={!read && "noread"}
                  onClick={(e) => handleClick(e)}
                />
              );
            })}
          {notice?.content &&
            notice.content.map((item, index) => {
              const { id, title, day, dayType, read } = item;
              const date = day ? day + dayType : dayType;
              return (
                <Styled.Item
                  key={index}
                  itemTitle={title}
                  itemDate={date}
                  itemLink={`/notification#notification-${id}`}
                  className={!read && "noread"}
                  onClick={(e) => handleClick(e)}
                />
              );
            })}
        </Styled.List>
        <Styled.List className={checked === 2 && "show"}>
          {upcomingData && upcomingData.length === 0 && <AlarmEmpty />}
          {upcomingData &&
            upcomingData.map((item, index) => {
              const { title, day, dayType, thumbnail, linkUrl } = item;
              const link = linkUrl.includes("www") ? linkUrl : "//" + linkUrl;
              const date = day ? day + dayType : dayType;
              return (
                <Styled.Item
                  key={index}
                  itemTitle={title}
                  itemDate={date}
                  itemLink=""
                  imgSrc={thumbnail === null ? srcImage : thumbnail}
                  // className={!read && 'noread'}
                  onClick={(e) => handleClick(e, linkUrl)}
                />
              );
            })}
        </Styled.List>
        {checked !== 2 && (
          <>
            <Styled.Link to="/notification" onClick={() => setOpen(false)}>
              {t("header.more")}
            </Styled.Link>
            <FiArrowRight
              className="arrow-right"
              color="#ffb500"
              size="1.6em"
            />
          </>
        )}
      </Styled.Notification>
    </Styled.Container>
  );
};

export default Notification;

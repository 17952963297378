import { asyncApi } from 'common/util/asyncApi';

const baseUrl = "/api/v1/products"

const wbmKoreaApi = {
  fetchVideoData: async (productId) => {
    const targetUrl = `${baseUrl}/${productId}/course/videos?type=detail`;

    const config = {
      url: targetUrl,
    };

    let response = await asyncApi(config);
    return response.data;
  },
  fetchOverview: async (productId) => {
    const targetUrl = `${baseUrl}/${productId}/course/overview`;

    const config = {
      url: targetUrl
    };

    let response = await asyncApi(config);
    return response.data;
  }
}

export default wbmKoreaApi;
import styled, { css } from 'styled-components';
import { BsChevronDoubleRight } from 'react-icons/bs';
import { RiArrowRightSLine } from 'react-icons/ri';
import { FaPlusCircle } from 'react-icons/fa';
import { Swiper } from 'swiper/react';
import swiperNavIcon from 'assets/images/event/230310/swiper_nav.png';

import visualImg from 'assets/images/event/230310/visual.jpg';
import visualImgKo from 'assets/images/event/230310/ko/visual.jpg';

import curriculumImg from 'assets/images/event/230310/curriculum.jpg';
import curriculumImgKo from 'assets/images/event/230310/ko/curriculum.jpg';
import curriculumImg2 from 'assets/images/event/230310/curriculum2.jpg';
import curriculumImg2Ko from 'assets/images/event/230310/ko/curriculum2.jpg';
import curriculumImg3 from 'assets/images/event/230310/curriculum3.jpg';
import curriculumImg3Ko from 'assets/images/event/230310/ko/curriculum3.jpg';

import curriculumMoImg from 'assets/images/event/230310/curriculumMo.jpg';
import curriculumMoImgKo from 'assets/images/event/230310/ko/curriculumMo.jpg';
import curriculumMoImg2 from 'assets/images/event/230310/curriculumMo2.jpg';
import curriculumMoImg2Ko from 'assets/images/event/230310/ko/curriculumMo2.jpg';
import curriculumMoImg3 from 'assets/images/event/230310/curriculumMo3.jpg';
import curriculumMoImg3Ko from 'assets/images/event/230310/ko/curriculumMo3.jpg';

const StyledEventPage = {
  BtnWrap: styled.div.attrs({ className: 'btnWrap' })`
    position: absolute;
    left: 0;
    bottom: 4em;
    width: 100%;
    z-index: 91;
    background-color: rgba(133, 184, 221, 0.8);
    &.fixed {
      position: fixed;
      bottom: 0;
      background-color: rgba(133, 184, 221, 1);
      box-shadow: 0.2em 0.2em 0.4em rgba(0,0,0,.3);
    }
    button {
      position: relative;
      display: inline-block;
      margin: auto;
      padding: 0.9em 1.3em;
      border-radius: 10em;
      line-height: 1em;
      font-weight: 700;
      font-size: 1.6em;
      color: #0f43a2;
      text-align: center;
      @media (max-width: 750px) {
        font-size: 1.4em;
      }
      svg {
        vertical-align: top;
      }
    }
  `,

  icoArrow: styled.span.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <BsChevronDoubleRight />
      </>
    ),
  }))``,

  icoArrow2: styled.span.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <RiArrowRightSLine />
      </>
    ),
  }))``,

  icoPlus: styled.span.attrs(({ children }) => ({
    children: (
      <>
        <FaPlusCircle />
        {children}
      </>
    ),
  }))`
    svg {
      margin-bottom: -0.14em;
      margin-right: 0.1em;
      font-size: 1.05em;
    }
  `,

  Title: styled.div`
    margin-bottom: 1.8em;
    font-weight: 200;
    font-size: 1.3em;
    color: ${({ color }) => color && color};
    h2 {
      margin-top: 0.3em;
      font-weight: 700;
      font-size: 1.4em;
      color: ${({ colorH2 }) => colorH2 && colorH2};
    }
    @media (max-width: 750px) {
      //font-size: 1.5em;
    }
  `,

  Tab: styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 0 1em;
    button {
      width: 12em;
      margin: 0 0.4em;
      padding: 0.8em 0.4em 0.4em;
      border-radius: 1.2em 1.2em 0 0;
      font-size: 1em;
      font-weight: 600;
      background-color: #9c82fc;
      color: #2c1e6d;
      > div {
        opacity: .6;
      }
      &.active {
        background-color: #ffffff;
        color: #0d205b;
        > div {
          opacity: 1;
        }
      }
      span {
        display: block;
        color: inherit;
        font-weight: 400;
        font-size: 1.2em;
      }
    }
    &.type2 {
      button {
        background-color: #5ef4cf;
        color: #005973;
        &.active{
          background-color: #ffffff;
          color: #005973;
        }
      }
    }
    @media (max-width: 750px) {
      button {
        margin: 0 0.2em;
        span {
          font-size: 1.1em;
        }
      }
    }
  `,
  TabCont: styled.section`
    &.show {
      display: block;
    }
    &.hide {
      display: none;
    }
    position: relative;
    padding: 2.5em;
    border-radius: 1em;
    background-color: #fff;
    box-shadow: 0 -0.4em 0.8em rgba(0, 0, 0, 0.2);

    .tit-tab {
      position: relative;
      margin-bottom: 1em;
      padding-left: 0.6em;
      text-align: left;
      font-weight: 600;
      font-size: 1.3em;
      color: #000;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0.2em;
        width: 0.2em;
        height: 0.8em;
        background-color: #613ee3;
      }
    }
    .course {
      display: flex;
      justify-content: space-between;
      .info {
        width: 265px;
        .btn-type {
          display: flex;
          justify-content: space-between;
          height: 50%;
          margin-bottom: 1.5em;
          button {
            width: calc(50% - 0.3em);
            height: 100%;
            background: #c0c0c0;
            color: #fff;
            border-radius: 1em 1em 0 0;
            &.active {
              background: #0250fa;
            }
            div {
              font-size: 1.2em;
              span {
                font-weight: 600;
              }
            }
          }
        }
        .picture {
          position: relative;
          width: 73%;
          height: 50%;
          margin-bottom: 1.5em;
          overflow: hidden;
          text-align: left;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 1em 1em 0 0;
          }
        }
        .teacher {
          text-align: left;
          &.show {
            display: block;
          }
          &.hide {
            display: none;
          }
          p {
            margin-bottom: 0.4em;
            font-weight: 600;
            color: #666666;
            strong {
              font-size: 1.3em;
              color: #000;
            }
          }
          li {
            position: relative;
            padding-left: 0.5em;
            font-size: 0.9em;
            color: #666666;
            &:before {
              content:"";
              display:inline-block;
              position: absolute;
              top:0.5em;
              left:0;
              width: 0.222em;
              height: 0.2222em;
              border-radius: 2em;
              vertical-align:middle;
              background-color: #666666;
            }
          }
        }
      }
      .video {
        width: 556px;
        .tit-lecture {
          position: relative;
          display: inline-block;
          margin-bottom: 1em;
          h5 {
            position: relative;
            font-weight: 600;
            font-size: 1.1em;
            color: #0250fa;
          }
          &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0.1em;
            width: 100%;
            height: 0.5em;
            background-color: #fff799;
          }
        }
        .youtube {
          position: relative;
          width: 100%;
          padding-top: 56.25%;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    @media (max-width: calc(960px + 2em)) {
      padding: 1.5em;
      .course {
        display: block;
        .info {
          width: 100%;
          margin-bottom: 2em;
          .btn-type {
            height: 8em;
          }
          .picture {
            width: 100%;
            height: 8em;
            img {
              position: static;
              width: auto;
            }
          }
        }
        .video {
          width: 100%;
        }
      }
    }
  `,

  SwiperWrap: styled.section`
    position: relative;
    border-radius: 1em;
    background-color: #fff;
    box-shadow: 0 -0.4em 0.8em rgba(0, 0, 0, 0.2);
    overflow: hidden;
  `,
  Swiper: styled(Swiper)`
    position:static;
    height: 524px;
    .swiper-button-prev,
    .swiper-button-next {
      display:inline-block;
      position: absolute;
      top: 50%;
      z-index:10;
      background-image: url(${swiperNavIcon});
      background-size: contain;
      background-repeat: no-repeat;
      width: 1.9em;
      height: 3.5em;
      cursor: pointer;
    }
    .swiper-button-prev {
      left: 1.5em;
      transform: translateY(-50%);
    }
    .swiper-button-next {
      right: 1.5em;
      transform: translateY(-50%) rotate(180deg);
    }
    .txt-box {
      position: absolute;
      width: 100%;
      max-width: 750px;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      padding: 0.6em 1em 1em;
      color: #fff;
      .tit {
        margin-bottom: 0.5em;
        font-weight: 600;
        color: #b8ed6b;
      }
    }
    .slide4 {
      height: 100%;
      background-color: #0f1012;
      .txt-box {
        background-color: #1f2024;
      }
    }
    .slide5 {
      height: 100%;
      background-color: #fff;
      .txt-box {
        background-color: #5a5b9b;
      }
    }
    @media (max-width: calc(960px + 2em)) {
      height: 55vw;
      .swiper-button-prev,
      .swiper-button-next {
        width: calc(1.9em * 0.7);
        height: calc(3.5em * 0.7);
      }
      .swiper-button-prev {
        left: 0.5em;
      }
      .swiper-button-next {
        right: 0.5em;
      }
      .slide4 img,
      .slide5 img {
        width: 61.458vw;
      }
    }
  `,

  Quiz: styled.div`
    height: 100%;
    padding-top: 3em;
    .flip-card {
      width: 100%;
      height: 350px;
      perspective: 1000px;
    }
    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }
    .animate-wiggle {
      transform: rotateY(180deg);
    }
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      /* Safari */
      backface-visibility: hidden;
    }
    .flip-card-front {
    }
    .flip-card-back {
      transform: rotateY(180deg);
    }
    .btn-answer {
      position: absolute;
      width: 100%;
      max-width: 750px;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      padding: 0.8em;
      font-weight: 600;
      font-size: 1.4em;
      color: #222;
      background-color: #fed440;
      border-radius: 0.89em 0.89em 0 0;
      z-index: 20;
    }
  `,

  EventWrap: styled.div`
    font-family: 'Pretendard';
		font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    color: #555;
    letter-spacing: -0.035em;

		.blind {
			overflow: hidden;
			position: absolute;
			clip: rect(0 0 0 0);
			width: 1px;
			height: 1px;
			margin: -1px;
		}

    .inner {
      position: relative;
			max-width: 960px;
      margin: auto;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 6em 0;
    }

    .visual {
      position: relative;
      background-size: auto 1110px;
      background-image: url(${visualImg});
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-color: #0d205b;
			.inner {
        height: 1110px;
      }
      .dday {
        position: absolute;
        top: 0;
        left: 50%;
        height: 3em;
        transform: translateX(-50%);
        background-color: #3bf2ff;
        font-size: 1.4em;
        color: #0e2670;
        padding: 0.35em 1em;
        margin-top: 4em;
        white-space: nowrap;
        strong {
          vertical-align: middle;
          font-size: 1.6em;
          font-weight: 900;
          color: #0f43a2;
          span {
            display: inline-block;
            width: 1.45em;
            height: 1.45em;
            line-height: 1.45em;
            background-color: #fff;
            border-radius: 1.45em;
          }
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 0.5em;
          top: 0;
          transform: translateX(-100%);
          border-top: 1.5em solid #3bf2ff;
          border-bottom: 1.5em solid #3bf2ff;
          border-left: 1em solid transparent;
          border-right: 0.5em solid #3bf2ff;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          right: 0.5em;
          top: 0;
          transform: translateX(100%);
          border-top: 1.5em solid #3bf2ff;
          border-bottom: 1.5em solid #3bf2ff;
          border-right: 1em solid transparent;
          border-left: 0.5em solid #3bf2ff;
        }
      }
    }
    &.ko .visual {
      background-image: url(${visualImgKo});
    }
    @media (max-width: calc(960px + 2em)) {
      .inner {
        padding-top: 4em;
        padding-bottom: 4em;
        padding-left: 1em;
        padding-right: 1em;
      }
      .visual {
        background-size: auto 115.625vw;
        .inner {
          height: 115.625vw;
        }
      }
    }
    @media (max-width: 750px) {
      .visual {
        .dday {
          margin-top: 9vw;
          font-size: 1.2em;
          height: 2.8em;
          strong {
            font-size: 1.3em;
          }
          &::before,
          &::after {
            border-top-width: 1.4em;
            border-bottom-width: 1.4em;
          }
        }
      }
    }

    .preview {
      background: #613ee3;
      .h2 {color: #f9f9f9;}
      .h3 {
        position: relative;
        margin: 2em 0 1.4em;
        padding-top: 0.5em;
        font-weight: 200;
        font-size: 1.5em;
        color: #fff45c;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 2em;
          height: 3px;
          background: #fff45c;
        }
        span {
          display: block;
          font-weight: 700;
        }
      }
    }

    .curriculum {
      background: #eaedf2;
      .tab-cont {
        margin: 0 auto;
        max-width: 750px;
        padding-top: 635px;
        background-position: 50% 0;
        background-repeat: no-repeat;
        background-size: 100% auto;
        &.cont {
          background-image: url(${curriculumImg});
        }
        &.cont2 {
          background-image: url(${curriculumImg2});
        }
        &.cont3 {
          background-image: url(${curriculumImg3});
        }
      }
    }
    &.ko .curriculum{
      .tab-cont {
        &.cont {
          background-image: url(${curriculumImgKo});
        }
        &.cont2 {
          background-image: url(${curriculumImg2Ko});
        }
        &.cont3 {
          background-image: url(${curriculumImg3Ko});
        }
      }
    }
    @media (max-width: 750px) {
      .curriculum {
        .tab-cont {
          margin: 0 auto;
          padding-top: 124%;
          &.cont {
            background-image: url(${curriculumMoImg});
          }
          &.cont2 {
            background-image: url(${curriculumMoImg2});
          }
          &.cont3 {
            background-image: url(${curriculumMoImg3});
          }
        }
      }
      &.ko .curriculum{
        .tab-cont {
          padding-top: 111.666%;
          &.cont {
            background-image: url(${curriculumMoImgKo});
          }
          &.cont2 {
            background-image: url(${curriculumMoImg2Ko});
          }
          &.cont3 {
            background-image: url(${curriculumMoImg3Ko});
          }
        }
      }
    }

    .report {
      background: #fed440;
      .flow_screen {position: relative; margin: 0 auto; overflow: hidden;}
      .flow_banner {position: relative; display: flex; -webkit-box-align: center; align-items: center;}
      .flow_banner ul {display: flex;}
      .flow_banner li {position:relative; flex-shrink: 0; box-sizing: content-box; width: 300px; height: 385px; border-radius:1em; overflow: hidden; box-shadow: 0.3em 0 0.8em rgba(0,0,0,.12); margin-top:1em; margin-bottom:1em; margin-right: 30px;}
      .flow_banner li img {width: 100%; height: auto;}
      .flow_banner ul:first-of-type {animation: 20s linear 0s infinite normal none running firstFlow;}
      .flow_banner ul:last-of-type {animation: 20s linear 0s infinite normal none running secondFlow; transform: translate(100%);}
      @keyframes firstFlow {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(-100%);
        }
        50.01%{
            transform: translateX(100%);
        }
        100%{
            transform: translateX(0);
        }
      }
      @keyframes secondFlow {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-200%);
        }
      }
    }
    @media (max-width: 960px) {
      .report{
        .inner {
          padding-left: 0;
          padding-right: 0;
        }
        .flow_banner li {
          width: 31.25vw;
          height: 40.104vw;
          margin-right: 3.125vw;
        }
      }
    }

    .plan {
      background: #0d205b;
      .plan-wrap {
        display: flex;
        justify-content: space-between;
        .card {
          position: relative;
          width: calc(50% - 1em);
          padding: 2em 1em calc(2em + 4.25em) 2em;
          background: #fff;
          border-radius: 1.25em;
          overflow: hidden;
          .btn {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 4.25em;
            line-height: 2.45em;
            padding: 0.8em 2em 1em 3em;
            border-top: 3px solid #0d205b;
            overflow: hidden;
            &.green {
              background-color: #b8ed6b;
              .apply {
                color: #005a14;
              }
            }
            &.blue {
              background-color: #4cf2ff;
              .apply {
                color: #0250fa;
              }
            }
            .price {
              float: left;
              font-weight: 600;
              font-size: 1.4em;
              color: #222;
              span {
                font-size: 1.6em;
              }
            }
            .apply {
              float: right;
              font-weight: 600;
              font-size: 1.4em;
              * {vertical-align: middle;}
            }
          }
          .tit {
            position: relative;
            display: inline-block;
            font-size: 1.5em;
            color: #0f43a2;
            margin-bottom: 1.1em;
            h3 {
              position: relative;
              font-weight: 600;
            }
            &::before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0.1em;
              width: 100%;
              height: 0.4em;
            }
            &.green::before {
              background-color: #cdf297;
            }
            &.blue::before {
              background-color: #81f6ff;
            }
          }
          dl {
            margin-bottom: 1em;
            display: flex;
            justify-content: left;
            text-align: left;
            dt {
              position: relative;
              width: 30%;
              padding-left: 0.6em;
              font-weight: 600;
              color: #0d205b;
              &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0.2em;
                width: 0.2em;
                height: 0.8em;
                background-color: #613ee3;
              }
            }
            dd {
              color: #222;
              .add {
                color: #c2102a;
              }
            }
          }
        }
      }
    }
    @media (max-width: 750px) {
      .plan {
        .plan-wrap {
          display: block;
          .card {
            width: 100%;
            & + .card {
              margin-top: 1em;
            }
            dt {
              br {display: none;}
            }
          }
        }
      }
    }

    .notification {
      background-color: #020f39;
      text-align: left;
      color: #015583;
      font-weight: 300;
      letter-spacing: normal;
      .inner {
        padding-top: 3em;
        padding-bottom: 3em;
      }
      h3 {
        font-weight: 700;
        font-size: 1.3em;
        color: #5e9ec1;
      }
      ul {
        margin-top: 1em;
        font-size: 0.9em;
      }
      li {
        position:relative;
        padding-left: 1.2em;
        margin-bottom: 0.5em;
        color: #015583;
        &:before {
          content:"-";
          display:inline-block;
          position: absolute;
          top:0;
          left:0.3em;
          width: 0.25em;
          height: 0.25em;
          border-radius: 0.2em;
          vertical-align:middle;
        }
      }
    }
  `,
};

export default StyledEventPage;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Style from './styled/Banner.style';
import {Link} from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

const NoticeBanner = () => {
  const { t } = useTranslation('wbmKoreaPage');

  return (
    <>
    <Style.BannerWrap>
        <p>{t('bannerNotice.title1')}</p>
        <p>{t('bannerNotice.title2')}</p>
        <p>{t('bannerNotice.title3')}</p>
        <Link to='mailto:wbm@worldwidelink.com.ph' target='_blank'>
            <span>{t('bannerNotice.noticeEmail')}</span>
        </Link>
    </Style.BannerWrap>
    </>
  );
};
export default NoticeBanner;
import React, { useEffect } from 'react';
import Style from './styled/Table.style.js';
import { priceFormat } from 'common/util/util.js';
import { useHistory, useLocation } from 'react-router';
import { CURRENCY } from 'common/constant.js';

const Table = ({ currentCurrency, freeTrialData, t }) => {
  const { state } = useLocation();
  const history = useHistory();

  const price = () =>
    currentCurrency === CURRENCY.DOMESTIC
      ? priceFormat.korea(state?.priceKrw)
      : priceFormat.usa(state?.priceUsd);
  const subscribePeriod = React.useMemo(() => {
    let term = '1';
    if (state && state.takeCourseTerm > 0) {
      if (state.takeCourseTerm === 1 || state.takeCourseTerm === 12)
        term = state.takeCourseTerm;
      else term = `Else`;
    }
    return term;
  }, [state]);
  if (!state) return history.goBack();
  return (
    <Style.Table>
      <Style.TableHead>
        <span>{t('selectedSection.thead1')}</span>
        <span>{t('selectedSection.thead2')}</span>
        <span>{t('selectedSection.thead3')}</span>
        <span>{t('selectedSection.thead4')}</span>
      </Style.TableHead>

      <Style.TableBody>
        <div>
          <span>Plan</span>
          <span>
            {state?.planCode} (
            {subscribePeriod === 'Else' ? state?.takeCourseTerm : ''}
            {t(`selectedSection.subscribe${subscribePeriod}`)})
          </span>
          <span className='quantity'>1</span>
          <span
            className={`price ${
              freeTrialData && freeTrialData.isFreeTrial === 'Y'
                ? 'free_trial'
                : ''
            }`}
          >
            <span className='txt_price'>{price()}</span>
            <span className='txt_free'>Free Trial</span>
          </span>
        </div>
      </Style.TableBody>
    </Style.Table>
  );
};

export default Table;

import React from 'react';
import Style from '../styled/component.style';

const li = [1, 2, 3, 4];
const ResultChoice = ({ answers }) => {
  let { userAnswer, correctNo } = answers;

  const className = (number) => {
    userAnswer = userAnswer ? parseInt(userAnswer) : null;
    correctNo = parseInt(correctNo);

    if (correctNo === userAnswer && correctNo === number) {
      return 'same';
    }
    if (correctNo === number) {
      return 'correct';
    }
    if (userAnswer === number) {
      return 'wrong';
    }
    return '';
  };
  return (
    <Style.QuestionChoiceContainer>
      {li.map((data) => {
        return (
          <Style.QuestionChoice key={data}>
            <span className={className(data)}>{data}</span>
          </Style.QuestionChoice>
        );
      })}
    </Style.QuestionChoiceContainer>
  );
};

export default React.memo(ResultChoice);

import { useSelector } from 'react-redux';

export const addBookToState = (bookItems, book) => {
  const findExistingItem = bookItems.find(
    (bookItem) => bookItem.id === book.id
  );

  if (findExistingItem) {
    return bookItems.map((bookItem) =>
      bookItem.id === book.id
        ? {
            ...bookItem,
            price: bookItem.price,
            quantity: bookItem.quantity + 1,
            total: bookItem.price * (bookItem.quantity + 1),
            totalDiscount: bookItem.discountCost * (bookItem.quantity + 1),
            totalDollar: bookItem.priceDollar * (bookItem.quantity + 1),
            totalDiscountDollar:
              bookItem.discountCostDollar * (bookItem.quantity + 1),
          }
        : bookItem
    );
  } else {
    return [
      {
        ...book,
        quantity: 1,
        total: book.price,
        totalDiscount: book.discountCost,
        totalDollar: book.priceDollar,
        totalDiscountDollar: book.discountCostDollar,
      },
      ...bookItems,
    ];
  }
};

export const deleteItem = (bookItems, book) => {
  const findExistingItem = bookItems.find(
    (cartItem) => cartItem.id === book.id
  );

  if (findExistingItem.quantity === 1) {
    return bookItems.filter((cartItem) => cartItem.id !== book.id);
  } else {
    const removedBookItems = bookItems.map((bookItem) =>
      bookItem.id === book.id
        ? {
            ...bookItem,
            price: bookItem.price,
            quantity: bookItem.quantity - 1,
            total: bookItem.price * (bookItem.quantity - 1),
            totalDiscount: bookItem.discountCost * (bookItem.quantity - 1),
            totalDollar: bookItem.priceDollar * (bookItem.quantity - 1),
            totalDiscountDollar:
              bookItem.discountCostDollar * (bookItem.quantity - 1),
          }
        : bookItem
    );
    return removedBookItems;
  }
};

//add total price and add total discount price
export const AddTotalPrice = (korean) => {
  const state = useSelector((state) => state.bookStore);

  let totalDisCountPrice, totalPrice, finalTotal;

  if (korean) {
    totalPrice = state.bookItems.reduce((acc, data) => {
      return (acc += data.total);
    }, 0);

    totalDisCountPrice = state.bookItems.reduce((acc, data) => {
      return (acc += data.totalDiscount);
    }, 0);

    finalTotal = totalPrice - totalDisCountPrice;
  } else {
    totalPrice = state.bookItems.reduce((acc, data) => {
      return (acc += data.totalDollar);
    }, 0);

    totalDisCountPrice = state.bookItems.reduce((acc, data) => {
      return (acc += data.totalDiscountDollar);
    }, 0);

    finalTotal = totalPrice - totalDisCountPrice;
  }

  return { totalDisCountPrice, totalPrice, finalTotal };
};

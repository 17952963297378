import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Style from './styled/AccountSelect.style';

const options = [
  { value: 'EN', label: 'English' },
  { value: 'KO', label: '한국어' },
  { value: 'CN', label: '汉语' },
  { value: 'JP', label: '日本語' },
  // { value: 'VN', label: 'Vietnamese' },
];
const AccountSelect = ({ onChange, data }) => {
  const [index, setIndex] = useState(null);

  useEffect(() => {
    const defaultIndex = options.findIndex(({ value }) => {
      return value === data.selectLanguage;
    });
    setIndex(defaultIndex);
  }, [data.selectLanguage]);

  const selectLang = (e) => {
    onChange(e);
    const newIndex = options.findIndex(({ value }) => {
      return value === e.value;
    });
    setIndex(newIndex);
  };

  return (
    <Style.Container>
      <Select
        value={options[index]}
        options={options}
        onChange={(e) => selectLang(e)}
        isSearchable={false}
        classNamePrefix='select'
      />
    </Style.Container>
  );
};

export default AccountSelect;

const EPSTranslation = {
  english: {
    header: {
      title: 'Master EPS-TOPIK!',
      desc1: 'The fastest way to pass the EPS-TOPIK!',
      desc2:
        'Customized courses for EPS TOPIK by thoroughly analyzing previous tests',
    },
    leftSection: {
      title: 'The characteristics of a lecture',
      content: [
        'Complete Analysis of EPS-TOPIK Previous Tests',
        'Complete organization of visual materials such as vocabulary, grammar, and sign that are frequently asked',
        'Complete preparation for actual test with mock test',
      ],
    },
    rightSection: {
      content: [
        {
          title: '01 Vocabulary',
          desc1: 'OOO essential vocabularies',
          desc2:
            'Complete organization vocabularies that are frequently asked on the reading and listening tests',
        },
        {
          title: '02 Grammar',
          desc1: 'OOO essential Grammars',
          desc2: 'Learning Grammar Required to Pass the EPS TOPIK',
        },
        {
          title: '01 Mock test',
          desc1: '2 SET Mock test commentary',
          desc2:
            '  Solving mock test questions of the same type as the actual test',
        },
      ],
    },
    button: {
      content: 'View',
    },
  },

  indonesia: {
    header: {
      title: 'Master EPS-TOPIK!',
      desc1: 'Cara tercepat lulus EPS-TOPIK!',
      desc2:
        'Kelas untuk EPS-TOPIK dengan banyak pembahasan soal ujian yang lalu',
    },
    leftSection: {
      title: 'Karakteristik Kelas',
      content: [
        'Analisis lengkap soal EPS-TOPIK yang lalu',
        'Kumpulan lengkap kosakata, tata bahasa, dan gambar yang sering keluar di ujian',
        'Persiapan ujian lengkap dengan soal latihan',
      ],
    },
    rightSection: {
      content: [
        {
          title: '01 Kosakata',
          desc1: 'Kosakata penting ',
          desc2:
            'Kumpulan lengkap kosakata yang sering ditanyakan di soal membaca dan mendengar',
        },
        {
          title: '02 Tata Bahasa',
          desc1: 'Tata bahasa penting ',
          desc2: 'Mempelajari tata bahasa agar lulus EPS-TOPIK',
        },
        {
          title: '03 Soal Latihan',
          desc1: '2 SET soal latihan dan pembahasan',
          desc2: 'Mengerjakan soal latihan yang mirip dengan ujian asli',
        },
      ],
    },
    button: {
      content: 'Lihat detail',
    },
  },
};

export default EPSTranslation;

import React, { useEffect, useRef, useState, useCallback } from 'react';
import Styled from '../styled/StickyArea.style';
import Scroll from "react-scroll";
import { useTranslation } from 'react-i18next';
import { MOBILE_APP_USER_AGENT } from "common/constant";
import { openLink } from 'common/util/util';

const TeacherStickyArea = ({dday, text, link, showTopik, showKorean, showMyClass}) => {
  const { t } = useTranslation('aliveClassPage');
  const [dDay, setDDay] = useState('D-0');
  const stickyWrapRef = useRef();
  const stickyRef = useRef();
  const [hHeight, sethHeight] = useState(document.querySelector('header')? document.querySelector('header').getBoundingClientRect().bottom : 0); //해더 높이
  const [sHeight, setsHeight] = useState(stickyRef.current?.clientHeight? stickyRef.current.clientHeight : 54); //stickyRef height
  const [scrollOffset, setScrollOffset] = useState((hHeight + sHeight) * -1); //tab scroll offset
  const [winScrollY, setWinScrollY] = useState(window.scrollY);

  // Sticky Menu
  useEffect(() => {
    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onResize);
    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('scroll', onResize);
    };
  }, []);

  const onResize = () => {
    const headerBtm = document.querySelector('header').getBoundingClientRect().bottom;
    sethHeight(headerBtm);
    setsHeight(stickyRef.current.clientHeight);
    setScrollOffset((headerBtm + stickyRef.current.clientHeight) * -1);
    setWinScrollY(window.scrollY);
  }

  useEffect(()=>{
    const stickyWrap = stickyWrapRef.current;
    const sticky = stickyRef.current;
    const stickyHeight = sHeight;
    const headerBtm = hHeight;
    const scrollTop = winScrollY;
    const stickyTop = window.pageYOffset + stickyWrapRef.current.getBoundingClientRect().top - headerBtm;

    if(sticky.classList.contains('sticky')){
      if(scrollTop < stickyTop) sticky.classList.remove('sticky');
    }else{
      if(scrollTop >= stickyTop) sticky.classList.add('sticky')
    }

    if (sticky.classList.contains('sticky')){
      stickyWrap.style.paddingTop = stickyHeight + 'px';
      sticky.style.top = headerBtm + 'px';
    } else {
      stickyWrap.style.paddingTop = '0';
      sticky.style.top = '0';
    }
  }, [hHeight, sHeight, winScrollY]);

  const scrollOptions={
    activeClass: 'active',
    smooth: true,
    spy: true,
    duration: 300,
  }

  return (
    <>
      {/* sticky 영역 */}
      <Styled.StickyWrap className='stickyWrap' ref={stickyWrapRef}>
        <Styled.StickyArea className='stickyArea' ref={stickyRef}>
          <Styled.StickyTab>
            <Styled.StickyInner>
              <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="AboutTeacher">
                {t('teacherDetail.tab')}
              </Scroll.Link>
              <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="AboutClass">
                {t('teacherDetail.tab2')}
              </Scroll.Link>
              <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="ApplyClass">
                {t('teacherDetail.tab3')}
              </Scroll.Link>
              <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="Availability">
                {t('teacherDetail.tab4')}
              </Scroll.Link>
              <Scroll.Link className='btnTab' {...scrollOptions} offset={scrollOffset} to="FAQ">
                {t('teacherDetail.tab5')}
              </Scroll.Link>
            </Styled.StickyInner>
          </Styled.StickyTab>
        </Styled.StickyArea>
      </Styled.StickyWrap>
    </>
  );
};

export default TeacherStickyArea;

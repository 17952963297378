import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languageCode } from 'common/constant';
import { termsOfServiceKo } from 'container/TermsPage/contents/2014/termsOfServiceKo';
// import { termsOfServiceEn } from 'container/TermsPage/contents/2021/termsOfServiceEn';
// import { termsOfServiceZh } from 'container/TermsPage/contents/2021/termsOfServiceZh';
// import { termsOfServiceJa } from 'container/TermsPage/contents/2021/termsOfServiceJa';

const Service20140728 = () => {
  const { i18n } = useTranslation('privacyPage');
  const { KOREAN, CHINESE, JAPANESE } = languageCode;
  const [content, setContent] = useState('');

  useEffect(() => {
    if (!i18n?.language) return;

    switch (i18n.language) {
      case KOREAN.locale:
        setContent(termsOfServiceKo);
        break;
      case CHINESE.locale:
        // setContent(termsOfServiceZh);
        setContent(termsOfServiceKo);
        break;
      case JAPANESE.locale:
        // setContent(termsOfServiceJa);
        setContent(termsOfServiceKo);
        break;
      default:
        // setContent(termsOfServiceEn);
        setContent(termsOfServiceKo);
        break;
    }
  }, [i18n.language]);

  return <>{content}</>;
};

export default Service20140728;

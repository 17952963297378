import {
  createReducer,
  createSetValueAction,
  setValueReducer,
} from "common/redux-helper";
import { CURRENCY, FetchStatus } from "../constant";

export const Types = {
  SetValue: "common/SetValue",
  SetIsSlow: "common/SetIsSlow",
  SetFetchStatus: "common/SetFetchStatus",
  SetCurrency: "common/SetCurrency",
  SetRedirect: "common/SetRedirect",
  SetBannerClose: "common/SetBannerClose",
  SetLastPayment: "common/SetLastPayment",
};

export const actions = {
  setValue: createSetValueAction(Types.SetValue),
  setIsSlow: (payload) => ({ type: Types.SetIsSlow, payload }),
  setFetchStatus: (payload) => ({ type: Types.SetFetchStatus, payload }),
  setRedirect: (redirectUrl) => ({ type: Types.SetRedirect, redirectUrl }),
  setCurrency: (currency) => ({ type: Types.SetCurrency, currency }),
  setBannerClose: (isBannerClose) => ({
    type: Types.SetBannerClose,
    isBannerClose,
  }),
  setLastPayment: (lastPaymentType) => ({ type: Types.SetLastPayment, lastPaymentType }),
};

const INITIAL_STATE = {
  fetchInfo: {
    fetchStatusMap: {},
    isSlowMap: {},
    totalCountMap: {},
    errorMessageMap: {},
    nextPageMap: {},
  },
  redirectUrl: undefined,
  currency: CURRENCY.DOMESTIC,
  isBannerClose: {
    state: false,
    bannerData: null,
  },
  lastPaymentType: undefined,
};
const reducer = createReducer(INITIAL_STATE, {
  [Types.SetValue]: setValueReducer,
  [Types.SetFetchStatus]: (state, action) => {
    const {
      actionType,
      fetchKey,
      status,
      totalCount,
      nextPage,
      errorMessage,
    } = action.payload;
    if (!state.fetchInfo.fetchStatusMap[actionType]) {
      state.fetchInfo.fetchStatusMap[actionType] = {};
    }
    state.fetchInfo.fetchStatusMap[actionType][fetchKey] = status;

    if (status !== FetchStatus.Request) {
      if (state.fetchInfo.isSlowMap[actionType]) {
        state.fetchInfo.isSlowMap[actionType][fetchKey] = false;
      }
      if (totalCount !== undefined) {
        if (!state.fetchInfo.totalCountMap[actionType]) {
          state.fetchInfo.totalCountMap[actionType] = {};
        }
        state.fetchInfo.totalCountMap[actionType][fetchKey] = totalCount;
      }
      if (nextPage !== undefined) {
        if (!state.fetchInfo.nextPageMap[actionType]) {
          state.fetchInfo.nextPageMap[actionType] = {};
        }
        state.fetchInfo.nextPageMap[actionType][fetchKey] = nextPage;
      }
      if (!state.fetchInfo.errorMessageMap[actionType]) {
        state.fetchInfo.errorMessageMap[actionType] = {};
      }
      if (errorMessage) {
        state.fetchInfo.errorMessageMap[actionType][fetchKey] = errorMessage;
      }
    }
  },
  [Types.SetIsSlow]: (state, action) => {
    const { actionType, fetchKey, isSlow } = action.payload;
    if (!state.fetchInfo.isSlowMap[actionType]) {
      state.fetchInfo.isSlowMap[actionType] = {};
    }
    state.fetchInfo.isSlowMap[actionType][fetchKey] = isSlow;
  },
  [Types.SetRedirect]: (state, action) =>
    (state.redirectUrl = action.redirectUrl),
  [Types.SetCurrency]: (state, action) => {
    if (Object.values(CURRENCY).some((i) => action.currency === i)) {
      state.currency = action.currency;
    }
  },
  [Types.SetBannerClose]: (state, action) => {
    state.isBannerClose = action.isBannerClose;
  },
  [Types.SetLastPayment]: (state, action) =>
    (state.lastPaymentType = action.lastPaymentType),
});

export default reducer;

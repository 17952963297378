import styled from 'styled-components';
import surveyIcon from 'assets/images/ico_survey.png';

import iconCheck from 'assets/images/ico_surveyCheck.png';

const StyleCancelSurveyPage = {
  Container: styled.div`
    padding: 0 20em;
    text-align: center;

    @media (max-width: 767px) {
      padding: 0;
    }
  `,
  Image: styled.span`
    display: inline-block;
    width: 5.7em;
    height: 7em;
    background: url(${surveyIcon}) no-repeat center center/cover;
  `,
  Title: styled.h3`
    margin: 1.2em 0 0.8em;
    color: #fff;
    font-size: 3.4em;
    font-weight: 700;
  `,
  Desc: styled.p`
    padding: 0 1.8em;
    color: #8e9199;
    font-size: 2em;
    font-weight: 400;
    > span {
      display: block;
      margin: 1em 0 4.27em;
      font-size: 0.7em;
    }
  `,
  Content: styled.div`
    margin-bottom: 5.6em;
    padding: 6em 0;
    border-top: 1px #2b2e35 solid;
    border-bottom: 1px #2b2e35 solid;
  `,
  CheckBoxContainer: styled.div`
    display: inline-block;
    text-align: left;
    color: #c9cbd1;
    font-size: 1.8em;
    font-weight: 400;
    .checkbox {
      position: relative;
      margin-bottom: 1.93em;

      &:last-of-type {
        margin-bottom: 0;
      }

      label {
        position: relative;
        padding-left: 0.625em;
        cursor: pointer;

        &[for]:before {
          content: '';
          position: absolute;
          width: 1.15em;
          height: 1.15em;
          left: -2.8rem;
          bottom: 0;
          border: 1px #c9cbd1 solid;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
    .etc {
      display: inline-block;
      width: 19.5em;
      margin-left: 1em;
      padding: 0.6em;
      border: none;
      border-radius: 0.25em;
      background-color: #2b2e35;
      color: #c7cad1;
      font-size: 0.9em;
      font-weight: 400;
    }
  `,
  InputCheck: styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    z-index: -1;
    margin-bottom: -1em;
    visibility: hidden;
    &:checked + label[for]:before {
      background-image: url(${iconCheck});
    }
    &:checked + label {
      color: #fff;
    }
  `,
  BtnText: styled.p`
    font-size: 2em;
    font-weight: 500;
  `,
  Button: styled.button`
    display: inline-block;
    width: 31%;
    margin: 2.5em 0 8em;
    padding: 1.25em 0;
    border-radius: 0.375em;
    background-color: #ffb500;
    color: #fff;
    font-size: 1.6em;
    font-weight: 700;
  `,
};

export default StyleCancelSurveyPage;

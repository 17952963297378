import React, { useEffect, useRef, useState } from 'react';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import Style from './styled/PlanSection.style';
import CourseListContainer from './ListContainer';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUrl, setVideoUrl } from './state/action';
//import Plyr from 'plyr-react';
//import 'plyr-react/dist/plyr.css';
import { useAxios } from 'common/hook/useAxiosHook';
import { fetchVideoData } from 'container/CourseDetailPage/api/courseDetailApi';
import Loading from 'component/atoms/Loading';
//import VideoPlayer from 'component/Player';
//import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';
import ReactPlayer from 'react-player';

const CourseDetailPlanSection = ({
  openLoginModal,
  overview,
  token,
  productId,
  courseDetailData,
}) => {
  const { t } = useTranslation('courseDetailPage');
  const dispatch = useDispatch();
  let overviewRef = useRef(0);
  const videoUrl = useSelector((state) => state.courseDetail.currentVideoUrl);
  const videoSelectNum = useSelector(
    (state) => state.courseDetail.selectNumber
  );
  const [getVideoData, refreshGetVideoData] = useAxios(
    fetchVideoData,
    [productId],
    true
  );
  const { loading, data } = getVideoData;
  // console.log('player overview', overview);
  // ReactPlayer 제어용
  const [playing, setPlaying] = useState(true);

  useEffect(() => {
    refreshGetVideoData(productId, token);
  }, []);

  useEffect(() => {
    if (overview) {
      dispatch(setCurrentUrl({ url: overview.ot, provider: 'youtube' }));
      dispatch(
        setVideoUrl({
          overview: { url: overview.ot, provider: 'youtube' },
          lecture: { url: overview.lecture, provider: 'mp4' },
        })
      );
    }
  }, [overview]);
  useEffect(() => {
    if (!overview || !videoSelectNum) return;
    if (videoSelectNum.num === 0) {
      dispatch(
        setCurrentUrl({
          url: overview.lecture ? overview.lecture : '',
          provider: 'mp4',
        })
      );
    } else if (videoSelectNum.num === 1) {
      dispatch(
        setCurrentUrl({
          url: overview.lecture2 ? overview.lecture2 : '',
          provider: 'mp4',
        })
      );
    } else if (videoSelectNum.num === 2) {
      dispatch(
        setCurrentUrl({
          url: overview.lecture3 ? overview.lecture3 : '',
          provider: 'mp4',
        })
      );
    }
  }, [videoSelectNum]);

  useEffect(() => {
    // console.log('videoUrl !!!!', videoUrl);
  }, [videoUrl]);

  if (loading) return <Loading />;

  if (data)
    return (
      <ContentsWrapper>
        <Style.Container>
          {!token && (
            <Style.CoursePlan>
              <h2>
                <Trans t={t}>coursePlan.title</Trans>
              </h2>

              {videoUrl?.url && (
                <ReactPlayer
                  ref={overviewRef}
                  url={videoUrl?.url}
                  playing={playing}
                  controls
                  config={{
                    file: {
                      attributes: {
                        onContextMenu: (e) => e.preventDefault(),
                        controlsList: 'nodownload',
                      },
                    },
                  }}
                />
              )}
            </Style.CoursePlan>
          )}
          <CourseListContainer
            data={data}
            openLoginModal={openLoginModal}
            overviewRef={overviewRef}
            loginStatus={token}
            productId={productId}
            totalClassNo={courseDetailData.classCount}
            totalHour={courseDetailData.hour}
          />
        </Style.Container>
      </ContentsWrapper>
    );
  return <></>;
};

export default CourseDetailPlanSection;

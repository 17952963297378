import styled from 'styled-components';
import FrontCardShadow from 'assets/images/course_setCard_shadow.png';
import BackCardShadow from 'assets/images/course_setCard_shadow2.png';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';

const StyledSetCard = {
  SetCardContainer: styled.div`
    position: relative;
    float: left;
    width: 30.76%;
    height: 47em;
    margin-right: 3.84%;
    margin-bottom: 5.5em;
    border-radius: 10px;
    background: ${({ bg }) => bg && `url(${bg}) no-repeat center center/cover`};

    @media (max-width: 1024px) {
      margin-right: 0;
      width: 49%;
    }
    @media (max-width: 767px) {
      margin-right: 0;
      width: 100%;
    }

    &.showBackCard {
      > div:first-child {
        visibility: hidden;
        opacity: 0;
      }
      > div:last-child {
        visibility: visible;

        opacity: 1;
      }
    }
  `,
  Heading: styled.div`
    padding: 4em 4em 0 4em;
    h3 {
      margin-bottom: 1.42em;
      font-size: 2.8em;
      font-weight: 700;
      word-break: break-word;

      span {
        display: block;
      }
    }
  `,
  SetCardFront: styled.div`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background: url(${FrontCardShadow}) repeat-x 0 0 / auto 100%;
    visibility: visible;
    opacity: 1;
    cursor: pointer;

    transition: 0.3s ease-in-out;
  `,
  Date: styled.span`
    position: absolute;
    left: 2.4em;
    bottom: 3.4em;
    font-size: 1.6em;
    letter-spacing: 0.5px;

    svg {
      display: inline-block;
      margin-right: 0.5em;
      vertical-align: top;
    }
  `,
  RegDt: styled(Moment)`
    display: inline-block;
    vertical-align: top;
  `,
  // Link: styled(Link)`
  Link: styled.div`
    display: inline-block;
    font-size: 1.8em;
    font-weight: 700;

    > * {
      vertical-align: middle;
    }

    svg {
      margin-left: 0.4em;
    }
  `,
  SetCardBack: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    visibility: hidden;
    overflow: auto;
    opacity: 0;
    transition: 0.3s ease-in-out;
    * {
      position: relative;
      z-index: 1;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      border: 2px #e2a000 solid;
      border-radius: 10px;
      background: url(${BackCardShadow}) repeat-x 0 0 / auto 100%;
      box-sizing: border-box;
    }
  `,
  SubjectList: styled.div`
    margin: 0 2.5em 2em 2.5em;
    font-size: 1.6em;
    font-weight: 400;
    > span {
      display: block;
    }
    @media (max-width: 767px) {
      font-size: 1.8em;
    }
  `,
  SubjectLink: styled.span`
    display: inline-block !important;
    border-bottom: 1px #ffb500 solid;
    color: #ffb500;
    font-size: 0.875em;
    font-weight: 400;
    cursor: pointer;

    &.result {
      color: #6b80fa;
      border-bottom: 1px #6b80fa solid;
    }
  `,
  CloseBtn: styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 1.37em;
    right: 1.37em;
    width: 2.875em;
    height: 2.875em;
    border-radius: 50%;
    background-color: rgba(24, 24, 24, 0.6);
    font-size: 1.8em;
    font-weight: 300;
    cursor: pointer;
  `,
};

export default StyledSetCard;

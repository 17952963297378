import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/members';

export const getMemberGoal = async (token, idx) => {
  const config = {
    url: `${url}/memberGoal?userIdx=${idx}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await asyncApi(config);
  return response.data;
};
export const addMemberGoal = async (token, idx, value) => {
  const config = {
    url: `${url}/addMemberGoal?userIdx=${idx}&goalNum=${value}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await asyncApi(config);
  return response.data;
};
export const registMemberGoalLevel = async (token, idx, value) => {
  const config = {
    url: `${url}/registMemberGoalLevel?userIdx=${idx}&level=${value}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await asyncApi(config);
  return response.data;
};
export const recommentContents = async (token, idx, value) => {
  const config = {
    url: `${url}/recommentContents?recommendSubject=${value.membership}&level=${value.selLevel}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await asyncApi(config);
  return response.data;
};
export const confirmMemberGoal = async (token, idx) => {
  const config = {
    url: `${url}/memberGoal?userIdx=${idx}`,
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await asyncApi(config);
  return response.data;
};
export const memberGoalFinish = async (token, idx) => {
  const config = {
    url: `${url}/memberGoalFinish?userIdx=${idx}&isFinish=true`,
    headers: { Authorization: `Bearer ${token}` },
  };
  const response = await asyncApi(config);
  return response.data;
};

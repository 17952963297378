import React from 'react';
import styled from 'styled-components';

const Style = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5em 2.8em;
  `,
  Label: styled.div`
    color: #c9cbd1;
    font-size: 1.6em;
    font-weight: 400;
  `,
  Grade: styled.div`
    color: #c9cbd1;
    font-size: 2em;
    font-weight: 400;
    span {
      color: #ffb500;
      font-weight: 500;
      font-size: 1.2em;
    }
  `,
};
const Grade = ({ totalScore, levelType }) => {
  return (
    <Style.Container>
      <Style.Label>총 점수</Style.Label>
      <Style.Grade>
        <span>{totalScore}점</span>{levelType === 11 ? '/ 200' : '/ 300'}
      </Style.Grade>
    </Style.Container>
  );
};

export default Grade;

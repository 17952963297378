import { MOBILE_APP_USER_AGENT, SiteMapData } from "common/constant";
import * as DeviceDetect from 'react-device-detect';

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

export const priceFormat = {
  korea: (number) =>
    new Intl.NumberFormat('ko-KR', {
      style: 'currency',
      currency: 'KRW',
    }).format(number),

  usa: (number) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(number),

  currency: (price) => {
    if (!price) return;
    const language = localStorage.getItem('i18nextLng');
    if (language === 'ko-KR') {
      return new Intl.NumberFormat('ko-KR', {
        style: 'currency',
        currency: 'KRW',
      }).format(price);
    } else {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(price);
    }
  },
};

export const getOrdinal = (n) => {
  n = parseInt(n, 10);
  const ordinal = ['th', 'st', 'nd', 'rd'];
  const calculate = n % 100;

  return (
    n + (ordinal[(calculate - 20) % 10] || ordinal[calculate] || ordinal[0])
  );
};

export const secondsToTime = (secs) => {
  let hours = Math.floor(secs / (60 * 60));

  let divisor_for_minutes = secs % (60 * 60);
  let minutes = Math.floor(divisor_for_minutes / 60);

  let divisor_for_seconds = divisor_for_minutes % 60;
  let seconds = Math.ceil(divisor_for_seconds);

  const format = (time) => {
    return time.toString().padStart(2, '0');
  };

  const time = `${format(hours)} : ${format(minutes)}: ${format(seconds)} `;

  return time;
};

export const toBeContinue = (e) => {
  e.preventDefault();
  alert('To be continue (준비중입니다)');
};

export const openLink = (url, target) => {
  const userAgent = window.navigator.userAgent;
  if (userAgent === MOBILE_APP_USER_AGENT) {
    window?.WebInterface?.movetoAndroidBrowser(true, url);
  }else{
    window.open(url, target? target : '_blank')
  }
  return false;
}

export const gotoAILink = (e, user, callback) => {
  e.preventDefault();

  //iOS 15.6 이상 제한 & iPad일 경우 제한
  // if(checkIPhoneVersion() && callback) {
  //   callback(e);
  //   return;
  // }
  // const user = useSelector((state) => state.user.data);
  // if(!user || !user.userIdx || !user.userID) return;

  let theForm;
  let mapInput;
  theForm = document.createElement("form");
  theForm.target = "Map";
  theForm.method = "POST"; // or "post" if appropriate
  theForm.action = SiteMapData.aiSpeak;

  mapInput = document.createElement("input");
  mapInput.type = "text";
  mapInput.name = "userIdx";
  mapInput.value = user.userIdx;
  theForm.appendChild(mapInput);

  mapInput = document.createElement("input");
  mapInput.type = "text";
  mapInput.name = "userID";
  mapInput.value = user.userID;
  theForm.appendChild(mapInput);

  mapInput = document.createElement("input");
  mapInput.type = "text";
  mapInput.name = "userPlatform";
  mapInput.value = "MT";
  theForm.appendChild(mapInput);

  document.body.appendChild(theForm);

  let map = window.open("", "Map");

  if (map) {
      theForm.submit();
      document.body.removeChild(theForm);
  } else {
      alert('Contact your manager');
  }
};

const checkIPhoneVersion = () => {
  // alert(DeviceDetect.getUA);
  // alert(JSON.stringify(DeviceDetect));
  let block = false;

  const mobileModel = DeviceDetect.mobileModel.toLowerCase(); // iPad || iPhnoe || ... || none
  const osVersion = DeviceDetect.osVersion.split('.').reduce((acc, x)=> acc.concat(+x), []);  // 15.6 || 10.15.6 || ...
  const osName = DeviceDetect.osName.toLowerCase().replace(/\s/g, ''); //MacOS || iOS
  const isIOS = DeviceDetect.isIOS;
  const isMobile = DeviceDetect.isMobile;
  const isSafari = DeviceDetect.isSafari;

  //iOS 15.6 이상 제한 & iPad일 경우 제한
  if(mobileModel === 'ipad'){
    if(!isNaN(osVersion[0]) && (osVersion[0] > 15 || (osVersion[0] === 15 && osVersion[1] >= 6))){
      //15.6 이상 ipad (chrome)
      block = true;
    }
  }else if(mobileModel === 'iphone' && osName === 'ios' && (osVersion[0] > 15 || (osVersion[0] === 15 && osVersion[1] >= 6))){
    //15.6 이상 iphone
    block = true;
  }else if(isIOS && isMobile && isSafari && osName === 'macos'){
    //ipad safari 인 경우 (임시)
    block = true;
  }

  return block;
}

export const chkFileType = (obj) => {
  let file_kind = obj.lastIndexOf('.');
  let file_name = obj.substring(file_kind+1,obj.length);
  let file_type = file_name.toLowerCase();
  let check_file_type=new Array();
  check_file_type=['jpg','gif','png','jpeg','bmp','tif'];

  if(check_file_type.indexOf(file_type)==-1) {
    return false;
  }
  else {
    return true;
  }
}
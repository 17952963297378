import styled, { css } from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';

const emptyBtn = css`
  position: relative;
  margin-left: 0.66em;
  padding: 0.85em 2em;
  border: 1px #fff solid;
  border-radius: 0.6rem;
  background: transparent;
  color: #fff;
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    border: 1px #fff solid;
    color: #fff;
  }
`;

const StyledSummaryBanner = {
  Container: styled.div`
    position: relative;
    padding: 7em 0 8em;
    font-size: 1.07em;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: ${({ bgImage }) => `url("${bgImage}") no-repeat center`};
      opacity: 0.35;
      background-size: cover;
      z-index: -10;
    }
  `,
  ContentWrapper: styled(ContentsWrapper)`
    max-width: 88.5em;
  `,

  H2: styled.h2`
    color: #fff;
    font-size: 5em;
    font-weight: 700;
  `,
  P: styled.p`
    max-width: 55.3em;
    margin-top: 2.4em;
    color: #bcbfc5;
    font-size: 1.6em;
    font-weight: 300;
  `,
};

export default StyledSummaryBanner;

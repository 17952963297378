import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useAxios } from "common/hook/useAxiosHook";
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';
import PlanContainer from './PlanContainer';
import Table from './Table';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';
import CurrencySelectBox from 'component/molecules/CurrencySelectBox';
import styled from 'styled-components';
// import PlanBannerPopup from "component/PlanBanner";
import RollBanner from "component/RollBanner";
import PlanBanner from './PlanBanner';
import useLoginStatus from "common/hook/useLoginStatus";
import plansApi from "api/plans";

const CurrencyBlock = styled.div`
  float: right;
  margin-top: -3em;
  @media (max-width: 767px) {
    float: none;
    margin: 3em 0 -2em;
  }
`;

const Plan = () => {
  const { t } = useTranslation('planPage');
  const token = useSelector((state) => state.user.token);
  const isLogin = useLoginStatus();
  const [canFreeTrial, setCanFreeTrial] = useState(false);
  const [{ loading: freeTrialLoading, data: freeTrialData }, getFreeTrial] = useAxios(
    plansApi.freeTrial,
    [],
    true
  );

  useEffect(() => {
    if (isLogin) getFreeTrial("PREMIUM", token); //무료체험 정보 임시 PREMIUM
    else setCanFreeTrial(true);
  }, [token]);

  useEffect(() => {
    if (freeTrialData) {
      /*
      isFreeTrial : Y = 무료체험 가능
      isFreeTrial : N = 무료체험 불가
      learning : Y = 무료체험 진행중
      learning : N = 무료체험 진행중 아님
      */
      if (freeTrialData.isFreeTrial === 'N') setCanFreeTrial(false);
      else setCanFreeTrial(true);
    }
  }, [freeTrialData])

  return (
    <>
      <SeoHelmet title={SeoData.plan.title} desc={SeoData.plan.desc} />

      <ContentsWrapper>
        {/* 20221209 PlanBannerPopup > RollBanner로 변경 */}
        {/* 20230224 PlanBanner 이목집중을 위해 RollBanner 숨김처리 */}
        {/* <RollBanner /> */}
        {/* <PlanBannerPopup /> */}

        <PageHeader title={t('title')} text={t('subtitle')} />
      </ContentsWrapper>

      {/* 무료체험 배너 */}
      {/* 로그아웃 || (로그인 && 무료체험 미사용) */}
      {canFreeTrial &&
        <PlanBanner t={t} />
      }

      <ContentsWrapper>
        <CurrencyBlock>
          <CurrencySelectBox />
        </CurrencyBlock>
      </ContentsWrapper>
      <ContentsWrapper>
        <PlanContainer
          t={t}
          canFreeTrial={canFreeTrial}
        />
        <Table t={t} />
      </ContentsWrapper>
    </>
  );
};

export default Plan;

import styled from "styled-components";
import { Swiper } from "swiper/react";
import Style from "component/ui/card/styled/CurriculumCard.style";
import arrowRightIcon from "assets/images/arrow_right.svg";
import selected from "assets/images/ico_leveltest_selected.png";
import checked from "assets/images/ico_leveltest_selected_check.png";
import { GoPlus } from "react-icons/go";

const Card = {
  SelectContainer: styled.div`
    position: relative;
    margin: 1em auto;
    width: 63em;
    z-index: 2;

    &::after {
      content: "";
      display: block;
      clear: both;
    }

    .order-by-select {
      float: right;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  CurriculumCardWrap: styled.div`
    position: relative;
    width: 63em;
    margin: 4.2em auto 4em;
    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  SwiperWrapper: styled.div`
    position: relative;
    width: 63em;
    margin: 4.2em auto 4em;
    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  Swiper: styled(Swiper)`
    position: relative;
    margin-top: 2em;
    text-align: left;
    overflow: visible;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 0;
      z-index: 9;
      width: 100%;
      height: 100%;
      background-color: #1e2024;
    }
    &:before {
      left: 100%;
    }
    &:after {
      right: 100%;
    }
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      bottom: 50%;
      z-index: 10;
      width: 4.2rem;
      height: 4.2rem;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      transform: rotate(45deg);
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 1;
      }
      &:after {
        background: url(${arrowRightIcon}) no-repeat center;
        background-size: 1.6rem;
      }

      &.swiper-button-disabled {
        display: none;
      }
    }
    .swiper-button-prev {
      left: -10rem;
      &:after {
        transform: rotate(135deg);
      }
    }
    .swiper-button-next {
      right: -10rem;
      &:after {
        transform: rotate(-45deg);
      }
    }
  `,
  Wrapper: styled.div`
    position: relative;
    overflow: hidden;
  `,
  ImgBox: styled(Style.ImgContainer)`
    &:hover::before,
    &.selected::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(10, 10, 11, 0.9);
      border-radius: 0.6em;
      z-index: 1;
    }

    &:hover::after,
    &.selected::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      width: 5.1em;
      height: 5.1em;
      background-repeat: no-repeat;
      background-position: 0 0;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    &:hover::after {
      background-image: url(${selected});
      background-size: cover;
    }

    &.selected::after {
      background-size: cover;
      background-image: url(${checked});
    }

    img {
      border-radius: 6px;
    }
  `,
  Badge: styled.span`
    display: inline-block;
    position: absolute;
    top: 0.8em;
    right: 1em;
    padding: 0.25em 0.5em;
    border-radius: 2px;
    background-color: ${({ bgColor }) => bgColor};
    font-size: 1.3em;
    font-weight: 700;
  `,
  Content: styled(Style.Content)`
    position: relative;
    cursor: pointer;

    &:hover .more::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ffb500;
    }
  `,
  PlusBtn: styled.span.attrs(() => ({
    children: (
      <>
        MORE <GoPlus />
      </>
    ),
    className: "more",
  }))`
    position: relative;
    display: inline-block;
    margin-top: 1em;
    font-size: 1.4em;
    color: #ffb500;
    font-weight: 400;
  `,
  Title: styled(Style.Title)`
    padding-right: 1.333em;
  `,
  Desc: styled(Style.Desc)``,
};

export default Card;

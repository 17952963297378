import React from 'react';
import styled from 'styled-components';
import PageSubTitle from 'component/molecules/PageSubTitle';
import { SwiperSlide } from 'swiper/react';
import VideoItem from 'component/molecules/VideoItem';
import SwiperList from 'component/molecules/SwiperList';
import { EmptyList } from 'component/atoms/EmptyList';
import { useSelector } from 'react-redux';

const Style = styled.div`
  margin: 10em 0 15em;
  @media (max-width: 767px) {
    margin-top: 5em;
  }
`;

const RecommendCourse = ({ data }) => {
  const token = useSelector((state) => state.user.token);

  return (
    <Style>
      <PageSubTitle title='추천 강좌' />
      <SwiperList
        id='testReport-courseRecommend'
        slideItemList={data}
        onNext={() => null}
      >
        {data.length > 0 ? (
          data.map((item) => (
            <SwiperSlide key={item.productId}>
              <VideoItem
                title={item.title}
                imgSrc={item.thumbnailPath}
                percent={item.progressPercent}
                to={`/courses/detail/${item.productId}`}
                videoLink={`/courses/detail/${item.productId}`}
                desc={
                  !token
                    ? `${item.classCount}classes, ${item.hour} hours`
                    : item.chapterLectureTitle
                }
              />
            </SwiperSlide>
          ))
        ) : (
          <EmptyList />
        )}
      </SwiperList>
    </Style>
  );
};

export default RecommendCourse;

import styled from 'styled-components';
import StyledModalForm, { inputStyles } from 'component/form/ModalForm.style';

import { AiOutlineGoogle, AiOutlineWechat } from 'react-icons/ai';
import { GrFacebookOption } from 'react-icons/gr';

import iconEmail from 'assets/images/ico_email.png';
import iconEmailLarge from 'assets/images/ico_email_large.png';
import iconLock from 'assets/images/ico_lock.png';
import iconLockLarge from 'assets/images/ico_lock_large.png';
import { Link } from 'react-router-dom';

const _IconDiv = styled.div`
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6em;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

const _SocialLoginLink = styled.a`
  position: relative;
  display: block;
  font-size: 1.6em;
  width: 100%;
  line-height: 3.375em;
  padding: 0 1.25em;
  border: 0;
  border-radius: 0.6rem;
  background-color: #ffb500;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.5px;
  @media (max-width: 375px) {
    font-size: 1.4em;
  }
  padding-left: 15%;
  svg {
    position: absolute;
    top: 50%;
    left: 15%;
    font-size: 1.5em;
    margin-top: -0.5em;
  }
  @media (max-width: 375px) {
    padding-left: 10%;
    svg {
      left: 10%;
    }
  }
`;

const StyledLogin = {
  IdIcon: styled(_IconDiv)`
    margin-bottom: 10px;
    &:before {
      background-image: url(${iconEmail});
      @media (max-width: 1024px) {
        background-image: url(${iconEmailLarge});
        background-size: 18px auto;
      }
    }
  `,
  PwIcon: styled(_IconDiv)`
    &:before {
      background-image: url(${iconLock});
      @media (max-width: 1024px) {
        background-image: url(${iconLockLarge});
        background-size: 14px auto;
      }
    }
  `,
  Input: styled.input.attrs((props) => ({
    placeholder: props.title,
  }))`
    ${inputStyles}
    padding-left: 3.75em;
    background-color: #ebedf2;
    color: #535866;
    ::placeholder {
      font-weight: 300;
      color: #535866;
    }
  `,
  FindPwDiv: styled.div`
    margin-bottom: 1em;
    text-align: right;
    font-size: 1.4em;
    line-height: 2em;
    color: #666;
  `,
  CreateAccountDiv: styled.div`
    font-size: 1.4em;
    margin-top: 1.5em;
    font-weight: bold;
    text-align: center;
  `,
  SocialLogin: styled.div`
    position: relative;
    padding-top: 5em;
    margin-top: 2em;
    &:before {
      content: '';
      position: absolute;
      top: 1.1em;
      left: 0;
      width: ${({ landingPage }) => (landingPage ? 'calc(50% - 2.45em)' : '100%')};
      height: 1px;
      background-color: #ddd;
    }
    &:after {
      content: '';
      position: absolute;
      top: 1.1em;
      right: 0;
      width: ${({ landingPage }) => (landingPage ? 'calc(50% - 2.45em)' : '100%')};
      height: 1px;
      background-color: #ddd;
    }
    .label {
      background: ${({ landingPage }) => (landingPage ? 'transparent' : '#fff')};
    }
  `,
  DataOr: styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    width: 3.5em;
    margin-left: -1.75em;
    font-size: 1.4em;
    font-weight: bold;
    text-align: center;
    z-index: 2;
  `,
  BlueButton: styled(StyledModalForm.Button)`
    background-color: #485cc7;
  `,
  GoogleButton: styled(_SocialLoginLink).attrs((props) => ({
    children: (
      <>
        <AiOutlineGoogle /> {props.children}
      </>
    ),
  }))`
    background-color: #1e85fd;
  `,
  FacebookButton: styled(_SocialLoginLink).attrs((props) => ({
    children: (
      <>
        <GrFacebookOption /> {props.children}
      </>
    ),
  }))`
    margin: 0.5em 0;
    background-color: #31599d;
  `,
  WechatButton: styled(_SocialLoginLink).attrs((props) => ({
    children: (
      <>
        <AiOutlineWechat /> {props.children}
      </>
    ),
  }))`
    background-color: #2dc100;
  `,
  LoginFailed: styled(StyledModalForm.WarnText)`
    text-align: center;
  `,
};

export default StyledLogin;

import React, { useState, useContext, useEffect } from 'react';
import useInterval from 'common/hook/useInterval';
import { secondsToTime } from 'common/util/util';
import { MockTestContext } from '../index';

const Countdown = ({ second = 0 }) => {
  const { mockTestStore, setMockTestStore } = useContext(MockTestContext);
  const [countdown, setCountdown] = useState(second);

  useEffect(() => {
    if (countdown < 0) return;

    if (countdown === 0) {
      mockTestStore.submitBtnRef.current.click();
      return;
    } else {
      setMockTestStore((prev) => ({ ...prev, testTime: countdown }));
    }
  }, [countdown]);

  useInterval(() => {
    setCountdown(countdown - 1);
  }, 1000);

  if (countdown) return <span>{secondsToTime(countdown)}</span>;
  return <></>;
};

export default Countdown;

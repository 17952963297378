import styled from 'styled-components';
const StyledTestPage = {
  Header: styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 2.8em 2.8em 2em;
    background-color: #eceef9;
    color: #1e2024;
  `,
  InfoContent: styled.div`
    position: relative;
    flex-grow: 1;
    width: calc(100% - 29.2em);
    > p {
      font-size: 2.6em;
      font-weight: 700;
      line-height: 1.231em;
    }
  `,
  InfoQuestion: styled.div`
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.4em;
    font-weight: 500;
    color: #272c35;
    span {
      font-weight: 300;
      color: #5f6268;
    }
    @media (max-width: 1024px) {
      top: 3em;
    }
  `,
  InfoProgress: styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    margin-top: 1.4em;
    @media (max-width: 1024px) {
      display: block;
    }
    > * {
      flex: 1 1 auto;
    }
    .text {
      flex-grow: 0;
      font-size: 1.6em;
      font-weight: 500;
    }
    .progress {
      flex-grow: 1;
      margin-left: 1.5em;
    }
  `,
  ProgressContent: styled.div`
    flex-grow: 0;
    font-size: 1.6em;
    font-weight: 500;
    span {
      font-weight: 700;
    }
  `,
  Progress: styled.div`
    flex-grow: 1;
    margin-left: 1.5em;
    @media (max-width: 1024px) {
      margin-left: 0;
    }
    .progress-bar {
      height: 0.4em;
      background-color: #fff;
      border-radius: 5rem;
      transform: translateY(1.2em);
      span {
        display: block;
        width: ${({ percentage }) => (percentage ? `${percentage}%` : 0)};
        height: 100%;
        background-color: #ffb500;
        border-radius: 5rem;
      }
    }
  `,
  BtnGroupContainer: styled.div`
    flex-grow: 0;
    margin-left: 3em;
    text-align: right;
    button {
      padding: 1.071em 2.125em;
      min-width: 8.571em;
      background-color: #485cc7;
      border-radius: 0.25em;
      line-height: 1.429em;
      color: #fff;
      font-size: 1.4em;
      font-weight: 600;
      &:first-of-type {
        margin-right: 0.429em;
        background-color: #999ba0;
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }
  `,

  MockTestContainer: styled.div`
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    padding: 2em 0 0;
    color: #1e2024;
    height: calc(100vh - 9.5rem);
    @media (max-width: 1024px) {
      display: block;
    }
  `,
  QuestionContainer: styled.div`
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    padding: 4.5em 3em 0;
    height: 100%;
    background-color: #fff;
    .question-title {
      position: relative;
      margin: 2em 0 1em;
    }
  `,
};

export default StyledTestPage;

import React, { useEffect, useState } from 'react';
import { Curriculum } from '../styled';
import CurriculumProgress from './CurriculumProgress';
import { useHistory } from 'react-router-dom';
import CourseList from './CourseList';
import { useTranslation } from 'react-i18next';

const MyCurriculum = ({ myCurriculumData, haveCurriculum, isMyCurriculum, setIsMyCurriculum, curriculumIds, setCurriculumIds }) => {
  const history = useHistory();
  const { t } = useTranslation('classroomPage');

  const changeLanguage = (index, newId) => {
    const newArray = curriculumIds.map((item, idx)=> {
      if(index === idx) return newId;
      else return item;
    });
    setCurriculumIds(newArray);
  };

  useEffect(()=>{
    if(myCurriculumData && myCurriculumData.curriculumCourseResponses) {
      const newArray = myCurriculumData.curriculumCourseResponses.map((item) => item.id);
      setCurriculumIds(newArray);
    }
  },[myCurriculumData]);

  return (
    <>
      {
        haveCurriculum && (
          <>
            <Curriculum.ProgressContainer>
              <CurriculumProgress data={myCurriculumData} isMyCurriculum={isMyCurriculum} setIsMyCurriculum={setIsMyCurriculum}/>
            </Curriculum.ProgressContainer>
            {myCurriculumData &&
              myCurriculumData?.curriculumCourseResponses.length > 0 && curriculumIds && (
                <>
                  <Curriculum.Title>Required Courses</Curriculum.Title>
                  <Curriculum.CourseListWrap>
                    {myCurriculumData.curriculumCourseResponses.map((data, index) => {
                      return <CourseList key={data.id} data={data} index={index} languageId={curriculumIds[index]} changeLanguage={changeLanguage}/>;
                    })}
                  </Curriculum.CourseListWrap>
                </>
              )}
          </>
        )
      }
    </>
  );
};

export default MyCurriculum;

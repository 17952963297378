import { all, put, call, takeLeading, takeEvery } from 'redux-saga/effects';
import { actions, Types } from './index';
import { actions as commonActions, Types as commonTypes } from 'common/state';
import { callApi } from 'common/util/api';
import { makeFetchSaga } from 'common/util/fetch';
import { UserStatus } from 'common/constant';

function* fetchAuth({ username, password }) {
  yield put(actions.setValue('status', UserStatus.NotLogin));
  const { response, error } = yield call(callApi, {
    url: '/api/v1/login',
    method: 'post',
    data: {
      username,
      password,
    },
  });
  if (response) {
    yield put(actions.setAuth(response.token));
    yield put(actions.fetchUser(response.token));
  } else {
    console.error(error.message);
    yield put(actions.setValue('status', UserStatus.LoginFailed));
  }
}
function* fetchAuthFacebook({ token, email, id }) {
  yield put(actions.setValue('status', UserStatus.NotLogin));
  const { response, error } = yield call(callApi, {
    url: '/api/v1/login/facebook',
    method: 'post',
    data: {
      token,
      username: email,
      id
    },
  });
  if(response && response.result === 'success'){
    if(response.isGathering){
      yield put(actions.setAuth(response.token));
      yield put(actions.fetchUser(response.token));
    }else{
      yield put(actions.setValue('status', UserStatus.NotRegistered));
    }
  }else{
    console.error(error);
    yield put(actions.setValue('status', UserStatus.LoginFailed));
  }
}

function* fetchUser({ token }) {
  const { response } = yield call(callApi, {
    url: '/api/v1/members',
    headers: { Authorization: `Bearer ${token}` },
  });
  if (response) {
    yield put(actions.setUser(response));
  }
}

function* fetchLogout() {
  yield put(actions.fetchLogout());
}

function* initUser() {
  yield put(
    commonActions.setFetchStatus({
      actionType: Types.FetchLogin,
      fetchKey: Types.FetchLogin,
    })
  );
  yield put(
    commonActions.setFetchStatus({
      actionType: Types.FetchUser,
      fetchKey: Types.FetchUser,
    })
  );
}

export default function* UserSaga() {
  yield all([
    takeEvery(
      Types.FetchLogin,
      makeFetchSaga({ fetchSaga: fetchAuth, canCache: false })
    ),
    takeEvery(
      Types.FetchLoginFacebook,
      makeFetchSaga({ fetchSaga: fetchAuthFacebook, canCache: false })
    ),
    takeEvery(
      Types.FetchUser,
      makeFetchSaga({ fetchSaga: fetchUser, canCache: false })
    ),
    takeLeading(
      Types.FetchLogout,
      makeFetchSaga({ fetchSaga: fetchLogout, canCache: false })
    ),
    takeEvery(
      Types.InitUser,
      makeFetchSaga({ fetchSaga: initUser, canCache: false })
    ),
  ]);
}

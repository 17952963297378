import React from 'react';
import Styled from './index.style';
import { ReviewData } from './ReviewData';
import Img from 'component/atoms/Img';
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper';
import { SwiperSlide } from 'swiper/react';

SwiperCore.use([Navigation, Autoplay, Pagination]);

export default function Reviews({ t }) {
  return (
    <Styled.Wrapper>
      <Styled.Inner>
        <Styled.H2>{t('reviews.title')}</Styled.H2>
        <Styled.Swiper
          speed={600}
          spaceBetween={20}
          loop
          centeredSlides
          navigation
          autoHeight
          pagination={{ clickable: true }}
        >
          {ReviewData(t).map((item, index) => (
            <SwiperSlide key={index}>
              <Styled.ItemContainer>
                <Styled.Texts>
                  <Styled.H3>{item.title}</Styled.H3>
                  <Styled.Desc>{item.desc}</Styled.Desc>
                  <Styled.Author>
                    {item.name} <span>{item.info}</span>
                  </Styled.Author>
                </Styled.Texts>
                <Styled.ImgWrapper>
                  <Img src={item.thumb} alt='Review Image' />
                </Styled.ImgWrapper>
              </Styled.ItemContainer>
            </SwiperSlide>
          ))}
        </Styled.Swiper>
      </Styled.Inner>
    </Styled.Wrapper>
  );
}

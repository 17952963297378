import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Profile from './Profile';
import ActivityInsight from './ActivityInsight';
import TodayLesson from './TodayLesson';
import MyClassList from './MyClassList';
import Recommend from './Recommend';
import ServiceContents from './ServiceContents';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';

import { useHistory } from 'react-router';
import {
  MainMultiEventBannerWithAPI,
  MainMultiEventBanner,
} from 'component/EventBanner';
import EventNotice from './EventNotice';
import plansApi from 'api/plans';
import useLoginStatus from 'common/hook/useLoginStatus';
import { useAxios } from 'common/hook/useAxiosHook';
import LearningHistory from 'container/MyCurriculumPage/components/LearningHistory';

export default function UserHomePage() {
  const token = useSelector((state) => state.user.token);
  const isLogin = useLoginStatus();
  const user = useSelector((state) => state.user.data);
  const [canFreeTrial, setCanFreeTrial] = useState(false);
  const [
    { loading: freeTrialLoading, data: freeTrialData },
    getFreeTrial,
  ] = useAxios(plansApi.freeTrial, [], true);

  const history = useHistory();

  useEffect(() => {
    if (user.isGathering === false) {
      setTimeout(() => {
        history.push(`/auth/redirect?token=${token}&isTerms=false`);
      }, 300);
    }
  }, [user]);

  useEffect(() => {
    if (isLogin) getFreeTrial('PREMIUM', token);
    //무료체험 정보 임시 PREMIUM
    else setCanFreeTrial(true);
  }, [token]);

  useEffect(() => {
    if (freeTrialData) {
      if (freeTrialData.isFreeTrial === 'N') setCanFreeTrial(false);
      else setCanFreeTrial(true);
    }
  }, [freeTrialData]);

  return (
    <>
      <SeoHelmet
        title={SeoData.userHomePage.title}
        desc={SeoData.userHomePage.desc}
      />
      {canFreeTrial && <MainMultiEventBanner />}
      {/* <MainMultiEventBannerWithAPI /> */}

      <h1 className='hidden'>User Home</h1>

      <Profile user={user} />

      {/*<EventNotice />*/}
      <TodayLesson></TodayLesson>

      <ActivityInsight user={user} />

      <LearningHistory type={'Home'}></LearningHistory>


      <MyClassList />

      <Recommend />

      <ServiceContents />
    </>
  );
}

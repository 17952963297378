import React, {useRef} from 'react';
import styled from 'styled-components';
import { ModalContext } from 'component/Modal/modalContext';
import Plyr from 'plyr-react';

const Style = {
  VideoWrap: styled.div`
    margin-top: 2em;
    border-radius: 6px;
    overflow: hidden;
  `,
};

/**
 *
 * @param {string} tMessage       전달 메세지
 * @param {string} tButton        버튼 이름
 * @param {()=>void} afterClose   button 클릭 후 callback
 * @returns
 */

const VideoModal = ({ src, afterClose}) => {
  const videoRef = useRef(null);
  return (
    <>
    <Style.VideoWrap>
        <Plyr
          ref={videoRef}
          source={{
            type: 'video',
            sources: [
              {
                src: src,
                provider: 'youtube',
              },
            ],
          }}
        />
      </Style.VideoWrap>
    </>
  );
};

export default VideoModal;

import styled from "styled-components";
import { Link } from "react-router-dom";
import { VscClose } from 'react-icons/vsc';

const StyledEventBanner = {
  EventBannerWrap: styled.div`
    // position: absolute;
    position: fixed;
	  top: 100px;
	  right: 6%;
    z-index:999;
    @media (max-width: 767px) {
      top: 150px;
	    right: auto;
	    padding: 0 5%;
    }

    &.mobile {
      position: fixed;
      top: auto;
      right: auto;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 1000000;

      &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.8);
        opacity: 0;
        overflow: auto;
        animation-name: fade-in;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
      @media (max-width: 767px) {
        padding: 0;
      }
    }
  `,
  Link: styled(Link)`
    img {
      display: inline-block;
	}
  `,
  Close: styled.button.attrs({
    type: 'button',
    children: <VscClose />,
  })`
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 3em;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    color: ${props => props.$btnColor ? props.$btnColor : '#fff'};
	  z-index:1000001;

  `,
  boxImg: styled.div`
    position: relative;
    z-index:1000000;
    img {
      width: 100%;
    }
  `,
};

export default StyledEventBanner;

import React, { useContext } from 'react';
import Styled from './index.style';
import { RiPauseMiniFill, RiPlayMiniFill } from 'react-icons/ri';
import { PlayerContext } from 'component/Player';

/**
 *
 * @param {*} props
 * @param {string=} props.handlePlayToggle
 * @param {func=} props.isPlaying
 */
const PlayToggleOverlay = ({ onClick }) => {
  const { playerState, handleState } = useContext(PlayerContext);

  const handlePlayToggle = () => {
    handleState({ playing: !playerState.playing });
  };

  return (
    <Styled.PlayToggleOverlay
      onClick={onClick}
      className={
        !playerState.playing
          ? 'play-toggle-overlay pause'
          : 'play-toggle-overlay'
      }
    >
      <div onClick={handlePlayToggle}>
        {!playerState.playing ? <RiPlayMiniFill /> : <RiPauseMiniFill />}
      </div>
    </Styled.PlayToggleOverlay>
  );
};
export default PlayToggleOverlay;

import styled from 'styled-components';
import omrArrowIcon from 'assets/images/ico-omr-arrow.png';
import playIcon from 'assets/images/ico_mockTestPlay.png';
import pauseIcon from 'assets/images/ico_mockTestStop.png';
import correctIcon from 'assets/images/ico_answer_correct.png';
import wrongIcon from 'assets/images/ico_answer_wrong.png';

const Style = {
  Question: styled.div`
    margin-top: 6em;
  `,
  QuestionTitle: styled.div`
    font-size: 1.6em;
    font-weight: 500;
  `,
  QuestionDesc: styled.div`
    margin: 2em auto 3em;
    padding: 3.5em;
    border: 1px solid #d4d6dc;
    text-align: center;
  `,
  QuestionChoiceContainer: styled.ol`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 1em;
    margin-top: 3em;
  `,

  QuestionChoice: styled.li`
    margin-right: 1em;

    span {
      display: inline-block;
      width: 2.143em;
      height: 2.143em;
      border: 1px solid #c9cbd1;
      border-radius: 50rem;
      font-size: 1.4em;
      line-height: 2em;
      color: #c9cbd1;
      text-align: center;
      cursor: pointer;
      &.active {
        background-color: #485cc7;
        border-color: #485cc7;
      }

      &.wrong {
        color: #fff;
        background-color: #c9cbd1;
      }
      &.correct {
        color: transparent;
        background: url(${correctIcon}) center/cover;
        background-color: #d11f1f;
        border: none;
      }
      &.same {
        color: transparent;
        border: none;
        background: url(${correctIcon}) center/cover;
        background-color: #485cc7;
      }
    }
  `,

  OmrCardContainer: styled.div`
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 0;
    padding: 1.4em;
    min-width: 280px;
    background-color: #d4d6dc;
    > button {
      position: absolute;
      top: 1em;
      left: 0;
      display: none;
      overflow: hidden;
      margin-left: -2.5em;
      width: 2.5em;
      height: 5em;
      background-color: #999ba0;
      border-radius: 6px 0 0 6px;
      font-size: 1em;
      line-height: 50em;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        width: 1em;
        height: 1.75em;
        background: url(${omrArrowIcon}) 0 0/100% 100%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
      }
      @media (max-width: 1024px) {
        display: block;
        z-index: 1;
      }
    }

    @media (max-width: 1024px) {
      position: fixed;
      z-index: 1;
      right: 0;
      top: 10em;
      bottom: 0;
      height: 55vh;
      overflow-x: initial;
      overflow-y: initial;
      transition-duration: 0.5s;
      transform: translateX(100%);
      &.open {
        transform: translateX(0);
        height: 86vh;
      }
    }
  `,
  OmrCard: styled.div`
    padding: 0.5em 2em;
    background-color: #fff;
    border-radius: 0.375em;
    counter-reset: question;
    @media (max-width: 1024px) {
      height: 75vh;
      overflow-y: auto;
    }
    ol {
      border-bottom: 1px solid #f2f3f4;
      padding-bottom: 2em;
      &:before {
        counter-increment: question;
        content: counters(question, '.') ' ';
        width: 2.5em;
        font-size: 1.6em;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  `,
  OmrBtnContainer: styled.div`
    display: none;
    margin-top: 2em;
    text-align: center;
    @media (max-width: 1024px) {
      display: block;
      margin-top: 1em;
    }
    button {
      width: 45%;
      padding: 0.533em 2.125em;
      background-color: #999ba0;
      line-height: 1.6em;
      font-size: 1.5em;
      color: #fff;
      border-radius: 0.375em;
      &:last-of-type {
        margin-left: 1em;
        background-color: #485cc7;
      }
    }
  `,
  AudioContainer: styled.div`
    font-size: 1.2em;
    > * {
      vertical-align: middle;
    }
    position: static;
    margin: 1.2em 0;
    button {
      position: relative;
      margin-right: 0.267em;
      width: 2em;
      height: 2em;
      border: 1px solid #d4d6dc;
      border-radius: 50rem;
      font-size: 1em;
      font-weight: 300;
      line-height: 100%;
      color: rgba(255, 255, 255, 0);
      white-space: nowrap;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background: url(${pauseIcon}) no-repeat center center;
      }

      &.active:before {
        background: url(${playIcon}) no-repeat center center;
      }
    }
  `,
};

export default Style;

import React, { useState, useEffect } from 'react';
import Style from './styled/PlanSection.style';
import CourseDetailList from './List';

import OverViewList from './OverViewList';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

const CourseListContainer = ({
  data,
  videoRef,
  playing,
  productId,
  lectureId
}) => {
  const { t } = useTranslation('jresearchPage');
  const [listShowIndex, setListShowIndex] = useState(-1);
  const [overViewPercentage, setOverViewPercentage] = useState(0);

  const currentVideoUrl = useSelector(
    (state) => state.jresearch.currentVideoUrl?.provider
  );
  const openAccordion = (index) => {
    setListShowIndex((prev) => (prev === index ? -1 : index));
  };
  useEffect(() => {
    const getPercentage = setInterval(() => {
      if (videoRef.current) {
        let percentage =
          (videoRef.current.getCurrentTime() /
          videoRef.current.getDuration()) *
          100;
        setOverViewPercentage(percentage);
      } else {
        setOverViewPercentage(0);
      }
    }, 1000);
    return () => clearInterval(getPercentage);
  }, [currentVideoUrl]);

  useEffect(()=>{
    if(data){
      data.forEach((value, index)=>{
        value.lessonResponseList.forEach((child, childIdx) => {
          if(child.chapterLectureId === Number(lectureId)){
            setListShowIndex(index);
          }
        });
      });
    }
  },[]);

  return (
    <Style.ListContainer>
      <h2>{t('stitle2')}</h2>
      <div className='title'>
        <span>
          {/* {totalClassNo} classes, {totalHour}hr */}
        </span>
      </div>

      <OverViewList
        percentage={overViewPercentage}
        title='Lecture overview'
        playing={playing}
        lectureId={lectureId}
      />
      {data &&
        data.map((data, index) => {
          const {
            id,
            title,
            hour,
            minute,
            lessonCount,
            lessonResponseList,
          } = data;
          return (
            <CourseDetailList
              key={id}
              title={title}
              chapterHour={hour}
              chapterMinute={minute}
              lessonCount={lessonCount}
              lessonList={lessonResponseList}
              onClick={() => openAccordion(index)}
              show={listShowIndex === index && 'active'}
              videoRef={videoRef}
              playing={playing}
            />
          );
        })}

      {/* {!loginStatus && data && data.length > 5 && (
        <Style.MoreBtn onClick={() => openLoginModal(currentPath + '/0')}>
          {t('coursePlan.link')} <BsArrowRightShort size='1.6em' />
        </Style.MoreBtn>
      )} */}
    </Style.ListContainer>
  );
};
export default CourseListContainer;
// export default React.memo(CourseListContainer);

import styled from 'styled-components';
import { FiArrowRight } from 'react-icons/fi';
import IcoCourses from 'assets/images/audio/ico_courses.png';
import IcoAispeak from 'assets/images/audio/ico_aispeak.png';
import IcoVoca from 'assets/images/audio/ico_voca.png';
import BgBanner from 'assets/images/audio/bg_banner.png';

const BannerStyle = {
  BannerList: styled.ul`
    display: flex;
    justify-content: space-between;
    gap: 4em;
    li {
      flex: 1;
      a {
        display: block;
        height: 100%;
      }
    }
    @media (max-width: 1024px) {
      gap: 2em;
    }
    @media (max-width: 750px) {
      display: block;
      li {
        margin-top: 1em;
      }
    }
  `,

  BannerListItem: styled.div.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <FiArrowRight />
      </>
    ),
  }))`
    position: relative;
    display: block;
    height: 100%;
    padding: 2.5em 7em 2.5em 8.5em;
    border-radius: 0.6em;
    overflow: hidden;
    color: #152848;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    &.courses {
      background-color: #73c2ff;
      &::before {
        left: 2.5em;
        width: 4.1em;
        height: 3.6em;
        background: url(${IcoCourses}) no-repeat 50% 50%/100% 100%;
      }
    }
    &.aispeak {
      background-color: #65f3d2;
      &::before {
        left: 3em;
        width: 3.5em;
        height: 3.9em;
        background: url(${IcoAispeak}) no-repeat 50% 50%/100% 100%;
      }
    }
    &.voca {
      background-color: #ffa190;
      &::before {
        left: 3em;
        width: 3.8em;
        height: 3.8em;
        background: url(${IcoVoca}) no-repeat 50% 50%/100% 100%;
      }
    }
    h4 {
      letter-spacing: -0.05em;
      font-weight: 700;
      font-size: 2em;
    }
    p {
      font-weight: 300;
      font-size: 1.6em;
    }
    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.5em;
      color: #2008c0;
      font-size: 2em;
    }
  `,

  BannerWrap: styled.div`
    margin: 8em 0 20em;
    background: url(${BgBanner}) no-repeat 50% 50%/cover;
    a {
      display: block;
      padding: 4em 2.5em 2.5em;
      text-align: center;
    }
    p {
      margin-bottom: 0.8em;
      font-weight: 900;
      font-size: 2.1em;
      color: #212121;
    }
    span {
      display: inline-block;
      padding: 0.5em 2em 0.5em 2.5em;
      border-radius: 2em;
      background-color: #212121;
      font-size: 1.8em;
      color: #fff;
      svg {
        position: relative;
        top: 0.1em;
        margin-left: 0.5em;
      }
    }
  `,
};

export default BannerStyle;
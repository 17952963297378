import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ico from 'assets/images/ico_planBanner.png';
import { BsChevronDown } from 'react-icons/bs';

const StyledBanner = {
  Container: styled.div`
    margin: 8em 0;
    background: #face00;
    text-align: center;
  `,
  Banner: styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 2em 20px;
  `,
  BannerLink: styled(Link)`
    position: relative;
    display: inline-block;
    min-height: 5em;
    padding-left: 10em;
    text-align: left;
    vertical-align: middle;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 9em;
      height: 5em;
      background: url(${ico}) no-repeat 0 0;
      background-size: contain;
      vertical-align: middle;
    }
  `,
  TextWrap: styled.span`
    display: inline-block;
    margin: 0.2em 0;
    font-weight: 600;
    font-size: 2.4em;
    color: #000000;
    span {
      display: inline-block;
      font-weight: 700;
    }
  `,
  ButtonToggle: styled.button.attrs(({ children }) => ({
    children: (
      <>
        <BsChevronDown />
        {children}
      </>
    ),
  }))`
    width: 1em;
    height: 1em;
    font-size: 3em;
    vertical-align: middle;
    margin-left: 1.5em;
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    &.active {
      transform: rotate(180deg);
    }
  `,
  Note: styled.div`
    border-top: 1px solid #f9de63;
    max-height: 0;
    overflow: hidden;
    transition-property: all;
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    &.active {
      max-height: 100vh;
    }
  `,
  NoteList: styled.ul`
    display: inline-block;
    padding: 2em;
    text-align: left;
    li {
      position: relative;
      padding-left: 1em;
      font-weight: 400;
      font-size: 1.7em;
      color: #000;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0.75em;
        left: 0;
        width: 3px;
        height: 3px;
        margin-top: -2px;
        border-radius: 50%;
        background: #000;
      }
    }
  `,

};

export default StyledBanner;

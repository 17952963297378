import styled from 'styled-components';

const StyledSelectBox = {
  Container: styled.div`
    display: inline-block;
    margin-right: 1em;
    .select {
      &__indicator {
        padding: 0.6em;
        svg {
          width: 1.5em;
          height: 1.5em;
        }
      }
      &__control {
        width: 12.4em;
        padding: 0.6em 0;
        border: none;
        border: 1px solid #909193;
        border-radius: 0.4em;
        background-color: transparent;
        box-shadow: 0 0 0 0 transparent;

        &:focus {
          outline: 0;
        }
        &:hover {
          border-color: inherit !important;
        }
      }
      &__single-value {
        font-size: 1.4em;
        font-weight: 500;
        color: #d1d1d1;
      }
      &__indicator-separator {
        display: none;
      }
      &__menu {
        width: 100%;
        background-color: #2b2e35;
        overflow: hidden;
        &-list {
          padding: 0;
        }
      }
      &__option {
        width: 100%;
        /* height: 100%; */
        font-size: 1.4em;
        font-weight: 400;
        color: #c7cad1;
        letter-spacing: 0.05em;
        &--is-focused {
          background-color: #1e2024;
        }

        &:active,
        &:focus,
        &--is-selected {
          background-color: #1f2024;
          color: #55575e;
        }
      }
    }
  `,
};

export default StyledSelectBox;

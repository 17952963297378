import styled, { css } from 'styled-components';
import visualBg from 'assets/images/event/230515/visual.jpg';
import infoObj from 'assets/images/event/230515/bg_obj.png';
import infoObj2 from 'assets/images/event/230515/bg_obj2.png';
import bgStep from 'assets/images/event/230515/bg_step.png';
import lbBest from 'assets/images/event/230515/lb_best.png';
import lbBestKo from 'assets/images/event/230515/lb_best_ko.png';
import icoReviewLeft from 'assets/images/event/230515/ico_review_left.png';
import icoReviewRight from 'assets/images/event/230515/ico_review_right.png';
import freeBg from 'assets/images/event/230515/free.jpg';
import icoFaq from 'assets/images/event/230515/ico_faq.png';

const StyledEventPage = {
  HeaderWrap: styled.div`
    background: red;
  `,
  Logo: styled.h1``,
  HeaderButtonWrap: styled.span`
    height: 4em;
  `,
  JoinButton: styled.button.attrs({
    type: 'button',
  })`
    height: 100%;
    padding: 0 1em;
    font-size: 1.6em;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #b9b9b9;
    border-radius: 2em;
    color: #fff;
    cursor: pointer;
  `,

  LoginButton: styled.button.attrs({
    type: 'button',
  })`
    height: 100%;
    padding: 0 1em;
    font-size: 1.6em;
    font-weight: 400;
    text-transform: uppercase;
    border: 1px solid #b9b9b9;
    border-radius: 2em;
    color: #fff;
    cursor: pointer;
  `,

  SwiperWrap: styled.div`
    position: relative;
    padding: 2.7em 85px 3em;
    background-color: #a1dfcd;
    border-radius: 1em;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 2.3em;
      left: 2em;
      width: 2.25em;
      height: 1.7em;
      background: url(${icoReviewLeft}) no-repeat 0 0;
      background-size: contain;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 2.3em;
      right: 2em;
      width: 2.25em;
      height: 1.7em;
      background: url(${icoReviewRight}) no-repeat 0 0;
      background-size: contain;
    }
    .swiper-container {
      width: 100%;
      height: 105px;
      border-bottom: 0.4em solid #70b1bf;
    }
    @media (max-width: 750px) {
      padding: 6.7vw 11.3vw 8vw;
      .swiper-container {
        height: 14vw;
      }
    }
  `,

  ButtonWrap: styled.div`
    button, a {
      display: inline-block;
      background-color: #ffb500;
      border-radius: 0.4em;
      padding: 1em 4em;
      line-height: 1;
      font-weight: 700;
      font-size: 1em;
      color: #000;
    }
  `,

  EventWrap: styled.div`
    /* font-family: 'Pretendard'; */
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    color: #555;
    letter-spacing: -0.035em;

    .blind {
      overflow: hidden;
      position: absolute;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      margin: -1px;
    }

    .inner {
      position: relative;
      max-width: 750px;
      margin: auto;
      padding: 5em 0 6em;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .title {
      margin-bottom: 2.5em;
      h2 {
        font-weight: 700;
        font-size: 1.7em;
        color: #fff;
        span {
          display: block;
          font-weight: 300;
          font-size: 0.823em;
          color: #7eb6f6;
        }
        span + p {
          margin-top: 0.4em;
        }
        p + span {
          margin-top: 0.488em;
        }
      }
    }

    @media (max-width: 750px) {
      .inner {
        padding: 4em 4%;
      }
    }

    .hide {
      display: none !important;
    }

    .visual {
      background-color: #171923;
      background-image: url(${visualBg});
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: auto 938px;
      .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 938px;
        padding-top: 0;
        padding-bottom: 100px;
      }
      .title {
        margin-top: -2.5em;
        margin-bottom: 2.75em;
        .sub {
          margin-bottom: 0.6em;
          font-weight: 200;
          font-size: 1.8em;
          color: #fff;
        }
        h2 {
          font-size: 2.1em;
          color: #fff;
        }
      }
    }
    @media (max-width: 750px) {
      .visual {
        background-size: auto 125vw;
        .inner {
          height: 125vw;
          padding-bottom: 13vw;
        }
        .title {
          .sub {
            font-size: calc(1.8em * 0.85);
          }
          h2 {
            font-size: calc(2.1em * 0.85);
          }
        }
      }
    }

    .info {
      background: rgb(26, 34, 53);
      background: linear-gradient(
        180deg,
        rgba(26, 34, 53, 1) 0%,
        rgba(42, 54, 80, 1) 15%,
        rgba(42, 54, 80, 1) 100%
      );
      .inner {
        padding-top: 0;
      }
      .boxWrap {
        position: relative;
        margin-top: 2em;
        min-height: 13.5em;
        padding: 1.5em 2.5em;
        border-radius: 1em;
        border: 2px solid #3d4866;
        background-color: #35405e;
        box-shadow: 0.5em 0.5em 1.5em rgba(22, 24, 34, 0.13);
        text-align: left;
        &::after {
          content: '';
          display: block;
          position: absolute;
          right: 2.25em;
          bottom: -2px;
          width: 100%;
          height: 100%;
          background-image: url(${infoObj});
          background-repeat: no-repeat;
          background-position: 100% 100%;
          background-size: auto 9.6em;
          opacity: 0.9;
          z-index: 1;
        }
        .box {
          position: relative;
          display: inline-flex;
          width: 60%;
          align-items: center;
          z-index: 2;
          .percent {
            flex-shrink: 0;
            width: 5.5em;
            height: 4.9em;
            margin-right: 1em;
            border-radius: 1.4em;
            line-height: 4.7em;
            text-align: center;
            background: rgb(126, 129, 234);
            background: radial-gradient(
              circle,
              rgba(126, 129, 234, 1) 0%,
              rgba(109, 113, 210, 1) 100%
            );
            color: #fff;
            strong {
              font-weight: 700;
              font-size: 2em;
            }
            span {
              font-size: 1.3em;
            }
          }
          p {
            padding-right: 1em;
            font-size: 0.9em;
            color: #acb1dc;
          }
        }
        &.type2 {
          padding-bottom: 9.6em;
          &::after {
            right: 1.8em;
            background-image: url(${infoObj2});
          }
          .box {
            width: 50%;
          }
        }
      }
      .stitle {
        position: relative;
        margin-bottom: 1.2em;
        padding-left: 0.6em;
        font-weight: 700;
        font-size: 1.3em;
        color: #fff;
        z-index: 2;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 0.3em;
          height: 100%;
          background: rgb(136, 49, 147);
          background: linear-gradient(
            180deg,
            rgba(136, 49, 147, 1) 0%,
            rgba(85, 48, 176, 1) 100%
          );
        }
      }
    }
    @media (max-width: 580px) {
      .info {
        .boxWrap {
          padding-bottom: 9.6em;
          &.type2 {
            .box {
              width: 100%;
              & + .box {
                margin-top: 1em;
              }
            }
          }
        }
      }
    }

    .info2 {
      background-color: #36415f;
      li {
        position: relative;
        margin-bottom: 2em;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -1em;
          width: 27%;
          height: 6.5em;
          background-image: url(${bgStep});
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: auto 100%;
        }
        .img {
          position: relative;
          width: 100%;
          padding-left: 33%;
          .step {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 27%;
            height: 100%;
            border-radius: 1em;
            background-color: #cce8ca;
            span {
              display: inline-block;
              margin-bottom: 0.5em;
              padding: 0.5em 0.8em;
              border-radius: 2em;
              background-color: #50c368;
              font-weight: 700;
              font-size: 0.8em;
              color: #fff;
            }
            div {
              font-weight: 700;
              font-size: 1.1em;
              color: #537b50;
            }
          }
          img {
            width: 100%;
            height: auto;
          }
        }
        .desc {
          width: 67%;
          margin-left: auto;
          border-bottom: 1px solid #46506c;
          img {
            width: 100%;
            height: auto;
          }
          div {
            padding: 0.6em 0 0.8em;
            min-height: 3.8em;
            line-height: 1.2em;
            font-size: 0.9em;
            color: #acb1dc;
          }
        }
        &:nth-child(2) {
          .img {
            .step {
              background-color: #f2bb9e;
              span {
                background-color: #c87a59;
              }
              div {
                color: #9a5e3e;
              }
            }
          }
        }
        &:nth-child(3) {
          margin-bottom: 0;
          &::before {
            display: none;
          }
          .img {
            .step {
              background-color: #91c7e7;
              span {
                background-color: #6385cd;
              }
              div {
                color: #34638c;
              }
            }
          }
          .desc {
            border: none;
          }
        }
      }
    }
    @media (max-width: 580px) {
      .info2 {
        li {
          .img {
            padding-left: 30%;
            .step {
              div {
                font-size: 0.9em;
              }
            }
          }
        }
      }
    }

    .video {
      background-color: #2a3650;
      h3 {
        margin-top: 2.5em;
        margin-bottom: 0.7em;
        text-align: left;
        font-weight: 700;
        font-size: 1em;
        color: #fff;
      }
      .boxWrap {
        display: flex;
        .box {
          position: relative;
          width: 48%;
          & + .box {
            margin-left: auto;
          }
          .plyr {
            border-radius: 0.7em;
            overflow: hidden;
          }
          .best {
            position: absolute;
            top: -5px;
            left: -5px;
            width: 117px;
            height: 117px;
            background-image: url(${lbBest});
            background-repeat: no-repeat;
            background-position: 100% 100%;
            background-size: 100%;
            z-index: 2;
          }
        }
      }
    }
    &.ko .video {
      .boxWrap {
        .box {
          .best {
            background-image: url(${lbBestKo});
          }
        }
      }
    }
    @media (max-width: 580px) {
      .video {
        .boxWrap {
          display: block;
          .box {
            width: 100%;
            & + .box {
              margin-top: 1em;
            }
            .best {
              top: calc(-5px * 0.8);
              left: calc(-5px * 0.8);
              width: calc(117px * 0.8);
              height: calc(117px * 0.8);
            }
          }
        }
      }
    }

    .review {
      background-color: #2a3650;
      .inner {
        padding-top: 0;
        padding-bottom: 7.5em;
      }
    }
    @media (max-width: 750px) {
      .review {
        .inner {
          padding-bottom: 5.5em;
        }
      }
    }

    .free {
      height: 320px;
      background-color: #242e43;
      background-image: url(${freeBg});
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: auto 100%;
      .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;
        text-align: left;
        .desc {
          margin-bottom: 1.2em;
          font-weight: 300;
          font-size: 1.4em;
          color: #fff;
        }
      }
    }
    @media (max-width: 750px) {
      .free {
        height: 42.7vw;
      }
    }

    .faq {
      background-color: #25293b;
      text-align: left;
      .title {
        margin-bottom: 1em;
        font-weight: 700;
        font-size: 1.3em;
        color: #fff;
      }
      li {
        margin-top: 0.3em;
        background-color: #2c3349;
        .question {
          padding: 1em 1.75em;
          cursor: pointer;
          div {
            font-size: 1em;
            color: #acb1dc;
          }
        }
        .answer {
          max-height: 0;
          overflow: hidden;
          transition: all 0.2s ease;
          padding: 0 1.75em;
          div {
            font-size: 0.9em;
            line-height: 1.6em;
            color: #a1a4bc;
            span {
              position: relative;
              display: inline-block;
              padding-left: 2em;
              &::before {
                content: '';
                position: absolute;
                top: 50%;
                left: 0.2em;
                margin-top: -0.8em;
                display: block;
                width: 1.6em;
                height: 1.6em;
                background: url(${icoFaq}) no-repeat 0 0;
                background-size: contain;
              }
            }
          }
          .refund {
            margin-top: 1em;
          }
        }
        &.active {
          .question {
            div {
              font-weight: 700;
              color: #fff;
            }
          }
          .answer {
            max-height: 100vh;
            padding: 1em 1.75em;
            border-top: 1px solid #444e68;
          }
        }
      }
    }
  `,
};

export default StyledEventPage;

import styled from 'styled-components';
import { BsArrowRight } from 'react-icons/bs';
import { FaArrowRight } from 'react-icons/fa';

const StyledAiSpeak = {
  FloatingButton: styled.div`
    position: fixed;
    left: 0;
    bottom: -12em;
    width: 100%;
    z-index: 98;
    background-color: #1e2024;
    padding: 3em 0;
    text-align: right;
    opacity: 0;
    transition: all ease-in 0.2s;
    a {
      font-size: 1.6em;
    }
    &.fixed {
      opacity: 1;
      bottom: 0;
    }
  `,

  VideoContainer: styled.div`
    max-width: 930px;
    margin: 8em auto 20em;
    @media (max-width: 767px) {
      margin-bottom: 15em;
    }
  `,

  ButtonWrap: styled.div`
    margin-top: 6em;
    text-align: center;
  `,

  ArrowButton: styled.a.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <BsArrowRight />
      </>
    ),
  }))`
    display: inline-block;
    background: #485cc7;
    border-radius: 2em;
    padding: 1em 3em;
    line-height: 1;
    font-weight: 600;
    font-size: 2.4em;
    color: #fff;
    svg {
      margin-left: 0.5em;
      vertical-align: middle;
    }
    cursor: pointer;
  `,

  ContentContainer: styled.div`
    display: flex;
    gap: 4em;
    align-items: center;
    margin-bottom: 12em;
    &.left-img {
      flex-direction: row-reverse;
    }
    @media (max-width: 767px) {
      flex-direction: column !important;
    }
  `,

  ContentBox: styled.div`
    flex: 1;
    text-align: left;
    padding: 0 6em;
    &.center {
      text-align: center;
    }
    @media (max-width: 1200px) {
      padding: 0 3em;
    }
    @media (max-width: 767px) {
      width: 100%;
      padding: 0;
    }
    .title {
      line-height: 1.2;
      font-weight: 600;
      font-size: 5em;
      color: #fff;
    }
    .desc {
      margin-top: 2em;
      font-size: 2em;
      color: #c9cbd1;
    }
  `,

  BannerTitle: styled.div`
    margin: 18em 0 6em;
    .title {
      line-height: 1.2;
      font-weight: 600;
      font-size: 3.6em;
      color: #fff;
    }
    .desc {
      margin-top: 0.8em;
      font-size: 2em;
      color: #c9cbd1;
    }
  `,

  BannerContainer: styled.div`
    display: flex;
    gap: 4em;
    margin-bottom: 15em;
  `,

  Banner: styled.div`
    width: 50%;
    flex: 1;
    height: 26em;
    padding: 4em;
    border-radius: 0.8em;
    overflow: hidden;
    background-color: #999;
    background-image: ${({ Bg }) => "url('" + Bg + "')" && "url('" + Bg + "')"};
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    a {
      display: block;
      cursor: pointer;
    }
    .title {
      line-height: 1.2;
      font-weight: 600;
      font-size: 2.8em;
      color: #fff;
    }
  `,

  IcoArrow: styled.span.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <FaArrowRight />
      </>
    ),
  }))`
    display: inline-block;
    margin-top: 1em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.8em;
    color: #fff;
    svg {
      margin-left: 0.5em;
      vertical-align: middle;
    }
  `,
};

export default StyledAiSpeak;

import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import Style from "./styled/index.style";
import ContentsWrapper from "component/atoms/ContentsWrapper";
import userApi from "api/user";
import { useAxios } from "common/hook/useAxiosHook";
import { priceFormat } from "common/util/util";
import Loading from "component/atoms/Loading";
import videoClassApi from "api/videoclass";

const InvoicePage = () => {
  const { t } = useTranslation("invoiceDetailPage");
  const { invoiceId } = useParams();
  const token = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.data);
  const language = localStorage.getItem('i18nextLng');

  const [getMyInvoice] = useAxios(
    () => userApi.fetchMyInvoice(token, invoiceId),
    []
  );
  const { loading, data } = getMyInvoice;
  
  const [{ loading: applicationLoading, data: applicationData, error }] = useAxios(
    () => videoClassApi.getApplication(invoiceId),
    []
  );

  const [videoclassInfo, setVideoclassInfo] = useState();

  const currency = (price) =>
    data && data.isDollar ? priceFormat.usa(price) : priceFormat.korea(price);

  const subTotal = useMemo(() => {
    if (!data) return;
    return data.items.reduce((acc, item) => {
      return item.unitPrice ? acc + item.unitPrice : acc + 0;
    }, 0);
  }, [data]);

  const discountCost = useMemo(() => {
    if (!data) return;
    return data.items.reduce((acc, item) => {
      return item.discountPrice ? acc + item.discountPrice : acc + 0;
    }, 0);
  }, [data]);

  const finalTotal = useMemo(() => {
    if (!data) return;
    return data.items.reduce((acc, item) => {
      return item.usePG ? acc + item.usePG : acc + 0;
    }, 0);
  }, [data]);

  useEffect(()=>{
    if(applicationData && applicationData.videoclassInfo){
      // console.log('applicationData', applicationData)
      setVideoclassInfo(applicationData.videoclassInfo);
    }
  }, [applicationData])

  if (loading) return <Loading />;
  if (data)
    return (
      <ContentsWrapper>
        <Style.Title>{t("title")}</Style.Title>
        <Style.Container>
          <Style.Info>
            <span>{t("label1")}</span>
            <span className="strong">{invoiceId}</span>
          </Style.Info>
          {data.hasDeliveryInformation && (
            <>
              <Style.Info>
                <span>{t("label3")}</span>
                <span className="strong">
                  {data.deliveryAddress.receiptAddress1 +
                    ", " +
                    data.deliveryAddress.receiptAddress2}
                </span>
              </Style.Info>
              <Style.Info>
                <span>{t("label4")}</span>
                <span>{data.deliveryAddress.receiptHP}</span>
              </Style.Info>
            </>
          )}

          <Style.Info>
            <span>{t("label5")}</span>
            <span>{user.userID}</span>
          </Style.Info>
          <Style.Info>
            <span>{t("label6")}</span>
            <span>{data.orderedAt.slice(0, 10)}</span>
          </Style.Info>

          <Style.SubTitle>{t("label7")}</Style.SubTitle>

          <Style.Table className="products">
            <tbody>
              <tr>
                <th>{t("table.label1")}</th>
                <th>{t("table.label4")}</th>
                <th>{t("table.label3")}</th>
              </tr>
              {data.items.map(({ title, qty, unitPrice }, index) => {
                return (
                  <tr key={index}>
                    <th>{title}</th>
                    <th>{qty}</th>
                    <th>{currency(unitPrice * qty)}</th>
                  </tr>
                );
              })}
            </tbody>
          </Style.Table>

          <Style.SubTitle>{t("label8")}</Style.SubTitle>

          <Style.Table>
            <colgroup>
              <col style={{ width: "40%" }} />
              <col style={{ width: "60%" }} />
            </colgroup>
            <tbody>
              <tr>
                <th>{t("table.label9")}</th>
                <th>{currency(subTotal)}</th>
              </tr>
              <tr>
                <th>{t("table.label6")}</th>
                <th>
                  {data &&
                  data.items[0] &&
                  (data.items[0].title === "EPS" ||
                    data.items[0].title === "STANDARD" ||
                    data.items[0].title === "PREMIUM") &&
                  data.items[0].usePG !== 0 &&
                  data.items[0].unitPrice === data.items[0].usePG
                    ? currency(data.items[0].unitPrice - data.items[0].usePG)
                    : data.items[0].unitPrice >= data.items[0].usePG
                    ? currency(data.items[0].usePG - data.items[0].unitPrice)
                    : currency(discountCost)}
                </th>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th>{t("table.label8")}</th>
                <th>
                  {data.items[0].usePG === 0
                    ? currency(
                        data.items[0].unitPrice - data.items[0].unitPrice
                      )
                    : currency(data.items[0].usePG)}
                </th>
              </tr>
            </tfoot>
          </Style.Table>
          {data.deliverNum && (
            <>
              <Style.SubTitle>Delivery Info</Style.SubTitle>
              {data.courierName && (
                <Style.Desc>
                  {t("courier")}:<span>{data.courierName}</span>
                </Style.Desc>
              )}
              <Style.Desc>
                {t("deliveryNo")}
                <span>{data.deliverNum}</span>
              </Style.Desc>
              {data.deliveryStatus && (
                <Style.Desc>
                  Delivery State: <span>{data.deliveryStatus}</span>
                </Style.Desc>
              )}
            </>
          )}
          <Style.SubTitle>{t("paymentMethod")}</Style.SubTitle>
          <Style.Desc>{data.paymentMethod}</Style.Desc>

          {videoclassInfo && 
          ((videoclassInfo.productTitle || videoclassInfo.productTitleEN) && 
            // videoclassInfo.stuEmail && videoclassInfo.learnSchedule && videoclassInfo.learnContent &&
            <Style.BlockWrap>
              <Style.SubTitle>{t("Application.stitle")}</Style.SubTitle>
              <Style.TextBox>
                <Style.TextItem>
                  <Style.Blue>{t("Application.label1")}</Style.Blue>
                  <p>{language === 'ko-KR' ? videoclassInfo.productTitle : videoclassInfo.productTitleEN}</p>
                </Style.TextItem>
                <Style.TextItem>
                  <Style.Blue>{t("Application.label2")}</Style.Blue>
                  <p>
                    <Moment format="YYYY/MM/DD HH:mm">{videoclassInfo.regDate}</Moment>
                  </p>
                </Style.TextItem>
              </Style.TextBox>
              <Style.TextBox className="mrbtm">
                <Style.TextItem>
                  <ul>
                    <li>
                      <p className='gray'>{t("Application.label3")}</p>
                      <p className='pre'>{videoclassInfo.stuEmail}</p>
                    </li>
                    <li>
                      <p className='gray'>{t("Application.label4")}</p>
                      <p className='pre'>{videoclassInfo.learnSchedule}</p>
                    </li>
                    <li>
                      <p className='gray'>{t("Application.label5")}</p>
                      <p className='pre'>{videoclassInfo.learnContent}</p>
                    </li>
                  </ul>
                </Style.TextItem>
              </Style.TextBox>
            </Style.BlockWrap>
          )}

          <Style.SubTitle>{t("contact")}</Style.SubTitle>
          <Style.Desc>
            48, 33-gil, Digital-ro, Guro-gu, Seoul, South Korea
          </Style.Desc>
          <Style.Desc>mastertopik@visang.com</Style.Desc>
          <Style.Desc className="email">www.mastertopik.com </Style.Desc>
          {/* <Style.SubTitle>Terms & Conditions</Style.SubTitle>
          <Style.Desc>
            The above will be delivered upon payment via check, debit, PayPal,
            Union Pay, or WeChat
          </Style.Desc>
          <Style.Desc>Pay This invoice is valid for 2 weeks.</Style.Desc>
          <Style.Desc>
            If you want to cancel your purchase, please contact the Master Topik
            Help Desk
          </Style.Desc>
          <Style.Desc>(mastertopikhelp.zendesk.com).</Style.Desc>
          <Style.SignatureContainer>
            <p>
              Yang Tae-Hoe <span className='role'>/ CEO</span>
            </p>
          </Style.SignatureContainer> */}
        </Style.Container>
      </ContentsWrapper>
    );
  return <></>;
};

export default InvoicePage;

import React, { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import Styled from "./index.style";
import { priceFormat } from "common/util/util";
import ButtonLink, { ButtonTheme } from "component/atoms/ButtonLink";
import Moment from "react-moment";
import { RiCheckFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions } from "component/user/state";
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';
import userApi from 'api/user';
import { useAxios } from 'common/hook/useAxiosHook';

const OrderCompleteForPlan = ({ orderData }) => {
  const { t } = useTranslation("paymentSuccessPage2");
  const [order, setOrder] = useState(null);
  const [isFreeTrialOrder, setIsFreeTrialOrder] = useState(false);
  const [{ loading: myPaymentListLoading, data: myPaymentListData }, getMyPaymentList] = useAxios(
    () => userApi.fetchMyPaymentList(token),
    []
  );

  useEffect(() => {
    setOrder(orderData);
  }, [orderData]);

  useEffect(()=>{
    if(orderData && myPaymentListData){
      const historyData = myPaymentListData.find((data)=> data.orderId === orderData.orderId);
      //title이 STANDARD || PREMIUM (구 결제한 사람의 경우 ESP)
      if(historyData.title !== 'BOOK' && historyData.title !== 'VIDEOCLASS'){
        //1번
        //쿠폰 사용 x && usePG값이 0 || 0.00 || 0.01 인 경우 > 무료체험 결제건
        if(!orderData.couponNum && (orderData.items[0].usePG.toString() === '0' || orderData.items[0].usePG.toString() === '0.01')){
          setIsFreeTrialOrder(true);
        } else setIsFreeTrialOrder(false);

        //2번
        //my-payment-histories 에서 title이 BOOK, VIDEOCLASS가 아니고 status가 SUCCESS인 경우 리스트
        // const planSuccessPaymentList = myPaymentListData.filter((data)=>{
        //   return (data.title !== 'BOOK' && data.title !== 'VIDEOCLASS') 
        //           && data.status === 'SUCCESS';
        // });
        // //리스트 중 마지막 데이터의 orderId가 orderData의 orderId와 같다면 > 무료체험 결제건
        // if(planSuccessPaymentList.length > 0 && planSuccessPaymentList[planSuccessPaymentList.length-1].orderId === orderData.orderId){
        //   setIsFreeTrialOrder(true);
        //   console.log('222222222')
        // } else setIsFreeTrialOrder(false);
      }else{
        setIsFreeTrialOrder(false);
      }
    }
  }, [orderData, myPaymentListData])

  const priceFormatting = (price) =>
    order.isDollar ? priceFormat.usa(price) : priceFormat.korea(price);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  useEffect(() => dispatch(userActions.fetchUser(token)), []);

  return (
    <>
      {order && (
        <>
          {isFreeTrialOrder && process.env.REACT_APP_ENV === "production" ?
            <Helmet>
              {/* Event snippet for 가입 conversion page */}
              <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-M6DSB3B"></script>
              <script type='text/javascript'>
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('event', 'conversion', {'send_to': 'AW-415877565/E_3vCKywsIsYEL2Tp8YB'});
                `}
              </script>
            </Helmet>
            :<></>
          }
          <Styled.Container>
            <Styled.Left>
              <Styled.H3>{t("subtitle")}</Styled.H3>

              <Styled.Icon>
                <RiCheckFill />
              </Styled.Icon>

              <Styled.Bold>
                <Moment format="YYYY/MM/DD HH:mm">{order.orderedAt}</Moment>
              </Styled.Bold>

              <Styled.Blue>{t("label1")}</Styled.Blue>
              {order.items.map((item, index) => (
                <p key={`label1_${index}`}>
                  {item.title}{" "}
                  <span style={{ marginLeft: 10 }}>
                    {priceFormatting(item.unitPrice)}
                  </span>
                </p>
              ))}
              <p className="gray">{t("stateMsg")} </p>
            </Styled.Left>

            <Styled.Right>
              <Styled.Blue style={{ marginBottom: 5 }}>{t("label1")}</Styled.Blue>
              <p>
                <b>
                  {order.paymentMethod}{" "}
                  {order.statusType !== 1 && " (결제 대기 중)"}
                </b>
              </p>
              <Styled.Blue>{t("label3")}</Styled.Blue>
              <Styled.Table>
                <thead>
                  <tr>
                    <th>{t("label1")}</th>
                    <th>{t("label5")}</th>
                  </tr>
                </thead>
                <tbody>
                  {order.items.map((item, index) => (
                    <tr key={`label3_${index}`}>
                      <td>{item.title}</td>
                      <td>{priceFormatting(item.unitPrice)}</td>
                    </tr>
                  ))}
                </tbody>
              </Styled.Table>
              <Styled.Hr />
              {/* http://localhost:3000/order-complete/plan/OK21071611416153 */}
              <Styled.Table>
                <tbody>
                  <tr>
                    <th>{t("label5")}</th>
                    <td>
                      {priceFormatting(
                        order.items.reduce((acc, item) => {
                          return item.unitPrice ? acc + item.unitPrice : acc + 0;
                        }, 0)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>{t("label7")}</th>
                    <td>
                      {order.items[0].usePG !== 0 &&
                      order.items[0].unitPrice === order.items[0].usePG
                        ? priceFormatting(
                            order.items[0].unitPrice - order.items[0].usePG
                          )
                        : order.items[0].unitPrice >= order.items[0].usePG
                        ? priceFormatting(
                            order.items[0].usePG - order.items[0].unitPrice
                          )
                        : priceFormatting(0)}
                    </td>
                  </tr>
                </tbody>
              </Styled.Table>
              <Styled.Hr />
              <Styled.Table>
                <tfoot>
                  <tr>
                    <td>{t("label11")}</td>
                    <td>
                      {priceFormatting(
                        order.items.reduce((acc, item) => {
                          return item.usePG ? acc + item.usePG : acc + 0;
                        }, 0)
                      )}
                    </td>
                  </tr>
                </tfoot>
              </Styled.Table>
            </Styled.Right>
          </Styled.Container>
        </>
      )}

      <Styled.ButtonContainer>
        <ThemeProvider theme={ButtonTheme.yellow}>
          <ButtonLink to="/level-test">LEVEL TEST</ButtonLink>
        </ThemeProvider>

        <ButtonLink to="/">MY PAGE</ButtonLink>
      </Styled.ButtonContainer>
    </>
  );
};

export default OrderCompleteForPlan;

import React from 'react';
import SelectWrapper from './index.style';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/**
 * 셀렉트 박스 (목록페이지 사용)
 * @param {*} param
 * @param {func=} param.onChange onChange event (기본 props: label, value)
 * @param {string} param.optionList option list
 * @param {string} param.width ex: '14.5em'
 * @param {string=} param.className set wrapper className ( title 및 subtitle에도 접두사로 className 생성됨 )
 */
const OrderBySelect = ({
  onChange,
  optionList,
  width,
  className,
  placeholder,
}) => {
  const { t } = useTranslation('resourcesPage');

  return (
    <SelectWrapper width={width} className={className}>
      <Select
        placeholder={
          placeholder ? placeholder : t('selector.option1')
        }
        options={optionList}
        onChange={onChange}
        isSearchable={false}
        classNamePrefix='select'
      />
    </SelectWrapper>
  );
};

OrderBySelect.propTypes = {
  onChange: PropTypes.func,
  optionList: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.string,
  className: PropTypes.string,
};

export default OrderBySelect;

import { asyncApi } from 'common/util/asyncApi';

export const fetchNationality = async () => {
  const config = { url: '/api/v1/members/nations' };
  let response = await asyncApi(config);

  return response.data;
};
export const fetchProfileViewTime = async (access_token) => {
  const config = {
    url: '/api/v1/my-classroom/view-time',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': `application/json`,
    },
  };

  let response = await asyncApi(config);
  return response.data;
};
export const fetchProfileClassInfo = async (access_token) => {
  const config = {
    url: '/api/v1/my-classroom/course-count',
    headers: {
      Authorization: `Bearer ${access_token}`,
      'Content-Type': `application/json`,
    },
  };

  let response = await asyncApi(config);
  return response.data;
};

export const postProfileInfo = async (data, token) => {
  data = JSON.stringify('postProfileInfo', data);
  const config = {
    method: 'put',
    url: '/api/v1/members/profile',
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
  };
  const response = await asyncApi(config);
  return response;
};

export const postProfileImage = async (file, token) => {
  var frm = new FormData();
  frm.append('file', file);
  const config = {
    method: 'post',
    url: '/api/v1/profilePics',
    data: frm,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };
  let response = await asyncApi(config);

  return response.data;
};

import React, { useEffect, useState } from 'react';
import Styled from './index.style';

import { useHistory, useLocation } from 'react-router-dom';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";
//import Img from 'component/atoms/Img';

import character from 'assets/images/event/230921/character.png';
import title from 'assets/images/event/230921/title.png';
import title_bg from 'assets/images/event/230921/step3_title_icon.png';
import closeIcon from 'assets/images/event/230921/close-icon.png';
import vIcon from 'assets/images/event/230921/v.png';
//import thumbnailDefault from 'assets/images/thumb_default_course.jpg';

import quizzes from 'container/EventPage/20230920korean/QuizData';
import copy from 'copy-to-clipboard';
import { useAxios } from 'common/hook/useAxiosHook';
import coursesApi from 'api/courses';

const Step2Content = ({ page, currentQuiz, moveToNext }) => {
  const currentQuestion = currentQuiz;

  return (
    <div className={`step2-con step2-con${currentQuestion?.id}`}>
      <div className='step2-con-head'>
        <h5>Question {currentQuestion?.id}.</h5>
        <h3 dangerouslySetInnerHTML={{ __html: currentQuestion?.title }}></h3>
        <p>{currentQuestion?.titleEn}</p>
      </div>
      <div className='step2-con-body'>
        <div className='img'>
          <img src={currentQuestion.src} alt="이미지" />
        </div>
        <div className='examples'>
          {currentQuestion?.examples &&
            currentQuestion?.examples.map((example, idx2) => (
              <button key={idx2} onClick={() => moveToNext(example)} className={`ex${idx2} ${page === 10 ? 'large' : ''}`}>
                {example}
              </button>
            ))}
        </div>
      </div>
    </div>
  );
};

const EventPage = () => {
  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);
  const location = useLocation();
  const history = useHistory();

  const [page, setPage] = useState(1); // 스탭2 page 번호
  const [step, setStep] = useState(1); // 스텝 번호
  const [correctAnswers, setCorrectAnswers] = useState([]); // 선택 정오답
  const [score, setScore] = useState(0); //총 점수
  const [copyModal, setCopyModal] = useState(false); //총 점수

  const [{ data: data }, fetchRecommendedCoursesResponse] = useAxios(
    coursesApi.getHangulDayEventRecommendedCourse,
    [],
    true
  );

  const [recommendedCourses, setCourses] = useState([]);

  const levelType = {
    begginer: "Begginer",
    intermediate: "Intermediate",
    advanced: "Advanced"
  }

  const level = (score >= 70) ? levelType.advanced : (score >= 40) ? levelType.intermediate : levelType.begginer;

  const startQuiz = () => {
    setStep(2);
  };

  const resetStart = () => {
    setCorrectAnswers([]);
    setScore(0);
    setStep(1);
    setPage(1);
  };

  const reStart = () => {
    setCorrectAnswers([]);
    setScore(0);
    setStep(2);
    setPage(1);
  };

  const closeModal = () => {
    setCopyModal(false);
  };

  const copyLink = () => {
    try {

      copy(window.location.href);
      setCopyModal(true);
      // navigator.clipboard.writeText(window.location.href).then(()=> {
      //   setCopyModal(true);
      // });
    } catch (error) {
      console.error("copy to clipboard error");
    }
  };

  const takeCoursesEvent = () => {
    if (isLogin) {
      history.push({
        pathname: `/courses/59`,
      });
    } else {
      handleModal(<Login />, false, 420, location.pathname);
      return;
    }
  };

  const moveToNext = (example) => {
    if (example === quizzes[page - 1].answer) {
      setScore((prev) => prev + 10);
    }

    setCorrectAnswers((prevCorrectAnswers) => [...prevCorrectAnswers, {
      choice: example,
      answer: quizzes[page - 1].answer,
      correct: example === quizzes[page - 1].answer // 정답이면 true , 오답이면 false
    }]);

    if (quizzes.length > page) {
      setPage((prev) => prev + 1);
    } else {
      setStep(3);
    }
  };

  useEffect(() => {
    const root = document.getElementById('root');
    root.style = `background: #124e4f;`;
    return () => {
      root.style = `background: none;`;
    };
  }, []);

  useEffect(() => {
    if (step === 3) {
      console.error("test: level - ", level);
      //TODO. 추천 강좌 검색
      const levelNumber = (level === levelType.begginer) ? 1 : (level === levelType.intermediate) ? 2 : 3;
      fetchRecommendedCoursesResponse(levelNumber);
    }
  }, [step]);

  useEffect(() => {
    if (data) {
      setCourses(data.courses);
    }
  }, [data]);

  return (
    <>
      <Styled.EventWrap>
        {step === 1 ?
          <Styled.Step1>
            <div className='step1-wrap'>
              <h1><img src={title} alt="10.09 한글데이" /></h1>
              <div className="character-wrap">
                <div className='character'><button className='btn-takeTest' onClick={startQuiz}><img src={character} alt="TAKE TEST" /></button></div>
              </div>
            </div>
          </Styled.Step1>
          : step === 2 ?
            <Styled.Step2>
              <div className='step2-wrap'>
                <Step2Content page={page} currentQuiz={quizzes[page - 1]} moveToNext={moveToNext} />
                <div className='step2-foot'>
                  <button onClick={resetStart}>HOME</button>
                  <div className='paging'>{page}/10</div>
                </div>
              </div>
            </Styled.Step2>
            : <Styled.Step3>
              <div className='step3-wrap'>
                <div className='step3-head'>
                  <h3><img src={title_bg} alt="무늬" />Your Korean Score <img src={title_bg} alt="무늬" /></h3>
                  <div className='score-wrap'>
                    <h5>Points</h5>
                    <h2>{score}</h2>
                    <p>
                      You are on the {level} level. <br />
                      Check which problem you got wrong.
                    </p>
                  </div>
                </div>
                <div className='step3-body'>
                  {copyModal && <div className='copy-modal'>
                    <button className='close-btn' onClick={closeModal}><img src={closeIcon} alt="닫기" /></button>
                    <h5>Event URL has been copied</h5>
                    <h3>
                      <span>Share</span> the <span>event</span><br />
                      with your friends.
                    </h3>
                    <button className='check-btn' onClick={closeModal}>Check!</button>
                  </div>
                  }
                  <div className='step3-table'>
                    <ul>
                      {step === 3 && correctAnswers.map((list, idx) => {
                        const { choice, answer, correct } = list

                        return (
                          <li key={idx} className={`${correct ? 'anwser' : 'wrong'}`}>
                            <span className='t-head'>{idx + 1}</span>
                            <span className='t-body'>
                              <span className='choice'>{choice}</span>
                              <span className='wrong-answer'>{answer}</span>
                            </span>
                          </li>
                        )

                      })}

                    </ul>
                  </div>
                  <div className='btn-wrap'>
                    <button className='btn-reset' onClick={reStart}>Test Again</button>
                    <button className='btn-share' onClick={copyLink}>Share Event</button>
                  </div>
                </div>
                <div className='step3-foot'>
                  <div className="read-more">
                    <img src={vIcon} alt='Read More' />
                    Read More
                  </div>
                  <h5>Take MT recommended course for free!</h5>
                  <h3>And improve your Korean Level</h3>
                  <ul>
                    {recommendedCourses && recommendedCourses.map(({ productId, thumbnailPath }, idx) => {
                      return (
                        <li key={productId}>
                          {/* <a href={`/courses/detail/${productId}`} target='_blank'> */}
                          <img src={thumbnailPath} alt={`${level}_${idx}`} />
                          {/* </a> */}
                        </li>
                      );
                    })}
                  </ul>
                  <div className='btn-wrap'>
                    <button className='btn-take' onClick={takeCoursesEvent}>TAKE COURSES</button>
                  </div>
                </div>
              </div>
            </Styled.Step3>
        }
      </Styled.EventWrap>
    </>
  );
};

export default EventPage;
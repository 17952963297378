import React, { useContext, useEffect, useMemo } from "react";
import Styled from "./Modal.style";
import StyledModal from "component/Modal/styled/Modal.style";
import StyledForm from "component/form/Form.style";
import { ModalContext } from "component/Modal/modalContext";
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions } from "component/user/state";
import Moment from "react-moment";
import { CURRENCY } from "common/constant";
import { formatNumber } from "common/util/util";
import { useTranslation } from "react-i18next";

const ChangeCancelCompleteModal = ({
  selectedPlan,
  getPlan,
  handleFetchPlan,
}) => {
  const { t } = useTranslation("planPage");

  const { handleModal } = useContext(ModalContext);
  const selectedPlanName = selectedPlan.planCode;
  const currentCurrency = useSelector((state) => state.common.currency);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  
  const currentPlan = useMemo(()=> getPlan.plans.find(
    (plan) => plan.productId === getPlan.currentPlanProductId
  ),[selectedPlan, getPlan]);

  useEffect(() => {
    dispatch(userActions.fetchUser(token));
    handleFetchPlan();
  }, []);

  const WebInterfaceHandleModal = () => {
    handleFetchPlan();
    handleModal();
  };

  useEffect(() => {
    window.addEventListener("webBridge", async (e) => {
      console.log(e.detail);
      WebInterfaceHandleModal();
    });
  }, [window.dispatchEvent]);

  return (
    <Styled.Container>
      <StyledModal.H2>{t("Modal.cancelConfirm.title")}</StyledModal.H2>

      <Styled.ChangePlan>
        {/* <Styled.CurrentPlan className={getPlan.currentPlan.toLowerCase()}>
          {getPlan.currentPlan}
        </Styled.CurrentPlan> */}
        <Styled.CurrentPlanP className={selectedPlan.planCode.toLowerCase()}>
          <span>{selectedPlan.planCode}</span>
          <Styled.PlanSpan>
            ({selectedPlan.takeCourseTerm}{
              selectedPlan.takeCourseTerm === 1
              ? t('planCard.month') : t('planCard.months')
            })
          </Styled.PlanSpan>
        </Styled.CurrentPlanP>
        <Styled.Arrow />
        {/* <Styled.NewPlan className={selectedPlan.planCode.toLowerCase()}>
          {selectedPlanName}
        </Styled.NewPlan> */}
        <Styled.NewPlanP className={getPlan.currentPlan.toLowerCase()}>
          <span>{getPlan.currentPlan}</span>
          <Styled.PlanSpan>
            ({currentPlan.takeCourseTerm}{
              currentPlan.takeCourseTerm === 1
              ? t('planCard.month') : t('planCard.months')
            })
          </Styled.PlanSpan>
        </Styled.NewPlanP>
      </Styled.ChangePlan>

      {/* {selectedPlan.upgradable && (
        <Styled.Text>
          지금부터{" "}
          <b className={selectedPlan.planCode.toLowerCase()}>
            {selectedPlan.planCode}
          </b>
          으로 학습하세요!
        </Styled.Text>
      )}

      {selectedPlan.downgradable && (
        <Styled.Text>
          <b>
            <Moment format="YYYY.MM.DD">{getPlan.nextBeginDate}</Moment>
          </b>
          부터{" "}
          <b>
            {currentCurrency === CURRENCY.DOMESTIC
              ? formatNumber(selectedPlan.priceKrw)
              : selectedPlan.priceUsd}
          </b>
          가 결제되고, PLAN이 <b>{selectedPlan.planCode}</b>으로 바뀝니다.
        </Styled.Text>
      )} */}

      {
        //   selectedPlan.cancelable && (
        //   <Styled.Text>
        //     기존의 <b>{getPlan.currentPlan}</b> PLAN이 유지됩니다.
        //   </Styled.Text>
        // )
      }

      <Styled.ButtonGroup>
        <StyledForm.ButtonLink to="/classroom" onClick={() => handleModal()}>
          {t("Modal.cancelConfirm.button")}
        </StyledForm.ButtonLink>
      </Styled.ButtonGroup>
    </Styled.Container>
  );
};

export default ChangeCancelCompleteModal;

import H3 from 'component/atoms/H3';
import PropTypes from 'prop-types';
import Styled from './index.style';
import React, { useEffect } from 'react';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';
/**
 *
 * @param {*} param
 * @param {array} param.initState [state, fetchData]
 * @returns
 */
const HotTopik = ({ initState }) => {
  const { t } = useTranslation('communityPage');
  const [topList, fetchTopList] = initState;
  const { loading, data } = topList;

  useEffect(() => fetchTopList(), []);

  return (
    <Styled.Wrapper>
      <H3>{t('title1')}</H3>
      <Styled.Ul>
        {data?.content &&
          data.content.map(({ id, title, categoryCode, productTitle }) => (
            <Styled.Li key={id}>
              <Styled.Link to={`/community/${id}`}>
                <Styled.Title>{title}</Styled.Title>
                {/* <Styled.Category>
                  {categoryCode} | {productTitle}
                </Styled.Category> */}
              </Styled.Link>
            </Styled.Li>
          ))}
      </Styled.Ul>
    </Styled.Wrapper>
  );
};

HotTopik.propTypes = {
  initState: PropTypes.array.isRequired,
};

export default HotTopik;

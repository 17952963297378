import { useMemo } from 'react';

export const findGender = (gender) => {
  switch (gender) {
    case 'M':
      return 'Male';
    case 'F':
      return 'Female';
    case 'O':
      return 'Other';
    default:
      return gender;
  }
};

export const DateOptions = (start, end, name, order = false) => {
  let addOptions = useMemo(() => {
    let dateOption = [];
    if (order) {
      for (let i = start; i > end; i--) {
        let data = { label: i, value: i, name: name };
        dateOption.push(data);
      }
    } else {
      for (let i = start; i < end; i++) {
        let data = { label: i, value: i, name: name };
        dateOption.push(data);
      }
    }
    return dateOption;
  }, [start, end, order, name]);
  return addOptions;
};

export const formatSingleInteger = (number) => {
  let stringNumber = number.toString();
  if (stringNumber.length === 1) {
    stringNumber = '0' + stringNumber;
    return stringNumber;
  }
  return stringNumber;
};

export const profileInfo = (userInfoData) => {
  return {
    userFirstName: userInfoData.userFirstName,
    userLastName: userInfoData.userLastName,
    userFullName: userInfoData.fullName,
    nickname: userInfoData.userNickName,
    photoURL: userInfoData.profilePhotoPath,
    birthdayYear: userInfoData.userBirth?.slice(0, 4),
    birthdayMonth: userInfoData.userBirth?.slice(5, 7),
    birthdayDay: userInfoData.userBirth?.slice(8, 10),
    gender: userInfoData.userGender,
    emailAcceptation: userInfoData.isReceiveEmail,
    smsAcceptation: userInfoData.isReceiveSMS,
    countryIndex: userInfoData.tblNationalityIdx,
    koreaHP: userInfoData.koreaHP,
    nationNumber: userInfoData.nationNumber ? userInfoData.nationNumber : 0,
    nationCode: userInfoData.nationCode ? userInfoData.nationCode : '',
  };
};

export const validation = (userInfo) => {
  if (!userInfo.userFullName.trim()) {
    return 'please enter your name';
  } else if (!userInfo.nickname.trim()) {
    return 'please enter your nickname';
  } 
  return null;
};

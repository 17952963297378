import styled, { css } from 'styled-components';
import StyledUtil from 'common/styled/Util.style';
import H2 from 'component/atoms/H2';
import iconEdit from 'assets/images/ico_edit.png';
import iconEditLarge from 'assets/images/ico_edit_large.png';
import iconDelete from 'assets/images/ico_delete.png';
import iconDeleteLarge from 'assets/images/ico_delete_large.png';
import iconLink from 'assets/images/ico_link.png';
import iconLinkLarge from 'assets/images/ico_link_large.png';

const buttonStyle = css`
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #1e2024;
  background-size: contain;
  text-indent: -99px;
  overflow: hidden;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #24262a;
  }
`;

const StyledThemeDetailHeader = {
  Wrapper: styled.header`
    position: relative;
    padding: 10em 0 6em;
    margin-bottom: 6em;
    border-bottom: 1px solid #222;
    text-align: center;
    @media (max-width: 767px) {
      padding-top: 7em;
    }
  `,
  Title: styled(H2)`
    font-size: 3.6em;
  `,
  Desc: styled.h3`
    margin-top: 0.5em;
    font-size: 1.8em;
    color: #c9cbd1;
    @media (max-width: 767px) {
      font-size: 2em;
    }
  `,
  Info: styled.div`
    display: inline-block;
    margin-top: 3.5em;
    padding: 1.5em 4em;
    border: 1px solid #454545;
    border-radius: 100px;
    color: 8e9199;
    > * {
      vertical-align: middle;
    }

    @media (max-width: 767px) {
      padding: 1em 2em;
    }
  `,
  Tag: styled.span`
    font-size: 1.4em;
    font-weight: 400;
    @media (max-width: 767px) {
      font-size: 2em;
    }
  `,
  Bar: styled.i`
    ${StyledUtil.Bar};
    margin: 0 3rem;
    @media (max-width: 767px) {
      margin: 0 2rem;
    }
  `,
  UserMenu: styled.div`
    position: absolute;
    bottom: 2em;
    right: 0;
    @media (max-width: 767px) {
      bottom: auto;
      top: -4em;
    }
  `,
  Edit: styled.button.attrs({ type: 'button', children: 'Edit' })`
    ${buttonStyle};
    background-image: url(${iconEdit});
    @media (max-width: 1024px) {
      background-image: url(${iconEditLarge});
    }
  `,
  Delete: styled.button.attrs({ type: 'button', children: 'Delete' })`
    ${buttonStyle};
    margin-left: 1em;
    background-image: url(${iconDelete});
    @media (max-width: 1024px) {
      background-image: url(${iconDeleteLarge});
    }
  `,
  Add: styled.button`
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-left: 1em;
    border-radius: 100px;
    background-image: url(${iconLink});
    background-repeat: no-repeat;
    background-position: center;
    background-color: #1e2024;
    vertical-align: middle;
    @media (max-width: 1024px) {
      background-image: url(${iconLinkLarge});
      background-size: 15px auto;
    }
  `,
  DeleteSubmit: styled.button.attrs({ type: 'button' })`
    position: absolute;
    bottom: 2em;
    left: 0;
    height: 0;
    padding: 0 2em;
    border-radius: 0.4rem;
    background-color: #1e2024;
    font-size: 1.4em;
    color: #cacbd0;
    transition: all 0.2s ease;
    overflow: hidden;
    &:hover {
      background-color: #24262a;
    }
    &.active {
      height: 4rem;
    }
    @media (max-width: 767px) {
      display: block;
      margin: 2em auto -2em;
      position: static;
      transition: all 0.3s ease;
    }
    &.active {
      height: 4rem;
    }
  `,
};

export default StyledThemeDetailHeader;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import plansApi from 'api/plans';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';
import { ModalContext } from 'component/Modal/modalContext';

const Style = {
  Container: styled.div`
    margin: 1em 0;
    h3 {
      margin-bottom: 1.875em;
      text-align: center;
      font-size: 2.4em;
      font-weight: 700;
    }
  `,
  BtnContainer: styled.div`
    text-align: center;
    button {
      display: inline-block;
      width: 45%;
      margin-top: 1.875em;
      margin-left: 1em;
      padding: 1.25em 0;
      border-radius: 0.375em;
      background-color: #5c5f66;
      color: #fff;
      font-size: 1.6em;
      font-weight: 700;
      &:first-of-type {
        background-color: #ffb500;
        margin-left: 0;
      }
    }
  `,
};
const CancelCancelModal = () => {
  const { handleModal } = React.useContext(ModalContext);

  const token = useSelector((state) => state.user.token);

  const [{ data }, refreshCancelCancelUserPlan] = useAxios(
    () => plansApi.cancelCancelUserPlan(token),
    [],
    true
  );
  useEffect(() => {
    if (!data) return;
    if (data.status === 204) {
      window.location.reload();
    }
  }, [data]);

  return (
    <Style.Container>
      <h3>Continue My Subscription?</h3>

      <Style.BtnContainer>
        <button onClick={() => refreshCancelCancelUserPlan()}>Yes</button>
        <button onClick={() => handleModal()}>No</button>
      </Style.BtnContainer>
    </Style.Container>
  );
};

export default CancelCancelModal;

import { css } from "styled-components";

export const IconTextStyles = css`
  display: inline-block;
  font-size: 1.4em;
  padding-left: 2em;
  background-repeat: no-repeat;
  background-position: left center;
  font-weight: 300;
  line-height: 1.5em;
  @media (max-width: 1024px) {
    background-size: 17px;
  }
  @media (max-width: 767px) {
    /* font-size: 2em; */
    line-height: 2em;
  }
`;

export * from "./CountIconLike";
export * from "./CountIconVideo";

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SwiperStyle from "component/slider/styled/slider.style";
import { useAxios } from "common/hook/useAxiosHook";
import PlanCard from "./PlanCardNew";
import PlanCardEx from "./PlanCard";
import { useSelector } from "react-redux";
import useLoginStatus from "common/hook/useLoginStatus";
import SwiperCore, { Navigation } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";
import plansApi from "api/plans";
import useErrorToastify from "common/hook/useErrorToastify";
import {
  plansApiPREMIUM,
  plansApiFree
} from "./SampleData";

SwiperCore.use([Navigation]);

const Container = styled.div`
  margin: 4em 0 8em 0;
  display: flex;
  > div + div {
    margin-left: 3em;
  }
  @media (max-width: 767px){
    display: block;
    > div + div {
      margin-left: 0;
      margin-top: 4em;
    }
  }
`;

const PlanContainer = ({t, canFreeTrial}) => {
  const token = useSelector((state) => state.user.token);
  const isLogin = useLoginStatus();
  const [planList, setPlanList] = useState([]);
  const [planObj, setPlanObj] = useState({});
  const [
    { loading: getPlanLoading, data: getPlanData, error: getPlanError },
    fetchPlan,
  ] = useAxios(plansApi.getPlan, [], true);

  const handleFetchPlan = () => fetchPlan(token);

  useEffect(() => {
    handleFetchPlan();
  }, [token]);

  useEffect(() => {
    if (!getPlanData) return;
    setPlanList(getPlanData.plans);
    if(getPlanData.plans && getPlanData.plans.length > 0){
      //정렬기준 takeCourseTerm
      const planCodeArr = [];
      for(const {planCode} of getPlanData.plans) {
        planCodeArr.push(planCode);
      }
      
      const planCodeList = Array.from(new Set(planCodeArr));
      const planCodeObj = {};
      planCodeList.map(code=>{
        planCodeObj[code] = getPlanData.plans.filter((data)=>data.planCode === code).sort((a,b)=> a.takeCourseTerm - b.takeCourseTerm)
      });
      // console.log("planCodeList", planCodeList)
      // console.log("planCodeObj", planCodeObj)

      setPlanObj(planCodeObj);
      // setPlanObj({
      //   "FREE": getPlanData.plans.filter((data)=>data.planCode === "FREE").sort((a,b)=> a.takeCourseTerm - b.takeCourseTerm),
      //   "STANDARD": getPlanData.plans.filter((data)=>data.planCode === "STANDARD").sort((a,b)=> a.takeCourseTerm - b.takeCourseTerm),
      //   "PREMIUM": getPlanData.plans.filter((data)=>data.planCode === "PREMIUM").sort((a,b)=> a.takeCourseTerm - b.takeCourseTerm),
      // });
    }
  }, [getPlanData]);

  // useEffect(()=>{
  //   if (process.env.REACT_APP_ENV === "local") {
  //     if(getPlanError === undefined){
  //       setPlanList(plansApiPREMIUM.plans);
  //     }
  //   }
  // },[getPlanError])

  useErrorToastify(getPlanError);

  const swiperBreakPoint = {
    1280: {
      slidesPerView: 2,
      spaceBetween: 25,
      cssMode: true,
    },
    767: { slidesPerView: 2, spaceBetween: 10, cssMode: false },
    0: { slidesPerView: 1, spaceBetween: 5 },
  };

  return (<>
    <Container>
      {!getPlanLoading && getPlanData && planObj && (<>
        {/* PREMIUM */}
        {planObj.PREMIUM && planObj.PREMIUM.length > 0 &&
            <PlanCard
              t={t}
              data={planObj.PREMIUM}
              getPlan={getPlanData}
              freeTrial={canFreeTrial}
              handleFetchPlan={handleFetchPlan}
            />
        }
        {/* STANDARD */}
        {planObj.STANDARD && planObj.STANDARD.length > 0 &&
            <PlanCard
              t={t}
              data={planObj.STANDARD}
              getPlan={getPlanData}
              freeTrial={canFreeTrial}
              handleFetchPlan={handleFetchPlan}
            />
        }
      </>)}
    </Container>
    {/* <Container>
      {!getPlanLoading && planList.length > 0 && (
        <SwiperStyle.Swiper breakpoints={swiperBreakPoint} slidesPerView={4}>
          {planList.map((item, index) => (
            <SwiperSlide key={index}>
              <PlanCardEx
                data={item}
                getPlan={getPlanData}
                handleFetchPlan={handleFetchPlan}
              />
            </SwiperSlide>
          ))}
        </SwiperStyle.Swiper>
      )}
    </Container> */}
    </>);
};

export default PlanContainer;

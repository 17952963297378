import styled from 'styled-components';
import StyledForm from 'component/form/Form.style';
import arrowRight from 'assets/images/arrow2_right_gray.svg';
import iconUnChecked from 'assets/images/ico_unchecked.png';
import iconUnCheckedLarge from 'assets/images/ico_unchecked_large.png';
import iconChecked from 'assets/images/ico_checked.png';
import iconCheckedLarge from 'assets/images/ico_checked_large.png';

const TermsForm = {
  Boxing: styled.div`
    margin-bottom: 1em;
    border: 1px solid #c9cbd1;
    border-radius: 4px;
    overflow: hidden;
  `,
  BoxingInner: styled.div`
    position: relative;
    padding: 1.5em 1.5em 0.5em 4em;
    background-color: #fff;
    &:not(:first-child) {
      border-top: 1px solid #c9cbd1;
    }
  `,
  Label: styled.label`
    float: left;
    position: relative;
    max-width: calc(100% - 3em);
    font-size: 1.4em;
    margin-bottom: 0.5em;
    font-weight: 500;
    &.termsOfAd {
      margin-left: 2em;
    }
    &[for] {
      cursor: pointer;
    }
    &[for='terms3'] {
      margin-left: 2em;
    }
    &[for]:before {
      content: '';
      position: absolute;
      width: 1.5em;
      height: 1.5em;
      left: -2.8rem;
      top: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(${iconUnChecked});
      background-color: #fff;
      transition: background 0.1s ease;
    }
    span.red {
      color: #e20c0c;
    }
    span.blue {
      color: #485cc7;
    }
    @media (max-width: 1024px) {
      &[for]:before {
        background-image: url(${iconUnCheckedLarge});
        background-size: 70%;
      }
    }
  `,
  InputCheck: styled.input.attrs({ type: 'checkbox' })`
    position: absolute;
    top: 1.5em;
    left: 4em;
    z-index: -1;
    &:checked + label[for]:before {
      background-image: url(${iconChecked});
    }
    @media (max-width: 1024px) {
      &:checked + label[for]:before {
        background-image: url(${iconCheckedLarge});
      }
    }
  `,
  Desc: styled.p`
    clear: both;
    position: relative;
    font-size: 1.3em;
    margin-bottom: 0.8em;
    font-weight: 400;
    color: #5c5f66;
  `,
  Detail: styled.div.attrs(({ children }) => ({
    children: <div>{children}</div>,
  }))`
    position: relative;
    clear: both;
    font-size: 12px;
    max-height: 0;
    background-color: #f2f4f7;
    color: #75787b;
    overflow-y: auto;
    transition: max-height 0.2s ease;
    line-height: 1.8;
    > div {
      padding: 0.83em;
    }
    pre {
      white-space: pre-wrap;
    }
    &.active {
      max-height: 110px;
    }
  `,
  DetailButtonLeft: styled.div`
    float: left;
    width: calc(100% - 2.5em);
  `,
  DetailButton: styled.button.attrs({ type: 'button' })`
    float: right;
    width: 2rem;
    height: 2rem;
    background: url(${arrowRight}) no-repeat center;
    background-size: 10px;
    transform: rotate(90deg);
    transition: all 0.2s ease;
    &.active {
      transform: rotate(-90deg);
    }
  `,
  NoticeH3: styled.div`
    font-size: 14px;
    padding: 0 1rem;
    margin-top: 1em;
    font-weight: 400;
    color: #5c5f66;
  `,
  NoticeDesc: styled.div`
    font-size: 12px;
    padding: 0 1rem;
    margin: 0.4em;
    color: #75787b;
  `,
};

export default TermsForm;

import React from 'react';
import Styled from './index.style';
import imgGift2 from 'assets/images/event/230802/topikTab/imgGift2.png';
import imgGift3 from 'assets/images/event/230802/topikTab/imgGift3.png';
import imgGift4 from 'assets/images/event/230802/topikTab/imgGift4.png';

import { Link, useLocation } from 'react-router-dom';
import { openLink } from 'common/util/util';
import {Trans, useTranslation } from 'react-i18next';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";
import VideoModal from "./VideoModal";

const EventPage = () => {
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation('event/20230802Signup');

  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);
	const location = useLocation();

	const checkLogin = (e, isPrevent) => {
		if (!isLogin) {
      e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
			handleModal(<Login />, false, 420, location.pathname);
			return;
		}else{
      if(isPrevent) e.preventDefault();
    }
	}
	const openLinkAfterLogin = (e, url) => {
		if (!isLogin) {
      e.preventDefault(); //로그인 안한 경우 Link to 기능 무효화
			handleModal(<Login />, false, 420, location.pathname);
			return;
		}else{
      e.preventDefault();
      openLink(url);
    }
	}

  const openVideoModal = (e, src) => {
    e.preventDefault();
    handleModal(<VideoModal src={ src } />, false, 810, location.pathname);
	}

  const VideoItem = ({ src }) => {
    return (
      <>
        <button onClick={(e)=>openVideoModal(e, src)}>
          <img src={'https://img.youtube.com/vi/' + src + '/maxresdefault.jpg'} alt="" />
        </button>
      </>
    )
	}

  const videoList = ['apT5A6irdk8', 'OmgbLf5kxD0', 'OiubOpXNTRM', '7xl_UOa6HYM', 'cjCF5RaUuy4', '4qtTYNlYlUU']

	return (
		<Styled.TopikTab>
      <div className='visual'>
        <div className='inner'>
          <div className='blind'>
            <h2>{t('topikTab.visual.title')}</h2>
            <p>{t('topikTab.visual.desc')}</p>
          </div>
          <div className='btn_wrap'>
            <a href="#!" onClick={(e)=>checkLogin(e, true)}><Trans t={t}>topikTab.visual.button</Trans></a>
          </div>
        </div>
      </div>

      <Styled.TargetWrap bgColor={'#270936'} >
        <div className='inner'>
          <ul>
            <li><a href="#part1">{t('topikTab.target.part1')}</a></li>
            <li><a href="#part2">{t('topikTab.target.part2')}</a></li>
            <li><a href="#part3">{t('topikTab.target.part3')}</a></li>
            <li><a href="#part4">{t('topikTab.target.part4')}</a></li>
          </ul>
        </div>
      </Styled.TargetWrap>

      <div className='gift1' id="part1">
        <div className='inner'>
          <div className='titleWrap'>
            <span className='label'>GIFT 1</span>
            <h2 className='title'>{t('topikTab.gift1.title')}</h2>
            <p className='desc'><Trans t={t}>topikTab.gift1.desc</Trans></p>
          </div>
          <ul className='videoList'>
            {
              videoList.map((video)=>{
                return (<li>
                  <VideoItem src={video}/>
                </li>);
              })
            }
          </ul>
          <div className='video'>
            <VideoItem src='cVf6YbpWnI0' />
          </div>
          <Styled.ButtonWrap>
            <Styled.ArrowButton bgColor={'#fff'} color={'#1a1265'}>
              <Link to={"/courses"}><Trans t={t}>topikTab.gift1.button</Trans></Link>
            </Styled.ArrowButton>
            <Styled.ArrowButton bgColor={'#ffaf40'} color={'#1a1265'}>
              <Link to={"/plan"} onClick={checkLogin}><Trans t={t}>topikTab.gift1.button2</Trans></Link>
            </Styled.ArrowButton>
          </Styled.ButtonWrap>
        </div>
      </div>

      <div className='gift2' id="part2">
        <div className='inner'>
          <div className='titleWrap'>
            <span className='label'>GIFT 2</span>
            <h2 className='title'>{t('topikTab.gift2.title')}</h2>
            <p className='desc'><Trans t={t}>topikTab.gift2.desc</Trans></p>
          </div>
          <div className='img'>
            <img src={imgGift2} alt="" />
          </div>

          <Styled.ButtonWrap>
            <Styled.ArrowButton bgColor={'#29406c'} color={'#fff100'}>
              <Link to={"/courses/topik-mock-test"} onClick={checkLogin}><Trans t={t}>topikTab.gift2.button</Trans></Link>
            </Styled.ArrowButton>
          </Styled.ButtonWrap>
        </div>
      </div>

      <div className='gift3' id="part3">
        <div className='inner'>
          <div className='titleWrap'>
            <span className='label'>GIFT 3</span>
            <h2 className='title'>{t('topikTab.gift3.title')}</h2>
            <p className='desc'><Trans t={t}>topikTab.gift3.desc</Trans></p>
          </div>
          <div className='img'>
            <img src={imgGift3} alt="" />
          </div>

          <Styled.ButtonWrap>
            <Styled.ArrowButton bgColor={'#3f3284'} color={'#ffffff'}>
              <Link to={"/level-test"} onClick={checkLogin}><Trans t={t}>topikTab.gift3.button</Trans></Link>
            </Styled.ArrowButton>
          </Styled.ButtonWrap>
        </div>
      </div>

      <div className='gift4' id="part4">
        <div className='inner'>
          <div className='titleWrap'>
            <span className='label'>GIFT 4</span>
            <h2 className='title'>{t('topikTab.gift4.title')}</h2>
            <p className='desc'><Trans t={t}>topikTab.gift4.desc</Trans></p>
          </div>
          <div className='img'>
            <img src={imgGift4} alt="" />
          </div>

          <Styled.ButtonWrap>
            <Styled.ArrowButton bgColor={'#3f5fb4'} color={'#ffffff'}>
              <a href="#!" onClick={(e)=>openLinkAfterLogin(e,"https://www.mastertopik.com/learning-material/detail/758")}><Trans t={t}>topikTab.gift4.button</Trans></a>
            </Styled.ArrowButton>
          </Styled.ButtonWrap>
        </div>
      </div>

      <div className='notification'>
        <div className='inner'>
          <h3>{t('topikTab.notification.title')}</h3>
          <ul>
            <li>{t('topikTab.notification.desc')}</li>
            <li>{t('topikTab.notification.desc2')}</li>
            <li>{t('topikTab.notification.desc3')}</li>
            <li>
              <Trans t={t}>topikTab.notification.desc4</Trans>
              <a href="#!" onClick={(e)=>{e.preventDefault(); openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4412786168467");}}>{t('topikTab.notification.desc4-2')}</a>{t('topikTab.notification.desc4-3')}
            </li>
          </ul>
        </div>
      </div>
		</Styled.TopikTab>
  );
};

export default EventPage;
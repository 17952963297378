import React, {
  useRef,
  useEffect,
  useCallback,
  useContext,
  useState,
} from 'react';
import Style from 'container/MockTestPage/styled/TestWritePaper.style';
import PreviewImage from './PreviewImage';
import { MockTestContext } from '../index';
import { useAxios } from 'common/hook/useAxiosHook';
import mockTestApi from 'api/mockTests';
import { useSelector } from 'react-redux';

const FileUploadContainer = ({ data }) => {
  const { productId, unitValuationId, writingTestAttachFilesResponses } = data;
  const token = useSelector((state) => state.user.token);

  const [{ data: uploadData }, refreshUploadFile] = useAxios(
    mockTestApi.uploadWritingFile,
    [],
    true
  );

  const [{ data: deleteFileData }, deleteFile] = useAxios(
    mockTestApi.deleteWritingFile,
    [],
    true
  );
  const { mockTestStore, setMockTestStore } = useContext(MockTestContext);

  //불러온 이미지
  const [updatedFile, setUpdateFile] = useState([
    ...writingTestAttachFilesResponses,
  ]);

  useEffect(() => {
    if (!uploadData) return;
    setUpdateFile((prev) => [...prev, { ...uploadData }]);
  }, [uploadData]);

  //추가되는 이미지
  // const [previewUrl, setPreviewUrl] = useState([]);
  const inputRef = useRef();

  const clickInput = () => {
    //이미지 업로드 2개이상이면 안되게 막음
    // if (previewUrl.length + updatedFile.length > 1) return;
    if (updatedFile.length > 1) return;
    inputRef.current.click();
  };
  // console.log(updatedFile);

  const uploadFile = (e) => {
    e.preventDefault();
    const maxSize = 1 * 1024 * 1024;

    if (!e.target.files[0]) return;
    //파일 사이즈 제어
    if (e.target.files[0].size > maxSize) {
      return;
    }

    //preview 보여주기

    // let reader = new FileReader();
    // let file = e.target.files[0];
    // reader.onloadend = () => {
    //   setUpdateFile((prev) => [
    //     ...prev,
    //     {
    //       fileUrl: reader.result,
    //     },
    //   ]);
    // };
    // reader.readAsDataURL(file);
    refreshUploadFile({
      token,
      productId,
      unitValuationId,
      fileData: e.target.files[0],
    });
  };

  const deleteUpdatedFile = (id, index) => {
    if (id) {
      deleteFile({ token: token, id: id });
    }

    setUpdateFile((prev) => {
      prev.splice(index, 1);
      return prev;
    });
  };

  return (
    <>
      <Style.FileInputContainer>
        <input
          style={{ display: 'none' }}
          type='file'
          ref={inputRef}
          accept='image/jpg, image/jpeg, image/png, image/pdf'
          onChange={(e) => uploadFile(e)}
        />
        <label onClick={clickInput}>파일 첨부하기</label>
        <span>
          * 파일은 최대 2개까지만 첨부 가능합니다. (jpg, jpeg, png, pdf 파일만
          첨부 가능)
        </span>
        <Style.PreviewContainer>
          {
            //불러온 이미지 파일
            updatedFile.length > 0 &&
              updatedFile.map((data, index) => {
                return (
                  <PreviewImage
                    key={index}
                    data={data.fileUrl}
                    deletePreview={() =>
                      deleteUpdatedFile(
                        data?.answerAttachFilesIdx
                          ? data.answerAttachFilesIdx
                          : null,
                        index
                      )
                    }
                  />
                );
              })
          }
        </Style.PreviewContainer>
      </Style.FileInputContainer>
    </>
  );
};

export default FileUploadContainer;

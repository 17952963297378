import React from 'react';

export const termsOfPrivacyJa = () => (
  <div class='policy'>
    <h3 class='policy-title'>個人情報処理方針</h3>

    <p>
      株式会社VISANG
      EDUCATION(以下、「会社」という)は、利用者の同意を基に個人情報を収集·利用及び提供しております。会社は、『個人情報保護法』と『情報通信網の利用促進及び情報保護等に関する法律』などすべての関連法規を遵守し、会員様の個人情報が保護されるように最善を尽くしております。
    </p>

    <p>
      本個人情報処理方針では、masterTOPIKサービスに関連して、会社が利用者からご提供いただいた個人情報をどのような用途や方式により利用し、個人情報保護のためにどのような方法を通じて管理しているかをご説明致します。
    </p>

    <p>
      会社の個人情報保護政策は、国内法令及び指針の変更、会社の政策変化によって変更されることがあります。
    </p>

    <dl class='policy-subject'>
      <dt>· 目次: </dt>
      <dd>第1条 個人情報の利用目的</dd>
      <dd>第2条 個人情報の収集項目</dd>
      <dd>第3条 個人情報の共有及び提供</dd>
      <dd>第4条 個人情報の処理の委託</dd>
      <dd>第5条 個人情報の保有及び利用期間</dd>
      <dd>第6条 個人情報の破棄手順及び方法</dd>
      <dd>第7条 利用者及び法定代理人の権利とその行使方法</dd>
      <dd>第8条 個人情報の自動収集装置の設置/運営及びその拒否に関する事項</dd>
      <dd>第9条 個人情報の技術的/管理的保護対策</dd>
      <dd>第10条 個人情報保護責任者及び苦情サービス</dd>
      <dd>第11条 個人情報処理方針の改定及びお知らせ</dd>
    </dl>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第1条 個人情報の利用目的</h4>

      <p>
        会社は会員管理、サービスの提供·開発及び向上など下記の目的でのみ個人情報を利用します。マーケティング及び広告に活用する場合は別途の同意を得ており、同意を拒否してもサービスの利用に制限はありません。
      </p>

      <p class='policy-tit'>(1) 会員管理</p>
      <p>
        {' '}
        本人確認、不良会員(masterTOPIK利用約款第7条の違反事由により永久利用が停止された会員)の不正利用防止と非認可使用防止、加入意思の確認、紛争調整のための記録保存、苦情処理、お知らせの伝達
      </p>

      <p class='policy-tit'>
        (2) サービス提供に関する契約履行及びサービス提供による料金精算
      </p>
      <p>
        財貨及びコンテンツの提供、カスタマイズサービスの提供、請求書及び購入履歴の送信、機器間同期化サービスの提供、本人認証、購入及び料金決済、物品配送など
      </p>

      <p class='policy-tit'>(3) 新規サービスの開発及びマーケティングへの活用</p>
      <p>
        新規サービスの開発、統計学的特性、利用形態などによるカスタマイズサービスの提供、広告掲載、アクセス頻度の把握、会員のサービス利用に関する統計
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第2条 個人情報の収集項目</h4>

      <p>
        会社は会員登録、サービスの申し込み及び提供、円滑な顧客相談などのために、次のような個人情報を収集しています。情報を提供しないか、又は削除を要請する場合、masterTOPIKサービスの利用が制限されることがあります。
      </p>

      <div class='policy-sec'>
        <h5 class='policy-sec-tit'>1. 会員登録時</h5>
        <p>
          会員登録時、下記のような個人情報を収集しており、次の情報については関連法令(masterTOPIK
          個人情報処理方針第5条の保有期間)に基づき、明示された期間の間に利用及び保管されます。
        </p>

        <p class='policy-tit'>(1) 電子メールを通じた会員登録</p>
        <ul class='policy-bu'>
          <li>〮 必須情報: 氏名、電子メールアドレス、パスワード、国籍</li>
          <li>
            〮 選択情報:
            ニックネーム、生年月日、性別、マーケティング情報の受信同意
          </li>
        </ul>

        <p class='policy-tit'>(2) SNSを通じた会員登録</p>
        <ul class='policy-bu'>
          <li>〮 必須情報: SNSプロフィール、電子メールアドレス</li>
          <li>
            〮 選択情報:
            ニックネーム、生年月日、性別、国籍、パスワード、マーケティング情報の受信同意
          </li>
        </ul>
      </div>

      <div class='policy-sec'>
        <h5 class='policy-sec-tit'>2. 自動収集する項目</h5>
        <p>
          会社は利用者がmasterTOPIKサービスを利用するとき、「クッキー(Cookie)」を含む下記の情報を収集します。
        </p>

        <p class='policy-tit'>(1)　ウェブサイト</p>
        <p>
          ウェブサイト利用者のIPアドレス、ブラウザタイプ、ブラウザ言語、運営体制、ソフトウェア及びハードウェアの属性(装置IDを含む)参照、終了ページ及びURL、クリック数、照会したページ及び照会手順、利用日及び時間、視聴したコンテンツ、総視聴時間、エラーログ及びウェブサイトの使用方法に関するその他類似した情報を収集します。
        </p>

        <p class='policy-tit'>(2) モバイルアプリケーション</p>
        <p>
          masterTOPIKモバイルアプリケーション利用者のIPアドレス、運営体制、バージョン、インターネットサービス提供業者を含め、アプリへのアクセスに使用される携帯電話、タブレット又はコンピュータに関する特定情報、ブラウザタイプ、
          SSAID、FCMトークン、ドメイン名及びその他類似した情報、アプリアップデートの有無及び時期、利用日及び時間、視聴したコンテンツ、総視聴時間、エラーログ及びアプリの使用方法に関するその他類似した情報を自動で受信します。
        </p>

        <p class='policy-tit'>(3) 位置情報</p>
        <p>
          利用者のコンピュータ又はモバイル装置から一般位置情報(例:
          IPアドレス、都市∙州及び/又はIPアドレスに関する郵便番号)を自動で収集することができます。
        </p>
      </div>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第3条 個人情報の共有及び提供</h4>

      <p>
        会社は個人情報を収集、利用する目的以外に他の用途で利用したり、会員の同意なしに第三者に対してこれを提供しません。ただし、会員の同意があったり、下記の内容に該当する場合は例外とします。
      </p>

      <p>
        利用者は個人情報の第三者への提供に同意しないことがあり、いつでも提供に対する同意を撤回することができます。ただし、第三者への提供に基づく一部サービスの利用が制限されることがあります。
      </p>

      <ul class='policy-bu'>
        <li>〮 会員が事前に公開又は第三者への提供に同意した場合</li>
        <li>
          〮
          法令の規定に基づき、又は捜査目的で法令に定められた手続きと方法により捜査機関の要求がある場合
        </li>
        <li>〮 サービスの提供による料金精算のために必要な場合</li>
        <li>
          〮
          統計作成、学術研究又は市場調査のために必要な場合として、特定の個人を識別できない形で提供する場合
        </li>
        <li>
          〮
          情報通信サービスの提供に関する契約を履行するために必要な個人情報として経済的、技術的な事由により通常の同意を得ることが困難な場合
        </li>
        <li>
          〮
          営業の譲渡や合併などに関する事由が発生する場合(ただし、会社は営業の譲渡などに関する事由が発生し、利用者の個人情報の移転が必要な場合、関係法律に定められた手続きと方法により、個人情報の移転に関する事実などを事前に告知し、利用者には個人情報の移転に関する同意撤回権を付与します。)
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第4条 個人情報の処理の委託</h4>

      <p>
        会社は円滑で便利なサービスのために、最小限の制限された範囲で個人情報を外部の専門業者に委託して運営しています。委託契約の際に個人情報が安全に管理できるよう、関連事項を別途規定しています。
      </p>

      <p class='policy-tit'>(1) 情報の国際移転</p>
      <p>
        会社は本政策に基づき、サービス利用のために利用者の居住国内外で個人情報を転送、保存及び処理し、特に「大韓民国」へ移転されます。利用者の情報が転送される国の情報保護及びその他の法律は、利用者の居住国の法律より包括的でないことがあります。
      </p>

      <p class='policy-tit'>(2) 決済</p>
      <ul class='policy-bu'>
        <li>〮 受託業者: Payletter Inc.、Paypal</li>
        <li>〮 委託業務: 決済、購入安全サービスの提供</li>
        <li>〮 収集項目: カード会社名、カード番号など</li>
        <li>〮 保有及び利用期間: 会員退会及び委託契約の満了時まで</li>
      </ul>

      <p class='policy-tit'>(3) 配送</p>
      <ul class='policy-bu'>
        <li>〮 受託業者: Hanjin Inc</li>
        <li>〮 委託業務: 商品の配送</li>
        <li>〮 収集項目: 氏名、配送先の住所、携帯電話番号など</li>
        <li>〮 保有及び利用期間: 目的が達成できれば直ちに破棄</li>
      </ul>

      <p class='policy-tit'>(4) 認証</p>
      <ul class='policy-bu'>
        <li>〮 受託業者: Payletter Inc.</li>
        <li>〮 委託業務: 本人認証</li>
        <li>〮 収集項目: 氏名、携帯電話番号など</li>
        <li>〮 保有及び利用期間: 目的が達成できれば直ちに破棄</li>
      </ul>

      <p class='policy-tit'>(5) お客様からのお問い合わせ</p>
      <ul class='policy-bu'>
        <li>〮 受託業者: Zendesk</li>
        <li>〮 移転される国: アメリカ</li>
        <li>〮 委託業務: お客様からのお問い合わせ及び案内管理</li>
        <li>
          〮 保有及び利用期間:
          消費者の不満又は紛争処理に関する記録保持方針に従う
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第5条 個人情報の保有及び利用期間</h4>

      <p>
        会社は、原則として利用者の個人情報を会員退会時に遅滞なく破棄します。ただし、次の情報については関連法令に基づき、明示した期間の間、別途に分離保管され、明示した期間が経過した個人情報は遅滞なく破棄します。
      </p>

      <p>
        退会完了したアカウントのIDは永久的に再利用が不可能であり、再登録を制限するためのID及び電子メールの重複有無の検査は、一方向暗号化されて復元できない固有値を対照する方式が使用されます。
      </p>

      <p class='policy-tit'>(1) 会員退会時</p>
      <ul class='policy-bu'>
        <li>
          〮 保存根拠:
          不良利用者の再登録防止、名誉毀損などの権利侵害紛争及び捜査協力
        </li>
        <li>〮 保存期間: 会員退会後1年</li>
      </ul>

      <p class='policy-tit'>(2) 商取引履歴</p>
      <ul class='policy-bu'>
        <li>〮 保存根拠: 商法、電子商取引等における消費者保護に関する法律</li>
        <li>
          〮 保存期間:
          <table class='policy-tbl'>
            <tbody>
              <tr>
                <th>契約又は申込撤回などに関する記録</th>
                <td>5年(電子商取引等における消費者保護に関する法律)</td>
              </tr>
              <tr>
                <th>代金決済及び財貨などの供給に関する記録</th>
                <td>5年(電子商取引等における消費者保護に関する法律)</td>
              </tr>
              <tr>
                <th>消費者の不満又は紛争処理に関する記録 </th>
                <td>3年(電子商取引等における消費者保護に関する法律)</td>
              </tr>
              <tr>
                <th>電子金融取引に関する記録</th>
                <td>5年(電子金融取引法)</td>
              </tr>
              <tr>
                <th>表示、広告に関する記録</th>
                <td>6ヶ月(電子商取引等における消費者保護に関する法律)</td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>

      <p class='policy-tit'>(3) ウェブサイトへの訪問記録</p>
      <ul class='policy-bu'>
        <li>〮 保存根拠: 通信秘密保護法</li>
        <li>〮 保存期間: 3ヶ月</li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第6条 個人情報の破棄手順及び方法</h4>

      <p>
        会社は、原則として個人情報の収集及び利用目的が達成された場合には、遅滞なく該当情報を破棄します。破棄手順及び方法は次のとおりです。
      </p>

      <p class='policy-tit'>(1) 破棄手順</p>
      <p>
        利用者が会員登録などのために入力した情報は、目的が達成された後、別途のDBに移され(紙の場合は別途の書類箱)、内部方針及びその他関連法令による情報保護事由に基づいて一定期間(個人情報の保有及び利用期間参照)保存された後に破棄されます。個人情報は法律による場合以外には他の目的で利用されません。
      </p>

      <p class='policy-tit'>(2) 破棄方法</p>
      <ul class='policy-bu'>
        <li>
          〮 紙の出力物形態: シュレッダーで破砕、もしくは焼却により破棄します。
        </li>
        <li>
          〮 電子的ファイル形態:
          記録を再生できない技術的方法を用いて削除します。
        </li>
      </ul>

      <p class='policy-tit'>(3) 休眠アカウントの個人情報の破棄手順</p>
      <p>
        会社は「情報通信網の利用促進及び情報保護等に関する法律第29条同法施行令第16条」に基づき、masterTOPIKサービスに1年以上ログインしていないアカウントは、個人情報保護のために該当会員の個人情報を休眠アカウントに転換及び別途管理します。
      </p>
      <ul class='policy-bu'>
        <li>
          〮 休眠処理される会員情報:
          会員登録時、又は会員情報の修正により収集/管理されるすべての情報
        </li>
        <li>
          〮
          会社は休眠アカウントの個人情報の分離保存期間が到来する1ヶ月前に電子メールなどを通じて該当利用者に関連内容をお知らせします。
        </li>
        <li>
          〮
          休眠処理されたアカウントの個人情報は、分離保存開始から3年が経過すると遅滞なく破棄します。
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        第7条 利用者及び法定代理人の権利とその行使方法
      </h4>

      <p class='policy-tit'>(1) 利用者の権利</p>
      <ul class='policy-bu'>
        <li>
          〮
          利用者はいつでも登録されている自分の個人情報を照会又は修正することができ、加入解約を要請することもできます。
        </li>
        <li>
          〮
          個人情報を照会、修正するためには「情報変更」を通じて直接閲覧又は訂正することができ、加入解約(同意撤回)を行うためには顧客センターの「1:1お問い合わせ」又は「mastertopik@visang.com」に退会の意思を記載して送っていただければ退会することができます。
        </li>
        <li>
          〮
          個人情報保護責任者に書面、電話又は電子メールにてご連絡いただければ遅滞なく措置します。
        </li>
        <li>
          〮
          個人情報の誤りに対する訂正を要請した場合には、訂正が完了するまで当該個人情報を利用又は提供しません。また、誤った個人情報を第三者に既に提供した場合には、訂正処理の結果を第三者に遅滞なく通知して訂正が行われるようにします。
        </li>
        <li>
          〮
          会社は他人の個人情報を盗用して会員登録などをしたことを知り得たとき、遅滞なく該当IDに対するサービスの利用停止又は会員退会など必要な措置をとります。また、自分の個人情報の盗用を認知した利用者が該当IDに対するサービスの利用停止又は会員退会を要求する場合にも、会社は直ちに措置をとります。
        </li>
      </ul>

      <p class='policy-tit'>(2) 利用者の義務</p>
      <ul class='policy-bu'>
        <li>
          〮
          利用者は個人情報を最新の状態で正確に入力し、不意の事故を予防してください。利用者が入力した不正確な情報により発生する事故の責任は利用者本人にあり、他人の情報を盗用したり虚偽の情報を入力する場合、会員資格を失うことがあります。
        </li>
        <li>
          〮
          利用者は自らを保護し、他人の情報を侵害しない義務を持っています。パスワードを含む個人情報が流出しないように注意し、他人の個人情報と掲示物を毀損しないようにご注意ください。
        </li>
        <li>
          〮
          このような責任を果たさず、他人の情報及び尊厳性を毀損した場合、「情報通信網の利用促進及び情報保護等に関する法律」によって処罰されることがあります。
        </li>
      </ul>

      <p class='policy-tit'>(3) 児童の個人情報保護 </p>
      <p>
        会社のサービスは14歳未満の子どもではなく、一般の利用者を対象とします。本サービスがミスなどにより14歳未満の子ども情報を収集したという事実を知り得た場合、有効な親の同意なしに情報を削除します。会社は、親の同意なしに16歳未満のEU居住者データを故意に処理しません。親の同意なしに16歳未満のEU居住者から情報を収集した場合、遅滞なく合理的な措置をとった後、情報を削除します。なお、該当地域の法律に基づき、その他の年齢制限及び要求事項を遵守します。
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        第8条 個人情報の自動収集装置の設置/運営及びその拒否に関する事項
      </h4>

      <p>
        会社は個人化され、カスタマイズされたサービスを提供するために、利用者の情報を保存して読み込む「クッキー(Cookie)」を使用します。「クッキー(Cookie)」」は、ウェブサイトを運営するために利用されるサーバーが利用者のブラウザに送信する小さなテキストファイルで、利用者のハードディスクに保存されます。
      </p>

      <p class='policy-tit'>(1) 会社のクッキーの使用目的</p>
      <ul class='policy-bu'>
        <li>〮 各サービスとサイトに対するアクセス頻度やサイトなどを分析</li>
        <li>〮 利用者の好みと関心分野の把握及び跡の追跡</li>
        <li>
          〮
          各サービスとウェブサイトに対する訪問及び利用形態、人気検索ワード、セキュリティ接続の有無、利用者の規模などを把握
        </li>
        <li>
          〮
          各種イベントへの参加程度や訪問回数の把握などを通じたマーケティング及び個人に合わせたサービスを提供
        </li>
      </ul>

      <p class='policy-tit'>(2) クッキーの設置/運営及び拒否</p>
      <p>
        利用者はクッキーの設置に対する選択権を持っています。従って、利用者はウェブブラウザでオプションを設定し、すべてのクッキーを許可したり、クッキーが保存されるたびに確認を経たり、あるいはすべてのクッキーの保存を拒否することができます。ただし、クッキーの保存を拒否する場合には、ログインが必要な一部サービスの利用が難しい場合があります。
      </p>
      <ul class='policy-bu'>
        <li>
          〮 Internet Explorer: ウェブブラウザ上段のツール &gt;
          インターネットオプション &gt; 個人情報 &gt; 設定
        </li>
        <li>
          〮 Google Chrome: ウェブブラウザ右側の設定メニュー &gt;
          画面下段の高級設定表示 &gt; 個人情報のコンテンツ設定ボタン &gt;
          クッキー
        </li>
        <li>
          〮 Firefox: オプションメニュー選択 &gt; 個人情報選択 &gt;
          訪問記録-使用者定義設定 &gt; クッキーレベル設定
        </li>
        <li>
          〮 Safari: 環境設定メニュー選択 &gt; 個人情報タブ選択 &gt;
          クッキー及びウェブサイトのデータレベル設定
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第9条 個人情報の技術的/管理的保護対策</h4>

      <p>
        会社は利用者の個人情報を保護するために、次のような努力をしています。
      </p>

      <p class='policy-tit'>(1) 技術的保護対策</p>
      <ul class='policy-bu'>
        <li>
          〮 情報の暗号化:
          利用者の個人情報を暗号化して保管し、暗号化された通信区間を利用して転送します。
        </li>
        <li>
          〮 ワクチンプログラム運営:
          ハッキングやコンピュータウイルスから保護するために、ワクチンプログラムを設置した後、24時間監視しています。また、新しいハッキング/セキュリティ技術について持続的に研究し、サービスに適用しています。
        </li>
        <li>
          〮 ファイアウォール利用:
          外部からの無断アクセスを防止しており、その他システム的にセキュリティを確保するための技術的装置を備えようと努力しています。
        </li>
      </ul>

      <p class='policy-tit'>(2) 管理的保護対策</p>
      <ul class='policy-bu'>
        <li>
          〮 取扱職員の最小化:
          個人情報を処理する職員を最小化し、業務用PCからは外部のインターネットサービスを使用できないようにブロックして個人情報の漏えいに対するリスクを減らしています。なお、個人情報を処理するシステムに対するパスワードの生成と変更、またアクセスできる権限に対する体系的な基準を設け、持続的な監査を実施しています。
        </li>
        <li>
          〮 個人情報の取扱教育:
          役職員に利用者の個人情報保護に対して定期的な教育を実施しています。
        </li>
        <li>
          〮 個人情報保護のための専担組織運営:
          利用者の個人情報を保護し、疑問解決や不満処理のために次のとおり責任者を指定し運営しています。ただし、会員本人の不注意や会社が管理しない領域における事故など、会社の帰責に起因しない損害については、会社は責任を負いません。反面、毀損が誘発された場合、会員にその事実を伝え、適切な対策と補償を講じます。
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第10条 個人情報保護責任者及び苦情サービス</h4>

      <p class='policy-tit'>(1) 個人情報保護責任者及び連絡先</p>
      <p>
        会社は顧客の個人情報を保護し、個人情報に関する不満を処理するために、下記のとおり関連部署及び個人情報管理責任者を指定しています。
      </p>
      <ul class='policy-bu'>
        <li>〮 責任者: IT戦略Core イ·ジョンウCP</li>
        <li>·담당부서: 정보보안 Cell</li>
        <li>〮 電話: 1544-0554</li>
        <li>〮 お問い合わせ: privacy@visang.com</li>
      </ul>
      <p>
        その他、masterTOPIK
        CSチーム(mastertopik@visang.com)にお問い合わせいただければ、迅速かつ丁寧にご返答させていただきます。
      </p>

      <p class='policy-tit'>(2) 苦情サービス</p>
      <p>
        個人情報侵害に対する申告や相談が必要な場合には、下記の機関にお問い合わせください。
      </p>
      <ul class='policy-bu'>
        <li>
          <p>〮 個人情報侵害申告センター(韓国インターネット振興院運営)</p>
          <p>電話: (局番なし)118</p>
          <p>
            URL:{' '}
            <a
              href='http://privacy.kisa.or.kr'
              target='_blank'
              rel='noreferrer'
            >
              http://privacy.kisa.or.kr
            </a>
          </p>
        </li>
        <li>
          <p>〮 大検察庁サイバー犯罪捜査課</p>
          <p>電話: (局番なし)1301</p>
          <p>
            URL:{' '}
            <a href='http://www.spo.go.kr' target='_blank' rel='noreferrer'>
              http://www.spo.go.kr
            </a>
          </p>
        </li>
        <li>
          <p>〮 警察庁サイバー捜査局です</p>
          <p>電話: (局番なし)182</p>
          <p>
            URL:{' '}
            <a
              href='http://cyberbureau.police.go.kr'
              target='_blank'
              rel='noreferrer'
            >
              http://cyberbureau.police.go.kr
            </a>
          </p>
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第11条 個人情報処理方針の改定及びお知らせ</h4>

      <p>
        会社は法律、データ収集及び使用慣行が変更されたり、サービス機能又は技術反映のために、個人情報保護政策を修正する権利を有します。改定された個人情報保護政策はサービスを通じて確認することができ、利用者は定期的に個人情報保護政策を検討しなければなりません。文書の最後の部分に含まれた「公告/施行日」を確認し、最後の検討後に個人情報保護政策が変更されたかが分かります。会社は上記内容の追加、削除及び修正がある場合、少なくとも改定の7日前にホームページの「お知らせ」を通じて告知します。ただし、利用者の権利に重要な変更がある場合には、少なくとも30日前に告知します。
      </p>

      <p>
        <strong>公告日: 2021年 3月 2日</strong>
      </p>
      <p>
        <strong>施行日: 2021年 3月 22日</strong>
      </p>

      <p>
        サービスのご利用に際してご不明な点がございましたら、mastertopik@visang.comにお問い合わせください。
      </p>
    </div>
  </div>
);

import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import Styled from "./index.style";
import { priceFormat } from "common/util/util";
import ButtonLink, { ButtonTheme } from "component/atoms/ButtonLink";
import { RiCheckFill } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { useDispatch } from "react-redux";
import { deleteAllItem } from "container/BookStorePage/state/action";

const OrderCompleteForBooks = ({ orderData }) => {
  const { t } = useTranslation("paymentSuccessPage");

  const [order, setOrder] = useState(null);
  const [orderItemList, setOrderItemList] = useState(null);
  const [delivery, setDelivery] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setOrder(orderData);
    setOrderItemList(
      orderData.items.filter((item) => item.title !== "DELIVERY")
    );
    setDelivery(orderData.items.filter((item) => item.title === "DELIVERY")[0]);
  }, [orderData]);

  const priceFormatting = (price) =>
    order.isDollar ? priceFormat.usa(price) : priceFormat.korea(price);

  useEffect(() => {
    dispatch(deleteAllItem());
  }, []);

  return (
    <>
      {order && (
        <Styled.Container>
          <Styled.Left>
            <Styled.H3>{t("subtitle")}</Styled.H3>

            <Styled.Icon>
              <RiCheckFill />
            </Styled.Icon>

            <Styled.Bold>
              <Moment format="YYYY/MM/DD HH:mm">{order.orderedAt}</Moment>
              <br />
              {order.deliveryAddress.receiptAddress1}{" "}
              {order.deliveryAddress.receiptAddress2}
            </Styled.Bold>

            <Styled.Blue>{t("label3")}</Styled.Blue>
            {orderItemList.map((item) => (
              <p>
                {item.title}{" "}
                <span style={{ marginLeft: 10 }}>
                  {priceFormatting(item.unitPrice * item.qty)}
                </span>
              </p>
            ))}
          </Styled.Left>

          <Styled.Right>
            <Styled.Blue style={{ marginBottom: 5 }}>{t("label1")}</Styled.Blue>
            <p>
              <b>
                {order.paymentMethod}{" "}
                {order.statusType !== 1 && " (결제 대기 중)"}
              </b>
            </p>

            <Styled.Blue>{t("label2")}</Styled.Blue>
            <Styled.Table>
              <thead>
                <tr>
                  <th>{t("label3")}</th>
                  <th style={{ textAlign: "center" }}>{t("label4")}</th>
                  <th>{t("label-item")}</th>
                </tr>
              </thead>
              <tbody>
                {orderItemList.map((item) => (
                  <tr>
                    <td>{item.title}</td>
                    <td style={{ textAlign: "center" }}>{item.qty}</td>
                    <td>{priceFormatting(item.unitPrice)}</td>
                  </tr>
                ))}
              </tbody>
            </Styled.Table>

            <Styled.Hr />

            <Styled.Table>
              <tbody>
                <tr>
                  <th>{t("label-item")}</th>
                  <td>
                    {priceFormatting(
                      orderItemList.reduce((acc, item) => {
                        return item.unitPrice
                          ? acc + item.unitPrice * item.qty
                          : acc + 0;
                      }, 0)
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{t("label-discount")}</th>
                  <td>
                    {priceFormatting(
                      orderItemList.reduce((acc, item) => {
                        return item.discountPrice
                          ? acc + item.discountPrice
                          : acc + 0;
                      }, 0)
                    )}
                  </td>
                </tr>
                <tr>
                  <th>{t("label-fee")}</th>
                  <td>
                    {priceFormatting(
                      delivery?.unitPrice ? delivery.unitPrice : 0
                    )}
                  </td>
                </tr>
              </tbody>
            </Styled.Table>

            <Styled.Hr />

            <Styled.Table>
              <tfoot>
                <tr>
                  <td>{t("label-total")}</td>
                  <td>
                    {priceFormatting(
                      orderData.items.reduce((acc, item) => {
                        if (item.unitPrice && item.discountPrice) {
                          return item.unitPrice
                            ? acc +
                                item.unitPrice * item.qty -
                                item.discountPrice
                            : acc + 0;
                        } else {
                          return item.unitPrice
                            ? acc + item.unitPrice * item.qty
                            : acc + 0;
                        }
                      }, 0)
                    )}
                  </td>
                </tr>
              </tfoot>
            </Styled.Table>
          </Styled.Right>
        </Styled.Container>
      )}

      <Styled.ButtonContainer>
        <ThemeProvider theme={ButtonTheme.primary.filled}>
          <ButtonLink to="/">MY PAGE</ButtonLink>
        </ThemeProvider>
        <ButtonLink to="/setting">SETTINGS</ButtonLink>
      </Styled.ButtonContainer>
    </>
  );
};

export default OrderCompleteForBooks;

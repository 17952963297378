import React, { useState } from 'react';
import Style from './styled/VerificationModal.style';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import userApi from 'api/user';
import { Trans, useTranslation } from 'react-i18next';

const VerificationModal = () => {
  const { t } = useTranslation('verifyEmailModal');
  const [showMessage, setShowMessage] = useState(false);
  const [, fetchFindPassword] = useAxios(userApi.findPassword, [], true);
  const email = useSelector((state) => state.user.data.userID);

  const sendEmail = () => {
    setShowMessage(true);
    fetchFindPassword(email);
  };
  return (
    <Style.Container>
      <Style.Title>
        <Trans t={t}>modal1.title</Trans>
      </Style.Title>

      <Style.Desc>
        <Trans t={t} values={{ emailAdd: email }}>
          modal1.notice1
        </Trans>
      </Style.Desc>

      <Style.Desc>
        <Trans t={t}>modal1.notice2</Trans>
      </Style.Desc>
      {!showMessage && (
        <Style.Button onClick={() => sendEmail()}>
          <Trans t={t}>modal1.submit</Trans>
        </Style.Button>
      )}
      {showMessage && (
        <Style.Message>
          <Trans t={t}>modal1.alert</Trans>
        </Style.Message>
      )}
    </Style.Container>
  );
};

export default VerificationModal;

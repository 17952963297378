import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import LikeIcon from 'component/atoms/Icon/LikeIcon';
import communityApi from 'api/community';
import { LoadingIcon } from 'component/atoms/Loading/index.style';

const LikeButton = styled(LikeIcon)`
  font-weight: 300;
  color: #8e9199;
  cursor: pointer;
`;
const StyledLoading = styled(LoadingIcon)`
  font-size: 2px;
  margin: 0;
`;

/**
 * 게시글 좋아요 & 좋아요 취소 기능 포함
 * @param {*} param
 * @param {number} param.id
 * @param {number} param.count
 * @param {boolean} param.isGood
 */
const CommunityLikeHandling = ({ id, count, isGood = false }) => {
  const [isLike, setIsLike] = useState(isGood);
  const [likeCount, setLikeCount] = useState(count);

  const token = useSelector((state) => state.user.token);
  const [postLike, fetchPostLike] = useAxios(communityApi.postLike, [], true);
  const [deleteLike, fetchDeleteLike] = useAxios(
    communityApi.deleteLike,
    [],
    true
  );

  const {
    loading: postLoading,
    data: postLikeCount,
    error: postError,
  } = postLike;
  const {
    loading: deleteLoading,
    data: deleteLikeCount,
    error: deleteError,
  } = deleteLike;

  const handleLike = useCallback(() => {
    if (!postLoading && !deleteLoading) {
      isLike ? fetchDeleteLike(id, token) : fetchPostLike(id, token);
    }
  }, [isLike]);

  // 좋아요 완료 후
  useEffect(() => {
    if (postLikeCount !== null) {
      setIsLike(true);
      setLikeCount(postLikeCount);
    }
  }, [postLikeCount]);

  // 좋아요 취소 후
  useEffect(() => {
    if (deleteLikeCount !== null) {
      setIsLike(false);
      setLikeCount(deleteLikeCount);
    }
  }, [deleteLikeCount]);

  if (id === null || id === undefined) return <LikeIcon>{count}</LikeIcon>;

  return (
    <LikeButton className={isLike && 'liked'} onClick={handleLike}>
      {postLoading && deleteLoading && <StyledLoading />}
      {likeCount}
    </LikeButton>
  );
};

CommunityLikeHandling.propTypes = {
  id: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  isGood: PropTypes.bool.isRequired,
};

export default CommunityLikeHandling;

import styled from "styled-components";
import { IconTextStyles } from "component/atoms/Icon";
import iconVisible from "assets/images/ico_lock_open.png";
import iconVisibleLarge from "assets/images/ico_lock_open_large.png";
import iconHidden from "assets/images/ico_lock.png";
import iconHiddenLarge from "assets/images/ico_lock_large.png";

const VisibleIcon = styled.span`
  ${IconTextStyles};
  padding: 0;
  width: 1.5em;
  height: 1.5em;
  background-image: url(${iconVisible});
  &.hidden-icon {
    background-image: url(${iconHidden});
  }
  @media (max-width: 1024px) {
    background-image: url(${iconVisibleLarge});
    background-size: 12px auto;
    &.hidden-icon {
      background-image: url(${iconHiddenLarge});
    }
  }
`;

export default VisibleIcon;

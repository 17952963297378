import React from "react";
import Styled from "./index.style";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import thumbnailDefault from "assets/images/thumb_default02.jpg";
import CollectedThumbnail from "component/atoms/CollectedThumbnail";
import CountIconVideo from "component/atoms/Icon/CountIconVideo";
import CountIconLike from "component/atoms/Icon/CountIconLike";
import UserThumbnail from "component/atoms/UserThumbnail";
import { TagLink } from "component/atoms/TagLink";
import VideoIcon from "component/atoms/Icon/VideoIcon";
import UserThumbImg from "component/atoms/Img/UserThumb";
import { masterTopikUrl } from "common/constant";
import TripleThumb from "component/atoms/TripleThumb";
import styled from "styled-components";
import ThemeLike from "component/molecules/ThemeLike";
import Img from "component/atoms/Img";

const Title = styled(Styled.Title)`
  margin: 0.5em 0 1em;
`;

/**
 * Theme list item - 제목 1줄, 부제목 1줄 형식. 여백 미포함
 * @param {*} param
 * @param {number} param.id 테마 id
 * @param {string=} param.detailLink 링크 주소 (기본값 : `/theme/${id}`)
 * @param {array} param.imgSrcList
 * @param {string} param.title 제목 1줄 초과 시, 말줄임표
 * @param {string} param.tag 제목 1줄 초과 시, 말줄임표
 * @param {string=} param.tagLink 테마 태그(카테고리) 상세 페이지 (기본값 : `/theme/tag/${tag}`)
 * @param {number} param.videoCount 비디오 갯수
 * @param {number} param.likeCount 좋아요 갯수
 * @param {string} param.userThumb 유저 썸네일
 * @param {string} param.userName
 * @param {boolean} param.isGood 유저의 좋아요 여부
 * @param {string=} param.className set wrapper className ( title 및 subtitle에도 접두사로 className 생성됨 )
 */
const UserThemeItem = ({
  id,
  detailLink,
  imgSrcList,
  title,
  tag,
  tagLink,
  videoCount,
  likeCount,
  userThumb,
  userName,
  isGood,
  className = "user-theme",
  userData = true,
  adminTheme = false,
}) => {
  return (
    <Styled.Wrapper className={className}>
      <Styled.Link to={detailLink ? detailLink : `/theme/${id}`}>
        {adminTheme ? (
          <span className="img-box">
            <Img src={imgSrcList} alt={title} onError={thumbnailDefault} />
          </span>
        ) : (
          <TripleThumb imgSrcList={imgSrcList} alt={title || ""} />
        )}

        <Title className={`${className}-title`}>{title}</Title>
      </Styled.Link>

      <Styled.Info className={`${className}-info`}>
        <TagLink to={tagLink ? tagLink : `/theme/tag/${tag}`}>#{tag}</TagLink>
        <Styled.InfoRight className={`${className}-info-right`}>
          <VideoIcon>{videoCount}</VideoIcon>
          <ThemeLike id={id} count={likeCount} isGood={isGood} />
        </Styled.InfoRight>
      </Styled.Info>
      {userData && (
        <Styled.User className={`${className}-user`}>
          <UserThumbImg src={masterTopikUrl + userThumb} />
          <Styled.UserName>{userName}</Styled.UserName>
        </Styled.User>
      )}
    </Styled.Wrapper>
  );
};

export default UserThemeItem;

import { asyncApi } from 'common/util/asyncApi';

const pathName = '/api/v1/my-classroom';

const classroomApi = {
  getWatches: async (props = { page: 0, token: null }) => {
    let response = await asyncApi({
      url: `${pathName}/courses?watches`,
      params: { page: props.page, size: 8 },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getComplete: async (props = { page: 0, token: null }) => {
    let response = await asyncApi({
      url: `${pathName}/courses?complete`,
      params: { page: props.page, size: 8 },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getCourseCount: async (token) => {
    let response = await asyncApi({
      url: `${pathName}/course-count`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getViewTime: async (token) => {
    let response = await asyncApi({
      url: `${pathName}/view-time`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getWatchedList: async (page, token) => {
    let response = await asyncApi({
      url: `${pathName}/courses`,
      params: { watches: '', page },
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
};

export default classroomApi;

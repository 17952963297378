import React from 'react';
import styled, { css } from 'styled-components';

const StyledRecommended = {
  Title: styled.h3`
    padding-top: 2.61em;
    color: #fff;
    font-size: 3.6em;
    font-weight: 700;
    @media (max-width: 1024px) {
      padding-top: 50px;
    }
  `,
  Lists: styled.ul`
    display:flex;
    gap:4rem;
    margin-top:3.5rem;
    margin-bottom:6rem;
    @media (max-width: 1024px) {
      flex-wrap: wrap;
      gap:24px;
    }
  `,
  List: styled.li`

    @media (max-width: 1024px) {
      width:calc(50% - 12px);
    }

    `,
  Thum: styled.div`
    img{
      width:100%;
    }
  `,
  ListH3: styled.h3`
    margin-top:2.5rem;
    font-size:2rem;
    color:#ffffff;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  slg: styled.p`
    color: #8e9199;;
    font-size:1.6rem;
    margin-top:0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  Button: styled.button`
    text-transform: uppercase;
    font-size:1.6rem;
    color:#ffffff;
    background:#485cc7;
    border-radius: 5px;;
    width:34rem;
    height:5.4rem;
    display: flex;
    align-items: center;
    justify-content: center;;
    margin:5rem auto;

    @media (max-width: 1024px) {
      margin:25px auto;
      width:90%;
      font-size:15px;
      height:50px;
    }


  `
}

export default StyledRecommended;

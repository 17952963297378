import React, { useEffect, useRef, useState } from 'react';
import Styled from './styled/FaqItem.style';

export default function FaqItem({ item, classActive, onClick }) {
  const content = useRef(0);
  const [scrollHeight, setScrollHeight] = useState(0);

  useEffect(()=>{
    if(classActive) setScrollHeight(content.current.scrollHeight);
  }, [classActive])

  return (
    <Styled.Item
      className={classActive}
      activeHeight={scrollHeight}
    >
      <Styled.H4 onClick={onClick}>
        {item.title}
        <Styled.Arrow />
      </Styled.H4>
      <Styled.Desc
        ref={content}
        dangerouslySetInnerHTML={{ __html: item.bodyAsHtml }}
      />
    </Styled.Item>
  );
}

export const MemoizedFaqItem = React.memo(FaqItem);

import styled from 'styled-components';
import gradientBottom from 'assets/images/gradient_bottom01.png';

const Card = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
  background-color: #0f1012;
  overflow: hidden;
  backface-visibility: hidden;
`;

const BackCard = styled(Card)`
  border: 3px solid #e2a000;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  transform: rotateY(180deg);
`;

export const StyledTeacherCard = {
  Wrapper: styled.div`
    cursor: pointer;
    perspective: 1000px;
    @media (max-width: 1024px) {
      font-size: 1.25em;
    }
  `,
  Container: styled.div`
    position: relative;
    width: 100%;
    padding-top: 140%;
    background-color: #0f1012;
    transition: transform 1s ease;
    transform-style: preserve-3d;

    &.safari {
      backface-visibility: hidden;
    }
    &:after {
      content: '';
      position: absolute;
      top: calc(100% - 0.2em);
      left: 50%;
      margin-left: -1.5em;
      border: 1.5em solid transparent;
      border-top: 0 solid #e2a000;
      transition: all 0.3s ease;
    }
  `,
  FrontCard: styled(Card).attrs({ className: 'front-card' })`
    &:hover * {
      text-decoration: underline;
    }
    &.safari {
      backface-visibility: hidden;
    }
    img {
      position: absolute;
      top: 0;
      left: 50%;
      width: 100%;
      min-height: 100%;
      transform: translateX(-50%);
      object-fit: cover;
    }

    @media (max-width: 767px) {
      font-size: 1.2em;
    }
  `,
  BackCard: styled(BackCard).attrs({ className: 'back-card' })`
    background-image: ${({ profileImage }) =>
      profileImage && `url('${profileImage}')`};

    &.safari {
      backface-visibility: hidden;
    }
  `,
  Texts: styled.div.attrs({ className: 'texts' })`
    position: absolute;
    bottom: -2px;
    left: -1px;
    z-index: 2;
    width: calc(100% + 4px);
    padding: 6rem 4rem 4rem;
    background: url(${gradientBottom}) repeat-x center bottom;
    background-size: auto 100%;
    opacity: 1;
    transition: opacity 0.3s ease 0.3s;
    @media (max-width: 767px) {
      padding: 4rem 2rem 2rem;
    }
  `,
  Name: styled.h3`
    font-size: 3.2em;
    font-weight: 500;
  `,
  Desc: styled.div`
    font-size: 1.8em;
  `,
  Slogan: styled.div`
    font-size: 1.6em;
    margin-top: 2em;
    color: #c9cbd1;
    word-break: break-word;
  `,
  Info: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 4em;
    background-color: rgba(15, 16, 18, 0.8);
    overflow: auto;
    h4 {
      font-size: 2.2em;
      margin-bottom: 0.5em;
      font-weight: 500;
    }
    p {
      font-size: 1.6em;
      margin-bottom: 2em;
      color: #c9cbd1;
      white-space: pre-line;
    }
  `,
};

export default StyledTeacherCard;
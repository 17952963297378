import styled from 'styled-components';

const StyledAsideHeader = {
  Title: styled.h2`
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 0.5em;
  `,
  TeacherName: styled.div`
    font-size: 1.4em;
    color: #8e9199;
  `,
};

export default StyledAsideHeader;

import React, { useEffect, useRef, useState } from 'react';
import Styled from './index.style';
import ReactPlayer from 'react-player';
import PlayerController from './PlayerController';
import PlayerControllerMobile from './PlayerControllerMobile';
import { isIOS, isMobile, isMobileOnly, isTablet } from 'react-device-detect';

export const PlayerContext = React.createContext();

/**
 * 비디오 플레이어 컴포넌트
 * @param {*} props
 * @param {string} props.url
 * @param {number=} props.lastPosition
 * @param {string=} props.title
 * @param {func=} props.setIsPlay
 * @param {func=} props.setIsEnded
 *
 * @param playerRef
 */
const VideoPlayer = React.forwardRef(
  (
    { url, lastPosition, setIsPlay, setIsEnded, title, videoQualityState },
    playerRef
  ) => {
    // Ref
    const wrapperRef = useRef(null);

    // initial Player State
    const [playerState, setPlayerState] = useState({
      playing: false,
      played: 0,
      seeking: false,
      duration: 0,
      playbackRate: 1.0,
      pip: false,
      volume: 1,
      muted: null,
      fullScreen: false,
    });
    const qualityList = ['1080P', '720P', '480P'];

    const handleState = (object) =>
      setPlayerState({ ...playerState, ...object });

    // 이어보기
    useEffect(() => {
      if (url && playerState.duration && playerRef.current) {
        // 마지막 시점이 95% 이상인 경우, 처음으로 되돌리기
        const played =
          lastPosition / playerState.duration > 0.95 ? 0 : lastPosition;

        handleState({ playing: true });
        playerRef.current.seekTo(played, 'seconds');
      }
    }, [url, playerState.duration, lastPosition]);

    const handlePlay = () => {
      handleState({ playing: true });
      setIsPlay && setIsPlay(true);
      setIsEnded && setIsEnded(false);
    };

    const handlePause = () => {
      handleState({ playing: false });
      setIsPlay && setIsPlay(false);
    };

    const handleDuration = (duration) => {
      handleState({ duration });
    };

    const handleEnded = () => setIsEnded && setIsEnded(true);

    const handleProgress = (state) => {
      if (!playerState.seeking) {
        handleState(state);
      }
    };

    // Context Store
    const playerStore = {
      playerState,
      handleState,
      wrapperRef,
      playerRef,
      playbackRateList: [2, 1.75, 1.5, 1.25, 1, 0.75, 0.5],
      qualityList,
      videoQualityState,
    };

    return (
      <PlayerContext.Provider value={playerStore}>
        <Styled.Wrapper className='player' ref={wrapperRef}>
          <ReactPlayer
            ref={playerRef}
            config={{
              file: {
                attributes: {
                  onContextMenu: (e) => e.preventDefault(),
                  controlsList: 'nodownload',
                },
              },
            }}
            url={url}
            playing={playerState.playing}
            playbackRate={playerState.playbackRate}
            pip={playerState.pip}
            controls={isIOS && isMobileOnly}
            volume={playerState.volume}
            muted={playerState.muted}
            onPlay={handlePlay}
            onPause={handlePause}
            onDuration={handleDuration}
            onProgress={handleProgress}
            onEnded={handleEnded}
            width='100%'
            height='100%'
          />

          <PlayerController />

          {/* 아이폰은 내장 플레이어 바 노출 (iphone ios는 가이드라인에 따라 내장 플레이어 바를 권장) */}
          {isIOS && !isTablet ? '' : <PlayerControllerMobile />}
        </Styled.Wrapper>
      </PlayerContext.Provider>
    );
  }
);

export default VideoPlayer;

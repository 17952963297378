import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import OrderBySelect from 'component/molecules/OrderBySelect';
import { useAxios } from 'common/hook/useAxiosHook';
import mockTestReportApi from 'api/mockTestReport';
import { useSelector } from 'react-redux';

const IndividualStackBarChart = ({ chartData, refreshGetData }) => {
  const token = useSelector((state) => state.user.token);
  const [{ data: testsData }] = useAxios(() => mockTestReportApi.getIndividualTests(token), []);

  const options = {
    offsetGridLines: true,
    drawTicks: false,
    layout: {
      padding: {
        top: 30,
        right: 40,
        bottom: 40,
      },
    },
    tooltips: {
      mode: 'index',
      yAlign: 'bottom',
      alignment: 'center',
      backgroundColor: '#1e2024',
      borderColor: '#5D6165',
      borderWidth: 1,
      callbacks: {
        // 툴팁 설정
        title: function (yAxis) {
          if (yAxis[0].index === 0) {
            return `총점수 ${chartData.totalScore}`
          }
          return `${yAxis[0].label} ${yAxis[yAxis[0].index - 1].value}`
        },
        label: function () {
          return;
        },
      },
    },
    legend: {
      display: false,
      position: 'top',
      align: 'end',
      labels: {
        usePointStyle: true,
        boxWidth: 7,
        boxHeight: 7,
        fontColor: '#fff',
        fontSize: 13,
        padding: 20,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: true,
          stacked: true,
          ticks: {
            padding: 5,
            fontColor: '#fff',
            fontSize: 14,
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          display: true,
          stacked: true,
          gridLines: {
            display: true,
            drawBorder: false,
            color: '#56585B',
            zeroLineColor: '#56585B',
          },
          categoryPercentage: 0.7,
          barPercentage: 0.9,

          ticks: {
            beginAtZero: true,
            padding: 20,
            fontColor: '#fff',
            fontSize: 14,
          },
        },
      ],
    },
  };

  let data;
  chartData.levelType === 11 ?
    data = chartData && {
      // x axis 텍스트
      labels: ["총점수", "읽기", "듣기"],

      // bar graph data => Y axis bar 그래프 데이터
      read: { dataSet: [chartData.readingScore, chartData.readingScore, 0, 0] },

      listen: { dataSet: [chartData.listeningScore, 0, chartData.listeningScore, 0] },
    }
    :
    data = chartData && {
      // x axis 텍스트
      labels: ["총점수", "읽기", "쓰기", "듣기"],

      // bar graph data => Y axis bar 그래프 데이터
      read: { dataSet: [chartData.readingScore, chartData.readingScore, 0, 0] },

      write: { dataSet: [chartData.writingScore, 0, chartData.writingScore, 0] },

      listen: { dataSet: [chartData.listeningScore, 0, 0, chartData.listeningScore] },
    };



  //년도 select 박스 option으로 변경
  const dataOptions = testsData && testsData.map((data) => {
    return { label: data.title, value: data.productId };
  });
  const selectBoxChange = (e) => {
    refreshGetData(token, e.value);
  };

  if (chartData)
    return (
      <div className='chart-container'>
        <div className='select-container'>
        </div>
        <div className='chart'>
          {chartData.levelType === 11 ?
            <Bar
              pointStyle='star'
              height={410}
              width={50}
              data={{
                labels: data.labels,
                responsive: true,
                offset: true,
                datasets: [
                  {
                    label: '읽기',
                    pointStyle: 'rectRounded',
                    backgroundColor: '#00acb2',
                    barThickness: 20,
                    categoryPercentage: 1,
                    data: data.read.dataSet,
                  },
                  {
                    label: '듣기',
                    backgroundColor: '#80aa00',
                    barThickness: 20,
                    categoryPercentage: 1,
                    pointStyle: 'triangle',
                    data: data.listen.dataSet,
                  },
                ],
              }}
              options={options}
            />
            :
            <Bar
              pointStyle='star'
              height={410}
              width={50}
              data={{
                labels: data.labels,
                responsive: true,
                offset: true,
                datasets: [
                  {
                    label: '읽기',
                    pointStyle: 'rectRounded',
                    backgroundColor: '#00acb2',
                    barThickness: 20,
                    categoryPercentage: 1,
                    data: data.read.dataSet,
                  },
                  {
                    label: '쓰기',
                    backgroundColor: '#8b56e3',
                    barThickness: 20,
                    categoryPercentage: 1,
                    pointStyle: 'triangle',
                    data: data.write.dataSet,
                  },
                  {
                    label: '듣기',
                    backgroundColor: '#80aa00',
                    barThickness: 20,
                    categoryPercentage: 1,
                    pointStyle: 'triangle',
                    data: data.listen.dataSet,
                  },
                ],
              }}
              options={options}
            />}
        </div>
      </div>
    );

  return <></>;
};

export default IndividualStackBarChart;

import React from 'react';
// import { useSetRedirect } from 'common/hook/useRedirectHooks';
import { actions } from 'common/state';
import { useDispatch } from 'react-redux';

const useModal = () => {
  let [modal, setModal] = React.useState(false);
  let [modalContent, setModalContent] = React.useState('');
  let [closeByBackground, setCloseByBackground] = React.useState('');
  let [maxWidth, setMaxWidth] = React.useState();
  const dispatch = useDispatch();

  /**
   *
   * @param {*} param
   * @param {string | object} param.content
   * @param {boolean=} param.closeByBackground 배경 클릭 시, 팝업 닫힘 여부
   * @param {number=} param.maxWidth 팝업 넓이값 (기본 420px )
   */
  let handleModal = (
    content = false,
    closeByBackground = true,
    maxWidth = 420,
    redirectUrl
  ) => {
    if (redirectUrl) {
      dispatch(actions.setRedirect(redirectUrl));
    }
    if (content) {
      !modal && setModal(true);
      setModalContent(content);
      setCloseByBackground(closeByBackground);
      setMaxWidth(maxWidth);
    } else {
      modal && setModal(false);
    }
  };

  return { modal, handleModal, modalContent, closeByBackground, maxWidth };
};

export default useModal;

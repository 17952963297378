import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';

const StyledLibrarySelect = {
  SelectContainer: styled.span.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <IoIosArrowDown />
      </>
    ),
  }))`
    display: inline-block;
    position: relative;
    width: 13%;
    border-radius: 0.6rem;
    background-color: #1e2024;
    margin-right: 0.63%;
    svg {
      position: absolute;
      top: 50%;
      right: 5%;
      font-size: 2em;
      color: #838587;
      transform: translate(-50%, -50%);
    }

    &.library {
      width: 17em;
      margin-right: 0;
      @media (max-width: 767px) {
        width: 14em;
      }
    }
  `,
  Input: styled.input`
    display: inline-block;
    width: 100%;
    height: 3em;
    padding-left: 1em;
    border: none;
    background: transparent;
    color: #838587;
    font-size: 1.6em;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  `,
};

export default StyledLibrarySelect;

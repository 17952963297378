import styled from 'styled-components';

const StyledInvoicePage = {
  Title: styled.h3`
    margin-bottom: 1.52em;
    text-align: center;
    color: #fff;
    font-size: 3.6em;
    font-weight: 700;
  `,
  Container: styled.div`
    max-width: 85em;
    margin: 0 auto 5em;
    padding: 7.5em;
    border-radius: 0.6em;
    background: #fff;
    color: #0f1012;

    @media (max-width: 767px) {
      padding: 3.5em;
    }
  `,
  Info: styled.div`
    display: flex;

    margin-bottom: 1em;
    > span {
      flex: 0.2;
      color: #0f1012;
      font-size: 1.6em;
      font-weight: 400;
    }
    > span:last-of-type {
      flex: 0.8;
      margin-left: 3.125em;
    }
    .strong {
      font-weight: 700;
    }
  `,
  Table: styled.table`
    width: 100%;
    margin-top: 2.5em;
    margin-bottom: 1em;
    border-top: 1px #5c5f66 solid;
    tbody {
      tr {
        border-bottom: 1px #c9cbd1 solid;
        &:last-of-type {
          border-bottom: none;
        }
      }
      th {
        padding: 1.5em 1em;
        text-align: left;
        font-size: 1.6em;
        font-weight: 400;
        &:first-of-type {
          font-weight: 700;
        }
        &:last-of-type {
          text-align: right;
        }
      }
    }
    tfoot {
      border-top: 1px #5c5f66 solid;
      border-bottom: 1px #5c5f66 solid;

      th {
        padding: 1.5em;
        padding-left: 1.875em;
        text-align: left;
        font-size: 1.8em;
        font-weight: 700;
        text-transform: uppercase;

        &:last-of-type {
          text-align: right;
        }
      }
    }

    &.products {
      table-layout: fixed;
      tr {
        border-bottom: 1px #c9cbd1 solid !important;
      }
      tr:first-of-type th {
        font-weight: 400;
      }
      th {
        vertical-align: middle;
        text-align: center !important;
        font-weight: 700;
      }

      @media (max-width: 767px) {
        th {
          padding: 1em;
        }
      }
    }
  `,
  SubTitle: styled.h4`
    margin-top: 2.33em;
    margin-bottom: 0.8em;
    font-size: 2em;
    font-weight: 500;
  `,
  Desc: styled.p`
    margin-bottom: 0.3em;
    font-size: 1.6em;
    font-weight: 400;
    &.email {
      font-weight: 500;
    }
    > span {
      margin-left: 1em;
      font-weight: 700;
    }
  `,
  SignatureContainer: styled.div`
    margin: 7.5em 0 5em;
    padding-top: 7em;
    border-top: 1px #c9cbd1 solid;

    > p {
      margin: 0.9em 0 0.65em;
      font-size: 1.8em;
      font-weight: 700;
    }
    .role {
      display: inline-block;
      margin-left: 0.1em;
      color: #000;
      font-size: 0.88em;
      font-weight: 300;
      text-transform: uppercase;
    }
  `,
  BlockWrap: styled.div`
    position: relative;
    display: block;
    vertical-align: bottom;
    overflow: hidden;
    // .H3 {
    //   margin-bottom: 2em;
    //   font-size: 2.4em;
    //   font-weight: 600;
    //   color: #8e9199;
    // }
    @media (max-width: 767px) {
      display: block;
    }
  `,
  TextBox: styled.div`
    display: flex;
    margin-bottom: 0.8em;
    &.mrbtm{
      margin-top: 3em;
    }
    @media (max-width: 767px) {
      display: block;
      // margin-top: 4em;
    }
  `,
  TextItem: styled.div`
    flex: 1;
    p {
      font-size: 1.6em;
      font-weight: 400;
      &.pre{
        white-space: pre-wrap;
        word-break: keep-all;
      }
    }
    li {
      display: flex;
      margin-top: 2em;
      &:first-of-type {
        margin-top: 0;
      }
      .gray {
        // flex-basis: 12em;
        flex: 0 0 12em;
      }
    }
    @media (max-width: 767px) {
      margin-top: 3em;
      li{
        display: block;
        .gray {
          margin-bottom: 1em;
        }
      }
    }
  `,
  Blue: styled.div`
    margin-bottom: 0.8em;
    font-size: 1.6em;
    font-weight: 500;
    color: #485cc7;
    &:not(:first-child) {
      margin-top: 30px;
    }
  `,
};

export default StyledInvoicePage;

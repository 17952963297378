import styled from 'styled-components';

const StyledTestWritePaper = {
  Content: styled.div`
    position: relative;
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: calc(100vh - 9.5rem);
    color: #1e2024;
  `,

  QuestionContainer: styled.div`
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;
    padding: 3.5em 3em 0;
    height: 100%;
    background-color: #fff;
  `,

  Question: styled.div`
    margin: 3em 0;
    .title {
      font-size: 1.6em;
      font-weight: 500;
    }
    .desc {
      margin: 2em auto 3em;
      padding: 3.5em;
      border: 1px solid #d4d6dc;
      text-align: center;
    }
  `,

  AnswerInputContainer: styled.div`
    margin-bottom: 3em;
    p,
    .title {
      margin-bottom: 0.625em;
      font-size: 1.6em;
      font-weight: 500;
      &.correction {
        color: red;
      }
    }
    textarea,
    .answer {
      padding: 1em 1.25em;
      width: 100%;
      border: 1px solid #c9cbd1;
      font-size: 1.6em;
      line-height: 1.6em;
      color: #5f6268;
      vertical-align: top;
      resize: none;
      &.correction {
        border: 1px solid red;
      }
    }
  `,
  FileInputContainer: styled.div`
    margin: 1em 0;
    label {
      display: inline-block;
      padding: 0.5em 1.1em;
      background-color: #5f6268;
      font-size: 1.4em;
      line-height: normal;
      color: #fff;
      vertical-align: middle;
      cursor: pointer;
    }
    span {
      display: inline-block;
      margin-left: 1em;
      font-size: 1.4em;
      color: #999ba0;
      vertical-align: middle;
    }

    input {
      visibility: hidden;
      width: 1px;
      height: 1px;
    }
  `,
  PreviewContainer: styled.ul`
    margin: 3em -2em 0;
    text-align: center;

    .writeAnswerImg {
      min-width: 8em;
      min-height: 8em;
      max-width: 10em;
      max-height: 10em;
    }

    &::after {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }
  `,
  Preview: styled.li`
    float: left;
    padding: 0 2em;
    width: 50%;

    img {
      min-width: 8em;
      min-height: 8em;
      max-width: 10em;
      max-height: 10em;
    }
    .name {
      position: relative;
      display: inline-block;
      overflow: hidden;
      padding-right: 1.429em;
      max-width: 100%;
      font-size: 1.4em;
      font-weight: 500;
      color: #999ba0;
      text-overflow: ellipsis;
      white-space: nowrap;

      button {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        overflow: hidden;
        width: 14px;
        height: 14px;
        font-size: 1em;
        line-height: 500px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 1px;
          background-color: #999ba0;
          transform: translate(-50%, -50%) rotate(135deg);
        }
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          height: 1px;
          background-color: #999ba0;
          transform: translate(-50%, -50%) rotate(-135deg);
        }
      }
    }
  `,
  BtnContainer: styled.div`
    margin: 2em;
    text-align: center;
    background: #fff;

    button {
      width: 20%;
      min-width: 10em;
      padding: 0.533em 2.125em;
      background-color: #999ba0;
      line-height: 1.6em;
      font-size: 1.5em;
      color: #fff;
      border-radius: 0.375em;
      &:last-of-type {
        margin-left: 1em;
        background-color: #485cc7;
      }
    }
  `,
};

export default StyledTestWritePaper;

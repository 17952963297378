import React from 'react';
import Styled from './index.style';

const AsideHeader = ({ product }) => {
  const { courseResponse, teacherResponse } = product;
  return (
    <>
      <Styled.Title>{courseResponse.title}</Styled.Title>

      <Styled.TeacherName>
        by. {teacherResponse.firstName} {teacherResponse.lastName}
      </Styled.TeacherName>
    </>
  );
};

export default React.memo(AsideHeader);

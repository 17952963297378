import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ModalContext } from "component/Modal/modalContext";
import LandingPage from "container/LandingPage";
import CreateEmailAccount from "component/user/CreateEmailAccount";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "./state";

export default function ConfirmationEmail() {
  const history = useHistory();
  const login = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const { ssid, userID } = useParams();
  let { handleModal } = React.useContext(ModalContext);

  useEffect(() => {
    if (
      login !== "" ||
      login !== false ||
      login !== undefined ||
      login !== null
    ) {
      dispatch(actions.fetchLogout());
    }
    history.push("/");
    handleModal(<CreateEmailAccount userID={userID} ssid={ssid} />, false);
  }, [ssid, userID, login]);

  return <LandingPage />;
}

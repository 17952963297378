import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} props
 * @param {string=} props.className
 * @param {string | object} props.src
 * @param {string} props.alt
 * @param {string=} props.onError
 */
function Img(props) {
  return (
    <img
      className={props.className}
      src={props.src}
      alt={props.alt}
      onError={(e) => {
        e.target.onerror = null;
        if (props.onError) {
          e.target.src = props.onError;
        }
      }}
    />
  );
}

Img.propTypes = {
  src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  onError: PropTypes.string,
};

export default Img;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import Styled from './index.style';
import { useHistory, useParams } from 'react-router-dom';
import { ModalContext } from 'component/Modal/modalContext';
import { useSelector } from 'react-redux';
import VisibleIcon from 'component/atoms/Icon/VisibleIcon';
import VideoIcon from 'component/atoms/Icon/VideoIcon';
import ThemeLike from 'component/molecules/ThemeLike';
import EditThemeModal from './EditThemeModal';
import { useAxios } from 'common/hook/useAxiosHook';
import themesApi from 'api/theme';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import CreateThemeModalDepth1 from 'container/ThemeDetailPage/AddTheme/AddThemeModalDepth1';

const ThemeDetailHeader = ({
  initialState,
  token,
  setIsLoading,
  setTag,
  selectableState,
  selectedListState,
}) => {
  const history = useHistory();
  const { id } = useParams();

  const userInfoData = useSelector((state) => state.user.data);

  let { handleModal } = React.useContext(ModalContext);

  const [{ data: themeList }] = initialState.themeListState;

  const [theme, fetchTheme] = initialState.themeState;
  const { loading: themeLoading, data: themeData, error: themeError } = theme;

  // 최초 1회 호출
  useEffect(() => {
    fetchTheme({ id, token });
  }, []);

  useEffect(() => {
    if (themeData !== null) {
      setIsLoading(false);
      setTag(themeData.categoryName);
    }
  }, [themeData]);

  const [deleteTheme, fetchDeleteTheme] = useAxios(
    themesApi.deleteThemeItems,
    [],
    true
  );
  const {
    loading: deleteLoading,
    data: deleteStatus,
    error: deleteError,
  } = deleteTheme;
  const [selectable, setSelectable] = selectableState;
  const [selectedList] = selectedListState;

  const onDelete = () => {
    fetchDeleteTheme({
      id,
      productIds: selectedList,
      token,
    });
  };

  useEffect(() => {
    if (deleteStatus === 200) {
      history.go(0);
    }
  });

  if (themeData === null) return null;

  return (
    <Styled.Wrapper>
      <Styled.Title>{themeData.name}</Styled.Title>

      <Styled.Desc>{themeData.description}</Styled.Desc>

      <Styled.Info>
        <Styled.Tag>#{themeData.categoryName}</Styled.Tag>
        <Styled.Bar />
        <VideoIcon>{themeData.courseCount}</VideoIcon>
        <Styled.Bar />
        <ThemeLike
          id={themeData.id}
          count={themeData.likeCount}
          isGood={themeData.isGood}
        />
        <Styled.Bar />
        <VisibleIcon className={!themeData.isOpen && 'hidden-icon'} />
      </Styled.Info>

      {userInfoData?.tblMemberBasicIdx === themeData.userId && (
        <Styled.UserMenu>
          <Styled.Edit
            onClick={() =>
              handleModal(
                <EditThemeModal
                  currentData={themeData}
                  fetchTheme={fetchTheme}
                />
              )
            }
          />
          <Styled.Add
            onClick={() =>
              handleModal(
                <CreateThemeModalDepth1 themeList={themeList} id={id} />,
                false,
                540
              )
            }
          />
          <Styled.Delete onClick={() => setSelectable(!selectable)} />
        </Styled.UserMenu>
      )}

      <Styled.DeleteSubmit
        className={selectable && 'active'}
        onClick={onDelete}
      >
        Delete
      </Styled.DeleteSubmit>

      <LoadingOverlay isLoading={deleteLoading} />
    </Styled.Wrapper>
  );
};

export default ThemeDetailHeader;

import React from 'react';
import Styled from './ClassTabListItem.style';
import ProgressCircle from 'container/CourseViewPage/Aside/ClassTab/ProgressCircle';
import styled from 'styled-components';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

const Block = styled.span`
  display: inline-block;
  width: 2em;
  height: 2em;
  vertical-align: middle;
`;

const Link = styled(NavLink)`
  display: block;
  font-size: 1.4em;
  padding: 5px 20px;
  margin: 1em 0;
  color: #8e9199;
  &.active {
    font-weight: 400;
    color: #fff;
  }
  .progress-circle {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    font-size: 7px;
  }
`;

const OverviewItem = () => {
  const { productId, lectureId: currentLectureId } = useParams();

  return (
    <Styled.Container activeClassName='active'>
      <Link to={`/courses/detail/${productId}/0`} activeClassName='active'>
        {parseInt(currentLectureId) === 0 ? <ProgressCircle /> : <Block />}
        <Styled.LectureName>Overview</Styled.LectureName>
      </Link>
    </Styled.Container>
  );
};

export default OverviewItem;

import styled from 'styled-components';
import { MdPlayArrow, MdPause } from 'react-icons/md';
import { IoIosArrowDown } from 'react-icons/io';
import TableLayout from 'common/styled/tableLayout.style';

const StyledList = {
  Accordion: styled.div`
    margin-bottom: 0.625em;
    background-color: #2b2e35;
    border-radius: 6px;
  `,
  List: styled.div`
    ${TableLayout}
    font-size: 1.6em;
    padding: 1em;

    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    .time {
      width: 8em;
      text-align: right;
      color: #7a7d84;
      font-weight: 400;
    }
  `,
  ListTitle: styled.span`
    padding-left: 0.3em;
    font-weight: 500;
    color: #c9cbd1;
  `,
  SubListContainer: styled.div`
    display: ${({ show }) => (show ? 'block' : 'none')};
    border-top: 1px #35373a solid;

    > div:last-child {
      padding-bottom: 1.6em;
    }
  `,
  SubList: styled.div`
    ${TableLayout}
    padding: 1em 4em 0 4em;
    color: #83868e;
    font-size: 1.6em;
    font-weight: 400;

    &.show {
      display: block;
      max-height: auto;
    }

    .time {
      width: 5em;
    }
  `,
  ArrowSpan: styled.span.attrs((props) => ({
    children: (
      <>
        {props.children}
        <IoIosArrowDown />
      </>
    ),
  }))`
    position: relative;
    width: 11em;
    padding-right: 2.3em;
    text-align: right;
    color: #64676e;
    font-weight: 400;

    svg {
      position: absolute;
      top: 50%;
      right: 0;
      color: #64676e;
      font-size: 1.25em;
      transform: ${({ show }) =>
        show
          ? 'translateY(-50%) rotate(180deg)'
          : 'translateY(-50%) rotate(0deg)'};
    }
  `,
  VideoListContainer: styled.div`
    padding: 1.4em 3.4em 0 1.4em;
    cursor: pointer;
    &.clickable {
      &:hover span {
        color: #fff;
        span {
          border-color: #fff;
        }
      }
    }
  `,
  VideoList: styled.div`
    ${TableLayout}
    margin-bottom: 0.625em;
    border-radius: 6px;
    background-color: #2b2e35;
    font-size: 1.6em;
    font-weight: 400;

    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      position: relative;
      width: 2.3em;
      line-height: 0;
      font-weight: 700;
      > .progressBar {
        width: 2.2em;
        height: 1.2em;
      }
      > .progressBarIcon {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }
    }
    .time {
      width: 6em;
      text-align: right;
      color: #7a7d84;
      font-weight: 400;
    }
  `,

  VideoListTitle: styled.span`
    padding-left: 0.3em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    color: #7a7d84;
    &.active {
      color: #fff;
    }
    &.preview {
      padding-top: 0;
      padding-bottom: 0;
      color: #ffbb00;
    }
  `,

  VideoListPreview: styled.span`
    display: inline-block;
    padding: 0.3em 1em 0.5em;
    line-height: 1;
    border: 1px solid #ffbb00;
    border-radius: 2em;
    margin-left: 0.8em;
  `,

  PlayIcon: styled(MdPlayArrow)`
    padding: 4px;
    border-radius: 50%;
    background: #485cc7;
    color: #bcbdbe;
  `,
  PauseIcon: styled(MdPause)`
    padding: 5px;
    border-radius: 50%;
    background: #485cc7;
    color: #fff;
  `,
};
export default StyledList;

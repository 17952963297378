import React, { useEffect, useState } from 'react';
import Styled from './index.style';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import useErrorToastify from 'common/hook/useErrorToastify';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import communityApi from 'api/community';
import { VscClose } from 'react-icons/vsc';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const CommentRevise = ({ item, setEditId, onRefresh, initContent }) => {
  const { t } = useTranslation('communityPage');
  const token = useSelector((state) => state.user.token);
  const [content, setContent] = useState(item.content);

  const [putReply, fetchPutReply] = useAxios(communityApi.putReply, [], true);
  const { loading, data, error } = putReply;
  const onSubmit = (e) => {
    e.preventDefault();
    if (content.trim() === item.content) {
      toast.warn('Nothing has changed.');
      return false;
    }
    fetchPutReply(item.id, content, token);
  };

  useEffect(() => {
    if (data === 200) {
      setEditId(null);
      onRefresh();
      initContent(content);
    }
  }, [data]);

  useErrorToastify(error);

  return (
    <Styled.Wrapper>
      <Styled.Form onSubmit={onSubmit}>
        <Styled.Textarea
          required
          onChange={(e) => setContent(e.target.value)}
          value={content}
          autoFocus
        />

        <Styled.Submit>{t('buttonEdit')}</Styled.Submit>

        <Styled.Cancel onClick={() => setEditId(null)}>
          <VscClose />
        </Styled.Cancel>
      </Styled.Form>

      <LoadingOverlay isLoading={loading} />
    </Styled.Wrapper>
  );
};

export default CommentRevise;

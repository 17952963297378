import React, { useContext } from 'react';
import Style from 'container/MockTestPage/styled/TestWritePaper.style';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import mockTestApi from 'api/mockTests';
import Question from './Question';
import { MockTestContext } from '../index';

const Content = () => {
  const { mockTestStore } = useContext(MockTestContext);

  const { productId, unitId } = useParams();

  const token = useSelector((state) => state.user.token);
  const [{ data, loading }, refreshGetData] = useAxios(
    () =>
      mockTestApi.getQuestions({
        token,
        productId,
        unitId,
      }),
    [productId, unitId]
  );

  if (data)
    return (
      <Style.Content>
        <Style.QuestionContainer>
          {data.map((question) => {
            return <Question data={question} key={question.unitValuationId} />;
          })}
          <Style.BtnContainer>
            <button onClick={() => mockTestStore.saveBtnRef.current.click()}>
              임시저장 <br /> Save
            </button>
            <button onClick={() => mockTestStore.submitBtnRef.current.click()}>
              제출하기 <br /> Submit
            </button>
          </Style.BtnContainer>
        </Style.QuestionContainer>
      </Style.Content>
    );
  return <></>;
};

export default Content;

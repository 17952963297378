import React, { useEffect, useRef } from 'react';
import Styled from './index.style';
import Img from 'component/atoms/Img';
import { masterTopikUrl } from 'common/constant';
import { isSafari } from 'react-device-detect';

const TeacherCard = ({ item, activeState, paramId }) => {
  const [activeId, setActiveId] = activeState;

  const cardRef = useRef(null);
  const moveToScroll = () => {
    setTimeout(() => {
      const headerH = document
        .querySelector('#root > header')
        .getBoundingClientRect().height;
      const cardTop = cardRef.current.getBoundingClientRect().top + 200;
      const scrolledTop = cardTop + window.pageYOffset - headerH;
      window.scrollTo(0, scrolledTop);
    }, 200);
  };

  // 강사 id가 pathname에 있을 경우, 스크롤 이동
  useEffect(() => {
    if (paramId === item.id) {
      moveToScroll();
    }
  }, [paramId]);

  const handleActive = () => {
    setActiveId(activeId === item.id ? null : item.id);
    activeId !== item.id && moveToScroll();
  };

  return (
    <Styled.Wrapper onClick={handleActive} ref={cardRef}>
      <Styled.Container
        className={isSafari ? 'teacher-card safari' : 'teacher-card'}
      >
        <Styled.FrontCard className={isSafari ? 'safari' : ''}>
          <Img
            src={masterTopikUrl + item.thumbnailBig}
            alt={`${item.firstName} ${item.lastName}`}
          />

          <Styled.Texts>
            <Styled.Name>
              {item.firstName} {item.lastName}
            </Styled.Name>

            <Styled.Slogan>{item.slogan}</Styled.Slogan>
          </Styled.Texts>
        </Styled.FrontCard>
        <Styled.BackCard
          profileImage={masterTopikUrl + item.thumbnailBig}
          className={isSafari ? 'safari' : ''}
        >
          <Styled.Info>
            {item.profiles && (
              <>
                <h4>History</h4>
                <p>{item.profiles}</p>
              </>
            )}
            {item.profilesDetail && (
              <>
                <h4>Details</h4>
                <p>{item.profilesDetail}</p>
              </>
            )}
          </Styled.Info>
        </Styled.BackCard>
      </Styled.Container>
    </Styled.Wrapper>
  );
};

export default TeacherCard;

import React, { useEffect, useState } from 'react';
import Style from '../styled/Explore.style';
import { useAxios } from 'common/hook/useAxiosHook';
import helpApi from 'api/help';

const Explore = () => {

  const [exploreList, fetchExploreList] = useAxios(
    helpApi.getExploreArticle,
    [],
    true
  );

  const { data } = exploreList;

  useEffect(() => {
    fetchExploreList();
  }, [fetchExploreList])

  return (
    <Style.ExploreWrap>
      <Style.ListWrap>
        {/* <Style.ListItem>
          <a href="">
            <Style.Thumb><img src="" alt="" /></Style.Thumb>
            <Style.Title>Getting started on masterTOPIK</Style.Title>
          </a>
        </Style.ListItem>
        <Style.ListItem>
          <a href="">
            <Style.Thumb><img src="" alt="" /></Style.Thumb>
            <Style.Title>Review and get a free coffee gift card! (~22.12.31)</Style.Title>
          </a>
        </Style.ListItem>
        <Style.ListItem>
          <a href="">
            <Style.Thumb><img src="" alt="" /></Style.Thumb>
            <Style.Title>2-Month TOPIK II Study Plan</Style.Title>
          </a>
        </Style.ListItem>
        <Style.ListItem>
          <a href="">
            <Style.Thumb><img src="" alt="" /></Style.Thumb>
            <Style.Title>I want to start a Free Trial</Style.Title>
          </a>
        </Style.ListItem> */}
        {data && data.exploreList &&
          data.exploreList.map((item) => {
            return (
              <Style.ListItem>
                {item.linkUrl &&
                  <a href={item.linkUrl}>
                    <Style.Thumb><img src={`${item.filePath}/${item.fileName}`} alt="" /></Style.Thumb>
                    <Style.Title>{item.exploreTxt}</Style.Title>
                  </a>}
              </Style.ListItem>
            );
          })
        }
      </Style.ListWrap>
    </Style.ExploreWrap>
  );
};
export default Explore;


import styled, { css } from 'styled-components';
import { StyledForm } from 'component/form/Form.style';
// import iconChecked from 'assets/images/ico_check.png';
import largeIconcheckbox from 'assets/images/ico_checkbox01_large.png';
import iconcheckbox from 'assets/images/ico_checkbox01.png';
import largeIconcheckbox2 from 'assets/images/ico_checkbox02_large.png';
import iconcheckbox2 from 'assets/images/ico_checkbox02.png';
import { RiErrorWarningFill } from 'react-icons/ri';
import StyledUtil from 'common/styled/Util.style';

const summaryRow = css`
  display: flex;
  justify-content: space-between;
  font-size: 1.6em;
  font-weight: 500;
  margin-top: 0.6em;
`;

const StyledProcess = {
  Container: styled.div`
    &::before {
      content: "";
      display: table;
      overflow: hidden;
      clear: both;
    }
    // > div:first-of-type {
    //   margin-bottom: 1em;
    //   width: 10em;
    //   > div {
    //     min-height: 2.5em;
    //   }
    // }
  `,
  LeftSection: styled.div`
    float: left;
    width: calc(68.8% - 3em);
    margin-right: 3em;
    counter-reset: process;
    &::after {
      content: "";
      display: table;
      overflow: hidden;
      clear: both;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  RightSection: styled.div`
    width: 31.1%;
    float: left;
    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  ProcessContainer: styled.div`
    padding: 3.5em 3.3em 0 8.8em;
    margin-bottom: 2em;
    border-radius: 0.6em;
    background-color: #1e2024;

    &:last-of-type {
      margin-bottom: 17.5em;
    }

    &::after {
      content: "";
      display: table;
      overflow: hidden;
      clear: both;
    }

    @media (max-width: 767px) {
      padding: 3em;
      &:last-of-type {
        margin-bottom: 2em;
      }
    }
  `,
  ProcessTitle: styled.h2`
    position: relative;
    margin-bottom: 1.45em;
    color: ${({ color }) => (color ? "#fff" : "#8e9199")};
    font-size: 2.4em;
    font-weight: 700;
    &::before {
      counter-increment: process;
      content: "0" counter(process);
      position: absolute;
      top: 50%;
      left: -3em;
      width: 2.5em;
      height: 2.5em;
      border-radius: 50%;
      background-color: ${({ color }) => (color ? "#485CC7" : "#0f1012")};
      color: ${({ color }) => (color ? "#fff" : "#8e9199")};
      line-height: 2.5em;
      text-align: center;
      font-size: 0.75em;
      font-weight: 700;
      transform: translateY(-50%);
    }
    @media (max-width: 767px) {
      margin-left: 2.2em;
      padding-left: 0.5em;
    }
  `,
  OrderSummary: styled.div`
    overflow: hidden;
    padding: 0 3em;
    border-radius: 0.6em;
    background-color: #1e2024;
    clear: both;
    @media (max-width: 767px) {
      margin-bottom: 17em;
      padding: 1em 3em;
      font-size: 1.25em;
    }

    > p {
      margin-bottom: 0.8em;
      font-size: 1.6em;
      font-weight: 500;
    }
    > .paymentMethod {
      color: #4f5258;
      font-weight: 400;
    }
    > .desc {
      margin-top: 3em;
      margin-bottom: 2.3em;
      color: #6a6e76;
      font-size: 1.3em;
      font-weight: 300;
      > span {
        border-bottom: 1px solid #8b8f99;
        > span {
          color: #fff;
        }
      }
      @media (max-width: 767px) {
        font-size: 1.6em;
      }
    }
  `,

  OrderTitle: styled.h2`
    margin: 1em 0;
    color: #fff;
    font-size: 1.8em;
    font-weight: 700;
  `,

  PriceSection: styled.div`
    margin-bottom: 2.3em;
    padding-bottom: 2em;
    border-bottom: 1px #2b2e35 solid;
    .row {
      ${summaryRow}
    }
    > .head {
      color: #5c5f66;
      font-weight: 400;
    }
    > .row span:last-of-type {
      align-self: center;
      padding-left: 0.5em;
    }
  `,
  TotalSection: styled.div`
    margin-bottom: 2.3em;
    padding-bottom: 2em;
    border-bottom: 1px #2b2e35 solid;
    .row {
      ${summaryRow}
      >span {
        width: 40%;
        text-align: right;
      }
      > span:first-of-type {
        width: 55%;
        text-align: right;
        color: #4f5258;
        font-weight: 400;
      }
    }
  `,
  TotalPrice: styled.div`
    ${summaryRow}
    margin-bottom:2.22em;
    font-size: 1.8em;
    font-weight: 700;
  `,

  Button: styled.button`
    width: 100%;
    margin-bottom: 1.875em;
    padding: 1.25em;
    border-radius: 0.375em;
    background-color: #ffb500;
    color: #fff;
    font-size: 1.6em;
    font-weight: 700;
    text-transform: uppercase;
  `,

  ShippingContainer: styled.div`
    margin-bottom: 5em;

    > .header {
      margin-bottom: 3em;
      &::after {
        content: "";
        display: table;
        overflow: hidden;
        clear: both;
      }
      h3 {
        float: left;
        font-size: 2em;
        font-weight: 500;
        > span {
          display: block;
          padding-top: 0.5em;
          color: #5c5f66;
          font-size: 0.7em;
          font-weight: 400;
        }
      }
      div {
        float: right;
      }
    }

    @media (max-width: 767px) {
      > .header {
        font-size: 1.42em;
        h3 {
          float: none;
        }
        div {
          margin-top: 1.9em;
          float: none;
        }
      }
    }
  `,

  AddressButton: styled.button`
    padding: 1em 1.5em;
    color: #fff;
    border: 1px solid #5c5f66;
    border-radius: 0.375em;
    font-size: 1.4em;
    font-weight: 500;
  `,

  InputContainer: styled.div`
    margin-bottom: 3em;

    &.coupon {
      > div:first-of-type {
        display: inline-block;
        font-size: 0.7em;
        margin-right: 1em;
        width: calc(35% - 1em);

        @media (max-width: 767px) {
          width: 100%;
          margin: 1em 0 2em;
        }

        .select__placeholder,
        .select__option,
        .select__single-value {
          font-size: 2em;
        }

        .select__placeholder,
        .select__option {
          ${StyledUtil.textEllipsis}
        }
      }
      label {
        display: block;
        color: #fff;
        font-size: 2em;
        font-weight: 500;
      }
      > input {
        width: calc(50.5% - 1em);
        margin-right: 1em;
        @media (max-width: 767px) {
          width: calc(85.5% - 1em);
        }
      }
      button {
        width: 14.5%;
        padding: 1.125em 0.1em;
        border: 1px #5c5f66 solid;
        border-radius: 0.375em;
        color: #56585b;
        font-size: 1.6em;
        font-weight: 700;
      }
      span {
        display: inline-block;
        margin: 1em 0 3.75em;
        /* color: #ffb500; */
        font-size: 1.6em;
        font-weight: 400;

        &.error {
          color: #cf1010;
        }
        svg {
          vertical-align: text-bottom;
          cursor: pointer;
        }
      }
    }

    &.address {
      position: relative;

      @media (max-width: 767px) {
        padding-bottom: 5em;
      }

      .zipContainer {
        margin-bottom: 1.1em;
        > input {
          width: 31.6%;
        }
        > button {
          display: inline-block;
          margin-left: 0.625em;
          padding: 1.125em 1.875em;
          border: 1px #5c5f66 solid;
          border-radius: 0.375em;
          color: #5c5f66;
          font-size: 1.6em;
          font-weight: 700;
        }
      }
      .addressInput {
        display: flex;
        input:first-of-type {
          margin-right: 1em;
          width: calc(60%-1em);
        }
        input:last-of-type {
          width: 40%;
        }
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .checkboxContainer {
      position: absolute;
      top: 0;
      right: 0;
      @media (max-width: 767px) {
        top: 83%;
      }
    }
    .checkbox {
      position: relative;
      label {
        padding-left: 0.625em;
        &[for]:before {
          content: "";
          position: absolute;
          width: 1.5em;
          height: 1.5em;
          left: -2.8rem;
          top: 0;
          border: 1px #5d6165 solid;
          background-repeat: no-repeat;
          background-position: center;
          background-image: url(${largeIconcheckbox});
          transition: background 0.1s ease;
          @media (max-width: 1024px) {
            background-image: url(${iconcheckbox});
          }
          @media (max-width: 767px) {
            border: none;
          }
        }
      }
    }

    label {
      display: inline-block;
      margin-bottom: 0.625em;
      color: #8b8f99;
      font-size: 1.6em;
      font-weight: 400;
      @media (max-width: 767px) {
        font-size: 2em;
      }
    }
    .mobile {
      display: flex;
      > * {
        width: calc(100% / 3);
        margin-right: 1em;
      }
      > input:last-of-type {
        margin-right: 0;
      }
    }
    input {
      color: #a9acb2;
    }
  `,
  Select: styled(StyledForm.Select)`
    .select__control {
      background-color: #2b2e35;
    }
    > .select__menu,
    .select__option,
    .select__menu-list {
      background-color: #2b2e35;
    }
    .select__option:hover {
      background: rgba(0, 0, 0, 0.4);
    }
    .css-b8ldur-Input {
      color: #c7cad1;
    }
    .select__indicator {
      svg {
        color: #8b8f99;
      }
    }
    .select__placeholder,
    .select__single-value {
      color: #c7cad1;
      font-weight: 400;
    }
  `,
  Input: styled(StyledForm.Input)`
    width: 100%;
    background-color: #2b2e35;
    color: #c7cad1;
    font-weight: 400;

    &:focus {
      background-color: #2b2e35;
    }

    &::placeholder {
      color: #c7cad1;
      font-weight: 400;
    }
  `,
  InputCheck: styled.input.attrs({ type: "checkbox" })`
    position: absolute;
    z-index: -1;
    margin-bottom: -1em;
    visibility: hidden;
    &:checked + label[for]:before {
      background-image: url(${largeIconcheckbox2});
    }
    @media (max-width: 1024px) {
      &:checked + label[for]:before {
        background-image: url(${iconcheckbox2});
      }
    }
  `,
  PaymentContainer: styled.div`
    h3 {
      margin-bottom: 1.3em;
      font-size: 2em;
      font-weight: 500;
    }
    .payMethods {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 6em;
      button {
        flex: 0 0 calc(25% - 0.625em);
        margin-bottom: 0.625em;
        padding: 1.875em;
        border: 1px #595c63 solid;
        border-radius: 0.375em;
        box-sizing: border-box;
        vertical-align: bottom;
        color: #595c63;
        font-size: 1.6em;
        font-weight: 700;
        margin-left: 0.625em;
        &:nth-of-type(1) {
          margin-left: 0;
        }
        &.active {
          color: #ffb500;
          border-color: #ffb500;
        }
        @media (max-width: 1024px) {
          font-size: 2em;
          flex: 0 0 calc(50% - 0.625em);
          &:nth-of-type(odd) {
            margin-left: 0;
            flex: 0 0 calc(50%);
          }
          &:nth-of-type(even) {
            margin-left: 0.625em;
          }
        }
      }
    }
    &.terms {
      label {
        font-weight: 400;
      }
      @media (max-width: 767px) {
        font-size: 1.25em;
      }
      > div {
        margin-bottom: 7em;
      }
    }
  `,

  TermsContainer: styled.div`
    h3 {
      margin-bottom: 1.5em;
      font-size: 2em;
      font-weight: 500;
    }
    .checkbox {
    }
  `,
  TermCheck: styled.input.attrs({ type: "checkbox" })`
    position: absolute;
    z-index: -1;
    margin-bottom: -1em;
    visibility: hidden;
    &:checked + label[for]:before {
      background-image: url(${largeIconcheckbox2});
    }
    @media (max-width: 1024px) {
      &:checked + label[for]:before {
        background-image: url(${iconcheckbox2});
      }
    }
  `,
  WarnText: styled.div.attrs(({ children }) => ({
    children: (
      <>
        <RiErrorWarningFill />
        {children}
      </>
    ),
  }))`
    margin-top: 0.5em;
    font-size: 1.4rem;
    font-weight: 400;
    padding-left: 1.6em;
    text-indent: -1.6em;
    color: #cf1010;
    svg {
      margin-right: 0.2em;
      font-size: 1.3em;
      vertical-align: -0.2em;
    }
  `,
};

export default StyledProcess;

import styled from 'styled-components';

const AudioDetailPageStyle = {
  DetailTitle: styled.div`
    margin: 10em 0 8em;
    text-align: center;
    h2 {
      font-weight: 700;
      font-size: 3.6em;
      color: #fff;
    }
  `,
};

export default AudioDetailPageStyle;
import React from 'react';

export const termsOfPrivacyKo = () => (
  <div class='policy'>
    <h3 class='policy-title'>개인정보 처리방침</h3>

    <p>
      주식회사 비상교육(이하 "회사”라 함)은 이용자의 동의를 기반으로 개인정보를
      수집·이용 및 제공하고 있습니다. 회사는 『개인정보보호법』과
      『정보통신망이용촉진 및 정보 보호 등에 관한 법률』 등 모든 관련 법규를
      준수하며 회원님의 개인정보가 보호받을 수 있도록 최선을 다 하고 있습니다.
    </p>

    <p>
      본 개인정보 처리방침에서는 masterTOPIK 서비스와 관련해 회사가 이용자가
      제공한 개인정보를 어떠한 용도와 방식으로 이용하며, 개인정보 보호를 위해
      어떠한 방법을 통해 관리하고 있는지에 대해 설명합니다.
    </p>

    <p>
      회사의 개인보호정책은 국내 법령 및 지침의 변경과 회사의 정책 변화에 따라
      변경될 수 있습니다.
    </p>

    <dl class='policy-subject'>
      <dt>·목차: </dt>
      <dd>제1조 개인정보 이용 목적</dd>
      <dd>제2조 개인정보 수집 항목</dd>
      <dd>제3조 개인정보 공유 및 제공</dd>
      <dd>제4조 개인정보 처리 위탁</dd>
      <dd>제5조 개인정보 보유 및 이용 기간</dd>
      <dd>제6조 개인정보 파기절차 및 방법</dd>
      <dd>제7조 이용자 및 법정대리인의 권리와 행사 방법</dd>
      <dd>제8조 개인정보 자동수집장치의 설치/운영 및 거부에 관한 사항</dd>
      <dd>제9조 개인정보 기술적/관리적 보호 대책</dd>
      <dd>제10조 개인정보 보호책임자 및 민원서비스</dd>
      <dd>제11조 개인정보 처리방침의 개정 및 공지</dd>
    </dl>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>제1조 개인정보 이용 목적</h4>

      <p>
        회사는 회원 관리, 서비스 제공·개발 및 향상 등 아래의 목적으로만
        개인정보를 이용합니다. 마케팅 및 광고에 활용하는 경우는 별도의 동의를
        받고 있으며, 동의를 거부하더라도 서비스 이용에 제한이 없습니다.
      </p>

      <p class='policy-tit'>(1) 회원 관리</p>
      <p>
        {' '}
        본인 확인, 불량회원(masterTOPIK 이용약관 제7조 위반 사유로 인하여
        영구이용이 정지된 회원)의 부정 이용 방지와 비인가 사용방지, 가입의사
        확인, 분쟁 조정을 위한 기록 보존, 민원처리, 공지사항 전달
      </p>

      <p class='policy-tit'>
        (2) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산
      </p>
      <p>
        재화 및 컨텐츠의 제공, 맞춤 서비스 제공, 청구서 및 구매내역 발송, 기기
        간 동기화 서비스 제공, 본인인증, 구매 및 요금 결제, 물품 배송 등
      </p>

      <p class='policy-tit'>(3) 신규 서비스 개발 및 마케팅 활용</p>
      <p>
        신규 서비스 개발, 통계학적 특성, 이용 형태 등에 따른 맞춤형 서비스 제공,
        광고 게재, 접속 빈도 파악, 회원의 서비스 이용에 대한 통계
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>제2조 개인정보 수집 항목</h4>

      <p>
        회사는 회원가입, 서비스 신청 및 제공, 원활한 고객상담 등을 위해 다음과
        같은 개인정보를 수집하고 있습니다. 정보를 제공하지 않거나 삭제를
        요청하면 masterTOPIK 서비스 이용에 제한이 있을 수 있습니다.
      </p>

      <div class='policy-sec'>
        <h5 class='policy-sec-tit'>1. 회원가입 시</h5>
        <p>
          회원 가입 시 아래와 같은 개인정보가 수집되며, 다음의 정보에 대해서는
          관련 법령(masterTOPIK 개인정보 처리방침 제5조 보유 기간)에 의거하여
          명시한 기간 동안 이용 및 보관됩니다.
        </p>

        <p class='policy-tit'>(1) 일반 이메일 가입 회원</p>
        <ul class='policy-bu'>
          <li>·필수 정보: 이름, 이메일 주소, 비밀번호, 국적</li>
          <li>·선택 정보: 닉네임, 생년월일, 성별, 마케팅 수신 동의</li>
        </ul>

        <p class='policy-tit'>(2) SNS 가입 회원</p>
        <ul class='policy-bu'>
          <li>·필수 정보: SNS 프로필, 이메일 주소, 비밀번호</li>
          <li>·선택 정보: 닉네임, 생년월일, 성별, 국적, 마케팅 수신 동의</li>
        </ul>
      </div>

      <div class='policy-sec'>
        <h5 class='policy-sec-tit'>2. 자동 수집 항목</h5>
        <p>
          회사는 이용자가 masterTOPIK 서비스를 이용할 때 '쿠키(Cookie)'를 포함한
          아래의 정보를 수집합니다.
        </p>

        <p class='policy-tit'>(1) 웹 사이트</p>
        <p>
          웹사이트 이용자의 IP 주소, 브라우저 유형, 브라우저 언어, 운영 체제,
          소프트웨어 및 하드웨어 속성 (장치 ID 포함) 참조 및 종료 페이지 및 URL,
          클릭 수, 조회한 페이지 및 조회 순서, 사용 날짜 및 시간, 시청한 콘텐츠,
          총 시청 시간, 오류 로그 및 웹 사이트 사용 방법에 대한 기타 유사한
          정보를 수집합니다.
        </p>

        <p class='policy-tit'>(2) 모바일 애플리케이션</p>
        <p>
          masterTOPIK 모바일 애플리케이션 이용자의 IP 주소, 운영 체제, 버전,
          인터넷 서비스 제공 업체를 포함하여 앱에 액세스하는 데 사용되는 휴대
          전화, 태블릿 또는 컴퓨터에 대한 특정 정보, 브라우저 유형, SSAID,
          FCM토큰, 도메인 이름 및 기타 유사한 정보, 앱 업데이트 여부 및 시기,
          사용 날짜 및 시간, 시청 한 콘텐츠, 총 시청 시간, 오류 로그 및 앱 사용
          방법에 대한 기타 유사한 정보를 자동으로 수신합니다.
        </p>

        <p class='policy-tit'>(3) 위치 정보</p>
        <p>
          이용자의 컴퓨터 또는 모바일 장치에서 일반 위치 정보(예 : IP 주소, 도시
          / 주 및 또는 IP 주소와 관련된 우편 번호)를 자동으로 수집할 수
          있습니다.
        </p>
      </div>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>제3조 개인정보 공유 및 제공</h4>

      <p>
        회사는 개인정보를 수집, 이용 목적 이외에 다른 용도로 이용하거나 회원의
        동의없이 제3자에게 이를 제공하지 않습니다. 다만, 회원의 동의가 있거나
        아래 내용에 해당하는 경우에는 예외로 합니다.
      </p>

      <p>
        이용자는 개인정보 제3자 제공에 대해 동의하지 않을 수 있고 언제든지 제공
        동의를 철회할 수 있습니다. 다만, 제3자 제공에 기반한 일부 서비스의
        이용이 제한될 수 있습니다.
      </p>

      <ul class='policy-bu'>
        <li>·회원이 사전에 공개 또는 제3자 제공에 동의한 경우</li>
        <li>
          ·법령의 규정에 의거하거나 수사 목적으로 법령에 정해진 절차와 방법에
          따라 수사기관의 요구가 있는 경우
        </li>
        <li>·서비스 제공에 따른 요금 정산을 위하여 필요한 경우</li>
        <li>
          ·통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을
          식별할 수 없는 형태로 제공하는 경우
        </li>
        <li>
          ·정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한
          개인정보로서 경제적, 기술적인 사유로 통상적인 동의를 받는 것이 곤란한
          경우
        </li>
        <li>
          ·영업의 양도·합병 등에 관한 사유가 발생하는 경우 (단, 회사는 영업의
          양도 등에 관한 사유가 발생하여 이용자의 개인정보 이전이 필요한 경우,
          관계법률에서 규정한 절차와 방법에 따라 개인정보 이전에 관한 사실 등을
          사전에 고지하며 이용자에게는 개인정보 이전에 관한 동의 철회권을
          부여합니다.)
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>제4조 개인정보 처리 위탁</h4>

      <p>
        회사는 원활하고 편리한 서비스를 위하여 최소한의 제한된 범위에서
        개인정보를 외부 전문업체에 위탁하여 운영하고 있습니다. 위탁 계약시
        개인정보가 안전하게 관리될 수 있도록 관련 사항들을 별도로 규정하고
        있습니다.
      </p>

      <p class='policy-tit'>(1) 정보의 국제 이전</p>
      <p>
        회사는 본 정책에 따라 서비스 사용을 위해 이용자의 거주 국가 내외에서
        개인정보를 전송, 저장 및 처리하며, 특히 '대한민국'으로 이전됩니다.
        이용자의 정보가 전송되는 국가의 정보보호 및 기타 법률은 이용자 거주
        국가의 법률보다 포괄적이지 않을 수 있습니다.
      </p>

      <p class='policy-tit'>(2) 결제</p>
      <ul class='policy-bu'>
        <li>·수탁 업체: 페이레터(주), Paypal</li>
        <li>·위탁 업무: 결제, 구매 안전 서비스 제공</li>
        <li>·수집 항목: 카드사명, 카드번호 등</li>
        <li>·보유 및 이용 기간: 회원 탈퇴 및 위탁 계약 만료 시까지</li>
      </ul>

      <p class='policy-tit'>(3) 배송</p>
      <ul class='policy-bu'>
        <li>·수탁 업체: ㈜한진</li>
        <li>·위탁 업무: 상품 배송</li>
        <li>·수집 항목: 이름, 배송지 주소, 휴대전화번호 등</li>
        <li>·보유 및 이용 기간: 목적 달성 시 즉시 파기</li>
      </ul>

      <p class='policy-tit'>(4) 인증</p>
      <ul class='policy-bu'>
        <li>·수탁 업체: 페이레터㈜</li>
        <li>·위탁 업무: 본인인증</li>
        <li>·수집 항목: 이름, 휴대전화번호 등</li>
        <li>·보유 및 이용 기간: 목적 달성 시 즉시 파기</li>
      </ul>

      <p class='policy-tit'>(5) 고객 문의</p>
      <ul class='policy-bu'>
        <li>·수탁 업체: ㈜채널코퍼레이션</li>
        <li>·위탁 업무: 고객 문의 및 안내 관리</li>
        <li>·수집 항목: (선택) 이름, 이메일 주소, 전화번호 등</li>
        <li>
          ·보유 및 이용 기간: 소비자의 불만 또는 분쟁처리에 관한 기록 보유
          방침을 따름
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>제5조 개인정보 보유 및 이용 기간</h4>

      <p>
        회사는 원칙적으로 이용자의 개인정보를 회원탈퇴 시 지체없이 파기합니다.
        단, 다음의 정보에 대해서는 관련 법령에 의거하여 명시한 기간 동안 별도로
        분리 보관되며, 명시한 기간이 경과한 개인정보는 지체없이 파기합니다.
      </p>

      <p>
        탈퇴 완료된 계정의 아이디는 영구적으로 재사용이 불가능하며, 재가입을
        제한하기 위한 아이디 및 이메일 중복여부 검사는 단방향 암호화되어 복원
        불가능한 고유값을 대조하는 방식이 사용됩니다.
      </p>

      <p class='policy-tit'>(1) 회원탈퇴 시</p>
      <ul class='policy-bu'>
        <li>
          ·보존 근거: 불량 이용자의 재가입 방지, 명예훼손 등 권리침해 분쟁 및
          수사 협조
        </li>
        <li>·보존 기간: 회원탈퇴 후 1년</li>
      </ul>

      <p class='policy-tit'>(2) 상거래 이력</p>
      <ul class='policy-bu'>
        <li>·보존 근거: 상법, 전자상거래 등에서의 소비자보호에 관한 법률</li>
        <li>
          ·보존 기관:
          <table class='policy-tbl'>
            <tbody>
              <tr>
                <th>계약 또는 청약 철회 등에 관한 기록</th>
                <td>5년 (전자상거래 등에서의 소비자보호에 관한 법률)</td>
              </tr>
              <tr>
                <th>대금결제 및 재화 등의 공급에 관한 기록</th>
                <td>5년 (전자상거래 등에서의 소비자보호에 관한 법률)</td>
              </tr>
              <tr>
                <th>소비자의 불만 또는 분쟁처리에 관한 기록</th>
                <td>3년 (전자상거래 등에서의 소비자보호에 관한 법률)</td>
              </tr>
              <tr>
                <th>전자 금융 거래에 관한 기록</th>
                <td>5년 (전자금융거래법)</td>
              </tr>
              <tr>
                <th>표시, 광고에 관한 기록</th>
                <td>6개월 (전자상거래 등에서의 소비자보호에 관한 법률)</td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>

      <p class='policy-tit'>(3) 웹 사이트 방문기록</p>
      <ul class='policy-bu'>
        <li>·보존 근거: 통신비밀보호법</li>
        <li>·보존 기간: 3개월</li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>제6조 개인정보 파기절차 및 방법</h4>

      <p>
        회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성된 경우에는 지체
        없이 해당 정보를 파기합니다. 파기 절차 및 방법은 다음과 같습니다.
      </p>

      <p class='policy-tit'>(1) 파기 절차</p>
      <p>
        이용자가 회원가입 등을 위해 입력한 정보는 목적 달성 후 별도의 DB에
        옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한
        정보보호 사유에 따라 일정기간(개인정보 보유 및 이용 기간 참조) 저장된 후
        파기됩니다. 개인정보는 법률에 의한 경우 외에는 다른 목적으로 이용되지
        않습니다.
      </p>

      <p class='policy-tit'>(2) 파기 방법</p>
      <ul class='policy-bu'>
        <li>
          ·종이 출력물 형태: 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        </li>
        <li>
          ·전자적 파일 형태: 기록을 재생할 수 없는 기술적 방법을 사용하여
          삭제합니다.
        </li>
      </ul>

      <p class='policy-tit'>(3) 휴면 계정의 개인정보 파기 절차</p>
      <p>
        회사는 "정보통신망 이용촉진 및 정보보호 등에 관한 법률 제29조 동법
        시행령 제 16조"에 따라 masterTOPIK 서비스에 1년 이상 로그인하지 않은
        계정은 개인정보보호를 위하여 해당 회원의 개인정보를 휴면계정으로 전환 및
        별도 관리합니다.
      </p>
      <ul class='policy-bu'>
        <li>
          ·휴면 처리되는 회원 정보: 회원 가입 시 또는 회원 정보 수정으로
          수집/관리되는 모든 정보
        </li>
        <li>
          ·회사 휴면 계정의 개인정보 분리·저장 기간이 도래하기 1개월 전에 이메일
          등을 통해 해당 이용자에게 관련 내용을 공지합니다.
        </li>
        <li>
          ·휴면 처리된 계정의 개인정보는 분리 보관 시작으로부터 3년이 경과하면
          지체 없이 파기합니다.
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        제7조 이용자 및 법정대리인의 권리와 행사 방법
      </h4>

      <p>
        회사는 원칙적으로 개인정보의 수집 및 이용목적이 달성된 경우에는 지체
        없이 해당 정보를 파기합니다. 파기 절차 및 방법은 다음과 같습니다.
      </p>

      <p class='policy-tit'>(1) 이용자의 권리</p>
      <ul class='policy-bu'>
        <li>
          ·이용자는 언제든지 등록되어 있는 자신의 개인정보를 조회하거나 수정할
          수 있으며, 가입 해지를 요청할 수도 있습니다.
        </li>
        <li>
          ·개인정보 조회, 수정을 위해서는 '정보변경'을 통해 직접 열람, 정정이
          가능하며, 가입 해지(동의철회)를 위해서는 고객센터 '1:1문의' 또는
          ‘mastertopik@visang.com'로 탈퇴의사를 기재하여 보내주시면 탈퇴가
          가능합니다.
        </li>
        <li>
          ·개인정보 보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이
          조치하겠습니다.
        </li>
        <li>
          ·개인정보 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지
          당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를
          제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이
          통지하여 정정이 이루어지도록 하겠습니다.
        </li>
        <li>
          ·회사는 타인의 개인정보를 도용하여 회원가입 등을 하였음을 알게 된 때
          지체 없이 해당 아이디에 대한 서비스 이용정지 또는 회원탈퇴 등 필요한
          조치를 취합니다. 또한 자신의 개인정보 도용을 인지한 이용자가 해당
          아이디에 대한 서비스 이용정지 또는 회원탈퇴를 요구하는 경우에도 회사는
          즉시 조치를 취합니다.
        </li>
      </ul>

      <p class='policy-tit'>(2) 이용자의 의무</p>
      <ul class='policy-bu'>
        <li>
          ·이용자는 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를
          예방해 주시기 바랍니다. 이용자가 입력한 부정확한 정보로 인해 발생하는
          사고의 책임은 고객님 자신에게 있으며 타인 정보를 도용하거나 허위정보를
          입력할 경우 회원자격이 상실될 수 있습니다.
        </li>
        <li>
          ·고객님은 스스로를 보호하고 타인의 정보를 침해하지 않을 의무를 가지고
          있습니다. 비밀번호를 포함한 귀하의 개인정보가 유출되지 않도록
          주의하시고 타인의 개인정보와 게시물을 훼손하지 않도록 유의해 주십시오.
        </li>
        <li>
          ·이 같은 책임을 다하지 못 하고 타인의 정보 및 존엄성을 훼손할 시,
          '정보통신망이용촉진 및 정보 보호 등에 관한 법률'에 의해 처벌받을 수
          있습니다.
        </li>
      </ul>

      <p class='policy-tit'>(3) 아동의 개인정보 보호 </p>
      <p>
        회사의 서비스는 만 14세 미만의 어린이가 아닌 일반 사용자를 대상으로
        합니다. 본 서비스가 실수 등으로 만 14세 미만의 어린이 정보를 수집했다는
        사실을 알게 된 경우 유효한 부모 동의 없이 정보를 삭제하겠습니다. 회사는
        부모의 동의 없는 16세 미만의 EU 거주자 데이터를 고의로 처리하지
        않습니다. 부모 동의 없이 16세 미만의 EU 거주자로부터 정보를 수집한 경우
        지체 없이 합리적인 조치를 취한 뒤 정보를 삭제하겠습니다. 또한 해당 지역
        법률에 따라 기타 연령 제한 및 요구 사항을 준수합니다.
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        제8조 개인정보 자동수집장치의 설치/운영 및 거부에 관한 사항
      </h4>

      <p>
        회사는 개인화되고 맞춤화된 서비스를 제공하기 위하여 이용자의 정보를
        저장하고 불러오는 '쿠키(Cookie)'를 사용합니다. '쿠키(Cookie)'는 웹
        사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에 보내는 작은
        텍스트 파일로 이용자의 디스크에 저장됩니다.
      </p>

      <p class='policy-tit'>(1) 회사의 쿠키 사용 목적</p>
      <ul class='policy-bu'>
        <li>·각 서비스와 사이트에 대한 접속 빈도나 사이트 등 분석</li>
        <li>·이용자의 취향과 관심 분야를 파악 및 자취 추적</li>
        <li>
          ·각 서비스와 웹 사이트에 대한 방문 및 이용 형태, 인기 검색어, 보안접속
          여부, 이용자 규모 등을 파악
        </li>
        <li>
          ·각종 이벤트 참여 정도 및 방문 횟수 파악 등을 통한 마케팅 및 개인 맞춤
          서비스 제공
        </li>
      </ul>

      <p class='policy-tit'>(2) 쿠키의 설치/운영 및 거부</p>
      <p>
        이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹
        브라우저에서 옵션을 설정하여 모든 쿠키를 허용하거나, 쿠키가 저장될
        때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. 다만,
        쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스의 이용이
        어려울 수 있습니다.{' '}
      </p>
      <ul class='policy-bu'>
        <li>
          ·Internet Explorer: 웹 브라우저 상단의 도구 &gt; 인터넷 옵션 &gt;
          개인정보 &gt; 설정
        </li>
        <li>
          ·Google Chrome: 웹 브라우저 우측의 설정 메뉴 &gt; 화면 하단의 고급
          설정 표시 &gt; 개인정보의 콘텐츠 설정 버튼 &gt; 쿠키
        </li>
        <li>
          ·Firefox: 옵션 메뉴 선택 &gt; 개인정보 선택 &gt; 방문기록-사용자 정의
          설정 &gt; 쿠키 수준 설정
        </li>
        <li>
          ·Safari: 환경설정 메뉴 선택 &gt; 개인정보 탭 선택 &gt; 쿠키 및 웹
          사이트 데이터 수준 설정
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>제9조 개인정보 기술적/관리적 보호 대책</h4>

      <p>
        회사는 이용자의 개인정보를 보호하기 위하여 다음과 같은 노력을 하고
        있습니다.
      </p>

      <p class='policy-tit'>(1) 기술적 보호 대책</p>
      <ul class='policy-bu'>
        <li>
          ·정보 암호화: 이용자의 개인정보를 암호화하여 보관하고, 암호화된
          통신구간을 이용하여 전송합니다.
        </li>
        <li>
          ·백신프로그램 운영: 해킹이나 컴퓨터 바이러스로부터 보호하기 위하여
          백신 프로그램을 설치한 후 24시간 감시하고 있습니다. 또한 새로운
          해킹/보안 기술에 대해 지속적으로 연구하여 서비스에 적용하고 있습니다.
        </li>
        <li>
          ·방화벽 이용: 외부로부터의 무단 접근을 방지하고 있으며, 기타
          시스템적으로 보안성을 확보하기 위한 기술적 장치를 갖추고자 노력하고
          있습니다.
        </li>
      </ul>

      <p class='policy-tit'>(2) 관리적 보호 대책</p>
      <ul class='policy-bu'>
        <li>
          ·취급 직원의 최소화: 개인정보를 처리하는 직원을 최소화하며, 업무용
          PC에서는 외부 인터넷 서비스를 사용할 수 없도록 차단하여 개인정보
          유출에 대한 위험을 줄이고 있습니다. 또한 개인정보를 처리하는 시스템에
          대한 비밀번호의 생성과 변경, 그리고 접근할 수 있는 권한에 대한
          체계적인 기준을 마련하고 지속적인 감사를 실시하고 있습니다.
        </li>
        <li>
          ·개인정보 취급 교육: 임직원에게 이용자 개인정보 보호에 대해 정기적인
          교육을 실시하고 있습니다.
        </li>
        <li>
          ·개인정보보호 전담조직 운영: 이용자의 개인정보를 보호하고 궁금증 해결,
          불만 처리를 위해 다음과 같이 책임자를 지정하고 운영하고 있습니다. 단,
          회원 본인의 부주의나 회사가 관리하지 않는 영역에서의 사고 등 회사의
          귀책에 기인하지 않는 손해에 대해서는 회사는 책임을 지지 않습니다.
          반면, 훼손이 유발될 경우 회원에게 사실을 알리고 적절한 대책과 보상을
          강구할 것입니다.
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>제10조 개인정보 보호책임자 및 민원서비스</h4>

      <p>
        회사는 이용자의 개인정보를 보호하기 위하여 다음과 같은 노력을 하고
        있습니다.
      </p>

      <p class='policy-tit'>(1) 개인정보 보호책임자 및 연락처</p>
      <p>
        회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기
        위하여 아래와 같이 관련 부서 및 개인정보관리 책임자를 지정하고 있습니다.
      </p>
      <ul class='policy-bu'>
        <li>·책임자: IT 전략 Core 이정우 CP</li>
        <li>·담당부서: 정보보안 Cell</li>
        <li>·전화: 1544-0554</li>
        <li>·문의: privacy@visang.com</li>
      </ul>
      <p>
        이외 masterTOPIK CS팀(mastertopik@visang.com)에 관련하여
        문의주시면 신속하고 충분한 답변을 드리도록 하겠습니다.
      </p>

      <p class='policy-tit'>(2) 민원서비스</p>
      <p>
        개인정보 침해에 대한 신고 또는 상담이 필요하신 경우에는 아래 기관으로
        문의하시기 바랍니다.
      </p>
      <ul class='policy-bu'>
        <li>
          <p>·개인정보침해신고센터 (한국인터넷진흥원 운영)</p>
          <p>전화: 국번없이 118</p>
          <p>
            URL:{' '}
            <a
              href='http://privacy.kisa.or.kr'
              target='_blank'
              rel='noreferrer'
            >
              http://privacy.kisa.or.kr
            </a>
          </p>
        </li>
        <li>
          <p>·대검찰청 사이버범죄수사과</p>
          <p>전화: 국번없이 1301</p>
          <p>
            URL:{' '}
            <a href='http://www.spo.go.kr' target='_blank' rel='noreferrer'>
              http://www.spo.go.kr
            </a>
          </p>
        </li>
        <li>
          <p>·경찰청 사이버수사국</p>
          <p>전화: 국번없이 182</p>
          <p>
            URL:{' '}
            <a
              href='http://cyberbureau.police.go.kr'
              target='_blank'
              rel='noreferrer'
            >
              http://cyberbureau.police.go.kr
            </a>
          </p>
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>제11조 개인정보 처리방침의 개정 및 공지</h4>

      <p>
        회사는 법률, 데이터 수집 및 사용 관행이 변경되었거나 서비스 기능 또는
        기술 반영을 위하여 개인 정보 보호 정책을 수정할 권리가 있습니다. 개정된
        개인정보 보호정책은 서비스를 통해 확인할 수 있으며, 이용자는 정기적으로
        개인정보 보호정책을 검토해야 합니다. 문서 마지막 부분에 포함된
        '공고/시행일자'를 확인하여 마지막 검토 이후 개인정보 보호정책이
        변경되었는지 알 수 있습니다. 회사는 위 내용의 추가, 삭제 및 수정이 있을
        시에는 개정 최소 7일 전부터 홈페이지의 '공지사항'을 통해 고지하겠습니다.
        다만, 이용자 권리의 중요한 변경이 있을 경우 최소 30일 전에 고지합니다.
      </p>

      <p>
        <strong>공고 일자: 2021년 3월 2일</strong>
      </p>
      <p>
        <strong>시행 일자: 2021년 3월 22일</strong>
      </p>

      <p>
        서비스 이용과 관련하여 궁금하신 사항이 있다면 mastertopik@visang.com으로
        문의 주시기 바랍니다.
      </p>
    </div>
  </div>
);

import React from 'react';
import Style from './styled/ConfirmPage.style';
import { RiCheckFill } from 'react-icons/ri';

const ConfirmPage = () => {
  return (
    <div>
      <Style.Receipt.Header>
        <h2>Payment Successful</h2>
      </Style.Receipt.Header>

      <Style.Receipt.Container>
        <Style.Receipt.ProfileInfo>
          <h2>Payment History</h2>
          <div className='info'>
            <span className='checkCircle'>
              <RiCheckFill color='#fff' size='2em' />
            </span>
            <span className='userInfo'>2020/11/29 14:07</span>
            <span className='userInfo'>John Doe</span>
            <span className='title'>Select Item</span>
            <span className='product'>비상 한국어 초급1 - USD 30.00</span>
            <span className='product'>Premium - USD 19.99/month</span>
            <span className='desc'>Billed monthly</span>
          </div>
        </Style.Receipt.ProfileInfo>
        <Style.Receipt.ProductInfo>
          <span className='title'>Payment Method</span>
          <span className='title white'>Credit Card</span>
          <span className='title'>Order Summary</span>

          <div className='product head'>
            <span>Select Item</span>
            <span>Price</span>
          </div>
          <div className='product'>
            <span>비상 한국어 초급1</span>
            <span>USD 30.00</span>
          </div>
          <div className='product last'>
            <span>Premium</span>
            <span>USD 19.00</span>
          </div>
          <div className='totalSection'>
            <div className='product total'>
              <span>Item Total</span>
              <span>USD 49.99</span>
            </div>
            <div className='product total last'>
              <span>Discounts</span>
              <span>-USD 9.99</span>
            </div>
          </div>
          <div className='totalSection'>
            <div className='product total'>
              <span>Subtotal</span>
              <span>USD 40.00</span>
            </div>
            <div className='product total'>
              <span>Delivery Fee</span>
              <span>USD 2.00</span>
            </div>
            <div className='product total last'>
              <span>Estimated Tax</span>
              <span>USD 0.00</span>
            </div>
          </div>
          <div className='product finalTotal'>
            <span>Total Due Today</span>
            <span>USD 42.00</span>
          </div>
        </Style.Receipt.ProductInfo>
      </Style.Receipt.Container>
    </div>
  );
};

export default ConfirmPage;

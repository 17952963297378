import React, { useContext } from 'react';
import Style from 'container/MockTestPage/styled/TestWritePaper.style';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import mockTestApi from 'api/mockTests';
import Question from '../WriteResultPage/Question';

const WriteResultPage = () => {
  const { productId, unitId } = useParams();

  const token = useSelector((state) => state.user.token);

  const [{ data }] = useAxios(
    () =>
      mockTestApi.getResults({
        token,
        productId,
        unitId,
      }),
    [productId, unitId]
  );

  if (data)
    return (
      <Style.Content>
        <Style.QuestionContainer>
          {data.map((question) => {
            return <Question data={question} key={question.unitValuationId} />;
          })}
        </Style.QuestionContainer>
      </Style.Content>
    );
  return <></>;
};

export default WriteResultPage;

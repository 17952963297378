import React, { useState, useEffect } from 'react';
import Style from './styled/Content.style';
import BookList from 'container/BookStorePage/BookList';
import { useAxios } from 'common/hook/useAxiosHook';
import BookStoreApi from 'api/bookstore';
import InfiniteScroll from 'react-infinite-scroll-component';
import Features from 'container/LandingPage/Features';
import { useTranslation } from 'react-i18next';

const BookStoreContent = () => {
  const { t } = useTranslation('bookstorePage');

  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [getBooks, refreshGetBooks] = useAxios(BookStoreApi.getBooks, []);
  const { data } = getBooks;

  const onNext = () => {
    refreshGetBooks(data.number + 1);
  };

  useEffect(() => {
    if (data) {
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(!data.last);
    }
  }, [getBooks]);

  return (
    <Style.Container>
      <Style.Title>{t('bookSection.title1')}</Style.Title>
      <InfiniteScroll
        dataLength={slideItem.length}
        next={onNext}
        hasMore={hasMore}
      >
        {slideItem.map((category) => {
          return (
            <div key={category.id}>
              <Style.SubTitle>{category.title}</Style.SubTitle>
              <Style.ListContainer>
                {category.bookResponses.map((book) => {
                  return <BookList key={book.id} data={book} />;
                })}
              </Style.ListContainer>
            </div>
          );
        })}
      </InfiniteScroll>
    </Style.Container>
  );
};

export default BookStoreContent;

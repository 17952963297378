import React, { useEffect, useState } from 'react';
import Styled from './index.style';
import ReactPlayer from 'react-player';
import linkCopyImg from 'assets/images/event/220512/btn_copy.png';
import contentsImg from 'assets/images/event/220512/contents.png';

import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// import { useSelector, useDispatch } from "react-redux";
import { Switch, useHistory, useLocation, Link } from 'react-router-dom';
// import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Thumbs } from 'swiper';

// import { addItem, deleteAllItem } from "container/PaymentPage/state/action";
// import { useAxios } from 'common/hook/useAxiosHook';
import useLoginStatus from "common/hook/useLoginStatus";
// import productApi from 'api/product';
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";
// import ConfirmModal from "container/EventPage/20220321Alive/ConfirmModal";
// import userApi from 'api/user';
import { MOBILE_APP_USER_AGENT } from "common/constant";

SwiperCore.use([Navigation, Thumbs]);

const EventPage = () => {
	// Language
	const { t } = useTranslation('event/20220512MockTest');
	const language = localStorage.getItem('i18nextLng');

	// const token = useSelector((state) => state.user.token);
	const isLogin = useLoginStatus();
	let { handleModal } = React.useContext(ModalContext);
	const location = useLocation();
	// const history = useHistory();
	// let dispatch = useDispatch();
	// const [vcProductId, setVCProductId] = React.useState(-1);

	// Video State
	const [playing, setPlaying] = useState(false);
	const [isPlay, setIsPlay] = useState(false);
	const onPLay = () => {
		setPlaying(true);
		setIsPlay(true);
	};

	// 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
	const [isMobile, setisMobile] = useState(false);
	const resizingHandler = () => {
		if (window.innerWidth <= 1024) {
		setisMobile(true);
		} else {
		setisMobile(false);
		}
	};
	useEffect(() => {
			if (window.innerWidth <= 1024) {
		setisMobile(true);
		}
		window.addEventListener("resize", resizingHandler);
		return () => {
		window.removeEventListener("resize", resizingHandler);
		};
	}, []);

	//선생님 소개
	const [tabActive, setTabActive] = useState(1);

	// swiper
	//const [BenefitSwiper, setBenefitSwiper] = useState(null);

	//링크복사
	const doCopy = text => {

		// 흐름 1.
		if (!document.queryCommandSupported("copy")) {
		return toast.error("Unsupported browser.");
		}

		const API_HOST = process.env.REACT_APP_ENV === 'local' ? 'http://localhost:3000' : process.env.REACT_APP_API_HOST;

		// 흐름 2.
		const textarea = document.createElement("textarea");
		textarea.value = API_HOST + text;
		textarea.style.top = 0;
		textarea.style.left = 0;
		textarea.style.position = "fixed";

		// 흐름 3.
		document.body.appendChild(textarea);
		// focus() -> 사파리 브라우저 서포팅
		textarea.focus();
		// select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
		textarea.select();
		// 흐름 4.
		document.execCommand("copy");
		// 흐름 5.
		document.body.removeChild(textarea);
		alert("Link copied.");
		//toast.success("Link copied.");
	};

	const clickSignUp = () => {
		if (!isLogin) {
			handleModal(<Login />, false, 420, location.pathname);
		}
	}

	const openLink = (url) => {
		const userAgent = window.navigator.userAgent;
		if (userAgent === MOBILE_APP_USER_AGENT) {
			window?.WebInterface?.movetoAndroidBrowser(true, url);
		}else{
			window.open(url)
		}
	}

	return (
		<Styled.EventWrap>
			<div className='topBanner'>
				{t('topBanner.title')}
			</div>

			<div className={language === 'ko-KR' ? 'visual ko' : 'visual'}>
				<div className='inner'>
					<div className='blind'>
						<h2>{t('visual.title')}</h2>
					</div>
					<ul className='btnWrap'>
						<li><a href="javascript:;" onClick={()=>{openLink("https://forms.gle/ebAvpNgP6p3pFQeQ6"); return false;}}>{t('visual.button')}</a></li>
						<li><a href="javascript:;" onClick={()=>{openLink("https://bit.ly/3lp5SmN"); return false;}}>{t('visual.button')}</a></li>
					</ul>
				</div>
			</div>

			<div className='section1'>
				<div className='inner'>
					<h2><span>{t('section1.title')}</span></h2>
					<div className="overview">
						<ul className="tab">
							<li><a
								className={tabActive === 1 ? 'active' : ''}
								onClick={(e) => {
									e.preventDefault();
									setTabActive(1);
								}}
								href="#tab1"
								>{t('section1.tab1')}</a>
							</li>
							<li><a
								className={tabActive === 2 ? 'active' : ''}
								onClick={(e) => {
									e.preventDefault();
									setTabActive(2);
								}}
								href="#tab2"
								>{t('section1.tab2')}</a>
							</li>
						</ul>
						<div className="tabContent">
							<div className={tabActive === 1 ? "block" : "hide"} id="tab1">
								<dl>
									<dt>{t('section1.tabCont1.period')}</dt>
									<dd><Trans t={t}>section1.tabCont1.periodDate</Trans></dd>
									<dt>{t('section1.tabCont1.shipping')}</dt>
									<dd>
										<Trans t={t}>section1.tabCont1.shippingDate1</Trans>
										<Trans t={t}>section1.tabCont1.shippingDate2</Trans>
										<Trans t={t}>section1.tabCont1.shippingDate3</Trans>
									</dd>
								</dl>
								<ol>
									<li>
										<em>STEP 1</em>
										<h3>{t('section1.tabCont1.step1.title')}</h3>
										<div><Trans t={t}>section1.tabCont1.step1.desc</Trans></div>
										<a href="javascript:;" onClick={clickSignUp}>{t('section1.tabCont1.step1.link')}</a>
									</li>
									<li>
										<em>STEP 2</em>
										<h3>{t('section1.tabCont1.step2.title')}</h3>
										<div><Trans t={t}>section1.tabCont1.step2.desc</Trans></div>
										{/* <a href="https://forms.gle/ebAvpNgP6p3pFQeQ6" target="_blank">{t('section1.tabCont1.step2.link')}</a> */}
										<a href="javascript:;" onClick={()=>{openLink("https://forms.gle/ebAvpNgP6p3pFQeQ6"); return false;}}>{t('section1.tabCont1.step2.link')}</a>
									</li>
									<li>
										<em>STEP 3</em>
										<h3>{t('section1.tabCont1.step3.title')}</h3>
										<div><Trans t={t}>section1.tabCont1.step3.desc</Trans></div>
									</li>
									<li>
										<em>STEP 4</em>
										<h3>{t('section1.tabCont1.step4.title')}</h3>
										<div><Trans t={t}>section1.tabCont1.step4.desc</Trans></div>
										{/* <a href="https://bit.ly/39h6zf4" target="_blank">{t('section1.tabCont1.step4.link')}</a> */}
										<a href="javascript:;" onClick={()=>{openLink("https://bit.ly/39h6zf4"); return false;}}>{t('section1.tabCont1.step4.link')}</a>
									</li>
								</ol>
							</div>
							<div className={tabActive === 2 ? "block" : "hide"} id="tab2">
								<ol>
									<li>
										<em>STEP 1</em>
										<h3>{t('section1.tabCont2.step1.title')}</h3>
										<div><Trans t={t}>section1.tabCont2.step1.desc</Trans></div>
										<a href="javascript:;" onClick={clickSignUp}>{t('section1.tabCont2.step1.link')}</a>
									</li>
									<li>
										<em>STEP 2</em>
										<h3>{t('section1.tabCont2.step2.title')}</h3>
										<div><Trans t={t}>section1.tabCont2.step2.desc</Trans></div>
										{/* <a href="https://www.mastertopik.com/plan" target="_blank">{t('section1.tabCont2.step2.link')}</a> */}
										<a href="javascript:;" onClick={()=>{openLink("https://www.mastertopik.com/plan"); return false;}}>{t('section1.tabCont2.step2.link')}</a>
									</li>
									<li>
										<em>STEP 3</em>
										<h3>{t('section1.tabCont2.step3.title')}</h3>
										<div><Trans t={t}>section1.tabCont2.step3.desc</Trans></div>
										{/* <a href="https://bit.ly/39h6zf4" target="_blank">{t('section1.tabCont2.step3.link')}</a> */}
										<a href="javascript:;" onClick={()=>{openLink("https://bit.ly/39h6zf4"); return false;}}>{t('section1.tabCont2.step3.link')}</a>
									</li>
								</ol>
							</div>
						</div>
					</div>
					<div className='question'>
						<h3>{t('section1.question.title')}</h3>
						<div className='list'>
							<ul>
								<li><Trans t={t}>section1.question.desc1</Trans></li>
								<li>
									<Trans t={t}>section1.question.desc2</Trans>
									{/* <a href="https://bit.ly/3DVMpAW" target="_blank">{t('section1.question.btn')}</a> */}
									<a href="javascript:;" onClick={()=>{openLink("https://bit.ly/3DVMpAW"); return false;}}>{t('section1.question.btn')}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className='section2'>
				<div className='inner'>
					<h2><span>{t('section2.title')}</span></h2>
					<div className='desc'>
						<Trans t={t}>section2.desc</Trans>
					</div>
					<div className='img'>
						<Trans t={t}>section2.img</Trans>
						<img src={contentsImg} alt="" />
					</div>
				</div>
			</div>

			<div className='section3'>
				<div className='inner'>
					<h2><span>{t('section3.title')}</span></h2>
					<div className='desc'>
						<p>{t('section3.desc1')}</p>
						<Trans t={t}>section3.desc2</Trans>
					</div>
					<div className='list'>
						<ul>
							<li><em>1</em> <Trans t={t}>section3.list1</Trans></li>
							<li><em>2</em> <Trans t={t}>section3.list2</Trans></li>
							<li><em>3</em> <Trans t={t}>section3.list3</Trans></li>
						</ul>
					</div>
				</div>
			</div>

			<div className='notification'>
				<div className='inner'>
					<h3>{t('notification.title')}</h3>
					<dl>
					<dt>{t('notification.group1.title')}</dt>
						<dd>{t('notification.group1.desc1')}</dd>
						{/* <dd>{t('notification.group1.desc2-1')} <a href="https://mastertopikhelp.zendesk.com/hc/en-us/articles/6170210382739" target='_blank'>{t('notification.group1.desc2-link')}</a> {t('notification.group1.desc2-2')}</dd> */}
						<dd>{t('notification.group1.desc2-1')} <a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/6170210382739"); return false;}}>{t('notification.group1.desc2-link')}</a> {t('notification.group1.desc2-2')}</dd>

						<dt>{t('notification.group2.title')}</dt>
						{/* <dd><Trans t={t}>notification.group2.desc1</Trans> <a href="https://bit.ly/3DVMpAW" target='_blank'>{t('notification.group2.link1')}</a></dd> */}
						<dd><Trans t={t}>notification.group2.desc1</Trans> <a href="javascript:;" onClick={()=>{openLink("https://bit.ly/3DVMpAW"); return false;}}>{t('notification.group2.link1')}</a></dd>
						{/* <dd><Trans t={t}>notification.group2.desc2</Trans> <a href="https://mastertopikhelp.zendesk.com/hc/en-us/articles/4412786168467" target='_blank'>{t('notification.group2.link2')}</a></dd> */}
						<dd><Trans t={t}>notification.group2.desc2</Trans> <a href="javascript:;" onClick={()=>{openLink("https://mastertopikhelp.zendesk.com/hc/en-us/articles/4412786168467"); return false;}}>{t('notification.group2.link2')}</a></dd>
						<dd><Trans t={t}>notification.group2.desc3</Trans> <a href="mailto:mastertopik@visang.com">{t('notification.group2.link3')} : mastertopik@visang.com</a></dd>

						<dt>{t('notification.group3.title')}</dt>
						<dd><Trans t={t}>notification.group3.desc1</Trans></dd>
						<dd><Trans t={t}>notification.group3.desc2</Trans></dd>
					</dl>
				</div>
			</div>

			<div className='shareWrap'>
				<div className='inner'>
					<div className="text">
						<Trans t={t}>shareWrap</Trans>
					</div>
					<div className="btn">
						<a href="javascript:;" onClick={() => doCopy("/event/mocktest")}><img src={linkCopyImg} alt="Copy" /></a>
					</div>
				</div>
			</div>
		</Styled.EventWrap>
  );
};

export default EventPage;
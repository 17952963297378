import React from 'react';
import PropTypes from 'prop-types';
import Styled from './index.style';
import thumbnailDefault from 'assets/images/thumb_default_course.jpg';
import VideoThumb from 'component/atoms/VideoThumb';
import styled from 'styled-components';
import useLoginStatus from 'common/hook/useLoginStatus';

/**
 * Video list item - 제목 1줄, 부제목 1줄 형식. 여백 미포함
 * @param {*} param
 * @param {string} param.to 링크 주소
 * @param {string || array} param.imgSrc 1개 이상인 경우 array
 * @param {string} param.title 제목 1줄 초과 시, 말줄임표
 * @param {string=} param.desc 부제목 1줄 초과 시, 말줄임표
 * @param {number=} param.percent 영상 시청 비율 (미입력시 0)
 * @param {string=} param.className set wrapper className ( title 및 subtitle에도 접두사로 className 생성됨 )
 */
export default function VideoItem({
  to,
  imgSrc,
  title,
  desc,
  percent = 0,
  className,
  videoLink,
  idNew = false
}) {
  const isLogin = useLoginStatus();
  const link = videoLink ? videoLink : to;

  return (
    <Styled.Wrapper className={className && className}>
      <Styled.Link to={isLogin ? link : to}>
        {idNew && (<Styled.NewIcon />)}
        <VideoThumb
          src={imgSrc ? imgSrc : thumbnailDefault}
          alt={title}
          percent={percent}
        />
      </Styled.Link>
      <Styled.Link to={to}>
        <Styled.Text>
          <Styled.Title className={className && className + '-title'}>
            {title}
          </Styled.Title>
          {desc && (
            <Styled.Desc className={className && className + '-desc'}>
              {desc}
            </Styled.Desc>
          )}
        </Styled.Text>
      </Styled.Link>
    </Styled.Wrapper>
  );
}

VideoItem.propTypes = {
  to: PropTypes.string.isRequired,
  imgSrc: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  className: PropTypes.string,
};

import React, { useState, useRef } from 'react';
import Styled from './index.style';
import ArrowLink from 'component/atoms/ArrowLink';
import LessonList from './LessonList';
import ReactPlayer from 'react-player';

const TeacherDetail = ({ item, setActiveId, currentVideoState, t }) => {
  const { id, firstName, lastName } = item;
  const [currentVideo] = currentVideoState;
  const [playing, setPlaying] = useState(true);
  const videoRef = useRef();

  const handleClose = () => {
    setActiveId(null);
  };

  const touchVideoScreen = () => {
    if (!videoRef) return;
    setPlaying((bool) => !bool);
  };

  return (
    <Styled.Wrapper className='teacher-detail'>
      <Styled.Inner>
        <Styled.Player onTouchStart={touchVideoScreen}>
          {currentVideo[id] && (
            <ReactPlayer
              ref={videoRef}
              url={currentVideo[id]}
              playing={playing}
              controls
              config={{
                file: {
                  attributes: {
                    onContextMenu: (e) => e.preventDefault(),
                    controlsList: 'nodownload',
                  },
                },
              }}
              /*
            알고보니 자동으로 인식함
            config={
              currentVideo[id].indexOf('m3u8') > 0 ?
              {file: {
                forceHLS: true,
              }} :
              {file: {
                forceVideo: true,
              }}}
            */
            />
          )}
        </Styled.Player>
        {/*
            <Plyr
              ref={videoRef}
              source={{
                type: 'video',
                sources: [{ src: currentVideo[id], type: 'video/mp4' }],
              }}
            />
            */}
        <Styled.Container>
          {currentVideo[id] && (
            <LessonList
              item={item}
              currentVideoState={currentVideoState}
              videoRef={videoRef}
              t={t}
            />
          )}

          <Styled.GoToLibrary>
            {t('representativeLecture.notice')}
          </Styled.GoToLibrary>

          <ArrowLink
            primary='true'
            to={{
              pathname: '/library',
              state: { teacherName: firstName + lastName },
            }}
          >
            {firstName} {lastName}
          </ArrowLink>
        </Styled.Container>

        <Styled.Close onClick={handleClose} />
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

export default TeacherDetail;

import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const ButtonTheme = {
  primary: {
    main: '#485cc7',
    filled: {
      main: '#485cc7',
      text: '#fff',
      hover: {
        background: '#3e52c0',
      },
    },
  },
  yellow: {
    main: '#ffb500',
    filled: {
      main: '#ffb500',
      text: '#fff',
    },
  },
};

export const defaultButtonStyles = css`
  display: inline-block;
  text-align: center;
  border-radius: 0.6rem;
`;

export const ButtonLink = styled(Link)`
  display: inline-block;
  font-size: 2rem;
  font-weight: 600;
  padding: 0.7em 2.5em;
  border: 1px solid ${(props) => props.theme.main};
  border-radius: 0.6rem;
  background-color: ${(props) =>
    props.theme.text ? props.theme.main : 'transparent'};
  color: ${(props) => (props.theme.text ? props.theme.text : props.theme.main)};

  &:hover {
    background-color: ${(props) =>
      props.theme?.hover?.background && props.theme.hover.background};
  }
  @media (max-width: 767px) {
    font-size: 2.4rem;
    font-weight: 500;
    padding: 0.7em 1.5em;
  }
`;

export const Button = styled.button.attrs(({ type }) => ({
  type: type ? type : 'button',
}))`
  font-size: 2rem;
  font-weight: 600;
  padding: 0.7em 2.5em;
  border: 1px solid ${(props) => props.theme.main};
  border-radius: 0.6rem;
  background-color: ${(props) =>
    props.theme.text ? props.theme.main : 'transparent'};
  color: ${(props) => (props.theme.text ? props.theme.text : props.theme.main)};
  @media (max-width: 767px) {
    font-size: 2.6rem;
    font-weight: 500;
  }
`;

ButtonLink.defaultProps = {
  theme: {
    main: '#fff',
  },
};

Button.defaultProps = {
  theme: {
    main: '#fff',
  },
};

export default ButtonLink;

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ViewContext } from 'container/CourseViewPage';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

import close from 'assets/images/mycurriculum_new/x.png';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


const PopWrap = styled.div`
  position:relative;
  position:fixed;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  width:42rem;
  height: 27.6rem;background:#fff;
  border-radius: 0.6rem;
  z-index:10;
  padding:4rem 3rem;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  > div{

  }
`;
const PopHead = styled.div`

`;
const H3 = styled.h3`
  font-size:2.4rem;
  color:#000000;
  text-align: center;
  font-weight: 600;
`;
const Close = styled.button`
  position:absolute;
  right:2rem;
  top:2rem;
`;
const PopBody = styled.div`
  padding:2.5rem;
  flex:1;
`;
const PopDescP = styled.p`
  color:#000000;
  font-size:1.6rem;
  text-align: center;


`;
const PopFoot = styled.div`
  display: flex;
  gap:1rem;
`;
const Button = styled.button`
  font-size: 1.6rem;
  width:17.5rem;
  height:5.4rem;
  display: flex;
  justify-content:center;
  align-items: center;
  border-radius: 0.6rem;
  &.cancel{
    color:#999999;
    background-color: #fff;
    border:1px solid #999
  }
  &.confirm{
    color:#fff;
    background-color: #485cc7;
  }
`;

/**
 * 코스 플레이어 하단
 */
const PopDesc = ({ children }) => {

  const formattedText = children.split('\n').map((text, index) => (
    <React.Fragment key={index}>
      {index > 0 && <br />} {/* 첫 번째 줄에는 <br>를 추가하지 않음 */}
      {text}
    </React.Fragment>
  ));

  return <PopDescP>{formattedText}</PopDescP>;
};



const EndPop = ({setIsPop, nextLectureId}) => {
  const { t } = useTranslation('viedoDetailPage');
  const { productId, lectureId } = useParams();
  const history = useHistory();


  return (
    <PopWrap>
      <PopHead>
        <H3>{t('pop.title')}</H3>
        <Close onClick={()=> setIsPop(false)}><img src={close} alt="닫기"/></Close>
      </PopHead>
      <PopBody>
        <PopDesc>{t('pop.desc')}</PopDesc>
      </PopBody>
      <PopFoot>
        <Button className='cancel' onClick={()=> setIsPop(false)}>{t('pop.button1')}</Button>
        <Button className='confirm' onClick={() => {
          history.push(`/courses/detail/${productId}/${nextLectureId}`);
          setIsPop(false);
        }}>{t('pop.button2')}</Button>
      </PopFoot>
    </PopWrap>
  );
};

export default EndPop;

import React, { useEffect, useState } from 'react';
import Style from "../styled/index.style";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useAxios } from 'common/hook/useAxiosHook';
import helpApi from 'api/help';

const SearchWords = () => {

  const history = useHistory();

  const [recommendKeyWords, fetchRecommendKeyWords] = useAxios(
    helpApi.getRecommendKeywords,
    [],
    true
  );

  const [keywords, setKeywords] = useState([]);

  const { data } = recommendKeyWords;

  const search = (searchText) => {
    console.error(`search : ${searchText}`)
    history.push({
      pathname: `/help/all`,
      state: {
        search: searchText
      }
    });
  };

  useEffect(() => {
    fetchRecommendKeyWords();
  }, [fetchRecommendKeyWords])


  useEffect(() => {
    if (data && data.recommendSearchList) {
      setKeywords(data.recommendSearchList)
    }
  }, [data])

  return (
    <Style.SearchWordWrap>
      {/* <Style.SearchWord>Free Trial</Style.SearchWord>
      <Style.SearchWord>Account</Style.SearchWord>
      <Style.SearchWord>Payment</Style.SearchWord>
      <Style.SearchWord>Notice</Style.SearchWord>
      <Style.SearchWord>Event</Style.SearchWord> */}
      {keywords &&
        keywords.map((item) => {
          return (
            <>
              <Style.SearchWord onClick={() => search(item.keyword)} key={item.keyword}>
                {item.keyword}
              </Style.SearchWord>
            </>
          );
        })
      }
    </Style.SearchWordWrap>
  );
};

export default SearchWords;
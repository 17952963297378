import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import MyCurriculum from 'container/MyCurriculumPage/MyCurriculumDetail/MyCurriculum';
import RecommendedCourses from 'container/MyCurriculumPage/MyCurriculumDetail/RecommendedCourses';
import curriculumApi from 'api/curriculum';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import Loading from 'component/atoms/Loading';

const MyCurriculumDetail = () => {
  const token = useSelector((state) => state.user.token);
  const { curriculumId } = useParams();

  const [fetchData, refreshFetchData] = useAxios(() =>
    curriculumApi.getCurriculumDetailData(token, curriculumId)
  );

  const [isMyCurriculum, setIsMyCurriculum] = useState(false);

  const [curriculumIds, setCurriculumIds] = useState();

  const [isMobile, setisMobile] = useState(false);
  const resizingHandler = () => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setisMobile(true);
    }
    window.addEventListener("resize", resizingHandler);
    return () => {
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);

  const { loading, data, error } = fetchData;

  const [duration, setDuration] = useState(0);

  useEffect(()=> {
    if(data) {
      var sumOfClass = 0;
      data.curriculumCourseResponses?.forEach(element => {
        sumOfClass += element.classCount;
      });
      setDuration(sumOfClass);
    }
  },[data])

  useEffect(() => {
    if(curriculumIds) {
      console.error("curriculumIds :", curriculumIds);
    }
  }, [curriculumIds])


  if (loading) return <Loading/>;
  if (error) return;
  if (data) {

    return (
      <>
        <MyCurriculumDetailWrap>


          <MyCurriculumHead>
            <MyCurriculumTitle>{data.title}</MyCurriculumTitle>
            <MyCurriculumSlg>
              {data.description}
            </MyCurriculumSlg>
          </MyCurriculumHead>
          <InfoContainer>
            <Info>
              <span className='title'>Audience {!isMobile ? <br /> : ""} Level</span>
              <span className='level'>
                <span>
                  LV.
                  <span className='levelNumber'>{data.languageLevel}</span>
                </span>
                <span className='name'>{data.levelName}</span>
              </span>
            </Info>
            <Info>
              <span className='title'>Number of Courses</span>
              <span className='level'>
                <span>
                  <span className='levelNumber'>{data.numberOfCourses}</span>
                </span>
                <span className='name'>Courses</span>
              </span>

            </Info>
            <Info>
              <span className='title'>Curriculum {!isMobile ? <br /> : ""}Duration</span>
              <span className='level'>
                <span>
                  <span className='levelNumber'>{duration}</span>
                </span>
                <span className='name'>Days</span>
              </span>

            </Info>
          </InfoContainer>
          <MyCurriculum
          myCurriculumData={data}
          haveCurriculum
          isMyCurriculum={isMyCurriculum}
          setIsMyCurriculum={setIsMyCurriculum}
          curriculumIds={curriculumIds}
          setCurriculumIds={setCurriculumIds}/>

          <RecommendedCourses curriculumData={data} isMyCurriculum={isMyCurriculum} setIsMyCurriculum={setIsMyCurriculum}/>
        </MyCurriculumDetailWrap>
      </>
    );
  }
  return (<></>);
};

export default MyCurriculumDetail;

const MyCurriculumDetailWrap = styled.div`
    position: relative;
    max-width: calc(1340px);
    padding: 0px 20px;
    margin: auto;`
const MyCurriculumHead = styled.div`
    margin-top: 8em;
  @media (max-width: 1024px) {}
`;

const MyCurriculumTitle = styled.h3`
  font-size:3.5rem;
  color:#ffb500;
  font-weight:500;
  text-align: center;
  line-height: 1;
  &:before{
    content:"";
    width:3rem;
    height:0.3rem;
    margin:0 auto 1.5rem;
    background:#ffb500;
    display:block;
  }

  @media (max-width: 1024px) {}
`;

const MyCurriculumSlg = styled.p`
  color:#c9cbd1;
  font-size:1.6rem;
  margin-top:2rem;
  line-height: 1.8;
  text-align: center;

  @media (max-width: 1024px) {}
`;

const InfoContainer = styled.div`
  display: flex;
  margin: 7.5em 0 3em;
  > * {
    flex: 1;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    > div {
      margin-right: 0;
      margin-bottom: 1em;
    }
  }
`;
const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:11.5rem;
  margin-right: 3em;
  padding:0 3rem;
  border-radius: 0.6em;
  background-color: #1e2024;
  color: #c9cbd1;

  .number {
    color: #fff;
    font-size: 4em;
    font-weight: 700;
    > span {
      vertical-align: 0.5em;
      font-size: 0.4em;
      font-weight: 500;
    }
  }

  &:last-of-type {
    margin-right: 0;
  }
  .title {
    margin-right: 0.5em;
    font-size: 2rem;
    font-weight: 400;
  }
  > .level {
    display: flex;
    flex-direction: column;
    text-align: right;
    color: #fff;
    &.eps {
      font-size: 2rem;
    }
    .levelNumber {
      font-size: 4rem;
      font-weight: 700;
    }

    > span {
      font-size: 2.2rem;
      line-height: 1;
      font-weight: 500;
      &.name{
        color:#fff;
        font-size:1.4rem;
        margin-top:1rem;
      }
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    padding:2rem;
  }
  @media (max-width: 767px) {
    flex-direction: row;
    > .level {
      text-align: right;
    }
  }
`
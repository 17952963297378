import React, { useState, useEffect, useRef } from 'react';
import Style from '../styled/Lnb.style';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const Nav = (props) => {
  const [mobileSelect, setMobileSelect] = useState(false);
  const openMenu = () => {
    setMobileSelect(mobileSelect => !mobileSelect);
  }

  const history = useHistory();

  const selectCategory = (cate) => {
    props.setCategory(cate);
    history.push({
      pathname: `/help/${cate}`
    });
  };

  const category = props.category;
  const searchResultAmount = props.searchResult;
  const searchText = props.searchText;

  const categoryName = (category === 'all') ? 'ALL'
    : (category === 'guide') ? 'Guide'
      : (category === 'notice') ? 'Notice'
        : (category === 'event') ? 'Event'
          : (category === 'study') ? 'Study' : selectCategory('all');

  const currentResultAmount = (category === 'all') ? searchResultAmount.all
    : (category === 'guide') ? searchResultAmount.guide
      : (category === 'notice') ? searchResultAmount.notice
        : (category === 'event') ? searchResultAmount.event
          : (category === 'study') ? searchResultAmount.study : selectCategory('all');

  return (
    <Style.NavWrap className={mobileSelect ? 'show-menu' : 'hide-menu'}>
      <div className='nav' onClick={() => openMenu()}>
        <Style.Info><strong>{categoryName}</strong>{searchText && <span>({currentResultAmount})</span>}</Style.Info>
        <ul>
          <li
            className={category === 'all' ? 'on' : ''}
            onClick={() => selectCategory('all')}
          >
            <button><strong>ALL</strong>{searchText && <span>({searchResultAmount.all})</span>}</button>
          </li>
          <li
            className={category === 'guide' ? 'on' : ''}
            onClick={() => selectCategory('guide')}
          >
            <button><strong>Guide</strong>{searchText && <span>({searchResultAmount.guide})</span>}</button>
          </li>
          <li
            className={category === 'notice' ? 'on' : ''}
            onClick={() => selectCategory('notice')}
          >
            <button><strong>Notice</strong>{searchText && <span>({searchResultAmount.notice})</span>}</button>
          </li>
          <li
            className={category === 'event' ? 'on' : ''}
            onClick={() => selectCategory('event')}
          >
            <button><strong>Event</strong>{searchText && <span>({searchResultAmount.event})</span>}</button>
          </li>
          <li
            className={category === 'study' ? 'on' : ''}
            onClick={() => selectCategory('study')}
          >
            <button><strong>Study</strong>{searchText && <span>({searchResultAmount.study})</span>}</button>
          </li>
        </ul>
      </div>
    </Style.NavWrap>
  );
};
export default Nav;


import React, { useEffect } from 'react';
import styled from 'styled-components';
import PageHeader from 'component/atoms/PageHeader';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import AccountSection from './AccountSection';
import SubscriptionSection from './SubscriptionSection';
import NotificationSection from './NotificationSection';
import PaymentSection from 'container/SettingPage/PaymentSection';
import PaymentListSection from 'container/SettingPage/PaymentListSection';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'component/user/state';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const StyledSettingPage = {
  Container: styled.div`
    display: flex;
    margin-top: 6em;
    margin-bottom: 3em;
    > * {
      flex: 1;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `,
};

const SettingPage = () => {
  const { t } = useTranslation('settingPage');
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);

  const location = useLocation();


  useEffect(() => {
    dispatch(actions.fetchUser(token));
  }, [dispatch, token]);

  useEffect(() => {
    if(location && location.hash) {
      const element = document.getElementById(location.hash);
      if(element){
        document.body.animate({scrollTop : element.offsetTop}, 500);
        window.scrollTo(0,element.offsetTop);
        console.error(`scrollTop : ${element.offsetTop}`);
      }
    }
  },[location]);

  return (
    <ContentsWrapper>
      <SeoHelmet title={SeoData.settings.title} desc={SeoData.settings.desc} />
      <PageHeader title={t('title')} />
      <StyledSettingPage.Container>
        <AccountSection />
        <div>
          <div id='#subscription'>
           <SubscriptionSection />
          </div>
          <div id='#allownotifications'>
            <NotificationSection />
          </div>
        </div>
      </StyledSettingPage.Container>
      <PaymentSection />
      <div id='#paymentlist'>
        <PaymentListSection />
      </div>
    </ContentsWrapper>
  );
};

export default SettingPage;

import styled, {css} from 'styled-components';
import icoArrow from 'assets/images/event/220829/ico_arrow.png';
import visualBg from 'assets/images/event/220829/bg_visual.png';
import visualImg from 'assets/images/event/220829/visual.png';
import visualImgKo from 'assets/images/event/220829/ko/visual.png';
import contImg from 'assets/images/event/220829/cont.jpg';
import contImg2 from 'assets/images/event/220829/cont2.jpg';
import contImg3 from 'assets/images/event/220829/cont3.jpg';
import contImg4 from 'assets/images/event/220829/cont4.jpg';
import contImg5 from 'assets/images/event/220829/cont5.jpg';
import contImgKo from 'assets/images/event/220829/ko/cont.jpg';
import contImg2Ko from 'assets/images/event/220829/ko/cont2.jpg';
import contImg3Ko from 'assets/images/event/220829/ko/cont3.jpg';
import contImg4Ko from 'assets/images/event/220829/ko/cont4.jpg';
import contImg5Ko from 'assets/images/event/220829/ko/cont5.jpg';

const StyledEventPage = {
  BtnArrow: styled.div.attrs({ className: 'btnWrap' })`
    a {
      display: block;
      max-width: 630px;
      margin: auto;
      padding: 0.8em;
      border-radius: 0.3em;
      line-height: 1em;
      font-size: 1.8em;
      color: #fff;
      text-align: center;
      > div {
        display: inline-block;
        padding-right: 1em;
        background-image:url(${icoArrow});
        background-position: 100% 50%;
        background-repeat: no-repeat;
        background-size: 16px 27px;
      }
      span{
        color: #fff600;
      }
    }
    @media (max-width: 750px) {
      padding: 0 8vw;
      a {
        font-size: 1.6em !important;
        > div {
          background-size: 0.4em auto;
        }
      }
    }
  `,

	EventWrap: styled.div`
    /* font-family: Pretendard; */
		font-size: 2rem; //
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    color: #555;
    letter-spacing: -0.035em;

		.blind {
			overflow: hidden;
			position: absolute;
			clip: rect(0 0 0 0);
			width: 1px;
			height: 1px;
			margin: -1px;
		}

    .inner {
      position: relative;
			max-width: 750px;
      margin: auto;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: 100%;
    }

    .visual {
      background-image: url(${visualBg});
      background-position: 50% 0;
      background-repeat: repeat-x;
      background-size: auto 100%;
			.inner {
        padding-top: 1210px;
        background-size: cover;
        background-image: url(${visualImg});
				z-index:2;
      }
    }
    &.ko .visual {
      .inner{
        background-image:url(${visualImgKo});
      }
    }

    .sticky {
      background-color: #6100b5;
      .btnWrap {
        background-color: #6100b5;
        text-align: center;
        &.sticky {
          position: fixed;
          top: 9rem;
          left: 0;
          width: 100%;
          z-index: 90;
        }
        a {
          max-width: 750px;
          padding: 1em;
          font-size: 2em;
        }
      }
    }

    .whatis {
      padding-bottom: 4em;
      background-color: #f3e8ff;
      .inner {
        padding-top: 1409px;
        background-image: url(${contImg});
      }
    }
    &.ko .whatis {
      .inner{
        padding-top: 1225px;
        background-image:url(${contImgKo});
      }
    }

    .steps {
      padding-bottom: 4em;
      background-color: #a162e0;
      .inner {
        padding-top: 506px;
        background-image: url(${contImg2});
      }
    }
    &.ko .steps {
      .inner{
        padding-top: 497px;
        background-image:url(${contImg2Ko});
      }
    }

    .registration {
      padding-bottom: 4em;
      background-color: #ffce38;
      .inner {
        padding-top: 1303px;
        background-image: url(${contImg3});
      }
    }
    &.ko .registration {
      .inner{
        padding-top: 1263px;
        background-image:url(${contImg3Ko});
      }
    }

    .lecture {
      padding-bottom: 4em;
      background-color: #f6f6f6;
      .inner {
        padding-top: 806px;
        background-image: url(${contImg4});
      }
      .btnWrap {
        margin-top: 70px;
        a {
          background-color: #333;
        }
      }
    }
    &.ko .lecture {
      .inner{
        padding-top: 772px;
        background-image:url(${contImg4Ko});
      }
    }

    .register {
      background-color: #e062cd;
      .inner {
        position: relative;
        padding-top: 580px;
        background-image: url(${contImg5});
      }
      .btnWrap {
        position: absolute;
        left: 0;
        top: 380px;
        width: 100%;
        a {
          background-color: #6100b5;
        }
      }
    }
    &.ko .register {
      .inner{
        padding-top: 560px;
        background-image:url(${contImg5Ko});
      }
      .btnWrap {
        top: 360px;
      }
    }

    @media (max-width: 750px) {
      .visual {
        .inner {
          padding-top: 161.3%;
        }
      }

      .whatis {
        .inner {
          padding-top: 187.8%;
        }
      }
      &.ko .whatis {
        .inner{
          padding-top: 163.3%;
        }
      }

      .steps {
        .inner {
          padding-top: 67.5%;
        }
      }
      &.ko .steps {
        .inner{
          padding-top: 66.3%;
        }
      }

      .registration {
        .inner {
          padding-top: 173.7%;
        }
      }
      &.ko .registration {
        .inner{
          padding-top: 168.4%;
        }
      }

      .lecture {
        .inner {
          padding-top: 107.5%;
        }
        .btnWrap {
          margin-top: 9vw;
        }
      }
      &.ko .lecture {
        .inner{
          padding-top: 102.9%;
        }
      }

      .register {
        .inner {
          padding-top: 77.3%;
        }
        .btnWrap {
          top: 51vw;
        }
      }
      &.ko .register {
        .inner{
          padding-top: 74.6%;
        }
        .btnWrap {
          top: 48vw;
        }
      }
    }

    .notification {
      padding: 4em 4%;
      background-color: #2f233a;
      text-align: left;
      color: #fff;
      font-weight: 300;
      letter-spacing: normal;
      h3 {
        text-align: center;
        font-size: 2.8rem;
        font-weight: 700;
      }
      dl {
        font-size: 2.2rem;
      }
      dt {
        margin: 2em 0 0.5em;
        font-weight: 700;
				a {
					border-bottom: none;
				}
      }
      dd {
        position:relative;
        padding-left: 0.8em;
        margin-bottom: 0.5em;
        &:before {
          content:"";
          display:inline-block;
          position: absolute;
          top:0.5em;
          left:0;
          width: 0.25em;
          height: 0.25em;
          border-radius: 0.2em;
          background: #fff;
          vertical-align:middle;
        }
        em {
          color: #fff799;
        }
      }
      P {
        padding: 1.6em 0 0.8em;
      }
      a {
        display: inline-block;
        border-bottom: 1px #fff solid;
        line-height: 1.2;
      }
    }
  `,
};

export default StyledEventPage;

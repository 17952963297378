import styled from 'styled-components';
import { IoIosArrowDown } from 'react-icons/io';

const StyledResourceSelect = {
  SelectContainer: styled.span.attrs((props) => ({
    children: (
      <>
        {props.children}
        <IoIosArrowDown />
      </>
    ),
  }))`
    float: right;
    position: relative;
    width: 12.2em;
    margin-bottom: 1.8em;
    border: 1px #5c5f66 solid;
    border-radius: 0.6rem;
    background-color: transparent;
    cursor: pointer;

    svg {
      position: absolute;
      top: 50%;
      right: 5%;
      font-size: 1.4em;
      color: #d1d1d1;
      transform: translate(-50%, -50%);
    }
  `,
  Input: styled.input`
    display: inline-block;
    width: 100%;
    padding: 0.6em 1em;
    border: none;
    background: transparent;
    color: #d1d1d1;
    font-size: 1.4em;
    outline: none;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  `,
  HiddenList: styled.ul`
    display: ${({ show }) => (show ? 'block' : 'none')};
    position: absolute;
    top: 3.8em;
    left: -0.1em;
    overflow-y: auto;
    width: 12.2em;
    max-height: 150px;
    border: 1px #5c5f66 solid;
    border-top: none;
    border-radius: 0 0 6px 6px;
    background-color: #0f1012;
    z-index: 10;

    @media (max-width: 767px) {
      left: -0.2em;
    }

    li {
      padding: 0.6em 1em;
      color: #d1d1d1;
      font-size: 1.39em;

      &:hover {
        background: #2b2e35;
      }
    }
  `,
};

export default StyledResourceSelect;

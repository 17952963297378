import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { StyledLibrarySearch } from 'container/LibraryPage/styled/libraryInput.style';
import Select from 'react-select';
import SearchFilterBox from './SearchFilterBox';
import useClickOutside from 'common/hook/useOutsideClick';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';

const Style = {
  Container: styled.div`
    margin-bottom: 8em;
    display: flex;
    > span {
      flex: 0.85;
      @media (max-width: 767px) {
        flex: 0.75;
      }
      @media (max-width: 375px) {
        flex: 0.65;
      }
    }
    > div {
      flex: 0.15;
      @media (max-width: 767px) {
        flex: 0.25;
      }
      @media (max-width: 375px) {
        flex: 0.35;
      }
    }
  `,
  Select: styled(Select).attrs({
    classNamePrefix: 'select',
  })`
    font-size: 1.6em;

    .select__control {
      &--is-focused {
        border: none;
        background-color: #1e2024;
        box-shadow: none;
      }
      background-color: #1e2024;
    }
    .select__indicator-separator {
      display: none;
    }
    .select__indicator {
      color: #666;
    }
    > div {
      background-color: #1e2024;
      border: none;
      min-height: 3.375em;
    }
    .select__single-value {
      color: #c7cad1;
      font-weight: 500;
      font-size: 0.9em;
    }
    .select__menu {
      z-index: 100;
    }

    .select__option {
      width: 100%;
      height: 2.5em;
      color: #c7cad1;
      font-size: 0.9em;
      font-weight: 400;
      letter-spacing: 0.05em;
      &--is-focused {
        background-color: #1e2024;
      }

      &:active,
      &:focus,
      &--is-selected {
        background-color: #1f2024;
        color: #55575e;
      }
      @media (max-width: 767px) {
        font-size: 1em;
      }
    }
  `,
};

const CommunitySearchContainer = ({ setSearchWords, setSelect }) => {
  const { t } = useTranslation('communityPage');
  const [searchList, setSearchList] = useState(false);
  const [searchWord, setSearchWord] = useState('');
  const [selectIdx, setSelectIdx] = useState(0);
  const inputRef = useRef();

  const onSearch = (text) => {
    setSearchWord(text);
    setSearchList((prev) => !prev);
    setSearchWords(text);
  };
  useClickOutside(inputRef, () => {
    setSearchList(false);
  });

  const options = [
    { value: 'All', label: 'All' },
    { value: 'Title', label: 'Title' },
    { value: 'Content', label: 'Content' },
    { value: 'Author', label: 'Author' },
    { value: 'Comment', label: 'Comment' },
  ];

  const onSelect = (e) => {
    const newIndex = options.findIndex(({ value }) => {
      return value === e.value;
    });
    setSelectIdx(newIndex);
    setSelect(options[newIndex].value);
  };
  return (
    <Style.Container>
      <StyledLibrarySearch.InputContainer ref={inputRef}>
        <StyledLibrarySearch.SearchImage />
        <StyledLibrarySearch.Input
          value={searchWord}
          onClick={() => setSearchList((prev) => !prev)}
          onChange={(event) => {
            onSearch(event.target.value);
          }}
          placeholder={t('postTable.placeholder')}
        ></StyledLibrarySearch.Input>

        {
          <SearchFilterBox
            searchList={searchList}
            onSearch={onSearch}
            searchWord={searchWord && searchWord}
          />
        }
      </StyledLibrarySearch.InputContainer>
      <Style.Select
        value={options[selectIdx]}
        options={options}
        onChange={(e) => onSelect(e)}
        isSearchable={false}
        classNamePrefix='select'
      />
    </Style.Container>
  );
};

export default CommunitySearchContainer;

import React from 'react';
import Style from './styled/Tab.style';
const Tab = ({ index, t }) => {
  const MenuList = [
    t('processBar.step1'),
    t('processBar.step2'),
    t('processBar.step3'),
  ];
  return (
    <Style.Container>
      {MenuList.map((data, idx) => {
        return (
          <Style.Menu key={idx} className={index === idx && 'active'}>
            {data}
          </Style.Menu>
        );
      })}
    </Style.Container>
  );
};

export default Tab;

import { UserStatus } from 'common/constant';
import {
  createReducer,
  createSetValueAction,
  setValueReducer,
} from 'common/redux-helper';
import { setCookie, getCookie, deleteCookie } from 'common/util/cookie';

export const Types = {
  SetValue: 'user/SetValue',
  FetchLogin: 'user/FetchLogin',
  FetchLoginFacebook: 'user/FetchLoginFacebook',
  SetAuth: 'user/SetAuth',
  SetUser: 'user/SetUser',
  FetchUser: 'user/FetchUser',
  FetchLogout: 'user/FetchLogout',
  InitUser: 'user/InitUser',
};

export const actions = {
  setValue: createSetValueAction(Types.SetValue),
  fetchLogin: (username, password) => ({
    type: Types.FetchLogin,
    username,
    password,
  }),
  fetchLoginFacebook: (token, email, id) => ({
    type: Types.FetchLoginFacebook,
    token,
    email,
    id
  }),
  setAuth: (token) => ({
    type: Types.SetAuth,
    token,
  }),
  setUser: (data) => ({
    type: Types.SetUser,
    data,
  }),
  fetchUser: (token) => ({
    type: Types.FetchUser,
    token,
  }),
  fetchLogout: () => ({ type: Types.FetchLogout }),
  initUser: () => ({
    type: Types.InitUser,
  }),
};

const INITIAL_STATE = {
  keyword: '',
  token: '',
  status: undefined,
  data: undefined,
  redirectUrl: undefined,
};

const userReducer = createReducer(INITIAL_STATE, {
  [Types.SetValue]: setValueReducer,
  [Types.SetAuth]: (state, action) => {
    state.token = action.token;
    state.status = UserStatus.Auth;
    if (action.token) {
      setCookie('MEMCookie', action.token, {
        path: '/',
        secure: true,
        sameSite: 'none',
      });
    }
  },
  [Types.SetUser]: (state, action) => {
    state.data = action.data;
    state.status = UserStatus.Login;
    state.redirectUrl = null;
  },
  [Types.FetchLogout]: (state) => {
    state.data = '';
    state.token = '';
    state.status = UserStatus.NotLogin;
    if(getCookie('MEMCookie')){
      deleteCookie('MEMCookie')
    }
  },
  [Types.InitUser]: (state) => {
    // if (!state.token || !state.data) {
    //   state.data = '';
    //   state.token = '';
    //   state.status = UserStatus.NotLogin;
    // }
  },
});

export default userReducer;

import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import styled from 'styled-components';
import Loading from 'component/atoms/Loading';

const Wrapper = styled.div`
  clear: both;
  position: relative;
  width: calc(100% + 4rem);
  margin-left: -2rem;
  transition: all 0.2s ease;
  &:after {
    content: '';
    clear: both;
    display: table;
  }
  .infinite-scroll-component {
    > *:not(.loading-container) {
      float: left;
      width: calc(25% - 4rem);
      margin: 0 2rem 6rem;
    }
  }
  @media (max-width: 1024px) {
    .infinite-scroll-component {
      > *:not(.loading-container) {
        width: calc(25% - 20px);
        margin: 0 10px 4rem;
      }
    }
  }
  @media (max-width: 767px) {
    width: calc(100% + 10px);
    margin-left: -5px;
    .infinite-scroll-component {
      > *:not(.loading-container) {
        width: calc(50% - 10px);
        margin: 0 5px 8rem;
      }
    }
  }
`;

/**
 * 4단 나열 무한 스크롤 목록 - InfiniteScroll의 인접한 자식들 기준으로 나열, 여백 포함 (기본 margin-bottom: 4rem)
 * @param {*} param
 * @param {number} param.dataLength 아이템 갯수
 * @param {func} param.next fetch function
 * @param {boolean} param.hasMore next 호출 여부
 * @param {string=} param.className set wrapper className
 * @param {*} param.children
 */
const InfiniteList = ({ dataLength, next, hasMore, className, children }) => {
  return (
    <Wrapper className={className}>
      <InfiniteScroll
        dataLength={dataLength}
        next={next}
        loader={<Loading />}
        hasMore={hasMore}
      >
        {children}
      </InfiniteScroll>
    </Wrapper>
  );
};

InfiniteList.propTypes = {
  dataLength: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

export default InfiniteList;

import styled from 'styled-components';

const StyledTeacherSection = {
  Container: styled.div`
    width: 100%;
    padding: 8.2rem 0 7.2rem;
    /* background-color: #1e2024; */
  `,

  H2: styled.h2`
    font-size: 3em;
    font-weight: 700;
  `,
    Slg: styled.p`
    font-size: 1.8em;
    margin-top:2rem;
    white-space: pre-wrap;
  `,
  IntroBox: styled.div`
      margin-top:3.5rem;
      &.first{
        margin-top:0;
      }
    `,
  lists: styled.ul`
    margin-top:2rem
  `,
  list: styled.li`
    font-size: 1.6em;
    position:relative;
    padding-left: 1.5rem;
    color:#c9cbd1;
    &::before{
      position: absolute;
      left:0;
      top:1rem;
      width:0.5rem;
      height:0.5rem;
      content:"";
      display: block;
      background:#8e9199;
      border-radius:0.5rem;;
      vertical-align: middle;
    }
  `,


  Content: styled.div`
    display: flex;
    position: relative;
    margin-top: 3.3em;
    img {
      width: 20em;
      height: 20em;
      object-fit: cover;
      border-radius: 50%;
      @media (max-width: 767px) {
        width: 15em;
        height: 15em;
      }
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `,

  ContentDesc: styled.div`
    margin-left: 4em;
    @media (max-width: 767px) {
      margin-left: 0;
      font-size: 1.25em;
    }
    div {
      @media (max-width: 767px) {
        position: absolute;
        top: 0;
        left: 15em;
      }
      h3 {
        display: inline-block;
        margin-right: 0.5em;
        color: #ffb500;
        font-size: 2.6em;
        font-weight: 900;
      }
      span {
        display: inline-block;
        position: relative;
        padding-left: 1em;
        color: #c9cbd1;
        font-size: 1.6em;
        font-weight: 400;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          height: 0.875em;
          width: 1px;
          background-color: #666;
          transform: translateY(50%);
        }
        @media (max-width: 767px) {
          display: block;
          margin-top: 0.5em;
          padding-left: 0;

          &::before {
            display: none;
          }
        }
      }
    }

    p {
      max-width: 59em;
      color: #787b82;
      font-size: 1.6em;
      font-weight: 400;
      margin-top: 1.5em;
    }
  `,
};

export default StyledTeacherSection;

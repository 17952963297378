import React, { useEffect, useState } from 'react';
import TabMenu from 'component/TabMenu';
import WorkbookTab from './WorkbookTab';
import QuizzesTab from './QuizzesTab';
import { useAxios } from 'common/hook/useAxiosHook';
import { fetchWorkBook } from 'container/CourseDetailPage/api/courseDetailApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CourseViewContentsTab = () => {
  const { t } = useTranslation('viedoDetailPage');
  const { productId } = useParams();
  const token = useSelector((state) => state.user.token);

  const [menuList, setMenuList] = useState([]);
  const [contentsList, setContentsList] = useState([]);

  const [{ data: workBookData }, refreshWorkBook] = useAxios(
    fetchWorkBook,
    [],
    true
  );

  useEffect(() => {
    refreshWorkBook(productId, token);
  }, [refreshWorkBook, productId, token]);

  useEffect(() => {
    if (workBookData) {
      setMenuList([t('workbookTab.title')]);
      // setMenuList(['t('workbookTab.title'), t('quizzesTab.title')]);
      setContentsList([
        <WorkbookTab
          productId={productId}
          workBookList={workBookData}
          token={token}
        />,
        // <QuizzesTab />,
      ]);
    }
  }, [workBookData]);

  return <TabMenu menuList={menuList} contentsList={contentsList} />;
};

export default CourseViewContentsTab;

import styled from 'styled-components';
import playIcon from 'assets/images/ico_play.png';
import playIconSmall from 'assets/images/ico_play_small.png';
import Img from 'component/atoms/Img';

const StyledVideoThumb = {
  Wrapper: styled.div.attrs(({ children, percent }) => ({
    children: (
      <>
        {children}
        <span className='overlay'></span>
        {percent > 0 && (
          <div className='progress'>
            <div style={{ width: `${percent}%` }}>{percent}%</div>
          </div>
        )}
      </>
    ),
  }))`
    position: relative;
    width: 100%;
    margin-bottom: 1em;
    border-radius: 0.6rem;
    overflow: hidden;
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      /* background-color: rgba(0, 0, 0, 0.4); */

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 19px;
        height: 26px;
        background-image: url(${playIcon});
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        transform: translate(-50%, -50%) scale(1);
        transition: all 0.1s linear;
      }
    }
    .progress {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: rgba(0, 0, 0, 0.4);
      > div {
        height: 4px;
        border-radius: 6px;
        background-color: #ffb500;
        line-height: 99;
        overflow: hidden;
      }
    }
  `,
  Img: styled(Img)`
    width: 100%;
  `,
};

export default StyledVideoThumb;

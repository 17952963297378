import React from 'react';
import Styled from './index.style';
import Img from 'component/atoms/Img';
import { masterTopikUrl } from 'common/constant';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';

const swiperBreakPoint = {
  1025: { slidesPerView: 3, spaceBetween: 20, centeredSlides: true },
  768: { slidesPerView: 2.5, spaceBetween: 20 },
  600: { slidesPerView: 2.5, spaceBetween: 10 },
  0: { slidesPerView: 1.5, spaceBetween: 10 },
};

SwiperCore.use([Navigation]);

export default function Teachers({ initialState, t }) {
  const [teacherListState] = initialState;
  const teacherList = teacherListState.data;

  if (teacherList != null) {
    return (
      <Styled.ShadeWrapper>
        <Styled.Wrapper>
          <Styled.Swiper
            breakpoints={swiperBreakPoint}
            navigation
            loop
            loopedSlides={10}
          >
            {teacherList.map((item, index) => (
              <SwiperSlide key={index}>
                <Styled.Link
                  to={`teachers/${item.id}`}
                  text={t('teachers.button')}
                >
                  <Img
                    src={item.thumbnail && masterTopikUrl + item.thumbnailBig}
                    alt={item.firstName + item.lastName}
                  />
                  <Styled.Texts>
                    <Styled.Name>{item.firstName + item.lastName}</Styled.Name>
                    <Styled.Category>{item.levelType}</Styled.Category>
                  </Styled.Texts>
                </Styled.Link>
              </SwiperSlide>
            ))}
          </Styled.Swiper>
        </Styled.Wrapper>
      </Styled.ShadeWrapper>
    );
  } else {
    return null;
  }
}

import React, { useState, useEffect, useRef } from "react";
import Style from "./styled/MTLIve.style";
import Plyr from "plyr-react";
import "plyr-react/dist/plyr.css";
import { fetchVideoList, fetchLiveVideo, fetchUpcoming } from "api/youtube";
import PageHeader from "component/atoms/PageHeader";
import VideoThumb from "component/MTLive/VideoThumb";
import { SeoData } from "common/seoData";
import SeoHelmet from "component/Helmet";
import { useTranslation } from "react-i18next";

const MTLive = ({ isMyPage = false }) => {
  const { t } = useTranslation("resourcesPage");
  const [playListVideos, setPlayListVideos] = useState(null);
  const [liveVideo, setLiveVideo] = useState(null);
  const [upcoming, setUpcoming] = useState(null);

  const channelThumbnail =
    "https://yt3.ggpht.com/ytc/AAUvwniekCoBF2bNE1lywFhzHuxQ9rBNmiLBqZ4atWW-Vg=s88-c-k-c0x00ffffff-no-rj";
  const videoRef = useRef(null);

  const touchVideoScreen = () => {
    if (!videoRef && !videoRef?.current?.plyr) return;
    if (videoRef?.current?.plyr.playing) {
      videoRef?.current?.plyr.pause();
      return;
    } else {
      videoRef?.current?.plyr.play();
      return;
    }
  };

  useEffect(() => {
    // let PlayListData = async () => {
    //   let response = await fetchVideoList();
    //   setPlayListVideos(response);
    // };
    // let LiveVideoData = async () => {
    //   let response = await fetchLiveVideo();
    //   setLiveVideo(response);
    // };
    if (videoRef?.current?.plyr?.playing) {
      videoRef?.current?.plyr.pause();
    }
    let upComingData = async () => {
      let response = await fetchUpcoming();
      setUpcoming(response);
    };
    // PlayListData();
    // LiveVideoData();
    upComingData();
  }, [videoRef?.current?.plyr.playing]);

  console.log(
    videoRef?.current?.plyr.playing,
    videoRef?.current?.plyr.paused,
    videoRef?.current?.plyr
  );
  return (
    <div>
      <SeoHelmet title={SeoData.MTLive.title} desc={SeoData.MTLive.desc} />

      {!isMyPage && (
        <Style.HeadingContainer>
          <h3>{t("mtLiveTab.title")}</h3>
          <p>{t("mtLiveTab.subtitle")}</p>
          {upcoming && <span>UPCOMING - {upcoming}</span>}
        </Style.HeadingContainer>
      )}

      {isMyPage && (
        <Style.HeadingContainer>
          <PageHeader
            title={t("mtLiveTab.title")}
            text={t("mtLiveTab.subtitle")}
          />
          {upcoming && <span>UPCOMING - {upcoming}</span>}
        </Style.HeadingContainer>
      )}

      <Style.VideContainer
        style={{ marginBottom: "15em" }}
        onTouchStart={touchVideoScreen}
      >
        {
          <>
            <div className="video">
              <Plyr
                ref={videoRef}
                source={{
                  type: "video",
                  title: "master Topik",
                  sources: [
                    {
                      src: "q5MdONKNcuQ",
                      provider: "youtube",
                    },
                  ],
                }}
              />

              <VideoThumb videoRef={videoRef} />
            </div>
            {
              //<h3>{liveVideo.snippet.title}</h3>
            }
            <h3>
              {
                "Hangeul Song Cover Contest │Award Ceremony"
              }
            </h3>
            <div className="info">
              <Style.ChannelImg src={channelThumbnail} />
              <div className="info-content">
                <span className="channel-name">masterTOPIK</span>
                <span className="badge">LIVE</span>
                {
                  // <span className='viewer'>
                  //   {liveVideo.liveStreamingDetails.concurrentViewers} viewers
                  // </span>
                }
              </div>
            </div>
          </>
        }
      </Style.VideContainer>

      {/* <Style.PastVideosContainer>
        <h3>Past Video</h3>
        {playListVideos &&
          playListVideos.map(({ id, snippet, statistics }, index) => {
            const data = {
              videoId: id,
              thumbnail: snippet.thumbnails.medium.url,
              channelTitle: snippet.channelTitle,
              description: snippet.description,
              title: snippet.title,
              views: statistics.viewCount,
              publishedAt: snippet.publishedAt,
            };

            return (
              <Style.PastVideo key={index}>
                <a href={`https://www.youtube.com/watch?v=${data.videoId}`} target="_blank" rel="noreferrer">
                  <Style.PastVideoImageContainer>
                    <div className="overlay"></div>
                    <Style.PastVideoImage src={data.thumbnail} alt="youtube-image" />
                  </Style.PastVideoImageContainer>
                  <div className="video-content">
                    <h4>{data.title}</h4>
                    <span className="views">
                      {data.views} views &middot;
                      {data.publishedAt.slice(0, 10)}
                    </span>
                    <p>{data.description}</p>
                    <span className="channel-profile ">
                      <img src={channelThumbnail} alt="channel profile" />
                      {data.channelTitle}
                    </span>
                  </div>
                </a>
              </Style.PastVideo>
            );
          })}
      </Style.PastVideosContainer> */}
    </div>
  );
};

export default MTLive;

import React, { useState, useEffect } from 'react';
import { SwiperSlide } from 'swiper/react';
import Loading from 'component/atoms/Loading';
import Error from 'component/Error';
import SwiperList from 'component/molecules/SwiperList';
import VideoItem from 'component/molecules/VideoItem';
import { EmptyList } from 'component/atoms/EmptyList';
import { masterTopikUrl } from 'common/constant';

const CoursesList = ({ id, initialState, setTotal, token }) => {
  const [themeList, fetchThemeList] = initialState;
  const [slideItems, setSlideItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { loading, data, error } = themeList;

  useEffect(() => {
    fetchThemeList({ page: 0, token });
  }, []);

  useEffect(() => {
    if (data !== null) {
      setTotal(data.totalElements);
      setSlideItems([...slideItems, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [themeList]);

  const onNext = () => {
    if (!loading && hasMore) {
      fetchThemeList({ page: data.number + 1, token });
    }
  };

  if (error) return <Error error={error} />;

  return (
    <>
      {slideItems.length === 0 ? (
        <EmptyList isLoading={loading} />
      ) : (
        <SwiperList slideItemList={slideItems} onNext={onNext}>
          {slideItems.map((item, index) => (
            <SwiperSlide key={index}>
              <VideoItem
                title={item.title}
                imgSrc={masterTopikUrl + item.thumbnailPath}
                desc={item.chapterLectureTitle}
                to={`/courses/detail/${item.productId}`}
                videoLink={`/courses/detail/${item.productId}/${item.chapterLectureId}`}
                idNew={item.isNew}
                percent={item.progressPercent}
              />
            </SwiperSlide>
          ))}
        </SwiperList>
      )}
    </>
  );
};

export default CoursesList;

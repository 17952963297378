import styled from 'styled-components';
import { IconTextStyles } from 'component/atoms/Icon';
import iconHeart from 'assets/images/ico_heart.png';
import iconHeartLarge from 'assets/images/ico_heart_large.png';
import iconHeartColored from 'assets/images/ico_heart_colored.png';
import iconHeartColoredLarge from 'assets/images/ico_heart_colored_large.png';

const LikeIcon = styled.span`
  ${IconTextStyles}
  background-image: url(${iconHeart});
  transition: background-image 0.3s ease;
  &.liked {
    background-image: url(${iconHeartColored});
  }
  @media (max-width: 1024px) {
    background-image: url(${iconHeartLarge});
    &.liked {
      background-image: url(${iconHeartColoredLarge});
    }
  }
`;

export default LikeIcon;

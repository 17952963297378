import { css } from 'styled-components';

const StyledUtil = {
  textEllipsis: css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,
  lineClamp: css`
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${({ line }) => (line ? line : 2)};
    -webkit-box-orient: vertical;
  `,
  Bar: css`
    display: inline-block;
    width: 1px;
    height: 10px;
    margin: 0 1.5rem;
    background-color: #2b2e35;
    vertical-align: 0.1em;
  `,
};

export default StyledUtil;

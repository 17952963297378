import React from 'react';
import StyledActivityInsight, {
  StyledCurriculumProgress as Styled,
} from 'container/UserHomePage/styled/ActivityInsight.style';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';

SwiperCore.use([Navigation]);

const swiperBreakPoint = {
  1025: { slidesPerView: 10.5, slidesPerGroup: 5 },
  0: { slidesPerView: 6.5, slidesPerGroup: 3 },
};

export default function CurriculumProgress({ data }) {
  const { t } = useTranslation('userHomePage');

  const weekArray = data
    ? Array.from({ length: data.entireWeek }, (_, startNo) => startNo + 1)
    : null;
  return (
    <Styled.Wrapper className='curriculum'>
      <Link to='/classroom/curriculum' className='curriculumLink'>
        <FiPlus size='2.3em' />
      </Link>
      <StyledActivityInsight.H3>
        {t('dashBoard.chartSection.chartName')}
      </StyledActivityInsight.H3>
      {data && (
        <>
          <Styled.Box className='mobile-hidden'>
            <Styled.Title.Wrapper>
              <Styled.Title.Top>Title</Styled.Title.Top>
              <Styled.Title.List>
                {data.curriculumCourseResponses.map(({ title, id }) => (
                  <Styled.Title.Item key={id} href={`/courses/detail/${id}`}>
                    {title}
                  </Styled.Title.Item>
                ))}
              </Styled.Title.List>
            </Styled.Title.Wrapper>
            <Styled.Timeline.Swiper
              breakpoints={swiperBreakPoint}
              slidesPerGroup={6}
              navigation
            >
              {weekArray &&
                weekArray.map((week, index) => (
                  <SwiperSlide
                    key={index}
                    className={week === data.userNowWeek ? 'current-week' : ''}
                  >
                    <Styled.Timeline.Top>{week}w</Styled.Timeline.Top>
                    <Styled.Timeline.List>
                      {data.curriculumCourseResponses.map(
                        ({ beginWeek, endWeek }, subIndex) => (
                          <Styled.Timeline.Item
                            key={subIndex}
                            week={beginWeek === week && endWeek - beginWeek + 1}
                          />
                        )
                      )}
                    </Styled.Timeline.List>
                  </SwiperSlide>
                ))}
            </Styled.Timeline.Swiper>
          </Styled.Box>
          <Styled.Timeline.Mobile
            week={data.userNowWeek}
            totalWeek={data.entireWeek}
          />
        </>
      )}
    </Styled.Wrapper>
  );
}

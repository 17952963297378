import React from 'react';
import Styled from './index.style';
import MainContentsStyled from '../index.style';
import Img from 'component/atoms/Img';

import companyImg from 'assets/images/main_company.png';
import pronunciationImg from 'assets/images/main_pronunciation.png';
import { Trans } from 'react-i18next';

export default function Features({ t }) {
  return (
    <>
      <Styled.Wrapper>
        <Styled.Texts>
          <MainContentsStyled.H2>
            <Trans t={t}>features.ai.title</Trans>
          </MainContentsStyled.H2>
          <Styled.Desc>
            <Trans t={t}>features.ai.desc</Trans>
          </Styled.Desc>
        </Styled.Texts>
        <Styled.ImgWrapper>
          <Styled.PronunciationImg
            src={pronunciationImg}
            alt='AI Pronunciation Evaluation'
          />
        </Styled.ImgWrapper>
      </Styled.Wrapper>
      <Styled.Wrapper>
        <Styled.Texts>
          <MainContentsStyled.H2>
            <Trans t={t}>features.company.title</Trans>
          </MainContentsStyled.H2>
          <Styled.Desc>
            <Trans t={t}>features.company.desc</Trans>
          </Styled.Desc>
        </Styled.Texts>
        <Styled.ImgWrapper>
          <Img src={companyImg} alt='top education company in Korea' />
        </Styled.ImgWrapper>
      </Styled.Wrapper>
    </>
  );
}

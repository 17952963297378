import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import iconArrow from 'assets/images/ico_leveltest_arrow.png';
import iconArrow02 from 'assets/images/ico_leveltest_arrow02.png';
import iconChecked from 'assets/images/ico_leveltest_checked.png';
import logo from 'assets/images/ico_leveltest.png';

const btnStyle = css`
  position: relative;
  margin: 0 auto;
  padding: 1.125em;
  border: 1px solid #485cc7;
  border-radius: 6px;
  color: #fff;
  font-size: 1.6em;
  font-weight: 600;
`;

const Button = {
  ButtonGroup: styled.div.attrs(({ children }) => ({
    children: <>{children}</>,
  }))`
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }
    &.allCurriculum {
      border-top: 1px solid #2b2e35;
    }
  `,
  Button: styled.button.attrs(({ children }) => ({
    type: 'button',
    children: <>{children}</>,
  }))`
    ${btnStyle}
    display: block;
    min-width: 21.25em;
    background-color: #485cc7;

    &:disabled,
    &.disabled {
      cursor: default;
      background-color: #2b2e35;
      border-color: #2b2e35;
      color: #5c5e62;
    }

    & + button {
      margin-top: 0.75em;
    }
  `,
  ReturnButton: styled.button.attrs(({ children }) => ({
    type: 'button',
    children: <>{children}</>,
  }))`
    position: relative;
    display: inline-block;
    padding: 1em 0 1em 2em;
    color: #8b8f99;
    font-size: 1.6em;
    font-weight: 600;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 1em;
      height: 1em;
      background: url(${iconArrow}) no-repeat center;
      transform: translateY(-50%);
    }
  `,
  QuizButton: styled.button.attrs(({ children }) => ({
    type: 'button',
    children: <>{children}</>,
  }))`
    ${btnStyle}
    display: inline-block;
    min-width: 10em;
    margin-left: 1em;
    background-color: #485cc7;
  `,
  QuizEndButton: styled.button.attrs(({ children }) => ({
    type: 'button',
    children: <>{children}</>,
  }))`
    ${btnStyle}
    display: inline-block;
    min-width: 10em;
    margin-left: 1em;
    border-color: #8b8f99;
    color: #8b8f99;
  `,
  MoreButton: styled(NavLink)`
    position: relative;
    display: inline-block;
    padding: 1em 2em 1em 0;
    color: #eeb221;
    font-size: 1.6em;
    font-weight: 600;

    svg {
      margin-left: 0.5em;
      vertical-align: middle;
    }
  `,
  Link: styled(NavLink)`
    ${btnStyle}
    display: block;
    max-width: 21.25em;
    background-color: #485cc7;
    line-height: 1em;

    &:disabled,
    &.disabled {
      cursor: default;
      background-color: #2b2e35;
      border-color: #2b2e35;
      color: #5c5e62;
    }

    & + button {
      margin-top: 0.75em;
    }
  `,
  ReturnLink: styled(NavLink)`
    position: relative;
    display: inline-block;
    padding: 1em 0;
    color: #8b8f99;
    font-size: 1.6em;
    font-weight: 600;

    svg {
      margin-right: 0.5em;
      vertical-align: middle;
    }
  `,
  QuizLink: styled(NavLink)`
    ${btnStyle}
    display: inline-block;
    min-width: 10em;
    margin-left: 1em;
    background-color: #485cc7;
    line-height: 1em;

    background-color: inherit;
    border-color: #8b8f99;
    color: #8b8f99;
    text-align: center;
  `,
  SelectedSubjectGroup: styled.div`
    margin: 5.3em auto 13.6em;
  `,
  SelectedSubject: styled.button.attrs(({ children }) => ({
    type: 'button',
    children: <>{children}</>,
  }))`
    position: relative;
    display: block;
    margin: 20px auto;
    padding: 1.125em 4em;
    min-width: 21.25em;
    border: 1px solid #fff;
    border-radius: 6px;
    text-align: center;
    font-size: 1.6em;
    line-height: 1.063em;
    color: #fff;

    &.active {
      color: #ffb500;
      border-color: #ffb500;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 1em;
        height: 1.5em;
        width: 1.5em;
        background: url(${iconChecked}) no-repeat 0 0 / 100%;
        transform: translateY(-50%);
      }
    }
  `,
  SelectedLevelGroup: styled.div`
    display: flex;
    width: 44em;
    height: 19em;
    margin: 4.3em auto 2em;
    padding-bottom: 1px;
    justify-items: center;
    flex-wrap: wrap;
  `,
  SelectedLevel: styled.button.attrs(({ children }) => ({
    type: 'button',
    children: <>{children}</>,
  }))`
    position: relative;
    display: flex;
    width: calc(25% + 1px);
    margin: 0 0 -1px -1px;
    background-color: #2b2e35;
    border: 1px solid #1e2024;
    font-size: 2.4em;
    font-weight: 600;
    color: #8b8f99;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:first-child {
      border-radius: 6px 0 0 0;

      span {
        display: inline-block;
        line-height: 500px;
        overflow: hidden;
        width: 1.083em;
        height: 0.917em;
        background: url(${logo}) no-repeat center;
        opacity: 0.2;
      }
    }

    &:nth-child(4) {
      border-radius: 0 6px 0 0;
    }

    &:nth-child(5) {
      border-radius: 0 0 0 6px;
    }

    &:last-child {
      border-radius: 0 0 6px 0;
    }

    &.active {
      background-color: #191b1f;
      border-color: #ffb500;
      color: #ffb500;
      z-index: 1;
    }

    &.disabled {
      cursor: default;
      color: rgba(139, 143, 153, 0.2);
    }
  `,
};

export default Button;

import React from 'react';
import Styled from './styled/TeacherSection.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { masterTopikUrl } from 'common/constant';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';


const TeacherSection = ({ data }) => {
  const { t } = useTranslation('courseDetailPage');
  const {
    teacherResponse: { slogan, firstName, lastName, thumbnail, levelType }
  } = data;

  const imgUrl = thumbnail ? masterTopikUrl + thumbnail : null;

  return (
    <Styled.Container>
      <ContentsWrapper>
        <Styled.H2>
          <Trans t={t}>teacherSection.title</Trans>
        </Styled.H2>
        <Styled.Content>
          <img src={imgUrl} alt='profile' />
          <Styled.ContentDesc>
            <div>
              <h3>{firstName + ' ' + lastName}</h3>
              <span>{levelType}</span>
            </div>
            <p>{slogan}</p>
          </Styled.ContentDesc>
        </Styled.Content>
      </ContentsWrapper>
    </Styled.Container>
  );
};

export default TeacherSection;

import React, { useState } from 'react';
import styled from 'styled-components';
import PageSubTitle from 'component/molecules/PageSubTitle';
import { SwiperSlide } from 'swiper/react';
import SwiperList from 'component/molecules/SwiperList';
import { EmptyList } from 'component/atoms/EmptyList';
import CurriculumCard from 'component/ui/card/CurriculumCard';

const Style = styled.div`
  margin-top: 10em;
  .prevtestReport-curriculumRecommend,
  .nexttestReport-curriculumRecommend {
    top: 12em;
  }

  @media (max-width: 767px) {
    margin-top: 5em;
  }
`;

const RecommendCurriculum = ({ data }) => {
  return (
    <Style>
      <PageSubTitle title='추천 커리큘럼' />
      <SwiperList
        id='testReport-curriculumRecommend'
        slideItemList={data}
        onNext={() => null}
      >
        {data.length > 0 ? (
          data.map((item) => (
            <SwiperSlide key={item.curriculumChargeId}>
              <CurriculumCard data={item} />
            </SwiperSlide>
          ))
        ) : (
          <EmptyList />
        )}
      </SwiperList>
    </Style>
  );
};

export default RecommendCurriculum;

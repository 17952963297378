import H3 from "component/atoms/H3";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { StyledPostList as Styled } from "../index.style";

export const RecommentPosts = ({ initState }) => {
  const { t } = useTranslation("communityPage");
  const [recomentPostList, fetchRecommentPostList] = initState;
  const { loading, data, error } = recomentPostList;

  useEffect(() => {
    fetchRecommentPostList();
  }, []);

  return (
    <Styled.Wrapper>
      <H3>{t("recommentTable.title")}</H3>
      <Styled.Header>
        <Styled.Column className="post-title">{t("postTable.theader1")}</Styled.Column>
        <Styled.Column className="replier"></Styled.Column>
        <Styled.Column className="view-count">{t("postTable.theader2")}</Styled.Column>
        <Styled.Column className="replies-count">{t("postTable.theader3")}</Styled.Column>
        <Styled.Column className="reg-date">{t("postTable.theader4")}</Styled.Column>
      </Styled.Header>
      {data?.content.map((item, index) => (
        <Styled.Item key={index}>
          <Link to={`/community/${item.id}`}>
            <Styled.Column className="post-title">
              {item.title}
              <div className="post-title-desc">
                {item?.categoryCode}
                {item.categoryCode && item.productTitle && " | "}
                {item?.productTitle}
              </div>
            </Styled.Column>
            <Styled.Column className="replier">{/* <BoardReply replierList={item.replierList} /> */}</Styled.Column>
            <Styled.Column className="view-count">{item.viewCount}</Styled.Column>
            <Styled.Column className="replies-count">{item.repliesCount}</Styled.Column>
            <Styled.Column className="reg-date">
              <Moment fromNow ago>
                {item.regDate}
              </Moment>
            </Styled.Column>
          </Link>
        </Styled.Item>
      ))}
    </Styled.Wrapper>
  );
};

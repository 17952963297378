import React, { useState } from 'react';
import Tab from './Tab.js';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import ProcessPage from './ProcessPage';
import ConfirmPage from './ConfirmPage';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';

const PaymentPage = () => {
  const { t } = useTranslation('paymentPage');
  const [tabMenuIndex, setTabMenuIndex] = useState(1);
  const components = [
    null,
    <ProcessPage setTabMenuIndex={setTabMenuIndex} t={t} />,
    <ConfirmPage />,
  ];

  return (
    <ContentsWrapper>
      <SeoHelmet title={SeoData.payment.title} desc={SeoData.payment.desc} />

      <Tab index={tabMenuIndex} t={t} />

      {components[tabMenuIndex]}
    </ContentsWrapper>
  );
};

export default PaymentPage;

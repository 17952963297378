import React from 'react';
import { useLocation } from 'react-router-dom';
import Styled from './styled/GoToLibrary.style';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';

const GoToLibrary = () => {
  const { t } = useTranslation('coursePage');
  const { pathname } = useLocation();

  return (
    <>
      {pathname === '/courses' && (
        <Styled.Container>
          <Styled.H3>
            <Trans t={t}>videoTab.gotoArea.title</Trans>
          </Styled.H3>
          <Styled.Btn to='/library'>
            <Trans t={t}>videoTab.gotoArea.button</Trans>
          </Styled.Btn>
        </Styled.Container>
      )}
    </>
  );
};

export default GoToLibrary;

import styled from 'styled-components';

const StyledNotificationModal = {
  Container: styled.div`
    p {
      margin: 1.16em 0 2.6em;
      text-align: center;
      font-size: 1.8em;
      font-weight: 400;
    }
    button {
      width: 48%;
      padding: 1.125em 4.175em;
      border: 1px #ffb500 solid;
      border-radius: 0.6rem;
      background-color: #ffb500;
      color: #fff;
      font-size: 1.6em;
      font-weight: 700;
    }

    .no-btn {
      margin-left: 4%;
      border: 1px #5c5f66 solid;
      background-color: #fff;
      color: #5c5f66;
    }
  `,
};

export default StyledNotificationModal;

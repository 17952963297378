import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import H3 from 'component/atoms/H3';
import Error from 'component/Error';
import SwiperList from 'component/molecules/SwiperList';
import UserThemeItem from 'component/molecules/ThemeItem/UserThemeItem';
import { SwiperSlide } from 'swiper/react';
import { masterTopikUrl } from 'common/constant';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  margin-bottom: 10em;
  h3 {
    margin-bottom: 1em;
  }
`;

const PopularTheme = ({ initialState, token }) => {
  const { t } = useTranslation('bookmarkPage');
  const [themeItem, fetchThemeItem] = initialState;
  const { loading, data: themeItemList, error } = themeItem;

  const [slideItemList, setSlideItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => fetchThemeItem({ token }), []);

  useEffect(() => {
    const newSlideItemList = themeItem.data?.content;
    newSlideItemList &&
      setSlideItemList([...slideItemList, ...newSlideItemList]);
  }, [themeItemList]);

  const onNext = () => {
    if (!loading && currentPage + 1 < themeItemList.totalPages - 1) {
      fetchThemeItem({ token, page: currentPage + 1 });
      setCurrentPage(currentPage + 1);
    }
  };

  if (error) return <Error error={error} />;

  return (
    <Wrapper>
      <H3>{t('themeTab.themes2')}</H3>
      <SwiperList
        id='popular-theme'
        slideItemList={slideItemList}
        onNext={onNext}
      >
        {slideItemList.map((item, index) => {
          const imgSrcList = item.themeProductResponse.map(
            (itemChild) =>
              masterTopikUrl +
              itemChild?.thumbnailPath +
              itemChild?.thumbnailTitle
          );
          return (
            <SwiperSlide key={index}>
              <UserThemeItem
                id={item.id}
                imgSrcList={imgSrcList}
                title={item.name}
                tag={item.categoryName}
                videoCount={item.courseCount}
                likeCount={item.likeCount}
                userThumb={item.userProfilePhotoPath}
                userName={item.userName}
                isGood={item.isGood}
              />
            </SwiperSlide>
          );
        })}
      </SwiperList>
    </Wrapper>
  );
};
export default PopularTheme;

import React, { useEffect, useState, useCallback } from "react";
import Style from "./styled/CancelSection.style";
import { useHistory } from "react-router-dom";
import { useAxios } from "common/hook/useAxiosHook";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import plansApi from "api/plans";
import { ModalContext } from "component/Modal/modalContext";
import DefaultTextModal from "component/Modal/DefaultTextModal";
import { MOBILE_APP_USER_AGENT } from "common/constant";

const CancelSection = () => {
  let { handleModal } = React.useContext(ModalContext);
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation("cancelPage");
  const token = useSelector((state) => state.user.token);
  const history = useHistory();
  const userAgent = window.navigator.userAgent;
  const [{ data: planData }] = useAxios(() => plansApi.getPlan(token), []);

  const [{ data, error }, refreshCancelUserPlan] = useAxios(
    () => plansApi.cancelUserPlan(token),
    [],
    true
  );
  useEffect(() => {
    if (!error) return;
    if (error.status === 404) {
      handleModal(<DefaultTextModal desc={error.data.message} />);
    }
  }, [error]);

  const clickCancelBtn = useCallback(() => {
    if (!disabled) {
      setDisabled(true);
      if (userAgent === MOBILE_APP_USER_AGENT) {
        window.WebInterface?.cancelSubscription(
          `plan_${planData?.currentPlan.toLowerCase()}`
        );
      }
      refreshCancelUserPlan();
    }
  }, [disabled, planData]);

  useEffect(() => {
    if (!data) return;
    if (data.status === 201) {
      history.replace({ pathname: "/setting/cancel-survey", state: true });
    }
  }, [data]);

  return (
    <Style.Container>
      <Style.ImageSpan className="cancel" />
      <Style.Title>{t("notice1.title")}</Style.Title>
      <Style.Desc>{t("notice1.subtitle")}</Style.Desc>
      <Style.List>
        <Trans t={t} values={{ date: planData?.expireDate }}>
          notice1.detail1
        </Trans>
      </Style.List>
      <Style.List>{t("notice1.detail2")}</Style.List>
      <Style.List>{t("notice1.detail3")}</Style.List>
      <Style.Button
        className="cancel"
        onClick={clickCancelBtn}
        disabled={disabled}
      >
        {t("notice1.button1")}
      </Style.Button>
      <Style.Button onClick={() => history.push("/setting")}>
        {t("notice1.button")}
      </Style.Button>
    </Style.Container>
  );
};

export default CancelSection;

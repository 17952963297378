import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';
import H3 from 'component/atoms/H3';

import Search from 'container/HelpPage/components/Search';
import SearchWords from 'container/HelpPage/components/SearchWords';
import Banner from 'container/HelpPage/components/Banner';
import Explore from 'container/HelpPage/components/Explore';
import MainList from 'container/HelpPage/List/MainList';
import ContactUs from 'container/HelpPage/components/ContactUs';
import { useAxios } from 'common/hook/useAxiosHook';
import helpApi from 'api/help';


const HelpMainPage = () => {
  const { t } = useTranslation('helpPage');

  const [bannerItem, fetchBannerItem] = useAxios(
    helpApi.getBannerArticle,
    [],
    true
  );

  useEffect(() => {
    fetchBannerItem();
  }, [fetchBannerItem]);

  return (
    <>
      <ContentsWrapper>
        <PageHeader title={t('title')} />

        {/* 검색 */}
        <Search placeholder={t('search')} className='main' />

        {/* 추천 검색어 */}
        <SearchWords />
      </ContentsWrapper>

      {/* 배너 */}
      {bannerItem.data && bannerItem.data.lineBanner && <Banner item={bannerItem.data.lineBanner} />}

      <ContentsWrapper>
        <H3>{t('stitle')}</H3>
        {/* 홍보 문서 */}
        <Explore />


        <H3>{t('stitle2')}</H3>
        {/* FAQ */}
        <MainList t={t} />
      </ContentsWrapper>

      <ContactUs />
    </>
  );
};
export default HelpMainPage;


import React from 'react';
import Header from './Header';
import Content from './Content';

const WritePage = () => {
  return (
    <>
      <Header />
      <Content />
    </>
  );
};

export default WritePage;

import React from "react";
import Styled from "container/CommunityPage/List/index.style";
import { useSelector } from "react-redux";
import { useAxios } from "common/hook/useAxiosHook";
import H2 from "component/atoms/H2";
import themesApi from "api/theme";
import GoToCreate from "container/CommunityPage/List/GoToCreate";
import HotTopik from "container/CommunityPage/List/HotTopik";
import PopularThemes from "container/CommunityPage/List/PopularThemes";
import ViewAllPosts from "container/CommunityPage/List/ViewAllPosts";
import communityApi from "api/community";
import { useTranslation } from "react-i18next";
import { NoticesPosts, RecommentPosts } from "./ViewPost";

const CommunityList = () => {
  const { t } = useTranslation("communityPage");
  const token = useSelector((state) => state.user.token);
  const popularThemeList = useAxios(themesApi.getPopularList, [], true);
  const postList = useAxios(communityApi.getAllList, [], true);
  const searchList = useAxios(communityApi.getSearch, [], true);
  const topPostList = useAxios(communityApi.getTopPostList, [], true);
  const recomentPostList = useAxios(communityApi.getRecommendPostList, [], true);
  const noticePostList = useAxios(communityApi.getNoticesPostList, [], true);

  return (
    <Styled.ContentsWrapper>
      <H2>{t("title")}</H2>

      <GoToCreate t={t} />
      <Styled.PopularContainer>
        <Styled.PopularLeft>
          <HotTopik initState={topPostList} />
        </Styled.PopularLeft>
        <Styled.PopularRight>
          <PopularThemes initState={popularThemeList} token={token} />
        </Styled.PopularRight>
      </Styled.PopularContainer>
      <NoticesPosts initState={noticePostList} />
      <RecommentPosts initState={recomentPostList} />
      <ViewAllPosts initState={{ postList, searchList }} />
    </Styled.ContentsWrapper>
  );
};

export default CommunityList;

import { asyncApi } from 'common/util/asyncApi';
// import { serialize } from 'object-to-formdata';

const pathName = '/api/v1/mock-tests';
const defaultSize = 12;

const mockTestApi = {
  getAllList: async (token, size = defaultSize) => {
    const headers = token && { headers: { Authorization: `Bearer ${token}` } };

    let response = await asyncApi({
      url: pathName,
      params: { size },
      ...headers,
    });
    return response.data;
  },
  getDashboard: async (token) => {
    let response = await asyncApi({
      url: `${pathName}?mine`,
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  },
  getIntro: async ({ token, productId, unitId }) => {
    const config = {
      url: pathName + `/${productId}/${unitId}`,
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await asyncApi(config);
    return response.data;
  },
  getQuestions: async ({ token, productId, unitId }) => {
    const config = {
      url: pathName + `/questions/${productId}/${unitId}`,
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await asyncApi(config);
    return response.data;
  },

  getResultsInfo: async ({ token, productId, unitId }) => {
    const config = {
      url: pathName + `/results/${productId}/${unitId}`,
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await asyncApi(config);
    return response.data;
  },

  getResults: async ({ token, productId, unitId }) => {
    const config = {
      url: pathName + `/results/questions/${productId}/${unitId}`,
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await asyncApi(config);
    return response.data;
  },

  saveAnswer: async ({ token, productId, unitId, data }) => {
    const config = {
      method: 'post',
      url: pathName + `/questions/${productId}/${unitId}`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    };
    let response = await asyncApi(config);
    return response;
  },

  submitAnswer: async ({ token, productId, unitId, data }) => {
    const config = {
      method: 'put',
      url: pathName + `/questions/${productId}/${unitId}`,
      headers: { Authorization: `Bearer ${token}` },
      data,
    };
    let response = await asyncApi(config);
    return response;
  },

  // saveWritingAnswer: async ({ token, productId, unitId, data }) => {
  //   /// 객체를 formdata로 변경
  //   const newFormData = new FormData();
  //   newFormData.append('testTime', data.testTime);
  //   data.mockTestAnswerRequest.forEach((question, index) => {
  //     for (const data in question) {
  //       //객체 키값이 file이면 아니면 append
  //       if (data !== 'files') {
  //         newFormData.append(
  //           `mockTestAnswerRequest[${index}].${data}`,
  //           question[data]
  //         );
  //       } else {
  //         //객체 키값이 file이면 한번더 loop 돌려서 append
  //         question[data].forEach((file, fileIndex) => {
  //           newFormData.append(
  //             `mockTestAnswerRequest[${index}].files[${fileIndex}]`,
  //             file
  //           );
  //         });
  //       }
  //     }
  //   });

  //   const config = {
  //     method: 'post',
  //     url: pathName + `/questions/${productId}/writing/${unitId}`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'multipart/form-data',
  //     },
  //     data: newFormData,
  //   };
  //   let response = await asyncApi(config);
  //   return response.data;
  // },

  // submitWritingAnswer: async ({ token, productId, unitId, data }) => {
  //   /// 객체를 formdata로 변경
  //   const newFormData = new FormData();
  //   newFormData.append('testTime', data.testTime);
  //   data.mockTestAnswerRequest.forEach((question, index) => {
  //     for (const data in question) {
  //       //객체 키값이 file이면 아니면 append
  //       if (data !== 'files') {
  //         newFormData.append(
  //           `mockTestAnswerRequest[${index}].${data}`,
  //           question[data]
  //         );
  //       } else {
  //         //객체 키값이 file이면 한번더 loop 돌려서 append
  //         question[data].forEach((file, fileIndex) => {
  //           newFormData.append(
  //             `mockTestAnswerRequest[${index}].${data}.files[${fileIndex}]`,
  //             file
  //           );
  //         });
  //       }
  //     }
  //   });

  // frm.append('file', file);
  //   const config = {
  //     method: 'put',
  //     url: pathName + `/questions/${productId}/writing/${unitId}`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       'Content-Type': 'multipart/form-data',
  //     },
  //     data: newFormData,
  //   };
  //   let response = await asyncApi(config);

  //   return response;
  // },

  deleteWritingFile: async ({ id, token }) => {
    const config = {
      method: 'delete',
      url: pathName + `/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let response = await asyncApi(config);

    return response;
  },

  uploadWritingFile: async ({
    token,
    productId,
    unitValuationId,
    fileData,
  }) => {
    const newFormData = new FormData();
    newFormData.append('multipartFile', fileData);
    const config = {
      method: 'post',
      url: pathName + `/uploads/${productId}/${unitValuationId}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data: newFormData,
    };
    let response = await asyncApi(config);

    return response.data;
  },
};

export default mockTestApi;

import { asyncApi } from 'common/util/asyncApi';

const pathName = '/api/v1/my-level';
const curriculums = '/api/v1/curriculums';
const user = '/api/v1/members';

/**
 * postLevelTestData: 레벨테스트 관련 데이터 저장
 */
const myyLevelApi = {
  postLevelTestData: async (props = {
    token: null,
    languageCode: null,
    languageLevel: null,
    targetLevel: null,
    subjectCode: null,
  }) => {
    let response = await asyncApi({
      url: pathName,
      method: 'post',
      data: {
        languageCode: props.languageCode,
        languageLevel: props.languageLevel,
        targetLevel: props.targetLevel,
        subjectCode: props.subjectCode,
      },
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  // getCurriculums: async (props = { token: null, languageCode: "ALL" }) => {
  //   const value = props.languageCode === "ALL" ? '' : props.languageCode;
  //   let response = await asyncApi({
  //     url: `${curriculums}?recommended=${value}`,
  //     headers: { Authorization: `Bearer ${props.token}` },
  //   });
  //   return response.data;
  // },
  getCurriculums: async (props = {
    languageCode: "ALL" ,
    languageLevel: null,
    targetLevel: null,
    subjectCode: null
  }) => {
    const value = props.languageCode === "ALL" ? '' : props.languageCode;
    let response = await asyncApi({
      url: curriculums,
      params: {
        recommended: value,
        languageLevel: props.languageLevel,
        targetLevel: props.targetLevel,
        subjectCode: props.subjectCode
      }
    });
    return response.data;
  },
  postCurriculums: async (props = { token: null, curriculumId: null }) => {
    let response = await asyncApi({
      url: `${curriculums}/${props.curriculumId}`,
      method: 'post',
      headers: { Authorization: `Bearer ${props.token}` },
    });
    return response.data;
  },
  getPlan: async (props = { token: null }) => {
    let response = await asyncApi({
      url: `${user}/settings/my-plan`,
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
    });
    return response.data;
  },
};

export default myyLevelApi;

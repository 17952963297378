import styled from 'styled-components';

const Step = {
  Step: styled.ol`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6.2em;
  `,
  Item: styled.li`
    position: relative;
    padding: 0 3em;
    flex: 0 0 auto;
    color: #5c5f66;
    font-weight: 600;

    &:first-child::before {
      display: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -1em;
      width: 20px;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
    }

    &.active {
      color: #ffffff;

      strong {
        background-color: #485cc7;
      }
    }

    strong {
      display: inline-block;
      width: 35px;
      height: 35px;
      margin-right: 10px;
      line-height: 35px;
      text-align: center;
      background-color: #0f1012;
      border-radius: 50rem;
      font-size: 1.4em;
    }

    span {
      font-size: 1.6em;
      text-transform: uppercase;
    }

    @media (max-width: 1024px) {
      &.active span {
        display: block;
        position: absolute;
        bottom: -2em;
        white-space: nowrap;
        text-align: center;
      }
      &.active:nth-child(1) span {
        left: 135%;
      }
      &.active:nth-child(2) span {
        left: 50%;
      }
      &.active:nth-child(3) span {
        left: -58%;
      }
      &.active:nth-child(4) span {
        left: -200%;
      }

      strong {
        margin-right: 0px;
      }
      span {
        display: none;
      }
    }
  `,
};

export default Step;

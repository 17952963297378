import styled from 'styled-components';
import { FaCheck, FaMinus } from 'react-icons/fa';

const StyledTable = {
  Check: styled.span.attrs({
    children: <FaCheck />,
  })`
    font-size: 2.8em;
    color: ${props => props.color ? props.color : '#fff'};
  `,
  Minus: styled.span.attrs({
    children: <FaMinus />,
  })`
    font-size: 2.8em;
    color: ${props => props.color ? props.color : '#fff'};
  `,
  Container: styled.div`
    margin-bottom: 17.8em;
  `,
  Table: styled.table`
    width: 100%;
    table-layout: fixed;

    .col1 {
        width: 34%;
      }
    @media (max-width: 1280px) {
      .col1 {
        width: 38%;
      }
    }
    @media (max-width: 767px) {
      .col1 {
        width: 52%;
      }
    }

    > thead {
      background-color: #191b1f;
      border-top: 1px #5d6165 solid;
      border-bottom: 1px #2b2e35 solid;
      > tr th {
        padding: 0.8em 0.5em;
        font-size: 2.2em;
        font-weight: 700;
        border-right: 1px #2b2e35 solid;

        span {
          display: inline-block;
        }

        &:last-of-type {
          border-right: none;
        }
      }
    }

    > tbody {
      border-bottom: 1px #2b2e35 solid;
      tr:nth-of-type(2n) td {
        background-color: #16181b;
      }
      td {
        vertical-align: middle;
        text-align: center;
        padding: 1.1em 1em;
        border: 1px #2b2e35 solid;
        color: #afafaf;
        &:first-of-type {
          border-left: none;
        }
        &:last-of-type {
          border-right: none;
        }
        &.title {
          padding-left: 7%;
          padding-right: 7%;
          background-color: #0f1012 !important;
          text-align: left;
          font-size: 2em;
          color: #fff;
          font-weight: 700;
          p {
            font-size: 0.9em;
            font-weight: 300;
            color: #d1d1d1;
            &:first-child {
              margin-top: 0.2em;
            }
          }
        }
        &.desc {
          text-align: left;
        }
        > span {
          display: inline-block;
          width: 0.8em;
          height: 0.8em;
          border-radius: 50%;
        }

        .standard {
          background-color: #69bd18;
        }
        .premium {
          background-color: #485cc7;
        }
      }
    }
  `,
};

export default StyledTable;

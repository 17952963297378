import styled from 'styled-components';
import indonesiaIcon from 'assets/images/ico_indonesia.png';
import usaIcon from 'assets/images/ico_usa.png';
import nepalIcon from 'assets/images/ico_nepal.png';
import checkIcon from 'assets/images/icon_check2.png';
import vocaIcon from 'assets/images/ico_alphabet.png';
import grammarIcon from 'assets/images/ico_grammar.png';
import testIcon from 'assets/images/ico_test.png';

const StyleCourseEPS = {
  Container: styled.div`
    > h2 {
      margin-bottom: 0.83em;
      text-align: center;
      font-size: 3.6em;
      font-weight: 700;

      @media (max-width: 767px) {
        margin-top: 0.417em;
      }
    }
    > p {
      text-align: center;

      font-size: 1.8em;
      font-weight: 400;
    }
    &::after {
      content: '';
      display: table;
      overflow: hidden;
      clear: both;
    }
  `,
  LanguageContainer: styled.div`
    position: absolute;
    top: 2em;
    left: 2em;
    > span {
      display: inline-block;
      width: 3em;
      height: 2.2em;
      border: 1px #d4d6dc solid;
      cursor: pointer;
    }
    .indonesia {
      margin-right: 0.5em;
      background: url(${indonesiaIcon}) no-repeat center center/cover;
    }
    .nepal {
      background: url(${nepalIcon}) no-repeat center center/cover;
    }
    .usa {
      margin-right: 0.5em;

      background: url(${usaIcon}) no-repeat center center/cover;
    }
    .active {
      border: 2px #5c5f66 solid;
    }
  `,

  LeftSection: styled.div`
    width: calc(51% - 2em);
    margin-top: 3.4em;
    margin-right: 2em;
    padding: 4em;
    border-radius: 0.6em;
    background-color: #f7f8fd;
    h3 {
      margin-bottom: 1.5em;
      font-size: 2.4em;
      font-weight: 600;
    }
    .content {
      position: relative;
      padding: 3.6em;
      padding-left: 0;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 1.3em;
        width: 1px;
        height: 100%;
        background-color: #e4e4e7;
      }
      p {
        position: relative;
        margin-bottom: 3.27em;
        padding-left: 3em;
        color: #5c5f66;
        font-size: 1.6em;
        font-weight: 400;
        &:last-of-type {
          margin-bottom: 0;
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          width: 1.6em;
          height: 1.6em;
          background: url(${checkIcon}) no-repeat center center;
          transform: translateY(-50%);
        }
      }
    }
  `,
  SectionContainer: styled.div`
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
      > div {
        width: 100%;
        margin-right: 0;
      }
    }
  `,
  RightSection: styled.div`
    width: 49%;
    margin-top: 3.4em;
    padding: 4em;
    padding-bottom: 3em;
    background-color: #f7f8fd;
    border-radius: 0.6em;
    .content {
      position: relative;
      margin-bottom: 3em;
      padding: 0 0 2.5em 6.5em;
      border-bottom: 1px #e4e4e7 solid;

      &.vocab {
        &::before {
          content: '';
          position: absolute;
          top: 25%;
          left: 0;
          width: 4.4em;
          height: 5.6em;
          background: url(${vocaIcon}) no-repeat center center/cover;
          transform: translateY(-50%);
        }
      }
      &.grammar {
        &::before {
          content: '';
          position: absolute;
          top: 25%;
          left: 0;
          width: 4.4em;
          height: 5.6em;
          background: url(${grammarIcon}) no-repeat center center/cover;
          transform: translateY(-50%);
        }
      }
      &.test {
        &::before {
          content: '';
          position: absolute;
          top: 25%;
          left: 0;
          width: 4.4em;
          height: 5.6em;
          background: url(${testIcon}) no-repeat center center/cover;
          transform: translateY(-50%);
        }
      }

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
      .title {
        margin-bottom: 0.6em;
        color: #1e2024;
        font-size: 1.8em;
        font-weight: 700;
      }
      span {
        display: block;
        margin-bottom: 0.5em;
        color: #5c5f66;
        font-size: 1.6em;
        font-weight: 500;
      }
      p {
        color: #666;
        font-size: 1.4em;
        font-weight: 400;
      }
    }
  `,

  ViewButton: styled.button`
    display: block;
    margin: 1.56em auto;
    padding: 1.2em 0;
    width: 33.9%;
    border-radius: 0.375em;
    background-color: #ffb500;
    color: #fff;
    font-size: 1.6em;
    font-weight: 700;
  `,
};

export default StyleCourseEPS;

import React, { useState } from 'react';
import styled from 'styled-components';

const Style = {
  TabButtonContainer: styled.div`
    margin-bottom: 1.5em;
    button {
      display: inline-block;
      padding: 0.8em 0;
      border: 1px #5c5f66 solid;
      width: 4.6em;
      color: #fff;
      font-size: 1.4em;
      &.active {
        background-color: #485cc7;
      }
    }
    button:first-of-type {
      border-right: none;
      border-color: #5c5f66;
      border-top-left-radius: 0.4em;
      border-bottom-left-radius: 0.4em;
    }

    button:last-of-type {
      border-left: none;
      border-top-right-radius: 0.4em;
      border-bottom-right-radius: 0.4em;
    }
  `,
};
const Tab = ({ activeIdx, setActiveIdx }) => {
  const clickBtn = (index) => {
    setActiveIdx(index);
  };

  return (
    <Style.TabButtonContainer>
      <button
        onClick={() => clickBtn(0)}
        className={activeIdx === 0 ? 'active' : ''}
      >
        Latest
      </button>
      <button
        onClick={() => clickBtn(1)}
        className={activeIdx === 1 ? 'active' : ''}
      >
        Top
      </button>
    </Style.TabButtonContainer>
  );
};

export default Tab;

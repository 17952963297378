import styled from 'styled-components';
import StyledForm from 'component/form/ModalForm.style';
import arrowRight from 'assets/images/arrow2_right_gray.svg';
import iconUnChecked from 'assets/images/ico_unchecked.png';
import iconUnCheckedLarge from 'assets/images/ico_unchecked_large.png';
import iconChecked from 'assets/images/ico_checked.png';
import iconCheckedLarge from 'assets/images/ico_checked_large.png';

const StyledCreateEmailAccount = {
  TopDesc: styled.p`
    font-size: 1.6em;
    margin-bottom: 1em;
    font-weight: 500;
  `,
  BlueButton: styled(StyledForm.Button)`
    background-color: #485cc7;
  `,
  EmailAuthButton: styled(StyledForm.Button)`
    font-size: 1.4em;
    height: 3em;
    margin-top: 4px;
    border-radius: 0.4rem;
    background-color: #ffb500;
    &[disabled] {
      background-color: #9a9ba0;
    }
  `,
  NameContainer: styled(StyledForm.InputContainer)`
    p {
      clear: both;
      padding-top: 0.3em;
      font-size: 1.4em;
      color: #666;
    }
  `,
  Bottom: {
    Login: styled.div`
      font-size: 1.6em;
      margin-top: 2em;
      text-align: center;
      font-weight: 300;
      color: ${({ landingPage }) => (landingPage ? '#ccc' : '')};
      a {
        font-weight: 500;
        color: ${({ landingPage }) => (landingPage ? '#fff' : '')};
      }
    `,
    Terms: styled.div`
      font-size: 1.2em;
      margin-top: 1.5em;
      font-weight: 300;
      text-align: center;
      color: ${({ landingPage }) => (landingPage ? '#969696' : '#8e9199')};
      a {
        --color : ${({ landingPage }) => (landingPage ? '#ccc' : '#222')};
        display: inline-block;
        border-bottom: 1px solid var(--color);
        color: var(--color);
      }
    `,
  },
};

export default StyledCreateEmailAccount;

import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/vocabularies';
const size = 8;

/**
 * getList 전체 목록
 * getListByLevel 레벨별 목록
 */
const vocabulariesApi = {
  getList: async (props = { level: null, page: 0 }) => {
    let response = await asyncApi({
      url,
      params: { level: props.level, page: props.page, size },
    });
    return response.data;
  },
  getListByLevel: async ({ level, page = 0 }) => {
    let response = await asyncApi({
      url,
      params: { level, page },
    });
    return response.data;
  },
};

export default vocabulariesApi;

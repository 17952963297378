import React, { useEffect, useState } from 'react';
import StyledList from './styled/List.style';
import { Circle } from 'rc-progress';
import { BiPlay, BiPause } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { setLectureId } from 'container/WbmKoreaPage/state/action';

const SecondAccordion = ({
  title,
  time,
  id,
  videoRef,
  playing
}) => {
  const dispatch = useDispatch();

  const lectureId = useSelector((state) => state.wbmKorea.lectureId);
  const [videoPercentage, setPercentage] = useState(0);


  const clickList = () => {
    dispatch(setLectureId(id));
  };

  useEffect(() => {
    if(lectureId === id && playing){
      const getPercentage = setInterval(() => {
        if (videoRef.current) {
          let percentage =
            (videoRef.current.getCurrentTime() /
            videoRef.current.getDuration()) *
            100;
            setPercentage(percentage);
        } else {
          setPercentage(0);
        }
      }, 1000);
      return () => clearInterval(getPercentage);
    }
  }, [playing]);

  return (
    <div>
      <StyledList.VideoListContainer
        className='clickable'
        onClick={() => clickList()}
      >
        {
          //비디오 데이터
          <StyledList.VideoList>
            <span className='icon'>
              <Circle
                percent={ lectureId === id? videoPercentage : 0 }
                strokeWidth={5}
                trailWidth={5}
                strokeColor={'#f8b209'}
                trailColor='#8b8f99'
                className='progressBar'
              />
              {lectureId === id && playing? (
                <BiPause className='progressBarIcon' color='#f8b209' />
              ) : (
                <BiPlay
                  className='progressBarIcon'
                  color='#8b8f99'
                  size='0.8em'
                />
              )}
            </span>
            {/* 기존 */}
            {/* <StyledList.VideoListTitle>{title}</StyledList.VideoListTitle> */}
            {/* 미리보기 추가 */}
            <StyledList.VideoListTitle
              className={id===Number(lectureId)? 'preview' : ''}
            >
              {title}
              {/* {overviewLecture ? (
                <StyledList.VideoListPreview>
                  preview
                </StyledList.VideoListPreview>
              ) : undefined} */}
            </StyledList.VideoListTitle>

            <span className='time'>{time && `${time} m`}</span>
          </StyledList.VideoList>
        }
      </StyledList.VideoListContainer>
    </div>
  );
};

export default SecondAccordion;

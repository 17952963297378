import styled from 'styled-components';

const ContactUsStyle = {
  Container: styled.div`
    margin-top: 14em;
    padding: 2em;
    background: #393c40;
    text-align: center;
  `,
  Desc: styled.p`
    display: inline-block;
    margin-right: 2.5em;
    font-weight: 700;
    font-size: 2em;
    color: #9fa1a5;
    vertical-align: middle;
  `,
  Link: styled.a`
    display: inline-block;
    width: 10em;
    padding: 0.5em 0;
    background: #b6b8b9;
    border-radius: 0.375em;
    overflow: hidden;
    font-size: 1.6em;
    color: #0f1012;
    vertical-align: middle;
  `,
};

export default ContactUsStyle;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import iconVideo from 'assets/images/ico_video.png';
import iconVideoLarge from 'assets/images/ico_video_large.png';
import { CountIconStyle } from 'component/atoms/Icon/index.style';

const Styled = styled.span`
  ${CountIconStyle}
  background-image: url(${iconVideo});
  @media (mas-width: 1024px) {
    background-image: url(${iconVideoLarge});
  }
`;

/**
 * 아이콘이 포함된 비디오 갯수 표시
 * @param {number} props.count
 * @param {string=} props.ClassName
 */
function CountIconVideo({ count = 0, className }) {
  return <Styled className={className}>{count}</Styled>;
}

CountIconVideo.propTypes = {
  count: PropTypes.number.isRequired,
  className: PropTypes.string,
};

export default CountIconVideo;

import React from 'react';
import Styled from './index.style';
import { useHistory } from 'react-router-dom';
import useErrorToastify from 'common/hook/useErrorToastify';
import H2 from 'component/atoms/H2';
import Loading from 'component/atoms/Loading';
import ReviseIcon from 'component/atoms/Icon/ReviseIcon';
import DeleteIcon from 'component/atoms/Icon/DeleteIcon';
import UserInfo from 'component/atoms/UserInfo';
import { ModalContext } from 'component/Modal/modalContext';
import CommunityLikeHandling from './LikeHandling';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import Moment from 'react-moment';
import { useTranslation } from 'react-i18next';

const Article = ({ initialState, userData }) => {
  const { t } = useTranslation('communityPage');

  let { handleModal } = React.useContext(ModalContext);

  const [post] = initialState;
  const { loading, data, error } = post;

  const history = useHistory();
  const goToRevise = () => {
    history.push({
      pathname: '/community/revise',
      state: { data: data },
    });
  };

  useErrorToastify(error);

  return (
    <>
      {loading && <Loading />}

      {data !== null && (
        <Styled.Article>
          <header>
            <H2>{data.title}</H2>

            <Styled.Info>
              <UserInfo
                src={data.userProfilePath}
                name={data.userName}
                className='community'
              />
              <Styled.RegDt>
                <Moment format='D, MMMM, h:mm a'>{data.regDate}</Moment>
              </Styled.RegDt>
              <CommunityLikeHandling
                id={data.id}
                count={data.likeCount}
                isGood={data.isGood}
              />
            </Styled.Info>

            {userData?.tblMemberBasicIdx === data.userId && (
              <Styled.UserMenu>
                <ReviseIcon onClick={goToRevise}>{t('buttonEdit')}</ReviseIcon>
                <DeleteIcon
                  onClick={() =>
                    handleModal(<ConfirmDeleteModal id={data.id} />)
                  }
                >
                  {t('buttonDelete')}
                </DeleteIcon>
              </Styled.UserMenu>
            )}
          </header>

          <Styled.Content>
            {data && (
              <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
            )}
          </Styled.Content>
        </Styled.Article>
      )}
    </>
  );
};

export default Article;

import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { ModalContext } from 'component/Modal/modalContext';
import { addItem } from './state/action';
import AddConfirmModal from 'container/BookStorePage/AddConfirmModal';
import ForeignLocationModal from 'container/BookStorePage/ForeignLocationModal';
import { Trans, useTranslation } from 'react-i18next';
import { toBeContinue } from 'common/util/util';

const Style = {
  Desc: styled.p`
    margin-top: 1em;
    font-size: 2em;
    font-weight: 700;
    &.subDesc {
      margin-bottom: 2em;
      color: #8b8f99;
      font-size: 1.6em;
      font-weight: 400;
    }
  `,
  ButtonContainer: styled.div`
    display: flex;
    > * {
      flex: 1;
    }
  `,
  Button: styled.button`
    display: inline-block;
    height: 6rem;
    border: 1px #8b8f99 solid;
    border-radius: 0.375em;
    font-size: 1.6em;
    font-weight: 500;
    letter-spacing: 0.1px;
    span {
      display: block;
    }
    &.primary {
      margin-right: 0.5em;
      border: 0;
      background-color: #475cc7;
      color: #fff;
    }
  `,
};

const PurchaseModal = ({ data, url }) => {
  const { t } = useTranslation('bookstorePage');
  let dispatch = useDispatch();
  const { handleModal } = React.useContext(ModalContext);

  const clickYes = () => {
    dispatch(addItem(data));
    handleModal(<AddConfirmModal t={t}/>);
  };

  const clickGoAmazon = (e) => {
    if(data.globalBookUrl && data.globalBookUrl !== '') {
      window.open(data.globalBookUrl, '_blank');
      handleModal();
    } else {
      toBeContinue(e);
    }
  };

  return (
    <>
      <Style.Desc>{t('foreignLocationModal.title')}</Style.Desc>
      <Style.Desc className='subDesc'>
        {t('foreignLocationModal.desc')}
      </Style.Desc>
      <Style.ButtonContainer>
        <Style.Button className='primary' onClick={clickYes}>
          {t('foreignLocationModal.button1')}
        </Style.Button>
        <Style.Button onClick={clickGoAmazon}>
          <Trans t={t}>foreignLocationModal.button2</Trans>
        </Style.Button>
      </Style.ButtonContainer>
    </>
  );
};

export default PurchaseModal;

import React, { useEffect, useState } from 'react';
import Styled from './index.style';
import visualImg from 'assets/images/event/220110/visual.png';
import visualLargeImg from 'assets/images/event/220110/visual_large.jpg';
import benefitImg from 'assets/images/event/220110/benefit.png';
import benefitImg2 from 'assets/images/event/220110/benefit2.png';
import benefitImg3 from 'assets/images/event/220110/benefit3.png';
import titleAwardImg from 'assets/images/event/220110/tit_award.jpg';
import awardGoldImg from 'assets/images/event/220110/award.png';
import awardSilverImg from 'assets/images/event/220110/award2.png';
import awardBronzeImg from 'assets/images/event/220110/award3.png';
import linkCopyimg from 'assets/images/event/220110/btn_copy.png';
import { toast } from 'react-toastify';
import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import { useSelector } from "react-redux";
import Login from "component/user/Login";
import mockTestApi from 'api/mockTests';
import { useLocation } from 'react-router-dom';
import ConfirmModal from '../ConfirmModal';

//1차 //220103
//dev: 1180 5030 5031 5032
//prod: 1196 5177 5178 5179

//2차 //220704
//prod: 1217 5407 5408 5409
// 1) 듣기    
// https://www.mastertopik.com/mock-test/1217/5407
// 2) 쓰기    
// https://www.mastertopik.com/mock-test/1217/5408
// 3) 읽기    
// https://www.mastertopik.com/mock-test/1217/5409

var productidx = 1217;
let unitidx = {
	L: 5407,
	W: 5408,
	R: 5409,
};
if (process.env.REACT_APP_ENV !== "production") {
	productidx = 1180;
	unitidx = {
		L: 5030,
		W: 5031,
		R: 5032,
	}
}

var data1 = '';
var data2 = '';
var data3 = '';

var allData = '';

const EventPage = () => {
	const token = useSelector((state) => state.user.token);
	const location = useLocation();
	const [date, setDate] = useState({});

	//D-day 설정
	let futureDate = new Date('2022/07/11 23:59:59');
	const getDate = (date1, date2) => {
		if(date2.getTime() - date1.getTime() < 0){
			return {
				day: 0,
				hour: 0,
				minute: 0,
				second: 0,
			};
		}else{
			const date = new Date(date2.getTime() - date1.getTime())
			return {
				day: date.getUTCDate() - 1,
				hour: date.getUTCHours(),
				minute: date.getUTCMinutes(),
				second: date.getUTCSeconds()
			};
		}
	};
	const formatDate = (date) => {
		let d = new Date(date),
		month = (d.getMonth() + 1).toString(),
		day = d.getDate().toString(),
		year = d.getFullYear().toString();
		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;
		return [year, month, day].join("-");
	};
  	useEffect(() => {
		setDate(getDate(new Date(), futureDate));
		const timer = setInterval(() => {
      		setDate(getDate(new Date(), futureDate));
		}, 1000);

		var promiseAllData = new Promise(function (resolve, reject) {
			var data = mockTestApi.getAllList(token);
			resolve(data);
		});
		promiseAllData.then( result => {
			let arrayResult = result.content;
			arrayResult.map((item, index) => {
				if (item.productId === productidx) {
					allData = item.mockTestResultResponses;
					data1 = allData[0].status;
					data2 = allData[1].status;
					data3 = allData[2].status;
				}
			})
		});

		return () => clearInterval(timer);
  	}, []);

	// var promise1 = new Promise(function (resolve, reject) {
	// 	let unitId = "5030";
	// 	var data = mockTestApi.getResultsInfo({ token, productId, unitId });
	// 	console.log(unitId);
	// 	resolve(data);
	// });

	// var promise2 = new Promise(function (resolve, reject) {
	// 	let unitId = "5031";
	// 	var data = mockTestApi.getResultsInfo({ token, productId, unitId });
	// 	console.log(unitId);
	// 	resolve(data);
	// });

	// var promise3 = new Promise(function (resolve, reject) {
	// 	let unitId = "5032";
	// 	var data = mockTestApi.getResultsInfo({ token, productId, unitId });
	// 	console.log(unitId);
	// 	resolve(data);
	// });

	// promise1.then( result => {data1 = result.status;});
	// promise2.then( result => {data2 = result.status;});
	// promise3.then( result => {data3 = result.status;});

	// Promise.all([promise1, promise2, promise3]).then(function (values) {
	// 	console.log("모두 완료됨", values);
	// });


	// 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
	const [isMobile, setisMobile] = useState(false);

	const resizingHandler = () => {
		if (window.innerWidth <= 1024) {
		setisMobile(true);
		} else {
		setisMobile(false);
		}
	};
  	useEffect(() => {
		if (window.innerWidth <= 1024) {
			setisMobile(true);
		}

		window.addEventListener("resize", resizingHandler);
		return () => {
		// 메모리 누수를 줄이기 위한 removeEvent
		window.removeEventListener("resize", resizingHandler);
		};
  	}, []);

	const doCopy = text => {

		// 흐름 1.
		if (!document.queryCommandSupported("copy")) {
		return toast.error("Unsupported browser.");
		}

		const API_HOST = process.env.REACT_APP_ENV === 'local' ? 'http://localhost:3000' : process.env.REACT_APP_API_HOST;

		// 흐름 2.
		const textarea = document.createElement("textarea");
		textarea.value = API_HOST + text;
		textarea.style.top = 0;
		textarea.style.left = 0;
		textarea.style.position = "fixed";

		// 흐름 3.
		document.body.appendChild(textarea);
		// focus() -> 사파리 브라우저 서포팅
		textarea.focus();
		// select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
		textarea.select();
		// 흐름 4.
		document.execCommand("copy");
		// 흐름 5.
		document.body.removeChild(textarea);
		alert("Link copied.");
		//toast.success("Link copied.");
  	};

	const isLogin = useLoginStatus();
	let { handleModal } = React.useContext(ModalContext);
	//const login = useSelector((state) => state.user.token);

	const clickCommunity = (data) => {
    if (!isLogin) {
		handleModal(<Login />, false, 420, location.pathname);
    } else {
			document.location.href = "/Community";
		}
	}
	const clickLink = (status, levelType) => {
		// let mock = 1196;
		// let mockL = 5177;
		// let mockW = 5178;
		// let mockR = 5179;
		let mock = productidx;
		let mockL = unitidx.L;
		let mockW = unitidx.W;
		let mockR = unitidx.R;

		if (!isLogin) {
			handleModal(<Login />, false, 420, location.pathname);
		} else {
			console.log('레벨타입==========',levelType);

			const now = new Date();
			let isOver = false;
			if(futureDate.getTime() - now.getTime() < 0){
				isOver = true;
			}

			if (status === 'L'){
				if (levelType === "TAKE A TEST" || levelType === "CONTINUE") {
					if(isOver) testOverModal();
					else window.open(`/mock-test/${mock}/${mockL}`,"","_blank");
				} else {
					window.open(`/mock-test/result/${mock}/${mockL}`,"","_blank");
				}
			}else if (status === 'W'){
				if (levelType === "TAKE A TEST" || levelType === "CONTINUE") {
					if(isOver) testOverModal();
					else window.open(`/mock-test/${mock}/${mockW}`,"","_blank");
				} else if (levelType === "IN-PROGRESS") {
					window.open(`/mock-test/result/${mock}/${mockW}`,"","_blank");
				} else {
					window.open(`/mock-test/result/${mock}/${mockW}`,"","_blank");
				}
			}else if (status === 'R'){
				if (levelType === "TAKE A TEST" || levelType === "CONTINUE") {
					if(isOver) testOverModal();
					else window.open(`/mock-test/${mock}/${mockR}`,"","_blank");
				} else {
					window.open(`/mock-test/result/${mock}/${mockR}`,"","_blank");
				}
			}else if (status === 'GR'){
				alert('Your TOPIK Writing Correction is in Progress. Please wait.');
			}
    	}
  	};
	const testOverModal = () => {
		handleModal(
			<ConfirmModal
				tMessage={'This test is over.'}
				tButton={'CONFIRM'}
			/>
		);
	}

  	const clickFlipCard = () => {
		if (!isLogin) {
			handleModal(<Login />, false, 420, location.pathname);
		}
	};

	const checkReport = () => {
		if (data1 === "VIEW RESULTS" && data2 === "IN-PROGRESS" && data3 === "VIEW RESULTS") {
			return (
				<li>Grade report <a href='javascript:;' onClick={() => clickLink('GR')}>IN-PROGRESS</a></li>
			);
		} else if (data1 === "VIEW RESULTS" && data2 === "VIEW RESULTS" && data3 === "VIEW RESULTS") {
				return (
					<li>Grade report <a href='/test-report/individual'>VIEW RESULTS</a></li>
				);
		} else {
			return (
				<li>Grade report</li>
			);
		}
	};
	return (
		<Styled.EventWrap>
			<div className='visual'>
				<div className="inner">
					<div className='timeline'>
						<span className='deadline'>Time remaining until deadline</span>
						<p className='days'>Due in<br /><em>{date.day}</em><strong>days!</strong></p>
						<div className='time'>
							<div><strong>{date.day}</strong><span>day</span></div>
							<div><strong>{date.hour}</strong><span>hours</span></div>
							<div><strong>{date.minute}</strong><span>minute</span></div>
							<div><strong>{date.second}</strong><span>seconds</span></div>
						</div>
						<a href='#test'>Take<br />the test</a>
					</div>
					<div className='txt'>
						<p className='sub'>Preparing for the 83th July TOPIK</p>
						<p className='title'>
							The 2nd MT TOPIK Ⅱ <br />Mock Test Competition
						</p>
						<p className='date'>~ July 11th, 2022</p>
					</div>
				</div>
			</div>

			<div className='section1'>
				<div className="inner">
					<div className="btnWrap">
						<a href='#test'>Take the MT TOPIK Mock Test right away</a>
					</div>
					<h2>TOPIK Mock Test<br /><strong>Introduction</strong></h2>
					<ul className='check'>
						<li>TOPIK tests will be taken in IBT format starting from the year 2023.</li>
						<li>Prepare the TOPIK mock test in the same environment with the actual IBT TOPIK</li>
						<li>Take the Listening, Writing, and Reading mock test / Issue a TOPIK report card</li>
					</ul>
					<h3>Benefits for the MT TOPIK Mock Test<br />Competition participants</h3>
					<ul className='benefit'>
						<li><img src={benefitImg} alt="" /><p>Issue a TOPIK report card<br />and check your answers</p></li>
						<li><img src={benefitImg2} alt="" /><p>Writing evaluation<br />and feedback service</p></li>
						<li><em>Korean<br />Mentoring<br />Service</em><img src={benefitImg3} alt="" /><p>A 1:1 consultation with<br />the Korean education<br />professionals</p></li>
					</ul>
					<div className="btnWrapCounsel">
						<a href='javascript:;' onClick={() => clickCommunity()} >Get counseling</a>
					</div>
				</div>
			</div>

			<div className='section2'>
				Prepare the IBT TOPIK with MT mock test,<br />And check your exact Korean skill!
			</div>

			<div className='section3'>
				<div className="inner">
					<h2>Participant <strong>Method</strong></h2>
					<ol>
						<li><em><span>STEP</span><br />1</em><a href='javascript:;' onClick={() => clickFlipCard()} className="btnLogin">Log in or Sign up</a></li>
						<li><em><span>STEP</span><br />2</em>Take a TOPIK II mock test <span>(Listening, Writing, Reading)<br />Writing Section : Manuscript paper</span><a href='/static/media/TOPIK_Mock_Test_Exam_Paper.ef9cb9ad.pdf' target="_blank" className="btnDown">Download</a></li>
						<li><em><span>STEP</span><br />3</em>Check Listening, Reading test results</li>
						<li><em><span>STEP</span><br />4</em>Check writing correction <span>(About a week from the test)</span></li>
						<li id='test'><em><span>STEP</span><br />5</em>See grade analysis report</li>
					</ol>

					<div className='testBox'>
						<h3>Take the TOPIK Mock Test right away</h3>
						<ul>
							<li>1-1. Listening <a href='javascript:;' onClick={() => clickLink('L',data1)}>{data1}</a></li>
							<li>1-2. Writing <a href='javascript:;' onClick={() => clickLink('W',data2)}>{data2}</a></li>
							<li>2. Reading <a href='javascript:;' onClick={() => clickLink('R',data3)}>{data3}</a></li>
							{checkReport()}
						</ul>
					</div>

					<h2>MT TOPIK Mock Test<br /><strong>Competition Introduction</strong></h2>
					<dl>
						<dt>Event For</dt>
						<dd>MT members <span>(Registration required)</span></dd>
						<dt>Test Duration</dt>
						<dd>2022. 7. 4 (Mon) ~ 2022. 7. 11 (Mon)(KST)</dd>
						<dt>Test Content</dt>
						<dd>TOPIK Ⅱ (Listening, Reading, Writing)</dd>
						<dt>Test Questions</dt>
						<dd>Slim Ver. Total 50 questions<br /><span>(Listening, Reading: 24 / Writing: 2)</span></dd>
						<dt>Award<br />Announcement</dt>
						<dd>2022. 7. 18 (Mon) 14:00 (KST)</dd>
					</dl>
				</div>
			</div>

			<div className='section4'>
				<div className="inner">
					<h2><img src={titleAwardImg} alt="Awards and Prizes" /></h2>

					<div className='prizes'>
						<ol>
							<li>
								<dl>
									<dt>
										<span className='people'>
											<img src={awardGoldImg} alt="Gold" />
											<span><em>5</em>people</span>
										</span>
										<strong>Gold winner<br />certificate</strong>
									</dt>
									<dd>MT Premium pass<br /><span>(3 months)</span></dd>
								</dl>
							</li>
							<li>
								<dl>
									<dt>
										<span className='people'>
											<img src={awardSilverImg} alt="Silver" />
											<span><em>5</em>people</span>
										</span>
										<strong>Silver winner<br />certificate</strong>
									</dt>
									<dd>MT Premium pass<br /><span>(2 months)</span></dd>
								</dl>
							</li>
							<li>
								<dl>
									<dt>
										<span className='people'>
											<img src={awardBronzeImg} alt="Bronze" />
											<span><em>5</em>people</span>
										</span>
										<strong>Bronze winner<br />certificate</strong>
									</dt>
									<dd>MT Premium pass<br /><span>(1 months)</span></dd>
								</dl>
							</li>
						</ol>
						<ul>
							<li>The participants are ranked based on total TOPIK test score.</li>
							<li>When any of the participants get the same scores, the winner is selected based on the following standard.<br />(Policy for Same Scores : Higher writing score {'>'} Higher reading score {'>'} Higher listening score)</li>
						</ul>
					</div>

					{/*}
					<h3>Where am I ranked<br />amongst the MT <strong>00</strong> members?</h3>
					<table>
						<tbody>
							<tr>
								<th>1<sup>st</sup></th>
								<td>Lv.6</td>
								<td><strong>123200</strong> Points</td>
								<td>PDDSE***</td>
							</tr>
							<tr>
								<th>2<sup>nd</sup></th>
								<td>Lv.6</td>
								<td><strong>123200</strong> Points</td>
								<td>PDDSE***</td>
							</tr>
							<tr>
								<th>3<sup>rd</sup></th>
								<td>Lv.6</td>
								<td><strong>123200</strong> Points</td>
								<td>PDDSE***</td>
							</tr>
							<tr>
								<th>4<sup>th</sup></th>
								<td>Lv.6</td>
								<td><strong>123200</strong> Points</td>
								<td>PDDSE***</td>
							</tr>
							<tr>
								<th>5<sup>th</sup></th>
								<td>Lv.6</td>
								<td><strong>1323</strong> Points</td>
								<td>PDDSE***</td>
							</tr>
						</tbody>
					</table>
					<p className='txt'>* The ranking is updated during weekdays at 10AM(KST)</p>
					*/}

					<div className='share'>
						Share the TOPIK Competition
						<span><a href="javascript:;" onClick={() => doCopy(location.pathname)}><img src={linkCopyimg} alt="Copy" /></a></span>
					</div>

					<div className='notification'>
						<h3>Notification</h3>
						<dl>
							<dt>[TOPIK Mock test]</dt>
							<dd>
								<em>※ Test Duration: 7/4 (Mon) ~7/11(Mon) (KST)</em>
							</dd>
							<dd>You can take the TOPIK mock tests on your computer, mobile and tablet devices.</dd>
							<dd>Once you complete all the 3 sections (Listening, Writing, Reading Comprehension), participation is automatically completed.</dd>
							<dd>About a week from the test, You can check your writing score.</dd>
							<dd>After finishing 3 tests, you must click “Submit” button to issue the report card. If you haven’t finished any of the 3 tests or if you have quitted during the test, you will be excluded from the candidates.</dd>
							<dt>Benefits</dt>
							<dd>All the benefits are only provided to participants who finish the entire exam(Listening, Reading, Writing).</dd>
							<dd>Award Announcement : 2022. 7. 18 (Mon) 14:00 (KST)</dd>
							<dt>Mock test guide</dt>
							<dd>If you want to check out the TOPIK Mock Test video guide, <a href="https://youtu.be/wf0SnQc6yOg" target='_blank'>Click Here</a></dd>
							<dd>If you want to check out the TOPIK Mock Test user guide, <a href="https://bit.ly/3oIsMbe" target="_blank">Click Here</a></dd>
						</dl>
						<p>Inquiry : <a href="mailto:mastertopik@visang.com">mastertopik@visang.com</a></p>
					</div>
				</div>

				<div className='btnTestBottom'>
					<div className='btnBox'>
						<a href='#test'>Take the MT TOPIK Mock Test right away</a>
					</div>
				</div>
			</div>
		</Styled.EventWrap>
  );
};

export default EventPage;
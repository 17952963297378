import React, { useState, useEffect } from 'react';
import PageSubTitle from 'component/molecules/PageSubTitle';
import { SwiperSlide } from 'swiper/react';
import coursesApi from 'api/courses';
import { useAxios } from 'common/hook/useAxiosHook';
import VideoItem from 'component/molecules/VideoItem';
import SwiperList from 'component/molecules/SwiperList';
import { EmptyList } from 'component/atoms/EmptyList';
import Features from 'container/LandingPage/Features';
import { Trans, useTranslation } from 'react-i18next';

const CourseRecommendContainer = ({ token }) => {
  const { t } = useTranslation('libraryPage');

  const [slideItems, setSlideItems] = useState([]);
  const [getCourseRecommendList, refreshGetCourseRecommendList] = useAxios(
    coursesApi.getCourseRecommendList,
    [],
    true
  );
  const { data } = getCourseRecommendList;

  useEffect(() => {
    if (!token) {
      refreshGetCourseRecommendList();
    } else {
      refreshGetCourseRecommendList(0, token);
    }
  }, [token]);

  useEffect(() => {
    if (data) {
      if (data.first) {
        setSlideItems([...data.content]);
      } else {
        setSlideItems([...slideItems, ...data.content]);
      }
    }
  }, [getCourseRecommendList]);

  const onNext = () => {
    if (!data.last) {
      if (!token) {
        refreshGetCourseRecommendList(data.number + 1);
      } else {
        refreshGetCourseRecommendList(data.number + 1, token);
      }
    }
  };

  return (
    <div>
      <PageSubTitle title={t('contentSection.section1.title')} />
      <SwiperList
        id='library-courseRecommend'
        slideItemList={slideItems}
        onNext={onNext}
      >
        {slideItems.length > 0 ? (
          slideItems.map((item) => (
            <SwiperSlide key={item.productId}>
              <VideoItem
                title={item.title}
                imgSrc={item.thumbnailPath}
                percent={item.progressPercent}
                to={`/courses/detail/${item.productId}`}
                videoLink={
                  !token
                    ? `/courses/detail/${item.productId}`
                    : `/courses/detail/${item.productId}/${item.chapterLecId}`
                }
                desc={
                  !token
                    ? `${item.classCount}classes, ${item.hour} hours`
                    : item.chapterTitle
                }
              />
            </SwiperSlide>
          ))
        ) : (
          <EmptyList />
        )}
      </SwiperList>
    </div>
  );
};

export default React.memo(CourseRecommendContainer);

import Count from './Count.js';
import React from 'react';
import Style from './styled/Table.style.js';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import { addItem as addItemBook, deleteItem as deleteItemBook } from 'container/BookStorePage/state/action';
// import { addItem as addItemVideo, deleteItem as deleteItemVideo } from 'container/PaymentPage/state/action';
import { priceFormat } from 'common/util/util';

const Table = ({ priceCurrency, t }) => {
  const dispatch = useDispatch();
  const { type: itemType } = useParams();
  const cartItems = useSelector((state) => {
    if(itemType === 'book') return state.bookStore.bookItems;
    else if(itemType === 'video-class') return state.videoClassStore.videoItems;
    return [];
  });
  const korean = priceCurrency === 'KRW' ? true : false;
  const currency = (price) =>
    korean ? priceFormat.korea(price) : priceFormat.usa(price);

  return (
    <Style.Table>
      <Style.TableHead>
        <span>{t('selectedSection.thead1')}</span>
        <span>{t('selectedSection.thead2')}</span>
        <span>{t('selectedSection.thead3')}</span>
        <span>{t('selectedSection.thead4')}</span>
      </Style.TableHead>

      <Style.TableBody>
        {cartItems.map((data) => {
          let bookItem = {
            id: data.id,
            type: 'Text Book',
            name: data.title,
            price: data.price,
            discountCost: data.discountCost,
          };
          const price = korean ? data.total : data.totalDollar;
          return (
            <div key={data.id}>
              <span>{data.type}</span>
              <span>{data.name}</span>
              <span className='quantity'>
                {itemType === 'book'?
                <Count
                  count={data.quantity}
                    addItem={() => dispatch(addItemBook(bookItem))}
                    deleteItem={() => dispatch(deleteItemBook(bookItem))}
                />
                  : data.quantity
                }
              </span>
              <span>{currency(price)}</span>
            </div>
          );
        })}
      </Style.TableBody>
    </Style.Table>
  );
};

export default Table;

import React, { useEffect, useState } from 'react';
import { useAxios } from 'common/hook/useAxiosHook';
import themesApi from 'api/theme';
import SwiperList from 'component/molecules/SwiperList';
import { SwiperSlide } from 'swiper/react';
import VideoItem from 'component/molecules/VideoItem';
import { masterTopikUrl } from 'common/constant';
import { EmptyList } from 'component/atoms/EmptyList';

export default function ThemeItemList({ id, token }) {
  const [themeItem, fetchThemeItem] = useAxios(
    themesApi.getListByThemeId,
    [],
    true
  );

  const { loading, data: themeItemList } = themeItem;

  const [slideItemList, setSlideItemList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => fetchThemeItem({ id, token }), []);

  useEffect(() => {
    const newSlideItemList = themeItem.data?.content;
    newSlideItemList &&
      setSlideItemList([...slideItemList, ...newSlideItemList]);
  }, [themeItemList]);

  const onNext = () => {
    if (currentPage < themeItemList.totalPages - 1) {
      fetchThemeItem({ id, token, page: currentPage + 1 });
      setCurrentPage(currentPage + 1);
    }
  };

  if (slideItemList.length === 0) {
    return <EmptyList>The list is empty.</EmptyList>;
  }

  return (
    <SwiperList
      id={`my-theme-${id}`}
      slideItemList={slideItemList}
      onNext={onNext}
    >
      {slideItemList.map((item, index) => (
        <SwiperSlide key={index}>
          <VideoItem
            title={item.courseTitle}
            imgSrc={masterTopikUrl + item.thumbnailPath + item.thumbnailTitle}
            desc={item.chapterLectureTitle}
            to={`/courses/detail/${item.id}`}
            isNew={item.isNew}
          />
        </SwiperSlide>
      ))}
    </SwiperList>
  );
}

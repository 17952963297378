import React, { useEffect, useState } from "react";
import Styled from "container/UserBookmarkPage/styled/CreateTheme.style";
import StyledModal from "component/Modal/styled/Modal.style";
import InfiniteScroll from "react-infinite-scroll-component";
import VideoThumb from "component/atoms/VideoThumb";
import { ModalContext } from "component/Modal/modalContext";
import CreateThemeModalDepth2 from "./CreateThemeModalDepth2";
import { useSelector } from "react-redux";
import { useAxios } from "common/hook/useAxiosHook";
import bookmarksApi from "api/bookmarks";
import Loading from "component/atoms/Loading";
import StyledForm from "component/form/ModalForm.style";
import { masterTopikUrl } from "common/constant";
import { useTranslation, Trans } from "react-i18next";

export default function CreateThemeModalDepth1() {
  let { handleModal } = React.useContext(ModalContext);

  const token = useSelector((state) => state.user.token);
  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [bookmarkList, fetchBookmarkList] = useAxios(
    bookmarksApi.getListOfUser,
    [],
    true
  );
  const { loading, data, error } = bookmarkList;

  const { t } = useTranslation("createBookmarkThemeModal");

  // 최초 1회 호출
  useEffect(() => fetchBookmarkList({ page: 0, token }), []);

  useEffect(() => {
    if (data !== null) {
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [bookmarkList]);

  const onNext = () => {
    fetchBookmarkList({ page: data.number + 1, token });
  };

  const [checkedList, setCheckedList] = useState([]);
  const onchange = (e, id) => {
    if (e.target.checked) {
      setCheckedList([...checkedList, id]);
    } else {
      setCheckedList(checkedList.filter((i) => i !== id));
    }
  };

  const [requiredSelect, setRequiredSelect] = useState(false);
  const onNextStep = () => {
    setRequiredSelect(checkedList.length === 0);
    if (checkedList.length > 0) {
      handleModal(<CreateThemeModalDepth2 checkedList={checkedList} />, false);
    }
  };

  return (
    <>
      <StyledModal.H2>{t("title")}</StyledModal.H2>
      <Styled.InfoText>
        <Trans t={t}>desc</Trans>
      </Styled.InfoText>

      {loading && slideItem.length === 0 && <Loading isLight={true} />}

      <Styled.Wrapper id="create-theme-wrapper">
        <InfiniteScroll
          scrollableTarget="create-theme-wrapper"
          dataLength={slideItem.length}
          next={onNext}
          loader={<Loading isLight={true} />}
          hasMore={hasMore}
        >
          {slideItem.map((item, index) => (
            <Styled.Item key={index}>
              <label>
                <VideoThumb
                  src={
                    masterTopikUrl + item.thumbnailPath + item.thumbnailTitle
                  }
                  percent={0}
                  className="video-thumb"
                />
                <Styled.Subject>{item.courseTitle}</Styled.Subject>
                <Styled.Desc>{item.chapterLectureTitle}</Styled.Desc>
                <Styled.CheckField
                  id={item.productId}
                  onChange={(e) => onchange(e, item.productId)}
                />
              </label>
            </Styled.Item>
          ))}
        </InfiniteScroll>
      </Styled.Wrapper>

      <Styled.ButtonGroup>
        <Styled.Button.Default onClick={() => handleModal()}>
          {t("button1")}
        </Styled.Button.Default>

        <Styled.Button.Primary onClick={onNextStep}>
          {t("button2")}
        </Styled.Button.Primary>

        {requiredSelect && (
          <StyledForm.WarnText>Please select one or more</StyledForm.WarnText>
        )}
      </Styled.ButtonGroup>
    </>
  );
}

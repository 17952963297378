import React from 'react';
import styled from 'styled-components';
import loadingLogo from 'assets/images/email_logo.png';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  font-size: 1rem;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, height 0s linear 0.3s;
  &.active {
    height: 100%;
    opacity: 1;
    transition: opacity 0.3s ease 0.05s, height 0.05s ease;
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 22px;
    margin-top: -60px;
    background: url(${loadingLogo}) no-repeat center;
    background-size: contain;
  }
`;

export const LoadingBar = styled.div.attrs({
  children: (
    <>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </>
  ),
})`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  margin: -20px 0 0 -40px;
  div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #fff;
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
  }
  div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
  }
  div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
  }
  div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
  }
  div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
  }
  div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
  }
  div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
  }
  div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
  }
  div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
  }
  div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
  }
  div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
  }
  div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
  }
  div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
  }
  @keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
`;

/**
 * 로딩 오버레이로 감쌀 영역에는 'position: relative'가 필수
 * @param {*} param
 * @param {*} param.isLoading
 */
const LoadingOverlay = ({ isLoading = false }) => {
  return (
    <Container className={isLoading && 'active'}>
      <LoadingBar />
    </Container>
  );
};

export default LoadingOverlay;

import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import ButtonLink from 'component/atoms/ButtonLink';

const StyledUserBookmark = {
  Wrapper: styled(ContentsWrapper)`
    padding-bottom: 10em;
    &.subWrapper {
      padding-bottom: 2em;
    }
    .swiper-container {
      margin-bottom: 5em;
    }
    h2:not(:first-of-type) {
      margin-top: 8rem;
    }
  `,
  BookmarkList: styled.ul`
    width: calc(100% + 4em);
    margin-bottom: 5em;
    margin-left: -2em;
    overflow: hidden;
    @media (max-width: 1024px) {
      width: calc(100% + 2em);
      margin-left: -1em;
    }
  `,
  BookmarkItem: styled.li`
    float: left;
    width: calc(25% - 4em);
    margin: 0 2em 6em;
    @media (max-width: 1024px) {
      width: calc(25% - 2em);
      margin: 0 1em 6em;
    }
    @media (max-width: 767px) {
      width: calc(50% - 2em);
    }
  `,
  MoreDiv: styled.div`
    text-align: center;
    margin-bottom: 5rem;
  `,
  More: styled(ButtonLink)`
    font-size: 1.6rem;
  `,
  ThemeH3: styled.h3`
    margin: 2.5em 0 3rem;
    font-size: 3.6em;
    font-weight: 700;
  `,
  RecommendContainer: styled.div`
    padding-top: 12em;
    background-color: #393c40;
    button {
      top: 10em !important;
      background-color: rgba(0, 0, 0, 0.5);
    }
  `,
};

export default StyledUserBookmark;

import styled from 'styled-components';
import gradientTop from 'assets/images/gradient_top02.png';

const StyledPlyer = {
  Wrapper: styled.div`
    position: relative;
    min-height: 54em;
    background-color: #000;
    overflow: hidden;
    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      vertical-align: middle;
    }
    &:hover .player-controller-wrapper.desktop {
      .video-title {
        transform: translateY(0);
        opacity: 0.9;
      }
      .player-controller {
        opacity: 1;
        bottom: 0;
      }
    }
    @media (max-width: 1024px) {
      min-height: 56vw;
      .player-controller-wrapper.desktop {
        display: none;
      }
    }
  `,
  Title: styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    font-size: 2em;
    padding: 1em;
    font-weight: 400;
    opacity: 0;
    transform: translateY(-50%);
    background: url(${gradientTop}) repeat-x center bottom / contain;
    transition: all 0.3s ease;
  `,
};

export default StyledPlyer;

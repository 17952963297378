import React, { useContext, useEffect, useMemo } from "react";
import Styled from "./Modal.style";
import StyledModal from "component/Modal/styled/Modal.style";
import { ModalContext } from "component/Modal/modalContext";
import { useDispatch, useSelector } from "react-redux";
import { actions as userActions } from "component/user/state";
import { useTranslation } from "react-i18next";

const UpgradeCancelImpossibleModal = ({
  selectedPlan,
  getPlan,
  handleFetchPlan,
}) => {
  const { t } = useTranslation("planPage");

  const { handleModal } = useContext(ModalContext);
  const currentCurrency = useSelector((state) => state.common.currency);

  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  
  const currentPlan = useMemo(()=> getPlan.plans.find(
    (plan) => plan.productId === getPlan.currentPlanProductId
  ),[selectedPlan, getPlan]);

  useEffect(() => {
    dispatch(userActions.fetchUser(token));
    handleFetchPlan();
  }, []);

  const WebInterfaceHandleModal = () => {
    handleFetchPlan();
    handleModal();
  };

  useEffect(() => {
    window.addEventListener("webBridge", async (e) => {
      console.log(e.detail);
      WebInterfaceHandleModal();
    });
  }, [window.dispatchEvent]);

  return (
    <Styled.Container>
      <StyledModal.H2>{t("Modal.upgradeCancelImpossible.title")}</StyledModal.H2>

      <Styled.ChangePlan>
        <Styled.CurrentPlanP className={getPlan.currentPlan.toLowerCase()}>
          <span>{getPlan.currentPlan}</span>
          <Styled.PlanSpan>
            ({currentPlan.takeCourseTerm}{
              currentPlan.takeCourseTerm === 1
              ? t('planCard.month') : t('planCard.months')
            })
          </Styled.PlanSpan>
        </Styled.CurrentPlanP>
        <Styled.Arrow />
        <Styled.NewPlanP className={selectedPlan.planCode.toLowerCase()}>
          <span>{selectedPlan.planCode}</span>
          <Styled.PlanSpan>
            ({selectedPlan.takeCourseTerm}{
              selectedPlan.takeCourseTerm === 1
              ? t('planCard.month') : t('planCard.months')
            })
          </Styled.PlanSpan>
        </Styled.NewPlanP>
      </Styled.ChangePlan>
      
      <Styled.Desc>
        {t("Modal.upgradeCancelImpossible.desc")}
      </Styled.Desc>

      <Styled.ButtonGroupSingle>
        <Styled.ButtonSingle className="primary" onClick={() => handleModal()}>
          {t("Modal.upgradeCancelImpossible.button")}
        </Styled.ButtonSingle>
        {/* <StyledForm.ButtonLink to="/classroom" onClick={() => handleModal()}>
          {t("Modal.upgradeCancelImpossible.button")}
        </StyledForm.ButtonLink> */}
      </Styled.ButtonGroupSingle>
    </Styled.Container>
  );
};

export default UpgradeCancelImpossibleModal;

import styled from 'styled-components';
import StyledUtil from 'common/styled/Util.style';

const StyledCurriculumCard = {
  Container: styled.div`
    cursor: pointer;
  `,

  ImgContainer: styled.div`
    position: relative;

    img {
      width: 100%;
      border-radius: 0.6em;
    }
  `,
  Badge: styled.span`
    display: inline-block;
    position: absolute;
    top: 0.8em;
    right: 1em;
    padding: 0.25em 0.5em;
    border-radius: 2px;
    background-color: ${({ bgColor }) => bgColor};
    font-size: 1.3em;
    font-weight: 700;
  `,
  Content: styled.div`
    margin-top: 1.7em;
    height: 10em;
  `,
  Title: styled.h3`
    ${StyledUtil.lineClamp}
    color: #fff;
    font-size: 1.8em;
    font-weight: 500;
  `,
  Desc: styled.p`
    ${StyledUtil.lineClamp}
    color: #8e9199;
    font-size: 1.6em;
    font-weight: 400;
  `,
};

export default StyledCurriculumCard;

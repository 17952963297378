import React, { useEffect, useRef, useState } from 'react';
import Style, { PdfBtn } from './style/Individual.style';
import { PrintReport1 } from './PrintTopik1';
import { PrintReport2 } from './PrintTopik2';
import {
  PDFDownloadLink,
  Document,
  BlobProvider,
  Page,
} from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import IndividualStackBarChart from 'container/MockTestReportPage/components/IndividualStackBarChart';
import { useAxios } from 'common/hook/useAxiosHook';
import mockTestReportApi from 'api/mockTestReport';
import Loading from 'component/atoms/Loading';
import Content from './components/Content';
import Grade from './components/Grade';
import IndividualTable from 'container/MockTestReportPage/components/IndividualTable';
import { Link } from 'react-router-dom';
import { EmptyList } from 'component/atoms/EmptyList';
import OrderBySelect from "component/molecules/OrderBySelect";

const Styled = {
  SelectContainer: styled.div`
    position: relative;
    width: 100%;
    height: 5.5em;
    background-color: #1e2024;
    padding: 2em 0 0 3em;
    .order-by-select{
      position: absolute;
      & > div {
        width: 30em;
      }
    }
    .select__control{
      background-color: transparent;
    }
    @media (max-width: 767px) {
    .select__control {
        font-size: 1.4rem;
      }
    }
  `,
};

const Individual = () => {
  // const [chartData, setChartData] = useState({});
  const [dataOptions, setDataOptions] = useState([]);
  const [dataOptionsIdx, setDataOptionsIdx] = useState(0);
  const blobRef = useRef(null);
  const token = useSelector((state) => state.user.token);
  const userName = useSelector((state) => state.user.data.fullName);
  const [totalScore, setTotalScore] = useState(0);

  const [{ data: chartDataArr }, refreshGetAllData] = useAxios(mockTestReportApi.getIndividualAllTests, [], true);
  const [{ data: chartData, loading }, refreshGetData] = useAxios(mockTestReportApi.getIndividualData, [], true);

  useEffect(() => { refreshGetAllData(token, 0); }, []);
  useEffect(() => {
    console.log('chartDataArr', chartDataArr);
    if (chartDataArr) {
      let list = [];
      chartDataArr.map((data, idx) => {
        list.push({ label: data.title, value: data.productId, idx });
      });
      setDataOptions(list);
      refreshGetData(token, chartDataArr[0].productId);
    }
  }, [chartDataArr]);

  const selectBoxChange = (e) => {
    refreshGetData(token, e.value);
    setDataOptionsIdx(e.idx);
  };


  useEffect(() => {
    if (!chartData) return;
    if (chartData.levelType === 11) {
      setTotalScore(chartData.listeningScore + chartData.readingScore);
    } else if (chartData.levelType === 12) {
      setTotalScore(chartData.totalScore);
    }
  }, [chartData]);
  if (loading) return <Loading />;
  if (chartData)
    return (
      <>
        <Style.Container>
          {dataOptions.length > 0 &&
            <Styled.SelectContainer>
              <OrderBySelect
                onChange={selectBoxChange}
                optionList={dataOptions}
                placeholder={dataOptions[dataOptionsIdx].label}
                className='order-by-select'
              />
            </Styled.SelectContainer>
          }
          <IndividualStackBarChart
            chartData={chartData}
            refreshGetData={refreshGetData}
          />
          <Style.H3 ref={blobRef}>
            {userName}님의 TOPIK 모의고사 성적표
            <PDFDownloadLink
              fileName='mockTest report.pdf'
              document={
                chartData.levelType === 11 ?
                  <PrintReport1 data={chartData} userName={userName} />
                  :
                  <PrintReport2 data={chartData} userName={userName} />
              }
            >
              {({ blob, url, loading, error }) => {
                // Do whatever you need with blob here
                return <div>다운로드</div>;
              }}
            </PDFDownloadLink>
            <Link
              to={'#'}
              target='_blank'
              onClick={() => {
                window.open(blobRef.current.children[0].href, '_blank');
              }}
            >
              프린트
            </Link>
          </Style.H3>
          <Style.FlexRow>
            <Content
              data={{
                grade: chartData.totalGrade,
                date: chartData.testDate ? chartData.testDate.slice(0, 10) : '',
              }}
            />
            <Grade totalScore={totalScore} levelType={chartData.levelType} />
          </Style.FlexRow>
          <Style.H3>시험 결과 (Test Result)</Style.H3>
          <IndividualTable data={chartData} />
        </Style.Container>
      </>
    );
  return <EmptyList />;
};

export default Individual;

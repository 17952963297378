import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Styled from '../styled/TeacherItem.style';

/**
 * 
 * @param {*} item 
 * @param {number} tblMemberTeacherIdx  선생님 코드번호
 * @param {string} teacherID            선생님 이메일
 * @param {string} teacherImage         선생님 이미지
 * @param {string} teacherName          선생님 이름
 * @param {string} subject              수업하는 과목
 * @param {string} subjectEtc           수업하는 과목 - 기타
 * @param {string} classLang            가능 언어
 * @param {string} classLangEtc         가능 언어 - 기타
 * @param {string} livingIn             거주 국가
 * @param {number} payPerHour           시간당 금액
 * @param {string} slogan               소개글
 * @param {string} teachingStyle        수업 스타일 //미사용
 * @param {string} teachingType         수업 종류 //미사용
 * @param {string} teachingTime         수업 가능 시간  //미사용
 */
const TeacherItem = ({item}) => {
  const { t } = useTranslation('aliveClassPage');
  const language = localStorage.getItem('i18nextLng');
	const history = useHistory();
  const [subjectList, setSubjectList] = useState([]);  //수업하는 과목
  const [classLangList, setClassLangList] = useState([]);  //수업 언어
  const [currency, setCurrency] = useState(language === 'ko-KR' ? 'KRW' : 'USD'); //KRW | USD
  
  const clickDetail = (teacherId) => {
    //teacher 개별 id추가 예정

    history.push({
      pathname: `/alive/teachers/${teacherId}`,
    })
  }

  useEffect(()=>{
    if(language === 'ko-KR'){
      setCurrency('KRW');
    }else{
      setCurrency('USD');
    }

    if(item.subject){
      const reg = /[^,\s]+/g;
      const arr = item.subject.match(reg);
      let list = [];
      arr.map((sub, index)=>{
        if(sub === "KO"){
          list = [...list, "Korean"];
        } else if(sub === 'ETC'){
          if(item.subjectEtc){
            list = [...list, item.subjectEtc];
          } else list = [...list, sub];
        } else list = [...list, sub];
      });
      setSubjectList(list);
    }
    
    if(item.classLang){
      const reg = /[^,\s]+/g;
      const arr = item.classLang.match(reg);
      let list = [];
      arr.map((sub, index)=>{
        if(sub === 'ETC'){
          if(item.classLangEtc){
            list = [...list, item.classLangEtc];
          } else list = [...list, sub];
        } else list = [...list, sub];
      });
      setClassLangList(list);
    }
  }, [item])

  return (
    <>
      <Styled.Wrapper>
        <Styled.Profile onClick={()=>clickDetail(item.tblMemberTeacherIdx)}>
          <Styled.Img>
            <img src={item.teacherImage} alt={item.teacherName} />
            <div className='label'>Teacher</div>
          </Styled.Img>
          <Styled.Info>
              <div className='name'>{item.teacherName}</div>
              <dl>
                <dt>{t('teacher.info')}</dt>
                <dd>{subjectList.join(', ')}</dd>
              </dl>
              <dl>
                <dt>{t('teacher.info2')}</dt>
                <dd>{classLangList.join(', ')}</dd>
              </dl>
              <dl>
                <dt>{t('teacher.info3')}</dt>
                <dd>{item.livingIn}</dd>
              </dl>
              <p>{item.slogan}</p>
          </Styled.Info>
          <Styled.ButtonWrap>
            <Styled.Price>
              <strong>{currency} {item.payPerHour}</strong><span> / hour</span>
              <button>See More Details</button>
            </Styled.Price>
          </Styled.ButtonWrap>
        </Styled.Profile>
      </Styled.Wrapper>
    </>
  );
};

export default TeacherItem;

import React from "react";
import styled from "styled-components";
import Img from "component/atoms/Img";
import errorImage from "assets/images/ico_error.png";
import { useTranslation } from "react-i18next";
import ButtonLink from "component/atoms/ButtonLink";
import { useSelector } from "react-redux";

const Styled = {
  Container: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 70vh;
    padding: 0 1em;
    img {
      width: 7.2em;
    }
  `,
  H2: styled.h2`
    margin: 0.5em 0;
    color: #fff;
    font-size: 3.4em;
    font-weight: 700;
  `,
  Desc: styled.p`
    color: #8e9199;
    font-size: 2em;
    font-weight: 400;
  `,
  Desc: styled.p`
    color: #8e9199;
    font-size: 2em;
    font-weight: 400;
  `,
  ButtonLink: styled(ButtonLink)`
    margin-top: 2em;
  `,
};
const OrderFailed = (props) => {
  const { t } = useTranslation("errorPage");
  const lastPaymentType = useSelector((state) => state.common.lastPaymentType);

  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
    const results = regex.exec(props.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };
  const message = getUrlParameter("message");

  return (
    <Styled.Container>
      <div>
        <Img src={errorImage} alt="error" />
        <Styled.H2>{t("paymentH2")}</Styled.H2>
        <Styled.Desc>{message}</Styled.Desc>
        <Styled.ButtonLink to={"/payment/" + lastPaymentType}>Go Cart</Styled.ButtonLink>
      </div>
    </Styled.Container>
  );
};

export default OrderFailed;

import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import reviewImg1en from 'assets/images/i18n/main_saying01_en.png';
import reviewImg2en from 'assets/images/i18n/main_saying02_en.png';
import reviewImg3en from 'assets/images/i18n/main_saying03_en.png';

import reviewImg1kr from 'assets/images/i18n/main_saying01_kr.png';
import reviewImg2kr from 'assets/images/i18n/main_saying02_kr.png';
import reviewImg3kr from 'assets/images/i18n/main_saying03_kr.png';

import reviewImg1jp from 'assets/images/i18n/main_saying01_jp.png';
import reviewImg2jp from 'assets/images/i18n/main_saying02_jp.png';
import reviewImg3jp from 'assets/images/i18n/main_saying03_jp.png';

import reviewImg1cn from 'assets/images/i18n/main_saying01_cn.png';
import reviewImg2cn from 'assets/images/i18n/main_saying02_cn.png';
import reviewImg3cn from 'assets/images/i18n/main_saying03_cn.png';

export const ReviewData = (t) => {
  const { i18n } = useTranslation();
  const [thumbList, setThumbList] = useState([
    reviewImg1en,
    reviewImg2en,
    reviewImg3en,
  ]);

  useEffect(() => {
    if (i18n?.language === 'ko-KR') {
      setThumbList([reviewImg1kr, reviewImg2kr, reviewImg3kr]);
    } else if (i18n?.language === 'ja-JP') {
      setThumbList([reviewImg1jp, reviewImg2jp, reviewImg3jp]);
    } else if (i18n?.language === 'zh-CN') {
      setThumbList([reviewImg1cn, reviewImg2cn, reviewImg3cn]);
    }
  }, [i18n?.language]);

  return [
    {
      title: t('reviews.item1.title'),
      desc: <Trans t={t}>reviews.item1.content</Trans>,
      name: t('reviews.item1.name'),
      info: t('reviews.item1.info'),
      thumb: thumbList[0],
    },
    {
      title: t('reviews.item2.title'),
      desc: <Trans t={t}>reviews.item2.content</Trans>,
      name: t('reviews.item2.name'),
      info: t('reviews.item2.info'),
      thumb: thumbList[1],
    },
    {
      title: t('reviews.item3.title'),
      desc: <Trans t={t}>reviews.item3.content</Trans>,
      name: t('reviews.item3.name'),
      info: t('reviews.item3.info'),
      thumb: thumbList[2],
    },
  ];
};

import { css } from 'styled-components';

export const CountIconStyle = css`
  display: inline-block;
  padding-left: 25px;
  margin: 0 0.5em;
  font-size: 14px;
	color: #8e9199;
  background-repeat: no-repeat;
  background-position: left center;
  @media (mas-width: 1024px) {
    background-size: 18px;
  }
`;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAxios } from 'common/hook/useAxiosHook';
import { TabItem, WorkbookBtn } from './index.style';
import { postFileDownloadLog } from 'container/CourseDetailPage/api/courseDetailApi';

const WorkbookTab = ({ productId, workBookList, token }) => {
  const [, refreshUpdateFileDownLog] = useAxios(postFileDownloadLog, [], true);

  const history = useHistory();
  const handleDownload = (fileId, path) => () => {
    if (path) {
      refreshUpdateFileDownLog(token, productId, fileId);
    } else {
      history.push('/plan');
    }
  };

  return (
    workBookList &&
    workBookList.map(({ id, title, path }, index) => (
      <TabItem key={index}>
        <span>{title}</span>
        <WorkbookBtn
          href={path ? path : '#none'}
          onClick={handleDownload(id, path)}
          target='_blank'
        >
          download
        </WorkbookBtn>
      </TabItem>
    ))
  );
};

export default WorkbookTab;

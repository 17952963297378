import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StyledTabMenu from './index.style';

/**
 *
 * @param {*} param
 * @param {Array} param.menuList
 * @param {Array} param.contentsList
 */
export default function TabMenu({ menuList, contentsList, setVideoComponent }) {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (setVideoComponent) {
      if (menuList[tabIndex] === 'Video') {
        setVideoComponent(true);
      } else {
        setVideoComponent(false);
      }
    }
  }, [menuList, tabIndex, setVideoComponent]);

  return (
    <>
      <StyledTabMenu.Container className='teb-menu' >
        {menuList.map((tabMenu, idx) => (
          <StyledTabMenu.Button
            key={idx}
            className={tabIndex === idx ? 'menu active' : 'menu'}
            onClick={() => setTabIndex(idx)}
          >
            {tabMenu}
          </StyledTabMenu.Button>
        ))}
      </StyledTabMenu.Container>
      {contentsList[tabIndex]}
    </>
  );
}

TabMenu.propTypes = {
  menuList: PropTypes.array.isRequired,
  contentsList: PropTypes.array.isRequired,
};

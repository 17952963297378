import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Style from './styled/Banner.style';
import {Link} from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

import H3 from 'component/atoms/H3';

const AudioBanner = () => {
  const { t } = useTranslation('audioPage');

  return (
    <>
      <Style.BannerWrap>
        <Link to='/event/signup/korean' target='_blank'>
          <p>{t('banner.title')}</p>
          <span>{t('banner.button')}<FiArrowRight /></span>
        </Link>
      </Style.BannerWrap>
    </>
  );
};
export default AudioBanner;
import styled from 'styled-components';
// import { VscClose } from 'react-icons/vsc';
import { Button } from 'container/LevelTestPage/styled';

const Quiz = {
  Wrapper: styled.div`
    position: relative;
    margin: 0 4.5em;
    text-align: center;
  `,
  QuizState: styled.div`
    position: absolute;
    top: 0.5em;
    left: 50%;
    font-size: 1.4em;
    font-weight: 600;
    line-height: 1;
    margin-left: 28em;
    white-space: nowrap;

    > * {
      vertical-align: middle;
    }

    span:first-child,
    span:last-child {
      font-size: 1.429em;
    }
    span:first-child {
      color: #ffb500;
    }

    @media (max-width: 767px) {
      top: -2em;
      left: auto;
      right: 0;
      margin-left: 0;
    }
  `,
  Quiz: styled.div`
    display: inline-block;
    position: relative;
    margin: 0 auto;
    max-width: 46em;
  `,
  Header: styled.div.attrs((props) => ({
    children: (
      <>
        <span>{props.number}.</span>
        {props.question}
      </>
    ),
  }))`
    position: relative;
    padding-left: 2em;
    font-size: 2em;
    font-weight: 700;
    text-align: left;

    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  `,
  Tmp: styled.div`
    position: relative;
    width: 100%;
    margin: 2em 0 0;
    border-radius: 6px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
    }
  `,
  Desc: styled.div`
    position: relative;
    margin-top: 0.833em;
    font-size: 1.8em;
    font-weight: 400;
    text-align: left;

    .blah {
      display: inline-block;
      width: 10em;
      border-bottom: 1px solid #fff;
    }

    .blank {
      display: inline-block;
      width: 3em;
    }

    .underline {
      text-decoration: underline;
    }
  `,
  Choices: styled.ol`
    position: relative;
    margin-top: 2.3em;
  `,
  ChoicesItem: styled.li`
    position: relative;
    font-size: 1.6em;
    margin: 0.188em 0 0.188em -0.5em;

    &.selected button::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0.87em;
      height: 0.5em;
      width: 0.5em;
      background-color: #fff;
      border-radius: 50rem;
      transform: translateY(-50%);
    }
    &.incorrect button {
      background-color: rgba(192, 26, 26, 0.5);
      border-radius: 50rem;
    }
    &.correct button {
      background-color: rgba(72, 92, 199, 0.5);
      border-radius: 50rem;
    }
  `,
  ShowResult: styled.div`
    position: relative;
    display: inline-block;
    padding-left: 1.714em;
    color: #c9cbd1;
    font-size: 1.4em;
    font-weight: 600;
    text-transform: uppercase;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
    }

    &.correct::before {
      width: 1em;
      height: 1em;
      border: 2px solid #c9cbd1;
      border-radius: 50rem;
      transform: translateY(-50%);
    }

    &.incorrect::before,
    &.incorrect::after {
      width: 1.286em;
      height: 0.143em;
      background-color: #c9cbd1;
    }

    &.incorrect::before {
      transform: translateY(-50%) rotate(45deg);
    }
    &.incorrect::after {
      transform: translateY(-50%) rotate(-45deg);
    }

    @media (max-width: 767px) {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  `,
  Button: styled.button.attrs({ type: 'button' })`
    position: relative;
    display: block;
    padding: 0.5em 0.5em 0.5em 2em;
    color: #fff;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0.5em;
      height: 1em;
      width: 1em;
      border: 1px solid #fff;
      border-radius: 50rem;
      transform: translateY(-50%);
    }

    &:disabled {
      cursor: default;
    }
  `,
  ButtonGroup: styled(Button.ButtonGroup)`
    position: relative;
    margin-top: 2em;

    @media (max-width: 767px) {
      &.right {
        text-align: center;
        padding-top: 3em;
      }
    }
  `,
};

export default Quiz;

import React from "react";
import styled from "styled-components";
import { ModalContext } from "component/Modal/modalContext";
import { Trans, useTranslation } from "react-i18next";
import { MOBILE_APP_USER_AGENT } from "common/constant";

const Style = {
  Desc: styled.p`
    margin: 1em 0 1.5em;
    text-align: center;
    font-size: 2em;
    font-weight: 700;
  `,
  ButtonContainer: styled.div`
    display: flex;
    > * {
      flex: 1;
    }
  `,
  Button: styled.button`
    display: inline-block;
    padding: 1.25em 0;
    border: 1px #8b8f99 solid;
    border-radius: 0.375em;
    font-size: 1.6em;
    font-weight: 700;
    letter-spacing: 0.1px;
    &.primary {
      margin-right: 0.5em;
      border: 0;
      background-color: #475cc7;
      color: #fff;
    }
  `,
};

const OverseasConfirmModal = ({ handleSubmit }) => {
  const { t } = useTranslation("paymentPage");
  let { handleModal } = React.useContext(ModalContext);

  const onSubmit = (e) => {
    e.preventDefault();
    const userAgent = window.navigator.userAgent;
    if (userAgent === MOBILE_APP_USER_AGENT) {
      handleModal();
      return;
    }
    handleSubmit();
  };

  return (
    <>
      <Style.Desc>
        <Trans t={t}>overseasConfirm.text</Trans>
      </Style.Desc>
      <Style.ButtonContainer>
        <Style.Button className="primary" onClick={onSubmit}>
          {t("overseasConfirm.confirm")}
        </Style.Button>
        <Style.Button
          onClick={() => {
            handleModal();
            window.location.reload();
          }}
        >
          {t("overseasConfirm.cancel")}
        </Style.Button>
      </Style.ButtonContainer>
    </>
  );
};

export default OverseasConfirmModal;

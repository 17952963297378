import React from 'react';
import Style from './styled/AudioSection.style'
import ListContainer from 'container/AudioDetailPage/ListContainer';

const AudioSection = ({ audioInfo }) => {

  return (
    <Style.Container>
      <ListContainer audioInfo={audioInfo} />
    </Style.Container>
  );
};

export default AudioSection;

import React, { useCallback, useEffect, useState, useRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss"
// import "swiper/components/navigation/navigation.scss"
import Styled from './styled/MultiEventBanner.style';
import { openLink } from 'common/util/util';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Autoplay, Pagination, Navigation]);

/**
 * @param {string} btnColor   닫기 버튼 색상
 * @param {string} cookieKey  '오늘 하루 보지 않기' 쿠키의 이름
 * 
 * @param {Array} bannerDataList
 * @param {string} linkUrl          클릭 시 연결되는 url링크
 * @param {boolean} isExternalLink  url링크가 외부링크인지
 * @param {boolean} isBlank         target='_blank'인지
 * @param {string} imgSrc           pc버전 이미지
 * @param {string} mobileImgSrc     mobile버전 이미지
 * @param {string} imgAlt           이미지 alt
 */
const MultiEventBanner = ({btnColor, cookieKey, bannerDataList}) => {
	// 다국어
	const { t } = useTranslation('eventBanner');

  // 팝업 띄움 여부
  const [openPopupCoupon, setOpenPopup] = useState(true);
  const [isMobile, setisMobile] = useState(false);
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const swiperRef = useRef(null);
  const [updateSwiper, setUpdateSwiper] = useState(0);
  const API_HOST = process.env.REACT_APP_ENV === 'local' ? 'http://localhost:3000' : process.env.REACT_APP_API_HOST;
  const [cookies, setCookie] = useCookies([cookieKey]);

  // 리사이즈 이벤트를 감지하여 가로 길이에 따라 모바일 여부 결정
  const resizingHandler = () => {
    // console.log('resizingHandler')
    if (window.innerWidth <= 1023) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth <= 1023) {
      setisMobile(true);
    }

    window.addEventListener("resize", resizingHandler);
    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);

  useEffect(()=>{
    const timeout = setTimeout(() => {
      //swiper update
      swiperRef.current?.swiper?.update();
    }, 0);

    return () => {
      clearTimeout(timeout);
    }
  },[updateSwiper])

  const SlideInner = useCallback((data) => {
    const { imgSrc, mobileImgSrc, imgAlt, } = data;

    if(isMobile){
      return(
        <Styled.BoxImg>
          <img src={mobileImgSrc} alt={imgAlt} />
        </Styled.BoxImg>
      );
    }else{
      return(
        <img src={imgSrc} alt={imgAlt} />
      );
    }
  }, [isMobile]);

  const swiperProps = React.useMemo(()=>{
    if(bannerDataList.length === 1){
      return {
        loop: false,
        pagination: false,
        navigation: false,
        watchOverflow: true,
      }
    }else if(bannerDataList.length > 1){
      return {
        loop: true,
        autoplay: {delay: 3000, disableOnInteraction: false},
        pagination: {clickable: true},
        navigation: true,
        // navigation: {
        //   nextEl: `.next`,
        //   prevEl: `.prev`,
        // }
      }
    }
  }, [bannerDataList.length]);

  const handleTodalClose = () => {
    const date = new Date();
    const expireDate = new Date(date.setDate(date.getDate()+1));

    setCookie(cookieKey, true, {
      path: '/',
      secure: true,
      sameSite: 'none',
      expires: expireDate,
    })
    setOpenPopup(false);
  }

  return (
    <>
    {openPopupCoupon && bannerDataList && bannerDataList.length > 0 && !cookies[cookieKey] &&
    <Styled.EventBannerWrap className={isMobile && "mobile"}>
      <Styled.EventContent
        onMouseEnter={()=>setIsMouseEnter(true)}
        onMouseLeave={()=>setIsMouseEnter(false)}
      >
        <Styled.Swiper
          ref={swiperRef}
          slidesPerView={1}
          observer={true}
          updateOnWindowResize={true}
          {...swiperProps}
          modules={[Autoplay, Pagination, Navigation]}
          className={'benefitSwiperTab' + (isMouseEnter ? ' enter' : '')}
          onResize={(swiper)=>setUpdateSwiper((state)=>state+1)}
          onInit={(swiper)=>setUpdateSwiper((state)=>state+1)}
        >
          {bannerDataList.map((data, index)=>{
            const { linkUrl, isExternalLink, isBlank} = data;
            if(isBlank){
              return (
                <SwiperSlide key={index}>
                  <Styled.Link to={''} onClick={()=>{
                    openLink(isExternalLink? linkUrl : API_HOST + linkUrl, '_blank')
                  }}>
                    {SlideInner(data)}
                  </Styled.Link>
                </SwiperSlide>
              );
            }
            return (
              <SwiperSlide key={index}>
                <Styled.Link to={linkUrl}>
                  {SlideInner(data)}
                </Styled.Link>
              </SwiperSlide>
            );
          })}
        </Styled.Swiper>
      </Styled.EventContent>
      <Styled.EventBottom>
        <Styled.TodayClose onClick={handleTodalClose}>
          {t('todayClose')}
        </Styled.TodayClose>
        <Styled.CloseBtn onClick={() => setOpenPopup(false)}>
          {t('closeBtn')}
        </Styled.CloseBtn>
      </Styled.EventBottom>
      <Styled.Close $btnColor={btnColor? btnColor : '#fff'} onClick={() => setOpenPopup(false)} />
    </Styled.EventBannerWrap>
    }</>
  );
};


export default MultiEventBanner;
import axios from "axios";
import { asyncApi } from "common/util/asyncApi";

const youtubeApiKey = "AIzaSyCTENWiPHn2suRrJm5Jr3Vr4WhspCdSlhM";
//마스터 토픽 라이브 url
// const liveVideoUrl = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&channelId=UCcU6RCjsoPqJ7HtiIpLmyyw&eventType=live&maxResults=1&type=video&key=${youtubeApiKey}`;

//임시 url
const liveVideoUrl = `https://youtube.googleapis.com/youtube/v3/search?part=snippet&eventType=live&maxResults=1&q=covid&type=video&key=${youtubeApiKey}`;
const playListUrl = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=PLYeT0MeDh3Cw0SkOk-DtIuyaKG2FOvv8U&maxResults=5&key=${youtubeApiKey}`;

export const fetchVideoList = async () => {
  let playlist = await axios.get(playListUrl);
  let videoIdList = playlist.data.items.map((data) => {
    return data.snippet.resourceId.videoId;
  });
  let searchVideoUrl = `https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails%2Cstatistics&id=${videoIdList.join(
    "%2C"
  )}&key=${youtubeApiKey}`;
  let response = await axios.get(searchVideoUrl);
  return response.data.items;
};

export const fetchLiveVideo = async () => {
  let LiveVideoData = await axios.get(liveVideoUrl);
  let liveVideoId = LiveVideoData.data.items[0].id.videoId;
  let searchLiveVideoUrl = `https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CliveStreamingDetails&id=${liveVideoId}&key=${youtubeApiKey}`;
  let response = await axios.get(searchLiveVideoUrl);
  return response.data.items[0];
};

export const fetchUpcoming = async () => {
  let url = "/api/v1/live-schedule/upcoming";
  let response = await asyncApi({ url });
  return response.data;
};

import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import PageHeader from 'component/atoms/PageHeader';
import ClassroomRoutes from 'container/ClassroomPage/Routes';
import StyledTab from 'component/TabMenu/index.style';
import SeoHelmet from 'component/Helmet';
import { SeoData } from 'common/seoData';
import { useTranslation } from 'react-i18next';
// import PlanBannerPopup from "component/PlanBanner";
import RollBanner from "component/RollBanner";

const Course = () => {
  const { t } = useTranslation('classroomPage');
  const { url } = useRouteMatch();

  return (
    <>
      <SeoHelmet
        title={SeoData.classroom.title}
        desc={SeoData.classroom.desc}
      />
      <ContentsWrapper>
        {/* 20221209 PlanBannerPopup > RollBanner로 변경 */}
        {/* <RollBanner /> */}
        {/* <PlanBannerPopup /> */}

        <PageHeader title={t('title')} text={t('subtitle')} />

        <StyledTab.Container>
          <StyledTab.NavLink exact to={url}>
            My Classroom
          </StyledTab.NavLink>
          <StyledTab.NavLink to={`${url}/topik-mock-test`}>
            TOPIK Mock Test
          </StyledTab.NavLink>
          <StyledTab.NavLink to={`${url}/curriculum`}>
            Curriculum
          </StyledTab.NavLink>
        </StyledTab.Container>

        <ClassroomRoutes />
      </ContentsWrapper>
    </>
  );
};

export default Course;

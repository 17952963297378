import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LevelTestHeader from 'container/LevelTestPage/common/LevelTestHeader';
import Step from 'container/LevelTestPage/common/Step';
import { Button } from 'container/LevelTestPage/styled';
import { useAxios } from 'common/hook/useAxiosHook';
import myyLevelApi from 'api/myLevel';
import { useTranslation } from 'react-i18next';

const Index = () => {
  const { t } = useTranslation('levelTestPage');
  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const selectLanguage = useSelector((state) => state.user.data? state.user.data.selectLanguage : "");
  const [, fetchLevelSave] = useAxios(myyLevelApi.postLevelTestData, [], true);

  const subject = [
    { id: 1, code: 2, subject: 'TOPIK', url: '/level-test/check-level' },
    { id: 2, code: 1, subject: '일반 한국어', url: '/level-test/check-level' },
    // { id: 3, code: 3, subject: 'EPS-TOPIK',  url: '/level-test/check-recommendation' }, 230502 태균CP님 요청으로 삭제 (사용하지않는다고 하심)
  ];

  const [checked, setChecked] = useState({
    id: null,
    code: null,
    subject: null,
    url: null,
  });

  const handleClick = () => {
    if (checked.id === null) return;

    if (checked.id === 3) {
      fetchLevelSave({
        languageCode: selectLanguage,
        languageLevel: null,
        subjectCode: checked.code,
        targetLevel: null,
        token: token,
      });
    }

    history.push({
      pathname: `${checked.url}`,
      state: {
        languageCode: selectLanguage,
        languageLevel: null,
        subjectCode: checked.code,
        targetLevel: null,
        token: token,
      },
    });
  };

  return (
    <>
      <Step activeIndex={1} />
      <LevelTestHeader title={t('selectSubject.title')} />
      <Button.SelectedSubjectGroup>
        {subject.map((selectedEle) => {
          const { id, code, subject, url } = selectedEle;
          return (
            <Button.SelectedSubject
              key={id}
              className={checked.id === id ? 'active' : ''}
              onClick={() => setChecked({ ...checked, id, code, subject, url })}
            >
              {subject}
            </Button.SelectedSubject>
          );
        })}
      </Button.SelectedSubjectGroup>

      <Button.ButtonGroup className='center'>
        <Button.Button onClick={handleClick}>
          {t('selectSubject.button')}
        </Button.Button>
      </Button.ButtonGroup>
    </>
  );
};

export default Index;

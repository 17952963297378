import React from 'react';
import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import VocaCard from 'component/VocaCard';

const StyledContainer = styled.div`
  margin-top: 8em;
`;
const UserVocaCardPage = () => {
  return (
    <ContentsWrapper>
      <StyledContainer>
        <VocaCard isMyPage={true} />
      </StyledContainer>
    </ContentsWrapper>
  );
};

export default UserVocaCardPage;

import styled from 'styled-components';
import editIcon from 'assets/images/ico_edit.png';

const StyledAccount = {
  Container: styled.div`
    margin-right: 3em;
    padding: 4em 3em 0 3em;
    border-radius: 0.6em;
    background-color: #1e2024;

    @media (max-width: 767px) {
      margin-right: 0;
      margin-bottom: 3em;
      font-size: 1.2em;
    }

    .title {
      display: block;
      margin-bottom: 1.18em;
      color: #8b8f99;
      font-size: 1.6em;
      font-weight: 400;
      > .edit {
        position: relative;
        float: right;
        padding-left: 1.625em;
        margin-right: 0.625em;
        margin-top: -1.4em;
        color: #5d5f63;
        cursor: pointer;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          width: 2em;
          height: 2em;
          background: url(${editIcon}) no-repeat center center/cover;
          transform: translateY(-50%);
        }
      }
    }
    .info {
      margin-bottom: 2.2em;
      padding-left: 0.55em;
      padding-bottom: 1em;
      border-bottom: 1px solid #2b2e35;
      color: #fff;
      font-size: 1.8em;
      font-weight: 400;

      &.sns {
        svg {
          margin-right: 0.55em;
        }
      }

      @media (max-width: 767px) {
        padding-left: 0.3em;
      }
    }
    .select {
      border-bottom: none;
    }
  `,
  Title: styled.h3`
    margin-bottom: 1.75em;
    color: #8b8f99;
    font-size: 2em;
    font-weight: 700;
    letter-spacing: 0.1px;
  `,
  CircleSpan: styled.span`
    display: inline-block;
    width: 0.35em;
    height: 0.35em;
    margin-right: 0.5em;
    border-radius: 50%;
    background-color: #fff;
  `,
};

export default StyledAccount;

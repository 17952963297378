import styled from 'styled-components';

const StyledAside = {
  TabWrapper: styled.div`
    height: calc(100vh - 32em);
    min-height: 64em;
    max-height: 80em;
    padding-bottom: 3em;
    border-bottom: 2px solid #2b2e35;
    overflow: auto;
    @media (max-width: 1024px) {
      height: auto;
      max-height: 100%;
    }
  `,
};

export default StyledAside;

import styled from 'styled-components';
import TableLayout from 'common/styled/tableLayout.style';

const StyledAddressModal = {
  H2: styled.h2`
    padding-bottom: 1.6em;
    border-bottom: 1px #e8e8e8 solid;
    text-align: center;
    font-size: 2.4em;
    font-weight: 700;
  `,
  Address: styled.div`
    display: flex;
    align-items: center;
    padding: 2em 0;
    border-bottom: 1px #f2f3f4 solid;

    cursor: pointer;
    > span {
      /* flex: 0.1; */
      width: 3em;
      height: 3em;
      background: ${({ active }) =>
        active === 'true' ? '#485cc7' : '#999BA0'};
      border-radius: 50%;
      text-align: center;
      line-height: 4.3em;
    }
    .address {
      flex: 1;
      margin-left: 1.5em;
      h3 {
        margin-bottom: 1em;
        color: #222;
        font-size: 1.6em;
        font-weight: 700;
      }
      p {
        &:last-of-type {
          margin-top: 1.1em;
        }
        color: #222;
        font-size: 1.4em;
        font-weight: 400;
      }
    }
  `,
  ButtonContainer: styled.div`
    margin-top: 3em;
    > button {
      display: inline-block;
      width: 48.62%;
      padding: 1.25em 0;
      border-radius: 0.375em;
      background-color: #485cc7;
      color: #fff;
      font-size: 1.6em;
      font-weight: 700;

      &:first-of-type {
        width: calc(51.38% - 0.625em);
        margin-right: 0.625em;
        background-color: #fff;
        border: 1px #d1d1d1 solid;
        color: #999;
      }
    }
  `,
};

export default StyledAddressModal;

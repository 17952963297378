import React, { useEffect } from 'react';
import Style from './styled/index.style';
import { useAxios } from 'common/hook/useAxiosHook';
import termApi from 'api/term';
import { useTranslation } from 'react-i18next';

// 이용약관 || 개인정보약관
const Term = ({ type }) => {
  const { t: privacyTrans } = useTranslation('privacyPage');
  const { t: termsTrans } = useTranslation('termsPage');
  const [terms, fetchTerms] = useAxios(termApi.getTerm, [], true);
  const { data: termData } = terms;
  const [privacy, fetchPrivacy] = useAxios(termApi.getPrivacy, [], true);
  const { data: privacyData } = privacy;

  useEffect(() => {
    if (type === 'terms') {
      fetchTerms();
    } else if (type === 'privacy') {
      fetchPrivacy();
    }
  }, [type, fetchTerms, fetchPrivacy]);

  return (
    <Style.Container>
      <h2>
        {termData && termsTrans('title')}
        {privacyData && privacyTrans('title')}
      </h2>
      <p>
        {termData && termsTrans('subtitle')}
        {privacyData && privacyTrans('subtitle')}
      </p>
      <div className='contentBox'>
        {termData && <div dangerouslySetInnerHTML={{ __html: termData }}></div>}
        {privacyData && (
          <div dangerouslySetInnerHTML={{ __html: privacyData }}></div>
        )}
      </div>
    </Style.Container>
  );
};

export default Term;

import React from 'react';
import Style from '../style/Table.style';

const Table = ({ gradeReports, levelType }) => {
  const firstTest = gradeReports[0];
  const lastTest = gradeReports[gradeReports.length - 1];
  return (
    <Style.Container>
      <Style.Table>
        <thead>
          <th>영역</th>
          <th>
            최초 <br />
            {firstTest ? firstTest.testDate.slice(0, 10) : '-'}
          </th>
          <th>
            현재 <br />
            {lastTest ? lastTest.testDate.slice(0, 10) : '-'}
          </th>
        </thead>
        <tr>
          <th>점수 (등급)</th>
          <td>
            {firstTest ? firstTest.totalScore : ' - '} <br />({`${firstTest ? firstTest.totalGrade : ' - '}`})
          </td>
          <td className='current'>
            {lastTest ? lastTest.totalScore : ' - '} <br />({`${lastTest ? lastTest.totalGrade : ' - '}`})
          </td>
        </tr>
        <tr>
          <th>듣기</th>
          <td>{firstTest ? firstTest.listeningScore : '-'} </td>
          <td className='current'>{lastTest ? lastTest.listeningScore : '-'} </td>
        </tr>
        <tr>
          <th>쓰기</th>
          <td>
            {(firstTest && firstTest.levelType === 11) || levelType === 11 ? '-' : firstTest && firstTest.levelType === 12 ? firstTest.writingScore : '-'}
          </td>
          <td className='current'>
            {(lastTest && lastTest.levelType === 11) || levelType === 11 ? '-' : lastTest && lastTest.levelType === 12 ? lastTest.writingScore : '-'}
          </td>
        </tr>
        <tr>
          <th>읽기</th>
          <td>{firstTest ? firstTest.readingScore : '-'} </td>
          <td className='current'>{lastTest ? lastTest.readingScore : '-'} </td>
        </tr>
      </Style.Table>
    </Style.Container>
  );
};

export default Table;

import styled from "styled-components";
import StyledUtil from "common/styled/Util.style";

const StyledFooterBanner = {
  Wrapper: styled.div`
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    height: 8rem;
    overflow: hidden;
    z-index: 99;
    @media (max-width: 1024px) {
      width: 100vw;
      justify-content: center;
    }
  `,
  Container: styled.div`
    margin: auto 15em auto;
    @media (max-width: 1024px) {
      margin: 0 auto;
    }
  `,
  Text: styled.span`
    ${StyledUtil.textEllipsis}
    display: inline-block;
    max-width: 41em;
    max-height: 3em;
    vertical-align: middle;
    color: #fff;
    white-space: pre-wrap;
    font-size: 1.4rem;
    font-weight: 500;
    @media (max-width: 1024px) {
      max-width: 30em;
      font-size: 2rem;
    }
    @media (max-width: 768px) {
      max-width: 14em;
    }
  `,
  Btn: styled.button`
    display: inline-block;
    margin-left: 1.5em;
    padding: 1em 1.3em;
    border-radius: 0.4rem;
    background-color: #485cc7;
    vertical-align: middle;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 700;

    @media (max-width: 1024px) {
      font-size: 2rem;
    }
  `,
};

export default StyledFooterBanner;

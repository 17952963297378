import styled from 'styled-components';
import { Circle } from 'rc-progress';

const TestResult = {
  Box: styled.div`
    text-align: center;
  `,
  CircleWrapper: styled.div`
    position: relative;
    width: 24em;
    height: 12em;
    margin: 4.3em auto 2.8em;
  `,
  Circle: styled(Circle).attrs({
    trailWidth: '2',
    trailColor: '#2b2b2b',
    strokeWidth: '2',
  })`
    vertical-align: middle;
    width: 24em;
    height: 24em;
  `,
  Percent: styled.div.attrs(({ children }) => ({
    children: (
      <>
        LV.<span>{children}</span>
      </>
    ),
  }))`
    position: absolute;
    bottom: 0.400em;
    left: 0;
    width: 100%;
    color: #ffb500;
    font-size: 3em;
    font-weight: 600;
    text-align: center;
    line-height: 1;

    span {
      font-size: 1.533em;
    }
  `,
  Msg: styled.p`
    font-size: 2em;
  `,
  Score: styled.p.attrs((props) => ({
    children: (
      <>
        {props.children}
        <span>{props.score}</span>
        <span>/</span>
        <span>{props.total}</span>
      </>
    ),
  }))`
    margin: 2em auto 4em;
    color: #d1d1d1;
    font-size: 1.6em;

    span {
      display: inline-block;
    }

    span:first-child {
      margin-left: 0.500em;
      color: #ffb500;
      font-weight: 600;
    }
  `,
};

export default TestResult;

import React, { useEffect } from 'react';
import Style from './styled/Books.style';
import { masterTopikUrl } from 'common/constant.js';
import {
  fetchWorkBook,
  fetchTextBook,
  postFileDownloadLog,
} from './api/courseDetailApi';
import { useAxios } from 'common/hook/useAxiosHook';
import { useHistory } from 'react-router-dom';
import thumbnailDefault from 'assets/images/thumb_default02.jpg';
import { priceFormat } from 'common/util/util';
import { useTranslation } from 'react-i18next';

const CourseDetailBooks = ({ productId, token }) => {
  const history = useHistory();
  const { i18n, t } = useTranslation('courseDetailPage');

  const [{ data: workBookData }, refreshGetWorkBook] = useAxios(
    fetchWorkBook,
    [],
    true
  );
  const [{ data: textBookData }, refreshGetTextBook] = useAxios(
    fetchTextBook,
    [],
    true
  );

  const [, refreshUpdateFileDownLog] = useAxios(postFileDownloadLog, [], true);

  useEffect(() => {
    refreshGetWorkBook(productId, token);
    refreshGetTextBook(productId);
  }, [refreshGetWorkBook, refreshGetTextBook, productId, token]);

  const clickDownload = (fileId) => {
    refreshUpdateFileDownLog(token, productId, fileId);
  };
  return (
    <Style.Container>
      {workBookData && workBookData.length > 0 && (
        <Style.WorkbookContainer>
          <Style.H2>{t('workbook.title')}</Style.H2>
          {workBookData.map((data) => {
            const { id, title, path: url } = data;
            return (
              <Style.WorkBookFile key={id}>
                <span>{title}</span>
                <Style.WorkbookBtn
                  href={url}
                  onClick={() =>
                    url ? clickDownload(id) : history.push('/plan')
                  }
                  target='_blank'
                >
                  {t('workbook.button')}
                </Style.WorkbookBtn>
              </Style.WorkBookFile>
            );
          })}
        </Style.WorkbookContainer>
      )}

      {textBookData && (
        <Style.TextbookContainer>
          <Style.H2>{t('textbook.title')}</Style.H2>
          <Style.Textbook>
            <Style.TextbookImgContainer>
              <Style.TextbookImg
                src={masterTopikUrl + textBookData.thumbnail}
                onError={thumbnailDefault}
              />
            </Style.TextbookImgContainer>
            <Style.TextbookContent>
              <span className='textbook-title'>{textBookData.title}</span>
              <span className='textbook-desc'>
                Publication:{textBookData.company}
              </span>
              <span className='textbook-desc'>
                Publication date: {textBookData.date}
              </span>
              <span className='textbook-desc'>
                Page: {textBookData.pageCount}
              </span>
              <span className='textbook-desc'>
                Cost of textbook:{' '}
                {i18n?.language === 'ko-KR'
                  ? priceFormat.korea(textBookData.discountPrice)
                  : priceFormat.usa(textBookData.discountPriceDollar)}
              </span>
              <Style.TextbookBtn to='/bookstore'>
                {t('textbook.button')}
              </Style.TextbookBtn>
            </Style.TextbookContent>
          </Style.Textbook>
        </Style.TextbookContainer>
      )}
    </Style.Container>
  );
};

export default CourseDetailBooks;

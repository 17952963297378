import React, { useEffect, useState } from "react";
import Styled from "./index.style";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { CURRENCY, MOBILE_APP_USER_AGENT, planKeyColor } from "common/constant";
import useLoginStatus from "common/hook/useLoginStatus";
import { useSetRedirect } from "common/hook/useRedirectHooks";
import { formatNumber, priceFormat } from "common/util/util";
import { ModalContext } from "component/Modal/modalContext";
import DefaultTextModal from 'component/Modal/DefaultTextModal';
import Login from "component/user/Login";
import ConfirmChangeModal from "../modal/ConfirmChangeModal";
import ConfirmChangeCancelModal from "container/PlanPage/modal/ConfirmChangeCancelModal";
import ChangeCompleteModal from "../modal/ChangeCompleteModal";
import ChangeImpossibleModal from "../modal/ChangeImpossibleModal";
import UpgradeCancelImpossibleModal from "../modal/UpgradeCancelImpossibleModal";

const PlanCard = ({ t, data, getPlan, freeTrial, handleFetchPlan }) => {
  const oneMonthData = data && data[0];
  const { planCode } = oneMonthData
  const { currentPlan, currentPlanProductId } = getPlan;
  // const [upgradable, setUpgradable] = useState(oneMonthData && oneMonthData.upgradable);
  // const [downgradable, setDowngradable] = useState(oneMonthData && oneMonthData.downgradable);
  // let upgradable = data.upgradable;
  // let downgradable= data.downgradable;
  const keyColor = planKeyColor[planCode]
  const userAgent = window.navigator.userAgent;
  const currentCurrency = useSelector((state) => state.common.currency);
  let { handleModal } = React.useContext(ModalContext);
  const isLogin = useLoginStatus();
  const history = useHistory();

  // useEffect(()=>{
  //   console.log('oneMonthData', oneMonthData)
  // }, [oneMonthData])

  // useEffect(()=>{
  //   console.log('planCode', planCode);
  //   console.log("data", data)
  //   console.log("getPlan", getPlan)
  // },[data, getPlan]);

  // useEffect(()=>{
  //   console.log('freeTrial', freeTrial)
  // }, [freeTrial])

  const handleButton = (e, item) => {
    e.preventDefault();

    //페이팔 플랜 변경 제한일 남아있고, 취소|다운그레이드|업그레이드 가능하다면
    if (item.paypalPlanChangeLimitDay && (item.cancelable || item.downgradable || item.upgradable)) {
      handleModal(
        <ChangeImpossibleModal
          selectedPlan={item}
          getPlan={getPlan}
          handleFetchPlan={handleFetchPlan}
        />,
        false,
        460
      );
      return;
    }

    //취소|다운그레이드|업그레이드 불가능 //my plan
    if (!item.cancelable && !item.downgradable && !item.upgradable) return;

    //비로그인
    if (!isLogin) {
      handleModal(<Login />, false);
      return;
    }

    //플랜 변경 취소 가능
    if (item.cancelable) {
      //모바일 앱 && 앱으로 구매
      if (userAgent === MOBILE_APP_USER_AGENT && getPlan.isAppPurchased) {
        handleModal(
          <ConfirmChangeCancelModal
            selectedPlan={item}
            getPlan={getPlan}
            handleFetchPlan={handleFetchPlan}
          />,
          false,
          460
        );
        return;
      }

      //(모바일 앱 && 앱으로 미구매) || (웹 && 앱으로 구매)
      if (
        (userAgent === MOBILE_APP_USER_AGENT && !getPlan.isAppPurchased) ||
        (userAgent !== MOBILE_APP_USER_AGENT && getPlan.isAppPurchased)
      ) {
        return;
      } else {
        handleModal(
          <ConfirmChangeCancelModal
            selectedPlan={item}
            getPlan={getPlan}
            handleFetchPlan={handleFetchPlan}
          />,
          false,
          460
        );
        return; // Cancel Plan Change 팝업이 안떠서 return 추가함 [2021-11-30 yym]
      }
    }

    //즉시변경 (s1 > p1 업그레이드)
    if ((getPlan.directChange && getPlan.toBePlan === null)) {
      handleModal(
        <UpgradeCancelImpossibleModal
          selectedPlan={item}
          getPlan={getPlan}
          handleFetchPlan={handleFetchPlan}
        />,
        false,
        460
      );
      return;
    }
    // 이미 변경 예약된 상태(toBePlan이 STANDARD 또는 PREMIUM 플랜이라면) //변경 취소 말고는 플랜변경 불가
    if ((getPlan.toBePlan !== null && getPlan.toBePlan !== "FREE")) return;

    if (currentPlan === "FREE") {
      //FREE : 신규결제 > 결제페이지로 이동

      //모바일 앱
      //230112 임시 연간 플랜
      if (userAgent === MOBILE_APP_USER_AGENT && (item.productId === 1291 || item.productId === 1292)) {
        //연간 멤버십은 모바일 또는 웹 브라우저에서 구매 가능합니다
        handleModal(<DefaultTextModal title={t('Modal.alert.disableMobile')} btnType="primary" />);
      } else {
        history.push({
          pathname: `/plan-payment/${planCode.toLowerCase()}`,
          search: `?productId=${item.productId}`,
          state: item,
        });
      }

    } else {
      //PREMIUM | STANDARD
      //결제한 경우 : 플랜 변경

      //모바일 앱 && 앱으로 구매
      if (userAgent === MOBILE_APP_USER_AGENT && getPlan.isAppPurchased) {

        //230112 임시 연간 플랜
        if (item.productId === 1291 || item.productId === 1292) {
          //현재 멤버십 구독 중이군요! <br/>1/20부터 연간 멤버십으로 변경 가능합니다.
          // handleModal(<DefaultTextModal title={t('Modal.alert.disableChange')} btnType="primary" />);

          //230126 앱에서 연간 플랜 변경 불가
          handleModal(<DefaultTextModal title={t('Modal.alert.disableMobile')} btnType="primary" />);
        } else {
          handleModal(
            <ConfirmChangeModal
              selectedPlan={item}
              getPlan={getPlan}
              handleFetchPlan={handleFetchPlan}
            />,
            false,
            460
          );
        }
      }

      //(모바일 앱 && 앱으로 미구매) || (웹 && 앱으로 구매)
      if (
        (userAgent === MOBILE_APP_USER_AGENT && !getPlan.isAppPurchased) ||
        (userAgent !== MOBILE_APP_USER_AGENT && getPlan.isAppPurchased)
      ) {
        return;
      } else {

        //230112 임시 연간 플랜
        // if(item.productId === 1291 || item.productId === 1292) {
        //   //현재 멤버십 구독 중이군요! <br/>1/20부터 연간 멤버십으로 변경 가능합니다.
        //   handleModal(<DefaultTextModal title={t('Modal.alert.disableChange')} btnType="primary" />);
        // } else {
        handleModal(
          <ConfirmChangeModal
            selectedPlan={item}
            getPlan={getPlan}
            handleFetchPlan={handleFetchPlan}
          />,
          false,
          460
        );
        // }
      }
    }
  };

  useSetRedirect();

  return (
    <>
      {/* PREMIUM */}
      <Styled.Container>
        {planCode === "PREMIUM" && <Styled.BestTag>BEST</Styled.BestTag>}

        <Styled.Title color={keyColor}>{planCode}</Styled.Title>
        <Styled.Desc>
          {data[0].chargeDescription}
          {/* All Course (TOPIK, Korean, EPS)<br />TOPIK Mock Test */}
        </Styled.Desc>

        {data.map((item, index) => {
          console.log('11111111111111', item)
          let upgradable = item.upgradable;
          let downgradable = item.downgradable;

          if (item.paypalPlanChangeLimitDay && (item.downgradable || item.upgradable)) {
            upgradable = false;
            downgradable = false;
          }
          return (
            <Styled.Button
              key={index}
              color={keyColor}
              className={(planCode === currentPlan && currentPlanProductId === item.productId) && "myPlan"}
              cancelable={item.cancelable}
              onClick={(e) => handleButton(e, item)}
            >
              <div>
                <Styled.ButtonLeftTop>
                  <Styled.Days>{item.takeCourseTerm}
                    {item.takeCourseTerm === 1
                      ? t('planCard.month')
                      : t('planCard.months')}
                  </Styled.Days>

                  {/* 할인 있을때만 보여줌, 0% 이하부터 안보여줌 */}
                  {item.takeCourseTerm > 1 && item.priceKrw < oneMonthData.priceKrw * 12 &&
                    // <Styled.Discount>{Math.round((1 - item.priceKrw / (oneMonthData.priceKrw * 12)) * 100)}%</Styled.Discount>
                    <Styled.Discount>40%</Styled.Discount>
                  }
                </Styled.ButtonLeftTop>

                {/* Will be changed */}
                {item.cancelable &&
                  <Styled.Label>
                    {item.actionLabel}
                  </Styled.Label>
                }
                {/* 비로그인 || (로그인 && 무료체험 사용X) */}
                {freeTrial &&
                  <Styled.Label>
                    {t('planCard.freeTrial')}
                  </Styled.Label>
                }
              </div>

              {freeTrial
                ? (
                  //true 비로그인 || (로그인 && 무료체험 사용X)
                  <Styled.PriceWrap>
                    <Styled.Price>
                      <span>{currentCurrency === CURRENCY.DOMESTIC ? "￦" : "$"}</span>
                      {currentCurrency === CURRENCY.DOMESTIC
                        ? formatNumber(item.priceKrw)
                        : item.priceUsd}
                    </Styled.Price>
                    {item.takeCourseTerm > 1 &&
                      <Styled.Monthly>
                        <p className={'monthly' + (item.takeCourseTerm > 1 ? ' nMonths' : '')}>
                          ({currentCurrency === CURRENCY.DOMESTIC
                            ? `￦${formatNumber(Math.round(item.priceKrw / item.takeCourseTerm / 100) * 100)}`
                            : `$${(item.priceUsd / item.takeCourseTerm).toFixed(2)}`}
                          <span> / </span> {t('planCard.perMonth')})
                        </p>
                      </Styled.Monthly>
                    }
                  </Styled.PriceWrap>)
                : (
                  //false 로그인 && 무료체험 완료
                  <Styled.PriceWrap>
                    <Styled.Price>
                      <span>{currentCurrency === CURRENCY.DOMESTIC ? "￦" : "$"}</span>
                      {currentCurrency === CURRENCY.DOMESTIC
                        ? formatNumber(item.priceKrw)
                        : item.priceUsd}
                    </Styled.Price>
                    {item.takeCourseTerm > 1 &&
                      <Styled.Monthly>
                        <p className='monthly'>
                          ({currentCurrency === CURRENCY.DOMESTIC
                            ? `￦${formatNumber(Math.round(item.priceKrw / item.takeCourseTerm / 100) * 100)}`
                            : `$${(item.priceUsd / item.takeCourseTerm).toFixed(2)}`}
                          <span> / </span> Month)
                        </p>
                      </Styled.Monthly>
                    }
                  </Styled.PriceWrap>)
              }
            </Styled.Button>
          )
        })}
      </Styled.Container>

      {/* 무료체험 화면 */}
      {/* <Styled.PriceWrap>
      <Styled.Price>
        <p className='free'>{item.usingDays}</p>
      </Styled.Price>
      <Styled.Monthly>
          <p className={'free'+ (item.takeCourseTerm > 1 ? ' nMonths' : '')}>
            {item.takeCourseTerm > 1 && <>{item.takeCourseTerm}개월 마다
            {currentCurrency === CURRENCY.DOMESTIC
              ? ` ￦${formatNumber(item.priceKrw)}`
              : ` $${item.priceUsd}`}</>}
            <span>월
              {currentCurrency === CURRENCY.DOMESTIC
              ? ` ￦${formatNumber(Math.round(item.priceKrw/item.takeCourseTerm/100)*100)}`
              : ` $${(item.priceUsd/item.takeCourseTerm).toFixed(2)}`}
            </span>
          </p>
      </Styled.Monthly>
    </Styled.PriceWrap> */}
    </>
  );
};

export default PlanCard;

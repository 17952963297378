import React, { useState, useEffect } from 'react';
import { useAxios } from 'common/hook/useAxiosHook';
import { useLocation } from 'react-router-dom';

import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';

import LandingTop from './LandingTop';
import ResearchMain from './Research';
// import BannerMain from './BannerMain';
import BannerCourses from './BannerCourses';
import Courses from './Courses';
// import Themes from './Themes';
import Teachers from './Teachers';
import LevelTest from './LevelTest';
import Analysis from './Analysis';
// import Benefits from './Benefits';
import Reviews from './Reviews';
import Faqs from './FaqList';

// import themesApi from 'api/theme';
import teachersApi from 'api/teacher';
import faqApi from 'api/faq';
import SeoHelmet from 'component/Helmet';
import coursesApi from 'api/courses';
import Features from 'container/LandingPage/Features';
import { useTranslation } from 'react-i18next';
import { SeoData } from 'common/seoData';

import { ModalContext } from 'component/Modal/modalContext';
import UserGuideModal from 'component/atoms/MobileAgreementModal/UserGuideModal';
import { getUA } from 'react-device-detect';
import { MOBILE_APP_USER_AGENT } from 'common/constant';
import { MainMultiEventBannerWithAPI } from 'component/EventBanner';

export default function LandingPage() {
  const { t, i18n, ready: i18nReady } = useTranslation('landingPage');
  // const getPopularThemeList = useAxios(themesApi.getRecommendPopularList);
  const getAllTeacherList = useAxios(teachersApi.getAllList);
  /*230506 최순철 - 태균CP님 요청으로 FAQ 리스트를 하드코딩으로 변경
  const [getFaqList, fetchFaqList] = useAxios(faqApi.getList, [], true);
  useEffect(() => {
    if (i18n.language) {
      fetchFaqList(
        i18n?.language === 'ko-KR'
          ? 'ko'
          : i18n?.language === 'ja-JP'
          ? 'ja'
          : i18n?.language?.toLowerCase()
      );
    }
  }, [fetchFaqList, i18n.language]);*/

  const getMainVideoByCategory = useAxios(coursesApi.getMainVideoByCategory);
  let { handleModal } = React.useContext(ModalContext);

  const location = useLocation();

  useEffect(() => {
    //mobile 앱 전용 슬라이드 팝업
    if (getUA !== MOBILE_APP_USER_AGENT) return;

    const slideStatus = localStorage.getItem('slide_status');
    if (slideStatus) return;

    localStorage.setItem('slide_status', 1);
    handleModal(<UserGuideModal />);
  }, []);

  return (
    <>
      <SeoHelmet
        title={SeoData.landing.title}
        desc={SeoData.landing.desc}
        t={t}
      />

      {location.pathname === '/' ? (
        <>
          <MainMultiEventBannerWithAPI />
        </>
      ) : (
        <></>
      )}

      {/* 20221207 메인 페이지 변경으로 LandingTop, ResearchMain 컴포넌트 추가됨 */}
      <LandingTop t={t} />

      <ResearchMain t={t} />

      {/* 20221207 메인 페이지 변경으로 인해 이전의 BannerMain 컴포넌트는 사용하지 않음 */}
      {/* <BannerMain t={t} /> */}

      <BannerCourses t={t} />

      <Courses
        initialState={getMainVideoByCategory}
        t={t}
        i18nReady={i18nReady}
      />

      {/* <Themes initialState={getPopularThemeList} t={t} /> */}

      <Teachers initialState={getAllTeacherList} t={t} />

      <LevelTest t={t} />

      <Analysis t={t} />

      {/* <Benefits t={t} /> */}

      <Features t={t} />

      {/* <Anywhere t={t} /> */}

      <Reviews t={t} />

      {/* <Faqs faqListState={faqList} t={t} /> */}
      <Faqs t={t} />
    </>
  );
}

import img1 from 'assets/images/temp/main_course_1.png';
import img2 from 'assets/images/temp/main_course_2.png';
import img3 from 'assets/images/temp/main_course_3.png';
import img4 from 'assets/images/temp/main_course_4.png';
import img5 from 'assets/images/temp/main_course_5.png';
import img6 from 'assets/images/temp/main_course_6.png';
import img7 from 'assets/images/temp/main_course_7.png';
import img8 from 'assets/images/temp/main_course_8.png';

export const CourseData = ({t}) => [
  // {
  //   categoryValue: t('courses.list.item1.categoryValue'),
  //   keyword: t('courses.list.item1.keyword'),
  //   title: t('courses.list.item1.title'),
  //   teacherName: t('courses.list.item1.teacherName'),
  //   otMovUrl:
  //     'https://lgcdn.mastertopik.com/watch/main%20mastertopik%20video/main_video_TOPIK%20I%20Mock_test_2021%200305_1080p20_20210305.mp4?e=1633541449&h=d6516f32ef29c4e7954137e05658593d',
  //   thumbnail: img1,
  // },
  {
    categoryValue: t('courses.list.item2.categoryValue'),
    keyword: t('courses.list.item2.keyword'),
    title: t('courses.list.item2.title'),
    teacherName: t('courses.list.item2.teacherName'),
    otMovUrl:
      'https://d13owoaeiymgg6.cloudfront.net/vod/main_course/topik_reading/HLS/topik_reading.m3u8',
    thumbnail: img2,
  },
  {
    categoryValue: t('courses.list.item3.categoryValue'),
    keyword: t('courses.list.item3.keyword'),
    title: t('courses.list.item3.title'),
    teacherName: t('courses.list.item3.teacherName'),
    otMovUrl:
      'https://d13owoaeiymgg6.cloudfront.net/vod/main_course/topik_listening/HLS/topik_listening.m3u8',
    thumbnail: img3,
  },
  {
    categoryValue: t('courses.list.item4.categoryValue'),
    keyword: t('courses.list.item4.keyword'),
    title: t('courses.list.item4.title'),
    teacherName: t('courses.list.item4.teacherName'),
    otMovUrl:
      'https://d13owoaeiymgg6.cloudfront.net/vod/main_course/topik_writing/HLS/topik_writing.m3u8',
    thumbnail: img4,
  },
  {
    categoryValue: t('courses.list.item5.categoryValue'),
    keyword: t('courses.list.item5.keyword'),
    title: t('courses.list.item5.title'),
    teacherName: t('courses.list.item5.teacherName'),
    otMovUrl:
      'https://d13owoaeiymgg6.cloudfront.net/vod/main_course/epstopik/HLS/epstopik.m3u8',
    thumbnail: img8,
  },
  {
    categoryValue: t('courses.list.item6.categoryValue'),
    keyword: t('courses.list.item6.keyword'),
    title: t('courses.list.item6.title'),
    teacherName: t('courses.list.item6.teacherName'),
    otMovUrl:
      'https://d13owoaeiymgg6.cloudfront.net/vod/main_course/korean_beginner1/HLS/korean_beginner1.m3u8',
    thumbnail: img5,
  },
  {
    categoryValue: t('courses.list.item7.categoryValue'),
    keyword: t('courses.list.item7.keyword'),
    title: t('courses.list.item7.title'),
    teacherName: t('courses.list.item7.teacherName'),
    otMovUrl:
      'https://d13owoaeiymgg6.cloudfront.net/vod/main_course/korean_beginner2/HLS/korean_beginner2.m3u8',
    thumbnail: img6,
  },
  {
    categoryValue: t('courses.list.item8.categoryValue'),
    keyword: t('courses.list.item8.keyword'),
    title: t('courses.list.item8.title'),
    teacherName: t('courses.list.item8.teacherName'),
    otMovUrl:
      'https://d13owoaeiymgg6.cloudfront.net/vod/main_course/korean_intermediate_advanced/HLS/korean_intermediate_advanced.m3u8',
    thumbnail: img7,
  },
];

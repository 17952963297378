import styled from 'styled-components';
import ButtonLink from 'component/atoms/ButtonLink';

const StyledGoToLibrary = {
  Container: styled.div`
    padding: 7.5em 0;
    text-align: center;
    background: #1e2024;
  `,
  H3: styled.h3`
    margin-bottom: 1em;
    font-size: 2.4em;
    font-weight: 700;
  `,
  Btn: styled(ButtonLink)`
    padding: 0.8em 2.1em;
    border: 1px #ccc solid;
    border-radius: 0.6rem;
    font-size: 1.6em;
    font-weight: 700;
    text-transform: uppercase;
  `,
};

export default StyledGoToLibrary;

import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import StyledForm from 'component/form/ModalForm.style';
import {
  mockTest_next_hover_icon,
  mockTest_next_icon,
  mockTest_prev_hover_icon,
  mockTest_prev_icon
} from 'assets/images/course/index';
const StyledCourseMockTest = {
  ListHeading: styled.div`
    position: relative;
    margin-bottom: 2.7rem;
  `,
  StyledSelect: styled(StyledForm.Select)`
  position: absolute !important;
  right:0;
  bottom:0;
  width: 10em;
  font-size: 1.6rem;
  .select__control {
    min-height: 25px;
    background-color: #2b2e35;
  }
  > .select__menu,
  .select__option,
  .select__menu-list {
    background-color: #2b2e35;
  }
  .select__option:hover {
    background: rgba(0, 0, 0, 0.4);
  }
  .css-b8ldur-Input {
    color: #c7cad1;
  }
  .select__indicator {
    svg {
      color: #8b8f99;
    }
  }
  .select__placeholder,
  .select__single-value {
    color: #c7cad1;
    font-weight: 400;
  }
  @media (max-width: 767px) {
    font-size: 2em;
    position:relative !important

  }
`,
  MockTestSample: styled.div`
    h2.sample-title {margin-bottom: 0.2rem}
    .mock-test-sample-wrapper{
      margin: 6.8em 0 15em;
      padding:8em 10em;
      background-color: #1e2024;

      .subtitle{
        text-align: center;
        h3{ font-size: 3.2em; font-weight: 700;}
        p{font-size:2.2em; white-space: pre-line;}
      }
      .mock-test-sample-contents{
        position: relative;
        background-color: #eceef9;
        border:6px solid #485cc7;
        border-radius: 1em;
        padding:5rem 0;
        margin: 3rem auto;
        @media (max-width: 767px) {
          margin: 3rem auto;
          padding:2rem 0;
        }
      }

      .btn-area{
        text-align: center;
        .more-btn{
          width:100%;
          max-width:37.3rem;
          height:5.5rem;
          line-height:5.4rem;
          color:#fff;
          font-size: 2em;
          font-weight:700;
          background-color:#485cc7;
          border-radius: 1rem;
          margin-bottom:1rem;
          display:inline-block;
        }
        p{
          color:#8e9199;
          font-size:1.4em;
        }
      }
      @media (max-width: 767px) {
        margin: 3.8em 0 7em;
        padding:3em 3em;
      }
    }

  `,
  Swiper: styled(Swiper)`
    position: relative;
    margin: auto;
    /* overflow: hidden; */
    max-width: 69rem;
    &.last-item {
      padding-bottom: 2.4em;
    }

    .swiper-wrapper {
      align-items: center;

    }

  `,
  Next: styled.button.attrs({ type: 'button' })`
    position:absolute;
      right:2rem;
      top:50%;
      transform: translateY(-50%);
      background: url(${mockTest_next_hover_icon}) no-repeat center center / cover;
      background-size: 2.5rem 5rem;
      width:10rem;
      height:80%;
      z-index:10;
      cursor: pointer;
      &.swiper-button-disabled{
        cursor: default;
        background: url(${mockTest_next_icon}) no-repeat center center / cover;
        background-size: 2.5rem 5rem;
        @media (max-width: 767px) {
          right:0;
          width:4rem;
          height:50%;
          background-size: 2rem 4rem;
        }
      }
      @media (max-width: 767px) {
        right:0;
        width:4rem;
        height:50%;
        background-size: 2rem 4rem;
    }
  `,
  Prev: styled.button.attrs({ type: 'button' })`
    position: absolute;
      left:2rem;
      top:50%;
      transform: translateY(-50%);
      background: url(${mockTest_prev_hover_icon}) no-repeat center center /  cover;
      background-size: 2.5rem 5rem;
      width:10rem;
      height:80%;
      z-index:10;
      cursor: pointer;
      &.swiper-button-disabled{
        cursor: default;
        background: url(${mockTest_prev_icon}) no-repeat center center / cover;
        background-size: 2.5rem 5rem;
        @media (max-width: 767px) {
          left:0;
          width:4rem;
          height:50%;
          background-size: 2rem 4rem;
        }
      }
      @media (max-width: 767px) {
        left:0;
        width:4rem;
        height:50%;
        background-size: 2rem 4rem;
      }
  `,

  TestWrapper: styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:3rem;
    @media (max-width: 767px) {
      padding:0 4rem
    }
  `,
  TestHeading: styled.div`
    display:flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;;
    gap:1rem;
    width:100%;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }

  `,
  TestHeadingType: styled.span`
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    font-size:1.8rem;
    color:#fff;
    font-weight:700;
    min-width:9.5rem;
    height:3rem;
    background: ${(props) => props.type === 11 ? '#4fc3f7' : props.type === 12 ? '#ffb500' : '#ccc'} /* 타입별 컬러  */
  `,
  Question: styled.h3`
    font-size:2.4rem;
    color:#485cc7;
    font-weight:700;
  `,
  TestBody: styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    gap:2.2rem;
    width:100%;
    @media (max-width: 767px) {
      flex-direction: column;
    }
  `,
  Text: styled.div`
    background-color: #fff;
    color:#000;
    width:32.6rem;
    height:23rem;
    font-size:2rem;
    word-break: keep-all;
    padding: 0 3rem;
    display:flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: pre-line;
    >div{
      >span{
        text-decoration: underline;
      }
    }

    @media (max-width: 767px) {
        width:37.1rem;
    }

  `,
  Example: styled.div`
      display:flex;
      flex-direction: column;
      flex:1;
      gap:1rem;
      width:100%;
      height:23rem;
      @media (max-width: 767px) {
        width:37.1rem;
      }
    >div {
      flex:1;
      width:100%;
      padding:0 2rem;
      border:1px solid #8e9199;
      border-radius: 0.5rem;
      display:flex;
      align-items: center;
      justify-content: flex-start;
      gap:1rem;
      cursor: pointer;
      @media (max-width: 767px) {
        padding:1rem 2rem
      }
      >span{
        display:flex;
        align-items: center;
        justify-content: center;
        width:2.4rem;
        height:2.4rem;
        border-radius:2.4rem;
        background:#8e9199;
        color:#fff;
        font-size:1.4rem
      }
      >p{
        font-size:1.8rem;
        color:#8e9199;
        font-weight:700;
      }


      &.active{
        background:#fff;
        border:1px solid #ffb500;
        >span{
          background-color: #ffb500;;
        }
        >p{color:#ffb500;}
      }
      &.answer{
        background:#fff;
        border:1px solid #485cc7;
        >span{
          background-color: #485cc7;;
        }
        >p{color:#485cc7;}
      }
    }
  `,
  Commentary: styled.div`
    font-size:1.8rem;
    color:#000;
    display:flex;
    align-items: center;
    justify-content: center;
    width:100%;
    height:5.5rem;
    background-color: #C7CBD6;
    opacity: 0;
    &.active{
      opacity:1;
    }
    &.answer{
      background-color: #84efad;
    }
  `,

  ContentContainer: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12em;
    > * {
      flex: 1;
    }

    &.wrapper {
      margin: auto;
      margin-bottom: 12em;
      .wrapperTxt {
        width: 100%;
        h2 {
          max-width: 50rem;
        }
        p {
          max-width: 50rem;
        }
      }
    }

    &.right-image {
      .image-container {
        text-align: right;
      }
    }

    &.left-image {
      .wrapperTxt {
        padding-left: 7em;
      }
      .image-container {
      }
    }

    @media (max-width: 767px) {
      .imageContainer {
        width: 100%;
        max-width: 500px;
      }

      &.right-image {
        flex-direction: column;
        .wrapperTxt {
          padding: 0;
        }
      }
      &.left-image {
        flex-direction: column-reverse;
        .wrapperTxt {
          padding: 0;
        }
      }
    }
  `,
  Heading: styled.div`
    width: 100%;
    h2 {
      margin-bottom: 0.8em;
      font-size: 5em;
      font-weight: 700;
      word-break: keep-all;
      @media (max-width: 767px) {
        font-size: 4.8em;
      }
      span {
        display: block;
      }
      &.spacing{letter-spacing: -0.06em;}
    }
    p {
      color: #9a9ca1;
      font-size: 2em;
      font-weight: 300;
      word-break: keep-all;
      white-space: pre-line;
      @media (max-width: 767px) {
        margin-bottom: 1em;
        max-width: 50rem;
      }
    }
  `,
  SetListH2: styled.h2`
    font-size: 3.6em;
    font-weight: 700;
  `,
  SetListP: styled.p`
    /* margin-bottom: 2.18em; */
    margin-bottom:1rem;
    color: #9a9ca1;
    font-size: 1.6em;
    font-weight: 300;
  `,
  SetCardList: styled.div`
    margin-bottom: 12.4em;
    > div:nth-child(3n) {
      margin-right: 0;
    }
    &::after {
      content: '';
      display: table;
      clear: both;
      overflow: hidden;
    }
    @media (max-width: 1024px) {
      > div:nth-child(odd) {
        margin-right: 2%;
      }
    }
  `,
  SelectContainer: styled.div`
    display: inline-block;
    width: 100%;
    margin: -3em 0 2em;
    .order-by-select {
      float: right;
      z-index: 2;
    }

    @media (max-width: 767px) {
      margin-top: 2em;
    }
  `,
};

export default StyledCourseMockTest;

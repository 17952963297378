import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SwiperSlide } from 'swiper/react';
import Loading from 'component/atoms/Loading';
import Error from 'component/Error';
import SwiperList from 'component/molecules/SwiperList';
import UserThemeItem from 'component/molecules/ThemeItem/UserThemeItem';
import { EmptyList } from 'component/atoms/EmptyList';
import StyledUserHomePage from 'container/UserHomePage/index.style';
import { masterTopikUrl } from 'common/constant';

export default function MyThemes({ initialState }) {
  const token = useSelector((state) => state.user.token);
  const [myThemeList, fetchMyThemeList] = initialState;
  const [slideItems, setSlideItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const { loading, data, error } = myThemeList;

  useEffect(() => {
    fetchMyThemeList({ page: 0, token });
  }, []);

  useEffect(() => {
    if (data !== null) {
      setSlideItems([...slideItems, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [myThemeList]);

  const onNext = () => {
    if (!loading && hasMore) {
      fetchMyThemeList({ page: data.number + 1, token });
    }
  };

  if (error) return <Error error={error} />;

  if (data && data.content.length > 0)
    return (
      <>
        <StyledUserHomePage.H2>My Themes</StyledUserHomePage.H2>
        {slideItems.length === 0 ? (
          <EmptyList isLoading={loading} />
        ) : (
          <SwiperList slideItemList={slideItems} onNext={onNext} id='myThemes'>
            {slideItems.map((item, index) => {
              const imgSrcList = item.themeProductResponse.map(
                (itemChild) =>
                  masterTopikUrl +
                  itemChild?.thumbnailPath +
                  itemChild?.thumbnailTitle
              );
              return (
                <SwiperSlide key={index}>
                  <UserThemeItem
                    id={item.id}
                    imgSrcList={imgSrcList}
                    title={item.name}
                    tag={item.categoryName}
                    videoCount={item.courseCount}
                    likeCount={item.likeCount}
                    isGood={item.isGood}
                    userData={false}
                  />
                </SwiperSlide>
              );
            })}
          </SwiperList>
        )}
      </>
    );
  return <></>;
}

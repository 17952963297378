import React, { useState } from 'react';
import Style from '../styled/component.style';
import Choices from 'container/MockTestPage/Components/Choices';

const OmrCard = ({ data, submitBtnRef, saveBtnRef }) => {
  const [open, showOpen] = useState(false);
  return (
    <Style.OmrCardContainer className={open && 'open'}>
      <button type='button' onClick={() => showOpen((prev) => !prev)}>
        답안지
      </button>
      <Style.OmrCard>
        {data.map((data) => {
          return (
            <Choices key={data.unitValuationId} id={data.unitValuationId} />
          );
        })}
      </Style.OmrCard>
      {
        <Style.OmrBtnContainer>
          <button onClick={() => saveBtnRef.current.click()}>임시저장</button>
          <button onClick={() => submitBtnRef.current.click()}>제출하기</button>
        </Style.OmrBtnContainer>
      }
    </Style.OmrCardContainer>
  );
};

export default OmrCard;

import React,{useEffect, useState} from 'react';
import { List } from '../styled';
import { Circle } from 'rc-progress';
import { getOrdinal } from 'common/util/util';
import { useHistory } from 'react-router-dom';
import Img from 'component/atoms/Img';
import thumbnailDefault from 'assets/images/thumb_default02.jpg';
import { masterTopikUrl } from 'common/constant';

import XIcon from 'assets/images/mycurriculum_new/x.png';
import test1 from 'assets/images/mycurriculum_new/test1.png';
import { useAxios } from 'common/hook/useAxiosHook';
import productApi from 'api/product';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const dummyData = [
  {
    id: 1123,
    title: "aaaaa",
  },
  {
    id: 1124,
    title: "bbbbb",
  },
  {
    id: 1125,
    title: "ccccc",
  },
  {
    id: 1126,
    title: "ddddd",
  },
];

const CourseList = ({ data, index, languageId, changeLanguage }) => {
  const [isPop, setIsPop] = useState(false)
  const [courseResponse, fetchCourseData] = useAxios(
    productApi.getProduct,
    [],
    true
  );

  const [checkedLanguage, checkLanguageCourse] = useState(languageId);

  const { data: courseData } = courseResponse;

  useEffect(() => {
    if (languageId) {
      fetchCourseData(languageId);
    }
  }, [languageId]);

  const handleSelectLanguageChange = (id) => {
    checkLanguageCourse(id);
  };

  const changeSelectLanguage = () => {
    changeLanguage(index, checkedLanguage);
    setIsPop(false);
  };

  return (
    <>
      {courseData && courseData.courseResponse &&
        <List.Container>
          <Link to={`/courses/detail/${courseData.courseResponse.productId}`}>
            <List.Image>
              <Img
                src={masterTopikUrl + data.thumbnailPath}
                onError={thumbnailDefault}
                alt='course thumbnail'
              />
            </List.Image>
          </Link>
          <List.Content>
            <List.FlexDiv>
              <List.CourseTitle>{courseData.courseResponse.title}</List.CourseTitle>
              {/* <List.Button className='period' onClick={() => setIsPop(true)}>
                Change Language
              </List.Button> */}

              <List.ChangePopWrap className={isPop ? 'active' : ""}>
                <List.ChangePopHead>
                  <h3>Change course in other languages</h3>
                  <p>Choose the course you want to take.</p>
                  <button className='close' onClick={() => setIsPop(false)}><img src={XIcon} alt="닫기" /></button>
                </List.ChangePopHead>
                <List.ChangePopBody>
                  <List.ChangePopLists>
                    {dummyData.map((item) => (
                      <List.ChangePopList onClick={()=> handleSelectLanguageChange(item.id)}>
                        <label htmlFor="change_lang1">
                          <List.Thum className={checkedLanguage === item.id ? 'checked' : ''}><img src={test1} /></List.Thum>
                          <List.TextBox>
                            <h3>{item.title}</h3>
                            <p>Overview</p>
                          </List.TextBox>
                        </label>
                      </List.ChangePopList>
                    ))}

                  </List.ChangePopLists>
                </List.ChangePopBody>
                <List.ChangePopFoot>
                  <button className='cancel' onClick={()=>{ setIsPop(false) }}>Cancel</button>
                  <button className='change' onClick={changeSelectLanguage}>Change</button>
                </List.ChangePopFoot>
              </List.ChangePopWrap>
            </List.FlexDiv>
            <List.FlexDiv>
              <div className='lecture-info'>
                <List.LectureInfos>
                  <List.LectureInfo>Teaches: {data.subjectTypeValue}</List.LectureInfo>
                  <List.LectureInfo>Target: {data.levelTypeValue}</List.LectureInfo>
                  <List.LectureInfo>Includes: {(courseData.courseResponse.minute > 0)? courseData.courseResponse.hour + 1 :courseData.courseResponse.hour} Hours, {courseData.courseResponse.classCount} lessons</List.LectureInfo>
                </List.LectureInfos>
                <List.LectureInfoText>
                  {courseData.courseResponse.character}
                </List.LectureInfoText>
              </div>
              {/*<div className="chart-wrap">
                  <List.ProgressContainer>
                    <Circle
                      percent={data.progressPercent}
                      strokeWidth={3}
                        trailWidth={3}
                        strokeColor='#f8b209'
                      trailColor='#8b8f99'
                      className='progressBar'
                    />
                    <span>{data.progressPercent}%</span>
                  </List.ProgressContainer>
                  <List.LectureNo>
                    {data.viewClassCount}/{data.classCount}
                  </List.LectureNo>
                </div>*/}
            </List.FlexDiv>
          </List.Content>
        </List.Container>

      }
    </>
  );
};

export default CourseList;

import React from 'react';

export const termsOfPrivacyKo = () => (
  <div class='policy'>
    <h3 class='policy-title'>개인정보처리방침</h3>
    <p>
      MasterTOPIK은 회원님의 소중한 개인정보를 보호하는 데 최선의 노력을 다하고
      있습니다.
    </p>
    <div class='policy-section'>
      <h4 class='policy-subtitle'>개인정보 수집 및 이용동의</h4>
      <p>수집하는 개인정보 항목 및 수집방법.</p>
      <br />
      <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
      <br />

      <ol class='policy-num'>
        <li>
          1) 수집하는 개인정보 항목 및 수집방법
          <ol class='policy-num'>
            <li>
              1-1) 홈페이지 회원 가입 및 관리
              <ul class='policy-bu'>
                <li>
                  ․ 필수항목 : 성명, 생년월일, 성별, 로그인ID, 비밀번호,
                  비밀번호 변경 질문/답변, 회원구분(일반, 학생, 선생님 등),
                  이메일, 자택 전화번호, 휴대 전화번호, 자택주소
                </li>
                <li>․ 선택항목 : 추천인ID, 만 14세 미만은 법정대리인 정보</li>
              </ul>
            </li>
            <li>
              1-2) 재화 또는 서비스 제공
              <ul class='policy-bu'>
                <li>
                  ․ 필수항목 : 성명, 생년월일, 성별, 로그인ID, 비밀번호,
                  비밀번호 변경 질문/답변, 회원구분(일반, 학생, 선생님 등),
                  이메일, 자택 전화번호, 휴대 전화번호, 자택주소, 신용카드번호,
                  은행계좌정보, 결제기록, 휴대폰 통신사 등 결제정보
                </li>
                <li>
                  ․ 선택항목 : 추천인ID, 학력, 학교, 학년, 계열, 희망대학,
                  접속환경, 인지경로, 관심 학습분야, 관심 선생님 스타일,
                  학습스타일, 관심 컨텐츠, 재직학교, 강의스타일, 인강에 대한
                  의견, 등록된 자녀의 정보, 만 14세 미만은 법정대리인 정보
                </li>
              </ul>
            </li>
            <li>
              1-3) 또한 인터넷 서비스 이용과정이나 사업처리 과정에서 아래와 같은
              개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
              <ul class='policy-bu'>
                <li>
                  ․ IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량
                  이용기록 등
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          2) 개인정보 수집방법 <br />
          <br />
          회사는 다음과 같은 방법으로 개인정보를 수집합니다.
          <ul class='policy-bu'>
            <li>
              - 홈페이지(회원가입, 상담게시판, 공개 게시판, 기타 개인정보를
              바탕으로 하는 서비스 이용 등), 서면양식, 전화/팩스를 통한
              회원가입, 경품행사 응모, 배송 요청, 제휴사로부터의 제공, 생성정보
              수집 툴을 통한 수집
              <br />
              <br />
              <br />
            </li>
          </ul>
        </li>
      </ol>

      <p>개인정보의 수집 및 이용목적</p>
      <p>
        회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
        개인정보는 다음의 목적이외의 용도로는 이용되지 않으며, 이용 목적이
        변경될 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
      </p>
      <br />

      <ol class='policy-num'>
        <li>
          1) 홈페이지 회원가입 및 관리 <br />
          회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별∙인증,회원자격
          유지∙관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용
          방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인,
          각종 고지∙통지, 고충처리, 분쟁 조정을 위한 기록 보존 등
        </li>
        <li>
          2) 재화 또는 서비스 제공
          <br />
          물품배송, 서비스 제공, 계약서․청구서 발송, 콘텐츠 제공, 맞춤서비스
          제공, 본인인증, 연령인증, 요금결제․정산, 채권추심 등
        </li>
        <li>
          3) 마케팅 및 광고에 활용
          <br />
          신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 등 광고성 정보
          전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도
          파악 또는 회원의 서비스 이용에 대한 통계
        </li>
        <li>
          4) 고충처리
          <br />
          민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리결과
          통보 등
        </li>
        <li>
          5) 기타
          <br />
          가입횟수 제한, 분쟁조정을 위한 기록보존,회원의 각종 통계자료 산출
          <br />
          <br />
          <br />
        </li>
      </ol>

      <p>
        수집한 개인정보의 보유 및 보유기간
        <br />
        회사는 법령에 따른 개인정보․이용기간 또는 이용자로부터 개인정보를 수집
        시에 동의 받은 개인정보 보유․이용기간 내에서 개인정보를 처리․보유합니다.
      </p>
      <br />

      <p>각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.</p>
      <ol class='policy-num'>
        <li>
          1) 홈페이지 회원 가입 및 관리 : 홈페이지 탈퇴 시 까지 다만, 다음의
          사유에 해당하는 경우에는 해당 사유 종료 시 까지
          <ol class='policy-num'>
            <li>
              1-1) 관계 법령 위반에 따른 수사․조사 등이 진행 중인 경우에는 해당
              수사․조사 종료 시 까지
            </li>
            <li>
              1-2) 홈페이지 이용에 따른 채권․채무관계 잔존 시에는 해당
              채권․채무관계 정산 시 까지
            </li>
          </ol>
        </li>
        <li>
          2) 재화 또는 서비스 제공 : 재화․서비스 공급완료 및 요금결제․정산
          완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지
          <ol class='policy-num'>
            <li>
              2-1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른
              표시․광고, 계약내용 및 이행 등 거래에 관한 기록
              <ul class='policy-bu'>
                <li>- 표시․광고에 관한 기록 : 6월</li>
                <li>
                  - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년
                </li>
                <li>- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년</li>
              </ul>
            </li>
            <li>
              2-2)「통신비밀보호법」제41조에 따른 통신 사실 확인 자료 보관
              <ul class='policy-bu'>
                <li>
                  - 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월
                </li>
              </ul>
            </li>
            <li>
              2-3)「정보통신망 이용촉진 및 정보보호 등에 관한 법률」시행령
              제29조에 따른 본인확인정보 보관 : 게시판에 정보 게시가 종료된 후
              6개월
            </li>
          </ol>
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>개인정보 취급 위탁 동의</h4>
      <br />
      <p>개인정보 이용약관</p>
      <br />
      <p>(주)비상교육 개인정보 취급방침.</p>
      <br />
      <p>
        (주)비상교육(이하 ‘회사’라 함)은 이용자 개인 정보를 중요시하며,
        '정보통신망 이용촉진 및 정보보호 규정‘을 준수하여 관련법령에 의거한
        개인정보 취급방침을 정하고 이용자 권익 보호에 최선을 다하고 있습니다.
        회사는 개인정보취급방침을 통하여 이용자가 제공하시는 개인정보가 어떠한
        용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가
        취해지고 있는지 알려드립니다. 회사의 개인정보취급방침은 정부의 법률 및
        지침변경이나 회사 내부의 방침변경 등으로 인하여 변경될 수 있으며,
        개인정보취급방침을 개정할 경우 홈페이지에 게시하여 개정된 사항을 쉽게
        알아볼 수 있도록 하고 있습니다.
      </p>
      <br />
      <br />

      <ol class='policy-num'>
        <li>
          1. 개인정보의 처리 목적 회사는 다음의 목적을 위하여 개인정보를
          처리합니다. 처리하고 있는 개인정보는 다음의 목적이외의 용도로는
          이용되지 않으며, 이용 목적이 변경될 경우에는 별도의 동의를 받는 등
          필요한 조치를 이행할 예정입니다. <br />
          <ol class='policy-num'>
            <li>
              1) 홈페이지 회원가입 및 관리 <br />
              회원 가입의사 확인, 회원제 서비스 제공에 따른 본인
              식별∙인증,회원자격 유지∙관리, 제한적 본인확인제 시행에 따른
              본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시
              법정대리인 동의 여부 확인, 각종 고지∙통지, 고충처리, 분쟁 조정을
              위한 기록 보존 등
            </li>
            <li>
              2) 재화 또는 서비스 제공 <br />
              물품배송, 서비스 제공, 계약서․청구서 발송, 콘텐츠 제공, 맞춤서비스
              제공, 본인인증, 연령인증, 요금결제․정산, 채권추심 등
            </li>
            <li>
              3) 마케팅 및 광고에 활용
              <br />
              신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 등 광고성 정보
              전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도
              파악 또는 회원의 서비스 이용에 대한 통계
            </li>
            <li>
              4) 고충처리 <br />
              민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지,
              처리결과 통보 등
            </li>
            <li>
              5) 기타
              <br />
              가입횟수 제한, 분쟁조정을 위한 기록보존,회원의 각종 통계자료 산출{' '}
              <br />
              <br />
            </li>
          </ol>
        </li>
        <li>
          2. 개인정보의 처리 및 보유기간 <br />
          회사는 법령에 따른 개인정보․이용기간 또는 이용자로부터 개인정보를 수집
          시에 동의 받은 개인정보 보유․이용기간 내에서 개인정보를
          처리․보유합니다. 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
          <br />
          <br />
          <ol class='policy-num'>
            <li>
              1) 홈페이지 회원 가입 및 관리 : 홈페이지 탈퇴 시 까지 다만, 다음의
              사유에 해당하는 경우에는 해당 사유 종료 시 까지
              <ol class='policy-num'>
                <li>
                  1-1) 관계 법령 위반에 따른 수사․조사 등이 진행 중인 경우에는
                  해당 수사․조사 종료 시 까지
                </li>
                <li>
                  1-2) 홈페이지 이용에 따른 채권․채무관계 잔존 시에는 해당
                  채권․채무관계 정산 시 까지
                </li>
              </ol>
            </li>
            <li>
              2) 재화 또는 서비스 제공 : 재화․서비스 공급완료 및 요금결제․정산
              완료시까지 다만, 다음의 사유에 해당하는 경우에는 해당 기간
              종료시까지
              <ol class='policy-num'>
                <li>
                  2-1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른
                  표시․광고, 계약내용 및 이행 등 거래에 관한 기록
                  <ul class='policy-bu'>
                    <li>- 표시․광고에 관한 기록 : 6월</li>
                    <li>
                      - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년
                    </li>
                    <li>- 소비자 불만 또는 분쟁처리에 관한 기록 : 3년</li>
                  </ul>
                </li>
                <li>
                  2-2)「통신비밀보호법」제41조에 따른 통신 사실 확인 자료 보관
                  <ul class='policy-bu'>
                    <li>
                      - 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월
                    </li>
                  </ul>
                </li>
                <li>
                  2-3)「정보통신망 이용촉진 및 정보보호 등에 관한 법률」시행령
                  제29조에 따른 본인확인정보 보관 : 게시판에 정보 게시가 종료된
                  후 6개월 <br />
                  <br />
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          3. 개인정보의 제3자 제공에 관한 사항 <br />
          회사는 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서 명시한
          범위 내에서만 처리하며, 동 범위를 초과하여 개인정보를 이용하거나
          제3자에게 제공∙공개하지 않습니다. <br />
          다만, 관련 법령상 규정이 있는 경우에는 예외로 합니다. <br />
          <br />
        </li>
        <li>
          4. 개인정보처리의 위탁에 관한 사항
          <br />
          <ol class='policy-num'>
            <li>
              1) 회사는 원활한 서비스 이행을 위해 다음과 같이 개인정보 처리를
              위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게
              관리될 수 있도록 필요한 사항을 규정하고 있습니다.
              <br />
              위탁항목 수탁자 위탁 기간 <br />
              물품배송 (주)한진 (주)모바일이앤엠에드, KGB택배(주) 회원 탈퇴
              <br />
              또는 <br />
              위탁 계약 <br />
              종료시 <br />
              까지 <br />
              학습동영상 서버 및 오류 해결 (주)LG U+ <br />
              학습디바이스 제공 (주)코원, (주)에듀플레이어, (주)명윤시스템,
              케이머그, (주)아이놀케이알 <br />
              결제시 본인인증 및 거래 LG U+, LG데이콤,
              나이스신용평가정보,(주)케이지이니시스 <br />
              회원관리 아웃소싱 (주)디자인커뮤니케이션, 이에스컴퍼니,
              더원프린팅, 코리아인 <br />
              컨텐츠 제공 경희대학교 국제교육원 <br />
              <br />
              <br />
            </li>
          </ol>
        </li>
        <li>
          5. 정보주체의 권리∙의무 및 그 행사방법에 관한 사항 <br />
          <ol class='policy-num'>
            <li>
              1) 이용자는 회사에 대해 언제든지 회사가 처리하는 자신의 개인정보에
              대한 열람, 정정∙삭제 및 처리정지를 요구하는 등 개인정보 보호 관련
              권리를 행사할 수 있습니다. 다만, 관련 법령상 규정이 있는 경우에는
              회사는 위와 같은 요구를 거절하거나 제한할 수 있습니다.
            </li>
            <li>
              2) 제1항에 따른 권리 행사는 회사에 대해 서면, 전자우편,
              모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체
              없이 조치하겠습니다.
            </li>
            <li>
              3) 이용자가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한
              경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를
              이용하거나 제공하지 않습니다.
            </li>
            <li>
              4) 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자
              등 대리인을 통하여 하실 수 있습니다. 이 경우 위임장을 제출하셔야
              합니다
              <br />
              <br />
            </li>
          </ol>
        </li>
        <li>
          6. 처리하는 개인정보 항목 <br />
          회사는 다음의 개인정보 항목을 처리하고 있습니다. <br />
          <ol class='policy-num'>
            <li>
              1) 수집하는 개인정보 항목 및 수집방법
              <ol class='policy-num'>
                <li>
                  1-1) 홈페이지 회원 가입 및 관리
                  <ul class='policy-bu'>
                    <li>
                      ․ 필수항목 : 성명, 생년월일, 성별, 로그인ID, 비밀번호,
                      비밀번호 변경 질문/답변, 회원구분(일반, 학생, 선생님 등),
                      이메일, 자택 전화번호, 휴대 전화번호, 자택주소
                    </li>
                    <li>
                      ․ 선택항목 : 추천인ID, 만 14세 미만은 법정대리인 정보
                    </li>
                  </ul>
                </li>
                <li>
                  1-2) 재화 또는 서비스 제공
                  <ul class='policy-bu'>
                    <li>
                      ․ 필수항목 : 성명, 생년월일, 성별, 로그인ID, 비밀번호,
                      비밀번호 변경 질문/답변, 회원구분(일반, 학생, 선생님 등),
                      이메일, 자택 전화번호, 휴대 전화번호, 자택주소,
                      신용카드번호, 은행계좌정보, 결제기록, 휴대폰 통신사 등
                      결제정보
                    </li>
                    <li>
                      ․ 선택항목 : 추천인ID, 학력, 학교, 학년, 계열, 희망대학,
                      접속환경, 인지경로, 관심 학습분야, 관심 선생님 스타일,
                      학습스타일, 관심 컨텐츠, 재직학교, 강의스타일, 인강에 대한
                      의견, 등록된 자녀의 정보, 만 14세 미만은 법정대리인 정보
                    </li>
                  </ul>
                </li>
                <li>
                  1-3) 또한 인터넷 서비스 이용과정이나 사업처리 과정에서 아래와
                  같은 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
                  <ul class='policy-bu'>
                    <li>
                      ․ IP주소, 쿠키, MAC주소, 서비스 이용기록, 방문기록, 불량
                      이용기록 등
                    </li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>
              2) 개인정보 수집방법
              <br />
              회사는 다음과 같은 방법으로 개인정보를 수집합니다.
              <ul class='policy-bu'>
                <li>
                  - 홈페이지(회원가입, 상담게시판, 공개 게시판, 기타 개인정보를
                  바탕으로 하는 서비스 이용 등), 서면양식, 전화/팩스를 통한
                  회원가입, 경품행사 응모, 배송 요청, 제휴사로부터의 제공,
                  생성정보 수집 툴을 통한 수집
                </li>
              </ul>
            </li>
          </ol>
        </li>
        <li>
          7. 개인정보의 파기에 관한 사항
          <ul class='policy-bu'>
            <li>
              - 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
              불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.
            </li>
            <li>
              - 이용자로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이
              달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속
              보존하여야 하는 경우에는, 해당 개인정보를 별도의
              데이터베이스(DB)로 옮기거나 보관 장소를 달리하여 보존합니다.
            </li>
            <li>
              - 개인정보의 파기 절차 및 방법은 다음과 같습니다. <br />
            </li>
            <li>
              1) 파기절차 <br />
              회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보
              보호책임자의 승인을 받아 개인정보를 파기합니다.
            </li>
            <li>
              2) 파기방법 <br />
              회사는 전자적 파일 형태로 기록∙저장된 개인정보는 기록을 재생할 수
              없도록 파기하며, 종이 문서에 기록∙저장된 개인정보는 분쇄기로
              분쇄하거나 소각하여 파기합니다. <br />
              <br />
            </li>
          </ul>
        </li>
        <li>
          8. 개인정보의 안전성 확보조치에 관한 사항 <br />
          회사는 개인정보의 안전성 확보에 필요한 관리적, 기술적, 물리적 조치를
          하고 있습니다. <br />
          <ol class='policy-num'>
            <li>
              1) 기술적 조치
              <ol class='policy-num'>
                <li>
                  1-1) 해킹 등에 대비한 대책 : 해킹이나 컴퓨터 바이러스 등에
                  의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을
                  설치하고 주기적인 갱신∙점검을 하며 외부로부터 접근이 통제된
                  구역에 시스템을 설치하고 기술적,물리적으로 감시 및 차단하고
                  있습니다.
                </li>
                <li>
                  1-2) 비밀번호 암호화 : 이용자 회원ID의 비밀번호는 암호화되어
                  저장 및 관리되고 있어, 본인만이 알수 있으며, 개인정보의 확인
                  및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
                </li>
              </ol>
            </li>
            <li>
              2) 관리적 조치
              <ol class='policy-num'>
                <li>
                  2-1) 내부관리계획의 수립 및 시행 : 개인정보의 안전한 처리를
                  위하여 내부관리계획을 수립,시행하고 있습니다.
                </li>
                <li>
                  2-2) 개인정보 취급 직원의 최소화 및 교육 : 회사의 개인정보
                  취급 직원은 담당자에 한정시키고 이를 위한 별도의 비밀번호를
                  부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을
                  통하여 개인정보 취급방침의 준수를 항상 강조하고 있습니다.
                </li>
              </ol>
            </li>
            <li>
              3) 물리적 조치
              <ol class='policy-num'>
                <li>
                  3-1) 문서보안을 위한 잠금장치 사용 : 개인정보가 포함된 서류,
                  보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고
                  있습니다.
                </li>
                <li>
                  3-2) 비인가자에 대한 출입 통제 : 개인정보를 보관하고 있는
                  물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립,
                  운영하고 있습니다.
                  <br />
                  <br />
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          9. 개인정보의 자동 수집 장치의 설치∙운영 및 그 거부에 관한 사항
          <br />
          회사는 이용자의 정보를 수시로 저장하고 찾아내는 쿠키(cookie) 등
          개인정보를 자동으로 수집하는 장치를 설치,운용합니다. 쿠키(cookie)란
          회사의 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에
          보내는 아주 작은 텍스트 파일로서 귀하의 컴퓨터 하드디스크에
          저장됩니다. 회사는 다음과 같은 목적을 위해 쿠키 등을 사용합니다.
          <ol class='policy-num'>
            <li>
              1) 쿠키의 사용 목적 <br />
              회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과
              관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문 회수
              파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
            </li>
            <li>
              2) 쿠키의 설치/운영 및 거부
              <br />
              이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서,
              이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를
              허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든
              쿠키의 저장을 거부할 수도 있습니다.
            </li>
            <li>
              3) 쿠키설정 거부 방법
              <br />
              쿠키 설정을 거부하는 방법으로는 회원님이 사용하시는 웹 브라우저의
              옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다
              확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. <br />
              <br />* 설정방법 예(인터넷 익스플로어의 경우): 웹 브라우저 상단의
              도구 {'>'} 인터넷 옵션 {'>'} 개인정보 단, 귀하께서 쿠키 설치를
              거부하였을 경우 서비스 제공에 어려움이 있을 수 있습니다. <br />
              <br />
            </li>
            <li>
              10. 개인정보 보호책임자에 관한 사항
              <ol class='policy-num'>
                <li>
                  1) 회사는 이용자의 개인정보를 보호하고, 불만처리 및 피해구제
                  등을 위하여 아래와 같이 개인정보 처리에 관한 업무를 총괄해서
                  책임지는 개인정보 보호책임자를 지정하고 있습니다. <br />
                  <ul class='policy-bu'>
                    <li>
                      ▶ 개인정보 보호 최고책임자
                      <br />
                      담당자 : 최 성 기<br />
                      직책 : 미래전략실 SP <br />
                      전화번호 : 1544-0554 <br />
                      <br />
                    </li>
                    <li>
                      ▶ 개인정보 보호 실무담당자 <br />
                      담당자 : 이 정 우<br />
                      부서명 : 미래전략실 IT전략과 IP
                      <br />
                      전화번호 : 1544-0554
                      <br />
                    </li>
                  </ul>
                </li>
                <li>
                  2) 이용자께서는 회사의 서비스를 이용하시면서 발생한 모든
                  개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을
                  개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는
                  이용자의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
                </li>
                <li>
                  3) 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는
                  아래 기관에 문의하시기 바랍니다.
                  <ol class='policy-num'>
                    <li>1. 개인분쟁조정위원회 (www.1336.or.kr/1336)</li>
                    <li>
                      2. 정보보호마크인증위원회
                      (www.eprivacy.or.kr/02-580-0533~4)
                    </li>
                    <li>
                      3. 대검찰청 인터넷범죄수사센터
                      (http://icic.sppo.go.kr/02-3480-3600)
                    </li>
                    <li>
                      4. 경찰청 사이버수사국 (www.ctrc.go.kr/02-392-0330){' '}
                      <br />
                      <br />
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              11. 개인정보 취급방침의 변경에 관한 사항 <br />
              현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정
              최소 7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다. 다만,
              개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한
              변경이 있을 경우에는 최소 30일 전에 고지합니다.
              <br />
              <br />
              <br />
              <ul class='policy-bu'>
                <li>
                  - 개인정보취급방침 공고일자 : 2013년 12월 27일 <br />
                  <br />
                </li>
                <li>- 개인정보취급방침 시행일자 : 2014년 1월 2일</li>
              </ul>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  </div>
);

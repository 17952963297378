import React, { useEffect, useMemo, useState } from 'react';
import Styled from './styled/VideoTab.style';
import { useAxios } from 'common/hook/useAxiosHook';
import themesApi from 'api/theme';
import coursesApi from 'api/courses';
import { useRouteMatch, useLocation } from 'react-router-dom';
import CoursesList from 'container/CoursePage/CoursesList';
import RecommendThemes from 'container/UserBookmarkPage/Theme/RecommendTheme';
import { useSelector } from 'react-redux';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import Lnb from 'container/CoursePage/VideoLnb';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
SwiperCore.use([Pagination]);

// import { useTranslation } from "react-i18next";

const VideoTab = (props) => {
  const login = useSelector((state) => state.user.token);
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const language = localStorage.getItem('i18nextLng');
  // const [categoryListState] = useAxios(coursesApi.getCategoryList);
  const [categoryListState, setCategoryListState] = useState([]);
  const [currentPath, setCurrentPath] = useState(0);

  const themeList = useAxios(themesApi.getRecommendList, [], true);
  const token = useSelector((state) => state.user.token);

  const [{ data: getLnbData }] = useAxios(() => coursesApi.getLnbMenu(), []);
  const [{ data: getBannerData }] = useAxios(() => coursesApi.getBanner(), []);

  useEffect(() => {
    if (pathname !== '/courses/' && pathname !== '/courses') {
      setCurrentPath(pathname.replace('/courses/', ''));
    }
  }, [pathname]);

  useEffect(() => {
    if (!getLnbData) return;
    const arr = [];
    let idx = 0;

    console.log('getLnbData', getLnbData);
    // console.log('language!!!!', language);
    const setLanguageName = (item) => {
      if (language === 'ko-KR') return item.subCategoryNameKo;
      else if (language === 'en-US') return item.subCategoryNameEn;
      else if (language === 'zh-CN') return item.subCategoryNameCn;
      else if (language === 'ja-JP') return item.subCategoryNameJp;
    };
    const setLanguageDesc = (item) => {
      if (language === 'ko-KR') return item.subCategoryDescKo;
      else if (language === 'en-US') return item.subCategoryDescEn;
      else if (language === 'zh-CN') return item.subCategoryDescCn;
      else if (language === 'ja-JP') return item.subCategoryDescJp;
    };
    if (currentPath * 1 > 0) {
      // 2뎁스 페이지일 경우
      const totalArr = [];
      getLnbData.map((item) => {
        item.subList.map((item2) => {
          totalArr.push(item2);
        });
      });

      const subData = totalArr.filter(
        (item) => item.nameIdx === currentPath * 1
      );
      arr.push({
        name: subData[0].nameIdx,
        id: subData[0].nameIdx,
        value: setLanguageName(subData[0]),
        description: setLanguageDesc(subData[0]),
      });
    } else {
      // 1뎁스 페이지일 경우
      if (currentPath === 'topik') idx = 1;
      else if (currentPath === 'korean') idx = 2;
      else if (currentPath === 'category') idx = 3;
      getLnbData[idx].subList?.map((item) => {
        arr.push({
          name: item.nameIdx,
          id: item.nameIdx,
          value: setLanguageName(item),
          description: setLanguageDesc(item),
        });
      });
    }
    setCategoryListState(arr);
  }, [getLnbData, currentPath]);
  return (
    <>
      <ContentsWrapper>
        <Styled.CourseWrap>
          <Lnb list={getLnbData} />
          <Styled.Course>
            <Styled.TopBanner>
              <Swiper
                slidesPerView={1}
                spaceBetween={0}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                }}
                loop={true}
                speed={300}
                pagination={{ clickable: true }}
                modules={[Autoplay, Pagination]}
              >
                {getBannerData &&
                  getBannerData.map((item, idx) => {
                    return (
                      <SwiperSlide key={idx}>
                        <Styled.BannerLink href={item.bannerUrl}>
                          <Styled.BannerImg
                            src={`${item.filePath}/${item.fileName}`}
                            alt={item.bannerDesc}
                          />
                          <Styled.BannerText>
                            {item.bannerDesc}
                          </Styled.BannerText>
                        </Styled.BannerLink>
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Styled.TopBanner>

            {categoryListState &&
              categoryListState.map((category, index) => (
                <CoursesList
                  key={index}
                  category={category}
                  subPage={currentPath * 1 > 0}
                />
              ))}
          </Styled.Course>
        </Styled.CourseWrap>
      </ContentsWrapper>

      {/* <Styled.ThemeWrap>
        <ContentsWrapper>
          <RecommendThemes initialState={themeList} token={token} />
        </ContentsWrapper>
      </Styled.ThemeWrap> */}
    </>
  );
};

export default VideoTab;

import React, { useEffect, useState } from 'react';
import Styled from './index.style';

import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Autoplay, Pagination } from 'swiper';

import ViewTimeSlide from 'container/UserHomePage/ActivityInsight/Summary/ViewTimeSlide';
import MonthlySlide from 'container/UserHomePage/ActivityInsight/Summary/MonthlySlide';
import HistorySlide from 'container/UserHomePage/ActivityInsight/Summary/HistorySlide';
import AccessTimeSlide from 'container/UserHomePage/ActivityInsight/Summary/AccessTimeSlide';
import PracticeSlide from 'container/UserHomePage/ActivityInsight/Summary/PracticeSlide';
import { useTranslation } from 'react-i18next';

SwiperCore.use([Navigation, Autoplay, Pagination]);

export default function Summary({ loginDateList, courseCount, viewTimeData }) {
  const { t } = useTranslation('userHomePage');
  const [isMobile, setisMobile] = useState(false);
	const resizingHandler = () => {
    if (window.innerWidth <= 767) {
      setisMobile(true);
    } else {
      setisMobile(false);
    }
  };


  useEffect(() => {
		if (window.innerWidth <= 767) {
      setisMobile(true);
    }
    window.addEventListener("resize", resizingHandler);
    return () => {
      window.removeEventListener("resize", resizingHandler);
    };
  }, []);


  return (
    <Styled.Wrapper>
      <Styled.Swiper pagination={{ clickable: true }}  autoHeight  spaceBetween={20} navigation  modules={[ Pagination]} >
        {/* autoHeight */}

      <SwiperSlide>
          <PracticeSlide t={t}/>
        </SwiperSlide>

        <SwiperSlide>
          <MonthlySlide pinDateList={loginDateList.data} t={t}/>
        </SwiperSlide>

        <SwiperSlide>
          <ViewTimeSlide viewTimeData={viewTimeData} t={t} />
        </SwiperSlide>

        <SwiperSlide>
          <HistorySlide courseCount={courseCount} t={t} />
        </SwiperSlide>

        {/* <SwiperSlide>
          <AccessTimeSlide />
        </SwiperSlide> */}
      </Styled.Swiper>
    </Styled.Wrapper>
  );
}

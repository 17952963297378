import React, { useContext, useEffect, useMemo } from 'react';
import Styled from './Modal.style';
import { ModalContext } from 'component/Modal/modalContext';
import StyledModal from 'component/Modal/styled/Modal.style';
import Moment from 'react-moment';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import useErrorToastify from 'common/hook/useErrorToastify';
import ChangeCompleteModal from 'container/PlanPage/modal/ChangeCompleteModal';
import { priceFormat } from 'common/util/util';
import { CURRENCY, MOBILE_APP_USER_AGENT } from 'common/constant';
import plansApi from 'api/plans';
import { useTranslation, Trans } from 'react-i18next';
import { getUA } from 'react-device-detect';

const ConfirmChangeModal = ({ selectedPlan, getPlan, handleFetchPlan }) => {
  const { t } = useTranslation('planPage');
  const { handleModal } = useContext(ModalContext);
  const token = useSelector((state) => state.user.token);
  const [{ loading, data: changeData, error }, changeSubscription] = useAxios(
    plansApi.putPlan,
    [],
    true
  );
  const currentCurrency = useSelector((state) => state.common.currency);
  const currentPlan = useMemo(()=> getPlan.plans.find(
    (plan) => plan.productId === getPlan.currentPlanProductId
  ),[selectedPlan, getPlan]);

  const WebInterfaceHandleModal = () => {
    handleFetchPlan();
    handleModal();
  };
  
  useEffect(() => {
    window.addEventListener('webBridge', async (e) => {
      console.log(e.detail);
      WebInterfaceHandleModal();
    });
  }, [window.dispatchEvent]);

  const onChangePlan = () => {
    const currentPlan = getPlan.plans.find(
      (plan) => plan.id === selectedPlan.id
    );
    const planCode = currentPlan.planCode.toLowerCase();
    const userAgent = window.navigator.userAgent;

    if (userAgent === MOBILE_APP_USER_AGENT && currentPlan.downgradable) {
      window.WebInterface?.downgradeSubscription(`plan_${planCode}`);
    } else if (
      userAgent === MOBILE_APP_USER_AGENT &&
      !currentPlan.downgradable
    ) {
      window.WebInterface?.upgradeSubscription(`plan_${planCode}`);
    } else {
      changeSubscription(selectedPlan.id, token);
    }
  };

  const onChangeCompleted = () => {
    handleModal(
        <ChangeCompleteModal
            selectedPlan={selectedPlan}
            getPlan={getPlan}
            handleFetchPlan={handleFetchPlan}
        />,
        false,
        460
    );
  };

  useEffect(() => {
    // paypal 인경우 approve 절차를 위해 윈도우를 open 한다.
    console.log(changeData);

    if (changeData !== null) {
      if (changeData.length == 0) {
        onChangeCompleted();
      } else {
        // https://www.sandbox.paypal.com/webapps/billing/subscriptions/update?ba_token=BA-7B553245BY917471B
        let paypalWin = window.open(changeData, "PopupWin", "width=500,height=600,left=500,top=100,modal=yes");

        let popupTick = setInterval(function() {
          if (paypalWin.closed) {
            clearInterval(popupTick);
            // console.log('window closed!');
            // onPaypalUnloadTest();
          } else {
            if (paypalWin.location && paypalWin.location.host) {
              try {
                if (window.location.host === paypalWin.location.host) {
                  // console.log("정상 처리");
                  // paypalChangeSuccess = true;
                  onChangeCompleted();
                }
              } catch(e) {
                // ignore
              }
            }
          }
        }, 500);
      }
    }
  }, [changeData]);

  useErrorToastify(error);

  return (
    <Styled.Container>
      <StyledModal.H2> {t('Modal.upgrade.title')}</StyledModal.H2>

      <Styled.ChangePlan>
        {/* <Styled.CurrentPlan className={getPlan.currentPlan.toLowerCase()}>
          {getPlan.currentPlan}
        </Styled.CurrentPlan>*/}
        <Styled.CurrentPlanP className={getPlan.currentPlan.toLowerCase()}>
          <span>{getPlan.currentPlan}</span>
          <Styled.PlanSpan>
            ({currentPlan.takeCourseTerm}{
              currentPlan.takeCourseTerm === 1
              ? t('planCard.month') : t('planCard.months')
            })
          </Styled.PlanSpan>
        </Styled.CurrentPlanP>
        <Styled.Arrow />
        {/* <Styled.NewPlan className={selectedPlan.planCode.toLowerCase()}>
          {selectedPlan.planCode}
        </Styled.NewPlan> */}
        <Styled.NewPlanP className={selectedPlan.planCode.toLowerCase()}>
          <span>{selectedPlan.planCode}</span>
          <Styled.PlanSpan>
            ({selectedPlan.takeCourseTerm}{
              selectedPlan.takeCourseTerm === 1
              ? t('planCard.month') : t('planCard.months')
            })
          </Styled.PlanSpan>
        </Styled.NewPlanP>
      </Styled.ChangePlan>

      {/* 230131
        STANDARD 1 -> PREMIUM 1 : 즉시변경
        그외 : 다음주기 변경
       */}
      {(selectedPlan.downgradable || selectedPlan.upgradable && selectedPlan.productId !== 1147) && (
        <>
          <Styled.Desc>
            <Trans
              t={t}
              values={{
                date: getPlan.expireDate.slice(0, 10),
                plan: getPlan.currentPlan,
                planMonth: currentPlan.takeCourseTerm,
              }}
            >
              {currentPlan.takeCourseTerm === 1 
              ? 'Modal.downgrade.desc'
              : 'Modal.downgrade.desc_months'
              }
            </Trans>
          </Styled.Desc>

          <Styled.Desc>
            <Trans
              t={t}
              values={{
                date: getPlan.expireDate.slice(0, 10),
                plan: selectedPlan.planCode,
                price:
                  currentCurrency === CURRENCY.DOMESTIC
                    ? priceFormat.korea(selectedPlan.priceKrw)
                    : priceFormat.usa(selectedPlan.priceUsd),
                planMonth: selectedPlan.takeCourseTerm,
              }}
            >
              {selectedPlan.takeCourseTerm === 1
              ? 'Modal.downgrade.desc2'
              : 'Modal.downgrade.desc2_months'
              }
            </Trans>
          </Styled.Desc>
        </>
      )}
      {selectedPlan.upgradable && selectedPlan.productId === 1147 && (
        <>
          <Styled.Desc>
            <b>{t('Modal.upgrade.desc')}</b>
          </Styled.Desc>
          <Styled.Desc>
            <Trans t={t} values={{ date: getPlan.expireDate.slice(0, 10) }}>
              Modal.upgrade.desc2
            </Trans>
          </Styled.Desc>
        </>
      )}

      <Styled.ButtonGroup>
        <Styled.Button className='gray bordered' onClick={() => handleModal()}>
          {t('Modal.upgrade.cancel')}
        </Styled.Button>
        <Styled.Button className='primary' onClick={onChangePlan}>
          {t('Modal.upgrade.change')}
        </Styled.Button>
      </Styled.ButtonGroup>

      <LoadingOverlay isLoading={loading} />
    </Styled.Container>
  );
};

export default ConfirmChangeModal;

import React from 'react';
import PropTypes from 'prop-types';
import Styled from './styled/calendar.style';

/**
 * @param {object=} param.targetDate
 */
export default function Calendar({
  targetDate = new Date(),
  pinDateList = [],
}) {
  const firstDate = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    1
  );
  const lastDate = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth() + 1,
    0
  );
  const weekList = [];

  let tempDate = new Date(firstDate);
  while (tempDate <= lastDate) {
    let weekArr = [];
    for (let i = 0; i < 7; i++) {
      if (tempDate <= lastDate && tempDate.getDay() === i) {
        weekArr.push(tempDate.getDate());
        tempDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
      } else {
        weekArr.push(0);
      }
    }
    weekList.push(weekArr);
  }
  return (
    <Styled.Wrapper>
      <Styled.Month>
        {targetDate.getFullYear()}.{targetDate.getMonth() + 1}
      </Styled.Month>
      <Styled.Inner>
        <Styled.Week>
          <Styled.Weekend>S</Styled.Weekend>
          <Styled.Weekend>M</Styled.Weekend>
          <Styled.Weekend>T</Styled.Weekend>
          <Styled.Weekend>W</Styled.Weekend>
          <Styled.Weekend>T</Styled.Weekend>
          <Styled.Weekend>F</Styled.Weekend>
          <Styled.Weekend>S</Styled.Weekend>
        </Styled.Week>
        {weekList.map((dateList, weekIndex) => (
          <Styled.Week key={weekIndex}>
            {dateList.map((date, dateIndex) => (
              <Styled.Day
                key={dateIndex}
                className={date === targetDate.getDate() && 'today'}
              >
                <span>{date !== 0 && date}</span>

                {pinDateList &&
                  pinDateList.some((pinDate) => {
                    return date === pinDate;
                  }) && <Styled.Checked />}
              </Styled.Day>
            ))}
          </Styled.Week>
        ))}
      </Styled.Inner>
    </Styled.Wrapper>
  );
}

Calendar.propTypes = {
  targetDate: PropTypes.instanceOf(Date),
};

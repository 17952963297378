import styled from 'styled-components';

const StyledBoardReply = {
  Wrapper: styled.div`
    text-align: left;
  `,
  ImgContainer: styled.span`
    display: inline-block;
    padding-left: 1rem;
    vertical-align: middle;
    .reply-thumb {
      float: left;
      width: 3rem;
      margin-left: -1rem;
      border-radius: 100px;
      overflow: hidden;
    }
    @media (max-width: 767px) {
      padding-left: 2.5rem;
      .reply-thumb {
        margin-left: -1rem;
      }
    }
  `,
  Count: styled.span`
    display: inline-block;
    margin-left: 7px;
    font-size: 1.4rem;
    color: #8e9199;
    vertical-align: middle;
  `,
};

export default StyledBoardReply;

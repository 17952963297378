import styled from 'styled-components';
import { IoIosArrowBack } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';

export const PaginationStyle = {
  Pagination: styled.div`
    margin-top: 4em;
    text-align: center;
  `,
  BtnPrev: styled.button.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <IoIosArrowBack />
      </>
    )}))`
    position: relative;
    margin-right: 1em;
    font-size: 2em;
    color: #bec0c6;
    vertical-align: middle;
    &.disabled {
      color: #5c5f66;
    }
    svg {vertical-align: middle;}
    span {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
    }
  `,
  BtnNext: styled.button.attrs(({ children }) => ({
    children: (
      <>
        {children}
        <IoIosArrowForward />
      </>
    )}))`
    position: relative;
    margin-left: 1em;
    font-size: 2em;
    color: #bec0c6;
    vertical-align: middle;
    &.disabled {
      color: #5c5f66;
    }
    svg {vertical-align: middle;}
    span {
      position: absolute;
      width: 1px;
      height: 1px;
      margin: -1px;
      clip: rect(0, 0, 0, 0);
      overflow: hidden;
    }
  `,

  BtnPage: styled.button`
    margin: 0 0.3em;
    padding: 0.3em;
    font-size: 1.6em;
    color: #8e9199;
    vertical-align: middle;
    &.active {
      font-weight: 700;
      color: #fff;
    }
  `,

  BtnMore: styled.button`
    width: 14em;
    height: 3em;
    background: #2a2c2e;
    border-radius: 0.375em;
    overflow: hidden;
    font-size: 1.8em;
    color: #fff;
  `,
}

export default PaginationStyle;

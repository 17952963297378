import styled from 'styled-components';
import { HiCheck } from 'react-icons/hi';

const StyledCalendar = {
  Wrapper: styled.div`
    font-weight: bold;
    text-align: center;
  `,
  Inner: styled.div`
    display: table;
    width: 100%;
    height: 25.5em;
    border-bottom: 1px solid #2b2e35;
    color: #8b8f99;
    table-layout: fixed;
    @media (max-width: 767px) {
      height: 40em;
    }
  `,
  Month: styled.div`
    font-size: 2.4em;
    margin-bottom: 0.7em;
  `,
  Week: styled.div`
    display: table-row;
    &:not(:first-child):not(:last-child) > * {
      border-bottom: 1px dashed #2b2e35;
    }
  `,
  Weekend: styled.div`
    display: table-cell;
    font-size: 12px;
    height: 2.75em;
    background-color: #2b2e35;
    vertical-align: middle;
  `,
  Checked: styled.i.attrs({
    children: <HiCheck />,
  })`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.7rem;
    margin: -1.2rem 0 0 -1.2rem;
    border-radius: 100px;
    background-color: #586eed;
    color: #fff;
    @media (max-width: 767px) {
      width: 4rem;
      height: 4rem;
      line-height: 4.5rem;
      margin: -2rem 0 0 -2rem;
    }
  `,
  Day: styled.div`
    position: relative;
    display: table-cell;
    font-size: 13px;
    vertical-align: middle;
    span {
      position: relative;
      display: inline-block;
      width: 1.85em;
      line-height: 1.85em;
    }
    &.today span:before {
      content: '';
      position: absolute;
      top: 2px;
      right: 0;
      z-index: 9;
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: #e9ac04;
    }
  `,
};

export default StyledCalendar;

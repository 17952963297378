import React from 'react';

export const termsOfServiceEn = () => (
  <div class='policy'>
    <h3 class='policy-title'>Terms of Service </h3>

    <dl class='policy-subject'>
      <dt>- Table of Contents -</dt>
      <dd>
        <dl>
          <dt>Chapter 1. General Provisions</dt>
          <dd>Article 1 [Purpose]</dd>
          <dd>Article 2 [Definition of Terms]</dd>
          <dd>Article 3 [Provision of Company Information, etc.]</dd>
          <dd>Article 4 [Publication of Terms of Service, etc.]</dd>
          <dd>Article 5 [Amendments of Terms of Service, etc.]</dd>
          <dd>Article 6 [Interpretation of Terms of Service]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>Chapter 2. Sign Up</dt>
          <dd>Article 7 [Sign Up]</dd>
          <dd>Article 8 [Change of Member Information]</dd>
          <dd>Article 9 [Duty of Member’s ID and Password Management]</dd>
          <dd>Article 10 [Notification to Member or User]</dd>
          <dd>Article 11 [Account Deletion, Disqualification, etc.]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>Chapter 3. Service Use Contract</dt>
          <dd>Article 12 [Publication of Contents, etc.]</dd>
          <dd>Article 13 [Conclusion of Service Use Contract, etc.]</dd>
          <dd>Article 14 [Automatic Payment and Cancellation]</dd>
          <dd>Article 15 [Sales and Shipping of Textbook, etc.]</dd>
          <dd>Article 16 [Duties of Company]</dd>
          <dd>Article 17 [Duties of Member]</dd>
          <dd>Article 18 [Means of Payment for Using Contents]</dd>
          <dd>Article 19 [Provision and Suspension of Service]</dd>
          <dd>Article 20 [Changes in Contents Service]</dd>
          <dd>
            Article 21 [Provision of Information and Publication of
            Advertisement]
          </dd>
          <dd>Article 22 [Postings by Member and Deletion of Postings]</dd>
          <dd>Article 23 [Ownership of Copyright, etc.]</dd>
          <dd>Article 24 [Personal Information Protection]</dd>
          <dd>
            Article 25 [Special Clause Applied on Users in Countries Other Than
            South Korea]
          </dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>
            Chapter 4. Change, Cancellation, and Refund of Service Use Contract
          </dt>
          <dd>
            Article 26 [Change/Termination/Cancellation of Service Use Contract
            by User]
          </dd>
          <dd>Article 27 [Contract Cancellation by User and Refund Policy]</dd>
          <dd>Article 28 [Refund of Overcharges]</dd>
          <dd>
            Article 29 [Contract Termination/Cancellation by Company and
            Restriction on Use]
          </dd>
          <dd>Article 30 [Compensation on Service Failure, etc.]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>Chapter 5. Others</dt>
          <dd>Article 31 [Indemnification Clause]</dd>
          <dd>Article 32 [Settlement of Dispute]</dd>
          <dd>Article 33 [Governing Act and Jurisdiction]</dd>
          <dd>[Addenda]</dd>
        </dl>
      </dd>
    </dl>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>Chapter 1. General Provisions</h4>
      <p>
        Welcome. masterTOPIK is an online customized subscription service that
        provides various Korean language education contents for the Member.
      </p>

      <p class='policy-tit'>Article 1 [Purpose]</p>
      <p>
        The purpose of this Terms of Service is to regulate the rights, duties,
        responsibility, and other necessary affairs in using the educational
        contents and Internet service (hereinafter referred to as “Service”)
        provided by VISANG EDUCATION INC (hereinafter referred to as “Company”).
        In masterTOPIK (www.mastertopik.com) (hereinafter referred to as
        “masterTOPIK”), the Member may use all services with the ID and password
        of the Company.{' '}
      </p>

      <p class='policy-tit'>Article 2 [Definition of Terms]</p>
      <p>
        The terms used in this Terms of Service shall be defined as follows. The
        terms that are not specified in this Terms of Service shall be defined
        according to the related statutes or according to general commercial
        transaction practices.{' '}
      </p>

      <ol class='policy-num'>
        <li>1. Company : Company refers to “VISANG EDUCATION INC.”.</li>
        <li>
          2. Site : Site refers to the site (mastertopik.com) operated by the
          Company to provide services.
        </li>
        <li>
          3. App : Application refers to an app installed in a mobile terminal
          such as smartphone and tablet to implement the service provided by the
          Company.
        </li>
        <li>
          4. User : User refers to a member and guest who accesses the site and
          app to use the service provided by the Company according to this Terms
          of Service.
        </li>
        <li>
          5. Member : Member refers to a person who signed up by providing the
          personal information to the Company and signed a Service Use Contract
          with the Company according to this Terms of Service.
        </li>
        <li>
          6. Guest : Guest refers a person who uses the service provided by the
          Company without signing up.
        </li>
        <li>
          7. ID : ID refers to a combination of letters and numbers or email
          account chosen by the Member and approved by the Company for the
          authentication and use of service.
        </li>
        <li>
          8. Password : Password refers to alphabets and numbers that Member
          directly set and registered to the Company to identify oneself,
          protect rights and interests of the Member, and protect
          confidentiality.
        </li>
        <li>
          9. Contents : Contents refer to the materials or information expressed
          in mark, text, voice, sound, image, or video used in the information
          communication networks according to provisions in Sub-Paragraph 1,
          Paragraph 1, Article 2 of Act on Promotion of Information and
          Communication Network Utilization and Information Protection, etc.
          produced or managed in an electrical form to enhance effectiveness in
          preservation and use.
        </li>
        <li>
          10. Membership : Membership refers to a regular contents subscription
          service.
        </li>
        <li>
          11. Free Service : Free service refers a service that can be used
          without paying for the service.
        </li>
        <li>
          12. Charged Service : Charged service refers to a service that can be
          used after paying for the service.
        </li>
        <li>
          13. Payment : Payment means to pay certain amount set by the Company
          via different means of payment specified in each Sub-Paragraph of
          Article 18 of this Terms of Service to use the charged service.
        </li>
        <li>
          14. Termination : Termination refers to cancellation of the Service
          Use Contract by Company or Member after providing/using the service.
        </li>
        <li>
          15. Operator(Manager) : Operator (Manager) refers to a person or
          agency (company) selected by the Company for the general service
          management and smooth operation.
        </li>
        <li>
          16. Post : Post refers to information such as writing, photo, video,
          file, link, and comment in text, sign, sound, image, and video forms
          posted on the Company’s site by the Member during the use of service.
        </li>
      </ol>

      <h4 class='policy-tit'>
        Article 3 [Provision of Company Information, etc.]
      </h4>
      <p>
        The Company indicates contents of this Terms of Service, name of
        representative, address and place of business (including the address
        that handles the customer complaints), phone number, FAX number, email
        address, business registration number, e-commerce business report
        number, and personal information management manager on the initial
        screen of the online service so that the “User” can easily notice them.
        Provided, the Company may set a separate link connected to Terms of
        Service.{' '}
      </p>

      <h4 class='policy-tit'>
        Article 4 [Publication of Terms of Service, etc.]
      </h4>
      <p>
        The Company shall take technical measures so that the Member is able to
        print the whole Terms of Service and refer to the matters of the Terms
        of Service during the transaction process.{' '}
      </p>

      <h4 class='policy-tit'>
        Article 5 [Amendments of Terms of Service, etc.]
      </h4>
      <ol class='policy-num'>
        <li>
          1. The Company may amend this Terms of Service within the scope of not
          infringing the related Acts such as Contents Industry Promotion Act,
          Act on the Consumer Protection in Electronic Commerce, etc., Act on
          the Regulation of Terms and Conditions, Framework Act on Electronic
          Documents and Transactions, Electronic Financial Transactions Act,
          Digital Signature Act, Act on Promotion of Information and
          Communications Network Utilization and Protection, etc., Act on
          Door-to-Door Sales, etc., and Framework Act on Consumers.
        </li>
        <li>
          2. In amendment of the Terms of Service, the Company shall specify the
          application date, specify reasons of amendment, and post the
          application date and reasons of amendment together with the current
          Terms of Service on the initial screen of the service within 10 days
          before the application or provide via other means. The Company shall
          also send an email concerning application date and major changes to
          the email address of the existing Member. Provided, when the amended
          Terms of Service is unfavorable to the Member, the Company shall state
          the application and reasons of amendment and post application date and
          reasons of amendment together with the current Terms of Service on the
          initial screen of the service from 30 days before the application day
          and the day before the application and shall clearly notify the Member
          via electronic means such as email separately from posting the notice.
        </li>
        <li>
          3. When the Member disagrees with the application of the Amended Terms
          of Service, the Member may stop using the service and delete the
          account.
        </li>
        <li>
          4. Notwithstanding the notification or announcement made in accordance
          with the Paragraph 2 of the Article, when the Member does not raise an
          objection until the date of applying the Amended Terms of Service, it
          is deemed that the Member agreed to the Amended Terms of Service.
        </li>
      </ol>

      <h4 class='policy-tit'>Article 6 [Interpretation of Terms of Service]</h4>
      <p>
        Matters that are not regulated in this Terms of Service and
        interpretation of this Terms of Service shall comply with the Contents
        Industry Promotion Act, Act on the Consumer Protection in Electronic
        Commerce, etc., Act on the Regulation of Terms and Conditions, Digital
        Contents User Protection Guideline, Consumer Protection Guideline and
        Other Related Statutes, or commercial practices.
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>Chapter 2 Sign Up </h4>

      <p class='policy-tit'>Article 7 [Sign Up]</p>
      <ol class='policy-num'>
        <li>
          1. A User shall be over the age of 14 years to sign up and the User
          may join after agreeing to the matters of Terms of Service, filling
          out the prescribed application form provided by the Company, applying
          for signing up, and getting approval from the Company.
        </li>
        <li>
          2. It is deemed that all information entered by the User in the
          application form of the previous Paragraph is the true information. A
          User who has not entered the real name or actual information shall not
          be legally protected and The User may be restricted in using the
          service.
        </li>
        <li>
          3. The Company shall accept the application submitted by the User
          according to Paragraph 1 of this Article. Provided, the Company may
          reject or suspend any of the following application and may cancel even
          after accepting the application.
          <ol class='policy-num'>
            <li>① Where the user is under the age of 14 years old;</li>
            <li>
              ② Where the applicant was earlier disqualified according to this
              Terms of Service;
            </li>
            <li>③ Where the applicant deleted the account within 3 months;</li>
            <li>④ Where the applicant used a false name or name of others;</li>
            <li>
              ⑤ Where the applicant filled out false information or didn’t fill
              out the matters required by the Company;
            </li>
            <li>
              ⑥ Where the approval cannot be made due to reasons attributable to
              User or where the application is made in violations of other
              matters;
            </li>
            <li>
              ⑦ Where the applicant violated the Duties of Member in Article 17
              of this Terms of Service;
            </li>
            <li>
              ⑧ Where the maters (ID, password, etc.) filled out and submitted
              to the Company in signing up infringe the morality, violate other
              social orders, or insult others;
            </li>
            <li>
              ⑨ Where the applicant used illegal means such as using the
              malicious programs, using bugs, or abusing the weakness of the
              system;
            </li>
            <li>
              ⑩ Where the applicant failed to satisfy other application
              requirements of the Company.
            </li>
          </ol>
        </li>
        <li>
          4. The Company may suspend the acceptance when it cannot afford
          service-related facilities or when it has technical or business
          issues.
        </li>
        <li>
          5. In rejecting or suspending the application according to Paragraph 3
          and Paragraph 4, the Company shall notify the fact to the applicant
          unless otherwise the Company’s cannot notify the applicant due to the
          reasons that are not attributable to the Company.
        </li>
      </ol>

      <p class='policy-tit'>Article 8 [Change of Member Information]</p>
      <ol class='policy-num'>
        <li>
          1. The member may inspect and modify his/her personal information by
          entering the passwords in the Member Information Management Screen,
          etc.
        </li>
        <li>
          2. When there are changes in the matters provided during application,
          the Member shall modify via online or notify the Company of the
          changes via email or other means.
        </li>
        <li>
          3. When the Member fails to notify the Company of the changes in
          matters filled out during application and results in any
          disadvantages, the Company shall not be liable for the disadvantages.
        </li>
      </ol>

      <p class='policy-tit'>
        Article 9 [Duty of Member’s ID and Password Management]
      </p>
      <ol class='policy-num'>
        <li>
          1. The Member shall be liable to manage his/her ID and password and
          shall not let the Third Party use his/her ID and password.
        </li>
        <li>
          2. When the Member realizes that his/her ID and password are stolen or
          used by the Third Party, the Member shall notify the Company
          immediately.
        </li>
        <li>
          3. In case of Paragraph 2, the Company may request the Member to take
          necessary measures such as changing the password to protect the
          Member’s personal information and to prevent other illegal uses. The
          Company shall not be liable for the disadvantages that happened for
          not complying with the requests of the Company.
        </li>
        <li>
          4. When the Member creates multiple IDs, the Company shall notify this
          to the Member and shall delete IDs other than the representative ID
          selected by the Member.
        </li>
      </ol>

      <p class='policy-tit'>Article 10 [Notification to Member or User]</p>
      <ol class='policy-num'>
        <li>
          1. When there are matters to be notified to the Member or User, the
          Company may notify via email address provided by the Member, notice,
          pop-up window, and phone calls.{' '}
        </li>
        <li>
          2. When notifying the whole Members or Users, the Company may post a
          notification on the Company site’s bulletin board to substitute the
          means of notification stated in the preceding Paragraph. Provided, the
          Company shall make a notice according to Paragraph 1 in case of
          important matters related to transactions such as use of the charged
          service by the Member.
        </li>
      </ol>

      <p class='policy-tit'>
        Article 11 [Account Deletion, Disqualification, etc.]
      </p>
      <ol class='policy-num'>
        <li>
          1. When the Member intends to delete the account, the Member may
          request the customer center on deleting the account via online. In
          such case, the Company shall handle the matter immediately and shall
          notify to the Member.
        </li>
        <li>
          2. When the Member deletes the account, the Member may not use the
          purchased or downloaded contents or posts he/she wrote before deleting
          the account. When the Member fails to take measures such as personal
          information preservation even after getting the Company’s notification
          on the preservation methods and deletion of such contents and posts,
          the Company shall not be liable for such contents or posts.
        </li>
        <li>
          3. When the Member infringes this Terms of Service or related
          statutes, the Company may limit the use of service or disqualify the
          Member.
        </li>
        <li>
          4. According to “Promotion of Information and Communication Network
          Utilization and Information Protection, etc.” and Enforcement Decree,
          the Company shall separate the personal information of the Member
          (Dormant Member) who hasn’t used the service for one consecutive year
          and shall save and manage such personal information separately from
          the personal information of other Members. The Company shall not use
          or provide the personal information of the Dormant Member unless
          otherwise there exist special provisions in any Act. In such case, the
          Company shall notify the Member on facts that the necessary measures
          will be taken and that personal information will be separately saved
          and managed and the Company shall notify the corresponding personal
          information via means of email, written notification, FAX, or similar
          means at least 30 days before taking the measures.
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>Chapter 3 Service Use Contract </h4>

      <p class='policy-tit'>Article 12 [Publication of Contents, etc.]</p>
      <ol class='policy-num'>
        <li>
          1. The Company shall indicate following matters on the initial
          contents screen or packaging to help easier understanding.
          <ol class='policy-num'>
            <li>① Name and type of contents</li>
            <li>② Date of contents production and indication</li>
            <li>
              ③ Name of contents producer (referring to the name of a corporate,
              if the producer is a corporate)
            </li>
            <li>
              ④ Contents items, method of use, charge, and other conditions of
              use
            </li>
          </ol>
        </li>
        <li>
          2. The Company shall provide the User of the information concerning
          the devices that support the contents and the minimum technical
          specification required for using the contents.{' '}
        </li>
      </ol>

      <p class='policy-tit'>
        Article 13 [Conclusion of Service Use Contract, etc.]
      </p>
      <ol class='policy-num'>
        <li>
          1. The Member shall apply for use of contents according to the
          following procedures or similar procedures provided by the Company.
          The Company shall provide the User of the information concerning the
          matters of each Sub-Paragraph before signing the contract so that the
          User can understand accurately and transact without mistake or error.
          <ol class='policy-num'>
            <li>① Inspection on contents list and selection</li>
            <li>② Detailed contents information</li>
            <li>
              ③ Confirmation on ordering product and payment amount (Inform on
              refund provisions)
            </li>
            <li>
              ④ Confirmation on application for use of contents or consent on
              Company’s confirmation request
            </li>
            <li>⑤ Confirmation and selection of means of payment</li>
            <li>⑥ Re-confirmation on payment amount</li>
          </ol>
        </li>
        <li>
          2. The Company may reject or suspend the Member’s application on use
          of contents in any of the following cases :
          <ol class='policy-num'>
            <li>① Where the Member used a false name or name of others;</li>
            <li>
              ② Where the Member filled out false information or didn’t fill out
              the matters required by the Company;
            </li>
            <li>
              ③ Where the Company cannot afford the service-related facilities
              or has technical or business issues;
            </li>
            <li>
              ④ Where the normal use of service is unavailable due to the
              circumstances uncontrollable by the Company;
            </li>
            <li>⑤ Where the Member failed to pay for the charged service;</li>
            <li>
              ⑥ Where the charged service amount and paid amount do not match.
            </li>
          </ol>
        </li>
        <li>
          3. When the Member applies for a use of the contents (service)
          according to Paragraph 1, the Company shall notify the Member as an
          expression of approval under the means stated in Paragraph 1 of
          Article 10 and it shall be deemed that the contract has been concluded
          upon the notification of approval reaches the Member.
        </li>
        <li>
          4. The Company’s expression of approval shall include information
          concerning the confirmation on the Member’s application for use,
          notification on service availability, and correction and cancellation
          on application for use.
        </li>
        <li>
          5. Where the grounds stated in Sub-Paragraph 3 or Sub-Paragraph 6 of
          Paragraph 2 occur even after the Company’s notification of approval
          according to Paragraph 3 of this Article, the Company may limit the
          Member’s use of the contents (service) until such grounds are handled.
        </li>
      </ol>

      <p class='policy-tit'>Article 14 [Automatic Payment and Cancellation]</p>
      <ol class='policy-num'>
        <li>
          1. For a monthly automatic Service Use Contract renewal and billing,
          the Company may hold the payment-related information of the Member for
          a certain period during and after the period of using the service.
        </li>
        <li>
          2. When the Member applies for an automatic payment and doesn’t apply
          for a cancellation separately, the automatic billing and payment will
          be made every month at the designated payment date and by designated
          means regardless of the actual use of the charged service. (This shall
          be based on the days of service from the day after the initial payment
          date)
        </li>
        <li>
          3. The expiration date for cancelling the automatic payment shall be
          one month from the automatic payment date. After cancelling the
          automatic payment, the Member cannot use the service for one month
          from the subscription payment date.
        </li>
        <li>
          4. Using payment information of others without the consent may lead to
          the civil and criminal liability and penalties according to the
          related statutes.
        </li>
        <li>
          5. Where the subscription has not been paid due to change of payment
          information, loss of credit card and mobile phone, or other reasons,
          the use of service will be automatically suspended by one month from
          the last subscription payment date.
        </li>
        <li>
          6. The Company shall not be liable for the damages caused by the
          suspension of the subscription payment due to reasons attributable to
          the Member such as unpaid charges and corresponding suspension of
          service use.
        </li>
      </ol>

      <p class='policy-tit'>
        Article 15 [Sales and Shipping of Textbook, etc.]
      </p>
      <ol class='policy-num'>
        <li>
          1. When the Company sells the products such as textbooks in each site,
          the Company shall provide the service as follows.
          <ol class='policy-num'>
            <li>
              ① The textbooks sold for the contents service in the Company’s
              site shall be purchasable at the Company’s site.
            </li>
            <li>
              ② Products such as textbooks shall be sold only to the Member who
              completed the sign-up process at the Company’s site.
            </li>
            <li>
              ③ Once the Member applies for purchasing the textbooks, the
              Company shall notify the Member on accepting the order.
            </li>
            <li>
              ④ The Company shall take measures so that the Member can check the
              shipping process via online.
            </li>
            <li>
              ⑤ The textbooks shall be delivered within 7 days from paying for
              the textbooks in principle. The shipping may be delayed in case of
              natural disasters or shortage of textbooks in stock.
            </li>
          </ol>
        </li>
        <li>
          2. The Member who signed a contract with the Company on purchasing the
          textbooks may choose a cooling-off system (cancel the order) within 7
          days from receiving the textbooks. Provided, the Member cannot refund
          and exchange the textbooks in any of the following cases after
          receiving the textbooks :
          <ol class='policy-num'>
            <li>
              ① Where the textbooks, etc. are lost or damaged due to reasons
              attributable to the Member;
            </li>
            <li>
              ② Where the value of the textbooks, etc. remarkably decreased due
              to use or partial consumption by the Member.{' '}
            </li>
          </ol>
        </li>
        <li>
          3. Notwithstanding the cooling-off period stated in Paragraph 2 of
          this Article, when the Member intends to refund and exchange the
          delivered textbooks in any of following reasons, the Company shall
          refund and exchange the textbooks within the 30 days of finding the
          problem or within 3 months from receiving the textbooks and the
          Company shall be liable for the refund and exchange fees in such
          cases. Provided, the Member shall return the delivered textbooks to
          the Company.
          <ol class='policy-num'>
            <li>
              ① Where the delivered textbooks are different from the order or
              information provided by the Company;
            </li>
            <li>
              ② Where the delivered textbooks have any external defects that
              occurred during distribution and handling by the Company;
            </li>
            <li>
              ③ Where the delivered textbooks are defective (assumed to occur
              during textbook production such as printing/bookbinding).
            </li>
          </ol>
        </li>
        <li>
          4. When the Member refunds or exchanges the delivered textbooks for
          the reasons that do not apply to any of the Sub-Paragraphs in this
          Article, the Member shall be liable for all additional fees. The
          shipping fee charged on the Member for refund and exchange shall be
          based on the shipping fee charged at the time of purchasing the
          textbooks. In case of shipping fee for islands such as Jeju Island,
          mountainous areas, and foreign countries, the shipping fee shall be
          based on the shipping fee charged by the general shipping companies.
        </li>
        <li>
          5. When the Member refunds textbooks for the cooling-off, etc., the
          refund will be made after subtracting the clearing commission and
          remittance charge from the actual paid amount except the coupons.
        </li>
      </ol>

      <p class='policy-tit'>Article 16 [Duties of Company]</p>
      <ol class='policy-num'>
        <li>
          1. The Company shall sincerely exercise the rights and perform duties
          pursuant to the statutes and Terms of Service under the good faith.
        </li>
        <li>
          2. The Company shall have a security system for the personal
          information (including credit information) so that the Member can use
          the contents safely. The Company shall post and comply with Privacy
          Policy.
        </li>
        <li>
          3. The Company shall take necessary measures so that the Member can
          use the contents and check on the payment details frequently.
        </li>
        <li>
          4. When the Company acknowledges that the opinions or complaints
          submitted by the Member concerning the use of contents to be valid,
          the Company shall handle the matter immediately. Concerning the
          opinions or complains summited by the Member, the Company shall
          deliver the handling process and the results by using the bulletin
          board, email, etc.
        </li>
        <li>
          5. The Company shall compensate for the damaged caused on the User by
          infringing the duties pursuant to this Terms of Service.
        </li>
      </ol>

      <p class='policy-tit'>Article 17 [Duties of Member]</p>
      <ol class='policy-num'>
        <li>
          1. The Member shall not be involved in any of the following
          activities. If the Member breaches, the Company may set a period and
          limit the use of whole or partial services or may terminate the
          Service Use Contract after notifying the Member for a considerable
          period. Provided, in case of restricting the Member as above, the
          Company shall grant the Member of the opportunity to explain for a
          certain period. When the Member proves that such breach wasn’t
          intentional or negligent, the Member shall extend the period of use
          for the suspended service period.
          <ol class='policy-num'>
            <li>
              ① Where the Member fills out the false information or steals
              information of others (personal information such as ID, etc.) in
              signing up or changing the personal information;
            </li>
            <li>
              ②Where the Member copies the information (contents) obtained from
              using the service for the purposes other than the intended
              purposes, uses such information for publication or transfer, or
              provides such information to the Third Party without the prior
              approval of the Company;
            </li>
            <li>
              ③ Where the Member changes the information posted by the Company
              (contents such as lesson plan, lecture video, etc.);
            </li>
            <li>
              ④ Where the Member transmits or posts information (computer
              program, etc.) prohibited by the Company;
            </li>
            <li>
              ⑤ Where the Member infringes the intellectual property rights of
              the Company and other Third Parties;
            </li>
            <li>
              ⑥ Where the Member damages the reputation or interferes the
              business of the Company or other Third Parties;
            </li>
            <li>
              ⑦ Where the Member discloses or posts the indecent or violent
              comments, posting, video, sound, and other information against the
              public order and morality on the Company’s site;
            </li>
            <li>
              ⑧ Where the Member uses the service for the sales activities such
              as product sales without a prior approval by the Company;
            </li>
            <li>
              ⑨ Where the Member uses the contents provided by the Company on
              the scope beyond the private uses such as demonstration of
              contents to the public in public places, profit-making business
              places, and stores;
            </li>
            <li>
              ⑩ Where the Member interrupts other Users from using the service
              or impersonates as the Company’s executives, employees, or related
              person;
            </li>
            <li>
              ⑪ Where the Member is involved in other illegal or wrongful
              activities.
            </li>
          </ol>
        </li>
        <li>
          2. The Member shall comply with related statutes, provisions of this
          Terms of Service, user guideline, cautions concerning contents, and
          matters notified by the Company. The Member shall not be involved in
          other activities that may interrupt the business of the Company.{' '}
        </li>
        <li>
          3. The Member shall not transfer or assign any of its rights to use
          service or statutes pursuant to Service Use Contract to a Third Party
          and shall not provide them as collateral unless otherwise obtaining
          the express consent or approval by the Company.
        </li>
        <li>
          4. The Member shall manage his/her ID and password safely and the
          Member shall be liable for the results caused by negligent management
          or wrongful use.
        </li>
      </ol>

      <p class='policy-tit'>Article 18 [Means of Payment for Using Contents]</p>
      <p>
        The Member may pay for contents by using any of the following means :{' '}
      </p>
      <ol class='policy-num'>
        <li>1. Payment by a credit card</li>
        <li>2. Payment by e-cash (Paypal)</li>
      </ol>

      <p class='policy-tit'>Article 19 [Provision and Suspension of Service]</p>
      <ol class='policy-num'>
        <li>
          1. The contents service shall be provided all around the year and 24
          hours a day in principle.
        </li>
        <li>
          2. The Company shall temporarily suspend the provision of the contents
          service in case of maintenance, replacement, failure in information
          and communication facilities such as computer or other operational
          reasons. In this case, the Company shall notify the Member by the
          means stated in Article 10. Provided, the Company may notify the
          Member later when there exists unavoidable reason.
        </li>
        <li>
          3. The Company shall compensate the damages caused on the Member for
          the temporary suspension of providing the charged (contents) service
          due to reasons attributable to the Company by extending the service
          period for the suspended days. Provided, the Company shall not be
          liable for the service suspension/failure that occurred intentionally
          or negligently by the Third Party.
        </li>
        <li>
          4. When the Company cannot provide the service due to reasons such as
          change of business item, abandonment of business, or merge of
          businesses, the Company shall notify the Member by the means stated in
          Article 10 and shall take measures for refund according to Paragraph
          5, Article 27.
        </li>
        <li>
          5. The Company may implement a regular maintenance if necessary for
          the provision of service. The regular maintenance time shall be
          according to the notice posted on the service screen.
        </li>
        <li>
          6. In providing the service, the Company may have the User to install
          a separate program if necessary and the Member may refuse on
          installing the program as he/she wants. Provided, the User may be
          restricted from using the service after refusing on installing the
          program.
        </li>
        <li>
          7. The Company may collect and use the User’s computer data for the
          program.
        </li>
        <li>
          8. The Company shall use the data of the previous Paragraph only for
          the purposes of improving the program or providing the service or
          technology suitable for the user environment.
        </li>
        <li>
          9. The provision of the contents may be partially restricted upon the
          requests from the contents supplier or copyright holder.
        </li>
      </ol>

      <p class='policy-tit'>Article 20 [Changes in Contents Service]</p>
      <ol class='policy-num'>
        <li>
          1. The Company may change the provided contents service for
          operational or technical needs when there exist reasonable grounds.{' '}
        </li>
        <li>
          2. When the service is changed according to Paragraph 1, the Company
          shall notify the Member of the reasons for change and details of
          change by the means stated in Article 10.{' '}
        </li>
        <li>
          3. When there are significant changes or changes that are
          disadvantageous to User in Paragraph 2 of this Article, the Company
          shall notify the Member who is provided of the contents service by the
          means stated in Paragraph 2, Article 5 and shall obtain consents. When
          the User rejects on giving his/her consent, the Company shall provide
          the original service without applying the changes. Provided, the
          Company may terminate the contract when the Company cannot provide
          such service.
        </li>
      </ol>

      <p class='policy-tit'>
        Article 21 [Provision of Information and Publication of Advertisement]
      </p>
      <ol class='policy-num'>
        <li>
          1. The Company may provide various information acknowledged to be
          necessary in using the service by posting a notice or sending email.
          Provided, the Member may unsubscribe the email at any time.
        </li>
        <li>
          2. When the Company sends the information concerning Paragraph 1 over
          phone or FAX, the Company shall obtain a prior consent from the
          Member.
        </li>
        <li>
          3. In providing the service, the Company may publish advertisements on
          the service screen, Company’s site, and email. The Member who received
          the email with advertisement may unsubscribe the email.
        </li>
        <li>
          4. The Company shall not be liable for the loss or damage caused as
          the results of the Member’s participation, communication, or
          transaction on the promotional activities of the advertiser in the
          advertisement published by the Company.
        </li>
      </ol>

      <p class='policy-tit'>
        Article 22 [Postings by Member and Deletion of Postings]
      </p>
      <ol class='policy-num'>
        <li>
          1. The Company may immediately delete Member’s postings that violate
          the Terms of Service or related Acts such as Act on Promotion of
          Information and Communication Network Utilization and Information
          Protection, etc., or postings that apply to any of the following
          Sub-Paragraphs. Provided, the Company shall post a notice in advance
          for the postings applying to Paragraph 10.
          <ol class='policy-num'>
            <li>
              ① Where the postings disparage or damage the reputation of
              Company, other Members, or the Third Party;
            </li>
            <li>
              ② Where the postings include contents that are against the public
              order and customs;
            </li>
            <li>
              ③ Where the postings are acknowledged to be related to criminal
              acts;
            </li>
            <li>
              ④ Where the postings infringe the rights such as copyrights of the
              Company or the Third Party;
            </li>
            <li>
              ⑤ Where the postings cause disputes by violating statutes or
              infringing the rights of others;
            </li>
            <li>
              ⑥ Where the postings contain unnecessary or unapproved
              advertisements or promotional materials;
            </li>
            <li>
              ⑦ Where the postings are prepared by stealing or impersonating the
              personal information of others or illegally fabricating/falsifying
              the information submitted by others;
            </li>
            <li>
              ⑧ Where the postings are against the purpose of posting such as
              uploading multiple postings with overlapping contents;
            </li>
            <li>
              ⑨ Where it is deemed that the postings violate the operational
              guideline of the Company’s bulletin board;
            </li>
            <li>
              ⑩ Where the Company decides that the Company cannot afford the
              capacity in the service facilities.
            </li>
          </ol>
        </li>
        <li>
          2. The person whose legal interests have been infringed due to the
          information posted on the bulletin board operated by the Company may
          request the Company to delete the information or post the refuting
          statements. In this case, the Company shall take necessary measures
          promptly and shall notify the person immediately.{' '}
        </li>
      </ol>

      <p class='policy-tit'>Article 23 [Ownership of Copyright, etc.]</p>
      <ol class='policy-num'>
        <li>
          1. The copyrights and other intellectual property rights on the
          Company’s works shall belong to the Company.
        </li>
        <li>
          2. Among the information obtained by using the service provided by the
          Company, the User shall not copy, transmit, publish, distribute, or
          broadcast the information of the intellectual property rights
          belonging to the Company or Supplier and shall not use such
          information for the commercial purpose or let the Third Party use the
          information without the prior consent from the Company or Supplier.
        </li>
        <li>
          3. The copyrights of the postings uploaded by the User in the
          Company’s service shall be protected by the Copyright Act. The Company
          may utilize the postings of the Member for the restricted purposes
          such as promotion of the Company. Provided, the Company shall stop
          using the postings when the Member raises an objection.
        </li>
        <li>
          4. The User shall allow the Company to use his/her postings for any of
          the following purposes domestically or abroad. Provided, the Company
          shall stop exhibition and distribution of the postings upon the Member
          raises an objection in Paragraph 2 and Paragraph 3.
          <ol class='policy-num'>
            <li>
              ① Where the Company copies, transmits, exhibits, and distributes
              the postings within the extent and service or where the Company
              modifies postings of the User by converting the size or
              simplifying the postings to expose the good postings on the
              service screen;
            </li>
            <li>
              ② Where the Company exhibits and distributes the postings of the
              User within the service of related sites operated by the Company;
            </li>
            <li>
              ③ Where the Company reports or broadcasts the contents of the
              User’s postings to the media, telecommunication companies, etc. to
              promote the service of the Company.
            </li>
          </ol>
        </li>
        <li>
          5. Notwithstanding the matters provided in Paragraph 4, when the
          Company uses the postings of the User for the commercial purposes
          (Example : Providing the postings to the Third Party and receiving
          monetary returns, etc.) other than the purposes stated in each
          Sub-Paragraph of Paragraph 4, the Company shall obtain a prior consent
          from the User. The Company’s request on using the postings, consent
          from User, and withdrawal of content shall be made by the means
          including phone, email, FAX, etc. requested by the Company.{' '}
        </li>
        <li>
          6. When the Member deletes the account or gets disqualified according
          to Paragraph 1, Article 17, the Company may delete the postings of the
          Member without a separate consent or notification procedure.
        </li>
        <li>
          7. In case of company merger, business transfer, or integration of
          sites operated by the Company, the Company may change the location of
          the postings without changing the contents of the original postings.
        </li>
      </ol>

      <p class='policy-tit'>Article 24 [Personal Information Protection]</p>
      <ol class='policy-num'>
        <li>
          1. The Company shall endeavor to protect the personal information of
          the User according to related statutes such as Act on Promotion of
          Information and Communication Network Utilization and Information
          Protection, etc. The protection and use of the personal information
          shall be applied with the related statutes and Privacy Policy of the
          Company.
        </li>
        <li>
          2. When the Company collects the information about an identifiable
          individual, the Company shall obtain the consent from the User. The
          User may request on inspecting and correcting the errors on his/her
          personal information held by the Company at any time and the Company
          shall have a duty to take necessary measures immediately.
        </li>
        <li>
          3. The Company shall not use the information submitted by the User in
          applying for the service and the information collected according to
          Paragraph 1 for any purpose other than the intended purpose without
          the consent from the User or provide such information to the Third
          Party.
          <ol class='policy-num'>
            <li>
              ① Where the personal information is necessary for compiling
              statistics, scientific research, or market survey, and the
              personal information is provided in a form by which a specific
              individual cannot be identified;
            </li>
            <li>
              ② Where the personal information is necessary for charging on the
              provided contents;
            </li>
            <li>
              ③ Where the personal information is necessary for identification
              to prevent stealing the personal information of others;
            </li>
            <li>
              ④ Where the personal information is necessary for the inevitable
              reasons by or under regulations of Terms of Service or statutes.
            </li>
          </ol>
        </li>
        <li>
          4. The Company shall limit the number of the manager to the minimum to
          protect the personal information and the Company shall be liable for
          the damages caused on the User due to loss, stealing, leakage,
          falsification, etc. of the personal information.
        </li>
        <li>
          5. The Company or a person provided of the personal information from
          the Company may use the personal information within the extent that
          obtained the consent of the User. After achieving the purpose, the
          Company or the person provided of the personal information shall
          immediately destroy the personal information.
        </li>
      </ol>

      <p class='policy-tit'>
        Article 25 [Special Clause Applied on Users in Countries Other Than
        South Korea]
      </p>
      <p>
        While the Company endeavors to form a global service that applies the
        consistent standards on everyone, it also endeavors to comply with each
        region’s local acts at the same time. The Member shall consent on
        transmitting the personal data to South Korea and processing the
        personal data in South Korea.{' '}
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        Chapter 4. Change, Cancellation, and Refund of Service Use Contract
      </h4>

      <p class='policy-tit'>
        Article 26 [Change of Service Use Contract by User]
      </p>
      <ol class='policy-num'>
        <li>
          1. The Member may not change the charged service that he/she already
          purchased/subscribed in principle and shall cancel the service and buy
          different membership on the following month. When the Member applies
          for a change on some of the memberships designated and notified by the
          Company in advance, the Company may reserve the change and change
          after one month (hereinafter referred to as “Reserved Change”) or
          change immediately (hereinafter referred to as “Immediate Change”).
        </li>
        <li>
          2. In “Reserved Change”, the Member may use the membership that he/she
          previously purchased/subscribed until expiration date of the month
          that the Member requested on the change and the Member may change to
          the membership on the day after the expiration date.
        </li>
        <li>
          3. In “Immediate Change”, the Company receives “Immediate Change”
          applied by the Member. Then, the membership that the Member previously
          purchased/subscribed will be suspended immediately and will change to
          the membership that the Member applied.
        </li>
        <li>
          4. When the Member applies on changing to the membership which is more
          expensive than the membership that he/she purchased, the Member may
          use the services of the new membership right after the applying for
          change. Provided, the Member cannot cancel the change request until
          the next payment date and the Member may change the membership again
          only after paying for the membership at least once.
        </li>
        <li>
          5. When the Member applies on changing to the membership which is less
          expensive than the membership that he/she purchased, the Member may
          use the services of the membership that he/she is currently using and
          the Member may to use the new membership after the next payment date.
          In this case, the Member may cancel the application for change until
          the next payment date.
        </li>
        <li>
          6. In case of “Reserved Change” and “Immediate Change” on all
          memberships, the membership shall not be refundable to prevent the
          Member from changing to different memberships by cancelling the
          previous membership.
        </li>
      </ol>

      <p class='policy-tit'>
        Article 27 [Contract Cancellation by User and Refund Policy]
      </p>
      <ol class='policy-num'>
        <li>
          1. The Member that signed a contract with the Company on using the
          service may have a cooling-off period up to 7 days. Provided, the
          right to a cooling-off period may be restricted according to the
          related statutes or any of the following measures taken by the
          Company.
        </li>
        <li>
          2. To exercise the right to a cooling-off period (cancel
          subscription), the Member shall visit the site (Settings &gt;
          Subscription), or contact the customer center to express his/her
          intention. When the Member expresses the intention to cancel the
          Service Use Contract, the Company shall take such expression of
          intention immediately.
        </li>
        <li>
          3. The Company shall verify the Member’s application on order
          cancellation and subscription cancellation/termination. Where there
          exist the amount to be refunded, the Company shall request suspension
          or cancellation of payment to businesses of each mean of payment
          within 3 business days from the date of taking the Member’s expression
          of the intention in principle and shall refund the amount by the same
          means of payment in principle. Provided, the Member’s right to a
          cooling-off period may be restricted when the Company takes measures
          such as notifying the Member that contents (service) cannot be
          canceled according to the related statutes (Electronic Commerce Act,
          Contents Industry Promotion Act, etc.).
        </li>
        <li>
          4. In refunding, the Company shall refund the amount paid by the
          Member completely or partially by the same means of payment. Provided,
          when the Company cannot refund the amount by the same means of
          payment, the Company shall notify the User immediately and shall
          refund the amount by the means of payment selected by the User.
          Provided, there may be differences in method of refund or refundable
          period for each mean of payment for the cases notified by the Company
          in advance or any of the following cases.
          <ol class='policy-num'>
            <li>
              ① Where the means of payment (such as credit card, etc.) require
              receipt confirmation, refund shall be made within 3 business days
              from the date of receipt confirmation;
            </li>
            <li>
              ② Where the businesses of each mean of payment signed a contract
              with the Company and set the payment suspension or cancellation
              period in advance and the refund has been applied after payment
              suspension/cancellation period;
            </li>
            <li>
              ③ Where the Member obtained the gains from using the charged
              service/membership or canceled in the middle of the
              service/membership;
            </li>
            <li>
              ④ Where the Member fails to provide the Company of the information
              or material necessary for refund; (not submitting the copy of
              account and ID card or providing the account which isn’t in own
              name, etc.)
            </li>
            <li>⑤ Where the Member expresses the intention explicitly.</li>
            <li>⑤ 해당 회원의 명시적 의사표시가 있는 경우"</li>
          </ol>
        </li>
        <li>
          5. The Company shall comply with the Acts related to membership
          refund. When the Member cancels the membership within one month from
          the subscription (purchase and payment) day and requests immediate
          refund, the Company shall make a refund after deducting the charges
          (days of using the service X daily charge).
        </li>
        <li>
          6. In refunding, the Company may refund the amount after deducting the
          amount specified by the Company for related cases such as amount of
          gains that the Member obtained from using the service and such as
          penalty.
        </li>
        <li>
          7. According to Contents User Protection Guideline, the Company may
          refund the amount after deducting the additional expenses that the
          Company paid or will be paying.
        </li>
        <li>
          8. The Company shall not be liable to refund the service which is not
          directly paid by the Member such as the charged service or membership
          gifted to the Member or acquired for free through the promotions, etc.
        </li>
        <li>
          9. When the Member breaches the duties of the Member stated in this
          Terms of Service, the Company may take measures such as contract
          cancellation, contract termination, service restriction, or claim for
          damages. When there exist amount to be refunded after the contract
          termination, the Company shall refund the amount after deducting the
          amount to be paid by the Member within the specific extent. In this
          case, the Member may raise an objection on the Company’s measures
          according to the procedures set by the Company. When the Company
          verifies that the objection is reasonable, the Company may resume the
          use of service. When the User proves that his/her breach wasn’t
          intentional or negligent, the Company shall extend the period of use
          for the suspended service period.
        </li>
        <li>
          10. For the charged service under a monthly subscription applied or
          agreed by the Member, the membership may be automatically canceled at
          the date of overdue in the charged service. To maintain the benefits
          from the monthly subscription, the Member shall take the prior
          measures to prevent defaults in charges or overdue in means of
          payment.
        </li>
        <li>
          11. When the Member using the charged service under a monthly
          subscription deletes the account, the membership shall be
          automatically canceled. When the Member voluntarily deletes the
          account without a refund claim even though the Company asked the
          Member on his/her intention to refund, the Company shall not be liable
          for the account recovery.
        </li>
      </ol>

      <p class='policy-tit'>Article 28 [Refund of Overcharges]</p>
      <ol class='policy-num'>
        <li>
          1. When the Company overcharged, the Company shall refund the total
          overcharges by the means used for paying the charges. Provided, when
          the Company cannot refund by the same means of payment, the Company
          shall notify in advance.
        </li>
        <li>
          2. When the Company overcharged for the reasons attributable to the
          Company, the Company shall refund the total overcharges regardless of
          contract cost and fees. Provided, when the Company overcharged for the
          reasons attributable to the User, the User shall be liable for the
          costs necessary for refunding the overcharges within the reasonable
          extent.
        </li>
        <li>
          3. When the Company refuses on refunding the overcharges claimed by
          the User, the Company shall be liable to prove that the fee has been
          charged reasonably.
        </li>
      </ol>

      <p class='policy-tit'>
        Article 29 [Contract Termination/Cancellation by Company and Restriction
        on Use]
      </p>
      <ol class='policy-num'>
        <li>
          1. When the User is involved in acts stated in Paragraph 2, Article 13
          and Paragraph 1, Article 17, the Company may terminate/cancel the
          contract without a prior notification or restrict the use of service
          for the certain period.
        </li>
        <li>
          2. The contract termination/cancellation stated in Paragraph 1 shall
          be effective when the Company expresses its intention to the User by
          the means of notification set by the Company.
        </li>
        <li>
          3. When the Company terminates/cancels the Service Use Contract
          according to the grounds attributable to the User, the Company may not
          apply the refund provisions stated in Article 27.
        </li>
      </ol>

      <p class='policy-tit'>
        Article 30 [Compensation on Service failure, etc.]
      </p>
      <ol class='policy-num'>
        <li>
          1. In case of service suspension/failure, the Company shall notify the
          User in advance within 24 hours from the point of service
          suspension/failure. Provided, when the service suspension/failure
          happened due to reasons attributable to the User, it shall not apply
          to the service suspension/failure hours.{' '}
        </li>
        <li>
          2. When the Company notifies the service suspension/failure to the
          User in advance, the Company shall take remedies on the damages caused
          on the User by the following Sub-Paragraph. Provided, up to 24 hours
          of maintenance for service improvement shall not apply to the service
          suspension/failure hours for one month.
          <ol class='policy-num'>
            <li>
              ① Where the hours of service suspension/failure are over 10 hours
              in one month : The Company shall extend the subscription by 10
              hours and twice the overtime for free;{' '}
            </li>
            <li>
              ② Where the hours of service suspension/failure are less than 10
              hours in one month : The Company shall extend the subscription by
              the duration of service suspension/failure hours for free.
            </li>
          </ol>
        </li>
        <li>
          3. When the Company didn’t notify the service suspension/failure in
          advance, the Company shall take remedies on the damages caused on the
          User by the following Sub-Paragraph
          <ol class='policy-num'>
            <li>
              ① Where the accumulative hours of service suspension/failure are
              over 72 hours in one month : The Company shall terminate/cancel
              the contract and shall provide refund and compensation on fees
              charged for the remaining period including the unused period
              (Provided, the Company shall not be liable for compensation of the
              damage when the Company proves that service suspension/failure was
              not intentional or negligent);
            </li>
            <li>
              ② Where the service suspension/failure happened due to reasons
              attributable to the Company : The Company shall extend the
              subscription by 3 times the service suspension/failure hours for
              free;
            </li>
            <li>
              ③ When the service suspension/failure happened due to force
              majeure or illegal acts of the Third Party : The contract cannot
              be canceled and the Company shall extend the subscription by the
              duration of service. suspension/failure hours for free.
            </li>
          </ol>
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>Chapter 5. Others</h4>

      <p class='policy-tit'>Article 31 [Indemnification Clause]</p>
      <ol class='policy-num'>
        <li>
          ① The Company shall be exempted from liability on provision of
          contents when the Company cannot provide the contents due to the
          natural disasters or equivalent force majeure.
        </li>
        <li>
          ② The Company shall not be liable for the failures in use of service
          due to reasons attributable to the User.
        </li>
        <li>
          ③ The Company shall not be liable for the reliability, accuracy, etc.
          of the contents-related information, material, and facts the Member
          uploaded.
        </li>
        <li>
          ④ The Company shall not be liable for the disputes that occurred
          between the Users or between the User and the Third Party over the
          contents.
        </li>
      </ol>

      <p class='policy-tit'>Article 32 [ Settlement of Dispute]</p>
      <ol class='policy-num'>
        <li>
          ① When a dispute occurs, the Company shall take the appropriate and
          quick measures and take into considerations of the reasonable opinion
          or complaints suggested by the User. Provided, when the Company cannot
          handle the matter quickly, the Company shall notify the User of the
          reason for delay in handling the matter and the schedule for handling
          the matter.
        </li>
        <li>
          ② When a dispute occurs, the Company or the User may apply for
          “Dispute Resolution” at Contents Dispute Resolution Committee as
          stated in Article 28 of Contents Industry Promotion Act.
        </li>
      </ol>

      <p class='policy-tit'>Article 33 [Governing Act and Jurisdiction]</p>
      <ol class='policy-num'>
        <li>
          ① This Terms of Service shall be regulated and interpreted according
          to the Korean laws.
        </li>
        <li>
          ② The action on the dispute between Company and the User shall be
          instituted by Seoul Central District Court in Korea.{' '}
        </li>
      </ol>

      <p class='policy-tit'>[Addenda]</p>
      <p>This Terms of Service shall enter into force on March 22, 2021.</p>
    </div>
  </div>
);

import React from 'react';
import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import contimg from 'assets/images/event/211119/cont.jpg';
import cont2img from 'assets/images/event/211119/cont2.jpg';
import cont3img from 'assets/images/event/211119/cont3.jpg';
import cont4img from 'assets/images/event/211119/cont4.jpg';
import cont5img from 'assets/images/event/211119/cont5.jpg';
import btnSubmitimg from 'assets/images/event/211119/btn_go.png';
import rank1img from 'assets/images/event/211119/rank1.png';
import rank2img from 'assets/images/event/211119/rank2.png';
import rank3img from 'assets/images/event/211119/rank3.png';
import rank4img from 'assets/images/event/211119/rank4.png';
import rank5img from 'assets/images/event/211119/rank5.png';
import rank6img from 'assets/images/event/211119/rank6.png';
import rank7img from 'assets/images/event/211119/rank7.png';
import rank8img from 'assets/images/event/211119/rank8.png';
import rank9img from 'assets/images/event/211119/rank9.png';
import rank10img from 'assets/images/event/211119/rank10.png';
import btnPrevimg from 'assets/images/event/211119/btn_prev.png';
import btnNextimg from 'assets/images/event/211119/btn_next.png';
import linkimg from 'assets/images/event/211119/link.png';
import noDataimg from 'assets/images/event/211119/no_data.png';
import { toast } from 'react-toastify';
import { SwiperSlide } from 'swiper/react';
import { SiteMapData } from 'common/constant';
import { useSelector } from 'react-redux';
import { gotoAILink } from 'common/util/util';

const Style = styled.div`
	.event211119 > div {position: relative;text-align: center;}
	.event211119 .section1 {background: #aec3f9}
	.event211119 .section2 {background:#6b96fc}
	.event211119 .section2 .inner {position: relative; width: 950px; padding: 0 0 6% 0; margin: auto}
	.event211119 .section2 .inner > p {margin-bottom: 3%;font-size:3.5rem; line-height: 1.2;}
	.event211119 .section2 .inner .noti {margin-top: 4%;font-size:2.8rem; line-height: 1.2;}
	.event211119 .section2 .swiper {position: relative;text-align: left; overflow: hidden;}
	.event211119 .section2 .no_data {width: 100%; padding:0 10.6%;z-index: 0;}
	.event211119 .section2 .swiper .swiper-wrapper {display:flex; position: relative; width: 100%; height: 100%; z-index: 1; transition-property:transform}
	.event211119 .section2 .swiper-slide {position: relative;flex-shrink:0; height:100%; padding: 0 10.6%; transition-property:transform}
	.event211119 .section2 .swiper-slide li {position: relative;width: 100%; margin-top: 2.3%; padding-left: 110px; font-size: 2.85rem; color: #333;}
	.event211119 .section2 .swiper-slide li:first-child {margin-top: 0;}
	.event211119 .section2 .swiper-slide li em {position: absolute; top:0; left:0; width: 7.149vw; max-width:92px; max-height:92px;}
	.event211119 .section2 .swiper-slide li em img {width: 100%; vertical-align: top;}
	.event211119 .section2 .swiper-slide li > div {height: 7.149vw; max-height:92px; padding: 3.3% 8% 0; border-radius:3.5745vw; background-color: #fff;}
	.event211119 .section2 .swiper-slide li > div:after {content: ""; display: block; clear: both;}
	.event211119 .section2 .swiper-slide li > div > span {float: right; display: inline-block; color: #232a6b;}
	.event211119 .section2 .swiper-slide li > div > span strong {color: #0007f3;}

	.event211119 .section2 .swiper .swiper-button-prev,
	.event211119 .section2 .swiper .swiper-button-next {position: absolute; top: 50%; width: 4rem; height: 8rem; margin-top: -4rem; cursor: pointer; z-index:10; color:#fff; font-size:10rem; background-position: 0 50%; background-repeat: no-repeat; -webkit-background-size: contain; background-size: contain;}
	.event211119 .section2 .swiper .swiper-button-prev {left: 0; background-image:url(${btnPrevimg});}
	.event211119 .section2 .swiper .swiper-button-next {right: 0; background-image:url(${btnNextimg});}
	.event211119 .section2 .swiper .swiper-button-disabled {opacity: 0.5;}
	.event211119 .section2 .swiper .swiper-pagination {margin-top: 5%;text-align: center;}
	.event211119 .section2 .swiper .swiper-pagination span {display: inline-block; width: 1.4rem; height: 1.4rem; border-radius:0.7rem; background-color: #4960fe; margin: 0 0.5%; cursor: pointer;}
	.event211119 .section2 .swiper .swiper-pagination span.swiper-pagination-bullet-active {background-color: #0045f3;}

	.event211119 .section3 {position: relative;background-color: #dde4fe;}
	.event211119 .section3 .video {position: absolute; top:38.3%; left:50%; margin: 0 0 0 -375px;width: 750px;}
	.event211119 .section3 .video .video_content {position: relative; width: 100%; height: auto; padding-top: 56.34%;}
	.event211119 .section3 .video .video_content iframe {position: absolute;top:0;left:0;width: 100%;height: 100%;z-index: 1;}
	.event211119 .section3 .btn {position: absolute; width: 100%; left: 0; top:58%; text-align: center;}

	.event211119 .section4 {position: relative;background-color: #05d7b4}
	.event211119 .section4 .link {position: absolute; width: 750px; top:33%; left:50%; margin: 0 0 0 -375px; text-align: right;}

	.event211119 .section5 {background-color: #aec3f9}

	.event211119 .section6 {background-color: #1b2a6d; padding: 70px 0; font-size:1.9rem}
	.event211119 .section6 h2 {margin-bottom: 40px;font-size:2.7rem; color: #aec3f9;}
	.event211119 .section6 ul {width: 750px; margin:auto;text-align: left; color: #fff;}
	.event211119 .section6 ul li {position: relative; padding-left: 10px;}
	.event211119 .section6 ul li:before {content:""; display: inline-block; position: absolute; top:12px; left:0;width: 2px; height: 2px; background-color: #fff; vertical-align: middle;}
	.event211119 .section6 ul li a {text-decoration: underline;}

	@media (max-width: 950px) {
		.event211119 .section2 .inner {width: 100%; margin: 0}
		.event211119 .section2 .swiper-slide li {padding-left: 12%; font-size:2rem}
		.event211119 .section2 .swiper .swiper-button-prev {left: 3%;}
		.event211119 .section2 .swiper .swiper-button-next {right: 3%;}

		.event211119 .section3 .video {left:auto; margin: 0;width: 100%; padding: 0 10.5%;}
		.event211119 .section3 .btn img {width: 68%;}

		.event211119 .section4 .link {width: 100%; left: 0; margin: 0; padding-right: 9%;}
		.event211119 .section4 .link img {width: 17%;}
		.event211119 .section6 ul {width: 100%; padding: 0 5%;}
	}
	@media (max-width: 768px) {
		.event211119 .section2 .swiper-slide li > div {padding: 2.8% 6% 0;}
	}
	@media (max-width: 375px) {
		.event211119 .section2 .inner > p {font-size:2.8rem;}
		.event211119 .section2 .swiper .swiper-button-prev,
		.event211119 .section2 .swiper .swiper-button-next {width: 2.4rem; height: 6rem; margin-top: -5.3rem;}
		.event211119 .section2 .swiper-slide li > div {padding: 1.2% 6% 0;}
	}
`;

const EventPage = () => {

  const doCopy = text => {

		// 흐름 1.
    if (!document.queryCommandSupported("copy")) {
      return toast.error("Unsupported browser.");
    }

    // 흐름 2.
    const textarea = document.createElement("textarea");
    textarea.value = text;
    textarea.style.top = 0;
    textarea.style.left = 0;
    textarea.style.position = "fixed";

    // 흐름 3.
    document.body.appendChild(textarea);
    // focus() -> 사파리 브라우저 서포팅
    textarea.focus();
    // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
    textarea.select();
    // 흐름 4.
    document.execCommand("copy");
    // 흐름 5.
    document.body.removeChild(textarea);
    alert("Link copied.");
    //toast.success("Link copied.");
  };

	const user = useSelector((state) => state.user.data);

	const clickAILink = (e) => {
	  gotoAILink(e, user);
	}

  /* 슬라이드 참고
  const Swiper = () => {
    pagination: {
			el: ".swiper-pagination",
			clickable: true
		},
		navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev"
		};
  }; */

  return (
     <Style>

		<div class="event211119">
			<div class="section1">
				<img src={contimg} alt="Korean Pronunciation Challenge!" />
			</div>

			<div class="section2">
				<div class="inner">
					<img src={cont2img} alt="" />
					<p>Where am I ranked<br/>amongst the MT <strong>115</strong> members?</p>

					{/*  결과 없을 경우
					<div class="no_data">
						<img src={noDataimg} alt="" />
					</div>*/}

					{/* 결과 있을 경우 */}
					<div class="swiper">
						<div class="swiper-wrapper">
							<ul class="swiper-slide">
								<li><em><img src={rank1img} alt="1st" /></em><div>Jac** <span><strong>80.9</strong> Points</span></div></li>
								<li><em><img src={rank2img} alt="2nd" /></em><div>miy** <span><strong>80.7</strong> Points</span></div></li>
								<li><em><img src={rank3img} alt="3rd" /></em><div>Nye** <span><strong>79.2</strong> Points</span></div></li>
								<li><em><img src={rank4img} alt="4th" /></em><div>Ste** <span><strong>78.5</strong> Points</span></div></li>
								<li><em><img src={rank5img} alt="5th" /></em><div>Ala** <span><strong>78.2</strong> Points</span></div></li>
								<li><em><img src={rank6img} alt="6th" /></em><div>Sue** <span><strong>78.1</strong> Points</span></div></li>
								<li><em><img src={rank7img} alt="7th" /></em><div>Tok** <span><strong>77.6</strong> Points</span></div></li>
								<li><em><img src={rank8img} alt="8rd" /></em><div>Mar** <span><strong>77.5</strong> Points</span></div></li>
								<li><em><img src={rank9img} alt="9th" /></em><div>Waf** <span><strong>77.5</strong> Points</span></div></li>
								<li><em><img src={rank10img} alt="10th" /></em><div>MUH** <span><strong>77.3</strong> Points</span></div></li>
							</ul>
						</div>
					</div>
					<button class="swiper-button-next"></button>
					<button class="swiper-button-prev"></button>
					<div class="swiper-pagination"></div>
					<div class="noti">* updated at 10AM (KST) on Weekdays.</div>

				</div>
			</div>

			<div class="section3">
				<div class="video">
					<div class="video_content">
						<iframe width="100%" height="420" src="https://www.youtube.com/embed/gd2IHHxafTA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
					</div>
				</div>
				<div class="btn">
					<a href="javascript:;" onClick={clickAILink} ><img src={btnSubmitimg} alt="Start Korean Pronunciation Challenge" /></a>
				</div>
				<img src={cont3img} alt="How to participate / Prize of the Challenge" />
			</div>

			<div class="section4">
				<div class="link">
					<a href="javascript:;" onClick={() => doCopy("http://www.mastertopik.com/event/AiSpeak")} ><img src={linkimg} alt="Share the Korean Pronunciation Challenge!" /></a>
				</div>
				<img src={cont4img} alt="" />
			</div>

			<div class="section5">
				<img src={cont5img} alt="" />
			</div>

			<div class="section6">
				<h2>Notification</h2>
				<ul>
					<li>Period : 2021.11.22 ~2021.12.12</li>
					<li>Prize : Participants ranked up to top 10</li>
					<li>This event is for the masterTOPIK members only.</li>
					<li>The ranking list will be updated at 10AM (KST) on Weekdays.</li>
					<li>‘AI SPEAK’ is a service that allows you to evaluate your pronunciation while speaking after words and sentences.</li>
					<li>If you want to check out the AI SPEAK user guide, <a href="https://mastertopikhelp.zendesk.com/hc/en-us/articles/4411040841875" target="_blank">Click Here</a></li>
					<li>You can check your score in the “학습현황 learning status” of AI SPEAK.</li>
					<li>You need to record all sentences (total 29 sentences in 3 steps).</li>
					<li>You can try again without limitation during the event period.</li>
					<li>Submitted grades and images can be used for masterTOPIK marketing.</li>
					<li>Prizes will be provided after individual notice, and can be changed under mutual agreement.</li>
					<li>Inquiry : <a href="mailto:mastertopik@visang.com">mastertopik@visang.com</a></li>
				</ul>
			</div>

		</div>
    </Style>
  );
};

export default EventPage;

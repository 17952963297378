import React, { useState, useEffect } from 'react';
import { SwiperSlide } from 'swiper/react';
import { useAxios } from 'common/hook/useAxiosHook';
import VideoItem from 'component/molecules/VideoItem';
import SwiperList from 'component/molecules/SwiperList';
import { fetchRelatedCourses } from 'container/CourseDetailPage/api/courseDetailApi';
import { EmptyList } from 'component/atoms/EmptyList';

const RelatedCourse = ({ token, productId }) => {
  const [slideItems, setSlideItems] = useState([]);
  const [getRelatedCourse, refreshGetRelatedCourse] = useAxios(
    fetchRelatedCourses,
    [productId],
    true
  );

  const { data } = getRelatedCourse;
  useEffect(() => {
    if (!token) {
      refreshGetRelatedCourse(productId);
    } else {
      refreshGetRelatedCourse(productId, 0, token);
    }
    return;
  }, [token]);

  useEffect(() => {
    if (data) {
      if (data.first) {
        setSlideItems([...data.content]);
      } else {
        setSlideItems([...slideItems, ...data.content]);
      }
    }
  }, [data]);

  const onNext = () => {
    if (!data.last) {
      if (!token) {
        refreshGetRelatedCourse(productId, data.number + 1);
      } else {
        refreshGetRelatedCourse(productId, data.number + 1, token);
      }
    }
  };
  return (
    <>
      <SwiperList
        id='library-course'
        slideItemList={slideItems}
        onNext={onNext}
      >
        {slideItems.length > 0 ? (
          slideItems.map((item) => (
            <SwiperSlide key={item.productId}>
              <VideoItem
                title={item.title}
                imgSrc={item.thumbnailPath}
                percent={item.progressPercent}
                to={`/courses/detail/${item.productId}`}
                idNew={item.isNew}
                videoLink={
                  !token
                    ? `/courses/detail/${item.productId}`
                    : `/courses/detail/${item.productId}/${item.chapterLectureId}`
                }
                // desc={
                //   !token
                //     ? `${item.classCount}classes, ${item.hour} hours`
                //     : item.chapterTitle
                // }
              />
            </SwiperSlide>
          ))
        ) : (
          <EmptyList />
        )}
      </SwiperList>
    </>
  );
};

export default React.memo(RelatedCourse);

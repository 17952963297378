import React, { useEffect } from "react";

const PaymentChangeRedirectHandler = () => {
  useEffect(() => {
    window.opener.document.location.href = `/setting`;
    window.self.close();
  }, []);

  return <></>;
};

export default PaymentChangeRedirectHandler;

import ContentsWrapper from 'component/atoms/ContentsWrapper';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';
import arrowRightIcon from 'assets/images/arrow_right.svg';

export const StyledLibraryPage = {
  SearchContainer: styled.div`
    margin-bottom: 5em;
  `,
  BadgeContainer: styled.div`
    margin: 1.5em 0 10.9em 0;
    width: 85.5%;
  `,
  Wrapper: styled(ContentsWrapper)`
    padding-bottom: 5em;
    .swiper-container {
      margin-bottom: 8.8em;
    }
  `,
  RecommendContainer: styled.div`
    background-color: #393c40;
    padding-top: 5em;
    > div {
      padding-top: 5em;
      .slider-prev,
      .slider-next {
        top: 12em;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  `,
  SwiperWrap: styled.div`
    position: relative;
    margin-top: 2em;
    font-size: 0.7em;
    .slider-prev,
    .slider-next {
      top: 0em;
      /* background-color: #242629; */
      background-color: #1e2024;
      transform: scale(0.7) rotate(45deg);
    }
    .slider-prev {
      left: 0;
    }
    .slider-next {
      right: 0;
    }
    @media (max-width: 1024px) {
      .slider-prev,
      .slider-next {
        display: block;
      }
    }
    @media (max-width: 767px) {
      .slider-prev,
      .slider-next {
        top: 1em;
      }
    }
    &.library {
      .slider-prev,
      .slider-next {
        display: none;
      }
    }
  `,
  Swiper: styled(Swiper)`
    position: relative;
    padding: '0 10em 0 2em';
    margin: 1.6em 0 1.5em;
    &.arrow {
      padding: '0 10em 0 8em';
    }
    .swiper-slide {
      width: auto;
      &:not(:last-child) span:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 1.25em;
        width: 2px;
        height: 2px;
        background-color: #fff9;
      }
    }
    .swiper-slide span {
      position: relative;
      display: inline-block;
      font-size: 2em;
      font-weight: 100;
      padding: 0.5em 4em 0.5em 0;
      cursor: pointer;
      @media (max-width: 767px) {
        font-size: 2.4em;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      z-index: 2;
      width: 1em;
      &.arrow {
        width: 8em;
      }
      height: 100%;

      background: linear-gradient(
        90deg,
        rgba(15, 16, 18, 1) 20%,
        rgba(15, 16, 18, 0)
      );
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      z-index: 2;
      width: 10rem;
      height: 100%;

      background: linear-gradient(
        90deg,
        rgba(15, 16, 18, 1) 20%,
        rgba(15, 16, 18, 0)
      );
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
      transform: rotate(180deg);
    }
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 50%;
      z-index: 99;
      width: 3rem;
      height: 3rem;
      margin-top: -1.5rem;
      padding: 0;
      border: 0;
      background: url(${arrowRightIcon}) no-repeat center transparent;
      background-size: 2rem;
      &:not(.swiper-button-disabled) {
        cursor: pointer;
      }
      &.swiper-button-disabled {
        opacity: 0.5;
      }
    }
    .swiper-button-prev {
      left: 0;
      transform: rotate(180deg);
    }
    .swiper-button-next {
      right: 0;
    }
    /* @media (max-width: 767px) {
      padding: 0 7.5em;
      &:before,
      &:after {
        width: 7.5em;
      }
    } */
  `,
};

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  clear: both;
  padding: 5em 0;
  font-size: 500;
  text-align: center;
`;

const Error = () => (
  <Container>
    <p>(Temporary) Error</p>
  </Container>
);

export default Error;

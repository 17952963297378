import React, { useEffect, useRef, useState } from 'react';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import Style from './styled/PlanSection.style';
import CourseListContainer from './ListContainer';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUrl, setVideoUrl } from './state/action';

import { useAxios } from 'common/hook/useAxiosHook';
import wbmKoreaApi from 'container/WbmKoreaPage/api/wbmKoreaApi';
import Loading from 'component/atoms/Loading';

import ReactPlayer from 'react-player';
import contentsApi from 'api/contents';

const WbmKoreaPlanSection = ({
  t,
  productId,
  productId2,
  productId3,
  productId4,
  productId5,
  productId6,
  productId7,
  productId8,
  productId9,
  overview
}) => {
  const dispatch = useDispatch();

  let overviewRef = useRef(0);

  const lectureId = useSelector((state) => state.wbmKorea.lectureId);
  const lectureId2 = useSelector((state) => state.wbmKorea.lectureId);
  const lectureId3 = useSelector((state) => state.wbmKorea.lectureId);
  const lectureId4 = useSelector((state) => state.wbmKorea.lectureId);
  const lectureId5 = useSelector((state) => state.wbmKorea.lectureId);
  const lectureId6 = useSelector((state) => state.wbmKorea.lectureId);
  const lectureId7 = useSelector((state) => state.wbmKorea.lectureId);
  const lectureId8 = useSelector((state) => state.wbmKorea.lectureId);
  const lectureId9 = useSelector((state) => state.wbmKorea.lectureId);
  const videoUrl = useSelector((state) => state.wbmKorea.currentVideoUrl);

  const [getVideoData, refreshGetVideoData] = useAxios(
      wbmKoreaApi.fetchVideoData,
    [productId],
    true
  );
  const [getVideoData2, refreshGetVideoData2] = useAxios(
      wbmKoreaApi.fetchVideoData,
    [productId2],
    true
  );
  const [getVideoData3, refreshGetVideoData3] = useAxios(
      wbmKoreaApi.fetchVideoData,
      [productId3],
      true
  );
  const [getVideoData4, refreshGetVideoData4] = useAxios(
      wbmKoreaApi.fetchVideoData,
      [productId4],
      true
  );
  const [getVideoData5, refreshGetVideoData5] = useAxios(
      wbmKoreaApi.fetchVideoData,
      [productId5],
      true
  );
  const [getVideoData6, refreshGetVideoData6] = useAxios(
      wbmKoreaApi.fetchVideoData,
      [productId6],
      true
  );
  const [getVideoData7, refreshGetVideoData7] = useAxios(
      wbmKoreaApi.fetchVideoData,
      [productId7],
      true
  );
  const [getVideoData8, refreshGetVideoData8] = useAxios(
      wbmKoreaApi.fetchVideoData,
      [productId8],
      true
  );
  const [getVideoData9, refreshGetVideoData9] = useAxios(
      wbmKoreaApi.fetchVideoData,
      [productId9],
      true
  );

  const { loading, data } = getVideoData;
  const { data: data2 } = getVideoData2;
  const { data: data3 } = getVideoData3;
  const { data: data4 } = getVideoData4;
  const { data: data5 } = getVideoData5;
  const { data: data6 } = getVideoData6;
  const { data: data7 } = getVideoData7;
  const { data: data8 } = getVideoData8;
  const { data: data9 } = getVideoData9;
  const [playing, setPlaying] = useState(false);

  const [videoInfoData, fetchVideo] = useAxios(
    contentsApi.getVideoByType,
    [],
    true
  )
  const [videoInfoData2, fetchVideo2] = useAxios(
      contentsApi.getVideoByType,
      [],
      true
  )
  const [videoInfoData3, fetchVideo3] = useAxios(
      contentsApi.getVideoByType,
      [],
      true
  )
  const [videoInfoData4, fetchVideo4] = useAxios(
      contentsApi.getVideoByType,
      [],
      true
  )
  const [videoInfoData5, fetchVideo5] = useAxios(
      contentsApi.getVideoByType,
      [],
      true
  )
  const [videoInfoData6, fetchVideo6] = useAxios(
      contentsApi.getVideoByType,
      [],
      true
  )
  const [videoInfoData7, fetchVideo7] = useAxios(
      contentsApi.getVideoByType,
      [],
      true
  )
  const [videoInfoData8, fetchVideo8] = useAxios(
      contentsApi.getVideoByType,
      [],
      true
  )
  const [videoInfoData9, fetchVideo9] = useAxios(
      contentsApi.getVideoByType,
      [],
      true
  )
  const { data: videoInfo } = videoInfoData;
  const { data: videoInfo2 } = videoInfoData2;
  const { data: videoInfo3 } = videoInfoData3;
  const { data: videoInfo4 } = videoInfoData4;
  const { data: videoInfo5 } = videoInfoData5;
  const { data: videoInfo6 } = videoInfoData6;
  const { data: videoInfo7 } = videoInfoData7;
  const { data: videoInfo8 } = videoInfoData8;
  const { data: videoInfo9 } = videoInfoData9;


  useEffect(() => {

    if (lectureId !== 0) {
      fetchVideo(lectureId, "", "");
    }
  }, [lectureId])

  useEffect(() => {
    refreshGetVideoData(productId, "");

    if (lectureId !== "0") {
      fetchVideo(lectureId, "", "");
    }
  }, [fetchVideo]);
  useEffect(() => {

    refreshGetVideoData2(productId2, "");

    if (lectureId2 !== "0") {
      fetchVideo2(lectureId2, "", "");
    }
  }, [fetchVideo2]);
  useEffect(() => {
    refreshGetVideoData3(productId3, "");

    if (lectureId3 !== "0") {
      fetchVideo3(lectureId3, "", "");
    }
  }, [fetchVideo3]);
  useEffect(() => {
    refreshGetVideoData4(productId4, "");

    if (lectureId4 !== "0") {
      fetchVideo4(lectureId4, "", "");
    }
  }, [fetchVideo4]);
  useEffect(() => {
    refreshGetVideoData5(productId5, "");

    if (lectureId5 !== "0") {
      fetchVideo5(lectureId5, "", "");
    }
  }, [fetchVideo5]);
  useEffect(() => {
    refreshGetVideoData6(productId6, "");

    if (lectureId6 !== "0") {
      fetchVideo2(lectureId6, "", "");
    }
  }, [fetchVideo6]);
  useEffect(() => {
    refreshGetVideoData7(productId7, "");

    if (lectureId7 !== "0") {
      fetchVideo7(lectureId7, "", "");
    }
  }, [fetchVideo7]);
  useEffect(() => {
    refreshGetVideoData8(productId8, "");

    if (lectureId8 !== "0") {
      fetchVideo8(lectureId8, "", "");
    }
  }, [fetchVideo8]);
  useEffect(() => {
    refreshGetVideoData9(productId9, "");

    if (lectureId9 !== "0") {
      fetchVideo9(lectureId9, "", "");
    }
  }, [fetchVideo9]);

  useEffect(() => {
    if (overview) {
      dispatch(setCurrentUrl({ url: overview.ot, provider: 'youtube' }));
      dispatch(
        setVideoUrl({
          overview: { url: overview.ot, provider: 'youtube' },
          lecture: { url: overview.lecture, provider: 'mp4' },
        })
      );
    }
  }, [overview]);


  useEffect(() => {
    //console.log('test: videoUrl !!!!', videoUrl);
  }, [videoUrl]);

  useEffect(() => {
    if (videoInfo) {
      dispatch(setCurrentUrl({ url: videoInfo.url, provider: 'youtube' }));
    }
  }, [videoInfo])
  useEffect(() => {
    if (videoInfo2) {
      dispatch(setCurrentUrl({ url: videoInfo2.url, provider: 'youtube' }));
    }
  }, [videoInfo2])
  useEffect(() => {
    if (videoInfo3) {
      dispatch(setCurrentUrl({ url: videoInfo3.url, provider: 'youtube' }));
    }
  }, [videoInfo3])
  useEffect(() => {
    if (videoInfo4) {
      dispatch(setCurrentUrl({ url: videoInfo4.url, provider: 'youtube' }));
    }
  }, [videoInfo4])
  useEffect(() => {
    if (videoInfo5) {
      dispatch(setCurrentUrl({ url: videoInfo5.url, provider: 'youtube' }));
    }
  }, [videoInfo5])
  useEffect(() => {
    if (videoInfo6) {
      dispatch(setCurrentUrl({ url: videoInfo6.url, provider: 'youtube' }));
    }
  }, [videoInfo6])
  useEffect(() => {
    if (videoInfo7) {
      dispatch(setCurrentUrl({ url: videoInfo7.url, provider: 'youtube' }));
    }
  }, [videoInfo7])
  useEffect(() => {
    if (videoInfo8) {
      dispatch(setCurrentUrl({ url: videoInfo8.url, provider: 'youtube' }));
    }
  }, [videoInfo8])
  useEffect(() => {
    if (videoInfo9) {
      dispatch(setCurrentUrl({ url: videoInfo9.url, provider: 'youtube' }));
    }
  }, [videoInfo9])

  if (loading) return <Loading />;

  if (data) {
    return (
      <ContentsWrapper>
        <Style.Container>
          <Style.CoursePlan>
            <h2>{t('stitle')}</h2>
            <ReactPlayer
              ref={overviewRef}
              url={videoUrl?.url}
              playing={playing}
              onPlay={() => setPlaying(true)}
              onPause={() => setPlaying(false)}
              controls
              config={{
                file: {
                  attributes: {
                    onContextMenu: (e) => e.preventDefault(),
                    controlsList: 'nodownload',
                  },
                },
              }}
            />
          </Style.CoursePlan>
          <CourseListContainer
              data={data}
              data2={data2}
              data3={data3}
              data4={data4}
              data5={data5}
              data6={data6}
              data7={data7}
              data8={data8}
              data9={data9}
              videoRef={overviewRef}
              playing={playing}
              productId={productId}
              productId2={productId2}
              productId3={productId3}
              productId4={productId4}
              productId5={productId5}
              productId6={productId6}
              productId7={productId7}
              productId8={productId8}
              productId9={productId9}
              lectureId={lectureId}
          />
          {/* 1204,1261,1249,1150,1151,1162,1163,1168,1169 */}
        </Style.Container>
      </ContentsWrapper>

    );
  }
  return <></>;
};

export default WbmKoreaPlanSection;

import styled, { css } from 'styled-components';

const Styled = {
  SubTitle: styled.div`
    margin-bottom: 4em;
    font-weight: 300;
    font-size: 2em;
    color: #d1d1d1;
    div {
      margin-bottom: 0.2em;
    }
    .link {
      position: relative;
      display: inline-block;
      margin-top: 1em;
      padding: 0.6em 2.6em 0.6em 1em;
      border: 2px solid #5c5f66;
      border-radius: 4px;
      line-height: 1.2em;
      font-size: 0.8em;
      color: #d1d1d1;
      &::after {
        content: '';
        position: absolute;
        right: 1em;
        top: 50%;
        margin-top: -0.25em;
        width: 0.5em;
        height: 0.5em;
        border-top: 2px solid #d1d1d1;
        border-right: 2px solid #d1d1d1;
        transform: rotate(45deg);
      }
    }
  `,

  Section: styled.div`
    position: relative;
    padding-top: 2em;
    margin-bottom: 7em;
    color: #c9cbd1;
    button {
      color: inherit;
    }
    &:last-child {
      margin-bottom: 12em;
    }
  `,

  ClassTitle: styled.div`
    position: relative;
  `,

  ClassLang: styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
    font-size: 1.4em;
    li {
      display: inline-block;
      font-weight: 300;
      &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0.8em;
        border-left: 1px solid #8b8f99;
        vertical-align: middle;
      }
      &:first-child::before{
        display: none;
      }
      &.active {
        font-weight: 700;
      }
      button {
        padding: 0.5em 1em;
        vertical-align: middle;
        font-weight: inherit;
        font-size: inherit;
      }
    }
    @media (max-width: 750px) {
      & {
        top: 0;
        bottom: auto;
        width: 8em;
        padding-top: 3em;
        font-size: 1.6em;
        &::before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          box-sizing: border-box;
          width: 100%;
          height: 3em;
          border: 1px solid #3c3e43;
          border-radius: 5px;
        }
        &::after {
          content: '';
          position: absolute;
          right: 1em;
          top: 1em;
          width: 0.5em;
          height: 0.5em;
          border-top: 2px solid #797979;
          border-right: 2px solid #797979;
          transform: rotate(135deg);
        }
        ul {
          margin-top: 0.5em;
          border-radius: 5px;
          overflow: hidden;
        }
        &:hover {
          li {
            display: block !important;
          }
        }
      }
      li {
        display: none;
        width: 100%;
        background: #2b2e35;
        &::before {display: none;}
        &.active {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          background: none;
        }
        button {
          width: 100%;
          height: 3em;
          padding: 1em 3em 1em 1.5em;
          text-align: left;
        }
      }
    }
  `,

  ClassList: styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    > * {
      width: calc(25% - 120px / 4);
      margin-right: 40px;
    }
    >*:nth-child(4) ~ * {
      margin-top: 80px;
    }
    >*:nth-of-type(4n) {
      margin-right: 0;
    }
    @media (max-width: 1024px) {
      > * {
        margin-right: 20px;
      }
    }
    @media (max-width: 750px) {
      > * {
        width: calc(50% - 10px / 2);
        margin-right: 10px;
      }
      >*:nth-child(2) ~ * {
        margin-top: 40px;
      }
      >*:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  `,

  FaqWrapper: styled.div`
    position: relative;
  `,

  Overview: styled.div`
    font-size: 2em;
    line-height: 1;
    padding: 1.5em 1em;
    background: #1e2024;
    table {
      table-layout: auto;
      max-width: 100%;
      th {
        position: relative;
        padding: 1em 2em;
        text-align: left;
        font-weight: 500;
        white-space: nowrap;
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          right: 0;
          width: 0;
          height: 1.2em;
          margin-top: -0.6em;
          border-right: 1px solid #55585f;
        }
      }
      td {
        padding: 1em 2em;
      }
    }
    @media (max-width: 767px) {
      padding: 1em 0;
      table {
        th {
          padding-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }
  `,

  MyClass: styled.div`
    font-size: 2em;
    line-height: 1.2;
    color: #c9cbd1;
    padding: 3.5em 0;
    text-align: center;
    strong {
      font-weight: 700;
    }
    p {
      font-weight:500;
      font-size: 1.1em;
    }
  `,

  Notification: styled.div`
    font-size: 2em;
    line-height: 1.2;
    color: #c9cbd1;
    strong {
      font-weight: 700;
    }
    p, dt {
      margin: 1.4em 0 0.6em;
      font-weight:500;
      font-size: 1.1em;
    }
    dd {
      position:relative;
      padding-left: 0.8em;
      margin-bottom: 0.6em;
      &:before {
        content:"";
        display:inline-block;
        position: absolute;
        top:0.5em;
        left:0;
        width: 0.2em;
        height: 0.2em;
        border-radius: 0.1em;
        background:#fff;
        vertical-align:middle;
      }
      em {
        color:#fff799;
      }
      a {
        border-bottom: 1px solid #c9cbd1;
      }
    }
  `,

};

export default Styled;

import styled from 'styled-components';

const Dashboard = {
  Wrapper: styled.div`
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-color: #1e2024;
    border-radius: 0.6em;
    padding: 3.5em 0;
    flex-wrap: wrap;
  `,
  ItemBox: styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
    border-left: 1px solid #2c2c2c;

    &:first-child {
      border-left: 0;
    }
  `,
  Item: styled.dl`
    position: relative;
    display: inline-block;
    padding-left: 7.5em;
  `,
  Title: styled.dt`
    color: #5f6268;
    font-size: 1.6em;
    font-weight: 500;
  `,
  Count: styled.dd`
    display: block;
    color: #ffffff;
    font-size: 4em;
    font-weight: 500;
  `,
}

export default Dashboard;

import React, { useEffect, useState } from 'react';
import H3 from 'component/atoms/H3';
import Board from 'component/Board';
import CommunitySearchContainer from './Search';
import { debounce } from 'lodash';
import SearchBoard from 'container/CommunityPage/List/ViewAllPosts/SearchBoard';
import Tab from './Tab';
import Features from 'container/LandingPage/Features';
import { useTranslation } from 'react-i18next';

const ViewAllPosts = ({ initState }) => {
  const { t } = useTranslation('communityPage');
  const [postList, fetchPostList] = initState.postList;
  const [tabActiveIdx, setTabActiveIdx] = useState(0);
  const { loading, data, error } = postList;

  const [slideItem, setSlideItem] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  const [orderByLevel, setOrderByLevel] = useState(null);

  const [searchWords, setSearchWords] = useState(null);
  const [select, setSelect] = useState('All');

  const onNext = () => {
    if (!tabActiveIdx) {
      fetchPostList(data.number + 1);
    } else if (tabActiveIdx === 1) {
      fetchPostList(data.number + 1, { likes: '' });
    }
  };

  const delaySearch = debounce((text) => {
    setSearchWords(text);
  }, 1000);

  useEffect(() => {
    if (!searchWords) {
      if (!tabActiveIdx) {
        fetchPostList(0);
      } else if (tabActiveIdx === 1) {
        fetchPostList(0, { likes: '' });
      }
    }
  }, [searchWords, tabActiveIdx]);

  useEffect(() => {
    if (data && data.first) {
      setSlideItem([...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    } else if (data && !data.first) {
      setSlideItem([...slideItem, ...data.content]);
      setHasMore(data.number + 1 < data.totalPages);
    }
  }, [postList, searchWords]);

  return (
    <div>
      <H3>{t('postTable.title')}</H3>
      <CommunitySearchContainer
        setSearchWords={delaySearch}
        setSelect={setSelect}
      />
      {searchWords && (
        <SearchBoard
          initState={initState.searchList}
          select={select}
          searchWords={searchWords}
          t={t}
        />
      )}

      {!searchWords && slideItem.length > 0 && (
        <>
          <Tab activeIdx={tabActiveIdx} setActiveIdx={setTabActiveIdx} />
          <Board boardList={slideItem} next={onNext} hasMore={hasMore} t={t} />
        </>
      )}
    </div>
  );
};

export default ViewAllPosts;

import { asyncApi } from 'common/util/asyncApi';

const url = '/api/v1/recommend-keywords';

const libraryApi = {
  getBadge: async (token) => {
    const headers = token && { headers: { Authorization: `Bearer ${token}` } };
    const config = {
      url: url + `?top12`,
      ...headers,
    };
    let response = await asyncApi(config);
    return response.data;
  },
  getSearchList: async (token) => {
    const config = {
      url: '/api/v1/search-word',
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await asyncApi(config);
    return response.data;
  },
};

export default libraryApi;

import styled from "styled-components";
import { Link } from "react-router-dom";
import { VscClose } from 'react-icons/vsc';
import { Swiper, SwiperSlide } from 'swiper/react';

const CloseButton = styled.button`
    display: block;
    padding: 9px;
    text-align: center;
    width: 50%;
    height: 50px;
    letter-spacing: -0.01em;
    font-size: 1.1em;
    color: #666666;

		@media (max-width: 1023px) {
      font-size: 2em;
    }
`;

const StyledEventBanner = {
  EventBannerWrap: styled.div`
    // position: absolute;
    position: fixed;
    top: 135px;
    left: 6%;
    z-index:999;
    width: 420px;
    // height: 403px;
    @media (max-width: 767px) {
      padding: 0;
    }

    &.mobile {
      position: fixed;
      top: auto;
      left: auto;
      right: 0;
      bottom: 0;
      width: 100%;
      height: auto;
      z-index: 1000000;

      &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        background-color: rgba(0, 0, 0, 0.8);
        opacity: 0;
        overflow: auto;
        animation-name: fade-in;
        animation-duration: 0.3s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        @keyframes fade-in {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  `,
  EventContent: styled.div`
    position: relative;
    z-index: 1000000;
    width: 100%;
    // max-height: 353px;
    overflow: hidden;
  `,
  Swiper: styled(Swiper)`
		--swiper-theme-color: #fff;
    --swiper-navigation-size: 44px;
    // max-height: 353px;
    touch-action: none;

    .swiper-button-prev,
    .swiper-button-next{
      display: none;
      position: absolute;
      top: 50%;
      width: calc(var(--swiper-navigation-size) / 44 * 27);
      height: var(--swiper-navigation-size);
      margin-top: calc(-1 * var(--swiper-navigation-size) / 2);
      z-index: 10;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      color: var(--swiper-navigation-color, var(--swiper-theme-color));
      &::after{
        font-family: swiper-icons;
        font-size: var(--swiper-navigation-size);
        text-transform: none !important;
        letter-spacing: 0;
        font-variant: initial;
        line-height: 1;
      }
    }
    .swiper-button-prev {
      &:after {
        content: 'prev';
      }
      left: 10px;
      right: auto;
    }
    .swiper-button-next {
      &:after {
        content: 'next';
      }
      right: 10px;
      left: auto;
    }

    &.enter{
      .swiper-button-prev,
      .swiper-button-next{
        display: flex;
      }
    }

    // .swiper-slide{
    //   display: flex;
    //   align-items: center;
    // }

		.swiper-pagination-bullets .swiper-pagination-bullet {
			margin: 0 6px;
		}
		.swiper-pagination-bullet {
			width: 14px;
			height: 14px;
			opacity: 0.4;
		}
		.swiper-pagination-bullet-active {
			opacity: 0.8;
		}
		.swiper-button-prev, .swiper-button-next {
			opacity: 0.8;
		}
  `,
  SwiperSlide: styled(SwiperSlide)`
  `,
  Link: styled(Link)`
    img {
      display: inline-block;
      image-rendering: -webkit-optimize-contrast;
      backface-visibility: hidden;
	  }
  `,
  EventBottom: styled.div`
    position: relative;
    z-index: 1000000;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f2f2f2;
  `,
  TodayClose: styled(CloseButton)`
		width: 65%;
    border-right: 1px solid #e6e6e6;
  `,
  CloseBtn: styled(CloseButton)`
  `,
  Close: styled.button.attrs({
    type: 'button',
    children: <VscClose />,
  })`
    position: absolute;
    top: 5%;
    right: 5%;
    font-size: 3em;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    color: ${props => props.$btnColor ? props.$btnColor : '#fff'};
	  z-index:1000001;
  `,
  BoxImg: styled.div`
    position: relative;
    z-index:1000000;
    img {
      width: 100%;
    }
  `,
};

export default StyledEventBanner;

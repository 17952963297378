import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { Trans, useTranslation } from "react-i18next";
import Styled from './index.style';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import useLoginStatus from 'common/hook/useLoginStatus';
import { languageCode, API_HOST } from 'common/constant';
import plansApi from 'api/plans';
import userApi from 'api/user';
import couponApi from "api/coupon";
import bannerApi from "api/banner";
import { useAxios } from "common/hook/useAxiosHook";
import { openLink } from 'common/util/util';

SwiperCore.use([Pagination, Navigation]);

const RollBanner = () => {
  const { t } = useTranslation('rollBanner');
  /*고정 데이터 운영 기준*/
  const couponNumber = "PREFORYOU";
  const planCode = "PREMIUM";
  const productIdx = "1147";
  /*고정 데이터 */

  const [updateSwiper, setUpdateSwiper] = useState(0);
  const swiperRef = useRef(null);
  const userData = useSelector((state) => state.user.data);
  const token = useSelector((state) => state.user.token);
  const currency = useSelector((state) => state.common.currency);
  const isLogin = useLoginStatus();
  const language = localStorage.getItem('i18nextLng');
  const [userClassfication, setUserClassfication] = useState(0);
  const [userBannerData, setUserBannerData] = useState([]);
  const [adminBannerData, setAdminBannerData] = useState([]); //관리자페이지에 등록된 롤링배너
  const [totalBannerList, setTotalBannerList] = useState([]);
  /*
  userClassfication
  0: NOTLOGIN     비회원
  1: NEWMEMBER    신규 - 회원가입후, 무료체험 전
  2: FREETRIAL    무료체험 - 무료체험 중
  3: PAIDPLAN     구독 - 유료구독 상태
  4: UNUSEDCOUPON 해지/쿠폰 미사용 - 무료체험or유료구독 해지상태 & 재구독 쿠폰 미사용
  5: USEDCOUPON   해지/쿠폰 사용 - 무료체험or유료구독 해지상태 & 재구독 쿠폰 사용

  번호  | 멤버십           | 무료체험  | 유료결제
  1     |FREE             | O         | X
  2     |STANDARD/PREMIUM | O         | X
  3     |STANDARD/PREMIUM | O         | O
  4     |FREE             | O         | -
  5     |FREE             | O         | O
  */

  const [{ loading: freeTrialLoading, data: freeTrialData }, getFreeTrial] = useAxios(
    plansApi.freeTrial,
    [],
    true
  );
  const [{ loading: myPaymentListLoading, data: myPaymentListData }, getMyPaymentList] = useAxios(
    userApi.fetchMyPaymentList,
    [],
    true
  );
  const [{ loading: couponLoading, data: couponData }, refreshCouponData] = useAxios(
    couponApi.getPlanCouponData,
    [],
    true
  );
  const [{ loading: rollingBannerLoading, data: rollingBannerData }, getRollingBanner] = useAxios(
    bannerApi.getRollingBanner,
    [],
    true
  );

  useEffect(()=>{
    const timeout = setTimeout(() => {
      //swiper update
      swiperRef.current?.swiper?.update();
    }, 0);

    return () => {
      clearTimeout(timeout);
    }
  },[updateSwiper]);

  useEffect(()=>{
    // console.log('사용자 정보~', userData);
    /*
    userData.serviceLevel : "FREE"
    userData.orderPayInfo
    재구독 쿠폰 PREFORYOU "available":true
    getPlanCouponData
    무료체험 쿠폰 : freeTrial
    */

    if(isLogin && token){
      getFreeTrial(planCode, token);
      getMyPaymentList(token);
      refreshCouponData({ token, currency, plan: planCode, couponNumber, productIdx });
    }
    getRollingBanner();
  }, [])

  useEffect(()=>{
    //couponData는 별도로 가려다가 일단 합침
    // if(couponData){
    if(false){
      if(couponData.available === true){
        //4. 해지 / 쿠폰 미사용
        setUserClassfication(4);
      }else if(couponData.available === false){
        //5. 해지 / 쿠폰 사용 완료
        /*
          available: false
          unavailableReason: "요청받은 쿠폰 코드 'PREFORYOU' 에 대한 쿠폰 정보를 찾을 수 없습니다."
          unavailableReason: "요청받은 쿠폰 'devclass50' 은 이미 사용했습니다."
          unavailableReason: "쿠폰 'PREFORYOU' 을 사용하려는 제품이 존재하지 않습니다."
          unavailableReason: "이 상품에는 쿠폰 'PREFORYOU' 을 사용할 수 없습니다."

          우선 false이면 그냥 쿠폰 사용한 거라고 하기
        */
        setUserClassfication(5);
      }
    }
  }, [couponData])

  useEffect(()=>{
    if(isLogin && freeTrialData && myPaymentListData && couponData){
      /*
      isFreeTrial : Y = 무료체험 가능
      isFreeTrial : N = 무료체험 불가
      learning : Y = 무료체험 진행중
      learning : N = 무료체험 진행중 아님
      */
      // console.log("freeTrialData:: ", freeTrialData)
      // console.log("myPaymentListData:: ", myPaymentListData)
      const myPlanSuccessList = myPaymentListData.filter((data)=>
        (data.title === "PREMIUM" || data.title === "STANDARD" || data.title === "EPS")
        && data.status === "SUCCESS"
      );
      // console.log("myPlanSuccessList:: ", myPlanSuccessList)
      if(freeTrialData.isFreeTrial === "Y"){ //무료체험 미사용
        if(userData.serviceLevel === "FREE"){
          if(myPlanSuccessList.length === 0){
            //1. 가입
            setUserClassfication(1);
          } else{
            //재구독 쿠폰 사용 여부
            // refreshCouponData({ token, currency, plan: planCode, couponNumber });
            if(couponData.available === true){
              setUserClassfication(4);
            } else {
              setUserClassfication(5);
            }
          }
        } else if(userData.serviceLevel === "PREMIUM" || userData.serviceLevel === "STANDARD" /*|| userData.serviceLevel === "EPS"*/){
          //3. 구독 //유료구독 상태
          setUserClassfication(3);
        }
      } else if(freeTrialData.isFreeTrial === "N" && freeTrialData.learning === "Y"){ //무료체험 진행중
        if(userData.serviceLevel === "PREMIUM" || userData.serviceLevel === "STANDARD" /*|| userData.serviceLevel === "EPS"*/){
          //2. 무료체험
          setUserClassfication(2);
        }
      } else if(freeTrialData.isFreeTrial === "N" && freeTrialData.learning === "N"){ //무료체험 사용 및 체험종료
        if(userData.serviceLevel === "FREE"){
          //재구독 쿠폰 사용 여부
          // refreshCouponData({ token, currency, plan: planCode, couponNumber });
          if(couponData.available === true){
            setUserClassfication(4);
          } else {
            setUserClassfication(5);
          }
        } else if(userData.serviceLevel === "PREMIUM" || userData.serviceLevel === "STANDARD" /*|| userData.serviceLevel === "EPS"*/){
          //3. 구독 //유료구독 상태
          setUserClassfication(3);
        }
      }
    }
  }, [freeTrialData, myPaymentListData, couponData])

  useEffect(()=>{
    let arr = [];
    switch (userClassfication) {
      case 2:
      case 3:
        arr=[{
          category: getCategory("SURVEY"),
          categoryText: "SURVEY",
          text: "Member Survey – We Need Your Help🙌",
          url: "https://up7wigmn2qc.typeform.com/to/EIIM2HBH",
          isExternalLink: true,
          isBlank: true,
        }, {
          category: getCategory(t(`RollBanner.userType${userClassfication}.category`)),
          categoryText: t(`RollBanner.userType${userClassfication}.category`),
          text: t(`RollBanner.userType${userClassfication}.text`, {userName: userData.fullName}),
          url: getURL(t(`RollBanner.userType${userClassfication}.url`)).url,
          isExternalLink: getURL(t(`RollBanner.userType${userClassfication}.url`)).isExternalLink,
          isBlank: getURL(t(`RollBanner.userType${userClassfication}.url`)).isBlank,
        }]
        break;
      case 1:
      case 4:
      case 5:
        arr = [{
          category: getCategory(t(`RollBanner.userType${userClassfication}.category`)),
          categoryText: t(`RollBanner.userType${userClassfication}.category`),
          text: t(`RollBanner.userType${userClassfication}.text`, {userName: userData.fullName}),
          url: getURL(t(`RollBanner.userType${userClassfication}.url`)).url,
          isExternalLink: getURL(t(`RollBanner.userType${userClassfication}.url`)).isExternalLink,
          isBlank: getURL(t(`RollBanner.userType${userClassfication}.url`)).isBlank,
        }];
        break;
      default:
        break;
    }
    setUserBannerData(arr);
  }, [userClassfication]);

  useEffect(()=>{
    if(rollingBannerData && rollingBannerData.rollingBannerList){
      if(rollingBannerData.rollingBannerList.length > 0){
        let arr = [];
        let lang = getLanguageCode(language);
        rollingBannerData.rollingBannerList.map((data)=>{
          arr = [...arr, {
            category: getCategory(data.bannerGubun),
            categoryText: data.bannerGubun,
            text: data[`title${lang}`],
            url: getURL(data[`url${lang}`]).url,
            isExternalLink: getURL(data[`url${lang}`]).isExternalLink,
            isBlank: getURL(data[`url${lang}`]).isBlank,
          }]
        });
        setAdminBannerData(arr);
      }
    }
  }, [rollingBannerData]);

  useEffect(()=>{
    if(userBannerData && adminBannerData){
      setTotalBannerList(userBannerData.concat(adminBannerData));
    }
  }, [userBannerData, adminBannerData]);

  // useEffect(()=>{
  //   console.log('합쳐버리기', totalBannerList)
  // }, [totalBannerList])

  const swiperProps = React.useMemo(()=>{
    return {
      loop: totalBannerList && totalBannerList.length > 1 ? true : false,
      autoplay: totalBannerList && totalBannerList.length > 1 ? {
        delay: 2000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      } : false,
      watchOverflow: true,
    }
  }, [totalBannerList]);

  const onClickURL = (e, url, isExternalLink, isBlank) => {
    if(isBlank){
      e.preventDefault(); //Link to 기능 무효화
      openLink(isExternalLink? url : API_HOST + url, '_blank');
    }
  }

  const handleMouseEnter = () => {
    swiperRef?.current?.swiper?.autoplay?.stop()
  };
  const handleMouseLeave = () => {
    swiperRef?.current?.swiper?.autoplay?.start()
  };

  return (
    <>
    {totalBannerList && totalBannerList.length > 0 &&
      <Styled.RollBanner>
        <ContentsWrapper>
          <Styled.SwiperWrap>
            <Swiper
              ref={swiperRef}
              slidesPerView={1}
              spaceBetween={0}
              observer={true}
              updateOnWindowResize={true}
              // autoHeight={true}
              speed={300}
              {...swiperProps}
              pagination={{
                type: "fraction",
                el: `.fraction`,
              }}
              navigation={{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }}
              onResize={(swiper)=>setUpdateSwiper((state)=>state+1)}
              onInit={(swiper)=>setUpdateSwiper((state)=>state+1)}
              modules={[Autoplay, Pagination, Navigation]}
            >
              {/*
                [카테고리별 클래스]
                - NOTICE -> notice
                - EVENT -> event
                - GUIDE -> guide
                - NEWS -> news
                - TALK -> talk
                - 기타 (*관리자 입력) -> etc
              */}
              {totalBannerList.map((data, index)=>{
                return (
                  <SwiperSlide key={"banner"+index}>
                    <Styled.Link to={data.url}
                      onClick={(e)=>onClickURL(e, data.url, data.isExternalLink, data.isBlank)}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <Styled.Banner>
                          <Styled.Category className={data.category.toLowerCase()}>{data.categoryText}</Styled.Category>
                          <Styled.Text dangerouslySetInnerHTML={{__html: data.text}}></Styled.Text>
                      </Styled.Banner>
                    </Styled.Link>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <Styled.SwiperControl>
              <Styled.Fraction className='fraction'></Styled.Fraction>
              <Styled.Navigation className={`swiper-button-prev`}>
                <span className='blind'>prev</span>
              </Styled.Navigation>
              <Styled.Navigation className={`swiper-button-next`}>
                <span className='blind'>next</span>
              </Styled.Navigation>
            </Styled.SwiperControl>
          </Styled.SwiperWrap>
        </ContentsWrapper>
      </Styled.RollBanner>
    }
    </>
  );
};

const getLanguageCode = (lang) => {
  switch(lang){
    case languageCode.KOREAN.locale:
      return "Ko";
    case languageCode.ENGLISH.locale:
    default:
      return "En";
    case languageCode.CHINESE.locale:
      return "Cn";
    case languageCode.JAPANESE.locale:
      return "Jp";
  }
};

const getCategory = (category) => {
  switch(category){
    case "NOTICE":
    case "EVENT":
    case "GUIDE":
    case "NEWS":
    case "TALK":
      return category;
    default:
      return "ETC";
  }
}

const getURL = (url) => {
  const hostReg = /mastertopik.com|localhost/gi;
  const urlReg = new RegExp(/^(([^:\/?#]+):)?(\/\/([^\/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/, 'i');
  /*
    urlReg.exec('https://www.mastertopik.com/theme/4?param=123#Related')

    $0: "https://www.mastertopik.com/theme/4?param=123#Related"
    $1: "https:"
    $2: "https"
    $3: "//www.mastertopik.com"
    $4: "www.mastertopik.com"
    $5: "/theme/4"
    $6: "?param=123"
    $7: "param=123"
    $8: "#Related"
    $9: "Related"
  */

  let link = "/";
  let isExternalLink = true;
  let isBlank = true;
  const urlArr = urlReg.exec(url);
  if(urlArr && urlArr[4] && urlArr[4].match(hostReg)){
    link = urlArr[5];
    isExternalLink = false;
    isBlank = false;
  } else {
    link = url;
    isExternalLink = true;
    isBlank = true;
  }

  return {
    url: link,
    isExternalLink: isExternalLink,
    isBlank: isBlank,
  }
}

export default RollBanner;
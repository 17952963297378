import React, { useCallback, useEffect, useState } from 'react';
import StyledActivityInsight, {
  StyledCurriculumProgress as Styled,
} from '../styled/ActivityInsight.style';
import { SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { useTranslation } from 'react-i18next';
import { FiPlus } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import curriculumApi from 'api/curriculum';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { useAxios } from 'common/hook/useAxiosHook';
import { toast } from 'react-toastify';

SwiperCore.use([Navigation]);

const swiperBreakPoint = {
  1025: { slidesPerView: 10.5, slidesPerGroup: 5 },
  0: { slidesPerView: 6.5, slidesPerGroup: 3 },
};

export default function CurriculumProgress({ data, isMyCurriculum, setIsMyCurriculum }) {
  const token = useSelector((state) => state.user.token);
  const { curriculumId } = useParams();

  const [maxDays, setMaxDays] = useState(0);
  const colorList = ['#00acb2', '#80aa00', '#8b56e3', '#586eed']

  const [fetchMyCurriculum, refreshMyCurriculum] = useAxios(
    () => curriculumApi.getMyCurriculum(token),
    [],
    false
  );
  const { data: myCurriculumData } = fetchMyCurriculum;

  const [setMyCurriculumResponse, refreshSetMyCurriculum] = useAxios(
    () => curriculumApi.setMyCurriculum(token, curriculumId),
    [curriculumId],
    true
  );

  const [cancelResponse, cancelMyCurriculum] = useAxios(
    () => curriculumApi.cancelMyCurriculum(token),
    [],
    true
  );

  const clickSetCurriculum = useCallback(
    debounce((cancel) => {
      if (cancel) {
        cancelMyCurriculum();
      } else {
        refreshSetMyCurriculum();
      }
    }, 500)
    , []
  );

  useEffect(() => {
    if (data) {
      var max = 0;
      data.curriculumCourseResponses.forEach((item) => {
        if (max < item.classCount) {
          max = item.classCount;
        }
      });
      setMaxDays(max);
    }
  }, [data])

  useEffect(() => {
    if (myCurriculumData) {
      setIsMyCurriculum(`${myCurriculumData.curriculumChargeId}` === curriculumId);
    }
  }, [myCurriculumData])

  useEffect(() => {
    if (setMyCurriculumResponse.data && setMyCurriculumResponse.data.status === 201) {
      refreshMyCurriculum();
      toast.success('curriculum has been set');
    } else if (setMyCurriculumResponse.error) {
      toast.error(setMyCurriculumResponse.error.data.description);
    }
  }, [setMyCurriculumResponse])


  useEffect(() => {
    if (cancelResponse.data && cancelResponse.data.status === 200) {
      refreshMyCurriculum();
      toast.success('curriculum has been canceled');
    } else if (cancelResponse.error) {
      toast.error(cancelResponse.error.data.description);
    }
  }, [cancelResponse])

  const progressLineData = Array.from({ length: 17 }, (_, index) => index + 1);

  return (
    <>
      <Styled.Wrapper className='curriculum'>
        <Link to='/classroom/curriculum' className='curriculumLink'>
          <FiPlus size='2.3em' />
        </Link>
        <StyledActivityInsight.H3 className='mycurri-progress-h3'>
          Course Lists
        </StyledActivityInsight.H3>
        {data && (
          <>
            <Styled.Box className='mobile-hidden'>
              <Styled.Title.Wrapper>
                <Styled.Title.Top>Title</Styled.Title.Top>
                <Styled.Title.List>
                  {data.curriculumCourseResponses.map((item) => (
                    <Styled.Title.TextWrap>
                      <Styled.Title.Item key={item.id} href={`/courses/detail/${item.id}`}>
                        {item.title}
                      </Styled.Title.Item>
                      <Styled.Title.Info>{item.subjectTypeValue} | {(item.minute > 0) ? item.hour + 1 : item.hour} Hours | {item.classCount} Lessons</Styled.Title.Info>
                    </Styled.Title.TextWrap>
                  ))}
                </Styled.Title.List>
              </Styled.Title.Wrapper>
              <Styled.Timeline.progressPcWrap>
                <Styled.Timeline.progressPcTitle>Title</Styled.Timeline.progressPcTitle>
                <Styled.Timeline.progressPcList>
                  <Styled.Timeline.progressLineWrap>
                    {progressLineData.map((_, index) => (
                      <Styled.Timeline.progressLine key={index} width={`${100 / progressLineData.length}`}></Styled.Timeline.progressLine>
                    ))}
                  </Styled.Timeline.progressLineWrap>
                  {
                    data.curriculumCourseResponses.map((item, index) => (
                      <Styled.Timeline.progressPc className={`progress-wrap progress-wrap${index % 4 + 1}`}>
                        <Styled.Timeline.ProgressPcBar percentage={item.classCount? (item.classCount * 100)/maxDays : 0} className='progress-bar'>
                          {item.classCount && <><span>{item.classCount} </span> Days</>}
                        </Styled.Timeline.ProgressPcBar>
                      </Styled.Timeline.progressPc>
                    ))
                  }



                </Styled.Timeline.progressPcList>

              </Styled.Timeline.progressPcWrap>
              {/* <Styled.Timeline.SwiperWrap>
              <Styled.Timeline.SwiperTopText>Expected Duration</Styled.Timeline.SwiperTopText>
              <Styled.Timeline.Swiper
                breakpoints={swiperBreakPoint}
                slidesPerGroup={6}
                navigation
              >
                {weekArray &&
                  weekArray.map((week, index) => (
                    <SwiperSlide
                      key={index}
                      className={week === data.userNowWeek ? 'current-week' : ''}
                    >
                      <Styled.Timeline.List>
                        {data.curriculumCourseResponses.map(
                          ({ beginWeek, endWeek }, subIndex) => (
                            <>
                            <Styled.Timeline.Item
                              key={subIndex}
                              // week={beginWeek === week && endWeek - beginWeek + 1}
                              week={beginWeek === week && endWeek - beginWeek + 1}
                            >
                              {beginWeek === week && <Styled.Timeline.SlideInfo><span>20</span> Days</Styled.Timeline.SlideInfo>}
                            </Styled.Timeline.Item>

                            </>
                          )
                        )}
                      </Styled.Timeline.List>

                    </SwiperSlide>
                  ))}
              </Styled.Timeline.Swiper>
            </Styled.Timeline.SwiperWrap> */}
            </Styled.Box>
            <Styled.Timeline.Mobile

            >
              {data.curriculumCourseResponses.map((item, index) => (
                <div className='progress-wrap'>
                  <Styled.Title.TextWrap>
                    <Styled.Title.Item key={item.id} href={`/courses/detail/${item.id}`} className='mobile-title'>
                      {item.title}
                    </Styled.Title.Item>
                    <Styled.Title.Info className='mobile-info'>{item.subjectTypeValue} | {(item.minute > 0) ? item.hour + 1 : item.hour} Hours | {item.classCount} Lessons</Styled.Title.Info>
                  </Styled.Title.TextWrap>
                  <Styled.Timeline.Progress>
                    <Styled.Timeline.ProgressBar percentage={(item.classCount * 100)/maxDays} className='progress-bar' style={{backgroundColor : colorList[index % 4]}}><span>{item.classCount} </span> Days</Styled.Timeline.ProgressBar>
                  </Styled.Timeline.Progress>
                </div>
              ))}

            </Styled.Timeline.Mobile>
          </>
        )}




      </Styled.Wrapper>
      <Styled.Title.Button onClick={() => clickSetCurriculum(isMyCurriculum)}>{isMyCurriculum ? "Cancel Curriculum" : "Set My Curriculum"}</Styled.Title.Button>
    </>
  );
}




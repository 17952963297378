import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';

const ArrowLink = styled(Link).attrs((props) => ({
  children: (
    <>
      {props.children}
      <FiArrowRight />
    </>
  ),
}))`
  display: inline-block;
  font-size: 2rem;
  font-weight: 500;
  color: ${({ primary }) => (primary ? '#ffb500' : '#FFF')};
  svg {
    margin-left: 0.5em;
    vertical-align: -0.1em;
  }
  @media (max-width: 767px) {
    font-size: 2.4rem;
  }
`;

export default ArrowLink;

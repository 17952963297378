import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import VideoProgressCircle from 'component/atoms/VideoProgressCircle';
import StyledUtil from 'common/styled/Util.style';

const H4 = styled.h4`
  font-size: 2.6em;
  margin-bottom: 0.6em;
  font-weight: 500;
`;

const List = styled.ul`
  margin-bottom: 8em;
  @media (max-width: 1024px) {
    margin-bottom: 5em;
  }
`;

const ListItem = styled.li`
  font-size: 1.6em;
  padding: 1.125em 1.5em;
  margin-bottom: 1rem;
  border-radius: 0.6rem;
  background-color: #434750;
  font-weight: 400;
  color: #c9cbd1;
  line-height: 1.5em;
  cursor: pointer;
  .progress-circle {
    margin-right: 1em;
  }
`;
const Title = styled.span`
  ${StyledUtil.textEllipsis}
  display: inline-block;
  max-width: 75%;
  vertical-align: middle;
  @media (max-width: 1024px) {
    font-size: 1.2em;
  }
`;

const LessonList = ({ item, currentVideoState, videoRef, t }) => {
  const { id, lessons, firstName, lastName } = item;

  const [currentVideo, setCurrentVideo] = currentVideoState;
  const [previousVideoUrl, setPreviousVideoUrl] = useState(currentVideo[id]);
  const [playProgress, setPlayProgress] = useState(0);

  useEffect(() => {
    const getPercentage = setInterval(() => {
      let percentage =
        (videoRef.current.getCurrentTime() / videoRef.current.getDuration()) *
        100;
      setPlayProgress(percentage);
    }, 1000);
    return () => clearInterval(getPercentage);
  }, []);

  const handleVideo = (movUrl) => () => {
    //console.log("#####" + movUrl);
    movUrl !== currentVideo[id] &&
      setCurrentVideo({ ...currentVideo, [id]: movUrl });
  };

  useEffect(() => {
    if (currentVideo[id] !== previousVideoUrl) {
      //videoRef.current.playing;
      setPreviousVideoUrl(currentVideo[id]);
      setPlayProgress(0);
    }
  }, [currentVideo]);

  return (
    <>
      <H4>
        {firstName} {lastName}{t('representativeLecture.title')}
      </H4>

      <List>
        {lessons.map((lesson, index) => {
          const isCurrentLesson = lesson.movUrl === currentVideo[id];
          return (
            <ListItem key={index} onClick={handleVideo(lesson.movUrl)}>
              <VideoProgressCircle
                isPlay={isCurrentLesson}
                percent={isCurrentLesson ? playProgress : 0}
              />
              <Title>{lesson.title}</Title>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default LessonList;
import React from 'react';

export const termsOfServiceJa0915 = () => (
  <div class='policy'>
    <h3 class='policy-title'>サービス利用約款 </h3>

    <dl class='policy-subject'>
      <dt>- 目次 -</dt>
      <dd>
        <dl>
          <dt>第1章 総則</dt>
          <dd>第1条 [目的]</dd>
          <dd>第2条 [用語の定義]</dd>
          <dd>第3条 [会社情報などの提供]</dd>
          <dd>第4条 [約款の掲示など]</dd>
          <dd>第5条 [約款の改定など]</dd>
          <dd>第6条 [約款の解釈]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>第2章　会員登録</dt>
          <dd>第7条 [会員登録]</dd>
          <dd>第8条 [会員情報の変更]</dd>
          <dd>第9条 [会員のID及びパスワードの管理に対する義務]</dd>
          <dd>第10条 [会員又は利用者に対する通知]</dd>
          <dd>第11条 [会員退会及び資格喪失など]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>第3章 サービス利用契約</dt>
          <dd>第12条 [コンテンツの内容などの掲示]</dd>
          <dd>第13条 [利用契約の成立など]</dd>
          <dd>第14条 [自動決済及び解約]</dd>
          <dd>第15条 [教材などの販売及び配送]</dd>
          <dd>第16条 [会社の義務]</dd>
          <dd>第17条 [会員の義務]</dd>
          <dd>第18条 [コンテンツ利用時の支払い方法]</dd>
          <dd>第19条 [サービスの提供及び中断]</dd>
          <dd>第20条 [コンテンツサービスの変更]</dd>
          <dd>第21条 [情報の提供及び広告の掲載]</dd>
          <dd>第22条 [会員の掲示物及び削除]</dd>
          <dd>第23条 [著作権などの帰属]</dd>
          <dd>第24条 [個人情報保護]</dd>
          <dd>第25条 [韓国外の利用者に適用される特別条項]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>第4章 サービス利用契約の変更、取り消し、払い戻し</dt>
          <dd>第26条 [会員のサービス利用契約の変更/解約/解除]</dd>
          <dd>第27条 [会員の契約取り消し、払い戻しポリシー]</dd>
          <dd>第28条 [過誤納金の還付]</dd>
          <dd>第29条 [会社の契約解除/解約及び利用制限]</dd>
          <dd>第30条 [サービスの瑕疵などに対する被害補償]</dd>
        </dl>
      </dd>
      <dd>
        <dl>
          <dt>第5章 その他</dt>
          <dd></dd>
          <dd>第31条 [免責条項]</dd>
          <dd>第32条 [紛争の解決]</dd>
          <dd>第33条 [準拠法及び裁判管轄]</dd>
          <dd>[附則]</dd>
        </dl>
      </dd>
    </dl>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第1章 総則</h4>
      <p>
        こんにちは。masterTOPIKは、インターネットを通じて会員に様々な韓国語教育コンテンツを提供するカスタマイズ型購読サービスです。
      </p>

      <p class='policy-tit'>第1条 [目的]</p>
      <p>
        この約款は、(株)VISANG
        EDUCATION(以下、「会社」という)が提供する教育コンテンツ及びインターネット関連サービス(以下、「サービス」という)を利用するにあたり、会員の権利と義務、責任及びその他必要な事項などを規定することを目的とします。masterTOPIK(www.mastertopik.com)(以下、「masterTOPIK」という)では、会員のID及びパスワードで該当サービスを利用することができます。
      </p>

      <p class='policy-tit'>第2条 [用語の定義]</p>
      <p>
        この約款で使用する用語の定義は次のとおりです。この約款に明示されていない用語は、関連法令の定めるところによるものとし、その他には一般的な商取引慣行によります。
      </p>

      <ol class='policy-num'>
        <li>1. 会社: 「(株)VISANG EDUCATION」をいいます。</li>
        <li>
          2. サイト:
          会社がサービスを提供するために運営するサイトで、「mastertopik.com」をいいます。
        </li>
        <li>
          3. アプリ:
          スマートフォン、タブレットなど携帯用端末に設置され、会社が提供するサービスを実現するアプリケーションをいいます。
        </li>
        <li>
          4. 利用者:
          サイト及びアプリにアクセスし、この約款に基づき、会社が提供するサービスを受ける会員及び非会員をいいます。{' '}
        </li>
        <li>
          5. 会員:
          会社に個人情報を提供して会員登録を行った者で、この約款に基づき、会社とサービス利用契約を締結した者をいいます。
        </li>
        <li>
          6. 非会員:
          会員ではないが、会社が提供するサービスを利用する者をいいます。
        </li>
        <li>
          7. アイディー(ID):
          会員の識別とサービス利用のために会員が選定し、会社が承認した文字と数字の組み合わせ又は電子メールをいいます。
        </li>
        <li>
          8. パスワード(Password):
          会員の同一性確認と会員の権益及び秘密保護のために、会員自らが設定して会社に登録した英文や数字
        </li>
        <li>
          9. コンテンツ:
          情報通信網の利用促進及び情報保護等に関する法律第2条第1項第1号の規定による情報通信網で使用される符号、文字、音声、音響、画像又は映像などで表現された資料又は情報のことで、その保存と利用において効用を高められるように電子的な形で製作又は処理されたものをいいます。
        </li>
        <li>10. メンバーシップ: コンテンツの定期購読サービスをいいます。</li>
        <li>
          11. 無料サービス:
          サービスを利用するために、代金を支払わずに利用できるサービスをいいます。
        </li>
        <li>
          12. 有料サービス:
          サービスを利用するために、代金を支払った後に利用できるサービスをいいます。
        </li>
        <li>
          13. 決済:
          会員が有料サービスを利用するために、この約款の第18条各号で定める各種支払い手段を通じて会社が定めた一定の金額を会社に支払うことをいいます。
        </li>
        <li>
          14. 解約:
          会社又は会員がサービスの開通後に利用契約を解約することをいいます。
        </li>
        <li>
          15. 運営者(管理者):
          サービスの全般的な管理と円滑な運営のために、会社が選定した人又は機関(会社)をいいます。
        </li>
        <li>
          16. 掲示物:
          会員がサービスを利用するにあたり、会社のサイトに掲示した文字、符号、音響、画像、動画などの情報形態の文章、写真、動画及び各種ファイルとリンク、コメントなどの情報をいいます。
        </li>
      </ol>

      <h4 class='policy-tit'>第3条 [会社情報などの提供]</h4>
      <p>
        会社はこの約款の内容、商号、代表者の氏名、営業所所在地の住所(消費者の苦情を処理できる場所の住所を含む)、電話番号、模写電送番号、電子メールアドレス、事業者登録番号、通信販売業申告番号及び個人情報管理責任者などを、「利用者」が容易に分かるようにオンラインサービスの初期画面に掲示します。ただし、約款の内容は利用者がリンク画面を通じて見られるようにすることができます。
      </p>

      <h4 class='policy-tit'>第4条 [約款の掲示など]</h4>
      <p>
        会社は、会員がこの約款の全部を印刷することができ、取引過程において該当約款の内容を確認できるように技術的な措置をとります。
      </p>

      <h4 class='policy-tit'>第5条 [約款の改定など]</h4>
      <ol class='policy-num'>
        <li>
          1.
          「会社」は、コンテンツ産業振興法、電子商取引等における消費者保護に関する法律、約款の規制に関する法律、電子文書及び電子取引基本法、電子金融取引法、電子署名法、情報通信網の利用促進及び情報保護等に関する法律、訪問販売等に関する法律、消費者基本法など関連法に違反しない範囲においてこの約款を改定することができます。
        </li>
        <li>
          2.
          「会社」が約款を改定する場合には、適用日及び改定事由を明示し、現行約款とともにサービスの初期画面にその適用日の10日前に掲示したり、その他の方法を通じて提供し、既存会員には適用日及び変更される内容のうち、重要事項について電子メールアドレスに送信します。ただし、会員にとって不利に約款内容を変更する場合は、適用日及び変更事由を明示し、現行約款とともにその改定約款の適用日の30日前から適用日の前日まで公知し、公知のほかに電子メールアドレスなどの電子的手段を介して個別に明確に通知するようにします。
        </li>
        <li>
          3.
          会員が改定約款の適用に同意しない場合、会員はサービス利用を中断し、会員退会をすることができます。
        </li>
        <li>
          4.
          既存会員が同条2項による告知又は通知にもかかわらず、改定約款の適用日まで異議を申し立てない場合には、変更された約款に同意したものとみなされます。
        </li>
      </ol>

      <h4 class='policy-tit'>第6条 [約款の解釈]</h4>
      <p>
        この約款で定めていない事項とこの約款の解釈に関しては、コンテンツ産業振興法、電子商取引等における消費者保護に関する法律、約款の規制に関する法律、デジタルコンテンツ利用者保護指針、消費者保護指針及びその他の関係法令又は商慣習に従います。
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第2章　会員登録</h4>

      <p class='policy-tit'>第7条 [会員登録]</p>
      <ol class='policy-num'>
        <li>
          1.
          会員登録は14歳以上の利用者のみ可能であり、利用者が約款の内容に同意し、会社が提供する所定の申込様式に関連事項を記載して会員登録を申し込んだ後、会社がこの申し込みに対して承諾することによって締結されます。
        </li>
        <li>
          2.
          前項の申込様式に利用者が記載するすべての情報は実際のデータとみなされ、実名や実際の情報を入力しない利用者は法的な保護を受けることができず、サービス利用が制限されることがあります。
        </li>
        <li>
          3.
          会社は本条第1項の利用者の申し込みに対し、会員登録を承諾することを原則とします。ただし、会社は次の各号に該当する申し込みについては承諾をしないか、又は留保することができ、承諾した後にも取り消すことができます。
          <ol class='policy-num'>
            <li>① 利用者の年齢が満14歳未満の場合</li>
            <li>
              ② 登録申込者がこの約款により以前会員資格を失ったことがある場合
            </li>
            <li>③ 登録申込者が3ヶ月以内に会員退会をしたことがある場合</li>
            <li>④ 実名ではないか、他人の名義を利用した場合</li>
            <li>
              ⑤ 虚偽の情報を記載し、又は会社が提示する内容を記載しない場合
            </li>
            <li>
              ⑥
              利用者の責に帰すべき事由により承認が不可能だったり、その他諸事項を違反して申し込む場合
            </li>
            <li>⑦ 本約款の第17条の会員の義務に違反した場合</li>
            <li>
              ⑧
              会員登録申し込み時に記載して会社に提供された事項(ID、パスワードなど)が善良な風俗、その他社会秩序に反したり他人を侮辱する場合
            </li>
            <li>
              ⑨
              悪性プログラム及びバグを利用したり、システムの脆弱性を悪用するなど不正な方法をサービスに使用した場合
            </li>
            <li>⑩ その他会社が定めた利用申込要件が不備である場合</li>
          </ol>
        </li>
        <li>
          4.
          会社はサービス関連設備の余裕がなかったり、技術上又は業務上の問題がある場合には、承諾を留保することができます。
        </li>
        <li>
          5.
          第3項と第4項に基づき、会員登録申し込みの承諾をしないか、留保する場合、会社はこれを申込者に知らせなければなりません。ただし、会社の責に帰すべき事由なく申込者に通知できない場合には例外とします。
        </li>
      </ol>

      <p class='policy-tit'>第8条 [会員情報の変更]</p>
      <ol class='policy-num'>
        <li>
          1.
          会員は会員情報管理画面などを通じてパスワードを正確に入力すれば、いつでも自分の個人情報を閲覧し、修正することができます。
        </li>
        <li>
          2.
          会員は会員登録申し込み時に記載した事項が変更された場合、オンラインで修正したり、電子メールやその他の方法により会社にその変更事項を知らせなければなりません。
        </li>
        <li>
          3.
          会員が会員登録申し込み時に記載した事項が変更されたにもかかわらず、その変更事項を「会社」に知らせなかったため発生した不利益について、会社は責任を負いません。
        </li>
      </ol>

      <p class='policy-tit'>第9条 [会員のID及びパスワードの管理に対する義務]</p>
      <ol class='policy-num'>
        <li>
          1.
          会員のIDとパスワードに関する管理責任は会員にあり、これを第三者が利用するようにしてはなりません。
        </li>
        <li>
          2.
          会員はID及びパスワードが盗用されたり、第三者が使用していることを認知した場合には、これを直ちに会社に通知しなければなりません。
        </li>
        <li>
          3.
          会社は第2項の場合、会員の個人情報保護及びその他不正利用行為などを防止するために、会員に対してパスワードの変更など必要な措置を要求することができ、会員が会社の要求に従わなかったため発生した不利益について、会社は責任を負いません。
        </li>
        <li>
          4.
          会社は会員が複数のIDを生成した場合、会員にこれを告知し、会員が選択する代表ID以外に他のIDを削除することができます。
        </li>
      </ol>

      <p class='policy-tit'>第10条 [会員又は利用者に対する通知]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社は会員又は利用者に知らせるべき事項が発生する場合、会員が提供した電子メールアドレス又はお知らせ、ポップアップウィンドウ、有線、無線などの方法により通知することができます。
        </li>
        <li>
          2.
          会社は会員又は利用者全体に対する通知の場合、7日以上会社のウェブサイトの掲示板に掲示することで、前項の通知に代えることができます。ただし、会員本人の有料サービス利用など取引に関する重大な事項については第1項により通知します。
        </li>
      </ol>

      <p class='policy-tit'>第11条 [会員退会及び資格喪失など]</p>
      <ol class='policy-num'>
        <li>
          1.
          会員が会員退会をしようとする場合、会社の顧客センターにオンラインで退会を要請することができ、この場合、会社は遅滞なく会員退会の処理を行い、その事実を会員に通知します。
        </li>
        <li>
          2.
          会員は会員退会の際、以前購入又はダウンロードしたコンテンツ、作成した掲示物などを利用することができません。これに対し、会社が保存方法及び削除を案内したにもかかわらず、会員が個人的に保存するなど措置をとらなかった場合、会社は責任を負いません。
        </li>
        <li>
          3.
          会社は会員が本約款又は関係法令を違反する場合、サービス利用を制限したり、会員資格を喪失させることができます。
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第3章 サービス利用契約</h4>

      <p class='policy-tit'>第12条 [コンテンツの内容などの掲示]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社は、次の事項を該当コンテンツの利用初期画面やその包装に利用者が分かりやすく表示します。
          <ol class='policy-num'>
            <li>① コンテンツの名称又は種類</li>
            <li>② コンテンツの制作及び表示年月日</li>
            <li>③ コンテンツ制作者の氏名(法人の場合は法人の名称)</li>
            <li>④ コンテンツの内容、利用方法、利用料その他の利用条件</li>
          </ol>
        </li>
        <li>
          2.
          会社は、コンテンツ別に利用可能な機器及び利用に必要な最小限の技術仕様に関する情報を利用者に提供します。
        </li>
      </ol>

      <p class='policy-tit'>第13条 [利用契約の成立など]</p>
      <ol class='policy-num'>
        <li>
          1.
          会員は、会社が提供する下記又はこれに類似する手順により利用申し込みを行います。会社は、契約締結前に各号の事項に関して利用者が正確に理解し、ミス又は錯誤なく取引できるように情報を提供します。
          <ol class='policy-num'>
            <li>① コンテンツリストの閲覧及び選択</li>
            <li>② コンテンツの詳細情報</li>
            <li>③ 注文商品及び決済金額の確認(払い戻し規定の案内)</li>
            <li>
              ④ コンテンツの利用申し込みに関する確認又は会社の確認に対する同意
            </li>
            <li>⑤ 決済方法の確認及び選択</li>
            <li>⑥ 決済金額の再確認</li>
          </ol>
        </li>
        <li>
          2.
          会社は会員の利用申し込みが次の各号に該当する場合には承諾をしないか、又は承諾を留保することができます。
          <ol class='policy-num'>
            <li>① 実名ではないか、他人の名義を利用した場合</li>
            <li>
              ② 虚偽の情報を記載し、又は会社が提示する内容を記載しない場合
            </li>
            <li>
              ③
              サービス関連設備の余裕がなかったり、技術上又は業務上の問題がある場合
            </li>
            <li>
              ④ 会社が統制しにくい事情により正常なサービスの利用に支障がある場合
            </li>
            <li>⑤ 有料サービスの利用料金を支払っていない場合</li>
            <li>⑥ 有料サービスの申込金額と入金金額が一致しない場合</li>
          </ol>
        </li>
        <li>
          3.
          会社は会員が第1項に基づき、コンテンツ(サービス)利用を申し込む場合、承諾の意思表示として第10条第1項の方法により会員に通知し、承諾の通知が会員に到達した時点で契約が成立したものとみなされます。
        </li>
        <li>
          4.
          会社の承諾の意思表示には会員の利用申し込みに対する確認及びサービス提供の可能有無、利用申し込みの訂正、取り消しなどに関する情報などが含まれます。
        </li>
        <li>
          5.
          本条第3項による会社の承諾の通知後にも、第2項の3号ないし6号の事由が発生する場合、会社はその事由が解消されるまで会員のコンテンツ(サービス)利用を制限することができます。
        </li>
      </ol>

      <p class='policy-tit'>第14条 [自動決済及び解約]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社は、毎月サービス利用契約の自動更新及び利用料金の請求のために、サービス利用期間及びサービス利用期間の終了後に一定期間の間会員の決済関連情報を保有することができます。
        </li>
        <li>
          2.
          会員が自動決済を申し込んだ後、別途の解約申し込みをしない場合、有料サービスの実際の利用有無に関係なく、毎月指定された決済日及び手段により自動請求及び決済が行われます。(最初決済日の翌日の1日目からサービス日数で計算)
        </li>
        <li>
          3.
          自動決済の解約による満了日は自動決済日から1ヶ月です。自動決済の解約時に、会員は定期決済日から1ヶ月間サービスを利用することができます。
        </li>
        <li>
          4.
          他人の決済情報を同意なしに使用する場合、民/刑事上の責任を負うことになり、関係法令によって処罰されることがあります。
        </li>
        <li>
          5.
          決済情報の変更、クレジットカード及び携帯電話の紛失、その他の事由により定期決済が行われない場合、最後の定期決済日より1ヶ月が過ぎた後、サービス利用が自動的に停止されます。
        </li>
        <li>
          6.
          利用料金の未支払いなど会員の責に帰すべき事由による定期決済の中止及びこれに伴うサービスの利用停止による損害について、会社は責任を負いません。
        </li>
      </ol>

      <p class='policy-tit'>第15条 [教材などの販売及び配送]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社の各サイトで教材などの製品を販売する場合、次のとおりサービスを行います。
          <ol class='policy-num'>
            <li>
              ①
              コンテンツサービスに関連して販売される教材は会社のサイトを通じて購入することができます。
            </li>
            <li>
              ②
              教材などの製品は会社のサイトに会員登録を完了した会員に限って販売します。
            </li>
            <li>
              ③
              会社は会員の教材購入申し込みがあった場合、会員に受信確認通知を行います。
            </li>
            <li>
              ④
              会社は会員がオンラインで確認できるように教材の配送過程を提供します。
            </li>
            <li>
              ⑤
              教材は会員の購入決済が完了した時点から7日以内に配送することを原則とし、天災地変、教材の在庫切れなどにより配送が遅れることがあります。
            </li>
          </ol>
        </li>
        <li>
          2.
          会社と教材購入契約を締結した会員は、教材を受領した日から7日以内に申し込みを撤回(注文取り消し)することができます。ただし、会員が教材などを受け取った場合、次の各号の1に該当する場合には返品及び交換することができません。
          <ol class='policy-num'>
            <li>① 会員の責任ある事由により教材などが滅失又は毀損された場合</li>
            <li>
              ② 会員の使用又は一部の消費により教材などの価値が著しく減少した場合
            </li>
          </ol>
        </li>
        <li>
          3.
          本条第2項の申込撤回期間にもかかわらず、受け取った教材を次の各号の事由により返品及び交換しようとする場合、問題点を見つけてから30日あるいは受領日から3ヶ月以内とし、返品、交換費用は会社が負担します。ただし、このとき、既に受け取った教材は会社に返送しなければなりません。
          <ol class='policy-num'>
            <li>
              ①
              配送された教材が注文内容と異なったり、会社が提供した情報と異なる場合
            </li>
            <li>
              ②
              配送された教材に会社の流通、取り扱い中に発生した外観上の瑕疵がある場合
            </li>
            <li>
              ③
              配送された教材が破本(印刷/製本エラーなど教材の制作時に発生したものと推定)である場合
            </li>
          </ol>
        </li>
        <li>
          4.
          本条第3項の各号に該当しない事由により配送された教材などを返品、交換する場合、一切の追加費用は会員が負担しなければなりません。このとき、会員が負担する配送費は教材など購入当時の配送費を基準とし、済州島などの島と山間地域及び海外配送の場合は一般配送業者の配送費を基準とします。
        </li>
        <li>
          5.
          申込撤回などにより教材の払い戻しを行う場合、クーポンを除いた実際の決済金額から決済手数料と送金手数料を差し引いて払い戻されます。
        </li>
      </ol>

      <p class='policy-tit'>第16条 [会社の義務]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社は、法令とこの約款が定める権利の行使及び義務の履行を信義に従い誠実に行わなければなりません。
        </li>
        <li>
          2.
          会社は、会員が安全にコンテンツを利用できるよう、個人情報(信用情報を含む)保護のためにセキュリティシステムを備えなければならず、個人情報取扱方針を公示して遵守します。
        </li>
        <li>
          3.
          会社は、会員がコンテンツ利用及びその代金内訳を随時確認できるように措置をとります。
        </li>
        <li>
          4.
          会社は、コンテンツ利用に関して会員から提起された意見や不満が正当だと認める場合には、それを遅滞なく処理します。会員が提起した意見や不満事項については、掲示板を活用したり電子メールなどを通じてその処理過程及び結果を伝えます。
        </li>
        <li>
          5.
          会社は、この約款で定めた義務違反により利用者が被った損害を賠償します。
        </li>
      </ol>

      <p class='policy-tit'>第17条 [会員の義務]</p>
      <ol class='policy-num'>
        <li>
          1.
          会員は次の行為をしてはならず、会員がこれを違反する場合、会社は期間を定めてサービスの全部又は一部の利用を制限したり、相当の期間催告後に利用契約を解約することができます。ただし、会社は上記の制裁がある場合、一定期間の間会員に疎明できる機会を与え、会員が自分の故意や過失がなかったことを立証した場合、会社はサービス提供の停止期間ほど利用期間を延長します。
          <ol class='policy-num'>
            <li>
              ①
              申し込み又は変更の際、虚偽の内容の記載や他人の情報(IDなどの個人情報)の盗用
            </li>
            <li>
              ②
              サービスを利用して得た情報(コンテンツ)を会社の事前承諾なしに利用者の利用以外の目的で複製したり、それを出版、転送などに使用したり、第三者に提供する行為
            </li>
            <li>
              ③ 会社に掲示された情報(教案、講義映像などのコンテンツ)の変更
            </li>
            <li>
              ④ 会社が禁止した情報(コンピュータプログラムなど)の送信又は掲示
            </li>
            <li>⑤ 会社とその他第三者の著作権など知的財産権に対する侵害</li>
            <li>⑥ 会社及びその他第三者の名誉を傷つけたり業務を妨害する行為</li>
            <li>
              ⑦
              わいせつ又は暴力的な言葉や文章、画像、音響、その他公序良俗に反する情報を会社のサイトに公開又は掲示する行為
            </li>
            <li>
              ⑧
              会社の事前承諾なしにサービスを利用して商品の販売など営業活動を行う行為
            </li>
            <li>
              ⑨
              会社が提供するコンテンツを公共の場及び営利を目的とする営業場、売場などで公衆に対する公開再現用など私的利用の範囲を超えて利用する行為
            </li>
            <li>
              ⑩
              他の利用者のサービス利用を妨害したり、会社の運営陣、職員、関係者を詐称する行為
            </li>
            <li>⑪ その他違法又は不当な行為</li>
          </ol>
        </li>
        <li>
          2.
          会員は関係法令、この約款の規定、利用案内及びコンテンツに関して公知した注意事項、会社が通知する事項などを遵守しなければならず、その他会社の業務を妨害する行為をしてはなりません。
        </li>
        <li>
          3.
          会員は会社の明示的な同意や承諾がない限り、サービスの利用権限、その他利用契約上の地位を他人に譲渡又は贈与することができず、これを担保として提供することもできません。
        </li>
        <li>
          4.
          会員は自分のID及びパスワードを安全に管理しなければならず、管理の怠慢、不正使用によって発生する結果に対する責任は会員にあります。
        </li>
      </ol>

      <p class='policy-tit'>第18条 [コンテンツ利用時の支払い方法]</p>
      <p>
        コンテンツの利用に対する代金の支払い方法は、次の各号のいずれかの方法で行うことができます。
      </p>
      <ol class='policy-num'>
        <li>1. クレジットカード決済</li>
        <li>2. 電子マネーによる決済(Paypal)</li>
      </ol>

      <p class='policy-tit'>第19条 [サービスの提供及び中断]</p>
      <ol class='policy-num'>
        <li>
          1. コンテンツサービスは年中無休で1日24時間提供することを原則とします。
        </li>
        <li>
          2.
          会社はコンピュータなど情報通信設備の保守点検、交換及び故障、通信途絶又は運営上相当な理由がある場合、コンテンツサービスの提供を一時的に中断することができます。この場合、会社は第10条で定める方法により会員に通知します。ただし、会社が事前に通知することができないやむを得ない事由がある場合は、事後に通知することができます。
        </li>
        <li>
          3.
          会社は、会社の責に帰すべき事由で有料(コンテンツ)サービスの提供が一時的に中断されることにより、会員が被った損害について、サービスを使用することができなかった期間ほどサービス期間を延長するなどの方法により賠償します。ただし、第三者の故意又は過失によりサービスが中止されたり障害が発生した場合、会社は責任を負いません。
        </li>
        <li>
          4.
          会社が事業種目の転換、事業の放棄、会社間の統合などの理由によりサービスを提供できなくなる場合、第10条で定める方法により会員に通知し、第27条5項に基づき、払い戻しの措置をとります。
        </li>
        <li>
          5.
          会社はサービスの提供に必要な場合、定期点検を実施することができ、定期点検時間はサービス提供画面に公知したところによります。
        </li>
        <li>
          6.
          会社はサービスを提供するにあたり、必要な場合、利用者に別途のプログラムを設置することができ、会員は希望しない場合、プログラムの設置を拒否することができます。ただし、会員がプログラムの設置を拒否した場合、サービスの利用が制限されることがあります。
        </li>
        <li>
          7.
          会社はプログラムに関連して、利用者のPC機器のデータを収集し利用することができます。
        </li>
        <li>
          8.
          会社は、前項のデータをプログラムの改善や利用者の使用環境に適したサービス又は技術を提供するための目的にのみ利用し、その他の目的には使用しません。
        </li>
        <li>
          9.
          コンテンツを供給する提供業者又は著作権者の要請により、コンテンツの提供が一部制限されることがあります。{' '}
        </li>
      </ol>

      <p class='policy-tit'>第20条 [コンテンツサービスの変更]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社は相当な理由がある場合に運営上、技術上の必要に応じて、提供しているコンテンツサービスを変更することができます。
        </li>
        <li>
          2.
          会社は第1項によりサービスが変更される場合、変更事由及び変更内容を第10条の方法で会員に通知します。
        </li>
        <li>
          3.
          本条第2項の場合、変更内容が重大だったり利用者に不利な変更である場合には、会社が該当コンテンツサービスの提供を受ける利用者に第5条2項の方法により通知し、同意を受けます。このとき、会社は同意を拒否した利用者については、変更前のサービスを提供します。ただし、このようなサービスの提供が不可能な場合は、契約を解約することができます。
        </li>
      </ol>

      <p class='policy-tit'>第21条 [情報の提供及び広告の掲載]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社は会員がサービスの利用中に必要だと認められる様々な情報について、お知らせや電子メールなどの方法で会員に提供することができます。ただし、会員はいつでも電子メールなどを通じて受信を拒否することができます。
        </li>
        <li>
          2.
          第1項の情報を電話及び模写電送機器により転送しようとする場合には、会員から事前に同意を得て転送します。
        </li>
        <li>
          3.
          会社はサービスの提供に関連して、該当サービス画面、会社の各サイト、電子メールなどに広告を掲載することができます。広告が掲載された電子メールなどを受信した会員は受信を拒否することができます。
        </li>
        <li>
          4.
          会社は掲載された広告を通じた広告主の販促活動に会員が参加したり、交信又は取引の結果として発生した損失や損害については責任を負いません。
        </li>
      </ol>

      <p class='policy-tit'>第22条 [会員の掲示物及び削除]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社は会員が登録した掲示物の中で、本約款と情報通信網の利用促進及び情報保護等に関する法律など関係法律を違反した掲示物又は次の各号に該当する場合には、これを直ちに削除することができます。ただし、第10号の場合には当該事項を事前に会社のお知らせを通じて公知します。
          <ol class='policy-num'>
            <li>
              ①
              会社、他の会員又は第三者を誹謗したり、名誉を傷つける内容である場合
            </li>
            <li>② 公共秩序及び公序良俗に違反する内容を流布する場合</li>
            <li>③ 犯罪的行為に結びつかれると認められる内容である場合</li>
            <li>
              ④
              会社の著作権、第三者の著作権など、その他の権利を侵害する内容である場合
            </li>
            <li>
              ⑤ 法令に違反するか、他人の権利を侵害する方式で紛争を引き起こす場合
            </li>
            <li>⑥ 不要又は承認されていない広告、販促物を掲載する場合</li>
            <li>
              ⑦
              他人の個人情報を盗用、詐称して作成した内容や、他人が入力した情報を無断で偽/変造した内容である場合
            </li>
            <li>
              ⑧ 同じ内容を重複して多数掲示するなど、掲示の目的にそぐわない場合
            </li>
            <li>⑨ 会社の掲示板運営指針などに違反すると判断される場合</li>
            <li>⑩ サービスの設備容量に余裕がないと判断される場合</li>
          </ol>
        </li>
        <li>
          2.
          会社が運営する掲示板などに掲示された情報によって法律上利益が侵害された者は、会社に当該情報の削除又は反論の内容の掲載を要請することができます。この場合、会社は遅滞なく必要な措置をとり、これを直ちに申込人に通知します。
        </li>
      </ol>

      <p class='policy-tit'>第23条 [著作権などの帰属]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社が作成した著作物に対する著作権、その他の知的財産権は会社に帰属します。
        </li>
        <li>
          2.
          利用者は会社が提供するサービスを利用することによって得た情報のうち、会社又は提供業者に知的財産権が帰属した情報を会社又は提供業者の事前承諾なしに複製、送信、出版、配布、放送その他の方法によって営利目的で利用したり、第三者に利用させてはなりません。
        </li>
        <li>
          3.
          利用者がサービス内に掲示した掲示物の著作権は、著作権法によって保護されます。会社は、会社の広報のために制限された用途に限って会員の掲示物を活用することができます。ただし、会員が異議を申し立てる場合、使用を中断します。
        </li>
        <li>
          4.
          利用者は自分が掲示した掲示物を会社が国内外において、次の各号の目的で使用することを許諾します。ただし、第2号及び第3号の場合、利用者が異議を申し立てると、会社は異議を申し立てた時点から展示、配布を中断します。
          <ol class='policy-num'>
            <li>
              ①
              サービス内で利用者の掲示物の複製、送信、展示、配布及び優秀掲示物をサービス画面に露出させるために、利用者の掲示物の大きさを変換又は単純化するなどの方式で修正する場合
            </li>
            <li>
              ②
              会社が運営する関連サイトのサービス内で利用者の掲示物を展示、配布する場合
            </li>
            <li>
              ③
              会社のサービスを広報する目的で、メディア、通信会社などに利用者の掲示物の内容を報道、放映するようにする場合
            </li>
          </ol>
        </li>
        <li>
          5.
          第4項の規定にもかかわらず、会社が利用者の掲示物を第4項の各号に記載された目的以外に商業的目的(例:
          第三者に掲示物を提供し、金銭的対価を支払われる場合など)で使用する場合には、事前に該当利用者から同意を得なければなりません。掲示物に対する会社の使用要請、利用者の同意及び同意撤回は電話、電子メール、ファックスなど会社が要請する方式に従います。
        </li>
        <li>
          6.
          会社は会員が退会したり、第17条1項により会員資格を失った場合、別途の同意又は通知手続きなしに会員の掲示物を削除することができます。
        </li>
        <li>
          7.
          会社は会社の合併、営業譲渡、会社が運営するサイト間の統合などの事由により、本来の掲示物の内容を変更せずに掲示物の掲示位置を変更することができます。
        </li>
      </ol>

      <p class='policy-tit'>第24条 [個人情報保護]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社は、情報通信網の利用促進及び情報保護等に関する法律など関係法令が定めるところにより、利用者の個人情報を保護するために努力しています。個人情報の保護及び使用については、関連法令及び会社の個人情報取扱方針が適用されます。
        </li>
        <li>
          2.
          会社が利用者の個人識別が可能な個人情報を収集する際には当該利用者の同意を得て、利用者はいつでも会社が保有する自分の個人情報について閲覧及び誤りの訂正を要求することができ、会社はこれに対して遅滞なく必要な措置をとる義務を負います。
        </li>
        <li>
          3.
          会社は、利用者が利用申し込みなどで提供した情報と第1項によって収集した情報を当該利用者の同意なしに目的以外の利用や第三者に提供することができません。ただし、次の場合には例外とします。
          <ol class='policy-num'>
            <li>
              ①
              統計作成、学術研究又は市場調査のために必要な場合として、特定の個人を識別できない形で提供する場合
            </li>
            <li>② コンテンツの提供による料金精算のために必要な場合</li>
            <li>③ 盗用防止のために本人確認が必要な場合</li>
            <li>
              ④ 約款の規定又は法令によって必要なやむを得ない事由がある場合
            </li>
          </ol>
        </li>
        <li>
          4.
          会社は、個人情報保護のために管理者を限定してその数を最小限に抑え、個人情報の紛失、盗難、流出、変造などによる利用者の損害に対して責任を負います
        </li>
        <li>
          5.
          会社又はそれから個人情報を提供された者は、利用者が同意した範囲内で個人情報を使用することができ、目的が達成された場合には、当該個人情報を遅滞なく破棄します。
        </li>
      </ol>

      <p class='policy-tit'>第25条 [韓国外の利用者に適用される特別条項]</p>
      <p>
        会社は、すべての人に一貫した標準が適用されるグローバルサービスを形成するために努力しており、それと同時に各地域の現地法律を遵守するためにも努力しています。会員は個人データが韓国に送信され、韓国内で処理されることに同意しなければなりません。
      </p>
    </div>
    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        第4章 サービス利用契約の変更、取り消し、払い戻し
      </h4>

      <p class='policy-tit'>第26条 [会員のサービス利用契約の変更]</p>
      <ol class='policy-num'>
        <li>
          1.
          会員が既に購入/登録した有料サービスは、原則として変更処理ができず、解約後の翌月に別の利用権で登録するようになっているが、会社が事前に公知して定める一部の利用権に限って会員が変更を申し込む場合、1ヶ月後に変更予約(以下、「変更予約」という)又は直ちに変更処理(以下、「即時変更」という)を行うことができます。
        </li>
        <li>
          2.
          変更予約の場合、会員が変更を要請した月の利用期間が満了する日まで以前購入/登録した利用権を利用することができ、利用期間満了日の翌日から変更を要請した利用権に変更及び適用されます。
        </li>
        <li>
          3.
          即時変更の場合、会員が即時変更を申し込むと、会社が受付完了後、直ちに以前購入/登録した利用権の利用が中断され、申し込んだ利用権に変更及び適用されます。
        </li>
        <li>
          4.
          会員が既に購入した利用権よりさらに高い価格の利用権に変更を申し込んだ場合、要請してすぐに変更する利用権のサービスを利用することができます。ただし、次回決済日まで変更要請を取り消すことができず、少なくとも1回決済が行われなければ、再び利用権を変更することができません。
        </li>
        <li>
          5.
          会員が既に購入した利用権よりさらに低い価格の利用権に変更を申し込んだ場合、現在利用中の利用権のサービスはそのまま維持され、次回決済日以降に要請した利用権に変更されます。このとき、次回決済日前までに変更要請を取り消すことができます。
        </li>
        <li>
          6.
          すべての利用権の「変更予約」、「即時変更」の場合には、利用権変更の属性上、払い戻しは一切行われません。{' '}
        </li>
      </ol>

      <p class='policy-tit'>第27条 [会員の契約取り消し、払い戻しポリシー]</p>
      <ol class='policy-num'>
        <li>
          1.　会社とサービスの利用に関する契約を締結した会員は、決済承認日から7日以内にのみ申し込みの撤回を行うことができます。ただし、関係法令又は会社が次の各号のいずれかの措置をとった場合には、会員の申込撤回権が制限されることがあります。
        </li>
        <li>
          2. 会員は第1項の申込撤回(取り消し)をしようとする場合、サイト(Settings
          &gt;Subscription)、顧客センターへのお問い合わせなどの方法により、会社にその意思表示をしなければなりません。会社は会員がサービス利用契約の取り消しの意思表示をした場合、これを直ちに受付します。
        </li>
        <li>
          3.　会社は会員の申込撤回、解約/解除の申し込みを確認した後に払い戻し金額がある場合、会員の該当意思表示を受領した日から3営業日以内に決済手段別の事業者に代金の請求停止ないし取り消しを要請し、会員が決済した同じ決済手段で払い戻すことを原則とします。ただし、申込撤回(取り消し)が不可能なコンテンツ(サービス)であることを告知するなど、関係法令(電子商取引法、コンテンツ産業振興法など)に基づく措置をとった場合には、会員の申込撤回権が制限されることがあります。
        </li>
        <li>
          4.　会社は、払い戻しの際に利用者が代金を決済した方法と同じ方法で決済代金の全部又は一部を払い戻します。しかし、同じ方法で払い戻しが不可能な場合は、直ちにこれを利用者に告知し、利用者が選択した方法で払い戻しを行います。ただし、会社が事前に会員に公知した場合及び下記の各場合のとおり個別決済手段別の払い戻し方法、払い戻し可能期間などが異なることがあります。
          <ol class='policy-num'>
            <li>
              ①
              クレジットカードなど収納確認が必要な決済手段の場合には、収納確認日から3営業日以内
            </li>
            <li>
              ②
              決済手段別の事業者が会社との約定を通じて代金請求停止ないし決済取消可能期限などをあらかじめ定めておいた場合として、該当期限を過ぎた払い戻しの場合
            </li>
            <li>
              ③
              会員が有料サービス/利用権を利用した結果、得た利益があったり中途解約する場合
            </li>
            <li>
              ④
              会員が払い戻し処理に必要な情報ないし資料を会社に直ちに提供しない場合
              (現金払い戻しの際に申込人の口座及び身分証の写しを提出しなかったり、他人名義の口座を提供する場合など)
            </li>
            <li>⑤ 該当会員の明示的な意思表示がある場合</li>
          </ol>
        </li>
        <li>
          5.
          会社は、利用権の払い戻しに関して関連法律を遵守します。払い戻しは申込(購入及び決済)日を基準に1ヶ月以内に利用権の取り消し及び即時の払い戻しを要請する場合、利用代金(既利用日数X1日利用代金)を計算し、それを差し引いて払い戻します。
        </li>
        <li>
          6.
          会社が払い戻しを行う場合、会員がサービス利用から得た利益に該当する金額と違約金など、会社が該当事例によって差し引くと明示した金額を差し引いて払い戻すことができます。
        </li>
        <li>
          7.
          会社はコンテンツ利用者保護指針などにより、会社が負担したか、又は負担する付帯費用、手数料を差し引いて払い戻すことができます。
        </li>
        <li>
          8.
          会員が有料サービス又は利用権をプレゼントされたり、プロモーションなどを通じて無料/無償で取得するなど、会員が直接費用を支払わなかったサービスについては、会社は払い戻し義務を負担しません。
        </li>
        <li>
          9.
          会員がこの約款で定める会員の義務を違反した場合、契約を解除又は解約したり、サービス利用制限、損害賠償請求などの措置をとることができ、契約の解約後に払い戻すべき金額がある場合には、一定の範囲内で会員が負担する金額を差し引いて払い戻します。この場合、会員は該当会社の措置に対し、会社が定めた手続きに従って異議を申し立てることができ、会社が正当だと確認した場合、サービスの利用再開などを行うことができます。なお、利用者が自分の故意、過失がなかったことを立証した場合、会社はサービスを停止した期間ほど利用期間を延長します。
        </li>
        <li>
          10.
          会員の申し込み又は同意によって月定期決済を行う有料サービスの場合、該当会員が有料サービスの利用料金を滞納すると、延滞が発生した日に自動的に利用権が解約されることがあるので、月定期決済による特典を維持しようとする会員は、利用料金の滞納又は決済手段の延滞が発生しないよう、事前に措置をとらなければなりません。
        </li>
        <li>
          11.
          月定期決済有料サービスを利用中の会員が退会する場合、該当利用権は直ちに解約されます。会社が会員に払い戻し意思の有無を確認したにもかかわらず、払い戻しを要請せずに自ら退会した場合、会社は該当アカウントの復旧などに対する一切の責任を負担しません。
        </li>
      </ol>

      <p class='policy-tit'>第28条 [過誤納金の還付]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社は過誤納金が発生した場合、利用代金の決済と同じ方法で過誤納金全額を払い戻さなければなりません。ただし、同じ方法で払い戻しが不可能な場合は、これを事前に告知します。
        </li>
        <li>
          2.
          会社の責任ある事由により過誤納金が発生した場合、会社は契約費用、手数料などに関係なく過誤納金全額を払い戻します。ただし、利用者の責任ある事由により過誤納金が発生した場合、会社が過誤納金を払い戻すのにかかる費用は合理的な範囲内で利用者が負担しなければなりません。
        </li>
        <li>
          3.
          会社は利用者が主張する過誤納金について払い戻しを拒否する場合、正当に利用代金が賦課されたことを立証する責任を負います。
        </li>
      </ol>

      <p class='policy-tit'>第29条 [会社の契約解除/解約及び利用制限]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社は、利用者が第13条2項及び第17条1項に定める行為をした場合、事前通知なしに契約を解除/解約するか、又は期間を定めてサービスの利用を制限することができます。
        </li>
        <li>
          2.
          第1項の解除/解約は、会社自らが定めた通知方法によって利用者にその意思表示をしたときに効力が発生します。
        </li>
        <li>
          3.
          利用者の責に帰すべき事由による利用契約の解除/解約の場合、第27条の払い戻し規定を適用しないことがあります。
        </li>
      </ol>

      <p class='policy-tit'>第30条 [サービスの瑕疵などに対する被害補償]</p>
      <ol class='policy-num'>
        <li>
          1.
          会社はサービスの中止/障害が発生した場合、サービスの中止/障害発生時点を基準に、24時間以内に利用者に事前に告知します。ただし、利用者の責任ある事由によりサービスの中止又は障害が発生した場合は、サービスの中止/障害時間に含まれません。
        </li>
        <li>
          2.
          会社がサービスの中止/障害に対して事前に告知した場合、会社は次の各号により利用者の被害を救済します。ただし、サービス改善を目的とした設備の点検及び補修の際、1ヶ月を基準に24時間は中止/障害時間に含まれません。
          <ol class='policy-num'>
            <li>
              ① 1ヶ月を基準にサービスの中止/障害時間が10時間を超える場合:
              10時間とそれを超えた時間の2倍の時間ほど利用期間を無料で延長
            </li>
            <li>
              ② 1ヶ月を基準にサービスの中止/障害時間が10時間を超えない場合:
              中止/障害時間に該当する時間を無料で延長
            </li>
          </ol>
        </li>
        <li>
          3.
          会社がサービスの中止/障害に対して事前に告知しなかった場合、会社は次の各号により利用者の被害を救済します。
          <ol class='policy-num'>
            <li>
              ① 1ヶ月間のサービスの中止/障害発生の累積時間が72時間を超えた場合:
              契約解除又は解約及び未利用期間を含む残余期間に対する利用代金の払い戻し及び損害賠償(ただし、会社が故意又は過失がないことを立証した場合、損害賠償の責任を負わない。)
            </li>
            <li>
              ② 会社の責任ある事由によるサービスの中止又は障害の場合:
              サービスの中止/障害時間の3倍を無料で延長
            </li>
            <li>
              ③
              不可抗力又は第三者による不法行為などによりサービスの中止又は障害が発生した場合:
              契約を解除することはできないが、サービスの中止又は障害時間ほど無料で利用期間を延長
            </li>
          </ol>
        </li>
      </ol>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第5章 その他</h4>

      <p class='policy-tit'>第31条 [免責条項]</p>
      <ol class='policy-num'>
        <li>
          ①
          会社は、天災地変又はこれに準ずる不可抗力によってコンテンツを提供することができない場合には、コンテンツ提供に関する責任が免除されます。
        </li>
        <li>
          ②
          会社は、利用者の責に帰すべき事由によるコンテンツ利用の障害については責任を負いません。
        </li>
        <li>
          ③
          会社は、会員がコンテンツに関連して掲載した情報、資料、事実の信頼度、正確性などの内容に関しては責任を負いません。
        </li>
        <li>
          ④
          会社は、利用者相互間又は利用者と第三者との間にコンテンツを媒介にして発生した紛争などについて責任を負いません。
        </li>
      </ol>

      <p class='policy-tit'>第32条 [紛争の解決]</p>
      <ol class='policy-num'>
        <li>
          ①　会社は、紛争が発生した場合に利用者が提起する正当な意見や不満を反映し、適切かつ迅速な措置をとります。ただし、迅速な処理が困難な場合には、会社は利用者にその事由と処理日程を通知します。
        </li>
        <li>
          ②　紛争が発生した場合、会社又は利用者はコンテンツ産業振興法第28条で定めているコンテンツ紛争調整委員会に紛争調整を申し込むことができます。
        </li>
      </ol>

      <p class='policy-tit'>第33条 [準拠法及び裁判管轄]</p>
      <ol class='policy-num'>
        <li>① 本約款は大韓民国法に基づいて規律され、解釈されます。</li>
        <li>
          ②
          会社と利用者の間に発生した紛争により訴訟を提起する場合には、大韓民国ソウル中央地方裁判所で提起しなければなりません。
        </li>
      </ol>

      <p class='policy-tit'>[附則]</p>
      <p>この約款は、2021年3月22日から施行します。</p>
    </div>
  </div>
);

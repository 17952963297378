import React from 'react';
import Style from './styled/CancelSection.style';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PlanSection = () => {
  let history = useHistory();
  const { t } = useTranslation('cancelPage');

  return (
    <Style.Container>
      <Style.ImageSpan className='plan' />
      <Style.Title>{t('notice2.title')}</Style.Title>
      <Style.Desc>{t('notice2.subtitle')}</Style.Desc>
      <Style.List>
        {t('notice2.detail1')} <span> USD 5.99/month.</span>
      </Style.List>
      <Style.List>
        {t('notice2.detail2')}
        <span>USD 9.99/month.</span>
      </Style.List>
      <Style.List>
        {t('notice2.detail3')} <span>USD 13.99/month.</span>
      </Style.List>
      <Style.Button className='plan' onClick={() => history.push('/plan')}>
        {t('notice2.submit')}
      </Style.Button>
      <Style.Button onClick={() => history.push('/setting')}>
        {t('notice2.goBack')}
      </Style.Button>
    </Style.Container>
  );
};

export default PlanSection;

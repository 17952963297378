const { default: styled } = require('styled-components');

const StyledToggleSwitch = {
  Wrapper: styled.label`
    position: relative;
    display: inline-block;
    width: 5em;
    height: 2.6em;
  `,
  Input: styled.input`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    width: 1px;
    height: 1px;
    &:checked + div {
      background-color: #485cc7;
      box-shadow: inset 1px 1px 2px 0 rgba(53, 68, 147, 0.8);
    }
    &:checked + div:after {
      left: calc(100% - 2em);
      box-shadow: 1px 2px 2px 0 rgba(53, 68, 147, 0.8);
    }
  `,
  Div: styled.div`
    position: relative;
    z-index: 1;
    width: 5em;
    height: 2.6em;
    border-radius: 100px;
    background-color: #ddd;
    box-shadow: inset 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease;
    cursor: pointer;
    &:after {
      content: '';
      position: absolute;
      top: 0.6em;
      left: 0.6em;
      width: 1.4em;
      height: 1.4em;
      border-radius: 100px;
      background-color: #fff;
      box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2);
      transition: all 0.1s ease;
    }
  `,
};

export default StyledToggleSwitch;

import React, { useEffect } from 'react';
import Styled from './index.style';
import { useLocation } from 'react-router-dom';
import NavBottom from 'component/layout/Header/Nav/NavBottom';
import MenuBeforeLogin from 'component/layout/Header/Nav/MenuBeforeLogin';
import MenuAfterLogin from 'component/layout/Header/Nav/MenuAfterLogin';
import { useSelector } from 'react-redux';
import { ModalContext } from 'component/Modal/modalContext';
import Login from 'component/user/Login';
import CreateAccount from 'component/user/CreateAccount';
import arrow_img from "assets/images/gnb_new/arrow.png";
import gift_img from "assets/images/gnb_new/gift.png";
const Nav = ({ isLogin, handleMouseOver, handleMouseOut }) => {
  let { handleModal } = React.useContext(ModalContext);
  const toggleNav = () => {
    document.body.classList.toggle('nav-active');
  };

  const openLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false, 420, location.pathname);
  };

  const changeCreatAccountModal = (e) => {
    e.preventDefault();
    handleModal(<CreateAccount />);
  };

  const location = useLocation();
  useEffect(() => {
    document.body.classList.remove('nav-active');
  }, [location]);

  const isBannerClose = useSelector((state) => state.common.isBannerClose);

  return (
    <>
        <Styled.Button
            onClick={toggleNav}
            className={!isBannerClose && !isLogin && 'nav-with-line-banner'}
          />

        <Styled.Wrapper>

        <Styled.NavHead>
              <Styled.Close onClick={toggleNav} />

          <Styled.UserWrap className='nav-user-log'>
              <button className='user-sign' onClick={changeCreatAccountModal}>SIGN IN</button>
              <button className='user-login' onClick={openLoginModal}>LOG IN</button>
          </Styled.UserWrap>
        </Styled.NavHead>
        <Styled.NewEvent className='nav-free'>
          <Styled.NewEventLink to='/event/FreeTrialTopik'><img src={gift_img} alt="선물"/>Sign up & Enjoy Free 2-week Trial<img src={arrow_img} alt="선물" className='more_arrow'/></Styled.NewEventLink>
        </Styled.NewEvent>
        <Styled.Inner>
          {isLogin ? <MenuAfterLogin isLogin={isLogin} handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOut}/> : <MenuBeforeLogin handleMouseOver={handleMouseOver} handleMouseOut={handleMouseOut} />}
          <NavBottom isLogin={isLogin} />
        </Styled.Inner>
      </Styled.Wrapper>
    </>
  );
};

export default Nav;

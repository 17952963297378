import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const Style = {
  Container: styled.div`
    width: 69%;
    padding: 1em 1.68em;
    margin-right: 2.8em;

    @media (max-width: 1024px) {
      width: 100%;
    }
  `,
  Info: styled.div`
    float: left;
    vertical-align: middle;
    margin-top: 0.7em;
    margin-right: 2.9em;
    color: #c9cbd1;
    font-size: 1.6em;
    font-weight: 400;

    span {
      margin-left: 1em;
      color: #fff;
      font-weight: 500;
    }
  `,
};
const Content = ({ data }) => {
  const { grade, testCount, date } = data;

  const userName = useSelector((state) => state.user.data.fullName);
  return (
    <Style.Container>
      <Style.Info>
        응시자 <span> {userName} </span>
      </Style.Info>
      {testCount ?
        <Style.Info>
          응시정보 <span> 총 {testCount}회 응시</span>
        </Style.Info>
        : undefined}
      <Style.Info>
        등급 <span> {grade}</span>
      </Style.Info>
      <Style.Info>
        응시일 <span>{date}</span>
      </Style.Info>
    </Style.Container>
  );
};

export default Content;

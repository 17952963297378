import React from 'react';
import useModal from 'component/Modal/useModal';
import Modal from 'component/Modal';

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

let ModalProvider = ({ children }) => {
  let {
    modal,
    handleModal,
    modalContent,
    closeByBackground,
    maxWidth,
    redirectUrl,
  } = useModal();
  return (
    <Provider
      value={{
        modal,
        handleModal,
        modalContent,
        closeByBackground,
        maxWidth,
        redirectUrl,
      }}
    >
      {children}
      <Modal />
    </Provider>
  );
};

export { ModalContext, ModalProvider };

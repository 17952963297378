import React, { useMemo } from "react";
import Style from "./styled/SubscriptionSection.style";
import { MOBILE_APP_USER_AGENT, planColors } from "common/constant";
import { useHistory } from "react-router-dom";
import { useAxios } from "common/hook/useAxiosHook";
import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { ModalContext } from "component/Modal/modalContext";
import plansApi from "api/plans";
import CancelCancelModal from "container/SettingPage/CancelCancelModal";

const SubscriptionSection = () => {
  const { t } = useTranslation("settingPage");
  const { handleModal } = React.useContext(ModalContext);

  const showCancelCancelModal = () => {
    handleModal(<CancelCancelModal handleModal={handleModal} />);
  };

  const history = useHistory();
  const token = useSelector((state) => state.user.token);
  const [myPlanData] = useAxios(() => plansApi.getPlan(token), []);
  const { data: planData } = myPlanData;
  const userAgent = window.navigator.userAgent;
  const planCode = planData?.currentPlan ? planData.currentPlan : "FREE";
  const planMonth = useMemo(()=>{
    if(planData){
      const currentPlan = planData.plans.find(plan=>plan.productId === planData.currentPlanProductId);
      return currentPlan.takeCourseTerm;
    }
    return '';
  },[planData])

  const BillingDate = planData?.expireDate
    ? planData.expireDate.slice(0, 10).replace(/-/g, " . ")
    : "";

  const cancelCondition = () => {
    // 현재 상태가 FREE 이거나, 구독취소 철회가 불가능한 경우라면 Continue my plan 버튼을 보여주지 않는다. [2021-11-10 yym]
    if (planCode === "FREE" || planData?.impossibleCancel) {
      return;
    }

    if (
      planCode !== "FREE" &&
      planData?.toBePlan === "FREE" &&
      planData?.isAppPurchased
    ) {
      // 현재 구독상태가 FREE가 아니고 구독 취소를 진행하여 이후 구독상태가 FREE 일때 마지막 결제가 앱결제라면
      return null;
    } else if (
      planCode !== "FREE" &&
      planData?.toBePlan === "FREE" &&
      !planData?.isAppPurchased
    ) {
      // 현재 구독상태가 FREE가 아니고 구독 취소를 진행하여 이후 구독상태가 FREE 일때 마지막 결제가 웹결제라면
      if (userAgent === MOBILE_APP_USER_AGENT) {
        return null;
      } else {
        return (
          <Style.CancelBtn className="continue" onClick={showCancelCancelModal}>
            <Trans t={t}>Continue My Plan</Trans>
          </Style.CancelBtn>
        );
      }
    }

    if (
      planCode !== "FREE" &&
      planData?.toBePlan === null &&
      planData?.isAppPurchased
    ) {
      // 현재 구독상태가 FREE가 아니고 구독 취소를 하지 않은 상태이며 마지막 결제가 앱결제라면 구독 취소버튼 출력
      if (userAgent === MOBILE_APP_USER_AGENT) {
        // 마지막 결제방식이 앱결제라면 앱에서는 구독취소 버튼 출력
        return (
          <Style.CancelBtn to="/setting/cancel">
            <Trans t={t}>subInfo.button</Trans>
          </Style.CancelBtn>
        );
      } else {
        // 마지막 결제방식이 앱결제라면 웹에서는 null 출력
        return null;
      }
    } else if (
      planCode !== "FREE" &&
      planData?.toBePlan === null &&
      !planData?.isAppPurchased
    ) {
      // 현재 구독상태가 FREE가 아니고 구독 취소를 하지 않은 상태이며 마지막 결제가 웹결제라면 구독 취소버튼 출력
      if (userAgent === MOBILE_APP_USER_AGENT) {
        // 마지막 결제방식이 웹결제라면 앱에서는 null 출력
        return null;
      } else {
        // 마지막 결제방식이 웹결제라면 웹에서는 구독취소 버튼 출력
        return (
          <Style.CancelBtn to="/setting/cancel">
            <Trans t={t}>subInfo.button</Trans>
          </Style.CancelBtn>
        );
      }
    } else { // 취소가능이면 취소버튼..


    }
  };

  return (
    <Style.Container>
      <Style.Title>
        <Trans t={t}>subInfo.title</Trans>
      </Style.Title>
      <span className="title">
        <Trans t={t}>subInfo.planLabel</Trans>
        {planData?.isAppPurchased && userAgent === MOBILE_APP_USER_AGENT ? (
          <span className="change" onClick={() => history.push("/plan")}>
            <Trans t={t}>subInfo.changeButton</Trans>
          </span>
        ) : !planData?.isAppPurchased && userAgent !== MOBILE_APP_USER_AGENT ? (
          <span className="change" onClick={() => history.push("/plan")}>
            <Trans t={t}>subInfo.changeButton</Trans>
          </span>
        ) : null}
      </span>
      <div style={{ clear: "both" }}></div>

      <div className="info">
        <Style.Badge color={planColors[planCode].color}>{planCode}{planCode === 'FREE'? '' : `_${planMonth}M`}</Style.Badge>

        {planData && planData?.subscriptionStatusText}
      </div>

      {planData && planData.toBePlan !== "FREE" && (
        <>
          <span className="title">
            <Trans t={t}>subInfo.dateLabel</Trans>
          </span>
          <div className="info">{BillingDate}</div>
        </>
      )}

      {cancelCondition()}
    </Style.Container>
  );
};

export default SubscriptionSection;

import { languageCode } from "common/constant";

export class GetLanguage {
  constructor(value = "") {
    const valueToLowercase = value?.toLowerCase();
    let code = "";
    let locale = "";

    for (const [, lang] of Object.entries(languageCode)) {
      if (
        valueToLowercase === lang.code ||
        valueToLowercase === lang.locale.toLowerCase()
      ) {
        code = lang.code;
        locale = lang.locale;
        break;
      }
    }

    this.code = code;
    this.locale = locale;
  }
}

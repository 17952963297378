import React from 'react';
import { useTranslation } from 'react-i18next';
import Styled from './styled/summaryBanner.style';

import ContentsWrapper from 'component/atoms/ContentsWrapper';

import BgBanner from 'assets/images/audio/bg_jresearch.png';

const SummaryBanner = () => {
  const { t } = useTranslation('jresearchPage');

  return (
    <Styled.Container bgImage={BgBanner}>
      <ContentsWrapper>
        <Styled.H2>{t('title')}</Styled.H2>
        <Styled.P>
          {/* 서브 텍스트 */}
          {t('subText')}
        </Styled.P>
      </ContentsWrapper>
    </Styled.Container>
  );
};

export default SummaryBanner;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import arrowLeft from 'assets/images/arrow2_left_white.svg';
import { useHistory } from 'react-router-dom';

const ArrowLink = styled.button`
  display: inline-block;
  font-size: 1.4em;
  padding-left: 2em;
  background-image: url(${arrowLeft});
  background-repeat: no-repeat;
  background-position: 0.2em center;
  background-size: 1em auto;
  font-weight: 400;
  color: #fff;
  line-height: 2em;
  transition: background-position 0.2s ease;
  &:hover {
    background-position: 0 center;
  }
  @media (max-width: 767px) {
    font-size: 2em;
  }
`;

/**
 * @param {object} param
 * @param {string} param.to
 * @param {string} param.text
 */
const PreviousLink = ({ to, text, className }) => {
  const history = useHistory();
  const goBack = () => {
    if (to) return;
    history.goBack();
  };
  return (
    <div className={className && className}>
      <ArrowLink onClick={goBack}>{text}</ArrowLink>
    </div>
  );
};

PreviousLink.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default PreviousLink;

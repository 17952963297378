import React, { useEffect } from 'react';
import Style from './styled/BookList.style';
import { Link } from 'react-router-dom';

import Img from 'component/atoms/Img';
import { useAxios } from 'common/hook/useAxiosHook';
import BookStoreApi from 'api/bookstore';

const BookList = () => {
  const language = localStorage.getItem('i18nextLng');

  const [{ data: bookList }, fetchBookList] = useAxios(
    BookStoreApi.getBookSoundTrack,
    [],
    true
  );

  useEffect(() => {
    fetchBookList();
  }, [fetchBookList]);

  return (
    <>
      <Style.BookList>
        {
          bookList && bookList.bookSoundTrack && bookList.bookSoundTrack.map((item) => {
            return (
              <Style.BookListItem>
                <Link to={`/audio/${item.bookLevel}`}>
                  <Img
                    src={item.thumbnailPath}
                    alt='text book'
                  />
                  <div className='title'>{(language === 'ko-KR') ? item.productTitleKO : item.productTitleEN}</div>
                </Link>
              </Style.BookListItem>
            );
          })
        }
      </Style.BookList>
    </>
  );
};
export default BookList;
import React from 'react';

export const termsOfPrivacyKo = () => (
  <div class='policy'>
    <h3 class='policy-title'>개인정보처리방침</h3>
    <p>
      MasterTOPIK / MasterKorean은 고객 (이하 ‘통합회원’이라 함) 의 개인 정보를
      소중하게 생각하고, 통합회원의 개인정보를 보호하기 위하여 항상 최선을
      다하고 있습니다. MasterTOPIK / MasterKorean은 정보통신망 이용촉진 및
      정보보호 등에 관한 법률, 개인정보보호법 등 정보통신서비스 제공자가
      준수하여야 할 관련 법령상의 개인정보 보호규정을 지키며, 관련 법령에 따른
      개인정보 처리방침을 정하여 이를 MasterTOPIK / MasterKorean홈페이지에
      공개하여 통합회원이 언제나 용이하게 열람할 수 있도록 하고 있습니다.
    </p>
    <p>
      MasterTOPIK / MasterKorean은 정부의 법률 및 지침 변경 등에 따라 수시로
      개인정보처리방침을 변경 할 수 있고, 이에 따른 개인정보 처리방침의 지속적인
      개선을 위하여 필요한 절차를 정하고 있습니다. 그리고 개인정보 처리방침을
      개정하거나 변경할 때에는 홈페이지 첫 페이지에 해당 내용을 알리고 있습니다.
      이용자들께서는 사이트 방문 시 수시로 확인하시기 바랍니다.
    </p>
    <p>
      MasterTOPIK / MasterKorean은 「개인정보처리방침」은 아래와 같은 내용을
      담고 있습니다.
    </p>
    <ol class='policy-num'>
      <li>1. 개인정보 수집 및 이용 목적</li>
      <li>2. 개인정보 수집 항목 및 방법</li>
      <li>3. 쿠키에 의한 개인정보 수집 방법</li>
      <li>4. 개인정보 보유 이용기간 및 파기</li>
      <li>5. 수집한 개인정보의 이용 및 제3자 제공</li>
      <li>6. 개인정보의 열람 및 정정</li>
      <li>7. 개인정보의 수집, 이용, 제공에 대한 동의철회</li>
      <li>8. 개인정보처리 업무의 위탁</li>
      <li>9. 개인정보 처리방침의 고지 또는 통지방법</li>
      <li>10. 개인정보 보호를 위한 기술 및 관리적 대책</li>
      <li>11. 게시글 관리와 책임</li>
      <li>12. 통합회원의 권리와 의무</li>
      <li>13. 광고성 정보 전송</li>
      <li>14. 의견수렴 및 불만처리</li>
      <li>15. 개인정보 보호책임자 및 담당자</li>
      <li>16. 고지 의무</li>
    </ol>
    <div class='policy-section'>
      <h4 class='policy-tit'>1. 개인정보 수집 및 이용 목적</h4>
      <table class='policy-tbl type2'>
        <thead>
          <tr>
            <th>사이트</th>
            <th>구분</th>
            <th>이용목적</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowspan='4'>MasterTOPIK /MasterKorean</td>
            <td>회원 관리</td>
            <td>
              •회원제 서비스 이용에 따른 회원식별
              <br />
              •불량회원의 부정이용 방지와 비인가 사용 방지, 중복가입방지
              <br />
              •통합회원상담, 통합회원불만 접수 및 처리, 분쟁조정을 위한 기록보존
              <br />
            </td>
          </tr>
          <tr>
            <td>
              서비스 제공에 관한
              <br />
              계약의 이행 및<br />
              서비스 제공에 따른 요금정산
            </td>
            <td>
              •거래 서비스 제공
              <br />
              •콘텐츠 제공, 이벤트/경품당첨 결과안내 및 상품배송
              <br />
              •금융거래 관련 본인인증 및 금융서비스
              <br />
              •구매 및 요금결제
              <br />
            </td>
          </tr>
          <tr>
            <td>마케팅 및 광고에 활용</td>
            <td>
              •통합회원에게 최적화된 서비스 제공
              <br />
              •신규 서비스(제품) 개발 및 특화
              <br />
              •인구통계학적 특성에 따른 서비스 제공 및 광고 게재
              <br />
              •웹 페이지 접속 빈도 파악
              <br />
              •상품/서비스의 이용실적 분석
              <br />
              •정기 간행물 발송, 새로운 상품 또는 서비스 안내
              <br />
              •통합회원 관심사에 부합하는 웹 서비스 및 이벤트 기획
              <br />
              •경품행사, 이벤트 등 광고 성 정보 전달
              <br />
              •통합회원대상 설문조사
              <br />
              •서비스 및 상품 안내
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>2. 개인정보 수집 항목 및 방법</h4>
      <ul class='policy-num'>
        <li>
          가. MasterTOPIK / MasterKorean은 기본적인 서비스 제공을 위한 필수
          정보만을 수집하고 있으며 통합회원 각각의 기호와 필요에 맞는 서비스를
          제공하기 위하여 정보 수집 때 별도 동의를 얻고 있습니다. 선택 정보를
          입력하지 않아도 서비스 이용에 제한은 없습니다.
        </li>
        <li>
          나. MasterTOPIK / MasterKorean은 이용자의 기본적 인권을 침해 할 우려가
          있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지,
          정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.
          그리고, 어떤 경우에라도 입력하신 정보를 이용자들에게 미리 밝힌 목적
          이외의 다른 목적으로는 사용하지 않으며 외부로 유출하지 않습니다.
        </li>
        <li>
          다. MasterTOPIK / MasterKorean은 다음과 같이 개인정보를 수집하여
          이용합니다.
          <ol class='policy-num'>
            <li>① 회원가입 및 주문 등 필요시점에서 수집하는 정보</li>
            <li>
              <table class='policy-tbl type2'>
                <thead>
                  <tr>
                    <th rowSpan='2' colSpan='3'>
                      목적
                    </th>
                    <th colSpan='2'>항목</th>
                    <th rowSpan='2'>보유기간</th>
                  </tr>
                  <tr>
                    <th>MasterKorean</th>
                    <th>MasterTOPIK</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan='5'>필수</td>
                    <td rowspan='2'>회원가입 및 관리</td>
                    <td>본인확인(식별)</td>
                    <td>이메일</td>
                    <td>없음</td>
                    <td>확인 후 파기</td>
                  </tr>
                  <tr>
                    <td>회원가입/회원관리</td>
                    <td>
                      영문 이름, 영문 성, 닉네임, 아이디(이메일) 페이스북
                      아이디, 비밀번호, 성별, 국적, 생년월일
                    </td>
                    <td>
                      영문 이름, 영문 성, 아이디(이메일) 페이스북 아이디,
                      비밀번호, 성별, 국적, 생년월일
                    </td>
                    <td rowspan='6'>
                      회원탈퇴 후 파기됩니다. 다만 관계법령에 의해 보존할 경우
                      그 의무기간 동안 별도 보관되며 불, 편법 행위의 방지 및
                      대응의 목적으로 6개월간 보관됩니다. <br />* 교재배송 /
                      구매 및 요금 결제: 이용목적 달성 후 지체 없이 파기합니다.
                    </td>
                  </tr>
                  <tr>
                    <td rowspan='3'>서비스제공 (계약이행/요금 정산)</td>
                    <td>교재배송</td>
                    <td>없음</td>
                    <td>
                      영문 이름, 영문 성, 주소, 한국 휴대폰 번호, 아이디(이메일)
                    </td>
                  </tr>
                  <tr>
                    <td>구매 및 요금 결제</td>
                    <td>아이디(이메일), 결제금액, 결제일, 결제수단</td>
                    <td>
                      아이디, 이메일, 카드정보, 계좌정보, 전자지갑 정보, 한국
                      휴대폰 번호, 통신사, 포인트 카드정보, 접속 IP, 상품거래
                      정보
                    </td>
                  </tr>
                  <tr>
                    <td>계좌 입금자 환불처리</td>
                    <td>없음</td>
                    <td>계좌번호, 은행이름, 예금 주명, 연락처</td>
                  </tr>
                  <tr>
                    <td rowspan='2'>선택</td>
                    <td>마케팅 및 광고에 활용</td>
                    <td>이벤트 정보 및 뉴스레터, 할인혜텍 알림</td>
                    <td colspan='2'>이메일, 한국 휴대폰 번호</td>
                  </tr>
                  <tr>
                    <td>회원가입 및 관리</td>
                    <td>회원가입 및 관리</td>
                    <td>영문 이름, 영문 성, 주소, 한국 휴대폰 번호</td>
                    <td>영문 이름, 영문 성, 한국 휴대폰 번호</td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>② 서비스 이용과정에서 생성되는 정보</li>
            <li>
              <table class='policy-tbl type2'>
                <thead>
                  <tr>
                    <th rowspan='2' colspan='2'>
                      목적
                    </th>
                    <th colspan='2'>항목</th>
                    <th rowspan='2'>보유기간</th>
                  </tr>
                  <tr>
                    <th>MasterKorean</th>
                    <th>MasterTOPIK</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowspan='2'>필수</td>
                    <td>
                      통합회원식별확인, 개인정보침해사고 대한 대비, 분쟁조정을
                      위한 기록 보존
                    </td>
                    <td colspan='2'>
                      수강기록, 결제기록, 회원가입일, 최근 접속 일시, 접속IP,
                      국가IP, 최종선택 언어.
                    </td>
                    <td rowspan='2'>
                      회원탈퇴 후 파기됩니다. 다만 관계법령에 의해 보존할 경우
                      그 의무 기간 동안 별도 보관되며 불, 편법 행위의 방지 및
                      대응의 목적으로 6개월간 보관됩니다. <br />
                      <br />※ 쿠키의 경우, 브라우저 종료 시 또는 로그아웃 시
                      만료
                    </td>
                  </tr>
                  <tr>
                    <td>서비스 이용 통계 등</td>
                    <td colspan='2'>
                      사용자 아이디, 영문 이름, 영문 성, 성별, 생일, 이메일,
                      국가코드, 학교코드, 매칭 코드, 휴면회원유무, 페이스 북
                      회원유무, 사이트 선택 언어
                    </td>
                  </tr>
                </tbody>
              </table>
            </li>
            <li>③ 기타 법정 의무 수집 정보 등</li>
            <li>
              <table class='policy-tbl type2'>
                <thead>
                  <tr>
                    <th rowspan='2'>관련법률</th>
                    <th rowspan='2'>이용목적</th>
                    <th colspan='2'>수집항목</th>
                    <th rowspan='2'>보유기간</th>
                  </tr>
                  <tr>
                    <th>MasterKorean</th>
                    <th>MasterTOPIK</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>통신비밀보호법</td>
                    <td>
                      수사기관 제공 (법원 영장 등 정당한 절차의 경우에 제공)
                    </td>
                    <td colspan='2'>로그기록, IP 등</td>
                    <td>3개월</td>
                  </tr>
                  <tr>
                    <td rowspan='3'>
                      전자상거래 등에서의 소비자보호에 관한 법률
                    </td>
                    <td>소비자의 불만 또는 분쟁처리에 관한 기록</td>
                    <td colspan='2'>소비자식별정보, 분쟁처리 기록 등</td>
                    <td>3년</td>
                  </tr>
                  <tr>
                    <td>대금결제 및 재화 등의 공급에 관한 기록</td>
                    <td colspan='2' rowspan='2'>
                      소비자 식별정보, 계약/청약철회 기록 등
                    </td>
                    <td rowspan='2'>5년</td>
                  </tr>
                  <tr>
                    <td>계약 또는 청약철회 등에 관한 기록</td>
                  </tr>
                </tbody>
              </table>
            </li>
          </ol>
        </li>
        <li>
          라. 개인정보 수집방법
          <ul class='policy-bu'>
            <li>
              - 홈페이지, 서면양식, 상담게시판, 전화, 팩스, 이벤트응모, 배송
              요청
            </li>
            <li>- 로그 분석 프로그램을 통한 생성정보수집</li>
            <li>- 스마트폰용 어플리케이션을 통한 수집</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>3. 쿠키에 의한 개인정보 수집 방법</h4>
      <ul class='policy-num'>
        <li>
          가. 쿠키란?
          <br />
          MasterTOPIK / MasterKorean은 통합회원에 대한 정보를 저장하고 수시로
          찾아내는 ‘쿠키(cookie)’를 사용합니다. 쿠키는 웹사이트가 통합회원의
          컴퓨터 브라우저(인터넷 익스플로러 등)에 전송하는 소량의 정보입니다.
        </li>
        <li>
          나. MasterTOPIK / MasterKoreanl의 쿠키 사용 목적
          <br />
          MasterTOPIK / MasterKorean은 다음과 같은 목적을 위해 쿠키를 통하여
          수집된 통합회원의 개인정보를 사용합니다.
          <ul class='policy-bu'>
            <li>- 개인의 관심 분야에 따라 차별화된 정보를 제공</li>
            <li>
              - 접속빈도 또는 방문시간 등을 분석하고 이용자의 취향과 관심분야를
              파악하여 타겟(target) 마케팅 및 서비스 개선의 척도로 활용
            </li>
            <li>
              - 쇼핑한 품목들에 대한 정보와 관심 있게 둘러본 품목을 추적하여
              개인 맞춤 쇼핑서비스 제공
            </li>
          </ul>
        </li>
        <li>
          다. MasterTOPIK / MasterKoreanl의 쿠키(cookie) 운용 <br />
          쿠키는 통합회원의 컴퓨터는 식별하지만 통합회원을 개인적으로 식별하지는
          않습니다. 또한 통합회원은 웹브라우저에 설정을 통해 모든 쿠키를
          허용/거부하거나, 쿠키를 저장될 때마다 확인을 거치도록 할 수 있습니다.
          쿠키 설정거부 방법은 다음과 같습니다. (Internet Explorer 기준)
          웹브라우저 [도구] 메뉴 [인터넷 옵션] 선택 {'>'} [개인 정보] 탭을 선택{' '}
          {'>'} [고급]에서 원하는 옵션 선택
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>4. 개인정보 보유 이용기간 및 파기</h4>
      <ul class='policy-num'>
        <li>
          가. MasterTOPIK / MasterKorean은 통합회원의 개인정보를 고지 및 동의
          받은 기간 동안 보유 및 이용합니다. 개인정보의 수집 및 이용목적 달성,
          보유기간 만료, 회원의 수집 및 이용 동의 철회 시 수집된 개인정보는
          열람하거나 이용할 수 없도록 파기 처리합니다. 단, 전자상거래 등에서의
          소비자보호에 관한 법률 등 관련법령의 규정에 의하여 다음과 같이 거래
          권리 의무 관계의 확인 등을 이유로 일정기간 보유하여야 할 필요가 있을
          경우에는 그 기간 동안 보유합니다.
          <ol class='policy-num'>
            <li>
              1) 전자상거래 등에서의 소비자보호에 관한 법률 제 6조
              <ul class='policy-bu'>
                <li>- 계약 또는 청약철회 등에 관한 기록: 5년</li>
                <li>- 대금결제 및 재화 등의 공급에 관한 기록: 5년</li>
                <li>- 소비자의 불만 또는 분쟁처리에 관한 기록: 3년</li>
              </ul>
            </li>
            <li>
              2) 통신비밀보호법
              <ul class='policy-bu'>
                <li>- 인터넷 로그기록자료, 접속지 추적자료: 3개월</li>
              </ul>
            </li>
            <li>
              3) 기타 관련 법령 등<br />※ 그 외 정보통신망법 제50조(영리목적의
              광고성 정보 전송 제한) 준수 이력을 증빙하기 위하여 이메일, 문자,
              앱 푸시 발송이력과 관련된 개인정보를 1년간 보관할 수 있습니다. 이
              경우 탈퇴회원 및 휴면회원의 발송이력은 일반 이용자의 개인정보와
              별도로 보관되며, 다른 목적으로 이용하지 않습니다.
            </li>
          </ol>
        </li>
        <li>
          나. 정보 파기방법은 아래와 같습니다.
          <ol class='policy-num'>
            <li>1) 종이에 출력된 개인정보: 분쇄기로 분쇄하거나 소각</li>
            <li>
              2) 전자적 파일형태로 저장된 개인정보: 기록을 재생할 수 없는 기술적
              방법을 사용하여 삭제
            </li>
          </ol>
        </li>
        <li>
          다. 개인정보 유효기간 제도
          <ol class='policy-num'>
            <li>
              1) MasterTOPIK / MasterKorean은 장기간 서비스 미이용자의
              개인정보보호를 위하여 휴면회원(최근 12개월 동안 서비스를 이용하지
              아니한 회원 또는 직접 유효기간을 선택한 회원)의 개인정보를 다 른
              이용자의 정보와 분리하여 저장, 관리합니다.
            </li>
            <li>
              2) 미이용의 기간은 로그인, 상담원 접촉일자 등으로 산정하며
              법령에서 정의한 기간 동안 서비스 미이용자에 대하여 분리,
              저장관리합니다.
            </li>
            <li>
              3) 당사는 미이용자 개인정보 분리/저장시점 도래 1개월 이전에 이메일
              등을 통해 해당 이용자에게 관련내용을 공지 합니다.
            </li>
          </ol>
        </li>
        <li>
          라. 회원에서 탈퇴한 후 회원 재가입, 임의해지 등을 반복적으로 행하여
          MasterTOPIK / MasterKorean이 제공하는 할인쿠폰, 이벤트 혜택 등으로
          경제상의 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 편법과
          불법행위를 하는 회원을 차단 하고자 MasterTOPIK / MasterKorean의
          홈페이지 회원 탈퇴 후 6개월 동안 회원의 이름, 아이디(ID), 로그기록,
          접속아이피(IP) 등을 보관합니다.
        </li>
      </ul>
    </div>
    
    <div class='policy-section'>
      <h4 class='policy-tit'>5. 수집한 개인정보의 이용 및 제3자 제공</h4>
      <ul class='policy-num'>
        <li>
          가. MasterTOPIK / MasterKorean은 통합회원의 개인정보를 가입신청서,
          이용약관, 「개인정보처리방침」의「개인정보의 수집 및 이용목적」에서
          알린 범위 내에서 사용하며, 이 범위를 초과하여 이용하거나 타인 또는
          다른 기업 · 기관에 제공하지 않습니다. 단, 통합회원의 동의가 있거나
          법령에 의하여 정해진 절차에 따라 정보를 요청 받은 경우는 예외로 하며,
          이 경우 주의를 기울여 개인정보를 이용 및 제공할 수 있습니다.
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>6. 개인정보의 열람 및 정정</h4>
      <ul class='policy-num'>
        <li>
          가. 귀하는 언제든지 등록된 귀하의 개인정보를 열람하거나 정정하실 수
          있습니다. 개인정보는『나의 정보 수정』을 클릭하여 직접 열람하거나
          정정할 수 있으며 개인정보보호책임자 또는 담당자 에게 서면이나 전화
          또는 이메일(E-mail)로 열람이나 정정을 요청하시면 곧바로
          조치하겠습니다.
        </li>
        <li>
          나. 귀하가 개인정보의 오류에 대해 정정을 요청하면, MasterTOPIK /
          MasterKorean은 정정을 완료하기 전까지 해당 개인 정보를 이용하거나
          제공하지 않습니다.
        </li>
        <li>
          다. 잘못된 개인정보를 제3자에게 이미 제공했을 때에는 정정 처리결과를
          제3자에게 곧바로 통지하여 정정하도록 조치하겠습니다.
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <ul class='policy-num'>
        <li>
          가. 회원가입 등을 통한 개인정보의 수집, 이용, 제공과 관련해 귀하는
          동의하신 내용을 언제든지 철회할 수 있습니다. 동의철회는 홈페이지 첫
          화면의 『나의 정보 수정』에서 " 회원탈퇴“를 클릭하면 개인정보의
          수집ㆍ이용ㆍ제공에 대한 동의를 바로 철회할 수 있으며,
          개인정보보호책임자에게 서면, 전화, 이메일(E-mail) 등으로 연락하시면
          MasterTOPIK / MasterKorean은 즉시 회원 탈퇴를 위해 필요한 조치를
          취합니다. 동의를 철회하고 개인정보를 파기하는 등의 조치가 있으면 그
          사실을 귀하께 지체 없이 통지하도록 하겠습니다.
        </li>
        <li>
          나. MasterTOPIK / MasterKorean은 개인정보의 수집에 대한
          동의철회(회원탈퇴)를 개인정보를 수집하는 방법보다 쉽게 할 수 있도록
          필요한 조치를 취합니다.
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>8. 개인정보처리 업무의 위탁</h4>
      <ul class='policy-num'>
        <li>
          가. MasterTOPIK / MasterKorean은 기본적인 서비스 제공, 더 나은 서비스
          제공, 통합회원편의 제공 등 원활한 업무 수행을 위하여 다음과 같이
          개인정보 처리 업무를 외부전문업체에 위탁하여 운영하고 있습니다.
        </li>
        <li>
          <table class='policy-tbl type2'>
            <thead>
              <tr>
                <th rowspan='2'>수탁자</th>
                <th rowspan='2'>위탁목적</th>
                <th colspan='2'>개인정보항목</th>
                <th rowspan='2'>개인정보보유 및 이용기간</th>
              </tr>
              <tr>
                <th>MasterKorean</th>
                <th>MasterTOPIK</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>(주)한진</td>
                <td>물품배송</td>
                <td>없음</td>
                <td>영문 이름, 영문 성, 주소, 한국 휴대폰번호</td>
                <td rowspan='2'>이용목적이 달성되면 지체 없이 파기합니다.</td>
              </tr>
              <tr>
                <td>(주)케이지이니시스</td>
                <td>결제 시 본인인증 및 거래</td>
                <td>없음</td>
                <td>
                  아이디, 이메일, 카드정보, 계좌정보, 전자지갑 정보, 한국
                  휴대폰번호, 통신사, 포인트 카드정보, 접속 IP, 상품거래 정보
                </td>
              </tr>
            </tbody>
          </table>
        </li>
        <li>
          나. MasterTOPIK / MasterKorean은 위탁업무계약서 등을 통하여
          개인정보보호 관련 법규의 준수, 개인정보에 관한 비밀유지, 개인정보의
          제3자 제공 금지, 사고시의 책임 부담, 위탁기간, 처리 종료 후의
          개인정보의 반환 또는 파기의무 등을 규정하고, 이를 지키도록 관리하고
          있습니다.
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>9. 개인정보 처리방침의 고지 또는 통지방법</h4>
      <ul class='policy-num'>
        <li>
          가. MasterTOPIK / MasterKorean은 통합회원이 동의한 범위를 넘어
          통합회원의 개인정보를 이용하거나 제3자에게 제공하기 위해
          통합회원에게서 추가적인 동의를 얻고자 할 때에는 미리 통합회원에게
          개별적으로 서면, 이메일(E- mail), 전화 등으로 해당사항을 알립니다.
        </li>
        <li>
          나. 타인에게 통합회원의 개인정보의 수집·보관·처리·이용·제공·관리·파기
          등을 위탁할 때에는 그 사실을 가입화면, 서비스이용약관, 홈페이지 의
          개인정보처리방침 등을 통하여 그 사실을 통합회원에게 고지하고 알리고
          동의를 얻습니다.
        </li>
        <li>
          다. MasterTOPIK / MasterKorean가 영업의 전부 또는 일부를 양도하거나
          합병·상속 등으로 그 권리와 의무를 이전할 때에는 서면·이메일(E-mail)
          등을 통하여 통합회원에게 개별적으로 알리는 동시에 해당 사실을 홈페이지
          첫 화면에서 식별할 수 있도록 표기하고 30일 이상 그 사실을 공지합니다.
          다만 천재·지변이나 그 밖에 정당한 사유로 홈페이지 게시가 곤란한
          경우에는 2곳 이상의 중앙일간지(통합회원의 대부분이 특정 지역에 거주할
          때에는 그 지역을 보급구역으로 하는 일간지로 할 수 있습니다)에 1 회
          이상 공고하는 것으로 대신합니다.
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>10. 개인정보 보호를 위한 기술 및 관리적 대책</h4>
      <ul class='policy-num'>
        <li>
          가. MasterTOPIK / MasterKorean에서는 통합회원의 개인정보를 보호하기
          위해 기술적 대책과 관리적 대책을 마련하고 있으며, 이를 적용하고
          있습니다.
        </li>
        <li>
          나. 기술적 대책 <br />
          MasterTOPIK / MasterKorean은 통합회원의 개인정보를 처리할 때
          개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 다음과 같은
          기술적 대책을 마련하여 개인정보의 안전성을 확보하고 있습니다.
          <ul class='policy-bu'>
            <li>
              - 통합회원의 개인정보는 비밀번호(Password)로 보호되며 중요한
              데이터는 파일 및 전송데이터를 암호화하거나 파일 잠금 기능 (Lock)을
              사용하여 중요한 데이터는 별도의 보안기능으로 보호됩니다.
            </li>
            <li>
              - MasterTOPIK / MasterKorean은 백신프로그램을 이용하여 컴퓨터
              바이러스의 피해를 방지하는 조치를 하고 있습니다. 백신프로그램은
              주기적으로 업데이트되며 바이러스가 갑자기 출현하면 백신이 나오는
              즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
            </li>
            <li>
              - MasterTOPIK / MasterKorean은 암호알고리즘을 이용하여 네트워크
              상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 또는 SET)를
              채택하고 있습니다.
            </li>
            <li>
              - 해킹 등 외부침입에 대비하여 침입차단시스템과 침입방지시스템 등을
              이용하여 보안에 최선을 다하고 있습니다.
            </li>
          </ul>
        </li>
        <li>
          다. 관리적 대책 <br />
          MasterTOPIK / MasterKorean은 통합회원의 개인정보에 대한 접근권한을
          최소한의 인원으로 제한하고 있으며 이에 해당하는 자는 다음과 같습니다.
          <ol class='policy-num'>
            <li>1) 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자</li>
            <li>
              2) 개인정보보호책임자와 담당자 등 개인정보보호업무를 수행하는 자
            </li>
            <li>3) 기타 업무상 개인정보의 처리가 불가피한 자</li>
          </ol>
          <ul class='policy-bu'>
            <li>
              - MasterTOPIK / MasterKorean은 통합회원의 개인정보에 대한 접근과
              관리에 필요한 절차 등을 마련하여 소속 직원으로 하여금 이를
              숙지하고 지키도록 하고 있으며, 개인정보를 처리하는 직원을 대상으로
              새로운 보안 기술 습득 및 개인정보보호 의무 등에 관해 정기적인 사내
              교육 등을 시행하고 있습니다.
            </li>
            <li>
              - 전산실 등을 통제구역으로 설정하여 출입을 통제하고 있습니다.
            </li>
            <li>
              - MasterTOPIK / MasterKorean은 신규로 채용된 직원에게
              정보보호서약서 또는 개인정보보호서약서에 서명하게 하여 직원에 의한
              정보유출을미리 방지하고 있으며, 개인정보처리방침에 대한 이행사항과
              직원의 준수여부를 감사하기 위한 내부절차를 마련하여 지속적으로
              시행하고 있습니다.
            </li>
            <li>
              - MasterTOPIK / MasterKorean은 직원 퇴직 시 비밀유지서약서에
              서명함으로 회원의 개인정보를 처리하였던 자가 직무상 알게 된
              개인정보를 훼손·침해 또는 누설하지 않도록 하고 있습니다.
            </li>
            <li>
              - 그 밖에 내부 관리자의 실수나 기술관리 상의 사고로 통합회원의
              개인정보가 상실ㆍ유출ㆍ변조ㆍ훼손되면 MasterTOPIK / MasterKorean은
              즉각 통합회원께 해당 사실을 알리고 적절한 대책과 보상을 마련할
              것입니다.
            </li>
          </ul>
        </li>
        <li>
          라. 기타 보호대책
          <ol class='policy-num'>
            <li>
              1) 개인정보 유출 등 통지∙신고제도 <br />
              MasterTOPIK / MasterKorean은 개인정보의 분실, 도난, 유출 사고 발생
              사실을 안 때에는 지체없이 이용자에게 해당 내용을 알리고, 방송통신
              위원회 또는 한국인터넷진흥원에 신고합니다.
            </li>
            <li>
              2) 개인정보 이용내역 통지 제도
              <br />
              MasterTOPIK / MasterKorean이용자의 개인정보 자기결정권을 보장하기
              위해 개인정보 이용내역을 해당 이용자에게 주기적으로(연 1회이상)
              통지합니다. <br />
              통지하는 개인정보 이용내역은 다음과 같습니다.
              <ul class='policy-bu'>
                <li>- 개인정보 수집∙이용목적 및 수집한 개인정보의 항목</li>
                <li>
                  - 개인정보를 제공받은 자, 그 제공 목적 및 제공한 개인정보의
                  항목
                </li>
                <li>
                  - 개인정보 처리위탁을 받은 자 및 그 처리위탁을 하는 업무의
                  내용
                </li>
              </ul>
            </li>
          </ol>
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>11. 게시글 관리와 책임</h4>
      <ul class='policy-num'>
        <li>
          가. MasterTOPIK / MasterKorean은 통합회원의 게시물을 소중하게 생각하며
          해당 게시물이 변조, 훼손, 삭제되지 않도록 최선을 다하여 보호합니다.
          그러나 다음의 경우에는 그렇지 않습니다.
          <ul class='policy-bu'>
            <li>
              - 스팸(spam)성 게시물 (예: 행운의 편지, 특정사이트에 대한 광고,
              다른 사이트로의 유도 광고 및 링크 등)
            </li>
            <li>
              - 타인을 비방할 목적으로 허위 사실을 유포하여 타인의 명예를
              훼손하는 글
            </li>
            <li>
              - 동의 없는 타인의 신상공개, MasterTOPIK / MasterKorean의 저작권,
              혹은 제3자의 지적재산권 등의 권리를 침해하는 내용, 기타 게시판
              주제와 다른 내용의 게시물
            </li>
            <li>
              - MasterTOPIK / MasterKorean은 바람직한 게시판 문화를 활성화하기
              위하여 타인의 신상을 동의 없이 공개할 때에는 특정부분을 삭제하거나
              기호 등으로 수정하여 게시할 수 있습니다.
            </li>
            <li>
              - 다른 주제의 게시판으로 이동할 수 있는 내용은 해당 게시물에 이동
              경로를 밝혀 오해가 없도록 하고 있습니다.
            </li>
            <li>
              - 그 밖의 경우에는 명시적 또는 개별적인 경고 후 삭제 조치할 수
              있습니다.
            </li>
          </ul>
        </li>
        <li>
          나. 근본적으로 게시물에 관련된 제반 권리와 책임은 작성자 개인에게
          있습니다. 또한 게시물을 통해 자발적으로 공개된 정보는 보호받기
          어려우므로 정보 공개 전에 심사 숙고한 후 서비스를 이용해야 합니다.
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>12. 통합회원의 권리와 의무</h4>
      <ul class='policy-num'>
        <li>
          가. 통합회원의 개인정보를 최신의 상태로 정확하게 입력하여 불의의
          사고를 예방해 주시기 바랍니다. 부정확한 정보 입력으로 말미암아
          발생하는 사고의 책임은 통합회원에게 있으며 타인의 정보를 무단으로
          사용하는 등 허위정보를 입력하면 회원자격이 상실될 수 있습니다.
        </li>
        <li>
          나. 통합회원은 개인정보를 보호받을 권리와 함께 자신을 스스로를
          보호하고 타인의 정보를 침해하지 않을 의무도 지니고 있습니다.
          비밀번호(Password)를 포함한 통합회원의 개인정보가 유출되지 않도록
          조심하시고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해
          주십시오. 만약 이 같은 책임을 다하지 못하고 타인의 정보를 훼손할
          때에는『정보통신망 이용촉진 및 정보보호 등 에 관한 법률』등에 의해
          처벌받을 수 있습니다.
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>13. 광고성 정보 전송</h4>
      <ul class='policy-num'>
        <li>
          가. MasterTOPIK / MasterKorean은 「정보통신망 이용 촉진 및 정보보호
          등에 관한 법률」제50조에 의거, 전자우편, 휴대폰문자, 모바일 앱, DM, TM
          등의 채널로 영리목적의 광고 성 정보를 전송할 수 있습니다.
        </li>
        <li>
          나. MasterTOPIK / MasterKorean은 문서 또는 구술의 방법으로
          통합회원에게 명시적으로 수신동의를 받아 광고 성 정보를 전송합니다.
          다만, 다음 각 호의 어느 하나에 해당하는 경우에는 사전동의를 받지
          않습니다.
          <ol class='policy-num'>
            <li>
              1) 재화 등의 거래관계를 통하여 수신자로부터 직접 연락처를 수집한
              자가 해당 재화 등의 거래가 종료된 날부터 6개월 이내에 자신이
              처리하고 수신자와 거래한 것과 동종의 재화 등에 대한 영리목적의
              광고 성 정보를 전송하려는 경우
            </li>
            <li>
              2)「방문판매 등에 관한 법률」에 따른 전화권유판매자가 육성으로
              전화권유를 하는 경우
            </li>
          </ol>
        </li>
        <li>
          다. MasterTOPIK / MasterKorean은 광고 성 정보가 시작되는 부분에 광고를
          의미하는 표시를 안내하고, 본문에는 전송자의 명칭 및 연락처, 그리고
          수신 거부 또는 수신동의 철회의 의사를 쉽게 표시할 수 있도록 하기 위한
          안내문을 명시합니다.
        </li>
        <li>
          라. MasterTOPIK / MasterKorean은 통합회원이 광고 성 정보의 수신거부,
          사전 동의를 철회한 경우 영리목적의 광고 성 정보를 전송하지 않습니다.
        </li>
        <li>
          마. MasterTOPIK / MasterKorean은 법률에서 금지하는 재화 또는 서비스에
          대한 광고 성 정보를 전송하지 않습니다.
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>14. 의견수렴 및 불만처리</h4>
      <ul class='policy-num'>
        <li>
          가. MasterTOPIK / MasterKorean은 통합회원의 의견을 매우 소중하게
          생각하며, 통합회원은 의문사항으로부터 언제나 성실한 답변을 받을 권리가
          있습니다.
        </li>
        <li>
          나. MasterTOPIK / MasterKorean은 통합회원과의 원활한 의사소통을 위해
          통합회원센터 등 통합회원상담창구를 운영하고 있습니다. 문의사항이
          있으면 아래의 연락처로 문의하시기 바랍니다.
          <ul class='policy-bu'>
            <li>•통합회원센터</li>
            <li>◦ 담당자: mastertopik@visang.com, masterkorean@visang.com</li>
            <li>◦ 전화번호: 02-1800-8674</li>
            <li>◦ 팩스번호: 02-6070-6148</li>
            <li>
              ◦ 주 소: 서울시 구로구 구로동 170-10(디지털로33길 48) 대륭 포스트
              타워 7차 20층
            </li>
          </ul>
        </li>
        <li>
          다. 기타 개인정보 침해에 관한 상담이 필요한 경우에는 한국인터넷진흥원
          개인정보침해신고센터, 경찰청 사이버수사국 등으로 문의하실 수
          있습니다.
          <ul class='policy-bu'>
            <li>• 개인정보침해신고센터</li>
            <li>◦ 전화: (국번 없이) 118</li>
            <li>◦ URL: http://privacy.kisa.or.kr</li>
            <li>• 경찰청 사이버수사국</li>
            <li>◦ 전화: 182</li>
            <li>◦ URL: http://www.netan.go.kr</li>
          </ul>
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>15. 개인정보 보호책임자 및 담당자</h4>
      <ul class='policy-num'>
        <li>
          가. MasterTOPIK / MasterKorean은 통합회원의 개인정보보호를 매우
          소중하게 생각하며, 통합회원의 개인정보가 훼손, 침해, 누설되지 않도록
          최선을 다하고 있습니다. 그러나 기술적인 보완조치를 했음에도 불구하고,
          해킹 등 기본적인 네트워크상의 위험성 때문에 예기치 못한 사고가
          발생하여 정보가 훼손되거나 방문자가 작성한 게시물에 의해 각종 분쟁이
          발생하는 것에 대해서는 책임을 지지 않습니다.
        </li>
        <li>
          나. MasterTOPIK / MasterKorean의 통합회원센터에서는 통합회원의
          개인정보보호 관련 문의에 신속하고 성실하게 답변을 드리도록 하고
          있습니다. MasterTOPIK / MasterKorean의 개인정보보호 담당자와
          연락하기를 원하시면 아래의 연락처나 이메일로 문의해 주시기 바랍니다.
          개인정보 관련 문의사항에 대해 신속하고 성실하게 답변해 드리겠습니다.
        </li>
        <li>
          <table class='policy-tbl type2'>
            <thead>
              <tr>
                <th>구분</th>
                <th>개인정보보호책임자</th>
                <th>개인정보보호담당자</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>이름</td>
                <td>최성기 SP</td>
                <td>이정우 IP</td>
              </tr>
              <tr>
                <td>부서</td>
                <td>미래전략실</td>
                <td>IT전략과</td>
              </tr>
              <tr>
                <td>전화번호</td>
                <td>02-6970-6005</td>
                <td>02-6970-6072</td>
              </tr>
              <tr>
                <td>e-mail</td>
                <td>choisk2@visang.com</td>
                <td>leejw5@visang.com</td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>
    </div>
    <div class='policy-section'>
      <h4 class='policy-tit'>16. 고지 의무</h4>
      <ul class='policy-bu'>
        <li>
          • 현 개인정보처리방침은 2016년 12월 01일에 제정되었으며 정부의 정책
          또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 필요하면
          일반적 내용은 개정 최소 7일 전부터, 중요한 내용 은 개정 최소 30일
          전부터 홈페이지의 공지게시판을 통해 알릴 것입니다.
        </li>
        <li>• 개인정보처리방침 공고 일자: 2016년 12월 01일</li>
        <li>• 개인정보처리방침 시행 일자: 2016년 12월 07일</li>
      </ul>
    </div>
  </div>
);

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext } from 'component/Modal/modalContext';
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from 'react-router-dom';
import { useAxios } from 'common/hook/useAxiosHook';
import useLoginStatus from "common/hook/useLoginStatus";
import { addItem, deleteAllItem } from "container/PaymentPage/state/action";
import Login from "component/user/Login";
import ClassDetailModal from './ClassDetailModal';
import ConfirmModal from "./ConfirmModal";
import productApi from 'api/product';
import userApi from 'api/user';

import Styled from './styled/ClassItem.style';

/**
 * 
 * @param {*} item 
 * @param {number} tblProductIdx        상품코드
 * @param {string} productTitle         상품명
 * @param {string} discountDefault      기본할인명
 * @param {string} discountDefaultType  기본할인 할인율PER|할인금액CST
 * @param {number} discountDefault_amt  기본할인 숫자
 * @param {string} discountSpecial      특별할인명
 * @param {string} discountSpecialType  특별할인 할인율PER|할인금액CST
 * @param {number} discountSpecial_amt  특별할인 숫자
 * 
 * @param {number} priceCost      정가 금액
 * @param {number} salesStore     판매가 금액
 * @param {string} discountType   판매가 할인율PER|할인금액CST
 * @param {string} discountCost   판매가 할인 숫자
 * 
 * @param {string} classType      수업구분 TOPIK|KO
 * @param {string} classLang      수업진행언어 KO|EN|CN|JP|ETC
 * @param {string} classLangEtc   ETC일때 언어
 * @param {string} classLevel     수업레벨 (0/1/2/3/4/5/6)
 * @param {string} classDay       수업일정 (요일) (월/화/수/목/금/토/일)
 * @param {string} classTimeStart 수업일정 (시작시간)
 * @param {string} classTimeEnd   수업일정 (종료시간)
 * @param {number} classDuration  수업구성 - 수업 시간 (분)
 * @param {number} classRound     수업구성 - 수업 시수 (회)
 * @param {string} classDetail    수업 상세 소개
 * @param {string} classCurri     수업 커리큘럼
 * 
 * @param {string} tblMemberTeacherIdx  선생님 코드번호
 * @param {string} teacherID            선생님 이메일
 * @param {string} teacherName          선생님 이름
 * @param {string} profilesDetail       선생님 소개말
 * @param {string} movURL               선생님 소개 url
 */
const ClassItem = ({item}) => {
  const { t } = useTranslation('aliveClassPage');
  let { handleModal } = React.useContext(ModalContext);
  const language = localStorage.getItem('i18nextLng');

  const [classLevel, setClassLevel] = useState('Lv.0');
  const [levelType, setLevelType] = useState('type1');
  const [classLang, setClassLang] = useState('English');
  const [classDay, setClassDay] = useState([]);
  const [priceCost, setPriceCost] = useState('$0.00');
  const [salesStore, setSalesStore] = useState('$0.00');
  const [discount, setDiscount] = useState('$0.00');

	const token = useSelector((state) => state.user.token);
  const isLogin = useLoginStatus();
  const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();

	const [vcProductId, setVCProductId] = React.useState(-1);
	const [
		{ loading: productLoading, data: productData },
		fetchProduct,
	] = useAxios(productApi.getVideoClassProduct, [], true);
	const [
		{ data: myVCData },
		fetchMyVCPaymentList
	] = useAxios(() => userApi.fetchMyPaymentListByItemType('VIDEOCLASS', token), [], true);

	useEffect(() => {
		if(productData){
			const name = (language) => {
				switch(language){
					case 'ko-KR':
						return productData.title;
					case 'en-US':
						return productData.titleEN;
					case 'zh-CN':
						return productData.titleCN;
					case 'ja-JP':
						return productData.titleJP;
					default:
						return productData.title;
				};
			}

			const videoItem = {
				id: productData.productId, //data.id,
				type: 'Video Class',
				name: name(language),
				price: productData.salesStoreWon,
				priceDollar: productData.salesStoreDollar,
				discountCost: productData.priceCostWon - productData.salesStoreWon,
				discountCostDollar: productData.priceCostDollar - productData.salesStoreDollar,
			};

			dispatch(deleteAllItem());
			dispatch(addItem(videoItem));
			history.push({
				pathname: `/payment/video-class`,
			})
		}
	}, [productData]);

	useEffect(() => {
		if(myVCData && vcProductId >= 0){
			const orderList = myVCData.filter((data)=>data.productIdx === vcProductId);
			if(orderList.length > 0){
				//구매불가능
				handleModal(
					<ConfirmModal
						tMessage={t('doublePaymentModal.title')}
						tButton={t('doublePaymentModal.confirm')}
					/>
				)
				return;
			}else{
				//구매가능
				fetchProduct(vcProductId, token)
			}
		}
	},[myVCData, vcProductId])

  const clickDetail = () => {
    handleModal(
      <ClassDetailModal
        item={item}
        onApply={clickApply}
      />, true, 1060);
  }

	const clickApply = () => {
		if (!isLogin) {
			handleModal(<Login />, false, 420, location.pathname);
			return false;
		}

		setVCProductId(item.tblProductIdx);
		fetchMyVCPaymentList(token);
    return true;
	}

  useEffect(()=>{
    //수업레벨
    if(item.classLevel){
      const levelArr = item.classLevel.split(/,\s|,/);
      if (levelArr.length === 1){
        setClassLevel(`Lv. ${levelArr[0]}`);
      } else if (levelArr.length === 2){
        setClassLevel(`Lv. ${levelArr[0]}-${levelArr[1]}`);
      } else if (levelArr.length > 2){
        setClassLevel(`Lv. ${levelArr[0]}-${levelArr[levelArr.length-1]}`);
      }
      
      switch (levelArr[levelArr.length-1]) {
        case '0':
          setLevelType('type1');
          break;
        case '1':
        case '2':
          setLevelType('type2');
          break;
        case '3':
        case '4':
          setLevelType('type3');
          break;
        case '5':
        case '6':
          setLevelType('type4');
          break;
        default:
          setLevelType('type1');
          break;
      }
    }

    //수업언어
    if(item.classLang){
      if(language === 'ko-KR'){
        if (item.classLang === 'KO'){
          setClassLang('한국어');
        }else if (item.classLang === 'EN'){
          setClassLang('영어');
        }else if (item.classLang === 'CN'){
          setClassLang('중국어');
        }else if (item.classLang === 'JP'){
          setClassLang('일본어');
        }else if (item.classLang === 'ETC'){
          setClassLang(item.classLangEtc);
        }
      }else{
        if (item.classLang === 'KO'){
          setClassLang('Korean');
        }else if (item.classLang === 'EN'){
          setClassLang('English');
        }else if (item.classLang === 'CN'){
          setClassLang('Chinese');
        }else if (item.classLang === 'JP'){
          setClassLang('Japanese');
        }else if (item.classLang === 'ETC'){
          setClassLang(item.classLangEtc.charAt(0).toUpperCase() + item.classLangEtc.slice(1));
        }
      }
    }

    //수업요일
    if(item.classDay){
      const dayArr = item.classDay.split(/,\s|,/);
      const arr = [];
      dayArr.map((day, index)=>{
        if(language === 'ko-KR'){
          if(day === '월'){
            arr.push('월요일');
          }else if(day === '화'){
            arr.push('화요일');
          }else if(day === '수'){
            arr.push('수요일');
          }else if(day === '목'){
            arr.push('목요일');
          }else if(day === '금'){
            arr.push('금요일');
          }else if(day === '토'){
            arr.push('토요일');
          }else if(day === '일'){
            arr.push('일요일');
          }
        }else{
          if(day === '월'){
            arr.push('Mon');
          }else if(day === '화'){
            arr.push('Tue');
          }else if(day === '수'){
            arr.push('Wed');
          }else if(day === '목'){
            arr.push('Thu');
          }else if(day === '금'){
            arr.push('Fri');
          }else if(day === '토'){
            arr.push('Sat');
          }else if(day === '일'){
            arr.push('Sun');
          }
        }
      })
      setClassDay(arr);
    }

    //정가
    if(item.priceCost){
      if(language === 'ko-KR'){
        setPriceCost(`￦${item.priceCost.toLocaleString('ko-KR')}`);
      }else {
        if(item.priceCost%1 !== 0){
          setPriceCost(`$${item.priceCost}`);
        } else {
          setPriceCost(`$${item.priceCost}.00`);
        }
      }
    }
    
    //판매가
    if(item.salesStore){
      if(language === 'ko-KR'){
        setSalesStore(`￦${item.salesStore.toLocaleString('ko-KR')}`);
      }else {
        if(item.salesStore%1 !== 0){
          setSalesStore(`$${item.salesStore}`);
        } else {
          setSalesStore(`$${item.salesStore}.00`);
        }
      }
    }

    //판매가 할인
    if(item.discountType && (item.discountCost || item.discountCost === 0)){
      if(item.discountType === 'PER'){
        setDiscount(`${item.discountCost*100}%`);
      }else{
        if(language === 'ko-KR') setDiscount(`￦${item.discountCost.toLocaleString('ko-KR')}`);
        else setDiscount(`$${item.discountCost}`);
      }
    }
  }, [item])

  return (
    <>
      <Styled.Wrapper>
        <Styled.Product onClick={clickDetail}
          > {/*1060: 모달창 최대 너비(임시)*/}
          <Styled.Title className={levelType}>{/*레벨 - 0 입문 : type1 / 1-2 초급 : type2 / 3-4 중급 : type3 / 5-6 고급 : type4 */}
            {/* <div className='group'>{item.classType === "KO" ? 'KOREAN' : item.classType}</div> */}
            <div className='group'>{item.classType === "KO" ? 'Writing' : 'Reading'}</div>
            <div className='title'>{classLevel} <br />{item.productTitle}</div>
          </Styled.Title>
          <Styled.Info>
              <div className='teacher'>{item.teacherName}</div>
              <div>{language === 'ko-KR' ? `${classLang}로 수업` : `In ${classLang}`}</div>
              <div>
                {classDay.map((day)=>`${day}, `)}
                {item.classTimeStart} (KST)
              </div>
          </Styled.Info>
          <Styled.Price>
            <span className={'listPrice' + (item.discountCost ? '' : ' nodiscount')}>
              {item.discountCost ? priceCost : ''}
            </span>
            <span className='total'><strong>{salesStore}</strong> {item.discountCost ? `(${discount}↓)` : ''}</span>
          </Styled.Price>
        </Styled.Product>
        <Styled.Button onClick={clickDetail}>{t('button.apply')}</Styled.Button>
      </Styled.Wrapper>
    </>
  );
};

export default ClassItem;

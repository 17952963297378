import styled from 'styled-components';
import ContentsWrapper from 'component/atoms/ContentsWrapper';
import { Swiper } from 'swiper/react';
import { Link } from 'react-router-dom';
import arrowLeft from 'assets/images/arrow2_left_gray.svg';
import arrowRight from 'assets/images/arrow2_right_gray.svg';


const StyledEventNotice = {
  Wrapper: styled.div`
    background-color: #393c40;
  `,
  Inner: styled(ContentsWrapper)`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
  `,
  H2: styled.h2`
    flex: 0 0 8em;
    padding-top: 0.8em;
    padding-left: 1em;
    font-size: 1.8em;
    color: #c9cbd1;
    font-weight: bold;

    &:after {
      content: "";
      width: 2px;
      height: 0.9em;
      display: inline-block;
      background:#5e5f61;
      margin-left: 1em;
      vertical-align: middle;
    }
    @media (max-width: 414px) {
      flex: 0 0 6.4em;
    }
  `,
  Swiper: styled(Swiper)`
    flex: 1;
    height: 6em;
    text-align:left;
    margin: 0;
    padding-right: 14em;
    .swiper-slide {
      padding-top: 1.63em;
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: inline-block;
      position: absolute;
      top: 0;
      width: 6em;
      height: 6em;
      border-right:1px #2b2e32 solid;
      cursor: pointer;
    }
    .swiper-button-prev {
      right: 6em;
      border-left:1px #2b2e32 solid;
      background: url(${arrowLeft}) no-repeat center transparent;
      background-size: 2rem;
    }
    .swiper-button-next {
      right: 0;
      background: url(${arrowRight}) no-repeat center transparent;
      background-size: 2rem;
    }
  `,
  Link: styled(Link)`
    display:block;
    max-width: 100%;
    font-size: 1.6em;
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
  `,
};

export default StyledEventNotice;

import React, { useEffect } from 'react';
import learningMaterialApi from 'api/learningMaterial';
import { useAxios } from 'common/hook/useAxiosHook';
import { Detail } from 'container/LearningMaterialPage/styled';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Login from 'component/user/Login';
import useLoginStatus from 'common/hook/useLoginStatus';
import { useLocation } from 'react-router-dom';
import { ModalContext } from 'component/Modal/modalContext';
import FreeTrialIntroModal from 'container/LearningMaterialPage/modal/FreeTrialIntroModal';

const FileButtonContainer = ({ getFileIcon, fileType, fileData, planCode }) => {
  const { id: urlId } = useParams();
  //const history = useHistory();
  const [fileInfo, fetchFile] = useAxios(learningMaterialApi.getFile, [], true);

  let { handleModal } = React.useContext(ModalContext);

  const isLogin = useLoginStatus();
  const location = useLocation();

  const token = useSelector((state) => (isLogin)? state.user.token : null);
  const userPlan = useSelector((state) => (isLogin)? state.user.data.serviceLevel : null);

  const openLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false, 420, location.pathname);
  };

  const openFreeTrialIntroModal = (e) => {
    e.preventDefault();
    handleModal(<FreeTrialIntroModal/>, false, 500);
  };

  const clickDownload = (e) => {
    if (!isLogin) {
      openLoginModal(e);
    } else {
      const userPlanCheck = (userPlan === 'FREE')? 0 : (userPlan === 'EPS')? 1 : (userPlan === 'STANDARD')? 2 : (userPlan === 'PREMIUM')? 3 :  0 ;
      const planCodeCheck = (planCode === 'FREE')? 0 : (planCode === 'EPS')? 1 : (planCode === 'STANDARD')? 2 : (planCode === 'PREMIUM')? 3 :  0 ;

      if (userPlanCheck >= planCodeCheck) {
        fetchFile(urlId, fileData.idx, token);
      } else {
        toast.error('Upgrade Your Plan');
        openFreeTrialIntroModal(e);
      }
    }
  };

  useEffect(() => {
    if (fileInfo && fileInfo.data) {
      const {
        data: { data: file },
      } = fileInfo;

      const url = new Blob([file], { type: file.type });
      const reader = new FileReader();
      const link = document.createElement('a');
      reader.readAsDataURL(url);
      reader.addEventListener('loadend', () => {
        link.classList.add('download-link');
        link.href = reader.result;
        link.setAttribute('download', fileData.name);
        document.body.appendChild(link);
        link.click();
      });
    }
    return () => {
      const link = document.querySelector('.download-link');
      if (link) {
        document.body.removeChild(link);
      }
    };
  }, [fileInfo]);

  return (
    <>
      <Detail.FileDown
        key={fileData?.idx}
        className={getFileIcon(fileType)}
        onClick={clickDownload}
      >
        {/* TODO : file Size는 있지만 이름이 없는 경우 텍스트 노출이 필요함 */}
        <Detail.FileName>
          {fileData && fileData.originalFileName
            ? fileData.originalFileName
            : `이름 없음.${fileType.toLowerCase()}`}
        </Detail.FileName>
      </Detail.FileDown>
    </>
  );
};

export default FileButtonContainer;

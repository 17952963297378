import React, { useEffect } from 'react';
import Style from './styled/index.style';
//component
import SummaryBanner from './SummaryBanner';
import PlanSection from './PlanSection';

import wbmKoreaApi from './api/wbmKoreaApi';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';

import BannerAudio from 'container/WbmKoreaPage/BannerAudio'
import BannerEvent from 'container/WbmKoreaPage/BannerEvent'
import BannerNotice from 'container/WbmKoreaPage/BannerNotice'

const WbmKoreaPage = () => {
  const { t } = useTranslation('wbmKoreaPage');

  const productId = 1204;
  const productId2 = 1249;
  const productId3 = 1261;
  const productId4 = 1150;
  const productId5 = 1151;
  const productId6 = 1162;
  const productId7 = 1163;
  const productId8 = 1168;
  const productId9 = 1169;
  const lectureId = useSelector((state) => state.wbmKorea.lectureId);

  const [{ data: overviewData }, refreshGetOverview] = useAxios(
    () => wbmKoreaApi.fetchOverview(productId),
    [],
    true
  );


  useEffect(() => {
    refreshGetOverview();
  }, [productId]);
    useEffect(() => {
        refreshGetOverview();
    }, [productId2]);
    useEffect(() => {
      refreshGetOverview();
    }, [productId3]);
    useEffect(() => {
      refreshGetOverview();
    }, [productId4]);
    useEffect(() => {
      refreshGetOverview();
    }, [productId5]);
    useEffect(() => {
      refreshGetOverview();
    }, [productId6]);
    useEffect(() => {
      refreshGetOverview();
    }, [productId7]);
    useEffect(() => {
      refreshGetOverview();
    }, [productId8]);
    useEffect(() => {
      refreshGetOverview();
    }, [productId9]);


  //GNB 영역 제거
  useEffect(()=>{
    const root = document.getElementById('root');
    root.style = "padding-top: 0rem;";
    return () => {
      root.style = "padding-top: 9rem;";
    };
  },[])

  // console.error('test: urlProductId', productId);
  // console.error('test: overviewData !!!!', overviewData);


  return (
    <>
      <SummaryBanner t={t} />
      <PlanSection
          t={t}
          productId={productId}
          productId2={productId2}
          productId3={productId3}
          productId4={productId4}
          productId5={productId5}
          productId6={productId6}
          productId7={productId7}
          productId8={productId8}
          productId9={productId9}
          overview={ lectureId === 0 && overviewData }
      />
      <Style.Container>
      {/*  <BannerAudio />*/}
      {/*  <BannerEvent />*/}
        <BannerNotice />
      </Style.Container>
    </>
  );
};

export default WbmKoreaPage;

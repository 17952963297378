import React from 'react';
import Styled from './index.style';
import Calendar from 'component/Calendar';

const MonthlySlide = ({ pinDateList, t }) => {
  return (
    <>
      <Styled.H3>{t('dashBoard.scoreSection.attendance.title')}</Styled.H3>
      <Styled.Box>
        <Calendar pinDateList={pinDateList} />
      </Styled.Box>
    </>
  );
};

export default MonthlySlide;

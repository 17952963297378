import React from 'react';
import Style from "../styled/DetailView.style";

const DetailView = ({ t, article }) => {
  const btnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <Style.DetailWrap>
        <Style.TitleArea>
          <Style.Title>{article.title}</Style.Title>
          <Style.Date>{article.regDate}</Style.Date>
          {
            article.fileName &&
            <Style.Attachment>
              <Style.BtnDownload href={`${article.filePath}/${article.fileName}`} download>{article.originalFileName}</Style.BtnDownload>
            </Style.Attachment>
          }
        </Style.TitleArea>
        <Style.ContentArea dangerouslySetInnerHTML={{ __html: article.content }}>
        </Style.ContentArea>
      </Style.DetailWrap>
      <Style.BtnWrap>
        <Style.BtnTop onClick={() => btnTop()}>{t('button.top')}</Style.BtnTop>
      </Style.BtnWrap>
    </>
  );
};
export default DetailView;
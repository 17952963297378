import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { actions } from 'component/user/state';
import { ModalContext } from 'component/Modal/modalContext';
import CreateSNSAccount from 'component/user/CreateSNSAccount';
import LandingPage from 'container/LandingPage';
import useErrorToastify from 'common/hook/useErrorToastify';
import useLoginStatus from 'common/hook/useLoginStatus';

export default function OAuth2RedirectHandler(props) {
  const location = useLocation();
  const { handleModal } = React.useContext(ModalContext);

  const getUrlParameter = (name) => {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    const results = regex.exec(props.location.search);
    return results === null
      ? ''
      : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  const token = getUrlParameter('token');
  const error = getUrlParameter('error');
  const isTerms = getUrlParameter('isTerms') === 'true';
  const isLogin = useLoginStatus();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) return;
    if (isTerms) {
      dispatch(actions.setAuth(token));
      dispatch(actions.fetchUser(token));
    } else {
      handleModal(<CreateSNSAccount token={token} isTerms={isTerms} />, false);
    }
  }, []);

  const history = useHistory();
  useEffect(() => {
    if (isTerms && isLogin) {
      history.replace('/');
      handleModal();
    }
  }, [isLogin]);

  useErrorToastify(error);

  return <LandingPage />;
}

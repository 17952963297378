import React from 'react';
import Style from '../styled/TestPaper.style';
import Question from 'container/MockTestPage/Components/Question';
import { useAxios } from 'common/hook/useAxiosHook';
import mockTestApi from 'api/mockTests';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import ResultOmrCard from 'container/MockTestPage/Components/ResultOmrCard';

const Content = () => {
  const { productId, unitId } = useParams();

  const token = useSelector((state) => state.user.token);
  const [{ data, loading }] = useAxios(
    () => mockTestApi.getResults({ token, productId, unitId }),
    [productId, unitId]
  );
  if (data)
    return (
      <>
        <Style.MockTestContainer>
          <Style.QuestionContainer>
            {data.map((data) => {
              return <Question key={data.unitValuationId} data={data} result />;
            })}
          </Style.QuestionContainer>
          <ResultOmrCard data={data} />
        </Style.MockTestContainer>
      </>
    );
  return <></>;
};

export default React.memo(Content);

import React from 'react';
import Styled from './styled/CreateEmailAccount.style';
import StyledModal from 'component/Modal/styled/Modal.style';
import StyledModalForm from 'component/form/ModalForm.style';
import StyledLogin from './styled/Login.style';
import RequiredConfirmEmail from './RequiredConfirmEmail';
import { ModalContext } from 'component/Modal/modalContext';
import { Link } from 'react-router-dom';

import {
  FACEBOOK_AUTH_URL,
  GOOGLE_AUTH_URL,
  WECHAT_AUTH_URL,
} from 'common/constant';
import { useTranslation, Trans } from 'react-i18next';
import Login from 'component/user/Login';

export default function CreateAccount({landingPage}) {
  let { handleModal } = React.useContext(ModalContext);
  const { t } = useTranslation('signupModal');

  const changeCreatAccountFromModal = (e) => {
    e.preventDefault();
    handleModal(<RequiredConfirmEmail t={t} />, false, 540);
  };
  const openLoginModal = (e) => {
    e.preventDefault();
    handleModal(<Login />, false);
  };
  return (
    <>
      {landingPage ? <></> : <StyledModal.H2>{t('createAccount.title')}</StyledModal.H2>}

      <StyledModalForm.DivWrapper>
        <StyledModalForm.Button onClick={changeCreatAccountFromModal}>
          {t('createAccount.createBtn')}
        </StyledModalForm.Button>

        <StyledLogin.SocialLogin dataOr={t('createAccount.or')} landingPage={landingPage}>
          <StyledLogin.DataOr className='label'>
            {t('createAccount.or')}
          </StyledLogin.DataOr>
          <StyledLogin.GoogleButton href={GOOGLE_AUTH_URL}>
            {t('createAccount.loginButtons.google')}
          </StyledLogin.GoogleButton>
          <StyledLogin.FacebookButton href={FACEBOOK_AUTH_URL}>
            {t('createAccount.loginButtons.facebook')}
          </StyledLogin.FacebookButton>
          <StyledLogin.WechatButton href={WECHAT_AUTH_URL}>
            {t('createAccount.loginButtons.wechat')}
          </StyledLogin.WechatButton>
        </StyledLogin.SocialLogin>
      </StyledModalForm.DivWrapper>

      <Styled.Bottom.Login landingPage={landingPage}>
        {t('guidance2')}{' '}
        <Link to='#none' onClick={openLoginModal}>
          {t('guidance2Link')}
        </Link>
      </Styled.Bottom.Login>

      <Styled.Bottom.Terms landingPage={landingPage}>
        <Trans
          t={t}
          i18nKey='guidance3'
          components={{
            link1: <Link to='/terms/terms-of-privacy' target='_blank' />,
            link2: <Link to='/terms' target='_blank' />,
          }}
        />
      </Styled.Bottom.Terms>
    </>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import useInterval from 'common/hook/useInterval';
import { useAxios } from 'common/hook/useAxiosHook';
import LoadingOverlay from 'component/atoms/LoadingOverlay';
import VideoPlayer from 'component/Player';
import { ViewContext } from 'container/CourseViewPage';
import contentsApi from 'api/contents';
import productApi from 'api/product';
import { ModalContext } from 'component/Modal/modalContext';
import DefaultTextModal from 'component/Modal/DefaultTextModal';
import { useTranslation } from 'react-i18next';
import EndPop from './EndPop';
/**
 * 코스 플레이어 컴포넌트
 */
const CourseViewer = React.forwardRef(({postLogData, nextLectureId}, playerRef) => {
  const { productId, lectureId } = useParams();
  const token = useSelector((state) => state.user.token);
  const { t } = useTranslation('viedoDetailPage');

  const { getVideoByType, postLogApi, updateLogApi } = contentsApi;

  const [
    { loading: videoUrlLoading, data: videoUrlData },
    fetchVideoByType,
  ] = useAxios(getVideoByType, [], true);
  const [
    { loading: overViewLoading, data: overViewData },
    fetchOverview,
  ] = useAxios(productApi.getOverview, [], true);

  const [{ data: videoLogData }, fetchVideoLog] = useAxios(
    updateLogApi,
    [],
    true
  );

  const { lectureState, videoQualityState, isPlayState } = useContext(
    ViewContext
  );

  const [{ data: lectureData }] = lectureState;
  const [videoQuality, setVideoQuality] = videoQualityState;
  const [isPlay, setIsPlay] = isPlayState;
  const [isEnded, setIsEnded] = useState(false);
  const [lastPosition, setLastPosition] = useState(0);
  const [isPop, setIsPop] = useState(false);  // 20231126 추가

  // 이전 시점 이어보기
  useEffect(() => {
    lectureData && setLastPosition(lectureData?.lastPosition);
    return () => {};
  }, [lectureData]);


  // 로그 업데이트
  const handleUpdateLog = () => {
    if (parseInt(lectureId) === 0) return;
    postLogData &&
      fetchVideoLog(
        {
          id: postLogData.id,
          productId,
          chapterLectureId: lectureId,
          playPosition: playerRef.current?.getCurrentTime(),
        },
        token
      );
  };
  useInterval(handleUpdateLog, isPlay ? 5000 : null);

  const history = useHistory();
  const { handleModal } = React.useContext(ModalContext);
  useEffect(() => {
    if (!videoLogData?.duplicated) return;
    history.replace(`/courses/detail/${productId}`);
    handleModal(<DefaultTextModal title={t('duplicatedView')} />);
  }, [videoLogData]);

  useEffect(() =>{
     if(isEnded){
      handleUpdateLog()
      setIsPop(true); // 20231126 추가
     }
  }, [isEnded]);

  // 기기별 화질 선택 ()
  useEffect(() => {
    if (!videoQuality) {
      if (isMobile) setVideoQuality('720P');
      else setVideoQuality('1080P');
      return;
    }
    //화질 변경시 current play time으로 변경
    lectureData && setLastPosition(playerRef?.current.getCurrentTime);

    return () => {};
  }, [setVideoQuality, videoQuality]);

  // video url 호출
  useEffect(() => {
    if (parseInt(lectureId) === 0) {
      fetchOverview(productId);
      return;
    }

    if (videoQuality) {
      fetchVideoByType(lectureId, videoQuality, token);
    }
    return () => {
      setIsPlay(false);
      handleUpdateLog();
    };
  }, [
    fetchOverview,
    fetchVideoByType,
    lectureId,
    productId,
    token,
    videoQuality,
  ]);
  const [videoUrl, setVideoUrl] = useState(null);
  useEffect(() => {
    if (parseInt(lectureId) === 0 && overViewData) {
      setVideoUrl(overViewData.ot);
      return;
    }
    videoUrlData && setVideoUrl(videoUrlData.url);
    return () => {};
  }, [videoUrlData, overViewData]);

  return (
    videoUrl && (
      <>
        <LoadingOverlay isLoading={videoUrlLoading || overViewLoading} />

        <VideoPlayer
          ref={playerRef}
          url={videoUrl}
          title={lectureData?.title}
          lastPosition={lastPosition}
          setIsPlay={setIsPlay}
          setIsEnded={setIsEnded}
          videoQualityState={videoQualityState}
        />

        {isPop && <EndPop setIsPop={setIsPop} nextLectureId={nextLectureId}/>}
      </>
    )
  );
});

export default CourseViewer;

import styled from "styled-components";
import { Swiper } from "swiper/react";
import Style from "component/ui/card/styled/CurriculumCard.style";
import arrowRightIcon from "assets/images/arrow_right.svg";
import selected from "assets/images/ico_leveltest_selected.png";
import checked from "assets/images/ico_leveltest_selected_check.png";
import { GoPlus } from "react-icons/go";

const CardNew = {
  SelectContainer: styled.div`
    position: relative;
    margin: 1em auto;
    width: 63em;
    z-index: 2;

    &::after {
      content: "";
      display: block;
      clear: both;
    }

    .order-by-select {
      float: right;
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  CurriculumCardWrap: styled.div`
    position: relative;
    width: 63em;
    margin: 4.2em auto 4em;

    @media (max-width: 767px) {
      width: 100%;
    }
  `,
  CurriculumCarNewWrap:styled.div`
     display: flex;
     flex-direction: column;
     gap:2rem;
     padding-bottom:4rem;
  `,
  Wrapper: styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    gap:4rem;
    @media (max-width: 767px) {
      flex-direction: column;
      width:100%;
      gap:2rem;
    }
  `,
  ImgBox: styled(Style.ImgContainer)`
    width: 29.5rem;
    height:21rem;
    &:hover::before,
    &.selected::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(10, 10, 11, 0.9);
      border-radius: 0.6em;
      z-index: 1;
    }



    &:hover::after,
    &.selected::after {
      content: "";
      position: absolute;
      top: 2rem;
      left: 2rem;
      display: block;
      width: 5.1em;
      height: 5.1em;
      background-repeat: no-repeat;
      background-position: 0 0;
      z-index: 1;
    }

    &:hover::after {
      background-image: url(${selected});
      background-size: cover;
    }

    &.selected::after {
      background-size: cover;
      background-image: url(${checked});
    }

    img {
      border-radius: 6px;
    }

    @media (max-width: 767px) {
      width:100%;
      height:auto;
    }

  `,
  Badge: styled.span`
    display: inline-block;
    position: absolute;
    top: 0.8em;
    right: 1em;
    padding: 0.25em 0.5em;
    border-radius: 2px;
    background-color: ${({ bgColor }) => bgColor};
    font-size: 1.3em;
    font-weight: 700;
  `,
  Content: styled(Style.Content)`
    position: relative;
    cursor: pointer;
    flex:1;
    > a{
      display:flex;
      flex-direction: column;
      gap: 1.5rem;
    }
    &:hover .more::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #ffb500;
    }
  `,
  PlusBtn: styled.span.attrs(() => ({
    children: (
      <>
        SEE MORE <GoPlus />
      </>
    ),
    className: "more",
  }))`
    position: relative;
    display: inline-block;
    margin-top: 1em;
    font-size: 1.4em;
    color: #ffb500;
    font-weight: 400;
    width:8.6rem
  `,
  Title: styled(Style.Title)`
  font-size:2.4rem;
  color:#f2f2f2;
  line-height:1;
    padding-right: 1.333em;
  `,
  Infos: styled.div``,
  Info: styled.p`
    font-size: 1.6rem;;
    color:#d1d1d1;
  `,
  Desc: styled(Style.Desc)``,
};

export default CardNew;




import { PAYMENT_METHOD } from "common/constant";
import { ModalContext } from "component/Modal/modalContext";
import React, {useEffect, useRef, useState} from "react";
import PayPalBtn from './PayPalBtn'
import {useAxios} from "../../common/hook/useAxiosHook";
import orderApi from "../../api/order";
import {useSelector} from "react-redux";
import { useHistory, useLocation } from "react-router-dom";


const REACT_APP_OVERSEAS_PAYMENT_URL =
    process.env.REACT_APP_OVERSEAS_PAYMENT_URL;

const OverseasPayPaypalForm = ({ data, priceCurrency, pginfo, book = false }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { OVERSEA_CREDIT, PAYPAL, UNION_PAY } = PAYMENT_METHOD;
  const { handleModal } = React.useContext(ModalContext);
  const inputFormRef = useRef(null);
  const [isCancel, setIsCancel] = useState(false);

  const token = useSelector((state) => state.user.token);
  const [cancelResult, fetchCancelOrder] = useAxios(orderApi.cancelPaypalOrder, [], true);
  const [approveResult, fetchApproveOrder] = useAxios(orderApi.confirmPaypalOrder,[],true);


  const handleSubmit = () => {
    inputFormRef.current?.click();
  };

  useEffect(() => {
    if (!data || !inputFormRef) return;
    if (pathname === "/setting" || book) {
      handleSubmit();
      return;
    } else {

        handleModal(
            <PayPalBtn
                createSubscription={paypalSubscribe}
                onApprove={paypalOnApprove}
                catchError={paypalOnError}
                onError={paypalOnError}
                onCancel={paypalOnError}
            />
            ,
            false
        );

    }
  }, [inputFormRef]);

  useEffect(() => {
   //console.log(cancelResult);

    if (cancelResult && cancelResult.data != null) {
      handleModal();
      window.location.reload();
    }

  }, [cancelResult]);

  useEffect(() => {
    if (approveResult && approveResult.data != null) {
      //console.log(approveResult.data);
      handleModal();
      history.push(`/order-complete/plan/${data.storeOrderNo}`);
    }

  }, [approveResult]);


  if (!data) return <div></div>;
  const {
    storeId,
    custom,
    serviceName,
    payerEmail,
    payAmt,
    storeOrderNo,
    payerId,
    hash,
    timestamp,
    returnUrl,
    backUrl,
    recurringType,
    intervalType,
    intervalCnt,
    totalCycles,
  } = data;

  const isEmpty = (data) => {
    if (typeof data === "object") {
      if (JSON.stringify(data) === "{}" || JSON.stringify(data) === "[]") {
        console.log("Null");
        return true;
      } else if (!data) {
        console.log("Null");
        return true;
      }
      console.log("Not Null");
      return false;
    } else if (typeof data === "string") {
      if (!data.trim()) {
        console.log("Null");
        return true;
      }
      return false;
    } else if (typeof data === "undefined") {
      console.log("Null");
      return true;
    } else {
      console.log("Not Null");
      return false;
    }
  };

  const paypalSubscribe = (data1, actions) => {
    return actions.subscription.create({
      'plan_id': data.payAmt
      //'plan_id': "P-2UX55691S46699737MGFWQUQ", // 1일 trial + std "P-8N519355M6257174DMGOJHYY"
    });
  };

  const paypalOnError = (err) => {
    console.log(err);
    // redirect page
    console.log("ErrorOrCancel: backUrl ==>" + data.backUrl); // 결제 취소시 연결

    setIsCancel(true);
    let returnCode = fetchCancelOrder(data.backUrl, token);
    console.log(returnCode);

  }

  const paypalOnApprove = (paypalData, detail) => {
    console.log("Payapl approved");
    console.log(paypalData);
    console.log(detail);
    // facilitatorAccessToken: "A21AAI8sUnlork8uYiselQZc_GDCaUohclQUTAn2ZWL4yknX5ak13PTKKztkzVTsGDCaSOfXAlRqDLG0AAGAioRLwysRkU8dw"
    // orderId: "8TX75573TH503534R"
    // subscriptionID(recurring_payment_id): I-5E6WH3RKL1T6 - DB에 기록해 두어야 callback 처리가 가능함.
    console.log("paypalOnApprove: orderId ==>" + data.storeOrderNo); // 결제 완료 후 연결

    const confirmPaypalOrderData = {
      orderId: data.storeOrderNo,
      serviceName: data.serviceName,
      paypalResponseData: paypalData
    };

    fetchApproveOrder(confirmPaypalOrderData, token);
  };

  return (
      <></>
  );
};

export default OverseasPayPaypalForm;

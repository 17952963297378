import React from 'react';
import Style from './styled/PaymentListSection.style';
import { useAxios } from 'common/hook/useAxiosHook';
import { useSelector } from 'react-redux';
import userApi from 'api/user';
import { EmptyList } from 'component/atoms/EmptyList';
import { Trans, useTranslation } from 'react-i18next';

const PaymentListSection = () => {
  const { t } = useTranslation('settingPage');
  const token = useSelector((state) => state.user.token);
  const [getMyPaymentList] = useAxios(
    () => userApi.fetchMyPaymentList(token),
    []
  );
  const { data } = getMyPaymentList;

  //orderId
  return (
    <Style.Container>
      <Style.Title>
        <Trans t={t}>paymentList.title</Trans>
      </Style.Title>
      {data && data.length > 0 ? (
        data.map((list) => {
          return (
            <Style.List key={list.orderId}>
              <div className='title'>{list.title}</div>
              <div className='creditcard'>{list.paymentMethod}</div>
              <div className='date'>{list?.confirmedAt?.slice(0, 10)}</div>
              <div className='status'>{list.statusText}</div>
              <Style.Detail className='detail' to={`/setting/invoice/${list.orderId}`}>
                Detail
              </Style.Detail>
            </Style.List>
          );
        })
      ) : (
        <EmptyList />
      )}
    </Style.Container>
  );
};

export default PaymentListSection;

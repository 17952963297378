import StyledUtil from 'common/styled/Util.style';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledHotTopik = {
  Wrapper: styled.div`
    @media (max-width: 767px) {
    }
  `,
  Ul: styled.ul`
    counter-reset: hot-topik;
    border-width: 1px 0;
    border-style: solid;
    border-color: #454545;
  `,
  Li: styled.li`
    counter-increment: hot-topik;
    &:not(:first-child) {
      border-top: 1px solid #222;
    }
  `,
  Link: styled(Link)`
    position: relative;
    display: block;
    padding: 2.6em 5.5em 2.6em 7.6em;
    &:before {
      content: counter(hot-topik);
      position: absolute;
      top: 50%;
      left: 2.1rem;
      font-size: 1.4em;
      margin-top: -1.5rem;
      width: 3rem;
      border: 1px solid #fff;
      border-radius: 100px;
      font-weight: 400;
			text-indent: 0.1rem;
      text-align: center;
      color: #fff;
      line-height: 3rem;
    }
  `,
  Title: styled.div`
    ${StyledUtil.textEllipsis}
    font-size: 1.8em;
    font-weight: 500;
  `,
  Category: styled.div`
    font-size: 1.4em;
    color: 8e9199;
  `,
};

export default StyledHotTopik;

import React from 'react';
import { useHistory, useLocation } from "react-router-dom";
import checkState from 'container/LevelTestPage/common/checkState';
import LevelTestHeader from 'container/LevelTestPage/common/LevelTestHeader';
import { IntroductionService, Button } from 'container/LevelTestPage/styled';
import { useTranslation, Trans } from 'react-i18next';

const Index = () => {
  const { t } = useTranslation('levelTestPage');

  checkState();

  const history = useHistory();
  const { state } = useLocation();

  const handleClick = () => {
    history.push({
      pathname: '/level-test/check-level-test',
      state: state,
    });
  };

  return (
    <>
      <LevelTestHeader
        title={t('testGuidance.title')}
        text={t('testGuidance.subtitle')}
      />

      <IntroductionService.Content>
        <IntroductionService.Introduction className="num">
          <Trans t={t}>testGuidance.desc</Trans>
        </IntroductionService.Introduction>
        <IntroductionService.Introduction className="start">
          <Trans t={t}>testGuidance.guidance2</Trans>
        </IntroductionService.Introduction>
        <IntroductionService.Introduction className="submit">
          <Trans t={t}>testGuidance.guidance3</Trans>
        </IntroductionService.Introduction>
        <IntroductionService.Introduction className="end">
          <Trans t={t}>testGuidance.guidance4</Trans>
        </IntroductionService.Introduction>
      </IntroductionService.Content>

      <Button.ButtonGroup className='center'>
        <Button.Button onClick={handleClick}>{t('testGuidance.button')}</Button.Button>
      </Button.ButtonGroup>
    </>
  );
}

export default Index;

import React, { useEffect, useState, useMemo } from "react";
import Style from "./styled/Process.style";
import Table from "./Table";
import makeAnimated from "react-select/animated";
import { ModalContext } from "component/Modal/modalContext";
import OrderSummary from "./OrderSummary";
import RadioButton from "component/ui/RadioButton";
import AddressApiModal from "./AddressApiModal";
import { isMobile } from "react-device-detect";
import useForm from "./Formhook/useFormHook";
import validation from "./Formhook/validation";
import Term from "component/Term";
import { useAxios } from "common/hook/useAxiosHook";
import orderApi from "api/order";
import { useSelector, useDispatch } from "react-redux";
import AddressContainer from "container/PaymentPage/AddressContainer";
import { toast } from "react-toastify";
// import CurrencySelectBox from "component/molecules/CurrencySelectBox";
import Coupon from "container/PaymentPage/Coupon";
import {
  CURRENCY,
  MOBILE_APP_USER_AGENT,
  PAYMENT_METHOD,
} from "common/constant";
import { Trans } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { actions } from 'common/state';

const animatedComponents = makeAnimated();
const Process = ({ t }) => {
  const currentCurrency = useSelector((state) => state.common.currency);
  const history = useHistory();
  const { DOMESTIC_CREDIT, OVERSEA_CREDIT, PAYPAL, UNION_PAY } = PAYMENT_METHOD;

  const [formSubmit, setFormSubmit] = useState(false);

  let { handleModal } = React.useContext(ModalContext);
  const token = useSelector((state) => state.user.token);
  const { type: itemType } = useParams();
  if (itemType !== "book" && itemType !== 'video-class') history.replace("/");

  const products = useSelector((state) => {
    if(itemType === 'book') return state.bookStore.bookItems;
    else if(itemType === 'video-class') return state.videoClassStore.videoItems;
    else return [];
  });
  const productsItems = useMemo(
    () =>
      products.map((data) => {
        return {
          productIdx: data.id,
          productQuantity: data.quantity,
          couponCode: null,
        };
      }),
    [products]
  );

  let [paymentMethod, setPaymentMethod] = useState(DOMESTIC_CREDIT);
  let [isSubmit, fetchSubmit] = useState(false);

  const [terms, setTerm] = useState(false);
  const [message, setMessage] = useState({});

  const [{ data }, refreshPostPayment] = useAxios(
    orderApi.postOrderBooks,
    [],
    true
  );
  const [{ data: videoData }, refreshPostPaymentVideo] = useAxios(
    orderApi.postOrderVideoClass,
    [],
    true
  );

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setLastPayment(itemType));
    setPaymentMethod(
      currentCurrency === CURRENCY.DOMESTIC ? DOMESTIC_CREDIT : OVERSEA_CREDIT
    );
  }, [])

  const onSubmit = () => {
    fetchSubmit(true);
  };

  let initialState = {
    name: "",
    mobileFirst: "010",
    mobileSecond: "",
    mobileLast: "",
    zoneCode: "",
    addressFirst: "",
    addressLast: "",
    shippingRequest: "",
    terms: false,
    paymentMethod: "",
    saveAddress: false,
    couponCode: "",
  };
  let {
    handleChange,
    handleSubmit,
    handleClick,
    setFormState,
    formState,
    errors,
  } = useForm(initialState, onSubmit, validation);

  useEffect(() => {
    //validation 에러 메세지
    for (const error in errors) {
      if (error) {
        toast.error(errors[error]);
        return;
      }
    }
  }, [errors]);
  useEffect(() => {
    //validation 에러 메세지
    for (const error in message) {
      if (error === "terms") {
        toast.error(message[error]);
        return;
      }
    }
  }, [message]);

  useEffect(() => {
    //구매하기 버튼 후 api 값 반환
    if (!data) return;
    if (currentCurrency === CURRENCY.DOMESTIC) {
      //국내 결제
      const userAgent = window.navigator.userAgent;
      const url = isMobile ? data.mobileUrl : data.onlineUrl;
      if (userAgent === MOBILE_APP_USER_AGENT) {
        history.push({
          pathname: "/bookstore-app-payment",
          state: {
            url: url,
            itemType: itemType
          },
        });
        return;
      } else {
        window.open(url, "payletter", "_blank");
        return;
      }
    } else {
      setFormSubmit(true);
    }
  }, [data]);

  useEffect(() => {
    //구매하기 버튼 후 api 값 반환
    if (!videoData) return;
    if (currentCurrency === CURRENCY.DOMESTIC) {
      //국내 결제
      const userAgent = window.navigator.userAgent;
      const url = isMobile ? videoData.mobileUrl : videoData.onlineUrl;
      if (userAgent === MOBILE_APP_USER_AGENT) {
        history.push({
          pathname: "/bookstore-app-payment",
          state: {
            url: url,
            itemType: itemType
          },
        });
        return;
      } else {
        window.open(url, "payletter", "_blank");
        return;
      }
    } else {
      setFormSubmit(true);
    }
  }, [videoData]);

  useEffect(() => {
    // setPaymentMethod(
    //   currentCurrency === CURRENCY.DOMESTIC ? DOMESTIC_CREDIT : OVERSEA_CREDIT
    // );
  }, [currentCurrency]);

  useEffect(() => {
    if (isSubmit) {
      //결제 데이터
      let paymentType;
      if (paymentMethod === OVERSEA_CREDIT) {
        paymentType = "CARD_INTERNATIONAL";
      } else if (paymentMethod === PAYPAL) {
        paymentType = "PAYPAL";
      } else if (paymentMethod === UNION_PAY) {
        paymentType = "UNIONPAY";
      }

      const data = {
        productForOrderRequests: productsItems,
        currency: currentCurrency,
        paymentMethod: paymentType,
        saveAddress: null,
        addressIdx: null,
        couponCode: null,
      };

      if(itemType === 'book'){
        data.deliveryInfo = {
          isSaveAddress: formState.saveAddress,
          recipient: formState.name,
          receiverZip: formState.zoneCode,
          receiverAddress1: formState.addressFirst,
          receiverAddress2: formState.addressLast,
          receiptHP: `${formState.mobileFirst}-${formState.mobileSecond}-${formState.mobileLast}`,
          deliverMemo: formState.ShippingRequest,
        };
        refreshPostPayment(data, token);
      }else if(itemType === 'video-class'){
        setMessage({});
        refreshPostPaymentVideo(data, token);
      }
    }
  }, [isSubmit]);

  const openModal = (e, type) => {
    e.preventDefault();
    if (type === "api") {
      handleModal(<AddressApiModal width="98%" onComplete={handleAddress} />);
    } else if (type === "terms") {
      handleModal(<Term type={type} />, false, 1060);
    }
  };

  const handleAddress = (data) => {
    formState.zoneCode = data.zonecode;
    formState.addressFirst = data.address;
  };

  const handlePaymentMethod = (value) => (e) => {
    e.preventDefault();
    
    if(value === DOMESTIC_CREDIT) dispatch(actions.setCurrency(CURRENCY.DOMESTIC));
    else dispatch(actions.setCurrency(CURRENCY.OVERSEA));

    setPaymentMethod(value);
  };

  const handleSubmitVideo = (e) => {
    e.preventDefault();

    if (!terms) {
      setMessage({ terms: "Please check your Agreement" });
      return;
    }

    onSubmit();
  }

  return (
    <Style.Container>
      {/* <CurrencySelectBox /> */}

      <Style.LeftSection>
        <Style.ProcessContainer>
          <Style.ProcessTitle>{t("selectedSection.title")}</Style.ProcessTitle>

          <Table priceCurrency={currentCurrency} t={t} />
        </Style.ProcessContainer>

        <Style.ProcessContainer>
          <Style.ProcessTitle color={"true"}>
            {t("paymentInfo.title")}
          </Style.ProcessTitle>

          {itemType === 'video-class' ?
            <></>
            :

            <Style.ShippingContainer>
              <div className="header">
                <h3>
                  {t("paymentInfo.subtitle")}
                  <span>{t("paymentInfo.desc")}</span>
                </h3>

                <AddressContainer setFormState={setFormState} t={t} />
              </div>

              <Style.InputContainer>
                <label htmlFor="name">{t("paymentInfo.orderForm.name")}</label>

                <Style.Input
                  id="name"
                  type="text"
                  name="name"
                  value={formState.name}
                  autoComplete="username"
                  onChange={(e) => handleChange(e)}
                  required
                />

                {errors.name && (
                  <Style.WarnText className="error">{errors.name}</Style.WarnText>
                )}
              </Style.InputContainer>

              <Style.InputContainer>
                <label htmlFor="mobile">
                  {t("paymentInfo.orderForm.mobile")}
                </label>

                <div className="mobile">
                  <Style.Select
                    onChange={(e) => handleChange(e)}
                    options={[
                      { value: "010", label: "010", name: "mobileFirst" },
                      { value: "011", label: "011", name: "mobileFirst" },
                      { value: "016", label: "016", name: "mobileFirst" },
                      { value: "017", label: "017", name: "mobileFirst" },
                      { value: "018", label: "018", name: "mobileFirst" },
                      { value: "019", label: "019", name: "mobileFirst" },
                    ]}
                    components={animatedComponents}
                    inputId="mobile"
                    placeholder={"010"}
                    required
                  />

                  <Style.Input
                    id="mobileSecond"
                    type="text"
                    name="mobileSecond"
                    value={formState.mobileSecond}
                    autoComplete="tel-local-prefix"
                    onChange={(e) => handleChange(e)}
                    required
                  />

                  <Style.Input
                    id="mobileLast"
                    type="text"
                    name="mobileLast"
                    value={formState.mobileLast}
                    autoComplete="tel-local-suffix"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>

                {errors.mobile && (
                  <Style.WarnText className="error">
                    {errors.mobile}
                  </Style.WarnText>
                )}
              </Style.InputContainer>

              <Style.InputContainer className="address">
                <label htmlFor="address">
                  {t("paymentInfo.orderForm.address")}
                </label>
                <div className="checkboxContainer">
                  <div className="checkbox">
                    <Style.InputCheck
                      type="checkbox"
                      value="saveAddress"
                      name="saveAddress"
                      id="saveAddress"
                      onClick={(e) =>
                        handleClick("saveAddress", e.target.checked)
                      }
                    />
                    <label htmlFor="saveAddress">
                      {t("paymentInfo.orderForm.saveAddress")}
                    </label>
                  </div>
                </div>

                <div className="zipContainer">
                  <Style.Input
                    id="zoneCode"
                    type="text"
                    name="zoneCode"
                    value={formState.zoneCode}
                    readOnly
                    required
                  />
                  <button onClick={(e) => openModal(e, "api")}>
                    {t("paymentInfo.orderForm.zipCodeBtn")}
                  </button>
                </div>

                <div className="addressInput">
                  <Style.Input
                    id="addressFirst"
                    type="text"
                    name="addressFirst"
                    value={formState.addressFirst}
                    readOnly
                    required
                  />
                  <Style.Input
                    id="addressLast"
                    type="text"
                    name="addressLast"
                    value={formState.addressLast}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                {errors.address && (
                  <Style.WarnText className="error">
                    {errors.address}
                  </Style.WarnText>
                )}
              </Style.InputContainer>
              <Style.InputContainer>
                <label htmlFor="shippingRequest">
                  {t("paymentInfo.orderForm.shipping")}
                </label>
                <Style.Input
                  id="shippingRequest"
                  type="text"
                  name="shippingRequest"
                  onChange={(e) => handleChange(e)}
                />
              </Style.InputContainer>
            </Style.ShippingContainer>
          }
          <Coupon
            currency={currentCurrency}
            productsItems={productsItems}
            t={t}
          />
          <Style.PaymentContainer>
            <h3>{t("paymentInfo.orderForm.method")}</h3>
            <div className="payMethods">
              {/* {currentCurrency === CURRENCY.DOMESTIC ? (
                <button
                  className={paymentMethod === DOMESTIC_CREDIT ? "active" : ""}
                  onClick={handlePaymentMethod(DOMESTIC_CREDIT)}
                >
                  {t("orderSummary.domesticCredit")}
                </button>
              ) : (
                <>
                  <button
                    className={paymentMethod === OVERSEA_CREDIT ? "active" : ""}
                    onClick={handlePaymentMethod(OVERSEA_CREDIT)}
                  >
                    {t("orderSummary.overseasCredit")}
                  </button>
                  <button
                    className={paymentMethod === PAYPAL ? "active" : ""}
                    onClick={handlePaymentMethod(PAYPAL)}
                  >
                    Pay Pal
                  </button>
                  <button
                    className={paymentMethod === UNION_PAY ? "active" : ""}
                    onClick={handlePaymentMethod(UNION_PAY)}
                  >
                    Union Pay
                  </button>
                </>
              )} */}
                <button
                  className={paymentMethod === DOMESTIC_CREDIT ? "active" : ""}
                  onClick={handlePaymentMethod(DOMESTIC_CREDIT)}
                >
                  {t("orderSummary.domesticCredit")}
                </button>
                <button
                  className={paymentMethod === OVERSEA_CREDIT ? "active" : ""}
                  onClick={handlePaymentMethod(OVERSEA_CREDIT)}
                >
                  {t("orderSummary.overseasCredit")}
                </button>
                <button
                  className={paymentMethod === PAYPAL ? "active" : ""}
                  onClick={handlePaymentMethod(PAYPAL)}
                >
                  Pay Pal
                </button>
                <button
                  className={paymentMethod === UNION_PAY ? "active" : ""}
                  onClick={handlePaymentMethod(UNION_PAY)}
                >
                  Union Pay
                </button>
            </div>
          </Style.PaymentContainer>
          <Style.PaymentContainer className="terms">
            <h3>{t("paymentInfo.orderForm.confirm")}</h3>
            <div>
              <RadioButton handleClick={(state, data) => {
                if(itemType === 'book') handleClick(state, data);
                else if(itemType === 'video-class') setTerm((prev) => !prev);
              }}>
                <Trans
                  t={t}
                  i18nKey="paymentInfo.orderForm.terms"
                  components={{
                    link1: (
                      <span
                        className="terms"
                        onClick={(e) => openModal(e, "terms")}
                      />
                    ),
                  }}
                />
              </RadioButton>
              {itemType === 'video-class'?
                errors.terms && (
                <Style.WarnText className="error">
                  {errors.terms}
                </Style.WarnText>
                )
                :
                message.terms && (
                  <Style.WarnText className="error">
                    {message.terms}
                  </Style.WarnText>
                )
              }
            </div>
          </Style.PaymentContainer>
        </Style.ProcessContainer>
      </Style.LeftSection>
      <Style.RightSection>
        <OrderSummary
          onclick={(e)=>{
            if(itemType === 'book') handleSubmit(e);
            else if(itemType === 'video-class') handleSubmitVideo(e);
          }}
          priceCurrency={currentCurrency}
          data={itemType === 'book'
            ? currentCurrency === "USD" && data && data
            : currentCurrency === "USD" && videoData && videoData
          }
          formSubmit={formSubmit}
          pginfo={paymentMethod}
          t={t}
        />
      </Style.RightSection>
    </Style.Container>
  );
};

export default Process;

import React from 'react';

export const termsOfPrivacyZh = () => (
  <div class='policy'>
    <h3 class='policy-title'>个人信息处理方针</h3>

    <p>
      VISANG
      EDUCATION股份公司（以下简称“公司”）在获得用户的同意之下，收集·利用及提供个人信息。公司遵守『个人信息保护法』和『信息通信网利用促进及信息保护等相关法律』等所有相关法规，并且尽最大努力使会员的个人信息受到保护。
    </p>

    <p>
      本个人信息处理方针是针对masterTOPIK服务，说明公司是以何种用途及方式利用用户所提供的个人信息，且为了保护个人信息，通过哪些方法进行管理。
    </p>

    <p>
      公司的个人信息保护政策可因政府法令、方针的变更及公司内部政策的变动而发生变更。
    </p>

    <dl class='policy-subject'>
      <dt>目录 </dt>
      <dd>第1条 个人信息的利用目的</dd>
      <dd>第2条 个人信息的收集项目</dd>
      <dd>第3条 个人信息的共享及提供</dd>
      <dd>第4条 个人信息的委托处理</dd>
      <dd>第5条 个人信息的保管及利用期限</dd>
      <dd>第6条 个人信息的销毁流程及方法</dd>
      <dd>第7条 用户及法定代理人的权利和行使方法</dd>
      <dd>第8条 个人信息自动收集装置的安装/运营及拒绝的相关事宜</dd>
      <dd>第9条 个人信息的技术性/管理性保护对策</dd>
      <dd>第10条 个人信息的保护责任人及投诉服务</dd>
      <dd>第11条 个人信息处理方针的改正及通知</dd>
    </dl>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第1条 个人信息的利用目的</h4>

      <p>
        公司只以会员管理、服务提供·开发及品质提升为目的来利用个人信息。活用于营销及广告时，会另外获得用户的同意，用户即使拒绝，服务的利用也不会受到任何限制。
      </p>

      <p class='policy-tit'>(1) 会员管理</p>
      <p>
        确认本人、防止不良会员（因违反masterTOPIK利用条款第7条，永久限制利用的会员）的不正当利用和防止非认可使用、确认注册意向、为了调解纠纷的记录保存、投诉处理、传达通知事项
      </p>

      <p class='policy-tit'>
        (2) 履行关于服务提供的合同及根据提供服务的费用结算
      </p>
      <p>
        提供财货及内容、提供针对性服务、发送账单及购买明细、提供设备之间的同步服务、本人认证、购买及费用支付、物品派送等
      </p>

      <p class='policy-tit'>(3) 新服务开发及营销活用</p>
      <p>
        新服务开发、统计学特性、根据利用形态等提供针对性服务、刊登广告、调查访问频度、关于会员使用服务的统计
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第2条 个人信息的收集项目</h4>

      <p>
        公司为了会员注册、服务的申请及提供、圆满的处理顾客咨询等，收集如下个人信息。拒绝提供信息或邀请删除时，可能会限制利用masterTOPIK服务。
      </p>

      <div class='policy-sec'>
        <h5 class='policy-sec-tit'>1. 注册会员时</h5>
        <p>
          注册会员时，将收集如下个人信息，根据相关法令（masterTOPIK
          个人信息处理方针第5条保存期间），按其明示的期限内，利用及保管如下信息。
        </p>

        <p class='policy-tit'>(1) 用一般邮箱注册的会员</p>
        <ul class='policy-bu'>
          <li>·必填信息：姓名、邮箱地址、密码、国籍</li>
          <li>·选择信息：昵称、生日、性别、同意接收营销广告</li>
        </ul>

        <p class='policy-tit'>(2) 用SNS注册的会员</p>
        <ul class='policy-bu'>
          <li>·必填信息：SNS用户属性信息、邮箱地址、密码</li>
          <li>·选择信息：昵称、生日、性别、国籍、同意接收营销广告</li>
        </ul>
      </div>

      <div class='policy-sec'>
        <h5 class='policy-sec-tit'>2. 自动收集项目</h5>
        <p>
          用户在利用masterTOPIK服务时，公司将收集包括‘Cookie’在内的如下信息。
        </p>

        <p class='policy-tit'>(1) 网站</p>
        <p>
          收集网站用户的IP地址、浏览器类型、浏览器语言、运营体系、参考软件及硬件属性（包括装置ID）及结束页面及URL、点击数、查询的页面及查询顺序、使用日期及时间、收看的内容、总共收看时间、错误日志及网站使用方法等其他类似的信息。
        </p>

        <p class='policy-tit'>(2) 手机应用程序</p>
        <p>
          自动接收masterTOPIK手机应用程序用户的IP地址、操作系统、版本、包括互联网服务供应商，访问应用程序时使用的手机、平板电脑或电脑的特性信息、浏览器类型、
          SSAID、FCM令牌、域名及其他类似的信息、应用程序更新与否及时期、使用日期及时间、观看的内容、总共收看时间、错误日志及应用程序使用方法等其他类似的信息。
        </p>

        <p class='policy-tit'>(3) 位置信息</p>
        <p>
          自动收集用户的电脑或手机上的一般位置信息（举例：IP地址、城市/州或与IP地址相关的邮编号）。
        </p>
      </div>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第3条 个人信息的共享及提供</h4>

      <p>
        公司除个人信息的收集、利用目的之外，不会使用于其他范围且未征得用户同意，不得提供于第3方。但，已征得会员的同意或属于如下情形时，可例外处理。
      </p>

      <p>
        用户可拒绝同意向第3方提供个人信息，并且可随时撤回同意提供的意向。但，有可能会限制利用以第3方提供为基础的部分服务。
      </p>

      <ul class='policy-bu'>
        <li>·会员在事前同意公开或提供于第3方时</li>
        <li>
          ·搜查机构根据法令的规定或以搜查为目的，按照法令指定的流程及方法要求个人信息时
        </li>
        <li>·根据服务的提供，在结算费用时需要使用个人信息时</li>
        <li>
          ·为了使用于统计、学术研究或市场调查，以无法识别特定个人的形态来提供时
        </li>
        <li>
          ·为了履行关于信息通信服务的提供合同而需要使用的个人信息，因经济性、技术性的原因，无法征得通常的同意意向时
        </li>
        <li>
          ·发生营业的转让·合并等事由时（但，公司发生营业的转让等事由，需要移转用户的个人信息时，会按照关系法律所规定的流程及方法，事前通知个人信息移转的相关事宜，用户可行使对于个人信息移转的同意撤销权。）
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第4条 个人信息的委托处理</h4>

      <p>
        公司为了圆满及便利的服务，在最小限度的限制范围内，将个人信息委托给外部专门公司运营。在签署委托合同时，为了确保安全的管理个人信息，另外规定了相关事宜。
      </p>

      <p class='policy-tit'>(1) 信息的国际移转</p>
      <p>
        公司根据本政策，为了使用服务，在用户居住国家的海内外传送、存储及处理个人信息，特别是移转至‘韩国’。传送用户信息的国家的信息保护及其他法律可能笼统于用户居住国家的法律。
      </p>

      <p class='policy-tit'>(2) 结算</p>
      <ul class='policy-bu'>
        <li>·受托公司：Payletter Inc.、Paypal</li>
        <li>·委托业务：提供结算、购买安全服务</li>
        <li>·收集项目：银行卡公司、银行卡编号等</li>
        <li>·保管及利用期间：截止到会员注销及委托合同到期时</li>
      </ul>

      <p class='policy-tit'>(3) 派送</p>
      <ul class='policy-bu'>
        <li>·受托公司：Hanjin Inc. </li>
        <li>·委托业务：商品派送</li>
        <li>·收集项目：姓名、派送地地址、手机号等</li>
        <li>·保管及利用期间：达成目的后，立即销毁</li>
      </ul>

      <p class='policy-tit'>(4) 认证</p>
      <ul class='policy-bu'>
        <li>·受托公司：Payletter Inc.</li>
        <li>·委托业务：本人认证</li>
        <li>·收集项目：姓名、手机号等</li>
        <li>·保管及利用期间：达成目的后，立即销毁</li>
      </ul>

      <p class='policy-tit'>(5) 顾客咨询</p>
      <ul class='policy-bu'>
        <li>·受托公司：Zendesk</li>
        <li>·移转的国家：美国</li>
        <li>·委托也：顾客咨询及说明管理</li>
        <li>·保管及利用期间：遵从关于消费者不满或纠纷处理相关记录的保管方针</li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第5条 个人信息的保管及利用期限</h4>

      <p>
        原则上，用户注销会员时，公司立即销毁用户的个人信息。但，对于如下信息，根据相关法令，按其明示的期限分离保存，超过明示的期间后，立即销毁个人信息。
      </p>

      <p>
        完成注销的账号ID将永久不可使用，为了限制重新注册，检查ID及邮箱重复与否是以单方向加密，使用不可复原的固有值对照方式。
      </p>

      <p class='policy-tit'>(1) 会员注销时</p>
      <ul class='policy-bu'>
        <li>
          ·保管依据：防止不良用户的再次注册、名誉毁损等权利侵害纠纷及协助搜查
        </li>
        <li>·保管期间：会员注销后1年</li>
      </ul>

      <p class='policy-tit'>(2) 商业交易履历</p>
      <ul class='policy-bu'>
        <li>·保管依据：商法、电子商务等消费者保护的相关法律</li>
        <li>
          ·保管期间：
          <table class='policy-tbl'>
            <tbody>
              <tr>
                <th>合同或订购撤回等相关记录</th>
                <td>5年（电子商务等的消费者保护相关法律）</td>
              </tr>
              <tr>
                <th>款项支付及财货等供应的相关记录</th>
                <td>5年（电子商务等的消费者保护相关法律）</td>
              </tr>
              <tr>
                <th>消费者的不满或纠纷处理的相关记录</th>
                <td>3年（电子商务等的消费者保护相关法律）</td>
              </tr>
              <tr>
                <th>电子金融交易的相关记录</th>
                <td>5年（电子金融交易法）</td>
              </tr>
              <tr>
                <th>显示、广告的相关记录</th>
                <td>6个月（电子商务等的消费者保护相关法律）</td>
              </tr>
            </tbody>
          </table>
        </li>
      </ul>

      <p class='policy-tit'>(3) 网站访问记录</p>
      <ul class='policy-bu'>
        <li>·保管依据：通信保密法</li>
        <li>·保管期间：3个月</li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第6条 个人信息的销毁流程及方法</h4>

      <p>
        原则上，公司在达到个人信息的收集及利用目的后，立即销毁相关信息。销毁流程及方法如下。
      </p>

      <p class='policy-tit'>(1) 销毁流程</p>
      <p>
        用户为了注册会员等而输入的信息在达成目的之后，将移转至另外的DB（纸质文件移转至另外的文件箱），根据内部方针及其他相关法令的信息保护事由，保管指定期间（参考个人信息的保管及利用期限）后销毁。除依据法律使用之外，不以其他目的利用个人信息。
      </p>

      <p class='policy-tit'>(2) 销毁方法</p>
      <ul class='policy-bu'>
        <li>·纸质打印件形态：用碎纸机粉碎或通过焚烧进行销毁。</li>
        <li>·电子文件形态：使用无法再生记录的技术性方法进行删除。</li>
      </ul>

      <p class='policy-tit'>(3) 休眠账号的个人信息销毁流程</p>
      <p>
        公司根据“信息通信网利用促进及信息保护等相关法律第29条同法施行令第16条”，masterTOPIK服务对于1年以上未登录的账号，为了保护其个人信息，对该会员个人信息转换至休眠账号并另外进行管理。
      </p>
      <ul class='policy-bu'>
        <li>
          ·休眠处理的会员信息：通过会员注册或会员信息修改，收集/管理的所有信息
        </li>
        <li>
          ·公司休眠账号的个人信息分离·保管期间到来1个月前，通过邮箱等将相关内容通知于该用户。
        </li>
        <li>·休眠账号的个人信息自分离保管开始日起，3年后立即销毁。</li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第7条 用户及法定代理人的权利和行使方法</h4>

      <p class='policy-tit'>(1) 用户的权利</p>
      <ul class='policy-bu'>
        <li>·用户可随时查询及修改上传的个人信息，也可邀请解除注册。</li>
        <li>
          ·想要查询及修改个人信息时，可通过‘信息变更’直接阅览及修正，想要解除注册（同意撤回）时，需要通过客服中心‘1:1咨询’或向‘mastertopik@visang.com’发送注销意向，即可注销会员。
        </li>
        <li>
          ·以书面、电话或邮箱联系至个人信息保护责任人，即可为您处理所需服务。
        </li>
        <li>
          ·邀请修改个人信息错误时，在完成修改之前，不会利用或提供该个人信息。还有，将错误的个人信息以提供于第3方时，会将修改处理结果立即通知于第3方进行修改。
        </li>
        <li>
          ·公司了解到会员盗用他人的个人信息来注册会员等时，会立即对该ID采取必要的措施，例如停止利用服务或会员注销等。还有，认知到自身的个人信息被盗用的用户可要求对该ID停止利用服务或注销会员，公司会立即对其采取措施。
        </li>
      </ul>

      <p class='policy-tit'>(2) 用户的义务</p>
      <ul class='policy-bu'>
        <li>
          ·用户需准确的输入最新状态的个人信息，以预防意外事故的发生。因用户输入的不准确信息而发生的事故，需要由顾客自身承担，盗用他人的信息或输入虚假信息时，会丧失会员资格。
        </li>
        <li>
          ·顾客有义务自行保护且不侵害他人的信息。请注意包括密码在内的个人信息之外泄，并且要留意不去毁损他人的个人信息和帖子。
        </li>
        <li>
          ·未尽如上责任，毁损他人的信息及尊严时，会根据‘信息通信网利用促进及信息保护等相关法律’授予处罚。
        </li>
      </ul>

      <p class='policy-tit'>(3) 儿童的个人信息保护 </p>
      <p>
        公司的服务仅针对一般使用者，不向未满14岁的儿童提供服务。如得知因本服务的失误等收集了未满14岁儿童的信息时，即使未获得其父母的同意，也会立即删除信息。公司未经父母同意，不会故意处理未满16岁的EU居住者数据。如未经父母同意，收集了未满16岁的EU居住者信息，将采取合理措施后，立即删除信息。还有，根据该地区法律，遵守其他年龄限制及要求事宜。
      </p>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>
        第8条 个人信息自动收集装置的安装/运营及拒绝的相关事宜
      </h4>

      <p>
        公司为了提供个人化且针对性的服务，使用存储及读取用户信息的‘Cookie’。‘Cookie’是由运营网站而使用的服务器向用户浏览器发送的文本文件，会存储于用户的硬盘中。
      </p>

      <p class='policy-tit'>(1) 公司的‘Cookie’使用目的</p>
      <ul class='policy-bu'>
        <li>·各服务和网站的访问频度或网站等的分析</li>
        <li>·了解用户的喜好和关注领域及追踪下落</li>
        <li>
          ·了解各服务和网站的反问及利用形态、人气检索语、安全连接与否、用户规模等
        </li>
        <li>·通过了解各种活动参与程度及访问次数等，提供营销及个人针对性服务</li>
      </ul>

      <p class='policy-tit'>(2) ‘Cookie’的安装/运营及拒绝</p>
      <p>
        用户可选择性安装‘Cookie’。因此，用户可在网络浏览器的选项中，设置接受所有Cookie或提示存储Cookie或阻止所有Cookie的存储。但，阻止Cookie的存储时，需要登录的部分服务将会限制利用。
      </p>
      <ul class='policy-bu'>
        <li>
          ·Internet Explorer：网络浏览器上端的工具 &gt; 网络选项 &gt; 个人信息
          &gt; 设置
        </li>
        <li>
          ·Google Chrome：网络浏览器右侧的设置菜单 &gt; 画面上端的高级设置 &gt;
          个人信息的内容设置键 &gt; Cookie
        </li>
        <li>
          ·Firefox：选择选项菜单 &gt; 选择个人信息 &gt;
          访问记录-使用者自定义设置 &gt; Cookie水准设置
        </li>
        <li>
          ·Safari：选择环境设置菜单 &gt; 选择个人信息 &gt;
          设置Cookie及网站数据水准
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第9条 个人信息的技术性/管理性保护对策</h4>

      <p>公司为了保护用户的个人信息，付出如下努力。</p>

      <p class='policy-tit'>(1) 技术性的保护对策</p>
      <ul class='policy-bu'>
        <li>
          ·信息加密：对用户的个人信息加密及保管，利用加密的通信区间来传送。
        </li>
        <li>
          ·运营杀毒软件：为了防止黑客入侵或电脑病毒，安装杀毒软件后，24小时进行监视。还有，持续研究新生成的黑客/安全技术，并将其适用于服务中。
        </li>
        <li>
          ·利用防火墙：防止外部擅自访问，努力确保其他系统性的可确保安全性的技术性装置。
        </li>
      </ul>

      <p class='policy-tit'>(2) 管理性的保护对策</p>
      <ul class='policy-bu'>
        <li>
          ·处理职员的最小化：最小化了处理个人信息的职员，业务用PC切断了外部网络，减少了个人信息外泄的危险性。还有，对于处理个人信息的系统之密码生成和变更，以及访问权限，制订了体系化的基准，并持续进行着监察。
        </li>
        <li>·个人信息处理教育：针对个人信息的保护，对全体职员定期实施教育。</li>
        <li>
          ·运营个人信息保护的专门负责组织：为了保护用户的个人信息，并且解决疑问及处理不满，指定运营如下责任人。但，因会员本人的不注意或未在公司管理的领域内，所发生的事故等，并非源自公司责任的损害，公司概不负责。另外，发生毁损时，会向会员通知事实，寻求适当的对策和补偿方案。
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第10条 个人信息的保护责任人及投诉服务</h4>

      <p class='policy-tit'>(1) 个人信息保护责任人及联系方式</p>
      <p>
        公司为了保护顾客的个人信息及处理与个人信息相关的不满，按如下制订相关部门及个人信息管理责任人。
      </p>
      <ul class='policy-bu'>
        <li>·责任人：IT战略Core Jungwoo Lee</li>
        <li>·담당부서: 정보보안 Cell</li>
        <li>·电话：1544-0554</li>
        <li>·咨询：privacy@visang.com</li>
      </ul>
      <p>
        此外，可向masterTOPIK CS部(mastertopik@visang.com)咨询，
        我们会迅速的为您充分解答咨询内容。
      </p>

      <p class='policy-tit'>(2) 投入服务</p>
      <p>需要举报个人信息的侵害或需要咨询时，请咨询如下机构。</p>
      <ul class='policy-bu'>
        <li>
          <p>·个人信息侵害举报中心（韩国互联网振兴院运营）</p>
          <p>·电话：无区号 118</p>
          <p>
            ·URL:{' '}
            <a
              href='http://privacy.kisa.or.kr'
              target='_blank'
              rel='noreferrer'
            >
              http://privacy.kisa.or.kr
            </a>
          </p>
        </li>
        <li>
          <p>·大检察厅网络犯罪搜查科</p>
          <p>·电话：无区号 1301</p>
          <p>
            ·URL:{' '}
            <a href='http://www.spo.go.kr' target='_blank' rel='noreferrer'>
              http://www.spo.go.kr
            </a>
          </p>
        </li>
        <li>
          <p>·警察厅网络调查局</p>
          <p>·电话：无区号 182</p>
          <p>
            ·URL:{' '}
            <a
              href='http://cyberbureau.police.go.kr'
              target='_blank'
              rel='noreferrer'
            >
              http://cyberbureau.police.go.kr
            </a>
          </p>
        </li>
      </ul>
    </div>

    <div class='policy-section'>
      <h4 class='policy-subtitle'>第11条 个人信息处理方针的改正及通知</h4>

      <p>
        公司因法律、数据收集及使用惯例的变更或为了向服务反映功能或技术，有权修改个人信息保护政策。改正的个人信息保护政策可通过服务确认，用户需定期检讨个人信息保护政策。请确认文件最下端的‘告示/执行日期’，可了解到个人信息保护政策是否有变更。公司如追加、删除及修改如上内容时，至少在改正7日之前，通过官网的‘通知事项’进行通知。但，如有用户权利的重要变更内容，至少会在30日之前进行通知。
      </p>

      <p>
        <strong>告示日期：2021年 3月 2日</strong>
      </p>
      <p>
        <strong>执行日期：2021年 3月 22日</strong>
      </p>

      <p>如有服务利用的任何疑问事项，可随时咨询mastertopik@visang.com。</p>
    </div>
  </div>
);

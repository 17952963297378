import React, { useState, useEffect } from 'react';
import { StyledLibrarySearch } from 'container/LibraryPage/styled/libraryInput.style';
import { useAxios } from 'common/hook/useAxiosHook';
import communityApi from 'api/community';

const SearchFilterBox = ({ searchList, onSearch, searchWord }) => {
  const [getSearchData, refreshGetSearchData] = useAxios(
    communityApi.getSearchFilterData,
    [searchWord],
    true
  );
  const { data } = getSearchData;

  useEffect(() => {
    if (searchWord) {
      refreshGetSearchData(searchWord);
    }
  }, [searchWord]);

  return (
    <StyledLibrarySearch.SearchList show={searchList}>
      {data &&
        data.content.map((data, index) => {
          return (
            <li key={index} onClick={() => onSearch(data)}>
              {data}
            </li>
          );
        })}
    </StyledLibrarySearch.SearchList>
  );
};

export default SearchFilterBox;

import bookstoreType from './type';

export const addItem = (item) => ({
  type: bookstoreType.Add_Book,
  payload: item,
});

export const deleteItem = (item) => ({
  type: bookstoreType.Delete_Book,
  payload: item,
});

export const addCoupon = (coupon) => ({
  type: bookstoreType.Add_Coupon,
  payload: coupon,
});

export const deleteAllItem = () => ({ type: bookstoreType.Delete_All });

import styled, {css} from 'styled-components';

import "assets/css/font-pretendard.css"; /* event only */

import visualImg from 'assets/images/event/230103/visual.jpg';
import visualImgKo from 'assets/images/event/230103/ko/visual.jpg';
import btnLabel from 'assets/images/event/230103/ko/lb_btn.png';

import graphImg from 'assets/images/event/230103/graph.jpg';
import graphImgKo from 'assets/images/event/230103/ko/graph.jpg';
import couponImg from 'assets/images/event/230103/coupon.jpg';
import couponImgKo from 'assets/images/event/230103/ko/coupon.jpg';

const StyledEventPage = {
  BtnWrap: styled.div.attrs({ className: 'btnWrap' })`
    a {
      position: relative;
      display: inline-block;
      max-width: 540px;
      margin: auto;
      padding: 0.9em 1.3em;
      border-radius: 10em;
      line-height: 1em;
      font-weight: 700;
      font-size: 1.6em;
      color: #feff73;
      text-align: center;
      background: #5e28f3;
      .label {
        position: absolute;
        top: 0;
        right: 1.5em;
        width: 10.8rem;
        height: 3.6rem;
        background-image: url(${btnLabel});
        background-size: contain;
        background-position: 0 0;
        background-repeat: no-repeat;
        transform: translateY(-50%);
      }
    }
    @media (max-width: 750px) {
      a {
        font-size: 1.4em;
      }
    }
    `,

	EventWrap: styled.div`
    font-family: 'Pretendard';
		font-size: 2rem;
    line-height: 1.2;
    font-weight: 400;
    text-align: center;
    color: #555;
    letter-spacing: -0.035em;

		.blind {
			overflow: hidden;
			position: absolute;
			clip: rect(0 0 0 0);
			width: 1px;
			height: 1px;
			margin: -1px;
		}

    .inner {
      position: relative;
			max-width: 750px;
      margin: auto;
      background-position: 50% 0;
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 6em 0;
    }

    .h2 {
      margin-bottom: 2em;
      line-height: 1.4;
      font-weight: 300;
      font-size: 1.6em;
      color: #fff;
      h2 {
        font-weight: 700;
        font-size: 1.4em;
        .last {
          font-size: 0.78em;
        }
        br {
          display: none;
        }
      }
      span {
        position: relative;
        display: inline-block;
        color: #ffff00;
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          display: block;
          width: 100%;
          height: 0.5em;
          background: #003274;
          z-index: 1;
        }
        span {
          z-index: 2;
          &::before {
            display: none;
          }
        }
      }
    }

    .visual {
      background: #090f35;
			.inner {
        padding-top: 770px;
        background-size: auto 770px;
        background-image: url(${visualImg});
        background-repeat: no-repeat;
        background-position: 50% 0;
      }
    }
    &.ko .visual {
      .inner{
        background-image: url(${visualImgKo});
      }
    }

    .intro {
      background: #1a2871;
      .inner {
        max-width: 780px;
      }
      .flow_screen {position: relative; margin: 0 auto; overflow: hidden;}
      .flow_screen::before {content: ''; display: block; position: absolute; top: 0; left: 0; width: 2.5em; height: 100%; background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%); z-index: 2;}
      .flow_screen::after {content: ''; display: block; position: absolute; top: 0; right: 0; width: 2.5em; height: 100%; background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%); z-index: 2;}
      .flow_banner {position: relative; display: flex; -webkit-box-align: center; align-items: center;}
      .flow_banner ul {display: flex;}
      .flow_banner li {position:relative; flex-shrink: 0; box-sizing: content-box; width: 250px; height: 141px; border: 0.15em solid #080e31; margin-left: 0.25em;}
      .flow_banner li img {position:absolute; top: 0; left: 0; width: 100%; height: 100%;}
      .flow_banner.group1 ul:first-of-type {animation: 20s linear 0s infinite normal none running firstFlow;}
      .flow_banner.group1 ul:last-of-type {animation: 20s linear 0s infinite normal none running secondFlow; transform: translate(100%);}
      .flow_banner.group2 {margin-top: 1.5em;}
      .flow_banner.group2 ul:first-of-type {animation: 20s linear 0s infinite normal none running firstFlowR;}
      .flow_banner.group2 ul:last-of-type {animation: 20s linear 0s infinite normal none running secondFlowR; transform: translate(100%);}
      .flow_banner.group3 {margin-top: 1.5em;}
      .flow_banner.group3 ul:first-of-type {animation: 20s linear 0s infinite normal none running firstFlow;}
      .flow_banner.group3 ul:last-of-type {animation: 20s linear 0s infinite normal none running secondFlow; transform: translate(100%);}
      @keyframes firstFlow {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(-100%);
        }
        50.01%{
            transform: translateX(100%);
        }
        100%{
            transform: translateX(0);
        }
      }
      @keyframes secondFlow {
        0% {
            transform: translateX(0);
        }
        100% {
            transform: translateX(-200%);
        }
      }
      @keyframes firstFlowR {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(100%);
        }
        50.01%{
            transform: translateX(-100%);
        }
        100%{
            transform: translateX(0);
        }
      }
      @keyframes secondFlowR {
        0% {
            transform: translateX(-200%);
        }
        100% {
            transform: translateX(0);
        }
      }
    }

    .graph {
      background: #090f35;
      .img {
        height: 680px;
        background-size: auto 100%;
        background-image: url(${graphImg});
        background-repeat: no-repeat;
        background-position: 50% 0;
      }
    }
    &.ko .graph {
      .img {
        background-image: url(${graphImgKo});
      }
    }

    .coupon {
      background: #090f35;
      padding-bottom: 2em;
      .inner {
        padding-top: 1em;
      }
      .img {
        height: 702px;
        margin-bottom: 4em;
        background-size: auto 100%;
        background-image: url(${couponImg});
        background-repeat: no-repeat;
        background-position: 50% 0;
      }
    }
    &.ko .coupon {
      .img {
        background-image: url(${couponImgKo});
      }
    }

    @media (max-width: 750px) {
      .h2 {
        font-size: 1.5em;
        h2 {
          br {
            display: block;
          }
        }
      }
      .inner {
        padding: 4em 4%;
      }
      .visual {
        .inner {
          padding-top: 102vw;
          background-size: auto 102vw;
        }
      }
      .intro {
        .flow_banner li {width: 33.333vw; height: 18.8vw;}
      }
      .graph {
        .img {
          height: 86.1vw;
        }
      }
      .coupon {
        .img {
          height: 88.86vw;
        }
      }
    }

    .notification {
      background-color: #090f35;
      text-align: left;
      color: #fff;
      font-weight: 300;
      letter-spacing: normal;
      .inner {
        /* padding: 4em 4%; */
        padding: 0 0 4em;
      }
      @media (max-width: 780px) {
        .inner {
          padding: 0 4% 4em;
        }
      }
      h3 {
        border-top: 0.2em solid #333752;
        padding-top: 2.8em;
        text-align: center;
        font-size: 2.8rem;
        font-weight: 700;
      }
      dl {
        font-size: 2.2rem;
      }
      dt {
        margin: 2em 0 0.5em;
        font-weight: 500;
				a {
					border-bottom: none;
				}
      }
      dd {
        position:relative;
        padding-left: 1.2em;
        margin-bottom: 0.5em;
        color: #e5e4e6;
        &:before {
          content:"";
          display:inline-block;
          position: absolute;
          top:0.5em;
          left:0.3em;
          width: 0.25em;
          height: 0.25em;
          border-radius: 0.2em;
          background: #e5e4e6;
          vertical-align:middle;
        }
        em {
          color: #fff799;
        }
      }
      dl.number dd {
        &:before {
          display: none;
        }
        .num {
          position: absolute;
          top:0;
          left:0;
        }
      }
      P {
        padding: 1.6em 0 0.8em;
      }
      a {
        display: inline-block;
        border-bottom: 1px #fff solid;
        line-height: 1.2;
      }
    }
  `,
};

export default StyledEventPage;

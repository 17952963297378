import React, { useState, useRef, useEffect } from 'react';
import Style from '../styled/component.style';
//import { secondsToTime } from 'common/util/util';

const Audio = ({ source }) => {
  const [play, setPlay] = useState(true);
  const [second, setSecond] = useState(0);
  const audioRef = useRef(null);

  const togglePlay = () => {
    if (!audioRef) return;
    if (play) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
    setPlay((prev) => !prev);
  };

  useEffect(() => {
    // 오디오 플레이 타임 가져오기
    if (!audioRef) return;
    const currentTime = setInterval(() => {
      setSecond(Math.floor(audioRef.current.currentTime));
    }, 1000);
    return () => clearInterval(currentTime);
  }, [audioRef]);

  return (
    <Style.AudioContainer>
      <audio controls style={{ width:'100%' }} ref={audioRef}>
        <source src={source} type='audio/mpeg' />
      </audio>
      {/*
      <button
        type='button'
        className={play ? 'active' : ''}
        onClick={togglePlay}
      >
        재생
      </button>
      <span>{secondsToTime(second)}</span>
      */}
    </Style.AudioContainer>
  );
};

export default Audio;

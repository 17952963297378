import React, { useState, useEffect } from 'react';
import Style from './styled/AddressModal.style';
import { FiCheck } from 'react-icons/fi';
import { ModalContext } from 'component/Modal/modalContext';
import { useAxios } from 'common/hook/useAxiosHook';
import userApi from 'api/user';
import { useSelector } from 'react-redux';
import Loading from 'component/atoms/Loading';
import EmptyAddress from 'component/atoms/EmptyAddress';

const AddressModal = ({ setFormState, t }) => {
  const token = useSelector((state) => state.user.token);

  const [clickIndex, setClickIndex] = useState(0);
  let { handleModal } = React.useContext(ModalContext);
  const [{ data, loading }, refreshGetMyAddress] = useAxios(
    () => userApi.fetchMyAddress(token),
    []
  );
  const [{ data: deleteAddress }, refreshDeleteAddress] = useAxios(
    userApi.deleteMyAddress,
    [],
    true
  );

  useEffect(() => {
    refreshGetMyAddress();
  }, [deleteAddress]);

  const clickDelete = () => {
    refreshDeleteAddress(token, data[clickIndex].tblMemberDeliveryIdx);
  };

  const clickSelect = () => {
    if (!data) return;
    const {
      deliveryName,
      zipCode,
      deliveryAddress1,
      deliveryAddress2,
      deliveryTel,
    } = data[clickIndex];

    const TelNum = deliveryTel.trim().split('-');

    const addressData = {
      name: deliveryName,
      zoneCode: zipCode,
      addressFirst: deliveryAddress1,
      addressLast: deliveryAddress2,
      mobileFirst: TelNum[0],
      mobileSecond: TelNum[1],
      mobileLast: TelNum[2],
    };
    setFormState((prev) => {
      return { ...prev, ...addressData };
    });
    handleModal();
  };

  if (!data?.length) return <EmptyAddress />;
  return (
    <div>
      <Style.H2>{t('paymentInfo.addressForm.title')}</Style.H2>
      {data &&
        data.map((data, index) => {
          const {
            deliveryName,
            tblMemberDeliveryIdx,
            deliveryTel,
            deliveryAddress1,
            deliveryAddress2,
            zipCode,
          } = data;
          return (
            <Style.Address
              key={tblMemberDeliveryIdx}
              onClick={() => setClickIndex(index)}
              active={clickIndex === index ? 'true' : 'false'}
            >
              <span>
                <FiCheck color='#fff' size='2em' />
              </span>
              <div className='address'>
                <h3>{deliveryName}</h3>
                <p>{zipCode}</p>
                <p>{deliveryAddress1}</p>
                <p>{deliveryAddress2}</p>
                <p>{deliveryTel}</p>
              </div>
            </Style.Address>
          );
        })}
      <Style.ButtonContainer className='no-btn'>
        <button onClick={() => clickDelete()}>{t('paymentInfo.addressForm.delete')}</button>
        <button onClick={() => clickSelect()}>{t('paymentInfo.addressForm.select')}</button>
      </Style.ButtonContainer>
    </div>
  );
};

export default AddressModal;

import React, { useContext, useState, useRef, useEffect } from 'react';
import Style from 'container/MockTestPage/styled/TestWritePaper.style';
import PreviewImage from 'container/MockTestPage/WritePage/PreviewImage';
import Img from 'component/atoms/Img';

const Input = ({ data }) => {
  const [answer01, setAnswer01] = useState([]);
  const [answer02, setAnswer02] = useState([]);
  const [answer03, setAnswer03] = useState([]);

  const {
    bestAnswerImage,
    bestAnswerText,
    userAnswer,
    isCorrections,
    isCorrectionsView,
    writingTestAttachFilesResponses,
  } = data;

  useEffect(() => {
    setAnswer01(userAnswer ? userAnswer.split(/\r\n|\n/) : '');
    setAnswer02(isCorrections ? isCorrections.split(/\r\n|\n/) : '');
    setAnswer03(bestAnswerText ? bestAnswerText.split(/\r\n|\n/) : '');
  }, []);

  return (
    <>
      <Style.AnswerInputContainer>
        <p className='title'>답안 작성</p>
        <ul className='answer'>{answer01 ? answer01.map((ans) => <li>{ans}</li>) : undefined}</ul>
        {/* <textarea
          cols='30'
          className='answer'
          value={userAnswer ? userAnswer : ''}
          readOnly
        ></textarea> */}
      </Style.AnswerInputContainer>
      <Style.PreviewContainer>
        {writingTestAttachFilesResponses.map((data, index) => {
          const { filePath } = data;
          return <PreviewImage key={index} data={filePath} />;
        })}
      </Style.PreviewContainer>
      {isCorrectionsView ?
        <Style.AnswerInputContainer>
          <p className='title correction'>선생님 첨삭</p>
          <ul className='answer correction'>{answer02 ? answer02.map((ans) => <li dangerouslySetInnerHTML={{ __html: ans }}></li>) : undefined}</ul>
          {/* <textarea
            cols='30'
            value={isCorrections ? isCorrections : ''}
            className='answer correction'
            readOnly
          ></textarea> */}
        </Style.AnswerInputContainer> : undefined}
      <Style.AnswerInputContainer>
        <p className='title'>표준 답안</p>
        <ul className='answer'>{answer03 ? answer03.map((ans) => <li>{ans}</li>) : undefined}</ul>
        {/* <textarea
          cols='30'
          className='answer'
          value={bestAnswerText ? bestAnswerText : ''}
          readOnly
        ></textarea> */}
      </Style.AnswerInputContainer>
      {
        // <Style.PreviewContainer>
        //   <Img src={bestAnswerImage} className='writeAnswerImg' />
        // </Style.PreviewContainer>
      }
    </>
  );
};

export default Input;

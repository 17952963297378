import React from 'react';
import Style from './styled/OverViewList.style';

import { Circle } from 'rc-progress';
import { BiPause } from 'react-icons/bi';
import { BiPlay } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUrl, setLectureId } from './state/action';

const OverViewList = ({ title, percentage, playing, lectureId }) => {
  const dispatch = useDispatch();

  let overviewUrl = useSelector(
    (state) => state.wbmKorea.videoUrl?.overview
  );

  return (
    <Style.Accordion>
      <Style.List
        onClick={() => {
          dispatch(setLectureId(0));
          dispatch(setCurrentUrl(overviewUrl));
        }}
      >
        <span className='icon'>
          <Circle
            percent={
              lectureId=== 0? percentage : 0
            }
            strokeWidth={10}
            trailWidth={10}
            strokeColor={percentage && '#f8b209'}
            trailColor='#8b8f99'
            className='progressBar'
          />
          {lectureId === 0 && playing? (
            <BiPause className='progressBarIcon' color='#f8b209' />
          ) : (
            <BiPlay className='progressBarIcon' color='#8b8f99' />
          )}
        </span>
        <Style.ListTitle> {title}</Style.ListTitle>
      </Style.List>
    </Style.Accordion>
  );
};

export default OverViewList;

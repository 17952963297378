import styled from 'styled-components';
import lineThroughIcon from 'assets/images/ico_lineThrough.png';
const StyleBookList = {
  Container: styled.div`
    position: relative;
    min-height: 34.83em;
    padding: 4em 0 4em 22em;
    border-bottom: 1px #2b2e35 solid;
    &:last-of-type {
      border-bottom: none;
    }
    @media (max-width: 767px) {
      padding-left: 18em;
    }
    img {
      display: inline-block;
      position: absolute;
      left: 0;
      top: 4em;
      width: 19em;
      @media (max-width: 767px) {
        width: 14em;
      }
      @media (max-width: 375px) {
        width: 12em;
      }
    }
  `,
  Content: styled.div`
    position: relative;
    .bookInfo {
      h5 {
        margin-bottom: 0.4em;
        font-size: 2.4em;
        font-weight: 500;
      }

      .publisher {
        padding-right: 1.5em;
        color: #8b8f99;
        font-size: 1.4em;
        font-weight: 400;
        @media (max-width: 767px) {
          display: block;
          padding-right: 0;
          font-size: 2em;
        }
      }

      .page {
        padding-left: 1.5em;
        border-left: 1px solid #8b8f99;
        color: #8b8f99;
        font-size: 1.4em;
        font-weight: 400;
        @media (max-width: 767px) {
          padding-left: 0;
          border-left: none;
          font-size: 2em;
        }
      }
    }

    .desc {
      margin-top: 1.875em;
      color: #c9cbd1;
      font-size: 1.6em;
      font-weight: 400;
      @media (max-width: 767px) {
        display: none;
      }
    }
    .container {
      display: flex;
      justify-content: space-between;
      margin-top: 6.8em;
      margin-bottom: 1em;

      @media (max-width: 767px) {
        padding-left: 0;
        flex-direction: column;
        margin-top: 3em;
      }
    }
    .priceContainer {
      color: #c9cbd1;
      font-size: 1.4em;
      font-weight: 400;
      @media (max-width: 767px) {
        font-size: 1.75em;
      }
      span {
        display: inline-block;
      }
      .priceTxt {
        @media (max-width: 767px) {
          display: none;
        }
      }
      .actualPrice {
        position: relative;
        margin-left: 0.7em;
        padding: 0 0.3em;
        font-size: 1.14em;
        font-weight: 500;
        @media (max-width: 767px) {
          margin-left: 0;
        }
        &::after {
          content: '';
          width: 100%;
          height: 0.3125em;
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          background: url(${lineThroughIcon}) no-repeat right center;
          background-size: contain;
          transform: translateY(-50%);
        }
      }
      .price {
        @media (max-width: 767px) {
          display: block;
        }
        .finalPrice {
          margin-left: 0.5em;
          color: #fff;
          font-size: 1.71em;
          font-weight: 700;
          @media (max-width: 767px) {
            margin-left: 0;
          }
        }

        .discount {
          margin-left: 0.4em;
          color: #fff;
          font-size: 1.15em;
          font-weight: 400;
          svg {
            vertical-align: bottom;
          }
        }
      }
    }
  `,

  Button: styled.button`
    display: inline-block;
    padding: 0.9em 1.185em;
    border-radius: 0.375em;
    background-color: #485cc7;
    color: #fff;
    font-size: 1.6em;
    font-weight: 700;
    @media (max-width: 767px) {
      max-width: 10em;
      margin-top: 1em;
    }
  `,
};
export default StyleBookList;

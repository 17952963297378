import React, { useEffect, useRef, useState} from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import Styled from './index.style';
import SignUpRoutes from "./Routes";

import useLoginStatus from "common/hook/useLoginStatus";
import { ModalContext } from "component/Modal/modalContext";
import Login from "component/user/Login";

const EventPage = () => {
  const location = useLocation();
  const { url } = useRouteMatch();
  const language = localStorage.getItem('i18nextLng');
  const { t } = useTranslation('event/20220905Signup');

  const isLogin = useLoginStatus();
  let { handleModal } = React.useContext(ModalContext);

  // Tab
  const [tabIndex, setTabIndex] = useState(0);

  // Sticky
  const stickyWrapRef = useRef();
  const stickyRef = useRef();
  const [hHeight, sethHeight] = useState(0); //header bottom
  const [stickyHeight, setStickyHeight] = useState(0); //stickyRef height
  const [stickyOffset, setStickyOffset] = useState(0); //stickyWrapRef offsetTop
  const [winScrollY, setWinScrollY] = useState(window.scrollY);

  useEffect(() => {
    /* url 주소가 /event/signup/topik 이라면 tabIndex값 1로 셋팅 */
    const reg = /[^\/]+/g;
    const url = [...location.pathname.matchAll(reg)];
    console.log(url, url[url.length-1][0])
    if(url[url.length-1][0] === 'topik') setTabIndex(1);

    /* Mount 된 직후 초기값 셋팅 */
    onResize();

    window.addEventListener('resize', onResize)
    window.addEventListener('scroll', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
      window.removeEventListener('scroll', onResize);
    };
  }, []);

  const onResize = () => {
    const headerBtm = document.querySelector('header').getBoundingClientRect().bottom; //header bottom
    sethHeight(headerBtm);
    setStickyHeight(stickyRef.current.clientHeight);
    setStickyOffset(stickyWrapRef.current.offsetTop);
    setWinScrollY(window.scrollY);
  }

  useEffect(()=>{
    if(winScrollY > (stickyOffset - hHeight)){
      stickyRef.current.classList.add('sticky');
      stickyWrapRef.current.style.paddingTop = stickyHeight + 'px';
      stickyRef.current.style.top = hHeight + 'px';
    }else {
      stickyRef.current.classList.remove('sticky');
      stickyWrapRef.current.style.paddingTop = '0';
      stickyRef.current.style.top = '0';
    }
  }, [hHeight, stickyHeight, stickyOffset, winScrollY]);

	return (
    <>
      <Styled.EventWrap className={language === 'ko-KR' ? 'ko' : ''}>
        <Styled.StickyWrap ref={stickyWrapRef}>
          <Styled.Sticky ref={stickyRef}>
            <ul>
              <li>
                <Link to={`${url}/korean`} className={tabIndex === 0 ? 'active' : ''} onClick={() => setTabIndex(0)}>{t('tab.tab1')}</Link>
              </li>
              <li>
                <Link to={`${url}/topik`} className={tabIndex === 1 ? 'active' : ''} onClick={() => setTabIndex(1)}>{t('tab.tab2')}</Link>
              </li>
            </ul>
          </Styled.Sticky>
        </Styled.StickyWrap>

        <SignUpRoutes />
      </Styled.EventWrap>
    </>
  );
};

export default EventPage;
import styled from 'styled-components';
import TableLayout from 'common/styled/tableLayout.style';

const StyledOverviewList = {
  Accordion: styled.div`
    margin-bottom: 0.625em;
    background-color: #2b2e35;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      span {
        color: #fff;
      }
    }
  `,
  List: styled.div`
    ${TableLayout}
    font-size: 1.6em;
    padding: 1em;
    color: #c9cbd1;
    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      position: relative;
      width: 2.3em;
      line-height: 0;
      > .progressBar {
        width: 2.2em;
        height: 1.5em;
      }
      > .progressBarIcon {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }
    }
  `,
  ListTitle: styled.span`
    padding-left: 0.3em;
    font-weight: 700;
  `,
};

export default StyledOverviewList;
